import { connect } from "react-redux";
import { RootState } from "src/ipm-shared/store/model/reducers";
import * as controlSelector from "src/ipm-shared/components/Form/selectors";
import ScheduleType from "src/ipm-shared/components/ScheduleType";
import { CHECKOUT_FORM } from "src/ipm-shared/store/model/Card/const";

import * as actions from "./actions";
import * as selectors from "./selectors";

import _isUndefined from "lodash-es/isUndefined";
import * as ReactRouter from "react-router";

import Component from "./View";

const mapStateToProps = (state: RootState) => ({
  getCheckoutFormControl: selectors.getControlByForm(state, CHECKOUT_FORM),
  accountCountryId: selectors.getAccountCountryId(state),
  isHongKongAccount: selectors.isHongKongAccount(state),
  chargeDate: selectors.getChargeDate(state),
  currencyId: selectors.getCurrentCurrencyId(state),
  currentCurrency: selectors.getCurrentCurrency(state),
  earliestDate: selectors.getEarliestDate(state),
  flashPayDate: selectors.getFlashPayDate(state),
  exclusionDates: selectors.getExclusionDates(state),
  pspNonWorkingDates: selectors.getPspNonWorkingDates(state),
  getCurrenciesById: selectors.getCurrenciesById(state),
  hasSelectedPayee: selectors.hasSelectedPayee(state),
  hasSelectedPayment: selectors.hasSelectedPayment(state),
  isChargeToday: selectors.getIsChargeToday(state),
  isValidated: !_isUndefined(
    controlSelector.getControl(state, "schedule_type").value
  ),
  selectedPayees: selectors.getSelectedPayees(state),
  selectedPayment: selectors.getSelectedPaymentDetail(state),
  selectedRecurringPayment:
    controlSelector.getControl(state, "schedule_type").value ===
    ScheduleType.OPTION_RECURRING,
  getControl: (name: string, form: string) =>
    selectors.getControl(state, name, form),
  countryCode: selectors.getAccountCountryCode(state),
  availableAcquirers: selectors.getAvailableAcquirers(state),
  ipmMerchantCategories: selectors.getIpmMerchantCategories(state),
  paymentRequest: selectors.getCurrentPaymentRequest(state),
  isBusiness: selectors.getIsBusinessAccount(state),
  isPersonalAccount: selectors.getIsPersonalAccount(state),
  features: selectors.getFeatures(state)
});

const mapDispatchToProps = {
  addPaymentRequestSubmit: actions.addPaymentRequestSubmit,
  editPaymentRequestSubmit: actions.editPaymentRequestSubmit,
  fetchDateConditions: actions.fetchDateConditions,
  selectPayment: actions.selectPayment,
  setControl: actions.setControl,
  toast: actions.toast
};

export type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  ReactRouter.RouterProps &
  ReactRouter.RouteComponentProps<any>;

export default connect(mapStateToProps, mapDispatchToProps)(Component);
