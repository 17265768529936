import { matchPath } from "react-router";
import ROUTES from "src/bepaid/routes";
import { UrlParams } from "../types";
import * as queryString from "query-string";

export const useMakePaymentUrlParams = (
  matchRoute: string = ROUTES.PAYMENTS_CREATION,
  pathName?: string
) => {
  const match = matchPath<UrlParams>(pathName ? pathName : location.pathname, {
    path: matchRoute
  });

  const { entity, paymentType, action } = match?.params || ({} as UrlParams);

  const replaceRouteWithParams = (
    route: string,
    options?: {
      newAction?: string;
      newEntity?: string;
      newPaymentType?: string;
    }
  ) => {
    const {
      newAction = action,
      newEntity = entity,
      newPaymentType = paymentType
    } = options || {};

    let newRoute = route;
    if (newAction) {
      newRoute = newRoute.replace(":action", newAction);
    }
    if (newPaymentType) {
      newRoute = newRoute.replace(":paymentType", newPaymentType);
    }
    if (newEntity) {
      newRoute = newRoute.replace(":entity", newEntity);
    }

    return newRoute;
  };

  const getToken = () => {
    const qs = queryString.parse(window.location.search);
    return qs.token;
  };

  return {
    query: queryString.parse(window.location.search),
    entity,
    paymentType,
    action,
    token: getToken(),
    replaceRouteWithParams
  };
};
