/* tslint:disable:object-literal-sort-keys */
import UrlHelper from "src/ipm-shared/Utils/UrlHelper";

const ROUTES = {
  ROOT: "/",

  LOGIN: "/login",
  LOGIN_REDIRECT: `/login${UrlHelper.getQsRedirectUrlUserLoggedOut()}`,
  RESET_PASSWORD: "/reset-password",
  SET_NEW_PASSWORD: "/set-password",
  PASSWORD_RESET: "/password-reset",
  CLOSE_ACCOUNT: "/close-account",
  CHECKOUT: "/payment_collection/checkout/:token",
  CHECKOUT_PROCESSED: "/payment_processed/:token",
  CHECKOUT_CANCEL: "/payment_cancel/:token",
  CHECKOUT_OVERDUE: "/payment_overdue/:token",
  CHECKOUT_LINK_FAIL: "/payment_link_fail/:token",
  CHECKOUT_CONFIRMATION: "/payment_confirmation/:token",

  REGISTER: "/register",
  POST_REGISTER: "/post-register",
  VERIFICATION_MOBILE: "/mobile-verification",
  VERIFICATION_EXPIRED: "/verification-expired",

  INVOICES: "/invoices",
  INVOICE_DETAILS: "/invoices/:id",
  CREATE_INVOICES: "/invoices/create",
  INVOICES_DRAFT: "/invoices/draft",
  CREATE_INVOICES_STEPS: "/invoices/create/:step",
  CREATE_INVOICES_STEP1: "/invoices/create/step1",
  CREATE_INVOICES_STEP2: "/invoices/create/step2",
  CREATE_INVOICES_STEP3: "/invoices/create/step3",
  CREATE_INVOICES_STEP4: "/invoices/create/step4",
  CREATE_INVOICES_SENT: "/invoices/create/sent",

  CUSTOMERS: "/customers",
  CUSTOMER_CREATION: "/customers/create",
  CUSTOMER_EDIT: "/customers/:id/update",
  CUSTOMER_DETAIL: "/customers/:id",

  PAYMENTS: "/payments",
  PAYMENT_DETAILS: "/payments/:id",
  PAYMENT_EDIT: "/payments/:id/edit",
  SCHEDULE_EDIT: "/payments/:id/edit-schedule",
  SCHEDULE_EXTEND: "/payments/:id/extend-schedule",
  SCHEDULE: "/schedules",
  PAYMENT_SCHEDULE_DETAILS: "/payment-schedules/:id",

  // This is for setup make payment: rent, salary, invoice, tax and action is select, add, schedule ...etc
  // MAKE_PAYMENT_SELECT_TYPE: "/payments/:paymentType/make",
  // MAKE_PAYMENT_SELECT_COUNTRY: "/payments/:paymentType/country",
  // MAKE_PAYMENT_SELECT_CURRENCY: "/payments/:paymentType/currency",
  PAYMENTS_CREATION: "/payments/:paymentType/:entity/:action",
  PAYMENT_CHECKOUT_SUCCESS: "/checkout/:token",

  RECIPIENTS: "/recipients/:entity",
  RECIPIENTS_DETAILS: "/recipients/:entity/:id",
  RECIPIENTS_DETAILS_UPDATE: "/recipients/:entity/:id/update",
  RECIPIENTS_CREATE: "/recipients/:entity/create",
  RECIPIENTS_LANDLORDS: "/recipients/landlords",
  RECIPIENTS_LANDLORDS_CREATE: "/recipients/landlords/create",
  RECIPIENTS_EMPLOYEES: "/recipients/employees",
  RECIPIENTS_EMPLOYEES_CREATE: "/recipients/employees/create",
  RECIPIENTS_SUPPLIERS: "/recipients/suppliers",
  RECIPIENTS_SUPPLIERS_CREATE: "/recipients/suppliers/create",
  RECIPIENTS_BANK_ACCOUNTS: "/recipients/bank-accounts",
  RECIPIENTS_BANK_ACCOUNTS_CREATE: "/recipients/bank-accounts/create",
  INSIGHTS: "/insights",
  INSIGHTS_PAY: "/insights-pay",
  SEARCH: "/search",
  FILTER: "/filter",
  KYC: "/kyc",
  MY_INFO: "/my-info",
  REFERRAL: "/referral",
  INCORPORATION_DATE: "/incorporation_date",
  PAYFETCH_TOUR: "/payfetch-tour",
  ADDITIONAL_DATA: "/additional-data",
  USERS: "/users",
  ADD_USER: "/users/add",

  UNSUBSCRIBE_EMAIL: "/unsubscribe_email/:token",

  SUPPORT: "https://help.ipaymy.com/en"
};

export const isPayUrl = (pathname: string) => {
  if (pathname.indexOf("/payments") === 0) {
    return true;
  }

  if (pathname.indexOf("/recipients") === 0) {
    return true;
  }

  return false;
};

export default ROUTES;
