import React, { useEffect } from "react";
import { isDesktop } from "react-device-detect";
import classNames from "classnames";

import { PLATFORM } from "src/ipm-shared/components/GlobalUI/reducers";
import { history } from "src/ipm-shared/store";

import ROUTES from "src/bepaid/routes";

import SelectGroup from "../SelectGroup";

import styles from "./SwitchPlatform.module.scss";
import { FetchModalID } from "src/ipm-shared/store/model/actions";

const SwitchPlatform = (props: {
  platform: number;
  isBusinessAccount: boolean;
  switchPlatform: (modalID: number) => void;
  getDashboardStatistics: any;
  toggleModal: (modalId: number) => void;
  isDeclinedKYC: boolean;
}) => {
  const [loading, setLoading] = React.useState(false);

  const onChangeMode = (e: any) => {
    if (props.isBusinessAccount) {
      props.switchPlatform(e.target.value);
      history.push(ROUTES.ROOT);
      props.getDashboardStatistics(setLoading);
    } else {
      props.toggleModal(FetchModalID.BENEFITS_OF_FETCH_MODAL);
    }
  };

  useEffect(() => {
    const platformRadioElements = document.getElementsByClassName(
      "ant-radio-button"
    );
    platformRadioElements[1].setAttribute("id", "ipaymy_btn_platform_fetch");
  }, []);

  return (
    <div
      className={classNames(styles.wrapper, { [styles.isDesktop]: isDesktop })}
    >
      <SelectGroup
        loading={loading}
        className={styles.customSelectGroup}
        options={[
          { label: "Pay", value: PLATFORM.PAY },
          {
            label: "Fetch",
            value: PLATFORM.FETCH,
            disabled: props.isBusinessAccount && props.isDeclinedKYC
          }
        ]}
        onChange={onChangeMode}
        value={props.platform}
        defaultValue={PLATFORM.PAY}
      />
    </div>
  );
};

export default SwitchPlatform;
