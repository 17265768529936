/**
 * Actions
 *
 * ActionCreators are like the "pub" of pubsub. It's just broadcasting an event (action).
 * So, don't couple it with a specific reducer. Let ANY reducer listen to
 * an action.
 *
 * `typesafe-actions` has a nice utility function so we don't need to define action types as
 * constants. Use it together with `getType(type)` in the reducers.
 */
import { createAction } from "typesafe-actions";
import { PaymentCalculatorBasic } from "src/ipm-shared/store/model/OnBoarding/types";
import { KycData } from "./reducers";

export const wallexKycPersonal = createAction(
  "onboarding/WALLEX_KYC_PERSONAL",
  resolve => (kycStatus: string) => resolve({ kycStatus })
);

export const wallexKycBusiness = createAction(
  "onboarding/WALLEX_KYC_BUSINESS",
  resolve => (step: number) => resolve({ step })
);

export const paymentCalculator = createAction(
  "onboarding/PAYMENT_CALCULATOR",
  resolve => (args: {
    currencyId: number;
    countryId: number;
    paidCurrencyId: number;
    cb: (args: PaymentCalculatorBasic) => void;
  }) => resolve(args)
);

export const setEmployeeStuff = createAction(
  "onboarding/SET_EMPLOYEE_STUFF",
  resolve => (args: {
    type: "industry" | "position" | "status";
    employeeData: {
      [id: number]: string;
    };
  }) => resolve(args)
);

export const fetchEmployeeStuff = createAction(
  "onboarding/FETCH_EMPLOYEE_STUFF",
  resolve => (type: "industry" | "position" | "status") => resolve({ type })
);

export const fetchWallexKycData = createAction(
  "onboarding/FETCH_WALLEX_KYC_DATA"
);

export const setWallexKycData = createAction(
  "onboarding/SET_WALLEX_KYC_DATA",
  resolve => (kycData: KycData, hasFetched?: boolean) =>
    resolve({ kycData, hasFetched })
);

export const getAddressByPostalCode = createAction(
  "onboarding/GET_ADDRESS_BY_POSTAL_CODE",
  resolve => (postalCode: string) => resolve({ postalCode })
);

export const setResidentialAddress = createAction(
  "onboarding/SET_RESIDENTIAL_ADDRESS",
  resolve => (residentialAddress: string) => resolve({ residentialAddress })
);
