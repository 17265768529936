import { getType } from "typesafe-actions";
import * as types from "./types";
import * as actions from "./actions";
import _isEmpty from "lodash-es/isEmpty";
import { CollectionRates } from "../CollectedAccount/reducers";

export type PaymentCollectionsType = {
  createdAt: string;
  updatedAt: string;
  currencyId: number;
  id: number;
  invoiceNo: number;
  customer: {
    name: string;
    email: string;
    mobileNumber: string;
    mobileCountryId: number;
  };
  receiptNumber: string;
  dueDate?: string;
  emailStatusId?: number;
  invoiceAmount: number;
  isSmsInvoice: boolean;
  grossAmount: number;
  fee: number;
  feeStructure: {
    customerRate: number;
    customerFee: number;
    requesterRate: number;
    requesterFee: number;
  };
  feePayer: number;
  requesterRates: CollectionRates[];
  net: number;
  batchId: string;
  payoutDate: string;
  paymentStatusId?: number;
  datePaid?: string;
  // For payment detail response structure
  supportingDocument: Array<{
    key: string;
    name: string;
    size: number;
    url: string;
  }>;
  payUrl?: string;
  smsStatusId?: number;
  order?: number;
  card?: {
    brandId: number;
  };
};

export type PaymentCollectionsState = {
  readonly isFetching: boolean;
  readonly payments: PaymentCollectionsType[];
  readonly total: number;
  readonly activity: {
    request?: number;
    inprogress?: number; // include onhold payment
    completed?: number;
  };
  readonly sumPaymentInThisMonth: {
    request?: number;
    inprogress?: number;
    completed?: number; // include marked-as-paid payment
  };
};

const defaultState: PaymentCollectionsState = {
  activity: {},
  isFetching: false,
  payments: [],
  sumPaymentInThisMonth: {},
  total: 0
};

export default (state: PaymentCollectionsState, action: types.Action) => {
  if (_isEmpty(state)) {
    state = defaultState;
  }

  switch (action.type) {
    case getType(actions.setPaymentCollections):
      return {
        ...state,
        activity: action.payload.activity
          ? action.payload.activity
          : state.activity,
        isFetching: action.payload.isFetching,
        payments: action.payload.payments
          ? action.payload.payments
          : state.payments,
        total: action.payload.total
      };

    case getType(actions.appendPaymentCollections):
      return {
        ...state,
        isFetching: action.payload.isFetching,
        payments: state.payments.concat(action.payload.payments)
      };

    case getType(actions.bePaidSetSumPaymentInThisMonth):
      return {
        ...state,
        sumPaymentInThisMonth: action.payload.sumPaymentInThisMonth
      };
  }
  return state;
};
