import React, { useState, useMemo } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import * as jwtDecode from "jwt-decode";
import classNames from "classnames";

import Flex from "antd-mobile/es/flex";
import Typography from "antd/es/typography";

import { LOGIN_FORM } from "src/ipm-shared/store/model/Auth/const";

import ROUTES from "src/bepaid/routes";
import Button from "src/bepaid/components/Form/controls/Button";
import Card from "src/bepaid/components/Card";
import GuestLayout from "src/bepaid/layouts/GuestLayout";
import InputText from "src/bepaid/components/Form/controls/InputText";
import { invokeMessage } from "src/bepaid/components/Message";
import Image from "src/bepaid/components/Image";

import HeaderLogo from "src/bepaid/assets/images/desktop/header_ipaymy_logo.svg";

import styles from "./VerificationExpired.module.scss";

const VerificationExpired: React.FC<any> = props => {
  const [loading, setLoading] = useState(false);

  const email = useMemo(() => {
    const token = props.qs.token;

    try {
      const data: any = jwtDecode(token);
      return data.email;
    } catch (err) {
      return null;
    }
  }, [props.qs]);

  const resendEmail = () => {
    if (email) {
      setLoading(true); // ok
      setTimeout(() => {
        invokeMessage("success", "Resent verification email!");
        setLoading(false); // ok
      }, 3000);
    } else {
      invokeMessage("error", "Email invalid!");
    }
    props.requestResendVerifyEmail();
  };

  const renderContent = () => {
    return (
      <div className={styles.wrapper}>
        <Flex
          justify="center"
          align="center"
          className={styles.instructionSection}
        >
          <Flex.Item span={22}>
            <Typography.Text>
              This verification link has expired, resend verification email to:{" "}
              <Typography.Link href="">{email}</Typography.Link>
            </Typography.Text>
          </Flex.Item>
        </Flex>
        <Flex justify="center" align="center" className={styles.buttonSection}>
          <Flex.Item span={24}>
            <Button
              type="primary"
              htmlType="button"
              loading={loading}
              onClick={resendEmail}
            >
              {loading ? "Requesting..." : "Resend verification email"}
            </Button>
          </Flex.Item>
        </Flex>
        <Flex
          direction="column"
          justify="center"
          align="center"
          className={styles.extraSection}
        >
          <Flex.Item span={24} className={styles.centerText}>
            <Typography.Link
              className={classNames(styles.strongLink, styles.signUpText)}
              href={ROUTES.REGISTER}
            >
              Sign up again.
            </Typography.Link>
          </Flex.Item>
          <Flex.Item
            span={24}
            className={classNames(styles.contactUs, styles.centerText)}
          >
            <Typography.Link
              href="mailto:support@ipaymy.com"
              className={styles.strongLink}
            >
              Need help? Contact us.
            </Typography.Link>
          </Flex.Item>

          <div hidden={true}>
            <InputText
              name="email"
              form={LOGIN_FORM}
              defaultValue={email}
              value={email}
              disabled={true}
            />
          </div>
        </Flex>
      </div>
    );
  };

  const renderDesktopView = () => {
    return (
      <div className={styles.desktopWrapper}>
        <Card>{renderContent()}</Card>
      </div>
    );
  };

  return (
    <GuestLayout
      header={<Image src={HeaderLogo} />}
      title={
        <Typography.Text className={styles.title}>
          Verification link expired
        </Typography.Text>
      }
    >
      <BrowserView>{renderDesktopView()}</BrowserView>
      <MobileView>{renderContent()}</MobileView>
    </GuestLayout>
  );
};

export default VerificationExpired;
