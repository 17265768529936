import React, { useState, useEffect, useMemo } from "react";
import FormStyles from "src/bepaid/components/Form/CommonForm.module.scss";
import InputText from "src/bepaid/components/Form/controls/InputText";
import { useMakePaymentUrlParams } from "src/bepaid/pages/Payments/hooks";
import { history } from "src/ipm-shared/store";
import ROUTES from "src/bepaid/routes";
import Skeleton from "src/bepaid/components/Skeleton";
import Pagination from "src/bepaid/components/Pagination";
import Image from "src/bepaid/components/Image";
import IconSearch from "src/bepaid/assets/images/common/icon_search.svg";
import NextIcon from "src/bepaid/assets/images/common/desktop/next_black_noborder.svg";
import Form from "src/bepaid/components/Form";
import ListEntity from "./components/ListEntity";
import { purposeByEntity } from "src/bepaid/pages/Payments/const";
import _lowerCase from "lodash-es/lowerCase";
import { PAYEES_PAGE_COUNT } from "src/ipm-shared/store/model/Payee/const";

import styles from "./SelectEntity.module.scss";

const initPagination = {
  offset: 0,
  pageCount: PAYEES_PAGE_COUNT
};

const SelectEntity = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [inputText, setInputText] = useState("");
  const [pagination, setPagination] = React.useState(initPagination);

  const {
    replaceRouteWithParams,
    entity,
    paymentType
  } = useMakePaymentUrlParams();
  const purpose = purposeByEntity[entity];
  const isWallex = paymentType === "international";

  const setInputSearchText = (value: any) => {
    setTimeout(() => {
      setInputText(value);
      setPagination({ ...pagination, offset: 0 });
    }, 300);
  };

  useEffect(() => {
    props.fetchPayees({
      type: purpose,
      noRedirect: true,
      isWallex,
      offset: pagination.offset,
      pageCount: pagination.pageCount,
      searchKeyword: inputText,
      cb: (data?: any) => {
        if (data) {
          if (data.length === 0) {
            history.replace(
              `${replaceRouteWithParams(ROUTES.PAYMENTS_CREATION, {
                newAction: "add"
              })}`
            );
          }
        }
      },
      loadingFunc: setLoading
    });
  }, [inputText, pagination]);

  const addSearchTextAsNewEntity = () => {
    history.replace(
      `${replaceRouteWithParams(ROUTES.PAYMENTS_CREATION, {
        newAction: "add"
      })}?name=${inputText}`
    );
  };

  // const optionsFiltered = props.list;
  const optionsFiltered = useMemo(() => {
    const list = props.list.filter((item: any) => {
      return item.isInternational === isWallex;
    });

    return list;
  }, [props.list]);

  const handleOnChangePage = (offset: number) => {
    setPagination({ ...pagination, offset });
  };

  const normalizedEntity =
    entity === "self_transfer" ? "bank account" : _lowerCase(entity);

  return (
    <div className={styles.wrapper}>
      <Form className={FormStyles.Form}>
        <InputText
          placeholder={`Search ${normalizedEntity}s...`}
          onChangeCustom={setInputSearchText}
          suffix={<Image src={IconSearch} />}
        />
      </Form>

      <div>
        <Skeleton loading={loading}>
          {optionsFiltered.length === 0 && inputText.trim().length > 0 ? (
            <div onClick={addSearchTextAsNewEntity}>
              <a>
                Add “{inputText}” as a new {normalizedEntity}.
              </a>
            </div>
          ) : (
            <>
              <ListEntity
                entity={entity}
                paymentType={paymentType}
                options={optionsFiltered}
                onChangeCustom={props.selectPayees}
                selectedPayee={props.selectedPayee}
              />
              <Pagination
                customClassname={styles.paginationWrapper}
                defaultMode={true}
                hideOnSinglePage={true}
                showSizeChanger={false}
                showLessItems={true}
                total={props.total}
                showQuickJumper={
                  props.total / pagination.pageCount > 10 && {
                    goButton: (
                      <button className={styles.gotoButton}>
                        <b>
                          Go
                          <Image src={NextIcon} className={styles.gotoIcon} />
                        </b>
                      </button>
                    )
                  }
                }
                locale={{ jump_to: "Go to page" }}
                isDisableToggle={props.total / pagination.pageCount < 5}
                onChange={handleOnChangePage}
                {...pagination}
              />
            </>
          )}
        </Skeleton>
      </div>
    </div>
  );
};

export default SelectEntity;
