// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Checkbox_wrapper__MDeQr{opacity:1;width:100%}.Checkbox_wrapper__MDeQr .ant-upload{width:100%}.Checkbox_wrapper__MDeQr .ant-checkbox+span{color:#624ce0;margin-left:0 !important;margin-bottom:.5rem}.Checkbox_helperText__1GhUk{margin-top:-0.6875rem !important;color:#74747b !important;font-size:.75rem !important;line-height:.875rem}.Checkbox_helperText__1GhUk .purple_text{color:#5a6be8 !important}.Checkbox_helperTextHasError__3LEov{width:100%;margin-top:0 !important;color:#c40698 !important;font-size:.75rem !important}", "",{"version":3,"sources":["webpack://./src/bepaid/components/Form/controls/Checkbox/Checkbox.module.scss"],"names":[],"mappings":"AAAA,yBAAS,SAAS,CAAC,UAAU,CAAC,qCAA6B,UAAU,CAAC,4CAAoC,aAAa,CAAC,wBAAwB,CAAC,mBAAmB,CAAC,4BAAY,gCAAgC,CAAC,wBAAwB,CAAC,2BAA2B,CAAC,mBAAmB,CAAC,yCAAiC,wBAAwB,CAAC,oCAAoB,UAAU,CAAC,uBAAuB,CAAC,wBAAwB,CAAC,2BAA2B","sourcesContent":[".wrapper{opacity:1;width:100%}.wrapper :global .ant-upload{width:100%}.wrapper :global .ant-checkbox+span{color:#624ce0;margin-left:0 !important;margin-bottom:.5rem}.helperText{margin-top:-0.6875rem !important;color:#74747b !important;font-size:.75rem !important;line-height:.875rem}.helperText :global .purple_text{color:#5a6be8 !important}.helperTextHasError{width:100%;margin-top:0 !important;color:#c40698 !important;font-size:.75rem !important}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "Checkbox_wrapper__MDeQr",
	"helperText": "Checkbox_helperText__1GhUk",
	"helperTextHasError": "Checkbox_helperTextHasError__3LEov"
};
export default ___CSS_LOADER_EXPORT___;
