import React from "react";
import {
  BrowserView,
  MobileView,
  isDesktop,
  isMobile
} from "react-device-detect";
import classNames from "classnames";
import Typography from "antd/es/typography";

import { history } from "src/ipm-shared/store";
import { FetchModalID } from "src/ipm-shared/components/GlobalUI/actions";

import Form from "src/bepaid/components/Form";
import Image from "src/bepaid/components/Image";
import Button from "src/bepaid/components/Form/controls/Button";
import DesktopModalLayout from "src/bepaid/components/Modal/components/DesktopModalLayout";
import MainLayout from "src/bepaid/layouts/MainLayout";
import ROUTES from "src/bepaid/routes";

import QuestionSingpassIcon from "src/bepaid/assets/images/verify/question_singpass_icon.svg";

import styles from "./SystemUpgradeModal.module.scss";

const ErrorIdentity: React.FC<any> = props => {
  const part1 =
    "Our system is currently undergoing an upgrade and is temporarily unavailable. We apologise for the inconvenience.";
  const part2 = (
    <Typography.Text className={styles.itemText}>
      Please try again or contact us at{" "}
      <Typography.Link href="mailto:support@ipaymy.com">
        support@ipaymy.com
      </Typography.Link>{" "}
      <br />
      for further assistance.
    </Typography.Text>
  );

  const renderContent = () => {
    return (
      <Form
        name="my-info-error-identity-form"
        layout="vertical"
        requiredMark={true}
        className={styles.form}
      >
        <div className={styles.formWrapper}>
          <div className={styles.title}>
            <div className={styles.itemSection}>
              <Image src={QuestionSingpassIcon} className={styles.image} />
            </div>
            <div className={styles.itemSection}>
              <Typography.Text className={styles.heroText}>
                {part1}
              </Typography.Text>
            </div>
          </div>
          <div className={styles.itemSection}>
            <Typography.Text className={styles.itemText}>
              {part2}
            </Typography.Text>
          </div>
        </div>
      </Form>
    );
  };

  const toggleModal = () => {
    props.toggleModal(FetchModalID.SYSTEM_UPPGRADE_MODAL);

    history.push(ROUTES.ROOT);
  };

  const renderButton = () => (
    <div className={styles.button}>
      <Button type="primary" htmlType="submit" onClick={toggleModal}>
        Got it
      </Button>
    </div>
  );

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.wrapperDesktop]: isDesktop,
        [styles.wrapperMobile]: isMobile
      })}
    >
      <BrowserView>
        <DesktopModalLayout
          hasBorder={false}
          onClose={toggleModal}
          btnSubmit={renderButton()}
        >
          {renderContent()}
        </DesktopModalLayout>
      </BrowserView>
      <MobileView>
        <MainLayout
          header={{
            hideMenu: true,
            onClose: toggleModal
          }}
          footer={renderButton()}
        >
          {renderContent()}
        </MainLayout>
      </MobileView>
    </div>
  );
};

export default ErrorIdentity;
