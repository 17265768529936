// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Select_wrapper__2aXV_{opacity:1;width:100%}.Select_wrapper__2aXV_ .ant-upload{width:100%}.Select_wrapper__2aXV_ .Select_btnUpload__tPgbn{width:100%;height:50px;border-radius:5px;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:justify;justify-content:space-between}.Select_helperText__1ETPP{margin-top:-0.6875rem !important;color:#74747b !important;font-size:.75rem !important}.Select_helperTextHasError__tA7VP{margin-top:0 !important;color:#74747b !important;font-size:.75rem !important}", "",{"version":3,"sources":["webpack://./src/bepaid/components/Form/controls/Select/Select.module.scss"],"names":[],"mappings":"AAAA,uBAAS,SAAS,CAAC,UAAU,CAAC,mCAA6B,UAAU,CAAC,gDAAoB,UAAU,CAAC,WAAW,CAAC,iBAAiB,CAAC,mBAAY,CAAZ,YAAY,CAAC,qBAAkB,CAAlB,kBAAkB,CAAC,qBAA4B,CAA5B,6BAA6B,CAAC,0BAAY,gCAAgC,CAAC,wBAAwB,CAAC,2BAA2B,CAAC,kCAAoB,uBAAuB,CAAC,wBAAwB,CAAC,2BAA2B","sourcesContent":[".wrapper{opacity:1;width:100%}.wrapper :global .ant-upload{width:100%}.wrapper .btnUpload{width:100%;height:50px;border-radius:5px;display:flex;align-items:center;justify-content:space-between}.helperText{margin-top:-0.6875rem !important;color:#74747b !important;font-size:.75rem !important}.helperTextHasError{margin-top:0 !important;color:#74747b !important;font-size:.75rem !important}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "Select_wrapper__2aXV_",
	"btnUpload": "Select_btnUpload__tPgbn",
	"helperText": "Select_helperText__1ETPP",
	"helperTextHasError": "Select_helperTextHasError__tA7VP"
};
export default ___CSS_LOADER_EXPORT___;
