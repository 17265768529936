import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import _get from "lodash-es/get";
import _isEmpty from "lodash-es/isEmpty";
// import { isDesktop } from "react-device-detect";

import { history } from "src/ipm-shared/store";
import { RootState } from "src/ipm-shared/store/model/reducers";
import { PLATFORM } from "src/ipm-shared/components/GlobalUI/reducers";
import { AccountProfile } from "src/ipm-shared/store/model/AccountProfile/reducers";
import { ACCOUNT_TYPE } from "src/ipm-shared/store/model/AccountProfile/const";

import * as selectors from "src/bepaid/store/model/Fetch/selectors";
import * as actions from "src/bepaid/store/model/Fetch/actions";
import {
  FetchModalID,
  toggleModal
} from "src/bepaid/store/model/Fetch/actions";
import useConstructor from "src/bepaid/hooks/useConstructor";
import SplashScreen from "src/bepaid/components/SplashScreen";

import ROUTES from "../routes";
import * as localStorageUtil from "../utils/localStorage";

const mapStateToProps = (state: RootState) => ({
  hasToken: selectors.hasToken(state),
  isSingaporeAccount: selectors.isSingaporeAccount(state),
  isAustraliaAccount: selectors.isAustraliaAccount(state),
  acquirerId: selectors.getAcquirerId(state)
});

const mapDispatchToProps = {
  fetchAccountProfiles: actions.fetchAccountProfiles,
  fetchCompanies: actions.fetchCompanies,
  fetchCustomers: actions.fetchCustomers,
  fetchMainCollectedAccount: actions.fetchMainCollectedAccount,
  fetchCards: actions.fetchCards,
  start: actions.start,
  toggleModal: actions.toggleModal,
  switchPlatform: actions.switchPlatform,
  getCustomerList: actions.getCustomerList
};

const withAuth = (WrapperComponent: React.ComponentType) => {
  const HOC = (props: any) => {
    useConstructor(() => {
      props.start();
    });

    const [ready, setReady] = React.useState(false);
    const disableSplash = localStorageUtil.isDisplayedSplashScreen();

    const showKYCPromptFirstLogin = () => {
      if (props.isSingaporeAccount) {
        history.push(ROUTES.MY_INFO);
      } else if (window.location.pathname !== "/kyc") {
        history.push(`${ROUTES.KYC}`);
      }
    };

    const showAdditionalDataPromptFirstLogin = () => {
      history.push(ROUTES.ADDITIONAL_DATA);
    };

    const setDefaultPlatform = () => {
      const existPlatform = localStorage.getItem("global_ui_platform");

      if (!existPlatform) {
        props.switchPlatform(PLATFORM.PAY);
      }
    };

    const checkOnboardingAccount = (profile: AccountProfile, data: any) => {
      if (profile.userSettingRequired.amexPromoModal) {
        if (disableSplash) {
          props.toggleModal(FetchModalID.PROMO_POPUP);
        } else {
          setTimeout(() => {
            props.toggleModal(FetchModalID.PROMO_POPUP);
          }, 2100);
        }
      }

      if (profile.type === ACCOUNT_TYPE.PERSONAL) {
        if (profile.userSettingRequired.additionalDataModal) {
          showAdditionalDataPromptFirstLogin();
        } else if (profile.userSettingRequired.sgMyInfo) {
          // show MyInfo popup here
          toggleModal(FetchModalID.MY_INFO_VERIFICATION_MODAL);
        }
      } else {
        if (profile.countryId !== 1) {
          if (!profile.userSettingRequired.kycFirstTimePopup) {
            const isCollectedAccount = !_isEmpty(data);
            const isDraftCollectedAccount = isCollectedAccount && data.draft;
            const hasBizfile =
              isCollectedAccount &&
              !_isEmpty(
                _get(data, "payee_data.kyc_document.b_acra_bizfile", [])
              );
            if (!isCollectedAccount) {
              showKYCPromptFirstLogin();
              return;
            }

            if (isDraftCollectedAccount) {
              if (!hasBizfile) {
                showKYCPromptFirstLogin();
                return;
              }
            }
          }
        }

        if (
          profile.userSettingRequired.showSuggestionModal &&
          window.location.pathname === "/"
        ) {
          history.push(`${ROUTES.PAYFETCH_TOUR}`);
        }
      }
    };

    React.useEffect(() => {
      setDefaultPlatform();
      props.fetchAccountProfiles((error: any, profile: AccountProfile) => {
        if (!error) {
          props.fetchMainCollectedAccount((err: any, data: any) => {
            if (!err) {
              setReady(true);
              localStorageUtil.setDisplaySplashScreen(true);
              setDefaultPlatform();

              checkOnboardingAccount(profile, data);
            }
          });
        } else {
          window.Logger.log(error);
          // TODO: logout here with failed fetching
        }
      }, false);

      props.fetchCompanies(true);
      props.fetchCustomers(
        0,
        "y",
        true,
        undefined,
        undefined,
        undefined,
        undefined,
        "desc"
      );
      props.getCustomerList();
      props.fetchCards(undefined, 0);
    }, []);

    return !props.hasToken ? (
      <Redirect to={ROUTES.LOGIN_REDIRECT} />
    ) : (
      <SplashScreen ready={ready} disabled={disableSplash}>
        <WrapperComponent {...props} />
      </SplashScreen>
    );
  };

  return connect(mapStateToProps, mapDispatchToProps)(HOC);
};

export default withAuth;
