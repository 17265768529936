import * as React from "react";
import classnames from "classnames";

const requireComponent = (loader: () => Promise<any>) => {
  type LazyComponentStates = {
    Component: any;
    show: boolean;
  };
  class ComponentLoader extends React.Component<any, LazyComponentStates> {
    private unmounted: boolean = false;
    constructor(props: any) {
      super(props);

      this.state = {
        Component: undefined,
        show: false
      };
    }

    public componentDidMount() {
      loader().then((Component: { default: React.Component }) => {
        if (!this.unmounted) {
          this.setState({ Component: Component.default, show: true });
        }
      });
    }

    public componentWillUnmount() {
      this.unmounted = true;
    }

    public render() {
      const Component = this.state.Component;

      if (Component !== undefined && !Component) {
        return (
          <span>
            Page cannot be fetched. Click <a href="/">Here</a> to reload
          </span>
        );
      }

      return (
        <div
          className={classnames("chunk-component", {
            loaded: this.state.show
          })}
        >
          {Component && <Component {...this.props} />}
        </div>
      );
    }
  }

  return ComponentLoader;
};

export default requireComponent;
