import React from "react";
import { isMobile } from "react-device-detect";
import Image from "src/bepaid/components/Image";
import FlashPay from "src/bepaid/assets/images/dashboard/carousel/flash_pay.svg";
import styles from "./IntroducingFlashpay.module.scss";

const IntroducingFlashpay = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.topLayer} />
      <div className={styles.bottomLayer} />
      <div className={styles.topLeft}>
        <p>
          <span>Introducing&nbsp;</span>
          <span>Flash Pay</span>
        </p>
        <p className={styles.description}>Get next-day payouts now</p>
      </div>
      <div className={styles.right}>
        <Image
          src={FlashPay}
          {...(isMobile
            ? {
                width: 260
              }
            : {})}
        />
      </div>
      <div className={styles.bottomLeft}>
        <a
          className={styles.learnMore}
          target="_blank"
          href={
            "http://help.ipaymy.com/en/articles/8014167-how-do-i-get-the-next-day-s-payout-in-singapore"
          }
        >
          Learn more.
        </a>
      </div>
    </div>
  );
};

export default IntroducingFlashpay;
