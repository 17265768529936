import React from "react";
import _get from "lodash-es/get";

import Landlords from "./Landlords";
import Employees from "./Employees";
import Suppliers from "./Suppliers";
import BankAccounts from "./BankAccounts";

const View = (props: any) => {
  const entity = _get(props, "match.params.entity") || "";

  if (entity === "landlords") {
    return <Landlords {...props} />;
  }

  if (entity === "employees") {
    return <Employees {...props} />;
  }

  if (entity === "suppliers") {
    return <Suppliers {...props} />;
  }

  if (entity === "bank-accounts") {
    return <BankAccounts {...props} />;
  }

  return <></>;
};

export default View;
