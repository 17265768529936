import * as React from "react";
// @ts-ignore
import { useIntl } from "react-intl";

let intl: any;

const GlobalIntl: React.FC<any> = (props: any) => {
  intl = useIntl();

  return props.children;
};

export default GlobalIntl;

export const getIntl = () => intl;
