// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PaymentDetailModal_wrapper__wz80s{display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center}.PaymentDetailModal_wrapperMobile__1TELh{padding-bottom:0}.PaymentDetailModal_iconMoreAction__1Zl1e{border:1px solid #c5c5c5;border-radius:100%;font-size:1rem;padding:.25rem;margin-right:.25rem}", "",{"version":3,"sources":["webpack://./src/bepaid/shared/modals/PaymentDetailModal/PaymentDetailModal.module.scss"],"names":[],"mappings":"AAAA,mCAAS,mBAAY,CAAZ,YAAY,CAAC,oBAAqB,CAArB,sBAAsB,CAAC,yCAAe,gBAAgB,CAAC,0CAAgB,wBAAwB,CAAC,kBAAkB,CAAC,cAAc,CAAC,cAAc,CAAC,mBAAmB","sourcesContent":[".wrapper{display:flex;justify-content:center}.wrapperMobile{padding-bottom:0}.iconMoreAction{border:1px solid #c5c5c5;border-radius:100%;font-size:1rem;padding:.25rem;margin-right:.25rem}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "PaymentDetailModal_wrapper__wz80s",
	"wrapperMobile": "PaymentDetailModal_wrapperMobile__1TELh",
	"iconMoreAction": "PaymentDetailModal_iconMoreAction__1Zl1e"
};
export default ___CSS_LOADER_EXPORT___;
