/**
 * Selectors
 *
 * It's like "views" in relational databases.
 * Store the most efficient format in the reducer state.
 * But to retrieve into easy to consume form, use selector.
 */
import { RootState } from "src/ipm-shared/store/model/reducers";
import ReducerFactory from "src/ipm-shared/Utils/ReduxReducer";
import fetchReducer from "./reducers";

ReducerFactory.registerReducer({ fetch: fetchReducer });

export * from "src/ipm-shared/components/GlobalUI/selectors";
export * from "src/ipm-shared/components/Form/selectors";
export * from "src/ipm-shared/store/model/Auth/selectors";
export * from "src/ipm-shared/store/model/AccountProfile/selectors";
export * from "src/ipm-shared/store/model/Company/selectors";
export * from "src/ipm-shared/store/model/CollectedAccount/selectors";
export * from "src/ipm-shared/store/model/Country/selectors";
export * from "src/ipm-shared/store/model/Uen/selectors";
export * from "src/ipm-shared/store/model/Customer/selectors";
export * from "src/ipm-shared/store/model/Currency/selectors";
export * from "src/ipm-shared/store/model/Payee/selectors";
export * from "src/ipm-shared/store/model/OnBoarding/selectors";
export * from "src/ipm-shared/store/model/PaymentCollectionRequest/selectors";
export * from "src/ipm-shared/store/model/Card/selectors";
export * from "src/ipm-shared/store/model/Payment/selectors";
export * from "src/ipm-shared/store/model/PaymentRequest/selectors";
export * from "src/ipm-shared/store/model/DateCondition/selectors";

export const getDashboardStatistics = (state: RootState) =>
  state.fetch.dashboardStatistics;

export const getInvoiceStatistics = (state: RootState) =>
  state.fetch.invoiceStatistics;
export const getInvoiceList = (state: RootState) => state.fetch.invoiceList;

export const getTotalInvoices = (state: RootState) => state.fetch.totalInvoices;

export const getInvoiceListModal = (state: RootState) =>
  state.fetch.invoiceListModal;

export const getCustomerStatistics = (state: RootState) =>
  state.fetch.customerStatistics;
export const getCustomerList = (state: RootState) => state.fetch.customerList;

export const getCustomerDetail = (state: RootState) => (id: number) => {
  return state.fetch.customerList.find(customer => +customer.id === +id);
};

export const getInvoiceInsights = (state: RootState) =>
  state.fetch.invoiceInsights;

export const getInvoiceSelected = (state: RootState) => () => {
  return state.fetch.invoiceList.find(
    item => String(item.id) === String(state.fetch.selectedInvoiceId)
  );
};

export const getInvoiceDraftSelected = (state: RootState) => () => {
  return state.fetch.invoiceListModal.find(
    item => String(item.id) === String(state.fetch.selectedInvoiceId)
  );
};

export const getPayInsights = (state: RootState) => state.fetch.payInsights;

export const getBepaidSelectedPaymentDetail = (state: RootState) =>
  state.payments.selectedPayment;

export const getFirstFetchDataByKey = (state: RootState) => (key: string) =>
  state.fetch.firstTimeFetchData[key] || false;

export const getConfirmedMyInfo = (state: RootState) =>
  state.fetch.confirmedMyInfo;

export const getPayFetchRecentActivities = (state: RootState) =>
  state.fetch.payFetchRecentActivities;
