import { connect } from "react-redux";

import { RootState } from "src/ipm-shared/store/model/reducers";

import View from "./View";
import * as actions from "src/bepaid/store/model/Fetch/actions";
import * as selectors from "src/bepaid/store/model/Fetch/selectors";

const mapStateToProps = (state: RootState, props: any) => ({
  payees: selectors.getPayees(state, "invoice"),
  selectedPayeesIds: state.payees.selectedPayeeIds,
  selectedPayees: selectors.getSelectedPayees(state),
  controls: selectors.getControls(state, ""),
  isCryptoPaymentMethod: selectors.isCryptoPaymentMethod(state),
  accountCountryId: selectors.getAccountCountryId(state)
});

const mapDispatchToProps = {
  addEmployeeSubmit: actions.addEmployeeSubmit,
  addLandlordSubmit: actions.addLandlordSubmit,
  addSupplierSubmit: actions.addSupplierSubmit,
  addBusinessEmployeeSubmit: actions.addBusinessEmployeeSubmit,
  addSelfTransferSubmit: actions.addSelfTransferSubmit,
  removeForm: actions.removeForm,
  resetExtraPayees: actions.resetExtraPayees,
  validatePaymentPayeeSubmit: actions.validatePaymentPayeeSubmit,
  addPaymentRequestSubmit: actions.addPaymentRequestSubmit,
  fetchDateConditions: actions.fetchDateConditions,
  parseServerErrors: actions.parseServerErrors,
  fetchPayees: actions.fetchPayees,
  selectPayees: actions.selectPayees
};

export type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(View);
