import React, { useEffect, useMemo, useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import DesktopModalLayout from "src/bepaid/components/Modal/components/DesktopModalLayout";
import MainLayout from "src/bepaid/layouts/MainLayout";

import Button from "src/bepaid/components/Form/controls/Button";
import Progress from "src/bepaid/components/Progress";
import ClonedValue from "src/ipm-shared/components/Form/helpers/ClonedValue";
import Skeleton from "src/bepaid/components/Skeleton";
import SCHEDULED_TYPE from "src/ipm-shared/components/ScheduleType";

import ROUTES from "src/bepaid/routes";
import { history } from "src/ipm-shared/store";
import { useMakePaymentUrlParams } from "src/bepaid/pages/Payments/hooks";
import { ADD_FORM } from "src/ipm-shared/store/model/Payment/const";
import { purposeByEntity } from "src/bepaid/pages/Payments/const";

import _get from "lodash-es/get";
import format from "date-fns/format";

import { invokeMessage } from "src/bepaid/components/Message";

import T from "src/ipm-shared/Utils/Intl";
import PaymentUtil from "src/ipm-shared/Utils/Payment";
import { BRAND } from "src/ipm-shared/Utils/Images";
import { CHECKOUT_FORM } from "src/ipm-shared/store/model/Card/const";
import { FORM } from "src/ipm-shared/store/model/Payee/const";

import ScheduleForm from "./components/ScheduleForm";
import List from "./components/AddressList";

import styles from "./SchedulePaymentModal.module.scss";
import { ACQUIRER_ID } from "src/ipm-shared/store/model/Card/const";
import { BRAND_ID } from "src/ipm-shared/store/model/CollectedAccount/const";

import FormErrors from "src/ipm-shared/components/Form/helpers/Errors";
import FeatureGateUtil from "src/ipm-shared/components/FeatureGating/Util";
import { startOfMonth } from "date-fns";
import DateUtils from "src/ipm-shared/Utils/Date";

const View = (props: any) => {
  const {
    entity,
    paymentType,
    replaceRouteWithParams
  } = useMakePaymentUrlParams();
  const purpose = purposeByEntity[entity];
  const isWallex = paymentType === "international";
  const [isReady, setIsReady] = useState(false);

  const [loadingDate, setLoadingDate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [chargeDateEnd, setChargeDateEnd] = useState(new Date());
  const [defaultScheduleType, setDefaultScheduleType] = useState<number>();
  const [defaultRecurringStartDate, setDefaultRecurringStartDate] = useState<
    Date
  >();
  const [defaultRecurringEndDate, setDefaultRecurringEndDate] = useState<
    Date
  >();
  const [disableSelectNextYear, setDisableSelectNextYear] = useState(false);
  const ipmMerchantValue = _get(
    props.getControl("ipm_merchant_category_id", CHECKOUT_FORM),
    "value"
  );
  // const [payoutDateByAcquirer, setPayoutDatesByAcquirer] = useState([]);

  const handleChangePayoutDates = (data: any) => {
    if (props.availableAcquirers.length > 1) {
      const payoutDates = props.availableAcquirers.map((acquirer: any) => {
        const otherData = _get(data, "other_data").find(
          (o: any) => o.acquirer_id === acquirer.id
        );
        return {
          acquirerId: otherData.acquirer_id,
          payoutDate: otherData.payout_date,
          cardBrandLabel: acquirer.label,
          cardBrandId: acquirer.card_brand_id,
          cardName: acquirer.name,
          isInternational: acquirer.is_international_payment
        };
      });
      const findRecurse = (node: any, value: any): any => {
        if (value?.includes(node.value)) return node;

        var result;
        for (var i = 0; i < node.children?.length; i++) {
          result = findRecurse(node.children[i], value);
          if (result) return result;
        }
        return null;
      };
      let ipmSelected: any = null;
      if (!props.isBusiness) {
        for (const o of props.ipmMerchantCategories) {
          if (ipmSelected) break;
          ipmSelected = findRecurse(o, ipmMerchantValue);
        }
      } else {
        props.ipmMerchantCategories.forEach((item: any) => {
          if (item.children.length > 0) {
            for (let i = 0; i < item.children.length; i++) {
              if (item.children[i].value === ipmMerchantValue) {
                ipmSelected = item.children[i];
                return;
              }
            }
          }
          if (ipmSelected) {
            return;
          }
          if (item.value === ipmMerchantValue) {
            ipmSelected = item;
            return;
          }
        });
      }

      let isOnlyWorldpayMasterCard = false;
      let isOnlyWorldpayVisa = false;
      let ignoreWPMCC = false;
      let payeeAmount = 0;
      let allowAmexOption = false;
      if (props.isPersonalAccount) {
        allowAmexOption = true;
      }
      if (props.selectedPayees.length > 0) {
        props.selectedPayees.forEach((item: any) => {
          if (item) {
            let itemAmount = Number(
              _get(
                props.getControl(`supplier_amount_${item.id}`, FORM),
                "value",
                0
              )
            );
            if (entity === "supplier") {
              if (item.isAmexMCC === true) {
                allowAmexOption = true;
              } else {
                allowAmexOption = false;
              }
            }
            if (item.isWPMCC === true) {
              isOnlyWorldpayMasterCard = true;
              isOnlyWorldpayVisa = true;
            }
            if (itemAmount > payeeAmount) {
              payeeAmount = itemAmount;
              if (item.ignoreWPMCC === true) {
                ignoreWPMCC = true;
              } else {
                ignoreWPMCC = false;
              }
            }
          }
        });
      }
      if (isOnlyWorldpayMasterCard) {
        // Do nothing
      } else {
        if (ignoreWPMCC) {
          isOnlyWorldpayMasterCard = false;
        } else {
          if (PaymentUtil.convertIpmMccId(ipmMerchantValue)) {
            if (ipmSelected.card_brand_ids) {
              if (ipmSelected.card_brand_ids.includes(BRAND.MASTER_CARD)) {
                isOnlyWorldpayMasterCard = true;
              }
            }
          }
        }
      }
      if (isOnlyWorldpayVisa) {
        // Do nothing
      } else {
        if (ignoreWPMCC) {
          isOnlyWorldpayVisa = false;
        } else {
          if (PaymentUtil.convertIpmMccId(ipmMerchantValue)) {
            if (ipmSelected.card_brand_ids) {
              if (ipmSelected.card_brand_ids.includes(BRAND.VISA)) {
                let mccCheck = Number(ipmMerchantValue.split("_")[1]);
                if (mccCheck === 11 || mccCheck === 35 || mccCheck === 25) {
                  isOnlyWorldpayVisa = true;
                } else {
                  isOnlyWorldpayVisa = true;
                }
              }
            }
          }
        }
      }

      isOnlyWorldpayMasterCard = true;
      if (
        props.features &&
        !FeatureGateUtil.verifyFeature(props.features, "AMEX_ALLOW_PAYMENT")
      ) {
        allowAmexOption = false;
      }
      if (
        props.selectedPayees &&
        props.selectedPayees[0] &&
        props.selectedPayees[0].extraPayees.length > 0
      ) {
        allowAmexOption = false;
      }
      if (isWallex) {
        var newBrandOptions = payoutDates.filter((option: any) => {
          if (!allowAmexOption) {
            if (option.cardBrandId === BRAND_ID.AMEX) {
              return false;
            }
          }
          return true;
          // if (option.isInternational) {
          //   return true;
          // }
          // return false;
        });
        // setPayoutDatesByAcquirer(newBrandOptions);
      } else {
        var newBrandOptions = payoutDates;
        if (!allowAmexOption) {
          newBrandOptions = newBrandOptions.filter((option: any) => {
            if (option.cardBrandId === BRAND_ID.AMEX) {
              return false;
            }
            return true;
          });
        }
        if (isOnlyWorldpayMasterCard) {
          newBrandOptions = newBrandOptions.filter((option: any) => {
            let wpSgCard =
              option.acquirerId === ACQUIRER_ID.STRIPE_TEST ||
              option.acquirerId === ACQUIRER_ID.STRIPE_PROD;
            if (option.cardBrandId === BRAND_ID.MASTER_CARD && wpSgCard) {
              if (option.cardName != "Worldpay") {
                return false;
              }
            }
            return true;
          });
        } else {
          var newBrandOptions = newBrandOptions.filter((option: any) => {
            let wpSgCard =
              option.acquirerId === ACQUIRER_ID.WORLDPAY_SG_LIVE ||
              option.acquirerId === ACQUIRER_ID.WORLDPAY_SG_TEST;
            if (option.cardBrandId === BRAND_ID.MASTER_CARD && wpSgCard) {
              if (option.cardName == "Worldpay") {
                return false;
              }
            }
            return true;
          });
        }
      }
      if (isOnlyWorldpayVisa) {
        newBrandOptions = newBrandOptions.filter((option: any) => {
          let wpSgCard =
            option.acquirerId === ACQUIRER_ID.STRIPE_TEST ||
            option.acquirerId === ACQUIRER_ID.STRIPE_PROD;
          if (option.cardBrandId === BRAND_ID.VISA && wpSgCard) {
            if (option.cardName != "Worldpay") {
              return false;
            }
          }
          return true;
        });
      } else {
        var newBrandOptions = newBrandOptions.filter((option: any) => {
          let wpSgCard =
            option.acquirerId === ACQUIRER_ID.WORLDPAY_SG_LIVE ||
            option.acquirerId === ACQUIRER_ID.WORLDPAY_SG_TEST;
          if (option.cardBrandId === BRAND_ID.VISA && wpSgCard) {
            if (option.cardName == "Worldpay") {
              return false;
            }
          }
          return true;
        });
      }
      // setPayoutDatesByAcquirer(newBrandOptions);
    }
  };

  const handleSelectDateConditions = (options: any, date: any) => {
    const cardBrandId =
      ipmMerchantValue?.includes("_amex") && entity === "supplier"
        ? BRAND_ID.AMEX
        : null;
    if (
      props.selectedPayees[0] &&
      PaymentUtil.isUSDPaymentInHK(
        props.accountCountryId,
        props.selectedPayees[0].currencyId as number,
        props.selectedPayees[0].countryId
      )
    ) {
      props.fetchDateConditions(
        new Date(date),
        props.selectedPayees.map((p: any) => p.bankId),
        3, // HK
        73, // USD
        73, // USD,
        cardBrandId,
        props.selectedPayees.map((p: any) => p.id),
        options,
        setLoadingDate
      );
    } else {
      props.fetchDateConditions(
        new Date(date),
        props.selectedPayees.map((p: any) => p.bankId),
        null,
        null,
        null,
        cardBrandId,
        props.selectedPayees.map((p: any) => p.id),
        options,
        setLoadingDate
      );
    }
  };

  const handleSelectStartDate = (date: any) => {
    if (date) {
      const options = {
        cb: (err?: any, data?: any) => {
          if (err) {
            invokeMessage("error", "Bad request!");
          }
          if (data) {
            handleChangePayoutDates(data);
          }
        }
      };

      setTimeout(() => {
        handleSelectDateConditions(options, date);
      }, 200);

      props.setControl({
        name: "start_date",
        errors: [],
        value: date,
        form: ADD_FORM
      });
    }
  };

  const handleSelectEndDate = (date: any) => {
    if (date) {
      const options = {
        cb: (err?: any, data?: any) => {
          if (err) {
            invokeMessage("error", "Bad request!");
          }
          if (data) {
            setChargeDateEnd(
              new Date(_get(data.data, "charge_date", Date.now()))
            );
          }
        },
        justGetData: true
      };

      setTimeout(() => {
        handleSelectDateConditions(options, date);
      }, 200);

      props.setControl({
        name: "end_date",
        errors: [],
        value: date,
        form: ADD_FORM
      });
    }
  };

  const replaceColonWithSpace = (data: object) => {
    // if (!payoutDateByAcquirer.length) {
    const replacedObject = Object.values(data);
    const colonIndex = replacedObject.indexOf(
      replacedObject.find((item: any) => String(item).includes(":"))
    );
    replacedObject[colonIndex] = String(replacedObject[colonIndex]).replace(
      ":",
      " "
    );
    return replacedObject;
    // }
    // return data;
  };

  const renderBeneficiaryStatement = () => {
    let localizedBeneficiaryToday = "";
    let localizedBeneficiary = "";

    switch (purpose) {
      case "rent":
        localizedBeneficiaryToday = "NEW_LABEL_RENT_SCHEDULE_BENIFICIARY_TODAY";
        localizedBeneficiary = "NEW_LABEL_RENT_SCHEDULE_BENIFICIARY";
        break;
      case "salary":
        localizedBeneficiaryToday =
          "NEW_LABEL_SALARY_SCHEDULE_BENIFICIARY_TODAY";
        localizedBeneficiary = "NEW_LABEL_SALARY_SCHEDULE_BENIFICIARY";
        break;
      case "invoice":
        localizedBeneficiaryToday =
          "NEW_LABEL_INVOICE_SCHEDULE_BENIFICIARY_TODAY";
        localizedBeneficiary = "NEW_LABEL_INVOICE_SCHEDULE_BENIFICIARY";
        break;
      case "insurance":
        localizedBeneficiaryToday =
          "NEW_LABEL_INVOICE_SCHEDULE_BENIFICIARY_TODAY";
        localizedBeneficiary = "NEW_LABEL_INVOICE_SCHEDULE_BENIFICIARY";
        break;
      case "salary_business":
        localizedBeneficiaryToday =
          "NEW_LABEL_SALARY_BUSINESS_SCHEDULE_BENIFICIARY_TODAY";
        localizedBeneficiary = "NEW_LABEL_SALARY_BUSINESS_SCHEDULE_BENIFICIARY";
        break;
    }

    // const renderListPayoutDates = () => {
    //   return (
    //     <ul className={styles.listPayoutDates}>
    //       {payoutDateByAcquirer.map((item: any, idx: number) => (
    //         <li key={idx}>
    //           <strong>
    //             {format(new Date(item.payoutDate), "DD MMM YYYY")}
    //           </strong>
    //           <span> using {T.transl(item.cardBrandLabel)}</span>
    //         </li>
    //       ))}
    //     </ul>
    //   );
    // };

    return (
      <div className={styles.beneficiaryStatement}>
        {replaceColonWithSpace(
          props.isChargeToday
            ? T.transl(localizedBeneficiaryToday, {
                // end_of_day
                render1: (
                  <strong>
                    {props.currentCurrency.toLocaleUpperCase() === "MYR"
                      ? T.transl("MY_LABEL_END_OF_DAY")
                      : T.transl("LABEL_END_OF_DAY")}
                  </strong>
                ),
                // payout_date
                render2: (
                  <>
                    {/* {payoutDateByAcquirer.length ? (
                      renderListPayoutDates()
                    ) : ( */}
                    <strong>
                      <ClonedValue name="start_date">
                        {(value: string) => {
                          return value
                            ? `${format(new Date(value), "DD MMM YYYY")}.`
                            : null;
                        }}
                      </ClonedValue>
                    </strong>
                    {/* )} */}
                  </>
                )
              })
            : T.transl(localizedBeneficiary, {
                // charged_date
                render1: (
                  <strong>{format(props.chargeDate, "DD MMM YYYY")}</strong>
                ),
                // end_of_day
                render2: (
                  <strong>
                    {props.currentCurrency.toLocaleUpperCase() === "MYR"
                      ? T.transl("MY_LABEL_END_OF_DAY")
                      : T.transl("LABEL_END_OF_DAY")}
                  </strong>
                ),
                // payout_date
                render3: (
                  <>
                    {/* {payoutDateByAcquirer.length ? (
                      renderListPayoutDates()
                    ) : ( */}
                    <strong>
                      <ClonedValue name="start_date">
                        {(value: string) => {
                          return value
                            ? `${format(new Date(value), "DD MMM YYYY")}.`
                            : null;
                        }}
                      </ClonedValue>
                    </strong>
                    {/* )} */}
                  </>
                )
              })
        )}
      </div>
    );
  };

  useEffect(() => {
    const cardBrandId =
      ipmMerchantValue?.includes("_amex") && entity === "supplier"
        ? BRAND_ID.AMEX
        : null;
    const bankIds = props.selectedPayees.map((p: any) => p.bankId);
    const options = {
      cb: (err?: any, data?: any) => {
        if (err) {
          invokeMessage("error", "Bad request!");
        }
        if (data) {
          handleChangePayoutDates(data);
        }
      }
    };
    if (!props.selectedPayees[0]) {
      return history.push(
        replaceRouteWithParams(ROUTES.PAYMENTS_CREATION, {
          newEntity: "entity",
          newAction: "select"
        })
      );
    }

    if (
      PaymentUtil.isUSDPaymentInHK(
        props.accountCountryId,
        props.selectedPayees[0].currencyId as number,
        props.selectedPayees[0].countryId
      )
    ) {
      props.fetchDateConditions(
        new Date(),
        bankIds,
        3, // HK
        73, // USD
        73, // USD
        cardBrandId,
        props.selectedPayees.map((p: any) => p.id),
        options,
        setLoadingDate
      );
    } else {
      props.fetchDateConditions(
        new Date(),
        bankIds,
        null,
        null,
        null,
        cardBrandId,
        props.selectedPayees.map((p: any) => p.id),
        options,
        setLoadingDate
      );
    }
  }, []);

  useEffect(() => {
    if (props.exclusionDates && props.earliestDate) {
      setIsReady(true);
    }
  }, [props.exclusionDates, props.earliestDate]);

  useEffect(() => {
    const selectedPayee = props.selectedPayees[0];

    if (isReady) {
      if (selectedPayee.rentalDueDate && selectedPayee.tenancyExpiryDate) {
        setDefaultScheduleType(SCHEDULED_TYPE.OPTION_RECURRING);
        setDisableSelectNextYear(true);
        const rentalDueDate = new Date(
          new Date(
            startOfMonth(new Date()).setDate(selectedPayee.rentalDueDate)
          ).setHours(12, 0, 0, 0)
        );
        const tenancyExpiryDate = new Date(selectedPayee.tenancyExpiryDate);
        setDefaultRecurringStartDate(
          DateUtils.calculatePayoutDate(
            rentalDueDate,
            props.exclusionDates,
            props.earliestDate
          )
        );
        setDefaultRecurringEndDate(
          DateUtils.calculatePayoutDate(
            tenancyExpiryDate,
            props.exclusionDates,
            props.earliestDate
          )
        );
      }
    }
  }, [isReady]);

  const handleError = (err: any, data?: any) => {
    if (err?.message) {
      invokeMessage("error", err.message);
    }
  };

  const onAddPaymentRequestSubmit = () => {
    props.addPaymentRequestSubmit(
      purpose,
      isWallex,
      {
        cb: (err?: any, data?: any) => {
          const checkoutUrl = data
            ? `${replaceRouteWithParams(ROUTES.PAYMENTS_CREATION, {
                newAction: "checkout"
              })}/?token=${data?.token}`
            : "/";
          if (err) {
            handleError(err, { checkoutUrl });
          } else {
            history.push(checkoutUrl, {
              prevRoute: window.location.pathname
            });
          }
        }
      },
      setLoading
    );
  };

  const submitLabel = useMemo(() => {
    return loading ? "Moving forward..." : "Move forward";
  }, [loading]);

  const onBack = () => {
    history.goBack();
  };

  const renderBtnSubmit = () => {
    return (
      <>
        <Button
          id={"ipaymy_btn_schedule_payment"}
          wrapperClassName={styles.wrapperButton}
          onClick={onAddPaymentRequestSubmit}
          type="primary"
          htmlType="button"
          loading={loading}
        >
          {submitLabel}
        </Button>
      </>
    );
  };

  const renderDesktopView = () => {
    return (
      <DesktopModalLayout
        maxWidth450={true}
        onSubmit={onAddPaymentRequestSubmit}
        title={props.titleLabel}
        onClose={props.onClose}
        onBack={onBack}
        progress={
          <Progress colors={["#937CF2", "#A581E8"]} value={props.progress} />
        }
        submitButtonClassName={""}
        btnSubmit={renderBtnSubmit()}
      >
        {renderContent()}
      </DesktopModalLayout>
    );
  };

  const renderMobileView = () => {
    return (
      <MainLayout
        header={{
          title: props.titleLabel,
          hideMenu: true,
          onBack,
          onClose: props.onClose,
          progress: (
            <Progress colors={["#937CF2", "#A581E8"]} value={props.progress} />
          )
        }}
        footer={renderBtnSubmit()}
      >
        {renderContent()}
      </MainLayout>
    );
  };

  const renderContent = () => {
    return (
      <div className={styles.wrapper}>
        <FormErrors form={ADD_FORM} />
        <ScheduleForm
          loading={loadingDate}
          handleSelectStartDate={handleSelectStartDate}
          handleSelectEndDate={handleSelectEndDate}
          chargeDate={props.chargeDate}
          chargeDateEnd={chargeDateEnd}
          earliestDate={props.earliestDate}
          flashPayDate={props.flashPayDate}
          exclusionDates={props.exclusionDates}
          pspNonWorkingDates={props.pspNonWorkingDates}
          countryCode={props.countryCode}
          getControl={props.getControl}
          setControl={props.setControl}
          entity={entity}
          features={props.features}
          defaultScheduleType={defaultScheduleType}
          defaultRecurringStartDate={defaultRecurringStartDate}
          defaultRecurringEndDate={defaultRecurringEndDate}
          disableSelectNextYear={disableSelectNextYear}
        />
        <div className={styles.textBlack}>
          <div className={styles.listContainer}>
            <List
              entity={entity}
              items={props.selectedPayees}
              getCheckoutFormControl={props.getCheckoutFormControl}
              getControl={props.getControl}
              isHongKongAccount={props.isHongKongAccount}
            />
          </div>
          <Skeleton loading={loadingDate} paragraph={{ rows: 2 }}>
            {renderBeneficiaryStatement()}
          </Skeleton>
        </div>
      </div>
    );
  };

  return (
    <>
      <BrowserView>{renderDesktopView()}</BrowserView>
      <MobileView>{renderMobileView()}</MobileView>
    </>
  );
};

export default View;
