import React, { useEffect, useState } from "react";
import _get from "lodash-es/get";
import Form from "src/bepaid/components/Form";
import FormErrors from "src/ipm-shared/components/Form/helpers/Errors";
import Skeleton from "src/bepaid/components/Skeleton";

import {
  FORM,
  INTERNATIONAL_FORM
} from "src/ipm-shared/store/model/Payee/const";

import { getBanksOptionsByCountryId } from "src/ipm-shared/store/model/Bank/selectors";
import store from "src/ipm-shared/store";

import styles from "../../RecipientModal.module.scss";
import CurrencyUtil from "src/ipm-shared/Utils/Currency";
import CountryUtil from "src/ipm-shared/Utils/Country";
import RecipientBankAccountFormContent from "./RecipientBankAccountFormContent";
import InputText from "src/ipm-shared/components/Form/controls/InputText";

export const RecipientBankAccountForm = (props: any) => {
  const {
    editMode,
    editedId,
    fetchPayees,
    paymentCountryCode,
    entity,
    initName
  } = props;

  const [loading, setLoading] = useState(false);
  const [editedPayee, setEditedPayee] = useState(null);
  const [countryId, setCountryId] = useState(
    Number(CountryUtil.getCountryId(paymentCountryCode))
  );
  const [countryCode, setCountryCode] = useState(paymentCountryCode);
  const filterBank = getBanksOptionsByCountryId(store.getState(), countryId);
  const ADD_FORM =
    entity === "supplier" || entity === "landlord" ? FORM : INTERNATIONAL_FORM;

  const commonFormItemProps =
    entity === "supplier" || entity === "landlord"
      ? {
          reserveValueOnUnmount: true,
          revertValueOnMount: true
        }
      : {};

  const fetchEditedPayee = () => {
    fetchPayees({
      type: "self_transfer",
      getAllPayees: true,
      noRedirect: true,
      isWallex: true,
      pageCount: 1000,
      cb: (payees: any) => {
        const payee = payees.find((p: any) => +p.id === +editedId);
        const paymentCountryCode = CountryUtil.getCountryCodeFromCountryId(
          _get(payee, "data.bank_country_id")
        );

        setEditedPayee(payee);
        setCountryId(payee.country_id);
        setCountryCode(paymentCountryCode);

        props.setPaymentCountryCode(paymentCountryCode);
        props.setPaymentCurrencyCode(
          CurrencyUtil.convertFromId(payee.currency_id).code
        );
      },
      loadingFunc: setLoading
    });
  };

  useEffect(() => {
    if (editMode) {
      fetchEditedPayee();
    }
  }, []);

  const recipientBankAccountFormContentProps = {
    editedPayee,
    ADD_FORM,
    commonFormItemProps,
    entity,
    initName,
    countryCode,
    filterBank
  };

  return (
    <div className={styles.wrapperForm}>
      <Skeleton loading={loading}>
        <Form
          name="company-form"
          layout="vertical"
          requiredMark={false}
          className={styles.form}
        >
          <FormErrors form={ADD_FORM} />
          {editedPayee && (
            <div hidden={true}>
              <InputText
                name={"payee_id"}
                form={ADD_FORM}
                defaultValue={_get(editedPayee, "id", "")}
                {...commonFormItemProps}
              />
            </div>
          )}
          <RecipientBankAccountFormContent
            {...recipientBankAccountFormContentProps}
          />
        </Form>
        {paymentCountryCode === "US" && (
          <p className={styles.noteFedwire}>
            {`* `}Please note that Fedwire payments are not supported.
          </p>
        )}
      </Skeleton>
    </div>
  );
};
