import React, { useEffect, useState } from "react";

import SharedBankBSB from "src/ipm-shared/components/BankBSBId";
import AutoComplete from "../AutoComplete";
import _get from "lodash-es/get";

const View = (props: any) => {
  const bankSwiftCodeControl = props.getControl("bank_swift_code", props.form);
  const searchControl = props.getControl("bank_bsb_search", props.form);
  const bankBsbIdControl = props.getControl("bank_bsb_id", props.form);
  const [defaultValue, setDefaultValue] = useState(undefined);

  const setErrorForSearchControl = (errors: any[]) => {
    if (errors?.length) {
      props.setControl({
        name: searchControl.name,
        errors
      });
    } else {
      props.setControl({
        name: searchControl.name,
        errors: [],
        showError: false
      });
    }
  };

  useEffect(() => {
    setErrorForSearchControl(bankSwiftCodeControl?.errors);
  }, [bankSwiftCodeControl?.errors?.length]);

  useEffect(() => {
    setErrorForSearchControl(bankBsbIdControl?.errors);
  }, [bankBsbIdControl?.errors?.length]);

  useEffect(() => {
    props.setControl({
      errors: [],
      form: props.form,
      name: props.name,
      value: props.defaultValue
    });
  }, [props.defaultValue]);

  const renderComponent = (shareProps: any) => {
    const allProps = {
      ...props,
      ...shareProps
    };

    if (allProps.defaultValue && allProps.name === "bank_bsb_id") {
      const item = allProps.options.find(
        (item: any) => String(item.value) === String(allProps.defaultValue)
      );
      if (item) {
        setDefaultValue(item.label);
      }
    }

    const onSelectCompany = (value: any) => {
      const selectedItem = allProps.options.find(
        (item: any) => String(item.label) === String(value)
      );

      if (selectedItem) {
        props.setControl({
          errors: [],
          form: allProps.form,
          name: allProps.name,
          value: selectedItem.value
        });

        props.setControl({
          errors: [],
          form: allProps.form,
          name: "bank_bsb_search"
        });

        if (shareProps.onChangeSelected) {
          shareProps.onChangeSelected(selectedItem.value);
        }
      }
    };

    return (
      <div>
        <AutoComplete
          name={"bank_bsb_search"}
          placeholder={allProps.placeholder}
          form={allProps.form}
          label={allProps.label}
          defaultValue={defaultValue}
          options={allProps.options.map((o: any) => ({
            label: o.label,
            value: o.label
          }))}
          onSearch={allProps.onChangeSearchText}
          onSelect={onSelectCompany}
          // disabled={setDisable}
        />
      </div>
    );
  };

  return <SharedBankBSB {...props} renderComponent={renderComponent} />;
};

export default View;
