import { connect } from "react-redux";

import { RootState } from "src/ipm-shared/store/model/reducers";

import View from "./View";
import * as actions from "src/bepaid/store/model/Fetch/actions";
// import * as selectors from "src/bepaid/store/model/Fetch/selectors";

const mapStateToProps = (state: RootState, props: any) => ({});

const mapDispatchToProps = {
  setControl: actions.setControl
};

export type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(View);
