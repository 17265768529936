import { RootState } from "../reducers";
import currencies from "./reducers";
import ReducerFactory from "src/ipm-shared/Utils/ReduxReducer";
import { getCurrentCurrencyId } from "src/ipm-shared/store/model/AccountProfile/selectors";
import _get from "lodash-es/get";
ReducerFactory.registerReducer({ currencies });

export const getAllCurrencies = (state: RootState) => {
  return Object.keys(state.currencies.byId).map(
    id => state.currencies.byId[id]
  );
};

export const getCurrenciesById = (state: RootState) => state.currencies.byId;

export const getCurrencySymbolByCurrencyCode = (
  currencyCode: string,
  state: RootState
) => {
  return getAllCurrencies(state).filter(
    c => c.code === currencyCode.toUpperCase()
  )[0].symbol;
};

export const getCurrentCurrencySymbol = (state: RootState) => {
  const currentCurrencyId = getCurrentCurrencyId(state);
  const filterSymbol = getAllCurrencies(state).filter(
    c => c.id === currentCurrencyId
  )[0];
  return _get(filterSymbol, "symbol", "");
};

export const getCurrencyCodeById = (
  state: RootState,
  currencyId: number
): string => {
  const filterSymbol = getAllCurrencies(state).filter(
    c => c.id === currencyId
  )[0];
  return _get(filterSymbol, "code", "");
};

export const getCurrencyIdByCode = (
  state: RootState,
  currencyCode: string
): number => {
  const filterSymbol = getAllCurrencies(state).filter(
    c => c.code === currencyCode
  )[0];
  return _get(filterSymbol, "id", 0);
};
