import * as React from "react";
import CurrencyUtil from "src/ipm-shared/Utils/Currency";
import { FormattedNumber } from "react-intl";

interface IProps extends React.Props<any> {
  value: number;
  currencyId?: number;
  removedZeroDecimal?: boolean;
  fractionDigits?: number;
}

export const toCents = (
  value: number = 0,
  removedZeroDecimal: boolean = false
): number => {
  const s = value.toString();
  let sig: string;
  if (value < 100) {
    sig = "0";
  } else {
    sig = s.slice(0, s.length - 2);
  }
  let cents: string;
  if (value % 100 < 10) {
    cents = "0" + (value % 100);
  } else {
    cents = (value % 100).toString();
  }

  let res = `${sig}.${cents}`;
  if (removedZeroDecimal) {
    if (res.length > 3 && res.slice(-3) === ".00") {
      res = res.substr(0, res.length - 3);
    }
  }

  return Number(res);
};

const Index: React.SFC<IProps> = props => {
  const { value, currencyId, removedZeroDecimal, fractionDigits = 2 } = props;
  let finalValue = value;
  let prefix = "";
  if (finalValue < 0) {
    prefix = "-";
    finalValue = Math.abs(finalValue);
  }
  return (
    <FormattedNumber
      value={toCents(finalValue, removedZeroDecimal)}
      minimumFractionDigits={removedZeroDecimal ? 0 : fractionDigits}
      maximumFractionDigits={fractionDigits}
    >
      {(formatted: string) => {
        if (props.currencyId) {
          return `${prefix}${
            CurrencyUtil.convertFromId(currencyId as number).$
          }${formatted}`;
        } else {
          return prefix + formatted;
        }
      }}
    </FormattedNumber>
  );
};

export default Index;
