import _includes from "lodash-es/includes";
import _filter from "lodash-es/filter";
import { getType } from "typesafe-actions";
import { ModalProps } from "reactstrap/lib/Modal";

import * as types from "./types";
import * as actions from "./actions";

export type GlobalUIState = {
  readonly platform: number;
  readonly opened: number[];
  readonly showLoader: boolean;
  readonly extraInfo: {
    [modalId: number]: any;
  };
  readonly settings: Partial<ModalProps>;
  readonly loaderMessage?: string;
};

export enum PLATFORM {
  FETCH,
  PAY
}

const defaultSettings = {
  backdrop: "static" as "static",
  keyboard: false
};

const existPlatform = localStorage.getItem("global_ui_platform");

const defaultState: GlobalUIState = {
  extraInfo: {},
  opened: [], // Stack
  platform: existPlatform ? Number(existPlatform) : PLATFORM.PAY,
  settings: defaultSettings,
  showLoader: false
};

export default (state = defaultState, action: types.Action) => {
  switch (action.type) {
    case getType(actions.switchPlatform): {
      localStorage.setItem(
        "global_ui_platform",
        String(action.payload.platform)
      );

      return {
        ...state,
        platform: action.payload.platform
      };
    }

    case getType(actions.toggleModal): {
      // if (!(action.payload.modalId in actions.FetchModalID)) {
      //   return state;
      // }

      const contains = _includes(state.opened, action.payload.modalId);

      if (contains) {
        return {
          ...state,
          extraInfo: {
            ...state.extraInfo,
            [action.payload.modalId]: {}
          },
          opened: _filter(
            state.opened,
            i => String(i) !== String(action.payload.modalId)
          ),
          settings: action.payload.settings
            ? { ...defaultSettings, ...action.payload.settings }
            : defaultSettings
        };
      }

      return {
        ...state,
        extraInfo: {
          ...state.extraInfo,
          [action.payload.modalId]: action.payload.extraInfo
        },
        opened: [...state.opened, action.payload.modalId],
        settings: action.payload.settings
          ? { ...defaultSettings, ...action.payload.settings }
          : defaultSettings
      };
    }

    case getType(actions.openModal): {
      const contains = _includes(state.opened, action.payload.modalId);

      if (!contains) {
        return {
          ...state,
          extraInfo: {
            ...state.extraInfo,
            [action.payload.modalId]: action.payload.extraInfo
          },
          opened: [...state.opened, action.payload.modalId],
          settings: action.payload.settings
            ? { ...defaultSettings, ...action.payload.settings }
            : defaultSettings
        };
      }
      return state;
    }

    case getType(actions.closeModal): {
      const contains = _includes(state.opened, action.payload);
      if (contains) {
        return {
          ...state,
          extraInfo: {
            ...state.extraInfo,
            [action.payload]: {}
          },
          opened: action.payload
            ? _filter(state.opened, i => String(i) !== String(action.payload))
            : []
        };
      }
      return state;
    }
    case getType(actions.showGlobalLoader): {
      state = { ...state, loaderMessage: "" };
      if (state.showLoader) {
        return state; // No need to update. (Prevent flash on UI)
      }
      return { ...state, showLoader: true };
    }
    case getType(actions.hideGlobalLoader): {
      return { ...state, showLoader: false };
    }
    case getType(actions.updateExtraInfo): {
      return {
        ...state,
        extraInfo: {
          ...state.extraInfo,
          [action.payload.modalID]: action.payload.extraInfo
        }
      };
    }
    case getType(actions.resetAllModals): {
      return defaultState;
    }
    case getType(actions.updateLoaderMessage):
      return {
        ...state,
        loaderMessage: action.payload.loadMessage
      };
  }
  return state;
};
