import React, { useCallback } from "react";
import { BrowserView, MobileView, isDesktop } from "react-device-detect";
import _get from "lodash-es/get";
import _isEmpty from "lodash-es/isEmpty";
import classNames from "classnames";

import { FetchModalID } from "src/ipm-shared/components/GlobalUI/actions";

import Form from "src/bepaid/components/Form";
import SelectBusinessIndustries from "src/ipm-shared/components/SelectBusiness/BusinessIndustries";
import SelectMultiLevel from "src/bepaid/components/Form/controls/SelectMultiLevel";

import Button from "src/bepaid/components/Form/controls/Button";
import DesktopModalLayout from "src/bepaid/components/Modal/components/DesktopModalLayout";
import MainLayout from "src/bepaid/layouts/MainLayout";

import { MY_INFO_MODES } from "../../const";

import T from "src/ipm-shared/Utils/Intl";

import styles from "./RetrieveBusinessDescription.module.scss";

const RetriveBusinessDescription: React.FC<any> = props => {
  const { openModal, onClose, setExtraInfoState } = props;

  const [loading, setLoading] = React.useState(false);

  const [
    businessDescriptionState,
    setBusinessDescriptionState
  ] = React.useState("");

  const onSubmit = () => {
    if (_isEmpty(businessDescriptionState)) {
      props.setControl({
        errors: [
          {
            code: "REQUIRED_FIELD",
            message: T.transl("REQUIRED_FIELD")
          }
        ],
        form: "my-info-retrieved-identity-form",
        name: "business_industry"
      });
      return;
    }
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setExtraInfoState({
        mode: MY_INFO_MODES.loginBusiness,
        isBiz: true,
        businessIndustry: businessDescriptionState
      });
      openModal(FetchModalID.MY_INFO_VERIFICATION_MODAL);
    }, 500);
  };

  const WrapperSelectMultiLevel = useCallback((propsWrapper: any) => {
    const handleChangeSelect = (value: any) => {
      setBusinessDescriptionState(value);
      props.setControl({
        errors: [],
        form: "my-info-retrieved-identity-form",
        name: "business_industry",
        value: Number(value)
      });
    };

    return (
      <SelectMultiLevel
        key={"WrapperSelectMultiLevel"}
        {...propsWrapper}
        onChange={handleChangeSelect}
      />
    );
  }, []);

  const renderContent = () => (
    <>
      <div className={styles.section}>
        <span className={styles.sectionText}>
          Please select your business description.
        </span>
      </div>
      <Form
        name="my-info-retrieved-identity-form"
        layout="vertical"
        requiredMark={false}
      >
        <div className={styles.formWrapper}>
          <SelectBusinessIndustries
            // @ts-ignore
            SelectComponent={WrapperSelectMultiLevel}
            name={"business_industry"}
            label="Business description"
            form={"my-info-retrieved-identity-form"}
            className={styles.select}
            reserveValueOnUnmount={true}
            revertValueOnMount={true}
          />
        </div>
      </Form>
    </>
  );

  const renderButton = () => (
    <div className={styles.button}>
      <Button
        type="primary"
        htmlType="submit"
        loading={loading}
        onClick={onSubmit}
      >
        {loading ? "Moving forward..." : "Move forward"}
      </Button>
    </div>
  );

  return (
    <>
      <BrowserView>
        <DesktopModalLayout
          title={"Verify your identity"}
          onClose={onClose}
          onSubmit={onSubmit}
          btnSubmit={renderButton()}
        >
          <div
            className={classNames(styles.wrapper, {
              [styles.wrapperDesktop]: isDesktop
            })}
          >
            {renderContent()}
          </div>
        </DesktopModalLayout>
      </BrowserView>
      <MobileView>
        <MainLayout
          header={{
            title: "Verify your identity",
            onClose,
            hideMenu: true
          }}
          footer={renderButton()}
        >
          <div className={styles.wrapper}>{renderContent()}</div>
        </MainLayout>
      </MobileView>
    </>
  );
};

export default RetriveBusinessDescription;
