import { history } from "src/ipm-shared/store";
import ROUTES from "../routes";

export const redirectToPrevPath = (fallBackPath: string = ROUTES.ROOT) => {
  const { previousPath } = JSON.parse(
    localStorage.getItem("historyState") || "{}"
  );
  localStorage.removeItem("historyState");
  history.push(previousPath || fallBackPath);
};
