import { connect } from "react-redux";

import { RootState } from "src/ipm-shared/store/model/reducers";

import View from "./View";
import * as selectors from "src/bepaid/store/model/Fetch/selectors";
import * as actions from "src/bepaid/store/model/Fetch/actions";

const mapStateToProps = (state: RootState, props: any) => ({
  hasCompany: selectors.hasCompany(state),
  isSingaporeAccount: selectors.isSingaporeAccount(state),
  isBusinessAccount: selectors.getIsBusinessAccount(state),
  selectedPurpose: selectors.getSelectedPurpose(state),
  getCompany: selectors.getCompany(state),
  paymentCountryCode: selectors.getPaymentCountryCode(state),
  getConfirmedMyInfo: selectors.getConfirmedMyInfo(state),
  accountCountryCode: selectors.getAccountCountryCode(state)
});

const mapDispatchToProps = {
  resetSelectedPayees: actions.resetSelectedPayees,
  toggleModal: actions.toggleModal,
  fetchMyInfoSingPass: actions.fetchMyInfoSingPass,
  fetchPayees: actions.fetchPayees,
  selectPayees: actions.selectPayees
};

export type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(View);
