// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BenefitsOfFetchModal_modalContainer__2fxQ6 .am-modal-content{border-radius:26px !important}.BenefitsOfFetchModal_wrapper__SLRsP{max-width:400px;text-align:center;margin:-20px auto auto}.BenefitsOfFetchModal_wrapper__SLRsP .BenefitsOfFetchModal_title__2Dwna{font-family:\"Proxima Nova Regular\",\"Roboto\";font-weight:400;font-size:35px;line-height:100%;font-weight:600}.BenefitsOfFetchModal_wrapper__SLRsP .BenefitsOfFetchModal_description__FORmj{font-family:\"Proxima Nova Regular\",\"Roboto\";font-weight:400;font-size:1rem;color:#242424;line-height:20px}.BenefitsOfFetchModal_wrapper__SLRsP .BenefitsOfFetchModal_description__FORmj .BenefitsOfFetchModal_comingSoon__3GOLn{color:#624ce0;font-weight:800;text-shadow:.8px 0 #624ce0}.BenefitsOfFetchModal_wrapper__SLRsP img{height:330px;margin-top:15px}", "",{"version":3,"sources":["webpack://./src/bepaid/pages/Dashboard/components/BenefitsOfFetchModal/BenefitsOfFetchModal.module.scss"],"names":[],"mappings":"AAAA,8DAA0C,6BAA6B,CAAC,qCAAS,eAAe,CAAC,iBAAiB,CAAC,sBAAsB,CAAC,wEAAgB,2CAA2C,CAAC,eAAe,CAAC,cAAc,CAAC,gBAAgB,CAAC,eAAe,CAAC,8EAAsB,2CAA2C,CAAC,eAAe,CAAC,cAAc,CAAC,aAAa,CAAC,gBAAgB,CAAC,sHAAkC,aAAa,CAAC,eAAe,CAAC,0BAA0B,CAAC,yCAAa,YAAY,CAAC,eAAe","sourcesContent":[".modalContainer :global .am-modal-content{border-radius:26px !important}.wrapper{max-width:400px;text-align:center;margin:-20px auto auto}.wrapper .title{font-family:\"Proxima Nova Regular\",\"Roboto\";font-weight:400;font-size:35px;line-height:100%;font-weight:600}.wrapper .description{font-family:\"Proxima Nova Regular\",\"Roboto\";font-weight:400;font-size:1rem;color:#242424;line-height:20px}.wrapper .description .comingSoon{color:#624ce0;font-weight:800;text-shadow:.8px 0 #624ce0}.wrapper img{height:330px;margin-top:15px}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalContainer": "BenefitsOfFetchModal_modalContainer__2fxQ6",
	"wrapper": "BenefitsOfFetchModal_wrapper__SLRsP",
	"title": "BenefitsOfFetchModal_title__2Dwna",
	"description": "BenefitsOfFetchModal_description__FORmj",
	"comingSoon": "BenefitsOfFetchModal_comingSoon__3GOLn"
};
export default ___CSS_LOADER_EXPORT___;
