import * as React from "react";

interface IProps {
  className: string;
  width?: number;
}

class IconQuestion extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }
  public render() {
    const { className, width } = this.props;
    return (
      <span
        className={`icon-question ${className}`}
        dangerouslySetInnerHTML={{
          __html: `<svg width="${width ||
            12}" height="auto" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
           viewBox="0 0 11 11" style="enable-background:new 0 0 11 11;" xml:space="preserve">
          <style type="text/css">
            .icon-question .st0{fill:#A6BABC;}
            .icon-question .st1{enable-background:new;}
            .icon-question .st2{fill:#FFFFFF;}
          </style>

          <g>
            <g id="Form">
              <g>
                <circle class="st0" cx="5.5" cy="5.5" r="5.5"/>
              </g>
              <g class="st1">
                <path class="st2" d="M4.2,2.9c0.3-0.2,0.7-0.3,1.2-0.3C6,2.6,6.5,2.7,6.9,3c0.4,0.3,0.6,0.7,0.6,1.3c0,0.3-0.1,0.6-0.3,0.9
                  C7.1,5.3,6.9,5.5,6.7,5.7L6.4,6C6.2,6.1,6.1,6.2,6.1,6.4C6,6.5,6,6.6,6,6.9H4.9c0-0.5,0.1-0.8,0.1-0.9c0.1-0.2,0.2-0.4,0.5-0.6
                  l0.3-0.2C6,5,6.1,4.9,6.1,4.9c0.1-0.1,0.2-0.3,0.2-0.5c0-0.2-0.1-0.4-0.2-0.6S5.8,3.6,5.4,3.6c-0.3,0-0.5,0.1-0.7,0.3
                  C4.7,4.1,4.6,4.3,4.6,4.5H3.4C3.5,3.8,3.7,3.2,4.2,2.9z M4.9,7.4h1.2v1.2H4.9V7.4z"/>
              </g>
            </g>
          </g>
          </svg>`
        }}
      />
    );
  }
}

export default IconQuestion;
