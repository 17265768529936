import React, { useEffect } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import _get from "lodash-es/get";
import Flex from "antd-mobile/es/flex";
import Typography from "antd/es/typography";

import Image from "src/bepaid/components/Image";
import Card from "src/bepaid/components/Card";
import GuestLayout from "src/bepaid/layouts/GuestLayout";
import useQuery from "src/bepaid/hooks/useQuery";

import HeaderLogo from "src/bepaid/assets/images/desktop/header_ipaymy_logo.svg";
import CircleCheckIcon from "src/bepaid/assets/images/common/circle-check-black.svg";

import styles from "./UnsubscribeEmail.module.scss";

const UnsubscribeEmail: React.FC<any> = props => {
  const qs = useQuery();
  const getToken = () => {
    const token = props.match.params.token;
    return token === "token" ? (qs.token as string) : token;
  };

  useEffect(() => {
    const token = getToken();

    props.unsubscribeEmail(token, (err: any) => {
      console.log(err);
    });
  }, []);

  const renderContent = () => {
    return (
      <div className={styles.wrapper}>
        <Flex justify="center" align="center" direction="column">
          <Flex.Item span={24} className={styles.centerText}>
            <div className={styles.checkIcon}>
              <Image src={CircleCheckIcon} width={100} />
            </div>
          </Flex.Item>

          <Flex.Item span={24} className={styles.centerText}>
            <Typography.Text className={styles.strongText}>
              We have removed your email address from this scheduled payment
              reminder list.
            </Typography.Text>
          </Flex.Item>

          <Flex.Item span={24} className={styles.centerText}>
            <Typography.Text className={styles.helpfulText}>
              We strive to make our emails helpful and we don't want to clutter
              your mailbox.
            </Typography.Text>
          </Flex.Item>

          <Flex.Item span={24} className={styles.centerText}>
            <Typography.Text className={styles.helpfulText}>
              For questions and comments, please contact us at:
              support@ipaymy.com
            </Typography.Text>
          </Flex.Item>
        </Flex>
      </div>
    );
  };

  const renderDesktopView = () => {
    return (
      <div className={styles.desktopWrapper}>
        <Card>{renderContent()}</Card>
      </div>
    );
  };

  return (
    <GuestLayout
      header={<Image src={HeaderLogo} />}
      title={
        <Typography.Text className={styles.title}>
          Unsubscribe Email
        </Typography.Text>
      }
    >
      <BrowserView>{renderDesktopView()}</BrowserView>
      <MobileView>{renderContent()}</MobileView>
    </GuestLayout>
  );
};

export default UnsubscribeEmail;
