import React, { useEffect } from "react";
import { connect } from "react-redux";

import * as actions from "src/ipm-shared/components/Form/actions";
import Countries from "src/ipm-shared/store/metadata/country";

import Select from "src/bepaid/components/Form/controls/Select";
import Image from "src/bepaid/components/Image";
import IconArrowDown from "src/bepaid/assets/images/common/arrow_down_fresh.svg";

import InputText from "../InputText";

import styles from "./InputMobile.module.scss";

const Component = (props: any) => {
  const { form, setControl, nameCode } = props;
  const sortByName = (a: any, b: any) => (a.name > b.name ? 1 : -1);

  const populateCountries = Countries.filter((item: any) =>
    ["AU", "HK", "MY", "SG"].includes(item.code)
  ).sort(sortByName);
  const otherCountries = Countries.filter(
    (item: any) => !["AU", "HK", "MY", "SG"].includes(item.code)
  ).sort(sortByName);

  const inputProps = {
    ...props,
    label: null
  };

  useEffect(() => {
    if (props.code) {
      const current = Countries.find(
        item => item.code.toLowerCase() === props.code.toLowerCase()
      );
      if (current) {
        setControl({
          form,
          name: nameCode,
          value: current.code
        });
      }
    }
  }, [props.code]);

  return (
    <>
      {props.label && <p className={styles.text}>{props.label}</p>}
      <div className={styles.wrapperInput}>
        <Select
          name={props.nameCode}
          form={props.form}
          options={[...populateCountries, ...otherCountries].map(item => {
            return {
              label: (
                <div className={styles.buttonCountry}>
                  <span
                    className={`flag-icon flag-icon-${item.code.toLowerCase()}`}
                  />
                  &nbsp;{` +${item.calling_code}`}
                </div>
              ),
              value: item.code
            };
          })}
          defaultValue={props.code}
          suffixIcon={<Image src={IconArrowDown} />}
          className={styles.dropdownCountry}
        />
        <InputText className="flex-grow-1" {...inputProps} />
      </div>
    </>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  setControl: actions.setControl
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
