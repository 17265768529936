// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InputCalendar_helperText__-TOgT{margin-top:-0.6875rem !important;color:#74747b !important;font-size:.75rem !important;color:#c40698 !important}", "",{"version":3,"sources":["webpack://./src/bepaid/components/InputCalendar/InputCalendar.module.scss"],"names":[],"mappings":"AAAA,iCAAY,gCAAgC,CAAC,wBAAwB,CAAC,2BAA2B,CAAC,wBAAwB","sourcesContent":[".helperText{margin-top:-0.6875rem !important;color:#74747b !important;font-size:.75rem !important;color:#c40698 !important}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"helperText": "InputCalendar_helperText__-TOgT"
};
export default ___CSS_LOADER_EXPORT___;
