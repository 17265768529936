import React, { useEffect, useState } from "react";
import classNames from "classnames";

import styles from "./Collapse.module.scss";

const Collapse = (props: any) => {
  const {
    iconOpened,
    iconClosed,
    rightIcon,
    type,
    isOpen = false,
    onChangeOpen = () => null
  } = props;

  const [collapsed, setCollapse] = useState(isOpen);

  const toggleCollapse = () => {
    setCollapse(!collapsed);
    onChangeOpen(!collapsed);
  };

  useEffect(() => {
    setCollapse(isOpen);
  }, [isOpen]);

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.btnCollapse]: type === "button",
        [styles.textCollapse]: type === "text"
      })}
    >
      {type === "button" ? (
        <div className={styles.btnCollapseHeading} onClick={toggleCollapse}>
          {props.title}
          <div className={styles.btnCollapseIcon}>
            {!collapsed ? iconClosed : iconOpened}
          </div>
        </div>
      ) : (
        <div className={styles.heading}>
          <div className={styles.title} onClick={toggleCollapse}>
            <div className={styles.leftIcon}>
              {!collapsed ? iconClosed : iconOpened}
            </div>
            <span>{props.title}</span>
          </div>
          <div className={styles.rightIcon}>{rightIcon}</div>
        </div>
      )}

      {props.children && (
        <div
          className={classNames(styles.content, {
            [styles.collapsed]: collapsed,
            [styles.collapsedOfButton]: type === "button",
            [styles.contentNoPadding]: type === "noPadding"
          })}
        >
          {type === "button" && <hr className={styles.hrChildren} />}
          {props.children}
        </div>
      )}
    </div>
  );
};

export default Collapse;
