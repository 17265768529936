import * as ReactRouter from "react-router";
import { connect } from "react-redux";
import Component from "./View";
import { RootState } from "src/ipm-shared/store/model/reducers";
import { ADD_FORM } from "src/ipm-shared/store/model/Card/const";
import * as selectors from "./selectors";
import _get from "lodash-es/get";
import * as actions from "./actions";

const mapStateToProps = (state: RootState) => ({
  cardBank: _get(selectors.getControls(state, ADD_FORM), "bank.value", -1),
  cardBrand: _get(selectors.getControls(state, ADD_FORM), "brand.value", -1)
});

const mapDispatchToProps = {
  setControl: actions.setControl
};

// Fixed template
export type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  ReactRouter.RouteProps;

export default connect(mapStateToProps, mapDispatchToProps)(Component);
