import { FetchModalID } from "src/ipm-shared/components/GlobalUI/actions";
import withPopup from "../HOC/withPopup";
import withRequireIncorporationDate from "../HOC/withRequireIncorporationDate";
import { typeByEntity } from "../utils/recipient";
import Recipients from "./Recipients";
import PaymentsNew from "./Payments";
import PaymentsList from "./Payments/components/PaymentsList";
import CustomersIndex from "./Customers";
import Dashboard from "./Dashboard";

export { default as Login } from "./SignIn";
export { default as ResetPassword } from "./ResetPassword";
export { default as SetNewPassword } from "./SetNewPassword";
export { default as PasswordResetConfirmation } from "./PasswordResetConfirmation";
export { default as Checkout } from "./Checkout";
export { default as CheckoutProcessed } from "./CheckoutProcessed";
export { default as CheckoutCancel } from "./CheckoutCancel";
export { default as CheckoutOverdue } from "./CheckoutOverdue";
export { default as CheckoutLinkFail } from "./CheckoutLinkFail";
export { default as CheckoutConfirmation } from "./CheckoutConfirmation";

export { default as Register } from "./SignUp";
export { default as PostRegister } from "./PostRegister";
export { default as VerificationMobile } from "./VerificationMobile";
export { default as VerificationExpired } from "./VerificationExpired";
export { default as ClosedAccountThankYou } from "../layouts/Menu/components/CloseAccount/components/ThankYou/ThankYou";
export { default as Users } from "./Users";
export { default as UnsubscribeEmail } from "./UnsubscribeEmail";

export { default as Dashboard } from "./Dashboard";

import Invoices from "./Invoices";
export { Invoices };

// export { default as Customers } from "./Customers";
export { CustomersIndex };

export { default as Insights } from "./Insights";

export { default as Search } from "./Search";
export { default as Filter } from "./Filter";

export { Recipients };
export { PaymentsNew };
export { PaymentsList };

export { default as Schedules } from "./Schedules";
export { default as InsightsPay } from "./InsightsPay";

export { default as Referral } from "./Referral";

// Customer Modal page
export const CustomersDetail = withPopup(CustomersIndex, {
  modalId: FetchModalID.CUSTOMER_DETAIL,
  extraInfo: {},
  pushExtraInfoFromProps: (routeProps: any) => ({
    data: {
      id: routeProps.match.params.id
    },
    match: routeProps.match
  })
});

export const CustomersAdd = withPopup(CustomersIndex, {
  modalId: FetchModalID.ADD_CUSTOMER
});

export const CustomersEdit = withPopup(CustomersIndex, {
  modalId: FetchModalID.EDIT_CUSTOMER,
  pushExtraInfoFromProps: (routeProps: any) => ({
    data: {
      id: routeProps.match.params.id
    },
    match: routeProps.match
  })
});

// Recipient Modal page
export const RecipientsDetail = withPopup(Recipients, {
  modalId: FetchModalID.RECIPIENT_DETAIL,
  extraInfo: {},
  pushExtraInfoFromProps: (routeProps: any) => ({
    payeeId: routeProps.match.params.id,
    match: routeProps.match
  })
});

export const RecipientsAdd = withPopup(Recipients, {
  modalId: FetchModalID.RECIPIENT_MODAL,
  extraInfo: {},
  pushExtraInfoFromProps: (routeProps: any) => ({
    mode: "add",
    type: typeByEntity[routeProps.match.params.entity]
  })
});

export const RecipientsEdit = withPopup(Recipients, {
  modalId: FetchModalID.RECIPIENT_DETAIL_UPDATE,
  extraInfo: {},
  pushExtraInfoFromProps: (routeProps: any) => ({
    payeeId: routeProps.match.params.id,
    match: routeProps.match,
    type: typeByEntity[routeProps.match.params.entity]
  })
});

// Payment Modal page
export const PaymentsEntitySelect = withPopup(PaymentsNew, {
  modalId: FetchModalID.MAKE_PAYMENT_SELECT_ENTITY,
  pushExtraInfoFromProps: (routeProps: any) => ({
    match: routeProps.match
  })
});

export const PaymentsCountrySelect = withRequireIncorporationDate(
  withPopup(PaymentsNew, {
    modalId: FetchModalID.MAKE_PAYMENT_SELECT_COUNTRY,
    pushExtraInfoFromProps: (routeProps: any) => ({
      match: routeProps.match
    })
  })
);

export const PaymentsCurrencySelect = withPopup(PaymentsNew, {
  modalId: FetchModalID.MAKE_PAYMENT_SELECT_CURRENCY,
  pushExtraInfoFromProps: (routeProps: any) => ({
    match: routeProps.match
  })
});

export const PaymentsCreation = withRequireIncorporationDate(
  withPopup(PaymentsNew, {
    modalId: FetchModalID.PAYMENTS_CREATION
  })
);

export const PaymentsCheckoutSuccess = withPopup(PaymentsNew, {
  modalId: FetchModalID.MAKE_PAYMENT_RESULT,
  refModalIds: [
    FetchModalID.PAYMENT_SUCCESS,
    FetchModalID.PAYMENT_SUCCESS_SCHEDULE,
    FetchModalID.RECURRING_PAYMENT_SUCCESS,
    FetchModalID.PAYMENT_ERROR,
    FetchModalID.COLLECTED_PAYMENT_FAILED
  ],
  pushExtraInfoFromProps: (props: any) => {
    return {
      match: props.match
    };
  }
});

export const PaymentsDetails = withPopup(PaymentsList, {
  modalId: FetchModalID.PAYMENT_DETAIL,
  extraInfo: {},
  pushExtraInfoFromProps: (routeProps: any) => ({
    paymentId: routeProps.match.params.id,
    match: routeProps.match
  })
});

export const ScheduleDetails = withPopup(PaymentsList, {
  modalId: FetchModalID.SCHEDULE_DETAIL,
  extraInfo: {},
  pushExtraInfoFromProps: (routeProps: any) => ({
    scheduleId: routeProps.match.params.id,
    match: routeProps.match
  })
});

export const PaymentsScheduleEdit = withPopup(PaymentsList, {
  modalId: FetchModalID.PAYMENT_DETAIL_EDIT,
  extraInfo: {
    isEditAll: true
  },
  pushExtraInfoFromProps: (routeProps: any) => ({
    scheduleId: routeProps.match.params.id,
    match: routeProps.match
  })
});

export const PaymentsDetailsEdit = withPopup(PaymentsList, {
  modalId: FetchModalID.PAYMENT_DETAIL_EDIT,
  extraInfo: {
    isEditAll: false
  },
  pushExtraInfoFromProps: (routeProps: any) => ({
    paymentId: routeProps.match.params.id,
    match: routeProps.match
  })
});

export const PaymentsDetailsExtend = withPopup(PaymentsList, {
  modalId: FetchModalID.SCHEDULE_EXTEND,
  extraInfo: {
    isEditAll: true
  },
  pushExtraInfoFromProps: (routeProps: any) => ({
    scheduleId: routeProps.match.params.id,
    match: routeProps.match
  })
});

export const KYCPage = withPopup(Dashboard, {
  modalId: FetchModalID.KYC_MODAL,
  extraInfo: {},
  pushExtraInfoFromProps: (routeProps: any) => ({
    match: routeProps.match
  })
});

export const MyInfo = withPopup(Dashboard, {
  modalId: FetchModalID.MY_INFO_VERIFICATION_MODAL,
  extraInfo: {},
  pushExtraInfoFromProps: (routeProps: any) => ({
    match: routeProps.match
  })
});

export const AdditionalDataPage = withPopup(Dashboard, {
  modalId: FetchModalID.ADDITIONAL_DATA_MODAL,
  extraInfo: {},
  pushExtraInfoFromProps: (routeProps: any) => ({
    match: routeProps.match
  })
});

// Invoices Modal pages
export const InvoiceDetailComponent = withPopup(Invoices, {
  modalId: FetchModalID.INVOICE_DETAIL,
  extraInfo: {},
  pushExtraInfoFromProps: (routeProps: any) => ({
    invoiceId: routeProps.match.params.id,
    match: routeProps.match
  })
});

export const InvoiceCreate = withPopup(Invoices, {
  modalId: FetchModalID.CREATE_INVOICE
});

export const InvoiceDraftList = withPopup(Invoices, {
  modalId: FetchModalID.LIST_INVOICE_DRAFT_MODAL
});

// Incorporation Modal
export const IncorporationDateComponent = withPopup(Dashboard, {
  modalId: FetchModalID.INCORPORATION_DATE_MODAL,
  extraInfo: {},
  pushExtraInfoFromProps: (routeProps: any) => ({
    match: routeProps.match
  })
});

// PayFetchTour Modal
export const PayFetchTourComponent = withPopup(Dashboard, {
  modalId: FetchModalID.PAYFETCH_TOUR_MODAL,
  extraInfo: {},
  pushExtraInfoFromProps: (routeProps: any) => ({
    match: routeProps.match
  })
});
