import { PURPOSE } from "src/ipm-shared/store/model/Payment/const";

const PurposeUtil = {
  toString: (purpose: number | string, defaultValue?: string): string => {
    switch (purpose) {
      case PURPOSE.RENTAL:
        return "rent";
      case PURPOSE.SALARY:
        return "salary";
      case PURPOSE.INVOICE:
        return "invoice";
      case PURPOSE.COLLECTION:
        return "collection";
      case PURPOSE.SELF_TRANSFER:
        return "self_transfer";
      case PURPOSE.SALARY_BUSINESS:
        return "salary_business";
      default:
        return defaultValue || "";
    }
  }
};

export default PurposeUtil;
