/**
 * Selectors
 *
 * It's like "views" in relational databases.
 * Store the most efficient format in the reducer state.
 * But to retrieve into easy to consume form, use selector.
 */
import { RootState } from "src/ipm-shared/store/model/reducers";
import ReducerFactory from "src/ipm-shared/Utils/ReduxReducer";
import accountsManager from "./reducer";
ReducerFactory.registerReducer({ accountsManager });

export const getAccountsManager = (state: RootState) =>
  state.accountsManager.accounts;
