import React, { useEffect } from "react";
import _get from "lodash-es/get";

import CurrencyUtil from "src/ipm-shared/Utils/Currency";

import SelectGroup from "src/bepaid/components/SelectGroup";

import InputText from "src/bepaid/components/Form/controls/InputText";
import InputFile from "src/bepaid/components/Form/controls/InputFile";
import Image from "src/bepaid/components/Image";
import BankBSB from "src/bepaid/components/BankBSB";
import Select from "src/bepaid/components/Form/controls/Select";
import CalendarInput from "src/bepaid/components/CalendarInput";
import Drawer from "src/bepaid/components/Drawer";
import Calendar from "src/bepaid/components/Calendar";

import IconArrowDown from "src/bepaid/assets/images/common/drop_down_arrow_grey.svg";
import styles from "../../RecipientModal.module.scss";

const DomesticRecipientLandlordFormContent = ({
  FORM,
  editMode,
  editedPayee,
  initName,
  isHongKongAccount,
  isAustraliaAccount,
  onChangePostalCode,
  residentialAddress,
  accountCountryCode,
  filterBank,
  onChangeCurrencyCustom,
  supportingDocumentsProps,
  currencyCode,
  currencyId,
  setControl
}: any) => {
  const [tenancyExpiryDate, setTenancyExpiryDate] = React.useState<Date>();
  const [isDatePickerOpen, openDatePicker] = React.useState(false);

  const dayPickerProps = {
    weekdaysLong: ["S", "M", "T", "W", "T", "F", "S"],
    weekdaysShort: ["S", "M", "T", "W", "T", "F", "S"],
    className: styles.calendar
  };
  const onChangePostalCodeCustom = (code: any) => {
    if (accountCountryCode === "SG") {
      onChangePostalCode(code);
    } else {
      return;
    }
  };
  const onSelectDate = (d: any) => {
    setTenancyExpiryDate(d);
  };

  const internalSelectDate = (d: any) => {
    onSelectDate(d);
    setControl({
      name: "tenancy_expiry_date",
      errors: [],
      value: d,
      form: FORM
    });
    openDatePicker(false);
  };

  useEffect(() => {
    if (_get(editedPayee, "data.tenancy_expiry_date", "")) {
      internalSelectDate(
        new Date(_get(editedPayee, "data.tenancy_expiry_date", ""))
      );
    }
  }, [editedPayee]);

  return (
    <>
      <InputText
        name={"recipient_name"}
        placeholder={"Landlord’s full name"}
        form={FORM}
        label={"Landlord’s full name"}
        defaultValue={_get(editedPayee, "name", "") || initName}
        // className={styles.codeSection}
      />

      {!isHongKongAccount && (
        <InputText
          name={"postal_code"}
          placeholder={"Postal code"}
          form={FORM}
          label={"Postal code"}
          onChangeCustom={onChangePostalCodeCustom}
          defaultValue={_get(editedPayee, "data.postal_code", "")}
          // className={styles.codeSection}
        />
      )}

      <InputText
        name={"unit_number"}
        placeholder={"Unit number"}
        form={FORM}
        label={"Unit number"}
        defaultValue={_get(editedPayee, "data.unit_number", "")}
        // className={styles.codeSection}
      />

      <InputText
        name={"address"}
        placeholder={"Address"}
        form={FORM}
        label={"Address"}
        defaultValue={
          editedPayee
            ? _get(editedPayee, "data.address")
            : residentialAddress
            ? residentialAddress
            : ""
        }
        // className={styles.codeSection}
      />

      {accountCountryCode === "AU" ? (
        <>
          <BankBSB
            form={FORM}
            name="bank_bsb_id"
            placeholder="Enter bank name, branch, or BSB"
            label="Bank name, branch, or BSB"
            defaultValue={
              _get(editedPayee, "data.bank_bsb_id") === 0
                ? null
                : _get(editedPayee, "data.bank_bsb_id")
            }
            suffixIcon={<Image src={IconArrowDown} />}
          />
          <div hidden={true}>
            <BankBSB
              form={FORM}
              name="bank_id"
              defaultValue={
                _get(editedPayee, "bank") === 0
                  ? null
                  : _get(editedPayee, "bank")
              }
            />
          </div>
        </>
      ) : (
        <Select
          form={FORM}
          name="bank_id"
          placeholder="Select bank"
          label="Bank"
          options={filterBank}
          defaultValue={
            _get(editedPayee, "bank") === 0 ? null : _get(editedPayee, "bank")
          }
          suffixIcon={<Image src={IconArrowDown} />}
          // className={styles.select}
        />
      )}

      <InputText
        name={"account_number"}
        placeholder={"Account number"}
        form={FORM}
        label={"Account number"}
        defaultValue={_get(editedPayee, "account_number", "")}
        // className={styles.codeSection}
      />

      <div className={styles.rentInput}>
        <div hidden={true}>
          {accountCountryCode === "HK" && (
            <SelectGroup
              className={styles.rentInputCustomSelect}
              options={[
                { label: "HK$", value: "HKD" },
                { label: "US$", value: "USD" }
              ]}
              onChange={onChangeCurrencyCustom}
              value={currencyCode}
              defaultValue={"HKD"}
            />
          )}
        </div>

        <InputText
          name={"default_amount"}
          placeholder={`0.00`}
          pattern={"__MONEY__"}
          form={FORM}
          label={"Rent"}
          prefix={CurrencyUtil.convertFromId(currencyId as number).$}
          defaultValue={_get(editedPayee, "amount", 0) / 100 || ""}
          // className={styles.codeSection}
        />
      </div>

      {!isAustraliaAccount && (
        <>
          <InputText
            name={"rental_due_date"}
            placeholder={"Date of each month"}
            pattern={"__DAY_OF_MONTH__"}
            form={FORM}
            label={"Monthly due date"}
            defaultValue={_get(editedPayee, "data.rental_due_date", "")}
            // className={styles.codeSection}
          />

          <CalendarInput
            name={"tenancy_expiry_date"}
            label={"Tenancy expiry date"}
            labelClass={styles.calendarLabel}
            btnClass={styles.calendarInput}
            iconClass={styles.iconCalendar}
            value={tenancyExpiryDate}
            optionDisplay={{
              day: "2-digit",
              month: "2-digit",
              year: "numeric"
            }}
            placeHolder={tenancyExpiryDate ? "" : "dd/mmm/yyyy"}
            onClick={openDatePicker.bind(null, true)}
          />
        </>
      )}

      <InputText
        name={"default_comments"}
        placeholder={"Description to appear on your landlord’s statement"}
        form={FORM}
        label={"Note to landlord"}
        defaultValue={_get(editedPayee, "comments", "")}
        // className={styles.codeSection}
      />

      <div className={styles.includeTexts}>
        <p>
          <b>Tenancy Agreement</b> must include:
        </p>
        <ul>
          <li>Landlord Name</li>
          <li>Bank Account</li>
          <li>Rental Amount</li>
        </ul>
      </div>

      {editMode ? (
        _get(editedPayee, "data.supporting_documents") ? (
          <InputFile
            {...supportingDocumentsProps}
            defaultValue={_get(editedPayee, "data.files", [])}
          />
        ) : null
      ) : (
        <InputFile {...supportingDocumentsProps} defaultValue={[]} />
      )}

      <Drawer
        height="auto"
        placement="bottom"
        visible={isDatePickerOpen}
        closable={true}
        closeIcon={null}
        onClose={openDatePicker.bind(null, false)}
        // className={styles.wrapper}
      >
        <Calendar
          submitText={"Select"}
          loading={false}
          selectDate={tenancyExpiryDate}
          onSelectDate={onSelectDate}
          dayPickerProps={dayPickerProps}
          onSubmit={internalSelectDate}
          type={"payment"}
          range={false}
          allowWeekends={true}
          label={"Tenancy expiry date"}
          calendarType={"default"}
        />
      </Drawer>
    </>
  );
};

export default DomesticRecipientLandlordFormContent;
