import React from "react";
import _get from "lodash-es/get";
import classNames from "classnames";
import Typography from "antd/es/typography";

import styles from "../RecipientDetail.module.scss";

import { useProcessPayeeDetail } from "../useProcessPaymentDetail";
import ShortCurrency from "src/ipm-shared/components/ShortCurrency";
import BankId from "src/ipm-shared/components/BankId";

const ContentCollapsed = (props: any) => {
  const { selectedPayee } = props;
  const {
    name,
    purpose,
    purposeTitle,
    currencyId,
    receiptDefaultAmount,
    bankId,
    accountNumber,
    idNumber,
    note
  } = useProcessPayeeDetail(selectedPayee);

  return (
    <>
      <div className={classNames(styles.row, styles.firstRow)}>
        <span>{purposeTitle}</span>
      </div>
      <div className={styles.row}>
        <Typography.Text strong ellipsis>
          {name}
        </Typography.Text>
      </div>
      <hr className={styles.hr1px} />
      <div className={styles.rowContainer}>
        <div className={classNames(styles.row, styles.verticalRow)}>
          <span>Employee ID</span>
          <Typography.Text ellipsis>{idNumber}</Typography.Text>
        </div>
        <div className={classNames(styles.row, styles.verticalRow)}>
          <span>Bank</span>
          <span>
            <BankId
              name={`${bankId}_bank`}
              valueShowOnly={true}
              defaultValue={bankId}
            />
          </span>
        </div>
        <div className={classNames(styles.row, styles.verticalRow)}>
          <span>Account number</span>
          <Typography.Text ellipsis>{accountNumber}</Typography.Text>
        </div>
        <div className={classNames(styles.row, styles.verticalRow)}>
          <span>{purpose}</span>
          <Typography.Text ellipsis>
            <ShortCurrency
              value={receiptDefaultAmount}
              currencyId={currencyId}
            />
          </Typography.Text>
        </div>
        {note && (
          <div className={classNames(styles.row, styles.verticalRow)}>
            <span>Note to employee</span>
            <Typography.Text ellipsis>{note}</Typography.Text>
          </div>
        )}
      </div>
    </>
  );
};

export default ContentCollapsed;
