import React from "react";
import Spin from "antd/es/spin";

import LoadingOutlined from "@ant-design/icons/LoadingOutlined";

import classNames from "classnames";
import styles from "./Loading.module.scss";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin={true} />;

interface IProps {
  wrapperClassname?: string;
}

const Loading = ({ wrapperClassname = "" }: IProps) => {
  return (
    <div className={classNames(styles.wrapper, wrapperClassname)}>
      <Spin indicator={antIcon} />
    </div>
  );
};

export default Loading;
