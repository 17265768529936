/**
 * Sagas
 *
 * All side effects must come here.
 * - calls to browser apis - localStorage, window.XXX, fetch, etc.
 */
import { call, put, select } from "redux-saga/effects";
import { ActionType } from "typesafe-actions";
import _get from "lodash-es/get";
import * as commonSelectors from "../selectors";
import { RootState } from "../reducers";
import * as couponActions from "./actions";
import * as commonActions from "../actions";
import { ADD_FORM, SEARCH_FORM, WHITELIST_FORM } from "./const";
import * as formSelectors from "src/ipm-shared/components/Form/selectors";
import * as formActions from "src/ipm-shared/components/Form/actions";
import {
  catchTakeLatest,
  reTryTakeLatest
} from "src/ipm-shared/Utils/ReduxSagaEffects";
import HttpRequestError from "src/ipm-shared/Utils/Exceptions/HttpRequestError";
import RestClient from "src/ipm-shared/services/Rest";
import Utils from "src/ipm-shared/Utils/Number";
import _isEmpty from "lodash-es/isEmpty";
import { PAGE_COUNT } from "./const";
import { PURPOSE } from "src/ipm-shared/components/Purpose/const";
import { ALL_BRAND, BRAND } from "src/ipm-shared/Utils/Images";
import { COUNTRY_OPTION_FORM } from "src/ipm-shared/components/SwitchCountryControl/const";
import CountryUtil from "src/ipm-shared/Utils/Country";

const actions = {
  ...commonActions,
  ...couponActions,
  ...formActions
};
const selectors = {
  ...commonSelectors,
  ...formSelectors
};
const watchedSagas = [
  reTryTakeLatest(actions.fetchPromoCode, handleFetchPromoCode),
  catchTakeLatest(actions.addPromoCode, handleAddPromoCode),
  reTryTakeLatest(actions.fetchWhitelist, handleFetchWhitelist),
  reTryTakeLatest(actions.addWhitelist, handleFetchAddWhitelist),
  catchTakeLatest(actions.editPromoCode, handleEditPromoCode),
  catchTakeLatest(actions.removeWhitelist, handleRemoveWhiteList),
  reTryTakeLatest(actions.fetchCountryBanks, handleFetchBanks)
];
export default watchedSagas;

// const checkRecurringCharge = (value: string) => {
//   let arr: string[] = [];
//   let cardTypes = value;
//   if (cardTypes !== "") {
//     arr = cardTypes.split(",");
//     const isHasVisa = arr.indexOf(BRAND.VISA.toString()) > -1;
//     if (!isHasVisa) {
//       arr.push(BRAND.VISA.toString());
//       cardTypes = arr.join(",");
//     }
//   } else {
//     cardTypes = BRAND.VISA.toString();
//   }
//   return cardTypes;
// };

export function* handleFetchPromoCode(
  action: ActionType<typeof actions.fetchPromoCode>
) {
  const state: RootState = yield select();

  yield put(
    actions.setPromoCode({
      isFetching: true,
      promoCodes: [],
      total: 0
    })
  );

  const { offset } = action.payload;
  const query = formSelectors.getControlsAsObject(state, SEARCH_FORM) as any;
  const queryCountry = formSelectors.getControlsAsObject(
    state,
    COUNTRY_OPTION_FORM
  ) as any;
  const countryId = _get(queryCountry, "switch_control_country_id", 0);
  const currencyId = _get(queryCountry, "switch_control_currency_id", 0);
  if (Number(countryId) === 0 && Number(currencyId) === 0) {
    query.is_global = "y";
  }
  query.country_whitelist = `${countryId}_${currencyId}`;
  query.offset = offset;
  query.page_count = PAGE_COUNT;
  if (query.promo_offer_value) {
    if (query.promo_offer === "amount") {
      query.amount_off = Math.round(query.promo_offer_value * 100);
    }
    if (query.promo_offer === "fee") {
      query.fee = Math.round(query.promo_offer_value * 100);
    }
  }
  const isAllPaymentTypesIsChecked = `${PURPOSE.RENTAL},${PURPOSE.SALARY},${PURPOSE.INVOICE}`;

  if (query.purposes === isAllPaymentTypesIsChecked) {
    query.purposes = "";
  }

  const res: Response = yield call(RestClient.send, {
    query,
    service: "admin_get_promo_code"
  });

  if (!res) {
    yield put(
      actions.setPromoCode({
        isFetching: false,
        promoCodes: [],
        total: 0
      })
    );
    throw new HttpRequestError("Failed to fetch");
  }

  try {
    const data: any[] = _get(res, "data", []);
    const total: number = _get(res, "total");

    yield put(
      actions.setPromoCode({
        isFetching: false,
        promoCodes: data.map(promo => {
          const item = {
            amountOff: promo.amount_off,
            bankID: promo.bank_id ? promo.bank_id : "all",
            cardBrands:
              promo.card_brands === null ? ALL_BRAND : promo.card_brands,
            code: promo.code,
            countryWhitelist: promo.country_whitelist
              .map((i: any) => `${i.country_id}_${i.currency_id}`)
              .join(","),
            createdAt: promo.created_at,
            createdBy: promo.created_by,
            createdByEmail: promo.created_by_email,
            createdByFirstName: promo.created_by_first_name,
            createdByLastName: promo.created_by_last_name,

            firstTimeUserOnly: promo.first_time_user_only,
            forBOCBINMastercardOnly: promo.boc_mastercard_only,

            forSpecialBINMastercardOnly: promo.special_mastercard_only,
            forTaxPaymentOnly: promo.for_tax_payment_only,
            hasWhitelist: promo.has_whitelist,
            id: promo.id,
            ignoreRateCheck: promo.ignore_rate_check,
            isBusinessAccount:
              promo.is_business_account === true
                ? "business"
                : promo.is_business_account === false
                ? "personal"
                : "both",
            isInactive: promo.is_inactive,
            isRecurringCharge: promo.is_recurring_charge,
            maxAmount: promo.max_amount,
            maxPerAccount: promo.max_per_account,
            minAmount: promo.min_amount,
            promoEndDate: promo.promo_end_date,
            promoStartDate: promo.promo_start_date,
            purposes: promo.purposes,
            rate: promo.rate,
            rateGroup: promo.group_id.toString(),
            scheduleType: promo.schedule_type
          };
          return item;
        }),
        total
      })
    );
  } catch (e) {
    window.Logger.error("handleFetchPromoCode: ", e.message);
    return;
  }
}

export function* handleAddPromoCode(
  action: ActionType<typeof actions.addPromoCode>
) {
  const state: RootState = yield select();
  const formState = selectors.getControls(state, ADD_FORM);

  const codeName = _get(formState, "code_name.value", "");
  const promoType = _get(formState, "promo_offer_add.value", "");
  let fee: number | undefined;
  let amountOff: string | undefined;
  if (promoType === "fee") {
    fee = Math.round(
      parseFloat(_get(formState, "promo_code_value.value", "")) * 100
    );
  } else if (promoType === "amount") {
    amountOff = _get(formState, "promo_code_value.value", "");
  }
  const isProduction = _get(formState, "general_environment.value", "");
  const queryCountry = formSelectors.getControlsAsObject(
    state,
    COUNTRY_OPTION_FORM
  ) as any;
  const countryId = _get(queryCountry, "switch_control_country_id", 0);
  const currencyId = _get(queryCountry, "switch_control_currency_id", 0);
  const isGlobal = Number(countryId) === 0 && Number(currencyId) === 0;
  const startDate = _get(formState, "promo_code_start_date.value", "");
  const endDate = _get(formState, "promo_code_expiry_date.value", "");
  const isActive = _get(formState, "is_active.value", "") === "active";
  let isRecurringCharge =
    _get(formState, "is_recurring_charge.value") === "checked";
  const firstTimeUserOnly =
    _get(formState, "first_time_user_only.value") === "checked";
  const maxAmount = Utils.amountStringToInt(
    _get(formState, "amount_cap.value")
  );
  const minAmount = Utils.amountStringToInt(
    _get(formState, "amount_min.value")
  );
  const maxPerAccount = parseFloat(_get(formState, "max_per_account.value", 0));
  const purposeId = _get(formState, "promo_code_purpose_id.value", "");
  const scheduleType = _get(formState, "schedule_type.value", "");

  const toggleWhitelist = _get(formState, "toggle_whitelist.value", "");
  const whiteListArr: string[] = [];
  const groupId = parseInt(_get(formState, "promo_code_group_id.value"), 10);

  const forTaxPaymentOnly =
    _get(formState, "for_tax_payment_only.value") === "checked";
  const ignoreRateCheck =
    _get(formState, "ignore_rate_check.value") === "checked";
  const forSpecialBINMastercardOnly =
    _get(formState, "for_special_bin_mastercard_only.value") === "checked";
  const forBOCBINMastercardOnly =
    _get(formState, "for_boc_bin_mastercardonly.value") === "checked";
  const isBusinessAccount =
    _get(formState, "account_type.value", "") === "business"
      ? true
      : _get(formState, "account_type.value", "") === "personal"
      ? false
      : null;
  const bankId = Number(_get(formState, "bank_account.value", ""));

  if (toggleWhitelist === "yes") {
    state.promo.whitelists.forEach(item => {
      whiteListArr.push(item.email);
    });
  }

  let cardType = _get(formState, "promo_code_card_type.value", "");
  const isRecurringChecked =
    _get(formState, "is_recurring_charge.value") === "checked";

  // Check if visa checked
  let arr: string[] = [];
  if (cardType !== "") {
    arr = cardType.split(",");
    const isHasVisa = arr.indexOf(BRAND.VISA.toString()) > -1;
    if (isHasVisa) {
      // If visa checked then visa recurring always is false.
      isRecurringCharge = false;
    } else {
      if (isRecurringCharge) {
        // If visa recurring checked then add visa and set recurring is true.
        arr.push(BRAND.VISA.toString());
        cardType = arr.join(",");
      }
    }
  } else {
    if (isRecurringChecked) {
      arr.push(BRAND.VISA.toString());
      cardType = arr.join(",");
    }
  }

  // Check if coupon global
  const countryWhitelistReq: any[] = [];
  if (isGlobal) {
    const countryWhitelist = _get(formState, "country_whitelist.value", "");
    countryWhitelist.split(",").forEach((item: string) => {
      const a = item.split("_") as string[];
      if (a.length === 2) {
        countryWhitelistReq.push({
          country_id: Number(_get(a, "[0]", 1)),
          currency_id: Number(_get(a, "[1]", 1))
        });
      }
    });
  } else {
    countryWhitelistReq.push({
      country_id: Number(countryId),
      currency_id: Number(currencyId)
    });
  }

  const res: Response = yield call(RestClient.send, {
    body: {
      amount_off: amountOff ? Utils.amountStringToInt(amountOff) : undefined,
      card_brand_id: cardType,
      card_issuer_country_id: null,
      code: codeName,
      country_whitelist: countryWhitelistReq,
      end_date: endDate,
      fee,
      first_time_user_only: firstTimeUserOnly,
      group_id: groupId,
      has_whitelist: toggleWhitelist,
      is_active: isActive,
      is_global: isGlobal,
      is_production: isProduction === "live",
      is_recurring_charge: isRecurringCharge,
      max_amount: maxAmount === 0 ? null : maxAmount,
      max_per_account: maxPerAccount,
      min_amount: minAmount === 0 ? null : minAmount,
      min_schedule_cycles: 0,
      purposes: purposeId,
      schedule_frequency: scheduleType,
      start_date: startDate,
      whitelist: whiteListArr,
      for_tax_payment_only: forTaxPaymentOnly,
      ignore_rate_check: ignoreRateCheck,
      special_mastercard_only: forSpecialBINMastercardOnly,
      boc_mastercard_only: forBOCBINMastercardOnly,
      is_business_account: isBusinessAccount,
      bank_id: bankId ? bankId : null
    },
    service: "admin_add_promo_code",
    showGlobalLoader: true,
    timeout: -1
  });

  if (!res) {
    return;
  }

  const errors = _get(res, "errors", {});

  if (!_isEmpty(errors)) {
    yield put(formActions.parseServerErrors(errors, ADD_FORM));
    return false;
  }

  yield put(actions.closeModal(actions.ModalID.PROMO_CODE_MODAL));
  yield put(actions.fetchPromoCode());
  return;
}

export function* handleEditPromoCode(
  action: ActionType<typeof actions.editPromoCode>
) {
  const id = action.payload.id;
  const state: RootState = yield select();
  const formState = selectors.getControls(state, ADD_FORM);
  const codeName = _get(formState, "code_name.value", "");
  const queryCountry = formSelectors.getControlsAsObject(
    state,
    COUNTRY_OPTION_FORM
  ) as any;
  const countryId = _get(queryCountry, "switch_control_country_id", 0);
  const currencyId = _get(queryCountry, "switch_control_currency_id", 0);
  const isGlobal = Number(countryId) === 0 && Number(currencyId) === 0;
  const promoType = _get(formState, "promo_offer_add.value", "");
  let fee: number | undefined;
  let amountOff: string | undefined;
  if (promoType === "fee") {
    fee = Math.round(
      parseFloat(_get(formState, "promo_code_value.value", "")) * 100
    );
  } else if (promoType === "amount") {
    amountOff = _get(formState, "promo_code_value.value", "");
  }
  const maxAmount = Utils.amountStringToInt(
    _get(formState, "amount_cap.value")
  );
  const minAmount = Utils.amountStringToInt(
    _get(formState, "amount_min.value")
  );
  const maxPerAccount = parseFloat(_get(formState, "max_per_account.value", 0));
  const firstTimeUserOnly =
    _get(formState, "first_time_user_only.value") === "checked";
  const startDate = _get(formState, "promo_code_start_date.value", "");
  const endDate = _get(formState, "promo_code_expiry_date.value", "");
  const purposeId = _get(formState, "promo_code_purpose_id.value", "");
  const scheduleType = _get(formState, "schedule_type.value", "");
  const isProduction = _get(formState, "general_environment.value", "");
  const isActive = _get(formState, "is_active.value", "") === "active";
  let isRecurringCharge =
    _get(formState, "is_recurring_charge.value") === "checked";
  const toggleWhitelist = _get(formState, "toggle_whitelist.value", "");
  const whiteListArr: string[] = [];
  const groupId = parseInt(_get(formState, "promo_code_group_id.value"), 10);

  const forTaxPaymentOnly =
    _get(formState, "for_tax_payment_only.value") === "checked";
  const ignoreRateCheck =
    _get(formState, "ignore_rate_check.value") === "checked";
  const forSpecialBINMastercardOnly =
    _get(formState, "for_special_bin_mastercard_only.value") === "checked";
  const forBOCBINMastercardOnly =
    _get(formState, "for_boc_bin_mastercardonly.value") === "checked";
  const isBusinessAccount =
    _get(formState, "account_type.value", "") === "business"
      ? true
      : _get(formState, "account_type.value", "") === "personal"
      ? false
      : null;
  const bankId = Number(_get(formState, "bank_account.value", ""));

  if (toggleWhitelist === "yes") {
    state.promo.whitelists.forEach(item => {
      whiteListArr.push(item.email);
    });
  }

  let cardType = _get(formState, "promo_code_card_type.value", "");
  const isRecurringChecked =
    _get(formState, "is_recurring_charge.value") === "checked";

  // Check if visa checked
  let arr: string[] = [];
  if (cardType !== "") {
    arr = cardType.split(",");
    const isHasVisa = arr.indexOf(BRAND.VISA.toString()) > -1;
    if (isHasVisa) {
      // If visa checked then visa recurring always is false.
      isRecurringCharge = false;
    } else {
      if (isRecurringCharge) {
        // If visa recurring checked then add visa and set recurring is true.
        arr.push(BRAND.VISA.toString());
        cardType = arr.join(",");
      }
    }
  } else {
    if (isRecurringChecked) {
      arr.push(BRAND.VISA.toString());
      cardType = arr.join(",");
    }
  }
  // Check if coupon global
  const countryWhitelistReq: any[] = [];
  if (isGlobal) {
    const countryWhitelist = _get(formState, "country_whitelist.value", "");
    countryWhitelist.split(",").forEach((item: string) => {
      const a = item.split("_") as string[];
      if (a.length === 2) {
        countryWhitelistReq.push({
          country_id: Number(_get(a, "[0]", 1)),
          currency_id: Number(_get(a, "[1]", 1))
        });
      }
    });
  } else {
    countryWhitelistReq.push({
      country_id: Number(countryId),
      currency_id: Number(currencyId)
    });
  }

  const res: Response = yield call(RestClient.send, {
    body: {
      amount_off: amountOff ? Utils.amountStringToInt(amountOff) : undefined,
      card_brand_id: cardType,
      card_issuer_country_id: null,
      code: codeName,
      country_whitelist: countryWhitelistReq,
      end_date: endDate,
      fee,
      first_time_user_only: firstTimeUserOnly,
      group_id: groupId,
      has_whitelist: toggleWhitelist,
      is_active: isActive,
      is_global: isGlobal,
      is_production: isProduction === "live",
      is_recurring_charge: isRecurringCharge,
      max_amount: maxAmount === 0 ? null : maxAmount,
      max_per_account: maxPerAccount,
      min_amount: minAmount === 0 ? null : minAmount,
      min_schedule_cycles: 0,
      purposes: purposeId,
      schedule_frequency: scheduleType,
      start_date: startDate,
      whitelist: whiteListArr,
      for_tax_payment_only: forTaxPaymentOnly,
      ignore_rate_check: ignoreRateCheck,
      special_mastercard_only: forSpecialBINMastercardOnly,
      boc_mastercard_only: forBOCBINMastercardOnly,
      is_business_account: isBusinessAccount,
      bank_id: bankId ? bankId : null
    },
    params: {
      id
    },
    service: "admin_edit_promo_code",
    showGlobalLoader: true,
    timeout: -1
  });

  if (!res) {
    return;
  }

  const errors = _get(res, "errors", {});

  if (!_isEmpty(errors)) {
    yield put(formActions.parseServerErrors(errors, ADD_FORM));
    return false;
  }

  yield put(actions.closeModal(actions.ModalID.PROMO_CODE_MODAL));
  yield put(actions.fetchPromoCode());
  return;
}

export function* handleFetchWhitelist(
  action: ActionType<typeof actions.fetchWhitelist>
) {
  const promoId = action.payload.promoId;
  const res: Response = yield call(RestClient.send, {
    params: {
      id: promoId
    },
    service: "admin_get_promo_whitelist"
  });

  if (!res) {
    return;
  }

  try {
    const data: any[] = _get(res, "data", []);
    action.payload.cb(data);
    yield put(
      actions.setWhiteList({
        isFetchingWhitelist: false,
        whitelist: data
      })
    );
    const whitelists = data.map(item => item.email);
    yield put(
      formActions.setControl({
        name: "whitelist",
        value: whitelists.join(",")
      })
    );
  } catch (e) {
    window.Logger.error("handleFetchWhitelist: ", e.message);
    return;
  }
}

export function* handleFetchAddWhitelist(
  action: ActionType<typeof actions.addWhitelist>
) {
  const state: RootState = yield select();
  const formState = selectors.getControlsAsObject(state, WHITELIST_FORM);
  const exactEmail = _get(formState, "exact_email", "");
  // Validate exactEmail
  if (_isEmpty(exactEmail)) {
    yield put(
      formActions.parseServerErrors(
        {
          fields: {
            exact_email: ["INVALID_EMAIL_ADDRESS"]
          },
          form: []
        },
        WHITELIST_FORM
      )
    );
    return;
  }
  const findWhitelist = state.promo.whitelists.find(
    item => item.email === exactEmail
  );
  if (findWhitelist) {
    yield put(
      formActions.setControl({
        name: "exact_email",
        value: ""
      })
    );
    return;
  }
  const res: Response = yield call(RestClient.send, {
    query: {
      exact_email: exactEmail,
      offset: 0,
      page_count: 1
    },
    service: "get_users",
    showGlobalLoader: true,
    timeout: -1
  });

  if (!res) {
    return;
  }
  const errors = _get(res, "errors", {});

  if (!_isEmpty(errors)) {
    yield put(formActions.parseServerErrors(errors, WHITELIST_FORM));
    return false;
  }

  try {
    const data: any = _get(res, "data", []);
    if (data.length === 0) {
      yield put(
        formActions.parseServerErrors(
          {
            fields: {
              exact_email: ["EMAIL_NOT_EXIST"]
            },
            form: []
          },
          WHITELIST_FORM
        )
      );
      return false;
    }
    const newWhitelist = [
      ...state.promo.whitelists,
      {
        email: data[0].email,
        firstName: data[0].first_name,
        id: data[0].id,
        lastName: data[0].last_name
      }
    ];
    yield put(
      formActions.setControl({
        name: "exact_email",
        value: ""
      })
    );
    yield put(
      actions.setWhiteList({
        isFetchingWhitelist: false,
        whitelist: newWhitelist
      })
    );
    const whitelists = newWhitelist.map(item => item.email);
    yield put(
      formActions.setControl({
        name: "whitelist",
        value: whitelists.join(",")
      })
    );
  } catch (e) {
    window.Logger.error("handleFetchPromoCode: ", e.message);
    return;
  }
  return;
}

export function* handleRemoveWhiteList(
  action: ActionType<typeof actions.removeWhitelist>
) {
  const state: RootState = yield select();
  const newWhitelist = state.promo.whitelists.filter(
    item => item.email !== action.payload.email
  );
  yield put(
    actions.setWhiteList({
      isFetchingWhitelist: false,
      whitelist: newWhitelist
    })
  );
  const whitelists = newWhitelist.map(item => item.email);
  yield put(
    formActions.setControl({
      name: "whitelist",
      value: whitelists.join(",")
    })
  );
  return;
}

export function* handleFetchBanks(
  action: ActionType<typeof actions.fetchCountryBanks>
) {
  const state: RootState = yield select();
  const query = formSelectors.getControlsAsObject(state, SEARCH_FORM) as any;
  const queryCountry = formSelectors.getControlsAsObject(
    state,
    COUNTRY_OPTION_FORM
  ) as any;

  const countryId = Number(_get(queryCountry, "switch_control_country_id", 0));
  const country = CountryUtil.getCountryCodeFromCountryId(countryId);
  query.country = country;

  yield put(
    actions.setBanks({
      ...state.promo,
      banks: []
    })
  );

  const res: Response = yield call(RestClient.send, {
    query,
    service: "get_banks"
  });

  if (!res) {
    return;
  }
  try {
    const data: any[] = _get(res, "data", []);

    yield put(
      actions.setBanks({
        ...state.promo,
        banks: data.map(bank => ({
          countryId: bank.country_id,
          displayName: bank.display_name ? bank.display_name : "",
          id: bank.id,
          isDeleted: bank.is_deleted,
          name: bank.name,
          swiftCode: bank.swift_code
        }))
      })
    );
  } catch (e) {
    window.Logger.error("handleFetchWhitelist: ", e.message);
    return;
  }
}
