import React from "react";
import _get from "lodash-es/get";
import Button from "src/bepaid/components/Form/controls/Button";
import { history } from "src/ipm-shared/store";
import Image from "src/bepaid/components/Image";
import IconPaymentScheduled from "src/bepaid/assets/images/payments/payment_scheduled.svg";
import ROUTES from "src/bepaid/routes";
import { useMakePaymentUrlParams } from "src/bepaid/pages/Payments/hooks";
import paymentMethodScheduleStyles from "../../PaymentResultModal.module.scss";
import BtnGoToDashBoard from "../BtnGoToDashBoard";
import { extractDateFromQueryParam, formatDate } from "../utils";

import T from "src/ipm-shared/Utils/Intl";
import PurposeUtil from "src/ipm-shared/Utils/Purpose";

const PaymentScheduled = (props: any) => {
  // const prevRoute = history?.location?.state?.prevRoute;
  const checkoutUrl = String(localStorage.getItem("checkoutUrl"));

  const {
    paymentType,
    replaceRouteWithParams,
    query
  } = useMakePaymentUrlParams(ROUTES.PAYMENTS_CREATION, checkoutUrl);

  const isWallex = paymentType === "international";

  const makeAnotherPayment = () => {
    props.resetAllModals();
    history.push(
      replaceRouteWithParams(ROUTES.PAYMENTS_CREATION, {
        newEntity: isWallex ? "country" : "entity",
        newAction: "select"
      })
    );
  };

  const payoutDate =
    props.openedModalIds.length > 1 && _get(props.extraInfo, "payoutDate")
      ? new Date(_get(props.extraInfo, "payoutDate"))
      : extractDateFromQueryParam(query, "mf_payout_date");

  const purpose: string =
    String(_get(query, "purpose")) ||
    PurposeUtil.toString(_get(props.extraInfo, "purposeId"));

  const labelPurpose = {
    invoice: "supplier",
    salary: "employee",
    rent: "landlord"
  }[purpose];

  return (
    <>
      <div className={paymentMethodScheduleStyles.text}>
        <Image width={200} src={IconPaymentScheduled} />
        <h2 className={paymentMethodScheduleStyles.title}>
          Payment scheduled!
        </h2>
        <p>
          {T.transl("LABEL_PAYMENT_SCHEDULE_SUCCESS_3", {
            charged_date: formatDate(props.chargeDate),
            recipient: labelPurpose,
            payout_date: formatDate(payoutDate)
          })}
        </p>
      </div>
      <Button
        type="primary"
        htmlType="button"
        onClick={makeAnotherPayment}
        wrapperClassName={paymentMethodScheduleStyles.btnMakeAnotherPayment}
      >
        Make another payment
      </Button>
      <BtnGoToDashBoard />
    </>
  );
};

export default PaymentScheduled;
