import React, { useEffect, useState, useImperativeHandle } from "react";
import _get from "lodash-es/get";
import uuidV1 from "uuid/v1";

import {
  ADD_ITEM_FORM,
  SETUP_INVOICE_NUMBER
} from "src/ipm-shared/store/model/CollectedAccount/const";

import InputText from "src/bepaid/components/Form/controls/InputText";
import Image from "src/bepaid/components/Image";
import ActionDetailDrawer from "src/bepaid/components/ActionDetailDrawer";
import ButtonIcon from "src/bepaid/components/Form/controls/ButtonIcon";

import IconDropDown from "src/bepaid/assets/images/common/drop_down_arrow.svg";
import GearIcon from "src/bepaid/assets/images/invoices/gear.svg";

import AddItem from "./AddItem";
import InvoiceNumber from "./InvoiceNumber";

import styles from "./Additional.module.scss";

const defaultSelectedItem: any[] = [];

const View = React.forwardRef((props: any, ref: any) => {
  const { isQuickInvoice = false } = props;

  useImperativeHandle(ref, () => ({
    removeForm: () => {
      props.setControl({
        errors: [],
        form: props.form,
        name: `invoice_note`,
        value: null
      });
      props.setControl({
        errors: [],
        form: props.form,
        name: `term_and_condition`,
        value: null
      });
      props.setControl({
        errors: [],
        form: props.form,
        name: `po_number`,
        value: null
      });
      props.setControl({
        errors: [],
        form: props.form,
        name: `invoice_number_digits_${getSelectedCustomers.id}`,
        value: _get(invoiceSelected, "payees[0].invoice_number_digits")
          ? _get(invoiceSelected, "payees[0].invoice_number_digits")
          : _get(otherSettings, "generateOrManualIN") === "generate" ||
            selectedItem.length === 0
          ? Number(_get(otherSettings, "invoiceNumberTemplate"))
          : null
      });
      props.setControl({
        errors: [],
        form: props.form,
        name: `payment_description_${getSelectedCustomers.id}`,
        value: _get(invoiceSelected, "payees[0].payment_description")
          ? _get(invoiceSelected, "payees[0].payment_description")
          : _get(otherSettings, "generateOrManualIN") === "manual" &&
            selectedItem.length > 0
          ? ""
          : `${_get(otherSettings, "invoiceNumberPrefix", "")}${_get(
              otherSettings,
              "invoiceNumberTemplate",
              ""
            )}`
      });
      props.selectAllItemIds([]);
    },
    hasData: () => {
      if (_get(props.getControl(`invoice_note`, props.form), "value")) {
        return true;
      }
      if (_get(props.getControl(`term_and_condition`, props.form), "value")) {
        return true;
      }
      if (_get(props.getControl(`po_number`, props.form), "value")) {
        return true;
      }
      if (selectedItem.length > 0) {
        return true;
      }
      return false;
    }
  }));
  const commonFormItemProps = {
    reserveValueOnUnmount: true,
    revertValueOnMount: true
  };
  const { otherSettings, getSelectedCustomers, form } = props;
  const invoiceSelected = props.getInvoiceSelected();
  const getSelectedCustomer = _get(getSelectedCustomers, "[0]", null);
  if (!getSelectedCustomer) {
    return null;
  }
  useEffect(() => {
    props.fetchItems();
    props.fetchTaxes();
    props.setControl({
      errors: [],
      form: props.form,
      name: `invoice_note`,
      value: _get(props.getControl(`invoice_note`, props.form), "value")
    });
    props.setControl({
      errors: [],
      form: props.form,
      name: `term_and_condition`,
      value: _get(props.getControl(`term_and_condition`, props.form), "value")
    });
    props.setControl({
      errors: [],
      form: props.form,
      name: `po_number`,
      value: _get(props.getControl(`po_number`, props.form), "value")
    });
  }, []);

  useEffect(() => {
    updateInputPaymentDescription(
      invoiceSelected,
      getSelectedCustomer.id,
      otherSettings
    );
  }, [_get(otherSettings, "invoiceNumberTemplate")]);
  useEffect(() => {
    const items = _get(invoiceSelected, "payees[0].items", []);
    if (items && items.length > 0) {
      const payload = items.map((item: any) => {
        let tax = null;
        if (item.tax_id) {
          tax = props.taxes.find((t: any) => t.id === item.tax_id);
        }
        return {
          ...item,
          discountType: item.discount_type,
          id: item.item_id,
          quantity: item.quantity,
          taxRate: tax ? tax.rate : item.tax_rate,
          taxName: tax ? tax.name : item.tax_name,
          taxId: item.tax_id,
          unitPrice: item.unit_price,
          // tslint:disable-next-line:object-literal-sort-keys
          uid: uuidV1()
        };
      });
      props.selectAllItemIds(payload);
      setSelectedItem(payload);
    }
  }, [_get(invoiceSelected, "payees[0].items")]);

  const handleOpenAddItem = () => {
    let type = "add";
    if (props.getItems && props.getItems.length > 0) {
      type = "select";
    }
    setActionDrawer({
      ...actionDrawer,
      current: "add_item",
      open: true,
      type
    });
  };
  const [actionDrawer, setActionDrawer] = useState({
    back: () => handleCloseActionDrawer(),
    current: "invoice_number",
    open: false,
    type: "select",
    // tslint:disable-next-line:object-literal-sort-keys
    data: {
      uid: uuidV1()
    }
  });
  const handleCloseActionDrawer = () => {
    setActionDrawer({
      ...actionDrawer,
      open: false
    });
  };
  const openInvoiceNumberModal = () => {
    setActionDrawer({
      ...actionDrawer,
      current: "invoice_number",
      open: true
    });
  };
  const handleOpenAddTax = (data: any) => {
    setActionDrawer({
      ...actionDrawer,
      current: "add_tax",
      open: true,
      // tslint:disable-next-line:object-literal-sort-keys
      data
    });
  };
  const handleChangeTypeInvoiceNumber = (type: string) => {
    props.setControl({
      errors: [],
      form: SETUP_INVOICE_NUMBER,
      name: `to_generate_or_add_manual_invoice_number`,
      value: type
    });
  };
  const handleSubmitInvoiceNumber = () => {
    props.setupInvoiceNumber((err: any, data: any) => {
      if (err === null) {
        updateInputPaymentDescription(invoiceSelected, getSelectedCustomer.id, {
          ...otherSettings,
          ...data
        });
        handleCloseActionDrawer();
      }
    });
  };
  const [selectedItem, setSelectedItem] = useState(defaultSelectedItem);
  const updateInputPaymentDescription = (
    invoiceSelectedParam: any,
    customerId: any,
    otherSettingsParam: any
  ) => {
    props.setControl({
      errors: [],
      form: props.form,
      name: `invoice_number_digits_${customerId}`,
      value: _get(invoiceSelectedParam, "payees[0].invoice_number_digits")
        ? _get(invoiceSelectedParam, "payees[0].invoice_number_digits")
        : _get(otherSettingsParam, "generateOrManualIN") === "generate" ||
          selectedItem.length === 0
        ? Number(_get(otherSettingsParam, "invoiceNumberTemplate"))
        : null
    });
    const valueInvoiceNumber = _get(
      invoiceSelectedParam,
      "payees[0].payment_description"
    )
      ? _get(invoiceSelectedParam, "payees[0].payment_description")
      : _get(otherSettingsParam, "generateOrManualIN") === "manual" &&
        selectedItem.length > 0
      ? ""
      : `${_get(otherSettingsParam, "invoiceNumberPrefix", "")}${_get(
          otherSettingsParam,
          "invoiceNumberTemplate",
          ""
        )}`;
    props.setControl({
      errors: [],
      form: props.form,
      name: `payment_description_${customerId}`,
      value: valueInvoiceNumber
    });
  };
  const handleChangeType = (type: any, data: any) => {
    let backAction = handleCloseActionDrawer;
    if (type === "add") {
      backAction = () => {
        setActionDrawer({
          ...actionDrawer,
          type: "select"
        });
      };
    }
    setActionDrawer({
      ...actionDrawer,
      back: backAction,
      data,
      type
    });
  };

  const handleSelectItem = (data: any) => {
    const payload: any[] = [];
    data.forEach((item: any) => {
      if (item && item.uid) {
        let tax;
        if (item.taxId) {
          tax = props.taxes.find((t: any) => t.id === item.taxId);
        }
        payload.push({
          ...item,
          taxRate: tax ? tax.rate : item.tax_rate,
          taxName: tax ? tax.name : item.tax_name,
          id: item.id ? item.id : item.itemId
        });
      }
    });
    props.selectAllItemIds(payload);
  };

  useEffect(() => {
    if (props.invoiceLineItems) {
      const newItemSelectedId: any[] = [];
      Object.keys(props.invoiceLineItems).forEach(key => {
        if (props.invoiceLineItems[key].itemId > 0) {
          newItemSelectedId.push({
            ...props.invoiceLineItems[key],
            id: props.invoiceLineItems[key].itemId
          });
        }
      });
      setSelectedItem(newItemSelectedId);
    }
  }, [props.invoiceLineItems]);

  const handleSubmitAddItem = (setLoading: any) => {
    props.addNewItem(
      uuidV1(),
      (data: number) => {
        if (data > 0) {
          setActionDrawer({
            ...actionDrawer,
            current: "add_item",
            open: true,
            type: "select"
          });
        }
      },
      setLoading
    );
  };

  const handleSubmitEditItem = (item: any, setLoading: any) => {
    props.editItem(
      item.uid,
      item.id,
      (data: number) => {
        if (data > 0) {
          handleOpenAddItem();
        }
      },
      setLoading
    );
  };
  const handleSubmitAddTax = (cb: (id: number) => void, setLoading: any) => {
    props.addNewTax({
      cb: (data: any) => {
        if (data) {
          cb(data);
          setActionDrawer({
            ...actionDrawer,
            current: "add_item",
            open: true,
            type: "add"
          });
        } else {
          cb(0);
        }
      },
      uid: _get(actionDrawer, "data.uid"),
      loadingFunc: setLoading
    });
  };

  const handleSelectTax = (uid: string, id: number) => {
    props.selectTaxId({
      id,
      name: uid
    });
  };
  const handleSubmitEditTax = (
    taxId: number,
    cb: (id: number) => void,
    setLoading: any
  ) => {
    props.editTax({
      cb: (data: any) => {
        if (data) {
          cb(data);
          setActionDrawer({
            ...actionDrawer,
            current: "add_item",
            open: true,
            type: "add"
          });
        } else {
          cb(0);
        }
      },
      id: taxId,
      uid: _get(actionDrawer, "data.uid"),
      loadingFunc: setLoading
    });
  };
  const handleUpdateInvoiceLineItem = (uid: any, field: string, value: any) => {
    props.updateInvoiceLineItem({
      item: {
        ...props.invoiceLineItems[uid],
        [field]: value
      },
      uid
    });
  };
  const { collectedAccount, getCurrenciesById } = props;
  const currencyId = collectedAccount.currencyId;
  const currencySymbol = getCurrenciesById[currencyId].symbol;

  const renderActionDrawer = () => {
    const renderContent = () => {
      if (actionDrawer.current === "invoice_number") {
        return (
          <InvoiceNumber
            form={SETUP_INVOICE_NUMBER}
            otherSetting={props.otherSettings}
            changeGenerateInvoiceNumber={handleChangeTypeInvoiceNumber}
            submit={handleSubmitInvoiceNumber}
            setControl={props.setControl}
          />
        );
      }
      if (actionDrawer.current === "add_item") {
        return (
          <AddItem
            close={handleCloseActionDrawer}
            type={actionDrawer.type}
            changeType={handleChangeType}
            openAddNewTag={handleOpenAddTax}
            form={ADD_ITEM_FORM}
            currencySymbol={currencySymbol}
            itemEdit={actionDrawer.data}
            items={props.getItems}
            selectedItem={props.getSelectedItemsIds}
            selectedTax={props.getSelectedTaxesIds}
            invoiceLineItems={props.invoiceLineItems}
            addItem={handleSubmitAddItem}
            editItem={handleSubmitEditItem}
            selectItem={handleSelectItem}
            taxes={props.taxes}
            selectTax={handleSelectTax}
            addTax={handleSubmitAddTax}
            editTax={handleSubmitEditTax}
            setControl={props.setControl}
            currencyId={props.currencyId}
            updateInvoiceLineItem={handleUpdateInvoiceLineItem}
            parseServerErrors={props.parseServerErrors}
          />
        );
      }
      return null;
    };

    return (
      <ActionDetailDrawer
        height={"auto"}
        visible={actionDrawer.open}
        data={null}
        onClose={handleCloseActionDrawer}
      >
        {renderContent()}
      </ActionDetailDrawer>
    );
  };
  const renderTitleItem = () => {
    if (selectedItem.length > 1) {
      const itemSelected = props.getItems.filter(
        (item: any) => item.id === selectedItem[0].id
      )[0];
      return (
        <>
          <span className={styles.textItemFirst}>
            {itemSelected
              ? itemSelected.name
              : "Sample item name that is very long"}
          </span>
          <span className={styles.btnCount}>+{selectedItem.length - 1}</span>
        </>
      );
    } else if (selectedItem.length === 1) {
      const itemSelected = props.getItems.filter(
        (item: any) => item.id === selectedItem[0].id
      )[0];
      return <span>{itemSelected ? itemSelected.name : ""}</span>;
    }
    return <span>Item or service name</span>;
  };

  return (
    <div className={styles.wrapperFormDetail}>
      {isQuickInvoice ? (
        <InputText
          label="Notes"
          type={"textarea"}
          placeholder={"Notes"}
          form={form}
          name={`invoice_note`}
          defaultValue={_get(invoiceSelected, "invoiceNote", null)}
          {...commonFormItemProps}
        />
      ) : (
        <>
          <InputText
            hidden={true}
            className={styles.hidden}
            form={form}
            name={`invoice_number_digits_${getSelectedCustomer.id}`}
            defaultValue={
              _get(invoiceSelected, "payees[0].invoice_number_digits")
                ? _get(invoiceSelected, "payees[0].invoice_number_digits")
                : _get(otherSettings, "generateOrManualIN") === "generate" ||
                  selectedItem.length === 0
                ? Number(_get(otherSettings, "invoiceNumberTemplate"))
                : null
            }
            {...commonFormItemProps}
          />
          <InputText
            hidden={true}
            className={styles.hidden}
            form={form}
            name={`terms_${getSelectedCustomer.id}`}
            defaultValue={0}
            {...commonFormItemProps}
          />

          <div className={styles.invoiceNumberWrapper}>
            <InputText
              label="Invoice number"
              placeholder={"Invoice number"}
              form={form}
              name={`payment_description_${getSelectedCustomer.id}`}
              suffix={<Image src={GearIcon} onClick={openInvoiceNumberModal} />}
              defaultValue={
                _get(invoiceSelected, "payees[0].payment_description")
                  ? _get(invoiceSelected, "payees[0].payment_description")
                  : _get(otherSettings, "generateOrManualIN") === "manual" &&
                    selectedItem.length > 0
                  ? ""
                  : `${_get(otherSettings, "invoiceNumberPrefix", "")}${_get(
                      otherSettings,
                      "invoiceNumberTemplate",
                      ""
                    )}`
              }
              {...commonFormItemProps}
            />
          </div>

          <InputText
            label="PO number"
            form={form}
            name={`po_number`}
            placeholder={"PO number"}
            defaultValue={_get(invoiceSelected, "payees[0].po_number", null)}
            {...commonFormItemProps}
          />

          {/*<p className={styles.text}>Terms</p>*/}
          {/*<Select*/}
          {/*  defaultValue={calendar.value.term}*/}
          {/*  name="terms"*/}
          {/*  onChange={handleChangeTerm}*/}
          {/*  options={TermsOptions}*/}
          {/*/>*/}

          {/* SelectDrawer */}
          <p className={styles.text}>Add item or service</p>
          <ButtonIcon
            className={styles.btnAddItem}
            iconClass={styles.iconItem}
            icon={<Image src={IconDropDown} />}
            onClick={handleOpenAddItem}
          >
            {renderTitleItem()}
          </ButtonIcon>
          <InputText
            label="Notes"
            type={"textarea"}
            placeholder={"Notes"}
            form={form}
            name={`invoice_note`}
            defaultValue={_get(invoiceSelected, "invoiceNote", null)}
            {...commonFormItemProps}
          />
          <InputText
            label="Terms and conditions"
            type={"textarea"}
            placeholder={"Terms and conditions"}
            form={form}
            name={`term_and_condition`}
            defaultValue={_get(invoiceSelected, "termAndCondition", null)}
            {...commonFormItemProps}
          />
          {renderActionDrawer()}
        </>
      )}
    </div>
  );
});

export default View;
