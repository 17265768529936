import { RouteProps } from "react-router";
import { connect } from "react-redux";

import { RootState } from "src/ipm-shared/store/model/reducers";

import * as selectors from "src/bepaid/store/model/Fetch/selectors";
import * as actions from "src/bepaid/store/model/Fetch/actions";

import Component from "./View";

const mapStateToProps = (state: RootState) => ({
  statistics: selectors.getDashboardStatistics(state),
  isPersonalAccount: selectors.getIsPersonalAccount(state),
  isSingaporeAccount: selectors.isSingaporeAccount(state),
  wallexKycStatus: selectors.getWallexKycStatus(state)
});

const mapDispatchToProps = {
  getDashboardStatistics: actions.getDashboardStatistics
};

export type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteProps;

export default connect(mapStateToProps, mapDispatchToProps)(Component);
