import React from "react";
import classNames from "classnames";

import Image from "src/bepaid/components/Image";

import IconDocument from "src/bepaid/assets/images/common/icon_document.svg";
import IconReview from "src/bepaid/assets/images/common/icon_review.svg";
import IconDelete from "src/bepaid/assets/images/common/icon_delete.svg";

import { getFileNameDisplay } from "src/bepaid/utils/file";

import styles from "../../InputFile.module.scss";

const View = (props: any) => {
  const {
    file,
    btnClassName,
    onClickDelete,
    onClickViewFile,
    justView
  } = props;

  return (
    <div className={classNames(styles.btnUpload, btnClassName)}>
      <div className={styles.left}>
        <Image src={IconDocument} />
        <span>{getFileNameDisplay(file.fileName)}</span>
      </div>
      <div className={styles.right}>
        <Image src={IconReview} onClick={onClickViewFile.bind(null, file)} />
        {!justView && (
          <Image src={IconDelete} onClick={onClickDelete.bind(null, file)} />
        )}
      </div>
    </div>
  );
};

export default View;
