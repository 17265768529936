/* tslint:disable:object-literal-sort-keys jsx-no-lambda */
import React, { useEffect, useState } from "react";

import Form from "src/bepaid/components/Form";

import InputText from "src/bepaid/components/Form/controls/InputText";
import Image from "src/bepaid/components/Image";
import FormStyles from "src/bepaid/components/Form/CommonForm.module.scss";
import Skeleton from "src/bepaid/components/Skeleton";
import Pagination from "src/bepaid/components/Pagination";

// import IconClose from "src/bepaid/assets/images/common/icon_close_light.svg";
import IconSearch from "src/bepaid/assets/images/common/icon_search.svg";
import NextIcon from "src/bepaid/assets/images/common/desktop/next_black_noborder.svg";

import CustomerList from "./components/List";

import styles from "./SelectCustomer.module.scss";
import { LIMIT_PER_PAGE } from "../../const";

const initPagination = {
  offset: 0,
  pageCount: LIMIT_PER_PAGE
};

const SelectCustomer: React.FC<any> = props => {
  const {
    // isFetchingCustomer,
    listCustomer,
    openAdd,
    selectCustomers,
    getSelectedCustomersId,
    getCustomerList,
    total
  } = props;

  const [pagination, setPagination] = React.useState(initPagination);
  // const [maxHeight, setMaxHeightList] = useState(0);

  const [isLoading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState<string | undefined>(undefined);
  // const [page, setPage] = React.useState(1);
  // const [loadMore, setLoadMore] = useState(false);
  // const [stopLoadMore, setStopLoadMore] = useState(false);

  // const foundCustomers = listCustomer.filter((cus: any) =>
  //   searchText
  //     ? cus.name.toLowerCase().includes(searchText.toLowerCase())
  //     : true
  // );

  const foundCustomers = listCustomer;

  const handleSelectCustomer = (id: number, checked: boolean) => {
    let ids = [...getSelectedCustomersId];
    if (checked) {
      if (!ids.includes(id)) {
        ids = []; // using on single select, prepare for multiple select
        ids.push(id);
      }
    } else {
      if (ids.includes(id)) {
        const index = ids.indexOf(id);
        if (index !== -1) {
          ids.splice(index, 1);
        }
      }
    }
    selectCustomers(ids);
  };

  const handleFetchCustomers = (searchText?: string) => {
    const queryRequest = {
      offset: pagination.offset,
      page_count: pagination.pageCount,
      search_keyword: searchText
    };

    getCustomerList(
      queryRequest,
      (err: any, data: any) => {},
      setLoading,
      true
    );
  };

  const onChangeSearchText = (v: string) => {
    setTimeout(() => {
      setSearchText(v);
    }, 500);
  };

  const onAddCustomerByName = () => {
    if (openAdd) {
      openAdd(searchText);
    }
  };

  const onSelectCustomer = (customer: any, checked: boolean) => {
    handleSelectCustomer(customer.id, checked);
  };

  useEffect(() => {
    handleFetchCustomers(searchText);
  }, [pagination]);

  useEffect(() => {
    return () => {
      setPagination(JSON.parse(JSON.stringify(initPagination)));
    };
  }, [searchText]);

  // const onRemoveSelectedCustomer = () => {
  //   selectCustomers([]);
  // };

  /* Waiting the desktop version of our “Edit customer” screen. */
  // const onGoToEditCustomer = () => {
  //   history.push(ROUTES.CUSTOMER_EDIT.replace(":id", selectedCustomer.id));
  // };
  // const onGoToCustomerDetail = () => {
  //   history.push(ROUTES.CUSTOMER_DETAIL.replace(":id", selectedCustomer.id));
  // };

  const handleOnChangePage = (offset: number) => {
    setPagination({ ...pagination, offset });
  };

  return (
    <div className={styles.wrapper}>
      {
        // !selectedCustomer ?
        <>
          <Form className={FormStyles.Form}>
            <InputText
              name={"search_customer"}
              form={"SearchCustomer"}
              placeholder={"Search customers…"}
              onChangeCustom={onChangeSearchText}
              suffix={<Image src={IconSearch} />}
            />
          </Form>

          <div className={styles.customerList}>
            {searchText && foundCustomers.length === 0 && (
              <p onClick={onAddCustomerByName} className={styles.linkText}>
                Add “{searchText}” as a new customer.
              </p>
            )}

            <Skeleton loading={isLoading}>
              <CustomerList
                options={foundCustomers}
                onSelectCustomer={onSelectCustomer}
                getSelectedCustomersId={getSelectedCustomersId}
              />
              <Pagination
                customClassname={styles.paginationWrapper}
                defaultMode={true}
                hideOnSinglePage={true}
                showSizeChanger={false}
                showLessItems={true}
                total={total}
                showQuickJumper={
                  total / pagination.pageCount > 10 && {
                    goButton: (
                      <button className={styles.gotoButton}>
                        <b>
                          Go
                          <Image src={NextIcon} className={styles.gotoIcon} />
                        </b>
                      </button>
                    )
                  }
                }
                locale={{ jump_to: "Go to page" }}
                isDisableToggle={total / pagination.pageCount < 5}
                onChange={handleOnChangePage}
                {...pagination}
              />
            </Skeleton>
          </div>
        </>
        // : (
        //   <div>
        //     <Form className={styles.formSelectedCustomer}>
        //       <InputText
        //         className={classNames(styles.inputSelectedCustomer, {
        //           [styles.selectedCustomerDesktop]: true
        //         })}
        //         name={"selected_customer"}
        //         label={"Customer name"}
        //         form={"SelectedCustomer"}
        //         placeholder={"Customer name"}
        //         disabled={true}
        //         defaultValue={selectedCustomer.name}
        //         {...{
        //           reserveValueOnUnmount: true,
        //           revertValueOnMount: false
        //         }}
        //       />

        //       <div className={styles.closeBtnSelectedCustomer}>
        //         <Image src={IconClose} onClick={onRemoveSelectedCustomer} />
        //       </div>
        //     </Form>

        //     <div className={styles.customerProgress}>
        //       <p>{selectedCustomerPercent}% Complete</p>
        //       <div className={styles.progressBar}>
        //         <Progress
        //           value={selectedCustomerPercent}
        //           colors={["#1890ff", "#1890ff"]}
        //         />
        //       </div>

        //       {/* Waiting the desktop version of our “Edit customer” screen. */}
        //       {/* {selectedCustomerPercent < 100 ? (
        //         <p onClick={onGoToEditCustomer} className={styles.linkText}>
        //           Update customer’s profile to get paid faster!
        //         </p>
        //       ) : (
        //         <p onClick={onGoToCustomerDetail} className={styles.linkText}>
        //           View or edit customer’s profile.
        //         </p>
        //       )} */}
        //     </div>
        //   </div>
        // )
      }
    </div>
  );
};

export default SelectCustomer;
