import React from "react";
import Drawer from "antd/es/drawer";
import Modal from "antd-mobile/es/modal";
import { isDesktop } from "react-device-detect";

import styles from "./Drawer.module.scss";
import Flex from "antd-mobile/es/flex";
import Image from "../Image";
import ArrowBottomIcon from "src/bepaid/assets/images/common/icon_big_arrow_bottom.svg";
import IconClose from "src/bepaid/assets/images/common/icon_close.svg";
import classNames from "classnames";

interface IProps {
  width?: string;
  height?: string;
  showCloseIcon?: boolean;
  roundedBorder?: boolean;
  onClose?: (d?: any) => void;
  visible?: boolean;

  [key: string]: any;
}

const Component = (props: IProps) => {
  const {
    children,
    width = "100%",
    height = "100%",
    placement = "right",
    showCloseIcon = true,
    roundedBorder = true,
    visible = false,
    drawerDesktopSmall = false,
    contentFullWidth = false,
    ...rest
  } = props;

  const renderCloseIcon = () => {
    if (!showCloseIcon) {
      return null;
    }
    if (isDesktop) {
      return (
        <Flex.Item
          className={classNames(styles.flexItem, styles.arrowClose, {
            [styles.closeDesktop]: isDesktop
          })}
        >
          <Image src={IconClose} onClick={rest.onClose} />
        </Flex.Item>
      );
    } else {
      return (
        <Flex.Item className={classNames(styles.flexItem, styles.arrowClose)}>
          <Image src={ArrowBottomIcon} onClick={rest.onClose} />
        </Flex.Item>
      );
    }
  };

  if (isDesktop) {
    // This is for force unmount child components on closing
    return visible ? (
      <Modal
        visible={visible}
        transparent={true}
        maskClosable={false}
        className={classNames({
          [styles.roundedBorder]: roundedBorder,
          [styles.drawerDesktop]: isDesktop,
          [styles.drawerDesktopSmall]: drawerDesktopSmall,
          [styles.contentFullWidth]: contentFullWidth
        })}
        onClose={rest.onClose}
      >
        <Flex
          justify="center"
          align="center"
          direction="column"
          className={styles.modalWrapper}
        >
          {renderCloseIcon()}
          <Flex.Item className={styles.content}>{children}</Flex.Item>
        </Flex>
      </Modal>
    ) : (
      <></>
    );
  }

  return visible ? (
    <Drawer
      visible={visible}
      width={width}
      height={height}
      placement={placement}
      className={classNames({
        [styles.roundedBorder]: roundedBorder,
        [styles.drawerDesktop]: isDesktop
      })}
      destroyOnClose={true}
      {...rest}
    >
      <Flex
        justify="center"
        align="center"
        direction="column"
        className={styles.wrapper}
      >
        {renderCloseIcon()}
        <Flex.Item className={styles.content}>{children}</Flex.Item>
      </Flex>
    </Drawer>
  ) : (
    <></>
  );
};

export default Component;
