import React from "react";
import _get from "lodash-es/get";
import * as uuidv1 from "uuid/v1";
import classnames from "classnames";
import Typography from "antd/es/typography";

import ShortCurrency from "src/ipm-shared/components/ShortCurrency";
import ShortCardInfo from "src/ipm-shared/components/ShortCardInfo";
import DateUtils from "src/ipm-shared/Utils/Date";

import { PAYMENT_GROUP_STATUS_NAME } from "src/bepaid/pages/Filter/const";

import { PaymentStatusPill } from "src/bepaid/shared/modals/PaymentDetailModal/components/PaymentStatusPill";
import paymentUtil from "src/bepaid/shared/modals/PaymentDetailModal/components/PaymentDetail/utils/payment";

import styles from "./Schedule.module.scss";

const ContentCollapsed = ({ selectedScheduleDetail }: any) => {
  const paymentSetting = _get(selectedScheduleDetail, "paymentSetting");
  const upcomingPayment = _get(
    selectedScheduleDetail,
    "upcomingPayment.payments",
    []
  );
  let paymentAmount = paymentSetting.paymentAmount;
  let paymentFees = paymentSetting.paymentFees;
  let paymentGstFees = paymentSetting.paymentGstFees;
  let paymentBankPayoutFees = paymentSetting.paymentBankPayoutFees;
  let paymentMinimumTransactionFees =
    paymentSetting.paymentMinimumTransactionFees;
  let paymentTotal = paymentSetting.paymentTotal;
  let payees = _get(paymentSetting, "payees", []);
  let cards = _get(paymentSetting, "cards", []);
  if (upcomingPayment.length > 0) {
    let minPayment = upcomingPayment.filter(
      (p: any) => p.paymentStatusId === 2
    )[0];
    if (minPayment) {
      paymentAmount = minPayment.paymentAmount;
      paymentFees = minPayment.paymentFee;
      paymentGstFees = minPayment.paymentGstFee;
      paymentBankPayoutFees = minPayment.paymentBankPayoutFee;
      paymentMinimumTransactionFees = minPayment.paymentMinimumTransactionFee;
      paymentTotal = minPayment.paymentTotal;
      payees = _get(minPayment, "payees", []);
      cards = _get(minPayment, "cards", []);
    }
  }

  const currencyId = _get(paymentSetting, "currencyId");
  const scheduleStartDate = _get(paymentSetting, "scheduleStartDate");
  const scheduleEndDate = _get(paymentSetting, "scheduleEndDate");
  return (
    <>
      <div className={classnames(styles.row, styles.firstRow)}>
        <span>{paymentUtil.getPaymentPurpose(paymentSetting)}</span>
        <PaymentStatusPill
          status={
            _get(selectedScheduleDetail, "isActive", false)
              ? PAYMENT_GROUP_STATUS_NAME.Active
              : PAYMENT_GROUP_STATUS_NAME.Inactive
          }
        />
      </div>
      <div className={classnames(styles.row, styles.verticalRow)}>
        {payees.map((p: any) => (
          <div
            key={uuidv1()}
            className={classnames(
              styles.row,
              styles.collapsedSectionRecipientName
            )}
          >
            <Typography.Text strong={true} ellipsis={true}>
              {p.name}
            </Typography.Text>
            <span>
              <ShortCurrency value={p.currentAmount} currencyId={currencyId} />
            </span>
          </div>
        ))}
      </div>
      <hr className={styles.hr1px} />
      <div className={styles.row}>
        <span>Subtotal</span>
        <span>
          <ShortCurrency value={paymentAmount} currencyId={currencyId} />
        </span>
      </div>
      <hr className={styles.hr1px} />
      <div className={styles.row}>
        <span>Fee</span>
        <span>
          <ShortCurrency value={paymentFees} currencyId={currencyId} />
        </span>
      </div>
      {paymentGstFees && (
        <div className={styles.row}>
          <span>GST (10%)</span>
          <span>
            <ShortCurrency value={paymentGstFees} currencyId={currencyId} />
          </span>
        </div>
      )}
      {paymentBankPayoutFees && (
        <div className={styles.row}>
          <span>Fund Safeguarding</span>
          <span>
            <ShortCurrency
              value={paymentBankPayoutFees}
              currencyId={currencyId}
            />
          </span>
        </div>
      )}
      {paymentMinimumTransactionFees && (
        <div className={styles.row}>
          <span>Minimum Transaction Fee</span>
          <span>
            <ShortCurrency
              value={paymentMinimumTransactionFees}
              currencyId={currencyId}
            />
          </span>
        </div>
      )}
      <hr className={styles.hr2px} />
      <div className={styles.row}>
        <b>Total Amount</b>
        <b>
          <ShortCurrency value={paymentTotal} currencyId={currencyId} />
        </b>
      </div>
      <hr className={styles.hr2px} />
      <div className={styles.rowsContainer}>
        <div className={styles.row}>
          <span>Card to be Charged</span>
          <div>
            {cards.map((c: any, idx: number) => {
              return (
                <div key={idx} className={styles.cardIconContainer}>
                  <ShortCardInfo cardId={c.id} />
                </div>
              );
            })}
          </div>
        </div>
        <div className={styles.row}>
          <span>First Payout Date</span>
          <span>
            {DateUtils.formatLocaleDate(scheduleStartDate, {
              day: "numeric",
              month: "short",
              year: "numeric"
            })}
          </span>
        </div>
        <div className={styles.row}>
          <span>Schedule End Date</span>
          <span>
            {DateUtils.formatLocaleDate(scheduleEndDate, {
              day: "numeric",
              month: "short",
              year: "numeric"
            })}
          </span>
        </div>
      </div>
    </>
  );
};

export default ContentCollapsed;
