import React from "react";

import styles from "./Line.module.scss";

const Line = (props: any) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.lineLeft} />
      {props.children && <div className={styles.lineMid}>{props.children}</div>}
      <div className={styles.lineRight} />
    </div>
  );
};

export default Line;
