import React from "react";
import classNames from "classnames";
import Pagination from "antd/es/pagination";

import NextIcon from "src/bepaid/assets/images/common/desktop/next_black.svg";
import PrevIcon from "src/bepaid/assets/images/common/desktop/previous_black.svg";

import Image from "../Image";

import styles from "./Pagination.module.scss";

const Component = (props: any) => {
  const {
    offset = 0,
    pageCount = 10,
    total = 0,
    onChange = null,
    simple = false,
    defaultMode = false,
    isDisableToggle = false,
    customClassname = null,
    ...rest
  } = props;

  // @ts-ignore
  const itemRender = (page, type, originalElement) => {
    if (!defaultMode) {
      if (type === "prev") {
        return <Image src={PrevIcon} />;
      }
      if (type === "next") {
        return <Image src={NextIcon} />;
      }
    }

    return originalElement;
  };

  const handleOnChange = (page: number) => {
    const responseOffset = (page - 1) * pageCount;
    if (onChange) {
      onChange(responseOffset);
    }
  };

  return (
    <div
      className={classNames(styles.wrapper, customClassname, {
        [styles.simpleMode]: simple,
        [styles.customNavigation]: !!itemRender && !defaultMode,
        [styles.defaultMode]: defaultMode,
        [styles.isDisableToggle]: isDisableToggle
      })}
    >
      <Pagination
        current={Math.floor(offset / pageCount) + 1}
        pageSize={pageCount}
        total={total}
        onChange={handleOnChange}
        itemRender={itemRender}
        simple={simple}
        {...rest}
      />
    </div>
  );
};

export default Component;
