/* tslint:disable:object-literal-sort-keys */
import styles from "./CardDetails.module.scss";

import React from "react";
import format from "date-fns/format";
import { BrowserView, MobileView, isDesktop } from "react-device-detect";

import _get from "lodash-es/get";
import _find from "lodash-es/find";

import Row from "antd/es/row";
import Col from "antd/es/col";
import Typography from "antd/es/typography";

import ShortCurrency from "src/ipm-shared/components/ShortCurrency";

import Card from "src/bepaid/components/Card";
import Image from "src/bepaid/components/Image";

import ShortCardInfo from "src/ipm-shared/components/ShortCardInfo";
import classNames from "classnames";
import CountryUtil from "src/ipm-shared/Utils/Country";
import { FLAGS } from "src/ipm-shared/Utils/Country";
import useQuery from "src/bepaid/hooks/useQuery";

// import styles from "../../Payments.module.scss";
import ImageUtils from "src/ipm-shared/Utils/Images";

const CardDetails = (props: any) => {
  const {
    title,
    payoutDate,
    description,
    amount,
    cardId,
    onClick,
    countryId,
    payeeAmount,
    hasWallexPayment,
    currencyId,
    paymentMethodDetails,
    isCryptoPaymentMethod,
    isShowSchedule
  } = props;

  const qs = useQuery();
  const isIndividual = qs.paymentType === "individual" && !isShowSchedule;

  const renderItemRight = () => (
    <>
      {isIndividual && (
        <Col span={6} className={styles.middleFlex}>
          <Row>
            <Col span={24}>
              <Typography.Text className={styles.title}>
                Payout Date
              </Typography.Text>
            </Col>
            <Col span={24}>
              <Typography.Text ellipsis={true} className={styles.description}>
                {format(payoutDate, "D MMMM YYYY")}
              </Typography.Text>
            </Col>
          </Row>
        </Col>
      )}
      {hasWallexPayment && (
        <Col span={3} className={styles.leftFlex}>
          <Row>
            <Col span={24}>
              <Typography.Text className={styles.title}>
                Country
              </Typography.Text>
            </Col>
            <Col span={24}>
              <Image
                src={FLAGS[CountryUtil.getCountryCodeFromCountryId(countryId)]}
              />
            </Col>
          </Row>
        </Col>
      )}
      <Col span={3} className={styles.rightFlex}>
        <Row>
          <Col span={24}>
            {isCryptoPaymentMethod ? (
              <div className={classNames(styles.cryptoIconContainer)}>
                <span>
                  <Image
                    src={ImageUtils.getCryptoTypeIcon(paymentMethodDetails?.id)}
                  />
                  <span className={styles.label}>
                    {"-"}
                    {paymentMethodDetails?.label}
                  </span>
                </span>
              </div>
            ) : (
              <div className={styles.cardIconContainer}>
                <ShortCardInfo cardId={cardId} />
              </div>
            )}
          </Col>
          <Col span={24}>
            <Typography.Text className={styles.amount}>
              <ShortCurrency value={amount} currencyId={currencyId} />
            </Typography.Text>
          </Col>
        </Row>
      </Col>
    </>
  );

  return (
    <Card
      className={classNames(styles.wrapper, {
        [styles.desktop]: isDesktop
      })}
    >
      <BrowserView>
        <Row align="middle" justify="space-between" onClick={onClick}>
          <Col
            span={isIndividual ? 9 : 12}
            className={classNames({ [styles.alignJustify]: isIndividual })}
          >
            <Row>
              <Col span={24}>
                <Typography.Text className={styles.title}>
                  {title}
                </Typography.Text>
              </Col>
              <Col span={24} className={styles.descriptionWrapper}>
                <Typography.Text ellipsis={true} className={styles.description}>
                  {description}
                </Typography.Text>
                {!!payeeAmount && (
                  <div className={styles.payeeAmount}>+{payeeAmount}</div>
                )}
              </Col>
            </Row>
          </Col>
          {isIndividual ? (
            <Row
              align="middle"
              justify="space-between"
              className={styles.itemLeft}
            >
              {renderItemRight()}
            </Row>
          ) : (
            renderItemRight()
          )}
        </Row>
      </BrowserView>
      <MobileView>
        <>
          {hasWallexPayment && (
            <Row align="middle" justify="space-between" onClick={onClick}>
              <Col span={9} className={styles.leftFlex}>
                <Row>
                  <Col span={24}>
                    <Image
                      src={
                        FLAGS[
                          CountryUtil.getCountryCodeFromCountryId(countryId)
                        ]
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
          <Row align="middle" justify="space-between" onClick={onClick}>
            <Col
              span={isIndividual ? 9 : 15}
              className={classNames(styles.alignJustify, {
                [styles.notIndividual]: !!payeeAmount && !isIndividual
              })}
            >
              <div
                className={classNames({
                  [styles.individual]: isIndividual,
                  [styles.notIndividual]: !isIndividual
                })}
              >
                <Typography.Text className={styles.title}>
                  {title}:
                </Typography.Text>{" "}
                <Typography.Text ellipsis={true} className={styles.description}>
                  {description}
                </Typography.Text>
                {!!payeeAmount && isIndividual && (
                  <div className={styles.payeeAmount}>+{payeeAmount}</div>
                )}
              </div>
              {isIndividual ? (
                <div className={styles.individual}>
                  <Typography.Text
                    className={classNames(styles.title, styles.lastTitle)}
                  >
                    Payout Date:
                  </Typography.Text>
                  <Typography.Text
                    ellipsis={true}
                    className={styles.description}
                  >
                    {format(payoutDate, "D MMMM YYYY")}
                  </Typography.Text>
                </div>
              ) : (
                !!payeeAmount && (
                  <div className={styles.payeeAmount}>+{payeeAmount}</div>
                )
              )}
            </Col>
            <Col span={9} className={styles.rightFlex}>
              <Row>
                <Col span={24}>
                  {isCryptoPaymentMethod ? (
                    <div className={styles.cryptoIconContainer}>
                      <span>
                        <Image
                          src={ImageUtils.getCryptoTypeIcon(
                            paymentMethodDetails?.id
                          )}
                        />
                        <span className={styles.label}>
                          {"-"}
                          {paymentMethodDetails?.label}
                        </span>
                      </span>
                    </div>
                  ) : (
                    <div className={styles.cardIconContainer}>
                      <ShortCardInfo cardId={cardId} />
                    </div>
                  )}
                </Col>
                <Col span={24}>
                  <Typography.Text className={styles.amount}>
                    <ShortCurrency value={amount} currencyId={currencyId} />
                  </Typography.Text>
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      </MobileView>
    </Card>
  );
};

export default CardDetails;
