/**
 * Actions
 *
 * ActionCreators are like the "pub" of pubsub. It's just broadcasting an event (action).
 * So, don't couple it with a specific reducer. Let ANY reducer listen to
 * an action.
 *
 * `typesafe-actions` has a nice utility function so we don't need to define action types as
 * constants. Use it together with `getType(type)` in the reducers.
 */
import { createAction } from "typesafe-actions";

import { LoadingFunc } from "src/ipm-shared/Utils/ReduxSagaEffects";

import {
  CustomerFee,
  PaymentCollectionRequestType,
  Incentive
} from "./reducers";
import { CardInfo } from "../Card/types";
type cbType = (err?: any, data?: any) => void;

export * from "src/ipm-shared/store/model/actions";

export {
  setControl,
  parseServerErrors
} from "src/ipm-shared/components/Form/actions";

export const fetchPaymentCollectionRequest = createAction(
  "paymentCollectionRequest/FETCH",
  resolve => (token: string) => resolve({ token })
);

export const setPaymentCollectionRequest = createAction(
  "paymentCollectionRequest/SET_REQUEST",
  resolve => (args: {
    isFetching: boolean;
    payment: PaymentCollectionRequestType;
    acquirerId: number;
  }) => resolve(args)
);

export const fetchPaymentCollectionFees = createAction(
  "paymentCollectionRequest/GET_FEES",
  resolve => (cardId: number) => resolve({ cardId })
);

export const setPaymentCollectionFees = createAction(
  "paymentCollectionRequest/SET_FEES",
  resolve => (args: {
    nationFee?: number;
    overseaFee: number;
    cryptoFee?: number;
    customerFee: CustomerFee[];
  }) => resolve(args)
);

export const chargePaymentCollection = createAction(
  "paymentCollectionRequest/CHARGE",
  resolve => (
    args: {
      cardInfo?: CardInfo;
      cardholderName?: string;
    },
    loadingFunc?: LoadingFunc
  ) => resolve({ ...args, loadingFunc })
);

export const createTemplateCard = createAction(
  "paymentCollectionRequest/SET_TEMPLATE_CARD",
  resolve => (args: {
    cardToken?: string;
    paymentToken?: string;
    cb?: (data: any) => void;
  }) => resolve(args)
);

export const setCardCode = createAction(
  "paymentCollectionRequest/SET_CARD_CODE",
  resolve => (cardCode: string) => resolve({ cardCode })
);

export const setCardInfo = createAction(
  "paymentCollectionRequest/SET_CARD_COUNTRY_CODE",
  resolve => (info: CardInfo) => resolve({ info })
);

export const resendPaymentRequest = createAction(
  "paymentCollectionRequest/RESEND",
  resolve => (id: number, cb: () => void, loadingFunc?: LoadingFunc) =>
    resolve({ id, cb, loadingFunc })
);

export const remindPaymentRequest = createAction(
  "paymentCollectionRequest/REMIND",
  resolve => (id: number, cb: (err?: any) => void) => resolve({ id, cb })
);

export const cancelPaymentRequest = createAction(
  "paymentCollections/CANCEL_PAYMENT_REQUEST",
  resolve => (paymentId: number, cb?: (err?: any) => void) =>
    resolve({ paymentId, cb })
);

export const sendPaymentRequest = createAction(
  "paymentCollections/SEND_PAYMENT_REQUEST",
  resolve => (
    isValidate?: boolean,
    requiredVerificationFields?: any,
    draft?: boolean,
    step?: number,
    cb?: (err?: any, data?: any) => void,
    loadingFunc?: LoadingFunc
  ) =>
    resolve({
      isValidate,
      requiredVerificationFields,
      draft,
      cb,
      step,
      loadingFunc
    })
);

// REQUEST BY INVOICE CREATION
export const submitCollectionInvoice = createAction(
  "paymentCollections/SUBMIT_INVOICE_VALIDATE",
  resolve => (
    isValidate: boolean = false,
    requiredVerificationFields?: any,
    draft?: boolean,
    step?: number,
    cb?: (err?: any, data?: any) => void,
    loadingFunc?: LoadingFunc
  ) =>
    resolve({
      isValidate,
      requiredVerificationFields,
      draft,
      cb,
      step,
      loadingFunc
    })
);

// SMS REQUEST
export const submitSmsRequest = createAction(
  "paymentCollections/SUBMIT_SMS_REQUEST"
);

export const getEstimateRate = createAction(
  "paymentCollection/GET_ESTIMATE_FEE",
  resolve => (token: string) => resolve({ token })
);

export const createPublicStripeCardIntent = createAction(
  "cards/PUBLIC_STRIPE_CARD_INTENT_CREATE",
  resolve => (paymentToken: string, cb: (clientSecret: string) => void) =>
    resolve({ cb, paymentToken })
);

export const setPaymentCollectionIncentives = createAction(
  "paymentCollectionRequest/SET_INCENTIVES",
  resolve => (args: { incentives: Incentive }) => resolve(args)
);

export const setPaymentCollectionMethodType = createAction(
  "paymentCollectionRequest/SET_PAYMENT_COLLECTION_METHOD_TYPE",
  resolve => (methodType: string) => resolve({ methodType })
);

export const selectPaymentCollectionMethod = createAction(
  "paymentCollectionRequest/SELECT_PAYMENT_COLLECTION_METHOD",
  resolve => (
    id: number,
    paymentToken?: string,
    options?: { cb: cbType },
    loadingFunc?: LoadingFunc
  ) => resolve({ id, paymentToken, options, loadingFuncCus: loadingFunc })
);

export const setPaymentCollectionMethodId = createAction(
  "paymentCollectionRequest/SET_SELECTED_PAYMENT_COLLECTION_METHOD",
  resolve => (args: { id: number }) => resolve(args)
);
