import { ActionType } from "typesafe-actions";
import { put } from "redux-saga/effects";
import * as actions from "./actions";
import { reTryTakeLatest } from "src/ipm-shared/Utils/ReduxSagaEffects";

const watchedSagas = [
  reTryTakeLatest(actions.fetchCardBrands, handleFetchCardBrands)
];
export default watchedSagas;
export function* handleFetchCardBrands(
  action: ActionType<typeof actions.fetchCardBrands>
) {
  yield put(
    actions.setCardBrands({
      cardBrands: [
        { id: 1, name: "Unknown", imgUrl: "" },
        { id: 2, name: "Visa", imgUrl: "" },
        { id: 3, name: "MasterCard", imgUrl: "" },
        { id: 4, name: "Amex", imgUrl: "" },
        { id: 5, name: "UnionPay", imgUrl: "" }
      ],
      isFetching: false
    })
  );
}
