import React from "react";
import { isMobile } from "react-device-detect";
import Image from "src/bepaid/components/Image";
import BinanceLogo from "src/bepaid/assets/images/dashboard/carousel/binance.svg";
import Binance1 from "src/bepaid/assets/images/dashboard/carousel/binance_1.svg";
import Binance2 from "src/bepaid/assets/images/dashboard/carousel/binance_2.png";
import classNames from "classnames";
import styles from "./BinancePay.module.scss";

const BinancePay = () => {
  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.isMobile]: isMobile
      })}
    >
      <div className={styles.topLayer} />
      <div className={styles.left}>
        <div className={styles.binanceLogo}>
          <Image src={BinanceLogo} width={140} />
        </div>
        <div className={styles.bottomLeft}>
          <p className={styles.description}>
            Pay virtually <b>any expense</b>
          </p>
          <p className={styles.description}>directly from your</p>
          <p className={styles.description}>Binance wallet</p>
        </div>
        <div>
          <a
            className={styles.learnMore}
            target="_blank"
            href={
              "http://help.ipaymy.com/en/articles/8340024-how-to-make-payments-with-binance-pay"
            }
          >
            Learn more.
          </a>
        </div>
      </div>
      <div className={styles.right}>
        <Image src={Binance2} height={206} />
      </div>
      {!isMobile && (
        <div className={styles.phone}>
          <Image src={Binance1} />
        </div>
      )}

      {/* <div className={styles.binanceLogo}>
        <Image src={BinanceLogo} width={140} />
      </div>
      <div className={styles.leftCenter}>
        <p className={styles.description}>Pay virtually <b>any expense</b></p>
        <p className={styles.description}>directly from your</p>
        <p className={styles.description}>Binance wallet</p>
      </div>
      <div className={styles.bottomLeft}>
        <a
          className={styles.learnMore}
          target="_blank"
          href={
            "http://help.ipaymy.com/en/articles/8014167-how-do-i-get-the-next-day-s-payout-in-singapore"
          }
        >
          Learn more.
        </a>
      </div> */}
    </div>
  );
};

export default BinancePay;
