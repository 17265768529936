import * as React from "react";
import { connect } from "react-redux";
import * as selectors from "../selectors";
import { RootState } from "src/ipm-shared/store/model/reducers";
import { ControlType, ControlValueType } from "../types";

const mapStateToProps = (
  state: RootState,
  props: {
    name: string;
  }
): {
  control: ControlType;
} => ({
  control: selectors.getControl(state, props.name)
});

type IProps = ReturnType<typeof mapStateToProps> & {
  className?: string;
  name: string;
  children?: (props: ControlValueType) => React.ReactNode;
};

/**
 * This is one of common controls in the entire app.
 * Use this when you want to show Form errors.
 */
class ClonedValue extends React.Component<IProps, any> {
  public render() {
    const { control } = this.props;

    if (this.props.children) {
      return this.props.children(control.value);
    } else {
      return control.value;
    }
  }
}

export default connect(mapStateToProps)(ClonedValue);
