import React, { useEffect, useState } from "react";
import { BrowserView, MobileView, isMobile } from "react-device-detect";
import classNames from "classnames";
import _get from "lodash-es/get";
import _uniq from "lodash-es/uniq";

import { history } from "src/ipm-shared/store";
import { useMakePaymentUrlParams } from "src/bepaid/pages/Payments/hooks";

import DesktopModalLayout from "src/bepaid/components/Modal/components/DesktopModalLayout";
import MainLayout from "src/bepaid/layouts/MainLayout";
import useQuery from "src/bepaid/hooks/useQuery";
import ROUTES from "src/bepaid/routes";
import Button from "src/bepaid/components/Form/controls/Button";

import EllipsisOutlined from "@ant-design/icons/EllipsisOutlined";
import PaymentDetail from "./components/PaymentDetail";
import paymentUtil from "./components/PaymentDetail/utils/payment";

import styles from "./PaymentDetailModal.module.scss";
import PurposeUtil from "src/ipm-shared/Utils/Purpose";
import { PURPOSE } from "src/ipm-shared/store/model/Payee/const";
import CountryUtil from "src/ipm-shared/Utils/Country";
import { invokeMessage } from "src/bepaid/components/Message";
import FeatureGate from "src/ipm-shared/components/FeatureGating/FeatureGate";

function PaymentDetailModal(props: any) {
  const {
    selectPayment,
    extraInfo,
    selectedPayment,
    resetSelectedPayment,
    updateMetadataPayment
  } = props;
  const [loadingPayment, setLoadingPayment] = useState(false);
  const [loading, setLoading] = useState(false);
  const { replaceRouteWithParams } = useMakePaymentUrlParams();
  const isInternationalPayment = _get(
    selectedPayment,
    "isInternationalPayment",
    false
  );
  const isFavourite = _get(selectedPayment, "metadata.is_favourite", false);

  const entity = paymentUtil.getPaymentEntity(selectedPayment);

  const qs = useQuery();

  const fetchPaymentDetail = () =>
    selectPayment(
      extraInfo.paymentId,
      () => {},
      {
        idType: qs.idType || "regNo",
        withPaymentActivity: false
      },
      setLoadingPayment
    );
  const [actionDrawer, openActionDrawer] = React.useState({
    value: 0,
    visibleDrawer: false,
    visibleOnePaymentDrawer: false
  });

  useEffect(() => {
    fetchPaymentDetail();
  }, [extraInfo.paymentId]);

  useEffect(() => {
    if (!qs.idType) {
      history.push(ROUTES.PAYMENTS);
    }
  }, []);

  const closeModal = () => {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.delete("idType");

    if (qs.is_schedule === "y") {
      const scheduleUrl = `${ROUTES.PAYMENT_SCHEDULE_DETAILS.replace(
        ":id",
        selectedPayment.scheduleId
      )}`;
      history.push({
        pathname: scheduleUrl,
        search: "?" + urlParams.toString()
      });
      return;
    }
    const url = ROUTES.PAYMENTS + "/?" + urlParams.toString();
    history.push(url, {
      prevRoute: ROUTES.PAYMENT_DETAILS
    });
    resetSelectedPayment();
  };

  const renderContent = () => {
    return (
      <PaymentDetail
        loading={loadingPayment}
        {...props.extraInfo}
        actionDrawer={actionDrawer}
        openActionDrawer={openActionDrawer}
      />
    );
  };

  const paymentCurrent = _get(
    selectedPayment,
    "schedulePayments.payments",
    []
  ).filter((p: any) => p.id === selectedPayment.id)[0];

  const onMoreAction = () => {
    openActionDrawer({
      ...actionDrawer,
      value: selectedPayment.id,
      visibleDrawer: false,
      visibleOnePaymentDrawer: !actionDrawer.visibleOnePaymentDrawer
    });
  };

  const shouldShowMoreIcon =
    _get(selectedPayment, "paymentStatusId") === 2 &&
    (paymentCurrent.editable ||
      _get(selectedPayment, "scheduleFrequency") === "00:00:00");

  const isOnScreeningPayment = _get(selectedPayment, "isOnScreening");

  const renderRightIcon = () => (
    <EllipsisOutlined
      className={styles.iconMoreAction}
      onClick={onMoreAction}
    />
  );

  const goToRoute = (route: string) => {
    localStorage.setItem(
      "historyState",
      JSON.stringify({
        previousPath: window.location.pathname + window.location.search
      })
    );

    history.push(route);
  };

  const handleRedirect = () => {
    if (!isInternationalPayment) {
      if (entity === "supplier") {
        goToRoute(
          replaceRouteWithParams(ROUTES.PAYMENTS_CREATION, {
            newPaymentType: "domestic",
            newEntity: "supplier",
            newAction: "invoice_details"
          })
        );
        return;
      }
      if (entity === "tax") {
        goToRoute(
          replaceRouteWithParams(ROUTES.PAYMENTS_CREATION, {
            newPaymentType: "domestic",
            newEntity: "tax",
            newAction: "payment_details"
          })
        );
        return;
      } else {
        goToRoute(
          replaceRouteWithParams(ROUTES.PAYMENTS_CREATION, {
            newPaymentType: "domestic",
            newEntity: entity,
            newAction: "schedule"
          })
        );
        return;
      }
    } else {
      const countryId: number = _get(selectedPayment, "payees[0].countryId");
      const currentCountryCode = CountryUtil.getCountryCodeFromCountryId(
        countryId
      );
      props.setPaymentCountryCode(currentCountryCode);
      props.setPaymentCurrencyCode(
        CountryUtil.getCurrencyCode(currentCountryCode)
      );

      if (entity === "supplier") {
        goToRoute(
          replaceRouteWithParams(ROUTES.PAYMENTS_CREATION, {
            newPaymentType: "international",
            newEntity: "supplier",
            newAction: "invoice_details"
          })
        );
        return;
      }
      if (entity === "self_transfer") {
        goToRoute(
          replaceRouteWithParams(ROUTES.PAYMENTS_CREATION, {
            newPaymentType: "international",
            newEntity: "self_transfer",
            newAction: "bank_account_details"
          })
        );
        return;
      }
      if (entity === "landlord") {
        goToRoute(
          replaceRouteWithParams(ROUTES.PAYMENTS_CREATION, {
            newPaymentType: "international",
            newEntity: "landlord",
            newAction: "schedule"
          })
        );
        return;
      }
    }
  };

  const onSubmit = () => {
    const purpose = PurposeUtil.toString(
      selectedPayment.purposeId as number
    ) as PURPOSE;
    props.fetchPayees({
      type: purpose,
      noRedirect: true,
      cb: (data?: any) => {
        let declinedPayee: any;

        if (entity !== "tax") {
          const ids = _uniq(
            _get(selectedPayment, "payees", []).map(
              (payment: any) => payment.id
            )
          );

          declinedPayee = data.filter(
            (payee: any) => payee.review_status === "D"
          );

          declinedPayee = declinedPayee.filter((payee: any) => {
            return ids.some(id => {
              return payee.id === id;
            });
          });

          props.selectPayees({
            ids: ids,
            purpose,
            removedIds: "all"
          });
        } else {
          const taxes = data.filter(
            (payee: any) => payee.ipaymy_event === "TAX2019"
          );

          declinedPayee = taxes.filter(
            (payee: any) => payee.review_status === "D"
          );

          props.selectPayees({
            ids: taxes.map((payee: any) => payee.id),
            purpose,
            removedIds: "all"
          });
        }

        if (declinedPayee.length > 0) {
          invokeMessage(
            "error",
            "This party has not been approved due to external and internal regulatory requirements."
          );
          return;
        }

        handleRedirect();
      },
      loadingFunc: setLoading,
      isWallex: isInternationalPayment
    });
  };

  const handleFavouriteAction = () => {
    updateMetadataPayment(
      _get(selectedPayment, "id", ""),
      {
        is_favourite: !isFavourite
      },
      fetchPaymentDetail
    );
  };

  const renderBtnSubmit = () => {
    return (
      <>
        {!isOnScreeningPayment && (
          <FeatureGate feature="NORMAL_PAYMENT">
            <Button
              id={"ipaymy_btn_pay_again"}
              wrapperClassName={styles.wrapperButton}
              onClick={onSubmit}
              type="primary"
              htmlType="button"
              loading={loading}
            >
              {loading ? "Paying again..." : "Pay again"}
            </Button>
          </FeatureGate>
        )}
      </>
    );
  };

  return (
    <>
      <BrowserView>
        <DesktopModalLayout
          title={"Payment details"}
          onSubmit={onSubmit}
          maxWidth450={true}
          onClose={closeModal}
          onMoreAction={shouldShowMoreIcon ? onMoreAction : undefined}
          onFavouriteAction={handleFavouriteAction}
          isFavourite={isFavourite}
          btnSubmit={renderBtnSubmit()}
        >
          <div className={styles.wrapper}>{renderContent()}</div>
        </DesktopModalLayout>
      </BrowserView>
      <MobileView>
        <MainLayout
          header={{
            title: "Payment details",
            onClose: closeModal,
            hideMenu: true,
            rightIcons: shouldShowMoreIcon ? renderRightIcon() : null,
            onFavouriteAction: handleFavouriteAction,
            isFavourite: isFavourite
          }}
          footer={renderBtnSubmit()}
          usingModal={true}
        >
          <div
            className={classNames(styles.wrapper, {
              [styles.wrapperMobile]: isMobile
            })}
          >
            {renderContent()}
          </div>
        </MainLayout>
      </MobileView>
    </>
  );
}

export default PaymentDetailModal;
