/**
 * Actions
 *
 * ActionCreators are like the "pub" of pubsub. It's just broadcasting an event (action).
 * So, don't couple it with a specific reducer. Let ANY reducer listen to
 * an action.
 *
 * `typesafe-actions` has a nice utility function so we don't need to define action types as
 * constants. Use it together with `getType(type)` in the reducers.
 */
import { createBrowserHistory } from "history";
import { createAction } from "typesafe-actions";

export * from "src/ipm-shared/store/model/actions";
export * from "src/ipm-shared/store/model/Auth/actions";
export * from "src/ipm-shared/store/model/AccountProfile/actions";
export * from "src/ipm-shared/store/model/CollectedAccount/actions";
export * from "src/ipm-shared/store/model/Company/actions";
export * from "src/ipm-shared/store/model/Customer/actions";
export * from "src/ipm-shared/store/model/Payment/actions";
export * from "src/ipm-shared/store/model/PaymentCollectionRequest/actions";
export * from "src/ipm-shared/store/model/Payee/actions";
export * from "src/ipm-shared/store/model/OnBoarding/actions";
export * from "src/ipm-shared/components/Form/actions";
export * from "src/ipm-shared/store/model/Country/actions";
export * from "src/ipm-shared/store/model/Currency/actions";

export * from "src/ipm-shared/store/model/PaymentRequest/actions";
export * from "src/ipm-shared/store/model/DateCondition/actions";
export * from "src/ipm-shared/store/model/Card/actions";

import { FirstTimeFetchData, PayFetchRecentActivities } from "./reducers";
import { LoadingFunc } from "../../../../ipm-shared/Utils/ReduxSagaEffects";

type cbType = (err?: any, data?: any) => void;

export const register = createAction(
  "fetch/REGISTER",
  resolve => (history: ReturnType<typeof createBrowserHistory>) =>
    resolve({ history })
);

// ---DASHBOARD STATISTICS---
export const getDashboardStatistics = createAction(
  "fetch/GET_DASHBOARD_STATISTICS",
  resolve => (loadingFunc?: LoadingFunc) => resolve({ loadingFunc })
);
export const setDashboardStatistics = createAction(
  "fetch/SET_DASHBOARD_STATISTICS",
  resolve => (result: {
    numberActiveCustomers?: number;
    numberInvoiceSent?: number;
    totalPaymentsReceived?: number;
    numberOfActiveRecipients?: number;
    numberOfPaymentsMade?: number;
    totalPaymentsMade?: number;
    numberLandlords?: number;
    numberEmployees?: number;
    numberSuppliers?: number;
    numberBankAccounts?: number;
    numberOfRecurringPayments?: number;
    numberOfInProgressPayments?: number;
  }) => resolve({ result })
);

// ---INVOICE LIST---
export const getInvoiceList = createAction(
  "fetch/GET_INVOICE_LIST",
  resolve => (
    query?: { [key: string]: any },
    cb?: cbType,
    loadingFunc?: LoadingFunc,
    offset?: number,
    pageCount?: number
  ) => resolve({ query, cb, loadingFunc, offset, pageCount })
);
export const setInvoiceList = createAction(
  "fetch/SET_INVOICE_LIST",
  resolve => (result: any[], total: number) => resolve({ result, total })
);
export const setInvoiceListModal = createAction(
  "fetch/SET_INVOICE_LIST_MODAL",
  resolve => (result: any[]) => resolve({ result })
);

// ---INVOICE STATISTICS---
export const getInvoiceStatistics = createAction(
  "fetch/GET_INVOICE_STATISTICS",
  resolve => (cb?: cbType, loadingFunc?: LoadingFunc) =>
    resolve({ cb, loadingFunc })
);
export const setInvoiceStatistics = createAction(
  "fetch/SET_INVOICE_STATISTICS",
  resolve => (result: {
    numberInvoiceSent?: number;
    percentagePunctualInvoices?: number;
    totalPaymentsReceived?: number;
    totalNumberOfInvoices?: number;
  }) => resolve({ result })
);

// ---CUSTOMER LIST---
export const getCustomerList = createAction(
  "fetch/GET_CUSTOMER_LIST",
  resolve => (
    query?: { [key: string]: any },
    cb?: cbType,
    loadingFunc?: LoadingFunc,
    forceRefresh?: boolean
  ) => resolve({ query, cb, loadingFunc, forceRefresh })
);

export const getCustomerActivities = createAction(
  "fetch/GET_CUSTOMER_ACTIVITIES",
  resolve => (
    id: number,
    query?: { from: string; to: string },
    cb?: cbType,
    loadingFunc?: LoadingFunc
  ) => resolve({ id, query, cb, loadingFunc })
);

export const setCustomerList = createAction(
  "fetch/SET_CUSTOMER_LIST",
  resolve => (
    result: any[],
    options?: {
      forceRefresh?: boolean;
    }
  ) => resolve({ result, options })
);

// ---CUSTOMER STATISTICS---
export const getCustomerStatistics = createAction(
  "fetch/GET_CUSTOMER_STATISTICS",
  resolve => (cb?: cbType, loadingFunc?: LoadingFunc) =>
    resolve({ cb, loadingFunc })
);
export const setCustomerStatistics = createAction(
  "fetch/SET_CUSTOMER_STATISTICS",
  resolve => (result: {
    numberActiveCustomers?: number;
    numberNewCustomers?: number;
    totalCustomers?: number;
    totalInvoices?: number;
  }) => resolve({ result })
);

// ---INVOICE INSIGHTS---
export const getInvoiceInsights = createAction(
  "fetch/GET_INVOICE_INSIGHTS",
  resolve => (duration: string, loadingFunc?: LoadingFunc) =>
    resolve({ duration, loadingFunc })
);
export const setInvoiceInsights = createAction(
  "fetch/SET_INVOICE_INSIGHTS",
  resolve => (result: {
    averageDaysPayment?: number;
    averageInvoiceAmount?: number;
    onTimeInvoices?: number;
    percentagePunctualInvoices?: number;
    totalCustomers?: number;
    totalInvoices?: number;
  }) => resolve({ result })
);

export const sendPaymentCollectionRequest = createAction(
  "fetch/SEND_PAYMENT_REQUEST",
  resolve => (args?: {}, cb?: cbType) => resolve({ args, cb })
);

export const selectInvoice = createAction(
  "fetch/SELECT_INVOICE",
  resolve => (id?: number, cb?: cbType) => resolve({ id, cb })
);

// ---INVOICE RECENT ACTIVITIES---
export const getInvoiceRecentActivities = createAction(
  "fetch/GET_INVOICE_RECENT_ACTIVITIES",
  resolve => (
    query: { offset: number; pageCount: number },
    cb?: cbType,
    loadingFunc?: LoadingFunc
  ) => resolve({ query, cb, loadingFunc })
);

export const getPaymentRecentActivities = createAction(
  "fetch/GET_PAYMENT_RECENT_ACTIVITIES",
  resolve => (
    query: { offset: number; pageCount: number },
    cb?: cbType,
    loadingFunc?: LoadingFunc
  ) => resolve({ query, cb, loadingFunc })
);

export const checkRemindDraftInvoice = createAction(
  "fetch/CHECK_REMIND_DRAFT_INVOICE",
  resolve => (cb?: cbType) => resolve({ cb })
);

export const checkKYC = createAction(
  "fetch/CHECK_KYC",
  resolve => (cb?: cbType) => resolve({ cb })
);

export const setPayFetchRecentActivities = createAction(
  "fetch/SET_PAY_FETCH_RECENT_ACTIVITIES",
  resolve => (data: PayFetchRecentActivities) => resolve(data)
);

// ---PAY INSIGHTS---
export const getPayInsights = createAction(
  "fetch/GET_PAY_INSIGHTS",
  resolve => (duration: string, loadingFunc?: LoadingFunc) =>
    resolve({ duration, loadingFunc })
);
export const setPayInsights = createAction(
  "fetch/SET_PAY_INSIGHTS",
  resolve => (result: {
    totalPaymentAmount?: number;
    averagePaymentAmount?: number;
    recurringPayment?: number;
    oneTimePayment?: number;
  }) => resolve({ result })
);

export const setFirstTimeFetchData = createAction(
  "fetch/SET_FIRST_TIME_FETCH_DATA",
  resolve => (data: FirstTimeFetchData) => resolve(data)
);

export const getExportPayments = createAction(
  "fetch/GET_EXPORT_PAYMENTS",
  resolve => (args: {
    query: {
      purposes: number;
      statuses: number;
      frequency: string;
      search_keyword: string;
      payout_date_key: string;
      payout_date_from: string;
      payout_date_to: string;
      charge_date_key: string;
      charge_date_from: string;
      charge_date_to: string;
    };
  }) => resolve(args)
);

export const setConfirmedMyInfo = createAction(
  "fetch/SET_CONFIRMED_MY_INFO_FETCH",
  resolve => (data: boolean) => resolve(data)
);
