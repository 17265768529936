const accessTokenProps = {
  account_country_id: 4,
  acquirer_id: 8,
  app_id: 16,
  dashboard_theme: 12,
  feature_gate: 14,
  has_company: 11,
  has_company_draft: 15,
  id: 1,
  is_business: 9,
  is_production: 10,
  payment_country_id: 5,
  payment_currency_id: 6,
  payment_paid_currency_id: 7,
  role_id: 13,
  sub_id: 3,
  uid: 2,
  version: 0
};
export { accessTokenProps };
