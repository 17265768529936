import React from "react";
// import _get from "lodash-es/get";
// import _findLast from "lodash-es/findLast";

import {
  PAYMENT_GROUP_STATUS,
  PAYMENT_GROUP_STATUS_LABEL,
  PAYMENT_STATUS
} from "src/ipm-shared/store/model/Payment/const";
import { INVOICE_ACTIVITIES } from "src/bepaid/pages/Dashboard/components/RecentActivities/const";

import StepsActivity, { Activity } from "src/bepaid/components/StepsActivity";

import styles from "./InvoiceActivity.module.scss";
import * as DateFns from "date-fns";

const InvoiceActivity = ({ activities = [], invoiceDetail = null }: any) => {
  const getStatus = () => {
    let statusId = invoiceDetail?.paymentStatusId;
    // Convert ON_HOLD, UNDER_REVIEW -> IN_PROGRESS
    if (
      statusId === PAYMENT_STATUS.ON_HOLD ||
      statusId === PAYMENT_STATUS.UNDER_REVIEW
    ) {
      statusId = PAYMENT_STATUS.IN_PROGRESS_2;
    }

    const matchingStatusKey = Object.keys(PAYMENT_GROUP_STATUS).find(
      (key: string) => {
        const arrMatching = PAYMENT_GROUP_STATUS[key];
        return arrMatching.includes(statusId);
      }
    );

    if (matchingStatusKey) {
      return PAYMENT_GROUP_STATUS_LABEL[matchingStatusKey];
    }

    return PAYMENT_GROUP_STATUS_LABEL.other;
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.paymentActivity}>
        <p>Payment activity</p>
        <button>{getStatus()}</button>
      </div>

      <StepsActivity
        type={"invoice"}
        activities={activities.map((act: any, idx: number) => {
          const isDone =
            act.activity === "invoice_due"
              ? DateFns.differenceInMinutes(new Date(), new Date(act.date)) >= 0
              : act.is_done;

          const activityLabel = INVOICE_ACTIVITIES[act.activity];
          const name = activityLabel
            ? isDone
              ? activityLabel.pastTenseLabel
              : activityLabel.label
            : act.activity.split("_").join(" ");

          return {
            date: act.date,
            description: name.charAt(0).toUpperCase() + name.slice(1),
            isDone,
            selected: act.activity === "invoice_due",
            selectedPosition:
              act.activity === "invoice_due" &&
              idx === 0 &&
              activities.length > 1
                ? "top"
                : idx === activities.length - 1
                ? "bottom"
                : "middle",
            type: act.type
          } as Activity;
        })}
      />
    </div>
  );
};

export default InvoiceActivity;
