/**
 * Actions
 *
 * ActionCreators are like the "pub" of pubsub. It's just broadcasting an event (action).
 * So, don't couple it with a specific reducer. Let ANY reducer listen to
 * an action.
 *
 * `typesafe-actions` has a nice utility function so we don't need to define action types as
 * constants. Use it together with `getType(type)` in the reducers.
 */
import { createAction } from "typesafe-actions";

import { GlobalUIState } from "./reducers";

export enum ModalID {
  ADD_CARD_FORM = 1,
  ADD_COUPON_FORM,
  ADD_RENT_PAYEE_FORM,
  ADD_INVOICE_PAYEE_FORM,
  ADD_SALARY_PAYEE_FORM,
  ADD_PAYEE_BULK_UPLOAD_CSV,
  ADD_PAYEE_BULK_PROCESS_CSV,
  ADD_RATE_FORM,
  ADD_COMPANY_FORM,
  CHANGE_RATE_FORM, // 10
  MAKE_PAYMENT_MODAL,
  PAYMENT_SUCCESS,
  PAYMENT_SUCCESS_SCHEDULE,
  PAYMENT_ERROR,
  PAYMENT_PAYEE_DETAIL,
  CONFIRMATION_DELETE,
  PAYMENT_DETAIL,
  ADMIN_PAYMENT_DETAIL,
  USER_SELECT_ACCOUNT_TYPE,
  REQUEST_LOGIN_MODAL, // 20
  NRIC_ID_PHOTOS_UPLOAD,
  NRIC_PHOTOS_DETAIL,
  ONE_TIME_MODAL,
  MARKETING_VIDEO_MODAL,
  REFUND_MODAL,
  FULL_REFUND_MODAL,
  PARTIAL_REFUND_MODAL,
  PRINCIPAL_REFUND_MODAL,
  PAYMENT_WORK_FLOW_INFO_MODAL,
  FEE_REFUND_MODAL, // 30
  EXPEDITE_MODAL,
  TWO_DAY_ANNOUNCEMENT_MODAL,
  REFERRAL_PROGRAM_MODAL,
  NEXT_DAY_PAYOUT_MODAL,
  RECURRING_PAYMENT_SUCCESS,
  POINTS_GUARANTEE_MODAL,
  TAX_PAYMENT_PROMOTION_MODAL,
  RENT_PROMO_MODAL,
  PROMO_CODE_MODAL,
  CROSS_BORDER_ANNOUNCEMENT_MODAL, // 40
  ADD_CUSTOMER_FORM_MODAL,
  RESEND_PAYMENT_REQUEST,
  COLLECTED_PAYMENT_SUCCESS,
  COLLECTED_PAYMENT_FAILED,
  REQUEST_PAYMENT_SUCCESSFULLY,
  SCHEDULED_PAYMENT_DETAIL,
  RESTRICTED_BUSINESSES_CONFIRM,
  EDIT_PAYMENT_DETAIL_MODAL,
  KYC_STATUS_MODAL,
  CHOOSE_CURRENCY_MODAL, // 50
  ADD_SELF_TRANSFER_PAYEE_FORM,
  DO_YOU_HAVE_CREDIT_CARD,
  PROMO_POPUP,
  COLLECTION_REVIEW_BEFORE_SEND_REQUEST,
  BE_PAID_SETUP_INVOICE_NUMBER,
  BE_PAID_INVOICE_EDIT_FEES,
  BE_PAID_INVOICE_ADD_NEW_TAX,
  BE_PAID_INVOICE_ADD_NEW_ITEM,
  BE_PAID_MAKE_PAYMENT,
  BE_PAID_SMS_NOTIFY, // 60
  OPTIMIZE_DASHBOARD_CONFIRM,
  BE_PAID_REFUND,
  BE_PAID_MARK_PAYMENT_AS_PAID,
  INTERNATIONAL_REDIRECT_KYC_MODAL,
  CREDIT_PROGRAM_CONFIRM_MODAL,
  CREDIT_PROGRAM_CONFIRMED_MODAL,
  CREDIT_PROGRAM_V1_NOTIFICATION,
  BE_PAID_UPDATE_FEE_SETTINGS_CONFIRMATION_MODAL,
  MY_INFO_VERIFICATION_MODAL,
  MY_INFO_VERIFICATION_RETRIEVED_NRIC_MODAL, // 70
  MY_INFO_VERIFICATION_ERROR_MODAL,
  INTERNATIONAL_KYC_REMINDER_MODAL,
  PAYMENT_INTEREST_MODAL,
  UEN_MODAL,
  UPLOAD_DOCUMENT_BEPAID_MODAL,
  CARDSPAL_MODAL,
  BEPAID_UNAVAILABLE_MODAL,
  ADMIN_PAYMENT_SUPPORT_DOCUMENT_DETAIL,
  ADMIN_VIEW_MY_INFO,
  ADMIN_VIEW_LIST_ACCOUNT_RATE,
  ADMIN_GET_RESET_LINK_MODAL,
  ADMIN_GET_EMAIL_CONFIRM_LINK_MODAL,
  INFORMATION_MODAL,
  ADMIN_BLOCK_USER_MODAL,
  REQUEST_LOGIN_PAYFETCH,
  RECOLLECT_CVV
}

export enum FetchModalID {
  PAYMENT_SUCCESS = 12,
  PAYMENT_SUCCESS_SCHEDULE = 13,
  PAYMENT_ERROR = 14,
  RECURRING_PAYMENT_SUCCESS = 35,
  COLLECTED_PAYMENT_FAILED = 44,
  MY_INFO_VERIFICATION_MODAL = 69,
  MY_INFO_VERIFICATION_ERROR_MODAL = 71,
  RECOLLECT_CVV = 86,
  TRIPLEA_PAYMENT_MODAL,
  DELETE_CUSTOMER_PROFILE = 1000,
  CANCEL_PAYMENT_MODAL = 1001,
  CANCEL_PAYMENT_REQUEST,
  MARK_PAYMENT_AS_PAID,
  REFUND_PAYMENT,
  INVOICE_SENT,
  ADD_COMPANY,
  LIST_COMPANIES,
  EDIT_COMPANY,
  CHANGE_EMAIL,
  UPDATE_PERSONAL_INFO,
  CHANGE_PASSWORD,
  UPDATE_ACCOUNT_SETTINGS,
  UPDATE_BUSINESS_INFORMATION,
  CREATE_INVOICE,
  INVOICE_DETAIL,
  ADD_CUSTOMER,
  EDIT_CUSTOMER,
  CUSTOMER_DETAIL,
  KYC_MODAL,
  CHANGE_OPTION_INVOICE_MODAL,
  UPDATE_DRAFT_INVOICE_SETTING,
  CANCEL_DRAFT_INVOICE,
  RECIPIENT_MODAL,
  RECIPIENT_DETAIL,
  RECIPIENT_DETAIL_UPDATE,
  LIST_CARD_MODAL,
  ADD_NEW_CARD_MODAL,
  DELETE_NEW_CARD_MODAL,
  // payments creation modal
  MAKE_PAYMENT_SELECT_ENTITY,
  MAKE_PAYMENT_SELECT_COUNTRY,
  MAKE_PAYMENT_SELECT_CURRENCY,
  PAYMENTS_CREATION,
  MAKE_PAYMENT_RESULT,
  BENEFITS_OF_FETCH_MODAL,
  PAYMENT_DETAIL,
  PAYMENT_DETAIL_EDIT,
  SCHEDULE_EDIT,
  DELETE_RECIPIENT_MODAL,
  LIST_INVOICE_DRAFT_MODAL,
  SCHEDULE_DETAIL,
  INCORPORATION_DATE_MODAL,
  PAYFETCH_TOUR_MODAL,
  PROMO_POPUP,
  SYSTEM_UPPGRADE_MODAL,
  PAYMENT_DUPLICATE_MODAL,
  ADDITIONAL_DATA_MODAL,
  USER_ADD_NEW_MODAL,
  USER_DELETE_MODAL,
  USER_INVITE_SENT_MODAL,
  CRYPTO_VERIFY_IDENTITY,
  SCHEDULE_EXTEND
}

// Switch PAY or FETCH
export const switchPlatform = createAction(
  "globalui/SWITCH_PLATFORM",
  resolve => {
    return (platform: number) =>
      resolve({
        platform
      });
  }
);

// Global Modal
export const toggleModal = createAction("globalui/TOGGLE_MODAL", resolve => {
  return (
    modalId: ModalID | FetchModalID,
    extraInfo?: any,
    settings?: GlobalUIState["settings"]
  ) => resolve({ modalId, extraInfo, settings });
});
export const openModal = createAction("globalui/OPEN_MODAL", resolve => {
  return (
    modalId: ModalID | FetchModalID,
    extraInfo?: any,
    settings?: GlobalUIState["settings"]
  ) => resolve({ modalId, extraInfo, settings });
});
export const closeModal = createAction("globalui/CLOSE_MODAL", resolve => {
  return (modalId: ModalID | FetchModalID) => resolve(modalId);
});

export const resetAllModals = createAction("globalui/RESET_ALL_MODALS");

// Global Loader
export const showGlobalLoader = createAction("globalui/SHOW_LOADER");
export const hideGlobalLoader = createAction("globalui/HIDE_LOADER");

// Global Toast
export const toast = createAction("globalui/TOAST", resolve => (text: string) =>
  resolve(text)
);

export const updateExtraInfo = createAction(
  "globalui/UPDATE_EXTRA_INFO",
  resolve => (modalID: ModalID, extraInfo: any) =>
    resolve({ extraInfo, modalID })
);

export const updateLoaderMessage = createAction(
  "globalui/UPDATE_LOADER_MESSAGE",
  resolve => (loadMessage: string) => resolve({ loadMessage })
);
