import React, { useEffect, useRef, useState } from "react";
import { isDesktop, isMobile } from "react-device-detect";
import classnames from "classnames";

import { CardInfo } from "src/ipm-shared/store/model/Card/types";
import { CardFormVersion } from "src/ipm-shared/components/CardForm/type";
import { ADD_CARD_FORM } from "src/ipm-shared/store/model/Card/const";

import CardForm from "src/bepaid/components/CardForm";
import Button from "src/bepaid/components/Form/controls/Button";
import Form from "src/bepaid/components/Form";
import { invokeMessage } from "src/bepaid/components/Message";

import styles from "./AddNewCard.module.scss";

const FormComponent = ({
  submitAddCardForm,
  acquirerId,
  countryCode,
  isWallexPayment,
  createUserStripeCardIntent,
  openListCardModal,
  closeModal,
  isListModalOpened,
  isCheckoutPaymentModalOpened,
  setFormErrors,
  renderSubmitButton,
  fetchCards,
  extraInfo,
  availableAcquirers,
  isAustraliaAccount,
  isMalaysiaAccount,
  action,
  onSubmitSuccess,
  syncBtnSubmitLoading
}: any) => {
  const [loading, setLoading] = React.useState(false);
  const cardFormRef = useRef<any>();

  const [card, setCard] = useState<any>({
    cardInfo: undefined,
    cardholderName: undefined
  });

  const onCardTokenized = (cardInfo: CardInfo, cardholderName?: string) => {
    setFormErrors([], ADD_CARD_FORM);
    setCard({
      cardInfo,
      cardholderName
    });
  };

  const onCardTokenizationCleared = () => {
    setCard({
      cardInfo: undefined,
      cardholderName: undefined
    });
  };

  const onSubmit = async () => {
    if (
      !card.cardInfo ||
      !card.cardholderName ||
      !cardFormRef?.current?.tokenize
    ) {
      return;
    }

    setLoading(true); // ok
    let data: any;
    try {
      data = await cardFormRef.current.tokenize();
    } catch (err) {
      setFormErrors([err.message], ADD_CARD_FORM);
      setLoading(false); // ok
      return;
    }

    if (!data) {
      setLoading(false); // ok
      return;
    }

    try {
      submitAddCardForm({
        cardInfo: data.cardInfo,
        cardholderName: data.cardholderName,
        cb: (cardId: number) => {
          const openListModal = () => {
            invokeMessage("success", "Card added.");
            closeModal();
            if (extraInfo && extraInfo.cb) {
              extraInfo.cb(cardId);
            }
            // TODO: maybe move this logic to sagas (pub-sub), if there's more logic here in the future
            if (isCheckoutPaymentModalOpened) {
              return;
            }
            if (extraInfo && extraInfo.notOpenList) {
              return;
            }
            if (!isListModalOpened) {
              openListCardModal();
            }
          };
          fetchCards(undefined, 0);
          setTimeout(() => {
            openListModal();
          }, 1000);
        },
        loadingFunc: setLoading
      });
    } catch (e) {
      window.Logger.error("submitAddCardForm: ", e);
    }
  };

  const onCardTokenizationFailed = (err: any) => {
    onCardTokenizationCleared();
    setFormErrors([err.message], ADD_CARD_FORM);
  };

  const submitBtn = (
    <Button
      id={"ipaymy_btn_add_new_card"}
      wrapperClassName={styles.submitBtn}
      type="primary"
      htmlType="submit"
      loading={loading || !card.cardInfo || !card.cardholderName}
      onClick={onSubmit}
      autoScrollToFirstError={true}
    >
      {loading ? "Adding new card…" : "Add new card"}
    </Button>
  );

  useEffect(() => {
    if (renderSubmitButton) {
      renderSubmitButton(submitBtn);
    }
  }, [loading, card]);

  useEffect(() => {
    if (syncBtnSubmitLoading) {
      syncBtnSubmitLoading(loading || !card.cardInfo || !card.cardholderName);
    }
  }, [loading, card.cardInfo, card.cardholderName]);

  useEffect(() => {
    if (action === "submit_add_new_card") {
      onSubmit();
      onSubmitSuccess();
    }
  }, [loading, action]);

  return (
    <div>
      <Form
        name="add-new-card-form"
        layout="vertical"
        requiredMark={false}
        className={styles.form}
      >
        <div>
          <div className={styles.formContent}>
            <CardForm
              loading={loading}
              ref={cardFormRef as any}
              cardFormVersion={CardFormVersion.BEPAID}
              modalView={false}
              acquirerId={acquirerId}
              countryCode={countryCode}
              onCardTokenized={onCardTokenized}
              onCardTokenizationCleared={onCardTokenizationCleared}
              onCardTokenizationFailed={onCardTokenizationFailed}
              isWallexPayment={isWallexPayment}
              hideSecureFormText={true}
              stripeCardIntentCreator={createUserStripeCardIntent}
              isDisabledChinaUnionPay={["AU", "MY"].includes(countryCode)}
              availableAcquirers={availableAcquirers}
              isCheckout={false}
              form={ADD_CARD_FORM}
              isWorldpayMasterCard={extraInfo.isWorldpayMasterCard}
              isWorldpayVisa={extraInfo.isWorldpayVisa}
              isWorldPayCard={extraInfo.isWorldPayCard}
              isProduction={extraInfo.isProduction}
              isInternational={extraInfo.isInternational}
              allowAmexPayment={extraInfo.allowAmexPayment}
              isAustraliaAccount={isAustraliaAccount}
              isMalaysiaAccount={isMalaysiaAccount}
            />
          </div>
        </div>
      </Form>
      {renderSubmitButton ? null : submitBtn}
    </div>
  );
};

const AddNewCard: React.FC<any> = props => {
  return (
    <div
      className={classnames(styles.wrapper, {
        [styles.wrapperDesktop]: isDesktop,
        [styles.wrapperMobile]: isMobile
      })}
    >
      <FormComponent {...props} />
    </div>
  );
};

export default AddNewCard;
