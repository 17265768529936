import * as ReactRouter from "react-router";
import { connect } from "react-redux";
import { RootState } from "src/ipm-shared/store/model/reducers";
import Component from "./View";
import * as actions from "./actions";
import * as selectors from "./selectors";

const mapStateToProps = (state: RootState) => ({
  acquirerId: selectors.getAcquirerId(state),
  countryCode: selectors.getCurrentCountry(state),
  isWallexPayment: selectors.isWallexPayment(state),
  isListModalOpened: selectors.isGivenModalOpened(
    state,
    actions.FetchModalID.LIST_CARD_MODAL
  ),
  isCheckoutPaymentModalOpened: selectors.isGivenModalOpened(
    state,
    actions.FetchModalID.PAYMENTS_CREATION
  ),
  availableAcquirers: selectors.getAvailableAcquirers(state),
  isAustraliaAccount: selectors.isAustraliaAccount(state),
  isMalaysiaAccount: selectors.isMalaysiaAccount(state)
});

const mapDispatchToProps = {
  submitAddCardForm: actions.submitAddCardForm,
  createUserStripeCardIntent: actions.createUserStripeCardIntent,
  closeModal: actions.closeModal.bind(
    actions,
    actions.FetchModalID.ADD_NEW_CARD_MODAL
  ),
  openListCardModal: actions.toggleModal.bind(
    actions,
    actions.FetchModalID.LIST_CARD_MODAL
  ),
  toggleModal: actions.toggleModal,
  setFormErrors: actions.setFormErrors,
  fetchCards: actions.fetchCards
};

// Fixed template
export type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  ReactRouter.RouteProps;

export default connect(mapStateToProps, mapDispatchToProps)(Component);
