import React from "react";
import * as queryString from "query-string";
import {
  BrowserView,
  MobileView,
  isDesktop,
  isMobile
} from "react-device-detect";
import classNames from "classnames";
import Typography from "antd/es/typography";

import { history } from "src/ipm-shared/store";
import { FetchModalID } from "src/ipm-shared/components/GlobalUI/actions";

import Form from "src/bepaid/components/Form";
import Image from "src/bepaid/components/Image";
import Button from "src/bepaid/components/Form/controls/Button";
import DesktopModalLayout from "src/bepaid/components/Modal/components/DesktopModalLayout";
import ROUTES from "src/bepaid/routes";

import QuestionSingpassIcon from "src/bepaid/assets/images/verify/question_singpass_icon.svg";

import styles from "./ErrorIdentity.module.scss";

const ErrorIdentity: React.FC<any> = props => {
  let part1 = "We are unable to retrieve your information from Myinfo.";
  let part2 = `You can click “Retrieve Myinfo with singpass" and try again.`;
  let part3 = (
    <Typography.Text className={styles.itemText}>
      Please visit Myinfo portal at{" "}
      <Typography.Link href="mailto:myinfo.gov.sg">
        myinfo.gov.sg
      </Typography.Link>{" "}
      for more updates or contact{" "}
      <Typography.Link href="mailto:support@myinfo.gov.sg">
        support@myinfo.gov.sg
      </Typography.Link>{" "}
      <br />
      if you require additional assistance.
    </Typography.Text>
  );
  const extraInfo = props.extraInfo;
  if (extraInfo) {
    part1 = extraInfo.message1;
    part2 = extraInfo.message2;
    part3 = extraInfo.message3;
  }

  const renderContent = () => {
    return (
      <Form
        name="my-info-error-identity-form"
        layout="vertical"
        requiredMark={true}
        className={styles.form}
      >
        <div className={styles.formWrapper}>
          <div className={styles.title}>
            <div className={styles.itemSection}>
              <Image src={QuestionSingpassIcon} className={styles.image} />
            </div>
            <div className={styles.itemSection}>
              <Typography.Text className={styles.heroText}>
                {part1}
              </Typography.Text>
            </div>
          </div>
          <div className={styles.itemSection}>
            <Typography.Text className={styles.itemText}>
              {part2}
            </Typography.Text>
          </div>
          <div className={styles.itemSection}>{part3}</div>
        </div>
      </Form>
    );
  };

  const toggleModal = () => {
    props.toggleModal(FetchModalID.MY_INFO_VERIFICATION_ERROR_MODAL);

    const qs = queryString.parse(window.location.search);
    const { result, valid, ...rest } = qs;

    // remove "result" & "valid" params on URL if existed
    history.push(
      `${ROUTES.MY_INFO}/?${queryString.stringify({
        ...rest
      })}`
    );
  };

  const renderButton = () => (
    <div className={styles.button}>
      <Button type="primary" htmlType="submit" onClick={toggleModal}>
        Okay
      </Button>
    </div>
  );

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.wrapperDesktop]: isDesktop,
        [styles.wrapperMobile]: isMobile
      })}
    >
      <BrowserView>
        <DesktopModalLayout
          hasBorder={false}
          onClose={toggleModal}
          btnSubmit={renderButton()}
        >
          {renderContent()}
        </DesktopModalLayout>
      </BrowserView>
      <MobileView>
        {renderContent()}
        {renderButton()}
      </MobileView>
    </div>
  );
};

export default ErrorIdentity;
