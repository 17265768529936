import React from "react";

import styles from "./ButtonIcon.module.scss";
import classNames from "classnames";

const ButtonIcon = (props: any) => {
  const onClick = (...params: any[]) => {
    if (props.onClick) {
      props.onClick(...params);
    }
  };
  return (
    <div
      className={classNames(styles.wrapper, props.className)}
      onClick={onClick}
    >
      {props.children}
      {props.icon && (
        <div className={classNames(styles.icon, props.iconClass)}>
          {props.icon}
        </div>
      )}
    </div>
  );
};

export default ButtonIcon;
