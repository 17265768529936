import * as React from "react";
import { IBaseProps, IBaseState } from "./lib/Base";
import _get from "lodash-es/get";
import BaseControl from "./lib/Base";
import { connect } from "react-redux";
import * as actions from "../actions";
import * as selectors from "../selectors";
import { RootState } from "src/ipm-shared/store/model/reducers";
import { ControlErrorType, ControlType, ControlValueType } from "../types";
import * as Images from "src/ipm-shared/components/Images";
import * as uuidv1 from "uuid/v1";
import * as filesize from "filesize";
import Dropzone, { DropEvent, FileRejection } from "react-dropzone";
import * as classNames from "classnames";
import FileUtils from "src/ipm-shared/Utils/Files";
import store from "src/ipm-shared/store";
import withPropsChecker from "./lib/withPropsChecker";
import T from "src/ipm-shared/Utils/Intl";
import _isEmpty from "lodash-es/isEmpty";
import LoaderIcon from "src/ipm-shared/components/LoaderIcon";

type DEF_ALLOWED_FILETYPES =
  | "doc"
  | "docx"
  | "jpg"
  | "jpeg"
  | "pdf"
  | "png"
  | "csv";
export type IInputFileProps = IBaseProps & {
  minFileAmount?: number;
  multiple?: boolean;
  allowedFileTypes?: DEF_ALLOWED_FILETYPES[];
  purpose: string;
  isAdmin?: boolean;
  accountId?: number;
  defaultValue?: Array<{
    key: string;
    size: string;
    url: string;
    name: string;
  }>;
  onChangeEach?: (value: { [fileRef: string]: any }) => void;
  storeFileDisabled?: boolean;
  InjectedHelper?: React.ComponentType<any>;
  description?: string | JSX.Element;
  maxFileAmount?: number;
  isDisplayPreview?: boolean;
  disabledUpload?: boolean;
  disabledRemove?: boolean;
  isHiddenListing?: boolean;
  getPreviewImageUrl?: (value: object) => void;
  renderComponent?: any;
};

const mapStateToProps = (
  state: RootState,
  props: IInputFileProps
): {
  control: ReturnType<typeof selectors.getControl>;
  fileList: ControlType[];
} => {
  const control = selectors.getControl(state, props.name);
  let fileList: ControlType[] = [];
  const fileRefs = _get(control, "extraInfo.fileRefs", undefined);
  if (fileRefs) {
    fileList = fileRefs.map((name: string) => {
      return _get(state.form.controls, name, undefined);
    });
  }
  return {
    control,
    fileList
  };
};

const mapDispatchToProps = {
  deleteFile: actions.deleteFile,
  removeControl: actions.removeControl,
  resetControlErrors: actions.resetControlErrors,
  setControl: actions.setControl,
  uploadFile: actions.uploadFile
};

type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  IInputFileProps;
type IState = IBaseState & {
  imagesPreviewData: {
    [name: string]: string;
  };
};

/**
 * This is one of common controls in the entire app.
 * Use this when you want to show a drop-down list.
 *
 * @base BaseControl.tsx
 */
class FilePicker extends BaseControl<IProps, IState> {
  private InputFile: HTMLInputElement;
  constructor(props: IProps) {
    super(props);

    this.state = {
      imagesPreviewData: {}
    };
  }

  public componentDidMount() {
    if (this.shouldRevertValueOnMount(this.props.control)) {
      return;
    }

    if (this.props.defaultValue && this.props.defaultValue.length > 0) {
      const fileRefs: string[] = [];
      const values: string[] = [];
      this.props.defaultValue.map((v: any) => {
        const name = `${this.props.name}-${uuidv1()}`;
        fileRefs.push(name);
        values.push(v.key);

        FileUtils.fetchResource({
          cb: (value: any) => {
            this.getImagePreview(value, name);
          },
          url: v.url
        });

        this.props.setControl({
          extraInfo: {
            fileName: v.name,
            fileSize: v.size,
            fileUrl: v.url
          },
          form: this.props.form as string,
          group: this.props.group,
          name,
          value: v.key
        });
      });

      const valueStr = values.length > 0 ? values.join(",") : undefined;

      this.props.setControl({
        extraInfo: {
          fileRefs
        },
        form: this.props.form as string,
        group: this.props.group,
        name: this.props.name,
        value: valueStr
      });

      this.doValidate(valueStr, this.props.name);
    } else {
      // Set default control
      this.props.setControl({
        form: this.props.form as string,
        group: this.props.group,
        name: this.props.name,
        value: undefined
      });

      this.doValidate(undefined, this.props.name, false);
    }
  }

  public componentWillUnmount() {
    this.alive = false;

    if (this.props.control.value && this.props.control.extraInfo) {
      this.props.control.extraInfo.fileRefs.map((f: string) => {
        if (!this.props.reserveValueOnUnmount) {
          this.props.removeControl(f);
          this.props.removeControl(`document_tag_${f}`);
        }
      });
    }

    if (!this.props.reserveValueOnUnmount) {
      this.props.removeControl(this.props.name);
    } else {
      this.props.resetControlErrors(this.props.name);
    }
  }

  public render() {
    const {
      control,
      disabled,
      InjectedHelper,
      renderComponent = null
    } = this.props;
    const { imagesPreviewData } = this.state;

    if (control.notFound) {
      return null;
    }

    let allowedFileTypes: string | undefined;
    let allowedFileTypesLabel: string | undefined;
    if (this.props.allowedFileTypes && this.props.allowedFileTypes.length > 0) {
      allowedFileTypesLabel = this.props.allowedFileTypes.join(", ");
      allowedFileTypes = this.props.allowedFileTypes
        .map(ft => `.${ft}`)
        .join(", ");
    } else {
      const defaultFileType = [
        "doc",
        "docx",
        "jpg",
        "jpeg",
        "png",
        "pdf",
        "csv"
      ];
      allowedFileTypesLabel = defaultFileType.join(", ");
      allowedFileTypes = defaultFileType.map(ft => `.${ft}`).join(", ");
    }

    let isDisabledUpload;
    if (
      this.props.fileList.length === 1 &&
      this.props.fileList[0] === undefined &&
      !this.props.disabledUpload
    ) {
      isDisabledUpload = false;
    } else if (this.props.maxFileAmount && !this.props.disabledUpload) {
      isDisabledUpload = !(
        this.props.maxFileAmount > this.props.fileList.length
      );
    } else {
      isDisabledUpload = this.props.disabledUpload;
    }

    let labelMaximizeUploadSize = 16;
    if (this.props.purpose === "company_logo") {
      labelMaximizeUploadSize = 5;
    }

    const getFileErrors = () => {
      const filesHasErrors = this.props.fileList.filter(fileItem => {
        return _get(fileItem, "errors.length", 0) > 0;
      });
      return filesHasErrors;
    };

    const commonProps = {
      deleteFile: (file: any) => {
        this.props.deleteFile({
          form: this.props.form,
          name: this.props.name,
          key: file.fileKey
        });
      },
      disabled,
      id: this.props.id,
      onAddFile: this.handleAddDocuments,
      onChange: this.clickInputFile,
      ref: (ref: HTMLInputElement) => (this.ref = ref),
      value: control.value || "",
      filesErrors: getFileErrors()
    };

    if (renderComponent) {
      return renderComponent({ ...commonProps, control });
    }

    return (
      <div className={this.props.className}>
        <input
          ref={(ref: HTMLInputElement) => (this.InputFile = ref)}
          type="file"
          id="file_picker"
          hidden={true}
          accept={allowedFileTypes}
          multiple={true}
          onChange={this.clickInputFile}
          disabled={isDisabledUpload}
        />
        <Dropzone
          accept={allowedFileTypes?.replace(/\s/, "").split(",")}
          multiple={true}
          disabled={isDisabledUpload}
          onDrop={this.dropFile}
          noClick={true}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps({ className: "dropzone" })}>
              <input {...getInputProps()} />
              {this.props.label && (
                <span className="label">{this.props.label}</span>
              )}

              {this.props.description && this.props.description}
              <div
                className={classNames("upload-box-place", {
                  "label-only": this.props.labelOnly
                })}
              >
                {this.props.isDisplayPreview && (
                  <span className={"image-preview-block"}>
                    {!_isEmpty(imagesPreviewData) ? (
                      Object.keys(imagesPreviewData).map(key => (
                        <div className={"image-preview text-center"} key={key}>
                          <div
                            className="image-preview-bg mb-2"
                            style={{
                              backgroundImage: `url(${this.state.imagesPreviewData[key]}`
                            }}
                          />

                          {this.props.disabledUpload ? (
                            <span className="remove text-black cursor-pointer">
                              {T.transl("REMOVE_BUTTON")}
                            </span>
                          ) : (
                            <span
                              onClick={this.removeValue.bind(this, key)}
                              className="remove text-black cursor-pointer"
                            >
                              {T.transl("REMOVE_BUTTON")}
                            </span>
                          )}
                        </div>
                      ))
                    ) : (
                      <div className="image-preview-none-image">
                        <span className="image-preview-bg mb-2">
                          {this.props.fileList.length > 0 ? (
                            this.props.fileList.map(
                              (f, idx) =>
                                f &&
                                (f.extraInfo.uploading ? (
                                  <LoaderIcon
                                    key={idx}
                                    type={"line-scale"}
                                    color="#00A9BE"
                                    active={true}
                                  />
                                ) : (
                                  <span key={idx}>
                                    Logo <br /> Preview
                                  </span>
                                ))
                            )
                          ) : (
                            <span>
                              Logo <br /> Preview
                            </span>
                          )}
                        </span>
                        <span className="remove text-black cursor-pointer">
                          200px x 200px
                        </span>
                      </div>
                    )}
                  </span>
                )}
                <div className={"upload-box-place-inner"}>
                  {!this.props.labelOnly && (
                    <React.Fragment>
                      <h6>{T.transl("FILE_DRAG_LABEL")}</h6>
                      <a
                        className="upload-action"
                        onClick={this.clickToAddDocument}
                      >
                        <span className="img-icon">
                          <img src={Images.plus_icon} alt="" />
                        </span>
                        {T.transl("FILE_SELECT_LABEL")}
                      </a>

                      <p className="mb-0">
                        <span>
                          {`${T.transl("FILE_MAXIMUM_SIZE")}:`}{" "}
                          <b className="fw-600">{labelMaximizeUploadSize} MB</b>
                          .
                        </span>{" "}
                        <span>
                          {`${T.transl("FILE_SUPPORTED_TYPES")}:`}{" "}
                          <b className="fw-600">{allowedFileTypesLabel}</b>.
                        </span>
                      </p>
                    </React.Fragment>
                  )}
                  {!this.props.isHiddenListing && (
                    <ul className="files-upload">
                      {this.props.fileList.map(
                        f =>
                          f && (
                            <li
                              className={classNames("file-upload", {
                                failed: f.errors.length > 0
                              })}
                              key={f.name}
                            >
                              <div
                                className={`file-content ${f.extraInfo
                                  .uploading && "uploading"}`}
                              >
                                <div className="file-content-inner d-flex flex-column justify-content-center">
                                  {f.extraInfo.fileName ? (
                                    <span>
                                      <a
                                        onClick={this.downloadFile.bind(
                                          this,
                                          f.extraInfo.fileUrl,
                                          f.extraInfo.fileName
                                        )}
                                      >
                                        {f.extraInfo.fileName}
                                      </a>{" "}
                                      ({filesize(f.extraInfo.fileSize)})
                                    </span>
                                  ) : (
                                    <span>Unknown</span>
                                  )}

                                  {f.errors.map((error, idx) => (
                                    <span className="is-invalid" key={idx}>
                                      {error.message}
                                    </span>
                                  ))}
                                  {f.extraInfo.uploading && (
                                    <div className="progress mt-1" />
                                  )}
                                </div>
                              </div>
                              {f.extraInfo.uploading ? (
                                <div
                                  className="remove-uploading"
                                  onClick={this.removeUploading.bind(
                                    this,
                                    f.name
                                  )}
                                />
                              ) : (
                                <div className="actions-group">
                                  {this.props.labelOnly ? (
                                    <button
                                      type="button"
                                      className="btn btn-sm btn-outline-secondary"
                                      onClick={this.downloadFile.bind(
                                        this,
                                        f.extraInfo.fileUrl,
                                        f.extraInfo.fileName
                                      )}
                                    >
                                      View
                                    </button>
                                  ) : (
                                    <React.Fragment>
                                      {InjectedHelper && (
                                        <InjectedHelper
                                          fileRef={f.name}
                                          reserveValueOnUnmount={
                                            this.props.reserveValueOnUnmount
                                          }
                                          revertValueOnMount={
                                            this.props.revertValueOnMount
                                          }
                                        />
                                      )}
                                      <button
                                        type="button"
                                        hidden={
                                          f.errors.length === 0 ||
                                          this.props.storeFileDisabled
                                        }
                                        className="btn btn-sm btn-outline-secondary mr-2 flex-shrink-0"
                                        onClick={this.handleRetry.bind(
                                          this,
                                          f.name
                                        )}
                                      >
                                        {T.transl("RETRY_BUTTON")}
                                      </button>
                                      <button
                                        type="button"
                                        disabled={this.props.disabledRemove}
                                        className="btn btn-sm btn-outline-secondary flex-shrink-0"
                                        onClick={this.removeValue.bind(
                                          this,
                                          f.name
                                        )}
                                      >
                                        {T.transl("REMOVE_BUTTON")}
                                      </button>
                                    </React.Fragment>
                                  )}
                                </div>
                              )}
                            </li>
                          )
                      )}
                    </ul>
                  )}
                </div>
              </div>
              {this.props.isHiddenListing &&
                this.props.fileList.map(
                  f =>
                    f &&
                    f.errors.map((error, idx) => (
                      <span
                        style={{ marginTop: "-25px" }}
                        className="invalid-feedback d-block"
                        key={idx}
                      >
                        {error.message}
                      </span>
                    ))
                )}

              {this.renderErrorMessage(control)}
            </div>
          )}
        </Dropzone>
      </div>
    );
  }

  private downloadFile = (
    fileUrl: string,
    fileName: string,
    e: React.ChangeEvent<any>
  ) => {
    e.preventDefault();
    e.stopPropagation();
    FileUtils.downloadResource(fileUrl, fileName);
  };

  private dropFile = <T1 extends File>(
    acceptedFiles: T1[],
    fileRejections: FileRejection[],
    event: DropEvent
  ) => {
    this.handleAddDocuments(acceptedFiles);
  };

  private clickInputFile = (e: React.ChangeEvent<any>) => {
    e.preventDefault();
    e.stopPropagation();
    const acceptedFiles: any[] = [];
    getDataTransferItems(e).forEach((item: any) => {
      acceptedFiles.push(item);
    });
    this.handleAddDocuments(acceptedFiles);
  };

  private clickToAddDocument = (e: React.ChangeEvent<any>) => {
    e.preventDefault();
    e.stopPropagation();
    if (
      this.props.isHiddenListing &&
      this.props.fileList.length > 0 &&
      this.props.fileList[0].errors.length > 0
    ) {
      this.removeValue(this.props.fileList[0].name, e);
      setTimeout(() => {
        this.InputFile.value = "";
        this.InputFile.click();
      }, 0);
    } else {
      this.InputFile.value = "";
      this.InputFile.click();
    }
  };

  private handleAddDocuments = async (
    files: any[],
    cb?: (err?: any, data?: any) => void
  ) => {
    const fileRefs = _get(this.props.control, "extraInfo.fileRefs", []);

    for (const file of files) {
      const inputName = `${this.props.name}-${uuidv1()}`;

      fileRefs.push(inputName);

      this.props.uploadFile({
        callbackSuccess: (...args) => {
          if (cb) {
            cb(null, args);
          }
          return this.addValue(...args);
        },
        form: this.props.form as string,
        name: inputName,
        purpose: this.props.purpose,
        storeFileDisabled: this.props.storeFileDisabled,
        value: file
      });
    }

    this.props.setControl({
      extraInfo: {
        fileRefs
      },
      form: this.props.form as string,
      name: this.props.name
    });
  };

  private getImagePreview = (file: any, name: string) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = (e: any) => {
      this.setState(preState => ({
        imagesPreviewData: {
          ...preState.imagesPreviewData,
          [name]: e.target.result
        }
      }));

      if (this.props.getPreviewImageUrl) {
        this.props.getPreviewImageUrl(this.state.imagesPreviewData);
      }
    };
  };

  private handleRetry = (name: string) => {
    const fileObj = this.props.fileList.filter(f => f.name === name)[0]
      .extraInfo.fileObj;

    this.props.uploadFile({
      accountId: this.props.accountId,
      callbackSuccess: this.addValue,
      form: this.props.form as string,
      isAdmin: this.props.isAdmin,
      name,
      purpose: this.props.purpose,
      retry: true,
      value: fileObj
    });
  };

  private addValue = (key: string, fileRef: string, extraInfo: any) => {
    this.getImagePreview(extraInfo.fileObj, fileRef);
    let values: string[] = [];
    if (this.props.control.value) {
      values = (this.props.control.value as string).split(",");
    }

    values.push(key);
    const valueStr = values.join(",");

    this.props.setControl({
      form: this.props.form as string,
      name: this.props.name,
      value: valueStr
    });

    this.doValidate(valueStr, this.props.name);
    this.doValidateEach(fileRef, extraInfo);
  };

  private removeValue = (name: string, e: React.ChangeEvent<any>) => {
    e.preventDefault();
    e.stopPropagation();

    let values: string[] = [];
    if (this.props.control.value) {
      values = (this.props.control.value as string).split(",");
    }

    const fileList = this.props.fileList.filter(f => f !== undefined);

    const value = _get(
      fileList.filter(f => f.name === name),
      "0.value"
    );

    values = values.filter(v => v !== value);
    const valueStr = values.length > 0 ? values.join(",") : undefined;

    this.props.setControl({
      extraInfo: {
        fileRefs: this.props.control.extraInfo.fileRefs.filter(
          (n: string) => n !== name
        )
      },
      form: this.props.form as string,
      name: this.props.name,
      value: valueStr
    });

    this.props.removeControl(name);
    this.props.removeControl(`document_tag_${name}`);

    const newState = this.state.imagesPreviewData;
    delete newState[name];
    this.setState({
      imagesPreviewData: newState
    });

    if (this.props.getPreviewImageUrl) {
      this.props.getPreviewImageUrl(this.state.imagesPreviewData);
    }

    this.doValidate(valueStr, this.props.name);
  };

  private removeUploading = (name: string, e: React.ChangeEvent<any>) => {
    e.preventDefault();
    e.stopPropagation();

    store.dispatch({ type: `CANCEL_UPLOAD_FILE_${name}` });
  };

  private doValidate = (
    value: ControlValueType,
    name: string,
    displayError: boolean = true
  ) => {
    this.props.setControl({
      errors: [],
      name
    });

    let result: any;
    const it = FilePicker.baseValidate(this.props, value);
    const errors: ControlErrorType[] = [];
    while (!(result = it.next()).done) {
      errors.push(result.value);
    }

    // Verify file upload amount
    if (this.props.minFileAmount && value) {
      const files = (value as string).split(",");
      if (files.length < this.props.minFileAmount) {
        errors.push({
          code: "INVALID_FILE_UPLOAD_AMOUNT",
          message: T.transl("INVALID_FILE_UPLOAD_AMOUNT", {
            file_amount: this.props.minFileAmount
          })
        });
      }
    }

    if (errors.length === 0) {
      if (this.props.onChangeCustom && this.alive) {
        this.props.onChangeCustom(value);
      }
    } else {
      this.props.setControl({
        displayError,
        errors,
        name
      });
    }
  };

  private doValidateEach = (fileRef: string, extraInfo: any) => {
    // Do some specific validation here...

    if (this.props.onChangeEach) {
      this.props.onChangeEach({
        [fileRef]: extraInfo
      });
    }
  };
}

export default withPropsChecker(
  connect(mapStateToProps, mapDispatchToProps)(FilePicker)
);

export function getDataTransferItems(event: React.ChangeEvent<any>) {
  const dataTransferItemsList = event.target.files;
  return Array.prototype.slice.call(dataTransferItemsList);
}
