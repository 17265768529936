import * as React from "react";
import { connect } from "react-redux";
import * as selectors from "../selectors";
import * as actions from "../actions";
import { RootState } from "src/ipm-shared/store/model/reducers";
import { ControlErrorType } from "../types";

import _isEqual from "lodash/isEqual";

const mapStateToProps = (
  state: RootState,
  props: {
    form: string;
  }
): {
  errors: ControlErrorType[];
} => ({
  errors: selectors.getFormErrors(state, props.form)
});

const mapDispatchToProps = {
  resetFormErrors: actions.resetFormErrors,
  setControl: actions.setControl
};

type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps & {
    className?: string;
    form: string;
    ignoreErrors?: string[];
    exception?: {
      [key: string]: {
        putToField: string;
        code?: string;
        message?: string;
      };
    };
  };

/**
 * This is one of common controls in the entire app.
 * Use this when you want to show Form errors.
 */
class FormErrors extends React.Component<IProps, any> {
  public componentDidUpdate(
    prevProps: Readonly<IProps>,
    prevState: Readonly<any>,
    snapshot?: any
  ) {
    if (
      !_isEqual(prevProps.errors, this.props.errors) &&
      this.props.exception &&
      this.props.errors.length > 0
    ) {
      const { errors, exception, form } = this.props;

      errors.forEach(err => {
        if (exception[err.code]) {
          const { putToField, code, message } = exception[err.code];
          // Set error for putToField
          if (code && message) {
            err = {
              ...err,
              code,
              message
            };
          }
          this.props.setControl({
            displayError: true,
            errors: [err],
            form,
            name: putToField
          });
        }
      });
    }
  }

  public render() {
    const { className, errors, ignoreErrors } = this.props;

    return (
      <div className={className}>
        {errors
          .filter(error => {
            if (ignoreErrors) {
              return ignoreErrors.indexOf(error.code) < 0;
            }
            return true;
          })
          .map((error, idx) => (
            <span key={idx} className="form-invalid-feedback">
              {error.message}
            </span>
          ))}
      </div>
    );
  }

  public componentWillUnmount() {
    this.props.resetFormErrors(this.props.form);
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormErrors);
