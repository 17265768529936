import { RouteProps } from "react-router";
import { connect } from "react-redux";
import { RootState } from "src/ipm-shared/store/model/reducers";
import * as selectors from "src/ipm-shared/store/model/Auth/selectors";
import * as actions from "src/ipm-shared/store/model/Auth/actions";
import * as formAction from "src/ipm-shared/components/Form/actions";

import Component from "./View";

const mapStateToProps = (state: RootState) => ({
  hasToken: selectors.hasToken()
});

const mapDispatchToProps = {
  login: actions.loginSubmit,
  redirectSAML: actions.redirectToSAML,
  loginWithSAMLSubmit: actions.loginWithSAMLSubmit,
  logout: actions.logout,
  setControl: formAction.setControl
};

export type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteProps & { qs: { [param: string]: any } };

export default connect(mapStateToProps, mapDispatchToProps)(Component);
