export enum PURPOSE {
  RENTAL = 1,
  SALARY,
  INVOICE,
  // Will be converted to INVOICE = 3
  TAX = "TAX",
  COLLECTION = 4,
  SELF_TRANSFER,
  PARTNERSHIP,
  SALARY_BUSINESS = 7,
  INSURANCE
}
