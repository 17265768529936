import React from "react";

import styles from "./ToolTipIBAN.module.scss";
import ToolTip from "src/ipm-shared/components/Tooltip";
import { IBAN_BREAKDOWN } from "./const";
import { isDesktop, isMobile } from "react-device-detect";
import classNames from "classnames";
import StringUtils from "src/ipm-shared/Utils/String";

interface IProps {
  countryCode: string;
}

const ToolTipIBAN = ({ countryCode }: IProps) => {
  const ibanBreakdown = IBAN_BREAKDOWN.find(
    (i: any) => i.country_code === countryCode
  );
  const keys = Object.keys(ibanBreakdown!);
  const renderToolTipLabel = () => {
    return (
      <b className={styles.showTooltipText} id="tooltip-iban">
        here
      </b>
    );
  };

  const renderToopTipDescription = () => {
    return (
      <div className="tooltip-description-container">
        <div className="tooltip-description">
          {keys.map((key: string) => (
            <div className="tooltip-description-item">
              <span>
                <b>{ibanBreakdown?.[key]}</b>
              </span>
              <span>
                {StringUtils.capitalizeFirstLetter(key.split("_").join(" "))}
              </span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className={styles.wrapper}>
      <span>
        Refer{" "}
        <ToolTip
          description={renderToopTipDescription()}
          label={renderToolTipLabel()}
          innerClassName={`${
            isDesktop ? `tooltipInnerDesktop` : `tooltipInnerMobile`
          }`}
          iconColor="gray"
          target={`tooltip-iban`}
          className={classNames(styles.tooltip, {
            [styles.tooltipMobile]: isMobile
          })}
          width={16}
        />{" "}
        to find the account number from IBAN number
      </span>
    </div>
  );
};

export default ToolTipIBAN;
