import React from "react";
import { BrowserView, MobileView } from "react-device-detect";

import { history } from "src/ipm-shared/store";

import Button from "src/bepaid/components/Form/controls/Button";
import Skeleton from "src/bepaid/components/Skeleton";
import AddCustomerForm from "src/bepaid/components/AddCustomerForm";
import MainLayout from "src/bepaid/layouts/MainLayout";
import DesktopModalLayout from "src/bepaid/components/Modal/components/DesktopModalLayout";
import useQuery from "src/bepaid/hooks/useQuery";
import { invokeMessage } from "src/bepaid/components/Message";

import styles from "./EditCustomerModal.module.scss";

function EditCustomerModal(props: any) {
  const {
    getCustomerList,
    editCustomer,
    extraInfo,
    selectCustomers,
    getSelectedCustomersId
  } = props;

  const setCustomer = (isSave: boolean) => {
    let ids = [...getSelectedCustomersId];
    const id = parseInt(window.location.pathname.split("/")[2]);

    if (isSave && !ids.includes(id)) {
      ids = []; // using on single select, prepare for multiple select
      ids.push(id);
    }

    selectCustomers(ids);
  };

  const toggleModal = () => {
    setCustomer(false);
    history.goBack();
  };

  const [loading, setLoading] = React.useState(false);
  const [isSubmit, setSubmit] = React.useState(false);

  const qs = useQuery();

  const [editData, setEditData] = React.useState({
    id: null
  });
  React.useEffect(() => {
    getCustomerList(
      qs,
      (err: any, data: any) => {
        if (!err) {
          const customer = data.find(
            (cus: any) => +cus.id === +extraInfo.data.id
          );

          setEditData({
            ...customer
          });
        }
      },
      setLoading
    );
  }, []);

  const handleSubmitEditCustomer = () => {
    setSubmit(true);
    setCustomer(true);

    editCustomer(
      "none",
      (err: any, data: any) => {
        setSubmit(false);
        if (err) {
          return;
        }
        invokeMessage("success", "Changes saved.");
        history.goBack();
      },
      setSubmit
    );
  };

  // const handleBackCustomer = () => {
  //   history.goBack();
  // };

  const renderForm = () => {
    return <AddCustomerForm isEdit={true} data={editData} />;
  };

  const renderSubmitButton = () => {
    return (
      <Button
        type="primary"
        htmlType="submit"
        onClick={handleSubmitEditCustomer}
        loading={isSubmit}
        autoScrollToFirstError={true}
      >
        {isSubmit ? "Saving changes..." : "Save changes"}
      </Button>
    );
  };

  return (
    <>
      <BrowserView>
        <DesktopModalLayout
          title={"Edit customer"}
          maxWidth450={true}
          onClose={toggleModal}
          onSubmit={handleSubmitEditCustomer}
          btnSubmit={renderSubmitButton()}
        >
          <div className={styles.wrapper}>
            <Skeleton loading={loading}>{renderForm()}</Skeleton>
          </div>
        </DesktopModalLayout>
      </BrowserView>
      <MobileView>
        <MainLayout
          header={{
            title: "Edit customer",
            hideMenu: true,
            onClose: toggleModal
          }}
          footer={renderSubmitButton()}
        >
          <div className={styles.wrapper}>
            <Skeleton loading={loading}>{renderForm()}</Skeleton>
          </div>
        </MainLayout>
      </MobileView>
    </>
  );
}

export default EditCustomerModal;
