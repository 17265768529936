// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AddCompanyModal_wrapper__1Aa4l{margin:auto -1.5rem}.AddCompanyModal_wrapper__1Aa4l .submit-btn{margin-top:3rem;margin-bottom:0}", "",{"version":3,"sources":["webpack://./src/bepaid/pages/Companies/components/AddCompanyModal/AddCompanyModal.module.scss"],"names":[],"mappings":"AAAA,gCAAS,mBAAmB,CAAC,4CAA6B,eAAe,CAAC,eAAe","sourcesContent":[".wrapper{margin:auto -1.5rem}.wrapper :global .submit-btn{margin-top:3rem;margin-bottom:0}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "AddCompanyModal_wrapper__1Aa4l"
};
export default ___CSS_LOADER_EXPORT___;
