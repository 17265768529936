import { connect } from "react-redux";
import { SelectProps } from "antd/es/select";

import View from "./View";

const mapStateToProps = () => ({});
const mapDispatchToProps = {};

interface IProps extends SelectProps<any> {
  label: string;
  name: string;
  defaultValue?: number;
}
export default connect<any, any, IProps>(
  mapStateToProps,
  mapDispatchToProps
)(View);
