import React, { useState } from "react";
import ExportPayments from "src/bepaid/assets/images/common/export_payments.svg";
import ExportPaymentsHover from "src/bepaid/assets/images/common/export_payments_hover.svg";

import Image from "src/bepaid/components/Image";

const Component = (props: any) => {
  const { onClickExport } = props;

  const [isHover, setIsHover] = useState(false);

  const setHoverImage = (value: boolean) => {
    setIsHover(value);
  };
  

  return (
    <Image
      onMouseEnter={() => setHoverImage(true)}
      onMouseLeave={() => setHoverImage(false)}
      src={isHover ? ExportPaymentsHover : ExportPayments}
      onClick={onClickExport}
    />
  );
};

export default Component;
