// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EditCustomerModal_modalContainer__2TSVV .am-modal-content{border-radius:26px !important}.EditCustomerModal_wrapper__3PwLg{max-width:450px;margin:auto}", "",{"version":3,"sources":["webpack://./src/bepaid/shared/modals/CustomerEditPageModal/EditCustomerModal.module.scss"],"names":[],"mappings":"AAAA,2DAA0C,6BAA6B,CAAC,kCAAS,eAAe,CAAC,WAAW","sourcesContent":[".modalContainer :global .am-modal-content{border-radius:26px !important}.wrapper{max-width:450px;margin:auto}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalContainer": "EditCustomerModal_modalContainer__2TSVV",
	"wrapper": "EditCustomerModal_wrapper__3PwLg"
};
export default ___CSS_LOADER_EXPORT___;
