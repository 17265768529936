import store from "src/ipm-shared/store";

import {
  Action,
  AnyAction,
  combineReducers,
  Reducer,
  ReducersMapObject
} from "redux";

class ReducerFactory {
  private registeredReducer: ReducersMapObject<any, any> = {};

  public createRootReducer<S, A extends Action = AnyAction>(
    reducers: ReducersMapObject<S, A>
  ): Reducer<S, A> {
    this.registeredReducer = Object.assign(this.registeredReducer, reducers);
    const appReducer = combineReducers(this.registeredReducer);

    return (state: S, action: A) => {
      return appReducer(state, action);
    };
  }

  /**
   * Register new reducer whenever page is about to load.
   *
   * @param {ReducersMapObject<S, A extends Action>} reducers
   */
  public registerReducer<S, A extends Action = AnyAction>(
    reducers: ReducersMapObject<S, A>
  ) {
    const nextRootReducer = this.createRootReducer(reducers);
    store.replaceReducer(nextRootReducer as any);
  }
}

export default new ReducerFactory();
