import React, { useState } from "react";
import _get from "lodash-es/get";

// import { history } from "src/ipm-shared/store";

// import ROUTES from "src/bepaid/routes";
// import { invokeMessage } from "src/bepaid/components/Message";
import DeleteModal from "src/bepaid/components/Modal/components/DeleteModal";
import { invokeMessage } from "src/bepaid/components/Message";
import { FetchModalID } from "../PaymentDuplicateModal/actions";

const View = (props: any) => {
  const { userId, accountId } = props.extraInfo;

  const [isDeleting, setIsDeleting] = useState(false);

  const closeAllModal = () => {
    props.toggleModal(FetchModalID.USER_DELETE_MODAL);
  };

  const handleDeleteUser = () => {
    if (!userId) {
      return;
    }
    props.deleteAccountUser(
      userId,
      accountId,
      () => {
        invokeMessage("success", "User deleted.");
        closeAllModal();
      },
      setIsDeleting
    );
  };

  return (
    <DeleteModal
      title={"Delete this user"}
      isLoading={isDeleting}
      description={
        <span>
          This will permanently delete this user and it cannot be undone.
        </span>
      }
      yesLabel={`Yes, delete user`}
      yesLabelLoading={`Deleting...`}
      noLabel={`No, keep user`}
      onYes={handleDeleteUser}
      onNo={props.toggleModal.bind(null, props.modalId)}
    />
  );
};

export default View;
