/* tslint:disable:object-literal-sort-keys */
import { getType } from "typesafe-actions";
import * as types from "./types";
import * as actions from "./actions";
import _isEmpty from "lodash-es/isEmpty";

export type FirstTimeFetchData = {
  payments?: boolean;
  fetch_dashboard?: boolean;
  pay_dashboard?: boolean;
  fetch_activities?: boolean;
  pay_activities?: boolean;
  customers?: boolean;
  invoices?: boolean;
  recipients_landlords?: boolean;
  recipients_employees?: boolean;
  recipients_suppliers?: boolean;
  recipients_business_employees?: boolean;
  recipients_insurances?: boolean;
};

export type PayFetchRecentActivities = {
  total?: number;
  data?: {
    recipientName: string;
    firstName: string;
    lastName: string;
    receiptNumber: string;
    invoiceId: number;
    date: string;
    activity: string;
    type: string;
    isDone: boolean;
  };
};

export type FetchState = {
  readonly dashboardStatistics: {
    numberActiveCustomers?: number;
    numberInvoiceSent?: number;
    totalPaymentsReceived?: number;
    numberOfActiveRecipients?: number;
    numberOfPaymentsMade?: number;
    totalPaymentsMade?: number;
    numberLandlords?: number;
    numberEmployees?: number;
    numberSuppliers?: number;
    numberBankAccounts?: number;
    totalPaymentAmount?: number;
    averagePaymentAmount?: number;
    recurringPayment?: number;
    oneTimePayment?: number;
  };
  readonly invoiceStatistics: {
    numberInvoiceSent?: number;
    percentagePunctualInvoices?: number;
    totalPaymentsReceived?: number;
  };
  readonly invoiceList: any[];
  readonly invoiceListModal: any[];
  readonly customerStatistics: {
    numberActiveCustomers?: number;
    numberNewCustomers?: number;
    totalCustomers?: number;
    totalInvoices?: number;
  };
  readonly customerList: any[];
  readonly invoiceInsights: {
    averageDaysPayment?: number;
    averageInvoiceAmount?: number;
    onTimeInvoices?: number;
    percentagePunctualInvoices?: number;
    totalCustomers?: number;
    totalInvoices?: number;
  };
  selectedInvoiceId?: number | null;
  readonly payInsights: {
    totalPaymentAmount?: number;
    averagePaymentAmount?: number;
    recurringPayment?: number;
    oneTimePayment?: number;
  };
  readonly firstTimeFetchData: FirstTimeFetchData;
  readonly confirmedMyInfo?: boolean;
  readonly totalInvoices?: number;
  readonly payFetchRecentActivities?: PayFetchRecentActivities;
};

const defaultState: FetchState = {
  dashboardStatistics: {},
  invoiceStatistics: {},
  invoiceList: [],
  invoiceListModal: [],
  customerStatistics: {},
  customerList: [],
  invoiceInsights: {},
  selectedInvoiceId: null,
  payInsights: {},
  firstTimeFetchData: {
    fetch_dashboard: false,
    pay_dashboard: false,
    fetch_activities: false,
    pay_activities: false,
    customers: false,
    invoices: false,
    recipients_landlords: false,
    recipients_employees: false,
    recipients_suppliers: false,
    recipients_business_employees: false,
    payments: false
  },
  totalInvoices: 0,
  payFetchRecentActivities: {}
};

export default (state: FetchState, action: types.Action) => {
  if (_isEmpty(state)) {
    state = defaultState;
  }

  switch (action.type) {
    case getType(actions.setDashboardStatistics):
      return {
        ...state,
        dashboardStatistics: action.payload.result || state.dashboardStatistics
      };

    case getType(actions.setInvoiceStatistics):
      return {
        ...state,
        invoiceStatistics: action.payload.result || state.invoiceStatistics
      };

    case getType(actions.setInvoiceList):
      return {
        ...state,
        invoiceList: action.payload.result || state.invoiceList,
        totalInvoices: action.payload.total
      };
    case getType(actions.setInvoiceListModal):
      return {
        ...state,
        invoiceListModal: action.payload.result || state.invoiceListModal
      };
    case getType(actions.setCustomerStatistics):
      return {
        ...state,
        customerStatistics: action.payload.result || state.customerStatistics
      };

    case getType(actions.setCustomerList):
      const { forceRefresh = true } = action.payload.options || {};
      return {
        ...state,
        customerList: forceRefresh
          ? action.payload.result || state.customerList
          : [...state.customerList, ...action.payload.result]
      };

    case getType(actions.setInvoiceInsights):
      return {
        ...state,
        invoiceInsights: action.payload.result || state.invoiceInsights
      };
    case getType(actions.setPayInsights):
      return {
        ...state,
        payInsights: action.payload.result || state.payInsights
      };
    case getType(actions.selectInvoice):
      return {
        ...state,
        selectedInvoiceId: action.payload.id
      };

    case getType(actions.setFirstTimeFetchData):
      return {
        ...state,
        firstTimeFetchData: {
          ...state.firstTimeFetchData,
          ...action.payload
        }
      };
    case getType(actions.setConfirmedMyInfo):
      return {
        ...state,
        confirmedMyInfo: action.payload
      };
    case getType(actions.setPayFetchRecentActivities):
      return {
        ...state,
        payFetchRecentActivities: action.payload
      };
  }
  return state;
};
