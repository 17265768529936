import _isEmpty from "lodash-es/isEmpty";

export const getUtmLink = (link: string) => {
  const s = window.location.search;
  if (s && !_isEmpty(s)) {
    return `${link}${s}`;
  }

  return link;
};
