/* tslint:disable */
// tslint:disable-object-literal-sort-keys
const t = [
  { id: 68, code: "RON", prefix_symbol: null, postfix_symbol: null },
  { id: 47, code: "HUF", prefix_symbol: null, postfix_symbol: null },
  { id: 20, code: "BOB", prefix_symbol: null, postfix_symbol: null },
  { id: 76, code: "XPF", prefix_symbol: null, postfix_symbol: null },
  { id: 39, code: "DZD", prefix_symbol: null, postfix_symbol: null },
  { id: 6, code: "AMD", prefix_symbol: null, postfix_symbol: null },
  { id: 72, code: "TRY", prefix_symbol: null, postfix_symbol: null },
  { id: 29, code: "CLP", prefix_symbol: null, postfix_symbol: null },
  { id: 19, code: "BND", prefix_symbol: null, postfix_symbol: null },
  { id: 26, code: "CAD", prefix_symbol: "C$", postfix_symbol: null },
  { id: 9, code: "ARS", prefix_symbol: null, postfix_symbol: null },
  { id: 44, code: "FJD", prefix_symbol: null, postfix_symbol: null },
  { id: 36, code: "DJF", prefix_symbol: null, postfix_symbol: null },
  { id: 8, code: "AOA", prefix_symbol: null, postfix_symbol: null },
  { id: 21, code: "BRL", prefix_symbol: null, postfix_symbol: null },
  { id: 11, code: "AUD", prefix_symbol: "A$", postfix_symbol: null },
  { id: 63, code: "NZD", prefix_symbol: "NZ$", postfix_symbol: null },
  { id: 2, code: "MYR", prefix_symbol: "RM", postfix_symbol: null },
  { id: 31, code: "COP", prefix_symbol: null, postfix_symbol: null },
  { id: 33, code: "CUP", prefix_symbol: null, postfix_symbol: null },
  { id: 27, code: "CDF", prefix_symbol: null, postfix_symbol: null },
  { id: 41, code: "ERN", prefix_symbol: null, postfix_symbol: null },
  { id: 48, code: "IDR", prefix_symbol: "Rp", postfix_symbol: null },
  { id: 40, code: "EGP", prefix_symbol: null, postfix_symbol: null },
  { id: 70, code: "SEK", prefix_symbol: null, postfix_symbol: null },
  { id: 78, code: "GBP", prefix_symbol: "£", postfix_symbol: null },
  { id: 3, code: "HKD", prefix_symbol: "HK$", postfix_symbol: null },
  { id: 67, code: "QAR", prefix_symbol: null, postfix_symbol: null },
  { id: 42, code: "ETB", prefix_symbol: null, postfix_symbol: null },
  { id: 59, code: "LKR", prefix_symbol: null, postfix_symbol: null },
  { id: 1, code: "SGD", prefix_symbol: "S$", postfix_symbol: null },
  { id: 57, code: "KRW", prefix_symbol: null, postfix_symbol: null },
  { id: 35, code: "CZK", prefix_symbol: null, postfix_symbol: null },
  { id: 54, code: "KES", prefix_symbol: null, postfix_symbol: null },
  { id: 32, code: "CRC", prefix_symbol: null, postfix_symbol: null },
  { id: 71, code: "THB", prefix_symbol: "฿", postfix_symbol: null },
  { id: 73, code: "USD", prefix_symbol: "US$", postfix_symbol: null },
  { id: 49, code: "ILS", prefix_symbol: null, postfix_symbol: null },
  { id: 10, code: "VND", prefix_symbol: null, postfix_symbol: "₫" },
  { id: 24, code: "BYN", prefix_symbol: null, postfix_symbol: null },
  { id: 22, code: "BTN", prefix_symbol: null, postfix_symbol: null },
  { id: 65, code: "PHP", prefix_symbol: "₱", postfix_symbol: null },
  { id: 69, code: "SAR", prefix_symbol: null, postfix_symbol: null },
  { id: 14, code: "BAM", prefix_symbol: null, postfix_symbol: null },
  { id: 75, code: "XOF", prefix_symbol: null, postfix_symbol: null },
  { id: 77, code: "ZAR", prefix_symbol: null, postfix_symbol: null },
  { id: 60, code: "MXN", prefix_symbol: null, postfix_symbol: null },
  { id: 61, code: "NOK", prefix_symbol: null, postfix_symbol: null },
  { id: 58, code: "KWD", prefix_symbol: null, postfix_symbol: null },
  { id: 34, code: "CVE", prefix_symbol: null, postfix_symbol: null },
  { id: 80, code: "CNH", prefix_symbol: "", postfix_symbol: null },
  { id: 46, code: "HRK", prefix_symbol: null, postfix_symbol: null },
  { id: 37, code: "DKK", prefix_symbol: null, postfix_symbol: null },
  { id: 25, code: "BZD", prefix_symbol: null, postfix_symbol: null },
  { id: 13, code: "AZN", prefix_symbol: null, postfix_symbol: null },
  { id: 17, code: "BHD", prefix_symbol: null, postfix_symbol: null },
  { id: 18, code: "BIF", prefix_symbol: null, postfix_symbol: null },
  { id: 56, code: "KMF", prefix_symbol: null, postfix_symbol: null },
  { id: 5, code: "ALL", prefix_symbol: null, postfix_symbol: null },
  { id: 55, code: "KHR", prefix_symbol: null, postfix_symbol: null },
  { id: 50, code: "INR", prefix_symbol: "₹", postfix_symbol: null },
  { id: 4, code: "AED", prefix_symbol: null, postfix_symbol: null },
  { id: 28, code: "CHF", prefix_symbol: null, postfix_symbol: null },
  { id: 15, code: "BDT", prefix_symbol: null, postfix_symbol: null },
  { id: 64, code: "OMR", prefix_symbol: null, postfix_symbol: null },
  { id: 74, code: "XAF", prefix_symbol: null, postfix_symbol: null },
  { id: 23, code: "BWP", prefix_symbol: null, postfix_symbol: null },
  { id: 7, code: "ANG", prefix_symbol: null, postfix_symbol: null },
  { id: 62, code: "NPR", prefix_symbol: null, postfix_symbol: null },
  { id: 79, code: "TWD", prefix_symbol: "NT$", postfix_symbol: null },
  { id: 12, code: "AWG", prefix_symbol: null, postfix_symbol: null },
  { id: 53, code: "JPY", prefix_symbol: "JP¥", postfix_symbol: null },
  { id: 30, code: "CNY", prefix_symbol: "CN¥", postfix_symbol: null },
  { id: 52, code: "JMD", prefix_symbol: null, postfix_symbol: null },
  { id: 45, code: "FKP", prefix_symbol: null, postfix_symbol: null },
  { id: 16, code: "BGN", prefix_symbol: null, postfix_symbol: null },
  { id: 38, code: "DOP", prefix_symbol: null, postfix_symbol: null },
  { id: 43, code: "EUR", prefix_symbol: "€", postfix_symbol: null },
  { id: 51, code: "IRR", prefix_symbol: null, postfix_symbol: null },
  { id: 66, code: "PLN", prefix_symbol: null, postfix_symbol: null }
];
export default t;
