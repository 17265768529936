/* tslint:disable:object-literal-sort-keys */
export const INVOICE_ACTIVITIES = {
  invoice_sent: {
    pastTenseLabel: "Invoice sent",
    label: "Sent invoice",
    value: "invoice_sent"
  },
  due_date_missed: {
    pastTenseLabel: "Due date missed",
    label: "Invoice due",
    value: "due_date_missed"
  },
  paid: {
    pastTenseLabel: "Invoice paid",
    label: "Paid",
    value: "paid"
  },
  early_payment_discount_offered: {
    pastTenseLabel: "Early payment discount offered",
    label: "Offer early payment discount",
    value: "early_payment_discount_offered"
  },
  late_payment_remediation_offered: {
    pastTenseLabel: "Late payment fee offered",
    label: "Offer late payment fee",
    value: "late_payment_remediation_offered"
  },
  immediate_payment_discount_offered: {
    pastTenseLabel: "Immediate payment discount offered",
    label: "Offer immediate payment discount",
    value: "immediate_payment_discount_offered"
  },
  installment_plan_offered: {
    pastTenseLabel: "Installment plan offered",
    label: "Offer installment plan",
    value: "installment_plan_offered"
  }
  // sent_sms_reminder: {
  //   pastTenseLabel: "SMS reminder sent",
  //   label: "Send SMS reminder",
  //   value: "sent_sms_reminder"
  // }
};

export const PAYMENT_ACTIVITIES = {
  money_transfer: {
    paid: {
      pastTenseLabel: "Money transfer paid",
      label: "Money transfer paid",
      value: "money_transfer_paid"
    },
    scheduled: {
      pastTenseLabel: "Money transfer scheduled",
      label: "Money transfer scheduled",
      value: "money_transfer_scheduled"
    },
    cancelled: {
      pastTenseLabel: "Money transfer cancelled",
      label: `Money transfer`,
      value: "money_transfer_cancelled"
    },
    schedule_cancelled: {
      pastTenseLabel: "Scheduled Money transfers cancelled",
      label: `Scheduled Money transfers`,
      value: "scheduled_money_transfer_cancelled"
    }
  },
  rent: {
    paid: {
      pastTenseLabel: "Rent paid",
      label: "Rent paid",
      value: "rent_paid"
    },
    scheduled: {
      pastTenseLabel: "Rent payment scheduled",
      label: "Rent payment scheduled",
      value: "rent_scheduled"
    },
    cancelled: {
      pastTenseLabel: "Rent payment cancelled",
      label: "Rent payment",
      value: "rent_cancelled"
    },
    schedule_cancelled: {
      pastTenseLabel: "Scheduled Rent payments cancelled",
      label: `Scheduled Rent payments`,
      value: "scheduled_rent_cancelled"
    }
  },
  invoice: {
    paid: {
      pastTenseLabel: "Invoice paid",
      label: "Invoice paid",
      value: "invoice_paid"
    },
    scheduled: {
      pastTenseLabel: "Invoice payment scheduled",
      label: "Invoice payment scheduled",
      value: "invoice_scheduled"
    },
    cancelled: {
      pastTenseLabel: "Invoice payment cancelled",
      label: "Invoice payment",
      value: "invoice_cancelled"
    },
    schedule_cancelled: {
      pastTenseLabel: "Scheduled Invoice payments cancelled",
      label: `Scheduled Invoice payments`,
      value: "scheduled_invoice_cancelled"
    }
  },
  tax: {
    paid: {
      pastTenseLabel: "Tax paid",
      label: "Tax paid",
      value: "tax_paid"
    },
    scheduled: {
      pastTenseLabel: "Tax payment scheduled",
      label: "Tax payment scheduled",
      value: "tax_scheduled"
    },
    cancelled: {
      pastTenseLabel: "Tax payment cancelled",
      label: "Tax payment",
      value: "tax_cancelled"
    },
    schedule_cancelled: {
      pastTenseLabel: "Scheduled Tax payments cancelled",
      label: `Scheduled Tax payments`,
      value: "scheduled_tax_cancelled"
    }
  },
  salary: {
    paid: {
      pastTenseLabel: "Salary paid",
      label: "Salary paid",
      value: "salary_paid"
    },
    scheduled: {
      pastTenseLabel: "Salary payment scheduled",
      label: "Salary payment scheduled",
      value: "salary_scheduled"
    },
    cancelled: {
      pastTenseLabel: "Salary payment cancelled",
      label: "Salary payment",
      value: "salary_cancelled"
    },
    schedule_cancelled: {
      pastTenseLabel: "Scheduled Salary payments cancelled",
      label: `Scheduled Salary payments`,
      value: "scheduled_salary_cancelled"
    }
  },
  insurance: {
    paid: {
      pastTenseLabel: "Insurance paid",
      label: "Insurance paid",
      value: "insurance_paid"
    },
    scheduled: {
      pastTenseLabel: "Insurance payment scheduled",
      label: "Insurance payment scheduled",
      value: "insurance_scheduled"
    },
    cancelled: {
      pastTenseLabel: "Insurance payment cancelled",
      label: "Insurance payment",
      value: "insurance_cancelled"
    },
    schedule_cancelled: {
      pastTenseLabel: "Scheduled Insurance payments cancelled",
      label: `Scheduled Insurance payments`,
      value: "scheduled_insurance_cancelled"
    }
  }
};
