export interface IbanBreakdown {
  country_code: string;
  check_digits?: string;
  bank_code?: string;
  branch_code?: string;
  sort_code?: string;
  bank_account_number?: string;
  national_check_digit?: string;
}

// Add the key with right position to show on FE
export const IBAN_BREAKDOWN: IbanBreakdown[] = [
  {
    country_code: "AT",
    check_digits: "48",
    bank_code: "32000",
    bank_account_number: "00012345864"
  },
  {
    country_code: "BE",
    check_digits: "71",
    bank_code: "096",
    bank_account_number: "1234567",
    national_check_digit: "69"
  },
  {
    country_code: "CY",
    check_digits: "21",
    bank_code: "002",
    branch_code: "00195",
    bank_account_number: "0000357001234567"
  },
  {
    country_code: "EE",
    check_digits: "47",
    bank_code: "10",
    branch_code: "00",
    bank_account_number: "00102014568",
    national_check_digit: "5"
  },
  {
    country_code: "FI",
    check_digits: "14",
    bank_code: "100930",
    bank_account_number: "0012345",
    national_check_digit: "8"
  },
  {
    country_code: "FR",
    check_digits: "76",
    bank_code: "30006",
    branch_code: "00001",
    bank_account_number: "12345678901",
    national_check_digit: "89"
  },
  {
    country_code: "DE",
    check_digits: "75",
    bank_code: "51210800",
    bank_account_number: "1245126199"
  },
  {
    country_code: "GR",
    check_digits: "96",
    bank_code: "081",
    branch_code: "0001",
    bank_account_number: "0000001234567890"
  },
  {
    country_code: "IE",
    check_digits: "64",
    bank_code: "IRCE",
    branch_code: "920501",
    bank_account_number: "12345678"
  },
  {
    country_code: "IT",
    check_digits: "60",
    national_check_digit: "X",
    bank_code: "05428",
    branch_code: "11101",
    bank_account_number: "000000123456"
  },
  {
    country_code: "LV",
    check_digits: "97",
    bank_code: "HABA",
    bank_account_number: "0012345678910"
  },
  {
    country_code: "LT",
    check_digits: "60",
    bank_code: "10100",
    bank_account_number: "12345678901"
  },
  {
    country_code: "LU",
    check_digits: "12",
    bank_code: "001",
    bank_account_number: "0001234567891"
  },
  {
    country_code: "MT",
    check_digits: "31",
    bank_code: "MALT",
    branch_code: "01100",
    bank_account_number: "000000000000000123"
  },
  {
    country_code: "NL",
    check_digits: "02",
    bank_code: "ABNA",
    bank_account_number: "0123456789"
  },
  {
    country_code: "PT",
    check_digits: "50",
    bank_code: "0001",
    branch_code: "0123",
    bank_account_number: "12345678901",
    national_check_digit: "92"
  },
  {
    country_code: "SK",
    check_digits: "89",
    bank_code: "7500",
    bank_account_number: "0000000012345671"
  },
  {
    country_code: "SI",
    check_digits: "56",
    bank_code: "19",
    branch_code: "200",
    bank_account_number: "12346578",
    national_check_digit: "92"
  },
  {
    country_code: "ES",
    check_digits: "79",
    bank_code: "2100",
    branch_code: "0813",
    national_check_digit: "61",
    bank_account_number: "0123456789"
  },
  {
    country_code: "GB",
    check_digits: "33",
    bank_code: "BUKB",
    sort_code: "202015",
    bank_account_number: "55555555"
  }
];
