import * as React from "react";
import classNames from "classnames";
import { IBaseProps, IBaseState } from "./lib/Base";
import _findIndex from "lodash-es/findIndex";
import BaseControl from "./lib/Base";
import Dropdown from "reactstrap/lib/Dropdown";
import DropdownToggle from "reactstrap/lib/DropdownToggle";
import DropdownMenu from "reactstrap/lib/DropdownMenu";
import { connect } from "react-redux";
import * as actions from "../actions";
import * as selectors from "../selectors";
import { RootState } from "src/ipm-shared/store/model/reducers";
import _get from "lodash-es/get";
import LoaderIcon from "src/ipm-shared/components/LoaderIcon";
import { ControlErrorType, ControlValueType } from "../types";

export type ISelectOption = {
  label: string;
  value: any;
};

export type ISelectProps = IBaseProps & {
  isLoading?: boolean;
  options: ISelectOption[];
  labelClassName?: string;
  itemClassName?: string;
  fixedLabel?: string;
  preventFromInitChange?: boolean;
  placeholderSearch?: string;
  defaultNotFound?: string;
  labelHidden?: boolean;
  onChangeSeleted?: (args: any) => any;
};

const mapStateToProps = (
  state: RootState,
  props: ISelectProps
): {
  control: ReturnType<typeof selectors.getControl>;
} => ({
  control: selectors.getControl(state, props.name)
});

const mapDispatchToProps = {
  removeControl: actions.removeControl,
  setControl: actions.setControl
};

type ISelectState = IBaseState & {
  dropdownShown: boolean;
  inputSearch: string;
};

type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  ISelectProps;

/**
 * This is one of common controls in the entire app.
 * Use this when you want to show a drop-down list.
 *
 * @base BaseControl.tsx
 */
class AutoCompleteInput extends BaseControl<IProps, ISelectState> {
  public static defaultProps = {
    ...BaseControl.defaultProps
  };

  constructor(props: IProps) {
    super(props);
    this.state = {
      dropdownShown: false,
      inputSearch: ""
    };
  }

  public componentDidMount() {
    const { name, defaultValue, form, control, options } = this.props;

    if (this.shouldRevertValueOnMount(control)) {
      return;
    }

    // Init control
    this.props.setControl({
      errors: [],
      form,
      group: this.props.group,
      name,
      value: defaultValue
    });

    if (defaultValue) {
      const foundOption = options.find(
        (o: ISelectOption) => o.value === defaultValue
      );

      if (foundOption) {
        this.setState({
          inputSearch: foundOption.label as string
        });
      }
    } else {
      this.doValidate(this.props.control.value, this.props.control.name, false);
    }
  }

  public componentWillUnmount() {
    this.alive = false;

    if (!this.props.reserveValueOnUnmount) {
      this.props.removeControl(this.props.name);
    }
  }

  public componentWillReceiveProps(nextProps: Readonly<IProps>): void {
    if (
      nextProps.control.forceRevalidate !== this.props.control.forceRevalidate
    ) {
      this.doValidate(nextProps.control.value, nextProps.control.name, true);
    }
  }

  public render() {
    const {
      control,
      labelClassName,
      fixedLabel,
      options,
      label,
      isLoading
    } = this.props;
    const labelSearch = this.getLabel();

    if (control.notFound) {
      return null;
    }

    return (
      <>
        {!this.props.labelHidden && <span className="label">{label}</span>}
        <div className="select-multi-dropdown w-100">
          <Dropdown
            className={this.props.className}
            isOpen={this.state.dropdownShown && options.length > 0}
            toggle={this.toggleDropDown}
          >
            <DropdownToggle
              disabled={this.props.disabled}
              tag="span"
              className={classNames(
                "form-control form-auto-complete",
                labelClassName,
                {
                  "is-invalid text-danger":
                    control.displayError && control.errors.length > 0,
                  "pr-0": control.displayError && control.errors.length > 0,
                  "required-input": this.props.required
                }
              )}
              caret={false}
              tabIndex={0}
            >
              <input
                ref={(ref: HTMLInputElement) => (this.ref = ref)}
                className={"form-control form-control-search"}
                type="text"
                name={"auto_complete_input_search_field"}
                id={"auto_complete_input_search_field"}
                placeholder={fixedLabel ? fixedLabel : labelSearch}
                value={this.state.inputSearch}
                onChange={this.filterList}
                autoComplete={"off"}
              />
            </DropdownToggle>

            {options.length > 0 && (
              <DropdownMenu tag="ul" className={"form-auto-complete-dropdown"}>
                <>
                  {options.map((o, idx) => {
                    return this.renderDropdownItem(o, idx);
                  })}
                </>
              </DropdownMenu>
            )}
            {this.renderErrorMessage(control)}
          </Dropdown>
          {isLoading && (
            <LoaderIcon
              className={"loader-input-icon"}
              type={"line-scale"}
              color="#00A9BE"
              active={true}
            />
          )}
        </div>
      </>
    );
  }

  private filter = (p: ISelectOption, value: string) => {
    if (!value) {
      return true;
    }
    if (p.label.toLowerCase().search(value.toLowerCase()) !== -1) {
      return true;
    }
    return false;
  };

  private filterList = (e: React.ChangeEvent<any>) => {
    const value = e.target.value;
    this.setState(
      {
        inputSearch: value
      },
      () => {
        if (this.props.onChangeCustom) {
          this.props.onChangeCustom(value);
        } else {
          this.props.options.filter(p => {
            return this.filter(p, value);
          });
        }
      }
    );
  };

  private getLabel(): string {
    const { options, control, placeholder } = this.props;
    let label: string = "";
    label = placeholder ? placeholder : label;
    const value = _get(control, "value.regno", "");
    if (value) {
      const selectedObj = options.find(item => item.value.regno === value);
      if (selectedObj) {
        label = selectedObj.label;
      }
    }
    return label;
  }

  private renderDropdownItem = (
    optionsChildren: ISelectOption,
    idx: number
  ) => {
    return (
      <li
        key={idx}
        className={"dropdown-item"}
        onClick={this.onChooseOption.bind(this, optionsChildren)}
      >
        <span className={"text-white"} title={`${optionsChildren.label}`}>
          {optionsChildren.label}
        </span>
      </li>
    );
  };

  private toggleDropDown = () => {
    if (!this.state.dropdownShown) {
      this.ref.focus();
    } else {
      this.ref.blur();
    }
    this.setState({
      dropdownShown: !this.state.dropdownShown
    });
  };

  private onChooseOption = (
    option: ISelectOption,
    e: React.ChangeEvent<any>
  ) => {
    e.preventDefault();
    const { name, form } = this.props;
    const value = option.value;

    this.props.setControl({
      errors: [],
      form,
      name,
      value
    });

    this.toggleDropDown();
    this.setState({
      inputSearch: option.label
    });
    if (this.props.onChangeSeleted) {
      this.props.onChangeSeleted(value);
    }
  };

  private doValidate = (
    value: ControlValueType,
    name: string,
    displayError: boolean = true,
    isInitChange: boolean = false
  ) => {
    const it = this.validate(value);
    let result: any;
    const errors: ControlErrorType[] = [];

    while (!(result = it.next()).done) {
      errors.push(result.value);
    }

    const it2 = AutoCompleteInput.baseValidate(this.props, value);
    while (!(result = it2.next()).done) {
      errors.push(result.value);
    }

    if (errors.length === 0) {
      if (isInitChange && this.props.preventFromInitChange) {
        // do nothing
      } else {
        if (this.props.onChangeCustom && this.alive) {
          this.props.onChangeCustom(value);
        }
      }
    } else {
      this.props.setControl({
        displayError,
        errors,
        name
      });
    }
  };
  // tslint:disable-next-line:no-empty
  private *validate(value: ControlValueType) {}
}

export default connect(mapStateToProps, mapDispatchToProps)(AutoCompleteInput);
