import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { Router, Switch, Route } from "react-router-dom";
import { isDesktop } from "react-device-detect";
// import * as WebFont from "webfontloader";

import store, { history, sagaMiddleware } from "src/ipm-shared/store";
import rootSaga from "src/ipm-shared/store/model/sagas";
import Logger from "src/ipm-shared/Utils/Logger";

import IntlProvider from "src/ipm-shared/components/Intl/IntlProvider";
import GlobalUI from "src/ipm-shared/components/GlobalUI";
import IntercomUtil from "src/ipm-shared/Utils/Intercom";
import IPMContext from "src/ipm-shared/Utils/IPMContext";

import ROUTES from "src/bepaid/routes";

import SplashScreenGif from "./assets/gifs/ipaymy_splash_screen.gif";
import SplashScreenLogo from "./assets/images/ipaymy_logo.svg";

import Image from "./components/Image";

import * as Pages from "./pages";
import AuthRoute from "./pages/AuthRoute";

import withAuth from "./HOC/withAuth";

import { overrideGlobalUIModalLookup } from "./components/Modal";

// using LESS for Antd library
import "./assets/styles/main.less";
import "./assets/styles/main.scss";
import FacebookPixelProvider from "src/ipm-shared/components/FacebookPixelProvider";
import LogRocket from "logrocket";
import Cookie from "../ipm-shared/Utils/Cookie";
import queryString from "query-string";

const qs = queryString.parse(window.location.search.replace("?", ""));
if (qs.with_log === "y") {
  Cookie.setCookie("log_rocket_enabled", "y");
}

if (Cookie.getCookie("log_rocket_enabled") === "y") {
  LogRocket.init("cxe8zt/ipaymy");
}

IntercomUtil.guestInit();

IPMContext.initSession();

window.Logger = Logger;

sagaMiddleware.run(rootSaga());

// WebFont.load({
//   custom: {
//     families: [
//       "Proxima Nova Thin",
//       "Proxima Nova Light",
//       "Proxima Nova Regular",
//       "Proxima Nova Bold",
//       "Proxima Nova Bold Italic",
//       "Proxima Nova Extra Bold"
//     ],
//     urls: ["./assets/styles/scss/font.scss"]
//   }
// });

const App: React.FC = () => {
  useEffect(() => {
    if (!isDesktop) {
      const appHeight = () => {
        const doc = document.documentElement;
        doc.style.setProperty("--app-height", `${window.innerHeight}px`);
      };

      appHeight();
    }

    history.listen(() => {
      window.vgo("process");
      window.vgo("update");
    });
  }, []);

  return (
    <Provider store={store}>
      <IntlProvider>
        <FacebookPixelProvider pixelId={"1077166438999750"}>
          <GlobalUI modalLookup={overrideGlobalUIModalLookup}>
            <Router history={history}>
              <Switch>
                <Route path={ROUTES.LOGIN} component={Pages.Login} />
                <Route
                  path={ROUTES.RESET_PASSWORD}
                  component={Pages.ResetPassword}
                />
                <Route
                  path={ROUTES.SET_NEW_PASSWORD}
                  component={Pages.SetNewPassword}
                />
                <Route
                  path={ROUTES.PASSWORD_RESET}
                  component={Pages.PasswordResetConfirmation}
                />

                <Route path={ROUTES.REGISTER} component={Pages.Register} />
                <Route
                  path={ROUTES.POST_REGISTER}
                  component={Pages.PostRegister}
                />
                <Route
                  path={ROUTES.VERIFICATION_MOBILE}
                  component={Pages.VerificationMobile}
                />
                <Route
                  path={ROUTES.VERIFICATION_EXPIRED}
                  component={Pages.VerificationExpired}
                />

                <Route
                  path={ROUTES.CLOSE_ACCOUNT}
                  component={Pages.ClosedAccountThankYou}
                />
                <Route path={ROUTES.CHECKOUT} component={Pages.Checkout} />
                <Route
                  path={ROUTES.CHECKOUT_PROCESSED}
                  component={Pages.CheckoutProcessed}
                />
                <Route
                  path={ROUTES.CHECKOUT_CANCEL}
                  component={Pages.CheckoutCancel}
                />
                <Route
                  path={ROUTES.CHECKOUT_OVERDUE}
                  component={Pages.CheckoutOverdue}
                />
                <Route
                  path={ROUTES.CHECKOUT_CONFIRMATION}
                  component={Pages.CheckoutConfirmation}
                />
                <Route
                  path={ROUTES.CHECKOUT_LINK_FAIL}
                  component={Pages.CheckoutLinkFail}
                />
                <Route
                  path={ROUTES.UNSUBSCRIBE_EMAIL}
                  component={Pages.UnsubscribeEmail}
                />
                <Route path={ROUTES.ROOT} component={withAuth(AuthRoute)} />
              </Switch>
            </Router>
            <Image
              src={!isDesktop ? SplashScreenGif : SplashScreenLogo}
              id={"splash_screen_img"}
            />
          </GlobalUI>
        </FacebookPixelProvider>
      </IntlProvider>
    </Provider>
  );
};

export default App;
