import React from "react";
import { connect } from "react-redux";
import Switch from "antd/es/switch";
import * as selectors from "src/ipm-shared/components/Form/selectors";
import { RootState } from "src/ipm-shared/store/model/reducers";

import * as actions from "src/ipm-shared/components/Form/actions";

const Component = (props: any) => {
  const {
    onChange = null,
    // getControl,
    defaultValue = false,
    disabled = false,
    checked
  } = props;

  // INFO: props in custom component should be a higher priority
  const handleChange = (...params: any[]) => {
    props.setControl({
      errors: [],
      form: props.form,
      name: props.name,
      value: params[0]
    });
    if (onChange) {
      onChange(...params);
    }
  };

  const commonProps = {
    ...props,
    onChange: handleChange
  };

  return (
    <Switch
      disabled={disabled}
      checked={checked ? checked : defaultValue}
      // checked={getControl(props.name, props.form).value}
      defaultChecked={defaultValue}
      {...commonProps}
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  getControl: (name: string, form: string) =>
    selectors.getControl(state, name, form)
});

const mapDispatchToProps = {
  setControl: actions.setControl
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
