import React from "react";
import Input from "antd/es/input";

import usePrevious from "src/bepaid/hooks/usePrevious";

export interface SingleOTPInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  focus?: boolean;
}

export function SingleOTPInputComponent(props: SingleOTPInputProps) {
  const { focus, autoFocus, ...rest } = props;

  const inputRef = React.useRef<HTMLInputElement>(null);

  const prevFocus = usePrevious(!!focus);

  React.useLayoutEffect(() => {
    if (inputRef.current) {
      if (focus && autoFocus) {
        inputRef.current.focus();
      }
      if (focus && autoFocus && focus !== prevFocus) {
        inputRef.current.focus();
        inputRef.current.select();
      }
    }
  }, [autoFocus, focus, prevFocus]);

  // @ts-ignore
  return <Input ref={inputRef} type={"tel"} {...rest} />;
}

const SingleOTPInput = React.memo(SingleOTPInputComponent);
export default SingleOTPInput;
