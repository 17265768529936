import _get from "lodash-es/get";
import _last from "lodash-es/last";
import {
  getRecipientPurpose,
  getRecipientTitle
} from "src/bepaid/utils/recipient";

export const useProcessPayeeDetail = (payee: any) => {
  const name = _get(payee, "name");
  const purpose = getRecipientPurpose(payee);
  const purposeTitle = getRecipientTitle(payee);
  const currencyId = _get(payee, "currencyId", 0);
  const receiptDefaultAmount = _get(payee, "defaultAmount", 0);
  const registrationNumber = _get(payee, "registrationNumber", "");
  const idNumber = _get(payee, "idNumber", "");
  const bankId = _get(payee, "bankId", 0);
  const accountNumber = _get(payee, "accountNumber", "");
  const postalCode = _get(payee, "postalCode", "");
  const unitNumber = _get(payee, "unitNumber", "");
  const address = _get(payee, "address", "");
  const note = _get(payee, "defaultComments", "");
  const supplierName = _get(payee, "recipientContactName", "");
  const supplierEmail = _get(payee, "email", "");
  const bankCountryId = _get(payee, "international.bankCountryId", 1);
  const bankAccountHolderName = _get(
    payee,
    "international.bankAccountHolderName"
  );
  const bankRawName = _get(payee, "international.bankRawName", "");
  const bankAccountType = _get(payee, "international.bankAccountType");
  const branchCode = _get(payee, "international.branchCode");
  const swiftCode = _get(payee, "international.bankRawSwiftCode");
  const bsbCode = _get(payee, "international.bsbCode");
  const ifscCode = _get(payee, "international.ifscCode");
  const bankCode = _get(payee, "international.bankCode");
  const aba = _get(payee, "international.aba");
  const sortCode = _get(payee, "international.sortCode");
  const iban = _get(payee, "international.iban");
  const cnaps = _get(payee, "international.cnaps");
  const rentalDueDate = _get(payee, "rentalDueDate");
  const tenancyExpiryDate = _get(payee, "tenancyExpiryDate");

  return {
    name,
    purpose,
    purposeTitle,
    currencyId,
    receiptDefaultAmount,
    registrationNumber,
    idNumber,
    bankId,
    accountNumber,
    postalCode,
    unitNumber,
    address,
    note,
    supplierName,
    supplierEmail,
    bankCountryId,
    bankAccountHolderName,
    bankRawName,
    bankAccountType,
    branchCode,
    swiftCode,
    bsbCode,
    ifscCode,
    bankCode,
    aba,
    sortCode,
    iban,
    cnaps,
    rentalDueDate,
    tenancyExpiryDate
  };
};
