import { connect } from "react-redux";

import { RootState } from "src/ipm-shared/store/model/reducers";

import View from "./View";
import * as actions from "src/bepaid/store/model/Fetch/actions";
import * as selectors from "src/bepaid/store/model/Fetch/selectors";

const mapStateToProps = (state: RootState, props: any) => ({
  accountCountryCode: selectors.getAccountCountryCode(state),
  countryId: selectors.getCurrentCountryId(state),
  extraInfo: selectors.getExtraInfo(state, props.modalId),
  isHongKongAccount: selectors.isHongKongAccount(state),
  isAustraliaAccount: selectors.isAustraliaAccount(state),
  residentialAddress: selectors.getResidentialAddress(state),
  controls: selectors.getControls(state, ""),
  paymentCountryCode: selectors.getPaymentCountryCode(state),
  paymentCurrencyCode: selectors.getPaymentCurrencyCode(state),
  currencyId: selectors.getAccountCurrencyId(state),
  getControl: (name: string, form: string) =>
    selectors.getControl(state, name, form),
  selectedPayee: selectors.selectedPayee(state),
  isBusinessAccount: selectors.getIsBusinessAccount(state)
});

const mapDispatchToProps = {
  addEmployeeSubmit: actions.addEmployeeSubmit,
  addLandlordSubmit: actions.addLandlordSubmit,
  addSupplierSubmit: actions.addSupplierSubmit,
  editEmployeeSubmit: actions.editEmployeeSubmit,
  editLandlordSubmit: actions.editLandlordSubmit,
  editSupplierSubmit: actions.editSupplierSubmit,
  editSelfTransferSubmit: actions.editSelfTransferSubmit,
  editPayee: actions.editPayee,
  fetchPayees: actions.fetchPayees,
  getAddressByPostalCode: actions.getAddressByPostalCode,
  setControl: actions.setControl,
  setPaymentCountryCode: actions.setPaymentCountryCode,
  setPaymentCurrencyCode: actions.setPaymentCurrencyCode,
  removeForm: actions.removeForm,
  selectPayees: actions.selectPayees
};

export type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(View);
