/**
 * Selectors
 *
 * It's like "views" in relational databases.
 * Store the most efficient format in the reducer state.
 * But to retrieve into easy to consume form, use selector.
 */
import { RootState } from "src/ipm-shared/store/model/reducers";
import ReducerFactory from "src/ipm-shared/Utils/ReduxReducer";
import _isEmpty from "lodash-es/isEmpty";
import _get from "lodash-es/get";
import { default as customersReducer } from "./reducers";
ReducerFactory.registerReducer({
  customer: customersReducer
});

export const isValidatedSelectCustomer = (state: RootState) =>
  state.customer.selectedCustomerIds.length > 0;

export const getCustomers = (state: RootState) => {
  const customers = state.customer.customers;
  const ids = Object.keys(customers);
  return ids.map(id => ({
    ...customers[id]
  }));
};

export const getCustomerByIds = (state: RootState) => {
  return state.customer.customers;
};

export const getTotalCustomers = (state: RootState) => state.customer.total;

export const getIsFetching = (state: RootState) => state.customer.isFetching;

export const selectedCustomer = (state: RootState) =>
  state.customer.selectedCustomerIds;

export const getEditedCustomer = (state: RootState) => {
  const customer = state.customer.customers[state.customer.editedId];
  if (customer) {
    return {
      ...customer
    };
  } else {
    return {} as any;
  }
};

export const hasCustomerAlready = (state: RootState) => {
  return !_isEmpty(state.customer.customers);
};

export const isEditedCustomer = (state: RootState) =>
  state.customer.editedId > -1;

export const getSelectedCustomersId = (state: RootState) =>
  state.customer.selectedCustomerIds;

export const getSelectedCustomers = (state: RootState) => {
  const { customers, selectedCustomerIds } = state.customer;
  return selectedCustomerIds
    .filter(id => customers[id] !== undefined)
    .map(id => ({
      ...customers[id],
      extraCustomers: _get(state.customer.extraCustomers, id, {})
    }));
};

export const getExtraCustomers = (state: RootState) =>
  state.customer.extraCustomers;
