import React, { useEffect, useState } from "react";
import classnames from "classnames";

import { history } from "src/ipm-shared/store";
import ROUTES from "src/bepaid/routes";

import SelectGroup from "src/bepaid/components/SelectGroup";
import SelectCustomer from "src/bepaid/pages/Customers/components/SelectCustomer";
import AddCustomer from "src/bepaid/components/AddCustomerForm";

import { StepRoute } from "src/bepaid/utils/invoice";

import styles from "./AddOrSelectCustomer.module.scss";

const WrapperAddCustomer = (props: any) => {
  const isAddMode =
    StepRoute[`${history.location.pathname}${history.location.search}`] === 1;

  const isFirstInvoice = props.statistics?.totalNumberOfInvoices === 0;

  const [mode, setMode] = useState(isAddMode ? "add" : "select");
  const [customerPrefilled, setcustomerPrefilled] = useState<any>({
    name: ""
  });

  const handleAdd = (searchText?: string) => {
    setcustomerPrefilled({
      name: searchText
    });
    setMode("add");
    // history.push(`${ROUTES.CREATE_INVOICES}?add_customer=y&name=${searchText}`);
  };

  const onChangeMode = (e: any) => {
    if (e.target.value === "add") {
      // remove select customer here
      props.selectCustomers([]);
    }
    setMode(e.target.value);
  };

  useEffect(() => {
    if (mode === "add") {
      props.updateStep(1);
      history.push(`${ROUTES.CREATE_INVOICES}?add_customer=y`);
    } else {
      props.updateStep(0);
      history.push(`${ROUTES.CREATE_INVOICES}`);
    }
  }, [mode]);
  useEffect(() => {
    if (props.listCustomer && props.listCustomer.length === 0) {
      setMode("add");
    }
  }, []);

  return (
    <div className={styles.wrapperCustomer}>
      <div
        className={styles.selectGroup}
        // hidden={props.listCustomer.length === 0}
      >
        <SelectGroup
          className={classnames(styles.customSelectGroup)}
          options={[
            { label: "Select customer", value: "select" },
            { label: "Add customer", value: "add" }
          ]}
          onChange={onChangeMode}
          value={mode}
          defaultValue={mode}
        />
      </div>

      <div>
        {mode === "select" && <SelectCustomer openAdd={handleAdd} />}
        {mode === "add" && (
          <div>
            <div className={styles.titleStarted} hidden={!isFirstInvoice}>
              <span>
                {"Let’s get started and create your first invoice together."}
              </span>
            </div>
            <AddCustomer customerPrefilled={customerPrefilled} />
          </div>
        )}
      </div>
    </div>
  );
};

export default WrapperAddCustomer;
