import React, { useEffect, useState } from "react";
import Checkbox from "antd/es/checkbox";
import styles from "./ChkAgreeTerms.module.scss";
import Typography from "antd/es/typography";
import classnames from "classnames";

const Component = (props: any) => {
  const { isCryptoPaymentMethod } = props;

  const [isConfirmedBTC, setIsConfirmedBTC] = useState(false);

  const [isConfirmedPayment, setIsConfirmPayment] = useState(false);

  const [isConfirmedRefundPolicy, setIsConfirmRefundPolicy] = useState(false);

  const onConfirmedBTC = () => {
    setIsConfirmedBTC((prev: any) => !prev);
  };

  const onConfirmedPayment = () => {
    setIsConfirmPayment((prev: any) => !prev);
  };

  const onConfirmedRefundPolicy = () => {
    setIsConfirmRefundPolicy((prev: any) => !prev);
  };

  useEffect(() => {
    if (isCryptoPaymentMethod) {
      props.onChange(
        isConfirmedBTC && isConfirmedPayment && isConfirmedRefundPolicy
      );
    } else {
      props.onChange(isConfirmedPayment && isConfirmedRefundPolicy);
    }
  }, [
    isConfirmedBTC,
    isConfirmedPayment,
    isConfirmedRefundPolicy,
    isCryptoPaymentMethod
  ]);
  return (
    <div className={classnames(styles.chkContainer)}>
      <Checkbox name="cf_payment_amount" onChange={onConfirmedPayment}>
        I agree to ipaymy’s{" "}
        <Typography.Link
          href="https://ipaymy.com/terms-conditions"
          target="_blank"
          underline={true}
        >
          Terms and Conditions
        </Typography.Link>{" "}
        and{" "}
        <Typography.Link
          href="https://ipaymy.com/privacy-policy"
          target="_blank"
          underline={true}
        >
          Privacy Policy
        </Typography.Link>
        , and I confirm that the payment amount and all payment details are
        correct.
      </Checkbox>
      <div className={classnames(styles.cbCrypto)}>
        <Checkbox name="cf_payment_refund" onChange={onConfirmedRefundPolicy}>
          I understand the Fee(s) is non-refundable as per ipaymy’s{" "}
          <Typography.Link
            href="https://ipaymy.com/terms-conditions"
            target="_blank"
            underline={true}
          >
            Terms and Conditions.
          </Typography.Link>
        </Checkbox>
      </div>

      {isCryptoPaymentMethod && (
        <div className={classnames(styles.cbCrypto)}>
          <Checkbox onChange={onConfirmedBTC}>
            I accept due to the evolving regulatory environment my Crypto
            payment may not be accepted and a refund will be issued. I recognise
            the price of Crypto can be volatile and the value of Crypto relative
            to respective Fiat currencies may decrease or increase between the
            time of payment and the time that we provide a refund. For more
            information view our{" "}
            <Typography.Link
              href="http://help.ipaymy.com/en/articles/8402860-crypto-payments-addendum"
              target="_blank"
              underline={true}
            >
              Crypto Terms and Conditions.
            </Typography.Link>
          </Checkbox>
        </div>
      )}
    </div>
  );
};

export default Component;
