import * as React from "react";
import FBPixel from "src/ipm-shared/Utils/FBPixel";
// import IsLiveUser from "src/ipm-shared/components/IsLiveUser";
import { connect } from "react-redux";
// import * as actions from "./actions";
// import * as selectors from "./selectors";
import { RootState } from "src/ipm-shared/store/model/reducers";
import * as ReactRouter from "react-router";
import Is from "src/ipm-shared/Utils/Is";

const mapStateToProps = (state: RootState) => ({
  // pixelReady: selectors.isPixelReady(state),
});
const mapDispatchToProps = {
  // setPixelReady: actions.setPixelReady
};
type IProps = typeof mapDispatchToProps & {
  pixelId: string;
} & ReactRouter.RouteProps;

class FacebookPixelProvider extends React.Component<IProps> {
  public constructor(props: IProps) {
    super(props);
    FBPixel.init(props.pixelId);
  }
  public render() {
    return (
      <>
        {/*<IsLiveUser callbackOnLive={this.activateUserPixel}>{null}</IsLiveUser>*/}
        {this.props.children}
      </>
    );
  }

  public componentDidMount = () => {
    if (Is.live()) {
      FBPixel.pageView();
    }
  };

  // public activateUserPixel = () => {
  //   if (Is.live()) {
  //     this.props.setPixelReady();
  //   }
  // };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FacebookPixelProvider);
