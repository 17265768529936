import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import DesktopModalLayout from "src/bepaid/components/Modal/components/DesktopModalLayout";
import { FetchModalID } from "src/ipm-shared/components/GlobalUI/actions";

import AddCustomerForm from "src/bepaid/components/AddCustomerForm";

import styles from "./AddCustomerModal.module.scss";
import { invokeMessage } from "src/bepaid/components/Message";
import Button from "src/bepaid/components/Form/controls/Button";
import MainLayout from "src/bepaid/layouts/MainLayout";
import { history } from "src/ipm-shared/store";
import ROUTES from "src/bepaid/routes";

const View = (props: any) => {
  const [loading, setLoading] = React.useState(false);

  const toggleModal = () => {
    props.toggleModal(FetchModalID.ADD_CUSTOMER);
    history.push(ROUTES.CUSTOMERS);
  };

  const onSaved = () => {
    invokeMessage("success", "Customer added.");
    toggleModal();
  };

  const handleSubmitAddCustomer = () => {
    props.addCustomer(
      null,
      null,
      null,
      "none",
      null,
      (err: any, data: any) => {
        if (err) {
          return;
        }

        onSaved();
      },
      setLoading
    );
    return;
  };
  // const handleBackCustomer = () => {
  //   history.goBack();
  // };

  const renderSubmitButton = () => {
    return (
      <Button
        type="primary"
        htmlType="submit"
        onClick={handleSubmitAddCustomer}
        loading={loading}
        autoScrollToFirstError={true}
      >
        {loading ? "Adding new customer..." : "Add new customer"}
      </Button>
    );
  };

  return (
    <>
      <BrowserView>
        <div className={styles.wrapper}>
          <DesktopModalLayout
            title={"Add customer"}
            maxWidth450={true}
            onClose={toggleModal}
            btnSubmit={renderSubmitButton()}
          >
            <div className={styles.content}>
              <AddCustomerForm />
            </div>
          </DesktopModalLayout>
        </div>
      </BrowserView>

      <MobileView>
        <MainLayout
          header={{
            title: "Add customer",
            hideMenu: true,
            onClose: toggleModal
          }}
          footer={renderSubmitButton()}
        >
          <div className={styles.wrapper}>
            <AddCustomerForm />
          </div>
        </MainLayout>
      </MobileView>
    </>
  );
};

export default View;
