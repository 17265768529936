import React, { useEffect } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import * as queryString from "query-string";
import _get from "lodash-es/get";
import classNames from "classnames";
import Flex from "antd-mobile/es/flex";
import Typography from "antd/es/typography";

import { LOGIN_FORM } from "src/ipm-shared/store/model/Auth/const";
import FormErrors from "src/ipm-shared/components/Form/helpers/Errors";

import * as localStorageUtil from "src/bepaid/utils/localStorage";
import Form from "src/bepaid/components/Form";
import ROUTES from "src/bepaid/routes";
import GuestLayout from "src/bepaid/layouts/GuestLayout";
import InputText from "src/bepaid/components/Form/controls/InputText";
import Button from "src/bepaid/components/Form/controls/Button";
import Image from "src/bepaid/components/Image";
import useQuery from "src/bepaid/hooks/useQuery";
import { getUtmLink } from "src/bepaid/utils/getUtmParams";
import Card from "src/bepaid/components/Card";
import { invokeMessage } from "src/bepaid/components/Message";

import HeaderLogo from "src/bepaid/assets/images/desktop/header_ipaymy_logo.svg";

import styles from "./SignIn.module.scss";
import GATrack from "src/ipm-shared/components/GAProvider/GATrack";
import LinkedInTrack from "src/ipm-shared/components/LinkedInProvider/LinkedInTrack";
import { ACCOUNT_TYPE } from "src/ipm-shared/store/model/AccountProfile/const";
import OktaLogo from "src/bepaid/assets/images/okta_logo.svg";
import Divider from "antd/es/divider";

const initialValues = { email: "", password: "" };
// const isSGLivePlatform = window.location.origin.includes(
//   process.env.REACT_APP_SG_PLATFORM_URL as string
// );

const FormComponent = (props: any) => {
  const {
    login,
    redirectUrl = null,
    loginWithSAMLSubmit,
    redirectSAML
  } = props;
  const qs = useQuery();
  const fireOnce = qs.post_register !== null && qs.post_register === "y";
  const hasSSOCode = qs.sso_code !== null;
  const ssoLogin = window.location.pathname.split("/").includes("motorola");

  useEffect(() => {
    if (qs.email) {
      props.setControl({
        errors: [],
        form: LOGIN_FORM,
        name: `email`,
        value: qs.email
      });
    }

    if (ssoLogin && hasSSOCode) {
      props.setControl({
        errors: [],
        form: LOGIN_FORM,
        name: `sso_code`,
        value: qs.sso_code
      });
    }

    if (ssoLogin && hasSSOCode) {
      if (!ssoLoading) {
        setSSOLoading(true);
      }

      loginWithSAMLSubmit(
        redirectUrl,
        null,
        {
          cb: (err?: any) => {
            if (err) {
              window.Logger.log(err);
            }
            setSSOLoading(false); // ok
          }
        },
        setSSOLoading
      );
    }
  }, []);

  const [loading, setLoading] = React.useState(false);
  const [ssoLoading, setSSOLoading] = React.useState(false);
  // @ts-ignore
  const [unverified, setUnverified] = React.useState(false);

  const enterLoading = () => {
    if (!loading) {
      setLoading(true); // ok
    }
  };

  const handleRedirectSAML = () => {
    redirectSAML(redirectUrl, null, {
      cb: (err?: any) => {
        if (err) {
          window.Logger.log(err);
        }
      }
    });
  };

  const onFinish = () => {
    enterLoading();
    login(
      redirectUrl,
      null,
      {
        cb: (err?: any) => {
          if (err) {
            window.Logger.log(err);
          }
          setLoading(false); // ok
        }
      },
      setLoading
    );
  };

  return (
    <>
      <Form
        name="login-form"
        layout="vertical"
        initialValues={initialValues}
        requiredMark={false}
        onFinish={onFinish}
        className={styles.form}
      >
        {fireOnce && (
          <div>
            <GATrack
              title={"PostRegistration"}
              data={{}}
              fireOnce={fireOnce}
              email={qs.email as any}
            />

            {qs.account_type === ACCOUNT_TYPE.PERSONAL && (
              <LinkedInTrack
                title="Lead Personal"
                allowGuest={true}
                fireOnce={fireOnce}
                email={qs.email as any}
              />
            )}
            {qs.account_type === ACCOUNT_TYPE.BUSINESS && (
              <LinkedInTrack
                title="Lead Business"
                allowGuest={true}
                fireOnce={fireOnce}
                email={qs.email as any}
              />
            )}
          </div>
        )}

        <InputText
          name="email"
          placeholder="Email"
          form={LOGIN_FORM}
          pattern="__EMAIL__"
          id={"email"}
          label="Email"
          className={styles.itemEmail}
        />
        <InputText
          type="password"
          name="password"
          placeholder="Password"
          form={LOGIN_FORM}
          reserveValueOnUnmount={true}
          label="Password"
          className={styles.itemPassword}
        />
        <div hidden={true}>
          <InputText
            name="sso_code"
            placeholder="SSO code"
            form={LOGIN_FORM}
            id={"sso_code"}
            className={styles.itemEmail}
          />
        </div>
        <div className={styles.loginFormError}>
          <FormErrors form={LOGIN_FORM} />
        </div>
        {/*{unverified && <UnverifiedNotification />}*/}
        <Form.Item>
          <Button
            id={"ipaymy_btn_signin"}
            type="primary"
            htmlType="submit"
            loading={loading}
            onClick={enterLoading}
          >
            {loading ? "Signing in..." : "Sign in"}
          </Button>
        </Form.Item>

        {ssoLogin && (
          <>
            <Divider className={styles.divider}>Or</Divider>
            <Form.Item>
              <Button
                id={"ipaymy_btn_signin_okta"}
                type="secondary"
                htmlType="button"
                onClick={handleRedirectSAML}
                wrapperClassName={styles.btnSigninOkta}
              >
                <>
                  {!ssoLoading && <Image src={OktaLogo} />}
                  {ssoLoading ? "Signing in..." : "Sign in with Okta"}
                </>
              </Button>
            </Form.Item>
          </>
        )}
      </Form>
    </>
  );
};

const getQueryString = (search: string) => {
  const thisQs: any = {};

  const qs = queryString.parse(search);

  if (qs.email) {
    thisQs.defaultEmail = qs.email;
  }
  thisQs.verified = qs.verified;
  thisQs.redirectUrl = qs.redirect_url;

  if (qs.def_acct) {
    thisQs.defaultAccount = parseInt(qs.def_acct as string, 10);
  }

  return thisQs;
};

const SignIn: React.FC<any> = props => {
  const { hasToken, logout } = props;

  const pageQs = getQueryString(window.location.search);

  React.useEffect(() => {
    if (hasToken) {
      logout();
    }

    localStorageUtil.setDisplaySplashScreen(false);

    switch (pageQs.verified) {
      case "OK": {
        return invokeMessage(
          "success",
          "Email verification successful! You may now sign in."
        );
      }
      default: {
        if (pageQs.verified) {
          invokeMessage(
            "error",
            "This verification link no longer is valid. Please request another link or contact us for support."
          );
        }
      }
    }
  }, []);

  const renderContent = () => {
    return (
      <div className={styles.wrapper}>
        <Flex justify="center" align="center">
          <Flex.Item span={24}>
            <FormComponent
              {...props}
              redirectUrl={_get(pageQs, "redirectUrl", null)}
              defaultValues={{
                email: pageQs.defaultEmail
              }}
            />
          </Flex.Item>
        </Flex>
        <Flex justify="center" align="center">
          <Flex.Item
            span={24}
            className={classNames(styles.agreementItem, styles.centerText)}
          >
            <Typography.Text className={styles.agreementText}>
              By continuing you agree to our{" "}
              <Typography.Link
                href="https://ipaymy.com/terms-conditions"
                target="_blank"
              >
                Terms and Conditions
              </Typography.Link>{" "}
              and{" "}
              <Typography.Link
                href="https://ipaymy.com/privacy-policy"
                target="_blank"
              >
                Privacy Policy
              </Typography.Link>
              .
            </Typography.Text>
          </Flex.Item>
        </Flex>
        <Flex
          direction="column"
          justify="center"
          align="center"
          className={styles.extraSection}
        >
          <Flex.Item span={24} className={styles.centerText}>
            <Typography.Text className={styles.signUpText}>
              Don't have an account?{" "}
              <Typography.Link
                className={styles.strongLink}
                href={getUtmLink(ROUTES.REGISTER)}
              >
                Sign up.
              </Typography.Link>
            </Typography.Text>
          </Flex.Item>
          {false && (
            <Flex.Item
              span={24}
              className={classNames(
                styles.forgetPasswordItem,
                styles.centerText
              )}
            >
              <Typography.Link
                href={process.env.REACT_APP_LEGACY_PLATFORM_URL}
                target="_blank"
                className={styles.strongLink}
              >
                Making international payments?
              </Typography.Link>
            </Flex.Item>
          )}
          <Flex.Item
            span={24}
            className={classNames(styles.forgetPasswordItem, styles.centerText)}
          >
            <Typography.Link
              href={getUtmLink(ROUTES.RESET_PASSWORD)}
              className={styles.strongLink}
            >
              Forgot your password?
            </Typography.Link>
          </Flex.Item>
        </Flex>
      </div>
    );
  };

  const renderDesktopView = () => {
    return (
      <div className={styles.desktopWrapper}>
        <Card>{renderContent()}</Card>
      </div>
    );
  };

  return (
    <GuestLayout
      header={<Image src={HeaderLogo} />}
      title={
        <Typography.Text className={styles.title}>
          Sign in to <b>ipaymy</b>
        </Typography.Text>
      }
    >
      <BrowserView>{renderDesktopView()}</BrowserView>
      <MobileView>{renderContent()}</MobileView>
    </GuestLayout>
  );
};

export default SignIn;
