import React, { useEffect, useState } from "react";
import { BrowserView, MobileView, isMobile } from "react-device-detect";
import classNames from "classnames";

import { history } from "src/ipm-shared/store";

import ROUTES from "src/bepaid/routes";
import useQuery from "src/bepaid/hooks/useQuery";
import MainLayout from "src/bepaid/layouts/MainLayout";
import DesktopModalLayout from "src/bepaid/components/Modal/components/DesktopModalLayout";
import Button from "src/bepaid/components/Form/controls/Button";
import { invokeMessage } from "src/bepaid/components/Message";

import ExtendPaymentDetail from "./components/ExtendPaymentDetail";

import _get from "lodash-es/get";
import _isEmpty from "lodash-es/isEmpty";
import { FetchModalID } from "../PaymentsCreationModal/components/SchedulePayment/actions";

import styles from "./PaymentDetailModal.module.scss";

function PaymentDetailExtendModal(props: any) {
  const { selectPayment, extraInfo, selectSchedule, openModal } = props;
  const [loadingPayment, setLoadingPayment] = useState(true);
  const [isValidCustomDuration, setIsValidCustomDuration] = useState(true);
  const qs = useQuery();

  useEffect(() => {
    if (extraInfo.paymentId) {
      selectPayment(
        extraInfo.paymentId,
        () => {
          setLoadingPayment(false);
        },
        {
          idType: "id",
          withPaymentActivity: false
        }
      );
    }
  }, [extraInfo.paymentId]);
  useEffect(() => {
    if (extraInfo.scheduleId) {
      selectSchedule(Number(extraInfo.scheduleId), () => {
        setLoadingPayment(false);
      });
    }
  }, [extraInfo.scheduleId]);

  const closeModal = () => {
    const urlParams = new URLSearchParams(window.location.search);
    if (qs.paymentType === "schedule") {
      const url = ROUTES.PAYMENT_SCHEDULE_DETAILS.replace(
        ":id",
        extraInfo.scheduleId
      );
      history.push({
        pathname: url,
        search: "?" + urlParams.toString()
      });
      return;
    }
    if (props.selectedPayment) {
      urlParams.set("idType", "id");
      const url = ROUTES.PAYMENT_DETAILS.replace(
        ":id",
        props.selectedPayment.id
      );
      history.push({
        pathname: url,
        search: "?" + urlParams.toString()
      });
    } else {
      const url = ROUTES.PAYMENTS;
      history.push({
        pathname: url,
        search: "?" + urlParams.toString()
      });
    }
  };
  const [loading, setLoading] = React.useState(false);

  const onSaved = () => {
    invokeMessage("success", "Changes saved.");
    closeModal();
  };

  const validateField = () => {
    const errors = {
      fields: {},
      form: []
    };
    if (!isValidCustomDuration) {
      errors.fields[`extend_payment_duration_${extraInfo.scheduleId}`] = [
        "REQUIRED_FIELD"
      ];
    }
    if (!_isEmpty(errors.fields)) {
      props.parseServerErrors(errors);
      return false;
    }

    return true;
  };

  const handleSubmitEditPayment = () => {
    if (!validateField()) {
      return setLoading(false);
    }
    props.submitEditPayments(
      !extraInfo.paymentId ? [] : [Number(extraInfo.paymentId)],
      false,
      (err: any, data: any) => {
        if (err) {
          if (_get(err, "form[0]") === "DUPLICATE_PAYOUT_DATE") {
            openModal(FetchModalID.PAYMENT_DUPLICATE_MODAL, {
              paymentId: extraInfo.paymentId
            });
          }
          return;
        }

        onSaved();
      },
      setLoading
    );
    return;
  };

  const onNo = () => {
    closeModal();
  };

  const onYes = () => {
    handleSubmitEditPayment();
  };

  const renderButtons = () => {
    return (
      <div
        className={classNames(styles.groupBtn, {
          [styles.isMobile]: isMobile
        })}
      >
        <Button type="secondary" onClick={onNo}>
          {"Cancel"}
        </Button>
        <Button type="primary" onClick={onYes} loading={loading}>
          {!loading ? "Proceed" : "Proceeding..."}
        </Button>
      </div>
    );
  };

  const renderContent = () => {
    return (
      <ExtendPaymentDetail
        loading={loadingPayment}
        {...props.extraInfo}
        isEditAll={props.extraInfo.isEditAll}
        setIsValidCustomDuration={setIsValidCustomDuration}
      />
    );
  };

  return (
    <>
      <BrowserView>
        <DesktopModalLayout
          title={
            <>
              <div>Confirm extension of</div>
              <div>scheduled payment</div>
            </>
          }
          maxWidth450={true}
          onClose={closeModal}
          onSubmit={handleSubmitEditPayment}
          btnSubmit={renderButtons()}
        >
          <div className={classNames(styles.wrapper)}>{renderContent()}</div>
        </DesktopModalLayout>
      </BrowserView>
      <MobileView>
        <MainLayout
          header={{
            title: "Confirm extension of scheduled payment",
            onClose: closeModal,
            hideMenu: true
          }}
          footer={renderButtons()}
        >
          <div
            className={classNames(styles.wrapper, {
              [styles.wrapperMobile]: isMobile
            })}
          >
            {renderContent()}
          </div>
        </MainLayout>
      </MobileView>
    </>
  );
}

export default PaymentDetailExtendModal;
