import React, { useEffect, useState } from "react";
import { isDesktop } from "react-device-detect";
import ClockCircleOutlined from "@ant-design/icons/ClockCircleOutlined";
import Typography from "antd/es/typography";
import classNames from "classnames";

import Tooltip from "src/ipm-shared/components/Tooltip";

import InputText from "src/bepaid/components/Form/controls/InputText";
import SearchIcon from "src/bepaid/assets/images/common/icon_search.svg";
import CloseIcon from "src/bepaid/assets/images/common/icon_close.svg";
import useQuery from "src/bepaid/hooks/useQuery";
import useLocalStorageEvent from "src/bepaid/hooks/useLocalStorageEvent";

import {
  getSearches,
  removeSearchHistory,
  clearSearchHistory,
  INPUT_HOLDER
} from "../../utils";

import styles from "./SearchContent.module.scss";

const { Text, Link } = Typography;

const View = (props: any) => {
  const qs = useQuery();

  const [text, setSearchText] = useState<any>();

  const SEARCH_KEY: string = String(qs.key || props.searchKey);

  const [SEARCHES, setHistory] = React.useState<string[]>(
    getSearches(SEARCH_KEY)
  );

  const [isOpenHistory, setOpenHistory] = React.useState<boolean>(false);

  let placeholder = INPUT_HOLDER[SEARCH_KEY].placeholder || "Search here...";
  const tooltipText = INPUT_HOLDER[SEARCH_KEY].tooltipText || null;

  if (placeholder.includes("UEN") && props.accountCountryCode === "AU") {
    placeholder = placeholder.replace("UEN", "ABN");
  }

  const onRemove = (v: string) => {
    removeSearchHistory(SEARCH_KEY, v);
    setHistory(getSearches(SEARCH_KEY));
  };

  const clearAll = () => {
    clearSearchHistory(SEARCH_KEY);
    setHistory(getSearches(SEARCH_KEY));
  };

  const newSearchState = useLocalStorageEvent(SEARCH_KEY);

  const onBlurInput = () => {
    setTimeout(() => {
      setOpenHistory(isDesktop ? false : isOpenHistory);
    }, 50);
  };

  useEffect(() => {
    setHistory(getSearches(SEARCH_KEY));
  }, [newSearchState]);

  useEffect(() => {
    setSearchText(qs.search_keyword);
  }, [qs.search_keyword]);

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.desktopWrapper]: isDesktop
      })}
      data-color={SEARCH_KEY}
    >
      <div className={styles.searchInputWrapper}>
        <InputText
          prefix={<img src={SearchIcon} />}
          suffix={
            tooltipText && (
              <Tooltip
                description={tooltipText}
                displayIcon={true}
                iconColor="gray"
                target={`tooltip-search`}
                className={styles.feeTooltip}
                width={20}
              />
            )
          }
          name="search_keyword"
          autoComplete={"off"}
          placeholder={isDesktop ? placeholder : "Search"}
          inputClassName={styles.searchInput}
          onChangeCustom={props.onChangeSearch}
          defaultValue={text ? text : undefined}
          onFocus={setOpenHistory.bind(null, true)}
          onBlur={onBlurInput}
        />
        <p className={styles.searchPlaceholder} hidden={isDesktop}>
          {placeholder}
        </p>
      </div>
      <div
        className={styles.searchHistoryLayout}
        hidden={!isOpenHistory || SEARCHES.length === 0}
      >
        <div className={styles.searchHistoryTitle}>
          <Text strong={true}>Recent searches</Text>
          <Link strong={true} onClick={clearAll}>
            Clear
          </Link>
        </div>

        {[...SEARCHES]
          .reverse()
          .slice(0, 5)
          .map((k, i) => (
            <div key={i} className={styles.searchHistoryItem}>
              <div onClick={props.onClickHistory.bind(null, k)}>
                <ClockCircleOutlined />
                <span className={styles.searchHistoryText}>{k}</span>
              </div>
              <div>
                <img src={CloseIcon} onClick={onRemove.bind(null, k)} />
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default View;
