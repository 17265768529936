import * as React from "react";
import FBPixel from "src/ipm-shared/Utils/FBPixel";
import { connect } from "react-redux";
import * as selectors from "../selectors";
import { RootState } from "src/ipm-shared/store/model/reducers";
import Is from "src/ipm-shared/Utils/Is";
import Cookies from "js-cookie";

const mapStateToProps = (state: RootState) => ({
  hasToken: selectors.hasToken(state),
  isBusinessAccount: selectors.getIsBusinessAccount(state)
  // pixelReady: selectors.isPixelReady(state)
});

type IProps = ReturnType<typeof mapStateToProps> & {
  assumeAccountType?: "personal" | "business";
  allowGuest?: boolean;
  data?: {
    [name: string]: any;
  };
  title: string;
  accountTypeCheck?: boolean;
  fireOnce?: boolean;
};

class FacebookTrack extends React.Component<IProps> {
  private fired: boolean;

  constructor(props: IProps) {
    super(props);

    if (props.fireOnce) {
      const ipmTrackCookieValue = Cookies.get(window.location.pathname);

      if (props.data && ipmTrackCookieValue) {
        if (ipmTrackCookieValue === "true") {
          this.fired = true;
        }
      }
    }
  }

  public setIPMTrackCookie() {
    if (this.props.data) {
      Cookies.set(window.location.pathname, "true");
    }
  }

  public render() {
    if (
      (!this.props.hasToken && !this.props.allowGuest) || // Pixel is not ready for authenticated user
      !Is.live() // Is not live
    ) {
      return null;
    }
    if (this.fired) {
      return null;
    }

    const { title, data } = this.props;
    switch (title) {
      case "Purchase":
        this.trackPurchaseAction();
        break;

      case "Schedule":
        this.trackScheduleAction();
        break;

      case "CollectionPurchase":
        this.trackCollectionPurchaseAction();
        break;
      default:
        this.trackCustomAction(title, data);
    }

    this.fired = true;
    return null;
  }

  public trackPurchaseAction() {
    let accountType: string;
    if (
      this.props.isBusinessAccount ||
      this.props.assumeAccountType === "business"
    ) {
      // FBPixel.trackCustom(`PurchaseBusiness`, this.props.data)
      accountType = "business";
    } else {
      // FBPixel.trackCustom(`PurchasePersonal`, this.props.data)
      accountType = "personal";
    }

    const data = {
      content_name: accountType,
      currency: (this.props.data as any).currency,
      value: (this.props.data as any).value
    };
    this.setIPMTrackCookie();
    FBPixel.track("Purchase", data);
  }

  public trackCollectionPurchaseAction() {
    let accountType: string;
    if (
      this.props.isBusinessAccount ||
      this.props.assumeAccountType === "business"
    ) {
      // FBPixel.trackCustom(`PurchaseBusiness`, this.props.data)
      accountType = "business";
    } else {
      // FBPixel.trackCustom(`PurchasePersonal`, this.props.data)
      accountType = "personal";
    }

    const data = {
      content_name: accountType,
      currency: (this.props.data as any).currency,
      value: (this.props.data as any).value
    };
    this.setIPMTrackCookie();
    FBPixel.track("CollectionPurchase", data);
  }

  public trackScheduleAction() {
    FBPixel.track("Schedule");
  }

  public trackCustomAction(event: string, data: any) {
    let trackTitle: string;
    if (this.props.accountTypeCheck) {
      if (
        this.props.isBusinessAccount ||
        this.props.assumeAccountType === "business"
      ) {
        trackTitle = `${event}Business`;
      } else {
        trackTitle = `${event}Personal`;
      }
    } else {
      trackTitle = event;
    }

    if (trackTitle === "ViewBusiness") {
      return;
    }

    FBPixel.trackCustom(trackTitle, data);
  }
}

export default connect(mapStateToProps)(FacebookTrack);
