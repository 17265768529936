import React from "react";

import Image from "src/bepaid/components/Image";

import IconArrow from "src/bepaid/assets/images/common/arrow_tooltip.svg";

import styles from "./ToolTipArrow.module.scss";

interface IProps {
  text: string;
}

function ToolTipArrow({ text }: IProps) {
  return (
    <div className={styles.wrapper}>
      <p className={styles.text}>{text}</p>
      <Image className={styles.arrow} src={IconArrow} />
    </div>
  );
}

export default ToolTipArrow;
