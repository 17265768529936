import * as queryString from "query-string";
import * as uuidv1 from "uuid/v1";

class IPMContext {
  public static initSession() {
    try {
      const qs = queryString.parse(window.location.search);
      if (qs.x_ipm_session_id) {
        window.sessionStorage.setItem("id", qs.x_ipm_session_id.toString());
      } else {
        window.sessionStorage.setItem("id", "ss_" + uuidv1());
      }
    } catch (e) {
      console.error("Client does not support sessionStorage");
    }
  }

  public static isPayFetchPlatform() {
    return process.env.REACT_APP_FETCH_APP_ID === "fetch";
  }
}

export default IPMContext;
