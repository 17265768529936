import React, { useEffect, useMemo, useState } from "react";

import { FetchModalID } from "src/ipm-shared/components/GlobalUI/actions";

import { invokeMessage } from "src/bepaid/components/Message";
import DeleteModal from "src/bepaid/components/Modal/components/DeleteModal";
import { PAY_WITH_ANOTHER_CARD_VALUE } from "src/bepaid/components/CardSelector/View";
import CardSelector from "src/bepaid/components/CardSelector";
import TabContent from "src/bepaid/pages/Payments/components/TabContent";
import AddNewCard from "src/bepaid/layouts/Menu/components/AddNewCard";

import styles from "./DeleteCardModal.module.scss";
import UrlHelper from "src/ipm-shared/Utils/UrlHelper";

const UpdateCardDetails = (props: any) => {
  const {
    toggleModal,
    hasAtLeastOneCard,
    cardId,
    selectNewCard,
    setIsAddNewCard,
    action,
    onSubmitSuccess,
    handleDeleteCard,
    setIsLoadingAddNewCard
  } = props;

  const [isCardSelectorOpen, openCardselector] = useState(false);
  const [updateType, setUpdateType] = useState("update");
  const [selectedCard, selectCard] = useState<number | string>(cardId);
  const handleSelectedCard = (cardId: any) => {
    selectCard(cardId);
  };
  const cardSelectorOnChange = (value: string) => {
    if (value === PAY_WITH_ANOTHER_CARD_VALUE) {
      setUpdateType("add");
      setIsAddNewCard(true);
      selectCard(PAY_WITH_ANOTHER_CARD_VALUE);
      return;
    }
    handleSelectedCard(value);
    selectNewCard(value);
  };

  const cardSelectorOnVisibleChange = (open: boolean) => {
    if (!hasAtLeastOneCard) {
      toggleModal(FetchModalID.ADD_NEW_CARD_MODAL);
      return;
    }

    openCardselector(open);
  };

  return (
    <div className={styles.textLeft}>
      <CardSelector
        open={isCardSelectorOpen}
        value={selectedCard}
        onChange={cardSelectorOnChange}
        onDropdownVisibleChange={cardSelectorOnVisibleChange}
        defaultValue={cardId}
        label={"Card"}
        name={`update_card_details`}
      />
      {updateType === "add" ? (
        <AddNewCard
          extraInfo={{
            notOpenList: true,
            cb: async (newCardId: number) => {
              selectCard(newCardId);
              setUpdateType("update");
              await handleDeleteCard(newCardId);
            }
          }}
          action={action}
          onSubmitSuccess={onSubmitSuccess}
          renderSubmitButton={() => {}}
          syncBtnSubmitLoading={(value: boolean) =>
            setIsLoadingAddNewCard(value)
          }
        />
      ) : selectedCard !== cardId ? (
        <p className={styles.helperText}>
          This card will replace the deleted card for all associated upcoming
          scheduled payments.
        </p>
      ) : null}
    </div>
  );
};

const View = (props: any) => {
  const { extraInfo, deleteCard, toggleModal, submitEditPayments } = props;

  const { cardId = null } = extraInfo;

  const closeModal = () => {
    toggleModal(FetchModalID.DELETE_NEW_CARD_MODAL);
  };

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPayments, setIsLoadingPayments] = useState(false);
  const [schedulePayments, setSchedulepayments] = useState([]);

  const [isUpdatingCard, setIsUpdatingCard] = useState(false);
  const [isAddNewCard, setIsAddNewCard] = useState(false);
  const [isLoadingAddNewCard, setIsLoadingAddNewCard] = useState(false);
  const [selectedNewCard, selectNewCard] = useState<number>();
  const [description, setDescription] = useState<React.ReactNode>(null);

  const isHasTiedSchedulePayments = useMemo(() => {
    return schedulePayments.length === 1;
  }, [schedulePayments]);

  const handleUpdatePayment = (
    payment: any,
    index: number,
    paymentsLength: number,
    newCardId: number
  ) => {
    return new Promise((rs, rj) => {
      try {
        submitEditPayments(
          [],
          false,
          (err: any, data: any) => {
            if (err) {
              invokeMessage(
                "error",
                "Unable to delete card. Please try it again!"
              );
              rj(new Error("Error"));
            } else {
              if (data.xLocation) {
                UrlHelper.openBackgroundIframe(
                  data.xLocation,
                  (err: any, data: any) => {
                    if (err) {
                      invokeMessage(
                        "error",
                        "Unable to delete card. Please try it again!"
                      );
                      rj(new Error(err));
                    } else {
                      if (index == paymentsLength - 1) {
                        deleteCard(cardId, (hasError: boolean) => {
                          closeModal();
                          invokeMessage(
                            hasError ? "error" : "success",
                            hasError
                              ? "Unable to delete card. Please try it again!"
                              : "Card deleted."
                          );
                          rs(true);
                        });
                      } else {
                        rs(true);
                      }
                    }
                  }
                );
              } else {
                if (index == paymentsLength - 1) {
                  deleteCard(cardId, (hasError: boolean) => {
                    closeModal();
                    invokeMessage(
                      hasError ? "error" : "success",
                      hasError
                        ? "Unable to delete card. Please try it again!"
                        : "Card deleted."
                    );
                    rs(true);
                  });
                } else {
                  rs(true);
                }
              }
            }
          },
          undefined,
          payment.scheduleId,
          newCardId,
          true,
          true
        );
      } catch {
        invokeMessage("error", "Unable to delete card. Please try it again!");
        rj(new Error("Error"));
      }
    });
  };

  const wait = (ms: number) =>
    new Promise(rs =>
      setTimeout(() => {
        rs(true);
      }, ms)
    );

  const handleUpdateAndDeleteCard = async (
    payments: any,
    sc: number,
    newCardId: number
  ) => {
    setIsLoading(true);
    localStorage.setItem("noRedirectIframe", "y");
    try {
      for (let i = 0; i < payments.length; i++) {
        await handleUpdatePayment(payments[i], i, sc, newCardId);
        await wait(3000);
      }
    } catch (error) {
      setIsLoading(false);
    }
    setIsLoading(false);
    let iframeEl = document.getElementById(
      "webhook_3ds_payment_update"
    ) as HTMLIFrameElement;
    if (iframeEl) {
      iframeEl.remove();
    }
    localStorage.removeItem("noRedirectIframe");
  };

  const handleDeleteCard = async (newCardId?: number) => {
    if (newCardId && typeof newCardId === "number") {
      let sc: number = schedulePayments.length;
      await handleUpdateAndDeleteCard(schedulePayments, sc, newCardId);
      return;
    }

    if (!cardId) {
      return;
    }

    setIsLoading(true);

    setTimeout(() => {
      deleteCard(cardId, (hasError: boolean) => {
        setIsLoading(false);
        closeModal();
        invokeMessage(
          hasError ? "error" : "success",
          hasError
            ? "Unable to delete card. Please try it again!"
            : "Card deleted."
        );
      });
    }, 2000);
  };

  const fetchData = () => {
    props.fetchSchedules(
      "y",
      0,
      undefined,
      (err?: any, data?: any) => {
        if (err) {
          window.Logger.log(err);
        }

        if (data && data.length === 1) {
          setSchedulepayments(data);
          setDescription(
            <div className={styles.textLeft}>
              <span>
                This will permanently delete this card and it cannot be undone.
              </span>
              <p className={styles.pinkText}>
                Deleting this card will also cancel any upcoming scheduled
                payments associated with this card.
              </p>
              <TabContent
                loading={isLoadingPayments}
                list={data}
                isShowSchedule={true}
              />
            </div>
          );
        } else {
          setDescription(
            <>
              <span>
                This will permanently delete this card
                <br />
                and it cannot be undone.
              </span>
              <br />
              <p className={styles.helperText}>
                Deleting this card will also delete any associated scheduled
                payments.
              </p>
            </>
          );
        }
      },
      setIsLoadingPayments,
      cardId,
      true
    );
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleUpdateCardDetails = (action?: string) => {
    setIsUpdatingCard(true);
    setDescription(
      <UpdateCardDetails
        {...props}
        cardId={cardId}
        action={action}
        setIsAddNewCard={setIsAddNewCard}
        selectNewCard={selectNewCard}
        onSubmitSuccess={() => {
          handleUpdateCardDetails();
        }}
        handleDeleteCard={handleDeleteCard}
        setIsLoadingAddNewCard={setIsLoadingAddNewCard}
      />
    );
  };

  const handleOnNo = async () => {
    if (isAddNewCard) {
      // force rerender component
      handleUpdateCardDetails("submit_add_new_card");
    } else if (isUpdatingCard) {
      if (selectedNewCard && selectedNewCard !== cardId) {
        await handleDeleteCard(selectedNewCard);
      }
    } else if (isHasTiedSchedulePayments) {
      handleUpdateCardDetails();
    } else {
      closeModal();
    }
  };

  return (
    <DeleteModal
      header={isHasTiedSchedulePayments ? "Schedule payments" : undefined}
      title={`Delete this card?`}
      isLoading={isLoading || (isAddNewCard && isLoadingAddNewCard)}
      disabled={isUpdatingCard && !selectedNewCard && !isAddNewCard}
      description={description}
      hideTitle={isLoadingPayments || isHasTiedSchedulePayments}
      maxWidth400={!isHasTiedSchedulePayments}
      yesLabel={
        isHasTiedSchedulePayments
          ? `Delete card and cancel payments`
          : `Yes, delete card`
      }
      yesLabelLoading={`Deleting card...`}
      noLabel={
        isHasTiedSchedulePayments ? `Update card details` : `No, keep card`
      }
      onYes={handleDeleteCard}
      onNo={handleOnNo}
      onClose={closeModal}
    />
  );
};

export default View;
