import React, { useEffect } from "react";
import _get from "lodash-es/get";

import Form from "src/bepaid/components/Form";

import { ADD_FORM } from "src/ipm-shared/store/model/CollectedAccount/const";

import InputText from "src/bepaid/components/Form/controls/InputText";
import Select from "src/bepaid/components/Form/controls/Select";

import FormStyles from "src/bepaid/components/Form/CommonForm.module.scss";
import OtherStyles from "../KYCContents/KycContents.module.scss";
import { AVERAGE_INVOICE_AMOUNT } from "./const";
import usePrevious from "src/bepaid/hooks/usePrevious";

const styles = {
  ...FormStyles,
  ...OtherStyles
};

const BusinessInformationForm = (props: any) => {
  const commonFormItemProps = {
    reserveValueOnUnmount: true,
    revertValueOnMount: true
  };

  const data = props.collectedAccount;
  // const profile = props.accountProfile;
  // const currentCompany = props.currentCompany;
  const prevAccountProfile = usePrevious(props.accountProfile);
  useEffect(() => {
    let recipentEmail = _get(data, "payeeData.recipientEmail", "");
    if (!recipentEmail) {
      props.setControl({
        form: ADD_FORM,
        name: "recipient_email",
        value: _get(props.accountProfile, "email", "")
      });
      props.setControl({
        form: ADD_FORM,
        name: "confirm_recipient_email",
        value: _get(props.accountProfile, "email", "")
      });
    }
    props.setControl({
      form: ADD_FORM,
      name: "current_id",
      value: _get(data, "currencyId", props.accountCurrencyId)
    });
    props.setControl({
      form: ADD_FORM,
      name: "b_acra_bizfile",
      value: _get(data, "payeeData.kycDocuments.b_acra_bizfile", [])
        ? _get(data, "payeeData.kycDocuments.b_acra_bizfile", []).join(",")
        : null
    });
    props.setControl({
      form: ADD_FORM,
      name: "b_poba",
      value: _get(data, "payeeData.kycDocuments.b_poba", [])
        ? _get(data, "payeeData.kycDocuments.b_poba", []).join(",")
        : null
    });
    props.setControl({
      form: ADD_FORM,
      name: "b_structured_chart",
      value: _get(data, "payeeData.kycDocuments.b_structured_chart", [])
        ? _get(data, "payeeData.kycDocuments.b_structured_chart", []).join(",")
        : null
    });
    props.setControl({
      form: ADD_FORM,
      name: "b_letter_of_authorization",
      value: _get(data, "payeeData.kycDocuments.b_letter_of_authorization", [])
        ? _get(
            data,
            "payeeData.kycDocuments.b_letter_of_authorization",
            []
          ).join(",")
        : null
    });
  }, []);
  useEffect(() => {
    props.setControl({
      form: ADD_FORM,
      name: "first_name",
      value: _get(props.accountProfile, "displayedFirstName", "")
    });
    props.setControl({
      form: ADD_FORM,
      name: "last_name",
      value: _get(props.accountProfile, "displayedLastName", "")
    });
  }, [props.accountProfile, prevAccountProfile]);
  return (
    <div className={styles.content}>
      <Form
        layout="vertical"
        requiredMark={false}
        className={styles.personalFormDetail}
      >
        <InputText
          type={"textarea"}
          form={ADD_FORM}
          name="business_operating_model"
          placeholder="Type here"
          label="Please provide a brief summary of your business operating model."
          defaultValue={_get(data, "payeeData.businessOperatingModel")}
          {...commonFormItemProps}
        />
      </Form>
      <Form
        layout="vertical"
        requiredMark={false}
        className={styles.personalFormDetail}
      >
        <InputText
          type={"textarea"}
          form={ADD_FORM}
          name="customer_serve"
          placeholder="Type here"
          label="Please provide a brief description on the type of customers you serve."
          defaultValue={_get(data, "payeeData.customerServe")}
          {...commonFormItemProps}
        />
      </Form>
      <Form
        layout="vertical"
        requiredMark={false}
        className={styles.personalFormDetail}
      >
        <label className={styles.label} htmlFor="input-business-website">
          Please provide your official business website.
          <br />
          (indicate *N/A where unavailable.)
        </label>
        <InputText
          id="input-business-website"
          type={"textarea"}
          form={ADD_FORM}
          name="business_website"
          placeholder="Type here"
          defaultValue={_get(data, "payeeData.businessWebsite")}
          {...commonFormItemProps}
        />
      </Form>
      <Form
        layout="vertical"
        requiredMark={false}
        className={styles.personalFormDetail}
      >
        <Select
          name="average_invoice_number"
          form={ADD_FORM}
          label="What is your expected average invoice amount?"
          placeholder="Average invoice amount"
          options={AVERAGE_INVOICE_AMOUNT[props.countryCode]}
          defaultValue={
            _get(data, "payeeData.averageInvoiceNumber") || undefined
          }
          {...commonFormItemProps}
        />
      </Form>
    </div>
  );
};
export default BusinessInformationForm;
