import React, { useState } from "react";
import { isDesktop } from "react-device-detect";
import _get from "lodash-es/get";
import Form from "src/bepaid/components/Form";
import classnames from "classnames";

import { DRAFT_INVOICE_SETTING_FORM } from "src/ipm-shared/store/model/CollectedAccount/const";

import Typography from "antd/es/typography";
import Switch from "antd/es/switch";

import Button from "src/bepaid/components/Form/controls/Button";
import { invokeMessage } from "src/bepaid/components/Message";

import styles from "./DraftInvoiceSetting.module.scss";

const FormComponent = (props: any) => {
  const {
    collectedAccount,
    fetchMainCollectedAccount,
    updateDraftInvoiceSetting
  } = props;

  const [isRemindDraft, setRemindDraft] = useState(
    _get(collectedAccount, "payeeData.remindDraftInvoice")
  );

  const [loading, setLoading] = React.useState(false);

  const onSubmit = () => {
    updateDraftInvoiceSetting(
      DRAFT_INVOICE_SETTING_FORM,
      true,
      (err?: any) => {
        if (!err) {
          invokeMessage("success", "Changes saved.");
          if (props.onSaved) {
            props.onSaved();
          }
          fetchMainCollectedAccount();
        }
      },
      setLoading
    );
  };

  const onChangeRemindDraft = (isOn: boolean) => {
    setRemindDraft(isOn);
    props.setControl({
      errors: [],
      form: DRAFT_INVOICE_SETTING_FORM,
      name: `remind_draft_invoice`,
      value: isOn
    });
    if (!isDesktop) {
      updateDraftInvoiceSetting(
        DRAFT_INVOICE_SETTING_FORM,
        true,
        (err?: any) => {
          if (!err) {
            invokeMessage("success", "Changes saved.");
            fetchMainCollectedAccount();
          }
        }
      );
    }
  };

  return (
    <Form
      name="draft-invoice-setting-form"
      layout="vertical"
      requiredMark={false}
      className={styles.form}
    >
      <div className={styles.formWrapper}>
        <div className={styles.formContent}>
          <div className={styles.flexItem}>
            <Typography className={styles.title}>
              Remind me about draft invoices when I create a new invoice.
            </Typography>
            <Switch checked={isRemindDraft} onChange={onChangeRemindDraft} />
          </div>
        </div>
      </div>
      {isDesktop && (
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            onClick={onSubmit}
          >
            {loading ? "Saving changes..." : "Save changes"}
          </Button>
        </Form.Item>
      )}
    </Form>
  );
};

const DraftInvoiceSetting: React.FC<any> = props => {
  return (
    <div
      className={classnames(styles.wrapper, {
        [styles.wrapperDesktop]: isDesktop
      })}
    >
      <FormComponent {...props} />
    </div>
  );
};

export default DraftInvoiceSetting;
