import { RootState } from "src/ipm-shared/store/model/reducers";
import _get from "lodash-es/get";
import { ActionType } from "typesafe-actions";
import { call, put, select } from "redux-saga/effects";
import {
  catchTakeLatest,
  reTryTakeLatest
} from "src/ipm-shared/Utils/ReduxSagaEffects";
import * as paymentActions from "../Payment/actions";
import * as paymentCollectionActions from "./actions";
import * as formActions from "src/ipm-shared/components/Form/actions";
import RestClient from "src/ipm-shared/services/Rest";
import * as commonActions from "../actions";
import HttpRequestError from "src/ipm-shared/Utils/Exceptions/HttpRequestError";
import { FILTER_EXPORT_FORM_PAYMENT_COLLECTIONS } from "src/ipm-shared/components/FilterExportProvider";
import { PAGE_COUNT, MARK_PAYMENT_AS_PAID_FORM, REFUND_FORM } from "./const";
import { PAYMENT_STATUS } from "../Payment/const";
import * as formSelectors from "src/ipm-shared/components/Form/selectors";
import utils from "src/ipm-shared/Utils/Number";
import _isEmpty from "lodash-es/isEmpty";
import { ModalID } from "src/ipm-shared/components/GlobalUI/actions";
import T from "src/ipm-shared/Utils/Intl";
import { format } from "date-fns";

const actions = {
  ...paymentActions,
  ...paymentCollectionActions,
  ...formActions,
  ...commonActions
};

const selectors = {
  ...formSelectors
};

const watchedSagas = [
  reTryTakeLatest(
    actions.fetchDashboardCollectedPayments,
    handleFetchDashboardCollectedPayments
  ),
  reTryTakeLatest(
    actions.fetchPaymentCollections,
    handleFetchPaymentCollections
  ),
  reTryTakeLatest(
    actions.fetchPaymentCollectionsActivityStatus,
    handleFetchPaymentCollectionsActivityStatus
  ),
  // reTryTakeLatest(actions.fetchCollectionRates, handleFetchCollectionRates),
  catchTakeLatest(actions.markPaymentAsPaid, handleMarkPaymentAsPaid),
  catchTakeLatest(
    actions.refundCollectionPayment,
    handleRefundCollectionPayment
  ),
  reTryTakeLatest(
    actions.fetchPaymentCollectionsHistoryList,
    handleFetchPaymentCollectionsHistoryList
  ),
  reTryTakeLatest(
    actions.fetchPaymentCollectionsHistoryExport,
    handleFetchPaymentCollectionsHistoryExport
  )
];
export default watchedSagas;

export function* handleFetchPaymentCollections(
  action: ActionType<typeof actions.fetchPaymentCollections>
) {
  yield put(
    actions.setPaymentCollections({
      isFetching: true,
      payments: [],
      total: 0
    })
  );
  const { offset } = action.payload;
  const query = { offset, page_count: PAGE_COUNT };
  const res: Response = yield call(RestClient.send, {
    query,
    service: "get_payment_collection"
  });

  if (!res) {
    yield put(
      actions.setPaymentCollections({
        isFetching: false,
        payments: []
      })
    );
    throw new HttpRequestError("Failed to fetch");
  }

  const data: any[] = _get(res, "data", []);
  const total: number = _get(res, "total", 0);

  yield put(
    actions.setPaymentCollections({
      isFetching: false,
      payments: data.map(payment => ({
        batchId: payment.paid_out_batch_id || "-",
        card: {
          brandId: _get(payment, "cards[0].card_brand_id", 2)
        },
        createdAt: payment.created_at,
        currencyId: payment.currency_id,
        customer: {
          email: payment.payer.email,
          mobileCountryId: payment.payer.mobile_country_id,
          mobileNumber: payment.payer.mobile_number,
          name: `${payment.payer.first_name} ${payment.payer.last_name}`
        },
        fee: payment.payment_fees,
        feePayer: payment.payees[0].fee_payer,
        feeStructure: {
          customerFee: _get(payment, "payees[0].fee_structure.customer_fee", 0),
          customerRate: _get(
            payment,
            "payees[0].fee_structure.customer_rate",
            0
          ),
          requesterFee: _get(
            payment,
            "payees[0].fee_structure.requester_fee",
            0
          ),
          requesterRate: _get(
            payment,
            "payees[0].fee_structure.requester_rate",
            0
          )
        },
        grossAmount: payment.payees[0].gross_amount,
        id: payment.id,
        invoiceAmount: payment.payment_total,
        invoiceNo: payment.payees[0].payment_description,
        isSmsInvoice: payment.is_sms_invoice,
        net: payment.payment_amount,
        paymentStatusId: payment.payment_status_id,
        payoutDate: payment.payout_date,
        receiptNumber: _get(payment, "card_charges[0].receipt_number", "-"),
        requesterRates: _get(payment, "payees[0].requester_rates", []),
        smsStatusId: payment.sms_status,
        supportingDocument: payment.supporting_documents.map((sp: any) => ({
          key: sp.key,
          name: sp.name,
          size: sp.size,
          url: sp.url
        })),
        updatedAt: payment.updated_at
      })),
      total
    })
  );

  return;
}

export function* handleFetchPaymentCollectionsActivityStatus(
  action: ActionType<typeof actions.fetchPaymentCollectionsActivityStatus>
) {
  const res: Response = yield call(RestClient.send, {
    service: "get_payments_history_dashboard",
    query: {
      outgoing_payment_summary: "y",
      outgoing_payment_summary_in_this_month: "y",
      incoming_payment_summary: "y",
      incoming_payment_summary_in_this_month: "y"
    }
  });

  if (!res) {
    throw new HttpRequestError("Failed to fetch");
  }

  const incomingPaymentSummary: {} = _get(res, "incoming_payment_summary", {});
  const incomingPaymentSummaryInThisMonth: {} = _get(
    res,
    "incoming_payment_summary_in_this_month",
    {}
  );
  yield put(
    actions.bePaidSetSumPaymentInThisMonth({
      completed:
        _get(incomingPaymentSummaryInThisMonth, `${PAYMENT_STATUS.PAID}`, 0) +
        _get(
          incomingPaymentSummaryInThisMonth,
          `${PAYMENT_STATUS.COMPLETED}`,
          0
        ),
      inprogress:
        _get(
          incomingPaymentSummaryInThisMonth,
          `${PAYMENT_STATUS.IN_PROGRESS_2}`,
          0
        ) +
        _get(incomingPaymentSummaryInThisMonth, `${PAYMENT_STATUS.ON_HOLD}`, 0),
      request: _get(
        incomingPaymentSummaryInThisMonth,
        `${PAYMENT_STATUS.REQUEST}`,
        0
      )
    })
  );
  yield put(
    actions.setPaymentCollections({
      activity: {
        completed:
          _get(incomingPaymentSummary, `${PAYMENT_STATUS.COMPLETED}`, 0) +
          _get(incomingPaymentSummary, `${PAYMENT_STATUS.PAID}`, 0),
        inprogress:
          _get(incomingPaymentSummary, `${PAYMENT_STATUS.IN_PROGRESS_2}`, 0) +
          _get(incomingPaymentSummary, `${PAYMENT_STATUS.ON_HOLD}`, 0) +
          _get(incomingPaymentSummary, `${PAYMENT_STATUS.UNDER_REVIEW}`, 0),
        request: _get(incomingPaymentSummary, `${PAYMENT_STATUS.REQUEST}`, 0)
      },
      isFetching: false
    })
  );

  // Normal payment
  const total: number = _get(res, "total", 0);
  const outgoingPaymentSummary: {} = _get(res, "outgoing_payment_summary", {});
  const outgoingPaymentSummaryInThisMonth: {} = _get(
    res,
    "outgoing_payment_summary_in_this_month",
    {}
  );
  const sumPaymentAmount: number = _get(res, "sum_payment_amount", 0);
  const sumPaymentTotal: number = _get(res, "sum_payment_total", 0);
  const totalBankPayout: number = _get(res, "total_bank_payout", 0);
  const totalStripeTransactions: number = _get(
    res,
    "total_stripe_transactions",
    0
  );

  yield put(
    actions.setPayments({
      isFetching: false,
      sumPaymentAmount,
      sumPaymentTotal,
      total,
      totalBankPayout,
      totalStripeTransactions
    })
  );

  yield put(
    actions.setPaymentActivity({
      completed: _get(outgoingPaymentSummary, `${PAYMENT_STATUS.COMPLETED}`, 0),
      inprogress:
        _get(outgoingPaymentSummary, `${PAYMENT_STATUS.IN_PROGRESS_2}`, 0) +
        _get(outgoingPaymentSummary, `${PAYMENT_STATUS.ON_HOLD}`, 0),
      scheduled: _get(
        outgoingPaymentSummary,
        `${PAYMENT_STATUS.IN_PROGRESS}`,
        0
      ) // is Schedule
    })
  );

  yield put(
    actions.setSumPaymentInThisMonth({
      completed: _get(
        outgoingPaymentSummaryInThisMonth,
        `${PAYMENT_STATUS.COMPLETED}`,
        0
      ),
      inprogress:
        _get(
          outgoingPaymentSummaryInThisMonth,
          `${PAYMENT_STATUS.IN_PROGRESS_2}`,
          0
        ) +
        _get(outgoingPaymentSummaryInThisMonth, `${PAYMENT_STATUS.ON_HOLD}`, 0),
      scheduled: _get(
        outgoingPaymentSummaryInThisMonth,
        `${PAYMENT_STATUS.IN_PROGRESS}`,
        0
      ) // is Schedule
    })
  );

  return;
}

export function* handleMarkPaymentAsPaid(
  action: ActionType<typeof actions.markPaymentAsPaid>
) {
  const state: RootState = yield select();
  const formState = selectors.getControls(state, MARK_PAYMENT_AS_PAID_FORM);

  const paidDate = _get(formState, "paid_date.value");
  const { id, FetchInfo } = action.payload;

  const { data: FetchData, cb = () => null } = FetchInfo || {};

  const res: Response = yield call(RestClient.send, {
    body: {
      note_other: !FetchInfo ? "" : FetchData.moreInfo,
      payment_id: id,
      payment_method: !FetchInfo ? "" : FetchData.paymentMethod,
      payout_date: !FetchInfo ? paidDate : FetchData.paidDate
    },
    service: "mark_payment_as_paid",
    showGlobalLoader: true
  });

  if (!res) {
    return false;
  }

  const errors = _get(res, "errors", {});

  if (!_isEmpty(errors)) {
    yield put(formActions.parseServerErrors(errors, MARK_PAYMENT_AS_PAID_FORM));

    return false;
  }

  if (!FetchInfo) {
    yield put(actions.closeModal(ModalID.BE_PAID_MARK_PAYMENT_AS_PAID));
    yield put(actions.fetchPaymentCollectionsActivityStatus());
    yield put(actions.fetchDashboardCollectedPayments());
  } else {
    cb(null);
  }
  return;
}

export function* handleRefundCollectionPayment(
  action: ActionType<typeof actions.refundCollectionPayment>
) {
  const state: RootState = yield select();
  const formState = selectors.getControls(state, REFUND_FORM);
  const paymentId = action.payload.paymentId;

  const { FetchInfo } = action.payload;
  const { cb = () => null, data: FetchData } = FetchInfo || {};

  let body = {};

  if (!FetchInfo) {
    const refundAmount = _get(formState, "refund_amount.value");
    let refundReason = _get(formState, "reason_refund.value", "");
    const refundReasonCode = refundReason.replace(" ", "_").toUpperCase();
    const otherRefundReason = _get(formState, "other_reason.value", "");

    if (refundReason === "Other") {
      refundReason = otherRefundReason;
    }

    body = {
      amount: utils.amountStringToInt(refundAmount),
      other_refusal_reason: "",
      payment_id: paymentId,
      refusal_reason: refundReason,
      refusal_reason_code: refundReasonCode
    };
  } else {
    body = {
      amount: utils.amountStringToInt(FetchData.refundAmount),
      other_refusal_reason: FetchData.otherReason,
      payment_id: paymentId,
      refusal_reason: FetchData.reason,
      refusal_reason_code: FetchData.reason.replace(" ", "_").toUpperCase()
    };
  }

  const res: Response = yield call(RestClient.send, {
    body,
    service: "refund_payment",
    showGlobalLoader: true
  });

  if (!res) {
    return;
  }

  const errors = _get(res, "errors", {});

  if (!_isEmpty(errors)) {
    yield put(formActions.parseServerErrors(errors, REFUND_FORM));
    return false;
  }

  if (FetchInfo) {
    cb(null);
  } else {
    yield put(actions.closeModal(ModalID.BE_PAID_REFUND));
    yield put(actions.fetchPaymentCollectionsActivityStatus());
    yield put(actions.fetchDashboardCollectedPayments());
  }

  return;
}

export function* handleFetchPaymentCollectionsHistoryList(
  action: ActionType<typeof actions.fetchPaymentCollectionsHistoryList>
) {
  yield put(
    actions.setPaymentCollections({
      isFetching: true,
      payments: [],
      total: 0
    })
  );

  const { offset } = action.payload;

  const defaultStatusIds = [
    PAYMENT_STATUS.IN_PROGRESS_2,
    PAYMENT_STATUS.ON_HOLD,
    PAYMENT_STATUS.INT_PENDING,
    PAYMENT_STATUS.UNDER_REVIEW,
    PAYMENT_STATUS.COMPLETED,
    PAYMENT_STATUS.PAID,
    PAYMENT_STATUS.FULLY_REFUNDED_WITH_FEE,
    PAYMENT_STATUS.FULLY_REFUNDED_WITHOUT_FEE,
    PAYMENT_STATUS.DECLINED,
    PAYMENT_STATUS.BLOCKED
  ].toString();
  // new default: "3,4,5,9,10,11,15,16,17,26"
  // old default: "3,4,6,7,9,10,11,16,17"

  let query = {
    offset,
    page_count: PAGE_COUNT,
    purposes: "4",
    statuses: defaultStatusIds
  } as any;

  const state = yield select();

  const formValues = formSelectors.getControlsAsObject(
    state,
    FILTER_EXPORT_FORM_PAYMENT_COLLECTIONS
  ) as any;

  const {
    [`charge_date_lower_${FILTER_EXPORT_FORM_PAYMENT_COLLECTIONS}`]: chargeDateLower,
    [`charge_date_upper_${FILTER_EXPORT_FORM_PAYMENT_COLLECTIONS}`]: chargeDateUpper,
    [`status_ids_${FILTER_EXPORT_FORM_PAYMENT_COLLECTIONS}`]: statusIds
  } = formValues;

  const withFilters = !!chargeDateLower || !!chargeDateUpper || !!statusIds;

  if (withFilters) {
    query = {
      ...query,
      charge_date_lower: chargeDateLower,
      charge_date_upper: chargeDateUpper,
      statuses: !!statusIds
        ? (statusIds as string).replace(/-/g, ",")
        : defaultStatusIds
    };
  }

  const res: Response = yield call(RestClient.send, {
    query,
    service: "get_payments_history_list",
    timeout: 30000
  });

  if (!res) {
    yield put(
      actions.setPaymentCollections({
        isFetching: false,
        payments: []
      })
    );
    throw new HttpRequestError("Failed to fetch");
  }

  const data: any[] = _get(res, "data", []);
  const total: number = _get(res, "total", 0);

  yield put(
    actions.setPaymentCollections({
      isFetching: false,
      payments: data.map(payment => ({
        batchId: payment.paid_out_batch_id || "-",
        card: {
          brandId: _get(payment, "cards[0].card_brand_id", 2)
        },
        createdAt: payment.created_at,
        currencyId: payment.currency_id,
        customer: {
          email: payment.payer.email,
          mobileCountryId: payment.payer.mobile_country_id,
          mobileNumber: payment.payer.mobile_number,
          name: `${payment.payer.first_name} ${payment.payer.last_name}`
        },
        fee: payment.payment_fees,
        feePayer: payment.payees[0].fee_payer,
        feeStructure: {
          customerFee: _get(payment, "payees[0].fee_structure.customer_fee", 0),
          customerRate: _get(
            payment,
            "payees[0].fee_structure.customer_rate",
            0
          ),
          requesterFee: _get(
            payment,
            "payees[0].fee_structure.requester_fee",
            0
          ),
          requesterRate: _get(
            payment,
            "payees[0].fee_structure.requester_rate",
            0
          )
        },
        grossAmount: payment.payees[0].gross_amount,
        id: payment.id,
        invoiceAmount: payment.payment_total,
        invoiceNo: payment.payees[0].payment_description,
        isSmsInvoice: payment.is_sms_invoice,
        net: payment.payment_amount,
        paymentStatusId: payment.payment_status_id,
        payoutDate: payment.payout_date,
        receiptNumber: _get(payment, "card_charges[0].receipt_number", "-"),
        requesterRates: _get(payment, "payees[0].requester_rates", []),
        smsStatusId: payment.sms_status,
        supportingDocument: payment.supporting_documents.map((sp: any) => ({
          key: sp.key,
          name: sp.name,
          size: sp.size,
          url: sp.url
        })),
        updatedAt: payment.updated_at
      })),
      total
    })
  );
}

export function* handleFetchPaymentCollectionsHistoryExport(
  action: ActionType<typeof actions.fetchPaymentCollectionsHistoryExport>
) {
  const defaultStatusIds = [
    PAYMENT_STATUS.IN_PROGRESS_2,
    PAYMENT_STATUS.ON_HOLD,
    PAYMENT_STATUS.INT_PENDING,
    PAYMENT_STATUS.UNDER_REVIEW,
    PAYMENT_STATUS.COMPLETED,
    PAYMENT_STATUS.PAID,
    PAYMENT_STATUS.FULLY_REFUNDED_WITH_FEE,
    PAYMENT_STATUS.FULLY_REFUNDED_WITHOUT_FEE,
    PAYMENT_STATUS.DECLINED,
    PAYMENT_STATUS.BLOCKED
  ].toString();
  // new default: "3,4,5,9,10,11,15,16,17,26"
  // old default: "3,4,11,16,17"

  let query = {
    offset: 0,
    page_count: 1000, // PAGE_COUNT,
    purposes: "4",
    statuses: defaultStatusIds,
    template: "collection"
  } as any;

  const state = yield select();

  const formValues = formSelectors.getControlsAsObject(
    state,
    FILTER_EXPORT_FORM_PAYMENT_COLLECTIONS
  ) as any;

  const {
    [`charge_date_lower_${FILTER_EXPORT_FORM_PAYMENT_COLLECTIONS}`]: chargeDateLower,
    [`charge_date_upper_${FILTER_EXPORT_FORM_PAYMENT_COLLECTIONS}`]: chargeDateUpper,
    [`status_ids_${FILTER_EXPORT_FORM_PAYMENT_COLLECTIONS}`]: statusIds
  } = formValues;

  const withFilters = !!chargeDateLower || !!chargeDateUpper || !!statusIds;

  if (withFilters) {
    query = {
      ...query,
      charge_date_lower: chargeDateLower,
      charge_date_upper: chargeDateUpper,
      statuses: !!statusIds
        ? (statusIds as string).replace(/-/g, ",")
        : defaultStatusIds
    };
  }

  const startDate = query.charge_date_lower
    ? format(query.charge_date_lower, "DD_MMM_YYYY")
    : "x";
  const endDate = query.charge_date_upper
    ? format(query.charge_date_upper, "DD_MMM_YYYY")
    : "y";

  const res = yield call(RestClient.download, {
    fileName: `my_payment_collections_csv_${startDate}-${endDate}.csv`,
    query,
    service: "get_payments_history_export",
    timeout: 30000
  });

  const errors = _get(res, "errors");

  if (!_isEmpty(errors)) {
    for (const e of _get(errors, "form", [])) {
      yield put(actions.toast(T.transl(e)));
    }

    return;
  }
}

export function* handleFetchDashboardCollectedPayments(
  action: ActionType<typeof actions.fetchDashboardCollectedPayments>
) {
  const res: Response = yield call(RestClient.send, {
    service: "get_payments_history_dashboard_collected_payment_list"
  });

  if (!res) {
    throw new HttpRequestError("Failed to fetch");
  }

  const data: any[] = _get(res, "data.collected_payments", []);
  yield put(
    actions.setPaymentCollections({
      isFetching: false,
      payments: data.map(payment => ({
        batchId: payment.paid_out_batch_id,
        card: {
          brandId: _get(payment, "cards[0].card_brand_id", 2)
        },
        createdAt: payment.created_at,
        currencyId: payment.currency_id,
        customer: {
          email: payment.payer.email,
          mobileCountryId: payment.payer.mobile_country_id,
          mobileNumber: payment.payer.mobile_number,
          name: `${payment.payer.first_name} ${payment.payer.last_name}`
        },
        datePaid: payment.scheduled_charge_date,
        dueDate: _get(payment, "payees[0].due_date", ""),
        emailStatusId: payment.email_status,
        fee: payment.payment_fees,
        feePayer: payment.payees[0].fee_payer,
        feeStructure: {
          customerFee: _get(payment, "payees[0].fee_structure.customer_fee", 0),
          customerRate: _get(
            payment,
            "payees[0].fee_structure.customer_rate",
            0
          ),
          requesterFee: _get(
            payment,
            "payees[0].fee_structure.requester_fee",
            0
          ),
          requesterRate: _get(
            payment,
            "payees[0].fee_structure.requester_rate",
            0
          )
        },
        grossAmount: payment.payees[0].gross_amount,
        id: payment.id,
        invoiceAmount: payment.payment_total,
        invoiceNo: payment.payees[0].payment_description,
        isSmsInvoice: payment.is_sms_invoice,
        net: payment.payment_amount,
        order: payment.order,
        payUrl: payment.pay_url,
        paymentStatusId: payment.payment_status_id,
        payoutDate: payment.payout_date,
        receiptNumber: _get(payment, "card_charges[0].receipt_number", ""),
        requesterRates: _get(payment, "payees[0].requester_rates", []),
        smsStatusId: payment.sms_status,
        supportingDocument: payment.supporting_documents.map((sp: any) => ({
          key: sp.key,
          name: sp.name,
          size: sp.size,
          url: sp.url
        })),
        updatedAt: payment.updated_at
      }))
    })
  );
}
