import _isEmpty from "lodash-es/isEmpty";
import * as DateFns from "date-fns";

export const getQueryParam = (
  qs: any,
  dateQueryKey: string = "dateQueryKey",
  customFromKey: string = "from",
  customToKey: string = "to"
) => {
  if (qs && !_isEmpty(qs)) {
    let from;
    let to;

    switch (qs[dateQueryKey]) {
      case "last_week": {
        from = DateFns.startOfWeek(new Date()).toISOString();
        to = new Date().toISOString();
        break;
      }
      case "last_month": {
        from = DateFns.startOfMonth(new Date()).toISOString();
        to = new Date().toISOString();
        break;
      }
      case "last_three_months": {
        from = DateFns.startOfQuarter(new Date()).toISOString();
        to = new Date().toISOString();
        break;
      }
      case "last_six_months": {
        from = DateFns.subQuarters(
          DateFns.startOfQuarter(new Date()),
          1
        ).toISOString();
        to = new Date().toISOString();
        break;
      }
      case "custom": {
        from = qs[customFromKey];
        to = qs[customToKey];
        break;
      }
    }

    return {
      ...qs,
      ...(from
        ? {
            from,
            to
          }
        : {})
    };
  }

  return {};
};
