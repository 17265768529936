// import { getType } from "typesafe-actions";
// import * as actions from "./actions";
import * as types from "./types";

export type PixelState = {
  readonly pixelReady: boolean;
};

const defaultState: PixelState = {
  pixelReady: false
};

export default (state = defaultState, action: types.Action) => {
  switch (
    action.type
    // case getType(actions.setPixelReady):
    //   return {
    //     ...state,
    //     pixelReady: true
    //   };
  ) {
  }
  return state;
};
