/**
 * Sagas
 *
 * All side effects must come here.
 * - calls to browser apis - localStorage, window.XXX, fetch, etc.
 */
import { call, put, select } from "redux-saga/effects";
import { ActionType } from "typesafe-actions";
import _get from "lodash-es/get";
import _isEmpty from "lodash-es/isEmpty";
import * as commonActions from "./actions";
import {
  catchTakeLatest,
  reTryTakeLatest
} from "src/ipm-shared/Utils/ReduxSagaEffects";
import HttpRequestError from "src/ipm-shared/Utils/Exceptions/HttpRequestError";
import RestClient from "src/ipm-shared/services/Rest";
import { RootState } from "../reducers";
import * as formSelectors from "src/ipm-shared/components/Form/selectors";
import * as formActions from "src/ipm-shared/components/Form/actions";
const actions = {
  ...commonActions,
  ...formActions
};

const watchedSagas = [
  reTryTakeLatest(actions.fetchListAccountUser, handleListAccountUser),
  catchTakeLatest(actions.sendAccountUserInvite, handleSendAccountUserInvite),
  catchTakeLatest(actions.deleteAccountUser, handleDeleteAccountUser)
];
export default watchedSagas;

export function* handleListAccountUser(
  action: ActionType<typeof actions.fetchListAccountUser>
) {
  const res = yield call(RestClient.send, {
    service: "list_account_user",
    timeout: 30000
  });

  if (!res) {
    yield put(actions.setListAccountUser([]));
    throw new HttpRequestError("Failed to fetch");
  }

  const data: any[] = _get(res, "data", []);

  const accountUsers = data.map(accountUser => ({
    userId: accountUser.user_id,
    accountId: accountUser.account_id,
    email: accountUser.email,
    firstName: accountUser.first_name,
    lastName: accountUser.last_name,
    roleId: accountUser.role_id
  }));

  yield put(actions.setListAccountUser(accountUsers));
}

export function* handleSendAccountUserInvite(
  action: ActionType<typeof actions.sendAccountUserInvite>
) {
  const { form, cb = () => null } = action.payload;

  const state: RootState = yield select();
  const formState = formSelectors.getControls(state, form);

  const email = _get(formState, "email.value");

  const body = {
    invite_email: email
  };

  const res = (Response = yield call(RestClient.send, {
    body,
    service: "send_account_user_invite"
  }));

  if (!res) {
    return;
  }

  const errors = _get(res, "errors", {});

  if (!_isEmpty(errors)) {
    yield put(formActions.parseServerErrors(errors, form));
    return;
  }

  yield put(actions.closeModal(actions.FetchModalID.USER_ADD_NEW_MODAL));
  yield put(
    actions.toggleModal(actions.FetchModalID.USER_INVITE_SENT_MODAL, {
      url: _get(res, "data.url", "")
    })
  );

  cb();
}

export function* handleDeleteAccountUser(
  action: ActionType<typeof actions.deleteAccountUser>
) {
  const { userId, accountId } = action.payload;

  const body = {
    user_id: userId,
    account_id: accountId
  };

  const res = (Response = yield call(RestClient.send, {
    body,
    service: "delete_account_user"
  }));

  const errors = _get(res, "errors", undefined);

  if (errors) {
    return;
  }

  if (action.payload.successCb) {
    action.payload.successCb();
  }

  // Re-fetch payees
  yield put(actions.fetchListAccountUser({}));
}
