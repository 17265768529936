/* tslint:disable:object-literal-sort-keys */
import React, { useState } from "react";
import classNames from "classnames";
import { BrowserView, MobileView } from "react-device-detect";
import _get from "lodash-es/get";
import _isEmpty from "lodash-es/isEmpty";

import MainLayout from "src/bepaid/layouts/MainLayout";
import DesktopModalLayout from "src/bepaid/components/Modal/components/DesktopModalLayout";
import Button from "src/bepaid/components/Form/controls/Button";
import ROUTES from "src/bepaid/routes";
import Progress from "src/bepaid/components/Progress";

// import AdditionalData from "../AdditionalData";

import styles from "./AdditionalData.module.scss";
import AddtionalDataForm from "./components/AddtionalDataForm";
import { invokeMessage } from "src/bepaid/components/Message";
import { PERSONAL_INFORMATION_FORM } from "src/bepaid/store/model/Fetch/const";
import { history } from "src/ipm-shared/store";

const AdditionalData: React.FC<any> = props => {
  const [isLoading, setLoading] = useState(false);
  const [confirmedName, setConfirmName] = useState(props.isSingaporeAccount);

  if (!props.accountProfile.email) {
    return null;
  }

  const onSubmit = () => {
    props.updateAdditionalData(
      PERSONAL_INFORMATION_FORM,
      (err: any) => {
        if (!err) {
          invokeMessage("success", "Changes saved.");
          props.changeUserSetting({
            enable: false,
            property: "additional_data_modal",
            refreshDisable: true
          });
          history.push(ROUTES.ROOT);
          props.fetchAccountProfiles();
        } else {
          invokeMessage(
            "error",
            "Submit failed, please contact us for more details."
          );
        }
      },
      setLoading
    );
  };

  const renderButtonSubmit = () => {
    return (
      <div className={styles.btnNext}>
        <Button
          type="primary"
          htmlType="submit"
          onClick={onSubmit}
          loading={isLoading || !confirmedName}
        >
          {isLoading ? "Submitting" : "Submit account details"}
        </Button>
      </div>
    );
  };
  const renderAdditionalDataForm = ({ className }: any) => {
    return (
      <div className={className}>
        <div className={styles.title}>
          <span>Tell us something about yourself.</span>
        </div>
        <AddtionalDataForm onConfirmName={onConfirmName} />
      </div>
    );
  };

  const onConfirmName = (e: any) => {
    setConfirmName(e.target.checked);
  };

  return (
    <>
      <BrowserView>
        <DesktopModalLayout
          title={"Get started"}
          maxWidth450={true}
          onClose={undefined}
          btnSubmit={renderButtonSubmit()}
        >
          <div className={classNames(styles.wrapper, styles.desktopWrapper)}>
            <div
              id="additional_data_wrapper_content"
              className={styles.wrapperInner}
            >
              {renderAdditionalDataForm({ className: styles.formBody })}
            </div>
          </div>
        </DesktopModalLayout>
      </BrowserView>
      <MobileView>
        <MainLayout
          header={{
            title: "Get started",
            onClose: undefined,
            center: true,
            hideMenu: true,
            progress: <Progress colors={[]} value={0} />
          }}
          footer={renderButtonSubmit()}
        >
          <div
            id="additional_data_wrapper_content"
            className={styles.wrapperInnerMobile}
          >
            {renderAdditionalDataForm({})}
          </div>
        </MainLayout>
      </MobileView>
    </>
  );
};

export default AdditionalData;
