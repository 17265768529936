import React from "react";
import { isDesktop } from "react-device-detect";
import _get from "lodash-es/get";
import Form from "src/bepaid/components/Form";
import classnames from "classnames";

import { CHANGE_EMAIL_FORM } from "src/ipm-shared/store/model/Auth/const";

import InputText from "src/bepaid/components/Form/controls/InputText";
import Button from "src/bepaid/components/Form/controls/Button";
import { invokeMessage } from "src/bepaid/components/Message";

import styles from "./ChangeEmail.module.scss";

const FormComponent = ({
  changeEmail,
  resendOrCancelEmail,
  fetchAccountProfiles,
  currentEmail,
  emailAwaitingVerification
}: any) => {
  const [loading, setLoading] = React.useState(false);

  const successSubmit = () => {
    fetchAccountProfiles();
  };

  const onSubmit = () => {
    changeEmail((err: any) => {
      if (!err) {
        invokeMessage(
          "success",
          "Email changed successfully. Please look for the verification email sent to your email."
        );
        successSubmit();
      }
    }, setLoading);
  };

  const onClickCancelEmail = () => {
    resendOrCancelEmail(
      "cancel",
      (err: any) => {
        if (!err) {
          invokeMessage("success", "Verification link cancelled.");
          successSubmit();
        }
      },
      setLoading
    );
  };

  const onClickResendEmail = () => {
    resendOrCancelEmail(
      "resend",
      (err: any) => {
        if (!err) {
          invokeMessage(
            "success",
            "Verification link resent to your new email."
          );
          successSubmit();
        }
      },
      setLoading
    );
  };

  return (
    <Form
      name="change-email-form"
      layout="vertical"
      requiredMark={false}
      className={styles.form}
      onFinish={onSubmit}
    >
      <div className={styles.formBody}>
        <InputText
          name="current_email"
          form={CHANGE_EMAIL_FORM}
          label="Current email"
          defaultValue={emailAwaitingVerification || currentEmail}
          disabled={true}
          {...(emailAwaitingVerification
            ? {
                helperText: {
                  showInEmptyMode: true,
                  txt: (
                    <div className={"purple_text mb-md"}>
                      Email is not yet verified, please check your inbox and
                      look for the verification email.{" "}
                      <a onClick={onClickResendEmail}>
                        <b>Click here to resend verification email</b>
                      </a>
                      .
                      <br />
                      <br />
                      <a onClick={onClickCancelEmail}>
                        <b>Or click here to cancel verification email.</b>
                      </a>
                    </div>
                  )
                }
              }
            : {})}
        />
        <InputText
          type="password"
          name="old_password"
          placeholder="Please enter your password"
          form={CHANGE_EMAIL_FORM}
          label="Current password"
        />
        <InputText
          name="new_email"
          placeholder="Please enter your new email"
          form={CHANGE_EMAIL_FORM}
          pattern="__EMAIL__"
          label="New email"
        />
        <InputText
          name="confirm_email"
          placeholder="Please re-enter your new email"
          form={CHANGE_EMAIL_FORM}
          pattern="__EMAIL__"
          label="Confirm email"
        />
      </div>
      <Form.Item>
        <Button
          className={styles.btnSubmit}
          type="primary"
          htmlType="submit"
          loading={loading}
        >
          {loading ? "Saving changes..." : "Save changes"}
        </Button>
      </Form.Item>
    </Form>
  );
};

const ChangeEmail: React.FC<any> = props => {
  const {
    accountProfile,
    changeEmail,
    resendOrCancelEmail,
    fetchAccountProfiles
  } = props;
  const currentEmail = _get(accountProfile, "displayedEmail", "");
  const emailAwaitingVerification = _get(
    accountProfile,
    "emailAwaitingVerification",
    ""
  );

  return (
    <div
      className={classnames(styles.wrapper, {
        [styles.wrapperDesktop]: isDesktop
      })}
    >
      <FormComponent
        changeEmail={changeEmail}
        resendOrCancelEmail={resendOrCancelEmail}
        fetchAccountProfiles={fetchAccountProfiles}
        currentEmail={currentEmail}
        emailAwaitingVerification={emailAwaitingVerification}
      />
    </div>
  );
};

export default ChangeEmail;
