import React, { useState } from "react";
import { BrowserView, isDesktop, MobileView } from "react-device-detect";
import { history } from "src/ipm-shared/store";
import { useMakePaymentUrlParams } from "src/bepaid/pages/Payments/hooks";

import DesktopModalLayout from "src/bepaid/components/Modal/components/DesktopModalLayout";
import MainLayout from "src/bepaid/layouts/MainLayout";
import Button from "src/bepaid/components/Form/controls/Button";
import ROUTES from "src/bepaid/routes";
import classnames from "classnames";
import styles from "./SelectPaymentMethodModal.module.scss";
import PaymentMethodItem from "./components/PaymentMethodItem";
import { PAYMENT_METHOD_TYPES } from "src/ipm-shared/store/model/PaymentRequest/const";

const View = (props: any) => {
  const { replaceRouteWithParams } = useMakePaymentUrlParams(
    ROUTES.PAYMENTS_CREATION
  );
  const [loading, setLoading] = useState(false);
  const [selectedMethodType, selectMethodType] = useState<string>();

  const paymentMethodTypes = [
    {
      name: "Card",
      description: "Pay with card.",
      payment_method_type: PAYMENT_METHOD_TYPES.CARD
    },
    {
      name: "Crypto",
      description: "Pay with Crypto.",
      payment_method_type: PAYMENT_METHOD_TYPES.CRYPTO
    }
  ];

  const onSelectedPaymentMethodType = (payment_method_type: string) => {
    if (props.canAccessCryptoPayment) {
      selectMethodType(payment_method_type);
    } else if (payment_method_type === PAYMENT_METHOD_TYPES.CARD) {
      selectMethodType(PAYMENT_METHOD_TYPES.CARD);
    }
  };

  const moveForward = () => {
    setLoading(true); // ok

    setTimeout(() => {
      props.setPaymentMethodType(selectedMethodType);
      if (
        !props.hasIdVerification &&
        selectedMethodType === PAYMENT_METHOD_TYPES.CRYPTO
      ) {
        history.push(
          replaceRouteWithParams(ROUTES.PAYMENTS_CREATION, {
            newEntity: "verify",
            newAction: "identity"
          })
        );
      } else {
        history.push(
          replaceRouteWithParams(ROUTES.PAYMENTS_CREATION, {
            newEntity: "entity",
            newAction: "select"
          })
        );
      }
    }, 500);
  };

  const renderContent = () => {
    return (
      <>
        <div
          className={classnames(styles.wrapper, {
            [styles.desktop]: isDesktop
          })}
        >
          <ul className={styles.list}>
            {paymentMethodTypes.map((paymentMethodType, idx: number) => (
              <li
                key={idx}
                onClick={onSelectedPaymentMethodType.bind(
                  null,
                  paymentMethodType.payment_method_type
                )}
                className={classnames({
                  [styles.selected]:
                    paymentMethodType.payment_method_type ===
                    selectedMethodType,
                  [styles.enabled]:
                    paymentMethodType.payment_method_type ===
                      PAYMENT_METHOD_TYPES.CARD || props.canAccessCryptoPayment,
                  [styles.disabled]:
                    paymentMethodType.payment_method_type ===
                      PAYMENT_METHOD_TYPES.CRYPTO &&
                    !props.canAccessCryptoPayment
                })}
              >
                <PaymentMethodItem paymentMethodType={paymentMethodType} />
              </li>
            ))}
          </ul>
        </div>
      </>
    );
  };

  const renderBtnSubmit = () => {
    return (
      <Button
        id={"ipaymy_btn_select_method"}
        wrapperClassName={styles.wrapperButton}
        className={styles.button}
        onClick={moveForward}
        type="primary"
        htmlType="button"
        loading={!selectedMethodType || loading}
      >
        {loading ? "Moving forward..." : "Move forward"}
      </Button>
    );
  };

  const renderDesktopView = () => {
    return (
      <DesktopModalLayout
        id={"ipaymy_select_method_domestic_payment"}
        title={"Select payment method"}
        onClose={props.onClose}
        maxWidth450={true}
        onSubmit={moveForward}
        btnSubmit={renderBtnSubmit()}
      >
        {renderContent()}
      </DesktopModalLayout>
    );
  };

  const renderMobileView = () => {
    return (
      <MainLayout
        id={"ipaymy_select_method_domestic_payment"}
        header={{
          title: "Select payment method",
          hideMenu: true,
          onClose: props.onClose
        }}
        footer={renderBtnSubmit()}
      >
        {renderContent()}
      </MainLayout>
    );
  };

  return (
    <>
      <BrowserView>{renderDesktopView()}</BrowserView>
      <MobileView>{renderMobileView()}</MobileView>
    </>
  );
};

export default View;
