import React from "react";
import _get from "lodash-es/get";
import classNames from "classnames";

import Form from "src/bepaid/components/Form";

import { ADD_FORM } from "src/ipm-shared/store/model/CollectedAccount/const";

import InputText from "src/bepaid/components/Form/controls/InputText";
import CompanyInformation from "../CompanyInformation";

import FormStyles from "src/bepaid/components/Form/CommonForm.module.scss";
import OtherStyles from "../KYCContents/KycContents.module.scss";

const styles = {
  ...FormStyles,
  ...OtherStyles
};

const ProfileInformationForm = (props: any) => {
  const commonFormItemProps = {
    reserveValueOnUnmount: true,
    revertValueOnMount: true
  };

  const data = props.collectedAccount;
  // const profile = props.accountProfile;
  // const currentCompany = props.currentCompany;
  return (
    <div className={styles.content}>
      <Form
        layout="vertical"
        requiredMark={false}
        className={styles.personalFormDetail}
      >
        <InputText
          form={ADD_FORM}
          name="recipient_email"
          pattern="__EMAIL__"
          placeholder="Your email name"
          label="Email address for transaction notifications"
          defaultValue={_get(data, "payeeData.recipientEmail", "")}
          // helperText={{
          //   txt:
          //     "Please provide the email address you would like all information regarding payments made to your account to be sent to."
          // }}
          {...commonFormItemProps}
        />
        <InputText
          form={ADD_FORM}
          name="confirm_recipient_email"
          pattern="__EMAIL__"
          placeholder="Confirm your email"
          label="Confirm email address for transaction notifications"
          defaultValue={_get(data, "payeeData.confirmedEmail", "")}
          {...commonFormItemProps}
        />
      </Form>
      <div className={styles.title}>
        <span>
          Let’s help your customer identify your payment request and their
          payment.
        </span>
      </div>
      <Form
        layout="vertical"
        requiredMark={false}
        className={styles.personalFormDetail}
      >
        <p className={styles.label}>Statement descriptor</p>
        <p className={classNames(styles.text, styles.purple)}>
          This information may appear on your customer’s credit card statement
        </p>
        <InputText
          form={ADD_FORM}
          name="default_statement_descriptor"
          placeholder="Acme"
          defaultValue={_get(data, "payeeData.defaultStatementDescriptor")}
          helperText={{
            txt:
              "We recommend using a descriptor your customer will recognise, such as the name of your business."
          }}
          {...commonFormItemProps}
        />
      </Form>
      <CompanyInformation hidden={true} {...props} />
    </div>
  );
};
export default ProfileInformationForm;
