import React, { useMemo } from "react";
import FormStyles from "src/bepaid/components/Form/CommonForm.module.scss";
import ShortCurrency from "src/ipm-shared/components/ShortCurrency";
import Image from "src/bepaid/components/Image";
import DateUtils from "src/ipm-shared/Utils/Date";
import styles from "./Invoice.module.scss";
import { useMakePaymentUrlParams } from "src/bepaid/pages/Payments/hooks";
import _get from "lodash-es/get";
import IconExchangePurple from "src/bepaid/assets/images/payment-activity/icon_exchange_purple.svg";
import { purposeByEntity } from "src/bepaid/pages/Payments/const";
import Checkbox from "antd/es/checkbox";
import Tooltip from "src/ipm-shared/components/Tooltip";

const {
  invoiceOverView,
  normalText,
  alignRightText,
  boldText,
  table,
  boldHr,
  payeeItem,
  exchangeText,
  chkContainer,
  msgText
} = styles as any;

const Invoice = (props: any) => {
  const { entity, paymentType } = useMakePaymentUrlParams();
  const isWallex = paymentType === "international";
  const isSelfTransfer = entity === "self_transfer";

  const paymentRequestPayees = props.paymentRequest?.payees || [];

  const paymentRequest = props.paymentRequest;

  const subTotal = props.paymentRequest?.subtotal || 0;

  const { amountOff = 0 } = props.paymentRequest;

  const fees = props.fees || {};

  const feeAmount = fees.licenseFee - amountOff;

  const channelFees = _get(paymentRequest, "channelFees", 0);

  const payees = useMemo(() => {
    if (!props.getPayeesByPurpose) return [];
    const purpose = purposeByEntity[entity];
    const payees = props.getPayeesByPurpose(purpose);
    return payees;
  }, [entity, props.getPayeesByPurpose]);

  const getPayeeById = (id: any) => {
    const payee = payees.find((item: any) => item.id === id);
    return payee || {};
  };

  const isShowAmount = paymentRequestPayees?.length > 1;

  const currencyId =
    paymentRequestPayees?.length > 0 ? paymentRequestPayees[0].currencyId : 1;

  const isShowAcceptAmexCheckbox =
    props.isAmexCard &&
    props.isAmexPayment &&
    props.isPersonalAccount &&
    props.isOneTimePayment &&
    !props.isAmexMcc &&
    props.isFlashPayPayment;

  const isShowUnionNote = props.isUnionCard && props.isFlashPayPayment;

  const payeeItemContent = (payeeRequest: any) => (
    <>
      <p className={normalText}>
        <span>Account number: {payeeRequest.accountNumber}</span>
      </p>
      <p className={normalText}>
        <span>Note: {payeeRequest.defaultComments}</span>
      </p>
    </>
  );

  const minimumTransactionInfo = {
    SG: {
      description: "For transactions S$100 and below"
    },
    MY: {
      description: "For transactions RM350 and below"
    },
    HK: {
      description: "For transactions HK$600 and below"
    },
    AU: {
      description: "For transactions A$100 and below"
    }
  }[props.countryCode];

  return (
    <div className={styles.wrapper}>
      <div className={FormStyles.personalFormDetail}>
        <div className={invoiceOverView}>
          {entity === "landlord" && (
            <>
              <p className={normalText}>Landlord</p>
              {paymentRequestPayees.map((payeeRequest: any, idx: number) => {
                const payee = getPayeeById(payeeRequest.id);
                return (
                  <div className={payeeItem} key={idx}>
                    <p className={boldText}>{payee.name}</p>
                    <p className={normalText}>
                      <span>{payee.address}</span>
                      {isShowAmount && (
                        <p className={normalText}>
                          <ShortCurrency
                            value={payee.currentAmount}
                            currencyId={currencyId}
                          />
                        </p>
                      )}
                    </p>
                    {payeeItemContent(payeeRequest)}
                  </div>
                );
              })}
            </>
          )}
          {entity === "employee" && (
            <>
              <p className={normalText}>Employee</p>
              {paymentRequestPayees.map((payeeRequest: any, idx: number) => {
                const payee = getPayeeById(payeeRequest.id);

                return (
                  <div className={payeeItem} key={idx}>
                    <p className={boldText}>{payee.name}</p>
                    <p className={normalText}>
                      <span>Employee ID: {payee.id}</span>
                      {isShowAmount && (
                        <p className={normalText}>
                          <ShortCurrency
                            value={payee.currentAmount}
                            currencyId={currencyId}
                          />
                        </p>
                      )}
                    </p>
                    {payeeItemContent(payeeRequest)}
                  </div>
                );
              })}
            </>
          )}

          {["supplier", "tax"].includes(entity) && !isWallex && (
            <>
              <p className={normalText}>
                {entity === "tax" ? "Recipient" : "Supplier"}
              </p>
              {paymentRequestPayees.map((payeeRequest: any, idx: number) => {
                const payee = getPayeeById(payeeRequest.id);

                return (
                  <div className={payeeItem} key={idx}>
                    <p className={boldText}>{payee.name}</p>
                    <p className={normalText}>
                      <span>
                        {props.countryCode === "AU" ? "ABN" : "UEN"}:{" "}
                        {payee.registrationNumber}
                      </span>
                      {isShowAmount && (
                        <p className={normalText}>
                          <ShortCurrency
                            value={payeeRequest.defaultAmount}
                            currencyId={currencyId}
                          />
                        </p>
                      )}
                    </p>
                    {payeeItemContent(payeeRequest)}
                  </div>
                );
              })}
            </>
          )}
          {["supplier", "self_transfer"].includes(entity) && isWallex && (
            <>
              <p className={normalText}>
                {entity === "supplier" ? "Supplier" : "Bank Account"}
              </p>
              {paymentRequestPayees.map((payeeRequest: any, idx: number) => {
                return (
                  <div className={table} key={idx}>
                    <p className={boldText}>
                      {`${payeeRequest.name}`}
                      {isSelfTransfer && (
                        <span>{`: ${_get(
                          payeeRequest,
                          "international.bankRawName",
                          "..."
                        )} - ${_get(
                          payeeRequest,
                          "accountNumber",
                          "..."
                        )}`}</span>
                      )}
                    </p>
                    <p className={normalText}>
                      <ShortCurrency value={subTotal} currencyId={currencyId} />
                    </p>
                  </div>
                );
              })}
            </>
          )}

          {["business_employee"].includes(entity) && (
            <>
              <p className={normalText}>Payor</p>
              {paymentRequestPayees.map((payeeRequest: any, idx: number) => {
                const payee = getPayeeById(payeeRequest.id);
                return (
                  <div className={payeeItem} key={idx}>
                    <p className={boldText}>{payee.name}</p>
                    <p className={normalText}>
                      <span>{`Bank Account: ${_get(
                        payeeRequest,
                        "bankName",
                        "..."
                      )} - ${_get(
                        payeeRequest,
                        "accountNumber",
                        "..."
                      )}`}</span>
                      {isShowAmount && (
                        <p className={normalText}>
                          <ShortCurrency
                            value={payee.currentAmount}
                            currencyId={currencyId}
                          />
                        </p>
                      )}
                    </p>
                  </div>
                );
              })}
            </>
          )}

          {["insurance"].includes(entity) && (
            <>
              <p className={normalText}>Insurer</p>
              {paymentRequestPayees.map((payeeRequest: any, idx: number) => {
                const payee = getPayeeById(payeeRequest.id);
                return (
                  <div className={payeeItem} key={idx}>
                    <p className={boldText}>{payee.finalName}</p>
                    <p className={normalText}>
                      <span>{`UEN: ${_get(payee, "uen", "...")}`}</span>
                      {isShowAmount && (
                        <p className={normalText}>
                          <ShortCurrency
                            value={payeeRequest.defaultAmount}
                            currencyId={currencyId}
                          />
                        </p>
                      )}
                    </p>
                    <p className={normalText}>
                      <span>{`Bank Account: ${_get(
                        payeeRequest,
                        "accountNumber",
                        "..."
                      )}`}</span>
                    </p>
                    <p className={normalText}>
                      <span>
                        Insurance policy number: {payeeRequest.defaultComments}
                      </span>
                    </p>
                  </div>
                );
              })}
            </>
          )}

          <hr className={boldHr} />
          <div className={table}>
            <p className={normalText}>Subtotal</p>
            <p className={normalText}>
              <ShortCurrency value={subTotal} currencyId={currencyId} />
            </p>
          </div>
          <div className={table}>
            <p className={normalText} />
            <p className={normalText}>
              {isWallex && (
                <ShortCurrency
                  value={fees.paymentAmount}
                  currencyId={isWallex ? paymentRequest.currencyId : currencyId}
                />
              )}
            </p>
          </div>
          <hr className={boldHr} />
          <div className={table}>
            <p className={normalText}>
              Fee (
              {props.loadingFee
                ? "..."
                : `${fees.isDeductedRate ? 0 : fees.rateUsed}%`}
              )
            </p>
            <p className={normalText}>
              {props.loadingFee ? (
                "..."
              ) : (
                <ShortCurrency
                  value={fees.isDeductedRate ? 0 : feeAmount}
                  currencyId={isWallex ? paymentRequest.currencyId : currencyId}
                />
              )}
            </p>
          </div>

          {fees.gstFee && !fees.isDeductedRate ? (
            <div className={table}>
              <p className={normalText}>GST (10%)</p>
              <p className={normalText}>
                {props.loadingFee ? (
                  "..."
                ) : (
                  <ShortCurrency
                    value={fees.gstFee}
                    currencyId={
                      isWallex ? paymentRequest.currencyId : currencyId
                    }
                  />
                )}
              </p>
            </div>
          ) : (
            <></>
          )}

          {fees.flashPayFee ? (
            <div className={table}>
              <p className={normalText}>Flash Pay</p>
              <p className={normalText}>
                {props.loadingFee ? (
                  "..."
                ) : (
                  <ShortCurrency
                    value={fees.flashPayFee}
                    currencyId={
                      isWallex ? paymentRequest.currencyId : currencyId
                    }
                  />
                )}
              </p>
            </div>
          ) : (
            <></>
          )}

          {fees.bankPayoutFee ? (
            <div className={table}>
              <p className={normalText}>Fund Safeguarding</p>
              <span className={styles.tooltipWrapper}>
                <Tooltip
                  description={
                    <>
                      In compliance with the Payment Services Act 2019, this
                      ensures your funds are protected and held in a segregated
                      safeguarded account. The fund safeguarding applies to each
                      payout instruction.
                    </>
                  }
                  displayIcon={true}
                  iconColor="gray"
                  target={`random`}
                  className={styles.tooltip}
                  width={16}
                />
              </span>
              <p className={normalText}>
                {props.loadingFee ? (
                  "..."
                ) : (
                  <ShortCurrency
                    value={fees.bankPayoutFee}
                    currencyId={
                      isWallex ? paymentRequest.currencyId : currencyId
                    }
                  />
                )}
              </p>
            </div>
          ) : (
            <></>
          )}

          {fees.minimumTransactionFee ? (
            <div className={table}>
              <p className={normalText}>Minimum Transaction Fee</p>
              <span className={styles.tooltipWrapper}>
                <Tooltip
                  description={minimumTransactionInfo.description}
                  displayIcon={true}
                  iconColor="gray"
                  target={`random`}
                  className={styles.tooltip}
                  width={16}
                />
              </span>
              <p className={normalText}>
                {props.loadingFee ? (
                  "..."
                ) : (
                  <ShortCurrency
                    value={fees.minimumTransactionFee}
                    currencyId={
                      isWallex ? paymentRequest.currencyId : currencyId
                    }
                  />
                )}
              </p>
            </div>
          ) : (
            <></>
          )}

          {channelFees ? (
            <div className={table}>
              <p className={normalText}>Bank Fee</p>
              <p className={normalText}>
                {props.loadingFee ? (
                  "..."
                ) : (
                  <ShortCurrency
                    value={channelFees}
                    currencyId={
                      isWallex ? paymentRequest.currencyId : currencyId
                    }
                  />
                )}
              </p>
            </div>
          ) : (
            <></>
          )}

          <hr className={boldHr} />
          <div className={table}>
            <p className={boldText}>Total</p>
            <p className={boldText}>
              {props.loadingFee ? (
                "..."
              ) : (
                <ShortCurrency
                  value={_get(fees, "total", 0)}
                  currencyId={isWallex ? paymentRequest.currencyId : currencyId}
                />
              )}
            </p>
          </div>
          <hr className={boldHr} />
          {isWallex && (
            <div className={table}>
              <p className={exchangeText}>Exchange Rate</p>
              <p className={exchangeText}>
                <div className={styles.exchangeIcon}>
                  <Image src={IconExchangePurple} />{" "}
                </div>
                {paymentRequest.exchangeRate}
              </p>
            </div>
          )}
          {isWallex ? (
            <>
              <div className={table}>
                <p className={normalText}>Your payment will arrive by: </p>
                <p className={alignRightText}>
                  {`${new Date(props.earliestDate).toLocaleDateString("en-GB", {
                    year: "numeric",
                    month: "long",
                    day: "2-digit",
                    timeZone: "Asia/Singapore"
                  })} (SGT)`}
                </p>
              </div>
            </>
          ) : (
            <>
              <div className={table}>
                <p className={normalText}>Payout Date</p>
                <p className={normalText}>
                  {DateUtils.formatLocaleDate(props.earliestDate, {
                    day: "numeric",
                    month: "short",
                    year: "numeric"
                  })}
                </p>
              </div>
            </>
          )}
          {isShowUnionNote && (
            <div className={msgText}>
              <p>{"Note: Flash Pay is not applicable for UnionPay cards."}</p>
            </div>
          )}
          {isShowAcceptAmexCheckbox && (
            <>
              <div className={msgText}>
                <p>
                  *Kindly note that Flash Pay is not applicable for AMEX card
                  payments.
                </p>
              </div>
              <div className={chkContainer}>
                <Checkbox onChange={props.onChangeAcceptAmexConfirmCheckbox}>
                  I accept this new payout date.
                </Checkbox>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Invoice;
