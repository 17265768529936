import React from "react";
import ImageUtils from "src/ipm-shared/Utils/Images";
import styles from "./CardSelector.module.scss";
import Select from "antd/es/select";
import classNames from "classnames";
import _get from "lodash-es/get";
import T from "src/ipm-shared/Utils/Intl";
import uuid from "uuid";

import Favourite from "src/bepaid/components/Favourite";

const { Option } = Select;

export const PAY_WITH_ANOTHER_CARD_VALUE = "PAY_WITH_ANOTHER_CARD_VALUE";

const CardSelector = (props: any) => {
  const labelCard =
    props.cards.length > 0
      ? "Pay with another card"
      : "Click to add a new card";

  const { control = {}, label } = props;
  const { errors = [] } = control;
  const errorMessage = _get(errors, "0.code", "");
  const hasError = errors.length > 0;

  return (
    <label className={classNames(styles.selectLabel)}>
      <span>{label ? label : "Card selected"}</span>
      <Select {...props} validateStatus={"error"}>
        {props.cards.map((card: any) => {
          // tslint:disable-next-line:no-shadowed-variable
          const { brandId, label, value, isFavourite } = card;
          return (
            <Option value={value} key={uuid()}>
              <div className={styles.option}>
                <div>
                  <img
                    alt={"brand icon"}
                    src={ImageUtils.getCardTypeIcon(brandId)}
                  />
                  <span>{label}</span>
                </div>
                {isFavourite && <Favourite isFavourite={true} />}
              </div>
            </Option>
          );
        })}
        <Option value={PAY_WITH_ANOTHER_CARD_VALUE}>
          <div className={styles.option}>
            <span>{labelCard}</span>
          </div>
        </Option>
      </Select>

      {hasError && (
        <div className={styles.errorText}>{T.transl(errorMessage)}</div>
      )}
    </label>
  );
};

export default CardSelector;
