// import { RouteProps } from "react-router";
import { connect } from "react-redux";

import * as actions from "src/bepaid/store/model/FetchPreStart/actions";
import * as selectors from "src/bepaid/store/model/FetchPreStart/selectors";

import Component from "./View";

const mapStateToProps = (state: any) => ({
  isLoading: selectors.isGlobalLoading(state),
  verifyCodeError: selectors.getFormErrors(state, "verify_form")
});

const mapDispatchToProps = {
  resendVerifyOtp: actions.resendVerifyOtp,
  verifyOtp: actions.verifyOtp
};

// export type IProps = ReturnType<typeof mapStateToProps> &
//   typeof mapDispatchToProps &
//   RouteProps;

export default connect(mapStateToProps, mapDispatchToProps)(Component);
