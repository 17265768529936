// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AddCustomerModal_wrapper__3DFYc .AddCustomerModal_content__3MQKd{max-width:400px;text-align:left;width:100%;margin:auto;padding-bottom:0}", "",{"version":3,"sources":["webpack://./src/bepaid/shared/modals/CustomerAddPageModal/AddCustomerModal.module.scss"],"names":[],"mappings":"AAAA,kEAAkB,eAAe,CAAC,eAAe,CAAC,UAAU,CAAC,WAAW,CAAC,gBAAgB","sourcesContent":[".wrapper .content{max-width:400px;text-align:left;width:100%;margin:auto;padding-bottom:0}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "AddCustomerModal_wrapper__3DFYc",
	"content": "AddCustomerModal_content__3MQKd"
};
export default ___CSS_LOADER_EXPORT___;
