import { RouteProps } from "react-router";
import { connect } from "react-redux";
import { RootState } from "src/ipm-shared/store/model/reducers";
import * as actions from "src/ipm-shared/store/model/AccountUsers/actions";
import * as FormAction from "src/ipm-shared/components/Form/actions";
import * as selectors from "src/ipm-shared/store/model/AccountUsers/selectors";

import Component from "./View";

const mapStateToProps = (state: RootState) => ({
  listAccountUser: selectors.getListAccountUser(state)
});

const mapDispatchToProps = {
  fetchListAccountUser: actions.fetchListAccountUser,
  setControl: FormAction.setControl,
  toggleModal: FormAction.toggleModal
};

export type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteProps;

export default connect(mapStateToProps, mapDispatchToProps)(Component);
