import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import DesktopModalLayout from "src/bepaid/components/Modal/components/DesktopModalLayout";
import { FetchModalID } from "src/ipm-shared/components/GlobalUI/actions";

import DraftInvoiceSetting from "src/bepaid/layouts/Menu/components/DraftInvoiceSetting";
import MainLayout from "src/bepaid/layouts/MainLayout";

import styles from "./ChangeDraftInvoiceModal.module.scss";

const View = (props: any) => {
  const toggleModal = () => {
    props.toggleModal(FetchModalID.UPDATE_DRAFT_INVOICE_SETTING);
  };

  const onSaved = () => {
    toggleModal();
  };

  const renderContent = () => (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <DraftInvoiceSetting onSaved={onSaved} />
      </div>
    </div>
  );

  return (
    <>
      <BrowserView>
        <DesktopModalLayout title={"Draft invoice"} onClose={toggleModal}>
          {renderContent()}
        </DesktopModalLayout>
      </BrowserView>
      <MobileView>
        <MainLayout
          header={{
            title: "Draft invoice",
            hideMenu: true,
            onClose: toggleModal
          }}
        >
          {renderContent()}
        </MainLayout>
      </MobileView>
    </>
  );
};

export default View;
