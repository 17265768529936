// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "div[data-color=customers],div[data-color=landlords],div[data-color=employees],div[data-color=suppliers]{--date-color: #067df4}div[data-color=invoices]{--date-color: #066f52}.Filter_resetBtn__3tyKX{padding:0 !important;margin:0 !important;height:auto !important}.Filter_resetBtn__3tyKX span{font-size:.875rem !important;color:var(--date-color);font-weight:500 !important}", "",{"version":3,"sources":["webpack://./src/bepaid/pages/Filter/Filter.module.scss"],"names":[],"mappings":"AAAA,wGAAwI,qBAAqB,CAAC,yBAAiC,qBAAqB,CAAC,wBAAU,oBAAoB,CAAC,mBAAmB,CAAC,sBAAsB,CAAC,6BAAe,4BAA4B,CAAC,uBAAuB,CAAC,0BAA0B","sourcesContent":["div[data-color=customers] :global,div[data-color=landlords] :global,div[data-color=employees] :global,div[data-color=suppliers] :global{--date-color: #067df4}div[data-color=invoices] :global{--date-color: #066f52}.resetBtn{padding:0 !important;margin:0 !important;height:auto !important}.resetBtn span{font-size:.875rem !important;color:var(--date-color);font-weight:500 !important}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"resetBtn": "Filter_resetBtn__3tyKX"
};
export default ___CSS_LOADER_EXPORT___;
