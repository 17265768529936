// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DeleteCardModal_wrapper__3YAkY{color:#242424;font-size:1.25rem;padding:2rem;max-width:420px;height:100%;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;margin:auto}.DeleteCardModal_wrapper__3YAkY .DeleteCardModal_text__33T6H{text-align:center}.DeleteCardModal_btnLoadingDelete__ds9Mk{margin-top:auto;margin-bottom:60px}.DeleteCardModal_btnCloseModal__DpLIR{margin-top:auto;margin-bottom:.5rem}.DeleteCardModal_btnDeleteCard__SYnR9 .ant-btn span{font-weight:500}.DeleteCardModal_title__GseAm{margin-top:1.25rem;margin-bottom:2.875rem;font-size:1.875rem;font-weight:800}.DeleteCardModal_helperText__1Z2J2{margin-top:1rem;color:#c40698 !important}.DeleteCardModal_pinkText__G7w8j{margin-top:.5rem;margin-bottom:0;color:#c40698 !important}.DeleteCardModal_textLeft__26WJN{text-align:left !important}", "",{"version":3,"sources":["webpack://./src/bepaid/pages/Wallet/components/DeleteCardModal/DeleteCardModal.module.scss"],"names":[],"mappings":"AAAA,gCAAS,aAAa,CAAC,iBAAiB,CAAC,YAAY,CAAC,eAAe,CAAC,WAAW,CAAC,mBAAY,CAAZ,YAAY,CAAC,yBAAqB,CAArB,qBAAqB,CAAC,WAAW,CAAC,6DAAe,iBAAiB,CAAC,yCAAkB,eAAe,CAAC,kBAAkB,CAAC,sCAAe,eAAe,CAAC,mBAAmB,CAAC,oDAAqC,eAAe,CAAC,8BAAO,kBAAkB,CAAC,sBAAsB,CAAC,kBAAkB,CAAC,eAAe,CAAC,mCAAY,eAAe,CAAC,wBAAwB,CAAC,iCAAU,gBAAgB,CAAC,eAAe,CAAC,wBAAwB,CAAC,iCAAU,0BAA0B","sourcesContent":[".wrapper{color:#242424;font-size:1.25rem;padding:2rem;max-width:420px;height:100%;display:flex;flex-direction:column;margin:auto}.wrapper .text{text-align:center}.btnLoadingDelete{margin-top:auto;margin-bottom:60px}.btnCloseModal{margin-top:auto;margin-bottom:.5rem}.btnDeleteCard :global .ant-btn span{font-weight:500}.title{margin-top:1.25rem;margin-bottom:2.875rem;font-size:1.875rem;font-weight:800}.helperText{margin-top:1rem;color:#c40698 !important}.pinkText{margin-top:.5rem;margin-bottom:0;color:#c40698 !important}.textLeft{text-align:left !important}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "DeleteCardModal_wrapper__3YAkY",
	"text": "DeleteCardModal_text__33T6H",
	"btnLoadingDelete": "DeleteCardModal_btnLoadingDelete__ds9Mk",
	"btnCloseModal": "DeleteCardModal_btnCloseModal__DpLIR",
	"btnDeleteCard": "DeleteCardModal_btnDeleteCard__SYnR9",
	"title": "DeleteCardModal_title__GseAm",
	"helperText": "DeleteCardModal_helperText__1Z2J2",
	"pinkText": "DeleteCardModal_pinkText__G7w8j",
	"textLeft": "DeleteCardModal_textLeft__26WJN"
};
export default ___CSS_LOADER_EXPORT___;
