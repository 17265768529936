import React from "react";
import paymentMethodScheduleStyles from "../../PaymentResultModal.module.scss";
import Button from "src/bepaid/components/Form/controls/Button";
import { history } from "src/ipm-shared/store";
import ROUTES from "src/bepaid/routes";
import { PLATFORM } from "src/ipm-shared/components/GlobalUI/reducers";

const View = ({ switchPlatform, hasOpenedModal, resetAllModals }: any) => {
  const goToDashboard = () => {
    if (hasOpenedModal) {
      resetAllModals();
    }
    history.push(ROUTES.ROOT);
    switchPlatform(PLATFORM.PAY);
  };

  return (
    <Button
      wrapperClassName={paymentMethodScheduleStyles.btnGoToDashboard}
      onClick={goToDashboard}
      type="text"
      htmlType="button"
    >
      Go to dashboard
    </Button>
  );
};

export default View;
