import ROUTES from "../routes";

export const StepRoute = {
  [ROUTES.CREATE_INVOICES_STEP1]: 0,
  [`${ROUTES.CREATE_INVOICES_STEP1}?add_customer=y`]: 1,
  [ROUTES.CREATE_INVOICES]: 0,
  [`${ROUTES.CREATE_INVOICES}?add_customer=y`]: 1,
  [ROUTES.CREATE_INVOICES_STEP2]: 2,
  [ROUTES.CREATE_INVOICES_STEP3]: 3,
  [ROUTES.CREATE_INVOICES_STEP4]: 4
};
