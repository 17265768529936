/* tslint:disable:object-literal-sort-keys jsx-no-lambda */
import React from "react";
import _get from "lodash-es/get";

import T from "src/ipm-shared/Utils/Intl";
import CountryUtil from "src/ipm-shared/Utils/Country";

import InputText from "src/bepaid/components/Form/controls/InputText";
import InputMobile from "src/bepaid/components/Form/controls/InputMobile";
import SearchCompanyForm from "src/bepaid/components/SearchCompanyForm";

import styles from "./AdditionalInfo.module.scss";

const AdditionalInfo: React.FC<any> = props => {
  const { getLinkFindUen, input, form, countryId, isEdit } = props;

  const isSG = countryId === CountryUtil.getCountryIdFromCountryCode("SG");
  const isMY = countryId === CountryUtil.getCountryIdFromCountryCode("MY");
  const isHK = countryId === CountryUtil.getCountryIdFromCountryCode("HK");
  const isAU = countryId === CountryUtil.getCountryIdFromCountryCode("AU");

  let inputUEN: {
    placeholder?: string;
    label?: string;
    disabled?: boolean;
    linkHereLabel?: string;
  } = {};

  if (isSG) {
    inputUEN = {
      placeholder: T.transl("UEN_PLACEHOLDER"),
      label: T.transl("UEN_PLACEHOLDER"),
      linkHereLabel: "Find all businesses registered in Singapore",
      disabled: true
    };
  }

  if (isHK) {
    inputUEN = {
      placeholder: T.transl("SUPPLIER_CRN_LABEL"),
      label: T.transl("SUPPLIER_CRN_LABEL"),
      linkHereLabel: "Find all businesses registered in Hong Kong",
      disabled: true
    };
  }

  if (isMY) {
    inputUEN = {
      placeholder: T.transl("COMPANY_REGISTRATION_PLACEHOLDER"),
      label: T.transl("LABEL_COMPANY_REGISTRATION_NUMBER"),
      linkHereLabel: "Find all businesses registered in Malaysia",
      disabled: false
    };
  }

  if (isAU) {
    inputUEN = {
      placeholder: T.transl("ABN_PLACEHOLDER"),
      label: T.transl("ABN_PLACEHOLDER"),
      linkHereLabel: "Find all businesses registered in Australia",
      disabled: true
    };
  }

  const handleChangeInput = (name: string) => (e: any) => {
    props.onChangeInput(name, e.target.value);
  };

  return (
    <>
      {isEdit && (
        <InputMobile
          form={form}
          name="mobile"
          nameCode="mobile_country_code"
          placeholder={`Mobile number`}
          label="Mobile number"
          defaultValue={_get(input, "mobileNumber")}
          code={input.mobileCountryCode}
          onChange={handleChangeInput("mobileNumber")}
        />
      )}

      <InputMobile
        form={form}
        name="phone"
        nameCode="phone_country_code"
        placeholder={`Phone number`}
        label="Phone number"
        defaultValue={_get(input, "phoneNumber")}
        code={input.phoneCountryCode}
        onChange={handleChangeInput("phoneNumber")}
      />
      <SearchCompanyForm
        form={form}
        getLinkFindUen={getLinkFindUen}
        fields={{
          autoCompleteField: {
            label: "Company name",
            name: "company_name", // "uen_search_company"
            placeholder: T.transl("COMPANY_SEARCH_PLACEHOLDER")
            // description: (
            //   <small className="text-muted">
            //     Company name must only contain alphanumeric character
            //   </small>
            // )
          },
          inputTextField: {
            className: styles.codeSection,
            name: "registration_number",
            onChange: handleChangeInput("registrationNumber"),
            linkHereLabel: inputUEN.linkHereLabel || T.transl("FIND_UEN"),
            ...inputUEN
          }
        }}
        input={{
          companyName: _get(input, "companyName"),
          registrationNumber: _get(input, "registrationNumber")
        }}
        onChangeInput={props.onChangeInput}
        shouldDisplayRegField={true}
      />
      <InputText
        form={form}
        name="address_line_1"
        placeholder="Address line 1"
        label="Address line 1"
        defaultValue={_get(input, "address1")}
        onChange={handleChangeInput("address1")}
      />
      <InputText
        form={form}
        name="address_line_2"
        placeholder="Address line 2"
        label="Address line 2"
        defaultValue={_get(input, "address2")}
        onChange={handleChangeInput("address2")}
      />
    </>
  );
};

export default AdditionalInfo;
