/* tslint:disable:object-literal-sort-keys */
import React from "react";

import { history } from "src/ipm-shared/store";

import MainLayout from "src/bepaid/layouts/MainLayout";
import Button from "src/bepaid/components/Form/controls/Button";
import useQuery from "src/bepaid/hooks/useQuery";

import SearchContent from "./components/SearchContent";

import { REDIRECT_URI, TITLE, saveSearchHistory } from "./utils";

const Search: React.FC<any> = props => {
  const qs = useQuery();

  const SEARCH_KEY: string = String(qs.key || "GLOBAL_SEARCH");

  const [searchKeyword, setSearchKeyword] = React.useState<string>("");

  const onChangeSearch = (v: string) => {
    setSearchKeyword(v);
  };

  const uri = REDIRECT_URI[SEARCH_KEY] || "";
  const title = TITLE[SEARCH_KEY] || "Search";

  const handleClickSearch = () => {
    if (searchKeyword.trim()) {
      saveSearchHistory(SEARCH_KEY, searchKeyword);
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.set("search_keyword", searchKeyword);
      history.push({
        pathname: `${uri}`,
        search: "?" + urlParams.toString()
      });
    }
  };

  const onClickHistory = (v: string) => {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set("search_keyword", v);
    history.push({
      pathname: `${uri}`,
      search: "?" + urlParams.toString()
    });
  };

  const onClickGoBack = () => {
    history.push(uri);
  };

  return (
    <MainLayout
      header={{
        title,
        onBack: onClickGoBack,
        hideMenu: true,
        hasBorder: false
      }}
      footer={
        <Button type="primary" htmlType="button" onClick={handleClickSearch}>
          Search
        </Button>
      }
    >
      <SearchContent
        onChangeSearch={onChangeSearch}
        onClickHistory={onClickHistory}
      />
    </MainLayout>
  );
};

export default Search;
