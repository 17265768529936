import { RouteProps } from "react-router";
import { connect } from "react-redux";
import { RootState } from "src/ipm-shared/store/model/reducers";
import * as actions from "src/bepaid/store/model/FetchPreStart/actions";
import * as formSelectors from "src/ipm-shared/components/Form/selectors";
import * as selectors from "src/bepaid/store/model/FetchPreStart/selectors";

import { REGISTER_FORM } from "src/bepaid/store/model/FetchPreStart/const";

import Component from "./View";

const mapStateToProps = (state: RootState) => ({
  getControl: (name: string) =>
    formSelectors.getControl(state, name, REGISTER_FORM),
  isLoading: selectors.isRegisterLoading(state)
});

const mapDispatchToProps = {
  register: actions.register,
  setControl: actions.setControl
};

export type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteProps;

export default connect(mapStateToProps, mapDispatchToProps)(Component);
