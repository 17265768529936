import React from "react";

const useLocalStorageEvent = (key: string) => {
  const [state, setState] = React.useState<any>();

  React.useEffect(() => {
    const originalSetItem = localStorage.setItem;
    localStorage.setItem = function() {
      document.createEvent("Event").initEvent("itemInserted", true, true);
      originalSetItem.apply(this, arguments);
      setState(localStorage.getItem(key));
    };
  }, []);

  return state;
};

export default useLocalStorageEvent;
