// export { default } from "./View";

import { connect } from "react-redux";
import _get from "lodash-es/get";

import { RootState } from "src/ipm-shared/store/model/reducers";
import * as GlobalUISelectors from "src/ipm-shared/components/GlobalUI/selectors";
import { ADD_CARD_FORM } from "src/ipm-shared/store/model/Card/const";
import * as actions from "src/bepaid/store/model/Fetch/actions";
import * as selectors from "src/bepaid/store/model/Fetch/selectors";

import View from "./View";

const mapStateToProps = (state: RootState, props: any) => ({
  isFavouriteCard: _get(
    selectors.getControl(state, "is_favourite_card", ADD_CARD_FORM, false),
    "value",
    false
  ),
  extraInfo: GlobalUISelectors.getExtraInfo(state, props.modalId)
});

const mapDispatchToProps = {
  setControl: actions.setControl
};

export type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(View);
