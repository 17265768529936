/**
 * Actions
 *
 * ActionCreators are like the "pub" of pubsub. It's just broadcasting an event (action).
 * So, don't couple it with a specific reducer. Let ANY reducer listen to
 * an action.
 *
 * `typesafe-actions` has a nice utility function so we don't need to define action types as
 * constants. Use it together with `getType(type)` in the reducers.
 */
import { createAction } from "typesafe-actions";
import { AccountUserType } from "./reducers";
import { LoadingFunc } from "src/ipm-shared/Utils/ReduxSagaEffects";

type cbType = (err?: any, data?: any) => void;

export const sendAccountUserInvite = createAction(
  "account_users/SEND_ACCOUNT_USER_INVITE",
  resolve => (form: string, cb?: cbType, loadingFunc?: LoadingFunc) =>
    resolve({ form, cb, loadingFunc })
);

export const setAccountUserInviteUrl = createAction(
  "account_users/SET_ACCOUNT_USER_INVITE_URL",
  resolve => (inviteUrl: string) => resolve({ inviteUrl })
);

export const fetchListAccountUser = createAction(
  "account_users/FETCH_LIST_ACCOUNT_USER",
  resolve => (args: { loadingFunc?: LoadingFunc }) => resolve(args)
);

export const setListAccountUser = createAction(
  "account_users/SET_LIST_ACCOUNT_USER",
  resolve => (listAccountUser: AccountUserType[]) =>
    resolve({ listAccountUser })
);

export const deleteAccountUser = createAction(
  "account_users/DELETE_ACCOUNT_USER",
  resolve => (
    userId: number,
    accountId: number,
    successCb?: () => void,
    loadingFunc?: LoadingFunc
  ) => resolve({ userId, accountId, successCb, loadingFunc })
);
