import React, { useState } from "react";

import ArrowDownGreen from "src/bepaid/assets/images/common/arrow_down_green.svg";
import ArrowUpGreen from "src/bepaid/assets/images/common/arrow_up_green.svg";

import ArrowUp from "src/bepaid/assets/images/common/arrow_up.svg";
import ArrowDown from "src/bepaid/assets/images/common/arrow_down.svg";

import Image from "src/bepaid/components/Image";

import styles from "./styles.module.scss";
import classNames from "classnames";

const arrows = {
  customer: [ArrowDown, ArrowUp],
  invoice: [ArrowDownGreen, ArrowUpGreen],
  recipient: [ArrowDown, ArrowUp]
};

const ExpandBox = (props: {
  originalContent?: any;
  expandContent?: any;
  type?: string;
  view?: string;
  defaultExpand?: boolean;
  hideExpandBtn?: boolean;
  // direction of addons againsts expand button
  addonsLeft?: React.ReactNode;
}) => {
  const {
    originalContent = null,
    expandContent = null,
    type = "invoice",
    view = "bottom", // "bottom" or "right",
    addonsLeft,
    defaultExpand = true,
    hideExpandBtn = false
  } = props;

  const [expand, setExpand] = useState(defaultExpand);

  const [downIcon, upIcon] = arrows[type] || arrows.invoice;

  const onExpand = () => {
    setExpand(!expand);
  };

  const renderContent = () => {
    return (
      <>
        <div hidden={expand}>{originalContent}</div>
        <div hidden={!expand}>{expandContent}</div>
      </>
    );
  };

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.wrapperRight]: view === "right"
      })}
    >
      {view === "bottom" ? (
        <>
          {!hideExpandBtn && (
            <div className={styles.icon} onClick={onExpand}>
              {!expand ? <Image src={downIcon} /> : <Image src={upIcon} />}
            </div>
          )}

          {/* <div>{!expand ? originalContent : expandContent}</div> */}
          {renderContent()}
        </>
      ) : (
        <>
          <div className={styles.iconRight}>
            {addonsLeft}
            {!hideExpandBtn && (
              <span onClick={onExpand}>
                {!expand ? <Image src={downIcon} /> : <Image src={upIcon} />}
              </span>
            )}
          </div>
          {/* <div>{!expand ? originalContent : expandContent}</div> */}
          {renderContent()}
        </>
      )}
    </div>
  );
};

export default ExpandBox;
