import React from "react";
import _get from "lodash-es/get";
import classnames from "classnames";
import Typography from "antd/es/typography";

import styles from "../RecipientDetail.module.scss";

import ShortCurrency from "src/ipm-shared/components/ShortCurrency";
import Image from "src/bepaid/components/Image";
import { useProcessPayeeDetail } from "../useProcessPaymentDetail";
import CountryUtil, { FLAGS } from "src/ipm-shared/Utils/Country";

const ContentCollapsed = (props: any) => {
  const { selectedPayee, hasWallexPayment } = props;
  const {
    bankAccountHolderName,
    receiptDefaultAmount,
    currencyId,
    bankCountryId
  } = useProcessPayeeDetail(selectedPayee);

  return (
    <>
      <div className={classnames(styles.row, styles.firstRow)}>
        <span>Bank account holder name</span>
        {hasWallexPayment && (
          <div className={styles.flag}>
            <Image
              src={
                FLAGS[CountryUtil.getCountryCodeFromCountryId(bankCountryId)]
              }
            />
          </div>
        )}
      </div>
      <div className={styles.row}>
        <Typography.Text strong={true} ellipsis={true}>
          {bankAccountHolderName}
        </Typography.Text>
      </div>
      <hr className={styles.hr1px} />
      <div className={styles.row}>
        <span>Money transfer</span>
        <span>
          <ShortCurrency value={receiptDefaultAmount} currencyId={currencyId} />
        </span>
      </div>
    </>
  );
};

export default ContentCollapsed;
