// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ChangeEmailModal_wrapper__JYqIB{height:100%}.ChangeEmailModal_wrapper__JYqIB .ChangeEmailModal_content__qxXU_{max-width:450px;text-align:left;height:100%;width:100%;margin:auto;padding-bottom:2rem}", "",{"version":3,"sources":["webpack://./src/bepaid/pages/Profile/components/ChangeEmailModal/ChangeEmailModal.module.scss"],"names":[],"mappings":"AAAA,iCAAS,WAAW,CAAC,kEAAkB,eAAe,CAAC,eAAe,CAAC,WAAW,CAAC,UAAU,CAAC,WAAW,CAAC,mBAAmB","sourcesContent":[".wrapper{height:100%}.wrapper .content{max-width:450px;text-align:left;height:100%;width:100%;margin:auto;padding-bottom:2rem}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "ChangeEmailModal_wrapper__JYqIB",
	"content": "ChangeEmailModal_content__qxXU_"
};
export default ___CSS_LOADER_EXPORT___;
