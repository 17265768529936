// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Carousel_wrapper__16ism .Carousel_dots__IPLtK{bottom:-3.5rem !important}.Carousel_wrapper__16ism .Carousel_dots__IPLtK li{width:1rem !important}.Carousel_wrapper__16ism .Carousel_dots__IPLtK li button{background:#34405b !important;width:.625rem !important;height:.625rem !important;border-radius:50% !important}.Carousel_wrapper__16ism .Carousel_dots__IPLtK li.Carousel_slick-active__2Wiw- button{background:#c1c2cc !important}", "",{"version":3,"sources":["webpack://./src/bepaid/components/Carousel/Carousel.module.scss"],"names":[],"mappings":"AAAA,+CAAe,yBAAyB,CAAC,kDAAkB,qBAAqB,CAAC,yDAAyB,6BAA6B,CAAC,wBAAwB,CAAC,yBAAyB,CAAC,4BAA4B,CAAC,sFAAsC,6BAA6B","sourcesContent":[".wrapper .dots{bottom:-3.5rem !important}.wrapper .dots li{width:1rem !important}.wrapper .dots li button{background:#34405b !important;width:.625rem !important;height:.625rem !important;border-radius:50% !important}.wrapper .dots li.slick-active button{background:#c1c2cc !important}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "Carousel_wrapper__16ism",
	"dots": "Carousel_dots__IPLtK",
	"slick-active": "Carousel_slick-active__2Wiw-"
};
export default ___CSS_LOADER_EXPORT___;
