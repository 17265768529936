/* tslint:disable:object-literal-sort-keys */
import React, { useState } from "react";
import queryString from "query-string";

import { history } from "src/ipm-shared/store";

import MainLayout from "src/bepaid/layouts/MainLayout";
import Button from "src/bepaid/components/Form/controls/Button";
import useQuery from "src/bepaid/hooks/useQuery";

import FilterContent from "./components/FilterContent";

import { REDIRECT_URI, TITLE } from "./utils";

import styles from "./Filter.module.scss";

const Filter: React.FC<any> = props => {
  const qs = useQuery();

  const [filterState, setFilterState] = useState<any>(null);

  const SEARCH_KEY: string = String(qs.key || "GLOBAL_SEARCH");
  const uri = REDIRECT_URI[SEARCH_KEY] || "";
  const title = TITLE[SEARCH_KEY] || "Search";

  const handleClickApply = () => {
    const urlParams = new URLSearchParams(window.location.search);

    const searchKeyword = urlParams.get("search_keyword");

    if (filterState) {
      history.push(
        `${uri}/?${queryString.stringify({
          ...filterState,
          search_keyword: searchKeyword
        })}`
      );
    }
  };

  const onClickGoBack = () => {
    history.push(uri);
  };

  const onClickReset = () => {
    setFilterState(null);
  };

  return (
    <div data-color={SEARCH_KEY}>
      <MainLayout
        header={{
          title,
          onBack: onClickGoBack,
          rightIcons: (
            <Button
              className={styles.resetBtn}
              type={"link"}
              onClick={onClickReset}
            >
              Reset
            </Button>
          ),
          hideMenu: true,
          hasBorder: false
        }}
        footer={
          <Button
            type="primary"
            htmlType="button"
            onClick={handleClickApply}
            disabled={filterState === null}
          >
            Apply
          </Button>
        }
      >
        <FilterContent
          filterState={filterState}
          onChangeFilter={setFilterState}
        />
      </MainLayout>
    </div>
  );
};

export default Filter;
