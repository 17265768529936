import { getType } from "typesafe-actions";
import * as types from "./types";
import * as actions from "./actions";
import _isEmpty from "lodash-es/isEmpty";

export type UenType = {
  regno: string;
  name: string;
  countryID: number;
  multipleAccountAllowed: boolean;
};

export type UenState = {
  readonly isFetching: boolean;
  readonly uenList: UenType[];
  readonly total: number;
};

const defaultState: UenState = {
  isFetching: false,
  total: 0,
  uenList: []
};

export default (state: UenState, action: types.Action) => {
  if (_isEmpty(state)) {
    state = defaultState;
  }

  switch (action.type) {
    case getType(actions.setUenList):
      return {
        ...state,
        isFetching: action.payload.isFetching,
        total: action.payload.total,
        uenList: action.payload.uenList
      };
      break;
  }
  return state;
};
