import React, { useState } from "react";

import ROUTES from "src/bepaid/routes";
import { history } from "src/ipm-shared/store";

import { invokeMessage } from "src/bepaid/components/Message";

import DeleteModal from "src/bepaid/components/Modal/components/DeleteModal";

const View = (props: any) => {
  const editData = props.extraInfo;

  // delete customer modal state
  const [isLoadingDeleting, setLoadingDeleting] = useState(false);

  const handleDeleteCustomer = () => {
    setLoadingDeleting(true);
    props.deleteCustomer(editData.id, (err?: any) => {
      if (err) {
        invokeMessage("error", "Request failed.");
      } else {
        invokeMessage("success", "Customer deleted.");
      }
      setLoadingDeleting(false);
      props.toggleModal(props.modalId);
      history.push(ROUTES.CUSTOMERS);
    });
  };

  return (
    <DeleteModal
      title={"Delete this customer?"}
      isLoading={isLoadingDeleting}
      description={
        "This will permanently delete the customer and it cannot be undone."
      }
      yesLabel={"Yes, delete customer"}
      yesLabelLoading={"Deleting customer..."}
      noLabel={"No, keep customer"}
      onYes={handleDeleteCustomer}
      onNo={props.toggleModal.bind(null, props.modalId)}
    />
  );
};

export default View;
