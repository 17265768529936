/* tslint:disable:object-literal-sort-keys */
import React, { useEffect, useState } from "react";

import queryString from "query-string";
import _get from "lodash-es/get";
import _find from "lodash-es/find";

import { history } from "src/ipm-shared/store";

import useQuery from "src/bepaid/hooks/useQuery";
import ROUTES from "src/bepaid/routes";
import Skeleton from "src/bepaid/components/Skeleton";
import Tab from "src/bepaid/components/Tabs";
import FilterTags from "src/bepaid/components/FilterTags";
import Image from "src/bepaid/components/Image";
import Pagination from "src/bepaid/components/Pagination";

import NextIcon from "src/bepaid/assets/images/common/desktop/next_black_noborder.svg";

import TabContent from "../TabContent";
import {
  PAGE_COUNT,
  PAYMENT_STATUS
} from "src/ipm-shared/store/model/Payment/const";

import styles from "../../Payments.module.scss";

const initPagination = {
  offset: 0,
  pageCount: PAGE_COUNT
};

const optionSwitch = {
  payments: {
    label: "Individual Payments",
    defaultFilter: {
      paymentType: "individual",
      statuses: String(PAYMENT_STATUS.COMPLETED)
    },
    value: "individual"
  },
  payments_schedules: {
    label: "Payment Schedules",
    defaultFilter: {
      paymentType: "schedule",
      statuses: "y"
    },
    value: "payments_schedules"
  }
};

const PaymentsListTab = (props: any) => {
  const {
    listPayments,
    listSchedules,
    getExportPayments,
    totalPayment
  } = props;
  const qs = useQuery();
  const isIndividual = qs.paymentType === "individual";

  const hasFilter =
    qs.search_keyword ||
    qs.frequency ||
    qs.favourite ||
    qs.purposes ||
    qs.statuses ||
    qs.payout_date_key ||
    qs.payout_date_from ||
    qs.payout_date_to ||
    qs.charge_date_key ||
    qs.charge_date_from ||
    qs.charge_date_to ||
    qs.paymentType ||
    qs.destination;

  const [list, setList] = React.useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = React.useState(initPagination);

  const fetchData = () => {
    if (!props.isReady) {
      return;
    }
    if (isIndividual) {
      props.fetchPayments(
        pagination.offset,
        pagination.pageCount,
        (err?: any, data?: any) => {
          if (err) {
            window.Logger.log(err);
          }

          if (props.finishFetchData) {
            props.finishFetchData();
          }
          if (data) {
            setList(data);
          }
        },
        setLoading
      );
    } else {
      props.fetchSchedules(
        undefined,
        pagination.offset,
        pagination.pageCount,
        (err?: any, data?: any) => {
          if (err) {
            window.Logger.log(err);
          }

          if (props.finishFetchData) {
            props.finishFetchData();
          }
          if (data) {
            setList(data);
          }
        },
        setLoading
      );
    }
  };

  useEffect(() => {
    if (props.isBgOfPopup && props.isFirstFetchData) {
      return;
    }
    fetchData();
  }, [pagination]);

  useEffect(() => {
    if (props.isBgOfPopup && props.isFirstFetchData) {
      return;
    }

    const prevRoute = _get(history, "location.state.prevRoute");

    // fetchData();

    // clear prevRoute
    if (prevRoute === ROUTES.PAYMENT_DETAILS) {
      window.history.replaceState(
        {
          ...history.location.state,
          prevRoute: null
        },
        ""
      );
    }

    return () => {
      setPagination(JSON.parse(JSON.stringify(initPagination)));
    };
  }, [
    qs.search_keyword,
    qs.frequency,
    qs.favourite,
    qs.purposes,
    qs.statuses,
    qs.payout_date_key,
    qs.payout_date_from,
    qs.payout_date_to,
    qs.charge_date_key,
    qs.charge_date_from,
    qs.charge_date_to,
    qs.paymentType,
    qs.destination,
    hasFilter
  ]);

  useEffect(() => {
    setList(
      String(qs.paymentType) === optionSwitch.payments.value
        ? listPayments
        : listSchedules
    );
  }, [qs.paymentType]);

  const handleSetNewQuery = (newQuery: any) => {
    history.push(
      `${ROUTES.PAYMENTS}/?${queryString.stringify(newQuery || {})}`
    );
  };

  const handleExport = () => {
    getExportPayments({
      query: qs
    });
  };

  const handleChangeTab = (idx: number) => {
    handleSetNewQuery(Object.values(optionSwitch)[idx].defaultFilter);
    setPagination(initPagination);
  };

  const contentProps = {
    loading,
    list,
    hasFilter
  };

  const tabs = Object.values(optionSwitch).map((item: any, idx: number) => ({
    name: item.label,
    content: <TabContent {...contentProps} />
  }));

  const handleOnChangePage = (offset: number) => {
    setPagination({ ...pagination, offset });
  };

  return (
    <div className={styles.tabWrapper}>
      <Skeleton loading={loading} paragraph={{ rows: 1 }}>
        <FilterTags
          showExportButton={list.length > 0}
          type={isIndividual ? "payments" : "payments_schedules"}
          handleSetNewQuery={handleSetNewQuery}
          handleExport={handleExport}
          onClickExport={handleExport}
          filterClear={{ paymentType: qs.paymentType }}
        />
      </Skeleton>

      <Tab
        tabs={tabs}
        onChange={handleChangeTab}
        defaultActiveKey={isIndividual ? "1" : "2"}
        extraMarginRight={true}
      />

      <Skeleton loading={loading} paragraph={{ rows: 1 }}>
        <Pagination
          customClassname={styles.paginationWrapper}
          defaultMode={true}
          hideOnSinglePage={true}
          showSizeChanger={false}
          showLessItems={true}
          total={totalPayment}
          showQuickJumper={
            totalPayment / pagination.pageCount > 10 && {
              goButton: (
                <button className={styles.gotoButton}>
                  <b>
                    Go
                    <Image src={NextIcon} className={styles.gotoIcon} />
                  </b>
                </button>
              )
            }
          }
          locale={{ jump_to: "Go to page" }}
          isDisableToggle={totalPayment / pagination.pageCount < 5}
          onChange={handleOnChangePage}
          {...pagination}
        />
      </Skeleton>
    </div>
  );
};
export default PaymentsListTab;
