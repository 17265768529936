/**
 * Sagas
 *
 * All side effects must come here.
 * - calls to browser apis - localStorage, window.XXX, fetch, etc.
 */
import { call, put, select } from "redux-saga/effects";
import { ActionType } from "typesafe-actions";
import * as actions from "./actions";
import * as accountSelector from "./selectors";
import _get from "lodash-es/get";
import { RootState } from "../reducers";
import {
  reTryTakeLatest,
  catchTakeLatest
} from "src/ipm-shared/Utils/ReduxSagaEffects";
import RestClient from "src/ipm-shared/services/Rest";

const watchedSagas = [
  reTryTakeLatest(actions.fetchAccountsManager, handleFetchAccountsManager),
  catchTakeLatest(actions.assignAccountManager, handleAssignAccountManager)
];
export default watchedSagas;

export function* handleFetchAccountsManager(
  action: ActionType<typeof actions.fetchAccountsManager>
) {
  const state: RootState = yield select();
  const accountsManager = accountSelector.getAccountsManager(state);

  if (accountsManager.length > 0) {
    return;
  }

  yield put(
    actions.setAccountsManager({
      accounts: [],
      isFetching: true
    })
  );

  const res = yield call(RestClient.send, {
    service: "get_accounts_manager"
  });

  if (!res) {
    yield put(actions.setAccountsManager({ isFetching: false }));
    return;
  }

  try {
    const json = res;
    const data: any[] = _get(json, "data", []);

    yield put(
      actions.setAccountsManager({
        accounts: data.map(account => ({
          amRoleName: account.am_role_name,
          assignmentType: account.assignment_type,
          countryId: account.country_id,
          deletedAt: account.deleted_at,
          firstName: account.first_name,
          isBusiness: account.is_business,
          lastName: account.last_name,
          userId: account.user_id
        })),
        isFetching: false
      })
    );
  } catch (e) {
    window.Logger.error("handleFetchAccountsManager: ", e.message);
  }
}

export function* handleAssignAccountManager(
  action: ActionType<typeof actions.assignAccountManager>
) {
  const { userId, accountManagerId } = action.payload;

  const res = yield call(RestClient.send, {
    body: {
      account_manager: accountManagerId,
      user_id: userId
    },
    service: "assign_manager"
  });

  if (!res) {
    return;
  }
}
