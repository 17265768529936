/* tslint:disable */
// tslint:disable-object-literal-sort-keys
const t = [
  { id: 1, name: "cash", label: "Cash", note: "", payment_method_type: "card" },
  {
    id: 2,
    name: "cheque",
    label: "Cheque",
    note: "",
    payment_method_type: "card"
  },
  {
    id: 3,
    name: "bank_transfer",
    label: "Bank Transfer",
    note: "",
    payment_method_type: "card"
  },
  {
    id: 4,
    name: "other",
    label: "Other",
    note: "",
    payment_method_type: "card"
  },
  {
    id: 5,
    name: "Bitcoin",
    label: "BTC",
    note: "",
    payment_method_type: "crypto"
  },
  {
    id: 6,
    name: "Lightning Bitcoin",
    label: "LNBTC",
    note: "",
    payment_method_type: "crypto"
  },
  {
    id: 7,
    name: "Ethereum",
    label: "ETH",
    note: "",
    payment_method_type: "crypto"
  },
  {
    id: 8,
    name: "USDC",
    label: "USDC",
    note: "",
    payment_method_type: "crypto"
  },
  {
    id: 9,
    name: "USDT",
    label: "USDT",
    note: "",
    payment_method_type: "crypto"
  },
  {
    id: 10,
    name: "Binance",
    label: "BNB",
    note: "",
    payment_method_type: "crypto"
  },
  {
    id: 11,
    name: "Tron USDT",
    label: "TRC_USDT",
    note: "",
    payment_method_type: "crypto"
  },
  {
    id: 12,
    name: "Tron USDC",
    label: "TRC_USDC",
    note: "",
    payment_method_type: "crypto"
  }
];
export default t;
