import * as React from "react";
import Features from "../features";

const { Consumer, Provider } = React.createContext<typeof Features>(Features);

type IState = {
  features: typeof Features;
};
class FeatureProvider extends React.Component<any, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      features: Features
    };
  }

  public render() {
    return (
      <Provider value={this.state.features}>{this.props.children}</Provider>
    );
  }
}

export { Consumer };
export default FeatureProvider;
