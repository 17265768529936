import React, { useEffect, useState } from "react";
import Form from "src/bepaid/components/Form";
import InputText from "src/bepaid/components/Form/controls/InputText";
import { BrowserView, isDesktop, MobileView } from "react-device-detect";
import MainLayout from "src/bepaid/layouts/MainLayout";
import Button from "src/bepaid/components/Form/controls/Button";
import DesktopModalLayout from "src/bepaid/components/Modal/components/DesktopModalLayout";
import { redirectToPrevPath } from "src/bepaid/utils/redirectToPrevPath";
import { history } from "src/ipm-shared/store";
import ROUTES from "src/bepaid/routes";
import classNames from "classnames";
import _intersection from "lodash-es/intersection";
import _sortBy from "lodash-es/sortBy";
import _get from "lodash-es/get";

import DateUtils from "src/ipm-shared/Utils/Date";

import styles from "./IncorporationDate.module.scss";

const IncorporationDate: React.FC<any> = props => {
  const FORM = "incorporation-date-form";
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [helperText, setHelperText] = useState<any>(null);
  const [formControl, setFormControl] = useState({
    year: "",
    month: "",
    day: ""
  });
  const isDisable = !(formControl.year && formControl.month && formControl.day);
  const closeModal = () => {
    redirectToPrevPath();
  };

  const moveForward = () => {
    props.updateCompanyIncorporationDate(
      _get(props.company, "id"),
      (err: any) => {
        if (!err) {
          history.push(
            ROUTES.PAYMENTS_CREATION.replace(":paymentType", "international")
              .replace(":entity", "country")
              .replace(":action", "select")
          );
        }
      },
      setLoading
    );
  };

  useEffect(() => {
    setHasError(true);
    setHelperText(null);
    const timeout = setTimeout(() => {
      validDate();
    }, 500);
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [formControl.year, formControl.month, formControl.day]);

  const validDate = () => {
    if (
      formControl.year &&
      (+formControl.year < 1900 ||
        +formControl.year > DateUtils.getCurrentYear())
    ) {
      setHasError(true);
      setHelperText({ txt: "Please enter a valid year." });
      return;
    }

    if (
      formControl.month &&
      (+formControl.month < 1 || +formControl.month > 12)
    ) {
      setHasError(true);
      setHelperText({ txt: "Please enter a valid month." });
      return;
    }

    if (formControl.day && (+formControl.day < 1 || +formControl.day > 31)) {
      setHasError(true);
      setHelperText({ txt: "Please enter a valid day." });
      return;
    }

    if (formControl.year && formControl.month && formControl.day) {
      if (
        !DateUtils.isValidDate(
          +formControl.day,
          +formControl.month,
          +formControl.year
        )
      ) {
        setHasError(true);
        setHelperText({ txt: "Please enter a valid date." });
        return;
      }
    }
    setHasError(false);
  };

  const onChange = (e: any) => {
    const input = e.target;
    setFormControl(prevState => {
      return {
        ...prevState,
        [input.name]: input.value
      };
    });
  };

  const renderContent = () => {
    return (
      <>
        <div
          className={classNames(styles.wrapper, {
            [styles.mobile]: !isDesktop
          })}
        >
          <div className={styles.title}>
            <span>
              Before your company can send funds abroad, we need to know your
              company’s incorporation date.
            </span>
          </div>
          <Form
            name="company-form"
            layout="vertical"
            requiredMark={false}
            className={styles.form}
          >
            <InputText
              name={"year"}
              placeholder={"YYYY"}
              form={FORM}
              label={"Incorporation date"}
              maxLength={4}
              pattern={"__NUMBER__"}
              onChange={onChange}
            />
            <InputText
              name={"month"}
              placeholder={"MM"}
              form={FORM}
              label={" "}
              maxLength={2}
              pattern={"__NUMBER__"}
              className={styles.middle}
              onChange={onChange}
            />
            <InputText
              name={"day"}
              placeholder={"DD"}
              form={FORM}
              maxLength={2}
              pattern={"__NUMBER__"}
              label={" "}
              onChange={onChange}
            />
          </Form>
          {helperText && (
            <p className={styles.helperText}>
              {hasError ? helperText.txt : ""}
            </p>
          )}
        </div>
      </>
    );
  };

  const renderDesktopView = () => {
    return (
      <DesktopModalLayout
        title={"Incorporation date"}
        id={"ipaymy_incorporation_date_modal"}
        onClose={closeModal}
        maxWidth450={true}
        submitLabel={loading ? "Moving forward..." : "Move forward"}
        onSubmit={moveForward}
        loading={loading || hasError || isDisable}
      >
        {renderContent()}
      </DesktopModalLayout>
    );
  };

  const renderMobileView = () => {
    return (
      <MainLayout
        id={"ipaymy_incorporation_date_modal"}
        header={{
          title: "Incorporation date",
          hideMenu: true,
          onClose: closeModal
        }}
        footer={
          <Button
            wrapperClassName={styles.wrapperButton}
            className={styles.button}
            onClick={moveForward}
            id="ipaymy_company_update_incorporation_date"
            type="primary"
            htmlType="button"
            loading={loading || hasError || isDisable}
          >
            {loading ? "Moving forward..." : "Move forward"}
          </Button>
        }
      >
        {renderContent()}
      </MainLayout>
    );
  };
  return (
    <>
      <BrowserView>{renderDesktopView()}</BrowserView>
      <MobileView>{renderMobileView()}</MobileView>
    </>
  );
};

export default IncorporationDate;
