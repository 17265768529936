import React, { useState, useMemo } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import _get from "lodash/get";

import Flex from "antd-mobile/es/flex";
import Typography from "antd/es/typography";

import { LOGIN_FORM } from "src/ipm-shared/store/model/Auth/const";
import T from "src/ipm-shared/Utils/Intl";

import Button from "src/bepaid/components/Form/controls/Button";
import Card from "src/bepaid/components/Card";
import GuestLayout from "src/bepaid/layouts/GuestLayout";
import InputText from "src/bepaid/components/Form/controls/InputText";
import { invokeMessage } from "src/bepaid/components/Message";

import styles from "./PostRegister.module.scss";

const PostRegister: React.FC<any> = props => {
  const { qs } = props;
  const [loading, setLoading] = useState(false);

  const email = useMemo(() => {
    try {
      return _get(qs, "email");
    } catch (err) {
      return null;
    }
  }, [props.qs]);

  const resendEmail = () => {
    if (email) {
      setLoading(true); // ok
      setTimeout(() => {
        invokeMessage("success", "Resent verification email!");
        setLoading(false); // ok
      }, 3000);
    } else {
      invokeMessage("error", "Email invalid!");
    }
    props.requestResendVerifyEmail();
  };

  const renderContent = () => {
    return (
      <div className={styles.wrapper}>
        <Flex
          justify="center"
          align="center"
          className={styles.instructionSection}
        >
          <Flex.Item span={22}>
            <Typography.Text>{T.transl("SIGNUP_THANKS")}</Typography.Text>
          </Flex.Item>
        </Flex>
        <Flex justify="center" align="center" className={styles.buttonSection}>
          <Flex.Item span={24}>
            <Button
              type="primary"
              htmlType="button"
              loading={loading}
              onClick={resendEmail}
              // disabled={!email}
            >
              {loading ? "Resending email..." : T.transl("RESEND_EMAIL_BUTTON")}
            </Button>
          </Flex.Item>
        </Flex>
        <Flex
          direction="column"
          justify="center"
          align="center"
          className={styles.extraSection}
        >
          <Flex.Item span={24} className={styles.centerText}>
            <Typography.Text>{T.transl("CHECK_SPAM")}</Typography.Text>
            <Typography.Link
              href="mailto:support@ipaymy.com"
              className={styles.strongLink}
            >
              {T.transl("CONTACT_SUPPORT")}
            </Typography.Link>
          </Flex.Item>
          <div hidden={true}>
            <InputText
              name="email"
              form={LOGIN_FORM}
              defaultValue={email}
              value={email}
              disabled={true}
            />
          </div>
        </Flex>
      </div>
    );
  };

  const renderDesktopView = () => {
    return (
      <div className={styles.desktopWrapper}>
        <Card>{renderContent()}</Card>
      </div>
    );
  };

  return (
    <GuestLayout
      header={
        <Typography.Text className={styles.header}>PayFetch</Typography.Text>
      }
      title={
        <Typography.Text className={styles.title}>
          {T.transl("SIGNUP_SUCCESSFUL")}
        </Typography.Text>
      }
    >
      <BrowserView>{renderDesktopView()}</BrowserView>
      <MobileView>{renderContent()}</MobileView>
    </GuestLayout>
  );
};

export default PostRegister;
