import * as React from "react";
import { connect } from "react-redux";
import Cookies from "js-cookie";

import { RootState } from "src/ipm-shared/store/model/reducers";
import Is from "src/ipm-shared/Utils/Is";

const mapStateToProps = (state: RootState) => ({});

type IProps = ReturnType<typeof mapStateToProps> & {
  data: {
    currency?: string;
    receiptNumber?: string;
    value?: number;
  };
  title: string;
  fireOnce?: boolean;
  email?: string;
};

class GATrack extends React.Component<IProps> {
  private fired: boolean;

  constructor(props: IProps) {
    super(props);

    if (props.fireOnce) {
      const ipmTrackCookieValue = Cookies.get(
        `${window.location.pathname}-${props.email}-ga-fired`
      );

      if (props.data && ipmTrackCookieValue) {
        if (ipmTrackCookieValue === "true") {
          this.fired = true;
        }
      }
    }
  }

  public setIPMTrackCookie() {
    if (this.props.data) {
      Cookies.set(
        `${window.location.pathname}-${this.props.email}-ga-fired`,
        "true"
      );
    }
  }

  public render() {
    if (this.fired) {
      return null;
    }

    const { title } = this.props;

    if (Is.live()) {
      switch (title) {
        // Legacy
        case "SuccessfulPayment":
          this.trackSuccessfulPaymentAction();
          break;
        case "Purchase":
          this.trackPurchaseAction();
          break;
        case "CollectionPurchase":
          this.trackCollectionPurchaseAction();
          break;
        case "PostRegistration":
          this.trackPostRegistrationAction();
          break;
        case "LegacyPostRegistration":
          this.trackLegacyPostRegistrationAction();
          break;
        default:
          // do nothing
          break;
      }
    } else {
      switch (title) {
        case "PostRegistration":
          this.trackPostRegistrationAction();
          break;
        default:
          // do nothing
          break;
      }
    }

    this.fired = true;

    return null;
  }

  public trackSuccessfulPaymentAction() {
    (window as any).gtag("event", "conversion", {
      currency: this.props.data.currency,
      send_to: "AW-832777793/wSW3CIqEzrgBEMHcjI0D",
      transaction_id: this.props.data.receiptNumber,
      value: this.props.data.value
    });
    this.setIPMTrackCookie();
  }

  public trackPurchaseAction() {
    window.gtag("event", "conversion", {
      currency: this.props.data.currency,
      send_to: "AW-438922467/xaJ_CODDmPIBEOPZpdEB",
      transaction_id: this.props.data.receiptNumber,
      value: this.props.data.value
    });
    this.setIPMTrackCookie();
  }

  public trackCollectionPurchaseAction() {
    window.gtag("event", "conversion", {
      currency: this.props.data.currency,
      send_to: "AW-438922467/RCkECM-o-PEBEOPZpdEB",
      transaction_id: this.props.data.receiptNumber,
      value: this.props.data.value
    });
    this.setIPMTrackCookie();
  }

  public trackPostRegistrationAction() {
    window.gtag("event", "conversion", {
      send_to: Is.live()
        ? "AW-438922467/xaJ_CODDmPIBEOPZpdEB"
        : "AW-438922467/DbH2COay5K0DEOPZpdEB"
    });
    this.setIPMTrackCookie();
  }

  public trackLegacyPostRegistrationAction() {
    window.gtag("event", "conversion", {
      send_to: "AW-832777793/fxNbCJDcrJQBEMHcjI0D"
    });
    this.setIPMTrackCookie();
  }
}

export default connect(mapStateToProps)(GATrack);
