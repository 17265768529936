/**
 * Actions
 *
 * ActionCreators are like the "pub" of pubsub. It's just broadcasting an event (action).
 * So, don't couple it with a specific reducer. Let ANY reducer listen to
 * an action.
 *
 * `typesafe-actions` has a nice utility function so we don't need to define action types as
 * constants. Use it together with `getType(type)` in the reducers.
 */
import { createAction } from "typesafe-actions";

export const fetchPurposes = createAction("purposes/FETCH");

export const setPurposes = createAction(
  "purposes/SET",
  resolve => (args: {
    isFetching: boolean;
    purposes: Array<{
      id: number;
      name: string;
    }>;
  }) => resolve(args)
);
