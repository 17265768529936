// import { RouteProps } from "react-router";
import { connect } from "react-redux";

import Component from "./View";
import * as actions from "src/ipm-shared/store/model/Auth/actions";
import * as FormAction from "src/ipm-shared/components/Form/actions";

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = {
  requestResetPasswordSubmit: actions.requestResetPasswordSubmit,
  setControl: FormAction.setControl,
  setFormErrors: FormAction.setFormErrors
};

// export type IProps = ReturnType<typeof mapStateToProps> &
//   typeof mapDispatchToProps &
//   RouteProps;

export default connect(mapStateToProps, mapDispatchToProps)(Component);
