import React, { useState } from "react";
import Flex from "antd-mobile/es/flex";
import { isDesktop } from "react-device-detect";
import classNames from "classnames";

import { transformTextFirstUppercase } from "src/bepaid/utils/pattern";

import styles from "./FilterTags.module.scss";
import Image from "../Image";
import IconClose from "src/bepaid/assets/images/common/icon_close.svg";
import useQuery from "src/bepaid/hooks/useQuery";
import * as DateFns from "date-fns";

import * as uuidv1 from "uuid/v1";

import {
  INVOICE_FILTER_OPTIONS,
  CUSTOMERS_FILTER_OPTIONS,
  RECIPIENTS_FILTER_OPTIONS,
  PAYMENT_FILTER_OPTIONS,
  SCHEDULE_FILTER_OPTIONS
} from "src/bepaid/pages/Filter/const";

import FilterExport from "./components/FilterExport";

const OPTIONS = {
  customers: CUSTOMERS_FILTER_OPTIONS,
  invoices: INVOICE_FILTER_OPTIONS,
  recipients: RECIPIENTS_FILTER_OPTIONS,
  payments: PAYMENT_FILTER_OPTIONS,
  payments_schedules: SCHEDULE_FILTER_OPTIONS
};

const OPTIONS_KEYS = {
  customers: [
    "search_keyword",
    "tag",
    "punctual",
    "from",
    "to",
    "dateQueryKey"
  ],
  invoices: ["search_keyword", "statuses", "from", "to", "dateQueryKey"],
  payments: [
    "search_keyword",
    "statuses",
    "favourite",
    "purposes",
    "frequency",
    "from",
    "to",
    "payout_date_key",
    "charge_date_key",
    "destination"
  ],
  recipients: ["search_keyword", "from", "to", "dateQueryKey"],
  payments_schedules: [
    "search_keyword",
    "statuses",
    "purposes",
    "frequency",
    "payout_date_key",
    "from",
    "to"
  ]
};

const convertQueryToList = (query: any, type: string) => {
  const options = OPTIONS[type];
  const keys = OPTIONS_KEYS[type];

  return keys
    .filter((key: string) => query[key])
    .map((key: string) => {
      const value = query[key];
      if (!value) {
        return null;
      }

      switch (key) {
        case "search_keyword": {
          return {
            fromQuery: [key],
            name: value
          };
        }
        case "tag": {
          const TAG = options.find((o: any) => o.key === "TAG");
          return {
            ...TAG.children.find((tg: any) => tg.key === value),
            fromQuery: ["tag"]
          };
        }
        case "punctual": {
          const PUN = options.find((o: any) => o.key === "PUN");
          return {
            ...PUN.children.find((tg: any) => tg.key === value),
            fromQuery: ["punctual"]
          };
        }
        case "statuses": {
          const STATUS = options.find((o: any) => o.key === "STATUS");
          return {
            ...STATUS.children.find((tg: any) => tg.key === value),
            fromQuery: ["statuses"]
          };
        }
        case "favourite": {
          const FAVOURITE = options.find((o: any) => o.key === "FAVOURITE");
          return {
            ...FAVOURITE.children.find((tg: any) => tg.key === value),
            fromQuery: ["favourite"]
          };
        }
        case "purposes": {
          const PURPOSE = options.find((o: any) => o.key === "PURPOSE");
          return {
            ...PURPOSE.children.find((tg: any) => tg.key === value),
            fromQuery: ["purposes"]
          };
        }
        case "frequency": {
          const FREQUENCY = options.find((o: any) => o.key === "FREQUENCY");
          return {
            ...FREQUENCY.children.find((tg: any) => tg.key === value),
            fromQuery: ["frequency"]
          };
        }
        case "from": {
          return null;
        }
        case "to": {
          return null;
        }
        case "payout_date_key": {
          if (value !== "custom") {
            return {
              fromQuery: [
                "payout_date_key",
                "payout_date_from",
                "payout_date_to"
              ],
              id: uuidv1(),
              name: "Payout Date: " + transformTextFirstUppercase(value, "_")
            };
          } else {
            const from = query.payout_date_from;
            const to = query.payout_date_to;

            return {
              fromQuery: [
                "payout_date_key",
                "payout_date_from",
                "payout_date_to"
              ],
              id: uuidv1(),
              name: `Payout date from ${DateFns.format(
                from,
                "D MMMM YYYY"
              )} to ${DateFns.format(to, "D MMMM YYYY")}`
            };
          }
        }
        case "charge_date_key": {
          if (value !== "custom") {
            return {
              fromQuery: [
                "charge_date_key",
                "charge_date_from",
                "charge_date_to"
              ],
              id: uuidv1(),
              name: "Charge Date: " + transformTextFirstUppercase(value, "_")
            };
          } else {
            const from = query.charge_date_from;
            const to = query.charge_date_to;

            return {
              fromQuery: [
                "charge_date_key",
                "charge_date_from",
                "charge_date_to"
              ],
              id: uuidv1(),
              name: `Charge date from ${DateFns.format(
                from,
                "D MMMM YYYY"
              )} to ${DateFns.format(to, "D MMMM YYYY")}`
            };
          }
        }
        case "dateQueryKey": {
          if (value !== "custom") {
            return {
              fromQuery: ["dateQueryKey", "from", "to"],
              id: uuidv1(),
              name: transformTextFirstUppercase(value, "_")
            };
          } else {
            const from = query.from;
            const to = query.to;

            return {
              fromQuery: ["dateQueryKey", "from", "to"],
              id: uuidv1(),
              name: `Added from ${DateFns.format(
                from,
                "D MMMM YYYY"
              )} to ${DateFns.format(to, "D MMMM YYYY")}`
            };
          }

          return null;
        }
        case "paymentType": {
          return null;
        }

        case "destination": {
          const DESTINATION = options.find((o: any) => o.key === "DESTINATION");
          return {
            ...DESTINATION.children.find((tg: any) => tg.key === value),
            fromQuery: ["destination"]
          };
        }
      }
    })
    .filter((item: any) => item !== null);
};

const Component = (props: any) => {
  const {
    type,
    handleSetNewQuery,
    onClickExport,
    showExportButton,
    filterClear
  } = props;

  const pageQuery = useQuery();

  const [arr, setArr] = useState(convertQueryToList(pageQuery, type));

  const [query, setQuery] = useState(pageQuery);

  const remove = (removedItem: any) => {
    const newQuery = {
      ...query
    };

    removedItem.fromQuery.forEach((key: string) => {
      delete newQuery[key];
    });

    handleSetNewQuery(newQuery);

    setQuery(newQuery);
    const newArr = arr.filter((item: any) => item.id !== removedItem.id);
    setArr(newArr);
  };

  const onClear = () => {
    if (filterClear) {
      setQuery(filterClear);
      handleSetNewQuery(filterClear);
      setArr([]);
    } else {
      setQuery({});
      handleSetNewQuery({});
      setArr([]);
    }
  };

  const renderExport = (className: string) => {
    return (
      <div className={styles[className]} hidden={!showExportButton}>
        <FilterExport onClickExport={onClickExport} />
      </div>
    );
  };

  return arr.length > 0 ? (
    <div
      className={classNames(styles.wrapper, {
        [styles.desktopWrapper]: isDesktop
      })}
      data-color={type}
    >
      <div className={styles.clearBar}>
        <h3 onClick={onClear}>Clear</h3>
        {type === "payments" && renderExport("flexRight")}
      </div>

      <Flex align="center" className={styles.item}>
        <Flex.Item className={styles.flexItem}>
          {arr
            .filter((item: any) => item)
            .map((item: any, idx: number) => {
              return (
                <div className={styles.flexArr} key={idx}>
                  <div>{item.name}</div>
                  <Image src={IconClose} onClick={remove.bind(null, item)} />
                </div>
              );
            })}
        </Flex.Item>
      </Flex>
    </div>
  ) : type === "payments" ? (
    renderExport("export")
  ) : (
    <></>
  );
};

export default Component;
