import React from "react";
import { Switch, Route } from "react-router-dom";

import ROUTES from "src/bepaid/routes";

import * as Pages from "./index";
import withAccountUserRole from "../HOC/withAccountUserRole";

const App: React.FC = () => {
  return (
    <Switch>
      {/* TODO: Move this into invoices component */}
      <Route exact={true} path={ROUTES.INVOICES} component={Pages.Invoices} />
      <Route
        exact={true}
        path={ROUTES.CREATE_INVOICES_STEPS}
        component={Pages.InvoiceCreate}
      />
      <Route
        exact={true}
        path={ROUTES.CREATE_INVOICES}
        component={Pages.InvoiceCreate}
      />
      <Route
        exact={true}
        path={ROUTES.INVOICES_DRAFT}
        component={Pages.InvoiceDraftList}
      />
      <Route
        exact={true}
        path={ROUTES.INVOICE_DETAILS}
        component={Pages.InvoiceDetailComponent}
      />
      <Route
        exact={true}
        path={ROUTES.CUSTOMERS}
        component={Pages.CustomersIndex}
      />
      <Route
        exact={true}
        path={ROUTES.CUSTOMER_CREATION}
        component={Pages.CustomersAdd}
      />
      <Route
        exact={true}
        path={ROUTES.CUSTOMER_DETAIL}
        component={Pages.CustomersDetail}
      />

      <Route
        exact={true}
        path={ROUTES.CUSTOMER_EDIT}
        component={Pages.CustomersEdit}
      />
      <Route path={ROUTES.INSIGHTS} component={Pages.Insights} />
      <Route path={ROUTES.INSIGHTS_PAY} component={Pages.InsightsPay} />
      <Route path={ROUTES.SEARCH} component={Pages.Search} />
      <Route path={ROUTES.FILTER} component={Pages.Filter} />
      <Route path={ROUTES.KYC} component={Pages.KYCPage} />
      <Route path={ROUTES.MY_INFO} component={Pages.MyInfo} />
      <Route
        path={ROUTES.ADDITIONAL_DATA}
        component={Pages.AdditionalDataPage}
      />
      <Route
        path={ROUTES.INCORPORATION_DATE}
        component={Pages.IncorporationDateComponent}
      />
      <Route
        path={ROUTES.PAYFETCH_TOUR}
        component={Pages.PayFetchTourComponent}
      />
      <Route path={ROUTES.REFERRAL} component={Pages.Referral} />
      {/* Route for Recipients */}
      <Route
        exact={true}
        path={ROUTES.RECIPIENTS}
        component={Pages.Recipients}
      />
      <Route
        exact={true}
        path={ROUTES.RECIPIENTS_CREATE}
        component={Pages.RecipientsAdd}
      />
      <Route
        exact={true}
        path={ROUTES.RECIPIENTS_DETAILS}
        component={Pages.RecipientsDetail}
      />
      <Route
        exact={true}
        path={ROUTES.RECIPIENTS_DETAILS_UPDATE}
        component={Pages.RecipientsEdit}
      />
      {/* Route for Payments */}
      <Route
        exact={true}
        path={ROUTES.PAYMENTS}
        component={Pages.PaymentsList}
      />
      <Route
        exact={true}
        path={ROUTES.PAYMENTS_CREATION}
        component={Pages.PaymentsCreation}
      />

      <Route exact={true} path={ROUTES.SCHEDULE} component={Pages.Schedules} />
      <Route
        exact={true}
        path={ROUTES.PAYMENT_CHECKOUT_SUCCESS}
        component={Pages.PaymentsCheckoutSuccess}
      />
      <Route
        exact={true}
        path={ROUTES.PAYMENT_DETAILS}
        component={Pages.PaymentsDetails}
      />
      <Route
        exact={true}
        path={ROUTES.PAYMENT_SCHEDULE_DETAILS}
        component={Pages.ScheduleDetails}
      />
      <Route
        exact={true}
        path={ROUTES.SCHEDULE_EDIT}
        component={Pages.PaymentsScheduleEdit}
      />

      <Route
        exact={true}
        path={ROUTES.SCHEDULE_EXTEND}
        component={Pages.PaymentsDetailsExtend}
      />

      <Route
        exact={true}
        path={ROUTES.PAYMENT_EDIT}
        component={Pages.PaymentsDetailsEdit}
      />

      {/*Route for Users management*/}
      <Route
        exact={true}
        path={ROUTES.USERS}
        component={withAccountUserRole(Pages.Users)}
      />

      <Route path={ROUTES.ROOT} exact={true} component={Pages.Dashboard} />
    </Switch>
  );
};

export default App;
