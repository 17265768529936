/**
 * Selectors
 *
 * It's like "views" in relational databases.
 * Store the most efficient format in the reducer state.
 * But to retrieve into easy to consume form, use selector.
 */

import ReducerFactory from "src/ipm-shared/Utils/ReduxReducer";
ReducerFactory.registerReducer({ onboardingKyc: onboardingKycReducer });
import { default as onboardingKycReducer } from "./reducers";
import { RootState } from "src/ipm-shared/store/model/reducers";

export const getEmployeeIndustry = (state: RootState) => {
  return state.onboardingKyc.employeeData.industry;
};
export const getEmployeePosition = (state: RootState) => {
  return state.onboardingKyc.employeeData.position;
};
export const getEmployeeStatus = (state: RootState) => {
  return state.onboardingKyc.employeeData.status;
};

export const getResidentialAddress = (state: RootState) =>
  state.onboardingKyc.residentialAddress;

export const getWallexKycStep = (state: RootState): number =>
  state.onboardingKyc.kycData.step;

export const getWallexKycData = (state: RootState) =>
  state.onboardingKyc.kycData;

export const hasFetchedKYCData = (state: RootState) =>
  state.onboardingKyc.hasFetched;
