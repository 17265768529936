import React from "react";
import Progress from "antd/es/progress";

const Component = (props: any) => {
  const {
    type = "line",
    colors = ["#FFF", "#FFF"],
    status = "active", // "active" works only for "line" type
    value = 0,
    strokeWidth = 4
  } = props;

  return (
    <Progress
      strokeColor={{
        "0%": colors[0],
        "100%": colors[1]
      }}
      percent={value}
      type={type}
      status={status}
      showInfo={false}
      strokeWidth={strokeWidth}
    />
  );
};

export default Component;
