// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CustomerTagInfo_wrapper__2SwBB{height:100%}.CustomerTagInfo_wrapper__2SwBB .CustomerTagInfo_flexItem__1PvhU{-ms-flex:0 0 !important;flex:0 0 !important}.CustomerTagInfo_wrapper__2SwBB .CustomerTagInfo_content__3mc_u{height:100%;padding:0 1rem;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center}.CustomerTagInfo_wrapper__2SwBB .CustomerTagInfo_content__3mc_u .CustomerTagInfo_title__2p4qF{font-size:1.25rem;font-weight:500;color:#242424;margin:1rem 0;text-align:center}.CustomerTagInfo_wrapper__2SwBB .CustomerTagInfo_content__3mc_u .CustomerTagInfo_description__3UOd8{font-size:1rem;color:#6a6a6a;text-align:center;line-height:1.5rem}", "",{"version":3,"sources":["webpack://./src/bepaid/components/CustomerTagInfo/CustomerTagInfo.module.scss"],"names":[],"mappings":"AAAA,gCAAS,WAAW,CAAC,iEAAmB,uBAAkB,CAAlB,mBAAmB,CAAC,gEAAkB,WAAW,CAAC,cAAc,CAAC,mBAAY,CAAZ,YAAY,CAAC,yBAAqB,CAArB,qBAAqB,CAAC,qBAAkB,CAAlB,kBAAkB,CAAC,oBAAqB,CAArB,sBAAsB,CAAC,8FAAyB,iBAAiB,CAAC,eAAe,CAAC,aAAa,CAAC,aAAa,CAAC,iBAAiB,CAAC,oGAA+B,cAAc,CAAC,aAAa,CAAC,iBAAiB,CAAC,kBAAkB","sourcesContent":[".wrapper{height:100%}.wrapper .flexItem{flex:0 0 !important}.wrapper .content{height:100%;padding:0 1rem;display:flex;flex-direction:column;align-items:center;justify-content:center}.wrapper .content .title{font-size:1.25rem;font-weight:500;color:#242424;margin:1rem 0;text-align:center}.wrapper .content .description{font-size:1rem;color:#6a6a6a;text-align:center;line-height:1.5rem}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "CustomerTagInfo_wrapper__2SwBB",
	"flexItem": "CustomerTagInfo_flexItem__1PvhU",
	"content": "CustomerTagInfo_content__3mc_u",
	"title": "CustomerTagInfo_title__2p4qF",
	"description": "CustomerTagInfo_description__3UOd8"
};
export default ___CSS_LOADER_EXPORT___;
