import * as React from "react";
import T from "src/ipm-shared/Utils/Intl";
import classNames from "classnames";
import { isDesktop, BrowserView, MobileView } from "react-device-detect";

import { FetchModalID } from "src/ipm-shared/components/GlobalUI/actions";

import Image from "src/bepaid/components/Image";
import Button from "src/bepaid/components/Form/controls/Button";
import { history } from "src/ipm-shared/store";

import IconCheckoutFailed from "src/bepaid/assets/images/invoices/payment_failed.svg";

import _isEmpty from "lodash-es/isEmpty";
import _get from "lodash-es/get";
import { getEntityByPurposeId } from "src/bepaid/utils/recipient";

import styles from "./CollectedPaymentFailed.module.scss";
import ROUTES from "src/bepaid/routes";

function CollectedPaymentFailed(props: any) {
  const {
    modalId = FetchModalID.PAYMENT_ERROR,
    extraInfo = {},
    toggleModal,
    paymentRequestPayees
  } = props;

  let errors: any[] = [];
  if (typeof extraInfo.errors === "string") {
    try {
      errors = JSON.parse(extraInfo.errors);
    } catch (e) {
      console.error("Wrong error format. Should be array", extraInfo.errors);
    }
  } else {
    errors = extraInfo.errors;
  }

  const hasWallexMissingFieldError = !_isEmpty(
    errors.find((error: string) => error.includes("WALLEX_QUOTE_INVALID_FIELD"))
  );

  const hasMinimumAmountError = !_isEmpty(
    errors.find((error: string) =>
      T.transl(error).includes("The minimum amount")
    )
  );

  const onClose = () => {
    if (hasWallexMissingFieldError) {
      toggleModal(modalId);
      history.push(
        ROUTES.RECIPIENTS_DETAILS_UPDATE.replace(
          ":id",
          paymentRequestPayees[0].id
        ).replace(
          ":entity",
          getEntityByPurposeId(paymentRequestPayees.purposeId)
        )
      );
    } else {
      toggleModal(modalId);
      const checkoutUrl = localStorage.getItem("checkoutUrl");
      if (checkoutUrl) {
        history.push(checkoutUrl);
      } else {
        history.push(ROUTES.ROOT);
      }
    }
  };

  const renderContent = () => (
    <>
      <div className={styles.invoiceSentLogo}>
        <Image src={IconCheckoutFailed} />
      </div>
      {!hasMinimumAmountError && (
        <h6 className={styles.title}>{T.transl("PAYMENT_FAILED_HERO")}</h6>
      )}
      {errors && (
        <div className={styles.messsage}>
          {hasWallexMissingFieldError ? (
            <>
              <p>{T.transl("INT_PAYMENT_MISSING_RECIPIENT_DATA")}</p>
              <ul className={styles.listErrors}>
                {Object.keys(_get(extraInfo, "serverErrors.fields", [])).map(
                  (errorField: string, idx: number) => (
                    <li key={idx}>
                      <p className="mb-0">{errorField}</p>
                    </li>
                  )
                )}
              </ul>
            </>
          ) : (
            <>
              {errors.map((error: string) => {
                if (T.transl(error).includes("Oops! Payment failed")) {
                  return (
                    <p className="mb-0" key={error}>
                      {T.transl("CHECKOUT_GENERAL_ISSUE", {
                        a: (text: any) => (
                          <a
                            href="https://help.ipaymy.com/en/"
                            target={"_blank"}
                          >
                            {text}
                          </a>
                        )
                      })}
                    </p>
                  );
                }
                if (hasMinimumAmountError) {
                  return (
                    <p
                      className={classNames("mb-0 mt-2", styles.error)}
                      key={error}
                    >
                      {T.transl(error, {
                        br: <br />
                      })}
                    </p>
                  );
                }
                return (
                  <p className="mb-0" key={error}>
                    {T.transl(error)}
                  </p>
                );
              })}
            </>
          )}
        </div>
      )}

      <div
        className={classNames(styles.groupBtn, styles.firstLetterCapitalize)}
      >
        <Button type="primary" onClick={onClose}>
          {hasWallexMissingFieldError
            ? "Update this recipient"
            : hasMinimumAmountError
            ? "Update payment"
            : T.transl("TRY_AGAIN_BUTTON").toLowerCase()}
        </Button>
      </div>
    </>
  );

  return (
    <div className={styles.contentWrapper}>
      <BrowserView>
        <div
          className={classNames(styles.wrapper, {
            [styles.wrapperDesktop]: isDesktop
          })}
        >
          {renderContent()}
        </div>
      </BrowserView>
      <MobileView>
        <div className={styles.wrapper}>{renderContent()}</div>
      </MobileView>
    </div>
  );
}

export default CollectedPaymentFailed;
