import * as DateFns from "date-fns";

function parseDateOnly(date?: Array<Date | string | null>): Array<Date | null>;
function parseDateOnly(date?: Date | string | null): Date | null;
function parseDateOnly(
  date?: Date | string | null | Array<Date | string | null>
): Date | null | Array<Date | null> {
  if (!date) return null;

  if (Array.isArray(date)) {
    return date.map(d => parseDateOnly(d));
  }

  if (DateFns.isDate(date)) return parseDateOnly((date as Date).toISOString());

  if (typeof date === "string") {
    const split = date.split("T");
    const ymd = split[0];

    if (ymd.length !== 10) return null;

    const y = parseInt(ymd.slice(0, 4), 10);
    const m = parseInt(ymd.slice(5, 7), 10);
    const d = parseInt(ymd.slice(8, 10), 10);

    return new Date(y, m - 1, d, 12, 0, 0);
  }

  return null;
}

export default {
  formatDate: (date: string, formatDate?: string): string => {
    if (date !== "") {
      return DateFns.format(new Date(date), formatDate || "DD/MM/YYYY");
    } else {
      return "-";
    }
  },
  formatISODate: (date: string): string => {
    if (date !== "") {
      return new Date(date).toISOString();
    } else {
      return "-";
    }
  },
  formatLocaleDate: (date: string, options: any) => {
    const dateData = new Date(date);
    return dateData.toLocaleDateString("en-US", options);
  },
  getCurrentYear: (): number => {
    return new Date().getFullYear();
  },
  getDifferenceInDays(date1: any, date2: any) {
    const diffInMs = date2 - date1;
    return Math.round(diffInMs / (1000 * 60 * 60 * 24));
  },
  getFromAndToByDateQueryKey(
    dateQueryKey: string,
    defaultValue?: { from: string; to: string }
  ) {
    let from: string | undefined;
    let to: string | undefined;

    switch (dateQueryKey) {
      case "last_week": {
        from = DateFns.startOfWeek(new Date()).toISOString();
        to = new Date().toISOString();
        break;
      }
      case "last_month": {
        from = DateFns.startOfMonth(new Date()).toISOString();
        to = new Date().toISOString();
        break;
      }
      case "last_three_months": {
        from = DateFns.startOfQuarter(new Date()).toISOString();
        to = new Date().toISOString();
        break;
      }
      case "last_six_months": {
        from = DateFns.subQuarters(
          DateFns.startOfQuarter(new Date()),
          1
        ).toISOString();
        to = new Date().toISOString();
        break;
      }
      default: {
        from = defaultValue?.from;
        to = defaultValue?.to;
      }
    }

    return { from, to };
  },
  getDayNextYear(date: string) {
    let dateData = new Date(date);
    dateData = DateFns.addYears(dateData, 1);
    if (DateFns.isSunday(dateData)) {
      dateData = DateFns.addDays(dateData, 1);
    } else if (DateFns.isSaturday(dateData)) {
      dateData = DateFns.addDays(dateData, -1);
    }
    return dateData;
  },

  isValidDate(day: number, month: number, year: number) {
    const date = new Date(`${year}/${month}/${day}`);

    if (
      date.getFullYear() !== year ||
      date.getMonth() !== month - 1 ||
      date.getDate() !== day
    ) {
      return false;
    }

    return true;
  },

  isGreaterThanNextDate(date: Date) {
    const tomorrow = DateFns.addDays(new Date(), 1);
    return DateFns.isAfter(date, tomorrow);
  },

  isTwoDateEqual(dateLeft: Date, dateRight: Date) {
    const dateL = new Date(dateLeft);
    const dateR = new Date(dateRight);

    return (
      new Date(
        dateL.getFullYear(),
        dateL.getMonth(),
        dateL.getDate()
      ).getTime() ===
      new Date(dateR.getFullYear(), dateR.getMonth(), dateR.getDate()).getTime()
    );
  },

  getPreviousFriday(date: Date): Date {
    const dayOfWeek = DateFns.getDay(date);
    const daysUntilFriday = (dayOfWeek + 7 - 5) % 7; // 5 corresponds to Friday
    return DateFns.subDays(date, daysUntilFriday);
  },
  parseDateOnly,
  calculatePayoutDate(date: Date, exclusionDates: Date[], earliestDate: Date) {
    const dateData = new Date(date);
    let calculateDate = dateData;

    if (
      DateFns.isBefore(
        parseDateOnly(calculateDate)!,
        parseDateOnly(earliestDate)!
      )
    ) {
      calculateDate = DateFns.addMonths(calculateDate, 1);
    }

    // Get previous Friday
    if (DateFns.isWeekend(calculateDate)) {
      calculateDate = this.getPreviousFriday(calculateDate);
    }

    while (
      exclusionDates.some(exclusionDate =>
        DateFns.isEqual(
          parseDateOnly(exclusionDate)!,
          parseDateOnly(calculateDate)!
        )
      )
    ) {
      calculateDate = DateFns.subDays(calculateDate, 1);
      if (DateFns.isWeekend(calculateDate)) {
        calculateDate = this.getPreviousFriday(calculateDate);
      }
    }

    return calculateDate;
  }
};
