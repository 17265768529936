/**
 * Selectors
 *
 * It's like "views" in relational databases.
 * Store the most efficient format in the reducer state.
 * But to retrieve into easy to consume form, use selector.
 */
import { RootState } from "../reducers";
import ReducerFactory from "src/ipm-shared/Utils/ReduxReducer";
import cardBrands from "./reducers";
ReducerFactory.registerReducer({ cardBrands });

export const brandsById = (state: RootState) => state.cardBrands.byId;
