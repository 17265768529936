// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ReviewAndSend_wrapper__r1l2Y{padding:.5rem 0 1.5rem}.ReviewAndSend_wrapper__r1l2Y .ReviewAndSend_invoiceOverView__BYwpX .ReviewAndSend_normalText__d5QfU{margin:.5rem 0;font-size:.875rem;font-weight:300}.ReviewAndSend_wrapper__r1l2Y .ReviewAndSend_invoiceOverView__BYwpX .ReviewAndSend_boldText__LybkV{margin:.5rem 0;font-size:.875rem;font-weight:bold}.ReviewAndSend_wrapper__r1l2Y .ReviewAndSend_invoiceOverView__BYwpX .ReviewAndSend_table__A9zCb{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:justify;justify-content:space-between;-ms-flex-align:center;align-items:center}.ReviewAndSend_wrapper__r1l2Y hr{height:2px;background-color:#7b7e80;border:none}.ReviewAndSend_wrapper__r1l2Y .ReviewAndSend_boldHr__uHND_{background-color:#000}.ReviewAndSend_wrapper__r1l2Y .ReviewAndSend_spacingBlock__2kOZy{margin-bottom:1.5rem}", "",{"version":3,"sources":["webpack://./src/bepaid/shared/modals/InvoiceCreateModal/components/ReviewAndSend/ReviewAndSend.module.scss"],"names":[],"mappings":"AAAA,8BAAS,sBAAsB,CAAC,qGAAsC,cAAc,CAAC,iBAAiB,CAAC,eAAe,CAAC,mGAAoC,cAAc,CAAC,iBAAiB,CAAC,gBAAgB,CAAC,gGAAiC,mBAAY,CAAZ,YAAY,CAAC,sBAAkB,CAAlB,kBAAkB,CAAC,qBAA6B,CAA7B,6BAA6B,CAAC,qBAAiB,CAAjB,kBAAkB,CAAC,iCAAY,UAAU,CAAC,wBAAwB,CAAC,WAAW,CAAC,2DAAiB,qBAAqB,CAAC,iEAAuB,oBAAoB","sourcesContent":[".wrapper{padding:.5rem 0 1.5rem}.wrapper .invoiceOverView .normalText{margin:.5rem 0;font-size:.875rem;font-weight:300}.wrapper .invoiceOverView .boldText{margin:.5rem 0;font-size:.875rem;font-weight:bold}.wrapper .invoiceOverView .table{display:flex;flex-direction:row;justify-content:space-between;align-items:center}.wrapper hr{height:2px;background-color:#7b7e80;border:none}.wrapper .boldHr{background-color:#000}.wrapper .spacingBlock{margin-bottom:1.5rem}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "ReviewAndSend_wrapper__r1l2Y",
	"invoiceOverView": "ReviewAndSend_invoiceOverView__BYwpX",
	"normalText": "ReviewAndSend_normalText__d5QfU",
	"boldText": "ReviewAndSend_boldText__LybkV",
	"table": "ReviewAndSend_table__A9zCb",
	"boldHr": "ReviewAndSend_boldHr__uHND_",
	"spacingBlock": "ReviewAndSend_spacingBlock__2kOZy"
};
export default ___CSS_LOADER_EXPORT___;
