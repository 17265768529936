import React, { useEffect, useState } from "react";
import { BrowserView, isDesktop, MobileView } from "react-device-detect";
import DesktopModalLayout from "src/bepaid/components/Modal/components/DesktopModalLayout";
import MainLayout from "src/bepaid/layouts/MainLayout";

import Button from "src/bepaid/components/Form/controls/Button";

import ROUTES from "src/bepaid/routes";
import { history } from "src/ipm-shared/store";
import { useMakePaymentUrlParams } from "src/bepaid/pages/Payments/hooks";
import { purposeByEntity } from "src/bepaid/pages/Payments/const";

import classnames from "classnames";
import styles from "./SelectPaymentOptionModal.module.scss";
import FeatureGate from "src/ipm-shared/components/FeatureGating/FeatureGate";

const View = (props: any) => {
  const { replaceRouteWithParams } = useMakePaymentUrlParams(
    ROUTES.PAYMENTS_CREATION
  );
  const [loading, setLoading] = useState(false);
  const [selectedPaymentOption, selectSelectedPaymentOption] = useState<any>(
    null
  );

  const onBack = () => {
    history.goBack();
  };

  const moveForward = () => {
    setLoading(true); // ok

    setTimeout(() => {
      const currentCompany = props.getCompany;
      if (props.isBusinessAccount && !currentCompany) {
        history.push(
          replaceRouteWithParams(ROUTES.PAYMENTS_CREATION, {
            newEntity: selectedPaymentOption,
            newAction: "company"
          })
        );
        return;
      }

      if (selectedPaymentOption === "business_employee") {
        const purpose = purposeByEntity["business_employee"];
        props.fetchPayees({
          type: purpose,
          getAllPayees: true,
          noRedirect: true,
          pageCount: 1000,
          cb: (payees: any) => {
            if (payees.length) {
              props.selectPayees({
                ids: [payees[0].id],
                purpose,
                removedIds: "all"
              });
              history.push(
                replaceRouteWithParams(ROUTES.PAYMENTS_CREATION, {
                  newEntity: selectedPaymentOption,
                  newAction: "salary_details"
                })
              );
            } else {
              history.push(
                replaceRouteWithParams(ROUTES.PAYMENTS_CREATION, {
                  newEntity: selectedPaymentOption,
                  newAction: "add"
                })
              );
            }
          },
          loadingFunc: setLoading
        });
      } else {
        history.push(
          replaceRouteWithParams(ROUTES.PAYMENTS_CREATION, {
            newEntity: selectedPaymentOption,
            newAction: "select"
          })
        );
      }
    }, 500);
  };

  useEffect(() => {
    const purpose = purposeByEntity[selectedPaymentOption];

    if (purpose) {
      props.resetSelectedPayees(purpose);
    }
  }, [selectedPaymentOption]);

  useEffect(() => {
    if (props.isSingaporeAccount && props.getConfirmedMyInfo === undefined) {
      const redirectPath = replaceRouteWithParams(ROUTES.PAYMENTS_CREATION, {
        newEntity: "entity",
        newAction: "select"
      });
      props.fetchMyInfoSingPass(({ confirmed = false, info }: any) => {
        if (!confirmed) {
          history.push(
            `${ROUTES.MY_INFO}?redirect_url=${encodeURIComponent(redirectPath)}`
          );
        }
      });
    }
  }, []);

  const renderContent = () => {
    return (
      <>
        <div
          className={classnames(styles.wrapper, {
            [styles.desktop]: isDesktop
          })}
        >
          <ul className={styles.list}>
            <li
              key={1}
              onClick={selectSelectedPaymentOption.bind(null, "employee")}
              className={classnames({
                [styles.selected]: "employee" === selectedPaymentOption
              })}
            >
              <b>Pay employees.</b>
              <span>Pay directly to your employees.</span>
            </li>
            <FeatureGate feature="SALARY_BUSINESS_PAYMENT">
              <li
                key={2}
                onClick={selectSelectedPaymentOption.bind(
                  null,
                  "business_employee"
                )}
                className={classnames({
                  [styles.selected]:
                    "business_employee" === selectedPaymentOption
                })}
              >
                <b>Pay business account.</b>
                <span>
                  Pay to your business account for payroll deductions.
                </span>
              </li>
            </FeatureGate>
          </ul>
        </div>
      </>
    );
  };

  const renderBtnSubmit = () => {
    return (
      <Button
        id={"ipaymy_btn_select_payment_option"}
        wrapperClassName={styles.wrapperButton}
        className={styles.button}
        onClick={moveForward}
        type="primary"
        htmlType="button"
        loading={!selectedPaymentOption || loading}
      >
        {loading ? "Moving forward..." : "Move forward"}
      </Button>
    );
  };

  const renderDesktopView = () => {
    return (
      <DesktopModalLayout
        id={"ipaymy_select_payment_option"}
        title={"Select payment option"}
        onClose={props.onClose}
        onBack={onBack}
        maxWidth450={true}
        onSubmit={moveForward}
        btnSubmit={renderBtnSubmit()}
      >
        {renderContent()}
      </DesktopModalLayout>
    );
  };

  const renderMobileView = () => {
    return (
      <MainLayout
        id={"ipaymy_select_payment_option"}
        header={{
          title: "Select payment option",
          hideMenu: true,
          onClose: props.onClose,
          onBack: onBack
        }}
        footer={renderBtnSubmit()}
      >
        {renderContent()}
      </MainLayout>
    );
  };

  return (
    <>
      <BrowserView>{renderDesktopView()}</BrowserView>
      <MobileView>{renderMobileView()}</MobileView>
    </>
  );
};

export default View;
