import React from "react";
import classNames from "classnames";
import { BrowserView, MobileView } from "react-device-detect";

import Image from "src/bepaid/components/Image";
import Button from "src/bepaid/components/Form/controls/Button";
// import MainLayout from "src/bepaid/layouts/MainLayout";
import DesktopModalLayout from "src/bepaid/components/Modal/components/DesktopModalLayout";

import IconDelete from "src/bepaid/assets/images/common/trash_big.svg";

import styles from "./DeleteModal.module.scss";

interface IProps {
  isLoading?: boolean;
  title: string;
  description?: any;
  yesLabel?: string;
  yesLabelLoading?: string;
  noLabel?: string;
  header?: string;
  hideTitle?: boolean;
  maxWidth400?: boolean;
  disabled?: boolean;
  onNo?: () => void;
  onYes?: () => void;
  onClose?: () => void;
}

function DeleteModal(props: IProps) {
  const {
    onYes,
    onNo,
    onClose,
    isLoading = false,
    title = "Are you sure?",
    description,
    yesLabel = "Yes, do request",
    yesLabelLoading = "Requesting...",
    noLabel = "No, we keep it",
    header = null,
    hideTitle = false,
    maxWidth400 = true,
    disabled = false
  } = props;

  const renderButtons = () => (
    <div className={styles.groupBtn}>
      <Button
        type="primary"
        onClick={onNo}
        loading={isLoading}
        disabled={disabled}
      >
        {!isLoading ? noLabel : yesLabelLoading}
      </Button>
      <Button type="secondary" onClick={onYes} hidden={isLoading}>
        {yesLabel}
      </Button>
    </div>
  );

  const renderContent = () => (
    <>
      {!hideTitle && (
        <div className={styles.titleContainer}>
          <Image src={IconDelete} />
          <h4 className={styles.title}>{title}</h4>
        </div>
      )}

      {description && (
        <div className={styles.body}>
          <div className={styles.description}>{description}</div>
        </div>
      )}
    </>
  );

  return (
    <>
      <BrowserView>
        <DesktopModalLayout
          hasBorder={!!header}
          noHeader={!header}
          btnSubmit={renderButtons()}
          onSubmit={header ? () => {} : undefined}
          title={header}
          onClose={onClose}
        >
          <div
            className={classNames(styles.wrapper, styles.wrapperDesktop, {
              [styles.maxWidth400]: maxWidth400
            })}
          >
            {renderContent()}
          </div>
        </DesktopModalLayout>
      </BrowserView>
      <MobileView>
        <div className={styles.wrapper}>
          {renderContent()}
          {renderButtons()}
        </div>
      </MobileView>
    </>
  );
}

export default DeleteModal;
