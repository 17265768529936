export const FORM = "add_payee";
export const BULK_ADD_FORM = "bulk_add_payee";
export const SELECT_PAYEE_FORM = "select_payee_form";
export const NOTE_TO_EMPLOYEE_FORM = "note_to_employee";
export const NOTE_TO_SUPPLIER_FORM = "note_to_supplier";
export const PAYEES_PAGE_COUNT = 30;
export type PURPOSE =
  | "rent"
  | "salary"
  | "invoice"
  | "collection"
  | "self_transfer"
  | "partner"
  | "salary_business"
  | "insurance";

// International
export const INTERNATIONAL_FORM = "add_recipient_form";

export const COUNTRY_NATIONAL_ID_NUMBER = ["MY", "IN", "CN", "TH", "PH"];

export const COUNTRY_REGISTRATION_NUMBER = ["MY", "IN", "TH", "PH"];

export const COUNTRY_BUSINESS_RELATIONSHIP = ["PH", "MY", "IN", "TH"];
export const COUNTRY_INDIVIDUAL_RELATIONSHIP = ["PH", "MY", "IN", "TH"];
export const COUNTRY_NATIONAL_MULTIPLE_CURRENCY = [
  "MY",
  "ID",
  "IN",
  "HK",
  "JP",
  "FR",
  "CN"
];

export const COUNTRY_NATIONAL_SELECT_BANK_ID = ["SG", "MY", "HK"];

export const COUNTRY_NATIONAL_REGION = ["IN"];

export const COUNTRY_NATIONAL_REGION_WITH_ID = ["ID"];

export const COUNTRY_NATIONAL_ID_NUMBER_PAY_FETCH = ["TH"];

export const COUNTRY_NATIONAL_BSB_CODE = ["AU"];

export const COUNTRY_NATIONAL_IFSC = ["IN"];

export const COUNTRY_NATIONAL_BANK_CODE = ["HK"];

export const COUNTRY_NATIONAL_ABA = ["US"];

export const COUNTRY_NATIONAL_SORT_CODE = ["GB"];

export const COUNTRY_NATIONAL_CITY = [
  "GB",
  "AU",
  "MY",
  "IN",
  "HK",
  "PH",
  "US",
  "JP",
  "FR",
  "DE",
  "CN",
  "NZ",
  "TH",
  "AT",
  "BE",
  "CY",
  "EE",
  "ES",
  "FI",
  "GR",
  "IE",
  "IT",
  "LT",
  "LU",
  "LV",
  "MT",
  "NL",
  "PT",
  "SI",
  "SK"
];

export const COUNTRY_NATIONAL_IBAN = [
  "FR",
  "GB",
  "DE",
  "AT",
  "BE",
  "CY",
  "EE",
  "ES",
  "FI",
  "GR",
  "IE",
  "IT",
  "LT",
  "LU",
  "LV",
  "MT",
  "NL",
  "PT",
  "SI",
  "SK"
];

export const COUNTRY_NATIONAL_CNAPS = ["CN"];
