/* tslint:disable:object-literal-sort-keys */
import React from "react";

import Typography from "antd/es/typography";

import T from "src/ipm-shared/Utils/Intl";

import Drawer from "src/bepaid/components/Drawer";
import Image from "src/bepaid/components/Image";

import { CUSTOMER_TAGS } from "src/bepaid/pages/Customers/const";

import ActiveIcon from "src/bepaid/assets/images/customers/tags/active_customer.svg";
import NewIcon from "src/bepaid/assets/images/customers/tags/new_customer.svg";
import RiskyIcon from "src/bepaid/assets/images/customers/tags/risky_customer.svg";
import VipIcon from "src/bepaid/assets/images/customers/tags/vip_customer.svg";

import styles from "./CustomerTagInfo.module.scss";

const CustomerTagInfo = (props: any) => {
  const { type = "new", visible = false, onClose = null } = props;

  const data = {
    [CUSTOMER_TAGS.active.value]: {
      icon: ActiveIcon,
      title: T.transl("HANDSHAKE_CUSTOMER_TAG_ACTIVE"),
      description: T.transl("HANDSHAKE_CUSTOMER_TAG_ACTIVE_DEFINITION")
    },
    [CUSTOMER_TAGS.new.value]: {
      icon: NewIcon,
      title: T.transl("HANDSHAKE_CUSTOMER_TAG_NEW"),
      description: T.transl("HANDSHAKE_CUSTOMER_TAG_NEW_DEFINITION")
    },
    [CUSTOMER_TAGS.risky.value]: {
      icon: RiskyIcon,
      title: T.transl("HANDSHAKE_CUSTOMER_TAG_RISKY"),
      description: T.transl("HANDSHAKE_CUSTOMER_TAG_RISKY_DEFINITION")
    },
    [CUSTOMER_TAGS.vip.value]: {
      icon: VipIcon,
      title: T.transl("HANDSHAKE_CUSTOMER_TAG_VIP"),
      description: T.transl("HANDSHAKE_CUSTOMER_TAG_VIP_DEFINITION")
    }
  };

  const renderContent = (value: string) => {
    return (
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <Image src={data[value].icon} />
          <Typography.Text className={styles.title}>
            {data[value].title}
          </Typography.Text>
          <Typography.Text className={styles.description}>
            {data[value].description}
          </Typography.Text>
        </div>
      </div>
    );
  };

  return (
    <Drawer
      height="40%"
      placement="bottom"
      visible={visible}
      closable={true}
      closeIcon={null}
      onClose={onClose}
    >
      {renderContent(type)}
    </Drawer>
  );
};

export default CustomerTagInfo;
