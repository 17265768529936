import React from "react";
import { connect } from "react-redux";
import _get from "lodash-es/get";
import _omit from "lodash-es/omit";
import classNames from "classnames";
import Input from "antd/es/input";

import SharedInputText from "src/ipm-shared/components/Form/controls/InputText";
import T from "src/ipm-shared/Utils/Intl";
import * as actions from "src/ipm-shared/components/Form/actions";

import Form from "src/bepaid/components/Form";

import styles from "./InputText.module.scss";

const { TextArea } = Input;

const Component = (props: any) => {
  const renderPasswordIcon = (visible: boolean) => {
    return visible ? "HIDE" : "SHOW";
  };

  const renderComponent = (sharedProps: any) => {
    const {
      label,
      // name,
      className = null,
      onChange = null,
      inputClassName = null,
      helperText = null,
      forcedHasError = false,
      fieldOptional = false,
      labelNoColon
    } = props;

    // INFO: props in custom component should be a higher priority
    const handleChange = (...params: any[]) => {
      let pass = false;
      if (sharedProps.onChange) {
        pass = sharedProps.onChange(...params);
      }
      if (onChange && pass) {
        onChange(...params);
      }
    };

    const commonProps = {
      ...sharedProps,
      ...props,
      onChange: handleChange
    };

    const validProps = {
      ..._omit(commonProps, [
        "inputClassName",
        "setControl",
        "onChangeCustom",
        "reserveValueOnUnmount"
      ])
    };

    const { control = {} } = sharedProps;

    const { errors = [] } = control;

    const errorMessage = _get(errors, "0.message", "");
    const errorCode = _get(errors, "0.code", "");

    const hasError = forcedHasError || errors.length > 0;

    return (
      <>
        <Form.Item
          label={label}
          // name={name}
          className={classNames(className, {
            [styles.fieldOptional]: fieldOptional,
            [styles.labelNoColon]: labelNoColon,
            [props.formItemClassName]: Boolean(props.formItemClassName)
          })}
          validateStatus={hasError ? "error" : ""}
          {...(hasError
            ? {
                help:
                  errorMessage || errorCode
                    ? T.transl(errorMessage || errorCode)
                    : helperText?.txt
              }
            : {})}
        >
          <>
            {props.type === "password" && (
              <Input.Password
                {...validProps}
                className={styles.wrapper}
                iconRender={renderPasswordIcon}
              />
            )}

            {props.type === "textarea" && (
              <TextArea {...validProps} rows={4} className={styles.wrapper} />
            )}

            {!["password", "textarea", "file"].includes(props.type) && (
              <Input
                {...validProps}
                prefix={props.prefix}
                className={`${inputClassName} ${styles.wrapper}`}
              />
            )}
          </>
          {props.description}
        </Form.Item>
        {helperText && (
          <p className={styles.helperText}>{!hasError ? helperText.txt : ""}</p>
        )}
      </>
    );
  };

  return <SharedInputText {...props} renderComponent={renderComponent} />;
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  setControl: actions.setControl
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
