// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PaymentStatusPill_pill__1ztlc{font-size:.75rem;font-weight:500;text-align:center;padding:4px 1rem;border-radius:4px;background-color:var(--payment-background);color:var(--payment-color)}", "",{"version":3,"sources":["webpack://./src/bepaid/shared/modals/PaymentDetailModal/components/PaymentStatusPill/PaymentStatusPill.module.scss"],"names":[],"mappings":"AAAA,+BAAM,gBAAgB,CAAC,eAAe,CAAC,iBAAiB,CAAC,gBAAgB,CAAC,iBAAiB,CAAC,0CAA0C,CAAC,0BAA0B","sourcesContent":[".pill{font-size:.75rem;font-weight:500;text-align:center;padding:4px 1rem;border-radius:4px;background-color:var(--payment-background);color:var(--payment-color)}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pill": "PaymentStatusPill_pill__1ztlc"
};
export default ___CSS_LOADER_EXPORT___;
