import { RootState } from "src/ipm-shared/store/model/reducers";
import { connect } from "react-redux";
import * as selectors from "src/bepaid/store/model/Fetch/selectors";

import View from "./View";

const mapStateToProps = (state: RootState) => ({
  isPersonalAccount: selectors.getIsPersonalAccount(state),
  isSingaporeAccount: selectors.isSingaporeAccount(state),
  isAustraliaAccount: selectors.isAustraliaAccount(state),
  isMalaysiaAccount: selectors.isMalaysiaAccount(state),
  canAccessCryptoPayment: selectors.canAccessCryptoPayment(state)
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(View);
