/**
 * Selectors
 *
 * It's like "views" in relational databases.
 * Store the most efficient format in the reducer state.
 * But to retrieve into easy to consume form, use selector.
 */
import _get from "lodash-es/get";
import _isNil from "lodash-es/isNil";
import { RootState } from "src/ipm-shared/store/model/reducers";
import ReducerFactory from "src/ipm-shared/Utils/ReduxReducer";
import collectedAccountReducer, {
  MainCollectedAccountData,
  OtherSettingsCollectedAccount
} from "./reducers";
import { getCurrentCurrencyId } from "../Auth/selectors";

ReducerFactory.registerReducer({
  collectedAccount: collectedAccountReducer
});

export const isUpdatedStatus = (state: RootState) =>
  state.collectedAccount.isUpdatedStatus;

export const getTaxes = (state: RootState) => state.collectedAccount.taxes;
export const getItems = (state: RootState) => state.collectedAccount.items;
export const getInvoiceLineItems = (state: RootState) =>
  state.collectedAccount.invoiceLineItems;

export const getSelectedItemsIds = (state: RootState) =>
  state.collectedAccount.selectedItemsIds;

export const getSelectedTaxesIds = (state: RootState) =>
  state.collectedAccount.selectedTaxesIds;

export const getAllCollectedAccounts = (state: RootState) =>
  state.collectedAccount.list;

export const shouldLoadingFetchingCollectedAccount = (state: RootState) =>
  state.collectedAccount.isDisplayLoading;

export const getTotalCollectedAccounts = (state: RootState) =>
  state.collectedAccount.totalCollectedAccounts;

export const getCollectedAccount = (
  state: RootState
): MainCollectedAccountData | undefined =>
  state.collectedAccount.mainCollectedAccount.data;

export const hasCollectedAccount = (state: RootState): boolean => {
  const ca = state.collectedAccount;

  return (
    _get(ca, "mainCollectedAccount.isFetched") === true &&
    _get(ca, "mainCollectedAccount.data") !== undefined &&
    _get(ca, "mainCollectedAccount.data.draft") !== true
  );
};

export const getIsFetchedMainCollectedAccount = (state: RootState): boolean => {
  return state.collectedAccount.mainCollectedAccount.isFetched;
};

export const getCollectionPayeeData = (state: RootState) =>
  _get(state.collectedAccount.mainCollectedAccount.data, "payeeData");

export const getCollectionOtherSettings = (
  state: RootState
): OtherSettingsCollectedAccount | undefined =>
  _get(
    state.collectedAccount.mainCollectedAccount.data,
    "otherSettings",
    undefined
  );

export const getHasActiveProgram = (state: RootState): boolean =>
  state.collectedAccount.mainCollectedAccount.hasActiveProgram;

export const getCurrentBePaidCurrencyId = (state: RootState): number => {
  const ca = getCollectedAccount(state);
  if (ca) {
    return ca.currencyId;
  }

  return getCurrentCurrencyId(state);
};

export const getCollectionRates = (state: RootState) =>
  state.collectedAccount.mainCollectedAccount.rates;

export const getCollectedAccountById = (state: RootState, id: number) => {
  const collectedAccount = state.collectedAccount.list.filter(
    item => item.id === id
  )[0];
  return collectedAccount;
};

export const isOpenDraftPayment = (state: RootState) =>
  _get(
    state.collectedAccount.mainCollectedAccount,
    "data.payeeData.remindDraftInvoice",
    true
  ) && state.collectedAccount.mainCollectedAccount.hasDraftPayment;

export const isDeclinedKYC = (state: RootState) => {
  const ca = getCollectedAccount(state);
  return _get(ca, "status") === "D";
};

export const hasCollectedAccountFirstStep = (state: RootState): boolean => {
  const ca = getCollectedAccount(state);

  return (
    !!ca &&
    !!_get(ca, "draft") &&
    !_isNil(_get(ca, "payeeData.kycDocuments.b_acra_bizfile"))
  );
};

export const getAccountUserRoleId = (state: RootState) => {
  return state.collectedAccount.mainCollectedAccount.accountUserRoleId;
};
