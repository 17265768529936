import React, { useEffect, useState } from "react";
import { isDesktop } from "react-device-detect";
import _get from "lodash-es/get";
import _isEmpty from "lodash-es/isEmpty";
import uuidV1 from "uuid/v1";
import classNames from "classnames";

import T from "src/ipm-shared/Utils/Intl";
import { DiscountTypeType } from "src/ipm-shared/store/model/CollectedAccount/reducers";
import { ADD_TAX_FORM } from "src/ipm-shared/store/model/CollectedAccount/const";
import ShortCurrency from "src/ipm-shared/components/ShortCurrency";

import InputText from "src/bepaid/components/Form/controls/InputText";

import Form from "src/bepaid/components/Form";
import Radio from "antd/es/radio";
import FormStyles from "src/bepaid/components/Form/CommonForm.module.scss";

import Image from "src/bepaid/components/Image";
import Button from "src/bepaid/components/Form/controls/Button";
import ButtonIcon from "src/bepaid/components/Form/controls/ButtonIcon";
import usePrevious from "src/bepaid/hooks/usePrevious";

import IconDropDown from "src/bepaid/assets/images/common/drop_down_arrow.svg";
import IconRadioBtn from "src/bepaid/assets/images/common/radio_button.svg";
import IconRadioDefaultBtn from "src/bepaid/assets/images/common/radio_default.svg";
import IconInfo from "src/bepaid/assets/images/common/icon_information.svg";

import AddTax from "../AddTax";

import styles from "../Additional.module.scss";

interface DataType {
  key: React.Key;
  id: number;
  uid: string;
  name: string;
  quantity: number;
  unitPrice: number;
  discount?: number;
  discountType?: DiscountTypeType;
  taxName?: string;
  taxRate?: number;
  taxId?: number;
  amount: number;
  checked?: boolean;
}

const defaultItem: any[] = [];
const Component = (props: any) => {
  const [loading, setLoading] = useState(false);

  const commonFormItemProps = {
    reserveValueOnUnmount: true,
    revertValueOnMount: true,
    onWheel: (e: any) => e.target.blur()
  };
  const [item, setItem] = useState(props.itemEdit);
  const handleOpenDetails = (record: any) => () => {
    const itemSelect = props.invoiceLineItems[record.uid];
    const newItem = {
      ...item,
      discount: record.discount,
      discountBackup: null,
      discountType: record.discountType,
      id: record.id,
      name: record.name,
      quantity: record.quantity,
      taxId: record.taxId,
      taxName: record.taxName,
      uid: record.uid,
      unitPrice: record.unitPrice ? record.unitPrice / 100 : 0
    };
    if (itemSelect) {
      newItem.quantity = itemSelect.quantity;
    }
    setItem(newItem);
    props.setControl({
      errors: [],
      form: props.form,
      name: `name`,
      value: record.name
    });
    props.setControl({
      errors: [],
      form: props.form,
      name: `description`,
      value: record.description
    });
    props.setControl({
      errors: [],
      form: props.form,
      name: `unit_price`,
      value: record.unitPrice ? record.unitPrice / 100 : 0
    });
    let discountValue = 0;
    discountValue =
      record.discountType === "amount"
        ? record.discount / 100
        : record.discount;

    if (discountValue) {
      setTimeout(() => {
        setSizeSuffixAmount(discountValue);
      }, 300);
    }
    props.setControl({
      errors: [],
      form: props.form,
      name: `discount`,
      value: discountValue
    });

    props.setControl({
      errors: [],
      form: props.form,
      name: `discount_type`,
      value: record.discountType ? record.discountType : "percentage"
    });
    props.setControl({
      errors: [],
      form: props.form,
      name: `quantity`,
      value: newItem.quantity
    });
    props.setControl({
      errors: [],
      form: props.form,
      name: `tax_id`,
      value: record.tagId
    });
    props.selectTax(record.uid, record.taxId);
    setOptionAddItem("add");
    props.changeType("add", record);
  };
  const [items, setItems] = useState(defaultItem);
  useEffect(() => {
    let data: any[] = [];
    if (props.items) {
      data = props.items.map((i: any) => {
        if (!i.uid) {
          i.uid = uuidV1();
        }
        i.quantity = 1;
        return i;
      });
      checkedItem(data);
    }
  }, [props.items]);
  const prevInvoiceLineItems = usePrevious(props.invoiceLineItems);
  useEffect(() => {
    const data = items.map((i: any) => {
      const itemSelect = props.invoiceLineItems[i.uid];
      if (itemSelect) {
        return {
          ...i,
          name: itemSelect.name,
          quantity: itemSelect.quantity,
          unitPrice: itemSelect.unitPrice,
          discount: itemSelect.discount,
          discountType: itemSelect.discountType,
          taxName: itemSelect.taxName,
          taxRate: itemSelect.taxRate,
          taxId: itemSelect.taxId,
          amount: itemSelect.amount
        };
      }
      return i;
    });
    checkedItem(data);
  }, [props.invoiceLineItems, prevInvoiceLineItems]);
  const prevSelectedItem = usePrevious(props.selectedItem);
  useEffect(() => {
    if (!props.selectedItem) {
      return;
    }
    checkedItem(items);
  }, [props.selectedItem, prevSelectedItem]);

  const checkedItem = (data: DataType[]) => {
    const newItemSelectedId: any[] = [];
    Object.keys(props.selectedItem).forEach(key => {
      newItemSelectedId.push({ id: props.selectedItem[key], key });
    });
    if (data.length > 0) {
      const newItems = data.map((i: DataType) => {
        const findSelected = newItemSelectedId.find(s => s.id === i.id);
        if (findSelected) {
          i.checked = true;
          i.uid = findSelected.key;
        }
        return i;
      });
      setItems([...newItems]);
    }
  };
  const [optionAddItem, setOptionAddItem] = useState(props.type);
  useEffect(() => {
    setOptionAddItem(props.type);
  }, [props.type]);
  const handleChangeOptionAddItem = (e: any) => {
    e.preventDefault();
    if (e.target.value === "add") {
      const recordNew = {
        discount: null,
        discountType: "percentage",
        name: null,
        quantity: 1,
        taxId: null,
        taxName: null,
        uid: uuidV1(),
        unitPrice: null
      };
      handleOpenDetails(recordNew)();
    } else {
      props.changeType(e.target.value);
    }
    setOptionAddItem(e.target.value);
  };
  const handleOpenAddTax = () => {
    setOptionAddItem("add_tax");
  };

  const setSizeSuffixAmount = (value: number) => {
    const inputSuffix: any = document.getElementById("discount_input_suffix")
      ?.parentNode;
    if (inputSuffix) {
      const size = value === 0 ? 4.5 : String(value).length + 1;
      inputSuffix.style.left = size + "ch";
    }
  };

  const handleChangeDiscountType = (e: any) => {
    const newDiscountType = e.target.value;
    const inputNumber: any = document.getElementById("discount_input_item");

    if (newDiscountType === "percentage") {
      setTimeout(() => {
        const value = Number(inputNumber.value);
        setSizeSuffixAmount(isNaN(value) ? 0 : value);
      }, 10);
    }

    props.setControl({
      errors: [],
      form: props.form,
      name: `discount_type`,
      value: newDiscountType
    });

    let discountValue = null;
    if (item.discountBackup) {
      discountValue = item.discountBackup;
    }
    setItem({
      ...item,
      discountType: newDiscountType,
      discountBackup: inputNumber.value
    });
    props.setControl({
      errors: [],
      form: props.form,
      name: `discount`,
      value: discountValue
    });
  };
  const validateBeforeSubmit = () => {
    let fieldsErr = {};
    if (!item.name) {
      fieldsErr = {
        ...fieldsErr,
        ["name"]: ["REQUIRED_FIELD"]
      };
    }
    if (Number(item.unitPrice) <= 0) {
      fieldsErr = {
        ...fieldsErr,
        ["unit_price"]: ["REQUIRED_FIELD"]
      };
    }
    if (Number(item.quantity) < 1) {
      fieldsErr = {
        ...fieldsErr,
        ["quantity"]: ["REQUIRED_FIELD"]
      };
    }
    if (!_isEmpty(fieldsErr)) {
      let errors = {
        fields: fieldsErr,
        form: []
      };
      props.parseServerErrors(errors, props.form);
      return false;
    }
    return true;
  };
  const handleAddItem = () => {
    if (!validateBeforeSubmit()) {
      return;
    }
    if (item.id) {
      props.editItem(item, setLoading);
    } else {
      props.addItem(setLoading);
    }
  };

  const prevSelectedTax = usePrevious(props.selectedTax);
  useEffect(() => {
    if (!item) {
      return;
    }
    const taxSelected = props.taxes.filter(
      (i: any) => i.id === props.selectedTax[item.uid]
    )[0];
    if (taxSelected) {
      setItem({
        ...item,
        taxId: taxSelected.id,
        taxName: taxSelected.name
      });
    }
  }, [props.selectedTax, prevSelectedTax]);
  const { form, currencySymbol } = props;
  const handleSelectTax = (uid: string, id: number) => {
    props.setControl({
      errors: [],
      form: props.form,
      name: `tax_id`,
      value: id
    });
    props.selectTax(uid, id);
  };
  const handleSubmitAddTax = (setLoading: any) => {
    props.addTax((data: any) => {
      if (data) {
        // handleSelectTax(item.uid, data);
        setOptionAddItem("add");
      }
    }, setLoading);
  };

  const handleSubmitEditTax = (id: number, setLoading: any) => {
    props.editTax(
      id,
      (data: any) => {
        if (data) {
          // handleSelectTax(item.uid, data);
          setOptionAddItem("add");
        }
      },
      setLoading
    );
  };
  const handleCloseSelectTax = () => {
    setOptionAddItem("add");
  };

  const handleSelectItem = (record: DataType) => () => {
    setItems([
      ...items.map((i: DataType) => {
        if (record.id === i.id) {
          i.checked = !i.checked;
        }
        return i;
      })
    ]);
  };
  const renderItem = (record: any) => {
    return (
      <div
        className={classNames(styles.wrapperItemList, {
          [styles.wrapperItemListChecked]: record.checked
        })}
      >
        <div
          className={styles.wrapperAction}
          onClick={handleSelectItem(record)}
        >
          {record.checked ? (
            <Image className={styles.iconMore} src={IconRadioBtn} />
          ) : (
            <Image className={styles.iconMore} src={IconRadioDefaultBtn} />
          )}
        </div>
        <div className={styles.wrapperDescription}>
          <span className={styles.title}>{record.name}</span>
          {record.description && (
            <span className={styles.description}>{record.description}</span>
          )}
        </div>
        <div className={styles.wrapperAmount}>
          <span className={styles.title}>
            <ShortCurrency
              value={record.unitPrice}
              currencyId={props.currencyId}
            />
          </span>
          <Image
            className={styles.iconMore}
            src={IconInfo}
            onClick={handleOpenDetails(record)}
          />
        </div>
      </div>
    );
  };

  const handleSubmitSelectItem = () => {
    const recordSelected: DataType[] = [];
    items.forEach((i: any) => {
      if (i.checked) {
        const record = props.invoiceLineItems[i.uid]
          ? props.invoiceLineItems[i.uid]
          : i;
        recordSelected.push(record);
      }
    });

    props.selectItem(recordSelected);
    props.close();
  };
  const handleChangeDataItem = (field: any) => (e: any) => {
    let value = e.target.value;
    if (field === "quantity") {
      value = Number(value);
    }
    setItem({
      ...item,
      [field]: value
    });
    props.updateInvoiceLineItem(item.uid, field, value);
  };

  const isDiscountTypeAmount = _get(item, "discountType") === "amount";

  const handleChangDiscountValue = (e: any) => {
    if (!isDiscountTypeAmount) {
      const value = Number(e.target.value);
      setSizeSuffixAmount(isNaN(value) ? 0 : value);
    }
  };

  const renderOption = () => {
    if (optionAddItem === "add") {
      let taxSelected = null;
      if (item !== null) {
        taxSelected = props.taxes.filter(
          (i: any) => i.id === props.selectedTax[item.uid]
        )[0];
      }
      return (
        <div className={styles.wrapperFormAddItem}>
          <Form
            layout="vertical"
            requiredMark={false}
            className={classNames(
              FormStyles.personalFormDetail,
              styles.formInput
            )}
          >
            <InputText
              form={form}
              name="name"
              placeholder={T.transl("INVOICE_NEW_ITEM_NAME_PLACEHOLDER")}
              label={T.transl("INVOICE_NEW_ITEM_NAME_LABEL")}
              defaultValue={_get(item, "name")}
              onChange={handleChangeDataItem("name")}
              {...commonFormItemProps}
            />
            <InputText
              form={form}
              name="description"
              placeholder="Description"
              label={<span>Description</span>}
              fieldOptional={true}
              defaultValue={_get(item, "description")}
              {...commonFormItemProps}
            />
            <InputText
              form={form}
              type={"number"}
              name="unit_price"
              placeholder={T.transl("INVOICE_NEW_ITEM_TAX_RATE_PLACEHOLDER")}
              label={`Rate`}
              defaultValue={_get(item, "unitPrice")}
              onChange={handleChangeDataItem("unitPrice")}
              // prefix={CurrencyUtil.convertFromId(props.currencyId as number).$}
              pattern={"__MONEY__"}
              {...commonFormItemProps}
            />
            <InputText
              form={form}
              type={"number"}
              name="quantity"
              placeholder={T.transl("INVOICE_NEW_ITEM_QUANTITY_PLACEHOLDER")}
              label={`Quantity`}
              defaultValue={_get(item, "quantity", 1)}
              onChange={handleChangeDataItem("quantity")}
              {...commonFormItemProps}
            />
            <p className={classNames(styles.text, styles.textOptional)}>
              Add or select tax
            </p>
            <ButtonIcon
              className={styles.btnAddItem}
              iconClass={styles.iconItem}
              icon={<Image src={IconDropDown} />}
              onClick={handleOpenAddTax}
            >
              {taxSelected ? taxSelected.name : "Tax"}
            </ButtonIcon>
            <div className={styles.discountItem}>
              <div className={styles.wrapperSwitchDiscount}>
                <Radio.Group
                  className={styles.groupOptionAddItem}
                  options={[
                    { label: "%", value: "percentage" },
                    { label: currencySymbol, value: "amount" }
                  ]}
                  onChange={handleChangeDiscountType}
                  value={_get(item, "discountType", "percentage")}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
              <InputText
                form={form}
                id="discount_input_item"
                type={"number"}
                className={classNames(styles.discountInput, {
                  [styles.discountPercentage]: !isDiscountTypeAmount
                })}
                name="discount"
                label={<span>Discount</span>}
                fieldOptional={true}
                {...(isDiscountTypeAmount && {
                  prefix: currencySymbol
                })}
                placeholder={
                  isDiscountTypeAmount
                    ? "0.00"
                    : T.transl("INVOICE_NEW_ITEM_PCT_DISCOUNT_PLACEHOLDER")
                }
                defaultValue={_get(item, "discount")}
                pattern={isDiscountTypeAmount ? "__MONEY__" : "__PERCENTAGE__"}
                maxValue={isDiscountTypeAmount ? null : 99}
                onChange={handleChangDiscountValue}
                {...commonFormItemProps}
              />
              <InputText
                hidden={true}
                className={styles.hidden}
                form={form}
                name={`discount_type`}
                defaultValue={"amount"}
                {...commonFormItemProps}
              />
              <InputText
                hidden={true}
                className={styles.hidden}
                form={form}
                name={`tax_id`}
                defaultValue={_get(taxSelected, "id", null)}
                {...commonFormItemProps}
              />
            </div>
          </Form>
          <Button
            type="primary"
            htmlType="submit"
            onClick={handleAddItem}
            loading={loading}
          >
            {_get(item, "id")
              ? loading
                ? "Saving..."
                : "Save"
              : loading
              ? "Adding..."
              : "Add"}
          </Button>
        </div>
      );
    } else if (optionAddItem === "add_tax") {
      return (
        <AddTax
          form={ADD_TAX_FORM}
          items={props.taxes}
          selectedItem={props.selectedTax}
          selectItem={handleSelectTax}
          addTax={handleSubmitAddTax}
          editTax={handleSubmitEditTax}
          uid={item.uid}
          close={handleCloseSelectTax}
          setControl={props.setControl}
          currencyId={props.currencyId}
        />
      );
    } else {
      return (
        <div
          className={classNames({
            [styles.wrapperDesktop]: isDesktop
          })}
        >
          <div className={styles.wrapperTable}>
            {items.map((i: any) => {
              return renderItem(i);
            })}
          </div>
          <Button
            type="primary"
            htmlType="submit"
            onClick={handleSubmitSelectItem}
            // loading={isLoading}
          >
            Select
          </Button>
        </div>
      );
    }
  };
  return (
    <div
      className={classNames(FormStyles.content, styles.wrapperDetailsContent, {
        [styles.desktopDetailContent]: isDesktop
      })}
    >
      {optionAddItem !== "add_tax" && (
        <Radio.Group
          className={styles.groupOptionAddItem}
          options={[
            { label: "Select item", value: "select" },
            { label: "Add item", value: "add" }
          ]}
          onChange={handleChangeOptionAddItem}
          value={optionAddItem}
          optionType="button"
          buttonStyle="solid"
        />
      )}
      {renderOption()}
    </div>
  );
};

export default Component;
