import React from "react";
import { isDesktop } from "react-device-detect";
import _get from "lodash-es/get";
import Form from "src/bepaid/components/Form";
import classnames from "classnames";

import { BUSINESS_INFORMATION_FORM } from "src/bepaid/store/model/Fetch/const";

import InputText from "src/bepaid/components/Form/controls/InputText";
import Button from "src/bepaid/components/Form/controls/Button";
import { invokeMessage } from "src/bepaid/components/Message";

import styles from "./BusinessInfomation.module.scss";

const FormComponent = (props: any) => {
  const {
    collectedAccount,
    fetchMainCollectedAccount,
    updateAdditionalInvoiceSetting
  } = props;

  const [loading, setLoading] = React.useState(false);

  const onSubmit = () => {
    updateAdditionalInvoiceSetting(
      BUSINESS_INFORMATION_FORM,
      (err?: any) => {
        if (!err) {
          invokeMessage("success", "Changes saved.");
          fetchMainCollectedAccount();
          if (props.onSaved) {
            props.onSaved();
          }
        }
      },
      setLoading
    );
  };

  return (
    <Form
      name="business-information-form"
      layout="vertical"
      requiredMark={false}
      className={styles.form}
    >
      <div className={styles.formWrapper}>
        <div className={styles.formContent}>
          <InputText
            name="gst_registration_number"
            placeholder="GST registration number"
            form={BUSINESS_INFORMATION_FORM}
            label="GST registration number"
            defaultValue={_get(
              collectedAccount,
              "payeeData.gstRegistrationNumber",
              ""
            )}
          />
          <InputText
            name="company_address_line_1"
            placeholder="Company address line 1"
            form={BUSINESS_INFORMATION_FORM}
            label="Company address"
            defaultValue={_get(
              collectedAccount,
              "payeeData.companyAddressLine1",
              ""
            )}
          />
          <InputText
            name="company_address_line_2"
            placeholder="Company address line 2"
            form={BUSINESS_INFORMATION_FORM}
            defaultValue={_get(
              collectedAccount,
              "payeeData.companyAddressLine2",
              ""
            )}
          />
        </div>
      </div>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
          onClick={onSubmit}
        >
          {loading ? "Saving changes..." : "Save changes"}
        </Button>
      </Form.Item>
    </Form>
  );
};

const BusinessInformation: React.FC<any> = props => {
  return (
    <div
      className={classnames(styles.wrapper, {
        [styles.wrapperDesktop]: isDesktop
      })}
    >
      <FormComponent {...props} />
    </div>
  );
};

export default BusinessInformation;
