/* tslint:disable:object-literal-sort-keys */
import React, { useEffect, useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import _get from "lodash-es/get";
import _isEmpty from "lodash-es/isEmpty";

import { history } from "src/ipm-shared/store";
import {
  INCENTIVES_DETAIL_FORM,
  INVOICE_DETAIL_FORM
} from "src/ipm-shared/store/model/Customer/const";
import { CREATE_INVOICE_FORM } from "src/ipm-shared/store/model/CollectedAccount/const";
import { FetchModalID } from "src/ipm-shared/components/GlobalUI/actions";
import { PAYMENT_STATUS } from "src/ipm-shared/store/model/Payment/const";
import IPMContext from "src/ipm-shared/Utils/IPMContext";
import T from "src/ipm-shared/Utils/Intl";
import ScrollUtil from "src/ipm-shared/Utils/Scroll";

import ROUTES from "src/bepaid/routes";
import Button from "src/bepaid/components/Form/controls/Button";
import Progress from "src/bepaid/components/Progress";
import { StepRoute } from "src/bepaid/utils/invoice";
import Image from "src/bepaid/components/Image";
import DesktopModalLayout from "src/bepaid/components/Modal/components/DesktopModalLayout";
import MainLayout from "src/bepaid/layouts/MainLayout";

import AddOrSelectCustomer from "../AddOrSelectCustomer";
import EnterInvoice from "../EnterInvoice";
import AddIncentives from "../AddIncentives";
import ReviewAndSend from "../ReviewAndSend";

import IconDelete from "src/bepaid/assets/images/common/icon_delete_border.svg";

import styles from "./CreateInvoice.module.scss";

const INVOICE_CONTENT_ID = "invoice-content";

const CreateInvoices: React.FC<any> = props => {
  const nextBtnTitle = "Move forward";
  const nextBtnTitleLoading = "Moving forward...";

  const [isLoading, setLoading] = useState(false);
  const [isSkipLoading, setSkipLoading] = useState(false);

  const [customerSelected, setCustomerSelected] = useState(
    props.getSelectedCustomersId
  );

  let invoiceSelected = props.getInvoiceSelected();
  const invoiceDraftSelected = props.getInvoiceDraftSelected();
  if (!invoiceSelected && invoiceDraftSelected) {
    invoiceSelected = invoiceDraftSelected;
  }

  const isDraft =
    invoiceSelected && props.isOpenDraftPayment
      ? invoiceSelected.paymentStatusId === PAYMENT_STATUS.DRAFT
      : false;

  const [steps] = useState([
    {
      title: `Select customer`,
      // tslint:disable-next-line:object-literal-sort-keys
      nextBtnTitle,
      nextBtnTitleLoading,
      nextBtnId: "ipaymy_btn_select_customer",
      nextStep: () => {
        setStepIndex(2);
        history.push(ROUTES.CREATE_INVOICES_STEP2);
      },
      percent: 25,
      renderContent: () => {
        return <AddOrSelectCustomer updateStep={updateStep} />;
      }
    },
    {
      title: `Add customer`,
      // tslint:disable-next-line:object-literal-sort-keys
      nextBtnTitle,
      nextBtnTitleLoading,
      nextBtnId: "ipaymy_btn_add_customer",
      nextStep: () => {
        setStepIndex(2);
        history.push(ROUTES.CREATE_INVOICES_STEP2);
      },
      percent: 25,
      renderContent: () => {
        return <AddOrSelectCustomer updateStep={updateStep} />;
      }
    },
    {
      title: "Enter details",
      // tslint:disable-next-line:object-literal-sort-keys
      nextBtnTitle,
      nextBtnTitleLoading,
      nextBtnId: "ipaymy_btn_enter_invoice_details",
      back: () => {
        setStepIndex(0);
        history.push(ROUTES.CREATE_INVOICES_STEP1);
      },
      nextStep: () => {
        setStepIndex(3);
        history.push(ROUTES.CREATE_INVOICES_STEP3);
      },
      percent: 50,
      requiredVerificationFields: [
        "amount",
        "payment_description",
        "due_date",
        "invoice_date",
        "supporting_documents"
      ],
      renderContent: () => {
        return (
          <EnterInvoice
            updateStep={updateStep}
            checkAmountDue={checkAmountDue}
          />
        );
      }
    },
    {
      title: "Add incentives",
      // tslint:disable-next-line:object-literal-sort-keys
      nextBtnTitle,
      nextBtnTitleLoading,
      nextBtnId: "ipaymy_btn_add_invoice_incentives",
      back: () => {
        setStepIndex(2);
        history.push(ROUTES.CREATE_INVOICES_STEP2);
      },
      nextStep: () => {
        setStepIndex(4);
        history.push(ROUTES.CREATE_INVOICES_STEP4);
      },
      percent: 75,
      requiredVerificationFields: ["incentives"],
      renderContent: () => {
        return <AddIncentives updateStep={updateStep} />;
      }
    },
    {
      title: "Review and send",
      // tslint:disable-next-line:object-literal-sort-keys
      nextBtnTitle: "Send invoice",
      nextBtnTitleLoading: "Sending invoice...",
      nextBtnId: "ipaymy_btn_send_invoice",
      back: () => {
        setStepIndex(3);
        history.push(ROUTES.CREATE_INVOICES_STEP3);
      },
      nextStep: () => null,
      percent: 100,
      requiredVerificationFields: null,
      renderContent: () => {
        return <ReviewAndSend updateStep={updateStep} />;
      }
    }
  ]);

  const [stepIndex, setStepIndex] = useState(
    StepRoute[`${history.location.pathname}${history.location.search}`] || 0
  );

  useEffect(() => {
    if (props.changeStep) {
      props.changeStep(steps[stepIndex]);
    }
  }, [stepIndex]);

  const step = steps[stepIndex];

  const updateStep = (index: number) => {
    setStepIndex(index);
  };

  const handleSave = (draft: boolean) => {
    submitCollection(
      false,
      null,
      draft,
      stepIndex,
      (errors: any, data: any) => {
        if (errors) {
          return;
        }
        props.fetchMainCollectedAccount();
        props.toggleModal(FetchModalID.CREATE_INVOICE);
        if (draft === false) {
          // open modal here
          props.toggleModal(FetchModalID.INVOICE_SENT, {
            invoiceId: _get(data, "checkout_url_list[0].id", null),
            url: _get(data, "checkout_url_list[0].url", "")
          });
        }
      },
      setLoading
    );
  };

  const handleAddCustomerStep = () => {
    props.addCustomer(
      null,
      null,
      null,
      "none",
      null,
      (err: any, data: any) => {
        if (err) {
          return;
        }
        const ids = customerSelected;
        ids.push(data.id);
        props.selectCustomers(ids);
        setCustomerSelected(ids);
        setStepIndex(stepIndex + 1);
        step.nextStep();
      },
      setLoading
    );
  };
  const checkAmountDue = (
    currentAmountDue: number,
    customerId: number,
    invoiceLineItems: any,
    taxes: any,
    isUpdate: boolean,
    calculateMethod: string = "discount_tax_per_item"
  ) => {
    if (!_isEmpty(invoiceLineItems) && customerId) {
      let amountDue = 0;
      Object.keys(invoiceLineItems).forEach((key: any) => {
        const item = invoiceLineItems[key];
        let itemAmount = item.amount;
        if (
          calculateMethod === "discount_all_item" ||
          calculateMethod === "discount_tax_all_item"
        ) {
          itemAmount = itemAmount * item.quantity;
        }
        if (item.discount) {
          if (item.discountType === "amount") {
            itemAmount = itemAmount - item.discount;
          } else if (item.discountType === "percentage") {
            itemAmount = itemAmount - (itemAmount * item.discount) / 100;
          }
        }
        if (item.taxId && taxes.length > 0) {
          const data = taxes.find((t: any) => t.id === item.taxId);
          itemAmount = itemAmount * (1 + data.rate / 100);
        }
        if (calculateMethod === "discount_tax_per_item") {
          amountDue += item.quantity * itemAmount;
        } else {
          amountDue += itemAmount;
        }
      });

      if (amountDue > 0) {
        const amountStr = (amountDue / 100).toFixed(2);
        if (isUpdate) {
          props.setControl({
            errors: [],
            form: CREATE_INVOICE_FORM,
            name: `supplier_amount_${customerId}`,
            value: amountStr
          });
          return;
        }
        if (Number(currentAmountDue) !== Number(amountStr)) {
          props.setControl({
            errors: [
              {
                code: "AMOUNT_DOES_NOT_MATCH_ITEMS_SUBTOTALS",
                message:
                  "This amount doesn’t match your item subtotals, please check the item details or remove all selected items."
              }
            ],
            form: CREATE_INVOICE_FORM,
            name: `supplier_amount_${customerId}`,
            value: currentAmountDue
          });
          const elm = document.querySelector(
            "input[name='" + `supplier_amount_${customerId}` + "']"
          ) as HTMLElement;

          const mainLayout = document.getElementById(INVOICE_CONTENT_ID);
          if (elm && mainLayout) {
            const topMain = mainLayout.getBoundingClientRect().top;
            const topElm = elm.getBoundingClientRect().top;
            if (mainLayout.scrollTop > 30) {
              ScrollUtil.scrollTopElement(
                mainLayout,
                mainLayout.scrollTop + topElm - topMain - 70
              );
            }
          }
          return false;
        }
      }
    }
    props.setControl({
      errors: [],
      form: CREATE_INVOICE_FORM,
      name: `supplier_amount_${customerId}`,
      value: currentAmountDue
    });
    return true;
  };

  const onClickNext = () => {
    if (stepIndex === 4) {
      handleSave(false);
      return;
    }

    if (steps[stepIndex + 1]) {
      setLoading(true); // ok

      if (stepIndex === 0) {
        if (invoiceSelected) {
          if (customerSelected.length === 0) {
            return null;
          }
        }
        setTimeout(() => {
          setLoading(false); // ok
          setStepIndex(2);
          steps[0].nextStep();
        }, 1000);
        return;
      }

      if (stepIndex === 1) {
        handleAddCustomerStep();
      } else {
        if (customerSelected && customerSelected.length === 0) {
          setStepIndex(0);
          history.push(ROUTES.CREATE_INVOICES);
        } else {
          props.selectCustomers(customerSelected);
        }

        if (stepIndex === 2) {
          const currentAmountDue = _get(
            props.getControl(
              `supplier_amount_${customerSelected[0]}`,
              CREATE_INVOICE_FORM
            ),
            "value"
          );
          const calculateMethod = _get(
            props.getControl(`calculate_method`, CREATE_INVOICE_FORM),
            "value"
          );
          if (
            !checkAmountDue(
              currentAmountDue,
              customerSelected[0],
              props.invoiceLineItems,
              props.taxes,
              false,
              calculateMethod
            )
          ) {
            setLoading(false); // ok
            return;
          }
        }

        submitCollection(
          true,
          step.requiredVerificationFields,
          false,
          stepIndex,
          (error: any, data: any) => {
            if (error) {
              const fields = _get(error, "fields", {});
              const form = _get(error, "form", []);
              if (form.length > 0) {
                form.forEach((key: string) => {
                  let elm = document.querySelector(
                    "#form-error-wrapper"
                  ) as HTMLElement;
                  const mainLayout = document.getElementById(
                    INVOICE_CONTENT_ID
                  );
                  if (elm && mainLayout) {
                    const topMain = mainLayout.getBoundingClientRect().top;
                    const topElm = elm.getBoundingClientRect().top;
                    ScrollUtil.scrollTopElement(
                      mainLayout,
                      mainLayout.scrollTop + topElm - topMain - 70
                    );
                  }
                });
                return;
              }
              Object.keys(fields).forEach((key: string) => {
                let elm = document.querySelector(
                  "input[name='" + key + "']"
                ) as HTMLElement;
                if (!elm) {
                  elm = document.querySelector("#" + key) as HTMLElement;
                }
                const mainLayout = document.getElementById(INVOICE_CONTENT_ID);
                if (elm && mainLayout) {
                  const topMain = mainLayout.getBoundingClientRect().top;
                  const topElm = elm.getBoundingClientRect().top;
                  ScrollUtil.scrollTopElement(
                    mainLayout,
                    mainLayout.scrollTop + topElm - topMain - 70
                  );
                }
              });
              return;
            }
            setStepIndex(stepIndex + 1);
            step.nextStep();
          },
          setLoading
        );
      }
    } else {
      setStepIndex(0);
      step.nextStep();
    }
    return;
  };

  const submitCollection = (
    isValidate: boolean = false,
    requiredVerificationFields?: any,
    draft?: boolean,
    step?: number,
    cb?: (err?: any, data?: any) => void,
    loadingFunc?: (loading: boolean) => void
  ) => {
    let currentTypeInvoice = _get(
      props.getControl(`to_create_or_upload_invoice`, CREATE_INVOICE_FORM),
      "value",
      "upload"
    );
    if (currentTypeInvoice === "upload" || currentTypeInvoice === "quick") {
      props.sendPaymentRequest(
        isValidate,
        requiredVerificationFields,
        draft,
        step,
        cb,
        loadingFunc
      );
    } else {
      props.submitCollectionInvoice(
        isValidate,
        requiredVerificationFields,
        draft,
        step,
        cb,
        loadingFunc
      );
    }
  };
  const resetForm = (clearSelectCustomersIds?: boolean) => {
    props.removeForm(CREATE_INVOICE_FORM);
    props.removeForm(INCENTIVES_DETAIL_FORM);
    if (clearSelectCustomersIds) {
      props.selectCustomers([]);
    }
    props.selectAllItemIds([]);
    props.selectInvoice(0);
  };
  const onClickSkip = () => {
    setSkipLoading(true);
    if (customerSelected && customerSelected.length === 0) {
      setStepIndex(0);
      setSkipLoading(false);
      resetForm();
      props.fetchMainCollectedAccount();
      props.toggleModal(FetchModalID.CREATE_INVOICE);
      history.push(ROUTES.ROOT);
      return;
    } else {
      if (stepIndex === 0) {
        props.setControl({
          errors: [],
          form: CREATE_INVOICE_FORM,
          name: `invoice_number_digits_${customerSelected[0]}`,
          value: Number(_get(props.otherSettings, "invoiceNumberTemplate"))
        });
        props.setControl({
          errors: [],
          form: CREATE_INVOICE_FORM,
          name: `payment_description_${customerSelected[0]}`,
          value: `${_get(props.otherSettings, "invoiceNumberPrefix", "")}${_get(
            customerSelected,
            "invoiceNumberTemplate",
            ""
          )}`
        });
      }
      props.selectCustomers(customerSelected);
    }
    submitCollection(
      false,
      null,
      true,
      stepIndex,
      (errors: any, data: any) => {
        setSkipLoading(false);
        if (errors) {
          return;
        }
        resetForm();
        props.fetchMainCollectedAccount();
        props.toggleModal(FetchModalID.CREATE_INVOICE);
        history.push(ROUTES.ROOT);
      },
      setSkipLoading
    );
  };

  const toggleCancelDraftModal = () => {
    props.toggleModal(FetchModalID.CANCEL_DRAFT_INVOICE, invoiceSelected);
  };

  useEffect(() => {
    IPMContext.initSession();
    if (stepIndex > 1 && customerSelected.length === 0) {
      history.push(ROUTES.CREATE_INVOICES);
    }
    props.fetchCustomers(
      0,
      "n",
      true,
      false,
      undefined,
      undefined,
      undefined,
      "desc"
    );
    props.getCustomerList();
    props.fetchTaxes();
    props.fetchItems();
    if (props.checkDraft) {
      props.handleCheckRemindDraftInvoice();
    }
    return () => {
      resetForm(
        [ROUTES.INVOICES, ROUTES.CREATE_INVOICES_STEP1].includes(
          window.location.pathname
        )
      );
      IPMContext.initSession();
    };
  }, []);
  const toggleModal = () => {
    props.toggleModal(FetchModalID.CREATE_INVOICE);
    history.push(ROUTES.INVOICES);
  };
  useEffect(() => {
    setCustomerSelected(props.getSelectedCustomersId);
    props.setControl({
      errors: [],
      form: INVOICE_DETAIL_FORM,
      name: `payment_request_id_${props.getSelectedCustomersId[0]}`,
      value: _get(invoiceSelected, "paymentRequestId", null)
    });
  }, [props.getSelectedCustomersId]);

  useEffect(() => {
    setStepIndex(
      StepRoute[`${history.location.pathname}${history.location.search}`]
    );
    ScrollUtil.scrollTopWindow();
  }, [`${history.location.pathname}${history.location.search}`]);

  if (!step) {
    return null;
  }

  return (
    <>
      <BrowserView style={{ height: "80vh" }}>
        <DesktopModalLayout
          onBack={step.back}
          onSubmit={onClickNext}
          title={step.title}
          onClose={toggleModal}
          hasBorder={false}
          progress={
            <Progress colors={["#937CF2", "#A581E8"]} value={step.percent} />
          }
          btnSubmit={
            <div className={styles.footer}>
              <Button
                id={step.nextBtnId}
                type="primary"
                htmlType="submit"
                onClick={onClickNext}
                loading={
                  isLoading ||
                  (stepIndex === 0 && customerSelected.length === 0)
                }
                autoScrollToFirstError={true}
              >
                {isLoading ? step.nextBtnTitleLoading : step.nextBtnTitle}
              </Button>
              {!(stepIndex === 0 && customerSelected.length === 0) && (
                <Button
                  type="secondary"
                  onClick={onClickSkip}
                  loading={isSkipLoading}
                  className={styles.btnSecondary}
                >
                  {isSkipLoading
                    ? T.transl("SAVING_AND_EXITING_BUTTON")
                    : T.transl("SAVE_AND_EXIT_BUTTON")}
                </Button>
              )}
            </div>
          }
        >
          <div className={styles.wrapperDesktop}>
            <>
              {isDraft && (
                <div className={styles.cancelDraftBtn}>
                  <Image src={IconDelete} onClick={toggleCancelDraftModal} />
                </div>
              )}
            </>
            <div className={styles.content} id={INVOICE_CONTENT_ID}>
              {step.renderContent()}
            </div>
          </div>
        </DesktopModalLayout>
      </BrowserView>
      <MobileView>
        <MainLayout
          header={{
            title: step.title,
            hideMenu: true,
            onBack: step.back,
            onClose: toggleModal,
            center: true,
            progress: (
              <Progress colors={["#937CF2", "#A581E8"]} value={step.percent} />
            ),
            rightIcons: (
              <>
                {isDraft && (
                  <Image
                    src={IconDelete}
                    // className={styles.icon}
                    onClick={toggleCancelDraftModal}
                  />
                )}
              </>
            )
          }}
          footer={
            <div>
              <Button
                id={step.nextBtnId}
                type="primary"
                htmlType="submit"
                onClick={onClickNext}
                loading={
                  isLoading ||
                  (stepIndex === 0 && customerSelected.length === 0)
                }
                autoScrollToFirstError={true}
              >
                {isLoading ? step.nextBtnTitleLoading : step.nextBtnTitle}
              </Button>
              {!(stepIndex === 0 && customerSelected.length === 0) && (
                <Button
                  type="secondary"
                  className={styles.btnSecondary}
                  onClick={onClickSkip}
                  loading={isSkipLoading}
                >
                  {/*{stepIndex !== 0 ? "Save and exit" : "Skip and go to dashboard"}*/}
                  {isSkipLoading
                    ? T.transl("SAVING_AND_EXITING_BUTTON")
                    : T.transl("SAVE_AND_EXIT_BUTTON")}
                </Button>
              )}
            </div>
          }
          usingModal={true}
        >
          {step.renderContent()}
        </MainLayout>
      </MobileView>
    </>
  );
};

export default CreateInvoices;
