import React, { useState } from "react";
import _capitalize from "lodash-es/capitalize";
import { history } from "src/ipm-shared/store";

import { FetchModalID } from "src/ipm-shared/components/GlobalUI/actions";
import PurposeUtil from "src/ipm-shared/Utils/Purpose";
import { PURPOSE } from "src/ipm-shared/store/model/Payee/const";
import { getRecipientPurposeRoute } from "src/bepaid/utils/recipient";

import DesktopModalLayout from "src/bepaid/components/Modal/components/DesktopModalLayout";
import { invokeMessage } from "src/bepaid/components/Message";
import DeleteModal from "src/bepaid/components/Modal/components/DeleteModal";

import { IProps } from "./index";

const View = (props: IProps) => {
  const { payee, deletePayee, toggleModal } = props;
  if (!payee) {
    return null;
  }

  const closeModal = () => {
    toggleModal(FetchModalID.DELETE_RECIPIENT_MODAL);
  };

  const closeAllModal = () => {
    toggleModal(FetchModalID.DELETE_RECIPIENT_MODAL);
    history.push(getRecipientPurposeRoute(payee));
  };

  const [isLoading, setLoading] = useState(false);

  const handleDeletePayee = () => {
    if (!payee) {
      return;
    }
    deletePayee(
      payee.id,
      PurposeUtil.toString(payee.purposeId as number) as PURPOSE,
      true,
      true,
      () => {
        invokeMessage(
          "success",
          `${getRecipientTitleFromPurpose(payee.purposeId, true)} deleted.`
        );
        closeAllModal();
      },
      setLoading
    );
  };

  const recipientTitle = getRecipientTitleFromPurpose(payee.purposeId);

  return (
    <DesktopModalLayout hasBorder={false} onClose={closeModal}>
      <DeleteModal
        title={`Delete this ${recipientTitle}?`}
        isLoading={isLoading}
        description={
          <span>
            This will permanently delete this {recipientTitle}
            <br />
            and it cannot be undone.
          </span>
        }
        yesLabel={`Yes, delete ${recipientTitle}`}
        yesLabelLoading={`Deleting ${recipientTitle}...`}
        noLabel={`No, keep ${recipientTitle}`}
        onYes={handleDeletePayee}
        onNo={closeModal}
      />
    </DesktopModalLayout>
  );
};

const getRecipientTitleFromPurpose = (
  purposeId?: number,
  upperFirstChar?: boolean
) => {
  let str = "";
  switch (purposeId) {
    case 1:
      str = "landlord"; // TODO: use localized string
      break;
    case 2:
      str = "employee";
      break;
    case 3:
      str = "supplier";
      break;
    case 5:
      str = "bank account";
      break;
    default:
      str = "recipient";
      break;
  }

  return upperFirstChar ? _capitalize(str) : str;
};

export default View;
