import React from "react";
import paymentMethodScheduleStyles from "../../PaymentResultModal.module.scss";
import Image from "src/bepaid/components/Image";

import IconPaymentSuccessful from "src/bepaid/assets/images/payments/payment_successful.svg";

import { history } from "src/ipm-shared/store";
import { useMakePaymentUrlParams } from "src/bepaid/pages/Payments/hooks";
import ROUTES from "src/bepaid/routes";
import Button from "src/bepaid/components/Form/controls/Button";
import BtnGoToDashBoard from "../BtnGoToDashBoard";
import { extractDateFromQueryParam, formatDate } from "../utils";

import _get from "lodash-es/get";

const View = (props: any) => {
  // const prevRoute = history?.location.state?.prevRoute;
  const checkoutUrl = String(localStorage.getItem("checkoutUrl"));

  const { paymentType, replaceRouteWithParams } = useMakePaymentUrlParams(
    ROUTES.PAYMENTS_CREATION,
    checkoutUrl
  );
  const isWallex = paymentType === "international";

  const makeAnotherPayment = () => {
    props.resetAllModals();
    history.push(
      replaceRouteWithParams(ROUTES.PAYMENTS_CREATION, {
        newEntity: isWallex ? "country" : "entity",
        newAction: "select"
      })
    );
  };

  const payoutDate =
    props.openedModalIds.length > 1 && _get(props.extraInfo, "payoutDate")
      ? new Date(_get(props.extraInfo, "payoutDate"))
      : extractDateFromQueryParam(props.query, "mf_payout_date");
  const receiptNumber =
    props.query.mf_receipt_number || _get(props.extraInfo, "receiptNumber");

  return (
    <>
      <div className={paymentMethodScheduleStyles.text}>
        <Image width={200} src={IconPaymentSuccessful} />
        <h2 className={paymentMethodScheduleStyles.title}>
          Payment successful!
        </h2>
        <p>
          Your card has been successfully charged. Your payment will be made by{" "}
          {isWallex
            ? `${new Date(payoutDate).toLocaleDateString("en-GB", {
                year: "numeric",
                month: "long",
                day: "2-digit"
              })} (SGT)`
            : formatDate(payoutDate)}
          .
        </p>
        <p>
          Receipt Number: <b>{receiptNumber}</b>
        </p>
      </div>
      <div>
        <Button
          type="primary"
          htmlType="button"
          onClick={makeAnotherPayment}
          wrapperClassName={paymentMethodScheduleStyles.btnMakeAnotherPayment}
        >
          Make another payment
        </Button>
        <BtnGoToDashBoard />
      </div>
    </>
  );
};

export default View;
