import { connect } from "react-redux";
import { RootState } from "src/ipm-shared/store/model/reducers";
import * as actions from "src/ipm-shared/store/model/Card/actions";
import * as selectors from "src/ipm-shared/store/model/Card/selectors";
import * as fetchSelectors from "src/bepaid/store/model/Fetch/selectors";
import { requireProfileComponent } from "src/ipm-shared/HOCs/RequiredProfile";

import Component from "./View";

const mapStateToProps = (state: RootState) => ({
  cardsByCountryId: selectors.getCards(state),
  isFetching: selectors.shouldDisplayLoadingCards(state),
  isPersonalAccount: fetchSelectors.getIsPersonalAccount(state),
  features: fetchSelectors.getFeatures(state)
});

const mapDispatchToProps = {
  editCard: actions.editCard
};

export default requireProfileComponent(
  connect(mapStateToProps, mapDispatchToProps)(Component)
);
