import * as React from "react";
import BaseControl from "./lib/Base";
import { connect } from "react-redux";
import * as selectors from "../selectors";
import * as actions from "../actions";
import { RootState } from "src/ipm-shared/store/model/reducers";
import classNames from "classnames";

export type ISubmitButtonProps = {
  form: string;
  onClick?: (args: any) => any;
  onBlur?: () => void;
  className?: string;
  type?: "button" | "submit";
  tabIndex?: number;
  disabled?: boolean;
  id?: string;
  autoScrollToError?: boolean;
  wheneverSubmitCb?: () => void;
  withServerError?: boolean;
  children?: any;
  renderComponent?: any;
};

const mapStateToProps = (
  state: RootState,
  props: ISubmitButtonProps
): {
  hasConfirmed: boolean;
  hasError: boolean;
  submitButton: { isSubmitting: boolean };
} => ({
  hasConfirmed: selectors.hasConfirmed(state, props.form),
  hasError: selectors.hasError(state, props.form, props.withServerError),
  submitButton: selectors.getSubmitButton(state)
});

const mapDispatchToProps = {
  displayControlErrors: actions.displayControlErrors,
  resetErrors: actions.resetErrors,
  resetFormErrors: actions.resetFormErrors,
  setSubmitButtonState: actions.setSubmitButtonState
};

type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  ISubmitButtonProps;

/**
 * This is one of common controls in the entire app.
 * Use this when you want to show a drop-down list.
 *
 * @base BaseControl
 */
class SubmitButton extends React.Component<IProps> {
  public static defaultProps = { ...BaseControl.defaultProps };
  private button: HTMLButtonElement;
  private alreadyPreventedOnce: boolean;

  public click = () => {
    this.button.click();
  };

  public render() {
    const { renderComponent = null } = this.props;

    const commonProps = {
      id: this.props.id,
      onBlur: this.props.onBlur,
      onClick: this.onClick,
      ref: (ref: HTMLButtonElement) => (this.button = ref)
    };

    if (renderComponent) {
      return renderComponent(commonProps);
    }

    return (
      <button
        tabIndex={this.props.tabIndex}
        type={this.props.type}
        className={classNames("btn", this.props.className, {
          disabled: this.props.disabled
        })}
        {...commonProps}
      >
        {this.props.children}
      </button>
    );
  }

  public componentWillReceiveProps(nextProps: Readonly<IProps>): void {
    if (
      this.props.submitButton.isSubmitting &&
      !nextProps.submitButton.isSubmitting &&
      this.props.autoScrollToError
    ) {
      this.scrollToErrorElement("invalid-feedback");
    }
  }

  private onClick = (e: React.ChangeEvent<any>) => {
    this.props.setSubmitButtonState(true);
    if (this.props.wheneverSubmitCb) {
      this.props.wheneverSubmitCb();
    }

    // Display all control errors to UI
    this.props.displayControlErrors(this.props.form);
    if (this.props.autoScrollToError) {
      this.scrollToErrorElement("invalid-feedback");
      this.scrollToErrorElement("need-2-confirm");
    }

    this.alreadyPreventedOnce = true;

    // Top priority, check confirmation
    if (!this.props.hasConfirmed) {
      console.warn("Need to click confirm");
      e.preventDefault();
      return;
    }

    // Next, check error
    if (!this.props.hasError) {
      // If has no error, go further.
      return this.doSubmit(e);
    } else {
      e.preventDefault();
      return;
    }

    // Has error
    if (this.alreadyPreventedOnce) {
      // On second click, allow them to bypass internal logic check and allow to fly to server
      return this.doSubmit(e);
    } else {
      // On first click, if there's any error, prevent user from submitting to the server
      e.preventDefault();
      this.alreadyPreventedOnce = true;
    }
  };

  private doSubmit = (e: React.ChangeEvent<any>) => {
    if (this.props.onClick && !this.props.disabled) {
      this.alreadyPreventedOnce = false; // reset alreadyPreventedOnce state
      this.props.resetErrors(this.props.form);
      this.props.resetFormErrors(this.props.form);
      this.props.onClick(e);
      return;
    }

    return;
  };

  private scrollToErrorElement = (els: string) => {
    setTimeout(() => {
      const errors = document.getElementsByClassName(els) as any;
      if (errors.length > 0) {
        const offsetTop =
          errors[0].getBoundingClientRect().top + window.scrollY;
        window.scroll(0, offsetTop - 220);
      }
    }, 500); // Temple solution, should check callback after render error.
  };
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  withRef: true
})(SubmitButton);
