/* tslint:disable */
// tslint:disable-object-literal-sort-keys
const t = [
  {
    id: 180,
    name: "Adelaide Bank",
    swift_code: "BENDAU3B",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 197,
    name: "Adelaide Bank (division of Bendigo and Adelaide Bank)",
    swift_code: "none",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 24,
    name: "AFFIN BANK BERHAD",
    swift_code: "PHBMMYKL",
    is_deleted: true,
    country_id: 2,
    display_name: null
  },
  {
    id: 80,
    name: "AFFIN BANK BHD",
    swift_code: "PABB",
    is_deleted: false,
    country_id: 2,
    display_name: null
  },
  {
    id: 91,
    name: "AGROBANK (AGRO)",
    swift_code: "AGRO",
    is_deleted: false,
    country_id: 2,
    display_name: null
  },
  {
    id: 109,
    name: "AGROBANK - SPI (IAGR)",
    swift_code: "IAGR",
    is_deleted: false,
    country_id: 2,
    display_name: null
  },
  {
    id: 25,
    name: "AIA BHD.",
    swift_code: "AIACMYKL",
    is_deleted: true,
    country_id: 2,
    display_name: null
  },
  {
    id: 27,
    name: "ALKHAIR INTERNATIONAL ISLAMIC BANK BERHAD",
    swift_code: "UIIBMYKL",
    is_deleted: true,
    country_id: 2,
    display_name: null
  },
  {
    id: 28,
    name: "ALLIANCE BANK MALAYSIA BERHAD",
    swift_code: "MFBBMYKL",
    is_deleted: true,
    country_id: 2,
    display_name: null
  },
  {
    id: 82,
    name: "ALLIANCE BANK MALAYSIA BHD",
    swift_code: "ALBB",
    is_deleted: false,
    country_id: 2,
    display_name: null
  },
  {
    id: 81,
    name: "AL RAJHI BANK",
    swift_code: "ARB",
    is_deleted: false,
    country_id: 2,
    display_name: null
  },
  {
    id: 26,
    name: "AL RAJHI BANKING AND INVESTMENT CORPORATION (MALAYSIA) BHD",
    swift_code: "RJHIMYKL",
    is_deleted: true,
    country_id: 2,
    display_name: null
  },
  {
    id: 83,
    name: "AMBANK BERHAD",
    swift_code: "AMBB",
    is_deleted: false,
    country_id: 2,
    display_name: null
  },
  {
    id: 29,
    name: "AMBANK (M) BERHAD",
    swift_code: "ARBKMYKL",
    is_deleted: true,
    country_id: 2,
    display_name: null
  },
  {
    id: 30,
    name: "AMINVESTMENT BANK BERHAD (FORMERLY KNOWN AS AMMERCHANT BANK BERHAD)",
    swift_code: "AMMBMYKL",
    is_deleted: true,
    country_id: 2,
    display_name: null
  },
  {
    id: 31,
    name: "AMISLAMIC BANK BERHAD",
    swift_code: "AISLMYKL",
    is_deleted: true,
    country_id: 2,
    display_name: null
  },
  {
    id: 156,
    name: "AMP Bank",
    swift_code: "AMPBAU2SRET",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 251,
    name: "ANEXT BANK PTE. LTD.",
    swift_code: "ANTPSGSGXXX",
    is_deleted: false,
    country_id: 1,
    display_name: "ANEXT BANK PTE. LTD. - ANTPSGSG"
  },
  {
    id: 3,
    name: "ANZ",
    swift_code: "ANZBSGSXXXX",
    is_deleted: true,
    country_id: 1,
    display_name: null
  },
  {
    id: 245,
    name: "ANZ Bank",
    swift_code: "ANZBSGSXXXX",
    is_deleted: false,
    country_id: 1,
    display_name: "ANZ BANK - ANZBSGSX"
  },
  {
    id: 231,
    name: "Arab Bank Australia",
    swift_code: "none",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 32,
    name: "ASIAN FINANCE BANK BERHAD",
    swift_code: "AFBQMYKL",
    is_deleted: true,
    country_id: 2,
    display_name: null
  },
  {
    id: 151,
    name: "Australia and New Zealand Banking Group",
    swift_code: "ANZBAU3M",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 250,
    name: "Australia and New Zealand Banking Group Limited",
    swift_code: "ANZBHKHXXXX",
    is_deleted: false,
    country_id: 3,
    display_name:
      "Australia and New Zealand Banking Group Limited (152) - ANZBHKHX"
  },
  {
    id: 205,
    name: "Australian Military Bank",
    swift_code: "none",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 196,
    name: "Australian Settlements",
    swift_code: "none",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 229,
    name: "Australia Post",
    swift_code: "none",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 206,
    name: "Auswide Bank",
    swift_code: "none",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 195,
    name: "Bananacoast Community Credit Union",
    swift_code: "none",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 84,
    name: "BANGKOK BANK BERHAD",
    swift_code: "BKKB",
    is_deleted: false,
    country_id: 2,
    display_name: null
  },
  {
    id: 33,
    name: "BANGKOK BANK BERHAD",
    swift_code: "BKKBMYKL",
    is_deleted: true,
    country_id: 2,
    display_name: null
  },
  {
    id: 34,
    name: "BANK AL HABIB LIMITED",
    swift_code: "BAHLMY2A",
    is_deleted: true,
    country_id: 2,
    display_name: null
  },
  {
    id: 165,
    name: "Bank Australia",
    swift_code: "none",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 85,
    name: "BANK ISLAM BHD",
    swift_code: "BIMB",
    is_deleted: false,
    country_id: 2,
    display_name: null
  },
  {
    id: 86,
    name: "BANK KERJASAMA RAKYAT MALAYSIA",
    swift_code: "BKRM",
    is_deleted: false,
    country_id: 2,
    display_name: null
  },
  {
    id: 87,
    name: "BANK MUAMALAT MALAYSIA BERHAD",
    swift_code: "BMMB",
    is_deleted: false,
    country_id: 2,
    display_name: null
  },
  {
    id: 23,
    name: "BANK NEGARA MALAYSIA",
    swift_code: "BNMAMY2K",
    is_deleted: true,
    country_id: 2,
    display_name: null
  },
  {
    id: 21,
    name: "Bank of America",
    swift_code: "BOFASG2XXXX",
    is_deleted: true,
    country_id: 1,
    display_name: null
  },
  {
    id: 88,
    name: "BANK OF AMERICA BHD",
    swift_code: "BOFA",
    is_deleted: false,
    country_id: 2,
    display_name: null
  },
  {
    id: 254,
    name: "Bank of America, National Association",
    swift_code: "BOFAHKHXXXX",
    is_deleted: false,
    country_id: 3,
    display_name: "Bank of America, National Association (055) - BOFAHKHXXXX"
  },
  {
    id: 6,
    name: "Bank of China",
    swift_code: "BKCHSGSGXXX",
    is_deleted: false,
    country_id: 1,
    display_name: "BANK OF CHINA - BKCHSGSG"
  },
  {
    id: 166,
    name: "Bank of China (014)",
    swift_code: "BKCHHKHH",
    is_deleted: false,
    country_id: 3,
    display_name: "Bank of China (014) - BKCHHKHH"
  },
  {
    id: 167,
    name: "Bank of China (019)",
    swift_code: "BKCHHKHH",
    is_deleted: false,
    country_id: 3,
    display_name: "Bank of China (019) - BKCHHKHH"
  },
  {
    id: 168,
    name: "Bank of China (026)",
    swift_code: "BKCHHKHH",
    is_deleted: false,
    country_id: 3,
    display_name: "Bank of China (026) - BKCHHKHH"
  },
  {
    id: 169,
    name: "Bank of China (030)",
    swift_code: "BKCHHKHH",
    is_deleted: false,
    country_id: 3,
    display_name: "Bank of China (030) - BKCHHKHH"
  },
  {
    id: 173,
    name: "Bank of China (031)",
    swift_code: "BKCHHKHH",
    is_deleted: false,
    country_id: 3,
    display_name: "Bank of China (031) - BKCHHKHH"
  },
  {
    id: 170,
    name: "Bank of China (036)",
    swift_code: "BKCHHKHH",
    is_deleted: false,
    country_id: 3,
    display_name: "Bank of China (036) - BKCHHKHH"
  },
  {
    id: 171,
    name: "Bank of China (064)",
    swift_code: "BKCHHKHH",
    is_deleted: false,
    country_id: 3,
    display_name: "Bank of China (064) - BKCHHKHH"
  },
  {
    id: 172,
    name: "Bank of China (070)",
    swift_code: "BKCHHKHH",
    is_deleted: false,
    country_id: 3,
    display_name: "Bank of China (070) - BKCHHKHH"
  },
  {
    id: 120,
    name: "Bank of China (Hong Kong)",
    swift_code: "BKCHHKHHXXX",
    is_deleted: false,
    country_id: 3,
    display_name: "Bank of China (012) - BKCHHKHH"
  },
  {
    id: 145,
    name: "Bank of China (Hong Kong)",
    swift_code: "BKCHHKHHXXX",
    is_deleted: false,
    country_id: 3,
    display_name: "Bank of China (033) - BKCHHKHH"
  },
  {
    id: 89,
    name: "BANK OF CHINA (M) BHD",
    swift_code: "BOCM",
    is_deleted: false,
    country_id: 2,
    display_name: null
  },
  {
    id: 222,
    name: "Bank of Communications",
    swift_code: "none",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 122,
    name: "Bank of Communications Co., Ltd.",
    swift_code: "COMMHKHHXXX",
    is_deleted: false,
    country_id: 3,
    display_name: "Bank of Communications (027) - COMMHKHH"
  },
  {
    id: 121,
    name: "Bank of Communications (Hong Kong) Limited",
    swift_code: "COMMHKHKXXX",
    is_deleted: false,
    country_id: 3,
    display_name: "Bank of Communications, Hong Kong (382) - COMMHKHK"
  },
  {
    id: 214,
    name: "Bank of Heritage Isle",
    swift_code: "none",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 153,
    name: "Bank of Queensland",
    swift_code: "QBANAU4B",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 237,
    name: "Bank of Sydney",
    swift_code: "none",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 92,
    name: "BANK SIMPANAN NASIONAL",
    swift_code: "BSNB",
    is_deleted: false,
    country_id: 2,
    display_name: null
  },
  {
    id: 157,
    name: "Bankwest (division of Commonwealth Bank)",
    swift_code: "BKWAAU6P",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 199,
    name: "B&E",
    swift_code: "none",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 155,
    name: "Bendigo Bank",
    swift_code: "BENDAU3B",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 189,
    name: "Beyond Bank",
    swift_code: "none",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 225,
    name: "Big Sky Building Society",
    swift_code: "none",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 142,
    name: "BNP Paribas",
    swift_code: "BNPASGSGXXX",
    is_deleted: false,
    country_id: 1,
    display_name: "BNP PARIBAS - BNPASGSG"
  },
  {
    id: 35,
    name: "BNP PARIBAS",
    swift_code: "BNPAMYKA",
    is_deleted: true,
    country_id: 2,
    display_name: null
  },
  {
    id: 93,
    name: "BNP PARIBAS MALAYSIA BERHAD",
    swift_code: "BNPM",
    is_deleted: false,
    country_id: 2,
    display_name: null
  },
  {
    id: 185,
    name: "BNP Paribas Securities",
    swift_code: "none",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 240,
    name: "BOQ Specialist",
    swift_code: "none",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 36,
    name: "CAGAMAS BERHAD",
    swift_code: "CAGAMYKL",
    is_deleted: true,
    country_id: 2,
    display_name: null
  },
  {
    id: 218,
    name: "Capricornian",
    swift_code: "none",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 124,
    name: "China Citi Bank International Limited",
    swift_code: "KWHKHKHHXXX",
    is_deleted: false,
    country_id: 3,
    display_name: "China Citic Bank International (018) - KWHKHKHH"
  },
  {
    id: 228,
    name: "China Construction Bank",
    swift_code: "none",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 125,
    name: "China Construction Bank (Asia) Corporation Limited",
    swift_code: "CCBQHKAXXXX",
    is_deleted: false,
    country_id: 3,
    display_name: "China Construction (009) - CCBQHKA"
  },
  {
    id: 37,
    name: "CHINA CONSTRUCTION BANK (MALAYSIA) BERHAD",
    swift_code: "PCBCMYKL",
    is_deleted: true,
    country_id: 2,
    display_name: null
  },
  {
    id: 95,
    name: "CHINA CONSTRUCTION BANK MALAYSIA BERHAD",
    swift_code: "CCBM",
    is_deleted: false,
    country_id: 2,
    display_name: null
  },
  {
    id: 126,
    name: "Chiyu Banking Corporation Limited",
    swift_code: "CIYUHKHHXXX",
    is_deleted: false,
    country_id: 3,
    display_name: "Chiyu Banking (039) - CIYUHKHH"
  },
  {
    id: 127,
    name: "Chong Hing Bank Limited",
    swift_code: "LCHBHKHHXXX",
    is_deleted: false,
    country_id: 3,
    display_name: "Chong Hing (041) - LCHBHKHH"
  },
  {
    id: 12,
    name: "CIMB",
    swift_code: "CIBBSGSGXXX",
    is_deleted: false,
    country_id: 1,
    display_name: "CIMB - CIBBSGSG"
  },
  {
    id: 94,
    name: "CIMB BANK BHD",
    swift_code: "CIMB",
    is_deleted: false,
    country_id: 2,
    display_name: null
  },
  {
    id: 38,
    name: "CIMB BANK (L) LIMITED",
    swift_code: "CIBBMYKA",
    is_deleted: true,
    country_id: 2,
    display_name: null
  },
  {
    id: 39,
    name: "CIMB-PRINCIPAL ASSET MANAGEMENT BERHAD",
    swift_code: "CANHMYKL",
    is_deleted: true,
    country_id: 2,
    display_name: null
  },
  {
    id: 184,
    name: "Citibank ",
    swift_code: "none",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 40,
    name: "CITIBANK BERHAD",
    swift_code: "CITIMYKLPEN",
    is_deleted: true,
    country_id: 2,
    display_name: null
  },
  {
    id: 96,
    name: "CITIBANK BHD",
    swift_code: "CITI",
    is_deleted: false,
    country_id: 2,
    display_name: null
  },
  {
    id: 128,
    name: "Citibank (Hong Kong) Limited",
    swift_code: "CITIHKAXXXX",
    is_deleted: false,
    country_id: 3,
    display_name: "Citibank, Hong Kong (250) - CITIHKA"
  },
  {
    id: 190,
    name: "Citibank NA",
    swift_code: "none",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 4,
    name: "Citibank N.A.",
    swift_code: "CITISGSGXXX",
    is_deleted: false,
    country_id: 1,
    display_name: "CITIBANK N.A. - CITISGSG"
  },
  {
    id: 129,
    name: "CITIBANK, N.A",
    swift_code: "CITIHKHXXXX",
    is_deleted: false,
    country_id: 3,
    display_name: "Citibank, N.A. (006) - CITIHKH"
  },
  {
    id: 117,
    name: "Citibank Singapore Ltd",
    swift_code: "CITISGSLXXX",
    is_deleted: false,
    country_id: 1,
    display_name: "CITIBANK SINGAPORE LTD - CITISGSL"
  },
  {
    id: 149,
    name: "Commonwealth Bank of Australia",
    swift_code: "CTBAAU2S",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 192,
    name: "Community First Credit Union",
    swift_code: "none",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 219,
    name: "Credit Union Australia",
    swift_code: "none",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 159,
    name: "Cuscal",
    swift_code: "CUSCAU2S",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 130,
    name: "Dah Sing Bank Limited",
    swift_code: "DSBAHKHHXXX",
    is_deleted: false,
    country_id: 3,
    display_name: "Dah Sing (040) - DSBAHKHH"
  },
  {
    id: 1,
    name: "DBS",
    swift_code: "DBSSSGSGXXX",
    is_deleted: false,
    country_id: 1,
    display_name: "DBS - DBSSSGSG"
  },
  {
    id: 131,
    name: "DBS Bank (Hong Kong) Limited",
    swift_code: "DHBKHKHHXXX",
    is_deleted: false,
    country_id: 3,
    display_name: "DBS, Hong Kong (016) - DHBKHKHH"
  },
  {
    id: 132,
    name: "DBS BANK LTD.",
    swift_code: "DBSSHKHHXXX",
    is_deleted: false,
    country_id: 3,
    display_name: "DBS (185) - DBSSHKHH"
  },
  {
    id: 41,
    name: "DBS BANK LTD, LABUAN BRANCH",
    swift_code: "DBSSMY2A",
    is_deleted: true,
    country_id: 2,
    display_name: null
  },
  {
    id: 226,
    name: "Defence Bank",
    swift_code: "none",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 236,
    name: "Delphi Bank (division of Bendigo and Adelaide Bank)",
    swift_code: "none",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 20,
    name: "Deutsche Bank",
    swift_code: "DEUTSGSGXXX",
    is_deleted: false,
    country_id: 1,
    display_name: "DEUTSCHE BANK - DEUTSGSG"
  },
  {
    id: 42,
    name: "DEUTSCHE BANK (MALAYSIA) BERHAD",
    swift_code: "DEUTMYKL",
    is_deleted: true,
    country_id: 2,
    display_name: null
  },
  {
    id: 97,
    name: "DEUTSCHE BANK (M) BHD",
    swift_code: "DEUM",
    is_deleted: false,
    country_id: 2,
    display_name: null
  },
  {
    id: 198,
    name: "Endeavour Mutual Bank",
    swift_code: "none",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 43,
    name: "EXPORT-IMPORT BANK OF MALAYSIA BERHAD",
    swift_code: "EXMBMYKL",
    is_deleted: true,
    country_id: 2,
    display_name: null
  },
  {
    id: 16,
    name: "Far Eastern Bank",
    swift_code: "UOVBSGSGXXX",
    is_deleted: true,
    country_id: 1,
    display_name: null
  },
  {
    id: 248,
    name: "FAZZ (Xfers Pte Ltd)",
    swift_code: "XFEPSGSGXXX",
    is_deleted: false,
    country_id: 1,
    display_name: "FAZZ - XFEPSGSG"
  },
  {
    id: 44,
    name: "FELDA GLOBAL VENTURES CAPITAL SDN. BHD.",
    swift_code: "FGVCMYKL",
    is_deleted: true,
    country_id: 2,
    display_name: null
  },
  {
    id: 45,
    name: "FIDELITY ASIA BANK LTD",
    swift_code: "FABEMY22",
    is_deleted: true,
    country_id: 2,
    display_name: null
  },
  {
    id: 46,
    name: "FIRST EAST EXPORT BANK",
    swift_code: "FEEBMYKA",
    is_deleted: true,
    country_id: 2,
    display_name: null
  },
  {
    id: 143,
    name: "FUBON BANK (HONG KONG) LIMITED (128)",
    swift_code: "IBALHKHHXXX",
    is_deleted: false,
    country_id: 3,
    display_name: "FUBON BANK (HONG KONG) LIMITED (128) - IBALHKHHXXX"
  },
  {
    id: 211,
    name: "Gateway Bank",
    swift_code: "none",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 210,
    name: "G&C Mutual Bank",
    swift_code: "none",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 186,
    name: "Greater Bank",
    swift_code: "none",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 133,
    name: "Hang Seng Bank, Limited",
    swift_code: "HASEHKHHXXX",
    is_deleted: false,
    country_id: 3,
    display_name: "Hang Seng (024) - HASEHKHH"
  },
  {
    id: 201,
    name: "Heritage Bank",
    swift_code: "none",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 114,
    name: "HL Bank",
    swift_code: "HLBBSGSGXXX",
    is_deleted: false,
    country_id: 1,
    display_name: "HL BANK - HLBBSGSG"
  },
  {
    id: 212,
    name: "Holiday Coast Credit Union",
    swift_code: "none",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 202,
    name: "Home Building Society (division of Bank of Queensland)",
    swift_code: "none",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 98,
    name: "HONGKONG BANK MALAYSIA BHD-HSBC",
    swift_code: "HSBC",
    is_deleted: false,
    country_id: 2,
    display_name: null
  },
  {
    id: 47,
    name: "HONG LEONG BANK BERHAD",
    swift_code: "HLBBMYKL",
    is_deleted: true,
    country_id: 2,
    display_name: null
  },
  {
    id: 111,
    name: "HONG LEONG BANK BHD",
    swift_code: "HLBB",
    is_deleted: false,
    country_id: 2,
    display_name: null
  },
  {
    id: 162,
    name: "HSBC",
    swift_code: "HKBAAU2S",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 242,
    name: "HSBC Bank Australia",
    swift_code: "none",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 5,
    name: "HSBC Bank Singapore Ltd ",
    swift_code: "HSBCSGS2XXX",
    is_deleted: false,
    country_id: 1,
    display_name: "HSBC BANK SINGAPORE LTD - HSBCSGS2"
  },
  {
    id: 22,
    name: "HSBC Corporation Ltd",
    swift_code: "HSBCSGSGXXX",
    is_deleted: false,
    country_id: 1,
    display_name: "HSBC CORPORATION LTD - HSBCSGSG"
  },
  {
    id: 48,
    name: "HSBC (MALAYSIA) TRUSTEE BERHAD",
    swift_code: "HSTMMYKL",
    is_deleted: true,
    country_id: 2,
    display_name: null
  },
  {
    id: 203,
    name: "Hume Bank",
    swift_code: "none",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 49,
    name: "IBH INVESTMENT BANK LIMITED",
    swift_code: "IBHIMY2L",
    is_deleted: true,
    country_id: 2,
    display_name: null
  },
  {
    id: 9,
    name: "ICICI Bank",
    swift_code: "ICICSGSGXXX",
    is_deleted: true,
    country_id: 1,
    display_name: null
  },
  {
    id: 204,
    name: "IMB",
    swift_code: "none",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 99,
    name: "IND&COMM BANK OF CHINA (M) BHD",
    swift_code: "ICBC",
    is_deleted: false,
    country_id: 2,
    display_name: null
  },
  {
    id: 50,
    name: "INDIA INTERNATIONAL BANK (MALAYSIA) BERHAD",
    swift_code: "IIMBMYKL",
    is_deleted: true,
    country_id: 2,
    display_name: null
  },
  {
    id: 15,
    name: "Indian Overseas Bank",
    swift_code: "IOBASGSGXXX",
    is_deleted: true,
    country_id: 1,
    display_name: null
  },
  {
    id: 135,
    name: "Industrial and Commercial Bank of China (Asia) Limited",
    swift_code: "UBHKHKHHXXX",
    is_deleted: false,
    country_id: 3,
    display_name: "Industrial and Commercial Bank of China (072) - UBHKHKHH"
  },
  {
    id: 51,
    name: "INDUSTRIAL AND COMMERCIAL BANK OF CHINA (MALAYSIA) BERHAD.",
    swift_code: "ICBKMYKL",
    is_deleted: true,
    country_id: 2,
    display_name: null
  },
  {
    id: 146,
    name: "Industrial & Commercial Bank",
    swift_code: "ICBKSGSGXXX",
    is_deleted: false,
    country_id: 1,
    display_name: "INDUSTRIAL & COMMERCIAL BANK - ICBKSGSG"
  },
  {
    id: 223,
    name: "Industrial & Commercial Bank of China",
    swift_code: "none",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 163,
    name: "ING",
    swift_code: "INGBAU2S",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 235,
    name: "ING Bank",
    swift_code: "none",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 183,
    name: "JP Morgan Chase Bank",
    swift_code: "none",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 52,
    name: "J.P.MORGAN CHASE BANK BERHAD",
    swift_code: "CHASMYKX",
    is_deleted: true,
    country_id: 2,
    display_name: null
  },
  {
    id: 100,
    name: "J.P. MORGAN CHASE BANK BHD",
    swift_code: "JPMC",
    is_deleted: false,
    country_id: 2,
    display_name: null
  },
  {
    id: 53,
    name: "JPMORGAN CHASE BANK, N.A., LABUAN BRANCH",
    swift_code: "CHASMY2A",
    is_deleted: true,
    country_id: 2,
    display_name: null
  },
  {
    id: 246,
    name: "JP Morgan Chase NA HK Branch",
    swift_code: "CHASHKHHXXX",
    is_deleted: false,
    country_id: 3,
    display_name: "JP Morgan Chase NA HK Branch (007) - CHASHKHHXXX"
  },
  {
    id: 54,
    name: "KAF INVESTMENT BANK BERHAD",
    swift_code: "KAFBMYKL",
    is_deleted: true,
    country_id: 2,
    display_name: null
  },
  {
    id: 55,
    name: "KENANGA INVESTMENT BANK BERHAD",
    swift_code: "ECMLMYKL",
    is_deleted: true,
    country_id: 2,
    display_name: null
  },
  {
    id: 101,
    name: "KUWAIT FINANCE HOUSE",
    swift_code: "KFHB",
    is_deleted: false,
    country_id: 2,
    display_name: null
  },
  {
    id: 56,
    name: "KUWAIT FINANCE HOUSE (MALAYSIA) BERHAD",
    swift_code: "KFHOMYKL",
    is_deleted: true,
    country_id: 2,
    display_name: null
  },
  {
    id: 244,
    name: "LIVI BANK LIMITED",
    swift_code: "LIVIHKHHXXX",
    is_deleted: false,
    country_id: 3,
    display_name: "LIVI BANK LIMITED (388) - LIVIHKHH"
  },
  {
    id: 154,
    name: "Macquarie Bank",
    swift_code: "MACQAU2S",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 207,
    name: "Maitland Mutual Building Society",
    swift_code: "none",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 8,
    name: "Malayan Banking Berhad",
    swift_code: "MBBESGSGXXX",
    is_deleted: false,
    country_id: 1,
    display_name: "MALAYAN BANKING BERHAD - MBBESGSG"
  },
  {
    id: 57,
    name: "MALAYAN BANKING BERHAD (MAYBANK)",
    swift_code: "MBBEMYKLBWC",
    is_deleted: true,
    country_id: 2,
    display_name: null
  },
  {
    id: 253,
    name: "MARIBANK SINGAPORE PRIVATE LIMITED",
    swift_code: "SSPISGSGXXX",
    is_deleted: false,
    country_id: 1,
    display_name: "MARIBANK SINGAPORE PRIVATE LIMITED - SSPISGSG"
  },
  {
    id: 58,
    name: "MAX MONEY SDN BHD",
    swift_code: "MMSBMYKL",
    is_deleted: true,
    country_id: 2,
    display_name: null
  },
  {
    id: 102,
    name: "Maybank Berhad",
    swift_code: "MBBB",
    is_deleted: false,
    country_id: 2,
    display_name: null
  },
  {
    id: 59,
    name: "MAYBANK INTERNATIONAL (L) LTD.",
    swift_code: "MBBEMYKA",
    is_deleted: true,
    country_id: 2,
    display_name: null
  },
  {
    id: 115,
    name: "Maybank Singapore Limited",
    swift_code: "MBBESGS2XXX",
    is_deleted: false,
    country_id: 1,
    display_name: "MAYBANK SINGAPORE LIMITED – MBBESGS2"
  },
  {
    id: 112,
    name: "MBSB BANK BERHAD",
    swift_code: "AFB",
    is_deleted: false,
    country_id: 2,
    display_name: null
  },
  {
    id: 233,
    name: "Mega International Commercial Bank",
    swift_code: "none",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 60,
    name: "MEGA INTERNATIONAL COMMERCIAL BANK CO., LTD. LABUAN BRANCH",
    swift_code: "ICBCMY2L",
    is_deleted: true,
    country_id: 2,
    display_name: null
  },
  {
    id: 160,
    name: "Members Equity (ME) Bank",
    swift_code: "none",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 61,
    name: "MERCEDES-BENZ MALAYSIA SDN. BHD.",
    swift_code: "DABEMYKL",
    is_deleted: true,
    country_id: 2,
    display_name: null
  },
  {
    id: 62,
    name: "MIDDLE EAST INVESTMENT BANK LTD",
    swift_code: "MEIBMYKA",
    is_deleted: true,
    country_id: 2,
    display_name: null
  },
  {
    id: 17,
    name: "Mizuho Bank",
    swift_code: "MHCBSGSGXXX",
    is_deleted: false,
    country_id: 1,
    display_name: "MIZUHO BANK - MHCBSGSG"
  },
  {
    id: 63,
    name: "MIZUHO BANK (MALAYSIA) BERHAD",
    swift_code: "MHCBMYKA",
    is_deleted: true,
    country_id: 2,
    display_name: null
  },
  {
    id: 103,
    name: "MIZUHO BANK (M) BERHAD",
    swift_code: "MHCB",
    is_deleted: false,
    country_id: 2,
    display_name: null
  },
  {
    id: 215,
    name: "MOVE",
    swift_code: "none",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 187,
    name: "MUFG Bank",
    swift_code: "none",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 141,
    name: "MUFG BANK - BOTKSGSX",
    swift_code: "BOTKSGSXXXX",
    is_deleted: false,
    country_id: 1,
    display_name: "MUFG BANK – BOTKSGSX"
  },
  {
    id: 90,
    name: "MUFG BANK MALAYSIA BERHAD (BTMU)",
    swift_code: "BTMU",
    is_deleted: false,
    country_id: 2,
    display_name: null
  },
  {
    id: 110,
    name: "MUFG BANK MALAYSIA BERHAD - SPI (IBOT)",
    swift_code: "IBOT",
    is_deleted: false,
    country_id: 2,
    display_name: null
  },
  {
    id: 188,
    name: "#N/A",
    swift_code: "none",
    is_deleted: true,
    country_id: 16,
    display_name: null
  },
  {
    id: 136,
    name: "Nanyang Commercial Bank Limited",
    swift_code: "NYCBHKHHXXX",
    is_deleted: false,
    country_id: 3,
    display_name: "Nanyang Commercial (043) - NYCBHKHH"
  },
  {
    id: 152,
    name: "National Australia Bank",
    swift_code: "NATAAU3303M",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 64,
    name: "NATIONAL BANK OF ABU DHABI",
    swift_code: "NBADMYKL",
    is_deleted: true,
    country_id: 2,
    display_name: null
  },
  {
    id: 179,
    name: "Newcastle Permanent Building Society",
    swift_code: "NPBOAU21",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 65,
    name: "NOMURA ASSET MANAGEMENT MALAYSIA SDN BHD",
    swift_code: "NOCMMYKL",
    is_deleted: true,
    country_id: 2,
    display_name: null
  },
  {
    id: 2,
    name: "OCBC",
    swift_code: "OCBCSGSGXXX",
    is_deleted: false,
    country_id: 1,
    display_name: "OCBC - OCBCSGSG"
  },
  {
    id: 66,
    name: "OCBC AL-AMIN BANK BERHAD",
    swift_code: "OABBMYKL",
    is_deleted: true,
    country_id: 2,
    display_name: null
  },
  {
    id: 191,
    name: "OCBC Bank",
    swift_code: "none",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 104,
    name: "OCBC BANK (M) BHD",
    swift_code: "OCBC",
    is_deleted: false,
    country_id: 2,
    display_name: null
  },
  {
    id: 137,
    name: "OCBC Wing Hang Bank Limited",
    swift_code: "WIHBHKHHXXX",
    is_deleted: false,
    country_id: 3,
    display_name: "OCBC Wing Hang (035) - WIHBHKHH"
  },
  {
    id: 67,
    name: "OVERSEA-CHINESE BANKING CORP. LTD, LABUAN BRANCH",
    swift_code: "OCBCMYKA",
    is_deleted: true,
    country_id: 2,
    display_name: null
  },
  {
    id: 68,
    name: "PETROLIAM NASIONAL BERHAD",
    swift_code: "PTROMYKL",
    is_deleted: true,
    country_id: 2,
    display_name: null
  },
  {
    id: 69,
    name: "PG ASIA INVESTMENT BANK LTD",
    swift_code: "AINEMY22",
    is_deleted: true,
    country_id: 2,
    display_name: null
  },
  {
    id: 14,
    name: "Philippine National Bank",
    swift_code: "PNBMSGSGXXX",
    is_deleted: true,
    country_id: 1,
    display_name: null
  },
  {
    id: 208,
    name: "Pioneer Permanent Building Society (division of Bank of Queensland)",
    swift_code: "none",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 220,
    name: "Police Bank",
    swift_code: "none",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 217,
    name: "Police & Nurse",
    swift_code: "none",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 19,
    name: "POSB",
    swift_code: "DBSSSGSGXXX",
    is_deleted: false,
    country_id: 1,
    display_name: "POSB - DBSSSGSG"
  },
  {
    id: 70,
    name: "POS MALAYSIA BERHAD",
    swift_code: "POSMMYKL",
    is_deleted: true,
    country_id: 2,
    display_name: null
  },
  {
    id: 105,
    name: "PUBLIC BANK BHD",
    swift_code: "PBBB",
    is_deleted: false,
    country_id: 2,
    display_name: null
  },
  {
    id: 148,
    name: "Public Bank (Hong Kong) Limited",
    swift_code: "CBHKHKHH",
    is_deleted: false,
    country_id: 3,
    display_name: "Public Bank (Hong Kong) Limited (028) - CBHKHKHH"
  },
  {
    id: 71,
    name: "PUBLIC BANK (L) LTD",
    swift_code: "PBLLMYKA",
    is_deleted: true,
    country_id: 2,
    display_name: null
  },
  {
    id: 247,
    name: "PUBLIC ISLAMIC BANK BERHARD",
    swift_code: "PIBEMYK1XXX",
    is_deleted: false,
    country_id: 2,
    display_name: null
  },
  {
    id: 164,
    name: "Qudos Banks",
    swift_code: "none",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 161,
    name: "Queensland Country Bank",
    swift_code: "none",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 181,
    name: "Rabobank",
    swift_code: "none",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 193,
    name: "RACQ Bank",
    swift_code: "none",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 234,
    name: "Regional Australia Bank",
    swift_code: "none",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 178,
    name: "Reserve Bank of Australia",
    swift_code: "RSBKAU2SXXX",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 13,
    name: "RHB",
    swift_code: "RHBBSGSGXXX",
    is_deleted: false,
    country_id: 1,
    display_name: "RHB - RHBBSGSG"
  },
  {
    id: 106,
    name: "RHB BANK BHD",
    swift_code: "RHBB",
    is_deleted: false,
    country_id: 2,
    display_name: null
  },
  {
    id: 72,
    name: "RHB BANK (L) LTD",
    swift_code: "RHBBMYKA",
    is_deleted: true,
    country_id: 2,
    display_name: null
  },
  {
    id: 73,
    name: "RHB INVESTMENT BANK BERHAD",
    swift_code: "OSKIMYKL",
    is_deleted: true,
    country_id: 2,
    display_name: null
  },
  {
    id: 138,
    name: "Shanghai Commercial Bank Limited",
    swift_code: "SCBKHKHHXXX",
    is_deleted: false,
    country_id: 3,
    display_name: "Shanghai Commercial (025) - SCBKHKHH"
  },
  {
    id: 213,
    name: "Southern Cross Credit Union",
    swift_code: "none",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 10,
    name: "Standard Chartered Bank",
    swift_code: "SCBLSGSGXXX",
    is_deleted: true,
    country_id: 1,
    display_name: null
  },
  {
    id: 107,
    name: "STANDARD CHARTERED BANK BHD",
    swift_code: "SCBB",
    is_deleted: false,
    country_id: 2,
    display_name: null
  },
  {
    id: 139,
    name: "Standard Chartered Bank (Hong Kong) Limited",
    swift_code: "SCBLHKHHXXX",
    is_deleted: false,
    country_id: 3,
    display_name: "Standard Chartered (003) - SCBLHKHH"
  },
  {
    id: 74,
    name: "STANDARD CHARTERED BANK MALAYSIA BERHAD",
    swift_code: "SCBLMYKX",
    is_deleted: true,
    country_id: 2,
    display_name: null
  },
  {
    id: 119,
    name: "Standard Chartered Bank (Singapore) Ltd",
    swift_code: "SCBLSG22XXX",
    is_deleted: false,
    country_id: 1,
    display_name: "STANDARD CHARTERED BANK (SINGAPORE) LTD - SCBLSG22"
  },
  {
    id: 11,
    name: "State Bank of India",
    swift_code: "SBINSGSGXXX",
    is_deleted: true,
    country_id: 1,
    display_name: null
  },
  {
    id: 249,
    name: "STATE BANK OF INDIA",
    swift_code: "SBINSGSGXXX",
    is_deleted: false,
    country_id: 1,
    display_name: "STATE BANK OF INDIA - SBINSGSG"
  },
  {
    id: 230,
    name: "State Street Bank & Trust Company",
    swift_code: "none",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 108,
    name: "SUMITOMO MITSUI BANK",
    swift_code: "BHD",
    is_deleted: false,
    country_id: 2,
    display_name: null
  },
  {
    id: 116,
    name: "Sumitomo Mitsui Banking Corporation",
    swift_code: "SMBCSGSGXXX",
    is_deleted: false,
    country_id: 1,
    display_name: "SUMITOMO MITSUI BANKING CORPORATION - SMBCSGSG"
  },
  {
    id: 75,
    name: "SUMITOMO MITSUI BANKING CORPORATION",
    swift_code: "SMBCMYKA",
    is_deleted: true,
    country_id: 2,
    display_name: null
  },
  {
    id: 174,
    name: "Sumitomo Mitsui Banking Corporation (065)",
    swift_code: "SMBCHKHHXXX",
    is_deleted: false,
    country_id: 3,
    display_name: null
  },
  {
    id: 175,
    name: "Sumitomo Mitsui Trust Bank (371)",
    swift_code: "STBCHKHHXXX",
    is_deleted: false,
    country_id: 3,
    display_name: null
  },
  {
    id: 216,
    name: "Summerland Credit Union",
    swift_code: "none",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 158,
    name: "Suncorp-Metway",
    swift_code: "METWAU4B",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 224,
    name: "Sutherland Credit Union",
    swift_code: "none",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 144,
    name: "TAIPEI FUBON COMMERCIAL BANK (HONG KONG) LIMITED (239)",
    swift_code: "TPBKHKHHXXX",
    is_deleted: false,
    country_id: 3,
    display_name:
      "TAIPEI FUBON COMMERCIAL BANK (HONG KONG) LIMITED (239) - TPBKHKHHXXX"
  },
  {
    id: 238,
    name: "Taiwan Business Bank",
    swift_code: "none",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 18,
    name: "The Bank of East Asia",
    swift_code: "BEASSGSGXXX",
    is_deleted: true,
    country_id: 1,
    display_name: null
  },
  {
    id: 123,
    name: "The Bank of East Asia, Limited",
    swift_code: "BEASHKHHXXX",
    is_deleted: false,
    country_id: 3,
    display_name: "Bank of East Asia (015) - BEASHKHH"
  },
  {
    id: 76,
    name: "THE BANK OF NOVA SCOTIA BERHAD",
    swift_code: "NOSCMYKL",
    is_deleted: true,
    country_id: 2,
    display_name: null
  },
  {
    id: 134,
    name: "The Hongkong and Shanghai Banking Corporation Limited",
    swift_code: "HSBCHKHHHKH",
    is_deleted: false,
    country_id: 3,
    display_name: "Hong Kong and Shanghai (HSBC) (004) - HSBCHKHHHKH"
  },
  {
    id: 77,
    name: "THE HONGKONG AND SHANGHAI BANKING CORPORATION LIMITED",
    swift_code: "HSBCMYKA",
    is_deleted: true,
    country_id: 2,
    display_name: null
  },
  {
    id: 209,
    name: "The Rock",
    swift_code: "none",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 78,
    name: "TMC SERVICES SDN. BHD.",
    swift_code: "TMCBMYKL",
    is_deleted: true,
    country_id: 2,
    display_name: null
  },
  {
    id: 182,
    name: "Town & Country Bank",
    swift_code: "none",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 177,
    name: "TransferWise Singapore Pte Ltd",
    swift_code: "TRWISGSGXXX",
    is_deleted: false,
    country_id: 1,
    display_name: "TRANSFERWISE - TRWISGSG"
  },
  {
    id: 243,
    name: "TRUST BANK SINGAPORE LIMITED",
    swift_code: "TRBUSGSGXXX",
    is_deleted: false,
    country_id: 1,
    display_name: "TRUST BANK SINGAPORE LIMITED - TRBUSGSG"
  },
  {
    id: 241,
    name: "Tyro Payments",
    swift_code: "none",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 239,
    name: "UBS AG",
    swift_code: "none",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 232,
    name: "United Overseas Bank",
    swift_code: "none",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 176,
    name: "UNITED OVERSEAS BANK LTD HONG KONG (071)",
    swift_code: "UOVBHKHHXXX",
    is_deleted: false,
    country_id: 3,
    display_name: "UNITED OVERSEAS BANK LTD HONG KONG (071) - UOVBHKHHXXX "
  },
  {
    id: 79,
    name: "UNITED OVERSEAS BANK (MALAYSIA) BERHAD",
    swift_code: "UOVBMYKL",
    is_deleted: true,
    country_id: 2,
    display_name: null
  },
  {
    id: 113,
    name: "UNITED OVERSEAS BANK MALAYSIA BHD",
    swift_code: "UOBB",
    is_deleted: false,
    country_id: 2,
    display_name: null
  },
  {
    id: 200,
    name: "Uniting Financial Services",
    swift_code: "none",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 227,
    name: "Unity Bank",
    swift_code: "none",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 7,
    name: "UOB",
    swift_code: "UOVBSGSGXXX",
    is_deleted: false,
    country_id: 1,
    display_name: "UOB - UOVBSGSG"
  },
  {
    id: 194,
    name: "Volt Bank",
    swift_code: "none",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 221,
    name: "Warwick Credit Union",
    swift_code: "none",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 150,
    name: "Westpac Banking Corporation",
    swift_code: "WPACAU2S",
    is_deleted: false,
    country_id: 16,
    display_name: null
  },
  {
    id: 140,
    name: "Wing Lung Bank Limited",
    swift_code: "WUBAHKHHXXX",
    is_deleted: false,
    country_id: 3,
    display_name: "Wing Lung (020) - WUBAHKHH"
  },
  {
    id: 147,
    name: "Yuanta Bank (378)",
    swift_code: "OURBHKHHXXX",
    is_deleted: false,
    country_id: 3,
    display_name: "Yuanta Bank (378) - OURBHKHHXXX"
  }
];
export default t;
