import _concat from "lodash-es/concat";
import _uniq from "lodash-es/uniq";

export const SG_ACCEPTABLE_BRAND = [
  "visa",
  "mastercard",
  "ecmc", // another name of mastercard
  "unionpay",
  "amex",
  "american-express"
];

export const MY_ACCEPTABLE_BRAND = [
  "visa",
  "mastercard",
  "ecmc", // another name of mastercard
  "amex",
  "american-express"
];

export const HK_ACCEPTABLE_BRAND = [
  "visa",
  "mastercard",
  "ecmc", // another name of mastercard
  "unionpay",
  "amex",
  "american-express"
];

export const AU_ACCEPTABLE_BRAND = [
  "visa",
  "mastercard",
  "ecmc", // another name of mastercard
  "unionpay",
  "amex",
  "american-express"
];

export const ACCEPTABLE_BRAND = _uniq(
  _concat(
    SG_ACCEPTABLE_BRAND,
    MY_ACCEPTABLE_BRAND,
    HK_ACCEPTABLE_BRAND,
    AU_ACCEPTABLE_BRAND
  )
);

export const COUNTRY_SUPPORT_UNIONPAY = [0, 1, 3, 16];

export const CKO_ACCEPTED_CARD_BRANDS = [
  "visa",
  "mastercard",
  "ecmc" // another name of mastercard
  // "american express"
];

export const BRAINTREE_ACCEPTED_CARD_BRANDS = [
  "visa",
  "mastercard",
  "ecmc" // another name of mastercard
];

export const CKO_ACCEPTED_CARD_BRANDS_ID = [2]; // 5, 4
