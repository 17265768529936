// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ScheduleDetailModal_wrapper__2X1m6{display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center}.ScheduleDetailModal_wrapperMobile__3nfJX{padding-bottom:0}.ScheduleDetailModal_iconMoreAction__2sgyM{border:1px solid #c5c5c5;border-radius:100%;font-size:1rem;padding:.25rem;margin-right:.25rem}.ScheduleDetailModal_banner__2n40w{background-color:#624ce0;display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center}.ScheduleDetailModal_banner__2n40w .ScheduleDetailModal_bannerText__2BPav{margin:0;text-decoration:underline;color:#fff;cursor:pointer}.ScheduleDetailModal_banner__2n40w .ScheduleDetailModal_bannerText__2BPav.ScheduleDetailModal_isMobile__V5tPr{font-size:.75rem}", "",{"version":3,"sources":["webpack://./src/bepaid/shared/modals/ScheduleDetailModal/ScheduleDetailModal.module.scss"],"names":[],"mappings":"AAAA,oCAAS,mBAAY,CAAZ,YAAY,CAAC,oBAAqB,CAArB,sBAAsB,CAAC,0CAAe,gBAAgB,CAAC,2CAAgB,wBAAwB,CAAC,kBAAkB,CAAC,cAAc,CAAC,cAAc,CAAC,mBAAmB,CAAC,mCAAQ,wBAAwB,CAAC,mBAAY,CAAZ,YAAY,CAAC,oBAAsB,CAAtB,sBAAsB,CAAC,qBAAiB,CAAjB,kBAAkB,CAAC,0EAAoB,QAAQ,CAAC,yBAAyB,CAAC,UAAU,CAAC,cAAc,CAAC,8GAA6B,gBAAgB","sourcesContent":[".wrapper{display:flex;justify-content:center}.wrapperMobile{padding-bottom:0}.iconMoreAction{border:1px solid #c5c5c5;border-radius:100%;font-size:1rem;padding:.25rem;margin-right:.25rem}.banner{background-color:#624ce0;display:flex;justify-content:center;align-items:center}.banner .bannerText{margin:0;text-decoration:underline;color:#fff;cursor:pointer}.banner .bannerText.isMobile{font-size:.75rem}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "ScheduleDetailModal_wrapper__2X1m6",
	"wrapperMobile": "ScheduleDetailModal_wrapperMobile__3nfJX",
	"iconMoreAction": "ScheduleDetailModal_iconMoreAction__2sgyM",
	"banner": "ScheduleDetailModal_banner__2n40w",
	"bannerText": "ScheduleDetailModal_bannerText__2BPav",
	"isMobile": "ScheduleDetailModal_isMobile__V5tPr"
};
export default ___CSS_LOADER_EXPORT___;
