/* tslint:disable:jsx-no-lambda */
import React from "react";
import classNames from "classnames";
import { BrowserView, MobileView } from "react-device-detect";
import Layout from "antd/es/layout";

import TitleHeading from "./components/TitleHeading";
import Menu from "../Menu";

import styles from "./MainLayout.module.scss";

const MainLayout: React.FC<any> = (props: any) => {
  const {
    children,
    header: {
      title = null,
      onBack = null,
      onClose = null,
      onFavouriteAction = null,
      isFavourite = null,
      rightIcons = null,
      hideMenu = false,
      center = false,
      progress = null,
      hasBorder = true,
      leftHeader = null, // desktop
      rightHeader = null // desktop
    } = {},
    body: {
      leftBody = null, // desktop
      rightBody = null, // desktop
      fullBody = null
    } = {},
    footer = null,
    usingModal = false,
    id,
    fullContent = false,
    banner = null
  } = props;

  React.useEffect(() => {
    try {
      (window as any).Intercom("update");
    } catch (e) {
      window.Logger.guestError("Intercom update failed: " + e.toString());
    }
  }, []);

  const renderDesktopView = () => {
    return (
      <div id={id} className={classNames(styles.wrapper, styles.desktop)}>
        <Layout>
          <Layout.Sider>
            <Menu />
          </Layout.Sider>
          <Layout>
            <Layout.Content>
              <div className={styles.body}>
                {fullContent && fullBody ? (
                  <div className={styles.full}>
                    <div className={styles.top}>
                      <div className={styles.left}>{leftHeader}</div>
                      <div className={styles.right}>{rightHeader}</div>
                    </div>
                    {fullBody}
                  </div>
                ) : (
                  <>
                    <div className={styles.left}>
                      <div className={styles.top}>{leftHeader}</div>
                      {leftBody}
                    </div>
                    <div className={styles.right}>
                      <div className={styles.top}>{rightHeader}</div>
                      {rightBody}
                    </div>
                  </>
                )}
              </div>
            </Layout.Content>
          </Layout>
        </Layout>
      </div>
    );
  };

  const renderMobileView = () => {
    return (
      <div id={id} className={styles.wrapper}>
        <div
          className={classNames(styles.header, {
            [styles.hasBorder]: !progress && hasBorder,
            [styles.hasProgress]: progress
          })}
        >
          <TitleHeading
            title={title}
            onBack={onBack}
            onClose={onClose}
            rightIcons={rightIcons}
            hideMenu={hideMenu}
            center={center}
            progress={progress}
            onFavouriteAction={onFavouriteAction}
            isFavourite={isFavourite}
          />
        </div>
        <div
          className={styles.content}
          {...(usingModal && { id: "modal-body-content" })}
        >
          {!!banner && <div className={styles.bannerContainer}>{banner}</div>}
          {children}
        </div>
        {footer && (
          <div className={styles.footer}>
            <div className={styles.footerOpacity} />
            {footer}
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <BrowserView>{renderDesktopView()}</BrowserView>
      <MobileView>{renderMobileView()}</MobileView>
    </>
  );
};

export default MainLayout;
