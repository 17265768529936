import React, { useMemo } from "react";
import Form from "src/bepaid/components/Form";

import AutoComplete from "antd/es/auto-complete";
import _debounce from "lodash-es/debounce";

import SharedInputText from "src/ipm-shared/components/Form/controls/InputText";
import Loader from "src/bepaid/components/Loading";

import styles from "./AutoComplete.module.scss";
import _get from "lodash-es/get";
import T from "src/ipm-shared/Utils/Intl";

import Image from "src/bepaid/components/Image";
import SearchIcon from "src/bepaid/assets/images/common/icon_search.svg";

const Complete: React.FC<any> = props => {
  const {
    label = "Search",
    // name = "sample_autocomplete",
    placeholder = "Search text...",
    defaultValue = null,
    disabled = false,
    // form = "default",
    options = [], // {value, label}
    onSearch = () => null,
    onSelect = () => null,
    onChange = () => null,
    value = "",
    loading = false
  } = props;

  const handleSearch = (value: string) => {
    onSearch(value);
  };

  const debounceSearch = useMemo(() => {
    return _debounce(handleSearch, 500);
  }, []);

  return (
    <div className={styles.wrapper}>
      <Form.Item
        label={label}
        // name={name}
        validateStatus={props.hasError ? "error" : ""}
        {...(props.hasError
          ? {
              help: T.transl(props.errorMessage)
            }
          : {})}
      >
        <div className={styles.inputTextWrapper}>
          {loading && <Loader wrapperClassname={styles.loadingWrapper} />}
          <AutoComplete
            defaultValue={defaultValue !== null ? defaultValue : undefined}
            disabled={disabled}
            options={options}
            onSelect={onSelect}
            onSearch={debounceSearch}
            placeholder={placeholder}
            dropdownClassName={"antd-autocomplete-custom"}
            onChange={onChange}
            value={value}
          />
          <div className={styles.searchIcon}>
            <Image src={SearchIcon} />
          </div>
        </div>
        {props.description}
      </Form.Item>
    </div>
  );
};

const SearchText = (props: any) => {
  const renderComponent = (sharedProps: any) => {
    const handleChange = (value: any) => {
      let pass = false;
      if (sharedProps.onChange) {
        pass = sharedProps.onChange({ target: { value } });
      }
      if (props.onChange && pass) {
        props.onChange(value);
      }
    };

    const commonProps = {
      ...sharedProps,
      ...props,
      onChange: handleChange
    };

    const { control = {} } = sharedProps;
    const { errors = [] } = control;
    const errorMessage = _get(errors, "0.code", "");
    const hasError = errors.length > 0;

    return (
      <Complete
        {...commonProps}
        hasError={hasError}
        errorMessage={errorMessage}
      />
    );
  };
  return <SharedInputText {...props} renderComponent={renderComponent} />;
};

export default SearchText;
