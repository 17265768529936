/**
 * Actions
 *
 * ActionCreators are like the "pub" of pubsub. It's just broadcasting an event (action).
 * So, don't couple it with a specific reducer. Let ANY reducer listen to
 * an action.
 *
 * `typesafe-actions` has a nice utility function so we don't need to define action types as
 * constants. Use it together with `getType(type)` in the reducers.
 */
import { createAction } from "typesafe-actions";

import { LoadingFunc } from "src/ipm-shared/Utils/ReduxSagaEffects";

type cbType = (err?: any, data?: any) => void;

export const fetchDateConditions = createAction(
  "dateconditions/FETCH",
  resolve => (
    payoutDate: Date,
    bankIds: number[] = [],
    countryId?: number,
    currencyId?: number,
    paymentPaidCurrencyId?: number,
    cardBrandId?: number,
    payeeIds: number[] = [],
    options?: {
      cb: cbType;
      justGetData: any;
    },
    loadingFunc?: LoadingFunc
  ) =>
    resolve({
      bankIds,
      countryId,
      currencyId,
      paymentPaidCurrencyId,
      payoutDate,
      cardBrandId,
      payeeIds,
      options,
      loadingFunc
    })
);

export const setDateConditions = createAction(
  "dateconditions/SET",
  resolve => (args: {
    chargeDate?: Date;
    earliest?: Date;
    exclusions?: Date[];
    pspNonWorkingDates?: Date[];
    flashPayDate?: Date;
    isChargeToday?: boolean;
    isFetching: boolean;
  }) => resolve(args)
);

export const fetchPaymentCalendar = createAction(
  "dateconditions/FETCH_PAYMENT_CALENDAR",
  resolve => (args: { month: number; year: number }) => resolve(args)
);

export const setPaymentCalendar = createAction(
  "dateconditions/SET_PAYMENT_CALENDAR",
  resolve => (args: {
    isFetching: boolean;
    outComingPayments: Date[];
    inComingPayments: Date[];
  }) => resolve(args)
);
