import React, { useMemo, useRef, useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import DesktopModalLayout from "src/bepaid/components/Modal/components/DesktopModalLayout";
import MainLayout from "src/bepaid/layouts/MainLayout";
import Button from "src/bepaid/components/Form/controls/Button";

import { redirectToPrevPath } from "src/bepaid/utils/redirectToPrevPath";
import ROUTES from "src/bepaid/routes";
import { history } from "src/ipm-shared/store";

import { useMakePaymentUrlParams } from "src/bepaid/pages/Payments/hooks";

import AddEditCompany from "src/bepaid/layouts/Menu/components/Companies/components/AddEditCompany";

import styles from "../ExpandBox/ExpandboxContainer.module.scss";

const View = (props: any) => {
  const {
    entity,
    action,
    paymentType,
    replaceRouteWithParams
  } = useMakePaymentUrlParams();

  const [loading, setLoading] = useState(false);
  const isWallex = paymentType === "international";
  const companyFormRef = useRef<any>();

  const moveForward = () => {
    props.addCompany((err: any) => {
      if (!err) {
        props.fetchAccountProfiles(() => {
          history.push(
            replaceRouteWithParams(ROUTES.PAYMENTS_CREATION, {
              newEntity: isWallex ? "country" : "entity",
              newAction: "select"
            })
          );
        });
      }
    }, setLoading);
  };

  const submitLabel = useMemo(() => {
    return loading ? `Adding ${entity}...` : `Add ${entity}`;
  }, [action, loading]);

  const onBack = () => {
    history.goBack();
  };

  const onClose = () => {
    redirectToPrevPath();
  };

  const renderBtnSubmit = () => {
    return (
      <>
        <Button
          wrapperClassName={styles.wrapperButton}
          onClick={moveForward}
          type="primary"
          htmlType="button"
          loading={loading}
          autoScrollToFirstError={true}
        >
          {submitLabel}
        </Button>
      </>
    );
  };

  const renderDesktopView = () => {
    return (
      <DesktopModalLayout
        maxWidth450={true}
        onSubmit={moveForward}
        title={props.titleLabel}
        onClose={onClose}
        onBack={onBack}
        submitButtonClassName={""}
        btnSubmit={renderBtnSubmit()}
      >
        {renderContent()}
      </DesktopModalLayout>
    );
  };

  const renderMobileView = () => {
    return (
      <MainLayout
        header={{
          title: props.titleLabel,
          hideMenu: true,
          onBack,
          onClose
        }}
        footer={renderBtnSubmit()}
      >
        {renderContent()}
      </MainLayout>
    );
  };

  const renderContent = () => {
    return (
      <AddEditCompany
        ref={_ => {
          companyFormRef.current = _;
        }}
        submitLabel={"Add new company"}
        disableSubmit={true}
        companyData={{
          id: "",
          name: "",
          // tslint:disable-next-line:object-literal-sort-keys
          code: "",
          countryCode: props.accountCountryCode,
          payeeData: {
            businessIndustry: null,
            businessType: null
          }
        }}
      />
    );
  };

  return (
    <>
      <BrowserView>{renderDesktopView()}</BrowserView>
      <MobileView>{renderMobileView()}</MobileView>
    </>
  );
};

export default View;
