// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InvoiceOverview_wrapper__ttcGd .InvoiceOverview_normalText__2pnZj{margin:0;padding:.5rem 0;font-size:.875rem;color:#000}.InvoiceOverview_wrapper__ttcGd .InvoiceOverview_helperText__15QYE{margin:0;font-size:.875rem;color:#000}.InvoiceOverview_wrapper__ttcGd .InvoiceOverview_boldText__bZMzM{margin:0;padding:.5rem 0;font-size:.875rem;color:#000;font-weight:bold}.InvoiceOverview_wrapper__ttcGd .InvoiceOverview_flexBetween__kag3w{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:justify;justify-content:space-between;-ms-flex-align:center;align-items:center;margin:.5rem 0}.InvoiceOverview_wrapper__ttcGd .InvoiceOverview_hrLine__2eZZ_{height:.1px;background-color:#000;margin:0}.InvoiceOverview_wrapper__ttcGd .InvoiceOverview_paddingTop__ZpISA{padding-top:.5rem}.InvoiceOverview_wrapper__ttcGd .InvoiceOverview_paddingBottom__3HM5G{padding-bottom:.5rem}", "",{"version":3,"sources":["webpack://./src/bepaid/shared/modals/InvoiceDetailModal/components/InvoiceOverview/InvoiceOverview.module.scss"],"names":[],"mappings":"AAAA,mEAAqB,QAAQ,CAAC,eAAe,CAAC,iBAAiB,CAAC,UAAU,CAAC,mEAAqB,QAAQ,CAAC,iBAAiB,CAAC,UAAU,CAAC,iEAAmB,QAAQ,CAAC,eAAe,CAAC,iBAAiB,CAAC,UAAU,CAAC,gBAAgB,CAAC,oEAAsB,mBAAY,CAAZ,YAAY,CAAC,sBAAkB,CAAlB,kBAAkB,CAAC,qBAA6B,CAA7B,6BAA6B,CAAC,qBAAkB,CAAlB,kBAAkB,CAAC,cAAc,CAAC,+DAAiB,WAAW,CAAC,qBAAqB,CAAC,QAAQ,CAAC,mEAAqB,iBAAiB,CAAC,sEAAwB,oBAAoB","sourcesContent":[".wrapper .normalText{margin:0;padding:.5rem 0;font-size:.875rem;color:#000}.wrapper .helperText{margin:0;font-size:.875rem;color:#000}.wrapper .boldText{margin:0;padding:.5rem 0;font-size:.875rem;color:#000;font-weight:bold}.wrapper .flexBetween{display:flex;flex-direction:row;justify-content:space-between;align-items:center;margin:.5rem 0}.wrapper .hrLine{height:.1px;background-color:#000;margin:0}.wrapper .paddingTop{padding-top:.5rem}.wrapper .paddingBottom{padding-bottom:.5rem}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "InvoiceOverview_wrapper__ttcGd",
	"normalText": "InvoiceOverview_normalText__2pnZj",
	"helperText": "InvoiceOverview_helperText__15QYE",
	"boldText": "InvoiceOverview_boldText__bZMzM",
	"flexBetween": "InvoiceOverview_flexBetween__kag3w",
	"hrLine": "InvoiceOverview_hrLine__2eZZ_",
	"paddingTop": "InvoiceOverview_paddingTop__ZpISA",
	"paddingBottom": "InvoiceOverview_paddingBottom__3HM5G"
};
export default ___CSS_LOADER_EXPORT___;
