import React, { useState } from "react";
import classNames from "classnames";
import { isDesktop } from "react-device-detect";

import Form from "src/bepaid/components/Form";

import DateUtils from "src/ipm-shared/Utils/Date";
import PaymentMethodData from "src/ipm-shared/store/metadata/payment_method";

import Button from "src/bepaid/components/Form/controls/Button";
import Select from "src/bepaid/components/Form/controls/Select";
import CalendarInput from "src/bepaid/components/Calendar/components/CalendarInput";
import Calendar from "src/bepaid/components/Calendar";
import Drawer from "src/bepaid/components/Drawer";
import InputText from "src/bepaid/components/Form/controls/InputText";

import { invokeMessage } from "src/bepaid/components/Message";

import CalendarIcon from "src/bepaid/assets/images/common/calendar.svg";

import styles from "./MarkAsPaid.module.scss";

function InvoiceMarkAsPaidModal(props: any) {
  const invoiceDetail = props.extraInfo;

  const [loading, setLoading] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState<string>("");
  const [moreInfo, setMoreInfo] = useState<string | null>(null);

  const [paidDateCalendar, setPaidDateCalendar] = useState({
    date: new Date(),
    isOpen: false
  });

  const toggleCalendar = () => {
    setPaidDateCalendar({
      ...paidDateCalendar,
      isOpen: !paidDateCalendar.isOpen
    });
  };

  const handleSelectDate = (v: any) => {
    setPaidDateCalendar({
      date: v,
      isOpen: false
    });
  };

  const onChangePaymentMethod = (v: any) => {
    setPaymentMethod(v);
    if (v !== "other") {
      setMoreInfo(null);
    }
  };

  const onChangeMoreInfo = (e: any) => {
    setMoreInfo(e.target.value);
  };

  const onSubmit = () => {
    const data = {
      moreInfo,
      paidDate: DateUtils.formatISODate(paidDateCalendar.date.toString()),
      paymentMethod
    };

    props.markPaymentAsPaid(
      invoiceDetail.id,
      {
        data,
        // tslint:disable-next-line:object-literal-sort-keys
        cb: (err?: any) => {
          if (!err) {
            props.getInvoiceDetail(invoiceDetail.id);
            invokeMessage("success", "Payment marked as paid.");
          } else {
            invokeMessage("error", "Request failed.");
          }
          props.toggleModal(props.modalId);
        }
      },
      setLoading
    );
  };

  const onNo = () => {
    props.toggleModal(props.modalId);
  };

  return (
    <>
      <div
        className={classNames(styles.wrapper, {
          [styles.wrapperDesktop]: isDesktop
        })}
      >
        <div className={styles.titleContainer}>
          <h4 className={styles.title}>Mark as paid</h4>
        </div>

        <div>
          <p className={styles.description}>
            Mark this payment as paid if you have made the payment outside of
            ipaymy.
          </p>
        </div>

        <div>
          <Form
            layout="vertical"
            size={"small"}
            requiredMark={false}
            className={styles.Form}
          >
            <CalendarInput
              icon={CalendarIcon}
              label={"Paid Date"}
              onClick={toggleCalendar}
              value={paidDateCalendar.date}
              labelClass={styles.calendarLabel}
              btnClass={styles.calendarInput}
              iconClass={styles.iconCalendar}
            />

            <Select
              form={"mark_payment_as_paid"}
              name="payment_method"
              placeholder="Select method"
              label="Payment method"
              onChange={onChangePaymentMethod}
              options={PaymentMethodData.map(pm => ({
                label: pm.label,
                value: pm.name
              }))}
            />

            <div hidden={paymentMethod !== "other"}>
              <InputText
                type={"textarea"}
                name={"more_info"}
                label={"Tell us more about your payment method"}
                placeholder={"Type here"}
                row={4}
                onChange={onChangeMoreInfo}
              />
            </div>
          </Form>
        </div>

        <div className={styles.groupBtn}>
          <Button type="primary" onClick={onSubmit} loading={loading}>
            {loading ? "Marking as paid..." : "Mark as paid"}
          </Button>
          <Button type="secondary" onClick={onNo}>
            Close
          </Button>
        </div>
      </div>

      <Drawer
        height="auto"
        placement="bottom"
        visible={paidDateCalendar.isOpen}
        closable={true}
        closeIcon={null}
        onClose={toggleCalendar}
      >
        <Calendar
          onSubmit={handleSelectDate}
          range={null}
          disabledDays={{ after: new Date() }}
          selectDate={paidDateCalendar.date || new Date()}
          type={"customers"}
          labelDate={"Paid Date"}
        />
      </Drawer>
    </>
  );
}

export default InvoiceMarkAsPaidModal;
