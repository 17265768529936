import ROUTES from "src/bepaid/routes";

export const TITLE = {
  customers: "Filter",
  invoices: "Filter",
  payments: "Filter",
  payments_schedules: "Filter",
  landlords: "Filter",
  employees: "Filter",
  suppliers: "Filter",
  bank_accounts: "Filter"
};

export const REDIRECT_URI = {
  customers: ROUTES.CUSTOMERS,
  invoices: ROUTES.INVOICES,
  payments: ROUTES.PAYMENTS,
  payments_schedules: ROUTES.PAYMENTS,
  landlords: ROUTES.RECIPIENTS_LANDLORDS,
  employees: ROUTES.RECIPIENTS_EMPLOYEES,
  suppliers: ROUTES.RECIPIENTS_SUPPLIERS,
  bank_accounts: ROUTES.RECIPIENTS_BANK_ACCOUNTS
};
