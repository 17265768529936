import React, { useState } from "react";
import _get from "lodash-es/get";
import _isEmpty from "lodash-es/isEmpty";

import { Radio, Typography } from "antd";
import FormErrors from "src/ipm-shared/components/Form/helpers/Errors";
import InputText from "src/bepaid/components/Form/controls/InputText";
import ShortCurrency, {
  toCents
} from "src/ipm-shared/components/ShortCurrency";
import ShortCardInfo from "src/ipm-shared/components/ShortCardInfo";
import Skeleton from "antd/es/skeleton";

import { SCHEDULE_EDIT_FORM } from "src/ipm-shared/store/model/Payment/const";
import paymentUtil from "../../../PaymentDetailModal/components/PaymentDetail/utils/payment";

import { addMonths, format } from "date-fns";
import * as uuidv1 from "uuid/v1";
import classnames from "classnames";

import FormStyles from "src/bepaid/components/Form/CommonForm.module.scss";
import styles from "./ExtendPaymentDetail.module.scss";

enum EXTEND_PAYMENT_DURATION {
  THREE_MONTHS = 3,
  SIX_MONTHS = 6,
  NINE_MONTHS = 9,
  CUSTOM = "CUSTOM"
}

const View = (props: any) => {
  const {
    paymentSetting,
    selectedPaymentDetail,
    currentCurrencySymbol,
    upcomingPayment,
    setIsValidCustomDuration
  } = props;
  const { isEditAll } = props;

  let paymentDetail = paymentSetting;
  if (!isEditAll) {
    paymentDetail = selectedPaymentDetail;
  }
  if (!paymentDetail) {
    return null;
  }

  const [extendTime, setExtendTime] = useState();

  const isSingleRecipient = _get(paymentDetail, "payees.length", 0) === 1;
  const currencyId = _get(paymentSetting, "currencyId");
  const scheduleStartDate = _get(paymentSetting, "scheduleStartDate");
  let payemntAmount = paymentSetting.paymentAmount;
  let paymentFees = paymentSetting.paymentFees;
  let paymentTotal = paymentSetting.paymentTotal;
  let payees = _get(paymentSetting, "payees", []);
  let cards = _get(paymentSetting, "cards", []);
  if (upcomingPayment.length > 0) {
    let minPayment = upcomingPayment.filter(
      (p: any) => p.paymentStatusId === 2
    )[0];
    if (minPayment) {
      payemntAmount = minPayment.paymentAmount;
      paymentFees = minPayment.paymentFee;
      paymentTotal = minPayment.paymentTotal;
      payees = _get(minPayment, "payees", []);
      cards = _get(minPayment, "cards", []);
    }
  }

  const onChangeDuration = (e: any) => {
    let months = typeof e === "number" ? e : e.target.value;
    setExtendTime(months);
    if (months === "CUSTOM") {
      setIsValidCustomDuration(false);
      months = 0;
    } else {
      setIsValidCustomDuration(true);
    }
    const newEndDate = addMonths(
      new Date(paymentDetail.scheduleEndDate),
      months
    );

    props.setControl({
      errors: [],
      form: SCHEDULE_EDIT_FORM,
      name: `end_date_${paymentDetail.scheduleId}`,
      value: newEndDate
    });

    props.setControl({
      errors: [],
      form: SCHEDULE_EDIT_FORM,
      name: `extend_payment_duration_${paymentDetail.scheduleId}`
    });
  };

  const handleOnChangeCustomExtendDuration = (e: any) => {
    const value = isNaN(Number(e.target.value)) ? 0 : Number(e.target.value);
    if (value) {
      setIsValidCustomDuration(true);
    }
    const newEndDate = addMonths(
      new Date(paymentDetail.scheduleEndDate),
      value
    );

    props.setControl({
      errors: [],
      form: SCHEDULE_EDIT_FORM,
      name: `end_date_${paymentDetail.scheduleId}`,
      value: newEndDate
    });
  };

  React.useEffect(() => {
    props.setControl({
      errors: [],
      form: SCHEDULE_EDIT_FORM,
      name: `card_id_${paymentDetail.scheduleId}`,
      value: _get(paymentDetail, "cards[0].id", 0)
    });
  }, [_get(paymentDetail, "cards[0].id", 0)]);

  React.useEffect(() => {
    if (isEditAll && _get(paymentDetail, "scheduleEndDate")) {
      props.setControl({
        errors: [],
        form: SCHEDULE_EDIT_FORM,
        name: `end_date_${paymentDetail.scheduleId}`,
        value: _get(paymentDetail, "scheduleEndDate")
      });
      props.setControl({
        errors: [],
        form: SCHEDULE_EDIT_FORM,
        name: `payout_date_${paymentDetail.scheduleId}`,
        value: null
      });
      if (!_isEmpty(paymentDetail)) {
        props.fetchDateConditions(
          new Date(_get(paymentDetail, "scheduleEndDate")),
          paymentDetail.payees.map((p: any) => p.bankId),
          null,
          null,
          null,
          undefined,
          paymentDetail.payees.map((p: any) => p.id)
        );
      }
    }
  }, [_get(paymentDetail, "scheduleEndDate")]);

  React.useEffect(() => {
    if (!_isEmpty(paymentDetail)) {
      onChangeDuration(EXTEND_PAYMENT_DURATION.THREE_MONTHS);
    }
  }, [paymentDetail]);

  return (
    <div className={classnames(FormStyles.content, styles.wrapper)}>
      <FormErrors form={SCHEDULE_EDIT_FORM} />
      <div hidden>
        {isSingleRecipient && (
          <>
            <InputText
              name={`supplier_amount_${paymentDetail.payees[0].id}`}
              pattern={"__MONEY__"}
              form={SCHEDULE_EDIT_FORM}
              defaultValue={toCents(
                _get(paymentDetail, "payees[0].defaultAmount", "")
              )}
              reserveValueOnUnmount={true}
              revertValueOnMount={false}
              placeholder={`${currentCurrencySymbol}0.00`}
            />
            <InputText
              className={styles.capitalizeFirstLetter}
              name={`default_comments_${paymentDetail.payees[0].id}`}
              form={SCHEDULE_EDIT_FORM}
              defaultValue={_get(
                paymentDetail,
                "payees[0].defaultComments",
                ""
              )}
              pattern={"__STATEMENT__"}
            />
          </>
        )}
        {payees.length > 1 &&
          payees.map((p: any, idx: number) => {
            return (
              <div
                key={`${p.id}_${p.uid}`}
                className={styles.spacingBlock}
                hidden={false}
              >
                <div>
                  <InputText
                    form={SCHEDULE_EDIT_FORM}
                    pattern={"__MONEY__"}
                    name={`supplier_amount_${p.id}${p.uid && "_" + p.uid}`}
                    defaultValue={toCents(p.defaultAmount)}
                    placeholder={`${currentCurrencySymbol}0.00`}
                  />
                  <InputText
                    form={SCHEDULE_EDIT_FORM}
                    pattern={"__STATEMENT__"}
                    name={`default_comments_${p.id}${p.uid && "_" + p.uid}`}
                    defaultValue={p.defaultComments}
                  />
                </div>
              </div>
            );
          })}
      </div>

      <Skeleton loading={props.loading}>
        <div className={styles.content}>
          <div className={styles.title}>
            <b>{`How long do you wish to extend this scheduled payment?`}</b>
          </div>
          <div className={classnames(styles.row, styles.firstRow)}>
            <span>{paymentUtil.getPaymentPurpose(paymentSetting)}</span>
          </div>
          <div className={classnames(styles.row, styles.verticalRow)}>
            {payees.map((p: any) => (
              <div
                key={uuidv1()}
                className={classnames(
                  styles.row,
                  styles.collapsedSectionRecipientName
                )}
              >
                <Typography.Text strong={true} ellipsis={true}>
                  {p.name}
                </Typography.Text>
                <span>
                  <ShortCurrency
                    value={p.currentAmount}
                    currencyId={currencyId}
                  />
                </span>
              </div>
            ))}
          </div>
          <hr className={styles.hr1px} />
          <div className={styles.row}>
            <span>Subtotal</span>
            <span>
              <ShortCurrency value={payemntAmount} currencyId={currencyId} />
            </span>
          </div>
          <hr className={styles.hr1px} />
          <div className={styles.row}>
            <span>Fee</span>
            <span>
              <ShortCurrency value={paymentFees} currencyId={currencyId} />
            </span>
          </div>
          <hr className={styles.hr2px} />
          <div className={styles.row}>
            <b>Total Amount</b>
            <b>
              <ShortCurrency value={paymentTotal} currencyId={currencyId} />
            </b>
          </div>
          <hr className={styles.hr2px} />
          <div className={styles.rowsContainer}>
            <div className={styles.row}>
              <span>Card to be Charged</span>
              <div>
                {cards.map((c: any, idx: number) => {
                  return (
                    <div key={idx} className={styles.cardIconContainer}>
                      <ShortCardInfo cardId={c.id} />
                    </div>
                  );
                })}
              </div>
            </div>
            <div className={styles.row}>
              <span>Payout date</span>
              <span>{format(scheduleStartDate, "Do")} of the month</span>
            </div>
          </div>

          <div className={classnames(styles.groupButtonWrapper)}>
            <Radio.Group
              className={styles.groupOptionAddItem}
              options={[
                {
                  label: "3 months",
                  value: EXTEND_PAYMENT_DURATION.THREE_MONTHS
                },
                {
                  label: "6 months",
                  value: EXTEND_PAYMENT_DURATION.SIX_MONTHS
                },
                {
                  label: "9 months",
                  value: EXTEND_PAYMENT_DURATION.NINE_MONTHS
                }
              ]}
              onChange={onChangeDuration}
              value={extendTime}
              optionType={"button"}
              buttonStyle={"solid"}
            />
          </div>
          <div
            className={classnames(
              styles.groupButtonWrapper,
              styles.customOption
            )}
          >
            <Radio.Group
              className={styles.groupOptionAddItem}
              options={[
                {
                  label: "Custom",
                  value: "CUSTOM"
                }
              ]}
              onChange={onChangeDuration}
              value={extendTime}
              optionType={"button"}
              buttonStyle={"solid"}
            />
            {extendTime === "CUSTOM" && (
              <InputText
                form={SCHEDULE_EDIT_FORM}
                pattern={"__NUMBER__"}
                name={`extend_payment_duration_${paymentDetail.scheduleId}`}
                onChange={handleOnChangeCustomExtendDuration}
                placeholder={""}
              />
            )}
          </div>
        </div>
      </Skeleton>
    </div>
  );
};

export default View;
