import * as React from "react";

import T from "src/ipm-shared/Utils/Intl";
import {
  BRAND_ID,
  CRYPTO_OPTION
} from "src/ipm-shared/store/model/CollectedAccount/const";

import { IProps } from "./index";
import CollectionSplitFee from "../CollectionSplitFee";
import Switch from "../Form/controls/Switch";

type IState = {
  selectedBrandId: number;
};
class CollectionSplitFees extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);

    this.state = {
      selectedBrandId: this.props.supportedBrandId[0]
    };
  }
  public render() {
    const optionSwitch = [
      {
        label: `${T.transl("VISA_MASTERCARD_FEE_LABEL")}`,
        value: BRAND_ID.VISA
      }
    ];
    if (this.props.supportedBrandId.indexOf(BRAND_ID.UNION) > -1) {
      optionSwitch.push({
        label: `${T.transl("UNIONPAY_COMMON_FEE_LABEL")}`,
        value: BRAND_ID.UNION
      });
    }

    if (this.props.supportedBrandId.indexOf(BRAND_ID.AMEX) > -1) {
      optionSwitch.push({
        label: `${T.transl("AMEX_COMMON_FEE_LABEL")}`,
        value: BRAND_ID.AMEX
      });
    }

    return (
      <div>
        {this.props.rendererSlider ? (
          this.props.rendererSlider(
            this.state.selectedBrandId,
            this.onChangeBrand
          )
        ) : (
          <Switch
            className="switch-card-brand-split-fee"
            name={`card_brand_value`}
            defaultValue={this.state.selectedBrandId}
            options={optionSwitch}
            onChangeCustom={this.onChangeBrand}
          />
        )}
        <div hidden={this.state.selectedBrandId !== CRYPTO_OPTION}>
          <CollectionSplitFee
            renderer={this.props.renderer}
            // extraName={
            //   "crypto" + (this.props.extraName ? `_${this.props.extraName}` : "")
            // }
            hasActiveProgram={this.props.hasActiveProgram}
            className={"collection-request-review"}
            defaultValue={{
              brand_id: this.props.defaultValue.filter(
                r => r.brand_id === CRYPTO_OPTION
              )[0]?.brand_id,
              international: this.props.defaultValue.filter(
                r => r.brand_id === CRYPTO_OPTION
              )[0]?.international,
              national: this.props.defaultValue.filter(
                r => r.brand_id === CRYPTO_OPTION
              )[0]?.national,
              crypto_rate: this.props.defaultValue.filter(
                r => r.brand_id === CRYPTO_OPTION
              )[0]?.crypto_rate
            }}
            brandId={CRYPTO_OPTION}
            currencyId={this.props.currencyId}
            // This should be different from this.props.extraInfo.formName to avoid override value
            form={this.props.form + "_edit_fees"}
            onChangeCustom={this.onChange.bind(this, CRYPTO_OPTION)}
          />
        </div>
        <div hidden={this.state.selectedBrandId !== BRAND_ID.VISA}>
          <CollectionSplitFee
            renderer={this.props.renderer}
            extraName={
              "visa" + (this.props.extraName ? `_${this.props.extraName}` : "")
            }
            hasActiveProgram={this.props.hasActiveProgram}
            className={"collection-request-review"}
            defaultValue={{
              brand_id: this.props.defaultValue.filter(
                r => r.brand_id === BRAND_ID.VISA
              )[0].brand_id,
              international: this.props.defaultValue.filter(
                r => r.brand_id === BRAND_ID.VISA
              )[0].international,
              national: this.props.defaultValue.filter(
                r => r.brand_id === BRAND_ID.VISA
              )[0].national
            }}
            brandId={BRAND_ID.VISA}
            currencyId={this.props.currencyId}
            // This should be different from this.props.extraInfo.formName to avoid override value
            form={this.props.form + "_edit_fees"}
            onChangeCustom={this.onChange.bind(this, BRAND_ID.VISA)}
          />
        </div>

        <div
          hidden={
            this.props.supportedBrandId.indexOf(BRAND_ID.UNION) < 0 ||
            this.state.selectedBrandId !== BRAND_ID.UNION
          }
        >
          <CollectionSplitFee
            renderer={this.props.renderer}
            extraName={
              "union" + (this.props.extraName ? `_${this.props.extraName}` : "")
            }
            hasActiveProgram={this.props.hasActiveProgram}
            className={"collection-request-review"}
            brandId={BRAND_ID.UNION}
            defaultValue={{
              brand_id: this.props.defaultValue.filter(
                r => r.brand_id === BRAND_ID.UNION
              )[0].brand_id,
              international: this.props.defaultValue.filter(
                r => r.brand_id === BRAND_ID.UNION
              )[0].international,
              national: this.props.defaultValue.filter(
                r => r.brand_id === BRAND_ID.UNION
              )[0].national
            }}
            // This should be different from this.props.extraInfo.formName to avoid override value
            form={this.props.form + "_edit_fees"}
            currencyId={this.props.currencyId}
            onChangeCustom={this.onChange.bind(this, BRAND_ID.UNION)}
          />
        </div>
        <div
          hidden={
            this.props.supportedBrandId.indexOf(4) < 0 ||
            this.state.selectedBrandId !== 4
          }
        >
          <CollectionSplitFee
            renderer={this.props.renderer}
            extraName={
              "amex" + (this.props.extraName ? `_${this.props.extraName}` : "")
            }
            hasActiveProgram={this.props.hasActiveProgram}
            className={"collection-request-review"}
            brandId={4}
            defaultValue={{
              brand_id: this.props.defaultValue.filter(r => r.brand_id === 4)[0]
                .brand_id,
              international: this.props.defaultValue.filter(
                r => r.brand_id === 4
              )[0].international,
              national: this.props.defaultValue.filter(r => r.brand_id === 4)[0]
                .national
            }}
            // This should be different from this.props.extraInfo.formName to avoid override value
            form={this.props.form + "_edit_fees"}
            currencyId={this.props.currencyId}
            onChangeCustom={this.onChange.bind(this, 4)}
          />
        </div>
      </div>
    );
  }

  // NOTE: the real value is in *100 format. For e.g: BRAND_ID.VISA00
  // When we display, easily /100 and toFix 2
  private onChange = (brandId: number, args: any) => {
    if (this.props.onChangeCustom) {
      this.props.onChangeCustom(brandId, args);
    }
  };

  private onChangeBrand = (brandId: number) => {
    this.setState({
      selectedBrandId: brandId
    });
  };
}

export default CollectionSplitFees;
