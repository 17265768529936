// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".KYC_wrapper__3JrMT .KYC_content__3vFIt{text-align:left;width:100%;height:100%}", "",{"version":3,"sources":["webpack://./src/bepaid/pages/KYC/KYC.module.scss"],"names":[],"mappings":"AAAA,wCAAkB,eAAe,CAAC,UAAU,CAAC,WAAW","sourcesContent":[".wrapper .content{text-align:left;width:100%;height:100%}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "KYC_wrapper__3JrMT",
	"content": "KYC_content__3vFIt"
};
export default ___CSS_LOADER_EXPORT___;
