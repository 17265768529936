import * as ReactRouter from "react-router";
import { connect } from "react-redux";
import Component from "./View";
import { RootState } from "src/ipm-shared/store/model/reducers";
import * as selectors from "./selectors";

const mapStateToProps = (state: RootState) => ({
  brandsById: selectors.brandsById(state),
  cardsById: selectors.cardsById(state)
});

const mapDispatchToProps = {};

export type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  ReactRouter.RouteProps & {
    cardId: number;
  };

export default connect(mapStateToProps, mapDispatchToProps)(Component);
