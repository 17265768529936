import React from "react";
import Modal from "antd-mobile/es/modal";
import classNames from "classnames";
import { isDesktop, isMobile } from "react-device-detect";

import { FetchModalID } from "src/ipm-shared/components/GlobalUI/actions";

// Modals
import DeleteCustomerModal from "src/bepaid/shared/modals/CustomerDeletePageModal";
import CancelPaymentRequestModal from "src/bepaid/shared/modals/InvoiceCancelPaymentRequestModal";
import MarkAsPaidModal from "src/bepaid/shared/modals/InvoiceMarkAsPaidModal";
import RefundPaymentModal from "src/bepaid/shared/modals/InvoiceRefundPaymentModal";
import InvoiceSentModal from "src/bepaid/shared/modals/InvoiceSentModal";
import ChangeOptionInvoiceModal from "src/bepaid/shared/modals/InvoiceCreateModal/components/ChangeOptionInvoiceModal";
import CancelDraftModal from "src/bepaid/shared/modals/InvoiceCancelDraftModal";
import InvoiceDetailModal from "src/bepaid/shared/modals/InvoiceDetailModal";
import PaymentDetailModal from "src/bepaid/shared/modals/PaymentDetailModal";
import PaymentDetailEditModal from "src/bepaid/shared/modals/PaymentDetailEditModal";
import CancelPaymentModal from "src/bepaid/shared/modals/PaymentCancelModal";

import AddCompanyModal from "src/bepaid/pages/Companies/components/AddCompanyModal";
import ListCompaniesModal from "src/bepaid/pages/Companies/components/ListCompaniesModal";

import ChangeEmailModal from "src/bepaid/pages/Profile/components/ChangeEmailModal";
import ChangePasswordModal from "src/bepaid/pages/Profile/components/ChangePasswordModal";
import PersonalModal from "src/bepaid/pages/Profile/components/PersonalModal";
import AccountSettingModal from "src/bepaid/pages/Profile/components/AccountSettingsModal";
import BusinessInformationModal from "src/bepaid/pages/Profile/components/BusinessInformationModal";

import CreateInvoiceModal from "src/bepaid/shared/modals/InvoiceCreateModal";
import AddCustomerModal from "src/bepaid/shared/modals/CustomerAddPageModal";
import EditCustomerModal from "src/bepaid/shared/modals/CustomerEditPageModal";
import CustomerDetailModal from "src/bepaid/shared/modals/CustomerDetailPageModal";

import ListCardModal from "src/bepaid/pages/Wallet/components/ListCardModal";
import AddNewCardModal from "src/bepaid/pages/Wallet/components/AddNewCardModal";
import DeleteCardModal from "src/bepaid/pages/Wallet/components/DeleteCardModal";

import PaymentsCreationModal from "src/bepaid/shared/modals/PaymentsCreationModal";
import PaymentResultModal from "src/bepaid/shared/modals/PaymentResultModal";
import CollectedPaymentFailedModal from "src/bepaid/components/Modal/components/CollectedPaymentFailed";

import KYCModal from "src/bepaid/pages/KYC";
import BenefitsOfFetchModal from "src/bepaid/pages/Dashboard/components/BenefitsOfFetchModal";

import RecipientModal from "src/bepaid/shared/modals/RecipientAddEditPageModal";
import RecipientDetailModal from "src/bepaid/shared/modals/RecipientDetailPageModal";
import DeleteRecipientModal from "src/bepaid/shared/modals/RecipientDeletePageModal";
import ListDraftInvoiceModal from "src/bepaid/shared/modals/InvoiceListDraftModal";
import ScheduleDetailModal from "src/bepaid/shared/modals/ScheduleDetailModal";
import RecollectCVVModal from "src/bepaid/shared/modals/RecollectCVVModal";
import CryptoVerifyIdentityModal from "src/bepaid/shared/modals/CryptoVerifyIdentityModal";

import ChangeDraftInvoiceModal from "src/bepaid/pages/Profile/components/ChangeDraftInvoiceModal";

import MyInfoVerificationModal from "src/bepaid/pages/MyInfo";
import MyInfoErrorIdentityModal from "src/bepaid/pages/MyInfo/components/ErrorIdentity";

import IncorporationDateModal from "src/bepaid/pages/IncorporationDate";
import PayFetchTour from "src/bepaid/pages/PayFetchTour";
import PromoPopupModal from "src/bepaid/shared/modals/PromoPopupModal";

import SystemUpgradeModal from "./components/SystemUpgradeModal";

import styles from "./Modal.module.scss";
import TripleAPaymentModal from "src/bepaid/shared/modals/TripleAPaymentModal";
import PaymentDuplicateModal from "src/bepaid/shared/modals/PaymentDuplicateModal";
import AddtionalDataModal from "src/bepaid/pages/AdditonalData";
import UserAddNewModal from "src/bepaid/shared/modals/UserAddNewModal";
import UserDeleteModal from "src/bepaid/shared/modals/UserDeleteModal";
import UserInviteResultModal from "src/bepaid/shared/modals/UserInviteResultModal";
import PaymentDetailExtendModal from "src/bepaid/shared/modals/PaymentDetailExtendModal";

interface IProps {
  isOpen: boolean;
  modalId: number;
  modalIndex?: number;

  [key: string]: any;
}

const notifyToSlackMissingModal = (id: number) => {
  fetch(
    "https://hooks.slack.com/services/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",
    {
      method: "POST",
      body: `{"text":"Missing modal: ${id}, ${window.location.href}"}`
    }
  );
};

const TOP_PLACE_MODALS: any[] = [];

const MOBILE_FULL_SCREEN_MODALS: any[] = [
  FetchModalID.ADD_NEW_CARD_MODAL,
  FetchModalID.RECOLLECT_CVV
];

const CLONED_SECONDARY_MODALS: any[] = [];
const SMALL_DESKTOP_MODALS: any[] = [FetchModalID.PAYFETCH_TOUR_MODAL];

function CustomModal(props: IProps) {
  const {
    isOpen,
    modalIndex,
    renderModalContentV1 = () => null,
    ...rest
  } = props;

  const renderModal = () => {
    switch (props.modalId) {
      case FetchModalID.DELETE_NEW_CARD_MODAL:
        return <DeleteCardModal {...rest} />;

      case FetchModalID.ADD_NEW_CARD_MODAL:
        return <AddNewCardModal {...rest} />;

      case FetchModalID.LIST_CARD_MODAL:
        return <ListCardModal {...rest} />;

      case FetchModalID.DELETE_CUSTOMER_PROFILE:
        return <DeleteCustomerModal {...rest} />;

      case FetchModalID.CANCEL_PAYMENT_REQUEST:
        return <CancelPaymentRequestModal {...rest} />;

      case FetchModalID.MARK_PAYMENT_AS_PAID:
        return <MarkAsPaidModal {...rest} />;

      case FetchModalID.REFUND_PAYMENT:
        return <RefundPaymentModal {...rest} />;

      case FetchModalID.INVOICE_SENT:
        return <InvoiceSentModal {...rest} />;

      case FetchModalID.ADD_COMPANY:
        return <AddCompanyModal {...rest} />;

      case FetchModalID.LIST_COMPANIES:
        return <ListCompaniesModal {...rest} />;

      case FetchModalID.CHANGE_EMAIL:
        return <ChangeEmailModal {...rest} />;

      case FetchModalID.CHANGE_PASSWORD:
        return <ChangePasswordModal {...rest} />;

      case FetchModalID.UPDATE_PERSONAL_INFO:
        return <PersonalModal {...rest} />;

      case FetchModalID.UPDATE_ACCOUNT_SETTINGS:
        return <AccountSettingModal {...rest} />;

      case FetchModalID.CREATE_INVOICE:
        return <CreateInvoiceModal {...rest} />;

      case FetchModalID.INVOICE_DETAIL:
        return <InvoiceDetailModal {...rest} />;

      case FetchModalID.UPDATE_BUSINESS_INFORMATION:
        return <BusinessInformationModal {...rest} />;

      case FetchModalID.ADD_CUSTOMER:
        return <AddCustomerModal {...rest} />;

      case FetchModalID.EDIT_CUSTOMER:
        return <EditCustomerModal {...rest} />;

      case FetchModalID.CUSTOMER_DETAIL:
        return <CustomerDetailModal {...rest} />;

      case FetchModalID.KYC_MODAL:
        return <KYCModal {...rest} />;

      case FetchModalID.ADDITIONAL_DATA_MODAL:
        return <AddtionalDataModal {...rest} />;

      case FetchModalID.CHANGE_OPTION_INVOICE_MODAL:
        return <ChangeOptionInvoiceModal {...rest} />;

      case FetchModalID.UPDATE_DRAFT_INVOICE_SETTING:
        return <ChangeDraftInvoiceModal {...rest} />;

      case FetchModalID.CANCEL_DRAFT_INVOICE:
        return <CancelDraftModal {...rest} />;

      case FetchModalID.RECIPIENT_MODAL:
        return <RecipientModal {...rest} />;

      case FetchModalID.RECIPIENT_DETAIL:
        return <RecipientDetailModal {...rest} />;

      case FetchModalID.RECIPIENT_DETAIL_UPDATE:
        return <RecipientModal {...rest} editMode={true} />;

      case FetchModalID.MY_INFO_VERIFICATION_MODAL:
        return <MyInfoVerificationModal {...rest} />;

      case FetchModalID.MY_INFO_VERIFICATION_ERROR_MODAL:
        return <MyInfoErrorIdentityModal {...rest} />;

      case FetchModalID.PAYMENTS_CREATION:
        return <PaymentsCreationModal {...rest} />;

      case FetchModalID.PAYMENT_DETAIL:
        return <PaymentDetailModal {...rest} />;
      case FetchModalID.PAYMENT_DETAIL_EDIT:
        return <PaymentDetailEditModal {...rest} />;
      case FetchModalID.SCHEDULE_EDIT:
        return <PaymentDetailEditModal {...rest} />;
      case FetchModalID.SCHEDULE_EXTEND:
        return <PaymentDetailExtendModal {...rest} />;
      case FetchModalID.CANCEL_PAYMENT_MODAL:
        return <CancelPaymentModal {...rest} />;
      case FetchModalID.PAYMENT_SUCCESS:
      case FetchModalID.PAYMENT_SUCCESS_SCHEDULE:
      case FetchModalID.RECURRING_PAYMENT_SUCCESS:
      case FetchModalID.MAKE_PAYMENT_RESULT:
        return <PaymentResultModal {...rest} />;

      case FetchModalID.PAYMENT_ERROR:
      case FetchModalID.COLLECTED_PAYMENT_FAILED:
        return <CollectedPaymentFailedModal {...rest} />;

      case FetchModalID.BENEFITS_OF_FETCH_MODAL:
        return <BenefitsOfFetchModal {...rest} />;
      case FetchModalID.DELETE_RECIPIENT_MODAL:
        return <DeleteRecipientModal {...rest} />;
      case FetchModalID.LIST_INVOICE_DRAFT_MODAL:
        return <ListDraftInvoiceModal {...rest} />;
      case FetchModalID.SCHEDULE_DETAIL:
        return <ScheduleDetailModal {...rest} />;

      case FetchModalID.RECOLLECT_CVV:
        return <RecollectCVVModal {...rest} />;

      case FetchModalID.INCORPORATION_DATE_MODAL:
        return <IncorporationDateModal {...rest} />;

      case FetchModalID.PAYFETCH_TOUR_MODAL:
        return <PayFetchTour {...rest} />;

      case FetchModalID.PROMO_POPUP:
        return <PromoPopupModal {...rest} />;

      case FetchModalID.SYSTEM_UPPGRADE_MODAL:
        return <SystemUpgradeModal {...rest} />;
      case FetchModalID.TRIPLEA_PAYMENT_MODAL:
        return <TripleAPaymentModal {...rest} />;

      case FetchModalID.PAYMENT_DUPLICATE_MODAL:
        return <PaymentDuplicateModal {...rest} />;

      case FetchModalID.USER_ADD_NEW_MODAL:
        return <UserAddNewModal {...rest} />;

      case FetchModalID.USER_DELETE_MODAL:
        return <UserDeleteModal {...rest} />;

      case FetchModalID.USER_INVITE_SENT_MODAL:
        return <UserInviteResultModal {...rest} />;

      case FetchModalID.CRYPTO_VERIFY_IDENTITY:
        return <CryptoVerifyIdentityModal {...rest} />;
      default: {
        const V1Modal = renderModalContentV1(props.modalId);
        if (V1Modal) {
          notifyToSlackMissingModal(props.modalId);
          return V1Modal;
        }
        return null;
      }
    }
  };

  const modalContent = renderModal();

  if (modalContent === null) {
    return null;
  }
  const isTopPlace = TOP_PLACE_MODALS?.includes(props.modalId);

  const isMobileFullScreen = MOBILE_FULL_SCREEN_MODALS.includes(props.modalId);

  const isClonedSecondaryModal = CLONED_SECONDARY_MODALS?.includes(
    props.modalId
  );

  const isSmallDesktopModal = SMALL_DESKTOP_MODALS?.includes(props.modalId);
  const isPromoPopupModal = props.modalId === FetchModalID.PROMO_POPUP;
  const isTripleAPaymentModal =
    props.modalId === FetchModalID.TRIPLEA_PAYMENT_MODAL;

  return isPromoPopupModal || isTripleAPaymentModal ? (
    <Modal
      visible={isOpen}
      className={classNames(
        styles.modalContainer,
        {
          [styles.promoPopupModal]: isPromoPopupModal,
          [styles.tripleAPaymentModal]: isTripleAPaymentModal
        },
        {
          [styles.modalDesktop]: isDesktop,
          [styles.modalMobile]: isMobile
        }
      )}
    >
      {modalContent}
    </Modal>
  ) : (
    <Modal
      visible={isOpen}
      transparent={true}
      maskClosable={false}
      className={classNames(styles.modalContainer, {
        "am-modal-top": isTopPlace,
        "am-modal-desktop": isDesktop,
        "am-modal-mobile": isMobile,
        [styles.modalDesktop]: isDesktop,
        [styles.modalMobile]: isMobile,
        [styles.mobileFullScreen]: isMobileFullScreen,
        [styles.primary]: modalIndex === 0,
        [styles.secondary]: modalIndex === 1,
        [styles.tertiary]: modalIndex === 2,
        [styles.clonedSecondary]: isClonedSecondaryModal,
        [styles.smallDesktopModal]: isSmallDesktopModal
      })}
    >
      {modalContent}
    </Modal>
  );
}

export default CustomModal;
