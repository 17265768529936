import React from "react";
import _get from "lodash-es/get";
import LandLordsContentCollapse from "./LandlordsContentCollapse";
import EmployeesContentCollapse from "./EmployeesContentCollapse";
import SuppliersContentCollapse from "./SuppliersContentCollapse";
import TaxContentCollapse from "./TaxContentCollapse";
import BankAccountsContentCollapse from "./BankAccountsContentCollapse";
import { TAX_PAYMENT_ACCOUNT_NUMBER } from "src/ipm-shared/store/model/Payment/const";

const View = (props: any) => {
  const purposeId = _get(props.selectedPayee, "purposeId", 0);
  const paymentAccountNumber = _get(props.selectedPayee, "accountNumber");
  if (purposeId === 1) {
    return <LandLordsContentCollapse {...props} />;
  }
  if (purposeId === 2) {
    return <EmployeesContentCollapse {...props} />;
  }
  if (purposeId === 3) {
    return TAX_PAYMENT_ACCOUNT_NUMBER.includes(paymentAccountNumber) ? (
      <TaxContentCollapse {...props} />
    ) : (
      <SuppliersContentCollapse {...props} />
    );
  }
  if (purposeId === 5) {
    return <BankAccountsContentCollapse {...props} />;
  }
  return <></>;
};

export default View;
