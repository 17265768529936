import React, { useMemo, useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import DesktopModalLayout from "src/bepaid/components/Modal/components/DesktopModalLayout";
import MainLayout from "src/bepaid/layouts/MainLayout";

import SelectGroup from "src/bepaid/components/SelectGroup";
import Button from "src/bepaid/components/Form/controls/Button";
import Progress from "src/bepaid/components/Progress";
import { invokeMessage } from "src/bepaid/components/Message";

import ROUTES from "src/bepaid/routes";
import { history } from "src/ipm-shared/store";
import _lowerCase from "lodash-es/lowerCase";
import _get from "lodash-es/get";
import _isEmpty from "lodash-es/isEmpty";

import { useMakePaymentUrlParams } from "src/bepaid/pages/Payments/hooks";
import { purposeByEntity } from "src/bepaid/pages/Payments/const";
import PaymentUtil from "src/ipm-shared/Utils/Payment";

import {
  FORM,
  INTERNATIONAL_FORM
} from "src/ipm-shared/store/model/Payee/const";
import {
  ADD_LANDLORD_FIRST_SCREEN_ERROR_FIELD,
  ADD_SUPPLIER_FIRST_SCREEN_ERROR_FIELD
} from "../../const";

import SelectEntity from "./components/SelectEntity";
import AddEntity from "./components/AddEntity";

import classnames from "classnames";
import styles from "./AddOrSelectEntity.module.scss";
import FeatureGate from "src/ipm-shared/components/FeatureGating/FeatureGate";

const AddOrSelectEntity = (props: any) => {
  const {
    action,
    entity,
    paymentType,
    replaceRouteWithParams
  } = useMakePaymentUrlParams();
  const [loading, setLoading] = useState(false);
  const purpose = purposeByEntity[entity];
  const isWallex = paymentType === "international";
  const isHas2Step = isWallex && entity !== "self_transfer";
  const [step, setStep] = useState(1);

  const disabled =
    purpose === "rent"
      ? (!props.controls["confirm_tenancy_agreement"] ||
          props.controls["confirm_tenancy_agreement"]?.value) &&
        (!props.controls["rent_confirm_tenancy_agreement_business"] ||
          props.controls["rent_confirm_tenancy_agreement_business"]?.value)
        ? false
        : true
      : !props.controls["confirm_tenancy_agreement"] ||
        props.controls["confirm_tenancy_agreement"]?.value
      ? false
      : true;

  const onChangeMode = (e: any) => {
    const newAction = e.target.value;

    history.push(
      replaceRouteWithParams(ROUTES.PAYMENTS_CREATION, {
        newEntity: entity,
        newAction
      })
    );
  };

  const normalizedEntity =
    entity === "self_transfer" || entity === "business_employee"
      ? "bank account"
      : _lowerCase(entity);

  const onAddDomesticEntity = () => {
    if (entity === "business_employee") {
      props.addBusinessEmployeeSubmit(
        true,
        {
          cb: () => {
            props.fetchPayees({
              type: purpose,
              getAllPayees: true,
              noRedirect: true,
              pageCount: 1000,
              cb: (payees: any) => {
                if (payees.length) {
                  props.selectPayees({
                    ids: [payees[0].id],
                    purpose,
                    removedIds: "all"
                  });
                  history.push(
                    replaceRouteWithParams(ROUTES.PAYMENTS_CREATION, {
                      newAction: "salary_details"
                    })
                  );
                }
              },
              setLoading
            });
            invokeMessage("success", "Business account added.");
          }
        },
        setLoading
      );
    }

    if (entity === "employee") {
      props.addEmployeeSubmit(
        true,
        {
          cb: () => {
            history.push(
              replaceRouteWithParams(ROUTES.PAYMENTS_CREATION, {
                newAction: "select"
              })
            );
            invokeMessage("success", "Employee added.");
          }
        },
        setLoading
      );
    }

    if (entity === "supplier") {
      props.addSupplierSubmit(
        true,
        {
          cb: (err?: any) => {
            if (!err) {
              history.push(
                replaceRouteWithParams(ROUTES.PAYMENTS_CREATION, {
                  newAction: "select"
                })
              );
              invokeMessage("success", "Supplier added.");
            }
          }
        },
        setLoading
      );
    }

    if (entity === "landlord") {
      props.addLandlordSubmit(
        true,
        {
          cb: (err?: any) => {
            if (!err) {
              history.push(
                replaceRouteWithParams(ROUTES.PAYMENTS_CREATION, {
                  newAction: "select"
                })
              );
              invokeMessage("success", "Landlord added.");
            }
          }
        },
        setLoading
      );
    }
  };

  const onAddWallexEntity = () => {
    if (entity === "self_transfer") {
      props.addSelfTransferSubmit(
        true,
        {
          cb: (err?: any) => {
            if (!err) {
              props.removeForm(INTERNATIONAL_FORM);
              history.push(
                replaceRouteWithParams(ROUTES.PAYMENTS_CREATION, {
                  newAction: "select"
                })
              );
              invokeMessage("success", "Bank account added.");
            }
          }
        },
        setLoading
      );
    }
    if (entity === "supplier") {
      props.addSupplierSubmit(
        true,
        {
          isWallex: true,
          cb: (err?: any) => {
            if (!err) {
              props.removeForm(FORM);
              history.push(
                replaceRouteWithParams(ROUTES.PAYMENTS_CREATION, {
                  newAction: "select"
                })
              );
              invokeMessage("success", "Supplier added.");
            } else {
              for (const field of Object.keys(err.fields)) {
                if (ADD_SUPPLIER_FIRST_SCREEN_ERROR_FIELD.includes(field)) {
                  onBack();
                  break;
                }
              }
            }
          }
        },
        setLoading
      );
    }
    if (entity === "landlord") {
      props.addLandlordSubmit(
        true,
        {
          isWallex: true,
          cb: (err?: any) => {
            if (!err) {
              props.removeForm(FORM);
              history.push(
                replaceRouteWithParams(ROUTES.PAYMENTS_CREATION, {
                  newAction: "select"
                })
              );
              invokeMessage("success", "Landlord added.");
            } else {
              for (const field of Object.keys(err.fields)) {
                if (ADD_LANDLORD_FIRST_SCREEN_ERROR_FIELD.includes(field)) {
                  onBack();
                  break;
                }
              }
            }
          }
        },
        setLoading
      );
    }
  };

  const handleError = (err: any, data?: any) => {
    if (err?.message) {
      invokeMessage("error", err.message);
    }
  };

  const onAddPaymentRequestSubmit = () => {
    props.addPaymentRequestSubmit(
      purpose,
      isWallex,
      {
        cb: (err?: any, data?: any) => {
          const checkoutUrl = data
            ? `${replaceRouteWithParams(ROUTES.PAYMENTS_CREATION, {
                newAction: "checkout"
              })}/?token=${data?.token}`
            : "/";
          if (err) {
            handleError(err, { checkoutUrl });
          } else {
            history.push(checkoutUrl, {
              prevRoute: window.location.pathname
            });
          }
        }
      },
      setLoading
    );
  };

  const onValidatePaymentPayeeSubmit = () => {
    props.validatePaymentPayeeSubmit(
      purpose,
      isWallex,
      {
        cb: (err?: any) => {
          if (err) {
            invokeMessage("error", err.message);
          } else {
            if (isWallex || props.isCryptoPaymentMethod) {
              const options = {
                cb: (err?: any, data?: any) => {
                  if (err) {
                    invokeMessage("error", "Bad request!");
                  }
                  if (data) {
                    onAddPaymentRequestSubmit();
                  }
                }
              };
              const bankIds = props.selectedPayees.map((p: any) => p.bankId);
              if (
                props.selectedPayees[0] &&
                PaymentUtil.isUSDPaymentInHK(
                  props.accountCountryId,
                  props.selectedPayees[0].currencyId as number,
                  props.selectedPayees[0].countryId
                )
              ) {
                props.fetchDateConditions(
                  new Date(),
                  bankIds,
                  3, // HK
                  73, // USD
                  73, // USD,
                  undefined,
                  props.selectedPayees.map((p: any) => p.id),
                  options,
                  setLoading
                );
              } else {
                props.fetchDateConditions(
                  new Date(),
                  bankIds,
                  isWallex
                    ? _get(
                        props.selectedPayees[0],
                        "international.bankCountryId"
                      )
                    : null,
                  isWallex ? _get(props.selectedPayees[0], "currencyId") : null,
                  null,
                  undefined,
                  props.selectedPayees.map((p: any) => p.id),
                  options,
                  setLoading
                );
              }
            } else {
              history.push(
                replaceRouteWithParams(ROUTES.PAYMENTS_CREATION, {
                  newAction: "schedule"
                })
              );
            }
          }
        }
      },
      setLoading
    );
  };

  const validateField = () => {
    const errors = {
      fields: {},
      form: []
    };
    if (entity === "supplier") {
      ADD_SUPPLIER_FIRST_SCREEN_ERROR_FIELD.forEach(field => {
        if (props.controls[field] && !props.controls[field]?.value) {
          errors.fields[field] = ["REQUIRED_FIELD"];
        }
      });
    } else if (entity === "landlord") {
      const isBusiness = props.controls["is_business"]?.value === "true";

      ADD_LANDLORD_FIRST_SCREEN_ERROR_FIELD.forEach(field => {
        if (isBusiness && (field === "first_name" || field === "last_name")) {
          return;
        }
        if (!isBusiness && field === "company_name") {
          return;
        }
        if (props.controls[field] && !props.controls[field]?.value) {
          errors.fields[field] = ["REQUIRED_FIELD"];
        }
      });
    }

    if (!_isEmpty(errors.fields)) {
      props.parseServerErrors(errors);
      return false;
    }

    return true;
  };

  const moveForward = () => {
    if (action === "select") {
      setLoading(true); // ok
      setTimeout(() => {
        setLoading(false); // ok
        if (isWallex) {
          if (entity === "landlord") {
            // move to checkout
            onValidatePaymentPayeeSubmit();
          }
          if (entity === "supplier") {
            // move to invoice details
            history.push(
              replaceRouteWithParams(ROUTES.PAYMENTS_CREATION, {
                newAction: "invoice_details"
              })
            );
          }
          if (entity === "self_transfer") {
            // move to bank_account details
            history.push(
              replaceRouteWithParams(ROUTES.PAYMENTS_CREATION, {
                newEntity: "self_transfer",
                newAction: "bank_account_details"
              })
            );
          }
        } else {
          if (entity === "supplier") {
            // move to invoice details
            if (
              props.selectedPayeesIds.length === 1 &&
              props.payees.find(
                (payee: any) => payee.id === props.selectedPayeesIds[0]
              ).ipaymyEvent === "TAX2019"
            ) {
              history.push(
                replaceRouteWithParams(ROUTES.PAYMENTS_CREATION, {
                  newEntity: "tax",
                  newAction: "payment_details"
                })
              );
            } else {
              history.push(
                replaceRouteWithParams(ROUTES.PAYMENTS_CREATION, {
                  newAction: "invoice_details"
                })
              );
            }
            return;
          }

          if (props.isCryptoPaymentMethod) {
            onValidatePaymentPayeeSubmit();
          } else {
            if (entity === "landlord") {
              // move to schedule
              history.push(
                replaceRouteWithParams(ROUTES.PAYMENTS_CREATION, {
                  newAction: "schedule"
                })
              );
            }
            if (entity === "employee") {
              // move to schedule
              history.push(
                replaceRouteWithParams(ROUTES.PAYMENTS_CREATION, {
                  newAction: "schedule"
                })
              );
            }
          }
        }
      }, 500);
    } else {
      if (isWallex) {
        if (entity === "self_transfer") {
          onAddWallexEntity();
        } else {
          setLoading(true);
          // move to step 2
          if (step !== 2) {
            setTimeout(() => {
              // check required field before move to step 2
              if (!validateField()) {
                setLoading(false);
                return;
              }
              setStep(2);
              setLoading(false);
              return;
            }, 500);
          } else {
            onAddWallexEntity();
          }
        }
      } else {
        onAddDomesticEntity();
      }
    }
  };

  const submitLabel = useMemo(() => {
    if (action === "add" && (!isHas2Step || (isHas2Step && step === 2))) {
      return loading
        ? `Adding ${normalizedEntity}...`
        : `Add ${normalizedEntity}`;
    }

    return loading ? "Moving forward..." : "Move forward";
  }, [step, action, loading]);

  const onBack = () => {
    if (isHas2Step && step === 2) {
      setStep(1);
      return;
    }
    history.goBack();
  };

  const renderBtnSubmit = () => {
    return (
      <>
        <Button
          id={"ipaymy_btn_select_recipient"}
          wrapperClassName={styles.wrapperButton}
          onClick={moveForward}
          type="primary"
          htmlType="button"
          loading={
            loading ||
            disabled ||
            (props.selectedPayeesIds.length === 0 && action === "select")
          }
          autoScrollToFirstError={true}
        >
          {submitLabel}
        </Button>
      </>
    );
  };

  const renderDesktopView = () => {
    return (
      <DesktopModalLayout
        maxWidth450={true}
        onSubmit={moveForward}
        title={props.titleLabel}
        onClose={props.onClose}
        onBack={onBack}
        progress={
          <Progress colors={["#937CF2", "#A581E8"]} value={props.progress} />
        }
        submitButtonClassName={""}
        btnSubmit={renderBtnSubmit()}
      >
        {renderContent()}
      </DesktopModalLayout>
    );
  };

  const renderMobileView = () => {
    return (
      <MainLayout
        header={{
          title: props.titleLabel,
          hideMenu: true,
          onBack,
          onClose: props.onClose,
          progress: (
            <Progress colors={["#937CF2", "#A581E8"]} value={props.progress} />
          )
        }}
        footer={renderBtnSubmit()}
      >
        {renderContent()}
      </MainLayout>
    );
  };

  const renderContent = () => {
    return (
      <div className={styles.wrapperCustomer} hidden={props.isFetchingCustomer}>
        {purpose !== "salary_business" ? (
          <>
            <div className={styles.selectGroup}>
              <SelectGroup
                className={classnames(styles.customSelectGroup)}
                options={[
                  { label: `Select ${normalizedEntity}`, value: "select" },
                  { label: `Add ${normalizedEntity}`, value: "add" }
                ]}
                onChange={onChangeMode}
                value={action}
                defaultValue={action}
              />
            </div>

            <div>
              {action === "select" && <SelectEntity entity={entity} />}
              {action === "add" && <AddEntity entity={entity} step={step} />}
            </div>
          </>
        ) : (
          <>{action === "add" && <AddEntity entity={entity} step={step} />}</>
        )}
      </div>
    );
  };

  return (
    <FeatureGate feature="NORMAL_PAYMENT">
      <BrowserView>{renderDesktopView()}</BrowserView>
      <MobileView>{renderMobileView()}</MobileView>
    </FeatureGate>
  );
};

export default AddOrSelectEntity;
