/**
 * Actions
 *
 * ActionCreators are like the "pub" of pubsub. It's just broadcasting an event (action).
 * So, don't couple it with a specific reducer. Let ANY reducer listen to
 * an action.
 *
 * `typesafe-actions` has a nice utility function so we don't need to define action types as
 * constants. Use it together with `getType(type)` in the reducers.
 */
import { createAction } from "typesafe-actions";
import { CardInfo, additionalData } from "./types";
import { LoadingFunc } from "src/ipm-shared/Utils/ReduxSagaEffects";

type cbType = (err?: any, data?: any) => void;

export const fetchCards = createAction(
  "cards/FETCH",
  resolve => (
    paymentToken?: string,
    defaultSelectedCard?: number | "last",
    noneSetSelectedId?: boolean,
    nocache?: boolean
  ) =>
    resolve({
      paymentToken,
      defaultSelectedCard,
      noneSetSelectedId,
      nocache
    })
);

export const setCardIsFetching = createAction(
  "cards/SET_IS_FETCHING",
  resolve => (args: boolean) => resolve(args)
);

export const setCards = createAction(
  "cards/SET",
  resolve => (args: {
    isFetching: boolean;
    cards: Array<{
      id: number;
      name: string;
      last4: string;
      bankId: number;
      brandId: number;
      expiryMonth: string;
      expiryYear: string;
      isWallet?: boolean;
    }>;
    selectedId?: number;
    isInitialFetch?: boolean;
  }) => resolve(args)
);

export const deleteCard = createAction(
  "cards/DELETE",
  resolve => (id: number, cb?: cbType) => resolve({ id, cb })
);

export const setSelectedCard = createAction(
  "cards/SET_SELECTED",
  resolve => (args: { id: number }) => resolve(args)
);

export const selectCardToEdit = createAction(
  "cards/SELECT_TO_EDOT",
  resolve => (args: { id: number }) => resolve(args)
);

// export const submitAdyenAddCardForm = createAction("cards/ADYEN_SUBMIT_ADD");
// export const submitAdyenEditCardForm = createAction("cards/ADYEN_SUBMIT_EDIT");

// Adyen
// export const adyenEncryptCard = createAction(
//   "cards/ADYEN_CARD_ENCRYPT",
//   resolve => (
//     args: Partial<{
//       number: string;
//       cvc: string;
//       expiryMonth: string;
//       expiryYear: string;
//       holderName: string;
//     }>
//   ) => resolve({ card: args })
// );

// export const adyenStoreToken = createAction(
//   "cards/ADYEN_CARD_TOKEN_STORE",
//   resolve => (token: string) => resolve(token)
// );

export const submitAddCardForm = createAction(
  "cards/SUBMIT_ADD",
  resolve => (args: {
    cardInfo: CardInfo;
    cardholderName?: string;
    cb?: (cardId: number) => void;
    errCb?: (errors: any) => void;
    loadingFunc?: LoadingFunc;
  }) => resolve(args)
);

export const editCard = createAction(
  "cards/EDIT_ADD",
  resolve => (args: {
    additionalData: additionalData;
    clearIsFavourite: boolean;
    cardId?: number;
    errCb?: (errors: any) => void;
  }) => resolve(args)
);

export const createUserStripeCardIntent = createAction(
  "cards/U!SER_STRIPE_CARD_INTENT_CREATE",
  resolve => (
    args: { acquirerId?: number },
    cb: (clientSecret: string) => void
  ) => resolve({ args, cb })
);
