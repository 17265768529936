import React, { useState } from "react";
import InputText from "src/bepaid/components/Form/controls/InputText";
import Button from "src/bepaid/components/Form/controls/Button";
import { CHECKOUT_FORM } from "src/ipm-shared/store/model/Card/const";

import styles from "./ApplyPromoteCode.module.scss";

const View = (props: any) => {
  const [code, setCode] = useState("");

  const onChangeCode = (v: string) => {
    setCode(v);
  };

  return (
    <div className={styles.wrapper}>
      <label className={styles.label} htmlFor="input-promote-code">
        Promo code
      </label>
      <div className={styles.container}>
        <div className={styles.inputContainer}>
          <InputText
            id="input-promote-code"
            name="code"
            placeholder="Code"
            className={styles.input}
            form={CHECKOUT_FORM}
            onChangeCustom={onChangeCode}
          />
        </div>
        <Button
          disabled={String(code).length === 0}
          loading={props.loading}
          wrapperClassName={styles.button}
          htmlType="button"
          onClick={props.applyCoupon}
        >
          {props.loading ? "Applying..." : "Apply"}
        </Button>
      </div>
    </div>
  );
};

export default View;
