const t = {
  account_type: 1,
  additional_data_modal: 36,
  additional_invoice_setting: 18,
  amex_promo_modal: 35,
  business_first_login_fetch_tour_in_pay_fetch: 32,
  business_first_login_pay_tour_in_pay_fetch: 31,
  cards_pal_popup: 27,
  close_kyc_status_bar: 14,
  credit_confirm_modal: 22,
  credit_confirmed_modal: 23,
  credit_program_v1_notification: 21,
  cross_border_announce: 11,
  cross_border_card_flag: 12,
  cross_border_new_icon: 10,
  edit_schedule_indicator: 13,
  fb199: 17,
  fetch_highlight_tour: 33,
  first_my_signup: 2,
  flashpay_promo_modal: 37,
  int_pay_enabled_notification: 20,
  int_pay_reminder_notification: 24,
  iras_popup: 16,
  kyc_first_time_popup: 28,
  my_credit_card: 15,
  next_day_payout: 6,
  nric_photo: 3,
  old_card_token_archived_notification: 29,
  payment_interest_confirmation: 26,
  personal_first_login_tour_in_pay_fetch: 30,
  points_guarantee: 7,
  referral: 5,
  rentmay_promo: 9,
  sg_myinfo: 25,
  sg_tax_2019: 8,
  show_suggestion_modal: 34,
  sms_interest: 19,
  two_day_payout_announce: 4
};
export default t;
