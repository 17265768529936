export const ADD_FORM = "add_request_payment_form";
export const PAGE_COUNT = 50;
export const REFUND_FORM = "be_paid_refund_form";
export const MARK_PAYMENT_AS_PAID_FORM = "be_paid_mark_payment_as_paid_form";

export enum EMAIL_STATUS {
  SENDING = 2,
  SUCCESS,
  ERROR
}

export enum SMS_STATUS {
  SENDING = 2,
  SUCCESS,
  ERROR
}

export const BE_PAID_REFUND_REASON = [
  {
    label: "Customer Request",
    value: "Customer Request"
  },
  {
    label: "Duplicate Charge",
    value: "Duplicate Charge"
  },
  {
    label: "Missing Item(s)",
    value: "Missing Item(s)"
  },
  {
    label: "Other",
    value: "Other"
  }
];
