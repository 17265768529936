import React from "react";
import Skeleton from "antd/es/skeleton";

interface IProps {
  loading: boolean;
  force?: boolean;

  [key: string]: any;
}

const FetchSkeleton = ({ loading, force = true, ...props }: IProps) => {
  if (force) {
    return loading ? <Skeleton active={true} {...props} /> : props.children;
  } else {
    return props.children;
  }
};

export default FetchSkeleton;
