import * as React from "react";
import {
  BrowserView,
  MobileView,
  isDesktop,
  isMobile
} from "react-device-detect";
import classNames from "classnames";

import T from "src/ipm-shared/Utils/Intl";
import StripeCardCVVForm from "src/ipm-shared/components/StripeCardCVVForm";

import Button from "src/bepaid/components/Form/controls/Button";
import DesktopModalLayout from "src/bepaid/components/Modal/components/DesktopModalLayout";
import MainLayout from "src/bepaid/layouts/MainLayout";

import { IProps } from ".";

import addNewCardStyles from "src/bepaid/layouts/Menu/components/AddNewCard/AddNewCard.module.scss";
import cardFormStyles from "src/bepaid/components/CardForm/styles.module.scss";

const RecollectCVVModal = (props: IProps) => {
  // @ts-ignore
  let stripeCardCvcForm: StripeCardCVVForm = React.useRef(null);

  const { closeModal, extraInfo } = props;

  const handleClose = () => {
    closeModal();
    extraInfo.onClose();
  };

  const renderStripe = () => {
    return (
      <StripeCardCVVForm
        ref={(ref: StripeCardCVVForm) => (stripeCardCvcForm = ref)}
        acquirerId={extraInfo.acquirerId}
      />
    );
  };

  const renderBraintree = () => {
    return "Not supported";
  };

  const renderCko = () => {
    return "Not supported";
  };

  const renderWP = () => {
    return "Not supported";
  };

  const handleSubmit = () => {
    // tslint:disable-next-line:no-unused-expression
    extraInfo.provider === "stripe" &&
      extraInfo.callback(stripeCardCvcForm.getCardCVVElement());
    closeModal();
  };

  const renderContent = () => {
    return (
      <div
        className={classNames(
          cardFormStyles.wrapper,
          addNewCardStyles.wrapper,
          {
            [addNewCardStyles.wrapperDesktop]: isDesktop,
            [addNewCardStyles.wrapperMobile]: isMobile
          }
        )}
        style={{ height: "50%" }}
      >
        <div className={addNewCardStyles.form}>
          <div className={addNewCardStyles.formContent}>
            {extraInfo.provider === "stripe" && renderStripe()}
            {extraInfo.provider === "braintree" && renderBraintree()}
            {extraInfo.provider === "cko" && renderCko()}
            {extraInfo.provider === "worldpay" && renderWP()}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <BrowserView>
        <DesktopModalLayout
          maxWidth450={true}
          title="Recollect CVC/CVV"
          onClose={handleClose}
          submitLabel={T.transl("SUBMIT_BUTTON")}
          onSubmit={handleSubmit}
        >
          {renderContent()}
        </DesktopModalLayout>
      </BrowserView>
      <MobileView>
        <MainLayout
          header={{
            title: "Recollect CVC/CVV",
            onClose: handleClose,
            hideMenu: true
          }}
          footer={
            <Button type="primary" htmlType="submit" onClick={handleSubmit}>
              {T.transl("SUBMIT_BUTTON")}
            </Button>
          }
        >
          {renderContent()}
        </MainLayout>
      </MobileView>
    </>
  );
};

export default RecollectCVVModal;
