// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SearchCompanyForm_textDescription__CmNcx{display:block;margin-top:7px;font-size:12px !important}.SearchCompanyForm_textDescription__CmNcx .SearchCompanyForm_linkHereLabel__1buLV{color:#989898}.SearchCompanyForm_hidden__1t03c{display:none;height:0;margin:0 !important;padding:0 !important;visibility:hidden;opacity:0;-webkit-transition:visibility 0s 2s,opacity 2s linear;-o-transition:visibility 0s 2s,opacity 2s linear;transition:visibility 0s 2s,opacity 2s linear}", "",{"version":3,"sources":["webpack://./src/bepaid/components/SearchCompanyForm/SearchCompanyForm.module.scss"],"names":[],"mappings":"AAAA,0CAAiB,aAAa,CAAC,cAAc,CAAC,yBAAyB,CAAC,kFAAgC,aAAa,CAAC,iCAAQ,YAAY,CAAC,QAAQ,CAAC,mBAAmB,CAAC,oBAAoB,CAAC,iBAAiB,CAAC,SAAS,CAAC,qDAA4C,CAA5C,gDAA4C,CAA5C,6CAA6C","sourcesContent":[".textDescription{display:block;margin-top:7px;font-size:12px !important}.textDescription .linkHereLabel{color:#989898}.hidden{display:none;height:0;margin:0 !important;padding:0 !important;visibility:hidden;opacity:0;transition:visibility 0s 2s,opacity 2s linear}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textDescription": "SearchCompanyForm_textDescription__CmNcx",
	"linkHereLabel": "SearchCompanyForm_linkHereLabel__1buLV",
	"hidden": "SearchCompanyForm_hidden__1t03c"
};
export default ___CSS_LOADER_EXPORT___;
