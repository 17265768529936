import * as creditCardType from "credit-card-type";
import {
  MY_ACCEPTABLE_BRAND,
  ACCEPTABLE_BRAND,
  COUNTRY_SUPPORT_UNIONPAY
} from "src/ipm-shared/store/model/CardBrand/const";
import { BRAND } from "src/ipm-shared/Utils/Images";
import _includes from "lodash-es/includes";
import _get from "lodash-es/get";

import { ACQUIRER_ID } from "src/ipm-shared/store/model/Card/const";

const CardUtil = {
  isValidAdyenCardType: (cardNumber: string): string => {
    const cardType = creditCardType(
      cardNumber.replace(/ /g, "")
    ).filter((card: any) => _includes(MY_ACCEPTABLE_BRAND, card.type));

    return _get(cardType, "0.type", "");
  },

  toBrandId: (brandName: string) => {
    switch (brandName) {
      case ACCEPTABLE_BRAND[0]:
        return BRAND.VISA;
      case ACCEPTABLE_BRAND[1]:
      case ACCEPTABLE_BRAND[2]:
        return BRAND.MASTER_CARD;
      case ACCEPTABLE_BRAND[3]:
        return BRAND.UNION_PAY;
      case ACCEPTABLE_BRAND[4]:
        return BRAND.AMEX;
    }

    return -1;
  },

  isMyCard: (acquirerId: number) => {
    return CardUtil.isAdyen(acquirerId) || CardUtil.isMYStripe(acquirerId);
  },

  isSGCard: (acquirerId: number) => {
    let sgWPCard =
      acquirerId === ACQUIRER_ID.WORLDPAY_SG_LIVE ||
      acquirerId === ACQUIRER_ID.WORLDPAY_SG_TEST;
    return (
      CardUtil.isSGStripeDev(acquirerId) ||
      CardUtil.isSGStripeProd(acquirerId) ||
      CardUtil.isMastercardVip13StripeDev(acquirerId) ||
      CardUtil.isMastercardVip13StripeProd(acquirerId) ||
      sgWPCard
    );
  },

  isHKCard: (acquirerId: number) => {
    return (
      CardUtil.isHKStripeDev(acquirerId) ||
      CardUtil.isHKStripeProd(acquirerId) ||
      CardUtil.isHKTP1StripeDev(acquirerId) ||
      CardUtil.isHKTP1StripeProd(acquirerId)
    );
  },

  isAdyen: (acquirerId: number) => {
    return CardUtil.isAdyenDev(acquirerId) || CardUtil.isAdyenProd(acquirerId);
  },

  isStripe: (acquirerId: number): boolean => {
    return 3 <= acquirerId && acquirerId <= 20;
  },

  isWorldpay: (acquirerId: number): boolean => {
    return 81 <= acquirerId && acquirerId <= 120;
  },

  isWorldpayProd: (acquirerId: number): boolean => {
    return (
      acquirerId === 82 ||
      acquirerId === 92 ||
      acquirerId === 94 ||
      acquirerId === 96 ||
      acquirerId === 98 ||
      acquirerId === 100 ||
      acquirerId === 102
    );
  },

  isCheckout: (acquirerId: number) => {
    return (
      ACQUIRER_ID.CHECKOUT_PROD === acquirerId ||
      ACQUIRER_ID.CHECKOUT_TEST === acquirerId
    );
  },

  isBraintree: (acquirerId: number): boolean => {
    return 51 <= acquirerId && acquirerId <= 80;
  },

  isMYStripe: (acquirerId: number) => {
    return (
      CardUtil.isMYStripeDev(acquirerId) || CardUtil.isMYStripeProd(acquirerId)
    );
  },

  isAdyenDev: (acquirerId: number) => {
    return ACQUIRER_ID.ADYEN_TEST === acquirerId;
  },

  isAdyenProd: (acquirerId: number) => {
    return ACQUIRER_ID.ADYEN_PROD === acquirerId;
  },

  isSGStripeDev: (acquirerId: number) => {
    return ACQUIRER_ID.STRIPE_TEST === acquirerId;
  },

  isSGStripeProd: (acquirerId: number) => {
    return ACQUIRER_ID.STRIPE_PROD === acquirerId;
  },

  isMYStripeDev: (acquirerId: number) => {
    return ACQUIRER_ID.STRIPE_TEST_MY === acquirerId;
  },

  isMYStripeProd: (acquirerId: number) => {
    return ACQUIRER_ID.STRIPE_PROD_MY === acquirerId;
  },

  isHKStripeDev: (acquirerId: number) => {
    return ACQUIRER_ID.STRIPE_TEST_HK === acquirerId;
  },

  isHKStripeProd: (acquirerId: number) => {
    return ACQUIRER_ID.STRIPE_PROD_HK === acquirerId;
  },

  isHKTP1StripeDev: (acquirerId: number) => {
    return ACQUIRER_ID.STRIPE_TEST_HK_TP1 === acquirerId;
  },

  isHKTP1StripeProd: (acquirerId: number) => {
    return ACQUIRER_ID.STRIPE_PROD_HK_TP1 === acquirerId;
  },

  isMastercardVip13StripeDev: (acquirerId: number) => {
    return ACQUIRER_ID.STRIPE_TEST_MASTERCARD_VIP13 === acquirerId;
  },

  isMastercardVip13StripeProd: (acquirerId: number) => {
    return ACQUIRER_ID.STRIPE_PROD_MASTERCARD_VIP13 === acquirerId;
  },

  getAdyenPublicKey: (acquirerId: number) => {
    if (CardUtil.isAdyenDev(acquirerId)) {
      return (process.env.REACT_APP_ADYEN_PUBLIC_KEY_DEV as string).toString();
    } else if (CardUtil.isAdyenProd(acquirerId)) {
      return (process.env
        .REACT_APP_ADYEN_PUBLIC_KEY_PRODUCTION as string).toString();
    }

    return "";
  },
  getWorldpayPublicKey: (acquirerId: number) => {
    if (
      ACQUIRER_ID.WORLDPAY_AU_LIVE === acquirerId ||
      ACQUIRER_ID.WORLDPAY_AU_TEST === acquirerId
    ) {
      return (process.env
        .REACT_APP_WORLDPAY_PUBLIC_KEY_LIVE_AU as string).toString();
    }
    if (
      ACQUIRER_ID.WORLDPAY_AU_MOTOROLA_LIVE === acquirerId ||
      ACQUIRER_ID.WORLDPAY_AU_MOTOROLA_TEST === acquirerId
    ) {
      return (process.env
        .REACT_APP_WORLDPAY_PUBLIC_KEY_LIVE_AU as string).toString();
    }
    if (
      ACQUIRER_ID.WORLDPAY_HK_LIVE === acquirerId ||
      ACQUIRER_ID.WORLDPAY_HK_TEST === acquirerId
    ) {
      return (process.env
        .REACT_APP_WORLDPAY_PUBLIC_KEY_LIVE_HK as string).toString();
    }
    if (
      ACQUIRER_ID.WORLDPAY_MY_MOTOROLA_LIVE === acquirerId ||
      ACQUIRER_ID.WORLDPAY_MY_MOTOROLA_TEST === acquirerId
    ) {
      return (process.env
        .REACT_APP_WORLDPAY_PUBLIC_KEY_LIVE_MY as string).toString();
    }
    if (
      ACQUIRER_ID.WORLDPAY_MY_LIVE === acquirerId ||
      ACQUIRER_ID.WORLDPAY_MY_TEST === acquirerId
    ) {
      return (process.env
        .REACT_APP_WORLDPAY_PUBLIC_KEY_LIVE_MY as string).toString();
    } else {
      return (process.env
        .REACT_APP_WORLDPAY_PUBLIC_KEY_LIVE_SG as string).toString();
    }

    return "";
  },

  getWorldpayChallengeEndpoint: (isProduction: boolean) => {
    if (isProduction) {
      return (process.env
        .REACT_APP_WORLDPAY_CHALLENGE_ENDPOINT_LIVE as string).toString();
    } else {
      return (process.env
        .REACT_APP_WORLDPAY_CHALLENGE_ENDPOINT_TEST as string).toString();
    }
  },

  getWorldpayDDCEndpoint: (isProduction: boolean) => {
    if (isProduction) {
      return (process.env
        .REACT_APP_WORLDPAY_DDC_ENDPOINT_LIVE as string).toString();
    } else {
      return (process.env
        .REACT_APP_WORLDPAY_DDC_ENDPOINT_TEST as string).toString();
    }
  },

  getStripePublicKey: (acquirerId: number): string => {
    switch (acquirerId) {
      case 1: // legacy adyen
        return (process.env
          .REACT_APP_ADYEN_PUBLIC_KEY_DEV as string).toString();
      case 2: // legacy adyen
        return (process.env
          .REACT_APP_ADYEN_PUBLIC_KEY_PRODUCTION as string).toString();
      case 3:
        return (process.env
          .REACT_APP_STRIPE_PUBLIC_KEY_DEV as string).toString();
      case 4:
        return (process.env
          .REACT_APP_STRIPE_PUBLIC_KEY_PRODUCTION as string).toString();
      case 5:
        return (process.env
          .REACT_APP_STRIPE_PUBLIC_KEY_DEV_MY as string).toString();
      case 6:
        return (process.env
          .REACT_APP_STRIPE_PUBLIC_KEY_PRODUCTION_MY as string).toString();
      case 7:
        return (process.env
          .REACT_APP_STRIPE_PUBLIC_KEY_DEV_HK as string).toString();
      case 8:
        return (process.env
          .REACT_APP_STRIPE_PUBLIC_KEY_PRODUCTION_HK as string).toString();
      case 9:
        return (process.env
          .REACT_APP_STRIPE_PUBLIC_KEY_DEV_HK_TP1 as string).toString();
      case 10:
        return (process.env
          .REACT_APP_STRIPE_PUBLIC_KEY_PRODUCTION_HK_TP1 as string).toString();
      case 11:
        return (process.env
          .REACT_APP_STRIPE_PUBLIC_KEY_DEV_MASTERCARD_VIP13 as string).toString();
      case 12:
        return (process.env
          .REACT_APP_STRIPE_PUBLIC_KEY_PRODUCTION_MASTERCARD_VIP13 as string).toString();
      case 13:
        return (process.env
          .REACT_APP_STRIPE_PUBLIC_KEY_DEV_AU as string).toString();
      case 14:
        return (process.env
          .REACT_APP_STRIPE_PUBLIC_KEY_PRODUCTION_AU as string).toString();
    }

    return "";
  },

  getCkoPublicKey: (acquirerId: number): string => {
    switch (acquirerId) {
      case 21:
        return (process.env
          .REACT_APP_CHECKOUT_PUBLIC_KEY_DEV as string).toString();
      case 22:
        return (process.env
          .REACT_APP_CHECKOUT_PUBLIC_KEY_LIVE as string).toString();
    }

    return "";
  },

  getBTPublicKey: (acquirerId: number): string => {
    switch (acquirerId) {
      case 51:
        return (process.env.REACT_APP_BT_PUBLIC_KEY_DEV as string).toString();
      case 52:
        return (process.env.REACT_APP_BT_PUBLIC_KEY_LIVE as string).toString();
    }

    return "";
  },

  getSupportedBrands: (acquirerId: number, countryId: number): number[] => {
    if (CardUtil.isStripe(acquirerId)) {
      if (COUNTRY_SUPPORT_UNIONPAY.indexOf(countryId) > 0) {
        return [2, 5];
      } else {
        return [2];
      }
    } else if (CardUtil.isCheckout(acquirerId)) {
      return [2]; // Amex, CUP on cko was not ready yet.
    } else if (CardUtil.isBraintree(acquirerId)) {
      return [2];
    }

    return [2];
  }
};

export default CardUtil;
