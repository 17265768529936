import { format, isEqual, isWeekend } from "date-fns";
import { WALLEX_KYC_STATUS } from "src/ipm-shared/store/model/OnBoarding/consts";

const PaymentUtil = {
  convertFrequencyString: (frequency: string) => {
    let result = "";

    switch (frequency) {
      case "7 days":
        result = "Weekly";
        break;
      case "1 mon":
        result = "Monthly";
        break;

      case "14 days":
        result = "Biweekly";
        break;

      case "3 mons":
        result = "Quarterly";
        break;

      case "6 mons":
        result = "Biannually";
        break;

      default:
        result = "Once";
    }
    return result;
  },

  payoutTimeBetween: (
    dateLeft: string,
    dateRight: string,
    exclusionDates: Date[]
  ) => {
    const from = new Date(format(new Date(dateLeft), "YYYY-MM-DD"));
    const to = new Date(format(new Date(dateRight), "YYYY-MM-DD"));
    let finalRangeDays: any[];

    const rangeDays = [];
    const d1 = from;
    while (d1 < to) {
      d1.setDate(d1.getDate() + 1);
      rangeDays.push(new Date(d1));
    }

    finalRangeDays = rangeDays
      .filter(
        d =>
          exclusionDates.filter((ex: any) => isEqual(new Date(ex), new Date(d)))
            .length === 0
      )
      .filter(d => !isWeekend(d));

    return finalRangeDays;
  },

  convertToSGD: (amount: number, rate: number): number => {
    return Math.floor(amount * rate);
  },

  isUSDPaymentInHK: (
    accountCountryId: number,
    currencyId: number,
    countryId?: number
  ) => {
    return (
      accountCountryId === 3 &&
      currencyId === 73 &&
      countryId !== undefined &&
      countryId === 3
    );
  },

  isWallexKycInProgress: (wallexKycStatus: number) => {
    return (
      wallexKycStatus !== WALLEX_KYC_STATUS.APPROVED &&
      wallexKycStatus !== WALLEX_KYC_STATUS.REJECTED &&
      wallexKycStatus !== WALLEX_KYC_STATUS.INACTIVE
    );
  },

  convertIpmMccId: (ipmMccId: string) => {
    return ipmMccId ? Number(ipmMccId.split("_")[1]) : 0;
  },

  formatIpmMerchantValue: (data: string) => {
    const str = data.split("_");
    return `${str[0]}_${str[1]}`;
  },
  getInsurancePayeeName: (name: string) => {
    if (
      [
        "Health and Life policy (UOB)",
        "Health/MINDEF/MHA policy (DBS)",
        "Life policy (DBS)",
        "Other Life, Health, Savings & Retirement Plans (DBS)",
        "Zurich Life Singapore policy (DBS)"
      ].includes(name)
    ) {
      return "Singapore Life Ltd";
    }
    if (["501036925001 (OCBC)", "501219000001 (OCBC)"].includes(name)) {
      return "The Great Eastern Life Assurance Co Ltd";
    }
    return name;
  }
};

export default PaymentUtil;
