import _includes from "lodash-es/includes";
import _get from "lodash-es/get";

export default class Is {
  public static live() {
    return (
      _includes(window.location.origin, "web.ipaymy.com") ||
      _includes(window.location.origin, "sg.ipaymy.com") ||
      _includes(window.location.origin, "my.ipaymy.com") ||
      _includes(window.location.origin, "hk.ipaymy.com") ||
      _includes(window.location.origin, "au.ipaymy.com")
    );
  }

  public static MYVisitor() {
    return _includes(window.location.origin, "my.ipaymy.com");
  }

  public static HKVisitor() {
    return _includes(window.location.origin, "hk.ipaymy.com");
  }

  public static SGVisitor() {
    return _includes(window.location.origin, "sg.ipaymy.com");
  }

  public static AUVisitor() {
    return _includes(window.location.origin, "au.ipaymy.com");
  }

  public static sameValues(propertyName: string, inputArray: any) {
    let found = false;
    for (const value of inputArray) {
      if (_get(value, propertyName) === _get(inputArray[0], propertyName)) {
        found = true;
      } else {
        found = false;
        break;
      }
    }
    return found;
  }

  public static differenceValues(
    propertyName: string,
    inputArray: any
  ): boolean {
    let found = false;
    for (const value of inputArray) {
      if (_get(value, propertyName) === _get(inputArray[0], propertyName)) {
        found = false;
      } else {
        found = true;
        break;
      }
    }
    return found;
  }
}
