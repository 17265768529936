import * as React from "react";
import classNames from "classnames";
import { IBaseProps } from "./lib/Base";
import BaseControl from "./lib/Base";
import { connect } from "react-redux";
import * as actions from "../actions";
import * as selectors from "../selectors";
import { RootState } from "src/ipm-shared/store/model/reducers";
import { ControlValueType } from "../types";
import withPropsChecker from "./lib/withPropsChecker";

type ISwitchOption = {
  value: string | number | undefined;
  label: string | React.Component;
};
export type ISwitchProps = IBaseProps & {
  options: ISwitchOption[];
};

const mapStateToProps = (
  state: RootState,
  props: ISwitchProps
): {
  control: ReturnType<typeof selectors.getControl>;
} => ({
  control: selectors.getControl(state, props.name)
});

const mapDispatchToProps = {
  removeControl: actions.removeControl,
  resetControlErrors: actions.resetControlErrors,
  setControl: actions.setControl
};

type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  ISwitchProps;

/**
 * This is one of common controls in the entire app.
 * Use this when you want to show a drop-down list.
 *
 * @base BaseControl.tsx
 */
class Switch extends BaseControl<IProps, any> {
  public static defaultProps = {
    ...BaseControl.defaultProps
  };

  public componentDidMount() {
    const { name, defaultValue, form, displayError, control } = this.props;

    if (this.shouldRevertValueOnMount(control)) {
      return;
    }

    // Init control
    this.props.setControl({
      errors: [],
      form,
      group: this.props.group,
      name,
      value: defaultValue
    });

    this.doValidate(defaultValue, name, displayError, true);
  }

  public componentWillUnmount() {
    this.alive = false;

    if (!this.props.reserveValueOnUnmount) {
      this.props.removeControl(this.props.name);
    } else {
      this.props.resetControlErrors(this.props.name);
    }
  }

  public render() {
    const { options, control, className } = this.props;

    if (control.notFound) {
      return null;
    }

    return (
      <div className={`filter-nations switch-country-section ${className}`}>
        {options.map(o => (
          <span
            key={`${o.value}`}
            className={classNames({
              active: o.value === control.value
            })}
            onClick={this.onChooseOption.bind(this, o.value)}
          >
            {o.label}
          </span>
        ))}
      </div>
    );
  }

  private onChooseOption = (value: number) => {
    const { name, form } = this.props;
    this.props.setControl({
      errors: [],
      form,
      name,
      value
    });

    this.doValidate(value, name);
  };

  private doValidate = (
    value: ControlValueType,
    name: string,
    displayError: boolean = true,
    isInitChange: boolean = false
  ) => {
    // DO nothing
    if (this.props.onChangeCustom) {
      this.props.onChangeCustom(value);
    }
  };
}

export default withPropsChecker(
  connect(mapStateToProps, mapDispatchToProps)(Switch),
  ["defaultValue"]
);
