import React, { useEffect, useMemo, useState } from "react";
import { BrowserView, isDesktop, MobileView } from "react-device-detect";
import DesktopModalLayout from "src/bepaid/components/Modal/components/DesktopModalLayout";
import MainLayout from "src/bepaid/layouts/MainLayout";

import Form from "src/bepaid/components/Form";
import InputText from "src/bepaid/components/Form/controls/InputText";
import Button from "src/bepaid/components/Form/controls/Button";

import ROUTES from "src/bepaid/routes";
import { history } from "src/ipm-shared/store";
import { useMakePaymentUrlParams } from "src/bepaid/pages/Payments/hooks";

import _sortBy from "lodash-es/sortBy";

import { COUNTRY_NATIONAL_MULTIPLE_CURRENCY } from "src/ipm-shared/store/model/Payee/const";
// import { WALLEX_KYC_STATUS } from "src/ipm-shared/store/model/OnBoarding/consts";

import CountryUtil from "src/ipm-shared/Utils/Country";
import { FLAGS } from "src/ipm-shared/Utils/Country";

import classnames from "classnames";
import styles from "./SelectPaymentCountryModal.module.scss";
import { PAYMENT_METHOD_TYPES } from "src/ipm-shared/store/model/PaymentRequest/const";

const listCountries = [
  "MY",
  "AU",
  "ID",
  "IN",
  "HK",
  "PH",
  "US",
  "JP",
  "FR",
  "GB",
  "DE",
  "CN",
  "NZ",
  "TH",
  "AT",
  "BE",
  "CY",
  "EE",
  "ES",
  "FI",
  "GR",
  "IE",
  "IT",
  "LT",
  "LU",
  "LV",
  "MT",
  "NL",
  "PT",
  "SI",
  "SK"
];

const View = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [selectedCountry, selectCountry] = useState<any>(null);
  const [inputText, setInputText] = useState("");

  const setInputSearchText = (value: any) => {
    setTimeout(() => {
      setInputText(value);
    }, 300);
  };

  const countries = _sortBy(
    props.getAllCountries
      .map((c: any) => {
        const countryIndex = listCountries.indexOf(c.code);
        return {
          ...c,
          countryIndex
        };
      })
      .filter((c: any) => {
        return c.countryIndex > -1;
      })
      .map((c: any) => ({
        ...c,
        flag: FLAGS[c.code]
      })),
    ["name"]
  );

  const { paymentType, replaceRouteWithParams } = useMakePaymentUrlParams(
    ROUTES.PAYMENTS_CREATION
  );

  const moveForward = () => {
    setLoading(true); // ok

    setTimeout(() => {
      const country = countries.find(
        (c: any) => c.code === selectedCountry?.code
      );

      props.setPaymentCountryCode(country.code);

      if (COUNTRY_NATIONAL_MULTIPLE_CURRENCY.includes(country.code)) {
        history.push(
          replaceRouteWithParams(ROUTES.PAYMENTS_CREATION, {
            newEntity: "currency",
            newAction: "select"
          })
        );
      } else {
        props.setPaymentCurrencyCode(CountryUtil.getCurrencyCode(country.code));
        history.push(
          replaceRouteWithParams(ROUTES.PAYMENTS_CREATION, {
            newPaymentType: paymentType,
            newEntity: "entity",
            newAction: "select"
          })
        );
      }
    }, 500);
  };

  const optionsFiltered = useMemo(() => {
    const list = countries;

    if (inputText.trim()) {
      return list.filter((l: any) => {
        return (
          String(l.name)
            .toLowerCase()
            .indexOf(inputText.toLowerCase()) > -1
        );
      });
    }

    return list;
  }, [inputText, countries]);

  const renderGlobalPromotionContent = () => {
    return (
      <div className={classnames(styles.globalPromotion)}>
        <a
          href="mailto:support@ipaymy.com?subject=Requesting a country for international payments"
          target="_blank"
        >
          <div className={styles.globeIcon} />
          Not seeing your country? Click to request it!
        </a>
      </div>
    );
  };

  const renderContent = () => {
    return (
      <>
        <div
          className={classnames(styles.wrapper, {
            [styles.desktop]: isDesktop
          })}
        >
          <div className={styles.formContainer}>
            <Form className={styles.form}>
              <InputText
                placeholder="Enter country name…"
                onChangeCustom={setInputSearchText}
              />
            </Form>
            {optionsFiltered.length === 0 && inputText.trim().length > 0 ? (
              <>
                <p>No results found.</p>
                {renderGlobalPromotionContent()}
              </>
            ) : (
              <>
                {renderGlobalPromotionContent()}
                <ul className={styles.list}>
                  {optionsFiltered.map((country: any) => (
                    <li
                      key={country.id}
                      onClick={selectCountry.bind(null, country)}
                      className={classnames({
                        [styles.selected]:
                          country?.code === selectedCountry?.code
                      })}
                    >
                      <div className={styles.container}>
                        <img
                          className={styles.flag}
                          src={country.flag}
                          alt=""
                        />
                        <span>{country.name}</span>
                      </div>
                    </li>
                  ))}
                </ul>
              </>
            )}
          </div>
        </div>
      </>
    );
  };

  const renderDesktopView = () => {
    return (
      <DesktopModalLayout
        id={"ipaymy_select_country_international_payment"}
        title={"Select country"}
        onClose={props.onClose}
        maxWidth450={true}
        submitLabel={loading ? "Moving forward..." : "Move forward"}
        onSubmit={moveForward}
        loading={!selectedCountry || loading}
      >
        {renderContent()}
      </DesktopModalLayout>
    );
  };

  const renderMobileView = () => {
    return (
      <MainLayout
        id={"ipaymy_select_country_international_payment"}
        header={{
          title: "Select country",
          hideMenu: true,
          onClose: props.onClose
        }}
        footer={
          <Button
            wrapperClassName={styles.wrapperButton}
            className={styles.button}
            onClick={moveForward}
            type="primary"
            htmlType="button"
            loading={!selectedCountry || loading}
          >
            {loading ? "Moving forward..." : "Move forward"}
          </Button>
        }
      >
        {renderContent()}
      </MainLayout>
    );
  };

  useEffect(() => {
    // if (
    //   !props.isSingaporeAccount ||
    //   props.wallexKycStatus !== WALLEX_KYC_STATUS.APPROVED
    // ) {
    //   history.push(ROUTES.ROOT);
    //   return;
    // }
    if (
      props.isSingaporeAccount &&
      props.isBusinessAccount &&
      props.getConfirmedMyInfo === undefined
    ) {
      const redirectPath =
        // ROUTES.PAYMENTS_CREATION.replace(
        //   ":paymentType",
        //   "international"
        // )
        //   .replace("entity", "country")
        //   .replace("action", "select");

        replaceRouteWithParams(ROUTES.PAYMENTS_CREATION, {
          newPaymentType: paymentType,
          newEntity: "country",
          newAction: "select"
        });
      props.fetchMyInfoSingPass(({ confirmed = false, info }: any) => {
        if (!confirmed) {
          history.push(
            `${ROUTES.MY_INFO}?redirect_url=${encodeURIComponent(redirectPath)}`
          );
        }
      });
    }
  }, []);

  useEffect(() => {
    props.setPaymentMethodType(PAYMENT_METHOD_TYPES.CARD);
  }, []);

  return (
    <>
      <BrowserView>{renderDesktopView()}</BrowserView>
      <MobileView>{renderMobileView()}</MobileView>
    </>
  );
};

export default View;
