import React, { useState } from "react";
import { MobileView, BrowserView, isDesktop } from "react-device-detect";
import * as copy from "copy-to-clipboard";
import classNames from "classnames";

import { FetchModalID } from "src/ipm-shared/components/GlobalUI/actions";
import { history } from "src/ipm-shared/store";

import Image from "src/bepaid/components/Image";
import Button from "src/bepaid/components/Form/controls/Button";
import InputText from "src/bepaid/components/Form/controls/InputText";
import DesktopModalLayout from "src/bepaid/components/Modal/components/DesktopModalLayout";
import MainLayout from "src/bepaid/layouts/MainLayout";

import IconInvoiceSent from "src/bepaid/assets/images/invoices/invoice_sent.svg";
import IconCopyLink from "src/bepaid/assets/images/common/copy_link.svg";
import IconLinkCopied from "src/bepaid/assets/images/common/link_copied.svg";

import IconWhatsApp from "src/bepaid/assets/images/icons/Whatsapp.svg";
import IconMessenger from "src/bepaid/assets/images/icons/Messenger.svg";
import IconEmail from "src/bepaid/assets/images/icons/Email.svg";
import ShareSocial, {
  SOCIAL_TYPES
} from "src/ipm-shared/components/ShareSocial";

import ROUTES from "src/bepaid/routes";

import styles from "./InvoiceSentModal.module.scss";
import { CREATE_INVOICE_FORM } from "src/ipm-shared/store/model/CollectedAccount/const";
import { INCENTIVES_DETAIL_FORM } from "src/ipm-shared/store/model/Customer/const";

function InvoiceSentModal(props: any) {
  const [isCopied, setCopySate] = useState(false);
  const { url, invoiceId = "" } = props.extraInfo;

  const handleCopy = () => {
    copy(url);
    setCopySate(!isCopied);
  };

  const goToInvoiceDetail = (e: any) => {
    e.preventDefault();
    props.toggleModal(FetchModalID.INVOICE_SENT);
    history.push(ROUTES.INVOICE_DETAILS.replace(":id", invoiceId));
  };

  const onSubmit = () => {
    props.toggleModal(FetchModalID.INVOICE_SENT);
    props.removeForm(CREATE_INVOICE_FORM);
    props.removeForm(INCENTIVES_DETAIL_FORM);
    props.selectCustomers([]);
    props.selectAllItemIds([]);
    history.push(ROUTES.ROOT);
  };

  const onRequest = () => {
    props.toggleModal(FetchModalID.INVOICE_SENT);
    props.removeForm(CREATE_INVOICE_FORM);
    props.removeForm(INCENTIVES_DETAIL_FORM);
    props.selectCustomers([]);
    props.selectAllItemIds([]);
    props.toggleModal(FetchModalID.CREATE_INVOICE);
  };

  const shareIcons = [
    { key: 1, src: IconWhatsApp, type: SOCIAL_TYPES.WHATSAPP },
    { key: 2, src: IconMessenger, type: SOCIAL_TYPES.FACEBOOK_MESSENGER },
    { key: 3, src: IconEmail, type: SOCIAL_TYPES.EMAIL }
  ];

  const renderContent = () => (
    <div>
      <div className={styles.invoiceSentLogo}>
        <Image src={IconInvoiceSent} />
        <h4 className={styles.title}>Invoice sent!</h4>
      </div>

      <div>
        <p className={styles.description}>
          Your invoice has been successfully sent. Fetch is working to get you
          paid early. Stay tuned for updates on your incoming payment.
        </p>
        <a
          onClick={goToInvoiceDetail}
          className={styles.keepUpdatedText}
          href={"javascript:void(0)"}
        >
          We’ll take it from here and keep you updated.
        </a>
      </div>

      <div className={styles.copyLink}>
        <p>Copy the link below and share it with your customer:</p>

        <div>
          <InputText
            prefix={
              <Image
                src={isCopied ? IconLinkCopied : IconCopyLink}
                onClick={handleCopy}
              />
            }
            name="copy_link"
            value={isCopied ? "Link copied" : url}
            inputClassName={styles.inputCopy}
            // onFocus={copyLink.bind(null, true)}
          />
        </div>
      </div>

      <div className={styles.social}>
        {shareIcons.map(step => {
          return (
            <>
              <ShareSocial
                type={step.type}
                url={url}
                icon={<Image className={styles.shareIcon} src={step.src} />}
              />
            </>
          );
        })}
      </div>
    </div>
  );
  const renderButtons = () => (
    <div className={styles.groupBtn}>
      <Button type="primary" onClick={onRequest}>
        Send another invoice
      </Button>
      <Button
        type="secondary"
        onClick={onSubmit}
        className={styles.btnSecondary}
      >
        Go to dashboard
      </Button>
    </div>
  );

  return (
    <>
      <BrowserView>
        <DesktopModalLayout
          hasBorder={false}
          noHeader={true}
          btnSubmit={renderButtons()}
        >
          <div
            className={classNames(styles.wrapper, {
              [styles.wrapperDesktop]: isDesktop
            })}
          >
            {renderContent()}
          </div>
        </DesktopModalLayout>
      </BrowserView>
      <MobileView>
        <MainLayout
          header={{
            hasBorder: false,
            hideMenu: true
          }}
        >
          <div className={classNames(styles.wrapper, styles.mobileWrapper)}>
            {renderContent()}
            {renderButtons()}
          </div>
        </MainLayout>
      </MobileView>
    </>
  );
}

export default InvoiceSentModal;
