import React, { useEffect, useMemo, useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import DesktopModalLayout from "src/bepaid/components/Modal/components/DesktopModalLayout";
import MainLayout from "src/bepaid/layouts/MainLayout";

import Button from "src/bepaid/components/Form/controls/Button";
import Progress from "src/bepaid/components/Progress";
import Image from "src/bepaid/components/Image";
import Form from "src/bepaid/components/Form";
import SelectMultiLevelCustom from "src/bepaid/components/Form/controls/SelectMultiLevelCustom";
import { invokeMessage } from "src/bepaid/components/Message";

import IconArrowDown from "src/bepaid/assets/images/common/drop_down_arrow_grey.svg";

import InvoiceItem from "./InvoiceItem";

import ROUTES from "src/bepaid/routes";
import { history } from "src/ipm-shared/store";
import { useMakePaymentUrlParams } from "src/bepaid/pages/Payments/hooks";
import { CHECKOUT_FORM } from "src/ipm-shared/store/model/Card/const";

import { purposeByEntity } from "src/bepaid/pages/Payments/const";
import { PURPOSE } from "src/ipm-shared/components/Purpose/const";
import { BRAND } from "src/ipm-shared/Utils/Images";

import * as uuidv1 from "uuid/v1";
import _get from "lodash-es/get";

import PaymentUtil from "src/ipm-shared/Utils/Payment";

import { IProps } from "./index";

import styles from "../ExpandBox/ExpandboxContainer.module.scss";
import T from "src/ipm-shared/Utils/Intl";

const View = ({
  selectedPayees,
  updateExtraPayee,
  deleteExtraPayee,
  removeControlPattern,
  accountCountryCode,
  accountCountryId,
  accountCurrencyId,
  paymentCurrencyCode,
  selectedPayment,
  addPaymentRequestSubmit,
  validatePaymentPayeeSubmit,
  fetchDateConditions,
  progress,
  titleLabel,
  onClose,
  ipmMerchantCategories,
  fetchIpmMerchantCategories,
  setControl,
  setErrors,
  getControl,
  isCryptoPaymentMethod,
  isSingaporeAccount
}: IProps) => {
  const {
    entity,
    paymentType,
    replaceRouteWithParams
  } = useMakePaymentUrlParams();
  const purpose = purposeByEntity[entity];
  const isWallex = paymentType === "international";

  const [loading, setLoading] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [mccId, setMccId] = useState<any>(undefined);
  const ipmMerchantValue = _get(
    getControl("ipm_merchant_category_id", CHECKOUT_FORM),
    "value"
  );

  const [defaultSelection] = useState(ipmMerchantValue);
  let allowAmexOption = false;
  let payeeNum = 0;
  if (selectedPayees.length > 0) {
    selectedPayees.forEach((item: any) => {
      if (item.isAmexMCC) {
        allowAmexOption = true;
      }
      payeeNum += 1;
      payeeNum += Object.keys(item.extraPayees).length;
    });
  }
  let ipmMerchantCategoriesFilter = ipmMerchantCategories as any;
  if (allowAmexOption) {
    ipmMerchantCategoriesFilter = [
      {
        label: "Pay with Visa, Mastercard or Unionpay",
        value: "Pay with Visa, Mastercard or Unionpay",
        hideSwitcherIcon: true,
        showCardSurffix: true,
        card_brand_ids: [BRAND.VISA, BRAND.MASTER_CARD, BRAND.UNION_PAY],
        children: ipmMerchantCategoriesFilter
      },
      {
        label: "Pay with American Express",
        value: "Pay with American Express",
        hideSwitcherIcon: true,
        showCardSurffix: true,
        card_brand_ids: [BRAND.AMEX],
        children: ipmMerchantCategoriesFilter
          .filter(
            (item: any) =>
              item.card_brand_ids && item.card_brand_ids.includes(BRAND.AMEX)
          )
          .map((item: any) => ({
            ...item,
            label: item.label,
            value: item.value + "_amex"
          }))
      }
    ];
  }

  const handleClickAddExtraPayee = (payee: any) => {
    const {
      accountNumber,
      bankBSBId,
      bankId,
      bankCode,
      bsbCode,
      currencyId,
      defaultAmount,
      defaultComments,
      id,
      name,
      isUnsupported,
      unsupportedMessage
    } = payee;

    let uid = uuidv1();
    let updateAmount = defaultAmount;
    if (payee.uid) {
      uid = payee.uid;
      updateAmount = defaultAmount / 100;
    }

    updateExtraPayee(id, uid, {
      accountNumber,
      bankBSBId,
      bankCode,
      bankId,
      bsbCode,
      currencyId,
      defaultAmount: updateAmount,
      defaultComments,
      files: [],
      id,
      isUnsupported,
      name,
      refundedAmount: 0,
      uid,
      unsupportedMessage
    });
  };

  const setExtraPayeeValue = (
    payeeId: number,
    uid: string,
    prop: any,
    value: string
  ) => {
    updateExtraPayee(payeeId, uid, {
      ...selectedPayees.filter((p: any) => p.id === payeeId)[0].extraPayees[
        uid
      ],
      [prop]: value
    });
  };

  const handleClickRemoveExtraPayee = (payee: any, uid: string) => {
    removeControlPattern(new RegExp(`${payee.id}_${uid}`));
    deleteExtraPayee(payee.id, uid);
  };

  const renderExtraPayees = (payee: any) => {
    return Object.keys(payee.extraPayees).map((uid: string) => {
      return (
        <InvoiceItem
          key={uid}
          accountCurrencyId={accountCurrencyId}
          accountCountryCode={accountCountryCode}
          paymentCurrencyCode={paymentCurrencyCode}
          payee={payee}
          extraPayeeUid={uid}
          setExtraPayeeValue={setExtraPayeeValue}
          onClickDeletePayee={handleClickRemoveExtraPayee.bind(
            null,
            payee,
            uid
          )}
          entity={payee.ipaymyEvent === "TAX2019" ? "tax" : "invoice"}
          selectedPayment={selectedPayment}
        />
      );
    });
  };

  const handleChangeSelect = (value: any) => {
    if (
      payeeNum > 1 &&
      (value.includes("Pay with American Express") || value.includes("amex"))
    ) {
      setDisableButton(true);
      setControl({
        errors: [
          {
            code: "ERROR_MULTIPLE_SUPPLIERS_AMEX",
            message: T.transl("ERROR_MULTIPLE_SUPPLIERS_AMEX")
          }
        ],
        form: CHECKOUT_FORM,
        name: "ipm_merchant_category_id"
      });
    } else {
      setControl({
        errors: [],
        form: CHECKOUT_FORM,
        name: "ipm_merchant_category_id",
        value
      });
      setMccId(value);
    }
  };

  useEffect(() => {
    if (
      mccId &&
      payeeNum > 1 &&
      (mccId.includes("Pay with American Express") || mccId.includes("amex"))
    ) {
      setDisableButton(true);
      setControl({
        errors: [
          {
            code: "ERROR_MULTIPLE_SUPPLIERS_AMEX",
            message: T.transl("ERROR_MULTIPLE_SUPPLIERS_AMEX")
          }
        ],
        form: CHECKOUT_FORM,
        name: "ipm_merchant_category_id"
      });
    } else {
      setControl({
        errors: [],
        form: CHECKOUT_FORM,
        name: "ipm_merchant_category_id"
      });
      setDisableButton(false);
    }
  }, [mccId, payeeNum]);

  useEffect(() => {
    setMccId(
      _get(
        getControl("ipm_merchant_category_id", CHECKOUT_FORM),
        "value"
      ) as any
    );
  }, []);

  useEffect(() => {
    if (selectedPayment) {
      _get(selectedPayment, "payees").map((payee: any) => {
        if (payee.uid) {
          handleClickAddExtraPayee(payee);
        }
      });
      if (selectedPayees.length < 1) {
        history.push(
          replaceRouteWithParams(ROUTES.PAYMENTS_CREATION, {
            newPaymentType: paymentType,
            newEntity: "entity",
            newAction: "select"
          })
        );
        invokeMessage("error", "Payee not found.");
      }
    } else {
      if (selectedPayees.length < 1) {
        history.push(
          replaceRouteWithParams(ROUTES.PAYMENTS_CREATION, {
            newPaymentType: paymentType,
            newEntity: "entity",
            newAction: "select"
          })
        );
      }
    }
  }, []);

  useEffect(() => {
    const brandId = accountCountryCode !== "SG" ? BRAND.AMEX : undefined;
    fetchIpmMerchantCategories(brandId, PURPOSE.INVOICE, false, undefined);
  }, []);

  const handleError = (err: any, data?: any) => {
    if (err?.message) {
      invokeMessage("error", err.message);
    }
  };

  const redirectToCheckout = () => {
    addPaymentRequestSubmit(
      purpose,
      isWallex,
      {
        cb: (err?: any, data?: any) => {
          const checkoutUrl = data
            ? `${replaceRouteWithParams(ROUTES.PAYMENTS_CREATION, {
                newAction: "checkout"
              })}/?token=${data?.token}`
            : "/";
          if (err) {
            handleError(err, { checkoutUrl });
          } else {
            history.push(checkoutUrl, {
              prevRoute: window.location.pathname
            });
          }
        }
      },
      setLoading
    );
  };

  const moveForward = () => {
    if (
      mccId === undefined &&
      !isWallex &&
      !isCryptoPaymentMethod &&
      isSingaporeAccount
    ) {
      setErrors(["REQUIRED_FIELD"], "ipm_merchant_category_id");
      return;
    }

    validatePaymentPayeeSubmit(
      purpose,
      isWallex,
      {
        cb: (err?: any) => {
          if (err) {
            invokeMessage("error", err.message);
          } else {
            if (isWallex || isCryptoPaymentMethod) {
              const options = {
                cb: (err?: any, data?: any) => {
                  if (err) {
                    invokeMessage("error", "Bad request!");
                  }
                  if (data) {
                    redirectToCheckout();
                  }
                },
                justGetData: false
              };
              const bankIds = selectedPayees.map((p: any) => p.bankId);
              if (
                selectedPayees[0] &&
                PaymentUtil.isUSDPaymentInHK(
                  accountCountryId,
                  selectedPayees[0].currencyId as number,
                  selectedPayees[0].countryId
                )
              ) {
                fetchDateConditions(
                  new Date(),
                  bankIds,
                  3, // HK
                  73, // USD
                  73, // USD,
                  undefined,
                  selectedPayees.map((p: any) => p.id),
                  options,
                  setLoading
                );
              } else {
                fetchDateConditions(
                  new Date(),
                  bankIds,
                  isWallex
                    ? _get(selectedPayees[0], "international.bankCountryId")
                    : null,
                  isWallex ? _get(selectedPayees[0], "currencyId") : undefined,
                  undefined,
                  undefined,
                  selectedPayees.map((p: any) => p.id),
                  options,
                  setLoading
                );
              }
            } else {
              history.push(
                replaceRouteWithParams(ROUTES.PAYMENTS_CREATION, {
                  newAction: "schedule"
                })
              );
            }
          }
        }
      },
      setLoading
    );
  };

  const submitLabel = useMemo(() => {
    return loading ? "Moving forward..." : "Move forward";
  }, [loading]);

  const onBack = () => {
    history.goBack();
  };

  const renderBtnSubmit = () => {
    return (
      <>
        <Button
          wrapperClassName={styles.wrapperButton}
          onClick={moveForward}
          type="primary"
          htmlType="button"
          loading={disableButton || loading}
        >
          {submitLabel}
        </Button>
      </>
    );
  };

  const renderDesktopView = () => {
    return (
      <DesktopModalLayout
        maxWidth450={true}
        onSubmit={moveForward}
        title={titleLabel}
        onClose={onClose}
        onBack={onBack}
        progress={<Progress colors={["#937CF2", "#A581E8"]} value={progress} />}
        submitButtonClassName={""}
        btnSubmit={renderBtnSubmit()}
      >
        {renderContent()}
      </DesktopModalLayout>
    );
  };

  const renderMobileView = () => {
    return (
      <MainLayout
        header={{
          title: titleLabel,
          hideMenu: true,
          onBack,
          onClose,
          progress: (
            <Progress colors={["#937CF2", "#A581E8"]} value={progress} />
          )
        }}
        footer={renderBtnSubmit()}
      >
        {renderContent()}
      </MainLayout>
    );
  };

  const renderContent = () => {
    return (
      <div className={styles.wrapper}>
        {!isWallex && !isCryptoPaymentMethod && isSingaporeAccount && (
          <div className={styles.paymentDescriptionSelector}>
            <Form layout="vertical">
              <SelectMultiLevelCustom
                form={CHECKOUT_FORM}
                name={"ipm_merchant_category_id"}
                placeholder={"Select payment description"}
                label={"Payment description"}
                options={ipmMerchantCategoriesFilter}
                showSearch={false}
                allowClear={false}
                suffixIcon={<Image src={IconArrowDown} />}
                onChange={handleChangeSelect}
                defaultValue={defaultSelection}
                reserveValueOnUnmount={true}
                revertValueOnMount={true}
                payeeNum={payeeNum}
              />
            </Form>
          </div>
        )}
        {selectedPayees.map((payee: any) => (
          <>
            <div key={payee.id} className={styles.groupContainer}>
              <InvoiceItem
                payee={payee}
                accountCurrencyId={accountCurrencyId}
                accountCountryCode={accountCountryCode}
                paymentCurrencyCode={paymentCurrencyCode}
                entity={payee.ipaymyEvent === "TAX2019" ? "tax" : "invoice"}
                selectedPayment={selectedPayment}
              />
              {renderExtraPayees(payee)}
              {paymentType === "domestic" && (
                <button
                  onClick={handleClickAddExtraPayee.bind(null, payee)}
                  className={styles.btnAdd}
                >
                  {payee.ipaymyEvent === "TAX2019"
                    ? "Make multiple tax payments."
                    : "Pay multiple invoices to this supplier."}
                </button>
              )}
            </div>
          </>
        ))}
      </div>
    );
  };

  return (
    <>
      <BrowserView>{renderDesktopView()}</BrowserView>
      <MobileView>{renderMobileView()}</MobileView>
    </>
  );
};

export default View;
