import { connect } from "react-redux";

import { RootState } from "src/ipm-shared/store/model/reducers";
import { FetchModalID } from "src/ipm-shared/components/GlobalUI/actions";
import * as actions from "src/bepaid/store/model/Fetch/actions";
import * as selectors from "src/bepaid/store/model/Fetch/selectors";

import Component from "./View";

const mapStateToProps = (state: RootState) => ({
  extraInfo: selectors.getExtraInfo(state, FetchModalID.DELETE_NEW_CARD_MODAL),
  hasAtLeastOneCard: selectors.getCards(state).length > 0
});

const mapDispatchToProps = {
  deleteCard: actions.deleteCard,
  fetchSchedules: actions.fetchSchedules,
  submitEditPayments: actions.editPayments
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
