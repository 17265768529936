import React from "react";

import ExpandBox from "src/bepaid/components/ExpandBox";
import Card from "src/bepaid/components/Card";

import ContentExpanded from "./ContentExpanded";
import ContentCollapsed from "./ContentCollapsed";
import styles from "./RecipientDetail.module.scss";
import Skeleton from "antd/lib/skeleton";
import InputFile from "src/bepaid/components/Form/controls/InputFile";
import Image from "src/bepaid/components/Image";
import CustomerAvatar from "src/bepaid/assets/images/customers/customers_avatar.svg";
import _get from "lodash-es/get";
import { PURPOSE } from "src/ipm-shared/store/model/Payment/const";

const View = (props: any) => {
  const { loading, selectedPayee } = props;
  const files =
    !loading && selectedPayee ? _get(selectedPayee, "files", []) : [];

  const purposeId = _get(selectedPayee, "purposeId");

  if (!loading && !selectedPayee) {
    return <div>Recipient not found</div>;
  }

  return (
    <>
      <div className={styles.avatarWrapper}>
        <Image src={CustomerAvatar} />
      </div>
      <div className={styles.wrapper}>
        <Skeleton loading={props.loading}>
          {purposeId === PURPOSE.SELF_TRANSFER ? (
            <Card>
              <ContentExpanded {...props} />
            </Card>
          ) : (
            <ExpandBox
              type={"recipient"}
              defaultExpand={false}
              originalContent={<ContentCollapsed {...props} />}
              expandContent={<ContentExpanded {...props} />}
            />
          )}

          {files.length > 0 && (
            <div className={styles.imageWrapper}>
              <InputFile
                filePreviewButtonClassName={styles.image}
                defaultValue={files}
                justView={true}
              />
            </div>
          )}
        </Skeleton>
      </div>
    </>
  );
};

export default View;
