import { getType } from "typesafe-actions";
import * as types from "./types";

import * as actions from "./actions";

export type FetchPreStartState = {
  isLoading: boolean;
};

const defaultState: FetchPreStartState = {
  isLoading: false
};

export default (
  state: FetchPreStartState = defaultState,
  action: types.Action
) => {
  switch (action.type) {
    case getType(actions.setLoading):
      return {
        ...state,
        isLoading: action.payload
      };
  }
  return state;
};
