import * as React from "react";
import { connect } from "react-redux";
import * as selectors from "src/ipm-shared/store/model/Bank/selectors";
import { RootState } from "src/ipm-shared/store/model/reducers";
import { IBaseProps } from "../Form/controls/lib/Base";
import {
  getBanksIdOptions,
  mapSwiftCodeToId
} from "src/ipm-shared/store/model/Bank/selectors";
import store from "src/ipm-shared/store";
import _get from "lodash-es/get";
import { SWIFT_CODE_LABEL_LIST } from "./const";
import T from "src/ipm-shared/Utils/Intl";

import { ControlValueType } from "src/ipm-shared/components/Form/types";
import InputText from "../Form/controls/InputText";
import * as actions from "../Form/actions";
import AutoCompleteInput, {
  ISelectOption
} from "../Form/controls/AutoCompleteInput";

const mapStateToProps = (state: RootState) => ({
  bankBSBByID: state.banks.bankBSB.byId,
  bankByID: state.banks.byId,
  banksBSBOptions: selectors.getBanksBSBOptions(state),
  banksOptions: selectors.getBanksIdOptions(state)
});

const mapDispatchToProps = {
  setControl: actions.setControl
};

type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  IBaseProps & {
    onChangeCustom?: (key: number) => void;
    defaultValue?: string | number;
    labelOnly?: boolean;
    labelHidden?: boolean;
    isAdmin?: boolean;
    defaultValueFormat?: "bank_swift_code" | "bank_swift_code|name";
    countryId?: string | number;
    isEditAble?: boolean;
    requiredConfirmation?: boolean;
    renderComponent?: any;
  };

type IState = {
  searchValue?: string;
};

class BankBSBId extends React.Component<IProps, IState> {
  private options: any[];

  constructor(props: IProps) {
    super(props);

    this.state = {
      searchValue: ""
    };

    const banksBSBOptions = this.props.banksBSBOptions;

    let filterBanks;

    if (this.props.isAdmin) {
      filterBanks = banksBSBOptions;
    } else {
      filterBanks = banksBSBOptions.filter(bank => !bank.isDeleted);
    }

    const options = this.composeBankSwiftCode(filterBanks);

    this.options = options;
  }

  public componentDidMount() {
    if (this.props.defaultValue) {
      this.generateExtraField(this.props.defaultValue);
    }
  }

  public render() {
    const options = this.options;
    const LIMIT_RESULT = 5;
    if (this.props.labelOnly === true) {
      const findBank = this.props.banksBSBOptions.find(
        item => item.value === this.props.defaultValue
      );
      return <span>{findBank ? findBank.displayName : ""}</span>;
    }

    const optionsFilter = options
      .filter(this.filterList)
      .slice(0, LIMIT_RESULT);

    if (this.props.renderComponent) {
      const shareProps = {
        ...this.props,
        defaultValue: this.defaultValue,
        onChangeSearchText: this.onSearch,
        onChangeSelected: this.onChangeCustom,
        options: optionsFilter
      };
      return this.props.renderComponent(shareProps);
    }

    return (
      <React.Fragment>
        {!this.props.labelHidden && (
          <span className={"label"}>{T.transl("BANK_LABEL")}</span>
        )}

        <AutoCompleteInput
          id={this.props.id}
          key={this.props.name}
          name={this.props.name}
          form={this.props.form}
          labelOnly={this.props.labelOnly}
          placeholder={T.transl("SEARCH_BANK_PLACEHOLDER")}
          options={optionsFilter}
          defaultValue={this.defaultValue}
          onChangeCustom={this.onSearch}
          onChangeSeleted={this.onChangeCustom}
          required={this.props.required}
          requiredMessage={this.props.requiredMessage}
          displayError={this.props.displayError}
          errorStyle={this.props.errorStyle}
          isLoading={false}
          labelHidden={this.props.labelHidden}
        />

        <div hidden={true}>
          <InputText form={this.props.form} name={"bank_id"} defaultValue={0} />
        </div>
      </React.Fragment>
    );
  }

  private generateExtraField = (bankBSBId: ControlValueType) => {
    const { bankBSBByID, bankByID, setControl, form } = this.props;
    try {
      const bankBSB = bankBSBByID[bankBSBId as string];
      const bank = bankByID[bankBSB.bankId];

      setControl({
        errors: [],
        form,
        name: "bank_id",
        value: +bank.id
      });
    } catch (err) {
      setControl({
        errors: [],
        form,
        name: "bank_id",
        value: 0
      });
    }
  };

  private onChangeCustom = (value: ControlValueType) => {
    this.generateExtraField(value);

    if (this.props.onChangeCustom) {
      this.props.onChangeCustom(value);
    }
  };

  private get defaultValue() {
    const { defaultValue } = this.props;

    return typeof defaultValue === "number"
      ? defaultValue
      : this.getId(this.options, defaultValue);
  }

  private composeBankSwiftCode = (
    banks: ReturnType<typeof getBanksIdOptions>
  ) => {
    // Currently just HSBC bank only
    let banksList;

    banksList = banks
      .map(bank => ({
        label:
          bank.displayName !== null
            ? bank.displayName
            : bank.label +
              (SWIFT_CODE_LABEL_LIST.indexOf(bank.swiftCode) > -1
                ? ` - ${bank.swiftCode.replace("XXX", "")}`
                : ""),
        value: bank.value
      }))
      .sort((a, b) => (a.label < b.label ? -1 : 1));

    return banksList;
  };

  private getId = (
    options: Array<{ label: string; value: number }>,
    value: string
  ) => {
    if (this.props.defaultValueFormat) {
      if (this.props.defaultValueFormat.indexOf("bank_swift_code") > -1) {
        const { id, ok } = mapSwiftCodeToId(store.getState(), value);
        if (ok) {
          return id;
        }
      }

      if (this.props.defaultValueFormat.indexOf("name") > -1) {
        const { id, ok } = this.findIdFromLabel(options, value);
        if (ok) {
          return id;
        }
      }
    }

    return value;
  };

  private findIdFromLabel = (
    options: Array<{ label: string; value: number }>,
    value: string
  ) => {
    if (value) {
      value = value.toUpperCase().trim();
      const id = _get(
        options.filter(o => {
          return value.toUpperCase() === o.label.toUpperCase();
        }),
        "0.value",
        undefined
      );

      return {
        id: id ? id : value,
        ok: id !== undefined
      };
    }

    return {
      id: value,
      ok: false
    };
  };

  private onSearch = (searchValue: string) => {
    this.setState(
      {
        searchValue
      },
      () => {
        const options = searchValue ? this.options.filter(this.filterList) : [];

        if (options.length === 0 || !searchValue) {
          this.props.setControl({
            displayError: true,
            errors: [
              {
                code: "SEARCH_BANK_NO_RESULTS",
                message: T.transl("SEARCH_BANK_NO_RESULTS")
              }
            ],
            form: this.props.form,
            name: this.props.name
          });
        }
      }
    );
  };

  private filterList = (option: ISelectOption) => {
    if (this.state.searchValue) {
      let keySearch = option.label;
      let searchValue = this.state.searchValue.toLowerCase();
      if (this.state.searchValue.length > 3) {
        keySearch = keySearch.replace(/-/g, "");
        searchValue = searchValue.replace(/-/g, "");
      }
      return `${keySearch}`.toLowerCase().search(searchValue) !== -1;
    }

    if (this.defaultValue) {
      return option.value === this.defaultValue;
    }

    return false;
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BankBSBId);
