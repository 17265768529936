import React from "react";
import _get from "lodash-es/get";
import { BrowserView, MobileView } from "react-device-detect";
import classNames from "classnames";

import Button from "antd/es/button";

import { history } from "src/ipm-shared/store";

import ROUTES from "src/bepaid/routes";
import DesktopModalLayout from "src/bepaid/components/Modal/components/DesktopModalLayout";
import InvoiceListContent from "src/bepaid/pages/Invoices/components/InvoiceListContent";
import MainLayout from "src/bepaid/layouts/MainLayout";

import styles from "./InvoiceListDraftModal.module.scss";
import { FetchModalID } from "src/ipm-shared/components/GlobalUI/actions";

const CreateInvoiceModal = (props: any) => {
  const close = () => {
    props.toggleModal(FetchModalID.LIST_INVOICE_DRAFT_MODAL);
    history.push(ROUTES.INVOICES);
  };
  const handleCreateNewInvoice = () => {
    props.selectInvoice(0);
    props.toggleModal(FetchModalID.LIST_INVOICE_DRAFT_MODAL);
    history.push(`${ROUTES.CREATE_INVOICES}`);
  };
  const renderListDraftContent = () => {
    return (
      <div className={styles.wrapperListContent}>
        <InvoiceListContent
          {...props}
          isModalContent={true}
          isDraft={true}
          offDraft={close}
        />
      </div>
    );
  };
  const renderButtonSubmit = () => {
    return (
      <div className={styles.buttons}>
        <Button
          type="primary"
          htmlType="button"
          onClick={handleCreateNewInvoice}
        >
          Create new invoice
        </Button>
      </div>
    );
  };
  return (
    <>
      <BrowserView>
        <div className={styles.wrapper}>
          <DesktopModalLayout
            title={"Draft invoices"}
            onClose={close}
            onSubmit={handleCreateNewInvoice}
            btnSubmit={renderButtonSubmit()}
          >
            {renderListDraftContent()}
          </DesktopModalLayout>
        </div>
      </BrowserView>
      <MobileView>
        <div className={classNames(styles.wrapper, styles.wrapperMobile)}>
          <MainLayout
            header={{
              title: "Draft invoices",
              hideMenu: true,
              center: true,
              onClose: close
            }}
            footer={renderButtonSubmit()}
          >
            {renderListDraftContent()}
          </MainLayout>
        </div>
      </MobileView>
    </>
  );
};

export default CreateInvoiceModal;
