/**
 * Selectors
 *
 * It's like "views" in relational databases.
 * Store the most efficient format in the reducer state.
 * But to retrieve into easy to consume form, use selector.
 */
import { RootState } from "src/ipm-shared/store/model/reducers";
import ReducerFactory from "src/ipm-shared/Utils/ReduxReducer";
import paymentCollectionsReducer from "./reducers";
import { PAYMENT_STATUS } from "../Payment/const";
import { EMAIL_STATUS, SMS_STATUS } from "./const";
ReducerFactory.registerReducer({
  paymentCollections: paymentCollectionsReducer
});

export const getPaymentCollections = (state: RootState) =>
  state.paymentCollections.payments;

export const getTotalPaymentCollections = (state: RootState) =>
  state.paymentCollections.total;

export const getPaymentCollectionIsFetching = (state: RootState) =>
  state.paymentCollections.isFetching;

export const getPaymentCollectionActivity = (state: RootState) =>
  state.paymentCollections.activity;

export const bePaidGetSumPaymentInThisMonth = (state: RootState) =>
  state.paymentCollections.sumPaymentInThisMonth;

export const getPaymentRequested = (state: RootState) =>
  state.paymentCollections.payments
    .filter(payment => payment.paymentStatusId === PAYMENT_STATUS.REQUEST)
    .sort((a, b) => {
      if (a.order && b.order) {
        return a.order > b.order ? 1 : -1;
      }
      return 1;
    });

export const getPaymentInProgress = (state: RootState) =>
  state.paymentCollections.payments
    .filter(
      payment =>
        payment.paymentStatusId === PAYMENT_STATUS.IN_PROGRESS_2 ||
        payment.paymentStatusId === PAYMENT_STATUS.ON_HOLD ||
        payment.paymentStatusId === PAYMENT_STATUS.UNDER_REVIEW
    )
    .sort((a, b) => {
      if (a.order && b.order) {
        return a.order > b.order ? 1 : -1;
      }
      return 1;
    });
export const getPaymentReceived = (state: RootState) =>
  state.paymentCollections.payments
    .filter(
      payment =>
        payment.paymentStatusId === PAYMENT_STATUS.COMPLETED ||
        payment.paymentStatusId === PAYMENT_STATUS.PAID
    )
    .sort((a, b) => {
      if (a.order && b.order) {
        return a.order > b.order ? 1 : -1;
      }
      return 1;
    });

export const getPaymentRequestFailed = (state: RootState) =>
  state.paymentCollections.payments
    .filter(
      p =>
        p.emailStatusId === EMAIL_STATUS.ERROR ||
        p.smsStatusId === SMS_STATUS.ERROR
    )
    .sort((a, b) => {
      if (a.order && b.order) {
        return a.order > b.order ? 1 : -1;
      }
      return 1;
    });
