import _get from "lodash-es/get";
import { ActionType } from "typesafe-actions";
import { call, put } from "redux-saga/effects";
import * as actions from "./actions";
import { default as RestClient } from "src/ipm-shared/services/Rest";
import { reTryTakeLatest } from "src/ipm-shared/Utils/ReduxSagaEffects";
import HttpRequestError from "src/ipm-shared/Utils/Exceptions/HttpRequestError";

const watchedSagas = [
  reTryTakeLatest(actions.fetchBusinessTypes, handleFetchBusinessType),
  reTryTakeLatest(
    actions.fetchBusinessIndustries,
    handleFetchBusinessIndustries
  ),
  reTryTakeLatest(
    actions.fetchBusinessOccupations,
    handleFetchBusinessOccupations
  )
];
export default watchedSagas;

export function* handleFetchBusinessType(
  action: ActionType<typeof actions.fetchBusinessTypes>
) {
  yield put(
    actions.setBusinessTypes({
      businessTypes: [],
      isFetching: true
    })
  );

  const res: { data: any } = yield call(RestClient.send, {
    service: "get_business_type"
  });

  if (!res) {
    throw new HttpRequestError("Failed to fetch");
  }

  try {
    const data: any[] = _get(res, "data.business_types", []);
    yield put(
      actions.setBusinessTypes({
        businessTypes: data.map(b => ({
          id: b.id,
          name: b.name
        })),
        isFetching: false
      })
    );
  } catch (e) {
    window.Logger.guestError(e);
  }
}

export function* handleFetchBusinessIndustries(
  action: ActionType<typeof actions.fetchBusinessIndustries>
) {
  yield put(
    actions.setBusinessIndustries({
      businessIndustries: [],
      isFetching: true
    })
  );

  const res: { data: any } = yield call(RestClient.send, {
    service: "get_business_industries"
  });

  if (!res) {
    throw new HttpRequestError("Failed to fetch");
  }

  try {
    const data: any[] = _get(res, "data.business_industries", []);
    yield put(
      actions.setBusinessIndustries({
        businessIndustries: data.map(b => ({
          groupId: b.group_id * 1,
          groupName: b.group_name,
          id: b.id,
          name: b.name
        })),
        isFetching: false
      })
    );
  } catch (e) {
    window.Logger.guestError(e);
  }
}

export function* handleFetchBusinessOccupations(
  action: ActionType<typeof actions.fetchBusinessOccupations>
) {
  yield put(
    actions.setBusinessOccupations({
      businessOccupations: [],
      isFetching: true
    })
  );

  const res: { data: any } = yield call(RestClient.send, {
    service: "get_business_occupations"
  });

  if (!res) {
    throw new HttpRequestError("Failed to fetch");
  }

  try {
    const data: any[] = _get(res, "data.business_occupations", []);
    yield put(
      actions.setBusinessOccupations({
        businessOccupations: data.map(b => ({
          groupId: b.group_id * 1,
          groupName: b.group_name,
          id: b.id,
          name: b.name
        })),
        isFetching: false
      })
    );
  } catch (e) {
    window.Logger.guestError(e);
  }
}
