/* tslint:disable:jsx-no-lambda */
import React from "react";
import qs from "query-string";
import { BrowserView, MobileView } from "react-device-detect";
import Flex from "antd-mobile/es/flex";
import Typography from "antd/es/typography";

import { history } from "src/ipm-shared/store";

import ROUTES from "src/bepaid/routes";
import GuestLayout from "src/bepaid/layouts/GuestLayout";
import Button from "src/bepaid/components/Form/controls/Button";
import Card from "src/bepaid/components/Card";
import Image from "src/bepaid/components/Image";

import HeaderLogo from "src/bepaid/assets/images/desktop/header_ipaymy_logo.svg";

import styles from "./PasswordSetConfirmation.module.scss";

const PasswordResetConfirmation: React.FC<any> = () => {
  const email = React.useMemo(() => {
    const query = qs.parse(window.location.search.slice(1));
    const emailString = String(query.email || "").replace(" ", "+");
    return query.email ? emailString : "unknown email";
  }, []);

  const renderContent = () => {
    return (
      <div className={styles.wrapper}>
        <Flex
          direction="column"
          justify="center"
          align="center"
          className={styles.instructionSection}
        >
          <Flex.Item span={18}>
            <Typography.Text>
              Instructions to reset your password have been sent to:
            </Typography.Text>
          </Flex.Item>
          <Flex.Item span={24} className={styles.email}>
            <Typography.Link className={styles.strongLink} href="">
              {email}
            </Typography.Link>
          </Flex.Item>
        </Flex>
        <Flex justify="center" align="center" className={styles.buttonSection}>
          <Flex.Item span={24}>
            <Button
              type="primary"
              htmlType="button"
              onClick={() => history.push(ROUTES.LOGIN)}
            >
              Sign in
            </Button>
          </Flex.Item>
        </Flex>
      </div>
    );
  };

  const renderDesktopView = () => {
    return (
      <div className={styles.desktopWrapper}>
        <Card>{renderContent()}</Card>
      </div>
    );
  };

  return (
    <GuestLayout
      header={<Image src={HeaderLogo} />}
      title={
        <Typography.Text className={styles.title}>
          Reset password
        </Typography.Text>
      }
    >
      <BrowserView>{renderDesktopView()}</BrowserView>
      <MobileView>{renderContent()}</MobileView>
    </GuestLayout>
  );
};

export default PasswordResetConfirmation;
