import { format } from "date-fns";
import * as React from "react";
import * as ReactPopper from "react-popper";
import styled from "styled-components";
import DayPicker from "react-day-picker";
import classNames from "classnames";
import { Placement } from "reactstrap/lib/Popper";
import T from "src/ipm-shared/Utils/Intl";

interface IProps extends React.Props<any> {
  onChange: (date?: Date) => void;
  initialDate?: Date;
  disabledDates?: Date[];
  displayFormat?: string; // The date format string that will be passed to date-fns.
  additionalModifiers?: {
    [modifierKey: string]: Date | Date[];
  };
  allowWeekends?: boolean;
  allowBeforeIntialDate?: boolean;
  disableBefore?: Date;
  disableAfter?: Date;
  isAdmin?: boolean;
  label?: string;
  earliestDate?: Date;
  expeditePayoutDate?: boolean;
  placeholder?: string;
  disabled?: boolean;
  adminDisableBefore?: Date | string;
  formClass?: string;
  collectedPayment?: boolean;
  showOutsideDays?: boolean;
  fromMonth?: Date | string;
  toMonth?: Date | string;
  allowAllDays?: boolean;
  placement?: Placement;
  footerDescription?: JSX.Element;
}
interface IState {
  date?: Date;
  open: boolean;
}

const StyledDayPicker = styled(DayPicker)`
  background: white;
`;

class DatePicker extends React.Component<IProps, IState> {
  private dateFormatString: string;
  private disabledDates: Date[];
  private closeWindowTimer?: number;
  private calendarPopupElement: HTMLElement | null;
  private earliestDate?: Date;

  constructor(props: IProps) {
    super(props);

    this.dateFormatString = props.displayFormat || "ddd, Do MMM YYYY";
    this.disabledDates = props.disabledDates || [];
    this.earliestDate = props.earliestDate || new Date();

    this.state = {
      date: props.initialDate,
      open: false
    };

    this.props.onChange(props.initialDate);
  }

  public render() {
    return (
      <ReactPopper.Manager>
        <ReactPopper.Target onClick={this.toggleCalendar}>
          <div
            className={`form-group ${
              this.props.formClass ? this.props.formClass : ""
            }`}
          >
            {this.props.label && (
              <span className="label">{this.props.label}</span>
            )}
            <input
              className="form-control"
              type="text"
              value={
                this.state.date
                  ? format(this.state.date, this.dateFormatString)
                  : ""
              }
              placeholder={this.props.placeholder}
              readOnly={true}
            />
            <div className="invalid-feedback">
              {T.transl("CALENDAR_SELECT_DATE_ERROR")}
            </div>
          </div>
        </ReactPopper.Target>
        <ReactPopper.Popper
          placement={this.props.placement ? this.props.placement : "bottom-end"}
          innerRef={this.setInnerRef}
          onBlur={this.closeCalendar.bind(this, 100)}
          onFocus={this.cancelCloseCalendar}
          tabIndex={-1}
          className={"z-index-10"}
        >
          {this.state.open && (
            <StyledDayPicker
              modifiers={{
                disabled: this.getDisabledDates(),
                ...this.props.additionalModifiers
              }}
              weekdaysShort={["S", "M", "T", "W", "T", "F", "S"] || undefined}
              months={
                [
                  "JAN",
                  "FEB",
                  "MAR",
                  "APR",
                  "MAY",
                  "JUN",
                  "JUL",
                  "AUG",
                  "SEP",
                  "OCT",
                  "NOV",
                  "DEC"
                ] || undefined
              }
              fromMonth={new Date(this.props.fromMonth || "")}
              toMonth={new Date(this.props.fromMonth || "")}
              selectedDays={this.state.date}
              onDayClick={this.handleClickDate}
              initialMonth={this.state.date}
              showOutsideDays={
                this.props.showOutsideDays !== undefined
                  ? this.props.showOutsideDays
                  : true
              } // By default is true.
              className={classNames("calendar-box", {
                "collected-payment-calendar": this.props.collectedPayment,
                "expedite-box": this.props.expeditePayoutDate,
                "is-admin": this.props.isAdmin,
                "not-allow-outside-days": !this.props.showOutsideDays
              })}
            />
          )}
          {this.state.open &&
            !this.props.isAdmin &&
            (!this.props.collectedPayment ? (
              <div className="datePicker-footer">
                {this.props.footerDescription ? (
                  this.props.footerDescription
                ) : (
                  <>
                    <p className="payoutdate mb-2">
                      {this.props.expeditePayoutDate
                        ? T.transl("CALENDAR_CURRENT_PAYOUT_DATE_LEGEND")
                        : T.transl("CALENDAR_PAYOUT_DATE_LEGEND")}
                    </p>
                    <p
                      className="chargedate mb-2"
                      hidden={this.props.expeditePayoutDate}
                    >
                      {T.transl("CALENDAR_CHARGE_DATE_LEGEND")}
                    </p>
                    <p className="public-holiday">
                      {T.transl("CALENDAR_HOLIDAY_LEGEND")}
                    </p>
                  </>
                )}
              </div>
            ) : (
              <div className="datePicker-footer">
                <p className="chargedate mb-0">
                  {T.transl("CALENDAR_DUE_DATE_LEGEND")}
                </p>
              </div>
            ))}
        </ReactPopper.Popper>
      </ReactPopper.Manager>
    );
  }

  private setInnerRef = (ref: any) => {
    this.calendarPopupElement = ref;
  };

  private toggleCalendar = () => {
    if (!this.props.disabled) {
      this.setState({ open: !this.state.open }, () => {
        if (this.calendarPopupElement) {
          this.calendarPopupElement.focus();
        }
      });
    }
  };

  /**
   * When user clicks outside of input element (including the calendar itself), we will close the calendar.
   *
   * If `delay` is given, we will close it after `delay` milliseconds. This is useful to let calendar element onClick event to fire off first.
   */
  private closeCalendar = (delay?: number) => {
    if (!delay) {
      return this.setState({ open: false });
    }

    this.closeWindowTimer = window.setTimeout(() => {
      this.setState({ open: false });
    }, delay);
  };

  private cancelCloseCalendar = () => {
    window.clearTimeout(this.closeWindowTimer);
    this.closeWindowTimer = undefined;
  };

  private handleClickDate = (date: Date, modifiers: any) => {
    if (modifiers.disabled) {
      return;
    }
    this.props.onChange(date);
    this.setState({ date });
    this.closeCalendar();
  };

  private getDisabledDates = () => {
    const disabledDates: any[] = [...this.disabledDates];

    if (!this.props.isAdmin && !this.props.allowAllDays) {
      disabledDates.push(
        {
          before: this.props.disableBefore
            ? this.props.disableBefore
            : new Date()
        },
        {
          before: this.props.allowBeforeIntialDate
            ? new Date()
            : this.earliestDate // To disable all days before the starting date.
        }
      );
      disabledDates.push({
        daysOfWeek: this.props.allowWeekends ? [] : [0, 6]
      });
    }

    if (this.props.adminDisableBefore) {
      disabledDates.push({
        before: this.props.adminDisableBefore
      });
    }

    if (this.props.disableBefore) {
      disabledDates.push({
        before: this.props.disableBefore
      });
    }

    if (this.props.disableAfter) {
      disabledDates.push({
        after: this.props.disableAfter
      });
    }
    return disabledDates;
  };
}

export default DatePicker;
