import { ActionType } from "typesafe-actions";
import { select, call, put } from "redux-saga/effects";

import BaseRestService from "src/ipm-shared/services/Rest/Base";
import { catchTakeLatest } from "src/ipm-shared/Utils/ReduxSagaEffects";

import * as commonActions from "../actions";
import { RootState } from "../reducers";
import * as selectors from "../selectors";
import * as actions from "./actions";

const watchedSagas = [catchTakeLatest(actions.uploadFile, handleFileUpload)];

export default watchedSagas;

export function* handleFileUpload(
  action: ActionType<typeof actions.uploadFile>
) {
  try {
    const state: RootState = yield select();
    const token = selectors.getToken(state);
    if (!token) {
      return;
    }

    const formData = new FormData();
    formData.append("purpose", action.payload.purpose);
    formData.append("file", action.payload.file);

    yield put(commonActions.showGlobalLoader());
    const serviceInfo = BaseRestService.getServiceInfo({
      service: "upload_file"
    });
    const res: Response = yield call([window, "fetch"], serviceInfo.url, {
      body: formData,
      headers: {
        authorization: `Bearer ${token}`
      },
      method: serviceInfo.method
    });
    yield put(commonActions.hideGlobalLoader());

    if (res.status !== 200) {
      return;
    }

    const json = yield res.json();

    yield put(
      actions.appendFormInput({
        field: action.payload.field,
        form: action.payload.form,
        value: json.key
      })
    );
  } catch (e) {
    window.Logger.error("handleFileUpload: ", e.message);
  }
}
