/* tslint:disable:object-literal-sort-keys jsx-no-lambda */
import React from "react";
import { Redirect } from "react-router-dom";
import { BrowserView, MobileView, isDesktop } from "react-device-detect";
import _get from "lodash-es/get";
import queryString from "query-string";
import classNames from "classnames";

import Row from "antd/es/row";
import Col from "antd/es/col";
import Typography from "antd/es/typography";

import { history } from "src/ipm-shared/store";

import ROUTES from "src/bepaid/routes";
import useQuery from "src/bepaid/hooks/useQuery";
import Card from "src/bepaid/components/Card";
import Button from "src/bepaid/components/Form/controls/Button";
import Image from "src/bepaid/components/Image";
import Tag from "src/bepaid/components/Tag";
import Skeleton from "src/bepaid/components/Skeleton";
import OverviewBox from "src/bepaid/components/OverviewBox";
import FilterTags from "src/bepaid/components/FilterTags";
import MainLayout from "src/bepaid/layouts/MainLayout";
import ProfileHeading from "src/bepaid/layouts/MainLayout/components/ProfileHeading";
import ButtonsHeading from "src/bepaid/layouts/MainLayout/components/ButtonsHeading";
import Pagination from "src/bepaid/components/Pagination";

import CustomerSearch from "src/bepaid/assets/images/customers/customers_search.svg";
import CustomerFilter from "src/bepaid/assets/images/customers/customers_filter.svg";
import CustomerAvatar from "src/bepaid/assets/images/customers/customers_avatar.svg";
import NextIcon from "src/bepaid/assets/images/common/desktop/next_black_noborder.svg";

import CustomerTagInfo from "src/bepaid/components/CustomerTagInfo";
import CustomerSearchAndFilter from "./components/CustomerSearchAndFilter";

import { CUSTOMER_TAGS, LIMIT_PER_PAGE } from "./const";

import styles from "./Customers.module.scss";

const initPagination = {
  offset: 0,
  pageCount: LIMIT_PER_PAGE
};

const CustomerList: React.FC<any> = props => {
  const {
    hasToken,
    list,
    statistics,
    getCustomerList,
    getCustomerStatistics,
    total,
    selectCustomers
  } = props;
  const [loadingStatistic, setLoadingStatistic] = React.useState(false);
  const [loadingCustomer, setLoadingCustomer] = React.useState(false);
  const [pagination, setPagination] = React.useState(initPagination);
  const qs = useQuery();

  const hasQueryParams = Object.keys(qs).length > 0;

  const [customerTag, setCustomerTag] = React.useState({
    visibleDrawer: false,
    value: CUSTOMER_TAGS.new.value
  });

  React.useEffect(() => {
    // fetchCustomers();
    selectCustomers([]);
    getCustomerStatistics(undefined, setLoadingStatistic);
  }, []);

  React.useEffect(() => {
    getCustomerList(
      { ...qs, offset: pagination.offset, page_count: pagination.pageCount },
      (err: any, data: any) => {},
      setLoadingCustomer,
      true
    );
  }, [pagination]);

  React.useEffect(() => {
    return () => {
      setPagination(JSON.parse(JSON.stringify(initPagination)));
    };
  }, [qs.tag, qs.punctual, qs.from, qs.to, qs.search_keyword, qs.dateQueryKey]);

  const handleOpenCustomerTagDrawer = (type: string) => {
    setCustomerTag({ value: type, visibleDrawer: true });
  };

  const handleCloseCustomerTagDrawer = () => {
    setCustomerTag({ ...customerTag, visibleDrawer: false });
  };

  const handleSetNewQuery = (newQuery: any) => {
    history.push(
      `${ROUTES.CUSTOMERS}/?${queryString.stringify(newQuery || {})}`
    );
  };

  const handleAddNewCustomer = () => {
    history.push(`${ROUTES.CUSTOMER_CREATION}`);
  };

  const handleOpenDetail = (id: any) => () => {
    history.push(`${ROUTES.CUSTOMER_DETAIL.replace(":id", id)}`);
  };

  const CardDetails = ({
    icon,
    id,
    title,
    description,
    tags,
    onOpenTagDrawer,
    onClick
  }: any) => {
    const renderCustomerTags = () => {
      const displayedTag = tags[0];

      return (
        <Row justify="end" gutter={8}>
          <Col>
            <Tag
              key={`payer-${id}-${displayedTag}`}
              type={displayedTag}
              onClick={() => onOpenTagDrawer(displayedTag)}
            >
              {_get(CUSTOMER_TAGS[displayedTag], "label")}
            </Tag>
          </Col>
          {tags.length > 1 && (
            <Col>
              <Tag type="count">{`+${tags.length - 1}`}</Tag>
            </Col>
          )}
        </Row>
      );
    };

    return (
      <Card className={styles.cardDetails}>
        <Row align="middle" justify="space-between">
          <Col span={5} onClick={onClick}>
            <Image src={icon} />
          </Col>
          <Col className={styles.titleData} onClick={onClick}>
            <Col>
              <Typography.Text className={styles.title}>
                {title}
              </Typography.Text>
            </Col>
            <Col>
              <Typography.Text className={styles.description}>
                {description}
              </Typography.Text>
            </Col>
          </Col>
          {tags.length > 0 && <Col span={8}>{renderCustomerTags()}</Col>}
        </Row>
      </Card>
    );
  };

  const handleOnChangePage = (offset: number) => {
    setPagination({ ...pagination, offset });
  };

  const renderContent = () => {
    const {
      numberActiveCustomers = 0,
      numberNewCustomers = 0,
      totalCustomers = 0,
      totalInvoices = 0
    } = statistics;

    const isEmptyData =
      !numberActiveCustomers && !numberNewCustomers && !totalCustomers;

    const noPaymentSent = list.length && !totalInvoices;

    const overviewData = [
      {
        key: 1,
        label: "Number of active customers",
        value: numberActiveCustomers
      },
      {
        key: 2,
        label: "Number of new customers",
        value: numberNewCustomers
      },
      {
        key: 3,
        label: "Total number of customers",
        value: totalCustomers
      }
    ];

    return (
      <div
        className={classNames(styles.wrapper, {
          [styles.desktop]: isDesktop
        })}
      >
        <div className={styles.container}>
          <Skeleton loading={loadingStatistic}>
            <div className={styles.overviewBoxWrapper}>
              <OverviewBox
                isEmptyData={isEmptyData}
                emptyTitle={
                  noPaymentSent
                    ? "Send your first invoice and get paid."
                    : "Add your first customer and get paid."
                }
                emptyDescription={
                  "Statistics on your customers will appear here."
                }
                data={overviewData}
              />
            </div>
          </Skeleton>
          <Skeleton loading={loadingCustomer}>
            <FilterTags
              type="customers"
              handleSetNewQuery={handleSetNewQuery}
            />
            <Row className={styles.listWrapper}>
              {list.length > 0
                ? list.map((item: any) => (
                    <Col key={item.id} span={24}>
                      <CardDetails
                        icon={CustomerAvatar}
                        id={item.id}
                        title={item.name}
                        description={item.companyName}
                        tags={item.tags}
                        onClick={handleOpenDetail(item.id)}
                        onOpenTagDrawer={handleOpenCustomerTagDrawer}
                      />
                    </Col>
                  ))
                : hasQueryParams && (
                    <div className={styles.noResults}>
                      <Typography.Text>No results found.</Typography.Text>
                    </div>
                  )}
            </Row>
            <Pagination
              customClassname={styles.paginationWrapper}
              defaultMode={true}
              hideOnSinglePage={true}
              showSizeChanger={false}
              showLessItems={true}
              total={total}
              showQuickJumper={
                total / pagination.pageCount > 10 && {
                  goButton: (
                    <button className={styles.gotoButton}>
                      <b>
                        Go
                        <Image src={NextIcon} className={styles.gotoIcon} />
                      </b>
                    </button>
                  )
                }
              }
              locale={{ jump_to: "Go to page" }}
              isDisableToggle={total / pagination.pageCount < 5}
              onChange={handleOnChangePage}
              {...pagination}
            />
          </Skeleton>
        </div>

        <CustomerTagInfo
          type={customerTag.value}
          visible={customerTag.visibleDrawer}
          onClose={handleCloseCustomerTagDrawer}
        />
      </div>
    );
  };

  const renderDesktopView = () => {
    return (
      <MainLayout
        header={{
          leftHeader: <ProfileHeading />,
          rightHeader: <ButtonsHeading />
        }}
        body={{
          leftBody: renderContent(),
          rightBody: <CustomerSearchAndFilter />
        }}
      />
    );
  };

  const renderMobileView = () => {
    return (
      <MainLayout
        header={{
          title: "Customers",
          onBack: () => history.push(ROUTES.ROOT),
          rightIcons: (
            <>
              <Image
                src={CustomerSearch}
                className={styles.icon}
                onClick={() => {
                  const urlParams = new URLSearchParams(window.location.search);
                  urlParams.set("key", "customers");
                  history.push({
                    pathname: `${ROUTES.SEARCH}`,
                    search: "?" + urlParams.toString()
                  });
                }}
              />
              <Image
                src={CustomerFilter}
                className={styles.icon}
                onClick={() => {
                  const urlParams = new URLSearchParams(window.location.search);
                  urlParams.set("key", "customers");
                  history.push({
                    pathname: `${ROUTES.FILTER}`,
                    search: "?" + urlParams.toString()
                  });
                }}
              />
            </>
          ),
          hasBorder: false
        }}
        footer={
          <Button
            type="primary"
            htmlType="button"
            onClick={handleAddNewCustomer}
          >
            Add new customer
          </Button>
        }
      >
        {renderContent()}
      </MainLayout>
    );
  };

  return !hasToken ? (
    <Redirect to={ROUTES.LOGIN_REDIRECT} />
  ) : (
    <>
      <BrowserView>{renderDesktopView()}</BrowserView>
      <MobileView>{renderMobileView()}</MobileView>
    </>
  );
};

export default CustomerList;
