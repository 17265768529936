import * as React from "react";
import FormErrors from "src/ipm-shared/components/Form/helpers/Errors";
import { ADD_CARD_FORM } from "src/ipm-shared/store/model/Card/const";
import BaseCardFormView, { IBaseProps, IBaseState } from "../lib/BaseCardForm";
import { client, HostedFields, HostedFieldsStateObject } from "braintree-web";
import { hostedFields } from "braintree-web";
import _get from "lodash-es/get";
import { CardInfo } from "../../../store/model/Card/types";
import T from "../../../Utils/Intl";
import CardUtils from "../../../Utils/Card";
import { CardFormVersion } from "../type";
// import {
//   CardNumberSurffix,
//   SurffixPaymentMethod
// } from "../components/CardNumberSurffix";
import { CvvSurffix } from "../components/CvvSurffix";
import classNames from "classnames";

type IProps = IBaseProps;
type IState = IBaseState & {
  cardNumberReady: boolean;
  cardExpiryReady: boolean;
  cardCvcReady: boolean;
  selectedPaymentMethod?: string;
};

class BraintreeCardForm extends BaseCardFormView<IProps, IState> {
  private hostedFieldsInstance: HostedFields;

  constructor(props: IProps) {
    super(props);
    this.state = {
      cardFieldFocus: "",
      errors: {
        cardNumber: ""
      },
      flipped: false,
      readyToShowForm: false,
      cardNumberReady: false,
      cardExpiryReady: false,
      cardCvcReady: false,
      selectedPaymentMethod: undefined
    };
  }

  public async componentDidMount() {
    super.componentDidMount();

    const { cardFormVersion = CardFormVersion.LEGACY } = this.props;

    const hostedFieldInstanceStyle = {
      [CardFormVersion.LEGACY]: {
        // change input styles to match
        // bootstrap styles
        "font-size": "14px",
        color: "#495057"
      },
      [CardFormVersion.BEPAID]: {
        color: "#242424",
        "font-size": "16px",
        "font-family": "sans-serif",
        padding: "10px",
        "::-webkit-input-placeholder": {
          color: "#74747b"
        },
        ":-moz-placeholder": {
          color: "#74747b"
        },
        "::-moz-placeholder": {
          color: "#74747b"
        },
        ":-ms-input-placeholder": {
          color: "#74747b"
        }
      }
    };

    const fields = {
      [CardFormVersion.LEGACY]: {
        number: {
          selector: "#cc-number",
          placeholder: "4111 1111 1111 1111"
        },
        cvv: {
          selector: "#cc-cvv",
          placeholder: "123"
        },
        expirationDate: {
          selector: "#cc-expiration",
          placeholder: "MM / YY"
        }
      },

      [CardFormVersion.BEPAID]: {
        number: {
          selector: "#cc-number",
          placeholder: "1234 1234 1234 1234"
        },
        cvv: {
          selector: "#cc-cvv",
          placeholder: "CVC/CVV"
        },
        expirationDate: {
          selector: "#cc-expiration",
          placeholder: "MM/YY"
        }
      }
    };

    try {
      const clientInstance = await client.create({
        authorization: CardUtils.getBTPublicKey(this.props.acquirerId)
      });

      this.hostedFieldsInstance = await hostedFields.create({
        client: clientInstance,
        styles: {
          input: hostedFieldInstanceStyle[cardFormVersion]
        },
        fields: fields[cardFormVersion]
      });

      this.hostedFieldsInstance.on("validityChange", e => {
        const brand = _get(e.cards, "0.niceType");
        const selectedPaymentMethod = brand.toLowerCase().trim();

        this.setState({
          selectedPaymentMethod
        });

        this.onCardValidationChange(e);
      });

      this.hostedFieldsInstance.on("cardTypeChange", this.onCardMethodChange);
      this.hostedFieldsInstance.on("empty", () => {
        this.setState({ selectedPaymentMethod: undefined });
      });
      this.hostedFieldsInstance.on("focus", this.onFrameFocus);
    } catch (e) {
      console.error(e);
    }
  }

  public render() {
    const {
      modalView = false,
      cardFormVersion = CardFormVersion.LEGACY
    } = this.props;

    const isBepaid = cardFormVersion === CardFormVersion.BEPAID;

    return (
      <>
        <div
          className={"BraintreeCardForm"}
          style={{
            display: this.state.readyToShowForm ? "block" : "none"
          }}
        >
          <div>
            {modalView && <h3>{T.transl("CARD_DETAILS_HEADING")}</h3>}

            {super.renderCardholderName()}

            <div className={"form-group"}>
              {/*<label htmlFor="cc-number">Credit card number</label>*/}
              <span className={"label"}>Credit card number</span>
              <div className="surffix-container">
                <div
                  className={classNames("form-control frame--activated", {
                    ["form-control--invalid"]: Boolean(
                      this.state.errors.cardNumber
                    )
                  })}
                  id="cc-number"
                />
                {/* {isBepaid && (
                  <CardNumberSurffix
                    selecetedPaymentMethod={
                      this.state.selectedPaymentMethod as SurffixPaymentMethod
                    }
                  />
                )} */}
              </div>
              {this.state.errors.cardNumber && (
                <span className="invalid-feedback d-block">
                  {this.state.errors.cardNumber}
                </span>
              )}
            </div>
            <div className={"form-group"}>
              <span className={"label"}>Expiration</span>
              {/*<label htmlFor="cc-expiration">Expiration</label>*/}
              <div
                className="form-control frame--activated"
                id="cc-expiration"
              />
            </div>
            <div className={"form-group"}>
              <span className={"label"}>CVV</span>
              {/*<label htmlFor="cc-expiration">CVV</label>*/}
              <div className="surffix-container">
                <div className="form-control frame--activated" id="cc-cvv" />
                {isBepaid && <CvvSurffix />}
              </div>
            </div>
            <FormErrors form={ADD_CARD_FORM} className="fz-12 mb-3" />
          </div>
        </div>
      </>
    );
  }

  // Used in wrapper
  public retokenize = async (): Promise<
    | {
        cardInfo: CardInfo;
        cardholderName?: string;
      }
    | undefined
  > => {
    return new Promise(resolve => {
      if (this.hostedFieldsInstance) {
        this.hostedFieldsInstance
          .tokenize({
            cardholderName: this.state.cardHolderName
          })
          .then(cardToken => {
            resolve({
              cardInfo: {
                cardBin: cardToken.details.bin,
                cardBrand: cardToken.details.cardType,
                cardExpiryMonth: parseInt(
                  cardToken.details.expirationMonth,
                  10
                ),
                cardExpiryYear: parseInt(cardToken.details.expirationYear, 10),
                cardIssuerCountry:
                  _get(cardToken.details, "binData.countryOfIssuance") || "SG",
                cardLast4: cardToken.details.lastFour,
                cardToken: cardToken.nonce,
                cardType: cardToken.type
              },
              cardholderName: this.state.cardHolderName
            });
          })
          .catch(e => {
            resolve(undefined);
          });
      } else {
        resolve(undefined);
      }
    });
  };

  private onFrameFocus = (e: HostedFieldsStateObject) => {
    let baseElementName: any = "";
    switch (e.emittedBy) {
      case "number":
        baseElementName = "cardNumber";
        break;
      case "expirationDate":
        baseElementName = "cardExp";
        break;
      case "cvv":
        baseElementName = "cardCvv";
        break;
    }

    this.onFocus(baseElementName);
  };

  private onCardMethodChange = (e: HostedFieldsStateObject) => {
    const brand = _get(e.cards, "0.niceType");

    this.validateCardBrand(brand);
  };

  private onCardValidationChange = async (e: HostedFieldsStateObject) => {
    this.onCardTokenizationCleared();

    const ready = {
      cardCvcReady: this.state.cardCvcReady,
      cardExpiryReady: this.state.cardExpiryReady,
      cardNumberReady: this.state.cardNumberReady
    };

    for (const fieldName in e.fields) {
      if (e.fields.hasOwnProperty(fieldName)) {
        const field = e.fields[fieldName];
        switch (fieldName) {
          case "number":
            ready.cardNumberReady = field.isValid;
            break;
          case "expirationDate":
            ready.cardExpiryReady = field.isValid;
            break;
          case "cvv":
            ready.cardCvcReady = field.isValid;
            break;
        }
      }
    }

    this.setState(
      {
        cardNumberReady: ready.cardNumberReady,
        cardExpiryReady: ready.cardExpiryReady,
        cardCvcReady: ready.cardCvcReady
      },
      async () => {
        if (
          this.state.cardExpiryReady &&
          this.state.cardCvcReady &&
          this.state.cardNumberReady
        ) {
          const cardToken = await this.hostedFieldsInstance.tokenize({
            cardholderName: this.state.cardHolderName
          });

          this.onTokenized({
            cardBin: cardToken.details.bin,
            cardBrand: cardToken.details.cardType,
            cardExpiryMonth: parseInt(cardToken.details.expirationMonth, 10),
            cardExpiryYear: parseInt(cardToken.details.expirationYear, 10),
            cardIssuerCountry:
              _get(cardToken.details, "binData.countryOfIssuance") || "SG",
            cardLast4: cardToken.details.lastFour,
            cardToken: cardToken.nonce,
            cardType: cardToken.type
          });
        }
      }
    );
  };
}

export default BraintreeCardForm;
