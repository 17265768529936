import React from "react";

import { history } from "src/ipm-shared/store";

import Flex from "antd-mobile/es/flex";

import Typography from "antd/es/typography";

import Button from "src/bepaid/components/Form/controls/Button";

import ROUTES from "src/bepaid/routes";
import GuestLayout from "src/bepaid/layouts/GuestLayout";

import styles from "./ThankYou.module.scss";
import * as hoc from "src/ipm-shared/HOCs";

const ThankYou: React.FC<any> = props => {
  const withFeedBack = props.qs.withoutFeedBack === "0";

  const goToSignUp = (e: any) => {
    history.push(ROUTES.REGISTER);
  };

  return (
    <GuestLayout
      header={
        <Typography.Text className={styles.header}>Be Paid</Typography.Text>
      }
      title={
        <Typography.Text className={styles.title}>Thank you!</Typography.Text>
      }
    >
      <div className={styles.wrapper}>
        <Flex
          justify="center"
          align="center"
          className={styles.instructionSection}
        >
          <Flex.Item span={22}>
            <Typography.Text className={styles.contentText}>
              <p>
                {!withFeedBack
                  ? "Thank you for using Fetch."
                  : "Thank you for taking your time to help us improve."}
              </p>
              <p>Your account has been closed.</p>
            </Typography.Text>
          </Flex.Item>
        </Flex>
        <Flex justify="center" align="center" className={styles.buttonSection}>
          <Flex.Item span={24}>
            <Button type="primary" htmlType="button" onClick={goToSignUp}>
              Sign up
            </Button>
          </Flex.Item>
        </Flex>
        <Flex
          direction="column"
          justify="center"
          align="center"
          className={styles.extraSection}
        >
          <Flex.Item span={24} className={styles.centerText}>
            <a
              className={styles.contactUs}
              href="mailto:cs@ipaymy.com?subject=Please open my account again"
            >
              Changed your mind? <br />
              Contact us to get your account back.
            </a>
          </Flex.Item>
        </Flex>
      </div>
    </GuestLayout>
  );
};

export default hoc.withQueryString(ThankYou);
