import React from "react";
import { isMobile } from "react-device-detect";
import classNames from "classnames";
import Tabs from "antd/es/tabs";

import styles from "./Tabs.module.scss";

const Component = (props: any) => {
  const {
    tabs = [],
    onChange,
    fullWidth = false,
    size = "small",
    defaultActiveKey,
    extraMarginRight = false
  } = props;

  const handleChange = (key: string) => {
    if (onChange) {
      onChange(Number(key) - 1);
    }
  };

  return (
    <Tabs
      defaultActiveKey={defaultActiveKey ? defaultActiveKey : "1"}
      onChange={handleChange}
      className={classNames(styles.wrapper, styles[size], {
        [styles.fullWidth]: fullWidth,
        [styles.extraMarginRight]: extraMarginRight && isMobile
      })}
    >
      {tabs.map((tab: any, idx: number) => (
        <Tabs.TabPane key={idx + 1} tab={tab.name}>
          {tab.content}
        </Tabs.TabPane>
      ))}
    </Tabs>
  );
};

export default Component;
