import React, { useEffect, useState, useImperativeHandle } from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import _get from "lodash-es/get";
import { isDesktop } from "react-device-detect";

import { Document, Page } from "react-pdf/dist/esm/entry.webpack";

import Upload from "antd/es/upload";
import Button from "antd/es/button";
import Form from "src/bepaid/components/Form";

import FileUtils from "src/ipm-shared/Utils/Files";
import * as actions from "src/ipm-shared/components/Form/actions";
import ShareFilePicker from "src/ipm-shared/components/Form/controls/FilePicker";
import T from "src/ipm-shared/Utils/Intl";

import Drawer from "src/bepaid/components/Drawer";
import Image from "src/bepaid/components/Image";

import FilePreviewButton from "./components/FilePreviewButton";
import * as formSelectors from "src/ipm-shared/components/Form/selectors";

import IconUpload from "src/bepaid/assets/images/common/icon_upload.svg";

import styles from "./InputFile.module.scss";
import { RootState } from "src/ipm-shared/store/model/reducers";

const { Dragger } = Upload;

enum FileType {
  img,
  pdf,
  txt,
  csv
}

const ACCEPT_TYPES = ["jpg", "jpeg", "png", "pdf"];

const Component = React.forwardRef((props: any, ref: any) => {
  const {
    allowedFileTypes = ACCEPT_TYPES,
    multiple = false,
    syncUiStateWithRedux,
    fileObjects
  } = props;

  const [previewFile, setPreviewFile] = useState<any>(null);
  const [files, setFiles] = useState<any[]>([]);

  const { value } = props;

  // control component
  useEffect(() => {
    const { value = [] } = props;
    if (!value) return;

    const fileValues = value.map((file: any) => ({
      fileName: file.objectFile.name,
      progress: file.progress,
      ...file
    }));

    setFiles(fileValues);
  }, [value]);

  useEffect(() => {
    if (!syncUiStateWithRedux) return;

    setFiles(fileObjects);
  }, [syncUiStateWithRedux, fileObjects]);

  // PDF
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  const onSelectPdfPage = (btn: string) => () => {
    if (btn === "prev") {
      if (pageNumber > 1) {
        setPageNumber(pageNumber - 1);
      }
    }

    if (btn === "next") {
      if (pageNumber < numPages) {
        setPageNumber(pageNumber + 1);
      }
    }
  };

  const getFileType = (file: any) => {
    if (file) {
      const type = file.objectFile.type;

      if (type === "application/pdf") {
        return FileType.pdf;
      } else if (type === "text/plain") {
        return FileType.txt;
      }

      return FileType.img;
    }

    return null;
  };

  useEffect(() => {
    if (props.defaultValue && props.defaultValue.length > 0) {
      // const data = FileUtils.getInfoFromKeys(props.defaultValue);
      props.defaultValue.map((v: any) => {
        FileUtils.fetchResource({
          cb: (value: any) => {
            if (files.length > 0) {
              setFiles(
                files.map(f =>
                  f.fileKey === v.key
                    ? {
                        ...f,
                        fileKey: v.key,
                        fileName: v.name,
                        objectFile: value
                      }
                    : f
                )
              );
            } else {
              setFiles(prevFiles => [
                ...prevFiles,
                {
                  fileKey: v.key,
                  fileName: v.name,
                  objectFile: value
                }
              ]);
            }
          },
          url: v.url
        });
      });
    }
  }, []);

  let sharedPropsData: any = null;

  useImperativeHandle(ref, () => ({
    onClickDelete: () => {
      if (files.length > 0) {
        if (files[0]) {
          sharedPropsData.deleteFile(files[0]);
        }
        setFiles([files[1]]);
      }
    },
    // tslint:disable-next-line:object-literal-sort-keys
    hasFile: () => {
      return files.length > 0;
    }
  }));

  const renderComponent = (sharedProps: any) => {
    const {
      label,
      icon,
      name,
      className = null,
      btnClassName = null,
      inputFileClassName = null,
      helperText = null,
      helperNote = null
    } = props;
    sharedPropsData = sharedProps;

    const handleBeforeUpload = (f: any) => {
      // const isAcceptTypes = ACCEPT_TYPES.includes(f.type);
      // const isLt16M = f.size / 1024 / 1024 < 16;
      // return isAcceptTypes && isLt16M;
      return false;
    };

    const handleChangeUpload = (info: any) => {
      sharedProps.onAddFile([info.file], (err: any, data: any) => {
        if (!err) {
          setFiles(prevFiles => [
            ...prevFiles,
            {
              ...data[2],
              fileId: data[1],
              fileKey: data[0],
              objectFile: info.file
            }
          ]);
        }
      });
    };

    const onClickDelete = (file: any) => {
      // tslint:disable-next-line:no-shadowed-variable
      setFiles((files: any) =>
        files.filter((f: any) => f.fileKey !== file.fileKey)
      );
      sharedProps.deleteFile(file);
    };

    const onClickViewFile = (file: any) => {
      setPreviewFile(file);
    };

    const onClosePreview = (file: any) => {
      setPreviewFile(null);
    };

    const { control = {}, filesErrors } = sharedProps;

    const { errors = [] } = control;

    let errorMessage = _get(errors, "0.code", "");
    let hasError = errors.length > 0;

    if (filesErrors.length > 0 && !hasError && !errorMessage) {
      try {
        if (files.length > 0) {
          const filesHasError = files.reduce((acc, file) => {
            const fileHasError = filesErrors.find(
              (fileErrors: any) => fileErrors.name === file.fileId
            );
            return fileHasError ? [...acc, fileHasError] : acc;
          }, []);

          if (filesHasError.length > 0) {
            hasError = true;
            errorMessage = _get(filesHasError[0].errors, "0.code", "");
          }
        } else {
          hasError = true;
          errorMessage = _get(filesErrors[0].errors, "0.code", "");
        }
      } catch (err) {
        // errorMessage = err.message;
      }
    }

    const renderFileReview = (file: any) => {
      const blobUrl = URL.createObjectURL(file.objectFile);
      const fileType = getFileType(file);

      if (fileType === FileType.img) {
        return <Image src={blobUrl} />;
      } else if (fileType === FileType.pdf) {
        const onLoadPdfSuccess = ({ numPages: n }: any) => {
          setNumPages(n);
        };

        return (
          <div className={styles.pdfView}>
            <Document file={blobUrl} onLoadSuccess={onLoadPdfSuccess}>
              <Page pageNumber={pageNumber} />
            </Document>
            <div className={styles.pdfViewNumberPages}>
              <button onClick={onSelectPdfPage("prev")}>{"<"}</button>
              <p>
                Page {pageNumber} of {numPages}
              </p>
              <button onClick={onSelectPdfPage("next")}>{">"}</button>
            </div>
          </div>
        );
      } else if (fileType === FileType.txt) {
        return (
          <div className={styles.txtView}>
            <iframe src={blobUrl} />
          </div>
        );
      }

      return null;
    };

    const renderPreviewFileComponent = () => {
      const filesList = (btnClass = btnClassName, justView = false) =>
        files.map((file: any, idx: number) =>
          file ? (
            <FilePreviewButton
              key={idx}
              file={file}
              onClickViewFile={onClickViewFile}
              onClickDelete={onClickDelete}
              btnClassName={btnClass}
              justView={justView}
            />
          ) : null
        );

      if (props.renderComponent) {
        if (props.canDrag) {
          return (
            <div className={styles.draggerPlusPreview}>
              <Dragger
                accept={allowedFileTypes
                  .map((ft: string) => `.${ft}`)
                  .join(", ")}
                name="file"
                beforeUpload={handleBeforeUpload}
                showUploadList={false}
                onChange={handleChangeUpload}
                multiple={multiple}
              >
                {props.renderComponent({
                  styles: {
                    paddingBottom: `${files.length * 50 + 50}px`
                  },
                  helperNote: helperNote
                })}
              </Dragger>

              <div
                className={classNames(
                  styles.fileListWrapper,
                  inputFileClassName
                )}
              >
                {filesList(styles.filePreviewButton)}
              </div>
            </div>
          );
        }

        return props.renderComponent({ helperNote: helperNote });
      }

      return filesList(props.filePreviewButtonClassName, props.justView);
    };

    const renderUploadFileComponent = () => {
      return props.canDrag ? (
        <Dragger
          accept={allowedFileTypes.map((ft: string) => `.${ft}`).join(", ")}
          name="file"
          beforeUpload={handleBeforeUpload}
          showUploadList={false}
          onChange={handleChangeUpload}
          multiple={multiple}
        >
          {props.renderComponent ? (
            props.renderComponent({ helperNote: helperNote })
          ) : (
            <Button className={classNames(styles.btnUpload, btnClassName)}>
              {label || "Upload bank statement"}
              <Image src={icon || IconUpload} />
            </Button>
          )}
        </Dragger>
      ) : (
        <Upload
          accept={allowedFileTypes.map((ft: string) => `.${ft}`).join(", ")}
          name="file"
          beforeUpload={handleBeforeUpload}
          showUploadList={false}
          onChange={handleChangeUpload}
          multiple={multiple}
        >
          <Button className={classNames(styles.btnUpload, btnClassName)}>
            {label || "Upload bank statement"}
            <Image src={icon || IconUpload} />
          </Button>
        </Upload>
      );
    };

    return (
      <>
        <Form.Item
          name={name}
          className={className}
          validateStatus={hasError ? "error" : ""}
          {...(hasError ? { help: T.transl(errorMessage) } : {})}
          label={props.formItemLabel}
        >
          <div className={styles.wrapper}>
            {files?.length > 0
              ? renderPreviewFileComponent()
              : renderUploadFileComponent()}

            {previewFile && (
              <Drawer
                height="90%"
                placement="bottom"
                visible={Boolean(previewFile)}
                closable={true}
                closeIcon={null}
                onClose={onClosePreview.bind(null, previewFile)}
              >
                <div
                  className={classNames(styles.fileUploadedPreview, {
                    [styles.filePreviewDesktop]: isDesktop
                  })}
                >
                  {renderFileReview(previewFile)}
                </div>
              </Drawer>
            )}
          </div>
        </Form.Item>
        {helperText && (
          <p
            className={hasError ? styles.helperTextHasError : styles.helperText}
            hidden={helperText.showInEmptyMode && files.length > 0}
          >
            {helperText.txt}
          </p>
        )}
      </>
    );
  };

  return <ShareFilePicker {...props} renderComponent={renderComponent} />;
});

const mapStateToProps = (state: RootState, props: any) => {
  const file = formSelectors.getControl(state, props.name);
  if (!file)
    return {
      fileObjects: []
    };

  const fileObjects = (file?.extraInfo?.fileRefs || [])
    .map((field: string) => formSelectors.getControl(state, field))
    .filter((ref: any) => ref)
    .map((ref: any) => ref.extraInfo)
    //filter empty
    .filter((fileInfo: any) => fileInfo)
    .map((fileInfo: any) => ({
      ...fileInfo,
      objectFile: fileInfo.fileObj
    }));

  return {
    fileObjects
  };
};

const mapDispatchToProps = {
  setControl: actions.setControl
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  withRef: true
})(Component);
