import { connect } from "react-redux";
import View from "./View";

import { RootState } from "src/ipm-shared/store/model/reducers";

import * as PaymentPanelsActions from "src/ipm-platform/pages/Dashboard/BePaid/PaymentPanels/actions";
import * as PaymentActions from "src/ipm-shared/store/model/Payment/actions";
import * as GlobalUISelectors from "src/ipm-shared/components/GlobalUI/selectors";

const mapStateToProps = (state: RootState, props: any) => ({
  extraInfo: GlobalUISelectors.getExtraInfo(state, props.modalId)
});

const mapDispatchToProps = {
  cancelPaymentRequest: PaymentPanelsActions.cancelPaymentRequest,
  getInvoiceDetail: PaymentActions.selectPayment
};

export type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(View);
