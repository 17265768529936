/* tslint:disable:jsx-no-lambda */
import React from "react";
import _get from "lodash-es/get";

import T from "src/ipm-shared/Utils/Intl";

import CollectionSplitFees from "src/ipm-shared/components/CollectionSplitFees";
import { ACCOUNT_SETTINGS_FORM } from "src/bepaid/store/model/Fetch/const";

import styles from "./CollectionSplitFeesNew.module.scss";
import classnames from "classnames";
import CardUtils from "src/ipm-shared/Utils/Card";
import { CollectionSplitFeePropsWithConnectedProps } from "src/ipm-shared/components/CollectionSplitFee";
import FieldErrors from "src/ipm-shared/components/Form/helpers/FieldErrors";
import IconCelebration from "src/ipm-shared/images/icon-celebration.svg";
import VisaIcon from "src/ipm-shared/images/brand-card/visa-icon.svg";
import MasterIcon from "src/ipm-shared/images/brand-card/master-icon.svg";
import NumberUtil from "src/ipm-shared/Utils/Number";
import UnionPayIcon from "src/ipm-shared/images/brand-card/china-unionpay-icon.svg";
import AmexIcon from "src/ipm-shared/images/brand-card/amex-icon.svg";
import CryptoIcons from "src/ipm-shared/images/crypto/crypto_icon_logos.svg";
import { getTrackBackground, Range } from "react-range";
import {
  BRAND_ID,
  CRYPTO_OPTION
} from "src/ipm-shared/store/model/CollectedAccount/const";

const FormComponent = (props: any) => {
  const {
    collectedAccount,
    collectionRates,
    feeSettings,
    handleChangeFees
  } = props;

  if (!collectedAccount || !collectionRates) {
    return null;
  }

  return (
    <div>
      <CollectionSplitFees
        renderer={renderCollectionSplitFee}
        rendererSlider={renderCollectionSplitFeesSlider.bind(
          null,
          props.accountCountryCode
        )}
        supportedBrandId={CardUtils.getSupportedBrands(
          Number(_get(props, "acquirerId", 0)),
          props.accountCountryId
        )}
        hasActiveProgram={props.hasActiveProgram}
        className={"collection-request-review"}
        defaultValue={feeSettings}
        // This should be different from props.extraInfo.formName to avoid override value
        form={ACCOUNT_SETTINGS_FORM + "_edit_fees"}
        onChangeCustom={handleChangeFees}
        currencyId={_get(props.collectedAccount, "currencyId") as number}
      />
    </div>
  );
};

const renderCollectionSplitFeesSlider = (
  accountCountryCode: string,
  selectedBrandId: number,
  handleChangeTab: (brandId: number) => void
) => {
  return (
    <div className={styles.tab_select}>
      <div
        className={`${styles.select} ${
          selectedBrandId === BRAND_ID.VISA ? styles.active : ""
        }`}
        onClick={() => handleChangeTab(BRAND_ID.VISA)}
      >
        Card
      </div>
      <div
        hidden={true}
        className={`${styles.select} ${styles.second_tab}  ${
          selectedBrandId === BRAND_ID.UNION ? styles.active : ""
        }`}
        onClick={() => handleChangeTab(BRAND_ID.UNION)}
      >
        China UnionPay
      </div>
      <div
        className={`${styles.select} ${styles.second_tab}  ${
          selectedBrandId === CRYPTO_OPTION ? styles.active : ""
        }`}
        onClick={() => handleChangeTab(CRYPTO_OPTION)}
      >
        Crypto
      </div>
    </div>
  );
};

const renderCollectionSplitFee = (
  props: CollectionSplitFeePropsWithConnectedProps,
  handleSplitRate: (type: string, values: number) => void
) => {
  const { className, collectionRates, feeControls } = props;
  if (!collectionRates) {
    return null;
  }

  return (
    <>
      <div
        className={`collection-split-fee-section ${className ? className : ""}`}
      >
        {props.brandId === CRYPTO_OPTION ? (
          <>
            {renderRangeDOM(
              props,
              collectionRates.crypto_rate || 0,
              feeControls.crypto_rate,
              "crypto_rate",
              handleSplitRate
            )}
          </>
        ) : (
          <>
            {renderRangeDOM(
              props,
              collectionRates.national,
              feeControls.national,
              "national",
              handleSplitRate
            )}
            <hr />
            {renderRangeDOM(
              props,
              collectionRates.international,
              feeControls.international,
              "international",
              handleSplitRate
            )}
          </>
        )}

        <FieldErrors
          name={`requester_national_rate${
            props.extraName ? `_${props.extraName}` : ""
          }`}
          className={"mt-2"}
        />
        <FieldErrors
          name={`requester_international_rate${
            props.extraName ? `_${props.extraName}` : ""
          }`}
          className={"mt-2"}
        />
      </div>
      {props.hasActiveProgram && (
        <div className="mt-2 collection-split-fee-notification">
          <span>
            {T.transl("FREE_PAYMENTS_MESSAGE")}
            <img src={IconCelebration} alt={"Icon Celebration"} />
          </span>
        </div>
      )}
    </>
  );
};

// NOTE: the real value is in *100 format. For e.g: 200
// When we display, easily /100 and toFix 2
const renderRangeDOM = (
  props: CollectionSplitFeePropsWithConnectedProps,
  defaultRate: number,
  value: number,
  type: string,
  handleSplitRate: (type: string, values: number) => void
) => {
  if (type !== "crypto_rate") {
    if (defaultRate === 0 || isNaN(value)) {
      return null; // This could be in initial stage. Should not render anything.
      // Also prevent this error: RangeError: min (0) is equal/bigger than max (0)
      // Btw, this should never happen
    }
  }
  if (defaultRate < value) {
    value = defaultRate;
  }
  return (
    <div className={styles.wrapper}>
      {props.brandId === 2 && type === "national" && (
        <p className={styles.titles}>
          <img width={28} src={VisaIcon} alt="Visa Icon" />
          <img width={28} src={MasterIcon} alt="Master Icon" />
          <b>{generateCopy(props.accountCountryCode)}</b>
          {` ${T.transl("VISA_LABEL")} `}
          {`${T.transl("MASTERCARD_LABEL")} `}
          <b>
            (0 - {NumberUtil.formatRate(defaultRate)}
            %)
          </b>
        </p>
      )}
      {props.brandId === 2 && type === "international" && (
        <p className={styles.titles}>
          <img width={28} src={VisaIcon} alt="Visa Icon" />
          <img width={28} src={MasterIcon} alt="Master Icon" />
          <b>{`${T.transl("INTERNATIONAL_LABEL")} `}</b>
          {`${T.transl("VISA_LABEL")} ${T.transl("MASTERCARD_LABEL")} `}
          <b>(0 - {NumberUtil.formatRate(defaultRate)}%)</b>
        </p>
      )}
      {props.brandId === 5 && type === "national" && (
        <p className={styles.titles}>
          <img width={28} src={UnionPayIcon} alt="Union Icon" />
          <b>{generateCopy(props.accountCountryCode)}</b>
          {` ${T.transl("UNIONPAY_LABEL")} `}
          <b>
            (0 - {NumberUtil.formatRate(defaultRate)}
            %)
          </b>
        </p>
      )}
      {props.brandId === 5 && type === "international" && (
        <p className={styles.titles}>
          <>
            <img width={28} src={UnionPayIcon} alt="Union Icon" />
            <b>{`${T.transl("INTERNATIONAL_LABEL")} `}</b>
            {`${T.transl("UNIONPAY_LABEL")} `}
            <b>(0 - {NumberUtil.formatRate(defaultRate)}%)</b>
          </>
        </p>
      )}
      {props.brandId === 4 && type === "national" && (
        <p className={styles.titles}>
          <img width={28} src={AmexIcon} alt="Amex" />
          <b>{generateCopy(props.accountCountryCode)}</b>
          {` ${T.transl("AMEX_LABEL")} `}
          <b>
            (0 - {NumberUtil.formatRate(defaultRate)}
            %)
          </b>
        </p>
      )}
      {props.brandId === 4 && type === "international" && (
        <p className={styles.titles}>
          <>
            <img width={28} src={AmexIcon} alt="Amex Icon" />
            <b>{`${T.transl("INTERNATIONAL_LABEL")} `}</b>
            {`${T.transl("AMEX_LABEL")} `}
            <b>(0 - {NumberUtil.formatRate(defaultRate)}%)</b>
          </>
        </p>
      )}
      {props.brandId === CRYPTO_OPTION && type === "crypto_rate" && (
        <p
          className={classnames(styles.titles, {
            [styles.crypto]: true
          })}
        >
          <>
            <img src={CryptoIcons} alt="crypto Icons" />
            <b>(0 - {NumberUtil.formatRate(defaultRate)}%)</b>
          </>
        </p>
      )}
      <div className={styles.slider}>
        <Range
          step={10}
          min={0}
          // MUST: init max = 100 in case not receive collectionRates: { national, international } data yet
          max={defaultRate}
          values={[value]}
          onChange={handleSplitRate.bind(handleSplitRate, type)}
          /* tslint:disable-next-line:jsx-no-lambda no-shadowed-variable */
          renderTrack={({ props, children }) => (
            <div
              className={styles.rangeTrack}
              {...props}
              style={{
                ...props.style,
                background: getTrackBackground({
                  colors: ["#5a6be8", "#f5f5f5"],
                  max: defaultRate,
                  min: 0,
                  values: [value]
                }),
                height: "4px",
                width: "100%"
              }}
            >
              {children}
            </div>
          )}
          /* tslint:disable-next-line:jsx-no-lambda no-shadowed-variable */
          renderThumb={({ props }) => (
            <div
              className={styles.circleThumb}
              {...props}
              style={{
                ...props.style,
                height: "1rem",
                width: "1rem"
              }}
            >
              <span className={styles.circleInner} />
            </div>
          )}
        />
      </div>
      <div className={styles.footer}>
        <span>
          {`${T.transl("YOU_PAY_LABEL")}: `}
          <b>{NumberUtil.formatRate(value)}%</b>
        </span>
        <span>
          {`${T.transl("CUSTOMER_PAYS_LABEL")}: `}
          <b>{NumberUtil.formatRate(defaultRate - value)}%</b>
        </span>
      </div>
    </div>
  );
};

const generateCopy = (accountCountryCode: string) => {
  switch (accountCountryCode) {
    case "SG":
      return T.transl("COUNTRY_LABEL_SG");
    case "MY":
      return T.transl("COUNTRY_LABEL_MY");
    case "HK":
      return T.transl("COUNTRY_LABEL_HK");
    case "AU":
      return T.transl("COUNTRY_LABEL_AU"); // TODO: Add AU label
    default:
      return "";
  }
};

const View: React.FC<any> = props => {
  // const { isDesktop } = props;

  return (
    <div className={classnames(styles.wrapper)}>
      <FormComponent {...props} />
    </div>
  );
};

export default View;
