/* tslint:disable:object-literal-sort-keys */
import React from "react";
import Checkbox from "antd/es/checkbox";
import _get from "lodash-es/get";

import { history } from "src/ipm-shared/store";

import Progress from "src/bepaid/components/Progress";
import ROUTES from "src/bepaid/routes";
import Image from "src/bepaid/components/Image";

import IconPen from "src/bepaid/assets/images/common/icon_pencil.svg";
import { getCompletedProfileCustomer } from "src/bepaid/utils/customer";

import styles from "./List.module.scss";
import { invokeMessage } from "src/bepaid/components/Message";

function View({
  options = [],
  onSelectCustomer = () => null,
  getSelectedCustomersId
}: any) {
  const selectedCustomerPercent = (o: any) => {
    return getCompletedProfileCustomer({
      ...o,
      phoneNumber: o.phoneNumber,
      customerCommunicationMethod: o.primaryCommunicationMethod
    });
  };
  const editCustomer = (option: any) => {
    history.push(ROUTES.CUSTOMER_EDIT.replace(":id", option.id));
  };
  const onChange = (o: any) => (e: any) => {
    onSelectCustomer(o, e.target.checked);
  };
  const onClick = (isDeclined: any) => (e: any) => {
    isDeclined &&
      invokeMessage(
        "error",
        "This party has not been approved due to external and internal regulatory requirements."
      );
  };
  return (
    <div className={styles.wrapper}>
      {options.map((option: any, idx: number) => {
        const isActivated = getSelectedCustomersId.includes(option.id);
        const isDeclined = option.reviewStatus === "D";
        return (
          <div
            key={idx}
            className={styles.listItem}
            style={isActivated ? { backgroundColor: "#f2f4f5" } : {}}
            onClick={onClick(isDeclined)}
          >
            <div className={styles.item}>
              <Checkbox
                checked={isActivated}
                onChange={onChange(option)}
                disabled={isDeclined}
              >
                <p className={styles.listItemName}>{option.name}</p>
                {option.companyName && (
                  <p className={styles.listItemDescription}>
                    {option.companyName}
                  </p>
                )}
              </Checkbox>
              {!isDeclined && (
                <div className={styles.icon}>
                  {/* <Image src={IconInformation} /> */}
                  <Image
                    src={IconPen}
                    alt={`update ${option.id}`}
                    onClick={editCustomer.bind(null, option)}
                  />
                </div>
              )}
            </div>
            <div className={styles.progressBar}>
              {selectedCustomerPercent(option)}% Complete
              <Progress
                value={selectedCustomerPercent(option)}
                colors={["#067DF4", "#0B4F93"]}
                strokeWidth={10}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default View;
