import React, { useMemo, useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import DesktopModalLayout from "src/bepaid/components/Modal/components/DesktopModalLayout";
import MainLayout from "src/bepaid/layouts/MainLayout";

import ROUTES from "src/bepaid/routes";
import { history } from "src/ipm-shared/store";

import Button from "src/bepaid/components/Form/controls/Button";
import Progress from "src/bepaid/components/Progress";
import { invokeMessage } from "src/bepaid/components/Message";

import _lowerCase from "lodash-es/lowerCase";
import _get from "lodash-es/get";

import { purposeByEntity } from "src/bepaid/pages/Payments/const";
import { useMakePaymentUrlParams } from "src/bepaid/pages/Payments/hooks";
import PaymentUtil from "src/ipm-shared/Utils/Payment";

import BankAccountItem from "./BankAccountItem";

import styles from "../ExpandBox/ExpandboxContainer.module.scss";

const View = (props: any) => {
  const {
    validatePaymentPayeeSubmit,
    selectedPayees,
    accountCountryId,
    fetchDateConditions,
    addPaymentRequestSubmit,
    onClose
  } = props;

  const {
    entity,
    paymentType,
    replaceRouteWithParams
  } = useMakePaymentUrlParams();
  const purpose = purposeByEntity[entity];
  const isWallex = paymentType === "international";

  const [loading, setLoading] = useState(false);

  const submitLabel = useMemo(() => {
    return loading ? "Moving forward..." : "Move forward";
  }, [loading]);

  const onBack = () => {
    history.goBack();
  };

  const handleError = (err: any, data?: any) => {
    if (err?.message) {
      invokeMessage("error", err.message);
    }
  };

  const redirectToCheckout = () => {
    addPaymentRequestSubmit(
      purpose,
      isWallex,
      {
        cb: (err?: any, data?: any) => {
          const checkoutUrl = data
            ? `${replaceRouteWithParams(ROUTES.PAYMENTS_CREATION, {
                newAction: "checkout"
              })}/?token=${data?.token}`
            : "/";
          if (err) {
            handleError(err, { checkoutUrl });
          } else {
            history.push(checkoutUrl, {
              prevRoute: window.location.pathname
            });
          }
        }
      },
      setLoading
    );
  };

  const moveForward = () => {
    validatePaymentPayeeSubmit(
      purpose,
      isWallex,
      {
        cb: (err?: any) => {
          if (err) {
            invokeMessage("error", err.message);
          } else {
            if (isWallex || props.isCryptoPaymentMethod) {
              const options = {
                cb: (err?: any, data?: any) => {
                  if (err) {
                    invokeMessage("error", "Bad request!");
                  }
                  if (data) {
                    redirectToCheckout();
                  }
                },
                justGetData: false
              };
              const bankIds = selectedPayees.map((p: any) => p.bankId);
              if (
                selectedPayees[0] &&
                PaymentUtil.isUSDPaymentInHK(
                  accountCountryId,
                  selectedPayees[0].currencyId as number,
                  selectedPayees[0].countryId
                )
              ) {
                fetchDateConditions(
                  new Date(),
                  bankIds,
                  3, // HK
                  73, // USD
                  73, // USD,
                  undefined,
                  selectedPayees.map((p: any) => p.id),
                  options,
                  setLoading
                );
              } else {
                fetchDateConditions(
                  new Date(),
                  bankIds,
                  isWallex
                    ? _get(selectedPayees[0], "international.bankCountryId")
                    : null,
                  isWallex ? _get(selectedPayees[0], "currencyId") : null,
                  undefined,
                  undefined,
                  selectedPayees.map((p: any) => p.id),
                  options,
                  setLoading
                );
              }
            } else {
              history.push(
                replaceRouteWithParams(ROUTES.PAYMENTS_CREATION, {
                  newAction: "schedule"
                })
              );
            }
          }
        }
      },
      setLoading
    );
  };

  const renderBtnSubmit = () => {
    return (
      <>
        <Button
          wrapperClassName={styles.wrapperButton}
          onClick={moveForward}
          type="primary"
          htmlType="button"
          loading={loading}
        >
          {submitLabel}
        </Button>
      </>
    );
  };

  const renderDesktopView = () => {
    return (
      <DesktopModalLayout
        maxWidth450={true}
        onSubmit={moveForward}
        title={props.titleLabel}
        onClose={onClose}
        onBack={onBack}
        progress={
          <Progress colors={["#937CF2", "#A581E8"]} value={props.progress} />
        }
        submitButtonClassName={""}
        btnSubmit={renderBtnSubmit()}
      >
        {renderContent()}
      </DesktopModalLayout>
    );
  };

  const renderMobileView = () => {
    return (
      <MainLayout
        header={{
          title: props.titleLabel,
          hideMenu: true,
          onBack,
          onClose,
          progress: (
            <Progress colors={["#937CF2", "#A581E8"]} value={props.progress} />
          )
        }}
        footer={renderBtnSubmit()}
      >
        {renderContent()}
      </MainLayout>
    );
  };

  const renderContent = () => {
    return (
      <div className={styles.content}>
        <div className={styles.wrapper}>
          <div className={styles.groupContainer}>
            {props.selectedPayees.map((payee: any) => {
              return (
                <div key={payee.id}>
                  <BankAccountItem
                    payee={payee}
                    paymentCurrencyCode={props.paymentCurrencyCode}
                    accountCountryCode={props.accountCountryCode}
                    selectedPayment={props.selectedPayment}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <BrowserView>{renderDesktopView()}</BrowserView>
      <MobileView>{renderMobileView()}</MobileView>
    </>
  );
};

export default View;
