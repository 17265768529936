import React from "react";
import classNames from "classnames";
import _get from "lodash-es/get";

import Form from "src/bepaid/components/Form";

import store from "src/ipm-shared/store";
import { getBanksOptionsByCountryId } from "src/ipm-shared/store/model/Bank/selectors";
import { ADD_FORM } from "src/ipm-shared/store/model/CollectedAccount/const";

import InputText from "src/bepaid/components/Form/controls/InputText";
import Select from "src/bepaid/components/Form/controls/Select";
import BankBSB from "src/bepaid/components/BankBSB";
import InputFile from "src/bepaid/components/Form/controls/InputFile";
import Image from "src/bepaid/components/Image";
import InputFileDropZone from "src/bepaid/components/Form/controls/InputFile/components/InputFileDropZone";

import IconArrowDown from "src/bepaid/assets/images/common/drop_down_arrow_grey.svg";

import FormStyles from "src/bepaid/components/Form/CommonForm.module.scss";
import OtherStyles from "../KYCContents/KycContents.module.scss";

import FileUtils from "src/ipm-shared/Utils/Files";

const styles = {
  ...FormStyles,
  ...OtherStyles
};

const BankDetailsForm = (props: any) => {
  const commonFormItemProps = {
    reserveValueOnUnmount: true,
    revertValueOnMount: true
  };

  const data = props.collectedAccount;
  const countryId = _get(props.accountProfile, "countryId", 0);
  const filterBank = getBanksOptionsByCountryId(store.getState(), countryId);
  const bankDocumentProps = {
    allowedFileTypes: ["jpg", "png", "heic", "pdf"],
    name: `bank_documents`,
    purpose: "kyc",
    form: ADD_FORM,
    label: "Upload invoice",
    // icon: IconUpload,
    canDrag: true,
    // multiple: true,
    renderComponent: InputFileDropZone,
    revertValueOnMount: true,
    reserveValueOnUnmount: true,
    className: "upload_bank_documents",
    defaultValue: _get(data, "payeeData.bankDocuments", [])
      ? FileUtils.getInfoFromKeys(_get(data, "payeeData.bankDocuments", []))
      : null,
    showUploadingItem: false
  };
  return (
    <div className={styles.content}>
      <Form
        layout="vertical"
        requiredMark={false}
        className={styles.personalFormDetail}
      >
        {props.accountCountryCode === "AU" ? (
          <Form.Item>
            <BankBSB
              form={ADD_FORM}
              name="bank_bsb_id"
              placeholder="Enter bank name, branch, or BSB"
              label="Bank name, branch, or BSB"
              defaultValue={_get(data, "bankBSBId")}
              suffixIcon={<Image src={IconArrowDown} />}
              className={styles.bankBsb}
              {...commonFormItemProps}
            />
          </Form.Item>
        ) : (
          <Select
            form={ADD_FORM}
            name="bank_id"
            placeholder="Select bank"
            label="Bank"
            options={filterBank}
            defaultValue={
              _get(data, "bankId") === 0 ? null : _get(data, "bankId")
            }
            suffixIcon={<Image src={IconArrowDown} />}
            className={styles.select}
            {...commonFormItemProps}
          />
        )}
        {props.isHongKongAccount && (
          <div hidden={true}>
            <Select
              form={ADD_FORM}
              name="currency_id"
              placeholder="Select currency"
              label="Currency"
              options={[
                { label: "HKD (HK$)", value: "3" },
                { label: "USD (US$)", value: "73" }
              ]}
              defaultValue={String(props.accountCurrencyId)}
              suffixIcon={<Image src={IconArrowDown} />}
              className={styles.select}
              {...commonFormItemProps}
            />
          </div>
        )}
        <InputText
          form={ADD_FORM}
          name="account_number"
          placeholder="Account number"
          label="Account number"
          defaultValue={_get(data, "accountNumber")}
          {...commonFormItemProps}
        />
        <InputText
          form={ADD_FORM}
          name="confirm_account_number"
          placeholder="Confirm account number"
          label="Confirm account number"
          defaultValue={_get(data, "accountNumber")}
          {...commonFormItemProps}
        />
      </Form>
      <Form
        layout="vertical"
        requiredMark={false}
        className={styles.personalFormDetail}
      >
        <h5>Bank statement</h5>
        <p className={styles.text}>
          Upload a recent copy of your bank statement (dated within the past 3
          months). This bank account must belong to the company you are creating
          your Fetch account for. The bank statement must include:
        </p>
        <ul>
          <li className={classNames(styles.text, styles.subText)}>
            Company Name
          </li>
          <li className={classNames(styles.text, styles.subText)}>Bank Name</li>
          <li className={classNames(styles.text, styles.subText)}>
            Account Number
          </li>
        </ul>
        <p className={classNames(styles.text, styles.purple)}>
          Please ensure your document includes the above information to avoid
          any delay in payouts.
        </p>
        <InputFile {...bankDocumentProps} />
      </Form>
    </div>
  );
};
export default BankDetailsForm;
