/**
 * Actions
 *
 * ActionCreators are like the "pub" of pubsub. It's just broadcasting an event (action).
 * So, don't couple it with a specific reducer. Let ANY reducer listen to
 * an action.
 *
 * `typesafe-actions` has a nice utility function so we don't need to define action types as
 * constants. Use it together with `getType(type)` in the reducers.
 */
import { createAction } from "typesafe-actions";
import { UenType } from "./reducer";

export const searchUenList = createAction(
  "adminUen/Search",
  resolve => (offset?: number, pageCount?: number) =>
    resolve({ offset, pageCount })
);

export const setUenList = createAction(
  "adminUen/setUenList",
  resolve => (args: {
    isFetching: boolean;
    uenList: UenType[];
    total: number;
  }) => resolve(args)
);

export const addUen = createAction("admiUen/addUen");
export const editUen = createAction(
  "adminUen/editUen",
  resolve => (regno: string) => resolve({ regno })
);
export const removeUen = createAction(
  "adminUen/removeUen",
  resolve => (regno: string) => resolve({ regno })
);
