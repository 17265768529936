import React, { useState } from "react";
import { BrowserView, MobileView, isDesktop } from "react-device-detect";
import classNames from "classnames";

import { FetchModalID } from "src/ipm-shared/components/GlobalUI/actions";

import DesktopModalLayout from "src/bepaid/components/Modal/components/DesktopModalLayout";
import ListCompanies from "src/bepaid/layouts/Menu/components/Companies";

import { MODES } from "src/bepaid/layouts/Menu/components/Companies/const";
import MainLayout from "src/bepaid/layouts/MainLayout";

import styles from "./ListCompaniesModal.module.scss";

const View = (props: any) => {
  const extraInfo = props.extraInfo || {};

  const [mode, setMode] = useState(MODES.SHOW_COMPANY_LIST);

  const title = {
    [MODES.SHOW_COMPANY_LIST]: "Companies",
    [MODES.ADD_NEW_COMPANY]: "Add company",
    [MODES.EDIT_EXISTING_COMPANY]: "Edit company"
  }[mode];

  const toggleModal = () => {
    props.toggleModal(FetchModalID.LIST_COMPANIES);
  };

  const renderContent = () => (
    <div
      className={classNames(styles.wrapper, {
        [styles.wrapperDesktop]: isDesktop
      })}
    >
      <ListCompanies
        onChangeMode={setMode}
        switchMode={extraInfo.switchCompany}
      />
    </div>
  );

  return (
    <>
      <BrowserView>
        <DesktopModalLayout title={title} onClose={toggleModal}>
          {renderContent()}
        </DesktopModalLayout>
      </BrowserView>
      <MobileView>
        <MainLayout
          header={{
            title,
            hideMenu: true,
            onClose: toggleModal
          }}
        >
          {renderContent()}
        </MainLayout>
      </MobileView>
    </>
  );
};

export default View;
