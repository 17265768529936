/* tslint:disable:object-literal-sort-keys jsx-no-lambda */
import React from "react";
import { Redirect } from "react-router-dom";
import { BrowserView, isDesktop, MobileView } from "react-device-detect";
import queryString from "query-string";
import classNames from "classnames";

import Row from "antd/es/row";
import Col from "antd/es/col";
import Typography from "antd/es/typography";

import { history } from "src/ipm-shared/store";

import ROUTES from "src/bepaid/routes";
import Button from "src/bepaid/components/Form/controls/Button";
import Image from "src/bepaid/components/Image";
import useQuery from "src/bepaid/hooks/useQuery";
import { purposeByEntity } from "src/bepaid/pages/Payments/const";
import { typeByEntity } from "src/bepaid/utils/recipient";

import Skeleton from "src/bepaid/components/Skeleton";
import FilterTags from "src/bepaid/components/FilterTags";
import MainLayout from "src/bepaid/layouts/MainLayout";
import ProfileHeading from "src/bepaid/layouts/MainLayout/components/ProfileHeading";
import ButtonsHeading from "src/bepaid/layouts/MainLayout/components/ButtonsHeading";
import Pagination from "src/bepaid/components/Pagination";

import CustomerSearch from "src/bepaid/assets/images/customers/customers_search.svg";
import CustomerFilter from "src/bepaid/assets/images/customers/customers_filter.svg";
import CustomerAvatar from "src/bepaid/assets/images/customers/customers_avatar.svg";
import NextIcon from "src/bepaid/assets/images/common/desktop/next_black_noborder.svg";

import RecipientsSearchAndFilter from "../components/RecipientsSearchAndFilter";
import OverviewBoxs from "../components/OverviewBoxs";
import CardDetails from "../components/CardDetails/View";

import { useMakePayeeUrlParams } from "../hooks";
import { PAYEES_PAGE_COUNT } from "src/ipm-shared/store/model/Payee/const";
import styles from "../Recipients.module.scss";

const initPagination = {
  offset: 0,
  pageCount: PAYEES_PAGE_COUNT
};

const View: React.FC<any> = props => {
  const {
    hasToken,
    list,
    // shouldDisplayLoadingPayees,
    total
  } = props;
  const [loading, setLoading] = React.useState(false);
  const [pagination, setPagination] = React.useState(initPagination);

  const qs = useQuery();
  const { replaceRouteWithParams } = useMakePayeeUrlParams();
  const purpose = purposeByEntity[typeByEntity.employees];

  // const hasQueryParams = Object.keys(qs).length > 0;

  const handleSetNewQuery = (newQuery: any) => {
    history.push(
      `${ROUTES.RECIPIENTS_EMPLOYEES}/?${queryString.stringify(newQuery || {})}`
    );
  };

  const handleOpenDetail = (payeeId: number) => {
    const search = new URLSearchParams(history.location.search);

    const url =
      `${ROUTES.RECIPIENTS_EMPLOYEES}/${payeeId}` + "?" + search.toString();

    history.push(url, {
      prevRoute: replaceRouteWithParams(ROUTES.RECIPIENTS_DETAILS, {
        newEntity: "employees"
      })
    });
  };

  const shouldFetchData = !(props.isBgOfPopup && props.isFirstFetchData);

  const handleOnChangePage = (offset: number) => {
    setPagination({ ...pagination, offset });
  };

  const renderContent = () => {
    return (
      <div
        className={classNames(styles.wrapper, {
          [styles.desktop]: isDesktop
        })}
      >
        <div className={styles.container}>
          <div className={styles.overviewBoxWrapper}>
            <OverviewBoxs />
          </div>

          <Skeleton loading={loading && shouldFetchData}>
            <FilterTags
              type="recipients"
              handleSetNewQuery={handleSetNewQuery}
            />
            <Row className={styles.listWrapper}>
              {list.length > 0 ? (
                list.map((item: any) => (
                  <Col key={item.id} span={24}>
                    <CardDetails
                      icon={CustomerAvatar}
                      id={item.id}
                      title={item.name}
                      description={item.idNumber}
                      onClick={() => handleOpenDetail(item.id)}
                      // onOpenTagDrawer={handleOpenCustomerTagDrawer}
                    />
                  </Col>
                ))
              ) : (
                <div className={styles.noResults}>
                  <Typography.Text>No results found.</Typography.Text>
                </div>
              )}
            </Row>
            <Pagination
              customClassname={styles.paginationWrapper}
              defaultMode={true}
              hideOnSinglePage={true}
              showSizeChanger={false}
              showLessItems={true}
              total={total}
              showQuickJumper={
                total / pagination.pageCount > 10 && {
                  goButton: (
                    <button className={styles.gotoButton}>
                      <b>
                        Go
                        <Image src={NextIcon} className={styles.gotoIcon} />
                      </b>
                    </button>
                  )
                }
              }
              locale={{ jump_to: "Go to page" }}
              isDisableToggle={total / pagination.pageCount < 5}
              onChange={handleOnChangePage}
              {...pagination}
            />
          </Skeleton>
        </div>
      </div>
    );
  };

  const renderDesktopView = () => {
    return (
      <MainLayout
        header={{
          leftHeader: <ProfileHeading />,
          rightHeader: (
            <ButtonsHeading type={"recipients"} recipientPath={"employees"} />
          )
        }}
        body={{
          leftBody: renderContent(),
          rightBody: <RecipientsSearchAndFilter recipientPath={"employees"} />
        }}
      />
    );
  };

  const renderMobileView = () => {
    const handleCreateNewEmployee = () => {
      history.push(ROUTES.RECIPIENTS_EMPLOYEES_CREATE);
    };
    return (
      <MainLayout
        header={{
          title: "Employees",
          onBack: () => history.push(ROUTES.ROOT),
          rightIcons: (
            <>
              <Image
                src={CustomerSearch}
                className={styles.icon}
                onClick={() => {
                  const urlParams = new URLSearchParams(window.location.search);
                  urlParams.set("key", "employees");
                  history.push({
                    pathname: `${ROUTES.SEARCH}`,
                    search: "?" + urlParams.toString()
                  });
                }}
              />
              <Image
                src={CustomerFilter}
                className={styles.icon}
                onClick={() => {
                  const urlParams = new URLSearchParams(window.location.search);
                  urlParams.set("key", "employees");
                  history.push({
                    pathname: `${ROUTES.FILTER}`,
                    search: "?" + urlParams.toString()
                  });
                }}
              />
            </>
          ),
          hasBorder: false
        }}
        footer={
          <Button
            type="primary"
            htmlType="button"
            onClick={handleCreateNewEmployee}
          >
            Add employee
          </Button>
        }
      >
        {renderContent()}
      </MainLayout>
    );
  };

  const fetchListData = () => {
    // setLoading(true);
    props.fetchPayees({
      type: "salary",
      // getAllPayees: true,
      offset: pagination.offset,
      pageCount: pagination.pageCount,
      noRedirect: true,
      cb: () => {
        // setLoading(false);
      },
      loadingFunc: setLoading
    });
  };

  React.useEffect(() => {
    if (!shouldFetchData) {
      return;
    }
    props.selectPayees({
      ids: [],
      purpose,
      removedIds: "all"
    });
    fetchListData();
  }, [pagination]);

  React.useEffect(() => {
    if (!shouldFetchData) {
      return;
    }
    // fetchListData();
    return () => {
      setPagination(JSON.parse(JSON.stringify(initPagination)));
    };
  }, [qs.from, qs.to, qs.search_keyword, qs.dateQueryKey]);

  return !hasToken ? (
    <Redirect to={ROUTES.LOGIN_REDIRECT} />
  ) : (
    <>
      <BrowserView>{renderDesktopView()}</BrowserView>
      <MobileView>{renderMobileView()}</MobileView>
    </>
  );
};

export default View;
