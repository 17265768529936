import { ActionType } from "typesafe-actions";
import { call } from "redux-saga/effects";
import * as screenfull from "screenfull";

import { catchTakeLatest } from "src/ipm-shared/Utils/ReduxSagaEffects";

import * as actions from "./actions";

const watchedSagas = [
  catchTakeLatest(actions.requestFullScreen, handleRequestFullscreen)
];
export default watchedSagas;
export function* handleRequestFullscreen(
  action: ActionType<typeof actions.requestFullScreen>
) {
  if (!screenfull) {
    return;
  }

  if (screenfull.isFullscreen) {
    yield call([screenfull, "exit"]);
  } else {
    yield call([screenfull, "request"]);
  }
}
