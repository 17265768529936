/**
 * Selectors
 *
 * It's like "views" in relational databases.
 * Store the most efficient format in the reducer state.
 * But to retrieve into easy to consume form, use selector.
 */
import { RootState } from "src/ipm-shared/store/model/reducers";
import ReducerFactory from "src/ipm-shared/Utils/ReduxReducer";
import business from "./reducers";
ReducerFactory.registerReducer({ business });
import _uniq from "lodash-es/uniq";
import _get from "lodash-es/get";
import _find from "lodash-es/find";
import T from "src/ipm-shared/Utils/Intl";

// Business Types Selectors
export const getBusinessTypes = (state: RootState) =>
  state.business.businessTypes;

// Business Industries Selectors
export const getIndustriesGroupName = (state: RootState) =>
  _uniq(state.business.businessIndustries.map(b => b.groupName)).sort((a, b) =>
    a < b ? -1 : 1
  );

export const getBusinessIndustries = (state: RootState) => {
  const groupName = getIndustriesGroupName(state);
  const allItems = state.business.businessIndustries;

  return groupName.map(gn => ({
    children: allItems
      .filter(item => item.groupName === gn)
      .map(c => ({
        label: T.transl(`BUSINESS_INDUSTRY_${c.id}`),
        value: c.id.toString()
      }))
      .sort((a, b) => (a.label < b.label ? -1 : 1)),
    label: T.transl(
      `BUSINESS_INDUSTRY_GROUP_${_get(
        _find(allItems, { groupName: gn }),
        "groupId"
      )}`
    )
  }));
};

// Business Industries Selectors
export const getOccupationsGroupName = (state: RootState) =>
  _uniq(state.business.businessOccupations.map(b => b.groupName)).sort((a, b) =>
    a < b ? -1 : 1
  );

export const getBusinessOccupations = (state: RootState) => {
  const groupName = getOccupationsGroupName(state);
  const allItems = state.business.businessOccupations;

  return groupName.map(gn => ({
    children: allItems
      .filter(item => item.groupName === gn)
      .map(c => ({
        label: c.name,
        value: c.id.toString()
      }))
      .sort((a, b) => (a.label < b.label ? -1 : 1)),
    label: gn
  }));
};

export const getIsFetchBusiness = (state: RootState) =>
  state.business.isFetching;
