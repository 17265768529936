/* tslint:disable:object-literal-sort-keys jsx-no-lambda */
import React, { useEffect, useState } from "react";
import classNames from "classnames";
import _get from "lodash-es/get";
import _truncate from "lodash-es/truncate";
import * as DateFns from "date-fns";

import Col from "antd/es/grid/col";
import Row from "antd/es/grid/row";
import Divider from "antd/es/divider";
import Skeleton from "antd/es/skeleton";
import DownOutlined from "@ant-design/icons/DownOutlined";
import UpOutlined from "@ant-design/icons/UpOutlined";

import T from "src/ipm-shared/Utils/Intl";
import CountryUtil from "src/ipm-shared/Utils/Country";

import Progress from "src/bepaid/components/Progress";
import Tag from "src/bepaid/components/Tag";
import Image from "src/bepaid/components/Image";
import ExpandBox from "src/bepaid/components/ExpandBox";
import ActionDetailDrawer from "src/bepaid/components/ActionDetailDrawer";

import { getCompletedProfileCustomer } from "src/bepaid/utils/customer";
import StepsActivity, { Activity } from "src/bepaid/components/StepsActivity";
import CustomerTagInfo from "src/bepaid/components/CustomerTagInfo";
import { CUSTOMER_TAGS } from "src/bepaid/pages/Customers/const";

import IconCustomerAvatar from "src/bepaid/assets/images/customers/customers_avatar.svg";
import IconArrowDown from "src/bepaid/assets/images/common/arrow_down_fresh.svg";

import styles from "./CustomerDetail.module.scss";

const showActivity = true;

const CustomerDetail: React.FC<any> = props => {
  const { data } = props;
  const [expand, setExpand] = useState(false);
  const [progress, setProgress] = useState(0);
  const [activities, setActivities] = useState([]);
  const [selectDays, setSelectDays] = useState("30");
  const [loading, setLoading] = useState(false);
  const [isOpenFilterDays, setOpenFilterDays] = useState(false);
  const [customerTag, setCustomerTag] = React.useState({
    visibleDrawer: false,
    value: CUSTOMER_TAGS.new.value
  });

  const handleToggleAdditionalInfo = () => {
    setExpand(!expand);
  };

  useEffect(() => {
    if (data.id > 0) {
      const p = getCompletedProfileCustomer(data);
      setProgress(p);
    }
  }, [data.id]);

  useEffect(() => {
    if (!data.id) {
      return;
    }
    let qs = null;
    if (selectDays !== "all") {
      const duration = Number(selectDays);
      const to = new Date();
      const from = DateFns.addDays(new Date(), -duration);
      qs = { from: from.toISOString(), to: to.toISOString() };
    }

    props.getCustomerActivities(
      data.id as number,
      qs,
      (err: any, value: any) => {
        setActivities(value || []);
      },
      setLoading
    );
  }, [selectDays]);

  const handleOnChangeFilterDays = (value: any) => {
    setSelectDays(value);
  };

  const filterDaysActions = [
    {
      label: "Last 30 days",
      key: "30"
    },
    {
      label: "Last 60 days",
      key: "60"
    },
    {
      label: "Last 90 days",
      key: "90"
    },
    {
      label: "All time",
      key: "all"
    }
  ].map((opt: any) => ({
    ...opt,
    func: (optData: any) => {
      handleOnChangeFilterDays(opt.key);
    }
  }));

  const handleOpenCustomerTagDrawer = (type: string) => {
    setCustomerTag({ value: type, visibleDrawer: true });
  };

  const handleCloseCustomerTagDrawer = () => {
    setCustomerTag({ ...customerTag, visibleDrawer: false });
  };

  const renderCommonInfo = () => {
    const { id, name, tags = [] } = data;
    return (
      <>
        <Row>
          <Col span={8}>
            <p className={styles.textTitle}>Customer</p>
            <p className={styles.cusName}>{name}</p>
          </Col>
          {tags.length > 0 && (
            <Col span={16}>
              <Row gutter={[8, 4]} justify="end">
                {tags.map((tag: string) => (
                  <Col span={8}>
                    <Tag
                      key={`payer-${id}-${tag}`}
                      type={tag}
                      onClick={() => handleOpenCustomerTagDrawer(tag)}
                    >
                      {_get(CUSTOMER_TAGS[tag], "label")}
                    </Tag>
                  </Col>
                ))}
              </Row>
            </Col>
          )}
        </Row>
        <Divider />
      </>
    );
  };

  const renderMoreInfo = () => {
    const {
      addressLine1,
      addressLine2,
      email,
      mobileNumber,
      phoneNumber,
      companyName,
      registrationNumber
    } = data;

    return (
      <>
        <Row>
          <Col>
            <p className={styles.textLabel}>Email</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className={styles.textTitle}>{email}</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className={styles.textLabel}>Mobile number</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className={styles.textTitle}>
              (+
              {CountryUtil.getCallingCodeFromCountryId(
                _get(data, "mobileCountryId", props.countryId)
              )}
              ) {mobileNumber}
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className={styles.textLabel}>Phone number</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className={styles.textTitle}>
              (+
              {CountryUtil.getCallingCodeFromCountryId(
                _get(data, "phoneCountryId", props.countryId)
              )}
              ) {phoneNumber}
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className={styles.textLabel}>Company name</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className={styles.textTitle}>{companyName}</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className={styles.textLabel}>{T.transl("UEN_PLACEHOLDER")}</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className={styles.textTitle}>{registrationNumber}</p>
          </Col>
        </Row>

        {addressLine1 && (
          <>
            <Row>
              <Col>
                <p className={styles.textLabel}>{T.transl("ADDRESS_LABEL")}</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className={styles.textAddress}>{addressLine1}</p>
                {addressLine2 && (
                  <p className={styles.textAddress}>{addressLine2}</p>
                )}
              </Col>
            </Row>
          </>
        )}
      </>
    );
  };

  return (
    <div className={styles.wrapper}>
      <Row>
        <Col className={styles.center} span={24}>
          <Image src={data.avatar || IconCustomerAvatar} width={70} />
        </Col>
        <Col className={styles.progress} span={24}>
          <p className={styles.titleProgress}>{progress}% Complete</p>
          <Progress value={progress} colors={["#067DF4", "#0B4F93"]} />
        </Col>
        <Col span={24}>
          {progress !== 100 && (
            <p className={classNames(styles.textMoreAbout)}>
              Update customer's profile to get paid faster!
            </p>
          )}
        </Col>

        <Col span={24}>
          <ExpandBox
            type={"customer"}
            originalContent={
              <>
                {renderCommonInfo()}
                <Row>
                  <Col>
                    <p className={styles.textTitle}>Email</p>
                  </Col>
                  <Col flex="auto">
                    <Row justify="end">
                      <Col>
                        <p className={styles.textTitle}>
                          {_truncate(data.email, { length: 20 })}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <div className={styles.wrapperAdditional}>
                  <DownOutlined
                    className={styles.additionalIcon}
                    onClick={handleToggleAdditionalInfo}
                  />
                </div>
              </>
            }
            expandContent={
              <>
                {renderCommonInfo()}
                {renderMoreInfo()}
                <div className={styles.wrapperAdditional}>
                  <UpOutlined
                    className={styles.additionalIcon}
                    onClick={handleToggleAdditionalInfo}
                  />
                </div>
              </>
            }
            defaultExpand={false}
          />
        </Col>

        {showActivity && (
          <>
            <Col span={24}>
              <Row className={styles.wrapperActivity}>
                <Col className={styles.wrapperTitle}>
                  <p className={styles.textTitle}>Activity</p>
                </Col>
                <Col flex="auto" className={styles.selectRateWrapper}>
                  <Row justify="end">
                    <Col>
                      <div
                        className={styles.selectRate}
                        onClick={setOpenFilterDays.bind(null, true)}
                      >
                        <span>
                          {filterDaysActions.find(opt => opt.key === selectDays)
                            ?.label || "Select a range"}
                        </span>
                        <Image src={IconArrowDown} />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Skeleton loading={loading}>
                <StepsActivity
                  type={"customer"}
                  activities={activities.map(
                    (act: any) =>
                      ({
                        description: act.message,
                        date: act.date,
                        isDone: true,
                        selected: false,
                        type: "payment"
                      } as Activity)
                  )}
                />
              </Skeleton>
            </Col>
          </>
        )}
      </Row>

      <ActionDetailDrawer
        height={"auto"}
        contentFullWidth={true}
        visible={isOpenFilterDays}
        actions={filterDaysActions}
        onClose={setOpenFilterDays.bind(null, false)}
      />

      <CustomerTagInfo
        type={customerTag.value}
        visible={customerTag.visibleDrawer}
        onClose={handleCloseCustomerTagDrawer}
      />
    </div>
  );
};

export default CustomerDetail;
