import React, { useEffect, useState } from "react";
import { BrowserView, MobileView, isMobile } from "react-device-detect";
import classNames from "classnames";
import _get from "lodash-es/get";

import { history } from "src/ipm-shared/store";

import DesktopModalLayout from "src/bepaid/components/Modal/components/DesktopModalLayout";
import MainLayout from "src/bepaid/layouts/MainLayout";
// import useQuery from "src/bepaid/hooks/useQuery";
import ROUTES from "src/bepaid/routes";

import ScheduleDetail from "./components/ScheduleDetail";

import styles from "./ScheduleDetailModal.module.scss";
import EllipsisOutlined from "@ant-design/icons/EllipsisOutlined";

function ScheduleDetailModal(props: any) {
  const { selectSchedule, extraInfo, selectedScheduleDetail } = props;
  const [loadingPayment, setLoadingPayment] = useState(true);
  const paymentDetail = _get(selectedScheduleDetail, "paymentSetting");

  useEffect(() => {
    if (extraInfo.scheduleId) {
      setLoadingPayment(false);
      selectSchedule(Number(extraInfo.scheduleId));
    }
  }, [extraInfo.scheduleId]);
  useEffect(() => {
    return () => {
      selectSchedule(-1);
    };
  }, []);

  const closeModal = () => {
    const url = ROUTES.PAYMENTS + history.location.search;

    history.push(url, {
      prevRoute: ROUTES.PAYMENT_SCHEDULE_DETAILS
    });
  };

  const extendSchedulePayment = () => {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set("is_schedule", "y");
    localStorage.setItem(
      "paymentEditUrl",
      window.location.pathname + window.location.search
    );
    const url = ROUTES.SCHEDULE_EXTEND.replace(":id", paymentDetail.scheduleId);
    history.push({
      pathname: url,
      search: "?" + urlParams.toString()
    });
  };

  const [actionDrawer, openActionDrawer] = React.useState({
    value: 0,
    visibleDrawer: false,
    visibleOnePaymentDrawer: false
  });

  const renderContent = () => {
    return (
      <ScheduleDetail
        loading={loadingPayment}
        {...props.extraInfo}
        actionDrawer={actionDrawer}
        openActionDrawer={openActionDrawer}
      />
    );
  };

  const onMoreAction = () => {
    openActionDrawer({
      ...actionDrawer,
      value: paymentDetail.id,
      visibleDrawer: false,
      visibleOnePaymentDrawer: !actionDrawer.visibleOnePaymentDrawer
    });
  };

  const shouldShowMoreIcon = _get(selectedScheduleDetail, "isActive", false);
  const upcomingSchedulePayments = _get(
    selectedScheduleDetail,
    "upcomingPayment.payments",
    []
  ).filter((payment: any) => payment.paymentStatusId === 2)?.length;
  const shouldShowExtendPaymentBanner =
    _get(selectedScheduleDetail, "upcomingPayment.payments") &&
    upcomingSchedulePayments > 0 &&
    upcomingSchedulePayments <= 3;

  const renderRightIcon = () => (
    <EllipsisOutlined
      className={styles.iconMoreAction}
      onClick={onMoreAction}
    />
  );

  const bannerProps = {
    ...(shouldShowExtendPaymentBanner && {
      banner: (
        <div className={styles.banner}>
          <p
            className={classNames(styles.bannerText, {
              [styles.isMobile]: isMobile
            })}
            onClick={extendSchedulePayment}
          >
            Your schedule payment is ending soon. Click here to extend it now
          </p>
        </div>
      )
    })
  };

  return (
    <>
      <BrowserView>
        <DesktopModalLayout
          title={"Schedule details"}
          maxWidth450={true}
          onClose={closeModal}
          onMoreAction={shouldShowMoreIcon ? onMoreAction : undefined}
          {...bannerProps}
        >
          <div className={styles.wrapper}>{renderContent()}</div>
        </DesktopModalLayout>
      </BrowserView>
      <MobileView>
        <MainLayout
          header={{
            title: "Schedule details",
            onClose: closeModal,
            hideMenu: true,
            rightIcons: shouldShowMoreIcon ? renderRightIcon() : null
          }}
          usingModal={true}
          {...bannerProps}
        >
          <div
            className={classNames(styles.wrapper, {
              [styles.wrapperMobile]: isMobile
            })}
          >
            {renderContent()}
          </div>
        </MainLayout>
      </MobileView>
    </>
  );
}

export default ScheduleDetailModal;
