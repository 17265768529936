// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ToolTipArrow_wrapper__slXqX{margin-bottom:1rem}.ToolTipArrow_wrapper__slXqX .ToolTipArrow_text__317q2{margin-bottom:0;font-size:.75rem;color:#5a6be8;font-style:italic;display:inline-block}.ToolTipArrow_wrapper__slXqX .ToolTipArrow_arrow__356r5{-webkit-transform:translateY(80%);-ms-transform:translateY(80%);transform:translateY(80%);z-index:9}", "",{"version":3,"sources":["webpack://./src/bepaid/components/ToolTipArrow/ToolTipArrow.module.scss"],"names":[],"mappings":"AAAA,6BAAS,kBAAkB,CAAC,uDAAe,eAAe,CAAC,gBAAgB,CAAC,aAAa,CAAC,iBAAiB,CAAC,oBAAoB,CAAC,wDAAgB,iCAAyB,CAAzB,6BAAyB,CAAzB,yBAAyB,CAAC,SAAS","sourcesContent":[".wrapper{margin-bottom:1rem}.wrapper .text{margin-bottom:0;font-size:.75rem;color:#5a6be8;font-style:italic;display:inline-block}.wrapper .arrow{transform:translateY(80%);z-index:9}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "ToolTipArrow_wrapper__slXqX",
	"text": "ToolTipArrow_text__317q2",
	"arrow": "ToolTipArrow_arrow__356r5"
};
export default ___CSS_LOADER_EXPORT___;
