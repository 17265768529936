import { connect } from "react-redux";
import _get from "lodash-es/get";

import { RootState } from "src/ipm-shared/store/model/reducers";
import * as actions from "src/ipm-shared/components/Form/actions";
import * as FormSelectors from "src/ipm-shared/components/Form/selectors";
import { INVOICE_DETAIL_FORM } from "src/ipm-shared/store/model/Customer/const";

import * as selectors from "src/bepaid/store/model/Fetch/selectors";

import View from "./View";

const mapStateToProps = (state: RootState) => ({
  collectedAccount: selectors.getCollectedAccount(state),
  currencyId: selectors.getAccountCurrencyId(state),
  formState: FormSelectors.getControls(state, INVOICE_DETAIL_FORM),
  getCollectionRates: selectors.getCollectionRates(state),
  getControl: (name: string, form: string) =>
    selectors.getControl(state, name, form),
  getFilesControl: selectors.getFileControl(
    state,
    selectors.getControlsPattern(state, /^document_tag_/),
    "document_tag_"
  ),
  getIncentives: selectors.getControlsPattern(state, /^incentives_/),
  getItems: selectors.getItems(state),
  getSelectedCustomers: selectors.getSelectedCustomers(state),
  getSelectedItemsIds: selectors.getSelectedItemsIds(state),
  invoiceLineItems: selectors.getInvoiceLineItems(state),
  taxes: selectors.getTaxes(state),
  acquirerId: selectors.getPaymentCollectionAcquirerId(state),
  accountCountryId: selectors.getAccountCountryId(state),
  hasActiveProgram: selectors.getHasActiveProgram(state),
  collectionRates: selectors.getCollectionRates(state),
  invoiceSelected: selectors.getInvoiceDraftSelected(state)
});

const mapDispatchToProps = {
  setControl: actions.setControl
};

export default connect(mapStateToProps, mapDispatchToProps)(View);
