import * as React from "react";
import Renderer from "./Renderer";
import { ComponentType } from "react";
import _isEmpty from "lodash-es/isEmpty";
import { ControlErrorType, ControlType, ControlValueType } from "../../types";
import T from "src/ipm-shared/Utils/Intl";
import Tooltip from "reactstrap/lib/Tooltip";

/**
 * Base class for form input
 *
 * Every control component must be extended from FormControl class
 */
export type IBaseProps = {
  form?: string;

  group?: string;

  /** Name of this control. See value at state.print_form[formContainer].data[name] */
  name: string;

  defaultValue?: any;

  /** Presence validation */
  required?: boolean;
  requiredMessage?: string | JSX.Element;

  /** Disable editing and copying */
  disabled?: boolean;

  /** Disable editing but enable to copy */
  readOnly?: boolean;

  placeholder?: string;

  className?: string;

  label?: string | JSX.Element;

  labelOnly?: boolean;

  labelOnlyRenderer?: string | ((value: ControlValueType) => JSX.Element);

  onChangeCustom?: (args: any) => any;

  displayInline?: boolean;

  reserveValueOnUnmount?: boolean;
  revertValueOnMount?: boolean;

  onEnter?: () => any;

  /* To force to display error on first init component */
  displayError?: boolean;

  /* To force hide error in every situation */
  hideError?: boolean;

  overrideMessage?: (errors: ControlErrorType) => React.ReactNode;

  tabIndex?: number;

  id?: string;

  errorStyle?: "__TOOLTIP__";

  /**
   * Set this to automatically focus on control on initialization
   * Second, if control is re-rendered after its parent change, this help to re-focus after control was rendered
   */
  autoFocus?: boolean;

  onFocus?: () => any;

  onBlur?: () => any;

  keyChanged?: boolean;

  requiredConfirmation?: boolean;

  dataAttributes?: {
    [name: string]: string;
  };
};

export type IBaseState = {
  errorTooltipControl?: boolean;
  errorTooltipLabel?: boolean;
};
export default class BaseControl<
  P extends IBaseProps,
  S extends IBaseState
> extends React.Component<P, S> {
  public static defaultProps = {
    displayError: false,
    form: "default"
  };

  public static *baseValidate(
    props: {
      required?: boolean;
      requiredMessage?: string | JSX.Element;
    },
    value: ControlValueType
  ) {
    const { required } = props;

    if (
      required &&
      (typeof value === "undefined" || // Is undefined
      value === null || // Is null
      (typeof value === "object" && _isEmpty(value)) || // Is empty object
        (typeof value === "string" && value === "")) // Is empty string
    ) {
      yield {
        code: "REQUIRED",
        message: props.requiredMessage || T.transl("REQUIRED_FIELD")
      };
    }

    return;
  }

  protected alive: boolean = true;
  protected ref: HTMLElement;
  private autoFocused: boolean = false;

  constructor(props: P) {
    super(props);
    this.state = {} as Readonly<S>;
  }

  protected onAfterValidate = (value: ControlValueType) => {
    if (this.props.autoFocus && !this.autoFocused) {
      setTimeout(() => {
        if (this.ref) {
          this.ref.focus();
          this.autoFocused = true;
        }
      }, 0);
    }
  };

  protected renderLabelOnly = (
    control: ControlType,
    id?: string,
    errorStyle?: string,
    fallbackRenderValue: boolean = true
  ) => {
    const { labelOnlyRenderer } = this.props;
    const { value, errors } = control;
    if (
      errors.length > 0 &&
      control.displayError &&
      errorStyle === "__TOOLTIP__"
    ) {
      return (
        <React.Fragment>
          <div className="d-flex csv-label-only">
            <span className="text-danger">
              {value}
              {"  "}
            </span>
            {id && (
              <i
                id={`tooltip-label-${id}`}
                className="tooltip-feedback-icon label-only"
                onClick={this.preventFromClickTooltip}
              >
                <img
                  width="12"
                  height="12"
                  src="data:image/svg+xml;charset=UTF-8,%3csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11.02 12.52'%3e%3cdefs%3e%3cstyle%3e.cls-1%7bfill:%23a6babc;%7d.cls-2%7bfont-size:8.21px;fill:%23fff;font-family:Helvetica-Bold, Helvetica;font-weight:700;%7d%3c/style%3e%3c/defs%3e%3ctitle%3eArtwork9_53714e77-0e54-4404-bf5e-6ddab1c33db2%3c/title%3e%3ccircle class='cls-1' cx='5.51' cy='5.51' r='5.51'/%3e%3ctext class='cls-2' transform='matrix(1, 0, 0, 1, 2.94, 8.56)'%3e?%3c/text%3e%3c/svg%3e"
                />
              </i>
            )}
          </div>

          {id && (
            <Tooltip
              placement="top-start"
              delay={0}
              target={`tooltip-label-${id}`}
              className="csv-error-tooltip"
              isOpen={this.state.errorTooltipLabel}
              toggle={this.errorTooltipLabel}
            >
              {control.errors.map((error, idx) => (
                <span key={idx} className="d-block">
                  {this.props.overrideMessage
                    ? this.props.overrideMessage(error)
                    : error.message}
                </span>
              ))}
            </Tooltip>
          )}
        </React.Fragment>
      );
    }

    if (errors.length === 0 && labelOnlyRenderer) {
      if (typeof labelOnlyRenderer === "string") {
        if (labelOnlyRenderer in Renderer) {
          return Renderer[labelOnlyRenderer](value);
        }
      } else {
        try {
          return (this.props.labelOnlyRenderer as any)(value);
        } catch (e) {
          window.Logger.guestError(
            "Please provide proper labelOnlyRenderer function"
          );
        }
      }
    }

    if (fallbackRenderValue) {
      return (
        <div className="csv-label-only">
          {" "}
          <span>{value}</span>
        </div>
      );
    }

    return null;
  };

  protected renderErrorMessage = (control: ControlType) => {
    const { errorStyle, hideError } = this.props;

    if (hideError) {
      return null;
    }

    return (
      <React.Fragment>
        {!errorStyle && // Normal error style
          control.displayError &&
          control.errors.map((error, idx) => (
            <span key={idx} className="invalid-feedback d-block">
              {this.props.overrideMessage
                ? this.props.overrideMessage(error)
                : error.message}
            </span>
          ))}
        {errorStyle === "__TOOLTIP__" && // Tooltip error style
          control.displayError &&
          control.errors.length > 0 && (
            <span className="tooltip-feedback">
              <i
                id={`tooltip_input_${this.props.id}`}
                className="tooltip-feedback-icon"
              >
                <img
                  width="12"
                  height="12"
                  src="data:image/svg+xml;charset=UTF-8,%3csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11.02 12.52'%3e%3cdefs%3e%3cstyle%3e.cls-1%7bfill:%23a6babc;%7d.cls-2%7bfont-size:8.21px;fill:%23fff;font-family:Helvetica-Bold, Helvetica;font-weight:700;%7d%3c/style%3e%3c/defs%3e%3ctitle%3eArtwork9_53714e77-0e54-4404-bf5e-6ddab1c33db2%3c/title%3e%3ccircle class='cls-1' cx='5.51' cy='5.51' r='5.51'/%3e%3ctext class='cls-2' transform='matrix(1, 0, 0, 1, 2.94, 8.56)'%3e?%3c/text%3e%3c/svg%3e"
                />
              </i>
              <Tooltip
                placement="top-start"
                delay={0}
                isOpen={this.state.errorTooltipControl}
                target={`tooltip_input_${this.props.id}`}
                className="csv-error-tooltip"
                toggle={this.errorTooltipControl}
              >
                {control.errors.map((error, idx) => (
                  <span key={idx} className="d-block">
                    {this.props.overrideMessage
                      ? this.props.overrideMessage(error)
                      : error.message}
                  </span>
                ))}
              </Tooltip>
            </span>
          )}
      </React.Fragment>
    );
  };

  protected errorTooltipControl = () => {
    this.setState({
      errorTooltipControl: !this.state.errorTooltipControl
    });
  };

  protected errorTooltipLabel = () => {
    this.setState({
      errorTooltipLabel: !this.state.errorTooltipLabel
    });
  };

  protected preventFromClickTooltip = (e: React.MouseEvent<any>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  protected shouldRevertValueOnMount(control: ControlType) {
    const { revertValueOnMount, keyChanged } = this.props;

    // Only revert if the value is reserved before that.
    return (
      !keyChanged &&
      revertValueOnMount &&
      !_isEmpty(control) &&
      control.value !== undefined
    );
  }
}

export type BaseControlComponentType<P extends IBaseProps> = ComponentType<
  P
> & {};
