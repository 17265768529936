export const CUSTOMER_TAGS = {
  active: { label: "Active", value: "active" },
  new: { label: "New", value: "new" },
  risky: { label: "Risky", value: "risky" },
  vip: { label: "VIP", value: "vip" }
};

export const MODES = {
  ADD_NEW_CUSTOMER: 1,
  DETAILS_CUSTOMER: 2,
  EDIT_CUSTOMER: 3,
  SELECT_CUSTOMER: 5,
  SHOW_CUSTOMER_LIST: 4
};

export const LIMIT_PER_PAGE = 30;
