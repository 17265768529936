import React, { useEffect, useState } from "react";
import { BrowserView, MobileView, isMobile } from "react-device-detect";
import classNames from "classnames";

import { history } from "src/ipm-shared/store";

import ROUTES from "src/bepaid/routes";
import useQuery from "src/bepaid/hooks/useQuery";
import MainLayout from "src/bepaid/layouts/MainLayout";
import DesktopModalLayout from "src/bepaid/components/Modal/components/DesktopModalLayout";
import { invokeMessage } from "src/bepaid/components/Message";

import EditPaymentDetail from "./components/EditPaymentDetail";

import styles from "./PaymentDetailModal.module.scss";
import MobileMainButton from "../../../layouts/MainLayout/components/MobileMainButton";
import _get from "lodash-es/get";
import { FetchModalID } from "../PaymentsCreationModal/components/SchedulePayment/actions";

function PaymentDetailEditModal(props: any) {
  const { selectPayment, extraInfo, selectSchedule, openModal } = props;
  const [loadingPayment, setLoadingPayment] = useState(true);
  const qs = useQuery();
  useEffect(() => {
    if (extraInfo.paymentId) {
      selectPayment(
        extraInfo.paymentId,
        () => {
          setLoadingPayment(false);
        },
        {
          idType: "id",
          withPaymentActivity: false
        }
      );
    }
  }, [extraInfo.paymentId]);
  useEffect(() => {
    if (extraInfo.scheduleId) {
      selectSchedule(Number(extraInfo.scheduleId));
    }
  }, [extraInfo.scheduleId]);

  const closeModal = () => {
    const urlParams = new URLSearchParams(window.location.search);
    if (qs.is_schedule === "y") {
      const url = ROUTES.PAYMENT_SCHEDULE_DETAILS.replace(
        ":id",
        extraInfo.scheduleId
      );
      history.push({
        pathname: url,
        search: "?" + urlParams.toString()
      });
      return;
    }
    if (props.selectedPayment) {
      urlParams.set("idType", "id");
      const url = ROUTES.PAYMENT_DETAILS.replace(
        ":id",
        props.selectedPayment.id
      );
      history.push({
        pathname: url,
        search: "?" + urlParams.toString()
      });
    } else {
      const url = ROUTES.PAYMENTS;
      history.push({
        pathname: url,
        search: "?" + urlParams.toString()
      });
    }
  };
  const [loading, setLoading] = React.useState(false);

  const onSaved = () => {
    invokeMessage("success", "Changes saved.");
    closeModal();
  };

  const handleSubmitEditPayment = () => {
    props.submitEditPayments(
      !extraInfo.paymentId ? [] : [Number(extraInfo.paymentId)],
      false,
      (err: any, data: any) => {
        if (err) {
          if (_get(err, "form[0]") === "DUPLICATE_PAYOUT_DATE") {
            openModal(FetchModalID.PAYMENT_DUPLICATE_MODAL, {
              paymentId: extraInfo.paymentId
            });
          }
          return;
        }

        onSaved();
      },
      setLoading
    );
    return;
  };

  const renderContent = () => {
    return (
      <EditPaymentDetail
        loading={loadingPayment}
        {...props.extraInfo}
        isEditAll={props.extraInfo.isEditAll}
      />
    );
  };

  return (
    <>
      <BrowserView>
        {" "}
        <DesktopModalLayout
          title={
            qs.is_schedule === "y"
              ? "Edit schedule details"
              : "Edit payment details"
          }
          maxWidth450={true}
          onClose={closeModal}
          submitLabel={loading ? "Saving..." : "Save changes"}
          loading={loading}
          onSubmit={handleSubmitEditPayment}
        >
          <div className={styles.wrapper}>{renderContent()}</div>
        </DesktopModalLayout>
      </BrowserView>
      <MobileView>
        <MainLayout
          header={{
            title: "Edit payment details",
            onClose: closeModal,
            hideMenu: true
          }}
          footer={
            <MobileMainButton
              buttonText={loading ? "Saving..." : "Save changes"}
              action={handleSubmitEditPayment}
            />
          }
        >
          <div
            className={classNames(styles.wrapper, {
              [styles.wrapperMobile]: isMobile
            })}
          >
            {renderContent()}
          </div>
        </MainLayout>
      </MobileView>
    </>
  );
}

export default PaymentDetailEditModal;
