/* tslint:disable:object-literal-sort-keys */
import React from "react";
import { isDesktop } from "react-device-detect";
import classnames from "classnames";
import Menu from "antd/es/menu";

import Drawer from "src/bepaid/components/Drawer";
import styles from "./ActionDetailDrawer.module.scss";

const ActionDetailDrawer = (props: any) => {
  const {
    type = "customer",
    data = {},
    visible = false,
    onClose = null,
    actions,
    children,
    width = "100%",
    height = "100%",
    contentFullWidth = false
  } = props;

  const handleClick = (e: any) => {
    const action = actions.find((item: any) => item.key === e.key);
    if (action) {
      action.func(data);
      onClose();
    }
  };
  const renderContent = () => {
    if (children) {
      return children;
    }
    return (
      <div
        className={classnames(styles.wrapper, {
          [styles.customerStyle]: type === "customer",
          [styles.invoiceStyle]: type === "invoice",
          [styles.recipientStyle]: type === "recipient",
          [styles.desktopWrapper]: isDesktop,
          [styles.contentFullWidth]: contentFullWidth
        })}
      >
        <div className={styles.content}>
          <Menu className={styles.menu} onClick={handleClick} mode="vertical">
            {actions.map((item: any) => {
              return (
                <Menu.Item className={styles.menuItem} key={item.key}>
                  {item.label}
                </Menu.Item>
              );
            })}
          </Menu>
        </div>
      </div>
    );
  };

  return (
    <Drawer
      height={height}
      width={width}
      placement="bottom"
      visible={visible}
      closable={true}
      closeIcon={null}
      onClose={onClose}
      contentFullWidth={contentFullWidth}
    >
      {renderContent()}
    </Drawer>
  );
};

export default ActionDetailDrawer;
