import React from "react";
import Card from "antd/es/card";

import styles from "./Card.module.scss";

const Component = (props: any) => {
  const { children, title = "", className, onClick = null } = props;

  const commonProps = {};

  return (
    <div className={styles.wrapper} onClick={onClick}>
      <Card title={title} {...commonProps} className={className}>
        {children}
      </Card>
    </div>
  );
};

export default Component;
