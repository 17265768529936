import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import _get from "lodash-es/get";
import _isEqual from "lodash-es/isEqual";
import classNames from "classnames";
import EllipsisOutlined from "@ant-design/icons/EllipsisOutlined";
import Checkbox from "antd/es/checkbox";

import IconReviewGreen from "src/bepaid/assets/images/common/icon_review_green.svg";
import Image from "src/bepaid/components/Image";
import usePrevious from "src/bepaid/hooks/usePrevious";

import StepsActivity, { Activity } from "./StepActivity";

import paymentUtil from "../utils/payment";
import useQuery from "src/bepaid/hooks/useQuery";
import styles from "./Styles.module.scss";
import { PAYMENT_GROUP_STATUS_NAME } from "src/bepaid/pages/Filter/const";

const SchedulePayments = ({
  openActionDrawer = null,
  paymentDetail = null,
  currentPaymentId = 0,
  currencyId = 0,
  onScroll = () => {},
  openScheduleDetail = null,
  isSchedule = "n"
}: any) => {
  const handleToggleMoreAction = () => {
    openActionDrawer();
  };
  const [toggleStatus, setToggleStatus] = useState({
    upcomming: true,
    inprogress: false,
    completed: false
  });
  const qs = useQuery();
  const showAllStatus = qs.statuses === "n";
  const handleChangeFilterStatus = (status: string) => () => {
    setToggleStatus({
      ...toggleStatus,
      [status]: !toggleStatus[status]
    });
  };
  const [activities, setActivities] = useState(
    _get(paymentDetail, "payments", [])
  );
  const prevPayments = usePrevious(_get(paymentDetail, "payments", []));
  useEffect(() => {
    handleFilterActivies(toggleStatus);
  }, [toggleStatus.upcomming, toggleStatus.inprogress, toggleStatus.completed]);

  useEffect(() => {
    if (!_isEqual(_get(paymentDetail, "payments", []), prevPayments)) {
      handleFilterActivies(toggleStatus);
    }
  }, [_get(paymentDetail, "payments", []), prevPayments]);

  useEffect(() => {
    if (showAllStatus) {
      setToggleStatus({
        ...toggleStatus,
        upcomming: false
      });
    }
  }, []);
  const handleFilterActivies = (toggleStatus: any) => {
    let filterActivities: any[] = [];
    let allPayment = _get(paymentDetail, "payments", []);
    if (toggleStatus.upcomming) {
      filterActivities = [
        ...filterActivities,
        ...allPayment.filter((item: any) => {
          const paymentStatus = paymentUtil.getPaymentStatus({
            paymentStatusId: item.paymentStatusId
          });
          return paymentStatus === PAYMENT_GROUP_STATUS_NAME.Scheduled;
        })
      ];
    }
    if (toggleStatus.inprogress) {
      filterActivities = [
        ...filterActivities,
        ...allPayment.filter((item: any) => {
          const paymentStatus = paymentUtil.getPaymentStatus({
            paymentStatusId: item.paymentStatusId
          });
          return paymentStatus === PAYMENT_GROUP_STATUS_NAME.InProgress;
        })
      ];
    }
    if (toggleStatus.completed) {
      filterActivities = [
        ...filterActivities,
        ...allPayment.filter((item: any) => {
          const paymentStatus = paymentUtil.getPaymentStatus({
            paymentStatusId: item.paymentStatusId
          });
          return paymentStatus === PAYMENT_GROUP_STATUS_NAME.Completed;
        })
      ];
    }
    if (
      !toggleStatus.upcomming &&
      !toggleStatus.inprogress &&
      !toggleStatus.completed
    ) {
      filterActivities = allPayment;
    }
    filterActivities = filterActivities.sort((a: any, b: any) => {
      return (
        new Date(a.payoutDate).getTime() - new Date(b.payoutDate).getTime()
      );
    });
    setActivities(filterActivities);
  };
  const handleOpenScheduleDetail = () => {
    openScheduleDetail();
  };
  const renderStatusTab = () => {
    return (
      <div className={styles.wrapperBtnTerms}>
        <Checkbox
          value={toggleStatus.upcomming}
          checked={toggleStatus.upcomming}
          className={classNames(styles.btnTerm, {
            [styles.activeTerm]: toggleStatus.upcomming
          })}
          onChange={handleChangeFilterStatus("upcomming")}
        >
          Scheduled
        </Checkbox>
        <Checkbox
          value={toggleStatus.inprogress}
          checked={toggleStatus.inprogress}
          className={classNames(styles.btnTerm, {
            [styles.activeTerm]: toggleStatus.inprogress
          })}
          onChange={handleChangeFilterStatus("inprogress")}
        >
          In Progress
        </Checkbox>
        <Checkbox
          value={toggleStatus.completed}
          checked={toggleStatus.completed}
          className={classNames(styles.btnTerm, {
            [styles.activeTerm]: toggleStatus.completed
          })}
          onChange={handleChangeFilterStatus("completed")}
        >
          Completed
        </Checkbox>
      </div>
    );
  };
  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.wrapperMobile]: isMobile
      })}
    >
      <div
        className={classNames(styles.paymentActivity, {
          [styles.lineBreak]: openScheduleDetail
        })}
      >
        <div className={styles.actionLeft}>
          <p className={styles.title}>Scheduled payments</p>
          {_get(paymentDetail, "isActive", false) && openActionDrawer && (
            <EllipsisOutlined
              className={styles.iconMoreAction}
              onClick={handleToggleMoreAction}
            />
          )}
          {openScheduleDetail && (
            <Image
              className={styles.iconViewSchedule}
              src={IconReviewGreen}
              onClick={handleOpenScheduleDetail}
            />
          )}
        </div>
        <div className={styles.actionRight}>
          <button className={styles.statusBtn}>
            {_get(paymentDetail, "isActive", false) ? "Active" : "Inactive"}
          </button>
          <button className={styles.frequencyBtn}>
            {_get(paymentDetail, "frequency", "Monthly")}
          </button>
        </div>
      </div>
      {renderStatusTab()}
      <StepsActivity
        currentPaymentId={currentPaymentId}
        isSchedule={isSchedule}
        onScroll={onScroll}
        activities={activities.map((act: any, idx: number) => {
          return {
            payoutDate: _get(act, "payoutDate"),
            paymentStatusId: _get(act, "paymentStatusId"),
            id: _get(act, "id"),
            cardId: _get(act, "cards[0].id"),
            paymentTotal: _get(act, "paymentTotal"),
            paymentAmount: _get(act, "paymentAmount"),
            currencyId
          } as Activity;
        })}
      />
    </div>
  );
};

export default SchedulePayments;
