import React from "react";
import { isDesktop } from "react-device-detect";
import Form from "src/bepaid/components/Form";
import classnames from "classnames";

import Typography from "antd/es/typography";

import { history } from "src/ipm-shared/store";
import { CHANGE_PASSWORD_FORM } from "src/ipm-shared/store/model/Auth/const";

import ROUTES from "src/bepaid/routes";
import InputText from "src/bepaid/components/Form/controls/InputText";
import Button from "src/bepaid/components/Form/controls/Button";
import { invokeMessage } from "src/bepaid/components/Message";

import styles from "./ChangePassword.module.scss";

const FormComponent = ({ changePassword, onChanged }: any) => {
  const [loading, setLoading] = React.useState(false);

  const onSubmit = () => {
    changePassword((err: any) => {
      if (!err) {
        invokeMessage("success", "Password changed! Please sign in again.");
        history.push(ROUTES.LOGIN);

        if (onChanged) {
          onChanged();
        }
      }
    }, setLoading);
  };

  const NewPasswordNotice = () => {
    return (
      <Typography.Text className={styles.newPasswordNoticeText}>
        At least 8 characters consisting of letters, numbers and symbols
      </Typography.Text>
    );
  };

  return (
    <Form
      name="change-password-form"
      layout="vertical"
      requiredMark={false}
      className={styles.form}
    >
      <div className={styles.formBody}>
        <InputText
          type="password"
          name="old_password"
          placeholder="Current password"
          form={CHANGE_PASSWORD_FORM}
          label="Current password"
        />
        <div className={styles.newPasswordSection}>
          <InputText
            type="password"
            name="new_password"
            placeholder="New password"
            form={CHANGE_PASSWORD_FORM}
            label="New password"
          />
          <NewPasswordNotice />
        </div>
        <InputText
          type="password"
          name="new_password_confirm"
          placeholder="Confirm password"
          form={CHANGE_PASSWORD_FORM}
          label="Confirm password"
        />
      </div>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
          onClick={onSubmit}
        >
          {loading ? "Changing password..." : "Change password"}
        </Button>
      </Form.Item>
    </Form>
  );
};

const ChangePassword: React.FC<any> = props => {
  const { changePassword, onChanged } = props;

  return (
    <div
      className={classnames(styles.wrapper, {
        [styles.wrapperDesktop]: isDesktop
      })}
    >
      <FormComponent changePassword={changePassword} onChanged={onChanged} />
    </div>
  );
};

export default ChangePassword;
