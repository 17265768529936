import React from "react";
import * as uuidv1 from "uuid/v1";
import classNames from "classnames";
import message from "antd/es/message";

import styles from "./Message.module.scss";

type NoticeType = "info" | "success" | "error" | "warning" | "loading";

export const invokeMessage = (type: NoticeType, content: any) => {
  message.config({
    top: 0
  });

  message.open({
    className: classNames(styles.wrapper, styles[type]),
    content,
    duration: 3,
    key: uuidv1(),
    type
  });
};

const Component = (props: any) => {
  const { type = "success", content } = props;

  React.useEffect(() => {
    invokeMessage(type, content);
  }, []);

  return null;
};

export default Component;
