import React, { useState } from "react";
import _get from "lodash-es/get";
import _find from "lodash-es/find";
import _flattenDeep from "lodash-es/flattenDeep";
import _toString from "lodash-es/toString";
import TreeSelect from "antd/es/tree-select";
import Form from "src/bepaid/components/Form";
import T from "src/ipm-shared/Utils/Intl";

import SharedSelectMultiLevel from "src/ipm-shared/components/Form/controls/SelectMultiLevel";

const Component = (props: any) => {
  const { name, options = [], label, defaultValue } = props;
  const [expandedKeys, setExpandedKeys] = useState<string[] | undefined>([]);

  const expandNode = (key: string) => {
    setExpandedKeys((prev: string[]) => {
      if (!prev) {
        return;
      }
      if (prev.includes(key)) {
        return prev.filter((item: string) => item !== key);
      } else {
        return [...prev, key];
      }
    });
  };

  const getTreeData = () => {
    return options.map((opt: any) => ({
      children: opt.children.map((c: any) => ({
        title: c.label,
        value: c.value
      })),
      selectable: !opt.children.length,
      title: (
        <span onClick={expandNode.bind(null, opt.label)}>{opt.label}</span>
      ),
      value: opt.children.length ? opt.label : opt.value
    }));
  };

  const getDefaultValue = () => {
    if (defaultValue) {
      const notHasChildren = options.filter((o: any) => !o.children.length);
      const flattenOptions = _flattenDeep(options.map((o: any) => o.children));
      const selectedItem = flattenOptions
        .concat(notHasChildren)
        .find((o: any) => {
          return _toString(_get(o, "value")) === _toString(defaultValue);
        });

      return _get(selectedItem, "label");
    }
    return null;
  };

  const onSearch = (value: string) => {
    value ? setExpandedKeys(undefined) : setExpandedKeys([]);
  };

  const renderComponent = (sharedProps: any) => {
    // INFO: props in custom component should be a higher priority
    const handleChange = (...params: any[]) => {
      if (sharedProps.onChange) {
        sharedProps.onChange(...params);
      }
      if (props.onChange) {
        props.onChange(...params);
      }
    };

    const commonProps = {
      ...sharedProps,
      ...props,
      onChange: handleChange
    };

    const { control = {} } = sharedProps;

    const { errors = [] } = control;
    const errorMessage = _get(errors, "0.code", "");
    const hasError = errors.length > 0;

    return (
      <Form.Item
        label={label}
        {...(hasError
          ? {
              help: T.transl(errorMessage),
              validateStatus: "error"
            }
          : {})}
      >
        <TreeSelect
          {...commonProps}
          treeData={getTreeData()}
          defaultValue={getDefaultValue()}
          showSearch={true}
          allowClear={true}
          treeExpandedKeys={expandedKeys}
          onDropdownVisibleChange={setExpandedKeys.bind(null, [])}
          treeNodeFilterProp={"title"}
          onSearch={onSearch}
        />
      </Form.Item>
    );
  };

  return (
    <SharedSelectMultiLevel
      {...props}
      name={name}
      options={options}
      renderComponent={renderComponent}
    />
  );
};

export default Component;
