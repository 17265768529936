import currencies from "src/ipm-shared/store/metadata/currency";

export default {
  convertFromId(
    currencyId: number
  ): {
    $: string;
    code: string;
  } {
    const currency = currencies.find(c => c.id === currencyId);

    return currency
      ? {
          $: currency.prefix_symbol as string,
          code: currency.code as string
        }
      : {
          $: "S$",
          code: "SGD"
        };
  },

  convertFromCurrencyCode(
    currencyCode: string
  ): {
    $: string;
    id: number;
  } {
    if (currencyCode === "HK_USD") {
      return {
        $: "US$",
        id: 73
      };
    }

    const currency = currencies.find(c => c.code === currencyCode);

    return currency
      ? {
          $: currency.prefix_symbol as string,
          id: currency.id
        }
      : {
          $: "S$",
          id: 1
        };
  },

  getAuthorisedAmount(currencyId: number): number {
    const currency = this.convertFromId(currencyId);

    switch (currency.code) {
      case "SGD":
        return 1;
      case "MYR":
        return 2;
      case "HKD":
        return 4;
      case "AUD":
        return 1;
      default:
        return 1;
    }
  }
};
