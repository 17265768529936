import React from "react";
import { isMobile } from "react-device-detect";
import Image from "src/bepaid/components/Image";
import HalfGreenCircle from "src/bepaid/assets/images/dashboard/carousel/179__1.svg";
import HalfYelloCircle from "src/bepaid/assets/images/dashboard/carousel/179__2.svg";
import styles from "./ReferralProgram.module.scss";
import classNames from "classnames";

const ReferralProgram = () => {
  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.isMobile]: isMobile
      })}
    >
      <div className={styles.topLayer} />
      <div className={styles.topRight}>
        <Image src={HalfYelloCircle} />
      </div>
      <div className={styles.center}>
        <p className={styles.rate}>
          <span>A$</span>
          <span>200</span>
        </p>
        <p className={styles.descriptionWrapper}>
          <p className={styles.description}>Earn up to A$200</p>
          <p className={styles.description}>on every referral</p>
          <a
            className={styles.learnMore}
            target="_blank"
            href={
              "http://help.ipaymy.com/en/articles/4344978-how-does-the-referral-program-work"
            }
          >
            Learn more.
          </a>
        </p>
      </div>
      <div className={styles.bottomLeft}>
        <Image src={HalfGreenCircle} />
      </div>
    </div>
  );
};

export default ReferralProgram;
