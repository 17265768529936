import { connect } from "react-redux";

import { RootState } from "src/ipm-shared/store/model/reducers";

import View from "./View";
import * as selectors from "src/bepaid/store/model/Fetch/selectors";
import * as actions from "src/bepaid/store/model/Fetch/actions";

const mapStateToProps = (state: RootState, props: any) => ({
  isSingaporeAccount: selectors.isSingaporeAccount(state),
  getConfirmedMyInfo: selectors.getConfirmedMyInfo(state),
  getControl: (name: string, form: string) =>
    selectors.getControl(state, name, form)
});

const mapDispatchToProps = {
  fetchMyInfoSingPass: actions.fetchMyInfoSingPass,
  parseServerErrors: actions.parseServerErrors,
  fetchAccountProfiles: actions.fetchAccountProfiles
};

export type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(View);
