import React, { useEffect, useState } from "react";
import Form from "src/bepaid/components/Form";
import InputText from "src/bepaid/components/Form/controls/InputText";

import DateUtils from "src/ipm-shared/Utils/Date";
import classNames from "classnames";

import styles from "./InputCalendar.module.scss";

const InputCalendar = (props: any) => {
  const {
    layout = "vertical",
    label = " ",
    formClassName = "",
    wrapperClassName = "",
    FORM = "",
    onChange = null,
    setHasError = null,
    limitCurrentYear = false,
    isGreaterThanNextDate = false
  } = props;

  const [hasErrorCustom, setHasErrorCustom] = useState(false);
  const [helperText, setHelperText] = useState<any>(null);
  const [formControl, setFormControl] = useState({
    year: "",
    month: "",
    day: ""
  });

  const onChangeCustom = (e: any) => {
    const input = e.target;
    setFormControl(prevState => {
      return {
        ...prevState,
        [input.name]: input.value
      };
    });
    if (onChange) {
      onChange(e);
    }
  };

  const handleSetHasErrorCustom = (value: boolean) => {
    setHasErrorCustom(value);
    if (setHasError) {
      setHasError(value);
    }
  };

  const doValidate = () => {
    if (
      formControl.year &&
      (+formControl.year < 1900 ||
        (limitCurrentYear && +formControl.year > DateUtils.getCurrentYear()))
    ) {
      handleSetHasErrorCustom(true);
      setHelperText({ txt: "Please enter a valid year." });
      return;
    }

    if (
      formControl.month &&
      (+formControl.month < 1 || +formControl.month > 12)
    ) {
      handleSetHasErrorCustom(true);
      setHelperText({ txt: "Please enter a valid month." });
      return;
    }

    if (formControl.day && (+formControl.day < 1 || +formControl.day > 31)) {
      handleSetHasErrorCustom(true);
      setHelperText({ txt: "Please enter a valid day." });
      return;
    }

    if (formControl.year && formControl.month && formControl.day) {
      const isValidDate = DateUtils.isValidDate(
        +formControl.day,
        +formControl.month,
        +formControl.year
      );

      if (!isValidDate) {
        handleSetHasErrorCustom(true);
        setHelperText({ txt: "Please enter a valid date." });
        return;
      }

      if (
        isGreaterThanNextDate &&
        !DateUtils.isGreaterThanNextDate(
          new Date(
            `${formControl.year}-${formControl.month}-${formControl.day}`
          )
        )
      ) {
        handleSetHasErrorCustom(true);
        setHelperText({ txt: "Please enter a valid date." });
        return;
      }
    } else {
      handleSetHasErrorCustom(true);
      return;
    }

    // there are no error, open submit button
    handleSetHasErrorCustom(false);
  };

  useEffect(() => {
    handleSetHasErrorCustom(true);
    setHelperText(null);
    const timeout = setTimeout(() => {
      doValidate();
    }, 500);
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [formControl.year, formControl.month, formControl.day]);

  return (
    <div className={classNames(styles.wrapper, wrapperClassName)}>
      <Form
        layout={layout}
        requiredMark={false}
        className={classNames(styles.formWrapper, formClassName)}
      >
        <InputText
          name={"year"}
          placeholder={"YYYY"}
          form={FORM}
          label={label}
          maxLength={4}
          pattern={"__NUMBER__"}
          onChange={onChangeCustom}
        />
        <InputText
          name={"month"}
          placeholder={"MM"}
          form={FORM}
          label={" "}
          maxLength={2}
          pattern={"__NUMBER__"}
          onChange={onChangeCustom}
        />
        <InputText
          name={"day"}
          placeholder={"DD"}
          form={FORM}
          maxLength={2}
          pattern={"__NUMBER__"}
          label={" "}
          onChange={onChangeCustom}
        />
      </Form>
      {helperText && (
        <p className={styles.helperText}>
          {hasErrorCustom ? helperText.txt : ""}
        </p>
      )}
    </div>
  );
};

export default InputCalendar;
