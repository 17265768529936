import React from "react";
// @ts-ignore
import { createIntl, createIntlCache, FormattedMessage } from "react-intl";
import { getIntl as getIntlDynamic } from "src/ipm-shared/components/Intl/GlobalIntl";

import LanguageUtils from "src/ipm-shared/Utils/Language";

import en from "src/ipm-shared/store/metadata/translations/en";
import zhhk from "src/ipm-shared/store/metadata/translations/zh-hk";

const cache = createIntlCache();

const initIntl = () => {
  const intlDynamic = getIntlDynamic();

  if (intlDynamic) {
    return intlDynamic;
  }

  const locale = LanguageUtils.getLanguage();

  return createIntl(
    {
      defaultLocale: "en",
      // Locale of the application
      locale,
      // Locale of the fallback defaultMessage
      messages: {
        en,
        "zh-hk": zhhk
      }[locale],
      ...intlConfig
    },
    cache
  );
};

export const intlConfig: any = {
  defaultFormats: {
    money: {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
      style: "number"
    }
  },
  formats: {
    money: {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
      style: "number"
    }
  }
};

// const useFormattedMessageComponent = (args: any) => {
//   return <FormattedMessage {...args} />;
// };

const commonValues = {
  b: (text: any) => <b>{text}</b>,
  br: <br />
};

const useFormatMessageFunction = (...args: any[]) => {
  const intl = initIntl();
  return intl.formatMessage(...args);
};

const transl = (id: string, values: any = {}) => {
  return useFormatMessageFunction(
    { id },
    {
      ...commonValues,
      ...values
    }
  );
};

const translText = (id: string, values: any = {}) => {
  return useFormatMessageFunction(
    { id },
    {
      ...commonValues,
      ...values
    }
  );
};

export default {
  locale: LanguageUtils.getLanguage(),
  transl,
  translText
};
