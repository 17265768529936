import { getType } from "typesafe-actions";
import * as types from "./types";
import * as actions from "./actions";
import _isEmpty from "lodash-es/isEmpty";

export type AccountUserType = {
  userId: number;
  accountId: number;
  email: string;
  firstName: string;
  lastName: string;
  roleId: number;
};

export type AccountUsersState = {
  readonly isFetching: boolean;
  readonly listAccountUser?: AccountUserType[];
};

const defaultState: AccountUsersState = {
  isFetching: false,
  listAccountUser: []
};

export default (state: AccountUsersState, action: types.Action) => {
  if (_isEmpty(state)) {
    state = defaultState;
  }

  switch (action.type) {
    case getType(actions.fetchListAccountUser):
      return {
        ...state,
        isFetching: true
      };
    case getType(actions.setListAccountUser):
      return {
        ...state,
        isFetching: false,
        listAccountUser: action.payload.listAccountUser || []
      };
  }
  return state;
};
