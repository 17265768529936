import { connect } from "react-redux";

import { RootState } from "src/ipm-shared/store/model/reducers";

import View from "./View";
import * as actions from "src/bepaid/store/model/Fetch/actions";
import * as selectors from "src/bepaid/store/model/Fetch/selectors";
import * as cardSelectors from "../../../../ipm-shared/store/model/Card/selectors";

const mapStateToProps = (state: RootState, props: any) => ({
  accountCountryCode: selectors.getAccountCountryCode(state),
  countryId: selectors.getCurrentCountryId(state),
  extraInfo: selectors.getExtraInfo(state, props.modalId),
  isHongKongAccount: selectors.isHongKongAccount(state),
  isSingaporeAccount: selectors.isSingaporeAccount(state),
  isMalaysiaAccount: selectors.isMalaysiaAccount(state),
  residentialAddress: selectors.getResidentialAddress(state),
  cards: selectors.getCards(state),
  earliestDate: selectors.getEarliestDate(state),
  shouldDisplayLoadingCards: selectors.shouldDisplayLoadingCards(state),
  firstCardIdInWallet: cardSelectors.getCards(state)[0]?.id,
  fees: selectors.getFees(state),
  payees: selectors.getPayees(state, "invoice"),
  isLoadingPaymentDetails: selectors.shouldDisplayLoadingPayments(state),
  hasAtleastOnecard: cardSelectors.getAllCards(state).length > 0,
  paymentRequest: selectors.getCurrentPaymentRequest(state),
  hasCompany: selectors.hasCompany(state),
  isBusinessAccount: selectors.getIsBusinessAccount(state),
  getCompany: selectors.getCompany(state),
  selectedPayeesIds: state.payees.selectedPayeeIds,
  selectedPayees: selectors.getSelectedPayees(state),
  accountCountryId: selectors.getAccountCountryId(state),
  paymentCountryCode: selectors.getPaymentCountryCode(state),
  paymentMethodType: selectors.getPaymentMethodType(state),
  isCryptoPaymentMethod: selectors.isCryptoPaymentMethod(state),
  selectedPayment: selectors.getBepaidSelectedPaymentDetail(state),
  controls: selectors.getControls(state, "")
});

const mapDispatchToProps = {
  addCompany: actions.addCompanySubmit,
  addEmployeeSubmit: actions.addEmployeeSubmit,
  addLandlordSubmit: actions.addLandlordSubmit,
  addSupplierSubmit: actions.addSupplierSubmit,
  addSelfTransferSubmit: actions.addSelfTransferSubmit,
  getAddressByPostalCode: actions.getAddressByPostalCode,
  addPaymentRequestSubmit: actions.addPaymentRequestSubmit,
  setControl: actions.setControl,
  validatePaymentPayeeSubmit: actions.validatePaymentPayeeSubmit,
  applyCoupon: actions.applyCoupon,
  fetchCards: actions.fetchCards,
  fetchPayees: actions.fetchPayees,
  fetchPaymentRequest: actions.fetchPaymentRequest,
  fulfilPaymentSubmit: actions.fulfilPaymentSubmit,
  fetchAccountProfiles: actions.fetchAccountProfiles,
  selectCard: actions.selectCard,
  toggleModal: actions.toggleModal,
  fetchDateConditions: actions.fetchDateConditions,
  removeForm: actions.removeForm,
  deleteExtraPayees: actions.deleteExtraPayeesByPayeeId,
  resetExtraPayees: actions.resetExtraPayees
};

export type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(View);
