/**
 * Actions
 *
 * ActionCreators are like the "pub" of pubsub. It's just broadcasting an event (action).
 * So, don't couple it with a specific reducer. Let ANY reducer listen to
 * an action.
 *
 * `typesafe-actions` has a nice utility function so we don't need to define action types as
 * constants. Use it together with `getType(type)` in the reducers.
 */
import { createAction } from "typesafe-actions";
import { CurrencyType } from "./reducers";

export const fetchCurrencies = createAction("currencies/FETCH");

export const setCurrencies = createAction(
  "currencies/SET",
  resolve => (args: { isFetching: boolean; currencies: CurrencyType[] }) =>
    resolve(args)
);
