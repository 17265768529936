import * as React from "react";
import { IntlProvider } from "react-intl";

import GlobalIntl from "./GlobalIntl";
import { LanguageContext } from "src/ipm-shared/Contexts";
import LanguageUtils, { LANGUAGE_OPTIONS } from "src/ipm-shared/Utils/Language";
import { intlConfig } from "src/ipm-shared/Utils/Intl";

import en from "src/ipm-shared/store/metadata/translations/en";
import zhhk from "src/ipm-shared/store/metadata/translations/zh-hk";
import zlm from "src/ipm-shared/store/metadata/translations/zlm";

interface IProps extends React.Props<any> {}

interface IState {
  locale: string;
}

class IntlProviderComponent extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    LanguageUtils.setDefaultLanguage();
    const lang = LanguageUtils.getLanguage();
    if (lang) {
      this.state = {
        locale: lang
      };
    } else {
      this.state = {
        locale: "en"
      };
    }
  }

  public render() {
    return (
      <LanguageContext.Provider
        value={{
          changeLanguage: this.changeLanguage,
          currentLanguageValue:
            Object.values(LANGUAGE_OPTIONS).find(
              languageOption => languageOption.value === this.state.locale
            ) || LANGUAGE_OPTIONS.en,
          languageOptions: Object.values(LANGUAGE_OPTIONS),
          locale: this.state.locale
        }}
      >
        <IntlProvider
          locale={this.state.locale}
          messages={this.translations}
          {...intlConfig}
        >
          <GlobalIntl>{this.props.children}</GlobalIntl>
        </IntlProvider>
      </LanguageContext.Provider>
    );
  }

  private get translations() {
    const lang = this.state.locale;

    switch (lang) {
      case LANGUAGE_OPTIONS.zhhk.value:
        return zhhk;

      case LANGUAGE_OPTIONS.zlm.value:
        return zlm;

      case LANGUAGE_OPTIONS.en.value:
      default:
        return en;
    }
  }

  private changeLanguage = (lang: string) => {
    LanguageUtils.changeLanguage(lang);
    window.location.reload();
    // this.setState({ locale: lang }, () => {
    //   LanguageUtils.changeLanguage(this.state.locale);
    // });
  };
}

export default IntlProviderComponent;
