import * as React from "react";
import CkoCardForm from "./CkoCardForm";
import CardUtils from "src/ipm-shared/Utils/Card";
import BaseCardFormView, { IBaseProps } from "./lib/BaseCardForm";
import BraintreeCardForm from "./BraintreeCardForm";
// import StripeCardForm from "./StripeCardForm2";
import StripeCardForm1 from "./StripeCardForm1";
import CardElement1 from "./StripeCardForm1/CardElement";
import WorldPayCardForm from "./WorldPayCardForm";

export type IProps = IBaseProps & {
  stripeCardIntentCreator?: (
    args: any,
    cb: (clientSecret: string) => void
  ) => void; // for stripe
  renderSelectCardBrand?: any;
  renderCardNickname?: any;
  selectedCardBrand?: number; // custom placeholder
};

class CardForm extends React.Component<IProps> {
  private cardFormRef: BaseCardFormView<any, any> | null;

  /**
   * renderFormCard
   */
  public renderFormCard() {
    if (this.props.acquirerId === -1 || !this.props.acquirerId) {
      return null;
    }

    if (CardUtils.isCheckout(this.props.acquirerId)) {
      return (
        <div data-private={true}>
          <CkoCardForm {...this.props} ref={ref => (this.cardFormRef = ref)} />
        </div>
      );
    } else if (CardUtils.isStripe(this.props.acquirerId)) {
      // Setup Intent implementation. Backup solution.
      // return (
      //   <StripeCardForm
      //     {...this.props}
      //     ref={ref => (this.cardFormRef = ref ? ref.getCardFormRef() : null)}
      //   />
      // );

      return (
        // Note1: Cant injectStripe without being wrapped by Elements.
        // Note2: Elements has only single child.
        <div data-private={true}>
          <StripeCardForm1 acquirerId={this.props.acquirerId}>
            <CardElement1
              {...this.props}
              // @ts-ignore
              ref={(ref: any) => {
                if (ref) {
                  this.cardFormRef = ref.getWrappedInstance();
                }
              }}
            />
          </StripeCardForm1>
        </div>
      );
    } else if (CardUtils.isBraintree(this.props.acquirerId)) {
      return (
        <div data-private={true}>
          <BraintreeCardForm
            {...this.props}
            ref={ref => (this.cardFormRef = ref)}
          />
        </div>
      );
    } else if (CardUtils.isWorldpay(this.props.acquirerId)) {
      return (
        <div data-private={true}>
          <WorldPayCardForm
            {...this.props}
            ref={ref => (this.cardFormRef = ref)}
          />
        </div>
      );
    } /*else if (CardUtils.isAdyen(this.props.acquirerId)) {
      return null;
    }*/

    return null;
  }

  public render() {
    return (
      <>
        {this.props.renderSelectCardBrand && this.props.renderSelectCardBrand()}
        {this.renderFormCard()}
        {this.props.renderCardNickname && this.props.renderCardNickname()}
      </>
    );
  }
  public tokenize() {
    return this.cardFormRef
      ? this.cardFormRef.retokenize()
      : Promise.resolve(undefined);
  }

  public createTmpCard(paymentToken: string) {
    return this.cardFormRef
      ? this.cardFormRef.createTmpCard(paymentToken)
      : Promise.resolve(undefined);
  }
}

export default CardForm;
