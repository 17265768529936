import React from "react";
import classNames from "classnames";
import { BrowserView, isDesktop, MobileView } from "react-device-detect";
import EllipsisOutlined from "@ant-design/icons/EllipsisOutlined";

import { history } from "src/ipm-shared/store";
import { FetchModalID } from "src/ipm-shared/components/GlobalUI/actions";

import Skeleton from "src/bepaid/components/Skeleton";
import ActionDetailDrawer from "src/bepaid/components/ActionDetailDrawer";
import DesktopModalLayout from "src/bepaid/components/Modal/components/DesktopModalLayout";
import MainLayout from "src/bepaid/layouts/MainLayout";
import ROUTES from "src/bepaid/routes";

import CustomerDetail from "./CustomerDetail";

import commonStyles from "src/bepaid/pages/Customers/Customers.module.scss";
import styles from "./CustomerDetailModal.module.scss";

function CustomerDetailModal(props: any) {
  const [loading, setLoading] = React.useState(true);

  const [actionDrawer, openActionDrawer] = React.useState({
    value: null,
    visibleDrawer: false
  });

  const [editData, setEditData] = React.useState({
    id: null
  });

  React.useEffect(() => {
    props.getCustomerList(
      null,
      (err: any, data: any) => {
        if (!err) {
          const customer = data.find(
            (cus: any) => +cus.id === +props.extraInfo.data.id
          );
          setEditData({
            ...customer
          });
        }
      },
      setLoading
    );
  }, []);

  const handleEditCustomer = (data: any) => {
    history.push(ROUTES.CUSTOMER_EDIT.replace(":id", data.id));
  };

  const handleBackCustomer = () => {
    history.push(ROUTES.CUSTOMERS);
  };

  const handleToggleMoreAction = () => {
    openActionDrawer({
      value: editData.id,
      visibleDrawer: !actionDrawer.visibleDrawer
    });
  };

  const actions = [
    {
      key: "edit",
      label: "Edit customer details",
      func: (data: any) => {
        handleEditCustomer(data);
      }
    },
    {
      key: "delete",
      label: "Delete customer",
      func: (data: any) => {
        props.toggleModal(FetchModalID.DELETE_CUSTOMER_PROFILE, data);
      }
    }
  ];

  return (
    <>
      <BrowserView>
        <DesktopModalLayout
          title={"Customer details"}
          maxWidth450={true}
          onClose={handleBackCustomer}
        >
          <div
            className={classNames(styles.wrapper, commonStyles.wrapperDesktop)}
          >
            <Skeleton loading={loading}>
              <CustomerDetail data={editData} />
            </Skeleton>
            <EllipsisOutlined
              className={commonStyles.iconMoreAction}
              onClick={handleToggleMoreAction}
            />
          </div>
        </DesktopModalLayout>
      </BrowserView>
      <MobileView>
        <MainLayout
          header={{
            title: "Customer details",
            hideMenu: true,
            onClose: handleBackCustomer,
            rightIcons: (
              <EllipsisOutlined
                className={commonStyles.iconMoreAction}
                onClick={handleToggleMoreAction}
              />
            )
          }}
        >
          <div className={styles.wrapper}>
            <Skeleton loading={loading}>
              <CustomerDetail data={editData} />
            </Skeleton>
          </div>
        </MainLayout>
      </MobileView>

      <ActionDetailDrawer
        contentFullWidth={isDesktop}
        height={"auto"}
        visible={actionDrawer.visibleDrawer}
        data={editData}
        actions={actions}
        onClose={handleToggleMoreAction}
      />
    </>
  );
}

export default CustomerDetailModal;
