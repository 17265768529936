const keysLS = {
  // key-value pairs
  paymentRequestUrls: "payment_request_urls"
};

const setLSItem = (key: string, value: string) =>
  window.localStorage.setItem(key, value);

const getLSItem = (key: string) => window.localStorage.getItem(key);

const removeLSItem = (key: string) => window.localStorage.removeItem(key);

const clearLS = () => window.localStorage.clear();

const getLSKey = (index: number) => window.localStorage.key(index);

const updateLSItemObjectType = (key: string, value: object) => {
  try {
    const obj = JSON.parse(getLSItem(key) || ("{}" as string));
    const newObj = { ...obj, ...value };
    setLSItem(key, JSON.stringify(newObj));
  } catch (e) {
    console.log(e);
  }
};

export default {
  clearLS,
  getLSItem,
  getLSKey,
  keysLS,
  removeLSItem,
  setLSItem,
  updateLSItemObjectType
};
