import React, { useEffect, useState } from "react";
import classNames from "classnames";
import _get from "lodash-es/get";
import _isEmpty from "lodash-es/isEmpty";
import * as DateFns from "date-fns";
import Typography from "antd/es/typography";
import Flex from "antd-mobile/es/flex";

import IncentiveType from "src/ipm-shared/store/metadata/incentive_type";
import { INCENTIVES_DETAIL_FORM } from "src/ipm-shared/store/model/Customer/const";
import T from "src/ipm-shared/Utils/Intl";

import Switch from "src/bepaid/components/Form/controls/Switch";
import Line from "src/bepaid/components/Line";
import Collapse from "src/bepaid/components/Collapse";
import Image from "src/bepaid/components/Image";
import Counter from "src/bepaid/components/Counter";

import IconArrowDown from "src/bepaid/assets/images/common/arrow_down.svg";
import IconArrowUp from "src/bepaid/assets/images/common/arrow_up.svg";
import No1 from "src/bepaid/assets/images/invoices/No.1.svg";
import No2 from "src/bepaid/assets/images/invoices/No.2.svg";

import LatePayment from "./LatePayment";

import FormStyles from "src/bepaid/components/Form/CommonForm.module.scss";
import styles from "./AddIncentives.module.scss";
import { CREATE_INVOICE_FORM } from "src/ipm-shared/store/model/CollectedAccount/const";

interface Incentive {
  default_rate: number;
  id: string;
  name: string;
  open: boolean;
  rate: number;
}

const defaultIncentives = {
  default_rate: 0,
  open: false,
  rate: 0
};

const incentiveDefault = IncentiveType.map((item: any) => {
  return {
    ...item,
    open: false,
    rate: item.default_rate
  };
});

const View = (props: any) => {
  const getSelectedCustomer = _get(props.getSelectedCustomers, "[0]", null);

  const isMobilePrimaryCommunicationMethod =
    getSelectedCustomer.primaryCommunicationMethod &&
    _isEmpty(getSelectedCustomer.email);

  if (!getSelectedCustomer) {
    return null;
  }

  const invoiceSelected = props.getInvoiceSelected();

  const [incentives, setIncentives] = React.useState<Incentive[]>(
    incentiveDefault
  );

  const commonFormItemProps = {
    reserveValueOnUnmount: true,
    revertValueOnMount: true
  };

  const handleSwitch = (ids: string) => (isOn: boolean) => {
    const idsData = ids.split(",");

    setIncentives(
      incentives.map(item =>
        idsData.includes(item.id)
          ? ({
              ...item,
              open: isOn
            } as Incentive)
          : item
      )
    );
  };

  const handleChangeDiscountRate = (id: string) => (rate: number) => {
    const incentive = incentives.find((item: any) => item.id === id);
    setIncentives(
      incentives.map(item =>
        item.id === id
          ? ({
              ...incentive,
              rate: rate * 100
            } as Incentive)
          : item
      )
    );
  };

  const handleLatePaymentSetIncentive = (id: string, dataUpdate: Incentive) => {
    setIncentives(prevIncentives => {
      return prevIncentives.map(item =>
        item.id === id
          ? {
              ...item,
              ...dataUpdate
            }
          : item
      );
    });
  };

  const handleSwitchLatePayment = (isOpen: boolean) => {
    if (!isOpen) {
      handleSwitch("P1,P2,D1")(false);
    } else {
      handleSwitch("P1")(true);
    }
  };

  const I1 =
    incentives.find((item: any) => item.id === "I1") || defaultIncentives;
  const I2 =
    incentives.find((item: any) => item.id === "I2") || defaultIncentives;
  const P1 =
    incentives.find((item: any) => item.id === "P1") || defaultIncentives;
  const P2 =
    incentives.find((item: any) => item.id === "P2") || defaultIncentives;
  const D1 =
    incentives.find((item: any) => item.id === "D1") || defaultIncentives;

  const isOpenLatePayment =
    _get(P1, "open") || _get(P2, "open") || _get(D1, "open");

  const cusId = getSelectedCustomer.id;
  const [disableIncentive, setDisableIncentive] = useState({
    I1: false,
    I2: false,
    I3: false
  });

  useEffect(() => {
    let incentivesData = _get(
      props.getControl(
        `incentives_${getSelectedCustomer.id}`,
        INCENTIVES_DETAIL_FORM
      ),
      "value"
    );
    let newIncentives = incentivesData;
    if (!incentivesData) {
      incentivesData = _get(invoiceSelected, "payees[0].incentives", []);
      const idsSelect: any[] = [];
      incentivesData.forEach((item: any) => {
        idsSelect.push(item.incentive_type_id);
      });
      newIncentives = incentives.map(item =>
        idsSelect.includes(item.id)
          ? ({
              ...item,
              open: true,
              rate: incentivesData.find(
                (d: any) => d.incentive_type_id === item.id
              ).rate
            } as Incentive)
          : item
      );
    }

    if (newIncentives && newIncentives.length > 0) {
      const invoiceDate = props.getControl(
        `invoice_date_${cusId}`,
        CREATE_INVOICE_FORM
      );
      const invoiceDueDate = props.getControl(
        `due_date_${cusId}`,
        CREATE_INVOICE_FORM
      );
      const distanceDates = Math.abs(
        DateFns.differenceInDays(invoiceDate.value, invoiceDueDate.value)
      );
      if (distanceDates < 15) {
        newIncentives = newIncentives.map((item: any) =>
          item.id === "I1"
            ? ({
                ...item,
                open: false
              } as Incentive)
            : item
        );
      }
      if (distanceDates < 30) {
        newIncentives = newIncentives.map((item: any) =>
          item.id === "I2"
            ? ({
                ...item,
                open: false
              } as Incentive)
            : item
        );
      }
      setIncentives([...newIncentives]);
    }
  }, [invoiceSelected]);

  useEffect(() => {
    const invoiceDate = props.getControl(
      `invoice_date_${cusId}`,
      CREATE_INVOICE_FORM
    );
    const invoiceDueDate = props.getControl(
      `due_date_${cusId}`,
      CREATE_INVOICE_FORM
    );
    const distanceDates = Math.abs(
      DateFns.differenceInDays(invoiceDate.value, invoiceDueDate.value)
    );
    setDisableIncentive({
      ...disableIncentive,
      I1: distanceDates < 15,
      I2: distanceDates < 30,
      I3: isMobilePrimaryCommunicationMethod
    });
    if (!invoiceSelected) {
      let incentivesData = _get(
        props.getControl(
          `incentives_${getSelectedCustomer.id}`,
          INCENTIVES_DETAIL_FORM
        ),
        "value"
      );
      if (incentivesData && incentivesData.length > 0) {
        if (distanceDates < 15) {
          incentivesData = incentivesData.map((item: any) =>
            item.id === "I1"
              ? ({
                  ...item,
                  open: false
                } as Incentive)
              : item
          );
        }
        if (distanceDates < 30) {
          incentivesData = incentivesData.map((item: any) =>
            item.id === "I2"
              ? ({
                  ...item,
                  open: false
                } as Incentive)
              : item
          );
        }
        setIncentives([...incentivesData]);
      }
    }
  }, []);
  useEffect(() => {
    props.setControl({
      errors: [],
      form: INCENTIVES_DETAIL_FORM,
      name: `incentives_${getSelectedCustomer.id}`,
      value: incentives
    });
  }, [incentives]);

  if (!getSelectedCustomer) {
    return null;
  }

  return (
    <div className={classNames(FormStyles.content, styles.wrapper)}>
      <h4>What incentives would you like to offer to your customer?</h4>
      <Flex.Item>
        <Image src={No1} />
        <Typography.Text className={styles.textDetails}>
          Fetch first tries to get your invoice paid in full.
        </Typography.Text>
      </Flex.Item>
      <Flex.Item className={styles.lastItem}>
        <Image src={No2} />
        <Typography.Text className={styles.textDetails}>
          Fetch then offers incentives only as necessary.
        </Typography.Text>
      </Flex.Item>
      <Line />
      <Collapse
        isOpen={_get(I1, "open")}
        iconOpened={<Image src={IconArrowUp} />}
        iconClosed={<Image src={IconArrowDown} />}
        title={"Early payment discount"}
        rightIcon={
          <Switch
            {...commonFormItemProps}
            defaultValue={_get(I1, "open")}
            checked={_get(I1, "open")}
            disabled={disableIncentive.I1}
            onChange={handleSwitch("I1")}
          />
        }
      >
        <p className={styles.collapsedText}>
          {T.transl("INCENTIVES_EARLY_DESCRIPTION")}
        </p>
        <Counter
          // form={INVOICE_DETAIL_FORM}
          // name="earlyDiscountcounter"
          {...commonFormItemProps}
          defaultValue={_get(I1, "rate", 0) / 100}
          onChange={handleChangeDiscountRate("I1")}
        />
      </Collapse>
      <Line />

      <Collapse
        isOpen={_get(I2, "open")}
        iconOpened={<Image src={IconArrowUp} />}
        iconClosed={<Image src={IconArrowDown} />}
        title={"Immediate payment discount"}
        rightIcon={
          <Switch
            {...commonFormItemProps}
            disabled={disableIncentive.I2}
            defaultValue={_get(I2, "open")}
            checked={_get(I2, "open")}
            onChange={handleSwitch("I2")}
          />
        }
      >
        <p className={styles.collapsedText}>
          {T.transl("INCENTIVES_IMMEDIATE_DESCRIPTION")}
        </p>
        <Counter
          {...commonFormItemProps}
          defaultValue={_get(I2, "rate", 0) / 100}
          onChange={handleChangeDiscountRate("I2")}
        />
      </Collapse>
      <Line />

      <Collapse
        isOpen={isOpenLatePayment}
        iconOpened={<Image src={IconArrowUp} />}
        iconClosed={<Image src={IconArrowDown} />}
        title={"Late payment remediation"}
        type={"noPadding"}
        rightIcon={
          <Switch
            {...commonFormItemProps}
            disabled={disableIncentive.I3}
            defaultValue={isOpenLatePayment}
            checked={isOpenLatePayment}
            onChange={handleSwitchLatePayment}
          />
        }
      >
        <LatePayment
          incentives={incentives}
          onSetIncentive={handleLatePaymentSetIncentive}
        />
      </Collapse>
      <Line />
      {isMobilePrimaryCommunicationMethod && (
        <p className={styles.helperText}>
          Fetch incentives are available only for customers with an email
          address.
        </p>
      )}
    </div>
  );
};

export default View;
