import * as React from "react";
import styled from "styled-components";
import LoaderIcon from "src/ipm-shared/components/LoaderIcon";
import { IProps } from "./index";

const Overlay = styled.div`
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 1051;
  cursor: pointer;
`;

const CenteredLoader = styled(LoaderIcon)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const OverlayWithLoader: React.SFC<IProps> = props =>
  props.isLoading ? (
    <Overlay>
      <CenteredLoader type="ball-pulse-sync" active={true} color="#00A9BE" />

      <div
        style={{
          position: "absolute",
          marginTop: 50,
          fontWeight: "bold",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)"
        }}
      >
        {props.message}
      </div>
    </Overlay>
  ) : null;

export default OverlayWithLoader;
