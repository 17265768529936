import { RouteProps } from "react-router";
import { connect } from "react-redux";

import { RootState } from "src/ipm-shared/store/model/reducers";

import * as selectors from "src/bepaid/store/model/Fetch/selectors";
import * as actions from "src/bepaid/store/model/Fetch/actions";

import Component from "./View";

const mapStateToProps = (state: RootState) => ({
  hasToken: selectors.hasToken(state),
  list: selectors.getPayees(state, "invoice"),
  statistics: selectors.getCustomerStatistics(state),
  shouldDisplayLoadingPayees: selectors.shouldDisplayLoadingPayees(state),
  isFirstFetchData: selectors.getFirstFetchDataByKey(state)(
    "recipients_suppliers"
  ),
  hasWallexPayment: selectors.getHasWallexPayment(state),
  total: selectors.getTotalPayees(state)
});

const mapDispatchToProps = {
  fetchPayees: actions.fetchPayees,
  getCustomerList: actions.getCustomerList,
  getCustomerStatistics: actions.getCustomerStatistics,
  toggleModal: actions.toggleModal,
  selectPayees: actions.selectPayees
};

export type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteProps;

export default connect(mapStateToProps, mapDispatchToProps)(Component);
