/**
 * Selectors
 *
 * It's like "views" in relational databases.
 * Store the most efficient format in the reducer state.
 * But to retrieve into easy to consume form, use selector.
 */
import { RootState } from "src/ipm-shared/store/model/reducers";
import _get from "lodash-es/get";
import { PayeeType } from "../Payee/reducers";
import { isAfter } from "date-fns";
import { PAYMENT_STATUS } from "./const";
import ReducerFactory from "src/ipm-shared/Utils/ReduxReducer";
import paymentsReducer from "./reducers";
ReducerFactory.registerReducer({ payments: paymentsReducer });

export const getPaymentsWithoutSchedules = (state: RootState) =>
  state.payments.payments
    .filter(p => p.paymentStatusId !== PAYMENT_STATUS.IN_PROGRESS)
    .sort((a, b) => (a.id > b.id ? -1 : 1)) // Sort ID first if payments has the same payout date!!
    .sort((a, b) =>
      isAfter(new Date(a.payoutDate), new Date(b.payoutDate)) ? -1 : 1
    );

// Return payments sorted by payout date descending
export const getPayments = (state: RootState) =>
  state.payments.payments.sort((a, b) =>
    isAfter(new Date(a.payoutDate), new Date(b.payoutDate)) ? -1 : 1
  );

export const getAdminPayments = (state: RootState) => state.payments.payments;

export const getPaymentsTotal = (state: RootState) => state.payments.total;

export const getPaymentsTotalValues = (state: RootState) => ({
  sumPaymentAmount: state.payments.sumPaymentAmount,
  sumPaymentTotal: state.payments.sumPaymentTotal,
  totalBankPayout: state.payments.totalBankPayout,
  totalBatch: state.payments.totalBatch,
  totalStripeTransactions: state.payments.totalStripeTransactions
});

// Return in-progress payments sorted by charge date ascending
export const getInProgressPayments = (state: RootState) =>
  state.payments.payments
    .filter(
      p =>
        p.paymentStatusId === PAYMENT_STATUS.IN_PROGRESS_2 ||
        p.paymentStatusId === PAYMENT_STATUS.ON_HOLD ||
        p.paymentStatusId === PAYMENT_STATUS.INT_PENDING ||
        p.paymentStatusId === PAYMENT_STATUS.UNDER_REVIEW
    )
    .sort((a, b) => {
      if (a.order && b.order) {
        return a.order > b.order ? 1 : -1;
      }
      return 1;
    });
export const shouldDisplayLoadingPayments = (state: RootState) =>
  state.payments.isFetching;

export const getSelectedPaymentPayees = (state: RootState) => {
  const payments = getSelectedPaymentDetail(state);
  return _get(payments, "payees", []) as PayeeType[];
};

export const getSelectedPaymentDetail = (state: RootState) =>
  state.payments.payments.filter(
    p => +p.id === +state.payments.selectedPaymentId
  )[0];

export const getEditPaymentPayeeRequest = (state: RootState) => {
  return state.payments.refundRequests.filter(
    r =>
      r.actionType === "edit_payment_payee" &&
      r.paymentId === state.payments.selectedPaymentId
  );
};

export const hasSelectedPayment = (state: RootState) =>
  state.payments.selectedPaymentId > -1;

// Return scheduled payments sorted by charge date ascending
export const getUpcommingPayments = (state: RootState) =>
  state.payments.payments
    .filter(
      payment =>
        [
          PAYMENT_STATUS.IN_PROGRESS,
          PAYMENT_STATUS.CANCELLED_DUE_TO_DELETED_CARD,
          PAYMENT_STATUS.CANCELLED_DUE_TO_DELETED_SCHEDULE
        ].indexOf(payment.paymentStatusId) > -1
    )
    .sort((a, b) => {
      if (a.order && b.order) {
        return a.order > b.order ? 1 : -1;
      }
      return 1;
    });

// Return completed payments sorted by charge date ascending
export const getCompletedPayments = (state: RootState) =>
  state.payments.payments
    .filter(payment => payment.paymentStatusId === PAYMENT_STATUS.COMPLETED)
    .sort((a, b) => {
      if (a.order && b.order) {
        return a.order > b.order ? 1 : -1;
      }
      return 1;
    });

export const getSchedules = (state: RootState) => state.payments.schedules;

export const getRefundType = (state: RootState) =>
  state.form.controls.refund_type;

export const getRefundRequests = (state: RootState) => {
  return state.payments.refundRequests;
};

export const getPaymentActivity = (state: RootState) => state.payments.activity;

export const getSumPaymentInThisMonth = (state: RootState) =>
  state.payments.sumPaymentInThisMonth;

export const getScheduleDetail = (state: RootState) =>
  state.payments.schedules.filter(
    s => s.scheduleId === state.payments.selectedScheduleId
  )[0];

export const getSchedulePaymentSetting = (state: RootState) =>
  _get(
    state.payments.schedules.filter(
      s => s.scheduleId === state.payments.selectedScheduleId
    )[0],
    "paymentSetting",
    {}
  );

export const getUpcomingPayment = (state: RootState) =>
  _get(getScheduleDetail(state), "upcomingPayment.payments", []);

export const getSelectedPaymentId = (state: RootState) =>
  state.payments.selectedPaymentId;

export const getSelectedPaymentIds = (state: RootState) =>
  state.payments.selectedPaymentIds;

export const getFeesVerboseBySelectedPaymentId = (state: RootState) => {
  const paymentId = getSelectedPaymentId(state);

  return state.payments.feesVerbose[paymentId];
};

export const getUpdatedPaymentStatus = (state: RootState) =>
  state.payments.updatedPaymentStatus;

export const getLogChargeAttempt = (state: RootState) =>
  state.payments.logChargeAttempt;

export const getIpmMerchantCategories = (state: RootState) =>
  state.payments.ipmMerchantCategories;

export const getInsurerList = (state: RootState) => state.payments.insurerList;
