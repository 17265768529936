import { getType } from "typesafe-actions";
import * as types from "./types";
import * as actions from "./actions";

export type AuthState = {
  readonly token: string;
  readonly smsOtpToken: string;
  readonly emailVerificationRequired: boolean;
  readonly isDisplayIntPayReminderPopup: boolean;
};

const defaultState: AuthState = {
  emailVerificationRequired: false,
  isDisplayIntPayReminderPopup: false,
  smsOtpToken: "",
  token: ""
};

export default (state = defaultState, action: types.Action) => {
  switch (action.type) {
    case getType(actions.setToken):
      return {
        ...state,
        token: action.payload.token
      };
    case getType(actions.setSmsOtpToken):
      return {
        ...state,
        smsOtpToken: action.payload.smsOtpToken
      };
    case getType(actions.requestVerifyEmail):
      return {
        ...state,
        emailVerificationRequired: true
      };

    case getType(actions.resetEmailVerificationRequired):
      return {
        ...state,
        emailVerificationRequired: false
      };

    case getType(actions.setDisplayIntPayReminderPopup):
      return {
        ...state,
        isDisplayIntPayReminderPopup: true
      };
  }
  return state;
};
