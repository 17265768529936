import * as React from "react";
import { IBaseProps, IBaseState } from "./Base";
import _isEqual from "lodash-es/isEqual";
import store from "src/ipm-shared/store";
import * as selectors from "../../selectors";
type WithPropsCheckerState = {
  [prop: string]: any;
  key: number;
};

type IProps = IBaseProps & {};
const withPropsChecker = <P extends IProps, S extends IBaseState>(
  Component: React.ComponentType<P>,
  monitoredProps: string[] = ["defaultValue"]
) => {
  class WithPropsChecker extends React.Component<P, WithPropsCheckerState> {
    public static getDerivedStateFromProps(
      nextProps: P,
      preState: WithPropsCheckerState
    ) {
      // Comparing arrays, array buffers, booleans, date objects, error objects, maps,
      // numbers, Object objects, regexes, sets, strings, symbols, and typed arrays.
      // Object objects are compared by their own, not inherited, enumerable properties.
      // Functions and DOM nodes are compared by strict equality, i.e. ===.

      let hasChange = false;
      const newState = preState;

      for (const prop of monitoredProps) {
        if (!_isEqual(nextProps[prop], preState[prop])) {
          if (prop === "defaultValue") {
            const state = store.getState();
            const control = selectors.getControl(state, nextProps.name);
            if (
              (control.value || "").toString() ===
              (nextProps[prop] || "").toString()
            ) {
              // Do nothing
            } else {
              hasChange = true;
            }
          } else {
            hasChange = true;
          }
          newState[prop] = nextProps[prop];
        }
      }

      if (hasChange) {
        newState.key = preState.key + 1;
        return newState;
      }

      return null;
    }

    constructor(props: P) {
      super(props);

      const initState = {
        key: 0,
        prevKey: 1
      };
      for (const prop of monitoredProps) {
        initState[prop] = props[prop];
      }

      this.state = initState;
    }

    public render() {
      return (
        <Component
          {...this.props}
          key={this.state.key}
          keyChanged={this.state.key > 0}
        />
      );
    }
  }

  return WithPropsChecker;
};

export default withPropsChecker;
