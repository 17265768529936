/**
 * Actions
 *
 * ActionCreators are like the "pub" of pubsub. It's just broadcasting an event (action).
 * So, don't couple it with a specific reducer. Let ANY reducer listen to
 * an action.
 *
 * `typesafe-actions` has a nice utility function so we don't need to define action types as
 * constants. Use it together with `getType(type)` in the reducers.
 */
import { createAction } from "typesafe-actions";
import { createBrowserHistory } from "history";

import { LoadingFunc } from "src/ipm-shared/Utils/ReduxSagaEffects";

type cbType = (err?: any, data?: any) => void;

export const setToken = createAction(
  "auth/SET_TOKEN",
  resolve => (args: { token: string }) => resolve(args)
);

export const setSmsOtpToken = createAction(
  "auth/SET_SMS_OTP_TOKEN",
  resolve => (args: { smsOtpToken: string }) => resolve(args)
);

export const logout = createAction("auth/LOGOUT", resolve => (cb?: cbType) =>
  resolve({ cb })
);

export const addAccount = createAction("auth/ADD_ACCOUNT");

export const switchAccount = createAction(
  "auth/SWITCH_ACCOUNT",
  resolve => (email: string) => resolve({ email })
);

export const changePasswordSubmit = createAction(
  "auth/CHANGE_PASSWORD",
  resolve => (
    successCallback: (err?: any) => void,
    loadingFunc?: LoadingFunc
  ) => resolve({ successCallback, loadingFunc })
);

export const exchangeToken = createAction(
  "auth/EXCHANGE_TOKEN",
  resolve => (args: {
    countryCode?: string;
    companyId?: number;
    currencyCode?: string;
    paidCurrencyCode?: string;
    preventDefault?: boolean;
    redirectPath?: string;
    purpose?: string;
    cb?: cbType;
  }) => resolve(args)
);

export const register = createAction(
  "auth/REGISTER",
  resolve => (
    history: ReturnType<typeof createBrowserHistory>,
    recaptchaToken: string,
    accountType: "business" | "personal"
  ) => resolve({ history, recaptchaToken, accountType })
);

export const loginSubmit = createAction(
  "auth/SUBMIT",
  resolve => (
    redirectUrl: string,
    defaultAccount?: number,
    others?: any,
    loadingFunc?: LoadingFunc
  ) => resolve({ defaultAccount, redirectUrl, others, loadingFunc })
);

export const requestVerifyEmail = createAction("auth/REQUEST_VERIFY_EMAIL");

export const requestResendVerifyEmail = createAction(
  "login/REQUEST_RESEND_VERIFY_EMAIL",
  resolve => () => resolve()
);

export const resetEmailVerificationRequired = createAction(
  "login/RESET_VERIFICATION_REQUIRED",
  resolve => () => resolve()
);

export const verifyMobile = createAction(
  "login/VERIFY_MOBILE",
  resolve => (smsOtp: string, options?: any, loadingFunc?: LoadingFunc) =>
    resolve({ smsOtp, options, loadingFunc })
);

export const requestResendVerifyMobile = createAction(
  "login/REQUEST_RESEND_VERIFY_MOBILE",
  resolve => (cb: cbType, loadingFunc?: LoadingFunc) =>
    resolve({ cb, loadingFunc })
);

export const setDisplayIntPayReminderPopup = createAction(
  "login/SET_DISPLAY_INT_PAY_REMINDER_POPUP",
  resolve => () => resolve()
);

export const changeEmail = createAction(
  "auth/CHANGE_EMAIL",
  resolve => (cb?: any, loadingFunc?: LoadingFunc) =>
    resolve({ cb, loadingFunc })
);

export const resendOrCancelEmail = createAction(
  "auth/RESEND_OR_CANCEL_EMAIL",
  resolve => (type: "resend" | "cancel", cb?: any, loadingFunc?: LoadingFunc) =>
    resolve({
      cb,
      type,
      loadingFunc
    })
);

export const requestResetPasswordSubmit = createAction(
  "auth/RESET_PASSWORD",
  resolve => (ars?: {
    redirectUrl: string;
    cb?: (err?: any) => void;
    loadingFunc?: LoadingFunc;
  }) => resolve(ars)
);

export const setNewPasswordSubmit = createAction(
  "auth/SET_NEW_PASSWORD",
  resolve => (ars?: { cb: (err?: any) => void }) => resolve(ars)
);

export const redirectToSAML = createAction(
  "auth/REDIRECT_TO_SAML",
  resolve => (
    redirectUrl: string,
    defaultAccount?: number,
    others?: any,
    loadingFunc?: LoadingFunc
  ) => resolve({ defaultAccount, redirectUrl, others, loadingFunc })
);

export const loginWithSAMLSubmit = createAction(
  "auth/LOGIN_WITH_SAML_SUBMIT",
  resolve => (
    redirectUrl: string,
    defaultAccount?: number,
    others?: any,
    loadingFunc?: LoadingFunc
  ) => resolve({ defaultAccount, redirectUrl, others, loadingFunc })
);

export const unsubscribeEmail = createAction(
  "auth/UNSUBSCRIBE_EMAIL",
  resolve => (token: any, cb?: cbType) => resolve({ token, cb })
);
