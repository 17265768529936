import React, { useEffect, useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import _get from "lodash-es/get";

import { history } from "src/ipm-shared/store";
import { FetchModalID } from "src/ipm-shared/components/GlobalUI/actions";

import ROUTES from "src/bepaid/routes";
import MainLayout from "src/bepaid/layouts/MainLayout";
import DesktopModalLayout from "src/bepaid/components/Modal/components/DesktopModalLayout";
import ActionDetailDrawer from "src/bepaid/components/ActionDetailDrawer";
import Image from "src/bepaid/components/Image";
import { useMakePayeeUrlParams } from "src/bepaid/pages/Recipients/hooks";
import {
  getRecipientPurposeRoute,
  getRecipientTitle
} from "src/bepaid/utils/recipient";

import IconMenuDot from "src/bepaid/assets/images/common/icon_menu_dot.svg";

import RecipientDetail from "./RecipientDetail";

import styles from "./RecipientDetailModal.module.scss";

function RecipientDetailModal(props: any) {
  const { selectPayee, extraInfo, toggleModal } = props;
  const [loadingPayee, setLoadingPayee] = useState(false);
  const [openActions, setOpenActions] = useState(false);
  const recipientTitle = getRecipientTitle(props.selectedPayee);

  const actions = [
    {
      key: "edit",
      label: `Edit ${recipientTitle.toLocaleLowerCase()} details`,
      func: (data: any) => {
        handleEditCustomer(data);
      }
    },
    {
      key: "delete",
      label: `Delete ${recipientTitle.toLocaleLowerCase()}`,
      func: (payeeId: number) => {
        handleDeleteCustomer(payeeId);
      }
    }
  ];

  const { replaceRouteWithParams } = useMakePayeeUrlParams();

  const closeModal = () => {
    const url =
      getRecipientPurposeRoute(props.selectedPayee) + history.location.search;

    history.push(url, {
      prevRoute: ROUTES.RECIPIENTS_DETAILS
    });
  };

  const handleEditCustomer = (data: any) => {
    const url = replaceRouteWithParams(ROUTES.RECIPIENTS_DETAILS_UPDATE, {
      newEntity: _get(extraInfo.match, "params.entity"),
      newId: data
    });

    history.push(url);
  };

  const handleDeleteCustomer = (payeeId: number) => {
    toggleModal(FetchModalID.DELETE_RECIPIENT_MODAL, {
      payeeId
    });
  };

  const onMoreAction = () => {
    setOpenActions(!openActions);
  };

  useEffect(() => {
    selectPayee(extraInfo.payeeId, setLoadingPayee);
  }, [extraInfo.payeeId]);

  return (
    <>
      <BrowserView>
        <DesktopModalLayout
          title={`${recipientTitle} details`}
          maxWidth450={true}
          onClose={closeModal}
          onMoreAction={onMoreAction}
          isTax={recipientTitle === "Tax"}
        >
          <div className={styles.wrapper}>
            <RecipientDetail loading={loadingPayee} {...props.extraInfo} />
          </div>
        </DesktopModalLayout>
      </BrowserView>

      <MobileView>
        <MainLayout
          header={{
            title: `${recipientTitle} details`,
            hideMenu: true,
            onClose: closeModal,
            rightIcons: <Image src={IconMenuDot} onClick={onMoreAction} />
          }}
        >
          <div className={styles.wrapper}>
            <RecipientDetail loading={loadingPayee} {...props.extraInfo} />
          </div>
        </MainLayout>
      </MobileView>

      <ActionDetailDrawer
        contentFullWidth={true}
        type={"recipient"}
        height={"auto"}
        visible={openActions}
        data={extraInfo.payeeId}
        actions={actions}
        onClose={onMoreAction}
      />
    </>
  );
}

export default RecipientDetailModal;
