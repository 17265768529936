// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_wrapper__1LQBE{position:relative;width:100%;margin:auto !important;border:.5px solid #fff;padding:1.5rem 1rem !important;border-radius:.625rem;-webkit-box-shadow:0 1px 6px #00000029;box-shadow:0 1px 6px #00000029}.styles_wrapper__1LQBE .styles_icon__2P-4g{position:absolute;left:50%;bottom:0;-webkit-transform:translate(-50%, 50%);-ms-transform:translate(-50%, 50%);transform:translate(-50%, 50%);z-index:2}.styles_wrapperRight__Au-cl{padding:1rem 1rem !important}.styles_wrapperRight__Au-cl .styles_iconRight__cf8JH{position:absolute;right:8px;top:5px;z-index:2}.styles_wrapperRight__Au-cl .styles_iconRight__cf8JH span{font-size:.875rem;color:#000}", "",{"version":3,"sources":["webpack://./src/bepaid/components/ExpandBox/styles.module.scss"],"names":[],"mappings":"AAAA,uBAAS,iBAAiB,CAAC,UAAU,CAAC,sBAAsB,CAAC,sBAAsB,CAAC,8BAA8B,CAAC,qBAAqB,CAAC,sCAA6B,CAA7B,8BAA8B,CAAC,2CAAe,iBAAiB,CAAC,QAAQ,CAAC,QAAQ,CAAC,sCAA8B,CAA9B,kCAA8B,CAA9B,8BAA8B,CAAC,SAAS,CAAC,4BAAc,4BAA4B,CAAC,qDAAyB,iBAAiB,CAAC,SAAS,CAAC,OAAO,CAAC,SAAS,CAAC,0DAA8B,iBAAiB,CAAC,UAAU","sourcesContent":[".wrapper{position:relative;width:100%;margin:auto !important;border:.5px solid #fff;padding:1.5rem 1rem !important;border-radius:.625rem;box-shadow:0 1px 6px #00000029}.wrapper .icon{position:absolute;left:50%;bottom:0;transform:translate(-50%, 50%);z-index:2}.wrapperRight{padding:1rem 1rem !important}.wrapperRight .iconRight{position:absolute;right:8px;top:5px;z-index:2}.wrapperRight .iconRight span{font-size:.875rem;color:#000}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "styles_wrapper__1LQBE",
	"icon": "styles_icon__2P-4g",
	"wrapperRight": "styles_wrapperRight__Au-cl",
	"iconRight": "styles_iconRight__cf8JH"
};
export default ___CSS_LOADER_EXPORT___;
