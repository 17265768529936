import * as React from "react";
import { connect } from "react-redux";
import { RootState } from "src/ipm-shared/store/model/reducers";
import * as accountProfileSelectors from "src/ipm-shared/store/model/AccountProfile/selectors";
import * as countriesSelectors from "src/ipm-shared/store/model/Country/selectors";
import * as formSelectors from "src/ipm-shared/components/Form/selectors";
import { StripeProvider } from "react-stripe-elements";
import { Elements } from "react-stripe-elements";
import CardUtils from "src/ipm-shared/Utils/Card";

const selectors = {
  ...accountProfileSelectors,
  ...countriesSelectors,
  ...formSelectors
};

const mapStateToProps = (state: RootState) => ({
  defaultAcquirerID: selectors.getAcquirerId(state)
});

type IProps = {
  acquirerId: number;
} & ReturnType<typeof mapStateToProps>;

class StripeProviderEx extends React.Component<IProps, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      isLoaded: false
    };
  }

  public componentDidMount() {
    const stripelib = document.getElementById(
      "stripe_script"
    ) as HTMLScriptElement;

    if (!stripelib) {
      window.Logger.guestError("Can find stripe script");
      return;
    }

    if (stripelib.loaded) {
      this.setState({ isLoaded: true });
    } else {
      stripelib.onload = () => {
        this.setState({ isLoaded: true });
      };
    }
  }

  public render() {
    let acquirerId;
    if (!this.props.acquirerId) {
      return null;
    } else {
      acquirerId = this.props.acquirerId;
    }

    if (this.state.isLoaded && acquirerId > 0) {
      const stripeKey = CardUtils.getStripePublicKey(acquirerId);

      if (stripeKey !== "") {
        return (
          <StripeProvider apiKey={stripeKey}>
            <Elements>{this.props.children}</Elements>
          </StripeProvider>
        );
      }
    }
    return <div className={"text-center"}>Loading...</div>;
  }
}

export default connect(mapStateToProps)(StripeProviderEx);
