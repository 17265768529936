import React, { useEffect, useState } from "react";
import { BrowserView, isDesktop, MobileView } from "react-device-detect";
import DesktopModalLayout from "src/bepaid/components/Modal/components/DesktopModalLayout";
import MainLayout from "src/bepaid/layouts/MainLayout";

import Button from "src/bepaid/components/Form/controls/Button";

import ROUTES from "src/bepaid/routes";
import { history } from "src/ipm-shared/store";
import { useMakePaymentUrlParams } from "src/bepaid/pages/Payments/hooks";
import { purposeByEntity } from "src/bepaid/pages/Payments/const";

import classnames from "classnames";
import styles from "./SelectPaymentEntityModal.module.scss";

const View = (props: any) => {
  const { paymentType, replaceRouteWithParams } = useMakePaymentUrlParams(
    ROUTES.PAYMENTS_CREATION
  );
  const [loading, setLoading] = useState(false);
  const [selectedEntity, selectEntity] = useState<any>(null);

  const categoriesOrders = {
    personal: {
      landlord: 1,
      insurance: 3,
      supplier: 4,
      employee: 5,
      tax: 2,
      self_transfer: 6
    },
    business: {
      landlord: 3,
      insurance: 5,
      supplier: 1,
      employee: 2,
      tax: 4,
      self_transfer: 6
    }
  };

  const paymentCategories = [
    {
      name: "Rent",
      description: "Pay your landlords.",
      entity: "landlord"
    },
    {
      name: "Insurance",
      description: props.isBusinessAccount
        ? "Pay your business insurance premiums."
        : "Pay your personal insurance premiums.",
      entity: "insurance"
    },
    {
      name: "Invoices",
      description: props.isBusinessAccount
        ? "Pay your suppliers."
        : "Pay miscellaneous invoices, tuition, MCST and more.",
      entity: "supplier"
    },
    {
      name: "Salaries",
      description: "Pay your employees.",
      entity: "employee"
    },
    {
      name: "Tax",
      description: props.isBusinessAccount
        ? "Pay stamp duty, GST, income and property tax."
        : "Pay your stamp duty, income and property tax.",
      entity: "tax"
    },
    {
      name: "Money Transfer",
      description: "Transfer money abroad.",
      entity: "self_transfer"
    }
  ];

  if (paymentType === "international") {
    paymentCategories.push(
      paymentCategories.splice(
        paymentCategories.map(item => item.entity).indexOf("landlord"),
        1
      )[0]
    );
  } else {
    paymentCategories.unshift(
      paymentCategories.splice(
        paymentCategories.map(item => item.entity).indexOf("landlord"),
        1
      )[0]
    );
  }

  const getCategoryPosition = (category: typeof paymentCategories[0]) => {
    const accountType = props.isBusinessAccount ? "business" : "personal";
    return categoriesOrders[accountType][category.entity];
  };

  const onBack = () => {
    history.goBack();
  };

  const moveForward = () => {
    setLoading(true); // ok

    setTimeout(() => {
      const currentCompany = props.getCompany;
      if (props.isBusinessAccount && !currentCompany) {
        history.push(
          replaceRouteWithParams(ROUTES.PAYMENTS_CREATION, {
            newEntity: selectedEntity,
            newAction: "company"
          })
        );
        return;
      }
      if (selectedEntity === "tax") {
        history.push(
          replaceRouteWithParams(ROUTES.PAYMENTS_CREATION, {
            newEntity: selectedEntity,
            newAction: "payment_details"
          })
        );

        return;
      }

      if (selectedEntity === "employee") {
        history.push(
          replaceRouteWithParams(ROUTES.PAYMENTS_CREATION, {
            newEntity: selectedEntity,
            newAction: "type"
          })
        );

        return;
      }

      if (selectedEntity === "insurance") {
        history.push(
          replaceRouteWithParams(ROUTES.PAYMENTS_CREATION, {
            newEntity: selectedEntity,
            newAction: "insurance_details"
          })
        );

        return;
      }

      history.push(
        replaceRouteWithParams(ROUTES.PAYMENTS_CREATION, {
          newEntity: selectedEntity,
          newAction: "select"
        })
      );
    }, 500);
  };

  useEffect(() => {
    const purpose = purposeByEntity[selectedEntity];

    if (purpose) {
      props.resetSelectedPayees(purpose);
    }
  }, [selectedEntity]);

  // useEffect(() => {
  //   if (!props.isSingaporeAccount) {
  //     props.setPaymentMethodType(PAYMENT_METHOD_TYPES.CARD);
  //   }
  // }, []);

  useEffect(() => {
    if (!props.paymentCountryCode && paymentType === "international") {
      history.push(
        replaceRouteWithParams(ROUTES.PAYMENTS_CREATION, {
          newEntity: "country"
        })
      );
      return;
    }
    if (
      props.isSingaporeAccount &&
      !props.isBusinessAccount &&
      props.getConfirmedMyInfo === undefined
    ) {
      const redirectPath = replaceRouteWithParams(ROUTES.PAYMENTS_CREATION, {
        newEntity: "entity",
        newAction: "select"
      });
      props.fetchMyInfoSingPass(({ confirmed = false, info }: any) => {
        if (!confirmed) {
          history.push(
            `${ROUTES.MY_INFO}?redirect_url=${encodeURIComponent(redirectPath)}`
          );
        }
      }, props.isBusinessAccount);
    }

    if (
      props.isSingaporeAccount &&
      props.getConfirmedMyInfo === undefined &&
      props.getCurrentUserId == "61757"
    ) {
      const redirectPath = replaceRouteWithParams(ROUTES.PAYMENTS_CREATION, {
        newEntity: "entity",
        newAction: "select"
      });
      props.fetchMyInfoSingPass(({ confirmed = false, info }: any) => {
        if (!confirmed) {
          history.push(
            `${ROUTES.MY_INFO}?redirect_url=${encodeURIComponent(redirectPath)}`
          );
        }
      }, false);
    }
  }, []);

  const renderContent = () => {
    return (
      <>
        <div
          className={classnames(styles.wrapper, {
            [styles.desktop]: isDesktop
          })}
        >
          <ul className={styles.list}>
            {paymentCategories
              .sort((a, b) => getCategoryPosition(a) - getCategoryPosition(b))
              .filter(cate => {
                let condition = true;
                if (!props.isBusinessAccount) {
                  condition = condition && cate.entity !== "employee";
                }
                if (
                  !props.isSingaporeAccount &&
                  props.accountCountryCode !== "AU"
                ) {
                  condition = condition && cate.entity !== "tax";
                }
                if (!props.isSingaporeAccount) {
                  condition = condition && cate.entity !== "insurance";
                }
                if (paymentType === "domestic") {
                  condition = condition && cate.entity !== "self_transfer";
                }
                if (paymentType === "international") {
                  condition =
                    condition &&
                    ["self_transfer", "supplier", "landlord"].includes(
                      cate.entity
                    );
                }

                return condition;
              })
              .map(({ name, description, entity }, idx: number) => (
                <li
                  key={idx}
                  onClick={selectEntity.bind(null, entity)}
                  className={classnames({
                    [styles.selected]: entity === selectedEntity
                  })}
                >
                  <b>{name}</b>
                  <span>{description}</span>
                </li>
              ))}
          </ul>
        </div>
      </>
    );
  };

  const onBackProp =
    // paymentType === "international" || !props.isMalaysiaAccount
    // ?
    {
      onBack: onBack
    };
  // : {}

  const renderBtnSubmit = () => {
    return (
      <Button
        id={"ipaymy_btn_select_entity"}
        wrapperClassName={styles.wrapperButton}
        className={styles.button}
        onClick={moveForward}
        type="primary"
        htmlType="button"
        loading={!selectedEntity || loading}
      >
        {loading ? "Moving forward..." : "Move forward"}
      </Button>
    );
  };

  const renderDesktopView = () => {
    return (
      <DesktopModalLayout
        id={"ipaymy_select_entity_international_payment"}
        title={"Select payment category"}
        onClose={props.onClose}
        {...onBackProp}
        maxWidth450={true}
        onSubmit={moveForward}
        btnSubmit={renderBtnSubmit()}
      >
        {renderContent()}
      </DesktopModalLayout>
    );
  };

  const renderMobileView = () => {
    return (
      <MainLayout
        id={"ipaymy_select_entity_international_payment"}
        header={{
          title: "Select payment category",
          hideMenu: true,
          onClose: props.onClose,
          ...onBackProp
        }}
        footer={renderBtnSubmit()}
      >
        {renderContent()}
      </MainLayout>
    );
  };

  return (
    <>
      <BrowserView>{renderDesktopView()}</BrowserView>
      <MobileView>{renderMobileView()}</MobileView>
    </>
  );
};

export default View;
