import React, { useEffect, useState } from "react";
import OverviewBox from "src/bepaid/components/OverviewBox";
import Skeleton from "src/bepaid/components/Skeleton";
import { WALLEX_KYC_STATUS } from "src/ipm-shared/store/model/OnBoarding/consts";

const View = (props: any) => {
  const [loading, setLoading] = useState(false);

  const {
    numberLandlords = 0,
    numberEmployees = 0,
    numberSuppliers = 0,
    numberBankAccounts = 0
  } = props.statistics || {};

  const overviewData = [
    {
      key: 1,
      label: "Total number of landlords",
      value: numberLandlords
    },
    {
      key: 2,
      label: "Total number of employees",
      value: numberEmployees
    },
    {
      key: 3,
      label: "Total number of suppliers",
      value: numberSuppliers
    }
  ];

  if (
    props.isSingaporeAccount &&
    props.wallexKycStatus === WALLEX_KYC_STATUS.APPROVED
  ) {
    overviewData.push({
      key: 4,
      label: "Total number of bank accounts",
      value: numberBankAccounts
    });
  }

  const isEmptyData = false;

  if (props.isPersonalAccount) {
    overviewData.splice(1, 1);
  }

  useEffect(() => {
    props.getDashboardStatistics(setLoading);
  }, []);

  return (
    <Skeleton loading={loading}>
      <OverviewBox
        isEmptyData={isEmptyData}
        emptyTitle={"Send your first payment"}
        emptyDescription={"Statistics on your recipients will appear here."}
        data={overviewData}
      />
    </Skeleton>
  );
};

export default View;
