import React from "react";
import Typography from "antd/es/typography";
import styles from "./PaymentMethodItem.module.scss";
import ImageUtils from "src/ipm-shared/Utils/Images";
import { ALL_BRAND, ALL_CRYPTO, BRAND } from "src/ipm-shared/Utils/Images";
import classNames from "classnames";
import { isMobile } from "react-device-detect";
import { PAYMENT_METHOD_TYPES } from "src/ipm-shared/store/model/PaymentRequest/const";
import * as Images from "src/ipm-shared/components/Images";
import Image from "src/bepaid/components/Image";

const PaymentMethodItem = (props: any) => {
  const {
    paymentMethodType,
    isPersonalAccount,
    isSingaporeAccount,
    isAustraliaAccount,
    isMalaysiaAccount,
    canAccessCryptoPayment
  } = props;
  const isCryptoPaymentMethod =
    paymentMethodType.payment_method_type === PAYMENT_METHOD_TYPES.CRYPTO;
  const icons = {
    card: ALL_BRAND.split(",")
      .filter(brandId =>
        !(isPersonalAccount && isSingaporeAccount) &&
        parseInt(brandId, 10) === BRAND.AMEX
          ? false
          : true
      )
      .filter(brandId =>
        (isMalaysiaAccount || isAustraliaAccount) &&
        parseInt(brandId, 10) === BRAND.UNION_PAY
          ? false
          : true
      )
      .map(brandId => ImageUtils.getCardTypeIcon(brandId)),

    crypto: ALL_CRYPTO.split(",").map(cryptoId =>
      ImageUtils.getCryptoTypeIcon(cryptoId)
    )
  }[paymentMethodType.payment_method_type];

  return (
    <div
      className={classNames(styles.container, {
        [styles.isMobile]: isMobile
      })}
    >
      <div
        className={classNames(styles.itemContent, {
          [styles.disabled]: isCryptoPaymentMethod && !canAccessCryptoPayment
        })}
      >
        <div>
          <Typography.Text className={classNames(styles.methodName)}>
            {paymentMethodType.name}
          </Typography.Text>
          <Typography.Text className={classNames(styles.methodDescription)}>
            {paymentMethodType.description}
          </Typography.Text>
        </div>
        <div className={styles.iconsWrapper}>
          <div className={styles.icons}>
            {icons.map((card: any, idx: number) => (
              <img
                key={idx}
                className={classNames({
                  [styles.cardIcon]: !isCryptoPaymentMethod,
                  [styles.cryptoIcon]: isCryptoPaymentMethod
                })}
                alt={"icon"}
                src={card}
              />
            ))}
          </div>
          {isCryptoPaymentMethod && !isSingaporeAccount && (
            <>
              <span className={styles.extraText}>or pay with</span>
              <Image
                className={styles.binanceIcon}
                alt={"icon"}
                src={Images.binance_icon}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentMethodItem;
