import { ActionType } from "typesafe-actions";
import { put } from "redux-saga/effects";
import * as actions from "./actions";
import { catchTakeLatest } from "src/ipm-shared/Utils/ReduxSagaEffects";
const watchedSagas = [
  catchTakeLatest(actions.fetchNotifications, handleFetchNotifications)
];
export default watchedSagas;
export function* handleFetchNotifications(
  action: ActionType<typeof actions.fetchNotifications>
) {
  yield put(actions.setNotifications({ isFetching: true, notifications: [] }));

  yield put(
    actions.setNotifications({
      isFetching: false,
      notifications: [{ id: 1 }]
    })
  );
}
