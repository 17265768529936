import View from "./View";

import { connect } from "react-redux";

import { RootState } from "src/ipm-shared/store/model/reducers";

import * as selectors from "src/bepaid/store/model/Fetch/selectors";

const mapStateToProps = (state: RootState) => ({
  isPersonalAccount: selectors.getIsPersonalAccount(state),
  accountCountryCode: selectors.getAccountCountryCode(state)
});

export default connect(mapStateToProps, null)(View);
