import { connect } from "react-redux";

import { RootState } from "src/ipm-shared/store/model/reducers";

import View from "./View";
import * as selectors from "src/bepaid/store/model/Fetch/selectors";
import * as actions from "src/bepaid/store/model/Fetch/actions";

const mapStateToProps = (state: RootState, props: any) => ({
  hasCompany: selectors.hasCompany(state),
  isSingaporeAccount: selectors.isSingaporeAccount(state),
  isBusinessAccount: selectors.getIsBusinessAccount(state),
  selectedPurpose: selectors.getSelectedPurpose(state),
  getCompany: selectors.getCompany(state),
  getAllCountries: selectors.getAllCountries(state),
  wallexKycStatus: selectors.getWallexKycStatus(state),
  getConfirmedMyInfo: selectors.getConfirmedMyInfo(state)
});

const mapDispatchToProps = {
  resetSelectedPayees: actions.resetSelectedPayees,
  toggleModal: actions.toggleModal,
  setSelectedCountry: actions.setSelectedCountry,
  setCurrentCountry: actions.setCurrentCountry,
  setCurrentCurrency: actions.setCurrentCurrency,
  exchangeToken: actions.exchangeToken,
  setPaymentCountryCode: actions.setPaymentCountryCode,
  setPaymentCurrencyCode: actions.setPaymentCurrencyCode,
  fetchMyInfoSingPass: actions.fetchMyInfoSingPass,
  setPaymentMethodType: actions.setPaymentMethodType
};

export type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(View);
