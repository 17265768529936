import { RouteProps } from "react-router";
import { connect } from "react-redux";

import { RootState } from "src/ipm-shared/store/model/reducers";

import * as selectors from "src/bepaid/store/model/Fetch/selectors";
import * as actions from "src/bepaid/store/model/Fetch/actions";

import Component from "./View";

const mapStateToProps = (state: RootState) => ({
  collectedAccount: selectors.getCollectedAccount(state),
  currencyId: selectors.getCurrentCurrencyId(state),
  currencySymbol: selectors.getCurrentCurrencySymbol(state),
  getCustomerByIds: selectors.getCustomerByIds(state),
  hasToken: selectors.hasToken(state),
  list: selectors.getInvoiceList(state),
  otherSettings: selectors.getCollectionOtherSettings(state),
  statistics: selectors.getInvoiceStatistics(state),
  taxes: selectors.getTaxes(state),
  isFirstFetchData: selectors.getFirstFetchDataByKey(state)("invoices"),
  total: selectors.getTotalInvoices(state)
});

const mapDispatchToProps = {
  fetchMainCollectedAccount: actions.fetchMainCollectedAccount,
  getInvoiceList: actions.getInvoiceList,
  getInvoiceStatistics: actions.getInvoiceStatistics,
  selectAllItemIds: actions.selectAllItemIds,
  selectCustomers: actions.selectCustomers,
  selectInvoice: actions.selectInvoice,
  setControl: actions.setControl,
  toggleModal: actions.toggleModal,
  openModal: actions.openModal,
  updateDraftInvoiceSetting: actions.updateDraftInvoiceSetting
};

export type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteProps;

export default connect(mapStateToProps, mapDispatchToProps)(Component);
