import React, { useEffect, useState } from "react";
import classNames from "classnames";
import _get from "lodash-es/get";
import _isEmpty from "lodash-es/isEmpty";

import {
  BRAND_ID,
  CREATE_INVOICE_FORM,
  CRYPTO_OPTION
} from "src/ipm-shared/store/model/CollectedAccount/const";
import ShortCurrency from "src/ipm-shared/components/ShortCurrency";
import DateUtils from "src/ipm-shared/Utils/Date";
import FormErrors from "src/ipm-shared/components/Form/helpers/Errors";
import { getMinMaxFeeRate } from "src/ipm-shared/components/CollectionSplitFee";
import FileUtils from "src/ipm-shared/Utils/Files";

import InputFile from "src/bepaid/components/Form/controls/InputFile";
import Collapse from "src/bepaid/components/Collapse";
import Image from "src/bepaid/components/Image";
import CollectionSplitFeesNew from "src/bepaid/components/CollectionSplitFees";

import IncentivesReview from "../IncentivesReview";
import InvoiceTemplate from "../InvoiceTemplate";

import ArrowDown from "src/bepaid/assets/images/common/arrow_down.svg";
import ArrowUp from "src/bepaid/assets/images/common/arrow_up.svg";

import FormStyles from "src/bepaid/components/Form/CommonForm.module.scss";

import styles from "./ReviewAndSend.module.scss";
import { INVOICE_DETAIL_FORM } from "src/ipm-shared/store/model/Customer/const";

const View = (props: any) => {
  const commonFormItemProps = {
    reserveValueOnUnmount: true,
    revertValueOnMount: true
  };
  const {
    invoiceOverView,
    normalText,
    boldText,
    table,
    boldHr
  } = styles as any;

  const { boxShadow } = FormStyles as any;

  const getSelectedCustomer = _get(props.getSelectedCustomers, "[0]", {});
  const collectedAccount = props.collectedAccount;
  const collectionRates = props.collectionRates;
  // const [rates, setRates] = useState(0);

  if (!collectedAccount || !collectionRates) {
    return null;
  }
  const handleChangeFees = (
    brandId: number,
    values: {
      requesterRate: {
        international: number;
        national: number;
        crypto_rate: number;
      };
    }
  ) => {
    const newFeeSettings = feeSettings.filter(r => r.brand_id !== brandId);
    newFeeSettings.push({
      brand_id: brandId,
      ...values.requesterRate
    });
    setFeeSettings(newFeeSettings);

    if (brandId === 2) {
      props.setControl({
        errors: [],
        form: CREATE_INVOICE_FORM,
        name: `requester_international_rate_visa_${getSelectedCustomer.id}`,
        value: values.requesterRate.international
      });

      props.setControl({
        errors: [],
        form: CREATE_INVOICE_FORM,
        name: `requester_national_rate_visa_${getSelectedCustomer.id}`,
        value: values.requesterRate.national
      });
    }

    if (brandId === 5) {
      props.setControl({
        errors: [],
        form: CREATE_INVOICE_FORM,
        name: `requester_international_rate_union_${getSelectedCustomer.id}`,
        value: values.requesterRate.international
      });

      props.setControl({
        errors: [],
        form: CREATE_INVOICE_FORM,
        name: `requester_national_rate_union_${getSelectedCustomer.id}`,
        value: values.requesterRate.national
      });
    }
    if (brandId === 4) {
      props.setControl({
        errors: [],
        form: CREATE_INVOICE_FORM,
        name: `requester_international_rate_amex_${getSelectedCustomer.id}`,
        value: values.requesterRate.international
      });

      props.setControl({
        errors: [],
        form: CREATE_INVOICE_FORM,
        name: `requester_national_rate_amex_${getSelectedCustomer.id}`,
        value: values.requesterRate.national
      });
    }
    if (brandId === CRYPTO_OPTION) {
      props.setControl({
        errors: [],
        form: CREATE_INVOICE_FORM,
        name: `requester_crypto_rate_${getSelectedCustomer.id}`,
        value: values.requesterRate.crypto_rate
      });
    }
  };

  if (!getSelectedCustomer || !getSelectedCustomer.id) {
    return null;
  }
  const itemsInvoice: any[] = [];
  if (props.invoiceLineItems) {
    Object.keys(props.invoiceLineItems).map(key => {
      if (props.invoiceLineItems[key].itemId > 0) {
        itemsInvoice.push(props.invoiceLineItems[key]);
      }
    });
  }
  const dataInvoice = {
    comments: _get(props.formState, `payment_description.value`),
    dueDate: getSelectedCustomer
      ? _get(props.formState, `due_date_${getSelectedCustomer.id}.value`)
      : "",
    invoiceDate: getSelectedCustomer
      ? _get(props.formState, `invoice_date_${getSelectedCustomer.id}.value`)
      : "",
    grossAmount: getSelectedCustomer
      ? _get(
          props.formState,
          `supplier_amount_${getSelectedCustomer.id}.value`,
          0
        ) * 100
      : 0,
    items: itemsInvoice,
    companyName: collectedAccount.companyName,
    registrationNumber: collectedAccount.registrationNumber,
    notes: getSelectedCustomer
      ? _get(props.formState, `invoice_note.value`)
      : "",
    termAndCondition: getSelectedCustomer
      ? _get(props.formState, `term_and_condition.value`)
      : "",
    invoiceNumber: getSelectedCustomer
      ? _get(
          props.formState,
          `payment_description_${getSelectedCustomer.id}.value`
        )
      : "",
    calculateMethod: _get(props.formState, `calculate_method.value`)
  };
  const requesterNationalRateVisa = getMinMaxFeeRate(
    collectedAccount.payeeData.requesterRates.filter(
      (r: any) => r.brand_id === 2
    )[0].fee_payer as number,
    collectedAccount.payeeData.requesterRates.filter(
      (r: any) => r.brand_id === 2
    )[0].national as number,
    collectionRates.filter((r: any) => r.brand_id === 2)[0].national
  );

  const requesterInternationalRateVisa = getMinMaxFeeRate(
    collectedAccount.payeeData.requesterRates.filter(
      (r: any) => r.brand_id === BRAND_ID.VISA
    )[0].int_fee_payer as number,
    collectedAccount.payeeData.requesterRates.filter(
      (r: any) => r.brand_id === BRAND_ID.VISA
    )[0].international as number,
    collectionRates.filter((r: any) => r.brand_id === BRAND_ID.VISA)[0]
      .international
  );

  const requesterNationalRateUnion = getMinMaxFeeRate(
    collectedAccount.payeeData.requesterRates.filter(
      (r: any) => r.brand_id === BRAND_ID.UNION
    )[0].fee_payer as number,
    collectedAccount.payeeData.requesterRates.filter(
      (r: any) => r.brand_id === BRAND_ID.UNION
    )[0].national as number,
    collectionRates.filter((r: any) => r.brand_id === BRAND_ID.UNION)[0]
      .national
  );

  const requesterInternationalRateUnion = getMinMaxFeeRate(
    collectedAccount.payeeData.requesterRates.filter(
      (r: any) => r.brand_id === BRAND_ID.UNION
    )[0].int_fee_payer as number,
    collectedAccount.payeeData.requesterRates.filter(
      (r: any) => r.brand_id === BRAND_ID.UNION
    )[0].international as number,
    collectionRates.filter((r: any) => r.brand_id === BRAND_ID.UNION)[0]
      .international
  );

  const requesterNationalRateAmex = getMinMaxFeeRate(
    collectedAccount.payeeData.requesterRates.filter(
      (r: any) => r.brand_id === BRAND_ID.AMEX
    )[0].fee_payer as number,
    collectedAccount.payeeData.requesterRates.filter(
      (r: any) => r.brand_id === BRAND_ID.AMEX
    )[0].national as number,
    collectionRates.filter((r: any) => r.brand_id === BRAND_ID.AMEX)[0].national
  );

  const requesterInternationalRateAmex = getMinMaxFeeRate(
    collectedAccount.payeeData.requesterRates.filter(
      (r: any) => r.brand_id === BRAND_ID.AMEX
    )[0].int_fee_payer as number,
    collectedAccount.payeeData.requesterRates.filter(
      (r: any) => r.brand_id === BRAND_ID.AMEX
    )[0].international as number,
    collectionRates.filter((r: any) => r.brand_id === BRAND_ID.AMEX)[0]
      .international
  );

  const requesterCryptoRate = getMinMaxFeeRate(
    collectedAccount.payeeData.requesterRates.filter(
      (r: any) => r.brand_id === CRYPTO_OPTION
    )[0].int_fee_payer as number,
    collectedAccount.payeeData.requesterRates.filter(
      (r: any) => r.brand_id === CRYPTO_OPTION
    )[0].crypto_rate as number,
    collectionRates.filter((r: any) => r.brand_id === CRYPTO_OPTION)[0]
      .crypto_rate
  );

  const invoicesSelected = props.invoiceSelected();
  useEffect(() => {
    if (invoicesSelected) {
      return;
    }
    props.setControl({
      errors: [],
      form: CREATE_INVOICE_FORM,
      name: `requester_international_rate_visa_${getSelectedCustomer.id}`,
      value: requesterInternationalRateVisa
    });

    props.setControl({
      errors: [],
      form: CREATE_INVOICE_FORM,
      name: `requester_national_rate_visa_${getSelectedCustomer.id}`,
      value: requesterNationalRateVisa
    });
    props.setControl({
      errors: [],
      form: CREATE_INVOICE_FORM,
      name: `requester_international_rate_union_${getSelectedCustomer.id}`,
      value: requesterInternationalRateUnion
    });

    props.setControl({
      errors: [],
      form: CREATE_INVOICE_FORM,
      name: `requester_national_rate_union_${getSelectedCustomer.id}`,
      value: requesterNationalRateUnion
    });
    props.setControl({
      errors: [],
      form: CREATE_INVOICE_FORM,
      name: `requester_international_rate_amex_${getSelectedCustomer.id}`,
      value: requesterInternationalRateAmex
    });

    props.setControl({
      errors: [],
      form: CREATE_INVOICE_FORM,
      name: `requester_national_rate_amex_${getSelectedCustomer.id}`,
      value: requesterNationalRateAmex
    });

    props.setControl({
      errors: [],
      form: CREATE_INVOICE_FORM,
      name: `requester_crypto_rate_${getSelectedCustomer.id}`,
      value: requesterCryptoRate
    });
  }, [invoicesSelected]);
  const [feeSettings, setFeeSettings] = useState([
    {
      brand_id: BRAND_ID.VISA,
      international: _get(
        props.getControl(
          `requester_international_rate_visa_${getSelectedCustomer.id}`,
          CREATE_INVOICE_FORM
        ),
        "value",
        requesterInternationalRateVisa
      ),
      national: _get(
        props.getControl(
          `requester_national_rate_visa_${getSelectedCustomer.id}`,
          CREATE_INVOICE_FORM
        ),
        "value",
        requesterNationalRateVisa
      )
    },
    {
      brand_id: BRAND_ID.UNION,
      international: _get(
        props.getControl(
          `requester_international_rate_union_${getSelectedCustomer.id}`,
          CREATE_INVOICE_FORM
        ),
        "value",
        requesterInternationalRateUnion
      ),
      national: _get(
        props.getControl(
          `requester_national_rate_union_${getSelectedCustomer.id}`,
          CREATE_INVOICE_FORM
        ),
        "value",
        requesterNationalRateUnion
      )
    },
    {
      brand_id: BRAND_ID.AMEX,
      international: _get(
        props.getControl(
          `requester_international_rate_amex_${getSelectedCustomer.id}`,
          CREATE_INVOICE_FORM
        ),
        "value",
        requesterInternationalRateAmex
      ),
      national: _get(
        props.getControl(
          `requester_national_rate_amex_${getSelectedCustomer.id}`,
          CREATE_INVOICE_FORM
        ),
        "value",
        requesterNationalRateAmex
      )
    },
    {
      brand_id: CRYPTO_OPTION,
      international: 0,
      national: 0,
      crypto_rate: _get(
        props.getControl(
          `requester_crypto_rate_${getSelectedCustomer.id}`,
          CREATE_INVOICE_FORM
        ),
        "value",
        requesterCryptoRate
      )
    }
  ]);

  const currentTypeInvoice = _get(
    props.getControl(`to_create_or_upload_invoice`, CREATE_INVOICE_FORM),
    "value",
    "upload"
  );

  // TODO: check condition here from redux data
  const dataIncentives = _get(props.getIncentives, "[0].value", []);
  const isShowIncentivesReview =
    dataIncentives.filter((item: any) => item.open === true).length > 0;
  const documentTags = props.getFilesControl;
  return (
    <div className={classNames(FormStyles.content, styles.wrapper)}>
      <FormErrors form={CREATE_INVOICE_FORM} />
      <FormErrors form={INVOICE_DETAIL_FORM} />

      {currentTypeInvoice === "upload" ? (
        <div className={FormStyles.personalFormDetail}>
          <div className={invoiceOverView}>
            <p className={normalText}>Customer</p>
            <p className={boldText}>{getSelectedCustomer.name}</p>
            <div className={table}>
              <p className={normalText}>Invoice number</p>
              <p className={normalText}>{dataInvoice.invoiceNumber}</p>
            </div>
            <hr className={boldHr} />
            <div className={table}>
              <p className={boldText}>Total Amount Due</p>
              <p className={boldText}>
                <ShortCurrency
                  value={dataInvoice.grossAmount}
                  currencyId={props.currencyId}
                />
              </p>
            </div>
            <hr className={boldHr} />
            <div className={table}>
              <p className={normalText}>Invoice Due Date</p>
              <p className={normalText}>
                {DateUtils.formatLocaleDate(dataInvoice.dueDate, {
                  day: "numeric",
                  month: "short",
                  year: "numeric"
                })}
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <InvoiceTemplate
            invoice={dataInvoice}
            currencyId={props.currencyId}
            currentTypeInvoice={currentTypeInvoice}
            // rate={rates}
            customer={getSelectedCustomer}
            taxes={props.taxes}
          />
        </div>
      )}

      {!_isEmpty(documentTags) && (
        <InputFile
          btnClassName={boxShadow}
          allowedFileTypes={["jpg", "png", "heic", "pdf"]}
          name={`supporting_documents_${getSelectedCustomer.id}`}
          purpose="invoice"
          form={"INVOICE_FORM"}
          maxFileAmount={1}
          defaultValue={FileUtils.getInfoFromKeys(
            _get(documentTags, `${getSelectedCustomer.id.toString()}`, [])
          )}
          helperText={{
            showInEmptyMode: true,
            txt: (
              <span className={"purple_text"}>
                Maximum file size: <b>16 MB</b>. Supported file types:{" "}
                <b>JPG, PDF, PNG</b>.
              </span>
            )
          }}
          {...commonFormItemProps}
        />
      )}

      <div className={styles.spacingBlock} hidden={!isShowIncentivesReview}>
        <Collapse
          iconOpened={<Image src={ArrowUp} />}
          iconClosed={<Image src={ArrowDown} />}
          type={"button"}
          title={"Incentives"}
        >
          <IncentivesReview
            data={dataIncentives}
            invoice={dataInvoice}
            currencyId={props.currencyId}
            // rate={rates}
            taxes={props.taxes}
          />
        </Collapse>
      </div>

      <div className={styles.spacingBlock}>
        <Collapse
          iconOpened={<Image src={ArrowUp} />}
          iconClosed={<Image src={ArrowDown} />}
          type={"button"}
          title={"Split the ipaymy platform fee with your customer"}
        >
          {/*<CollectionSplitFees*/}
          {/*  currencyId={props.currencyId}*/}
          {/*  defaultBrandId={BRAND_ID.VISA}*/}
          {/*  defaultRates={loadSplitRate()}*/}
          {/*  onChangeCustom={handleChangeFees}*/}
          {/*/>*/}

          <CollectionSplitFeesNew
            feeSettings={feeSettings}
            handleChangeFees={handleChangeFees}
          />
        </Collapse>
      </div>
    </div>
  );
};

export default View;
