/* tslint:disable:object-literal-sort-keys */

export const MODES = {
  SHOW_COMPANY_LIST: 1,
  ADD_NEW_COMPANY: 2,
  EDIT_EXISTING_COMPANY: 3
};

export const COUNTRY_CODES = {
  SINGAPORE: "SG",
  HONGKONG: "HK",
  MALAYSIA: "MY",
  AUSTRALIA: "AU"
};

export const COMPANY_PAGE_COUNT = 100;
