import _get from "lodash-es/get";
import _isEmpty from "lodash-es/isEmpty";
import _isIncludes from "lodash-es/includes";
import _groupBy from "lodash-es/groupBy";
import { ActionType } from "typesafe-actions";
import HttpRequestError from "src/ipm-shared/Utils/Exceptions/HttpRequestError";
import {
  reTryTakeLatest,
  catchTakeLatest
} from "src/ipm-shared/Utils/ReduxSagaEffects";
import { select, call, put } from "redux-saga/effects";
import { default as RestClient } from "src/ipm-shared/services/Rest";
import * as Images from "src/ipm-shared/components/Images";
import IPMContext from "src/ipm-shared/Utils/IPMContext";

import * as companyActions from "./actions";
import * as countryActions from "../Country/actions";
import * as accountActions from "../AccountProfile/actions";
import { RootState } from "../reducers";
import * as commonActions from "../actions";
import * as formSelectors from "src/ipm-shared/components/Form/selectors";
import * as formActions from "src/ipm-shared/components/Form/actions";
import * as authActions from "../Auth/actions";
import * as companySelectors from "./selectors";
import * as accountProfileSelectors from "src/ipm-shared/store/model/AccountProfile/selectors";
import * as guiSelectors from "src/ipm-shared/components/GlobalUI/selectors";
import { ADD_FORM, UPDATE_INCORPORATION_DATE_FORM } from "./const";
import { FORM } from "../Payee/const";
import DateUtils from "src/ipm-shared/Utils/Date";

const selectors = {
  ...companySelectors,
  ...accountProfileSelectors,
  ...guiSelectors
};

const actions = {
  ...authActions,
  ...companyActions,
  ...commonActions,
  ...countryActions,
  ...accountActions
};

const watchedSagas = [
  reTryTakeLatest(actions.fetchCompanies, handleFetchCompanies),
  catchTakeLatest(actions.fetchUEN, handleFetchUEN),
  catchTakeLatest(actions.addCompanySubmit, handleAddCompany),
  catchTakeLatest(actions.editCompanySubmit, handleEditCompany),
  catchTakeLatest(actions.deleteCompany, handleDeleteCompany),
  catchTakeLatest(actions.hasCreditCard, handleHasCreditCard),
  reTryTakeLatest(actions.fetchSearchCompanyName, handleSearchCompanyName),
  catchTakeLatest(
    actions.updateCompanyIncorporationDate,
    handleUpdateCompanyIncorporationDate
  )
];
export default watchedSagas;

export function* handleFetchCompanies(
  action: ActionType<typeof actions.fetchCompanies>
) {
  const res = yield call(RestClient.send, {
    query: {
      detailed: action.payload.viewDetail ? "1" : undefined
    },
    service: "get_companies"
  });

  if (!res) {
    throw new HttpRequestError("Failed to fetch");
  }

  try {
    const json = res;
    const data: any[] = _get(json, "data") || [];
    const formErrors: string = _get(json, "errors.form") || [];

    if (_isIncludes(formErrors, "WRONG_ACCOUNT_TYPE_ERROR")) {
      yield put(
        actions.setCompanies({
          companies: [],
          isFetching: false
        })
      );
      return;
    }

    if (data.length === 0) {
      if (IPMContext.isPayFetchPlatform()) {
        yield put(
          actions.setCompanies({
            companies: [],
            isFetching: false
          })
        );
        return;
      }

      const state = yield select();
      const isModalOpened = selectors.isGivenModalOpened(
        state,
        actions.ModalID.ADD_COMPANY_FORM
      );

      if (!isModalOpened) {
        // Always display add-company modal in every pages.
        yield put(
          actions.toggleModal(
            actions.ModalID.ADD_COMPANY_FORM,
            {
              disallowCancel: true,
              guideDisplay: true
            },
            {
              backdrop: "static",
              keyboard: false
            }
          )
        );
      }

      return;
    }

    // no company has isCurrent = true => exchange token needed
    if (data.every(c => !c.is_current)) {
      yield put(
        actions.exchangeToken({
          companyId: _get(data, "0.id"),
          purpose: "switch_to_first_company"
        })
      );
      return;
    }

    const currentCompany = data.filter(c => c.is_current)[0];
    if (currentCompany) {
      yield call(
        [localStorage, "setItem"],
        "current_company",
        JSON.stringify({
          accountId: currentCompany.account_id,
          allowDelete: currentCompany.allow_delete,
          businessIndustry: currentCompany.business_industry,
          businessType: currentCompany.business_type,
          countryCode: currentCompany.country_code,
          hasCollectedAccount: currentCompany.has_collected_account,
          id: currentCompany.id,
          isCurrent: currentCompany.is_current,
          name: currentCompany.name,
          profileUrl: currentCompany.url || Images.logo,
          registrationNumber: currentCompany.registration_number,
          reviewStatus: currentCompany.review_status
        })
      );
    }

    yield put(
      actions.setCompanies({
        companies: data.map(company => ({
          accountId: company.account_id,
          allowDelete: company.allow_delete,
          businessIndustry: company.business_industry,
          businessType: company.business_type,
          countryCode: company.country_code,
          hasCollectedAccount: company.has_collected_account,
          id: company.id,
          isCurrent: company.is_current,
          name: company.name,
          profileUrl: company.url || Images.logo,
          registrationNumber: company.registration_number,
          reviewStatus: company.review_status
        })),
        isFetching: false
      })
    );
  } catch (e) {
    window.Logger.error("handleFetchCompanies: ", e.message);
  }
}

export function* handleFetchUEN(action: ActionType<typeof actions.fetchUEN>) {
  const {
    uen,
    callback,
    registrationFieldName,
    checker,
    purposeId,
    formError,
    noError
  } = action.payload;

  const state: RootState = yield select();
  const countryId = selectors.getCurrentCountryId(state);
  const currencyId = selectors.getCurrentCurrencyId(state);
  const paymentPaidCurrencyId = selectors.getCurrentPaidCurrencyId(state);

  const res = yield call(RestClient.send, {
    query: {
      checker,
      country_id: countryId,
      currency_id: currencyId,
      id: action.payload.id,
      paid_currency_id: paymentPaidCurrencyId,
      purpose_id: purposeId || 0,
      uen
    },
    service: "post_uen"
  });

  if (!res) {
    throw new HttpRequestError("Failed to fetch");
  }

  const companyName = _get(res, "data.name", "");
  const predefinedUen = _get(res, "data.predefined_uen", []);
  const editable = _get(res, "data.editable", "");

  const predefinedUenGroup = _groupBy(predefinedUen, "account_number");

  yield put(
    actions.setPredefinedUen({
      predefinedUen: Object.values(predefinedUenGroup).map(key => ({
        accountNumber: key[0].account_number,
        bankName: key[0].bank_name,
        companyName: key.map(k => k.company_name)
      }))
    })
  );

  if (callback) {
    callback({ companyName, editable });
  }
  const errors = _get(res, "errors", {});

  if (noError === true) {
    // Do nothing
    return;
  }

  if (!_isEmpty(errors)) {
    if (formError) {
      // Want to display error to another form name
      yield put(
        formActions.parseServerErrors(errors, formError, {
          uen: registrationFieldName
        })
      );
    } else {
      yield put(
        formActions.parseServerErrors(errors, FORM, {
          uen: registrationFieldName
        })
      );
    }
  }
}

export function* handleAddCompany(
  action: ActionType<typeof actions.addCompanySubmit>
) {
  const { callback } = action.payload;

  const state: RootState = yield select();
  const formState = formSelectors.getControls(state, ADD_FORM);
  const firstName = _get(formState, "first_name.value", null);
  const lastName = _get(formState, "last_name.value", null);
  const name = _get(formState, "company_name.value");
  const regno = _get(formState, "registration_number.value");
  const businessType = _get(formState, "business_type.value", null);
  const businessIndustry = _get(formState, "business_industry.value", null);
  const companies = state.company.companies;

  const isPayFetchPlatform = IPMContext.isPayFetchPlatform();

  const found = companies.find(company => company.name === name);
  if (found) {
    yield put(
      formActions.parseServerErrors(
        {
          fields: {
            company_name: ["COMPANY_EXISTED"],
            uen_search_company: ["COMPANY_EXISTED"]
          },
          form: []
        },
        ADD_FORM
      )
    );

    if (callback) {
      callback("COMPANY_EXISTED");
    }
    return;
  }

  yield put(actions.showGlobalLoader());

  const res = yield call(RestClient.send, {
    body: {
      country_code: _get(formState, "country_code.value"),
      name,
      registration_number: regno,
      first_name: firstName,
      last_name: lastName,
      business_type: businessType,
      business_industry: businessIndustry
    },
    service: "create_company"
  });

  yield put(actions.hideGlobalLoader());

  if (!res) {
    if (callback) {
      callback("500");
    }
    return;
  }

  const errors = _get(res, "errors", {});

  if (!_isEmpty(errors)) {
    yield put(
      formActions.parseServerErrors(errors, ADD_FORM, {
        name: "company_name"
      })
    );
    if (callback) {
      callback(errors);
    }
    return;
  }

  const companyId = _get(res, "data.company_id", "");

  if (!isPayFetchPlatform) {
    yield put(actions.closeModal(actions.ModalID.ADD_COMPANY_FORM));
  }

  if (selectors.hasCompany(state) || selectors.hasCompanyDraft(state)) {
    yield put(actions.fetchCompanies());
  } else {
    // First company was created. Then fetch token attached to this such company.
    yield put(
      actions.exchangeToken({
        companyId,
        preventDefault: true,
        purpose: "switch_to_new_created_company"
      })
    );

    // Payment interest confirmation
    if (!isPayFetchPlatform) {
      yield put(actions.toggleModal(actions.ModalID.PAYMENT_INTEREST_MODAL));
    }
  }
  if (callback) {
    callback(null);
  }
}

export function* handleEditCompany(
  action: ActionType<typeof actions.editCompanySubmit>
) {
  const { callback } = action.payload;

  const state: RootState = yield select();
  const formState = formSelectors.getControls(state, ADD_FORM);
  const companies = state.company.companies;
  const name = _get(formState, "company_name.value");
  const regno = _get(formState, "registration_number.value");
  const firstName = _get(formState, "first_name.value", null);
  const lastName = _get(formState, "last_name.value", null);
  const businessType = _get(formState, "business_type.value", null);
  const businessIndustry = _get(formState, "business_industry.value", null);
  const found = companies.find(company => company.name === name);

  const isPayFetchPlatform = IPMContext.isPayFetchPlatform();

  if (found && !isPayFetchPlatform) {
    yield put(
      formActions.parseServerErrors(
        {
          fields: {
            company_name: ["COMPANY_EXISTED"]
          },
          form: []
        },
        ADD_FORM
      )
    );

    if (callback) {
      callback("COMPANY_EXISTED");
    }
    return;
  }

  if (!isPayFetchPlatform) {
    yield put(actions.showGlobalLoader());
  }

  const res = yield call(RestClient.send, {
    body: {
      country_code: _get(formState, "country_code.value"),
      name,
      registration_number: regno,
      first_name: firstName,
      last_name: lastName,
      business_type: businessType,
      business_industry: businessIndustry
    },
    params: {
      id: _get(formState, "id.value")
    },
    service: "edit_company"
  });

  if (!isPayFetchPlatform) {
    yield put(actions.hideGlobalLoader());
  }

  if (!res) {
    if (callback) {
      callback("500");
    }
    return;
  }

  const errors = _get(res, "errors", {});

  if (!_isEmpty(errors)) {
    yield put(
      formActions.parseServerErrors(errors, ADD_FORM, {
        name: "company_name"
      })
    );
    if (callback) {
      callback(errors);
    }
    return;
  }

  if (isPayFetchPlatform) {
    yield put(actions.fetchCompanies(true));
  } else {
    yield put(actions.fetchCompanies());
  }

  if (callback) {
    callback(null);
  }

  if (!isPayFetchPlatform) {
    yield put(actions.closeModal(actions.ModalID.ADD_COMPANY_FORM));
  }
}

export function* handleDeleteCompany(
  action: ActionType<typeof actions.deleteCompany>
) {
  yield put(actions.showGlobalLoader());

  const res = yield call(RestClient.send, {
    params: {
      id: action.payload.id
    },
    service: "delete_company"
  });

  yield put(actions.hideGlobalLoader());

  if (!res) {
    return;
  }

  const errors = _get(res, "errors", {});
  const token = _get(res, "data.token");

  if (!_isEmpty(errors)) {
    return;
  }

  if (token) {
    yield call([localStorage, "setItem"], "token", token);
    yield put(actions.setToken({ token }));

    window.location.reload();
  } else {
    yield put(actions.fetchCompanies());
  }
}

export function* handleHasCreditCard(
  action: ActionType<typeof actions.hasCreditCard>
) {
  const state: RootState = yield select();
  const formState = formSelectors.getControls(state, ADD_FORM);

  let value = action.payload.value;
  if (value === undefined) {
    value = _get(formState, "has_credit_card.value", "") === "yes";
  }

  const res = yield call(RestClient.send, {
    body: {
      has_credit_card: value
    },
    service: "has_credit_card"
  });

  if (!res) {
    return;
  }

  const errors = _get(res, "errors", {});

  if (!_isEmpty(errors)) {
    return;
  }
}

export function* handleSearchCompanyName(
  action: ActionType<typeof actions.fetchSearchCompanyName>
) {
  const res = yield call(RestClient.send, {
    query: {
      country_id: action.payload.arg.countryId,
      offset: action.payload.arg.offset,
      page_count: action.payload.arg.pageCount,
      q: action.payload.arg.value
    },
    service: "fetch_search_uen"
  });
  if (!res) {
    return;
  }
  const data: any[] = _get(res, "data", []);
  const editable: boolean = _get(res, "editable", false);
  if (data.length === 0) {
    yield put(
      formActions.parseServerErrors(
        {
          fields: {
            [action.payload.arg.name || "uen_search_company"]: [
              "SEARCH_COMPANY_NO_RESULTS"
            ]
          },
          form: []
        },
        ADD_FORM
      )
    );
  }
  if (action.payload.callback) {
    action.payload.callback(data, editable);
  }
  return;
}

export function* handleUpdateCompanyIncorporationDate(
  action: ActionType<typeof actions.updateCompanyIncorporationDate>
) {
  const state: RootState = yield select();
  const formState = formSelectors.getControls(
    state,
    UPDATE_INCORPORATION_DATE_FORM
  );
  const year = _get(formState, "year.value", null);
  const month = _get(formState, "month.value", null);
  const day = _get(formState, "day.value", null);

  const res = yield call(RestClient.send, {
    body: {
      incorporation_date: DateUtils.formatDate(
        `${+year}-${+month}-${+day}`,
        "YYYY-MM-DD"
      )
    },
    params: {
      id: action.payload.companyId
    },
    service: "update_company_incorporation_date"
  });

  if (!res) {
    return;
  }

  const errors = _get(res, "errors", {});

  if (!_isEmpty(errors)) {
    return;
  }

  yield put(
    actions.fetchAccountProfiles((err?: any) => {
      if (action.payload.callback) {
        action.payload.callback();
      }
    })
  );

  return;
}
