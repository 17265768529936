import React from "react";
import Input from "antd/es/input";

import Flex from "antd-mobile/es/flex";

import SingleOTPInput from "./SingleOtpInput";

import styles from "./InputOptVerification.module.scss";

const BACKSPACE_KEY = 8;
const SPACE_KEY = 32;
const LEFT_ARROW_KEY = 37;
const RIGHT_ARROW_KEY = 39;
const DELETE_KEY = 46;

export interface OTPInputProps {
  length?: number;
  onChange?: (otp: string) => any;
  autoFocus?: boolean;
  isNumberInput?: boolean;
  disabled?: boolean;
  style?: React.CSSProperties;
  className?: string;
  inputStyle?: React.CSSProperties;
  inputClassName?: string;
}

export function OTPInputComponent(props: OTPInputProps) {
  const {
    length = 6,
    isNumberInput = true,
    autoFocus = true,
    disabled = false,
    onChange,
    inputClassName,
    inputStyle,
    ...rest
  } = props;

  const [activeInput, setActiveInput] = React.useState(0);
  const [otpValues, setOTPValues] = React.useState(
    Array<string>(length).fill("")
  );

  const handleOtpChange = React.useCallback(
    (otp: string[]) => {
      const otpValue = otp.join("");
      if (onChange) {
        onChange(otpValue);
      }
    },
    [onChange]
  );

  // return value with the right type: 'text' or 'number'
  const getRightValue = React.useCallback(
    (str: string) => {
      const changedValue = str;
      if (!isNumberInput) {
        return changedValue;
      }
      return !changedValue || /\d/.test(changedValue) ? changedValue : "";
    },
    [isNumberInput]
  );

  const changeCodeAtFocus = React.useCallback(
    (str: string) => {
      const updatedOTPValues = [...otpValues];
      updatedOTPValues[activeInput] = str[0] || "";
      setOTPValues(updatedOTPValues);
      handleOtpChange(updatedOTPValues);
    },
    [activeInput, handleOtpChange, otpValues]
  );

  const focusInput = React.useCallback(
    (inputIndex: number) => {
      const selectedIndex = Math.max(Math.min(length - 1, inputIndex), 0);
      setActiveInput(selectedIndex);
    },
    [length]
  );

  const focusPrevInput = React.useCallback(() => {
    focusInput(activeInput - 1);
  }, [activeInput, focusInput]);

  const focusNextInput = React.useCallback(() => {
    focusInput(activeInput + 1);
  }, [activeInput, focusInput]);

  const handleOnFocus = React.useCallback(
    (index: number) => () => {
      focusInput(index);
    },
    [focusInput]
  );

  const handleOnChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const val = getRightValue(e.currentTarget.value);
      if (!val) {
        e.preventDefault();
        return;
      }
      changeCodeAtFocus(val);
      focusNextInput();
    },
    [changeCodeAtFocus, focusNextInput, getRightValue]
  );

  const onBlur = React.useCallback(() => {
    setActiveInput(-1);
  }, []);

  const handleOnKeyDown = React.useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      switch (e.keyCode) {
        case BACKSPACE_KEY:
        case DELETE_KEY: {
          e.preventDefault();
          if (otpValues[activeInput]) {
            changeCodeAtFocus("");
          }
          focusPrevInput();
          break;
        }
        case LEFT_ARROW_KEY: {
          e.preventDefault();
          focusPrevInput();
          break;
        }
        case RIGHT_ARROW_KEY: {
          e.preventDefault();
          focusNextInput();
          break;
        }
        case SPACE_KEY: {
          e.preventDefault();
          break;
        }
        default:
          break;
      }
    },
    [activeInput, changeCodeAtFocus, focusNextInput, focusPrevInput, otpValues]
  );

  const handleOnPaste = React.useCallback(
    (e: React.ClipboardEvent<HTMLInputElement>) => {
      e.preventDefault();
      const pastedData = e.clipboardData
        .getData("text/plain")
        .trim()
        .slice(0, length - activeInput)
        .split("");
      if (pastedData) {
        let nextFocusIndex = 0;
        const updatedOTPValues = [...otpValues];
        updatedOTPValues.forEach((val, index) => {
          if (index >= activeInput) {
            const changedValue = getRightValue(pastedData.shift() || val);
            if (changedValue) {
              updatedOTPValues[index] = changedValue;
              nextFocusIndex = index;
            }
          }
        });
        setOTPValues(updatedOTPValues);
        setActiveInput(Math.min(nextFocusIndex + 1, length - 1));
      }
    },
    [activeInput, getRightValue, length, otpValues]
  );

  return (
    <Input.Group className={styles.wrapper} {...rest}>
      <Flex>
        {Array(length)
          .fill("")
          .map((_, index) => (
            <Flex.Item key={index} span={4}>
              <SingleOTPInput
                key={`SingleOTPInput-${index}`}
                focus={activeInput === index}
                value={otpValues && otpValues[index]}
                autoFocus={autoFocus}
                onFocus={handleOnFocus(index)}
                onChange={handleOnChange}
                onKeyDown={handleOnKeyDown}
                onBlur={onBlur}
                onPaste={handleOnPaste}
                disabled={disabled}
                style={inputStyle}
                className={inputClassName}
              />
            </Flex.Item>
          ))}
      </Flex>
    </Input.Group>
  );
}

const InputOtpVerification = React.memo(OTPInputComponent);
export default InputOtpVerification;
