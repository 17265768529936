import React from "react";
import _get from "lodash-es/get";
import LandlordsContentExtend from "./LandlordsContentExtend";
import EmployeesContentExtend from "./EmployeesContentExtend";
import SuppliersContentExtend from "./SuppliersContentExtend";
import BankAccountsContentExtend from "./BankAccountsContentExtend";
import TaxContentExtend from "./TaxContentExtend";
import { TAX_PAYMENT_ACCOUNT_NUMBER } from "src/ipm-shared/store/model/Payment/const";

const View = (props: any) => {
  const purposeId = _get(props.selectedPayee, "purposeId", 0);
  const paymentAccountNumber = _get(props.selectedPayee, "accountNumber");

  if (purposeId === 1) {
    return <LandlordsContentExtend {...props} />;
  }
  if (purposeId === 2) {
    return <EmployeesContentExtend {...props} />;
  }
  if (purposeId === 3) {
    return TAX_PAYMENT_ACCOUNT_NUMBER.includes(paymentAccountNumber) ? (
      <TaxContentExtend {...props} />
    ) : (
      <SuppliersContentExtend {...props} />
    );
  }
  if (purposeId === 5) {
    return <BankAccountsContentExtend {...props} />;
  }
  return <></>;
};

export default View;
