import { connect } from "react-redux";

import View from "./View";
import { RootState } from "src/ipm-shared/store/model/reducers";
import * as selectors from "src/bepaid/store/model/Fetch/selectors";

const mapStateToProps = (state: RootState, props: any) => ({
  accountCountryCode: selectors.getAccountCountryCode(state)
});

export default connect(mapStateToProps, null)(View);
