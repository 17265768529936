/* tslint:disable */
// tslint:disable-object-literal-sort-keys
const t = [
  {
    id: 88,
    code: "GT",
    name: "Guatemala",
    calling_code: 502,
    currency_code: null,
    alpha3_code: "GTM"
  },
  {
    id: 89,
    code: "GG",
    name: "Guernsey",
    calling_code: 44,
    currency_code: null,
    alpha3_code: "GGY"
  },
  {
    id: 90,
    code: "GN",
    name: "Guinea",
    calling_code: 224,
    currency_code: null,
    alpha3_code: "GIN"
  },
  {
    id: 128,
    code: "MW",
    name: "Malawi",
    calling_code: 265,
    currency_code: null,
    alpha3_code: "MWI"
  },
  {
    id: 129,
    code: "MV",
    name: "Maldives",
    calling_code: 960,
    currency_code: null,
    alpha3_code: "MDV"
  },
  {
    id: 91,
    code: "GW",
    name: "Guinea-Bissau",
    calling_code: 245,
    currency_code: null,
    alpha3_code: "GNB"
  },
  {
    id: 130,
    code: "ML",
    name: "Mali",
    calling_code: 223,
    currency_code: null,
    alpha3_code: "MLI"
  },
  {
    id: 131,
    code: "MT",
    name: "Malta",
    calling_code: 356,
    currency_code: "EUR",
    alpha3_code: "MLT"
  },
  {
    id: 92,
    code: "GY",
    name: "Guyana",
    calling_code: 592,
    currency_code: null,
    alpha3_code: "GUY"
  },
  {
    id: 93,
    code: "HT",
    name: "Haiti",
    calling_code: 509,
    currency_code: null,
    alpha3_code: "HTI"
  },
  {
    id: 132,
    code: "MH",
    name: "Marshall Islands",
    calling_code: 692,
    currency_code: null,
    alpha3_code: "MHL"
  },
  {
    id: 133,
    code: "MR",
    name: "Mauritania",
    calling_code: 222,
    currency_code: null,
    alpha3_code: "MRT"
  },
  {
    id: 134,
    code: "MU",
    name: "Mauritius",
    calling_code: 230,
    currency_code: null,
    alpha3_code: "MUS"
  },
  {
    id: 135,
    code: "YT",
    name: "Mayotte",
    calling_code: 262,
    currency_code: null,
    alpha3_code: "MYT"
  },
  {
    id: 94,
    code: "HN",
    name: "Honduras",
    calling_code: 504,
    currency_code: null,
    alpha3_code: "HND"
  },
  {
    id: 137,
    code: "FM",
    name: "Micronesia",
    calling_code: 691,
    currency_code: null,
    alpha3_code: "FSM"
  },
  {
    id: 138,
    code: "MD",
    name: "Moldova",
    calling_code: 373,
    currency_code: null,
    alpha3_code: "MDA"
  },
  {
    id: 96,
    code: "IS",
    name: "Iceland",
    calling_code: 354,
    currency_code: null,
    alpha3_code: "ISL"
  },
  {
    id: 139,
    code: "MC",
    name: "Monaco",
    calling_code: 377,
    currency_code: null,
    alpha3_code: "MCO"
  },
  {
    id: 140,
    code: "MN",
    name: "Mongolia",
    calling_code: 976,
    currency_code: null,
    alpha3_code: "MNG"
  },
  {
    id: 141,
    code: "ME",
    name: "Montenegro",
    calling_code: 382,
    currency_code: null,
    alpha3_code: "MNE"
  },
  {
    id: 142,
    code: "MS",
    name: "Montserrat",
    calling_code: 1,
    currency_code: null,
    alpha3_code: "MSR"
  },
  {
    id: 143,
    code: "MA",
    name: "Morocco",
    calling_code: 212,
    currency_code: null,
    alpha3_code: "MAR"
  },
  {
    id: 144,
    code: "MZ",
    name: "Mozambique",
    calling_code: 258,
    currency_code: null,
    alpha3_code: "MOZ"
  },
  {
    id: 145,
    code: "MM",
    name: "Myanmar",
    calling_code: 95,
    currency_code: null,
    alpha3_code: "MMR"
  },
  {
    id: 146,
    code: "NA",
    name: "Namibia",
    calling_code: 264,
    currency_code: null,
    alpha3_code: "NAM"
  },
  {
    id: 147,
    code: "NR",
    name: "Nauru",
    calling_code: 674,
    currency_code: null,
    alpha3_code: "NRU"
  },
  {
    id: 148,
    code: "NP",
    name: "Nepal",
    calling_code: 977,
    currency_code: "NPR",
    alpha3_code: "NPL"
  },
  {
    id: 149,
    code: "NL",
    name: "Netherlands",
    calling_code: 31,
    currency_code: "EUR",
    alpha3_code: "NLD"
  },
  {
    id: 160,
    code: "OM",
    name: "Oman",
    calling_code: 968,
    currency_code: "OMR",
    alpha3_code: "OMN"
  },
  {
    id: 171,
    code: "PT",
    name: "Portugal",
    calling_code: 351,
    currency_code: "EUR",
    alpha3_code: "PRT"
  },
  {
    id: 1,
    code: "SG",
    name: "Singapore",
    calling_code: 65,
    currency_code: "SGD",
    alpha3_code: "SGP"
  },
  {
    id: 2,
    code: "MY",
    name: "Malaysia",
    calling_code: 60,
    currency_code: "MYR",
    alpha3_code: "MYS"
  },
  {
    id: 3,
    code: "HK",
    name: "Hong Kong",
    calling_code: 852,
    currency_code: "HKD",
    alpha3_code: "HKG"
  },
  {
    id: 4,
    code: "AL",
    name: "Albania",
    calling_code: 355,
    currency_code: "ALL",
    alpha3_code: "ALB"
  },
  {
    id: 5,
    code: "DZ",
    name: "Algeria",
    calling_code: 213,
    currency_code: "DZD",
    alpha3_code: "DZA"
  },
  {
    id: 6,
    code: "AS",
    name: "American Samoa",
    calling_code: 1,
    currency_code: null,
    alpha3_code: "ASM"
  },
  {
    id: 7,
    code: "AD",
    name: "Andorra",
    calling_code: 376,
    currency_code: "EUR",
    alpha3_code: "AND"
  },
  {
    id: 8,
    code: "AO",
    name: "Angola",
    calling_code: 244,
    currency_code: "AOA",
    alpha3_code: "AGO"
  },
  {
    id: 176,
    code: "RO",
    name: "Romania",
    calling_code: 40,
    currency_code: "RON",
    alpha3_code: "ROU"
  },
  {
    id: 9,
    code: "AI",
    name: "Anguilla",
    calling_code: 1,
    currency_code: null,
    alpha3_code: "AIA"
  },
  {
    id: 10,
    code: "VN",
    name: "Vietnam",
    calling_code: 84,
    currency_code: "VND",
    alpha3_code: "VNM"
  },
  {
    id: 11,
    code: "AQ",
    name: "Antarctica",
    calling_code: 672,
    currency_code: null,
    alpha3_code: "ATA"
  },
  {
    id: 12,
    code: "AG",
    name: "Antigua and Barbuda",
    calling_code: 1,
    currency_code: null,
    alpha3_code: "ATG"
  },
  {
    id: 199,
    code: "ZA",
    name: "South Africa",
    calling_code: 27,
    currency_code: "ZAR",
    alpha3_code: "ZAF"
  },
  {
    id: 200,
    code: "KR",
    name: "South Korea",
    calling_code: 82,
    currency_code: "KRW",
    alpha3_code: "KOR"
  },
  {
    id: 209,
    code: "CH",
    name: "Switzerland",
    calling_code: 41,
    currency_code: "CHF",
    alpha3_code: "CHE"
  },
  {
    id: 13,
    code: "AR",
    name: "Argentina",
    calling_code: 54,
    currency_code: "ARS",
    alpha3_code: "ARG"
  },
  {
    id: 218,
    code: "TT",
    name: "Trinidad and Tobago",
    calling_code: 1,
    currency_code: null,
    alpha3_code: "TTO"
  },
  {
    id: 220,
    code: "TR",
    name: "Turkey",
    calling_code: 90,
    currency_code: "TRY",
    alpha3_code: "TUR"
  },
  {
    id: 14,
    code: "AM",
    name: "Armenia",
    calling_code: 374,
    currency_code: "AMD",
    alpha3_code: "ARM"
  },
  {
    id: 235,
    code: "WF",
    name: "Wallis and Futuna",
    calling_code: 681,
    currency_code: null,
    alpha3_code: "WLF"
  },
  {
    id: 236,
    code: "EH",
    name: "Western Sahara",
    calling_code: 212,
    currency_code: null,
    alpha3_code: "ESH"
  },
  {
    id: 237,
    code: "YE",
    name: "Yemen",
    calling_code: 967,
    currency_code: null,
    alpha3_code: "YEM"
  },
  {
    id: 18,
    code: "AZ",
    name: "Azerbaijan",
    calling_code: 994,
    currency_code: "AZN",
    alpha3_code: "AZE"
  },
  {
    id: 19,
    code: "BS",
    name: "Bahamas",
    calling_code: 1,
    currency_code: null,
    alpha3_code: "BHS"
  },
  {
    id: 21,
    code: "BD",
    name: "Bangladesh",
    calling_code: 880,
    currency_code: "BDT",
    alpha3_code: "BGD"
  },
  {
    id: 22,
    code: "BB",
    name: "Barbados",
    calling_code: 1,
    currency_code: null,
    alpha3_code: "BRB"
  },
  {
    id: 23,
    code: "BY",
    name: "Belarus",
    calling_code: 375,
    currency_code: "BYN",
    alpha3_code: "BLR"
  },
  {
    id: 24,
    code: "BE",
    name: "Belgium",
    calling_code: 32,
    currency_code: "EUR",
    alpha3_code: "BEL"
  },
  {
    id: 25,
    code: "BZ",
    name: "Belize",
    calling_code: 501,
    currency_code: "BZD",
    alpha3_code: "BLZ"
  },
  {
    id: 26,
    code: "BJ",
    name: "Benin",
    calling_code: 229,
    currency_code: "XOF",
    alpha3_code: "BEN"
  },
  {
    id: 27,
    code: "BM",
    name: "Bermuda",
    calling_code: 1,
    currency_code: null,
    alpha3_code: "BMU"
  },
  {
    id: 98,
    code: "ID",
    name: "Indonesia",
    calling_code: 62,
    currency_code: "IDR",
    alpha3_code: "IDN"
  },
  {
    id: 100,
    code: "IQ",
    name: "Iraq",
    calling_code: 964,
    currency_code: null,
    alpha3_code: "IRQ"
  },
  {
    id: 102,
    code: "IM",
    name: "Isle of Man",
    calling_code: 44,
    currency_code: null,
    alpha3_code: "IMN"
  },
  {
    id: 103,
    code: "IL",
    name: "Israel",
    calling_code: 972,
    currency_code: "ILS",
    alpha3_code: "ISR"
  },
  {
    id: 105,
    code: "CI",
    name: "Ivory Coast",
    calling_code: 225,
    currency_code: null,
    alpha3_code: "CIV"
  },
  {
    id: 108,
    code: "JE",
    name: "Jersey",
    calling_code: 44,
    currency_code: null,
    alpha3_code: "JEY"
  },
  {
    id: 109,
    code: "JO",
    name: "Jordan",
    calling_code: 962,
    currency_code: null,
    alpha3_code: "JOR"
  },
  {
    id: 110,
    code: "KZ",
    name: "Kazakhstan",
    calling_code: 7,
    currency_code: null,
    alpha3_code: "KAZ"
  },
  {
    id: 111,
    code: "KE",
    name: "Kenya",
    calling_code: 254,
    currency_code: "KES",
    alpha3_code: "KEN"
  },
  {
    id: 112,
    code: "KI",
    name: "Kiribati",
    calling_code: 686,
    currency_code: null,
    alpha3_code: "KIR"
  },
  {
    id: 113,
    code: "XK",
    name: "Kosovo",
    calling_code: 383,
    currency_code: null,
    alpha3_code: ""
  },
  {
    id: 238,
    code: "ZM",
    name: "Zambia",
    calling_code: 260,
    currency_code: null,
    alpha3_code: "ZMB"
  },
  {
    id: 114,
    code: "KW",
    name: "Kuwait",
    calling_code: 965,
    currency_code: "KWD",
    alpha3_code: "KWT"
  },
  {
    id: 115,
    code: "KG",
    name: "Kyrgyzstan",
    calling_code: 996,
    currency_code: null,
    alpha3_code: "KGZ"
  },
  {
    id: 116,
    code: "LA",
    name: "Laos",
    calling_code: 856,
    currency_code: null,
    alpha3_code: "LAO"
  },
  {
    id: 118,
    code: "LB",
    name: "Lebanon",
    calling_code: 961,
    currency_code: null,
    alpha3_code: "LBN"
  },
  {
    id: 119,
    code: "LS",
    name: "Lesotho",
    calling_code: 266,
    currency_code: null,
    alpha3_code: "LSO"
  },
  {
    id: 120,
    code: "LR",
    name: "Liberia",
    calling_code: 231,
    currency_code: null,
    alpha3_code: "LBR"
  },
  {
    id: 121,
    code: "LY",
    name: "Libya",
    calling_code: 218,
    currency_code: null,
    alpha3_code: "LBY"
  },
  {
    id: 122,
    code: "LI",
    name: "Liechtenstein",
    calling_code: 423,
    currency_code: null,
    alpha3_code: "LIE"
  },
  {
    id: 124,
    code: "LU",
    name: "Luxembourg",
    calling_code: 352,
    currency_code: "EUR",
    alpha3_code: "LUX"
  },
  {
    id: 125,
    code: "MO",
    name: "Macau",
    calling_code: 853,
    currency_code: null,
    alpha3_code: "MAC"
  },
  {
    id: 126,
    code: "MK",
    name: "Macedonia",
    calling_code: 389,
    currency_code: null,
    alpha3_code: "MKD"
  },
  {
    id: 127,
    code: "MG",
    name: "Madagascar",
    calling_code: 261,
    currency_code: null,
    alpha3_code: "MDG"
  },
  {
    id: 239,
    code: "ZW",
    name: "Zimbabwe",
    calling_code: 263,
    currency_code: null,
    alpha3_code: "ZWE"
  },
  {
    id: 240,
    code: "AF",
    name: "Afghanistan",
    calling_code: 93,
    currency_code: null,
    alpha3_code: "AFG"
  },
  {
    id: 219,
    code: "TN",
    name: "Tunisia",
    calling_code: 216,
    currency_code: null,
    alpha3_code: "TUN"
  },
  {
    id: 151,
    code: "NC",
    name: "New Caledonia",
    calling_code: 687,
    currency_code: null,
    alpha3_code: "NCL"
  },
  {
    id: 153,
    code: "NI",
    name: "Nicaragua",
    calling_code: 505,
    currency_code: null,
    alpha3_code: "NIC"
  },
  {
    id: 221,
    code: "TM",
    name: "Turkmenistan",
    calling_code: 993,
    currency_code: null,
    alpha3_code: "TKM"
  },
  {
    id: 172,
    code: "PR",
    name: "Puerto Rico",
    calling_code: 1,
    currency_code: null,
    alpha3_code: "PRI"
  },
  {
    id: 154,
    code: "NE",
    name: "Niger",
    calling_code: 227,
    currency_code: null,
    alpha3_code: "NER"
  },
  {
    id: 222,
    code: "TC",
    name: "Turks and Caicos Islands",
    calling_code: 1,
    currency_code: null,
    alpha3_code: "TCA"
  },
  {
    id: 15,
    code: "AW",
    name: "Aruba",
    calling_code: 297,
    currency_code: "AWG",
    alpha3_code: "ABW"
  },
  {
    id: 155,
    code: "NG",
    name: "Nigeria",
    calling_code: 234,
    currency_code: null,
    alpha3_code: "NGA"
  },
  {
    id: 20,
    code: "BH",
    name: "Bahrain",
    calling_code: 973,
    currency_code: "BHD",
    alpha3_code: "BHR"
  },
  {
    id: 156,
    code: "NU",
    name: "Niue",
    calling_code: 683,
    currency_code: null,
    alpha3_code: "NIU"
  },
  {
    id: 30,
    code: "BA",
    name: "Bosnia and Herzegovina",
    calling_code: 387,
    currency_code: "BAM",
    alpha3_code: "BIH"
  },
  {
    id: 223,
    code: "TV",
    name: "Tuvalu",
    calling_code: 688,
    currency_code: null,
    alpha3_code: "TUV"
  },
  {
    id: 224,
    code: "VI",
    name: "U.S. Virgin Islands",
    calling_code: 1,
    currency_code: null,
    alpha3_code: "VIR"
  },
  {
    id: 225,
    code: "UG",
    name: "Uganda",
    calling_code: 256,
    currency_code: null,
    alpha3_code: "UGA"
  },
  {
    id: 174,
    code: "CG",
    name: "Republic of the Congo",
    calling_code: 242,
    currency_code: null,
    alpha3_code: "COG"
  },
  {
    id: 31,
    code: "BW",
    name: "Botswana",
    calling_code: 267,
    currency_code: "BWP",
    alpha3_code: "BWA"
  },
  {
    id: 32,
    code: "BR",
    name: "Brazil",
    calling_code: 55,
    currency_code: "BRL",
    alpha3_code: "BRA"
  },
  {
    id: 35,
    code: "BN",
    name: "Brunei",
    calling_code: 673,
    currency_code: "BND",
    alpha3_code: "BRN"
  },
  {
    id: 36,
    code: "BG",
    name: "Bulgaria",
    calling_code: 359,
    currency_code: "BGN",
    alpha3_code: "BGR"
  },
  {
    id: 37,
    code: "BF",
    name: "Burkina Faso",
    calling_code: 226,
    currency_code: "XOF",
    alpha3_code: "BFA"
  },
  {
    id: 38,
    code: "BI",
    name: "Burundi",
    calling_code: 257,
    currency_code: "BIF",
    alpha3_code: "BDI"
  },
  {
    id: 39,
    code: "KH",
    name: "Cambodia",
    calling_code: 855,
    currency_code: "KHR",
    alpha3_code: "KHM"
  },
  {
    id: 42,
    code: "CV",
    name: "Cape Verde",
    calling_code: 238,
    currency_code: "CVE",
    alpha3_code: "CPV"
  },
  {
    id: 43,
    code: "KY",
    name: "Cayman Islands",
    calling_code: 1,
    currency_code: null,
    alpha3_code: "CYM"
  },
  {
    id: 46,
    code: "CL",
    name: "Chile",
    calling_code: 56,
    currency_code: "CLP",
    alpha3_code: "CHL"
  },
  {
    id: 47,
    code: "CN",
    name: "China",
    calling_code: 86,
    currency_code: "CNY",
    alpha3_code: "CHN"
  },
  {
    id: 49,
    code: "CC",
    name: "Cocos Islands",
    calling_code: 61,
    currency_code: null,
    alpha3_code: "CCK"
  },
  {
    id: 50,
    code: "CO",
    name: "Colombia",
    calling_code: 57,
    currency_code: "COP",
    alpha3_code: "COL"
  },
  {
    id: 52,
    code: "CK",
    name: "Cook Islands",
    calling_code: 682,
    currency_code: null,
    alpha3_code: "COK"
  },
  {
    id: 54,
    code: "HR",
    name: "Croatia",
    calling_code: 385,
    currency_code: "HRK",
    alpha3_code: "HRV"
  },
  {
    id: 56,
    code: "CW",
    name: "Curacao",
    calling_code: 599,
    currency_code: "ANG",
    alpha3_code: "CUW"
  },
  {
    id: 57,
    code: "CY",
    name: "Cyprus",
    calling_code: 357,
    currency_code: "EUR",
    alpha3_code: "CYP"
  },
  {
    id: 58,
    code: "CZ",
    name: "Czech Republic",
    calling_code: 420,
    currency_code: "CZK",
    alpha3_code: "CZE"
  },
  {
    id: 59,
    code: "CD",
    name: "Democratic Republic of the Congo",
    calling_code: 243,
    currency_code: "CDF",
    alpha3_code: "COD"
  },
  {
    id: 61,
    code: "DJ",
    name: "Djibouti",
    calling_code: 253,
    currency_code: "DJF",
    alpha3_code: "DJI"
  },
  {
    id: 62,
    code: "DM",
    name: "Dominica",
    calling_code: 1,
    currency_code: null,
    alpha3_code: "DMA"
  },
  {
    id: 64,
    code: "TL",
    name: "East Timor",
    calling_code: 670,
    currency_code: null,
    alpha3_code: "TLS"
  },
  {
    id: 65,
    code: "EC",
    name: "Ecuador",
    calling_code: 593,
    currency_code: "USD",
    alpha3_code: "ECU"
  },
  {
    id: 67,
    code: "SV",
    name: "El Salvador",
    calling_code: 503,
    currency_code: "USD",
    alpha3_code: "SLV"
  },
  {
    id: 76,
    code: "FR",
    name: "France",
    calling_code: 33,
    currency_code: "EUR",
    alpha3_code: "FRA"
  },
  {
    id: 106,
    code: "JM",
    name: "Jamaica",
    calling_code: 1,
    currency_code: "JMD",
    alpha3_code: "JAM"
  },
  {
    id: 107,
    code: "JP",
    name: "Japan",
    calling_code: 81,
    currency_code: "JPY",
    alpha3_code: "JPN"
  },
  {
    id: 158,
    code: "MP",
    name: "Northern Mariana Islands",
    calling_code: 1,
    currency_code: null,
    alpha3_code: "MNP"
  },
  {
    id: 159,
    code: "NO",
    name: "Norway",
    calling_code: 47,
    currency_code: "NOK",
    alpha3_code: "NOR"
  },
  {
    id: 161,
    code: "PK",
    name: "Pakistan",
    calling_code: 92,
    currency_code: null,
    alpha3_code: "PAK"
  },
  {
    id: 162,
    code: "PW",
    name: "Palau",
    calling_code: 680,
    currency_code: null,
    alpha3_code: "PLW"
  },
  {
    id: 163,
    code: "PS",
    name: "Palestine",
    calling_code: 970,
    currency_code: null,
    alpha3_code: "PSE"
  },
  {
    id: 164,
    code: "PA",
    name: "Panama",
    calling_code: 507,
    currency_code: null,
    alpha3_code: "PAN"
  },
  {
    id: 165,
    code: "PG",
    name: "Papua New Guinea",
    calling_code: 675,
    currency_code: null,
    alpha3_code: "PNG"
  },
  {
    id: 166,
    code: "PY",
    name: "Paraguay",
    calling_code: 595,
    currency_code: null,
    alpha3_code: "PRY"
  },
  {
    id: 167,
    code: "PE",
    name: "Peru",
    calling_code: 51,
    currency_code: null,
    alpha3_code: "PER"
  },
  {
    id: 168,
    code: "PH",
    name: "Philippines",
    calling_code: 63,
    currency_code: "PHP",
    alpha3_code: "PHL"
  },
  {
    id: 169,
    code: "PN",
    name: "Pitcairn",
    calling_code: 64,
    currency_code: null,
    alpha3_code: "PCN"
  },
  {
    id: 175,
    code: "RE",
    name: "Reunion",
    calling_code: 262,
    currency_code: null,
    alpha3_code: "REU"
  },
  {
    id: 177,
    code: "RU",
    name: "Russia",
    calling_code: 7,
    currency_code: null,
    alpha3_code: "RUS"
  },
  {
    id: 178,
    code: "RW",
    name: "Rwanda",
    calling_code: 250,
    currency_code: null,
    alpha3_code: "RWA"
  },
  {
    id: 179,
    code: "BL",
    name: "Saint Barthelemy",
    calling_code: 590,
    currency_code: null,
    alpha3_code: "BLM"
  },
  {
    id: 180,
    code: "SH",
    name: "Saint Helena",
    calling_code: 290,
    currency_code: null,
    alpha3_code: "SHN"
  },
  {
    id: 181,
    code: "KN",
    name: "Saint Kitts and Nevis",
    calling_code: 1,
    currency_code: null,
    alpha3_code: "KNA"
  },
  {
    id: 182,
    code: "LC",
    name: "Saint Lucia",
    calling_code: 1,
    currency_code: null,
    alpha3_code: "LCA"
  },
  {
    id: 183,
    code: "MF",
    name: "Saint Martin",
    calling_code: 590,
    currency_code: null,
    alpha3_code: "MAF"
  },
  {
    id: 184,
    code: "PM",
    name: "Saint Pierre and Miquelon",
    calling_code: 508,
    currency_code: null,
    alpha3_code: "SPM"
  },
  {
    id: 185,
    code: "VC",
    name: "Saint Vincent and the Grenadines",
    calling_code: 1,
    currency_code: null,
    alpha3_code: "VCT"
  },
  {
    id: 186,
    code: "WS",
    name: "Samoa",
    calling_code: 685,
    currency_code: null,
    alpha3_code: "WSM"
  },
  {
    id: 187,
    code: "SM",
    name: "San Marino",
    calling_code: 378,
    currency_code: null,
    alpha3_code: "SMR"
  },
  {
    id: 188,
    code: "ST",
    name: "Sao Tome and Principe",
    calling_code: 239,
    currency_code: null,
    alpha3_code: "STP"
  },
  {
    id: 190,
    code: "SN",
    name: "Senegal",
    calling_code: 221,
    currency_code: null,
    alpha3_code: "SEN"
  },
  {
    id: 191,
    code: "RS",
    name: "Serbia",
    calling_code: 381,
    currency_code: null,
    alpha3_code: "SRB"
  },
  {
    id: 192,
    code: "SC",
    name: "Seychelles",
    calling_code: 248,
    currency_code: null,
    alpha3_code: "SYC"
  },
  {
    id: 193,
    code: "SL",
    name: "Sierra Leone",
    calling_code: 232,
    currency_code: null,
    alpha3_code: "SLE"
  },
  {
    id: 194,
    code: "SX",
    name: "Sint Maarten",
    calling_code: 1,
    currency_code: null,
    alpha3_code: "SXM"
  },
  {
    id: 196,
    code: "SI",
    name: "Slovenia",
    calling_code: 386,
    currency_code: "EUR",
    alpha3_code: "SVN"
  },
  {
    id: 197,
    code: "SB",
    name: "Solomon Islands",
    calling_code: 677,
    currency_code: null,
    alpha3_code: "SLB"
  },
  {
    id: 198,
    code: "SO",
    name: "Somalia",
    calling_code: 252,
    currency_code: null,
    alpha3_code: "SOM"
  },
  {
    id: 201,
    code: "SS",
    name: "South Sudan",
    calling_code: 211,
    currency_code: null,
    alpha3_code: "SSD"
  },
  {
    id: 202,
    code: "ES",
    name: "Spain",
    calling_code: 34,
    currency_code: "EUR",
    alpha3_code: "ESP"
  },
  {
    id: 205,
    code: "SR",
    name: "Suriname",
    calling_code: 597,
    currency_code: null,
    alpha3_code: "SUR"
  },
  {
    id: 206,
    code: "SJ",
    name: "Svalbard and Jan Mayen",
    calling_code: 47,
    currency_code: null,
    alpha3_code: "SJM"
  },
  {
    id: 207,
    code: "SZ",
    name: "Swaziland",
    calling_code: 268,
    currency_code: null,
    alpha3_code: "SWZ"
  },
  {
    id: 208,
    code: "SE",
    name: "Sweden",
    calling_code: 46,
    currency_code: "SEK",
    alpha3_code: "SWE"
  },
  {
    id: 211,
    code: "TW",
    name: "Taiwan",
    calling_code: 886,
    currency_code: "TWD",
    alpha3_code: "TWN"
  },
  {
    id: 212,
    code: "TJ",
    name: "Tajikistan",
    calling_code: 992,
    currency_code: null,
    alpha3_code: "TJK"
  },
  {
    id: 213,
    code: "TZ",
    name: "Tanzania",
    calling_code: 255,
    currency_code: null,
    alpha3_code: "TZA"
  },
  {
    id: 215,
    code: "TG",
    name: "Togo",
    calling_code: 228,
    currency_code: null,
    alpha3_code: "TGO"
  },
  {
    id: 216,
    code: "TK",
    name: "Tokelau",
    calling_code: 690,
    currency_code: null,
    alpha3_code: "TKL"
  },
  {
    id: 217,
    code: "TO",
    name: "Tonga",
    calling_code: 676,
    currency_code: null,
    alpha3_code: "TON"
  },
  {
    id: 226,
    code: "UA",
    name: "Ukraine",
    calling_code: 380,
    currency_code: null,
    alpha3_code: "UKR"
  },
  {
    id: 227,
    code: "AE",
    name: "United Arab Emirates",
    calling_code: 971,
    currency_code: "AED",
    alpha3_code: "ARE"
  },
  {
    id: 228,
    code: "GB",
    name: "United Kingdom",
    calling_code: 44,
    currency_code: "GBP",
    alpha3_code: "GBR"
  },
  {
    id: 230,
    code: "UY",
    name: "Uruguay",
    calling_code: 598,
    currency_code: null,
    alpha3_code: "URY"
  },
  {
    id: 231,
    code: "UZ",
    name: "Uzbekistan",
    calling_code: 998,
    currency_code: null,
    alpha3_code: "UZB"
  },
  {
    id: 232,
    code: "VU",
    name: "Vanuatu",
    calling_code: 678,
    currency_code: null,
    alpha3_code: "VUT"
  },
  {
    id: 233,
    code: "VA",
    name: "Vatican",
    calling_code: 379,
    currency_code: null,
    alpha3_code: "VAT"
  },
  {
    id: 136,
    code: "MX",
    name: "Mexico",
    calling_code: 52,
    currency_code: "MXN",
    alpha3_code: "MEX"
  },
  {
    id: 152,
    code: "NZ",
    name: "New Zealand",
    calling_code: 64,
    currency_code: "NZD",
    alpha3_code: "NZL"
  },
  {
    id: 170,
    code: "PL",
    name: "Poland",
    calling_code: 48,
    currency_code: "PLN",
    alpha3_code: "POL"
  },
  {
    id: 173,
    code: "QA",
    name: "Qatar",
    calling_code: 974,
    currency_code: "QAR",
    alpha3_code: "QAT"
  },
  {
    id: 189,
    code: "SA",
    name: "Saudi Arabia",
    calling_code: 966,
    currency_code: "SAR",
    alpha3_code: "SAU"
  },
  {
    id: 195,
    code: "SK",
    name: "Slovakia",
    calling_code: 421,
    currency_code: "EUR",
    alpha3_code: "SVK"
  },
  {
    id: 203,
    code: "LK",
    name: "Sri Lanka",
    calling_code: 94,
    currency_code: "LKR",
    alpha3_code: "LKA"
  },
  {
    id: 214,
    code: "TH",
    name: "Thailand",
    calling_code: 66,
    currency_code: "THB",
    alpha3_code: "THA"
  },
  {
    id: 229,
    code: "US",
    name: "United States",
    calling_code: 1,
    currency_code: "USD",
    alpha3_code: "USA"
  },
  {
    id: 16,
    code: "AU",
    name: "Australia",
    calling_code: 61,
    currency_code: "AUD",
    alpha3_code: "AUS"
  },
  {
    id: 17,
    code: "AT",
    name: "Austria",
    calling_code: 43,
    currency_code: "EUR",
    alpha3_code: "AUT"
  },
  {
    id: 28,
    code: "BT",
    name: "Bhutan",
    calling_code: 975,
    currency_code: "BTN",
    alpha3_code: "BTN"
  },
  {
    id: 29,
    code: "BO",
    name: "Bolivia",
    calling_code: 591,
    currency_code: "BOB",
    alpha3_code: "BOL"
  },
  {
    id: 33,
    code: "IO",
    name: "British Indian Ocean Territory",
    calling_code: 246,
    currency_code: "USD",
    alpha3_code: "IOT"
  },
  {
    id: 34,
    code: "VG",
    name: "British Virgin Islands",
    calling_code: 1,
    currency_code: "USD",
    alpha3_code: "VGB"
  },
  {
    id: 40,
    code: "CM",
    name: "Cameroon",
    calling_code: 237,
    currency_code: "XAF",
    alpha3_code: "CMR"
  },
  {
    id: 41,
    code: "CA",
    name: "Canada",
    calling_code: 1,
    currency_code: "CAD",
    alpha3_code: "CAN"
  },
  {
    id: 44,
    code: "CF",
    name: "Central African Republic",
    calling_code: 236,
    currency_code: "XAF",
    alpha3_code: "CAF"
  },
  {
    id: 45,
    code: "TD",
    name: "Chad",
    calling_code: 235,
    currency_code: "XAF",
    alpha3_code: "TCD"
  },
  {
    id: 48,
    code: "CX",
    name: "Christmas Island",
    calling_code: 61,
    currency_code: "AUD",
    alpha3_code: "CXR"
  },
  {
    id: 51,
    code: "KM",
    name: "Comoros",
    calling_code: 269,
    currency_code: "KMF",
    alpha3_code: "COM"
  },
  {
    id: 53,
    code: "CR",
    name: "Costa Rica",
    calling_code: 506,
    currency_code: "CRC",
    alpha3_code: "CRI"
  },
  {
    id: 60,
    code: "DK",
    name: "Denmark",
    calling_code: 45,
    currency_code: "DKK",
    alpha3_code: "DNK"
  },
  {
    id: 63,
    code: "DO",
    name: "Dominican Republic",
    calling_code: 1,
    currency_code: "DOP",
    alpha3_code: "DOM"
  },
  {
    id: 66,
    code: "EG",
    name: "Egypt",
    calling_code: 20,
    currency_code: "EGP",
    alpha3_code: "EGY"
  },
  {
    id: 68,
    code: "GQ",
    name: "Equatorial Guinea",
    calling_code: 240,
    currency_code: "XAF",
    alpha3_code: "GNQ"
  },
  {
    id: 69,
    code: "ER",
    name: "Eritrea",
    calling_code: 291,
    currency_code: "ERN",
    alpha3_code: "ERI"
  },
  {
    id: 70,
    code: "EE",
    name: "Estonia",
    calling_code: 372,
    currency_code: "EUR",
    alpha3_code: "EST"
  },
  {
    id: 71,
    code: "ET",
    name: "Ethiopia",
    calling_code: 251,
    currency_code: "ETB",
    alpha3_code: "ETH"
  },
  {
    id: 72,
    code: "FK",
    name: "Falkland Islands",
    calling_code: 500,
    currency_code: "FKP",
    alpha3_code: "FLK"
  },
  {
    id: 73,
    code: "FO",
    name: "Faroe Islands",
    calling_code: 298,
    currency_code: null,
    alpha3_code: "FRO"
  },
  {
    id: 74,
    code: "FJ",
    name: "Fiji",
    calling_code: 679,
    currency_code: "FJD",
    alpha3_code: "FJI"
  },
  {
    id: 75,
    code: "FI",
    name: "Finland",
    calling_code: 358,
    currency_code: "EUR",
    alpha3_code: "FIN"
  },
  {
    id: 77,
    code: "PF",
    name: "French Polynesia",
    calling_code: 689,
    currency_code: "XPF",
    alpha3_code: "PYF"
  },
  {
    id: 78,
    code: "GA",
    name: "Gabon",
    calling_code: 241,
    currency_code: null,
    alpha3_code: "GAB"
  },
  {
    id: 79,
    code: "GM",
    name: "Gambia",
    calling_code: 220,
    currency_code: null,
    alpha3_code: "GMB"
  },
  {
    id: 80,
    code: "GE",
    name: "Georgia",
    calling_code: 995,
    currency_code: null,
    alpha3_code: "GEO"
  },
  {
    id: 81,
    code: "DE",
    name: "Germany",
    calling_code: 49,
    currency_code: "EUR",
    alpha3_code: "DEU"
  },
  {
    id: 82,
    code: "GH",
    name: "Ghana",
    calling_code: 233,
    currency_code: null,
    alpha3_code: "GHA"
  },
  {
    id: 83,
    code: "GI",
    name: "Gibraltar",
    calling_code: 350,
    currency_code: null,
    alpha3_code: "GIB"
  },
  {
    id: 84,
    code: "GR",
    name: "Greece",
    calling_code: 30,
    currency_code: "EUR",
    alpha3_code: "GRC"
  },
  {
    id: 85,
    code: "GL",
    name: "Greenland",
    calling_code: 299,
    currency_code: null,
    alpha3_code: "GRL"
  },
  {
    id: 86,
    code: "GD",
    name: "Grenada",
    calling_code: 1,
    currency_code: null,
    alpha3_code: "GRD"
  },
  {
    id: 87,
    code: "GU",
    name: "Guam",
    calling_code: 1,
    currency_code: null,
    alpha3_code: "GUM"
  },
  {
    id: 95,
    code: "HU",
    name: "Hungary",
    calling_code: 36,
    currency_code: "HUF",
    alpha3_code: "HUN"
  },
  {
    id: 97,
    code: "IN",
    name: "India",
    calling_code: 91,
    currency_code: "INR",
    alpha3_code: "IND"
  },
  {
    id: 101,
    code: "IE",
    name: "Ireland",
    calling_code: 353,
    currency_code: "EUR",
    alpha3_code: "IRL"
  },
  {
    id: 104,
    code: "IT",
    name: "Italy",
    calling_code: 39,
    currency_code: "EUR",
    alpha3_code: "ITA"
  },
  {
    id: 117,
    code: "LV",
    name: "Latvia",
    calling_code: 371,
    currency_code: "EUR",
    alpha3_code: "LVA"
  },
  {
    id: 123,
    code: "LT",
    name: "Lithuania",
    calling_code: 370,
    currency_code: "EUR",
    alpha3_code: "LTU"
  }
];
export default t;
