import React from "react";
import styles from "./FormGroup.module.scss";
import classNames from "classnames";

const FormGroup = (props: any) => {
  const { children, title = "", className } = props;

  return (
    <div className={classNames(styles.wrapper, className)}>
      <div className={styles.formGroupTitle}>{title}</div>
      <div>{children}</div>
    </div>
  );
};

export default FormGroup;
