import React, { useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import _get from "lodash-es/get";
import Flex from "antd-mobile/es/flex";
import Form from "src/bepaid/components/Form";
import Typography from "antd/es/typography";

import { RESET_PASSWORD_FORM } from "src/ipm-shared/store/model/Auth/const";
import FormErrors from "src/ipm-shared/components/Form/helpers/Errors";
import T from "src/ipm-shared/Utils/Intl";

import ROUTES from "src/bepaid/routes";
import InputText from "src/bepaid/components/Form/controls/InputText";
import Button from "src/bepaid/components/Form/controls/Button";
import Image from "src/bepaid/components/Image";
import Card from "src/bepaid/components/Card";
import GuestLayout from "src/bepaid/layouts/GuestLayout";

import HeaderLogo from "src/bepaid/assets/images/desktop/header_ipaymy_logo.svg";

import styles from "./ResetPassword.module.scss";

interface IProps {
  email: string;
}
const initialValues = { email: "" };

const FormComponent = (props: any) => {
  const { email, onFinish, onChangeEmail, loading } = props;

  return (
    <Form
      name="reset-password-form"
      layout="vertical"
      initialValues={initialValues}
      requiredMark={false}
      onFinish={onFinish.bind(null, { email })}
      className={styles.form}
    >
      <InputText
        form={RESET_PASSWORD_FORM}
        value={email}
        label="Email"
        name="email"
        placeholder="Email"
        className={styles.itemEmail}
        onChange={onChangeEmail}
      />
      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          {!loading ? "Send reset link" : "Sending reset link..."}
        </Button>
      </Form.Item>
    </Form>
  );
};

const ResetPassword: React.FC<IProps> = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");

  const handleChangeEmail = (e: any) => {
    setEmail(e.target.value);
    props.setFormErrors([], RESET_PASSWORD_FORM);
  };

  const onFinish = (values: any) => {
    const formattedEmail = _get(values, "email", "")
      .toLowerCase()
      .trim();

    setEmail(formattedEmail);
    props.setControl({
      value: formattedEmail,
      form: RESET_PASSWORD_FORM,
      name: "email"
    });

    props.requestResetPasswordSubmit({
      redirectUrl: `${ROUTES.PASSWORD_RESET}?email=${formattedEmail}`,
      // tslint:disable-next-line:object-literal-sort-keys
      cb: () => {
        //
      },
      loadingFunc: setLoading
    });
  };

  const renderContent = () => {
    return (
      <div className={styles.wrapper}>
        <Flex justify="center" align="center">
          <Flex.Item span={20} className={styles.centerText}>
            <Typography.Text className={styles.signInText}>
              {T.transl("PASSWORD_RESET_INSTRUCTIONS")}
            </Typography.Text>
          </Flex.Item>
        </Flex>
        <Flex justify="center" align="center" className={styles.extraSection}>
          <Flex.Item span={24}>
            <FormErrors
              form={RESET_PASSWORD_FORM}
              ignoreErrors={["INVALID_REQUEST"]}
              exception={{
                INVALID_REQUEST: {
                  putToField: "email",
                  code: "UNAVAILABLE_EMAIL_USED",
                  message: "UNAVAILABLE_EMAIL_USED"
                }
              }}
            />
            <FormComponent
              {...props}
              email={email}
              onChangeEmail={handleChangeEmail}
              onFinish={onFinish}
              loading={loading}
            />
          </Flex.Item>
        </Flex>
        <Flex justify="center" align="center" className={styles.extraSection}>
          <Flex.Item span={24} className={styles.centerText}>
            <Typography.Text className={styles.signInText}>
              Remembered your password?{" "}
              <Typography.Link
                className={styles.strongLink}
                href={ROUTES.LOGIN}
              >
                Sign in
              </Typography.Link>
              .
            </Typography.Text>
          </Flex.Item>
        </Flex>
      </div>
    );
  };

  const renderDesktopView = () => {
    return (
      <div className={styles.desktopWrapper}>
        <Card>{renderContent()}</Card>
      </div>
    );
  };

  return (
    <GuestLayout
      header={<Image src={HeaderLogo} />}
      title={
        <Typography.Text className={styles.title}>
          Reset password
        </Typography.Text>
      }
    >
      <BrowserView>{renderDesktopView()}</BrowserView>
      <MobileView>{renderContent()}</MobileView>
    </GuestLayout>
  );
};

export default ResetPassword;
