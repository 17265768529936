import React from "react";
import classNames from "classnames";
import { isDesktop } from "react-device-detect";

import { FetchModalID } from "src/ipm-shared/components/GlobalUI/actions";

import Image from "src/bepaid/components/Image";
import Button from "src/bepaid/components/Form/controls/Button";
import IconChangingYourMind from "src/bepaid/assets/images/invoices/changing_your_mind.svg";

import styles from "./ChangeOptionInvoiceModal.module.scss";

function ChangeModalInvoiceModal(props: any) {
  const {
    callBackChange,
    currentMode,
    onClose,
    onSuccess,
    nextMode
  } = props.extraInfo;

  const [loading, isLoading] = React.useState(false);

  const onSubmit = () => {
    isLoading(true);
    callBackChange(() => {
      isLoading(false);
      if (onSuccess) {
        onSuccess();
      } else {
        props.toggleModal(FetchModalID.CHANGE_OPTION_INVOICE_MODAL);
      }
    });
  };

  const onRequest = () => {
    if (onClose) {
      onClose();
    } else {
      props.toggleModal(FetchModalID.CHANGE_OPTION_INVOICE_MODAL);
    }
  };

  const getDescriptionText = () => {
    if (nextMode === "upload") {
      return "Are you sure you want to upload an invoice instead? You’ll need to re-enter any information you’ve entered if you change your mind later.";
    }
    if (nextMode === "create") {
      return "Are you sure you want to use the invoice template instead? You’ll need to re-enter any information you’ve entered if you change your mind later.";
    }
    if (nextMode === "quick") {
      return "Are you sure you want to use the quick invoice instead? You’ll need to re-enter any information you’ve entered if you change your mind later.";
    }
    return "";
    // return nextMode === "upload"
    //   ? "Are you sure you want to use the invoice template instead? You’ll need to re-upload the invoice if you change your mind later."
    //   : "Are you sure you want to upload an invoice instead? You’ll need to re-enter any information you’ve entered if you change your mind later.";
  };

  const getPrimaryText = () => {
    let text = "";
    let text2 = "";
    if (currentMode === "upload") {
      text = "my invoice";
    }
    if (currentMode === "create") {
      text = "using template";
    }
    if (currentMode === "quick") {
      text = "my quick invoice";
    }
    if (nextMode === "upload") {
      text2 = "upload invoice";
    }
    if (nextMode === "create") {
      text2 = "use template";
    }
    if (nextMode === "quick") {
      text2 = "use quick invoice";
    }
    return !loading ? `No, keep ${text}` : `Proceeding to ${text2}…`;
  };

  const getSecondaryText = () => {
    let text = "";
    if (nextMode === "upload") {
      text = "upload invoice";
    }
    if (nextMode === "create") {
      text = "use template";
    }
    if (nextMode === "quick") {
      text = "use quick invoice";
    }
    return `Yes, proceed to ${text}`;
  };

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.wrapperDesktop]: isDesktop
      })}
    >
      <div>
        <div className={styles.invoiceSentLogo}>
          <Image src={IconChangingYourMind} />
          <p className={styles.title}>Changing your mind?</p>
        </div>

        <div>
          <p className={styles.description}>{getDescriptionText()}</p>
        </div>
      </div>

      <div className={styles.groupBtn}>
        <Button type="primary" onClick={onRequest} loading={loading}>
          {getPrimaryText()}
        </Button>
        {!loading && (
          <Button
            type="secondary"
            onClick={onSubmit}
            className={styles.btnSecondary}
          >
            {getSecondaryText()}
          </Button>
        )}
      </div>
    </div>
  );
}

export default ChangeModalInvoiceModal;
