import * as Images from "src/ipm-shared/components/Images";

export const enum BRAND {
  UNKNOWN = 1,
  VISA,
  MASTER_CARD,
  AMEX,
  UNION_PAY
}

export const enum CRYPTO {
  BTC = 5,
  BTC_LIGHTNING,
  ETHEREUM,
  USDC,
  USDT,
  BINANCE,
  TRC_USDT,
  TRC_USDC
}

export const ALL_BRAND = `${BRAND.VISA},${BRAND.MASTER_CARD},${BRAND.UNION_PAY},${BRAND.AMEX}`;
export const ALL_CRYPTO = `${CRYPTO.BTC},${CRYPTO.BTC_LIGHTNING},${CRYPTO.ETHEREUM},${CRYPTO.USDC},${CRYPTO.USDT}`;

export const enum BANK {
  OCBC = 1,
  Citibank,
  DBS,
  ANZ,
  HSBC_Bank_Singapore_Ltd,
  Bank_of_China,
  UOB,
  Maybank,
  ICICI_Bank,
  Standard_Chartered_Bank,
  State_Bank_of_India,
  CIMB,
  RHB,
  Philippine_National_Bank,
  Indian_Overseas_Bank,
  Far_Eastern_Bank,
  Mizuho_Bank,
  The_Bank_of_East_Asia,
  POSB,
  Deutsche_Bank,
  Bank_of_America,
  HSBC_Corporation_Ltd
}
export default {
  getCardTypeIcon(brandId: number | string) {
    if (typeof brandId === "string") {
      brandId = parseInt(brandId, 10);
    }

    switch (brandId) {
      case BRAND.MASTER_CARD:
        return Images.mastercard_border;

      case BRAND.AMEX:
        return Images.amex_color;

      case BRAND.VISA:
        return Images.visa_border;

      case BRAND.UNION_PAY:
        return Images.unionpay_border;

      case BRAND.UNKNOWN:
      default:
        return Images.card_icon;
    }
  },

  getCryptoTypeIcon(cryptoId: number | string | undefined) {
    if (typeof cryptoId === "string") {
      cryptoId = parseInt(cryptoId, 10);
    }

    switch (cryptoId) {
      case CRYPTO.BTC:
        return Images.btc_logo;
      case CRYPTO.BTC_LIGHTNING:
        return Images.btc_lightning_logo;
      case CRYPTO.ETHEREUM:
        return Images.ethereum_logo;
      case CRYPTO.USDC:
      case CRYPTO.TRC_USDC:
        return Images.usdcoin_logo;
      case CRYPTO.USDT:
      case CRYPTO.TRC_USDT:
        return Images.usdtether_logo;
      case CRYPTO.BINANCE:
        return Images.binance_logo;
      default:
        return Images.btc_logo;
    }
  },

  getCardTypeInlineIcon(brandId: number | string) {
    if (typeof brandId === "string") {
      brandId = parseInt(brandId, 10);
    }

    switch (brandId) {
      case BRAND.MASTER_CARD:
        return Images.mastercard_sm;

      case BRAND.AMEX:
        return Images.amex_logo;

      case BRAND.VISA:
        return Images.visa_sm;

      case BRAND.UNION_PAY:
        return Images.unionpay_sm;

      case BRAND.UNKNOWN:
      default:
        return Images.card_icon;
    }
  },

  getCardBankIcon(bankId: number | string) {
    if (typeof bankId === "string") {
      bankId = parseInt(bankId, 10);
    }

    switch (bankId) {
      case BANK.Citibank:
        return Images.citi_bank;

      case BANK.DBS:
        return Images.dbs_bank;

      default:
        return Images.logo;
    }
  }
};
