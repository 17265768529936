/**
 * Sagas
 *
 * All side effects must come here.
 * - calls to browser apis - localStorage, window.XXX, fetch, etc.
 */
import { call, put, select } from "redux-saga/effects";
import _get from "lodash-es/get";
import { ActionType } from "typesafe-actions";
import * as uenActions from "./actions";
import {
  catchTakeLatest,
  reTryTakeLatest
} from "src/ipm-shared/Utils/ReduxSagaEffects";
import * as formSelectors from "src/ipm-shared/components/Form/selectors";
import * as formActions from "src/ipm-shared/components/Form/actions";
import { RootState } from "../reducers";
import { SEARCH_FORM, ADD_FORM } from "./const";
import RestClient from "src/ipm-shared/services/Rest";
import HttpRequestError from "src/ipm-shared/Utils/Exceptions/HttpRequestError";
import _isEmpty from "lodash-es/isEmpty";
import * as commonSelectors from "../selectors";

const actions = {
  ...uenActions,
  ...formActions
};
const selectors = {
  ...commonSelectors,
  ...formSelectors
};
const watchedSagas = [
  reTryTakeLatest(actions.searchUenList, handleSearchUEN),
  catchTakeLatest(actions.addUen, handleAddUen),
  catchTakeLatest(actions.editUen, handleEditUen),
  catchTakeLatest(actions.removeUen, handleRemoveUen)
];
export default watchedSagas;

export function* handleSearchUEN(
  action: ActionType<typeof actions.searchUenList>
) {
  const state: RootState = yield select();
  yield put(
    actions.setUenList({
      isFetching: true,
      total: 0,
      uenList: []
    })
  );
  const { offset, pageCount } = action.payload;
  const query = formSelectors.getControlsAsObject(state, SEARCH_FORM) as any;
  const queryCountry = formSelectors.getControl(
    state,
    "switch_control_country_id"
  ) as any;
  const countryId = _get(queryCountry, "value", 1);
  query.country_id = countryId;
  query.offset = offset;
  query.page_count = pageCount;
  const res: Response = yield call(RestClient.send, {
    query,
    service: "admin_search_uen"
  });

  if (!res) {
    yield put(
      actions.setUenList({
        isFetching: false,
        total: 0,
        uenList: []
      })
    );
    throw new HttpRequestError("Failed to fetch");
  }

  try {
    const data: any[] = _get(res, "data", []);
    const total: number = _get(res, "total");

    yield put(
      actions.setUenList({
        isFetching: false,
        total,
        uenList: data.map(uen => ({
          countryID: uen.country_id,
          multipleAccountAllowed: uen.multiple_account_allowed,
          name: uen.name,
          regno: uen.regno
        }))
      })
    );
  } catch (e) {
    window.Logger.error("handleFetchPromoCode: ", e.message);
    return;
  }
}

export function* handleAddUen(action: ActionType<typeof actions.addUen>) {
  const state: RootState = yield select();
  const formState = selectors.getControls(state, ADD_FORM);
  const queryCountry = formSelectors.getControl(
    state,
    "switch_control_country_id"
  ) as any;
  const countryId = _get(queryCountry, "value", 1);
  const regno = _get(formState, "uen_regno.value", "");
  const name = _get(formState, "uen_name.value", 1);
  const forceUpdate =
    _get(formState, "uen_force_update.value", "") === "" ? false : true;
  const multipleAccountAllowed =
    _get(formState, "uen_multiple_account_allowed.value", "") === ""
      ? false
      : true;
  const res: Response = yield call(RestClient.send, {
    body: {
      company_name: name,
      country_id: parseInt(countryId, 10),
      force_update: forceUpdate,
      is_production: false,
      multiple_account_allowed: multipleAccountAllowed,
      regno
    },
    service: "admin_add_uen",
    showGlobalLoader: true,
    timeout: -1
  });
  if (!res) {
    return;
  }

  const errors = _get(res, "errors", {});

  if (!_isEmpty(errors)) {
    yield put(formActions.parseServerErrors(errors, ADD_FORM));
    return false;
  }

  yield put(actions.closeModal(actions.ModalID.UEN_MODAL));
  yield put(actions.searchUenList());
  return;
}

export function* handleEditUen(action: ActionType<typeof actions.editUen>) {
  const regno = action.payload.regno;
  const state: RootState = yield select();
  const formState = selectors.getControls(state, ADD_FORM);
  const queryCountry = formSelectors.getControl(
    state,
    "switch_control_country_id"
  ) as any;
  const countryId = _get(queryCountry, "value", 1);

  const name = _get(formState, "uen_name.value", 1);
  const multipleAccountAllowed =
    _get(formState, "uen_multiple_account_allowed.value", "") === ""
      ? false
      : true;
  const res: Response = yield call(RestClient.send, {
    body: {
      company_name: name,
      country_id: parseInt(countryId, 10),
      force_update: true,
      is_production: false,
      multiple_account_allowed: multipleAccountAllowed,
      regno
    },
    service: "admin_add_uen",
    showGlobalLoader: true,
    timeout: -1
  });

  if (!res) {
    return;
  }

  const errors = _get(res, "errors", {});

  if (!_isEmpty(errors)) {
    yield put(formActions.parseServerErrors(errors, ADD_FORM));
    return false;
  }

  yield put(actions.closeModal(actions.ModalID.UEN_MODAL));
  yield put(actions.searchUenList());
  return;
}

export function* handleRemoveUen(action: ActionType<typeof actions.removeUen>) {
  const regno = action.payload.regno;
  const res: Response = yield call(RestClient.send, {
    params: {
      regno
    },
    service: "admin_remove_uen",
    showGlobalLoader: true,
    timeout: -1
  });

  if (!res) {
    return;
  }

  const errors = _get(res, "errors", {});

  if (!_isEmpty(errors)) {
    yield put(formActions.parseServerErrors(errors, ADD_FORM));
    return false;
  }

  yield put(actions.closeModal(actions.ModalID.UEN_MODAL));
  yield put(actions.searchUenList());
  return;
}
