/* tslint:disable:object-literal-sort-keys jsx-no-lambda */
import React from "react";
// import { Redirect } from "react-router-dom";
import {
  BrowserView,
  MobileView
  // isDesktop,
  // isMobile
} from "react-device-detect";
import classNames from "classnames";
import * as copy from "copy-to-clipboard";
import _get from "lodash-es/get";
import Flex from "antd-mobile/es/flex";
import Typography from "antd/es/typography";
import Input from "antd/es/input";

import { history } from "src/ipm-shared/store";
import Tooltip from "src/ipm-shared/components/Tooltip";
import T from "src/ipm-shared/Utils/Intl";
import { ACCOUNT_TYPE } from "src/ipm-shared/store/model/AccountProfile/const";
import ShareSocial, {
  SOCIAL_TYPES
} from "src/ipm-shared/components/ShareSocial";

import ROUTES from "src/bepaid/routes";
import Card from "src/bepaid/components/Card";
import Skeleton from "src/bepaid/components/Skeleton";
import Image from "src/bepaid/components/Image";
import Button from "src/bepaid/components/Form/controls/Button";
import InputText from "src/bepaid/components/Form/controls/InputText";
import Collapse from "src/bepaid/components/Collapse";
import MainLayout from "src/bepaid/layouts/MainLayout";
import ProfileHeading from "src/bepaid/layouts/MainLayout/components/ProfileHeading";

import Arrow from "src/bepaid/assets/images/referral/arrow.svg";
import JoinIcon from "src/bepaid/assets/images/referral/join_now.svg";
import ShareIcon from "src/bepaid/assets/images/referral/share_your_link.svg";
import EnjoyIcon from "src/bepaid/assets/images/referral/enjoy_the_rewards.svg";
import FbIcon from "src/bepaid/assets/images/referral/facebook.svg";
import TwitterIcon from "src/bepaid/assets/images/referral/twitter.svg";
import WhatsappIcon from "src/bepaid/assets/images/referral/whatsapp.svg";
import MessIcon from "src/bepaid/assets/images/referral/messenger.svg";
import MailIcon from "src/bepaid/assets/images/referral/gmail.svg";
import IconCopyLink from "src/ipm-shared/images/icon-link.svg";
import IconArrowDown from "src/ipm-shared/images/referral-program/arrow-down.svg";
import IconArrowUp from "src/ipm-shared/images/referral-program/arrow-up.svg";

import styles from "./Referral.module.scss";

const Referral: React.FC<any> = props => {
  const { joinReferral, accountCountryCode, getReferralReport } = props;
  const [isJoined, setJoined] = React.useState(false);
  const [referralLink, setReferralLink] = React.useState("");
  const [referralReport, setReferralReport] = React.useState({
    signUpRefs: 0,
    businessRefs: 0,
    personalRefs: 0
  });
  React.useEffect(() => {
    getReferralReport((result: any) => {
      if (result) {
        setReferralReport({
          businessRefs: result.numberBusinessReferrals || 0,
          personalRefs: result.numberPersonalReferrals || 0,
          signUpRefs: result.numberSignUps || 0
        });
        setReferralLink(result.referralLink || "");
        setJoined(result.joined);
      }
    });
  }, []);

  const getReferralAmount = (
    accountType: string = ACCOUNT_TYPE.PERSONAL
  ): string => {
    const data = {
      [ACCOUNT_TYPE.BUSINESS]: {
        AU: "A$200",
        HK: "HK$1,200",
        MY: "RM600",
        SG: "S$200"
      },
      [ACCOUNT_TYPE.PERSONAL]: {
        AU: "A$30",
        HK: "HK$250",
        MY: "RM90",
        SG: "S$30"
      }
    };

    return data[accountType][accountCountryCode] || "$200";
  };

  const renderContentRight = () => {
    const {
      signUpRefs = 0,
      personalRefs = 0,
      businessRefs = 0
    } = referralReport;
    const result = [
      {
        key: 1,
        label: T.transl("REFERRAL_SIGNUPS_LABEL"),
        tooltipText: T.transl("REFERRAL_SIGNUPS_TOOLTIP"),
        value: signUpRefs
      },
      {
        key: 2,
        label: T.transl("REFERRAL_BUSINESS_LABEL"),
        tooltipText: T.transl("REFERRAL_BUSINESS_TOOLTIP"),
        value: businessRefs
      },
      {
        key: 3,
        label: T.transl("REFERRAL_PERSONAL_LABEL"),
        tooltipText: T.transl("REFERRAL_PERSONAL_TOOLTIP"),
        value: personalRefs
      }
    ];

    return (
      <div className={styles.content}>
        {result.map((item: any, index: number) => (
          <Card
            key={index}
            className={classNames(styles.card, { [styles.first]: index === 0 })}
          >
            <Skeleton
              loading={false}
              paragraph={{
                rows: 1
              }}
            >
              <Flex justify="center" align="stretch" direction={"column"}>
                <Flex.Item span={24} className={styles.title}>
                  <Typography.Text>{item.label}</Typography.Text>
                  <Tooltip
                    description={item.tooltipText}
                    displayIcon={true}
                    iconColor="gray"
                    target={`tooltip-${item.key}`}
                    className={classNames(styles.feeTooltip)}
                  />
                </Flex.Item>
                <Flex.Item span={24} className={styles.amount}>
                  <Typography.Text>{item.value}</Typography.Text>
                </Flex.Item>
              </Flex>
            </Skeleton>
          </Card>
        ))}
      </div>
    );
  };

  const renderTitles = () => {
    const {
      signUpRefs = 0,
      personalRefs = 0,
      businessRefs = 0
    } = referralReport;

    const [isCopied, setCopyState] = React.useState(false);
    const [isLoading, setLoading] = React.useState(false);

    const stepArray = [
      {
        key: 1,
        title: T.transl("REFERRAL_JOIN_STEP"),
        isActivated: isJoined,
        src: JoinIcon
      },
      {
        key: 2,
        title: T.transl("REFERRAL_SHARE_STEP"),
        isActivated: signUpRefs || personalRefs || businessRefs,
        src: ShareIcon
      },
      {
        key: 3,
        title: T.transl("REFERRAL_ENJOY_STEP"),
        isActivated: false,
        src: EnjoyIcon
      }
    ];
    const shareIcons = [
      { key: 1, src: FbIcon, type: SOCIAL_TYPES.FACEBOOK },
      { key: 2, src: TwitterIcon, type: SOCIAL_TYPES.TWITTER },
      { key: 3, src: WhatsappIcon, type: SOCIAL_TYPES.WHATSAPP },
      { key: 4, src: MessIcon, type: SOCIAL_TYPES.FACEBOOK_MESSENGER },
      { key: 5, src: MailIcon, type: SOCIAL_TYPES.EMAIL }
    ];

    const handleClickNextStep = () => {
      setLoading(true);
      if (isJoined) {
        handleCopy();
      } else {
        onJoinReferral();
      }
    };

    const handleCopy = () => {
      setCopyState(!isCopied);
      copy(referralLink);
      setTimeout(() => {
        setCopyState(!isCopied);
      }, 1000);
    };

    const onJoinReferral = (): void => {
      joinReferral((link: string) => {
        if (link) {
          setReferralLink(link);
          setJoined(true);
        }
      });
      setLoading(false);
    };

    const buttonLabels = () => {
      const buttons = [
        { key: 1, label: T.transl("REFERRAL_JOIN_STEP") },
        { key: 2, label: T.transl("ACTION_COPY_LINK") }
      ];
      const labelLoading = (label: string) => {
        return isLoading ? `${label}...` : label;
      };
      return !isJoined
        ? labelLoading(buttons[0].label)
        : isLoading
        ? "Link copied"
        : buttons[1].label;
    };

    const referralAmount = {
      strong: () => <strong>{getReferralAmount(ACCOUNT_TYPE.BUSINESS)}</strong>
    };

    return (
      <Flex justify="between" direction={"column"} className={styles.titles}>
        <Flex.Item span={24}>
          <Typography.Text className={styles.title}>
            {T.transl("REFERRAL_HERO", referralAmount)}
          </Typography.Text>
          <br />
          <div className={styles.subtitleBox}>
            <Typography.Text>
              {T.transl("REFERRAL_INSTRUCTIONS", referralAmount)}
            </Typography.Text>
          </div>
        </Flex.Item>
        <Flex.Item span={24}>
          <Flex
            justify="center"
            align="center"
            direction={"row"}
            className={styles.referralImgs}
          >
            {stepArray.map(step => {
              return (
                <>
                  {step.key !== 1 && (
                    <Flex.Item className={styles.arrowText} span={24}>
                      <Image style={{ height: "40px" }} src={Arrow} />
                    </Flex.Item>
                  )}
                  <Flex.Item
                    className={classNames(styles.referralImg, {
                      [styles.stepImg]: !!step.isActivated
                    })}
                    span={24}
                  >
                    <Image className={styles.image} src={step.src} />
                    <br />
                    <div className={styles.titleStep}>
                      <Typography.Text strong={true}>
                        {step.title}
                      </Typography.Text>
                    </div>
                  </Flex.Item>
                </>
              );
            })}
          </Flex>
        </Flex.Item>

        <Flex direction={"column"}>
          <Flex.Item
            className={classNames(styles.btn, {
              [styles.inputGroup]: isJoined
            })}
            span={24}
          >
            {isJoined && (
              <Typography.Text>
                {T.transl("REFERRAL_COPY_LINK")}
              </Typography.Text>
            )}
            <Input.Group className={styles.copyLink} compact={true}>
              {isJoined && (
                <InputText
                  prefix={<Image src={IconCopyLink} onClick={handleCopy} />}
                  name="copy_link"
                  value={referralLink}
                  inputClassName={styles.inputCopy}
                />
              )}
              <Button
                type="primary"
                htmlType="button"
                loading={isLoading}
                onClick={handleClickNextStep}
              >
                {buttonLabels()}
              </Button>
            </Input.Group>
          </Flex.Item>

          {isJoined && (
            <Flex.Item span={24}>
              <Flex
                className={styles.shareIcons}
                justify="center"
                align="stretch"
                direction={"row"}
              >
                {shareIcons.map(step => {
                  return (
                    <Flex.Item key={step.key} span={24}>
                      <ShareSocial
                        type={step.type}
                        url={referralLink}
                        icon={
                          <Image className={styles.shareIcon} src={step.src} />
                        }
                      />
                    </Flex.Item>
                  );
                })}
              </Flex>
            </Flex.Item>
          )}
        </Flex>
      </Flex>
    );
  };

  const renderTCS = () => {
    const [isShowTCs, setShowTCs] = React.useState(false);

    const collapseLabel = (
      <Typography.Text>*{T.transl("REFERRAL_TCS_LABEL")}</Typography.Text>
    );
    const IconArrow = isShowTCs ? IconArrowUp : IconArrowDown;

    return (
      <Flex
        className={styles.descriptions}
        align="stretch"
        direction={"column"}
      >
        <Typography.Text>
          *
          {T.transl("REFERRAL_PERSONAL_NOTE", {
            render: getReferralAmount(ACCOUNT_TYPE.PERSONAL)
          })}
        </Typography.Text>
        <Typography.Text>
          *
          {T.transl("REFERRAL_BUSINESS_NOTE", {
            render: getReferralAmount(ACCOUNT_TYPE.BUSINESS)
          })}
        </Typography.Text>
        <br />
        <br />

        <Collapse
          title={collapseLabel}
          type={"text"}
          isOpen={isShowTCs}
          rightIcon={
            <Image onClick={() => setShowTCs(!isShowTCs)} src={IconArrow} />
          }
          onChangeOpen={setShowTCs}
        >
          <Typography.Text className={styles.conditionText}>
            <span>• {T.transl("REFERRAL_TCS_1")}</span>
            <br />
            <span>
              •{" "}
              {T.transl("REFERRAL_TCS_2", {
                // @ts-ignore
                a: text => (
                  <a
                    href="https://help.ipaymy.com/en/articles/2515162-what-can-i-pay-with-ipaymy"
                    target="_blank"
                  >
                    {text}
                  </a>
                )
              })}
            </span>
            <br />
            <span>• {T.transl("REFERRAL_TCS_3")}</span>
            <br />
            <span>• {T.transl("REFERRAL_TCS_4")}</span>
          </Typography.Text>
          <br />
          <Typography.Text>
            {T.transl("REFERRAL_TCS_VIEW", {
              a: (text: string) => (
                <a
                  href="https://www.ipaymy.com/terms-conditions"
                  target="_blank"
                >
                  <u>{text}</u>
                </a>
              )
            })}
          </Typography.Text>
        </Collapse>
      </Flex>
    );
  };

  const renderContentLeft = () => {
    return (
      <Flex
        justify="between"
        direction={"column"}
        className={styles.bodyLeftContent}
      >
        {renderTitles()}
        {renderTCS()}
      </Flex>
    );
  };

  const renderDesktopView = () => {
    return (
      <div className={classNames(styles.wrapper, styles.desktop)}>
        <MainLayout
          header={{
            leftHeader: <ProfileHeading />
          }}
          body={{
            leftBody: renderContentLeft(),
            rightBody: renderContentRight()
          }}
        />
      </div>
    );
  };

  const renderMobileView = () => {
    return (
      <MainLayout
        header={{
          title: "Referrals",
          onBack: () => history.push(ROUTES.ROOT)
        }}
      >
        <div className={styles.wrapper}>
          {renderTitles()}
          {renderContentRight()}
          {renderTCS()}
        </div>
      </MainLayout>
    );
  };

  return (
    <>
      <BrowserView>{renderDesktopView()}</BrowserView>
      <MobileView>{renderMobileView()}</MobileView>
    </>
  );
};

export default Referral;
