// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ant-form-small .btn-select-date{height:39px !important}.CalendarInput_wrapper__3gEjV{width:100%;margin-bottom:1.5rem}.CalendarInput_wrapper__3gEjV>button{width:100%;background-color:#fff;border:1px solid #d6d6d6;outline:none;padding-left:.5rem;margin:0;height:47px;position:relative;color:#74747b;border-radius:5px}.CalendarInput_wrapper__3gEjV>p{color:#74747b;margin-bottom:4px}.CalendarInput_wrapper__3gEjV .CalendarInput_icon__mAMI5{position:absolute;left:10px;top:15px}.CalendarInput_wrapper__3gEjV .CalendarInput_placeHolderText__3u6Yv{color:#c7c7c7;font-size:.875rem}", "",{"version":3,"sources":["webpack://./src/bepaid/components/Calendar/components/CalendarInput.module.scss"],"names":[],"mappings":"AAAA,iCAAyC,sBAAsB,CAAC,8BAAS,UAAU,CAAC,oBAAoB,CAAC,qCAAgB,UAAU,CAAC,qBAAqB,CAAC,wBAAwB,CAAC,YAAY,CAAC,kBAAkB,CAAC,QAAQ,CAAC,WAAW,CAAC,iBAAiB,CAAC,aAAa,CAAC,iBAAiB,CAAC,gCAAW,aAAa,CAAC,iBAAiB,CAAC,yDAAe,iBAAiB,CAAC,SAAS,CAAC,QAAQ,CAAC,oEAA0B,aAAa,CAAC,iBAAiB","sourcesContent":[":global .ant-form-small .btn-select-date{height:39px !important}.wrapper{width:100%;margin-bottom:1.5rem}.wrapper>button{width:100%;background-color:#fff;border:1px solid #d6d6d6;outline:none;padding-left:.5rem;margin:0;height:47px;position:relative;color:#74747b;border-radius:5px}.wrapper>p{color:#74747b;margin-bottom:4px}.wrapper .icon{position:absolute;left:10px;top:15px}.wrapper .placeHolderText{color:#c7c7c7;font-size:.875rem}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "CalendarInput_wrapper__3gEjV",
	"icon": "CalendarInput_icon__mAMI5",
	"placeHolderText": "CalendarInput_placeHolderText__3u6Yv"
};
export default ___CSS_LOADER_EXPORT___;
