import { RootState } from "../reducers";
// Forms
import { createSelector } from "reselect";
import _isUndefined from "lodash-es/isUndefined";
import { ValidationState } from "./reducers";

export const getFormState = createSelector(
  (state: RootState) => state.forms.currentForm,
  (state: RootState) => state.forms.byId,
  (currentForm, byId) => {
    if (!byId) {
      return {};
    }
    const form = byId[currentForm];
    if (_isUndefined(form)) {
      return {};
    }
    return form;
  }
);
export const getFormErrors = createSelector(
  (state: RootState) => state.forms.currentForm,
  (state: RootState) => state.forms.byId,
  (currentForm, byId) => {
    if (!byId) {
      return {};
    }
    const form = byId[currentForm];
    if (!form) {
      return {};
    }
    const errors = {};
    Object.keys(form).map(fieldName => {
      if (form[fieldName].validationState === ValidationState.INVALID) {
        errors[fieldName] = true;
      }
    });
    return errors;
  }
);
