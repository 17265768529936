import React from "react";
import paymentMethodScheduleStyles from "../../PaymentResultModal.module.scss";
import Image from "src/bepaid/components/Image";
import CurrencyUtil from "src/ipm-shared/Utils/Currency";

import IconPaymentSuccessful from "src/bepaid/assets/images/payments/payment_successful.svg";

import { history } from "src/ipm-shared/store";
import { useMakePaymentUrlParams } from "src/bepaid/pages/Payments/hooks";
import ROUTES from "src/bepaid/routes";
import Button from "src/bepaid/components/Form/controls/Button";
import BtnGoToDashBoard from "../BtnGoToDashBoard";

import _get from "lodash-es/get";

const View = (props: any) => {
  // const prevRoute = history?.location.state?.prevRoute;
  const checkoutUrl = String(localStorage.getItem("checkoutUrl"));

  const { paymentType, replaceRouteWithParams } = useMakePaymentUrlParams(
    ROUTES.PAYMENTS_CREATION,
    checkoutUrl
  );

  const isWallex = paymentType === "international";

  const getAuthorisedAmountText = () => {
    const currencyId =
      +_get(props, "query.mf_currency_id") ||
      +_get(props.extraInfo, "currencyId");

    const currency = CurrencyUtil.convertFromId(currencyId);

    const amount = CurrencyUtil.getAuthorisedAmount(currencyId);

    return !currency.$ ? "" : `${currency.$}${amount}`;
  };

  const makeAnotherPayment = () => {
    props.resetAllModals();
    history.push(
      replaceRouteWithParams(ROUTES.PAYMENTS_CREATION, {
        newEntity: isWallex ? "country" : "entity",
        newAction: "select"
      })
    );
  };

  return (
    <div className={paymentMethodScheduleStyles.smallerTexts}>
      <div className={paymentMethodScheduleStyles.text}>
        <Image width={200} src={IconPaymentSuccessful} />
        <h2 className={paymentMethodScheduleStyles.title}>
          Yay! Payment has been authorised.
        </h2>
        <p>
          You will receive a confirmation email and receipt for the full amount
          within the next 30 minutes.
        </p>
        <p>
          As part of the authorisation process, you may see a{" "}
          {getAuthorisedAmountText()} pending charge on your bank statement.
          Note that it is only temporary and will be automatically reversed.
        </p>
      </div>
      <div>
        <Button
          type="primary"
          htmlType="button"
          onClick={makeAnotherPayment}
          wrapperClassName={paymentMethodScheduleStyles.btnMakeAnotherPayment}
        >
          Make another payment
        </Button>
        <BtnGoToDashBoard />
      </div>
    </div>
  );
};

export default View;
