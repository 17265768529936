// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AddIncentives_wrapper__NpBcD{font-size:.875rem}.AddIncentives_wrapper__NpBcD .am-flexbox-item{display:-ms-flexbox;display:flex;padding-bottom:.5rem}.AddIncentives_wrapper__NpBcD .AddIncentives_textDetails__1codq{font-size:.75rem;margin-left:.5rem;font-weight:400;color:#526288}.AddIncentives_wrapper__NpBcD .AddIncentives_lastItem__2QWJ8{padding-bottom:0}.AddIncentives_wrapper__NpBcD .AddIncentives_collapsedText__2bwsk{color:#74747b;line-height:120%}.AddIncentives_wrapper__NpBcD .AddIncentives_helperText__DjEJj{font-size:.75rem !important;color:#624ce0 !important}", "",{"version":3,"sources":["webpack://./src/bepaid/shared/modals/InvoiceCreateModal/components/AddIncentives/AddIncentives.module.scss"],"names":[],"mappings":"AAAA,8BAAS,iBAAiB,CAAC,+CAAkC,mBAAY,CAAZ,YAAY,CAAC,oBAAoB,CAAC,gEAAsB,gBAAgB,CAAC,iBAAiB,CAAC,eAAe,CAAC,aAAa,CAAC,6DAAmB,gBAAgB,CAAC,kEAAwB,aAAa,CAAC,gBAAgB,CAAC,+DAAqB,2BAA2B,CAAC,wBAAwB","sourcesContent":[".wrapper{font-size:.875rem}.wrapper :global .am-flexbox-item{display:flex;padding-bottom:.5rem}.wrapper .textDetails{font-size:.75rem;margin-left:.5rem;font-weight:400;color:#526288}.wrapper .lastItem{padding-bottom:0}.wrapper .collapsedText{color:#74747b;line-height:120%}.wrapper .helperText{font-size:.75rem !important;color:#624ce0 !important}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "AddIncentives_wrapper__NpBcD",
	"textDetails": "AddIncentives_textDetails__1codq",
	"lastItem": "AddIncentives_lastItem__2QWJ8",
	"collapsedText": "AddIncentives_collapsedText__2bwsk",
	"helperText": "AddIncentives_helperText__DjEJj"
};
export default ___CSS_LOADER_EXPORT___;
