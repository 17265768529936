import { ActionType } from "typesafe-actions";
import { select, call, put } from "redux-saga/effects";
import { delay } from "redux-saga";
import { format } from "date-fns";
import _get from "lodash-es/get";
import _maxBy from "lodash-es/maxBy";
import _isEmpty from "lodash-es/isEmpty";

import * as formActions from "src/ipm-shared/components/Form/actions";
import * as accProfileActions from "src/ipm-shared/store/model/AccountProfile/actions";
import * as formSelectors from "src/ipm-shared/components/Form/selectors";
import * as paymentRequestSelectors from "src/ipm-shared/store/model/PaymentRequest/selectors";
import * as accountSelectors from "src/ipm-shared/store/model/AccountProfile/selectors";
import RestClient from "src/ipm-shared/services/Rest";
import {
  reTryTakeLatest,
  catchTakeLatest
} from "src/ipm-shared/Utils/ReduxSagaEffects";
import HttpRequestError from "src/ipm-shared/Utils/Exceptions/HttpRequestError";
import IPMContext from "src/ipm-shared/Utils/IPMContext";

import * as commonActions from "../actions";
import * as commonSelectors from "../selectors";
import { RootState } from "../reducers";
import * as accProfileSelectors from "../AccountProfile/selectors";
import * as cardSelectors from "./selectors";
import * as cardActions from "./actions";
import { ADD_CARD_FORM } from "./const";
import ScrollUtil from "src/ipm-shared/Utils/Scroll";

const actions = {
  ...cardActions,
  ...commonActions,
  ...accProfileActions,
  ...formActions
};

const selectors = {
  ...accProfileSelectors,
  ...cardSelectors,
  ...commonSelectors,
  ...formSelectors,
  ...paymentRequestSelectors,
  ...accountSelectors
};
const watchedSagas = [
  reTryTakeLatest(actions.fetchCards, handleFetchCards),
  // catchTakeLatest(actions.submitAdyenAddCardForm), handleAddAdyenCard),
  // catchTakeLatest(actions.submitAdyenEditCardForm), handleEditAdyenCard),
  // catchTakeLatest(actions.encryptCard), handleAdyenEncryptCardDetails),
  catchTakeLatest(actions.submitAddCardForm, handleAddCard),
  catchTakeLatest(
    actions.createUserStripeCardIntent,
    handleUserCreateStripeCardIntent
  ),
  catchTakeLatest(actions.editCard, handleFavouriteCard),
  catchTakeLatest(actions.deleteCard, handleDeleteCard)
];
export default watchedSagas;

// export function* handleAdyenAddAdyenCard(
//   action: ActionType<typeof actions.submitAdyenAddCardForm>
// ) {
//   yield put(actions.showGlobalLoader());
//   const state: RootState = yield select();
//   const formState = selectors.getControls(state, ADD_FORM);
//   const currentCurrency = selectors.getCurrentCurrency(state);
//
//   let year = _get(formState, "year.value", "").toString();
//   if (year.length === 2) {
//     year = "20" + year;
//   }
//
//   const adyenToken = yield handleEncryptAdyenCardDetails(
//     actions.adyenEncryptCard({
//       cvc: _get(formState, "cvc.value", 737).toString(),
//       expiryMonth: _get(formState, "month.value", "").toString(),
//       expiryYear: year,
//       holderName: _get(formState, "name.value", "").toString(),
//       number: _get(formState, "number.value", "").toString()
//     })
//   );
//
//   if (_get(adyenToken, "length", 0) === 0) {
//     yield put(actions.hideGlobalLoader());
//     yield put(
//       formActions.parseServerErrors(
//         { fields: {}, form: ["ERROR_INVALID_CARD"] },
//         ADD_FORM
//       )
//     );
//     return;
//   }
//
//   const saveCard = _get(formState, "save_card_checkbox.value", "") === "saved";
//   const paymentToken = _get(formState, "payment_token.value", "");
//   const res = yield call(RestClient.send, {
//     body: {
//       currency_code: currentCurrency,
//       payment_token: saveCard ? undefined : paymentToken,
//       token: adyenToken
//     },
//     service: "add_card",
//     timeout: 20000
//   });
//
//   yield put(actions.hideGlobalLoader());
//
//   if (!res) {
//     return;
//   }
//
//   if (!_isEmpty(res.errors)) {
//     yield put(formActions.parseServerErrors(res.errors, ADD_FORM));
//     return;
//   }
//
//   try {
//     yield put(actions.fetchCards(paymentToken, "last"));
//     yield put(actions.closeModal(actions.ModalID.ADD_CARD_FORM));
//     return;
//   } catch (e) {
//     window.Logger.error("handleAddAdyenCard: ", e.message);
//     return;
//   }
// }

// export function* handleAdyenEditAdyenCard(
//   action: ActionType<typeof actions.submitAdyenEditCardForm>
// ) {
//   yield put(actions.showGlobalLoader());
//   const state: RootState = yield select();
//   const formState = selectors.getControls(state, ADD_FORM);
//
//   let year = _get(formState, "year.value", "").toString();
//   if (year.length === 2) {
//     year = "20" + year;
//   }
//
//   const adyenToken = yield handleAdyenEncryptCardDetails(
//     actions.AdyenEncryptCard({
//       cvc: _get(formState, "cvc.value", "").toString(),
//       expiryMonth: _get(formState, "month.value", "").toString(),
//       expiryYear: year
//     })
//   );
//
//   if (_get(adyenToken, "length", 0) === 0) {
//     yield put(actions.hideGlobalLoader());
//     yield put(
//       formActions.parseServerErrors(
//         { fields: {}, form: ["ERROR_INVALID_CARD"] },
//         ADD_FORM
//       )
//     );
//     return;
//   }
//
//   const res = yield call(RestClient.send, {
//     body: {
//       token: adyenToken
//     },
//     params: {
//       id: _get(formState, "card_id.value", "").toString()
//     },
//     service: "edit_card"
//   });
//
//   yield put(actions.hideGlobalLoader());
//
//   if (!res) {
//     return;
//   }
//
//   if (_get(res.errors, "form.0", "")) {
//     yield put(formActions.parseServerErrors(res.errors, ADD_FORM));
//     return;
//   }
//
//   try {
//     yield put(actions.fetchCards());
//     yield put(actions.closeModal(actions.ModalID.ADD_CARD_FORM));
//     // yield put(
//     //   actions.toast(getIntl().formatMessage({ id: "SUCCESS_CARD_EDITED" }))
//     // );
//     return;
//   } catch (e) {
//     window.Logger.error("handleEditAdyenCard: ", e.message);
//     return;
//   }
// }

// const options = {};
// export function* handleAdyenEncryptCardDetails(
//   action: ActionType<typeof actions.adyenEncryptCard>
// ) {
//   const acquirerId = selectors.getAcquirerId();
//   const publicKey = CardUtils.getAdyenPublicKey(acquirerId);
//
//   const cseInstance = yield adyen.createEncryption(publicKey, options);
//   let token = "";
//   try {
//     token = yield cseInstance.encrypt({
//       ...action.payload.card,
//       generationtime: new Date().toISOString()
//     });
//   } catch (e) {
//     // Maybe get "Malformed public key"
//     window.Logger.log(e);
//   }
//
//   if (token) {
//     yield put(actions.adyenStoreToken(token));
//     return token;
//   } else {
//     yield put(actions.adyenStoreToken(""));
//   }
// }

export function* handleFetchCards(
  action: ActionType<typeof actions.fetchCards>
) {
  const state: RootState = yield select();

  const {
    paymentToken,
    defaultSelectedCard,
    noneSetSelectedId
  } = action.payload;

  if (IPMContext.isPayFetchPlatform()) {
    yield put(actions.setCardIsFetching(true));
  }

  const res: {
    data: any;
  } = yield call(RestClient.send, {
    query: {
      payment_token: paymentToken
    },
    service: "get_cards",
    nocache: action.payload.nocache
  });

  if (!res) {
    yield put(
      actions.setCards({
        cards: [],
        isFetching: true,
        selectedId: 0,
        isInitialFetch: false
      })
    );
    const err = new HttpRequestError("Failed to fetch");
    throw err;
  }

  try {
    let selectedCardId;

    const availableAcquirersIds: any[] = selectors
      .getAvailableAcquirers(state)
      .map((a: any) => a.id);

    const cards = res.data
      .filter((card: any) => card.is_wallet_card === true)
      .filter((card: any) => availableAcquirersIds.includes(card.acquirer_id));

    const lastUsedCard = _maxBy(cards, card => {
      const lastUsedAt = _get(card, "last_used_at");
      if (lastUsedAt) {
        const times = format(new Date(lastUsedAt), "X"); // return to "Seconds timestamp" format
        return Number(times);
      }
      return null;
    });

    switch (defaultSelectedCard) {
      case "last":
        selectedCardId = _get(cards[cards.length - 1], "id", 0);
        break;
      case 0:
        // selectedCardId = _get(cards[0], "id", 0);
        selectedCardId = _get(lastUsedCard || cards[0], "id", 0); // last card used (if existed) OR first card by default
        break;
      default:
        selectedCardId = defaultSelectedCard;
    }
    yield delay(0);

    let cardData: any = {
      cards: res.data.map((card: any) => ({
        acquirerId: card.acquirer_id,
        isFavourite: card.additional_data?.is_favourite,
        cardNickName: card.additional_data?.card_nick_name,
        bankId: 1,
        brandId: card.card_brand,
        expiryMonth: card.expiry_month,
        expiryYear: card.expiry_year,
        id: card.id,
        isWallet: card.is_wallet_card,
        last4: card.last_4,
        name: card.name,
        createdAt: card.created_at,
        oldAcquirerId: card.old_acquirer_id || 0
      })),
      isFetching: false,
      isInitialFetch: false
    };

    if (!noneSetSelectedId) {
      cardData = {
        ...cardData,
        selectedId: selectedCardId
      };
    }

    yield put(actions.setCards(cardData));
    // cb();

    return;
  } catch (e) {
    if (IPMContext.isPayFetchPlatform()) {
      yield put(actions.setCardIsFetching(false));
    }

    window.Logger.error("handleFetchCards: ", e.message);
    return;
  }
}

export function* handleDeleteCard(
  action: ActionType<typeof actions.deleteCard>
) {
  yield put(actions.showGlobalLoader());

  const { id, cb } = action.payload;

  const res: {} = yield call(RestClient.send, {
    params: { id },
    service: "delete_card"
  });

  yield put(actions.hideGlobalLoader());

  if (!res) {
    return;
  }

  if (cb) {
    cb();
  }

  yield put(actions.fetchCards());
}

export function* handleFavouriteCard(
  action: ActionType<typeof actions.editCard>
) {
  const state: RootState = yield select();
  const token = selectors.getToken(state);
  const id = _get(action.payload, "cardId", 0);
  if (!token) {
    return;
  }
  if (!id) {
    return;
  }

  yield put(actions.showGlobalLoader());

  const res: { data: any } = yield call(RestClient.send, {
    body: {
      is_favourite: action.payload.additionalData.isFavourite,
      card_nick_name: action.payload.additionalData.cardNickName,
      clear_is_favourite: action.payload.clearIsFavourite
    },
    params: {
      id
    },
    service: "edit_favourite_card"
  });

  yield put(actions.hideGlobalLoader());

  if (!res) {
    return;
  }

  const errors = _get(res, "errors");
  if (!_isEmpty(errors)) {
    if (action.payload.errCb) {
      action.payload.errCb(errors);
    }
    yield put(formActions.parseServerErrors(errors, ADD_CARD_FORM));
    return;
  }

  yield put(actions.fetchCards());
}

export function* handleAddCard(
  action: ActionType<typeof actions.submitAddCardForm>
) {
  const state: RootState = yield select();
  const token = selectors.getToken(state);
  if (!token) {
    return;
  }

  const formState = selectors.getControls(state, ADD_CARD_FORM);

  yield put(actions.showGlobalLoader());

  const saveCard = _get(formState, "save_card_checkbox.value", "") === "saved";

  const paymentToken = _get(formState, "payment_token.value", "");
  const acquirerId = _get(
    formState,
    "acquirer_id.value",
    selectors.getAcquirerId(state)
  );
  const cardNickname = _get(formState, "card_nick_name.value", "");
  const isFavoriteCard = _get(formState, "is_favourite_card.value") || false;

  const res: { data: any } = yield call(RestClient.send, {
    body: {
      cardholder_name: action.payload.cardholderName,
      payment_token: saveCard ? undefined : paymentToken,
      redirect_url: window.location.href,
      token: action.payload.cardInfo.cardToken,
      token_type: action.payload.cardInfo.cardTokenType,
      card_info: {
        card_bin: action.payload.cardInfo.cardBin,
        additional_card_bin: action.payload.cardInfo.additionalCardBin,
        card_brand: action.payload.cardInfo.cardBrand,
        card_expiry_month: action.payload.cardInfo.cardExpiryMonth,
        card_expiry_year: action.payload.cardInfo.cardExpiryYear,
        card_issuer_country: action.payload.cardInfo.cardIssuerCountry,
        card_last4: action.payload.cardInfo.cardLast4,
        card_token: action.payload.cardInfo.cardToken,
        card_token_type: action.payload.cardInfo.cardTokenType,
        card_type: action.payload.cardInfo.cardType
      },
      additional_data: {
        is_favourite: isFavoriteCard,
        card_nick_name: cardNickname
      },
      clear_is_favourite: isFavoriteCard,
      acquirer_id: Number(acquirerId)
    },
    service: "add_card",
    timeout: 20000
  });

  yield put(actions.hideGlobalLoader());

  if (!res) {
    return;
  }

  const errors = _get(res, "errors");
  if (!_isEmpty(errors)) {
    if (action.payload.errCb) {
      action.payload.errCb(errors);
    }
    yield put(formActions.parseServerErrors(errors, ADD_CARD_FORM));
    return;
  }

  if (action.payload.cb && res.data.code) {
    action.payload.cb(res.data.id);
  }

  yield put(actions.fetchCards(paymentToken, res.data.id, true, true));
  yield put(actions.closeModal(actions.ModalID.ADD_CARD_FORM));

  // reset is_favorite_card on card form
  yield put(actions.removeControl("is_favourite_card"));

  ScrollUtil.scrollTopElementId("modal-body-content");
}

export function* handleUserCreateStripeCardIntent(
  action: ActionType<typeof actions.createUserStripeCardIntent>
) {
  const { args = {}, cb = null } = action.payload;
  const state: RootState = yield select();
  const acquirerId = _get(args, "acquirerId", selectors.getAcquirerId(state));

  const res: { data: any } = yield call(RestClient.send, {
    query: {
      acquirer_id: acquirerId
    },
    service: "generate_user_stripe_card_intent",
    showGlobalLoader: true
  });

  if (!res) {
    return;
  }

  const errors = _get(res, "errors");
  if (!_isEmpty(errors)) {
    window.Logger.error(errors);
    return;
  }

  if (cb) {
    cb(res.data.client_secret);
  }
}
