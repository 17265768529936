import React, { useState } from "react";
import { MobileView, BrowserView, isDesktop } from "react-device-detect";
import * as copy from "copy-to-clipboard";
import classNames from "classnames";

import { FetchModalID } from "src/ipm-shared/components/GlobalUI/actions";

import Image from "src/bepaid/components/Image";
import Button from "src/bepaid/components/Form/controls/Button";
import InputText from "src/bepaid/components/Form/controls/InputText";
import DesktopModalLayout from "src/bepaid/components/Modal/components/DesktopModalLayout";
import MainLayout from "src/bepaid/layouts/MainLayout";

import IconInvoiceSent from "src/bepaid/assets/images/invoices/invoice_sent.svg";
import IconCopyLink from "src/bepaid/assets/images/common/copy_link.svg";
import IconLinkCopied from "src/bepaid/assets/images/common/link_copied.svg";

import styles from "./UserInviteResultModal.module.scss";

function UserInviteResultModal(props: any) {
  const [isCopied, setCopySate] = useState(false);
  const { url } = props.extraInfo;

  const handleCopy = () => {
    copy(url);
    setCopySate(!isCopied);
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    props.toggleModal(FetchModalID.USER_INVITE_SENT_MODAL);
    props.toggleModal(FetchModalID.USER_ADD_NEW_MODAL);
  };

  const renderContent = () => (
    <div>
      <div className={styles.inviteSentLogo}>
        <Image src={IconInvoiceSent} />
        <h4 className={styles.title}>Invite sent!</h4>
      </div>

      <div>
        <p className={styles.description}>
          Your invite has been successfully sent.
        </p>
      </div>

      <div className={styles.copyLink}>
        <p>Copy the link below and share it with your employee:</p>

        <div>
          <InputText
            prefix={
              <Image
                src={isCopied ? IconLinkCopied : IconCopyLink}
                onClick={handleCopy}
              />
            }
            name="copy_link"
            value={isCopied ? "Link copied" : url}
            inputClassName={styles.inputCopy}
          />
        </div>
      </div>
    </div>
  );
  const renderButtons = () => (
    <div className={styles.groupBtn}>
      <Button type="primary" onClick={onSubmit}>
        Send another invite
      </Button>
    </div>
  );

  return (
    <>
      <BrowserView>
        <DesktopModalLayout
          hasBorder={false}
          noHeader={true}
          btnSubmit={renderButtons()}
        >
          <div
            className={classNames(styles.wrapper, {
              [styles.wrapperDesktop]: isDesktop
            })}
          >
            {renderContent()}
          </div>
        </DesktopModalLayout>
      </BrowserView>
      <MobileView>
        <MainLayout
          header={{
            hasBorder: false,
            hideMenu: true
          }}
        >
          <div className={classNames(styles.wrapper, styles.mobileWrapper)}>
            {renderContent()}
            {renderButtons()}
          </div>
        </MainLayout>
      </MobileView>
    </>
  );
}

export default UserInviteResultModal;
