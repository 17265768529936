import { connect } from "react-redux";

import View from "./View";
import { RootState } from "src/ipm-shared/store/model/reducers";
import * as selectors from "./selectors";
import * as actions from "src/bepaid/store/model/Fetch/actions";
const mapStateToProps = (state: RootState, props: any) => ({
  selectedPayment: selectors.getBepaidSelectedPaymentDetail(state),
  hasWallexPayment: selectors.getHasWallexPayment(state)
});

const mapDispatchToProps = {
  toggleModal: actions.toggleModal
};

export default connect(mapStateToProps, mapDispatchToProps)(View);
