// import _get from "lodash-es/get";
// import { isAfter } from "date-fns";
import { RootState } from "src/ipm-shared/store/model/reducers";

export * from "src/bepaid/store/model/Fetch/selectors";

// export const getPayments = (state: RootState) =>
//   state.payments.payments
//     .sort((a, b) => {
//       // in ascending chronological order by recipient name.
//       const aName = _get(a, "payees[0].name", "") as string;
//       const bName = _get(b, "payees[0].name", "") as string;
//       const result = aName.localeCompare(bName);
//       return result;
//     })
//     .sort((a, b) => {
//       // in descending chronological order by payment sent date
//       const aName = _get(a, "payees[0].name", "") as string;
//       const bName = _get(b, "payees[0].name", "") as string;
//       // only sort if they're in the same group
//       if (aName !== bName) return 0;
//       return isAfter(new Date(a.createdAt), new Date(b.createdAt)) ? -2 : 1;
//     });

export const getPayments = (state: RootState) => state.payments.payments;
