import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const defaultBarOptions: any = {
  responsive: true,
  maintainAspectRatio: true,
  layout: {
    padding: {}
  },
  plugins: {
    legend: {},
    datalabels: {},
    tooltip: {}
  },
  scales: {
    y: {},
    x: {}
  }
};

function BarChart(props: any) {
  const {
    data,
    options: { height = 120, width = 0, ...otherOptions },
    widthLegend = 80
  } = props;

  const defaultBarPlugins: any = {
    id: "legendMargin",
    beforeInit(chart: any) {
      const fitValue = chart.legend.fit;
      chart.legend.fit = function fit() {
        fitValue.bind(chart.legend)();
        return (this.width += widthLegend);
      };
    }
  };
  return (
    <Bar
      options={{ ...defaultBarOptions, ...otherOptions }}
      plugins={[ChartDataLabels, defaultBarPlugins]}
      data={data}
      height={height}
      width={width}
    />
  );
}

export default BarChart;
