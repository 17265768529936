import React, { useEffect, useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import _get from "lodash-es/get";
import * as jwtDecode from "jwt-decode";
// import classNames from "classnames";

import Flex from "antd-mobile/es/flex";
import Typography from "antd/es/typography";
import T from "src/ipm-shared/Utils/Intl";

// import ROUTES from "src/bepaid/routes";
import { invokeMessage } from "../../components/Message";
import Form from "src/bepaid/components/Form";
import InputOtpVerification from "src/bepaid/components/Form/controls/InputOtpVerification";
import Button from "src/bepaid/components/Form/controls/Button";
import Image from "src/bepaid/components/Image";
import Card from "src/bepaid/components/Card";
import GuestLayout from "src/bepaid/layouts/GuestLayout";

import HeaderLogo from "src/bepaid/assets/images/desktop/header_ipaymy_logo.svg";

import { ERROR_MESSAGES } from "./const";

import styles from "./VerificationMobile.module.scss";

const FormComponent = (props: any) => {
  const [otp, setOtp] = useState<string>("");
  const [error, setError] = useState("");

  const onSubmit = () => {
    if (otp.length < 6) {
      setError(ERROR_MESSAGES[0].value);
    } else {
      setError("");
    }
    props.onSubmit(otp);
  };

  const hasError = Boolean(error) || props.verifyCodeError.length;
  const errorCode = _get(props.verifyCodeError, "0.code", "");

  const loading = !hasError && props.isLoading;

  return (
    <Form
      name="verification-mobile-form"
      layout="vertical"
      requiredMark={false}
      className={styles.form}
    >
      <Form.Item
        name="verification-code"
        validateStatus={hasError ? "error" : ""}
        {...(hasError
          ? {
              help: error || (
                <div>
                  {T.transl(errorCode, {
                    RESEND_PIN: (
                      <a
                        className={styles.resendCodeErrorLink}
                        href={"#"}
                        onClick={props.onResetCode}
                      >
                        {T.transl("RESEND_PIN")}
                      </a>
                    )
                  })}
                </div>
              )
            }
          : {})}
        className={styles.itemVerificationCode}
      >
        <InputOtpVerification
          /* tslint:disable-next-line:jsx-no-lambda */
          onChange={(code: string) => {
            setOtp(code);
          }}
        />
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          onClick={onSubmit}
          loading={loading || props.resendingCode}
        >
          {props.resendingCode
            ? "Resending code..."
            : !loading
            ? "Verify"
            : "Verifying..."}
        </Button>
      </Form.Item>
    </Form>
  );
};

const VerificationMobile: React.FC<any> = props => {
  const [last4MobileNumber, setLast4MobileNumber] = useState("3789");
  const [loading, setLoading] = useState(false);
  const [resendingCode, setResendingCode] = useState(false);

  useEffect(() => {
    const smsOtpToken = localStorage.getItem("sms_otp_token");
    if (smsOtpToken) {
      const decoded = jwtDecode(smsOtpToken);
      if (decoded) {
        setLast4MobileNumber(_get(decoded, "last4"));
      }
    }
  }, []);

  const onResetCode = () => {
    // reset error here
    props.resendVerifyOtp(() => {
      invokeMessage("success", "Code resent! Please check your mobile device.");
    }, setResendingCode);
  };

  const handleSubmit = (otp: string) => {
    props.verifyOtp({
      smsOtp: otp,
      cb: (err?: any) => {
        if (err) {
          window.Logger.log(err);
        }
      },
      loadingFunc: setLoading
    });
  };

  const renderContent = () => {
    return (
      <div className={styles.wrapper}>
        <Flex className={styles.title}>
          <Flex.Item>
            <Typography.Text>Verify mobile number</Typography.Text>
          </Flex.Item>
        </Flex>
        <Flex className={styles.instructionSection}>
          <Flex.Item span={22}>
            <Typography.Text>
              Please enter the verification code sent to your mobile number
              ending in <b>{last4MobileNumber}.</b>
              {/* It may take a few minutes to receive it. */}
            </Typography.Text>
          </Flex.Item>
        </Flex>
        <Flex>
          <Flex.Item span={24}>
            <FormComponent
              {...props}
              resendingCode={resendingCode}
              isLoading={loading}
              onResetCode={onResetCode}
              onSubmit={handleSubmit}
            />
          </Flex.Item>
        </Flex>
        <Flex className={styles.extraSection}>
          <Flex.Item span={24} className={styles.centerText}>
            <Typography.Text className={styles.resendCodeText}>
              Didn't receive the code?{" "}
              <Typography.Link
                className={styles.strongLink}
                href="javascript:void(0)"
                onClick={onResetCode}
              >
                Resend code.
              </Typography.Link>
            </Typography.Text>
          </Flex.Item>
        </Flex>
      </div>
    );
  };

  const renderDesktopView = () => {
    return (
      <div className={styles.desktopWrapper}>
        <Card>{renderContent()}</Card>
      </div>
    );
  };

  return (
    <GuestLayout header={<Image src={HeaderLogo} />}>
      <BrowserView>{renderDesktopView()}</BrowserView>
      <MobileView>{renderContent()}</MobileView>
    </GuestLayout>
  );
};

export default VerificationMobile;
