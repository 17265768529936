import { connect } from "react-redux";

import { RootState } from "src/ipm-shared/store/model/reducers";

import * as GlobalUISelectors from "src/ipm-shared/components/GlobalUI/selectors";
import * as actions from "src/bepaid/store/model/Fetch/actions";

import Component from "./View";

const mapStateToProps = (state: RootState, props: any) => ({
  extraInfo: GlobalUISelectors.getExtraInfo(state, props.modalId),
  openedModalIds: GlobalUISelectors.getOpenedModalIds(state)
});

const mapDispatchToProps = {
  toggleModal: actions.toggleModal,
  resetAllModals: actions.resetAllModals
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
