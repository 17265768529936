import _get from "lodash-es/get";
import {
  PURPOSE,
  TAX_PAYMENT_ACCOUNT_NUMBER
} from "src/ipm-shared/store/model/Payment/const";
import { PAYMENT_GROUP_STATUS_NAME } from "src/bepaid/pages/Filter/const";
import {
  PAYMENT_GROUP_STATUS,
  PAYMENT_STATUS
} from "src/ipm-shared/store/model/Payment/const";

import PaymentMethodData from "src/ipm-shared/store/metadata/payment_method";

const paymentUtil = {
  getPaymentEntity: (payment: any) => {
    const purposeId = _get(payment, "purposeId");
    const paymentAccountNumber = _get(payment, "payees[0].accountNumber");

    switch (purposeId) {
      case PURPOSE.RENTAL:
        return "landlord";

      case PURPOSE.SALARY:
        return "employee";

      case PURPOSE.SALARY_BUSINESS:
        return "business_employee";

      case PURPOSE.INVOICE:
        return TAX_PAYMENT_ACCOUNT_NUMBER.includes(paymentAccountNumber)
          ? "tax"
          : "supplier";

      case PURPOSE.SELF_TRANSFER:
        return "self_transfer";

      default:
        return "supplier";
    }
  },
  getPaymentMethodDetails: (payment: any) => {
    return PaymentMethodData.find(
      data => data.id === _get(payment, "cryptoPaymentDetail.id")
    );
  },
  getPaymentPurpose: (payment: any) => {
    const purposeId = _get(payment, "purposeId");
    const paymentAccountNumber = _get(payment, "payees[0].accountNumber");
    const isMultiplePurpose = _get(payment, "isMultiplePurpose");

    if (isMultiplePurpose) {
      return "Multiple";
    }

    switch (purposeId) {
      case PURPOSE.RENTAL:
        return "Rent";

      case PURPOSE.SALARY:
        return "Salaries";

      case PURPOSE.SALARY_BUSINESS:
        return "Business Salaries";

      case PURPOSE.INVOICE:
        return TAX_PAYMENT_ACCOUNT_NUMBER.includes(paymentAccountNumber)
          ? "Tax"
          : "Invoices";

      case PURPOSE.SELF_TRANSFER:
        return "Money Transfer";

      case PURPOSE.INSURANCE:
        return "Insurance";

      default:
        return "Invoices";
    }
  },
  getPaymentStatus: (payment: any) => {
    const paymentStatusId = payment.paymentStatusId;
    if (PAYMENT_GROUP_STATUS.cancelled.includes(paymentStatusId)) {
      return PAYMENT_GROUP_STATUS_NAME.Cancelled;
    }

    if (PAYMENT_GROUP_STATUS.refunded.includes(paymentStatusId)) {
      return PAYMENT_GROUP_STATUS_NAME.Refunded;
    }

    if (PAYMENT_GROUP_STATUS.scheduled.includes(paymentStatusId)) {
      return PAYMENT_GROUP_STATUS_NAME.Scheduled;
    }

    if (PAYMENT_GROUP_STATUS.under_review.includes(paymentStatusId)) {
      return PAYMENT_GROUP_STATUS_NAME.InProgress;
    }

    if (PAYMENT_GROUP_STATUS.on_screening.includes(paymentStatusId)) {
      return PAYMENT_GROUP_STATUS_NAME.OnScreening;
    }

    switch (paymentStatusId) {
      case PAYMENT_STATUS.UNDER_REVIEW:
        return PAYMENT_GROUP_STATUS_NAME.InProgress;

      case PAYMENT_STATUS.IN_PROGRESS_2:
        return PAYMENT_GROUP_STATUS_NAME.InProgress;

      case PAYMENT_STATUS.COMPLETED:
        return PAYMENT_GROUP_STATUS_NAME.Completed;

      default:
        return PAYMENT_GROUP_STATUS_NAME.Other;
    }
  },
  getRecipientTitle: (payment: any) => {
    if (payment.purposeId === PURPOSE.RENTAL) {
      return "Landlord";
    }

    if (payment.purposeId === PURPOSE.SALARY) {
      return "Employee";
    }

    if (
      payment.purposeId === PURPOSE.INVOICE &&
      _get(payment, "payees[0].accountNumber")
    ) {
      return "Supplier";
    }

    if (payment.purposeId === PURPOSE.SELF_TRANSFER) {
      return "Bank account";
    }

    if (payment.purposeId === PURPOSE.SALARY_BUSINESS) {
      return "Business Employee";
    }

    if (payment.purposeId === PURPOSE.INSURANCE) {
      return "Insurer";
    }

    return "Supplier";
  }
};

export default paymentUtil;
