/**
 * Actions
 *
 * ActionCreators are like the "pub" of pubsub. It's just broadcasting an event (action).
 * So, don't couple it with a specific reducer. Let ANY reducer listen to
 * an action.
 *
 * `typesafe-actions` has a nice utility function so we don't need to define action types as
 * constants. Use it together with `getType(type)` in the reducers.
 */
import { createAction } from "typesafe-actions";
export type DocumentPurposeType = "tenancy_agreement" | "invoice";

// Generic Form Input
export const setFormInput = createAction(
  "form/SET_INPUT",
  resolve => (args: { form: string; field: string; value: string | number }) =>
    resolve(args)
);
// For arrays of values
export const appendFormInput = createAction(
  "form/APPEND_INPUT",
  resolve => (args: { form: string; field: string; value: string | number }) =>
    resolve(args)
);
export const removeFormInput = createAction(
  "form/REMOVE_INPUT",
  resolve => (args: { form: string; field: string; value: string | number }) =>
    resolve(args)
);

export const setInvalidationState = createAction(
  "form/SET_INPUT_VALIDATION",
  resolve => (args: { form: string; field: string }) => resolve(args)
);

export const uploadFile = createAction(
  "files/UPLOAD",
  resolve => (args: {
    field: string;
    form: string;
    file: File;
    purpose: DocumentPurposeType;
  }) => resolve(args)
);
