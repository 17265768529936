// import format from "date-fns/format";
import { ActionType } from "typesafe-actions";
import { select, call, put } from "redux-saga/effects";
import {
  reTryTakeLatest,
  catchTakeEvery
} from "src/ipm-shared/Utils/ReduxSagaEffects";
import HttpRequestError from "src/ipm-shared/Utils/Exceptions/HttpRequestError";
import * as dataConditionActions from "./actions";
import * as commonActions from "../actions";
import * as commonSelectors from "../selectors";
import * as payeeSelectors from "src/ipm-shared/store/model/Payee/selectors";
import { RootState } from "../reducers";
import * as accountProfileSelectors from "../AccountProfile/selectors";
// import * as countrySelectors from "../Country/selectors";
import * as paymentSelectors from "../PaymentRequest/selectors";

import RestClient from "src/ipm-shared/services/Rest";
import _get from "lodash-es/get";

const actions = {
  ...commonActions,
  ...dataConditionActions
};

const selectors = {
  ...commonSelectors,
  ...accountProfileSelectors,
  ...payeeSelectors,
  ...paymentSelectors
};

const watchedSagas = [
  catchTakeEvery(actions.fetchDateConditions, handleFetchDateConditions),
  reTryTakeLatest(actions.fetchPaymentCalendar, handleFetchPaymentCalendar)
];
export default watchedSagas;
export function* handleFetchDateConditions(
  action: ActionType<typeof actions.fetchDateConditions>
) {
  const state: RootState = yield select();
  // const acquirerId = selectors.getAcquirerId(state);
  const acquirerIds = selectors
    .getAvailableAcquirers(state)
    .map((acquirer: any) => acquirer.id);

  const { cb = () => null, justGetData = false } = action.payload.options || {};

  const token = selectors.getToken(state);
  const paymentMethodType = selectors.getPaymentMethodType(state);
  if (!token) {
    return;
  }

  yield put(actions.setDateConditions({ isFetching: true }));

  const countryId =
    action.payload.countryId || selectors.getCurrentCountryId(state);
  const currencyId =
    action.payload.currencyId || selectors.getCurrentCurrencyId();
  const paymentPaidCurrencyId =
    action.payload.paymentPaidCurrencyId ||
    selectors.getCurrentPaidCurrencyId();
  const cardBrandId = action.payload.cardBrandId;

  // Mock fetching from api.
  const res = yield call(RestClient.send, {
    query: {
      bank_ids: action.payload.bankIds.join(","),
      payee_ids: action.payload.payeeIds.join(","),
      country_id: countryId,
      currency_id: currencyId,
      paid_currency_id: paymentPaidCurrencyId,
      payout_date: action.payload.payoutDate.toISOString(),
      acquirer_ids: acquirerIds.join(","),
      card_brand_id: cardBrandId,
      payment_method_type: paymentMethodType
    },
    service: "get_date_conditions"
  });

  if (!res) {
    yield put(actions.setDateConditions({ isFetching: false }));
    throw new HttpRequestError("Failed to fetch");
  }

  try {
    const json = res;
    const earliest = new Date(json.data.earliest);
    const flashPayDate = new Date(json.data.flash_pay_date);
    const chargeDate = new Date(json.data.charge_date);
    const isChargeToday = json.data.is_charge_today;
    const exclusions = json.data.exclude_dates.map(
      (date: string) => new Date(date)
    );
    const pspNonWorkingDates = json.data.psp_non_working_dates.map(
      (date: string) => new Date(date)
    );

    if (!justGetData) {
      yield put(
        actions.setDateConditions({
          chargeDate,
          earliest,
          exclusions,
          pspNonWorkingDates,
          flashPayDate,
          isChargeToday,
          isFetching: false
        })
      );
    }

    cb(null, json);
  } catch (e) {
    window.Logger.error("handleFetchDateConditions: ", e.message);
  }
}

export function* handleFetchPaymentCalendar(
  action: ActionType<typeof actions.fetchPaymentCalendar>
) {
  const { month, year } = action.payload;
  const res = yield call(RestClient.send, {
    query: {
      month,
      year
    },
    service: "get_payment_calendar"
  });

  if (!res) {
    yield put(
      actions.setPaymentCalendar({
        inComingPayments: [],
        isFetching: false,
        outComingPayments: []
      })
    );
    throw new HttpRequestError("Failed to fetch");
  }

  try {
    const inComingPayments = _get(res, "incoming_payment_dates", []);
    const outComingPayments = _get(res, "outgoing_payment_dates", []);

    yield put(
      actions.setPaymentCalendar({
        inComingPayments,
        isFetching: false,
        outComingPayments
      })
    );
  } catch (e) {
    window.Logger.error("handleFetchPaymentCalendar: ", e.mesage);
  }
}
