import { RootState } from "src/ipm-shared/store/model/reducers";
import { connect } from "react-redux";
import * as selectors from "src/bepaid/store/model/Fetch/selectors";
import * as actions from "src/bepaid/store/model/Fetch/actions";

import View from "./View";

const mapStateToProps = (state: RootState) => ({
  accountCurrencyId: selectors.getAccountCurrencyId(state),
  paymentCountryCode: selectors.getPaymentCountryCode(state)
});

const mapDispatchToProps = {
  paymentCalculatorBasic: actions.paymentCalculator,
  setPaymentCurrencyCode: actions.setPaymentCurrencyCode
};

export default connect(mapStateToProps, mapDispatchToProps)(View);
