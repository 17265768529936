import { connect } from "react-redux";
import Component from "./View";
import { RootState } from "src/ipm-shared/store/model/reducers";
import * as actions from "src/ipm-shared/store/model/AccountUsers/actions";
import * as FormAction from "src/ipm-shared/components/Form/actions";

const mapStateToProps = (state: RootState, props: any) => ({});

const mapDispatchToProps = {
  toggleModal: FormAction.toggleModal,
  sendAccountUserInvite: actions.sendAccountUserInvite
};

// Fixed template
export type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Component);
