import React, { useEffect, useState } from "react";
import { BrowserView, MobileView, isMobile } from "react-device-detect";
import classNames from "classnames";
import Skeleton from "antd/es/skeleton";

import { FetchModalID } from "src/ipm-shared/components/GlobalUI/actions";

import MainLayout from "src/bepaid/layouts/MainLayout";
import DesktopModalLayout from "src/bepaid/components/Modal/components/DesktopModalLayout";
import MobileMainButton from "src/bepaid/layouts/MainLayout/components/MobileMainButton";

import { ListCards } from "./List";

import styles from "./ListCardModal.module.scss";
import FeatureGateUtil from "src/ipm-shared/components/FeatureGating/Util";

const View = (props: any) => {
  const { cardsByCountryId, toggleModal, editCard } = props;
  const [shouldDisplayLoadingCards, displayLoadingCards] = useState(false);

  useEffect(() => {
    displayLoadingCards(true);

    setTimeout(() => {
      displayLoadingCards(false);
    }, 1000);
  }, []);

  useEffect(() => {
    // redirect to add card modal if fetch card data, and has no value
    // keep the loading when redireciting instead of showing empty list
    if (cardsByCountryId.length === 0) {
      setTimeout(handleAddNewCard, 500);
    }
  }, [cardsByCountryId]);

  const closeModal = () => {
    toggleModal(FetchModalID.LIST_CARD_MODAL);
  };

  const handleAddNewCard = () => {
    let allowAmexPayment = false;
    if (props.isPersonalAccount) {
      allowAmexPayment = true;
    }
    if (
      props.features &&
      !FeatureGateUtil.verifyFeature(props.features, "AMEX_ALLOW_PAYMENT")
    ) {
      allowAmexPayment = false;
    }
    toggleModal(FetchModalID.LIST_CARD_MODAL);
    toggleModal(FetchModalID.ADD_NEW_CARD_MODAL, {
      allowAmexPayment: allowAmexPayment
    });
  };

  const handleDelete = (cardId: number) => {
    toggleModal(FetchModalID.DELETE_NEW_CARD_MODAL, { cardId });
  };

  const renderContent = () => {
    return (
      <Skeleton loading={shouldDisplayLoadingCards}>
        <ListCards
          editCard={editCard}
          onDelete={handleDelete}
          items={cardsByCountryId}
        />
      </Skeleton>
    );
  };

  return (
    <>
      <BrowserView>
        <DesktopModalLayout
          title={"Wallet"}
          onClose={closeModal}
          submitLabel={"Add new card"}
          onSubmit={handleAddNewCard}
          maxWidth450={true}
        >
          <div className={styles.wrapper}>{renderContent()}</div>
        </DesktopModalLayout>
      </BrowserView>

      <MobileView>
        <MainLayout
          header={{
            title: "Wallet",
            onClose: closeModal,
            hideMenu: true
            // rightIcons: <Image src={IconMenuDot} onClick={onMoreAction} />
          }}
          footer={
            <MobileMainButton
              buttonText={"Add new card"}
              action={handleAddNewCard}
            />
          }
        >
          <div
            className={classNames(styles.wrapper, {
              [styles.wrapperMobile]: isMobile
            })}
          >
            {renderContent()}
          </div>
        </MainLayout>
      </MobileView>
    </>
  );
};

export default View;
