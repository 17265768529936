import React, { useEffect, useState } from "react";
import { isDesktop } from "react-device-detect";
import classNames from "classnames";
import _get from "lodash-es/get";
import Radio from "antd/es/radio";

import T from "src/ipm-shared/Utils/Intl";

import Form from "src/bepaid/components/Form";
import Button from "src/bepaid/components/Form/controls/Button";
import Image from "src/bepaid/components/Image";
import usePrevious from "src/bepaid/hooks/usePrevious";
import InputText from "src/bepaid/components/Form/controls/InputText";

import IconInfo from "src/bepaid/assets/images/common/icon_information.svg";
import IconRadioDefaultBtn from "src/bepaid/assets/images/common/radio_default.svg";
import IconRadioBtn from "src/bepaid/assets/images/common/radio_button.svg";

import FormStyles from "src/bepaid/components/Form/CommonForm.module.scss";
import styles from "../Additional.module.scss";

interface DataType {
  key: React.Key;
  id: number;
  name: string;
  rate: number;
  checked?: boolean;
}
const defaultItem: any[] = [];
const Component = (props: any) => {
  const [loading, setLoading] = useState(false);
  const commonFormItemProps = {
    reserveValueOnUnmount: true,
    revertValueOnMount: true,
    onWheel: (e: any) => e.target.blur()
  };
  const { form, uid } = props;
  const [tax, setTax] = useState({
    id: null,
    name: "",
    rate: 0
  });
  const [items, setItems] = useState(defaultItem);
  useEffect(() => {
    if (props.items) {
      setItems(props.items);
    }
    if (props.items.length <= 0) {
      setOptionAddItem("add");
    }
  }, [props.items]);
  const prevSelectedItem = usePrevious(props.selectedItem);
  useEffect(() => {
    if (!props.selectedItem) {
      return;
    }
    const newItemSelectedId: any[] = [];
    Object.keys(props.selectedItem).forEach(key => {
      if (key === uid) {
        newItemSelectedId.push(props.selectedItem[key]);
      }
    });
    if (items && items.length > 0) {
      setItems([
        ...items.map((i: DataType) => {
          if (newItemSelectedId.includes(i.id)) {
            i.checked = true;
          } else {
            i.checked = false;
          }
          return i;
        })
      ]);
    }
  }, [props.selectedItem, prevSelectedItem]);
  const [optionAddItem, setOptionAddItem] = useState("select");
  const handleChangeOptionAddItem = (e: any) => {
    if (e.target.value === "add") {
      handleOpenDetails({
        name: null,
        rate: null
      })();
    }
    setOptionAddItem(e.target.value);
  };
  const handleAddItem = () => {
    if (tax.id) {
      props.editTax(tax.id, setLoading);
    } else {
      props.addTax(setLoading);
    }
  };
  const handleOpenDetails = (record: any) => () => {
    setTax({
      ...tax,
      id: record.id,
      name: record.name,
      rate: record.tax
    });
    props.setControl({
      errors: [],
      form: props.form,
      name: `tax_name`,
      value: record.name
    });
    props.setControl({
      errors: [],
      form: props.form,
      name: `tax_rate`,
      value: record.rate
    });
    setOptionAddItem("add");
  };
  const handleSelectItem = (record: DataType) => () => {
    setItems([
      ...items.map((i: DataType) => {
        if (record.id === i.id) {
          i.checked = !i.checked;
        } else {
          i.checked = false;
        }
        return i;
      })
    ]);
  };
  const renderItem = (record: any) => {
    return (
      <div className={styles.wrapperItemList}>
        <div
          className={styles.wrapperAction}
          onClick={handleSelectItem(record)}
        >
          {record.checked ? (
            <Image className={styles.iconMore} src={IconRadioBtn} />
          ) : (
            <Image className={styles.iconMore} src={IconRadioDefaultBtn} />
          )}
        </div>
        <div className={styles.wrapperDescription}>
          <span className={styles.title}>{record.name}</span>
        </div>
        <div className={styles.wrapperAmount}>
          <span className={styles.title}>{record.rate}%</span>
          <Image
            className={styles.iconMore}
            src={IconInfo}
            onClick={handleOpenDetails(record)}
          />
        </div>
      </div>
    );
  };
  const handleSubmitSelectItem = () => {
    let recordSelectedId: number = 0;
    items.forEach((i: any) => {
      if (i.checked) {
        recordSelectedId = i.id;
        props.setControl({
          errors: [],
          form: props.form,
          name: `tax_name`,
          value: ""
        });
        props.setControl({
          errors: [],
          form: props.form,
          name: `tax_rate`,
          value: ""
        });
        props.setControl({
          errors: [],
          form: props.form,
          name: `tax_id`,
          value: i.id
        });
      }
    });

    props.selectItem(uid, recordSelectedId);
    props.close();
  };

  return (
    <>
      <Radio.Group
        className={styles.groupOptionAddItem}
        options={[
          { label: "Select tax", value: "select" },
          { label: "Add tax", value: "add" }
        ]}
        onChange={handleChangeOptionAddItem}
        value={optionAddItem}
        optionType="button"
        buttonStyle="solid"
      />
      {optionAddItem === "add" ? (
        <div
          className={classNames(styles.wrapperFormAddItem, {
            [styles.formInputDesktop]: isDesktop
          })}
        >
          <Form
            layout="vertical"
            requiredMark={false}
            className={classNames(
              FormStyles.personalFormDetail,
              styles.formInput
            )}
          >
            <InputText
              form={form}
              name="tax_name"
              placeholder="Tax name"
              label="Tax Name"
              defaultValue={tax.name}
              {...commonFormItemProps}
            />
            <InputText
              form={form}
              type={"number"}
              name="tax_rate"
              placeholder={T.transl("INVOICE_NEW_ITEM_TAX_RATE_PLACEHOLDER")}
              label="Rate (%)"
              pattern={"__PERCENTAGE__"}
              maxValue={99}
              defaultValue={tax.rate}
              {...commonFormItemProps}
            />
          </Form>
          <Button
            loading={loading}
            type="primary"
            htmlType="submit"
            onClick={handleAddItem}
          >
            {_get(tax, "id")
              ? loading
                ? "Saving..."
                : "Save"
              : loading
              ? "Adding..."
              : "Add"}
          </Button>
        </div>
      ) : (
        <div
          className={classNames({
            [styles.wrapperDesktop]: isDesktop
          })}
        >
          <div className={styles.wrapperTable}>
            {items.map((i: any) => {
              return renderItem(i);
            })}
          </div>
          <Button
            type="primary"
            htmlType="submit"
            onClick={handleSubmitSelectItem}
          >
            Select
          </Button>
        </div>
      )}
    </>
  );
};

export default Component;
