import React, { useState } from "react";
import _get from "lodash-es/get";
import Form from "src/bepaid/components/Form";
import classNames from "classnames";

import { ADD_FORM } from "src/ipm-shared/store/model/Company/const";

import Button from "src/bepaid/components/Form/controls/Button";
import { invokeMessage } from "src/bepaid/components/Message";
import InputText from "src/bepaid/components/Form/controls/InputText";

import { MODES } from "../../const";
import CompanyForm from "../CompanyForm";

import styles from "./AddEditCompany.module.scss";

const Component = (props: any) => {
  const { mode, companyData, disableSubmit } = props;

  const [loading, setLoading] = useState(false);
  const [contentButton, setContentButton] = useState("");

  React.useEffect(() => {
    if (mode === MODES.ADD_NEW_COMPANY) {
      loading
        ? setContentButton("Adding new company...")
        : props.submitLabel || setContentButton("Add new company");
    } else {
      loading
        ? setContentButton("Saving changes...")
        : props.submitLabel || setContentButton("Save changes");
    }
  }, [mode, loading]);

  const onSubmit = () => {
    switch (mode) {
      case MODES.ADD_NEW_COMPANY: {
        props.add((err: any) => {
          if (!err) {
            invokeMessage("success", "Changes saved.");
            props.setMode(MODES.SHOW_COMPANY_LIST);
          }
        }, setLoading);
        return;
      }
      case MODES.EDIT_EXISTING_COMPANY: {
        props.edit((err: any) => {
          if (!err) {
            invokeMessage("success", "Changes saved.");
          }
        }, setLoading);
        return;
      }
      default: {
        return;
      }
    }
  };

  return (
    <Form
      name="company-form"
      layout="vertical"
      requiredMark={false}
      className={styles.form}
    >
      <div hidden={true}>
        <InputText
          name={"id"}
          form={ADD_FORM}
          defaultValue={_get(companyData, "id")}
          disabled={true}
        />
        <InputText
          name={"company_name"}
          form={ADD_FORM}
          defaultValue={_get(companyData, "name")}
          disabled={true}
        />
        <InputText
          name={"country_code"}
          form={ADD_FORM}
          defaultValue={_get(companyData, "countryCode")}
          disabled={true}
        />
      </div>
      <CompanyForm
        className={styles.companyForm}
        data={{
          companyData,
          formName: ADD_FORM,
          formOptions: {
            names: {
              businessIndustry: "business_industry",
              businessType: "business_type",
              companyName: "company_name",
              regNo: "registration_number",
              uenSearchCompany: "uen_search_company"
            }
          }
        }}
      />
      {!disableSubmit && (
        <Form.Item className={"submit-btn"}>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            onClick={onSubmit}
          >
            {contentButton}
          </Button>
        </Form.Item>
      )}
    </Form>
  );
};

const AddEditCompany: React.FC<any> = props => {
  return (
    <div className={classNames(styles.wrapper, styles.desktopView)}>
      <Component {...props} />
    </div>
  );
};

export default AddEditCompany;
