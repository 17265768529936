import * as React from "react";
import * as ReactShare from "react-share";

export const SOCIAL_TYPES = {
  EMAIL: "email",
  FACEBOOK: "facebook",
  FACEBOOK_MESSENGER: "facebook-messenger",
  TWITTER: "twitter",
  WHATSAPP: "whatsapp"
};

type IProps = {
  icon: JSX.Element;
  type: string;
  url: string;
  content?: string;
  disabled?: boolean;
  hashtags?: string[];
  title?: string;
};

class ShareSocial extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  public render() {
    return <div className="share-social-icons">{this.renderButton()}</div>;
  }

  private renderButton = () => {
    const {
      icon,
      type,
      url,
      content = "",
      disabled = false,
      hashtags = [],
      title = ""
    } = this.props;

    const commonProps = {
      children: icon,
      disabled,
      url
    };

    switch (type) {
      case SOCIAL_TYPES.FACEBOOK: {
        const fbProps = {
          hashtag: hashtags && hashtags[0] ? hashtags[0] : "",
          quote: title
        };
        // @ts-ignore
        return <ReactShare.FacebookShareButton {...commonProps} {...fbProps} />;
      }
      case SOCIAL_TYPES.TWITTER: {
        const twitterProps = {
          hashtags,
          title
        };
        return (
          // @ts-ignore
          <ReactShare.TwitterShareButton {...commonProps} {...twitterProps} />
        );
      }
      case SOCIAL_TYPES.WHATSAPP: {
        const whatsappProps = {
          separator: " - ",
          title
        };
        return (
          // @ts-ignore
          <ReactShare.WhatsappShareButton {...commonProps} {...whatsappProps} />
        );
      }
      case SOCIAL_TYPES.FACEBOOK_MESSENGER: {
        const fbMessengerProps = {
          appId: ""
        };
        return (
          // @ts-ignore
          <ReactShare.FacebookMessengerShareButton
            {...commonProps}
            {...fbMessengerProps}
          />
        );
      }
      case SOCIAL_TYPES.EMAIL: {
        const emailProps = {
          body: content,
          separator: " - ",
          subject: title
        };
        // @ts-ignore
        return <ReactShare.EmailShareButton {...commonProps} {...emailProps} />;
      }
    }

    return null;
  };
}

export default ShareSocial;
