import React, { useState } from "react";
import _get from "lodash-es/get";

import { history } from "src/ipm-shared/store";

import ROUTES from "src/bepaid/routes";
import { invokeMessage } from "src/bepaid/components/Message";
import DeleteModal from "src/bepaid/components/Modal/components/DeleteModal";

const View = (props: any) => {
  const editData = props.extraInfo;

  // delete customer modal state
  const [isLoadingDeleting, setLoadingDeleting] = useState(false);

  const handleCancelPayment = () => {
    setLoadingDeleting(true);
    let id = editData.paymentId;
    if (editData.scheduleId) {
      id = editData.scheduleId;
      props.cancelSchedule(
        id,
        (err?: any) => {
          if (err) {
            invokeMessage("error", "Request failed.");
          } else {
            invokeMessage("success", "Payments cancelled.");
          }
          setLoadingDeleting(false);
          props.toggleModal(props.modalId);
          const firstPayoutId = _get(
            props.selectedScheduleDetail,
            "upcomingPayment.payments[0].id",
            ""
          );
          const urlParams = new URLSearchParams(window.location.search);
          if (editData.isScheduleDetail === "y") {
            urlParams.set("is_schedule", "y");
            const url = ROUTES.PAYMENT_SCHEDULE_DETAILS.replace(
              ":id",
              editData.scheduleId
            );
            history.push({
              pathname: url,
              search: "?" + urlParams.toString()
            });
            return;
          }
          urlParams.set("idType", "id");
          const url = ROUTES.PAYMENT_DETAILS.replace(":id", firstPayoutId);
          history.push({
            pathname: url,
            search: "?" + urlParams.toString()
          });
        },
        setLoadingDeleting
      );
      return;
    }
    props.cancelPayment(
      id,
      (err?: any) => {
        if (err) {
          invokeMessage("error", "Request failed.");
        } else {
          invokeMessage("success", "Payment cancelled.");
        }
        props.selectPayment(
          props.selectedPaymentDetail.id,
          () => {
            setLoadingDeleting(false);
            props.toggleModal(props.modalId);
            const urlParams = new URLSearchParams(window.location.search);
            urlParams.set("idType", "id");
            const url = ROUTES.PAYMENT_DETAILS.replace(
              ":id",
              props.selectedPaymentDetail.id
            );
            history.push({
              pathname: url,
              search: "?" + urlParams.toString()
            });
          },
          {
            idType: "id",
            withPaymentActivity: false
          }
        );
      },
      setLoadingDeleting
    );
  };

  const isCancelAll = editData.scheduleId > 0;

  return (
    <DeleteModal
      title={
        isCancelAll
          ? "Cancel these recurring payments?"
          : "Cancel this payment?"
      }
      isLoading={isLoadingDeleting}
      description={
        <span>
          This will permanently delete the{" "}
          {isCancelAll ? "payments" : "payment"} and it cannot be undone.
          <br />
          <br />
          You may not be able to enjoy this discounted rate again.
        </span>
      }
      yesLabel={`Yes, cancel ${isCancelAll ? "payments" : "payment"}`}
      yesLabelLoading={`Cancelling ${isCancelAll ? "payments" : "payment"}...`}
      noLabel={`No, keep ${isCancelAll ? "payments" : "payment"}`}
      onYes={handleCancelPayment}
      onNo={props.toggleModal.bind(null, props.modalId)}
    />
  );
};

export default View;
