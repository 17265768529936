// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Line_wrapper__2XddU{display:-ms-flexbox;display:flex;-ms-flex-pack:justify;justify-content:space-between;-ms-flex-align:center;align-items:center;margin:1rem 0}.Line_wrapper__2XddU .Line_lineLeft__33wuO{width:200%;height:1px;background-color:#e4e4e4}.Line_wrapper__2XddU .Line_lineRight__3WiAv{width:200%;height:1px;background-color:#e4e4e4}.Line_wrapper__2XddU .Line_lineMid__2uoJD{padding:0 15px}", "",{"version":3,"sources":["webpack://./src/bepaid/components/Line/Line.module.scss"],"names":[],"mappings":"AAAA,qBAAS,mBAAY,CAAZ,YAAY,CAAC,qBAA6B,CAA7B,6BAA6B,CAAC,qBAAkB,CAAlB,kBAAkB,CAAC,aAAa,CAAC,2CAAmB,UAAU,CAAC,UAAU,CAAC,wBAAwB,CAAC,4CAAoB,UAAU,CAAC,UAAU,CAAC,wBAAwB,CAAC,0CAAkB,cAAc","sourcesContent":[".wrapper{display:flex;justify-content:space-between;align-items:center;margin:1rem 0}.wrapper .lineLeft{width:200%;height:1px;background-color:#e4e4e4}.wrapper .lineRight{width:200%;height:1px;background-color:#e4e4e4}.wrapper .lineMid{padding:0 15px}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "Line_wrapper__2XddU",
	"lineLeft": "Line_lineLeft__33wuO",
	"lineRight": "Line_lineRight__3WiAv",
	"lineMid": "Line_lineMid__2uoJD"
};
export default ___CSS_LOADER_EXPORT___;
