import React from "react";
import { BrowserView, MobileView, isDesktop } from "react-device-detect";
import _get from "lodash-es/get";
import * as uuidv1 from "uuid/v1";
import classNames from "classnames";
import Typography from "antd/es/typography";

import { history } from "src/ipm-shared/store";
import { FetchModalID } from "src/ipm-shared/components/GlobalUI/actions";
import T from "src/ipm-shared/Utils/Intl";

import Form from "src/bepaid/components/Form";
import InputText from "src/bepaid/components/Form/controls/InputText";
import Button from "src/bepaid/components/Form/controls/Button";
import { invokeMessage } from "src/bepaid/components/Message";
import DesktopModalLayout from "src/bepaid/components/Modal/components/DesktopModalLayout";
import MainLayout from "src/bepaid/layouts/MainLayout";

import { MY_INFO_MODES } from "../../const";

import styles from "./RetrievedIdentityPersonal.module.scss";

const RetrievedIdentityPersonal: React.FC<any> = props => {
  const {
    redirectUrl,
    residentialAddress,
    fetchMyInfoSingPass,
    confirmMyInfoSingPass,
    getAddressByPostalCode,
    toggleModal,
    openModal,
    onClose
  } = props;

  const [loading, setLoading] = React.useState(false);
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [myInfoData, setMyInfoData] = React.useState({
    address: "",
    dob: "",
    mobileNumber: "",
    name: "",
    nationality: "",
    nric: "",
    passExpiryDate: "",
    passType: "",
    passportExpiryDate: "",
    passportNumber: "",
    postalCode: "",
    residentialStatus: "",
    sex: ""
  });

  React.useEffect(() => {
    fetchMyInfoSingPass(({ confirmed, info }: any) => {
      if (info.postalCode) {
        getAddressByPostalCode(info.postalCode);
      }
      if (info.mobileNumber === "none") {
        info.mobileNumber = "-";
      }

      setMyInfoData(info);
    });
  }, []);

  React.useEffect(() => {
    let timeout: any = null;
    if (isSubmitted) {
      setLoading(false); // ok
    } else {
      timeout = setTimeout(() => {
        setLoading(false); // ok
      }, 3000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [isSubmitted, loading]);

  const onSubmit = () => {
    confirmMyInfoSingPass(
      ({ confirmed = false, error = null }) => {
        if (confirmed) {
          toggleModal(FetchModalID.MY_INFO_VERIFICATION_MODAL);
          invokeMessage(
            "success",
            "Your information has been successfully retrieved from Myinfo."
          );
          if (redirectUrl) {
            history.push(redirectUrl);
          }
        } else {
          openModal(FetchModalID.MY_INFO_VERIFICATION_MODAL, {
            mode: MY_INFO_MODES.login
          });
          if (_get(error, "form[0]") === "SINGPASS_CONFIRMED_ANOTHER_USER") {
            openModal(FetchModalID.MY_INFO_VERIFICATION_ERROR_MODAL, {
              message1:
                "Looks like there's already an account registered with this Singpass ID.",
              message2: (
                <Typography.Text className={styles.itemText}>
                  Please sign in using that account or contact us at{" "}
                  <Typography.Link href="mailto:support@ipaymy.com">
                    support@ipaymy.com
                  </Typography.Link>{" "}
                  for further assistance.
                </Typography.Text>
              ),
              message3: null
            });
          } else if (_get(error, "form[0]") === "BLOCKED_USER_MYINFO_ERROR") {
            openModal(FetchModalID.MY_INFO_VERIFICATION_ERROR_MODAL, {
              message1: T.transl(
                _get(error, "form[0]", "BLOCKED_USER_MYINFO_ERROR")
              ),
              message2: null,
              message3: null
            });
          } else {
            openModal(FetchModalID.MY_INFO_VERIFICATION_ERROR_MODAL);
          }
        }

        setIsSubmitted(true);
      },
      setLoading,
      false
    );
  };

  const getData = () => {
    const get = (value: string) => {
      return _get(myInfoData, value) || "-";
    };

    const getAddressValue = () => {
      return residentialAddress || get("address");
    };

    return [
      {
        name: "nric",
        label: "NRIC/FIN",
        value: get("nric")
      },
      {
        name: "name",
        label: "Name",
        value: get("name")
      },
      {
        name: "sex",
        label: "Sex",
        value: get("sex")
      },
      {
        name: "dob",
        label: "Day of birth",
        value: get("dob")
      },
      {
        name: "residential_status",
        label: "Residential status",
        value: get("residentialStatus")
      },
      {
        name: "nationality",
        label: "Nationality",
        value: get("nationality")
      },
      {
        name: "passport_number",
        label: "Passport number",
        value: get("passportNumber")
      },
      {
        name: "passport_expiry_date",
        label: "Passport expiry date",
        value: get("passportExpiryDate")
      },
      {
        name: "pass_type",
        label: "Pass type",
        value: get("passType")
      },
      {
        name: "pass_expiry_date",
        label: "Pass expiry date",
        value: get("passExpiryDate")
      },
      {
        name: "mobile_number",
        label: "Mobile number",
        value: get("mobileNumber")
      },
      {
        name: "address",
        label: "Address",
        value: getAddressValue()
      }
    ];
  };

  const renderContent = () => (
    <>
      <div className={styles.section}>
        <span className={styles.sectionText}>
          Please note that information retrieved from Myinfo cannot be edited.
        </span>
      </div>
      <Form
        name="my-info-retrieved-identity-form"
        layout="vertical"
        requiredMark={false}
        className={styles.form}
      >
        <div className={styles.formWrapper}>
          <div className={styles.formContent}>
            {getData().map(({ name, label, value }) => (
              <div key={uuidv1()} className={styles.itemSection}>
                <InputText
                  name={name}
                  disabled={true}
                  label={label}
                  defaultValue={value}
                />
              </div>
            ))}
          </div>
        </div>
      </Form>
    </>
  );

  const renderButton = () => (
    <div className={styles.button}>
      <Button
        type="primary"
        htmlType="submit"
        loading={loading}
        onClick={onSubmit}
      >
        {loading ? "Verifying..." : "Verify"}
      </Button>
    </div>
  );

  return (
    <>
      <BrowserView>
        <DesktopModalLayout
          title={"Verify your identity"}
          onClose={onClose}
          onSubmit={onSubmit}
          btnSubmit={renderButton()}
        >
          <div
            className={classNames(styles.wrapper, {
              [styles.wrapperDesktop]: isDesktop
            })}
          >
            {renderContent()}
          </div>
        </DesktopModalLayout>
      </BrowserView>
      <MobileView>
        <MainLayout
          header={{
            title: "Verify your identity",
            onClose,
            hideMenu: true
          }}
          footer={renderButton()}
        >
          <div className={styles.wrapper}>{renderContent()}</div>
        </MainLayout>
      </MobileView>
    </>
  );
};

export default RetrievedIdentityPersonal;
