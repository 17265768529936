// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InputOptVerification_wrapper__UJDqx .ant-input{font-size:2rem;text-align:center !important;padding:.125rem .125rem}", "",{"version":3,"sources":["webpack://./src/bepaid/components/Form/controls/InputOtpVerification/InputOptVerification.module.scss"],"names":[],"mappings":"AAAA,gDAA4B,cAAc,CAAC,4BAA4B,CAAC,uBAAuB","sourcesContent":[".wrapper :global .ant-input{font-size:2rem;text-align:center !important;padding:.125rem .125rem}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "InputOptVerification_wrapper__UJDqx"
};
export default ___CSS_LOADER_EXPORT___;
