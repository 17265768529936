export const AVERAGE_INVOICE_AMOUNT = {
  SG: [
    { label: "< $1,000", value: 1 },
    { label: "$1,000 – $5,000", value: 2 },
    { label: "$5,001 - $10,000", value: 3 },
    { label: "$10,001 - $20,000", value: 4 },
    { label: "> $20,000", value: 5 }
  ],
  HK: [
    { label: "< HK$6,000", value: 1 },
    { label: "HK$6,000 – HK$30,000", value: 2 },
    { label: "HK$30,001 - HK$60,000", value: 3 },
    { label: "HK$60,001 - HK$120,000", value: 4 },
    { label: "> HK$120,000", value: 5 }
  ],
  MY: [
    { label: "< RM3,000", value: 1 },
    { label: "RM3,000 – RM15,000", value: 2 },
    { label: "RM15,001 - RM30,000", value: 3 },
    { label: "RM30,001 - RM60,000", value: 4 },
    { label: "> RM60,000", value: 5 }
  ],
  AU: [
    { label: "< AU$1,000", value: 1 },
    { label: "AU$1,000 – AU$5,000", value: 2 },
    { label: "AU$5,001 - AU$10,000", value: 3 },
    { label: "AU$10,001 - AU$20,000", value: 4 },
    { label: "> AU$20,000", value: 5 }
  ]
};
