import * as React from "react";
import { Consumer } from "../FeatureProvider";
import { connect } from "react-redux";
import { RootState } from "src/ipm-shared/store/model/reducers";
import * as selectors from "../selectors";

type FeatureConsumerProps = {
  feature: string;
  fallback?: string | JSX.Element;
};

const mapStateToProps = (state: RootState) => ({
  featureMask: selectors.getFeatures()
});

type IProps = FeatureConsumerProps & ReturnType<typeof mapStateToProps>;

class FeatureGate extends React.Component<IProps> {
  public render() {
    const { feature, featureMask } = this.props;
    return (
      <Consumer>
        {features => {
          const idx = features[feature];
          /* tslint:disable:no-bitwise */
          if (idx >= 0 && ((2 ** idx) & featureMask) > 0) {
            return this.props.children;
          } else {
            return this.props.fallback;
          }
        }}
      </Consumer>
    );
  }
}

export default connect(mapStateToProps)(FeatureGate);
