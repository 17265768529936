import React, { useCallback, useState } from "react";
import _get from "lodash-es/get";

import Form from "src/bepaid/components/Form";

import { PERSONAL_INFORMATION_FORM } from "src/bepaid/store/model/Fetch/const";

import InputText from "src/bepaid/components/Form/controls/InputText";
import Checkbox from "src/bepaid/components/Form/controls/Checkbox";
import Image from "src/bepaid/components/Image";
import IconArrowDown from "src/bepaid/assets/images/common/arrow_down_fresh.svg";
import InputFile from "src/bepaid/components/Form/controls/InputFile";
import InputFileDropZone from "src/bepaid/components/Form/controls/InputFile/components/InputFileDropZone";
import IconUpload from "src/bepaid/assets/images/common/icon_upload.svg";

import SelectBusinessIndustries from "src/ipm-shared/components/SelectBusiness/BusinessIndustries";
import SelectBusinessOccupations from "src/ipm-shared/components/SelectBusiness/BusinessOccupations";
import SelectMultiLevel from "src/bepaid/components/Form/controls/SelectMultiLevel";
import Select from "src/bepaid/components/Form/controls/Select";

import FormStyles from "src/bepaid/components/Form/CommonForm.module.scss";
import OtherStyles from "./AdditionalDataForm.module.scss";

import Country from "src/ipm-shared/Utils/Country";

import classNames from "classnames";
import Flex from "antd-mobile/es/flex";
import Typography from "antd/es/typography";

const styles = {
  ...FormStyles,
  ...OtherStyles
};

const countryCodeOptions = Country.getCountriesCodeOptions([
  "SG",
  "AU",
  "HK",
  "MY"
]);

const AdditionalDataForm = (props: any) => {
  const commonFormItemProps = {
    reserveValueOnUnmount: true,
    revertValueOnMount: true
  };

  const [occupation, setOccupation] = useState("");

  const WrapperSelectBusinessIndustryMultiLevel = useCallback(
    (propsWrapper: any) => {
      const handleChangeSelect = (value: any) => {
        props.setControl({
          errors: [],
          form: PERSONAL_INFORMATION_FORM,
          name: "industry",
          value: Number(value)
        });
      };

      return (
        <SelectMultiLevel
          key={"WrapperSelectBusinessIndustryMultiLevel"}
          {...propsWrapper}
          onChange={handleChangeSelect}
        />
      );
    },
    []
  );

  const WrapperSelectBusinessOccupationMultiLevel = useCallback(
    (propsWrapper: any) => {
      const handleChangeSelect = (value: any) => {
        setOccupation(value);
        props.setControl({
          errors: [],
          form: PERSONAL_INFORMATION_FORM,
          name: "occupation",
          value: Number(value)
        });
      };

      return (
        <SelectMultiLevel
          key={"WrapperSelectBusinessOccupationMultiLevel"}
          {...propsWrapper}
          onChange={handleChangeSelect}
        />
      );
    },
    []
  );

  const renderLabel = (flag: any, label: string) => (
    <Flex align="center">
      <Image src={flag} style={{ width: "32px" }} />
      <Typography.Text style={{ marginLeft: "0.5rem" }}>
        {label}
      </Typography.Text>
    </Flex>
  );

  const getCountryOptions = () => {
    return countryCodeOptions.map(({ code, flag, label, value }: any) => ({
      label: renderLabel(flag, label),
      value
    }));
  };

  return (
    <div className={styles.content}>
      <Form
        layout="vertical"
        requiredMark={false}
        className={classNames(
          styles.personalFormDetail,
          styles.addtionalDataform
        )}
      >
        {!props.isSingaporeAccount && (
          <>
            <InputText
              className={styles.name}
              form={PERSONAL_INFORMATION_FORM}
              name="full_legal_name"
              placeholder="Full legal name"
              label="Full legal name"
              defaultValue={null}
              {...commonFormItemProps}
            />
            <div className={styles.additionalDataCheckbox}>
              <Checkbox
                id="addtional_data_opt_in"
                name={"addtional_data_opt_in"}
                presentedValue="checked"
                defaultChecked={false}
                form={PERSONAL_INFORMATION_FORM}
                label={"I confirm that my name is written exactly as per my ID"}
                onChange={props.onConfirmName}
              />
            </div>
            <Form.Item label={"Date of birth"}>
              <div className={styles.selectBirthDate}>
                <Select
                  name={"date"}
                  form={PERSONAL_INFORMATION_FORM}
                  options={Array.from(Array(31), (_, i) => i + 1).map(ele => ({
                    key: ele,
                    value: ele
                  }))}
                  placeholder={"Date"}
                  suffixIcon={<Image src={IconArrowDown} />}
                  formItemClassName={styles.dropdownItem}
                />
                <Select
                  name={"month"}
                  form={PERSONAL_INFORMATION_FORM}
                  options={Array.from(Array(12), (_, i) => i + 1).map(ele => ({
                    key: ele,
                    value: ele
                  }))}
                  defaultValue={props.code}
                  placeholder={"Month"}
                  suffixIcon={<Image src={IconArrowDown} />}
                  formItemClassName={classNames(
                    styles.dropdownItem,
                    styles.middleItem
                  )}
                />
                <InputText
                  form={PERSONAL_INFORMATION_FORM}
                  name="year"
                  placeholder="Year"
                  defaultValue={null}
                  maxLength={4}
                  pattern={"__NUMBER__"}
                  className={styles.dropdownItem}
                  {...commonFormItemProps}
                />
              </div>
            </Form.Item>
            <Select
              label="Nationality"
              name="nationality"
              form={PERSONAL_INFORMATION_FORM}
              options={getCountryOptions()}
              defaultValue={null}
              placeholder={"Nationality"}
              formItemClassName={styles.countrySelect}
              suffixIcon={<Image src={IconArrowDown} />}
            />
          </>
        )}
        <SelectBusinessOccupations
          SelectComponent={WrapperSelectBusinessOccupationMultiLevel}
          name={"occupation"}
          label={"Occupation"}
          form={PERSONAL_INFORMATION_FORM}
          className={styles.select}
          {...commonFormItemProps}
        />
        {occupation === "70" && (
          <InputText
            type={"textarea"}
            form={PERSONAL_INFORMATION_FORM}
            name="tell_more_data"
            placeholder="Tell us more"
            defaultValue={""}
            {...commonFormItemProps}
          />
        )}
        <SelectBusinessIndustries
          SelectComponent={WrapperSelectBusinessIndustryMultiLevel}
          name={"industry"}
          label="Industry"
          form={PERSONAL_INFORMATION_FORM}
          className={styles.select}
          {...commonFormItemProps}
          placeholder={"Industry"}
        />

        {/* <FormErrors form={PERSONAL_INFORMATION_FORM} /> */}
      </Form>
      {!props.isSingaporeAccount && (
        <Form
          name="company-form"
          layout="vertical"
          requiredMark={false}
          className={styles.personalFormDetail}
        >
          <div className={styles.includeTexts}>
            <p>
              Please upload a clear and in focus photo of the front of your
              valid ID (National ID/Passport/Driver’s License.)
            </p>
            <p className={styles.helperText}>
              Please ensure the details on your ID must be clearly visible.
            </p>
          </div>
          <InputFile
            allowedFileTypes={["jpg", "jpeg", "png", "heic"]}
            name={"supporting_documents"}
            purpose={"id_verification"}
            form={PERSONAL_INFORMATION_FORM}
            label={"Upload your front ID photo"}
            icon={IconUpload}
            canDrag={true}
            multiple={true}
            renderComponent={InputFileDropZone}
            defaultValue={[]}
          />
        </Form>
      )}
    </div>
  );
};
export default AdditionalDataForm;
