import { connect } from "react-redux";

import { RootState } from "src/ipm-shared/store/model/reducers";

import View from "./View";
import * as selectors from "src/bepaid/store/model/Fetch/selectors";
import * as actions from "src/bepaid/store/model/Fetch/actions";

const mapStateToProps = (state: RootState, props: any) => ({
  getControl: (name: string, form: string) =>
    selectors.getControl(state, name, form),
  extraInfo: selectors.getExtraInfo(
    state,
    actions.FetchModalID.CRYPTO_VERIFY_IDENTITY
  )
});

const mapDispatchToProps = {
  parseServerErrors: actions.parseServerErrors,
  toggleModal: actions.toggleModal
};

export type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(View);
