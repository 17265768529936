// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Message_wrapper__25cYr{background-color:#74747b;padding:0 !important}.Message_wrapper__25cYr.Message_success__3bVIx{background-color:#5a6be8}.Message_wrapper__25cYr.Message_error__xTvBT{background-color:#dc3545}.Message_wrapper__25cYr .ant-message-notice-content{background:none;-webkit-box-shadow:none;box-shadow:none;color:#fff;font-size:.875rem;font-weight:500;padding:.5rem}.Message_wrapper__25cYr .anticon{display:none}", "",{"version":3,"sources":["webpack://./src/bepaid/components/Message/Message.module.scss"],"names":[],"mappings":"AAAA,wBAAS,wBAAwB,CAAC,oBAAoB,CAAC,+CAAiB,wBAAwB,CAAC,6CAAe,wBAAwB,CAAC,oDAA6C,eAAe,CAAC,uBAAe,CAAf,eAAe,CAAC,UAAU,CAAC,iBAAiB,CAAC,eAAe,CAAC,aAAa,CAAC,iCAA0B,YAAY","sourcesContent":[".wrapper{background-color:#74747b;padding:0 !important}.wrapper.success{background-color:#5a6be8}.wrapper.error{background-color:#dc3545}.wrapper :global .ant-message-notice-content{background:none;box-shadow:none;color:#fff;font-size:.875rem;font-weight:500;padding:.5rem}.wrapper :global .anticon{display:none}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "Message_wrapper__25cYr",
	"success": "Message_success__3bVIx",
	"error": "Message_error__xTvBT"
};
export default ___CSS_LOADER_EXPORT___;
