import React from "react";
import { isDesktop } from "react-device-detect";
import _get from "lodash-es/get";
import _trim from "lodash-es/trim";
import Form from "src/bepaid/components/Form";
import Avatar from "antd/es/avatar";

import { PERSONAL_INFORMATION_FORM } from "src/bepaid/store/model/Fetch/const";

import InputText from "src/bepaid/components/Form/controls/InputText";
import Button from "src/bepaid/components/Form/controls/Button";
import { invokeMessage } from "src/bepaid/components/Message";

import AvatarDefault from "src/bepaid/assets/images/dashboard/avatar_default.svg";

import styles from "./PersonalInformation.module.scss";
import classNames from "classnames";

const FormComponent = (props: any) => {
  const {
    accountProfile,
    fetchAccountProfiles,
    updateAccountProfile,
    onSaved
  } = props;

  const firstName = _trim(_get(accountProfile, "displayedFirstName", ""));
  const lastName = _get(accountProfile, "displayedLastName", "");

  const [loading, setLoading] = React.useState(false);

  const onSubmit = () => {
    updateAccountProfile(
      PERSONAL_INFORMATION_FORM,
      (err: any) => {
        if (!err) {
          invokeMessage("success", "Changes saved.");
          if (onSaved) {
            onSaved();
          }
          fetchAccountProfiles(null, true);
        }
      },
      setLoading
    );
  };

  return (
    <Form
      name="personal-information-form"
      layout="vertical"
      requiredMark={false}
      className={classNames(styles.form)}
    >
      <div className={styles.formBody}>
        <InputText
          name="firstName"
          placeholder="First Name"
          form={PERSONAL_INFORMATION_FORM}
          label="First Name"
          defaultValue={firstName}
        />
        <InputText
          name="lastName"
          placeholder="Last Name"
          form={PERSONAL_INFORMATION_FORM}
          label="Last Name"
          defaultValue={lastName}
        />
      </div>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
          onClick={onSubmit}
        >
          {loading ? "Saving changes..." : "Save changes"}
        </Button>
      </Form.Item>
    </Form>
  );
};

const PersonalInformation: React.FC<any> = props => {
  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.wrapperDesktop]: isDesktop
      })}
    >
      {!isDesktop && <Avatar size={{ xs: 66 }} src={AvatarDefault} />}
      <FormComponent {...props} />
    </div>
  );
};

export default PersonalInformation;
