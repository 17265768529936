import { connect } from "react-redux";
import React, { useEffect, useState } from "react";

import { FORM } from "src/ipm-shared/store/model/Payee/const";
import ExpandBox from "../../ExpandBox";
import IconUpload from "src/bepaid/assets/images/common/icon_upload.svg";
import FormStyles from "src/bepaid/components/Form/CommonForm.module.scss";
import InputFile from "src/bepaid/components/Form/controls/InputFile";
import InputFileDropZone from "src/bepaid/components/Form/controls/InputFile/components/InputFileDropZone";
import Form from "src/bepaid/components/Form";
import InputText from "src/bepaid/components/Form/controls/InputText";

import CurrencyUtil from "src/ipm-shared/Utils/Currency";
import FileUtils from "src/ipm-shared/Utils/Files";
import { useMakePaymentUrlParams } from "src/bepaid/pages/Payments/hooks";
// import T from "src/ipm-shared/Utils/Intl";
import _isEmpty from "lodash-es/isEmpty";
import _get from "lodash-es/get";

import * as actions from "../actions";
import * as selectors from "../selectors";

import { RootState } from "src/ipm-shared/store/model/reducers";

import styles from "../../ExpandBox/ExpandBox.module.scss";

const SalaryForm = ({
  accountCountryCode,
  accountCurrencyId,
  payee,
  validateAmount,
  checkValidNoteToRecipient,
  filesControl,
  selectedPayment
}: any) => {
  const [currencyId] = useState(accountCurrencyId);
  const selectedPaymentPayee = _get(selectedPayment, "payees", []).find(
    (p: any) => p.id === payee.id
  );
  const { entity } = useMakePaymentUrlParams();

  const [supportingDocuments, setSupportingDocuments] = useState<any>({
    fetch: false,
    value: []
  });

  const setAmountValue = (id: number, e: any) => {
    const amount = e.target.value;

    validateAmount({
      amount: parseFloat(amount) * 100,
      form: FORM,
      fieldName: name,
      isWallet: true
    });
  };

  const onChangeComments = (id: number, e: any) => {
    const comment = e.target.value;
    checkValidNoteToRecipient(comment, e.target.name, true, entity, () => {});
  };

  useEffect(() => {
    if (!_isEmpty(filesControl)) {
      const data = FileUtils.getInfoFromKeys(
        _get(filesControl, `${payee.id}`, [])
      );
      setSupportingDocuments({
        ...supportingDocuments,
        fetch: true,
        value: data
      });
    } else {
      setSupportingDocuments({
        ...supportingDocuments,
        fetch: true
      });
    }
  }, []);

  return (
    <Form requiredMark={false} layout="vertical">
      <div className={styles.inputMoney}>
        <InputText
          labelNoColon={true}
          name={`supplier_amount_${payee.id}`}
          placeholder={`0.00`}
          label={"Total salary amount"}
          prefix={CurrencyUtil.convertFromId(currencyId as number).$}
          // defaultValue={
          //   selectedPaymentPayee
          //     ? _get(selectedPaymentPayee, "defaultAmount") / 100
          //     : ""
          // }
          form={FORM}
          pattern={"__MONEY__"}
          revertValueOnMount={true}
          reserveValueOnUnmount={true}
          onChange={setAmountValue.bind(null, payee.id)}
        />
      </div>

      <InputText
        labelNoColon={true}
        name={`default_comments_${payee.id}`}
        placeholder={"Description to appear on your company’s statement"}
        label={"Salary reference for your bank statement"}
        defaultValue={_get(selectedPaymentPayee, "defaultComments", "")}
        form={FORM}
        pattern={"__STATEMENT__"}
        revertValueOnMount={true}
        reserveValueOnUnmount={true}
        onChange={onChangeComments.bind(null, payee.id)}
      />
      <>
        <p className={styles.invoiceFieldInstruction}>
          <b>
            {accountCountryCode !== "SG"
              ? "Latest payroll records"
              : "Latest CPF record and payroll system records"}
          </b>{" "}
          must include:
        </p>
        <ul>
          {/* <li>
            <span>{T.transl("BANK_STATEMENT_NAME")}</span>
          </li>
          <li>
            <span>{T.transl("BANK_STATEMENT_ACCOUNT")}</span>
          </li>
          <li>
            <span>{T.transl("BANK_STATEMENT_DATE")}</span>
          </li> */}

          <li>
            <span>Employee’s Names</span>
          </li>
          <li>
            <span>Employee’s Salaries</span>
          </li>
        </ul>
      </>
      {supportingDocuments.fetch && (
        <InputFile
          formItemLabel={""}
          name={`supporting_documents_${payee.id}`}
          btnClassName={FormStyles.boxShadow}
          allowedFileTypes={["jpg", "png", "heic", "pdf"]}
          purpose="bank_statement"
          form={FORM}
          label={"Upload Bank Statement"}
          icon={IconUpload}
          canDrag={true}
          multiple={true}
          renderComponent={InputFileDropZone}
          revertValueOnMount={true}
          reserveValueOnUnmount={true}
        />
      )}
    </Form>
  );
};

const View = (props: any) => {
  return (
    <ExpandBox name={`${_get(props.payee, "name")}`} hideExpandBtn={true}>
      <SalaryForm {...props} />
    </ExpandBox>
  );
};

const mapDispatchToProps = {
  validateAmount: actions.validateAmount,
  checkValidNoteToRecipient: actions.checkValidNoteToRecipient
};

const mapStateToProps = (state: RootState) => ({
  filesControl: selectors.getFileControl(
    state,
    selectors.getControlsPattern(state, /^document_tag_/),
    "document_tag_"
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(View);
