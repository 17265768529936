import { connect } from "react-redux";

import View from "./View";
import { RootState } from "src/ipm-shared/store/model/reducers";

import { SelfTransfer } from "src/ipm-shared/store/model/Payee/reducers";
import * as selectors from "./selectors";
import * as actions from "./actions";
import { PayeeTypeEx } from "src/ipm-shared/store/model/Payee/selectors";

const mapStateToProps = (state: RootState, props: any) => ({
  extraPayees: selectors.getExtraPayees(state),
  selectedPayees: selectors.getSelectedPayees<SelfTransfer & PayeeTypeEx>(
    state
  ),
  selectedPayeesId: selectors.selectedPayee(state),
  accountCountryCode: selectors.getAccountCountryCode(state),
  accountCountryId: selectors.getAccountCountryId(state),
  paymentCurrencyCode: selectors.getPaymentCurrencyCode(state),
  selectedPayment: selectors.getBepaidSelectedPaymentDetail(state),
  isCryptoPaymentMethod: selectors.isCryptoPaymentMethod(state)
});

const mapDispatchToProps = {
  closeModal: actions.closeModal,
  deleteExtraPayee: actions.deleteExtraPayee,
  removeControlPattern: actions.removeControlPattern,
  setControl: actions.setControl,
  toggleModal: actions.toggleModal,
  updateExtraPayee: actions.updateExtraPayee,
  updateExtraPayees: actions.updateExtraPayees,
  updatePayeeCurrencyId: actions.updatePayeeCurrencyId,
  validateAmount: actions.validateAmount,
  validatePaymentPayeeSubmit: actions.validatePaymentPayeeSubmit,
  addPaymentRequestSubmit: actions.addPaymentRequestSubmit,
  fetchDateConditions: actions.fetchDateConditions
};

export default connect(mapStateToProps, mapDispatchToProps)(View);
