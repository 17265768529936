/* tslint:disable:object-literal-sort-keys jsx-no-lambda */
import React, { useCallback, useEffect, useState } from "react";
import { isDesktop } from "react-device-detect";
import _get from "lodash-es/get";
import debounce from "lodash-es/debounce";
import _isEqual from "lodash-es/isEqual";
import classNames from "classnames";
import Divider from "antd/es/divider";

import FormErrors from "src/ipm-shared/components/Form/helpers/Errors";
import CountryUtil from "src/ipm-shared/Utils/Country";
import { ADD_FORM } from "src/ipm-shared/store/model/Customer/const";

import Form from "src/bepaid/components/Form";
import InputText from "src/bepaid/components/Form/controls/InputText";
import InputMobile from "src/bepaid/components/Form/controls/InputMobile";
import Progress from "src/bepaid/components/ProgressStep";
import ToolTipArrow from "src/bepaid/components/ToolTipArrow";
import Tooltip from "src/ipm-shared/components/Tooltip";
import Image from "src/bepaid/components/Image";
import usePrevious from "src/bepaid/hooks/usePrevious";
import { getCompletedProfileCustomer } from "src/bepaid/utils/customer";

import SelectGroup from "../SelectGroup";
import IconArrowDown from "src/bepaid/assets/images/common/arrow_down.svg";
import IconArrowUp from "src/bepaid/assets/images/common/arrow_up.svg";
import IconCheckCircle from "src/bepaid/assets/images/customers/progress/check-circle.svg";
import IconCheckCircleActive from "src/bepaid/assets/images/customers/progress/check-circle-active.svg";
import IconPerson from "src/bepaid/assets/images/customers/progress/person.svg";
import IconPersonActive from "src/bepaid/assets/images/customers/progress/person-active.svg";

import AdditionalInfo from "./CustomerAdditionalInfo";

import styles from "./AddCustomer.module.scss";

const steps = [
  {
    percent: 0,
    icon: () => <Image src={IconCheckCircle} />,
    iconPassed: () => <Image src={IconCheckCircleActive} />
  },
  {
    percent: 28,
    icon: () => <Image src={IconCheckCircle} />,
    iconPassed: () => <Image src={IconCheckCircleActive} />
  },
  {
    percent: 56,
    icon: () => <Image src={IconCheckCircle} />,
    iconPassed: () => <Image src={IconCheckCircleActive} />
  },
  {
    percent: 70,
    icon: () => <Image src={IconCheckCircle} />,
    iconPassed: () => <Image src={IconCheckCircleActive} />
  },
  {
    percent: 84,
    icon: () => <Image src={IconCheckCircle} />,
    iconPassed: () => <Image src={IconCheckCircleActive} />
  },
  {
    percent: 100,
    icon: () => <Image src={IconPerson} className={styles.checkFinish} />,
    iconPassed: () => (
      <Image src={IconPersonActive} className={styles.checkFinish} />
    )
  }
];

const AddCustomer: React.FC<any> = props => {
  const [expand, isExpand] = useState(false);

  const handleToggleAdditionalInfo = () => {
    isExpand(!expand);
  };

  const [form, setForm] = useState({});

  const [stepIndex, setStepIndex] = useState(0);
  const prevForm = usePrevious(form);

  const [
    optionCustomerCommunicationMethod,
    setOptionCustomerCommunicationMethod
  ] = useState("email");

  const debounced = useCallback(
    debounce((formData: any) => {
      checkProgress(formData);
    }, 300),
    []
  );

  const checkProgress = (formData: any) => {
    const customer =
      props.listCustomer[
        props.listCustomer.findIndex(
          (customer: any) => customer.id === formData.customerId
        )
      ];
    const percent = getCompletedProfileCustomer({
      name: formData.customerName,
      email: formData.customerEmail,
      mobileNumber: formData.mobileNumber,
      phoneNumber: formData.phoneNumber,
      companyName: formData.companyName,
      registrationNumber: formData.registrationNumber,
      addressLine1: formData.address1,
      addressLine2: formData.address2,
      customerCommunicationMethod: isEdit
        ? customer?.primaryCommunicationMethod
        : formData.customerCommunicationMethod
    });

    const index = steps.findIndex(step => step.percent === percent);
    setStepIndex(index);
  };

  const updateForm = (name: string, value: string) => {
    setForm(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleChangeInput = (name: string) => (event: any) => {
    updateForm(name, event.target.value);
  };

  const handleChangeOptionCustomer = (name: string) => (event: any) => {
    setOptionCustomerCommunicationMethod(event.target.value);
    updateForm(name, event.target.value);
    props.resetControlErrors(
      event.target.value === "email" ? "mobile" : "email"
    );
  };

  const renderAdditionalForm = () => {
    return (
      <AdditionalInfo
        form={ADD_FORM}
        input={form}
        onChangeInput={updateForm}
        isEdit={isEdit}
      />
    );
  };

  const { isEdit, customerPrefilled } = props;

  useEffect(() => {
    if (!_isEqual(form, prevForm)) {
      debounced(form);
    }
  }, [form, prevForm]);

  useEffect(() => {
    setForm({
      ...form,
      customerId: _get(props, "data.id", null),
      customerName: _get(props, "data.name", ""),
      customerEmail: _get(props, "data.email", ""),
      companyName: _get(props, "data.companyName", ""),
      mobileNumber: _get(props, "data.mobileNumber", ""),
      mobileCountryCode: CountryUtil.getCountryCodeFromCountryId(
        _get(props, "data.mobileCountryId", props.countryId)
      ),
      phoneNumber: _get(props, "data.phoneNumber", ""),
      phoneCountryCode: CountryUtil.getCountryCodeFromCountryId(
        _get(props, "data.phoneCountryId", props.countryId)
      ),
      registrationNumber: _get(props, "data.registrationNumber", ""),
      address1: _get(props, "data.addressLine1", ""),
      address2: _get(props, "data.addressLine2", ""),
      customerCommunicationMethod: _get(
        props,
        "data.preferredComunicationMethod",
        "email"
      )
    });
  }, [props.data]);

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.wrapperDesktop]: isDesktop
      })}
    >
      <div className={styles.formWrapper}>
        <div className={styles.content}>
          <Progress stepIndex={stepIndex} steps={steps} />

          <FormErrors
            form={ADD_FORM}
            className={styles.formErrors}
            ignoreErrors={[
              "PAYER_EXIST",
              "Mobile or Email needs to be provided",
              "INVALID_EMAIL_ADDRESS"
            ]}
            exception={{
              PAYER_EXIST: {
                putToField: "email"
              },
              "Mobile or Email needs to be provided": {
                putToField: "email",
                code: "Please enter customer email",
                message: "Please enter customer email"
              },
              INVALID_EMAIL_ADDRESS: {
                putToField: "email"
              }
            }}
          />

          <Form
            layout="vertical"
            requiredMark={false}
            className={styles.personalFormDetail}
          >
            <InputText
              form={ADD_FORM}
              name="customer_name"
              placeholder="Customer full legal name"
              label="Customer full legal name"
              onChange={handleChangeInput("customerName")}
              defaultValue={
                _get(form, "customerName") || _get(customerPrefilled, "name")
              }
            />
            <InputText
              form={ADD_FORM}
              name="email"
              pattern="__EMAIL__"
              placeholder="Customer email"
              label={
                <>
                  <p>Customer email</p>
                  <Tooltip
                    description={
                      "Fetch incentives are available only for customers with an email address."
                    }
                    displayIcon={true}
                    iconColor="gray"
                    target={`random`}
                    className={styles.tooltip}
                    width={16}
                  />
                </>
              }
              onChange={handleChangeInput("customerEmail")}
              defaultValue={_get(form, "customerEmail")}
            />
            {!isEdit && (
              <>
                <InputMobile
                  form={form}
                  name="mobile"
                  nameCode="mobile_country_code"
                  placeholder={`Mobile number`}
                  label="Mobile number"
                  defaultValue={_get(form, "mobileNumber")}
                  code={_get(form, "mobileCountryCode")}
                  onChange={handleChangeInput("mobileNumber")}
                />
                <div className={styles.wrapperOptionCustomer}>
                  <p className={styles.additionalTitle}>
                    Select your preferred communication method
                  </p>
                  <SelectGroup
                    name={"communication_method"}
                    form={ADD_FORM}
                    className={styles.groupOptionCustomer}
                    optionType={"default"}
                    options={[
                      { label: "Email", value: "email" },
                      { label: "Mobile number", value: "mobile_number" }
                    ]}
                    onChange={handleChangeOptionCustomer(
                      "customerCommunicationMethod"
                    )}
                    value={optionCustomerCommunicationMethod}
                    defaultValue={"email"}
                  />
                </div>
              </>
            )}
            {isEdit && (
              <>
                <InputText
                  hidden={true}
                  className={styles.formHidden}
                  form={ADD_FORM}
                  name="customer_id"
                  defaultValue={_get(form, "customerId")}
                />
                {renderAdditionalForm()}
              </>
            )}
          </Form>

          {!isEdit && (
            <>
              <div className={styles.textMoreAbout}>
                <ToolTipArrow
                  text={"Tell us more about your customer to get paid faster!"}
                />
              </div>

              <Form
                layout="vertical"
                requiredMark={false}
                className={classNames(
                  styles.personalFormDetail,
                  styles.formAdditionalInformation
                )}
              >
                <div className={styles.additionalInformation}>
                  <p className={styles.additionalTitle}>
                    Additional Information
                  </p>
                  <div className={styles.additionalIcon}>
                    <Image
                      src={expand ? IconArrowUp : IconArrowDown}
                      onClick={handleToggleAdditionalInfo}
                    />
                  </div>
                </div>
                {/* {expand && ( */}
                <div className={styles.extraContent} hidden={!expand}>
                  <Divider />
                  {renderAdditionalForm()}
                </div>
                {/* )} */}
              </Form>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddCustomer;
