import { getType } from "typesafe-actions";
import * as types from "./types";
import * as actions from "./actions";
import _isEmpty from "lodash-es/isEmpty";

export type PaymentCollectionRequestType = {
  currencyId: number;
  subTotal: number;
  totalAmount?: number;
  nationFee?: number;
  overseaFee?: number;
  cryptoFee?: number;
  feePayer: number;
  incentives?: Incentive;
  isProduction: boolean;
  isCharged?: boolean;
  isCancelled?: boolean;
  isOverdue?: boolean;
  isOnScreening?: boolean;
  requests: RequestType[];
  receiptNumber?: string;
  companyName?: string;
  token: string;
  cardCode?: string;
  statementDescriptor: string;
  paymentCountryId: number;
  purposeId?: number;
  companyLogoBase64?: string;
  returnUrl?: string;
  customerFee?: CustomerFee[];
  dueDate?: string;
  paymentId?: number;
};

export type Incentive = {
  incentiveTypeId: string;
  rate: number;
  fee: number;
  emailId?: number;
  isFirstInstallmentPlanPayment?: boolean;
  deadline?: string;
  installmentPlanNumber?: number;
};

export type RequestType = {
  invoiceNumber: string;
  amount: number;
  currencyId: number;
  amountTotal?: number;
  commissionFee?: number;
  isSecondary?: boolean;
  recipientName?: string;
};

export type CustomerFee = {
  brandId: number;
  currency: number;
  nationFee: number;
  overseaFee: number;
  cryptoFee: number;
  feePayer: number;
  intFeePayer: number;
};
export type PaymentCollectionRequestState = {
  readonly isFetching: boolean;
  readonly payment: PaymentCollectionRequestType;
  readonly acquirerId: number;
  readonly paymentCollectionMethodType: string;
  readonly paymentCollectionMethodId?: number;
};

const defaultState: PaymentCollectionRequestState = {
  acquirerId: 0,
  isFetching: false,
  payment: {
    cardCode: "",
    currencyId: 0,
    customerFee: [],
    feePayer: 0,
    isCharged: false,
    isProduction: false,
    nationFee: 0,
    overseaFee: 0,
    cryptoFee: 0,
    paymentCountryId: 0,
    requests: [],
    statementDescriptor: "",
    subTotal: 0,
    token: "",
    totalAmount: 0
  },
  paymentCollectionMethodType: "",
  paymentCollectionMethodId: 0
};

export default (state: PaymentCollectionRequestState, action: types.Action) => {
  if (_isEmpty(state)) {
    state = defaultState;
  }

  switch (action.type) {
    case getType(actions.setPaymentCollectionRequest):
      const updatedPayment = action.payload.payment;
      return {
        ...state,
        acquirerId: action.payload.acquirerId,
        isFetching: action.payload.isFetching,
        payment: {
          ...state.payment,
          ...updatedPayment
        }
      };
    case getType(actions.setPaymentCollectionFees):
      return {
        ...state,
        payment: {
          ...state.payment,
          nationFee: action.payload.nationFee,
          overseaFee: action.payload.overseaFee,
          cryptoFee: action.payload.cryptoFee,
          customerFee: action.payload.customerFee
        }
      };
    case getType(actions.setCardCode):
      return {
        ...state,
        payment: {
          ...state.payment,
          cardCode: action.payload.cardCode
        }
      };
    case getType(actions.setPaymentCollectionIncentives):
      return {
        ...state,
        payment: {
          ...state.payment,
          incentives: action.payload.incentives
        }
      };
    case getType(actions.setPaymentCollectionMethodType):
      return {
        ...state,
        paymentCollectionMethodType: action.payload.methodType
      };
    case getType(actions.setPaymentCollectionMethodId):
      return {
        ...state,
        paymentCollectionMethodId: action.payload.id
      };
  }
  return state;
};
