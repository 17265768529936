// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AccountDetails_wrapperRadioGroup__30ULu{height:42px}.AccountDetails_textDescription__2Vg_f{margin-top:.5rem !important}.AccountDetails_restrictedText__1Lbrb{text-decoration:underline !important;color:#624ce0 !important}.AccountDetails_documentHelperText__pgSN2{margin-bottom:.3rem !important}", "",{"version":3,"sources":["webpack://./src/bepaid/pages/KYC/components/AccountDetails/AccountDetails.module.scss"],"names":[],"mappings":"AAAA,yCAAmB,WAAW,CAAC,uCAAiB,2BAA2B,CAAC,sCAAgB,oCAAoC,CAAC,wBAAwB,CAAC,0CAAoB,8BAA8B","sourcesContent":[".wrapperRadioGroup{height:42px}.textDescription{margin-top:.5rem !important}.restrictedText{text-decoration:underline !important;color:#624ce0 !important}.documentHelperText{margin-bottom:.3rem !important}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapperRadioGroup": "AccountDetails_wrapperRadioGroup__30ULu",
	"textDescription": "AccountDetails_textDescription__2Vg_f",
	"restrictedText": "AccountDetails_restrictedText__1Lbrb",
	"documentHelperText": "AccountDetails_documentHelperText__pgSN2"
};
export default ___CSS_LOADER_EXPORT___;
