/* tslint:disable:object-literal-sort-keys jsx-no-lambda */
import React from "react";
import { isDesktop } from "react-device-detect";
import classNames from "classnames";
import * as uuidv1 from "uuid/v1";
import Typography from "antd/es/typography";

// import {history} from "src/ipm-shared/store";

// import ROUTES from "src/bepaid/routes";
import * as localStorageUtil from "src/bepaid/utils/localStorage";
import Button from "src/bepaid/components/Form/controls/Button";
import Image from "src/bepaid/components/Image";

import AddEditCompany from "./components/AddEditCompany";
import { MODES } from "./const";

import NextIcon from "src/bepaid/assets/images/common/icon_next.svg";

import styles from "./Companies.module.scss";

const Item = ({ item, onClick }: any) => (
  <>
    <div className={styles.itemWrapper} onClick={() => onClick(item)}>
      <Typography.Text className={styles.text}>{item.name}</Typography.Text>
      <Image src={NextIcon} className={styles.nextIcon} />
    </div>
  </>
);

const Companies: React.FC<any> = props => {
  const { companies, fetchCompanies, onChangeMode } = props;

  const [mode, setModeState] = React.useState(MODES.SHOW_COMPANY_LIST);

  const [selectedCompany, setSelectedCompany] = React.useState({
    id: "",
    name: "",
    code: "",
    countryCode: props.countryCode,
    payeeData: {
      businessType: null,
      businessIndustry: null
    }
  });

  const setMode = (m: any) => {
    setModeState(m);
    if (onChangeMode) {
      onChangeMode(m);
    }
  };

  React.useEffect(() => {
    fetchCompanies(true);
  }, []);

  const handleAddNewCompany = () => {
    // if (!companies.length) {
    //   history.push(`${ROUTES.KYC}/?firstLogin=0`);
    //   return;
    // }
    setMode(MODES.ADD_NEW_COMPANY);
  };

  const handleClickCompanyDetail = (company: any) => {
    if (props.switchMode) {
      props.exchangeToken({
        cb: () => {
          localStorageUtil.setDisplaySplashScreen(true);
          window.location.reload();
        },
        companyId: company.id,
        purpose: "switch_company"
      });
    } else {
      Promise.resolve()
        .then(() => {
          setSelectedCompany({
            id: company.id,
            name: company.name,
            code: company.registrationNumber,
            countryCode: props.countryCode,
            payeeData: {
              businessType: company.businessType,
              businessIndustry: company.businessIndustry
            }
          });
        })
        .then(() => {
          setMode(MODES.EDIT_EXISTING_COMPANY);
        });
    }
  };

  if ([MODES.ADD_NEW_COMPANY, MODES.EDIT_EXISTING_COMPANY].includes(mode)) {
    return (
      <AddEditCompany
        mode={mode}
        companyData={selectedCompany}
        setMode={setMode}
      />
    );
  }

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.desktopWrapper]: isDesktop
      })}
    >
      <div className={styles.list}>
        {companies
          .filter((company: any) => Boolean(company.name))
          .map((company: any) => (
            <Item
              key={uuidv1()}
              item={company}
              onClick={handleClickCompanyDetail}
            />
          ))}
      </div>
      <Button
        className={styles.btnSubmit}
        type="primary"
        htmlType="button"
        onClick={handleAddNewCompany}
      >
        Add new company
      </Button>
    </div>
  );
};

export default Companies;
