// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AccountSettingsModal_wrapper__2pGjE .AccountSettingsModal_content__1vLdR{text-align:left;width:100%;margin:auto}", "",{"version":3,"sources":["webpack://./src/bepaid/pages/Profile/components/AccountSettingsModal/AccountSettingsModal.module.scss"],"names":[],"mappings":"AAAA,0EAAkB,eAAe,CAAC,UAAU,CAAC,WAAW","sourcesContent":[".wrapper .content{text-align:left;width:100%;margin:auto}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "AccountSettingsModal_wrapper__2pGjE",
	"content": "AccountSettingsModal_content__1vLdR"
};
export default ___CSS_LOADER_EXPORT___;
