// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ProfileHeading_profile__28Hy7{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center}.ProfileHeading_profile__28Hy7 .ant-avatar{width:4rem;height:auto}.ProfileHeading_profile__28Hy7 .ant-avatar:hover{cursor:pointer}.ProfileHeading_profile__28Hy7 .ProfileHeading_info__2BD3W{margin-left:1rem;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column}.ProfileHeading_profile__28Hy7 .ProfileHeading_info__2BD3W .ProfileHeading_nameText__btqXk{font-size:1.25rem;font-weight:500;color:#242424}.ProfileHeading_profile__28Hy7 .ProfileHeading_info__2BD3W .ProfileHeading_emailText__1G5XM{font-size:1rem;color:#74747b !important;line-height:1.5rem}", "",{"version":3,"sources":["webpack://./src/bepaid/layouts/MainLayout/components/ProfileHeading/ProfileHeading.module.scss"],"names":[],"mappings":"AAAA,+BAAS,mBAAY,CAAZ,YAAY,CAAC,qBAAiB,CAAjB,kBAAkB,CAAC,2CAA6B,UAAU,CAAC,WAAW,CAAC,iDAAmC,cAAc,CAAC,2DAAe,gBAAgB,CAAC,mBAAY,CAAZ,YAAY,CAAC,yBAAoB,CAApB,qBAAqB,CAAC,2FAAyB,iBAAiB,CAAC,eAAe,CAAC,aAAa,CAAC,4FAA0B,cAAc,CAAC,wBAAwB,CAAC,kBAAkB","sourcesContent":[".profile{display:flex;align-items:center}.profile :global .ant-avatar{width:4rem;height:auto}.profile :global .ant-avatar:hover{cursor:pointer}.profile .info{margin-left:1rem;display:flex;flex-direction:column}.profile .info .nameText{font-size:1.25rem;font-weight:500;color:#242424}.profile .info .emailText{font-size:1rem;color:#74747b !important;line-height:1.5rem}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"profile": "ProfileHeading_profile__28Hy7",
	"info": "ProfileHeading_info__2BD3W",
	"nameText": "ProfileHeading_nameText__btqXk",
	"emailText": "ProfileHeading_emailText__1G5XM"
};
export default ___CSS_LOADER_EXPORT___;
