import {
  ForkEffect,
  HelperFunc0,
  takeEvery,
  takeLatest
} from "redux-saga/effects";
import { delay } from "redux-saga";
import HttpRequestError from "src/ipm-shared/Utils/Exceptions/HttpRequestError";

import { Action } from "redux";
import {
  ActionCreator,
  StringType,
  TypeMeta
} from "typesafe-actions/src/types";
import { getType } from "typesafe-actions";

export const reTryTakeLatest = <T extends StringType, A extends Action>(
  action: ActionCreator<T> & TypeMeta<T>,
  handler: HelperFunc0<A>,
  time: number = 3
): ForkEffect => {
  let loading = false;
  function* handlerCatcher(args: any, retry = 0): any {
    if (args.payload && args.payload.loadingFunc) {
      loading = true;
      args.payload.loadingFunc(true);
    }

    if (retry === time) {
      window.Logger.warn(`Ipaymy: Saga retried ${retry} times. Will stop`);
      return;
    }

    try {
      yield handler(args);
    } catch (e) {
      if (e instanceof HttpRequestError) {
        retry++;

        window.Logger.warn(
          `Ipaymy: Retry saga ${retry} time in 3s`,
          getType(action),
          e.message
        );

        yield delay(3000);

        yield handlerCatcher(args, retry);
      } else {
        window.Logger.warn("Ipaymy: Saga failed", getType(action), e.message);
      }
    }

    if (args.payload && args.payload.loadingFunc) {
      if (loading) {
        args.payload.loadingFunc(false);
      }
    }

    loading = false;
  }

  return takeLatest(getType(action), handlerCatcher);
};

export const catchTakeLatest = <T extends StringType, A extends Action>(
  action: ActionCreator<T> & TypeMeta<T>,
  handler: HelperFunc0<A>
): ForkEffect => {
  function* handlerCatcher(args: any): any {
    if (args.payload && args.payload.loadingFunc) {
      yield args.payload.loadingFunc(true);
    }

    try {
      yield handler(args);
    } catch (e) {
      window.Logger.error("Ipaymy: Saga failed", getType(action), e.message);
    }

    if (args.payload && args.payload.loadingFunc) {
      yield args.payload.loadingFunc(false);
    }
  }

  return takeLatest(getType(action), handlerCatcher);
};

export const catchTakeEvery = <T extends StringType, A extends Action>(
  action: ActionCreator<T> & TypeMeta<T>,
  handler: HelperFunc0<A>
): ForkEffect => {
  function* handlerCatcher(args: any): any {
    if (args.payload && args.payload.loadingFunc) {
      args.payload.loadingFunc(true);
    }

    try {
      yield handler(args);
    } catch (e) {
      window.Logger.error("Ipaymy: Saga failed", getType(action), e.message);
    }

    if (args.payload && args.payload.loadingFunc) {
      args.payload.loadingFunc(false);
    }
  }

  return takeEvery(getType(action), handlerCatcher);
};

export type LoadingFunc = (loading: boolean) => void;
