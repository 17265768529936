import _get from "lodash-es/get";
import _isEmpty from "lodash-es/isEmpty";
import { ActionType } from "typesafe-actions";
import { select, call, put } from "redux-saga/effects";
import * as actions from "./actions";
import { RootState } from "src/ipm-shared/store/model/reducers";
import RestClient from "src/ipm-shared/services/Rest";
import { RESET_PASSWORD_FORM } from "./const";
import * as formSelectors from "src/ipm-shared/components/Form/selectors";
import * as formActions from "src/ipm-shared/components/Form//actions";
import T from "src/ipm-shared/Utils/Intl";
import { history } from "src/ipm-shared/store";
import * as queryString from "query-string";
import { catchTakeLatest } from "src/ipm-shared/Utils/ReduxSagaEffects";

const watchedSagas = [
  catchTakeLatest(
    actions.requestResetPasswordSubmit,
    handleRequestResetPasswordSubmit
  ),
  catchTakeLatest(actions.setNewPasswordSubmit, handleSetNewPasswordSubmit)
];
export default watchedSagas;

export function* handleRequestResetPasswordSubmit(
  action: ActionType<typeof actions.requestResetPasswordSubmit>
) {
  const state: RootState = yield select();
  const formState = formSelectors.getControls(state, RESET_PASSWORD_FORM);

  const email = _get(formState, "email.value", "");

  const { cb = () => null } = action.payload || {};

  if (!email) {
    yield put(
      formActions.setControl({
        errors: [
          {
            code: "0",
            message: T.transl("REQUIRED_FIELD")
          }
        ],
        form: RESET_PASSWORD_FORM,
        name: "email"
      })
    );

    return;
  }

  const res = yield call(RestClient.send, {
    body: {
      email: _get(formState, "email.value")
    },
    service: "create_reset_password_request",
    showGlobalLoader: true
  });

  if (!res) {
    return;
  }

  const errors = _get(res, "errors", undefined);

  if (!_isEmpty(errors) && errors) {
    yield put(formActions.parseServerErrors(errors, RESET_PASSWORD_FORM));
    return;
  }

  const { redirectUrl } = action.payload;

  cb(null);

  history.push(redirectUrl || "/reset-password/instructions");
}

export function* handleSetNewPasswordSubmit(
  action: ActionType<typeof actions.setNewPasswordSubmit>
) {
  const state: RootState = yield select();
  const formState = formSelectors.getControls(state, RESET_PASSWORD_FORM);
  const password = _get(formState, "password.value");
  const token = _get(formState, "token.value");
  const passwordConfirm = _get(formState, "password_confirm.value");

  const isPasswordError = _get(formState, "password.errors", []).length > 0;

  const { cb = () => null } = action.payload || {};

  if (
    isPasswordError &&
    _get(formState, "password_allow_weak.value") !== "allowed"
  ) {
    cb("password_allow_weak");
    return;
  }

  if (password !== passwordConfirm) {
    yield put(
      formActions.parseServerErrors(
        {
          fields: {
            password_confirm: [T.transl("CONFIRM_PASSWORD_ERROR")]
          },
          form: []
        },
        RESET_PASSWORD_FORM
      )
    );

    cb("CONFIRM_PASSWORD_ERROR");
    return;
  }

  const res = yield call(RestClient.send, {
    body: {
      allow_weak: _get(formState, "password_allow_weak.value") === "allowed",
      password,
      token
    },
    service: "reset_password",
    showGlobalLoader: true
  });

  if (!res) {
    return;
  }

  const errors = _get(res, "errors", undefined);
  const email = _get(res, "data.email", "");

  if (!_isEmpty(errors) && errors) {
    yield put(formActions.parseServerErrors(errors, RESET_PASSWORD_FORM));
    return;
  }

  cb(null);

  history.push(
    `/login?${queryString.stringify({
      email
    })}`
  );

  // yield put(
  //   actions.toast(T.transl({ id: "SUCCESS_PASSWORD_EDITED" }))
  // );
}
