import Cookie from "./Cookie";

const LANG_KEY = "ipm-lang";

export const LANGUAGE_OPTIONS = {
  en: { value: "en", label: "English" },
  zhhk: { value: "zh-hk", label: "繁體中文" },
  zlm: { value: "zlm", label: "Melayu" }
};

function filterLanguage(lang: string): string {
  const filteredLang = lang.toLowerCase().match(/^[^(,|;)]*/i);

  let res = "en";
  if (filteredLang && filteredLang[0]) {
    res = filteredLang[0];
  }

  switch (res) {
    case "zh":
    case "zh-hk":
    case "zh-hant":
    case "zh-tw":
    case "zh-sg":
    case "yue-hk":
    case "zh-cn":
    case "zh-hans":
      return LANGUAGE_OPTIONS.zhhk.value;
    case "zlm":
      return LANGUAGE_OPTIONS.zlm.value;
    case "en":
    case "en-us":
    case "en-gb":
    default:
      return LANGUAGE_OPTIONS.en.value;
  }
}
function getLanguage() {
  return Cookie.getCookie(LANG_KEY);
}
function setDefaultLanguage() {
  if (getLanguage()) {
    return;
  }

  const navigatorLanguage = navigator.language;
  const lang = filterLanguage(navigatorLanguage);
  Cookie.setCookie(LANG_KEY, lang);
}
function changeLanguage(lang: string = "en") {
  Cookie.setCookie(LANG_KEY, lang);
}
function checkLanguage(lang: string = "en") {
  return Cookie.getCookie(LANG_KEY) === lang;
}

export default {
  changeLanguage,
  checkLanguage,
  filterLanguage,
  getLanguage,
  setDefaultLanguage
};
