import React from "react";
import { isMobile } from "react-device-detect";
import classNames from "classnames";
import Steps from "antd/es/steps";

import { history } from "src/ipm-shared/store";
import DateUtils from "src/ipm-shared/Utils/Date";
import ShortCurrency from "src/ipm-shared/components/ShortCurrency";
import ShortCardInfo from "src/ipm-shared/components/ShortCardInfo";

import ROUTES from "src/bepaid/routes";
import Image from "src/bepaid/components/Image";

import IconReviewGreen from "src/bepaid/assets/images/common/icon_review_green.svg";

import { PaymentStatusPill } from "../../PaymentStatusPill";
import paymentUtil from "../utils/payment";

import styles from "./Styles.module.scss";

const { Step } = Steps;

export type Activity = {
  payoutDate: string;
  paymentStatusId: number;
  isCurrent: boolean;
  id: number;
  cardId: number;
  paymentTotal: number;
  currencyId: number;
  paymentAmount: number;
};

interface IProps {
  currentPaymentId: number;
  activities: Activity[];
  onScroll: () => {};
  isSchedule?: string;
}

const StepsActivity = (props: IProps) => {
  const { activities = [], currentPaymentId = 0, onScroll } = props;
  if (activities.length === 0) {
    return (
      <div className={styles.noResults}>
        <span>No results found.</span>
      </div>
    );
  }

  const hanldeOnClick = (activity: Activity) => {
    onScroll();
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set("idType", "id");
    if (props.isSchedule === "y") {
      urlParams.set("is_schedule", "y");
    } else {
      urlParams.delete("is_schedule");
    }
    const url = ROUTES.PAYMENT_DETAILS.replace(":id", activity.id.toString());
    history.push({
      pathname: url,
      search: "?" + urlParams.toString()
    });
  };

  return (
    <div className={styles.wrapperStep}>
      <Steps
        className={styles.stepActivity}
        direction="vertical"
        size="small"
        current={0}
      >
        {activities.map((activity: Activity, index: number) => {
          const date = DateUtils.formatLocaleDate(activity.payoutDate, {
            day: "numeric",
            month: "long",
            year: "numeric"
          });
          const paymentStatus = paymentUtil.getPaymentStatus({
            paymentStatusId: activity.paymentStatusId
          });

          const [icon, activatedIcon] = [IconReviewGreen, IconReviewGreen];
          return (
            <Step
              className={classNames(styles.stepItem, {
                [styles.disableItem]: currentPaymentId === activity.id
              })}
              key={index}
              onClick={hanldeOnClick.bind(null, activity)}
              icon={<Image src={activity.isCurrent ? activatedIcon : icon} />}
              description={
                <div className={styles.descriptionWrapper}>
                  <div className={styles.wrapperPaymentTitle}>
                    <p className={styles.step}>Payout Date</p>
                    <p className={styles.description}>{date}</p>
                  </div>

                  {activity.cardId && paymentStatus !== "Cancelled" && (
                    <div className={styles.wrapperPaymentCard}>
                      <p className={styles.step}>Card</p>
                      <div className={styles.cardIconContainer}>
                        <ShortCardInfo cardId={activity.cardId} />
                      </div>
                    </div>
                  )}
                  <div
                    className={classNames(styles.wrapperPaymentStatus, {
                      [styles.isMobile]: isMobile
                    })}
                  >
                    <PaymentStatusPill status={paymentStatus} />
                    {paymentStatus !== "Cancelled" && (
                      <div className={styles.wrapperCurrency}>
                        <b>
                          <ShortCurrency
                            value={
                              props.isSchedule === "y"
                                ? activity.paymentAmount
                                : activity.paymentTotal
                            }
                            currencyId={activity.currencyId}
                          />
                        </b>
                      </div>
                    )}
                  </div>
                </div>
              }
            />
          );
        })}
      </Steps>
    </div>
  );
};

export default StepsActivity;
