import { getType } from "typesafe-actions";
import * as types from "./types";
import * as actions from "./actions";
import _isEmpty from "lodash-es/isEmpty";
import currencyList from "src/ipm-shared/store/metadata/currency";
import normalize from "src/ipm-shared/Utils/NormalizeObject";

export type CurrencyType = {
  id: number;
  code: string;
  symbol: string;
};

export type CurrenciesState = {
  readonly byId: { [id: string]: CurrencyType };
};

const defaultState: CurrenciesState = {
  byId: normalize(
    currencyList.map(c => ({
      code: c.code.toUpperCase(),
      id: c.id,
      symbol: c.prefix_symbol || ""
    }))
  )
};

export default (state: CurrenciesState, action: types.Action) => {
  if (_isEmpty(state)) {
    state = defaultState;
  }

  switch (action.type) {
    case getType(actions.setCurrencies):
      return {
        ...state,
        byId: normalize(action.payload.currencies)
      };
  }
  return state;
};
