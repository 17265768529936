import { RouteProps } from "react-router";
import { connect } from "react-redux";
import { RootState } from "src/ipm-shared/store/model/reducers";
import * as selectors from "src/bepaid/store/model/Fetch/selectors";
import * as actions from "src/bepaid/store/model/Fetch/actions";

import Component from "./View";

const mapStateToProps = (state: RootState) => ({
  accountProfile: selectors.getCurrentAccountProfile(state)
});

const mapDispatchToProps = {
  changeEmail: actions.changeEmail,
  fetchAccountProfiles: actions.fetchAccountProfiles,
  resendOrCancelEmail: actions.resendOrCancelEmail
};

export type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteProps & { qs: { [param: string]: any } };

export default connect(mapStateToProps, mapDispatchToProps)(Component);
