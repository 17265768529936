import React from "react";
import Image from "src/bepaid/components/Image";
import styles from "./ExpandBox.module.scss";
import ExpandBox from "src/bepaid/components/ExpandBox";
import IconDelete from "src/bepaid/assets/images/common/icon_delete.svg";
import classNames from "classnames";

const View = ({
  name,
  children,
  onClickDelete,
  hideExpandBtn,
  onlyShowChildren
}: any) => {
  const originalContent = <div className={styles.name}>{name}</div>;
  const expandContent = (
    <>
      {onlyShowChildren ? (
        <div className={styles.form}>{children}</div>
      ) : (
        <>
          <div className={classNames(styles.name, styles.expand)}>{name}</div>
          <hr className={styles.hr} />
          <div className={styles.form}>{children}</div>
        </>
      )}
    </>
  );

  const addons = <Image src={IconDelete} onClick={onClickDelete} />;

  return (
    <div className={styles.wrapper}>
      <ExpandBox
        addonsLeft={onClickDelete ? addons : null}
        type="customer"
        view="right"
        originalContent={originalContent}
        expandContent={expandContent}
        hideExpandBtn={hideExpandBtn}
      />
    </div>
  );
};

export default View;
