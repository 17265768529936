import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import * as selectors from "src/ipm-shared/components/Form/selectors";
import { RootState } from "src/ipm-shared/store/model/reducers";
import * as actions from "src/ipm-shared/components/Form/actions";

import styles from "./Counter.module.scss";

const Counter = (props: any) => {
  const { defaultValue, step = 0.1 } = props;

  const ref = useRef<any>();
  const [counter, setCounterState] = useState(defaultValue || 0);

  const innerHTMLCounter = (n: number) => {
    ref.current.innerHTML = Number(defaultValue).toFixed(1);
  };

  const setCounter = (v: number) => {
    if (+v >= 0) {
      setCounterState(+v > 100 ? 100 : +v);
    } else {
      setCounterState(0);
    }
  };

  const increase = () => {
    setCounter(counter + step);
  };

  const decrease = () => {
    setCounter(counter - step);
  };

  const onChange = (e: any) => {
    const div = ref.current;
    if (div) {
      const n = +div.innerHTML;

      if (Number.isNaN(n)) {
        setCounter(counter);
        innerHTMLCounter(counter);
      } else {
        setCounter(+div.innerHTML);
      }
    }
  };

  useEffect(() => {
    if (counter && props.onChange) {
      props.onChange(counter);
    }
  }, [counter]);

  useEffect(() => {
    if (defaultValue) {
      props.onChange(Number(defaultValue));
      innerHTMLCounter(defaultValue);
    }
    setCounter(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    innerHTMLCounter(counter);
  }, [counter]);

  return (
    <div className={styles.wrapper}>
      <button className={styles.btn} onClick={increase}>
        +
      </button>
      <div className={styles.content}>
        <div
          ref={_ => {
            ref.current = _;
          }}
          className={styles.contentEdit}
          contentEditable={true}
          onBlur={onChange}
        />
        %
      </div>
      <button className={styles.btn} onClick={decrease}>
        -
      </button>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  getControl: (name: string, form: string) =>
    selectors.getControl(state, name, form)
});

const mapDispatchToProps = {
  setControl: actions.setControl
};

export default connect(mapStateToProps, mapDispatchToProps)(Counter);
