import * as React from "react";
import InputText from "src/ipm-shared/components/Form/controls/InputText";
import CardBrandLogo from "src/ipm-shared/components/CardBrandLogo";
import CardBankLogo from "src/ipm-shared/components/CardBankLogo";
import T from "src/ipm-shared/Utils/Intl";
import _includes from "lodash-es/includes";
import {
  CKO_ACCEPTED_CARD_BRANDS,
  HK_ACCEPTABLE_BRAND,
  MY_ACCEPTABLE_BRAND,
  SG_ACCEPTABLE_BRAND,
  BRAINTREE_ACCEPTED_CARD_BRANDS
} from "src/ipm-shared/store/model/CardBrand/const";
import CardUtils from "src/ipm-shared/Utils/Card";
import { CardInfo } from "src/ipm-shared/store/model/Card/types";
import { ADD_CARD_FORM } from "src/ipm-shared/store/model/Card/const";
import LockIcon from "src/ipm-shared/images/lock-icon.svg";
import { CardFormVersion } from "../../type";

export type IBaseProps = {
  onCardTokenized: (info: CardInfo, cardholderName?: string) => void;
  onFieldFocus?: (element: string) => void;
  acquirerId: number;
  countryCode: string;
  isWallexPayment?: boolean;
  modalView?: boolean;
  onCardTokenizationCleared?: () => void;
  onCardTokenizationFailed?: (err: any) => void;
  hasSecureFormText?: boolean;
  cardFormVersion?: CardFormVersion;
  onChangeCardholderName?: (value?: string) => void;
  hideSecureFormText?: boolean;
};

export interface IBaseState {
  flipped: boolean;
  cardFieldFocus: string;
  readyToShowForm: boolean;
  cardHolderName?: string;
  cardNickName?: string;
  errors: {
    cardNumber?: string | JSX.Element;
  };
}

export interface IBaseCardFormInterface {
  retokenize: () => Promise<
    | {
        cardInfo: CardInfo;
        cardholderName?: string;
      }
    | undefined
  >;
  createTmpCard: (
    paymentToken: string
  ) => Promise<
    | {
        cardInfo: CardInfo;
        cardholderName?: string;
      }
    | undefined
  >;
}
class BaseCardFormView<P extends IBaseProps, S extends IBaseState>
  extends React.Component<P, S>
  implements IBaseCardFormInterface {
  protected numberInputRef: HTMLInputElement | null;
  protected cvcInputRef: HTMLInputElement | null;

  // Please override
  public createTmpCard(
    paymentToken: string
  ): Promise<
    | {
        cardInfo: CardInfo;
        cardholderName?: string;
      }
    | undefined
  > {
    return Promise.resolve(undefined);
  }
  // Please override
  public retokenize(): Promise<
    | {
        cardInfo: CardInfo;
        cardholderName?: string;
      }
    | undefined
  > {
    // Please override
    return Promise.resolve(undefined);
  }
  public componentDidMount() {
    setTimeout(() => {
      if (this.numberInputRef) {
        this.numberInputRef.focus();
      }
      this.setState({
        ...this.state,
        readyToShowForm: true
      });
    }, 1000);
  }

  protected renderCardModel() {
    const filpCard = this.state.flipped ? "back-flip" : "front-flip";
    const withAnimation = this.state.cardFieldFocus;

    if (this.state.flipped && this.cvcInputRef) {
      this.cvcInputRef.focus();
    }

    return (
      <div className={"card-content " + filpCard}>
        <div className="front-card">
          <div className="card-head-logo">
            <CardBankLogo />
            <CardBrandLogo />
          </div>
          <div className="card-infomation">
            <div className="card-number">
              <span className="label">{T.transl("CARD_IMAGE_NUMBER")}</span>
              <div
                className={
                  "card-digits d-flex " +
                  `${withAnimation === "cardNumber" ? "with-animation" : ""}`
                }
              >
                <div className="digits-group">
                  <span>*</span>
                  <span>*</span>
                  <span>*</span>
                  <span>*</span>

                  <span className="ml-3">*</span>
                  <span>*</span>
                  <span>*</span>
                  <span>*</span>

                  <span className="ml-3">*</span>
                  <span>*</span>
                  <span>*</span>
                  <span>*</span>

                  <span className="ml-3">*</span>
                  <span>*</span>
                  <span>*</span>
                  <span>*</span>
                </div>
              </div>
              <p
                className={
                  "px-0 describe mb-0 " +
                  `${
                    withAnimation === "cardNumber" ||
                    withAnimation === "cardExp"
                      ? "with-animation"
                      : ""
                  }`
                }
              >
                {T.transl("CARD_ENCRYPTED_MESSAGE")}
              </p>
            </div>
            <div className="card-name-exp-section mb-1">
              <div className="card-name">
                <span className="label text-uppercase">
                  {T.transl("LABEL_CARD_HOLDER")}
                </span>
                <InputText
                  name="your_name"
                  readOnly={true}
                  className="input-number mb-0"
                  onChangeCustom={this.onChangeCardholderName}
                  placeholder={T.transl("CARD_IMAGE_YOUR_NAME")}
                />
              </div>
              <div className="card-exp">
                <span className="label">{T.transl("CARD_IMAGE_EXPIRY")}</span>
                <div className="card-exp-inputs">
                  <div
                    className={
                      "card-digits d-flex " +
                      `${withAnimation === "cardExp" ? "with-animation" : ""}`
                    }
                  >
                    <div className="digits-group">
                      <span>*</span>
                      <span>*</span>
                      <i>/</i>
                      <span>*</span>
                      <span>*</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="back-card">
          <span className="black-line">{/*this is black line*/}</span>
          <div className="bot-section">
            <span className="signature-line">{/*this is signature line*/}</span>
            <div className="card-cvc">
              <div className="d-flex flex-column">
                <span className="label">cvc/ cvv</span>
                <div
                  className={
                    "card-digits d-flex " +
                    `${withAnimation === "cardCvc" ? "with-animation" : ""}`
                  }
                >
                  <div className="digits-group">
                    <span>*</span>
                    <span>*</span>
                    <span>*</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p
            className={
              "describe " +
              `${withAnimation === "cardCvc" ? "with-animation" : ""}`
            }
          >
            {" "}
            {T.transl("CARD_ENCRYPTED_MESSAGE")}
          </p>
        </div>
      </div>
    );
  }

  protected renderCardholderName(
    cardFormVersion: CardFormVersion = CardFormVersion.LEGACY
  ) {
    const hasSecureText = cardFormVersion === CardFormVersion.BEPAID;
    const { hideSecureFormText } = this.props;
    return (
      <>
        <div
          className="form-group"
          onFocus={this.onFocus.bind(this, "cardholderName")}
        >
          <div className={"label"}>
            {T.transl("LABEL_CARD_HOLDER")}
            {hasSecureText && !hideSecureFormText && (
              <div className="secure-text">
                <img src={LockIcon} className="lock-icon" />
                <span>Secure Form</span>
              </div>
            )}
          </div>

          <InputText
            name="your_name"
            placeholder={T.transl("LABEL_CARD_HOLDER")}
            form={ADD_CARD_FORM}
            onChangeCustom={this.onChangeCardholderName}
          />
        </div>
      </>
    );
  }

  // Return error message or undefine if no error
  protected validateCardBrand = (brand: string = ""): boolean => {
    brand = brand.toLowerCase().trim();
    const { acquirerId, isWallexPayment } = this.props;
    let { countryCode } = this.props;
    if (isWallexPayment === true) {
      countryCode = "SG";
    }
    let acceptCardBrands: string[] = [];
    let acceptCardBrandsMessage: string = "";

    if (CardUtils.isCheckout(acquirerId)) {
      acceptCardBrands = CKO_ACCEPTED_CARD_BRANDS;
      acceptCardBrandsMessage = "ERROR_CARD_BRAND_UNACCEPTED";
    } else if (CardUtils.isBraintree(acquirerId)) {
      acceptCardBrands = BRAINTREE_ACCEPTED_CARD_BRANDS;
      acceptCardBrandsMessage = "ERROR_CARD_BRAND_UNACCEPTED";
    } else if (CardUtils.isStripe(acquirerId)) {
      switch (countryCode) {
        case "SG":
          acceptCardBrands = SG_ACCEPTABLE_BRAND;
          acceptCardBrandsMessage = "ERROR_CARD_BRAND_UNACCEPTED";
          break;
        case "MY":
          acceptCardBrands = MY_ACCEPTABLE_BRAND;
          acceptCardBrandsMessage = "ERROR_CARD_BRAND_UNACCEPTED";
          break;
        case "HK":
          acceptCardBrands = HK_ACCEPTABLE_BRAND;
          acceptCardBrandsMessage = "ERROR_CARD_BRAND_UNACCEPTED";
          break;
      }
    }

    if (acceptCardBrands.length > 0) {
      if (!_includes(acceptCardBrands, brand)) {
        this.setState({
          errors: { cardNumber: T.transl(acceptCardBrandsMessage) }
        });
        return false;
      }
    }

    this.setState({ errors: { cardNumber: undefined } });

    return true;
  };
  protected onFocus = (
    element: "cardholderName" | "cardNumber" | "cardExp" | "cardCvv" | ""
  ) => {
    if (this.props.onFieldFocus) {
      this.props.onFieldFocus(element);
    }
  };
  protected onBlur = () => {
    this.setState({
      cardFieldFocus: ""
    });
  };

  protected onTokenized = (info: CardInfo) => {
    this.props.onCardTokenized(info, this.state.cardHolderName);
  };

  protected onCardTokenizationCleared = () => {
    if (this.props.onCardTokenizationCleared) {
      this.props.onCardTokenizationCleared();
    }
  };

  protected onCardTokenizationFailed = (error: any) => {
    if (this.props.onCardTokenizationFailed) {
      this.props.onCardTokenizationFailed(error);
    }
  };

  private onChangeCardholderName = (value: string) => {
    this.setState({
      cardHolderName: value
    });
    if (this.props.onChangeCardholderName) {
      this.props.onChangeCardholderName(value);
    }
  };
}

export default BaseCardFormView;
