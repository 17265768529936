import React from "react";
import _get from "lodash-es/get";
import * as uuidv1 from "uuid/v1";
import classnames from "classnames";
import Typography from "antd/es/typography";

import ShortCurrency from "src/ipm-shared/components/ShortCurrency";
import DateUtils from "src/ipm-shared/Utils/Date";
import Image from "src/bepaid/components/Image";

import { PAYMENT_GROUP_STATUS_NAME } from "src/bepaid/pages/Filter/const";

import { PaymentStatusPill } from "../PaymentStatusPill";
import { useProcessPaymentDetail } from "./useProcessPaymentDetail";
import paymentUtil from "./utils/payment";
import CountryUtil from "src/ipm-shared/Utils/Country";
import PaymentUtil from "src/ipm-shared/Utils/Payment";
// import NumberUtil from "src/ipm-shared/Utils/Number";
import { FLAGS } from "src/ipm-shared/Utils/Country";
import styles from "./PaymentDetail.module.scss";
import ImageUtils from "src/ipm-shared/Utils/Images";

const ContentCollapsed = ({ selectedPayment, hasWallexPayment }: any) => {
  const {
    payees,
    paymentStatus,
    countryId,
    SGDPaymentTotal,
    isInternationalPayment,
    paymentMethodDetails,
    isCryptoPaymentMethod,
    cryptoPaymentTotal,
    isDeductedRate
  } = useProcessPaymentDetail(selectedPayment);

  const currencyId = _get(selectedPayment, "currencyId");
  const receiptNumber = _get(selectedPayment, "receiptNumber");
  const scheduledChargeDate = _get(selectedPayment, "scheduledChargeDate");
  const payoutDate = _get(selectedPayment, "payoutDate");

  return (
    <>
      <div className={classnames(styles.row, styles.firstRow)}>
        <div className={styles.title}>
          <span>{paymentUtil.getPaymentPurpose(selectedPayment)}</span>
          {hasWallexPayment && (
            <div className={styles.flag}>
              <Image
                src={FLAGS[CountryUtil.getCountryCodeFromCountryId(countryId)]}
              />
            </div>
          )}
        </div>
        <PaymentStatusPill status={paymentStatus} />
      </div>
      <div className={classnames(styles.row, styles.verticalRow)}>
        {payees.map((p: any) => (
          <div
            key={uuidv1()}
            className={classnames(
              styles.row,
              styles.collapsedSectionRecipientName
            )}
          >
            <Typography.Text strong={true} ellipsis={true}>
              {isInternationalPayment &&
              selectedPayment.purposeId == 5 &&
              p.international.bankAccountHolderName
                ? p.international.bankAccountHolderName
                : PaymentUtil.getInsurancePayeeName(p.name)}
            </Typography.Text>
          </div>
        ))}
      </div>
      {paymentStatus === PAYMENT_GROUP_STATUS_NAME.Completed &&
        receiptNumber &&
        !isInternationalPayment && (
          <>
            <hr className={styles.hr1px} />
            <div className={styles.row}>
              <span>Receipt Number</span>
              <span>{receiptNumber}</span>
            </div>
          </>
        )}
      <hr className={styles.hr2px} />
      <div className={styles.row}>
        <b>{isDeductedRate ? "Amount Paid" : "Total Amount"}</b>
        <div className={classnames(styles.row, styles.verticalRow)}>
          <b
            className={classnames({
              [styles.totalValue]: isCryptoPaymentMethod
            })}
          >
            <ShortCurrency value={SGDPaymentTotal} currencyId={currencyId} />
          </b>
          {isCryptoPaymentMethod && (
            <div className={classnames(styles.cryptoCol)}>
              <Image
                src={ImageUtils.getCryptoTypeIcon(paymentMethodDetails?.id)}
              />
              <Typography.Text>{paymentMethodDetails?.label}</Typography.Text>
              <Typography.Text>{cryptoPaymentTotal}</Typography.Text>
            </div>
          )}
        </div>
      </div>
      {isInternationalPayment && receiptNumber && (
        <>
          <hr className={styles.hr2px} />
          <div className={styles.row}>
            <span>Receipt Number</span>
            <span>{receiptNumber}</span>
          </div>
        </>
      )}
      <hr
        className={
          isInternationalPayment || isCryptoPaymentMethod
            ? styles.hr1px
            : styles.hr2px
        }
      />
      <div className={styles.rowsContainer}>
        <div className={styles.row}>
          <span>{isCryptoPaymentMethod ? "Pay-in Date" : "Charge Date"}</span>
          {isInternationalPayment || isCryptoPaymentMethod ? (
            <span>
              {new Date(scheduledChargeDate).toLocaleDateString("en-GB", {
                year: "numeric",
                month: "long",
                day: "2-digit"
              })}
            </span>
          ) : (
            <span>
              {DateUtils.formatLocaleDate(scheduledChargeDate, {
                day: "numeric",
                month: "short",
                year: "numeric"
              })}
            </span>
          )}
        </div>
        <div className={styles.row}>
          <span>{isCryptoPaymentMethod ? "Pay-out Date" : "Payout Date"}</span>
          {isInternationalPayment || isCryptoPaymentMethod ? (
            <span>
              {new Date(payoutDate).toLocaleDateString("en-GB", {
                year: "numeric",
                month: "long",
                day: "2-digit"
              })}
            </span>
          ) : (
            <span>
              {DateUtils.formatLocaleDate(payoutDate, {
                day: "numeric",
                month: "short",
                year: "numeric"
              })}
            </span>
          )}
        </div>
      </div>
    </>
  );
};

export default ContentCollapsed;
