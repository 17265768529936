/* tslint:disable:object-literal-sort-keys */
import React, { useEffect, useState } from "react";

import { Redirect } from "react-router-dom";
import classNames from "classnames";
import _get from "lodash-es/get";
import _find from "lodash-es/find";
import { isDesktop } from "react-device-detect";

import ROUTES from "src/bepaid/routes";
import Skeleton from "src/bepaid/components/Skeleton";
import OverviewBox from "src/bepaid/components/OverviewBox";

import styles from "../../Payments.module.scss";
import PaymentListTab from "../PaymentListTab";

const PaymentsListContent = (props: any) => {
  const {
    hasToken,
    dashboardStatistics,
    // otherSettings,
  } = props;

  const {
    numberOfPaymentsMade,
    numberOfRecurringPayments,
    numberOfInProgressPayments,
  } = dashboardStatistics || {};

  const [loading, setLoading] = useState<boolean>(false);

  const isEmptyData =
    !numberOfPaymentsMade &&
    !numberOfRecurringPayments &&
    !numberOfInProgressPayments;

  const overviewData = [
    {
      key: 1,
      label: "Number of payments in progress",
      value: numberOfInProgressPayments,
    },
    {
      key: 2,
      label: "Number of completed payments",
      value: numberOfPaymentsMade,
    },
    {
      key: 3,
      label: "Number of recurring payments",
      value: numberOfRecurringPayments,
    },
  ];

  useEffect(() => {
    if (props.isBgOfPopup && props.isFirstFetchData) {
      return;
    }
    props.getDashboardStatistics(setLoading);
  }, []);

  return !hasToken ? (
    <Redirect to={ROUTES.LOGIN_REDIRECT} />
  ) : (
    <div
      className={classNames(styles.wrapper, {
        [styles.desktop]: isDesktop,
      })}
    >
      <Skeleton loading={loading}>
        <div className={styles.overviewBoxWrapper}>
          <OverviewBox
            isEmptyData={isEmptyData}
            emptyTitle={"Make your first payment."}
            emptyDescription={"Statistics on your payments will appear here."}
            data={overviewData}
            type={"invoices"}
          />
        </div>
        <PaymentListTab {...props} />
      </Skeleton>
    </div>
  );
};
export default PaymentsListContent;
