import React from "react";
import _get from "lodash-es/get";
import classnames from "classnames";
import Typography from "antd/es/typography";

import styles from "../RecipientDetail.module.scss";

import CountryUtil from "src/ipm-shared/Utils/Country";
import { useProcessPayeeDetail } from "../useProcessPaymentDetail";
import Image from "src/bepaid/components/Image";
import { FLAGS } from "src/ipm-shared/Utils/Country";

const ContentCollapsed = (props: any) => {
  const { selectedPayee, accountCountryCode, hasWallexPayment } = props;
  const { name, purposeTitle, idNumber, bankCountryId } = useProcessPayeeDetail(
    selectedPayee
  );

  return (
    <>
      <div className={classnames(styles.row, styles.firstRow)}>
        <span>{purposeTitle}</span>
        {hasWallexPayment && (
          <div className={styles.flag}>
            <Image
              src={
                FLAGS[CountryUtil.getCountryCodeFromCountryId(bankCountryId)]
              }
            />
          </div>
        )}
      </div>
      <div className={styles.row}>
        <Typography.Text strong={true} ellipsis={true}>
          {name}
        </Typography.Text>
      </div>
      <hr className={styles.hr1px} />
      <div className={styles.row}>
        <span>
          {CountryUtil.getCountryRegistrationCodeFromCountryCode(
            accountCountryCode
          )}
        </span>
        <span>{idNumber}</span>
      </div>
    </>
  );
};

export default ContentCollapsed;
