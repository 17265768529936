import { ActionType } from "typesafe-actions";
import { select, call, put } from "redux-saga/effects";
import * as bankActions from "./actions";
import * as commonActions from "../actions";
import * as accountProfileSelectors from "../AccountProfile/selectors";
import * as bankSelectors from "./selectors";
import * as commonSelectors from "../selectors";
import * as countrySelectors from "src/ipm-shared/store/model/Country/selectors";
import { RootState } from "../reducers";
import { reTryTakeLatest } from "src/ipm-shared/Utils/ReduxSagaEffects";
import HttpRequestError from "src/ipm-shared/Utils/Exceptions/HttpRequestError";
import RestClient from "src/ipm-shared/services/Rest";

const actions = {
  ...bankActions,
  ...commonActions
};

const selectors = {
  ...bankSelectors,
  ...commonSelectors,
  ...accountProfileSelectors,
  ...countrySelectors
};
const watchedSagas = [reTryTakeLatest(actions.fetchBanks, handleFetchBanks)];
export default watchedSagas;

export function* handleFetchBanks(
  action: ActionType<typeof actions.fetchBanks>
) {
  yield put(actions.setBanks({ isFetching: true, banks: [] }));
  const state: RootState = yield select();
  const isAdminView = selectors.isAdminRole(state);
  const currentCountry = selectors.getCurrentCountry(state);
  let countryCode = action.payload.countryCode;

  if (countryCode === "getAll" || isAdminView) {
    countryCode = undefined; // Undefined mean fetch all
  } else if (countryCode === undefined) {
    countryCode = currentCountry;
  }

  const res = yield call(RestClient.send, {
    query: {
      country: countryCode
    },
    service: "get_banks"
  });

  if (!res) {
    throw new HttpRequestError("Failed to fetch");
  }

  try {
    const banks = res.data.map((bank: any) => ({
      countryId: bank.country_id,
      displayName: bank.display_name,
      id: bank.id,
      isDeleted: bank.is_deleted,
      name: bank.name,
      swiftCode: bank.swift_code
    }));

    yield put(
      actions.setBanks({
        banks,
        isFetching: false
      })
    );
  } catch (e) {
    window.Logger.error("handleFetchBanks: ", e.message);
  }
}
