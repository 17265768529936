import { connect } from "react-redux";
import Component from "./View";
import { RootState } from "src/ipm-shared/store/model/reducers";
import * as selectors from "../selectors";

const mapStateToProps = (state: RootState) => ({
  isLoading: selectors.shouldDisplayLoader(state),
  message: selectors.getLoaderMessage(state)
});

const mapDispatchToProps = {};

// Fixed template
export type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Component);
