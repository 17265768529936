// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BusinessInformationModal_wrapper__3mbtm{height:100%}.BusinessInformationModal_wrapper__3mbtm .BusinessInformationModal_content__26fqR{text-align:left;width:100%;height:100%;margin:auto}", "",{"version":3,"sources":["webpack://./src/bepaid/pages/Profile/components/BusinessInformationModal/BusinessInformationModal.module.scss"],"names":[],"mappings":"AAAA,yCAAS,WAAW,CAAC,kFAAkB,eAAe,CAAC,UAAU,CAAC,WAAW,CAAC,WAAW","sourcesContent":[".wrapper{height:100%}.wrapper .content{text-align:left;width:100%;height:100%;margin:auto}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "BusinessInformationModal_wrapper__3mbtm",
	"content": "BusinessInformationModal_content__26fqR"
};
export default ___CSS_LOADER_EXPORT___;
