import React from "react";
import { isMobile } from "react-device-detect";
import Image from "src/bepaid/components/Image";
import HalfGreenCircle from "src/bepaid/assets/images/dashboard/carousel/179__1.svg";
import HalfYelloCircle from "src/bepaid/assets/images/dashboard/carousel/179__2.svg";
import styles from "./RecurringPromo.module.scss";
import classNames from "classnames";

type RecurringPromoProps = {
  learnMoreUrl: string;
  fee: string;
  recurringPaymentsDescription?: string;
};

const RecurringPromo: React.FC<RecurringPromoProps> = ({
  learnMoreUrl,
  fee,
  recurringPaymentsDescription
}) => {
  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.isMobile]: isMobile
      })}
    >
      <div className={styles.topLayer} />
      <div className={styles.topRight}>
        <Image src={HalfYelloCircle} />
      </div>
      <div className={styles.center}>
        <p className={styles.rate}>
          <span>{fee}</span>
          <span>%</span>
        </p>
        <p className={styles.descriptionWrapper}>
          <p className={styles.description}>Special rate for</p>
          <p className={styles.description}>
            {recurringPaymentsDescription
              ? recurringPaymentsDescription
              : "recurring payments"}
          </p>
          <a
            className={styles.learnMore}
            target="_blank"
            href={
              learnMoreUrl
              // "https://help.ipaymy.com/en/articles/6360497-current-promotions#h_5a3cffa7a6"
            }
          >
            Learn more.
          </a>
        </p>
      </div>
      <div className={styles.bottomLeft}>
        <Image src={HalfGreenCircle} />
      </div>
    </div>
  );
};

export default RecurringPromo;
