import React from "react";
import classNames from "classnames";

import Steps from "antd/es/steps";

import DateUtils from "src/ipm-shared/Utils/Date";

import Image from "../Image";

import DiscountIcon from "src/bepaid/assets/images/payment-activity/icon_discount.svg";
import DiscountGreenIcon from "src/bepaid/assets/images/payment-activity/icon_discount_green.svg";
import DueDateIcon from "src/bepaid/assets/images/payment-activity/icon_due_date.svg";
import DueDateGreenIcon from "src/bepaid/assets/images/payment-activity/icon_due_date_green.svg";
import InvoiceSentIcon from "src/bepaid/assets/images/payment-activity/icon_invoice_sent.svg";
import ReminderIcon from "src/bepaid/assets/images/payment-activity/icon_reminder.svg";
import ReminderGreenIcon from "src/bepaid/assets/images/payment-activity/icon_reminder_green.svg";
import EmailIcon from "src/bepaid/assets/images/payment-activity/icon_email.svg";
import EmailGreenIcon from "src/bepaid/assets/images/payment-activity/icon_email_green.svg";
import PaymentInitiatedGreenIcon from "src/bepaid/assets/images/payment-activity/icon_payment_initiated_green.svg";

import OtherGreenIcon from "src/bepaid/assets/images/payment-activity/icon_payment_deposited_green.svg";

import CheckBlueIcon from "src/bepaid/assets/images/common/icon_check_blue.svg";

import styles from "./Styles.module.scss";

const { Step } = Steps;

export type Activity = {
  type: string;
  description: string;
  isDone: boolean;
  date: Date;
  selected: boolean;
  selectedPosition: string | boolean;
};

interface IProps {
  type: "invoice" | "customer";
  activities: Activity[];
}

const IconsTypes = {
  customer: {
    payment: [CheckBlueIcon, CheckBlueIcon]
  },
  invoice: {
    incentive: [DiscountIcon, DiscountGreenIcon],
    invoice_due: [DueDateIcon, DueDateGreenIcon],
    invoice_send: [InvoiceSentIcon, InvoiceSentIcon],
    reminder: [ReminderIcon, ReminderGreenIcon],
    send_email_reminder: [EmailIcon, EmailGreenIcon],
    // tslint:disable-next-line:object-literal-sort-keys
    payment_initiated: [PaymentInitiatedGreenIcon, PaymentInitiatedGreenIcon]
  }
};

const StepsActivity = (props: IProps) => {
  const { activities = [], type } = props;

  const IconsType = IconsTypes[type];
  if (activities.length === 0) {
    return (
      <div className={styles.wrapper}>
        <p className={styles.notes}>No activity in this time period.</p>
      </div>
    );
  }
  return (
    <div className={styles.wrapper}>
      <Steps
        className={styles.stepActivity}
        direction="vertical"
        size="small"
        current={0}
      >
        {activities.map((activity: Activity, index: number) => {
          if (!activity.type) {
            return null;
          }

          const date = DateUtils.formatLocaleDate(activity.date.toString(), {
            day: "numeric",
            month: "short",
            year: "numeric"
          });

          const [icon, activatedIcon] = IconsType[activity.type] || [
            OtherGreenIcon,
            OtherGreenIcon
          ];

          if (activity.selected) {
            return (
              <Step
                className={classNames(
                  styles.selectedActivity,
                  activity.selectedPosition
                    ? {
                        [styles.selectedTop]:
                          activity.selectedPosition === "top",
                        // [styles.selectedBottom]: activity.selectedPosition === "bottom",
                        [styles.selectedMiddle]:
                          activity.selectedPosition === "middle"
                      }
                    : {}
                )}
                key={index}
                icon={<Image src={activity.isDone ? activatedIcon : icon} />}
                title={<p className={styles.step}>{date}</p>}
                description={activity.description}
              />
            );
          }

          if (activity.isDone) {
            return (
              <Step
                key={index}
                className={styles.activatedIcon}
                icon={
                  <Image
                    className={styles.activatedIconImg}
                    src={activatedIcon}
                  />
                }
                title={<p className={styles.step}>{date}</p>}
                description={activity.description}
              />
            );
          }

          return (
            <Step
              key={index}
              icon={<Image src={icon} />}
              title={<p className={styles.step}>{date}</p>}
              description={activity.description}
            />
          );
        })}
      </Steps>
    </div>
  );
};

export default StepsActivity;
