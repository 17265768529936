import React, { useEffect } from "react";
import classNames from "classnames";
import { isDesktop } from "react-device-detect";
import Button from "antd/es/button";

import SharedButton from "src/ipm-shared/components/Form/controls/SubmitButton";

import styles from "./Button.module.scss";

const Component = (props: any) => {
  const { children, wrapperClassName = "", autoScrollToFirstError } = props;
  useEffect(() => {
    if (autoScrollToFirstError) {
      const errorsEls = Array.from(
        document.getElementsByClassName("ant-form-item-has-error")
      ).concat(
        Array.from(document.getElementsByClassName("form-invalid-feedback"))
      );

      if (!props.loading && errorsEls.length > 0) {
        errorsEls[0].scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  }, [props.loading]);

  const renderComponent = (sharedProps: any) => {
    // INFO: props in custom component should be a higher priority
    const onClick = (...params: any[]) => {
      if (sharedProps.onClick) {
        sharedProps.onClick(...params);
      }
      if (props.onClick) {
        props.onClick(...params);
      }
    };

    return (
      <div
        className={classNames(styles.wrapper, wrapperClassName, {
          [styles.desktop]: isDesktop
        })}
      >
        <Button {...sharedProps} {...props} onClick={onClick}>
          {children}
        </Button>
      </div>
    );
  };

  return <SharedButton form={""} renderComponent={renderComponent} />;
};

export default Component;
