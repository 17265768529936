import { getType } from "typesafe-actions";
import * as types from "./types";
import * as actions from "./actions";
import _isEmpty from "lodash-es/isEmpty";
import _findIndex from "lodash-es/findIndex";
import { PayeeType } from "../Payee/reducers";
import { CollectionRates } from "../CollectedAccount/reducers";

export type RefundCommonAttrs = {
  actionType: string;
  chargeDate: string;
  createdAt: string;
  createdBy: {
    email: string;
    firstName: string;
    lastName: string;
    userId: number;
  };
  email: string;
  paymentId: number;
  paymentAmount: number;
  paymentFees: number;
  paymentTotal: number;
  payoutDate: string;
  requestId: number;
  receiptNumber: number;
  scheduledChargeDate: string;
};

export type RefundType = RefundCommonAttrs & {
  metaData: {
    newPaymentFees: number;
    newPaymentTotal: number;
    newPayoutDate: string;
    newRate: number;
    otherRefusalReason: string;
    paymentRefNo: string;
    refundedFee?: number;
    refundedAmount?: number;
    refundedTotal?: number;
    refusalReason: string;
    refusalReasonCode: string;
    bankId: number;
    comments: string;
    accountNumber: string;
  };
};

export type EditPaymentPayeeRequest = RefundCommonAttrs & {
  metaData: [
    {
      payeeId: number;
      bankId: number;
      comments: string;
      accountNumber: string;
      bankRawName: string;
      recipientName: string;
    }
  ];
  oldMetaData: [
    {
      payeeId: number;
      bankId: number;
      comments: string;
      accountNumber: string;
      bankRawName: string;
      recipientName: string;
    }
  ];
};
export type SchedulePayment = {
  payoutDate: string;
  paymentStatusId: number;
  id: number;
  receiptNumber: string;
  accountId: number;
  editable: boolean;
};
export type SchedulePayments = {
  isActive: boolean;
  frequency: string;
  payments: SchedulePayment[];
};
export type ScheduleType = {
  isCancelled?: boolean;
  purposeId: number;
  scheduleStartDate: string;
  scheduleEndDate: string;
  scheduleFrequency: string;
  scheduleStatus?: string;
  schedulePayments?: SchedulePayments;
  cards: [
    {
      id: number;
      statementDescriptor: string;
      amount?: number;
      token?: string;
      last4?: string;
      cardBrand?: number;
    }
  ];
  paymentAmount: number;
  paymentStatusId: number;
  paymentFees: number;
  paymentTotal: number;
  scheduleId: number;
  payees: PayeeType[];
  upcomingPayment?: {
    payments: UpcomingPaymentType[];
  };
  paymentSetting?: PaymentType;
  lastPayout?: string;
  hasSpecialRate?: boolean;
  isActive?: boolean;
};

export type UpcomingPaymentType = {
  id: number;
  chargeDate: string;
  payoutDate: string;
  paymentFee: number;
  paymentAmount: number;
  paymentTotal: number;
  cards: [
    {
      id: number;
      statementDescriptor: string;
      amount?: number;
      token?: string;
      last4?: string;
      cardBrand?: number;
    }
  ];
  defaultComments: string;
  editable: boolean;
};

export type PaymentType = ScheduleType & {
  accountId?: number;
  id: number;
  cardCharge?: [
    {
      amount: number;
      chargeDate: string;
      id: string;
      receiptNumber: string;
      status: string;
      refuseReason?: string[];
      refundedAmount: number;
      refundedReason?: string[];
      refundedReasonCode?: string;
      refundedFee: number;
    }
  ];
  exchangeRate?: number;
  receiptNumber?: string;
  createdAt: string;
  channelFees?: number;
  feeRate?: number;
  origPayoutDate?: string;
  creatorEmail?: string;
  customerEmail?: string;
  updatedAt: string;
  currencyId?: number;
  payoutDate: string;
  payees: PayeeType[];
  payer?: any;
  payUrl?: any;
  paymentActivities?: any;
  /**
   * 1: Rental
   * 2: Salary
   * 3: Invoice
   */
  paymentStatusId: number;
  scheduledChargeDate: string;
  couponCode?: string;
  company?: string;
  settlementTime?: string;
  isInternationalPayment?: boolean;

  // For payment detail response structure
  supportingDocument: Array<{
    key: string;
    name: string;
    size: number;
    url: string;
  }>;

  amountOff?: number;
  order?: number;
  commissionAmount?: number;
  requesterRates?: CollectionRates[];
  isAmexMCC?: boolean;
  paymentMethodType?: string;
  cryptoPaymentDetail?: {
    id?: number;
    cryptoCurrency?: string;
    exchangeRate?: number;
  };
};

export type FeesVerboseType = {
  coupon: string;
  fee: number;
  rate: number;
  rateBeforeCoupon: number;
  savings: number;
  total: number;
};

export type LogChargeAttempt = {
  id: number;
  success: boolean;
  metadata: string;
  errors: string;
  actionType: string;
  last4: string;
  cardBrandId: number;
  name: string;
  cardType: string;
  bankIssuer: string;
  cardFunding: string;
};

export type IpmMerchantType = {
  label: string;
  value: string | number;
};

export type InsurerType = {
  label: string;
  value: string | number;
};

export type PaymentState = {
  readonly isFetching: boolean;
  readonly selectedPaymentId: number;
  readonly selectedPayment?: PaymentType;
  readonly selectedPaymentIds: number[];
  readonly payments: PaymentType[];
  readonly schedules: ScheduleType[];
  readonly selectedScheduleId: number;
  readonly refundRequests: Array<RefundType | EditPaymentPayeeRequest>;
  readonly total: number;
  readonly sumPaymentAmount: number;
  readonly sumPaymentTotal: number;
  readonly totalStripeTransactions: number;
  readonly totalBankPayout: number;
  readonly totalBatch: number;
  readonly activity: {
    scheduled?: number;
    inprogress?: number; // include onhold payment
    completed?: number;
  };
  readonly sumPaymentInThisMonth: {
    scheduled?: number;
    inprogress?: number; // include onhold payment
    completed?: number;
  };
  readonly feesVerbose: { [paymentId: number]: FeesVerboseType };
  readonly updatedPaymentStatus: {
    success: boolean;
    failed: boolean;
  };
  readonly logChargeAttempt: LogChargeAttempt[];
  readonly metadata: any;
  readonly ipmMerchantCategories: IpmMerchantType[];
  readonly insurerList: InsurerType[];
};

const defaultState: PaymentState = {
  activity: {},
  feesVerbose: {},
  isFetching: false,
  payments: [],
  refundRequests: [],
  schedules: [],
  selectedPaymentId: -1,
  selectedPaymentIds: [],
  selectedScheduleId: -1,
  sumPaymentAmount: 0,
  sumPaymentInThisMonth: {},
  sumPaymentTotal: 0,
  total: 0,
  totalBankPayout: 0,
  totalBatch: 0,
  totalStripeTransactions: 0,
  updatedPaymentStatus: { success: false, failed: false },
  logChargeAttempt: [],
  selectedPayment: undefined,
  metadata: {},
  ipmMerchantCategories: [],
  insurerList: []
};

export default (state: PaymentState, action: types.Action) => {
  if (_isEmpty(state)) {
    state = defaultState;
  }

  switch (action.type) {
    case getType(actions.setSelectedPayment):
      return {
        ...state,
        selectedPayment: action.payload.payment
      };
    case getType(actions.resetSelectedPayment):
      return {
        ...state,
        selectedPayment: {}
      };

    case getType(actions.setPayments):
      return {
        ...state,
        isFetching: action.payload.isFetching,
        payments: action.payload.payments
          ? action.payload.payments
          : state.payments,
        sumPaymentAmount: action.payload.sumPaymentAmount,
        sumPaymentTotal: action.payload.sumPaymentTotal,
        total: action.payload.total,
        totalBankPayout: action.payload.totalBankPayout,
        totalBatch: action.payload.totalBatch,
        totalStripeTransactions: action.payload.totalStripeTransactions
      };
    case getType(actions.appendPayments):
      return {
        ...state,
        isFetching: action.payload.isFetching,
        payments: state.payments.concat(action.payload.payments)
      };
    case getType(actions.setRefundRequests):
      return {
        ...state,
        isFetching: action.payload.isFetching,
        refundRequests: action.payload.refundRequests
      };
    case getType(actions.setSchedules):
      return {
        ...state,
        isFetching: action.payload.isFetching,
        schedules: action.payload.schedules,
        total: action.payload.total
      };
    case getType(actions.updatePaymentDetail):
      const idx = _findIndex(
        state.payments,
        o => String(o.id) === String(action.payload.id)
      );
      const payments = state.payments;
      if (idx > -1 && action.payload.payment) {
        payments.splice(idx, 1, action.payload.payment);
      } else if (action.payload.payment) {
        payments.push(action.payload.payment);
      }

      return {
        ...state,
        payments,
        selectedPaymentId: action.payload.id
      };
    case getType(actions.setPaymentActivity):
      return {
        ...state,
        activity: action.payload.activity
      };

    case getType(actions.setSumPaymentInThisMonth):
      return {
        ...state,
        sumPaymentInThisMonth: action.payload.sumPaymentInThisMonth
      };

    case getType(actions.setScheduleDetail):
      const schedules = state.schedules;
      const sIdx = _findIndex(
        schedules,
        o => o.scheduleId === action.payload.scheduleId
      );
      if (sIdx < 0) {
        schedules.push({
          isActive: action.payload.isActive,
          scheduleId: action.payload.scheduleId,
          lastPayout: action.payload.lastPayout,
          paymentSetting: action.payload.paymentSetting,
          upcomingPayment: {
            payments: action.payload.payments
          },
          scheduleFrequency: action.payload.scheduleFrequency
        } as ScheduleType);
      }
      const scheduleDetail = schedules
        .filter(s => s.scheduleId === action.payload.scheduleId)
        .map(ns => ({
          ...ns,
          lastPayout: action.payload.lastPayout,
          isActive: action.payload.isActive,
          paymentSetting: action.payload.paymentSetting,
          upcomingPayment: {
            payments: action.payload.payments
          },
          scheduleFrequency: action.payload.scheduleFrequency
        }))[0];

      if (sIdx > -1) {
        schedules.splice(sIdx, 1, scheduleDetail);
      }
      return {
        ...state,
        schedules
      };
    case getType(actions.selectSchedule):
      return {
        ...state,
        selectedScheduleId: action.payload.id
      };

    case getType(actions.selectPayments):
      return {
        ...state,
        selectedPaymentIds: action.payload.ids
      };

    case getType(actions.setFeesVerbose):
      return {
        ...state,
        feesVerbose: action.payload.feesVerbose
      };

    case getType(actions.setUpdatedPaymentStatus):
      return {
        ...state,
        updatedPaymentStatus: action.payload.updatedPaymentStatus
      };

    case getType(actions.setLogChargeAttempt):
      return {
        ...state,
        logChargeAttempt: action.payload.logChargeAttempt
      };

    case getType(actions.setMetadataPayment):
      return {
        ...state,
        metadata: action.payload.metadata
      };

    case getType(actions.setIpmMerchantCategories):
      return {
        ...state,
        ipmMerchantCategories: action.payload.ipmMerchantCategories
      };

    case getType(actions.setInsurerList):
      return {
        ...state,
        insurerList: action.payload.insurerList
      };
  }
  return state;
};
