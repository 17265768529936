import * as React from "react";
import SelectControl from "../Form/controls/Select";
import { IBaseProps } from "../Form/controls/lib/Base";
import T from "src/ipm-shared/Utils/Intl";

type IProps = IBaseProps & {
  handleSetScheduleType?: (key: number) => void;
  defaultValue?: number;
};

class ScheduleType extends React.Component<IProps> {
  public static OPTION_ONE_TIME = 1;
  public static OPTION_RECURRING = 2;

  public static defaultProps: Partial<IProps> = {
    defaultValue: ScheduleType.OPTION_ONE_TIME
  };

  public static scheduleType = [
    {
      label: T.transl("SCHEDULE_TYPE_ONE_TIME_ITEM"),
      value: ScheduleType.OPTION_ONE_TIME
    },
    {
      label: T.transl("SCHEDULE_TYPE_RECURRING_ITEM"),
      value: ScheduleType.OPTION_RECURRING
    }
  ];

  public render() {
    return (
      <React.Fragment>
        {this.props.label && <span className="label">{this.props.label}</span>}
        <SelectControl
          revertValueOnMount={this.props.revertValueOnMount}
          reserveValueOnUnmount={this.props.reserveValueOnUnmount}
          options={ScheduleType.scheduleType}
          onChangeCustom={this.onChooseScheduleType}
          placeholder={this.props.placeholder || "Select Schedule Type"}
          name={this.props.name}
          form={this.props.form}
          defaultValue={this.props.defaultValue}
          required={this.props.required}
          requiredMessage={this.props.requiredMessage}
        />
      </React.Fragment>
    );
  }

  private onChooseScheduleType = (value: number) => {
    if (this.props.handleSetScheduleType) {
      this.props.handleSetScheduleType(value);
    }
  };
}

export default ScheduleType;
