export const MT_ADD_FORM = "money_transfer_add_recipient_form";

export const RELATIONSHIP = [
  {
    label: "Branch / Representative office",
    value: "BRANCH_REPRESENTATIVE_OFFICE"
  },
  {
    label: "Business partner",
    value: "BUSINESS_PARTNER"
  },
  {
    label: "Children",
    value: "CHILDREN"
  },
  {
    label: "Creditor",
    value: "CREDITOR"
  },
  {
    label: "Customer",
    value: "CUSTOMER"
  },
  {
    label: "Data is not required to be collected by local jurisdiction",
    value: "DATA_IS_NOT_REQUIRED_TO_BE_COLLECTED_BY_LOCAL_JURISDICTION"
  },
  {
    label: "Debtor",
    value: "DEBTOR"
  },
  {
    label: "Employee",
    value: "EMPLOYEE"
  },
  {
    label: "Ex-Spouse",
    value: "EX_SPOUSE"
  },
  {
    label: "Franchisee / Franchisor",
    value: "FRANCHISEE_FRANCHISOR"
  },
  {
    label: "Friend",
    value: "FRIEND"
  },
  {
    label: "Holding Company",
    value: "HOLDING_COMPANY"
  },
  {
    label: "Non Related",
    value: "NON_RELATED"
  },
  {
    label: "Parent",
    value: "PARENT"
  },
  {
    label: "Relative / Uncle / Auntie / Cousin",
    value: "RELATIVE_UNCLE_AUNTIE_COUSIN"
  },
  {
    label: "Self",
    value: "SELF"
  },
  {
    label: "Sibling / Brother / Sister",
    value: "SIBLING_BROTHER_SISTER"
  },
  {
    label: "Spouse",
    value: "SPOUSE"
  },
  {
    label: "Subsidiary Company",
    value: "SUBSIDIARY_COMPANY"
  }
];

export const BANK_ACCOUNT_TYPE = [
  {
    label: "Checking",
    value: "Checking"
  },
  {
    label: "Credit",
    value: "Credit"
  },
  {
    label: "Loan",
    value: "Loan"
  },
  {
    label: "Savings",
    value: "Savings"
  }
];
