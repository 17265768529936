import { connect } from "react-redux";
import _get from "lodash-es/get";
// import { startOfMonth, startOfToday } from "date-fns";
import Component from "./View";
import { RootState } from "src/ipm-shared/store/model/reducers";
import { PAYMENT_STATUS } from "src/ipm-shared/store/model/Payment/const";
import * as actions from "./actions";
import * as selectors from "./selectors";
import T from "src/ipm-shared/Utils/Intl";

export const FILTER_EXPORT_FORM_PAYMENTS = "filter_export_form_payments";
export const FILTER_EXPORT_FORM_PAYMENT_COLLECTIONS =
  "filter_export_form_payment_collections";
export enum FILTER_EXPORT_TYPES {
  FILTER = 1,
  EXPORT
}

export const FILTER_DEFAULT_VALUES = {
  chargeDateLower: "", // startOfMonth(startOfToday()).toISOString(),
  chargeDateUpper: "", // startOfToday().toISOString(),
  statusList: [
    {
      id: "filter_export_stt_in_progress",
      label: T.transl("FILTER_STATUS_PROGRESS"),
      presentedValue: `${PAYMENT_STATUS.IN_PROGRESS_2.toString()}-${PAYMENT_STATUS.ON_HOLD.toString()}-${PAYMENT_STATUS.INT_PENDING.toString()}-${PAYMENT_STATUS.UNDER_REVIEW.toString()}`
    },
    {
      id: "filter_export_stt_completed",
      label: T.transl("FILTER_STATUS_COMPLETED"),
      presentedValue: `${PAYMENT_STATUS.COMPLETED.toString()}-${PAYMENT_STATUS.PAID.toString()}`
    },
    {
      id: "filter_export_stt_refund",
      label: T.transl("FILTER_STATUS_REFUNDED"),
      presentedValue: `${PAYMENT_STATUS.FULLY_REFUNDED_WITH_FEE.toString()}-${PAYMENT_STATUS.FULLY_REFUNDED_WITHOUT_FEE.toString()}`
    },
    {
      id: "filter_export_stt_declined",
      label: T.transl("FILTER_STATUS_DECLINED"),
      presentedValue: PAYMENT_STATUS.DECLINED.toString()
    },
    {
      id: "filter_export_stt_cancelled",
      label: T.transl("FILTER_STATUS_CANCELLED"),
      presentedValue: `${PAYMENT_STATUS.CANCELLED_DUE_TO_DELETED_CARD.toString()}-${PAYMENT_STATUS.CANCELLED_DUE_TO_DELETED_SCHEDULE.toString()}`
    }
  ]
};

export const FILTER_DEFAULT_STATUSES = FILTER_DEFAULT_VALUES.statusList
  .filter(status => status.id !== "filter_export_stt_cancelled")
  .map(status => status.presentedValue)
  .toString();

export const getFormValue = (
  state: RootState,
  name: string,
  form: string,
  defaultValue: string = ""
) => _get(selectors.getControl(state, name, form), "value", defaultValue);

const mapStateToProps = (state: RootState) => ({ rootState: state });

const mapDispatchToProps = {
  setControl: actions.setControl
};

export type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps & {
    form: string;
    cancel?: (cb?: () => void) => void;
    submit?: (type: number, cb?: () => void) => void;
  };

export default connect(mapStateToProps, mapDispatchToProps)(Component);
