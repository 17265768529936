// import { RouteProps } from "react-router";
import { connect } from "react-redux";

import Component from "./View";

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = {};

// export type IProps = ReturnType<typeof mapStateToProps> &
//   typeof mapDispatchToProps &
//   RouteProps;

export default connect(mapStateToProps, mapDispatchToProps)(Component);
