import { RouteProps } from "react-router";
import { connect } from "react-redux";

import { RootState } from "src/ipm-shared/store/model/reducers";

import * as selectors from "src/bepaid/store/model/Fetch/selectors";
import * as actions from "src/bepaid/store/model/Fetch/actions";

import Component from "./View";

const mapStateToProps = (state: RootState) => ({
  collectedAccount: selectors.getCollectedAccount(state),
  hasActiveProgram: selectors.getHasActiveProgram(state),
  countryId: selectors.getCurrentCountryId(state),
  collectionRates: selectors.getCollectionRates(state),
  acquirerId: selectors.getAcquirerId(state),
  accountCountryId: selectors.getAccountCountryId(state)
});

const mapDispatchToProps = {
  fetchMainCollectedAccount: actions.fetchMainCollectedAccount,
  updateCollectedAccount: actions.updateCollectedAccount
};

export type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteProps & { qs: { [param: string]: any } };

// export default requireCollectedAccountComponent(
//   connect(mapStateToProps, mapDispatchToProps)(Component),
//   true,
//   true
// );

export default connect(mapStateToProps, mapDispatchToProps)(Component);
