/**
 * Reducers
 *
 * Reducers is the "sub" of pubsub. They don't need to be coupled to a specific action.
 *
 * It also means we are allowing 1 action to be handled by n reducers.
 *
 * Reducers second responsibility is to define an efficient State representation, it doesn't need
 * to be easy to consume, yet. That is the Selector's job.
 *
 * We can think of reducer's state as a "table"/"relation" in relational database.
 */
import { StateType } from "typesafe-actions";

import ReducerFactory from "src/ipm-shared/Utils/ReduxReducer";
import globalUI from "src/ipm-shared/components/GlobalUI/reducers";
import { FetchState } from "../../../bepaid/store/model/Fetch/reducers";
import { FetchPreStartState } from "../../../bepaid/store/model/FetchPreStart/reducers";
import { CompaniesState } from "./Company/reducers";
import { PayeesState } from "./Payee/reducers";
import { BanksState } from "./Bank/reducers";
import { CardBrandsState } from "./CardBrand/reducers";
import { CardsState } from "./Card/reducers";
import { NotificationsState } from "./Notification/reducers";
import accounts from "./AccountProfile/reducers";
import forms from "./LegacyForms/reducers";
import auth from "./Auth/reducers";
import { CountriesState } from "./Country/reducers";
import { CurrenciesState } from "./Currency/reducers";
import form from "src/ipm-shared/components/Form/reducers";
import { PaymentState } from "./Payment/reducers";
import { PaymentRequestState } from "./PaymentRequest/reducers";
import { DateConditionsState } from "./DateCondition/reducers";
import { UserState } from "./Users/reducer";
import { PromoCodeState } from "./PromoCode/reducer";
import { AccountRatesState } from "./AccountRates/reducer";
import { AccountsManagerState } from "./AccountsManager/reducer";
import { PixelState } from "src/ipm-shared/components/FacebookPixelProvider/reducers";
import { PaymentCollectionsState } from "./PaymentCollections/reducers";
import { CustomerState } from "./Customer/reducers";
import { BusinessState } from "src/ipm-shared/components/SelectBusiness/reducers";
import { CollectedAccountState } from "src/ipm-shared/store/model/CollectedAccount/reducers";
import { PaymentCollectionRequestState } from "src/ipm-shared/store/model/PaymentCollectionRequest/reducers";
import { onBoardingKycState } from "src/ipm-shared/store/model/OnBoarding/reducers";
import { UenState } from "./Uen/reducer";
import { AccountUsersState } from "./AccountUsers/reducers";

const ipmSharedReducers = {
  // Common Reducers
  accounts, // authorize
  auth, // authenticate
  form, // TODO: reducers should be only loaded when neccessary,
  forms,
  globalUI,

  // Placeholders
  // These reducers will not be included on first load. We imported them here for typing only and typescript
  // by default will not include them in transpiled code. :)
  account_rates: (s: AccountRatesState = {} as AccountRatesState) => s,
  account_users: (s: AccountUsersState = {} as AccountUsersState) => s,
  accountsManager: (s: AccountsManagerState = {} as AccountsManagerState) => s,
  banks: (s: BanksState = {} as BanksState) => s,
  fetch: (s: FetchState = {} as FetchState) => s,
  fetchPreStart: (s: FetchPreStartState = {} as FetchPreStartState) => s,
  business: (s: BusinessState = {} as BusinessState) => s,
  cardBrands: (s: CardBrandsState = {} as CardBrandsState) => s,
  cards: (s: CardsState = {} as CardsState) => s,
  collectedAccount: (s: CollectedAccountState = {} as CollectedAccountState) =>
    s,
  company: (s: CompaniesState = {} as CompaniesState) => s,
  countries: (s: CountriesState = {} as CountriesState) => s,
  currencies: (s: CurrenciesState = {} as CurrenciesState) => s,
  customer: (s: CustomerState = {} as CustomerState) => s,
  dateconditions: (s: DateConditionsState = {} as DateConditionsState) => s,
  fbpixel: (s: PixelState = {} as PixelState) => s,
  notifications: (s: NotificationsState = {} as NotificationsState) => s,
  onboardingKyc: (s: onBoardingKycState = {} as onBoardingKycState) => s,
  payees: (s: PayeesState = {} as PayeesState) => s,
  paymentCollectionRequest: (
    s: PaymentCollectionRequestState = {} as PaymentCollectionRequestState
  ) => s,
  paymentCollections: (
    s: PaymentCollectionsState = {} as PaymentCollectionsState
  ) => s,
  paymentrequests: (s: PaymentRequestState = {} as PaymentRequestState) => s,
  payments: (s: PaymentState = {} as PaymentState) => s,
  promo: (s: PromoCodeState = {} as PromoCodeState) => s,
  uen: (s: UenState = {} as UenState) => s,
  user: (s: UserState = {} as UserState) => s
};
const rootReducer = ReducerFactory.createRootReducer(ipmSharedReducers);

type RootState = StateType<typeof rootReducer>;

export { RootState };
export default rootReducer;
