import * as React from "react";

import T from "src/ipm-shared/Utils/Intl";

import { IProps, FILTER_EXPORT_TYPES } from "./index";

import FilterExportButton from "./Button";
import FilterContent from "./Content";

import FilterIcon from "src/ipm-shared/images/filter.svg";
import ExportIcon from "src/ipm-shared/images/export.svg";

type IState = {
  filterDropdownOpen: boolean;
};

class FilterExportProvider extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      filterDropdownOpen: false
    };
  }

  public render() {
    const { filterDropdownOpen } = this.state;

    return (
      <div className="filter-export-buttons">
        <FilterExportButton
          dropdownOpen={filterDropdownOpen}
          onClick={this.handleToggleFilter}
          type={FILTER_EXPORT_TYPES.FILTER}
          text={T.transl("FILTER_BUTTON")}
          icon={FilterIcon}
          content={
            <FilterContent
              {...this.props}
              onClear={this.handleClearFilter}
              onSubmit={this.handleSubmitFilter}
            />
          }
        />
        <FilterExportButton
          type={FILTER_EXPORT_TYPES.EXPORT}
          text={T.transl("EXPORT_BUTTON")}
          icon={ExportIcon}
          onClick={this.handleSubmitExport}
        />
      </div>
    );
  }

  private handleToggleFilter = (): void => {
    const { filterDropdownOpen } = this.state;

    this.setState({
      filterDropdownOpen: !filterDropdownOpen
    });
  };

  private handleClearFilter = (): void => {
    if (this.props.cancel) {
      this.props.cancel(() => {
        if (this.handleToggleFilter) {
          this.handleToggleFilter();
        }
      });
    }
  };

  private handleSubmitFilter = (): void => {
    if (this.props.submit) {
      this.props.submit(FILTER_EXPORT_TYPES.FILTER, () => {
        if (this.handleToggleFilter) {
          this.handleToggleFilter();
        }
      });
    }
  };

  private handleSubmitExport = (): void => {
    if (this.props.submit) {
      this.props.submit(FILTER_EXPORT_TYPES.EXPORT);
    }
  };
}

export default FilterExportProvider;
