/* tslint:disable:object-literal-sort-keys jsx-no-lambda */
import React, { useEffect } from "react";
import { BrowserView, MobileView, isMobile } from "react-device-detect";
import classNames from "classnames";
import _isEmpty from "lodash-es/isEmpty";
import _get from "lodash-es/get";

import { history } from "src/ipm-shared/store";
import T from "src/ipm-shared/Utils/Intl";
import DateUtils from "src/ipm-shared/Utils/Date";
import IntercomUtil from "src/ipm-shared/Utils/Intercom";
import Loader from "src/bepaid/components/Loading";
import Image from "src/bepaid/components/Image";
import useQuery from "src/bepaid/hooks/useQuery";
import FormStyles from "src/bepaid/components/Form/CommonForm.module.scss";
import ROUTES from "src/bepaid/routes";
import Button from "src/bepaid/components/Form/controls/Button";
import MainLayout from "src/bepaid/layouts/MainLayout";

import CancelledPaymentRequestIcon from "src/bepaid/assets/images/invoices/payment_request_cancelled.svg";
import FetchLogo from "src/bepaid/assets/images/fetch_logo.svg";
import PoweredByIpaymy from "src/bepaid/assets/images/powered_by_ipaymy.svg";

import styles from "./CheckoutCancel.module.scss";

IntercomUtil.guestInit();

const CheckoutCancel: React.FC<any> = props => {
  const qs = useQuery();
  const getToken = () => {
    const token = props.match.params.token;
    return token === "token" ? (qs.token as string) : token;
  };

  useEffect(() => {
    const token = getToken();
    if (_isEmpty(token)) {
      history.push(ROUTES.ROOT);
    }
    props.fetchPaymentCollectionRequest(token);
  }, []);

  const payment = props.paymentCollectionRequest;
  const companyName = _get(payment, "companyName", "");
  if (!props.acquirerId || props.acquirerId <= 0) {
    return <Loader></Loader>;
  }

  const renderContent = () => (
    <div className={classNames(styles.item)}>
      <div className={styles.header}>
        <Image src={FetchLogo} className={styles.logo} />
      </div>
      <div className={styles.main}>
        <div className={classNames(FormStyles.content, styles.invoiceInfo)}>
          <div
            className={classNames(
              FormStyles.personalFormDetail,
              styles.formDetail
            )}
          >
            <div className={classNames(styles.formItem)}>
              <div className="img">
                <Image
                  src={CancelledPaymentRequestIcon}
                  className={styles.logo}
                />
              </div>
              <h2 className={styles.firstLetterCapitalize}>
                {T.transl("PAYMENT_CANCELLED_HERO").toLowerCase()}
              </h2>
              <br />
              <p className={styles.normalText}>
                {T.transl("PAYMENT_CANCELLED_DESCRIPTION", {
                  br: <br />,
                  span: () => (
                    <span className={styles.boldText}>{companyName}</span>
                  )
                })}
              </p>
              <br />
              <br />
              <p className={styles.normalText}>
                {T.transl("PAYMENT_CONFIRMATION_CONTACT")}{" "}
                <a href="https://help.ipaymy.com/en/" target={"_blank"}>
                  support@ipaymy.com
                </a>
              </p>
            </div>
            <div className={classNames(styles.formItem, styles.itemAction)}>
              {T.transl("PAYMENT_CONFIRMATION_GO_TO_IPAYMY", {
                a: (text: any) => (
                  <Button
                    type="primary"
                    htmlType="submit"
                    onClick={() => {
                      window.location.href = "https://www.ipaymy.com";
                    }}
                  >
                    {text}
                  </Button>
                ),
                p: (text: any) => <p className={styles.normalText}>{text}</p>
              })}
              <br />
            </div>
            <div>
              <p>
                {T.transl("PAYMENT_CONFIRMATION_LEARN")}{" "}
                <a href="https://www.ipaymy.com/sg/en/products/be-paid/fetch">
                  Fetch
                </a>
                .
              </p>
            </div>
          </div>
        </div>
        <div className={styles.footer}>
          <div className={styles.poweredIpaymy}>
            <span>
              © {DateUtils.getCurrentYear()} Fetch.{" "}
              {T.transl("EMAIL_COPYRIGHT")}
            </span>
            <Image className={styles.icon} width="100%" src={PoweredByIpaymy} />
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <BrowserView>
        <div className={styles.wrapper}>{renderContent()}</div>
      </BrowserView>
      <MobileView>
        <MainLayout header={{ hasBorder: false }}>
          <div
            className={classNames(styles.wrapper, {
              [styles.wrapperMobile]: isMobile
            })}
          >
            {renderContent()}
          </div>
        </MainLayout>
      </MobileView>
    </>
  );
};

export default CheckoutCancel;
