/* tslint:disable:object-literal-sort-keys jsx-no-lambda */
import React from "react";
import { BrowserView, MobileView, isMobile } from "react-device-detect";
import classNames from "classnames";

import Typography from "antd/es/typography";
import Avatar from "antd/es/avatar";
import Flex from "antd-mobile/es/flex";

import ShortCurrency from "src/ipm-shared/components/ShortCurrency";
import { history } from "src/ipm-shared/store";
import { PLATFORM } from "src/ipm-shared/components/GlobalUI/reducers";
import {
  startPayTour,
  startFetchTour
  // startHighlightFetchTour
} from "src/ipm-shared/Utils/Intercom";
import { WALLEX_KYC_STATUS } from "src/ipm-shared/store/model/OnBoarding/consts";

import ROUTES from "src/bepaid/routes";
import Card from "src/bepaid/components/Card";
import Image from "src/bepaid/components/Image";
import Skeleton from "src/bepaid/components/Skeleton";
import OverviewBox from "src/bepaid/components/OverviewBox";
import MainLayout from "src/bepaid/layouts/MainLayout";
import ProfileHeading from "src/bepaid/layouts/MainLayout/components/ProfileHeading";
import ButtonsHeading from "src/bepaid/layouts/MainLayout/components/ButtonsHeading";
import MobileMainButton from "src/bepaid/layouts/MainLayout/components/MobileMainButton";

// import AvatarSample from "src/bepaid/assets/images/dashboard/avatar_sample.svg";
import AvatarDefault from "src/bepaid/assets/images/dashboard/avatar_default.svg";
import InvoicesIcon from "src/bepaid/assets/images/dashboard/icon_invoices.svg";
import CustomersIcon from "src/bepaid/assets/images/dashboard/icon_customers.svg";
import InsightsIcon from "src/bepaid/assets/images/dashboard/icon_insights.svg";

import EmployeesIcon from "src/bepaid/assets/images/dashboard/icon_employees.svg";
import LandlordsIcon from "src/bepaid/assets/images/dashboard/icon_landlords.svg";
import SuppliersIcon from "src/bepaid/assets/images/dashboard/icon_suppliers.svg";
import BankAccountsIcon from "src/bepaid/assets/images/dashboard/icon_bank_accounts.svg";

import RecentActivities from "./components/RecentActivities";

import styles from "./Dashboard.module.scss";
import IntroducingFlashpay from "./components/IntroducingFlashpay/View";
// import WirexPromo from "./components/WirexPromo/View";
import RecurringPromo from "./components/RecurringPromo/View";
import ReferralProgram from "./components/ReferralProgram/View";
import BinancePay from "./components/BinancePay/View";

const CardDetails = ({ icon, title, description, onClick }: any) => {
  return (
    <div onClick={onClick}>
      <Card className={styles.cardDetails}>
        <Flex align="center" justify="between">
          <Flex.Item className={"flex-grow-1-2"}>
            <Image src={icon} />
          </Flex.Item>
          <Flex.Item className={"flex-grow-4"}>
            <Flex direction={"column"} align={"start"}>
              <Flex.Item span={24}>
                <Typography.Text className={styles.title}>
                  {title}
                </Typography.Text>
              </Flex.Item>
              <Flex.Item>
                <Typography.Text className={styles.description}>
                  {description}
                </Typography.Text>
              </Flex.Item>
            </Flex>
          </Flex.Item>
        </Flex>
      </Card>
    </div>
  );
};

const Dashboard: React.FC<any> = props => {
  const {
    currencySymbol,
    dashboardStatistics,
    getDashboardStatistics,
    isFirstFetchDataFetch,
    isFirstFetchDataPay,
    isBgOfPopup,
    isSingaporeAccount,
    wallexKycStatus,
    accountCountryCode
  } = props;

  const [loading, setLoading] = React.useState(false);

  let shouldFetchData = true;

  if (props.platform === PLATFORM.FETCH) {
    shouldFetchData = !(isBgOfPopup && isFirstFetchDataFetch);
  }

  if (props.platform === PLATFORM.PAY) {
    shouldFetchData = !(isBgOfPopup && isFirstFetchDataPay);
  }

  const fetchData = () => {
    getDashboardStatistics(setLoading);
  };

  React.useEffect(() => {
    if (!shouldFetchData) {
      return;
    }
    fetchData();
  }, [props.platform]);

  // Intercom: Start Pay/Fetch tours (only desktop)
  React.useEffect(() => {
    if (isMobile || props.hasOpenedModal || isBgOfPopup) {
      return;
    }

    setTimeout(() => {
      if (props.platform === PLATFORM.FETCH) {
        startFetchTour(props);
      }
      if (props.platform === PLATFORM.PAY) {
        startPayTour(props);
      }
    }, 3000);
  }, [
    props.platform,
    props.userRequiredSetting,
    props.hasCollectedAccountFirstStep,
    props.hasOpenedModal
  ]);

  const renderContent = () => {
    const {
      totalPaymentsReceived = 0,
      numberInvoiceSent = 0,
      numberActiveCustomers = 0,
      numberOfActiveRecipients = 0,
      numberOfPaymentsMade = 0,
      totalPaymentsMade = 0
    } = dashboardStatistics;

    const isEmptyDataFetch =
      !totalPaymentsReceived && !numberInvoiceSent && !numberActiveCustomers;

    const isEmptyDataPay =
      !numberOfActiveRecipients && !numberOfPaymentsMade && !totalPaymentsMade;

    const overviewDataFetch = [
      {
        key: 1,
        label: "Total payments received",
        value: (
          <ShortCurrency
            value={totalPaymentsReceived}
            removedZeroDecimal={true}
            fractionDigits={0}
          />
        ),
        currency: currencySymbol
      },
      {
        key: 2,
        label: "Number of invoices sent",
        value: numberInvoiceSent
      },
      {
        key: 3,
        label: "Number of active customers",
        value: numberActiveCustomers
      }
    ];

    // const overviewDataPay = [
    //   {
    //     key: 1,
    //     label: "Total payments made",
    //     value: (
    //       <ShortCurrency
    //         value={totalPaymentsMade}
    //         removedZeroDecimal={true}
    //         fractionDigits={0}
    //       />
    //     ),
    //     currency: currencySymbol
    //   },
    //   {
    //     key: 2,
    //     label: "Number of payments made",
    //     value: numberOfPaymentsMade
    //   },
    //   {
    //     key: 3,
    //     label: "Number of active recipients",
    //     value: numberOfActiveRecipients
    //   }
    // ];

    const overviewDataPayWithPromo = {
      SG: [
        {
          key: 1,
          label: "",
          value: <IntroducingFlashpay />,
          isBigger: true,
          noPadding: true
        },
        {
          key: 2,
          label: "Total payments made",
          value: (
            <ShortCurrency
              value={totalPaymentsMade}
              removedZeroDecimal={true}
              fractionDigits={0}
            />
          ),
          currency: currencySymbol,
          isBigger: true
        },
        {
          key: 3,
          label: "",
          value: (
            <RecurringPromo
              learnMoreUrl={
                "https://help.ipaymy.com/en/articles/6360497-current-promotions#h_5a3cffa7a6"
              }
              fee={"1.79"}
              recurringPaymentsDescription={"recurring rent payments"}
            />
          ),
          isBigger: true,
          noPadding: true
        },
        {
          key: 4,
          label: "",
          value: (
            // <WirexPromo learnMoreUrl={"https://ipaymy.ac-page.com/wirex-sg"} />
            <RecurringPromo
              learnMoreUrl={
                "https://help.ipaymy.com/en/articles/6360497-current-promotions#h_5a3cffa7a6"
              }
              fee={"1.85"}
              recurringPaymentsDescription={"other recurring payments"}
            />
          ),
          isBigger: true,
          noPadding: true
        }
      ],
      AU: [
        {
          key: 1,
          label: "",
          value: <BinancePay />,
          isBigger: true,
          noPadding: true
        },
        {
          key: 2,
          label: "Total payments made",
          value: (
            <ShortCurrency
              value={totalPaymentsMade}
              removedZeroDecimal={true}
              fractionDigits={0}
            />
          ),
          currency: currencySymbol,
          isBigger: true
        },
        // {
        //   key: 3,
        //   label: "",
        //   value: (
        //     <WirexPromo learnMoreUrl={"https://ipaymy.ac-page.com/wirex-au"} />
        //   ),
        //   isBigger: true,
        //   noPadding: true
        // },
        {
          key: 3,
          label: "",
          value: <ReferralProgram />,
          isBigger: true,
          noPadding: true
        }
      ],
      HK: [
        {
          key: 1,
          label: "",
          value: <BinancePay />,
          isBigger: true,
          noPadding: true
        },
        {
          key: 2,
          label: "Total payments made",
          value: (
            <ShortCurrency
              value={totalPaymentsMade}
              removedZeroDecimal={true}
              fractionDigits={0}
            />
          ),
          currency: currencySymbol,
          isBigger: true
        },
        {
          key: 3,
          label: "",
          value: (
            <RecurringPromo
              learnMoreUrl={
                "https://help.ipaymy.com/en/articles/6360497-current-promotions#h_537e15d5ec"
              }
              fee={"1.79"}
            />
          ),
          isBigger: true,
          noPadding: true
        }
        // {
        //   key: 4,
        //   label: "",
        //   value: (
        //     <WirexPromo learnMoreUrl={"https://ipaymy.ac-page.com/wirex-hk"} />
        //   ),
        //   isBigger: true,
        //   noPadding: true
        // }
      ],
      MY: [
        {
          key: 1,
          label: "",
          value: <BinancePay />,
          isBigger: true,
          noPadding: true
        },
        {
          key: 2,
          label: "Total payments made",
          value: (
            <ShortCurrency
              value={totalPaymentsMade}
              removedZeroDecimal={true}
              fractionDigits={0}
            />
          ),
          currency: currencySymbol,
          isBigger: true
        },
        {
          key: 3,
          label: "",
          value: (
            <RecurringPromo
              learnMoreUrl={
                "https://help.ipaymy.com/en/articles/6360497-current-promotions#h_a6504e269e"
              }
              fee={"1.75"}
            />
          ),
          isBigger: true,
          noPadding: true
        }
      ]
    }[accountCountryCode];

    return (
      <div className={styles.content}>
        <Skeleton loading={loading}>
          <div className={styles.overviewBoxWrapper}>
            {props.platform === PLATFORM.FETCH && (
              <OverviewBox
                isEmptyData={isEmptyDataFetch}
                emptyTitle={"Send your first invoice and get paid."}
                emptyDescription={
                  "Statistics on your payments, invoices and customers will appear here."
                }
                data={overviewDataFetch}
              />
            )}
            {props.platform === PLATFORM.PAY && (
              <OverviewBox
                isEmptyData={isEmptyDataPay}
                emptyTitle={"Make your first payment."}
                emptyDescription={
                  "Statistics on your payments and recipients will appear here."
                }
                data={overviewDataPayWithPromo}
              />
            )}
          </div>
          <div
            className={styles.cardWrapper}
            hidden={props.platform !== PLATFORM.FETCH}
          >
            <Flex direction={"column"} align={"stretch"} justify={"between"}>
              <Flex.Item span={24}>
                <CardDetails
                  icon={InvoicesIcon}
                  title="Invoices"
                  description="View or manage your invoices."
                  onClick={() => history.push(ROUTES.INVOICES)}
                />
              </Flex.Item>
              <Flex.Item span={24}>
                <CardDetails
                  icon={CustomersIcon}
                  title="Customers"
                  description="View or manage your customers."
                  onClick={() => history.push(ROUTES.CUSTOMERS)}
                />
              </Flex.Item>
              <Flex.Item span={24}>
                <CardDetails
                  icon={InsightsIcon}
                  title="Insights"
                  description="View insights, recommendations and more."
                  onClick={() => history.push(ROUTES.INSIGHTS)}
                />
              </Flex.Item>
            </Flex>
          </div>

          <div
            className={styles.cardWrapper}
            hidden={props.platform !== PLATFORM.PAY}
          >
            <Flex direction={"column"} align={"stretch"} justify={"between"}>
              <Flex.Item span={24}>
                <CardDetails
                  icon={InvoicesIcon}
                  title="Payments"
                  description="View or manage your payments."
                  onClick={() => {
                    history.push(ROUTES.PAYMENTS);
                  }}
                />
              </Flex.Item>
              <Flex.Item span={24}>
                <CardDetails
                  icon={LandlordsIcon}
                  title="Landlords"
                  description="View or manage your landlords."
                  onClick={() => {
                    history.push(ROUTES.RECIPIENTS_LANDLORDS);
                  }}
                />
              </Flex.Item>
              {!props.isPersonalAccount && (
                <Flex.Item span={24}>
                  <CardDetails
                    icon={EmployeesIcon}
                    title="Employees"
                    description="View or manage your employees."
                    onClick={() => {
                      history.push(ROUTES.RECIPIENTS_EMPLOYEES);
                    }}
                  />
                </Flex.Item>
              )}
              <Flex.Item span={24}>
                <CardDetails
                  icon={SuppliersIcon}
                  title="Suppliers"
                  description="View or manage your suppliers."
                  onClick={() => {
                    history.push(ROUTES.RECIPIENTS_SUPPLIERS);
                  }}
                />
              </Flex.Item>
              {isSingaporeAccount &&
                wallexKycStatus === WALLEX_KYC_STATUS.APPROVED && (
                  <Flex.Item span={24}>
                    <CardDetails
                      icon={BankAccountsIcon}
                      title="Bank accounts"
                      description="View or manage your bank accounts."
                      onClick={() => {
                        history.push(ROUTES.RECIPIENTS_BANK_ACCOUNTS);
                      }}
                    />
                  </Flex.Item>
                )}
              <Flex.Item span={24}>
                <CardDetails
                  icon={InsightsIcon}
                  title="Insights"
                  description="View insights, recommendations and more."
                  onClick={() => history.push(ROUTES.INSIGHTS_PAY)}
                />
              </Flex.Item>
            </Flex>
          </div>
        </Skeleton>
      </div>
    );
  };

  const renderDesktopView = () => {
    return (
      <div className={classNames(styles.wrapper, styles.desktop)}>
        <MainLayout
          header={{
            leftHeader: <ProfileHeading />,
            rightHeader: <ButtonsHeading />
          }}
          body={{
            leftBody: renderContent(),
            rightBody: (
              <Card className={styles.activitiesBox}>
                <RecentActivities isBgOfPopup={isBgOfPopup} />
              </Card>
            )
          }}
        />
      </div>
    );
  };

  const renderMobileView = () => {
    return (
      <MainLayout
        header={{
          title: "Dashboard",
          rightIcons: <Avatar src={AvatarDefault} />,
          hasBorder: false
        }}
        footer={<MobileMainButton platform={props.platform} />}
      >
        <div className={styles.wrapper}>{renderContent()}</div>
      </MainLayout>
    );
  };

  return (
    <>
      <BrowserView>{renderDesktopView()}</BrowserView>
      <MobileView>{renderMobileView()}</MobileView>
    </>
  );
};

export default Dashboard;
