import React, { useRef, useEffect } from "react";
import { PAYMENT_GROUP_STATUS_NAME } from "src/bepaid/pages/Filter/const";
import styles from "./PaymentStatusPill.module.scss";

const pillColors: Record<
  // PAYMENT_GROUP_STATUS_NAME,
  string,
  { background: string; color: string }
> = {
  [PAYMENT_GROUP_STATUS_NAME.Scheduled]: {
    background: "#F2EDFD",
    color: "#6032C4"
  },
  [PAYMENT_GROUP_STATUS_NAME.Completed]: {
    background: "#EAF9FC",
    color: "#17775B"
  },
  [PAYMENT_GROUP_STATUS_NAME.Cancelled]: {
    background: "#F0EFEF",
    color: "#747474"
  },
  [PAYMENT_GROUP_STATUS_NAME.Overdue]: {
    background: "#FFEDED",
    color: "#D05475"
  },
  [PAYMENT_GROUP_STATUS_NAME.UnderReview]: {
    background: "#F8F3E7",
    color: "#836A0B"
  },
  [PAYMENT_GROUP_STATUS_NAME.Other]: {
    background: "#FFEDE0",
    color: "#C96C43"
  },
  [PAYMENT_GROUP_STATUS_NAME.InProgress]: {
    background: "#E4F7DE",
    color: "#17775B"
  },
  [PAYMENT_GROUP_STATUS_NAME.OnScreening]: {
    background: "#BF1717",
    color: "#FFFFFF"
  }
};

export const PaymentStatusPill = ({
  status
}: {
  status: PAYMENT_GROUP_STATUS_NAME;
}) => {
  const pillColor = pillColors[status] || pillColors.Scheduled;
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!ref.current) return;
    if (!pillColor) return;
    const style = `
    --payment-color: ${pillColor.color};
    --payment-background: ${pillColor.background};
  `;
    ref.current.setAttribute("style", style);
  }, [pillColor?.color, pillColor?.background]);

  return (
    <div ref={ref} className={styles.pill}>
      {status}
    </div>
  );
};
