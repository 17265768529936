// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RecipientDetailModal_wrapper__1uUyC{display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;padding-bottom:120px;-ms-flex-flow:column;flex-flow:column}", "",{"version":3,"sources":["webpack://./src/bepaid/shared/modals/RecipientDetailPageModal/RecipientDetailModal.module.scss"],"names":[],"mappings":"AAAA,qCAAS,mBAAY,CAAZ,YAAY,CAAC,oBAAsB,CAAtB,sBAAsB,CAAC,qBAAkB,CAAlB,kBAAkB,CAAC,oBAAoB,CAAC,oBAAe,CAAf,gBAAgB","sourcesContent":[".wrapper{display:flex;justify-content:center;align-items:center;padding-bottom:120px;flex-flow:column}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "RecipientDetailModal_wrapper__1uUyC"
};
export default ___CSS_LOADER_EXPORT___;
