// tslint:disable:object-literal-sort-keys

import * as uuidv1 from "uuid/v1";
import {
  PAYMENT_STATUS,
  PAYMENT_GROUP_STATUS,
  PAYMENT_FREQUENCY,
  SCHEDULE_STATUS,
  SCHEDULE_FREQUENCY,
  PAYMENT_FAVOURITE
} from "src/ipm-shared/store/model/Payment/const";
import { PURPOSE } from "src/ipm-shared/components/Purpose/const";

export interface IOption {
  id: string;
  name: string;
  key?: string;
  children?: Array<{ id: string; name: string; key?: string }>;
}

export const CUSTOMERS_FILTER_OPTIONS: IOption[] = [
  {
    id: uuidv1(),
    name: "Tag",
    key: "TAG",
    children: [
      {
        id: uuidv1(),
        name: "VIP",
        key: "vip"
      },
      {
        id: uuidv1(),
        name: "Active",
        key: "active"
      },
      {
        id: uuidv1(),
        name: "New",
        key: "new"
      },
      {
        id: uuidv1(),
        name: "Risky",
        key: "risky"
      }
    ]
  },
  {
    id: uuidv1(),
    name: "Punctuality",
    key: "PUN",
    children: [
      {
        id: uuidv1(),
        name: "Most punctual",
        key: "most_punctual"
      },
      {
        id: uuidv1(),
        name: "Least punctual",
        key: "least_punctual"
      }
    ]
  },
  {
    id: uuidv1(),
    name: "Date added",
    key: "DATE",
    children: [
      {
        id: uuidv1(),
        name: "Last week",
        key: "last_week"
      },
      {
        id: uuidv1(),
        name: "Last month",
        key: "last_month"
      },
      {
        id: uuidv1(),
        name: "Last three months",
        key: "last_three_months"
      },
      {
        id: uuidv1(),
        name: "Last six months",
        key: "last_six_months"
      },
      {
        id: uuidv1(),
        name: "Custom",
        key: "custom"
      }
    ]
  }
];

export const RECIPIENTS_FILTER_OPTIONS: IOption[] = [
  {
    id: uuidv1(),
    name: "Date added",
    key: "DATE",
    children: [
      {
        id: uuidv1(),
        name: "Last week",
        key: "last_week"
      },
      {
        id: uuidv1(),
        name: "Last month",
        key: "last_month"
      },
      {
        id: uuidv1(),
        name: "Last three months",
        key: "last_three_months"
      },
      {
        id: uuidv1(),
        name: "Last six months",
        key: "last_six_months"
      },
      {
        id: uuidv1(),
        name: "Custom",
        key: "custom"
      }
    ]
  }
];

export enum PAYMENT_GROUP_STATUS_NAME {
  Scheduled = "Scheduled",
  UnderReview = "Under Review",
  InProgress = "In Progress",
  Completed = "Completed",
  Overdue = "Overdue",
  Refunded = "Refunded",
  Cancelled = "Cancelled",
  Other = "Other",
  Active = "Active",
  Inactive = "Inactive",
  OnScreening = "On Screening"
}

const paymentFilters = [
  {
    id: uuidv1(),
    name: PAYMENT_GROUP_STATUS_NAME.Scheduled,
    key: `${PAYMENT_GROUP_STATUS.scheduled.join(",")},${
      PAYMENT_STATUS.ON_SCREENING
    }`
  },
  // {
  //   id: uuidv1(),
  //   name: PAYMENT_GROUP_STATUS_NAME.UnderReview,
  //   key: PAYMENT_GROUP_STATUS.under_review.join(",")
  // },
  {
    id: uuidv1(),
    name: PAYMENT_GROUP_STATUS_NAME.InProgress,
    key: `${PAYMENT_STATUS.IN_PROGRESS_2},${PAYMENT_STATUS.UNDER_REVIEW},${PAYMENT_STATUS.ON_HOLD},${PAYMENT_STATUS.ON_SCREENING}`
  },
  {
    id: uuidv1(),
    name: PAYMENT_GROUP_STATUS_NAME.Completed,
    key: String(PAYMENT_STATUS.COMPLETED)
  },
  {
    id: uuidv1(),
    name: PAYMENT_GROUP_STATUS_NAME.Overdue,
    key: String(PAYMENT_STATUS.OVERDUE)
  },
  {
    id: uuidv1(),
    name: PAYMENT_GROUP_STATUS_NAME.Refunded,
    key: `${PAYMENT_STATUS.FULLY_REFUNDED_WITH_FEE},${PAYMENT_STATUS.FULLY_REFUNDED_WITHOUT_FEE},${PAYMENT_STATUS.REFUNDED}`
  },
  {
    id: uuidv1(),
    name: PAYMENT_GROUP_STATUS_NAME.Cancelled,
    key: `${PAYMENT_STATUS.DECLINED},${PAYMENT_STATUS.CANCELLED_DUE_TO_DELETED_CARD},${PAYMENT_STATUS.CANCELLED_DUE_TO_DELETED_SCHEDULE},${PAYMENT_STATUS.BLOCKED}`
  },
  {
    id: uuidv1(),
    name: PAYMENT_GROUP_STATUS_NAME.Other,
    key: PAYMENT_GROUP_STATUS.other.join(",")
  }
];

export const paymentFilterKeyMapByStatus: Record<
  string,
  string[]
> = paymentFilters.reduce((map, elm) => {
  map[elm.name] = elm.key.split(",");
  return map;
}, {});

export const PAYMENT_FILTER_OPTIONS: IOption[] = [
  {
    id: uuidv1(),
    name: "Favourite",
    key: "FAVOURITE",
    children: [
      {
        id: uuidv1(),
        name: "Yes",
        key: String(PAYMENT_FAVOURITE.YES)
      },
      {
        id: uuidv1(),
        name: "No",
        key: String(PAYMENT_FAVOURITE.NO)
      }
    ]
  },
  {
    id: uuidv1(),
    name: "Category",
    key: "PURPOSE",
    children: [
      {
        id: uuidv1(),
        name: "Rent",
        key: String(PURPOSE.RENTAL)
      },
      {
        id: uuidv1(),
        name: "Insurance",
        key: String(PURPOSE.INSURANCE)
      },
      {
        id: uuidv1(),
        name: "Salary",
        key: `${String(PURPOSE.SALARY)},${String(PURPOSE.SALARY_BUSINESS)}`
      },
      {
        id: uuidv1(),
        name: "Invoices",
        key: String(PURPOSE.INVOICE)
      },
      {
        id: uuidv1(),
        name: "Tax",
        key: String(PURPOSE.TAX)
      },
      {
        id: uuidv1(),
        name: "Money transfer",
        key: String(PURPOSE.SELF_TRANSFER)
      }
    ]
  },
  {
    id: uuidv1(),
    name: "Status",
    key: "STATUS",
    children: paymentFilters
  },
  {
    id: uuidv1(),
    name: "Frequency",
    key: "FREQUENCY",
    children: [
      {
        id: uuidv1(),
        name: "One-time",
        key: PAYMENT_FREQUENCY.ONE_TIME
      },
      {
        id: uuidv1(),
        name: "Recurring",
        key: PAYMENT_FREQUENCY.RECURRING
      }
    ]
  },
  {
    id: uuidv1(),
    name: "Charge Date",
    key: "CHARGE_DATE",
    children: [
      {
        id: uuidv1(),
        name: "Last week",
        key: "last_week"
      },
      {
        id: uuidv1(),
        name: "Last month",
        key: "last_month"
      },
      {
        id: uuidv1(),
        name: "Last three months",
        key: "last_three_months"
      },
      {
        id: uuidv1(),
        name: "Last six months",
        key: "last_six_months"
      },
      {
        id: uuidv1(),
        name: "Custom",
        key: "custom"
      }
    ]
  },
  {
    id: uuidv1(),
    name: "Payout Date",
    key: "PAYOUT_DATE",
    children: [
      {
        id: uuidv1(),
        name: "Last week",
        key: "last_week"
      },
      {
        id: uuidv1(),
        name: "Last month",
        key: "last_month"
      },
      {
        id: uuidv1(),
        name: "Last three months",
        key: "last_three_months"
      },
      {
        id: uuidv1(),
        name: "Last six months",
        key: "last_six_months"
      },
      {
        id: uuidv1(),
        name: "Custom",
        key: "custom"
      }
    ]
  },
  {
    id: uuidv1(),
    name: "Destination",
    key: "DESTINATION",
    children: [
      {
        id: uuidv1(),
        name: "Domestic",
        key: "domestic"
      },
      {
        id: uuidv1(),
        name: "International",
        key: "international"
      }
    ]
  }
];

export const INVOICE_FILTER_OPTIONS: IOption[] = [
  {
    id: uuidv1(),
    name: "Status",
    key: "STATUS",
    children: [
      {
        id: uuidv1(),
        name: "Request sent",
        key: String(PAYMENT_STATUS.REQUEST)
      },
      // {
      //   id: uuidv1(),
      //   name: "Payment under review",
      //   key: PAYMENT_GROUP_STATUS.under_review.join(",")
      // },
      {
        id: uuidv1(),
        name: "Payment in progress",
        key: `${
          PAYMENT_STATUS.IN_PROGRESS_2
        },${PAYMENT_GROUP_STATUS.under_review.join(",")},${
          PAYMENT_STATUS.UNDER_REVIEW
        },${PAYMENT_STATUS.ON_SCREENING}`
      },
      {
        id: uuidv1(),
        name: "Completed",
        key: `${PAYMENT_STATUS.PAID},${PAYMENT_STATUS.COMPLETED}`
      },
      {
        id: uuidv1(),
        name: "Overdue",
        key: String(PAYMENT_STATUS.OVERDUE)
      },
      {
        id: uuidv1(),
        name: "Draft",
        key: String(PAYMENT_STATUS.DRAFT)
      },
      {
        id: uuidv1(),
        name: "Refunded",
        key: `${PAYMENT_STATUS.FULLY_REFUNDED_WITH_FEE},${PAYMENT_STATUS.FULLY_REFUNDED_WITHOUT_FEE}`
      },
      {
        id: uuidv1(),
        name: "Cancelled",
        key: `${PAYMENT_STATUS.DECLINED},${PAYMENT_STATUS.CANCELLED_DUE_TO_DELETED_CARD},${PAYMENT_STATUS.CANCELLED_DUE_TO_DELETED_SCHEDULE},${PAYMENT_STATUS.BLOCKED}`
      },
      {
        id: uuidv1(),
        name: "Other",
        key: PAYMENT_GROUP_STATUS.other.join(",")
      }
    ]
  },
  {
    id: uuidv1(),
    name: "Date sent",
    key: "DATE",
    children: [
      {
        id: uuidv1(),
        name: "Last week",
        key: "last_week"
      },
      {
        id: uuidv1(),
        name: "Last month",
        key: "last_month"
      },
      {
        id: uuidv1(),
        name: "Last three months",
        key: "last_three_months"
      },
      {
        id: uuidv1(),
        name: "Last six months",
        key: "last_six_months"
      },
      {
        id: uuidv1(),
        name: "Custom",
        key: "custom"
      }
    ]
  }
];

export const SCHEDULE_FILTER_OPTIONS: IOption[] = [
  {
    id: uuidv1(),
    name: "Category",
    key: "PURPOSE",
    children: [
      {
        id: uuidv1(),
        name: "Rent",
        key: String(PURPOSE.RENTAL)
      },
      {
        id: uuidv1(),
        name: "Insurance",
        key: String(PURPOSE.INSURANCE)
      },
      {
        id: uuidv1(),
        name: "Salary",
        key: `${String(PURPOSE.SALARY)},${String(PURPOSE.SALARY_BUSINESS)}`
      },
      {
        id: uuidv1(),
        name: "Invoices",
        key: String(PURPOSE.INVOICE)
      },
      {
        id: uuidv1(),
        name: "Tax",
        key: String(PURPOSE.TAX)
      }
    ]
  },
  {
    id: uuidv1(),
    name: "Status",
    key: "STATUS",
    children: [
      {
        id: uuidv1(),
        name: "Active",
        key: String(SCHEDULE_STATUS.ACTIVE)
      },
      {
        id: uuidv1(),
        name: "Inactive",
        key: String(SCHEDULE_STATUS.INACTIVE)
      }
    ]
  },
  {
    id: uuidv1(),
    name: "Frequency",
    key: "FREQUENCY",
    children: [
      {
        id: uuidv1(),
        name: "Weekly",
        key: SCHEDULE_FREQUENCY.WEEKLY
      },
      {
        id: uuidv1(),
        name: "Biweekly",
        key: SCHEDULE_FREQUENCY.BIWEEKLY
      },
      {
        id: uuidv1(),
        name: "Monthly",
        key: SCHEDULE_FREQUENCY.MONTHLY
      },
      {
        id: uuidv1(),
        name: "Quarterly",
        key: SCHEDULE_FREQUENCY.QUARTERLY
      },
      {
        id: uuidv1(),
        name: "Biannually",
        key: SCHEDULE_FREQUENCY.BIANNALLY
      }
    ]
  },
  {
    id: uuidv1(),
    name: "Payout Date",
    key: "PAYOUT_DATE",
    children: [
      {
        id: uuidv1(),
        name: "Custom",
        key: "custom"
      }
    ]
  }
];
