const t = {
  ABN_COLUMN: "澳大利亞商業編號（ABN）",
  ABN_PLACEHOLDER: "澳大利亞商業編號（ABN）",
  ACCEPT_BUTTON: "接受",
  ACCOUNT_NUMBER_CONFIRM_DOES_NOT_MATCH: "輸入的銀行賬戶不匹配。",
  ACCOUNT_NUMBER_LABEL: "帳號",
  ACCOUNT_NUMBER_PLACEHOLDER: "收款人的銀行賬號",
  ACTION_CANCEL: "取消請求",
  ACTION_CANCEL_HERO: "你想取消這個付款請求嗎？",
  ACTION_CANCEL_INSTRUCTIONS: "你的客戶將獲通知收款要求已被取消。",
  ACTION_CANCEL_NO: "不要",
  ACTION_CANCEL_YES: "是的，取消",
  ACTION_COLUMN: "行動",
  ACTION_COPY_LINK: "複製鏈接",
  ACTION_COPY_LINK_CONFIRMED: "已複製",
  ACTION_MARK_PAID: "標記為已支付",
  ACTION_RESEND_EMAIL_SUCCESS: "你的支付請求已重新發送到更新的電子郵件地址。",
  ACTION_RESEND_MOBILE_SUCCESS: "你的支付請求已重新發送到更新的手提電話上。",
  ACTION_RESEND_REMIND: "提醒客戶",
  ACTION_RESEND_REQUEST: "重新發送收款要求",
  ACTION_RESEND_SENT: "已發送",
  ACTION_RESEND_SUCCESS: "你的支付請求已重新發送至你的客戶。",
  ACTIVITY_COMPLETED_PAYMENTS: "已完成的付款",
  ACTIVITY_PAYMENTS_COLLECTED: "已收款項",
  ACTIVITY_PAYMENTS_DEPOSITED: "已存款項",
  ACTIVITY_PAYMENTS_IN_PROGRESS: "處理中的付款",
  ACTIVITY_PAYMENTS_REQUEST: "已提出收取的款項",
  ACTIVITY_SCHEDULED_PAYMENTS: "定期付款",
  ACTIVITY_TITLE: "過去30天內的\u003cb\u003e交易活動\u003c/b\u003e",
  ADDITIONAL_INFORMATION_SECTION: "其他資訊",
  ADDRESS_LABEL: "地址",
  ADDRESS_PLACEHOLDER: "地址",
  ADD_BUTTON: "添加",
  ADD_CARDS_CTA: "添加銀行卡到我的錢包",
  ADD_CARD_BUTTON: "添加信用卡支付款項",
  ADD_COMPANY_BUTTON: "添加新公司",
  ADD_COMPANY_WELCOME: "歡迎來到ipaymy! 確認你的公司信息以開始使用",
  ADD_CUSTOMER_BUTTON: "添加客戶",
  ADD_EMPLOYEE: "添加僱員",
  ADD_EMPLOYEE_BUTTON: "添加僱員",
  ADD_EMPLOYEE_LABEL: "添加新僱員",
  ADD_LANDLORD_HERO: "添加業主",
  ADD_MULTIPLE_EMPLOYEES: "添加多名僱員",
  ADD_MULTIPLE_SUPPLIERS_BUTTON: "添加多個供應商",
  ADD_NEW_CUSTOMER_BUTTON: "添加新客戶",
  ADD_NEW_CUSTOMER_HERO: "添加新客戶",
  ADD_NEW_SUPPLIER_BUTTON: "添加新供應商",
  ADD_NEW_SUPPLIER_HERO: "添加新的供應商",
  ADD_PROPERTY_BUTTON: "添加物業",
  ADD_PROPERTY_LABEL: "添加新物業",
  ADD_SUPPLIER: "添加供應商",
  ADMIN_EDIT_PAYEE_ERROR: "\u003cp\u003e編輯出現問題。 請重試。\u003c/p\u003e",
  ADMIN_EDIT_PAYEE_SUCCESS: "編輯已保存。",
  ADMIN_MENU_ITEM: "管理",
  ALERTS_MENU_ITEM: "我的提示",
  ALREADY_PROCESSED_OR_DELETED_PAYMENT_ERROR: "付款已完成或已刪除",
  ALREADY_REFUNDED_OR_DELETED_PAYMENT_ERROR: "付款已被退還或刪除",
  AMEX_COMMON_FEE_LABEL: "Amex",
  AMEX_LABEL: "Amex",
  AMOUNT_DOES_NOT_MATCH_ITEMS_SUBTOTALS:
    "此金額與您的商品小計不符，請檢查商品詳情或刪除所有選定的商品。",
  AMOUNT_EXCEED_ERROR: "該付款金額超過了我們的最高限額。",
  AMOUNT_LABEL: "數額",
  APPLY_ALL_BUTTON: "套用於所有項目",
  APPLY_ALL_INSTRUCTIONS: "如果你想對所有供應商留下相同的備註，請於此處輸入。",
  APPROVAL_REQUEST: "批准請求",
  AT_LEAST_ONE_PAYMENT_TYPE: "請選擇至少一種付款方式。",
  AU_POSTAL_CODE_MAX_LENGTH_ERROR: "請輸入少於4個字符。",
  AU_T1_DEADLINE:
    "如果您在今天美國東部標準時間下午6:30點之前付款，您將能夠在下一個工作日之前結清您的付款。",
  BACK_BUTTON: "返回",
  BANK_ACCOUNT_HOLDER_COLUMN: "銀行賬戶持有人姓名",
  BANK_ACCOUNT_HOLDER_LABEL: "銀行賬戶持有人姓名",
  BANK_ACCOUNT_HOLDER_PLACEHOLDER: "銀行賬戶持有人姓名",
  BANK_DETAILS_INSTRUCTIONS:
    "請提供銀行賬戶資料以便存入所有收到的款項。該賬戶必須是在的銀行賬戶",
  BANK_DETAILS_INSTRUCTIONS_AU: "接受澳元的澳大利亞。",
  BANK_DETAILS_INSTRUCTIONS_HK: "接受港幣的香港。",
  BANK_DETAILS_INSTRUCTIONS_MY: "馬來西亞接受馬幣。",
  BANK_DETAILS_INSTRUCTIONS_SG: "新加坡接受新幣。",
  BANK_DETAILS_SECTION: "銀行詳細資料",
  BANK_LABEL: "銀行",
  BANK_NAME_LABEL: "銀行名稱",
  BANK_PLACEHOLDER: "選擇銀行",
  BANK_RECIPIENT_GENERAL_ERROR:
    "呃，有資料或許不正確。請檢查你的收款人的銀行資料。",
  BANK_STATEMENT_ACCOUNT: "帳號號碼",
  BANK_STATEMENT_DATE: "月結單日期",
  BANK_STATEMENT_HEADER: "銀行月結單",
  BANK_STATEMENT_INSTRUCTIONS:
    "上傳你最近的銀行對賬單副本（日期在過去3個月內）。該銀行賬戶必須屬於你建立收款賬戶的公司。銀行對賬單必須包括",
  BANK_STATEMENT_INSTRUCTIONS_EXTRA:
    "請確保你的文件包含上述資料，以避免延誤任何付款",
  BANK_STATEMENT_MESSAGE:
    "請上傳你最近的銀行月結單副本（30天內），以確認資金的可用性和來源。你的月結單應包括：",
  BANK_STATEMENT_MESSAGE_INT:
    "請為您的海外收款賬戶上傳您最近（過去3個月內）的銀行對賬單副本。 您的聲明應包括：",
  BANK_STATEMENT_NAME: "你的姓名",
  BANK_STATEMENT_PURPOSE_LABEL: "輸入付款詳情及上傳銀行月結單",
  BETA_BADGE: "測試版",
  BE_PAID_CALENDAR_SUBTITLE: "本月收到的總金額",
  BE_PAID_CALENDAR_TITLE: "已收取款項",
  BE_PAID_LABEL: "收款",
  BLOCKED_USER_MYINFO_ERROR: "此帳戶已被暫停.",
  BT_INTERNAL_TECHNICAL_ISSUE:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  BUSINESS_DESCRIPTION_LABEL: "業務介紹",
  BUSINESS_DESCRIPTION_PLACEHOLDER: "選擇公司行業",
  BUSINESS_INDUSTRY_1: "軟件",
  BUSINESS_INDUSTRY_10: "書籍",
  BUSINESS_INDUSTRY_100: "特種行業承包商",
  BUSINESS_INDUSTRY_101: "電信服務",
  BUSINESS_INDUSTRY_102: "冷氣和暖氣承包商",
  BUSINESS_INDUSTRY_103: "其他建築服務",
  BUSINESS_INDUSTRY_104: "金屬服務中心",
  BUSINESS_INDUSTRY_105: "衛生和專業清潔",
  BUSINESS_INDUSTRY_106: "建築材料",
  BUSINESS_INDUSTRY_107: "商業設備",
  BUSINESS_INDUSTRY_108: "花店和花卉用品",
  BUSINESS_INDUSTRY_109: "食品與飲品",
  BUSINESS_INDUSTRY_11: "音樂或其他媒體",
  BUSINESS_INDUSTRY_110: "紡織品",
  BUSINESS_INDUSTRY_111: "硬件設備和用品",
  BUSINESS_INDUSTRY_112: "工業供應商",
  BUSINESS_INDUSTRY_113: "辦公和商業家具",
  BUSINESS_INDUSTRY_114: "辦公用品和文具",
  BUSINESS_INDUSTRY_115: "攝影器材",
  BUSINESS_INDUSTRY_116: "管道和加熱設備",
  BUSINESS_INDUSTRY_117: "醫療器械和實驗室設備",
  BUSINESS_INDUSTRY_12: "遊戲",
  BUSINESS_INDUSTRY_13: "博客和書面內容",
  BUSINESS_INDUSTRY_14: "其他數碼產品",
  BUSINESS_INDUSTRY_16: "餐館和夜生活",
  BUSINESS_INDUSTRY_17: "雜貨店",
  BUSINESS_INDUSTRY_18: "餐飲業",
  BUSINESS_INDUSTRY_19: "其他食品餐飲",
  BUSINESS_INDUSTRY_2: "服裝和配件",
  BUSINESS_INDUSTRY_20: "顧問諮詢",
  BUSINESS_INDUSTRY_21: "印刷出版",
  BUSINESS_INDUSTRY_22: "律師",
  BUSINESS_INDUSTRY_23: "會計、審計或稅務準備",
  BUSINESS_INDUSTRY_24: "電腦維修",
  BUSINESS_INDUSTRY_25: "測試實驗室",
  BUSINESS_INDUSTRY_26: "汽車服務",
  BUSINESS_INDUSTRY_27: "租車",
  BUSINESS_INDUSTRY_28: "汽車銷售",
  BUSINESS_INDUSTRY_29: "發掘潛在客戶",
  BUSINESS_INDUSTRY_3: "家居用品和家具",
  BUSINESS_INDUSTRY_30: "直銷",
  BUSINESS_INDUSTRY_31: "公用事業",
  BUSINESS_INDUSTRY_32: "政府服務",
  BUSINESS_INDUSTRY_33: "電話營銷",
  BUSINESS_INDUSTRY_34: "抵押貸款諮詢服務",
  BUSINESS_INDUSTRY_35: "保用服務",
  BUSINESS_INDUSTRY_36: "其他營銷服務",
  BUSINESS_INDUSTRY_37: "其他商業服務",
  BUSINESS_INDUSTRY_38: "公民、兄弟或社會協會",
  BUSINESS_INDUSTRY_39: "慈善機構或社會服務組織",
  BUSINESS_INDUSTRY_4: "家用電子產品",
  BUSINESS_INDUSTRY_40: "宗教組織",
  BUSINESS_INDUSTRY_41: "鄉村俱樂部",
  BUSINESS_INDUSTRY_42: "政治組織",
  BUSINESS_INDUSTRY_43: "其他成員組織",
  BUSINESS_INDUSTRY_44: "個人服務",
  BUSINESS_INDUSTRY_45: "攝影工作室",
  BUSINESS_INDUSTRY_46: "健康和美容水療",
  BUSINESS_INDUSTRY_47: "沙龍或理髮店",
  BUSINESS_INDUSTRY_48: "景觀美化服務",
  BUSINESS_INDUSTRY_49: "按摩店",
  BUSINESS_INDUSTRY_5: "汽車零部件和配件",
  BUSINESS_INDUSTRY_50: "諮商服務",
  BUSINESS_INDUSTRY_51: "健康和保健指導",
  BUSINESS_INDUSTRY_52: "洗衣或清潔服務",
  BUSINESS_INDUSTRY_53: "約會服務",
  BUSINESS_INDUSTRY_54: "其他個人服務",
  BUSINESS_INDUSTRY_55: "乘車",
  BUSINESS_INDUSTRY_56: "出租車和豪華轎車",
  BUSINESS_INDUSTRY_57: "快遞服務",
  BUSINESS_INDUSTRY_58: "停車場",
  BUSINESS_INDUSTRY_59: "貨運代理",
  BUSINESS_INDUSTRY_6: "其他商品",
  BUSINESS_INDUSTRY_60: "運輸或轉運",
  BUSINESS_INDUSTRY_61: "通勤交通",
  BUSINESS_INDUSTRY_62: "郵輪公司",
  BUSINESS_INDUSTRY_63: "航空公司和航空承運人",
  BUSINESS_INDUSTRY_64: "其他運輸服務",
  BUSINESS_INDUSTRY_65: "財產租賃",
  BUSINESS_INDUSTRY_66: "酒店、旅館或汽車旅館",
  BUSINESS_INDUSTRY_67: "分時度假",
  BUSINESS_INDUSTRY_68: "其他旅行住宿",
  BUSINESS_INDUSTRY_70: "醫療器械和實驗室設備",
  BUSINESS_INDUSTRY_71: "醫生",
  BUSINESS_INDUSTRY_72: "配鏡師及眼鏡商",
  BUSINESS_INDUSTRY_73: "牙醫和牙齒矯正師",
  BUSINESS_INDUSTRY_74: "脊椎按摩師",
  BUSINESS_INDUSTRY_75: "護理或個人護理設施",
  BUSINESS_INDUSTRY_76: "醫院",
  BUSINESS_INDUSTRY_77: "心理健康服務",
  BUSINESS_INDUSTRY_78: "輔助生活",
  BUSINESS_INDUSTRY_79: "獸醫服務",
  BUSINESS_INDUSTRY_8: "軟件即服務",
  BUSINESS_INDUSTRY_80: "醫療機構",
  BUSINESS_INDUSTRY_81: "遠程醫療和遠程保健",
  BUSINESS_INDUSTRY_82: "其他醫療服務",
  BUSINESS_INDUSTRY_83: "托兒服務",
  BUSINESS_INDUSTRY_84: "學院或大學",
  BUSINESS_INDUSTRY_85: "職業或貿易培訓機構",
  BUSINESS_INDUSTRY_86: "小學或中學",
  BUSINESS_INDUSTRY_87: "其他教育服務",
  BUSINESS_INDUSTRY_89: "活動票務",
  BUSINESS_INDUSTRY_9: "應用程序",
  BUSINESS_INDUSTRY_90: "旅遊景點",
  BUSINESS_INDUSTRY_91: "休閒營",
  BUSINESS_INDUSTRY_92: "音樂家、樂隊、管弦樂隊",
  BUSINESS_INDUSTRY_93: "遊樂園、狂歡節或馬戲團",
  BUSINESS_INDUSTRY_94: "電影院",
  BUSINESS_INDUSTRY_95: "其他娛樂休閒",
  BUSINESS_INDUSTRY_97: "總承包商",
  BUSINESS_INDUSTRY_98: "電氣承包商",
  BUSINESS_INDUSTRY_99: "木工承包商",
  BUSINESS_INDUSTRY_GROUP_1: "零售業",
  BUSINESS_INDUSTRY_GROUP_10: "醫療服務",
  BUSINESS_INDUSTRY_GROUP_11: "娛樂休閒",
  BUSINESS_INDUSTRY_GROUP_12: "建築服務",
  BUSINESS_INDUSTRY_GROUP_2: "專業服務",
  BUSINESS_INDUSTRY_GROUP_3: "會員組織",
  BUSINESS_INDUSTRY_GROUP_4: "交通運輸",
  BUSINESS_INDUSTRY_GROUP_5: "旅行住宿",
  BUSINESS_INDUSTRY_GROUP_6: "教育",
  BUSINESS_INDUSTRY_GROUP_7: "數碼產品",
  BUSINESS_INDUSTRY_GROUP_8: "食品飲料",
  BUSINESS_INDUSTRY_PLACEHOLDER: "請選擇你的行業",
  BUSINESS_TYPE_1: "公司",
  BUSINESS_TYPE_2: "有限責任公司",
  BUSINESS_TYPE_3: "非牟利機構",
  BUSINESS_TYPE_4: "合夥經營",
  BUSINESS_TYPE_5: "獨資經營",
  BUSINESS_TYPE_LABEL: "業務類型",
  CALCULATE_PAYMENT_AMOUNT: "計算付款金額",
  CALCULATING_PAYMENT_AMOUNT: "計算支付金額",
  CALENDAR_CHARGE_DATE_LEGEND: "扣賬日期：你的信用卡將在這天被扣款",
  CALENDAR_CURRENT_PAYOUT_DATE_LEGEND: "當前付款日期",
  CALENDAR_DUE_DATE_LEGEND: "到期日：客戶需要在此日期之前付款",
  CALENDAR_HOLIDAY_LEGEND: "公衆假期",
  CALENDAR_PAYOUT_DATE_LEGEND: "付款日期：你的收款人將在這天收到付款",
  CALENDAR_SELECT_DATE_ERROR: "請選擇一個日期",
  CANCELLED: "已取消",
  CANCELLED_DUE_TO_DELETED_CARD: "此項付款已被取消，因為與之相關的卡已被刪除。",
  CANCELLED_DUE_TO_DELETED_PROPERTY: "由於相關的財產已被刪除，該款項已被取消",
  CANCELLED_DUE_TO_DELETED_SCHEDULE: "根據用戶的要求，該款項已被取消",
  CANCEL_BUTTON: "取消",
  CANNOT_ADD_LATE_PAYMENT_REMEDIATION_AND_INSTALLMENT_PLAN_IN_A_PAYMENT_REQUEST:
    "選擇分期付款計劃或滯納金。",
  CANNOT_APPLY_COUPON_ERROR: "優惠券代碼無效。",
  CARD_ADD_LABEL: "添加一張信用卡進行支付",
  CARD_BRAND_AMEX_LABEL: "美國運通",
  CARD_BRAND_CHINA_UNIONPAY_LABEL: "中國銀聯",
  CARD_BRAND_MASTERCARD_LABEL: "萬事達",
  CARD_BRAND_VISA_AND_MASTER_LABEL: "維薩卡和萬事達卡",
  CARD_BRAND_VISA_LABEL: "維薩卡",
  CARD_CONFIRMATION_CHECKBOX:
    "我同意\u003ca\u003e服務條款\u003c/a\u003e，並確認付款金額和所有付款詳細信息正確無誤。",
  CARD_CONFIRMATION_CHECKBOX_WALLEX:
    "我同意\u003ca\u003e服務條款\u003c/a\u003e，並確認付款金額和所有付款詳細信息正確無誤，並且我知道我的付款將在{payout_date}到達。",
  CARD_CVC_LABEL: "CVC/CVV",
  CARD_CVC_PLACEHOLDER: "CVC/CVV",
  CARD_DETAILS_HEADING: "信用卡詳情",
  CARD_ENCRYPTED_MESSAGE: "你輸入的信用卡信息是加密的。",
  CARD_EXPIRED: "請使用有效期內的銀行卡。",
  CARD_EXPIRED_ERROR: "請使用有效期內的銀行卡。",
  CARD_EXPIRY_LABEL: "到期日",
  CARD_EXPIRY_PLACEHOLDER: "月份/年份",
  CARD_IMAGE_EXPIRY: "到期日",
  CARD_IMAGE_HOLDER: "持卡人姓名",
  CARD_IMAGE_NUMBER: "信用卡號",
  CARD_IMAGE_YOUR_NAME: "你的名字",
  CARD_INFORMATION_DESCRIPTION:
    "請於下方輸入你的信用卡資料。所有交易均安全並且經加密處理。",
  CARD_INFORMATION_HEADING: "信用卡資訊",
  CARD_LICENSE_FEE_NOTE: "牌照費金額取決於所使用的信用卡。",
  CARD_NAME_PLACEHOLDER: "持卡人姓名",
  CARD_NUMBER_LABEL: "卡號",
  CARD_NUMBER_PLACEHOLDER: "1234 1234 1234 1234",
  CARD_PAY_BUTTON: "支付",
  CARD_REAUTHORIZED_MESSAGE:
    "看不到你的信用卡？你的卡需要重新授權才能付款予這個國家的收款人。",
  CARD_RECORDS_NOTE:
    "這說明將出現在你的信用卡月結單，並會出現在控制界面和付款詳情中「付款說明」一欄。",
  CARD_RECORDS_PLACEHOLDER: "最多22個字符",
  CARD_RECORDS_TOOLTIP:
    "這是內部記錄，供您記錄此筆付款的付款目的。 這將在控制界面和付款明細中顯示為您的“付款說明”。",
  CARD_SAVE_CHECKBOX: "保存我的信用卡資料以繳付未來款項",
  CARD_SELECTED_LABEL: "已選信用卡",
  CARD_SELECTED_PLACEHOLDER: "選擇信用卡",
  CARD_SELECTED_SECTION: "已選信用卡",
  CARD_SELECT_LABEL: "選擇一張信用卡進行支付",
  CARD_SLIDER_ADD_INSTRUCTIONS: "點擊此處添加新卡",
  CARD_SLIDER_AUTHORIZED_TOOLTIP: "這張卡已被授權向本國的收款人支付。",
  CARD_SLIDER_BUTTON: "編輯詳情",
  CARD_TOTAL_LABEL: "總金額",
  CARD_WAS_DELETED: "此卡不再可用。 請選擇另一張卡。",
  CHANGE_PASSWORD_CONFIRM: "確認新密碼",
  CHANGE_PASSWORD_CONFIRM_PLACEHOLDER: "請再次輸入新密碼",
  CHANGE_PASSWORD_HERO: "更改密碼",
  CHANGE_PASSWORD_NEW: "新密碼",
  CHANGE_PASSWORD_NEW_PLACEHOLDER: "請輸入新密碼",
  CHANGE_PASSWORD_OLD: "舊密碼",
  CHANGE_PASSWORD_OLD_PLACEHOLDER: "請輸入你的舊密碼",
  CHANGE_PASSWORD_SUCCESS: "更新",
  CHARACTER_LIMIT_60: "請在此處輸入不超過 60 個字符。",
  CHECKBOX_SELECT_ALL_LABEL: "全選",
  CHECKOUT_GENERAL_ISSUE:
    "您的付款請求似乎存在技術問題。請重試或通過\u003ca\u003esupport@ipaymy.com\u003c/a\u003e聯繫我們。",
  CHECK_SPAM:
    "沒有收到電郵？請檢查你的垃圾郵件/垃圾郵件文件夾，或者點擊 「重新發送驗證電郵 」",
  CITY_LABEL: "城市",
  CITY_PLACEHOLDER: "城市",
  CKO_ERROR_3DS_MALFUNCTION:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_3DS_NOT_CONFIGURED:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_3DS_NOT_ENABLED_FOR_CARD:
    "ipaymy不支持用此卡支付。你可以聯繫我們以獲得更多資訊。",
  CKO_ERROR_3DS_NOT_SUPPORTED:
    "ipaymy不支持用此卡支付。你可以聯繫我們以獲得更多資訊。",
  CKO_ERROR_3DS_PAYMENT_REQUIRED:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_3DS_VERSION_INVALID:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_3DS_VERSION_NOT_SUPPORTED:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_ADDRESS_INVALID:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  CKO_ERROR_AMOUNT_EXCEEDS_BALANCE:
    "由於信用額度不足，我們無法完成你的付款。你所使用的信用卡可能已達最高信用額度。請調整付款金額或使用其他信用卡。",
  CKO_ERROR_AMOUNT_INVALID:
    "該交易無法被授權。請嘗試使用其他卡或聯繫你的發卡機構了解更多資訊。",
  CKO_ERROR_API_CALLS_QUOTA_EXCEEDED:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_BILLING_DESCRIPTOR_CITY_INVALID:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_BILLING_DESCRIPTOR_CITY_REQUIRED:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_BILLING_DESCRIPTOR_NAME_INVALID:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_BILLING_DESCRIPTOR_NAME_REQUIRED:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_BUSINESS_INVALID:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_BUSINESS_SETTINGS_MISSING:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_CAPTURE_VALUE_GREATER_THAN_AUTHORIZED:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_CAPTURE_VALUE_GREATER_THAN_REMAINING_AUTHORIZED:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_CARD_AUTHORIZATION_FAILED:
    "該交易無法被授權。請嘗試使用其他卡或聯繫你的發卡機構了解更多資訊。",
  CKO_ERROR_CARD_DISABLED:
    "該交易無法被授權。請嘗試使用其他卡或聯繫你的發卡機構了解更多資訊。",
  CKO_ERROR_CARD_EXPIRED: "信用卡的有效期不正確。檢查到期日或使用其他卡。",
  CKO_ERROR_CARD_EXPIRY_MONTH_INVALID:
    "信用卡的有效期不正確。檢查到期日或使用其他卡。",
  CKO_ERROR_CARD_EXPIRY_MONTH_REQUIRED:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_CARD_EXPIRY_YEAR_INVALID:
    "信用卡的有效期不正確。檢查到期日或使用其他卡。",
  CKO_ERROR_CARD_EXPIRY_YEAR_REQUIRED:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_CARD_HOLDER_INVALID:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_CARD_NOT_FOUND:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_CARD_NUMBER_INVALID: "卡號不正確。請檢查卡號或使用其他卡。",
  CKO_ERROR_CARD_NUMBER_REQUIRED:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_CHANNEL_DETAILS_INVALID:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_CHANNEL_URL_MISSING:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_CHARGE_DETAILS_INVALID:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_CITY_INVALID:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_COUNTRY_ADDRESS_INVALID:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_COUNTRY_INVALID:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_COUNTRY_PHONE_CODE_INVALID:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_COUNTRY_PHONE_CODE_LENGTH_INVALID:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_CURRENCY_INVALID:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_CURRENCY_REQUIRED:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_CUSTOMER_ALREADY_EXISTS:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_CUSTOMER_EMAIL_INVALID:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_CUSTOMER_ID_INVALID:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_CUSTOMER_MISMATCH:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_CUSTOMER_NOT_FOUND:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_CUSTOMER_NUMBER_INVALID:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_CUSTOMER_PLAN_EDIT_FAILED:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_CUSTOMER_PLAN_ID_INVALID:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_CVV_INVALID:
    "銀行卡的CVC/CVV不正確。請檢查卡的安全碼或使用其他卡。",
  CKO_ERROR_DO_NOT_HONOR:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  CKO_ERROR_EMAIL_INVALID:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_EMAIL_IN_USE:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_EMAIL_REQUIRED:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_ENDPOINT_INVALID:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_EXPIRY_DATE_FORMAT_INVALID:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_FAIL_URL_INVALID:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_FIRST_NAME_REQUIRED:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_IP_ADDRESS_INVALID:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  CKO_ERROR_ISSUER_NETWORK_UNAVAILABLE:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  CKO_ERROR_LAST_NAME_REQUIRED:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_METADATA_KEY_INVALID:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_PARAMETER_INVALID:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_PASSWORD_INVALID:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_PAYMENTS_NOT_SUPPORTED:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_PAYMENT_EXPIRED:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_PAYMENT_INVALID:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_PAYMENT_METHOD_INVALID:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_PAYMENT_METHOD_NOT_SUPPORTED:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_PAYMENT_SOURCE_DOES_NOT_EXIST:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_PAYMENT_SOURCE_REQUIRED:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_PAYMENT_TYPE_INVALID:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_PHONE_NUMBER_INVALID:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_PHONE_NUMBER_LENGTH_INVALID:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_PREVIOUS_PAYMENT_ID_INVALID:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_PROCESSING_ERROR:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_PROCESSING_KEY_REQUIRED:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_PROCESSING_VALUE_REQUIRED:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_RECIPIENT_ACCOUNT_NUMBER_INVALID:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_RECIPIENT_ACCOUNT_NUMBER_REQUIRED:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_RECIPIENT_DOB_INVALID:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_RECIPIENT_DOB_REQUIRED:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_RECIPIENT_LAST_NAME_REQUIRED:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_RECIPIENT_ZIP_INVALID:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_RECIPIENT_ZIP_REQUIRED:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_RECURRING_PLAN_EXISTS:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_RECURRING_PLAN_NOT_EXIST:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_RECURRING_PLAN_REMOVAL_FAILED:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_REFUNDS_NOT_SUPPORTED:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_REQUEST_INVALID:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_REQUEST_JSON_INVALID:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_RISK_ENABLED_REQUIRED:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_SERVER_API_NOT_ALLOWED:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_SERVICE_UNAVAILABLE:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_SOURCE_EMAIL_INVALID:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_SOURCE_EMAIL_REQUIRED:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_SOURCE_ID_INVALID:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_SOURCE_ID_OR_EMAIL_REQUIRED:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_SOURCE_ID_REQUIRED:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_SOURCE_ID_UNKNOWN:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_SOURCE_INVALID:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_SOURCE_OR_DESTINATION_REQUIRED:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_SOURCE_TOKEN_INVALID:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_SOURCE_TOKEN_REQUIRED:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_SOURCE_TOKEN_TYPE_INVALID:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_SOURCE_TOKEN_TYPE_REQUIRED:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_SOURCE_TYPE_REQUIRED:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_SUCCESS_URL_INVALID:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_TOKEN_EXPIRED:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_TOKEN_INVALID:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_TOKEN_IN_USE:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_TOKEN_REQUIRED:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_TOKEN_TYPE_REQUIRED:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_TOKEN_USED:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_VOID_AMOUNT_INVALID:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_WALLET_ID_INVALID:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_ERROR_ZIP_INVALID:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CKO_INTERNAL_TECHNICAL_ISSUE:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  CLOSE_ACCOUNT_REASON_1: "我想註冊一個新的Fetch帳戶。",
  CLOSE_ACCOUNT_REASON_2: "我不願意為使用該應用付費。",
  CLOSE_ACCOUNT_REASON_3: "當我需要幫助時很難獲得支持。",
  CLOSE_ACCOUNT_REASON_4: "我改用了另一個應用程序。",
  CLOSE_ACCOUNT_REASON_5: "該應用程序難以使用。",
  CLOSE_ACCOUNT_REASON_6: "該應用程序與我期望的不同。",
  CLOSE_ACCOUNT_REASON_7: "該應用程序對我的付款沒有太大幫助。",
  CLOSE_ACCOUNT_REASON_8: "我不再需要開具發票。",
  CLOSE_ACCOUNT_REASON_9: "其他",
  CLOSE_BUTTON: "關閉",
  COMING_SOON: "即將推出",
  COMMENT_HELP_COMPANY_REQUEST:
    "您可以在提交新公司上市請求後立即使用 ipaymy，但請注意，在上市批准期間，任何付款都將被扣留。 ipaymy 會在列表獲得批准後通知您，這通常不會晚於 1 個工作日。",
  COMPANIES_HERO: "我的公司",
  COMPANIES_INSTRUCTIONS:
    "這是你的註冊公司。有新公司嗎？{br}點擊 「添加新公司 」來添加。",
  COMPANIES_MENU_ITEM: "公司",
  COMPANY_ABN_NOT_FOUND:
    "未找到或未列出公司或ABN？ 在\u003ca\u003e這裡\u003c/a\u003e找到它，或者只是在\u003cb\u003e這裡\u003c/b\u003e請求上市批准",
  COMPANY_ADDRESS_1_PLACEHOLDER: "公司地址第一行",
  COMPANY_ADDRESS_2_PLACEHOLDER: "公司地址第二行",
  COMPANY_ADDRESS_LABEL: "公司地址",
  COMPANY_ALREADY_EXISTS_ERROR: "這個公司已經存在。",
  COMPANY_CRN_NOT_FOUND:
    "未找到或未列出公司或CRN？ 在\u003ca\u003e這裡\u003c/a\u003e找到它，或者只是在\u003cb\u003e這裡\u003c/b\u003e請求上市批准",
  COMPANY_EMAIL_PLACEHOLDER: "公司電郵地址",
  COMPANY_EXISTED: "該公司已經存在。請與你的管理員聯繫。",
  COMPANY_LOCATION_LABEL: "公司的總部設在哪裡？",
  COMPANY_NAME_COLUMN: "公司名稱",
  COMPANY_NAME_CONFIRMATION_LABEL: "公司名稱確認",
  COMPANY_NAME_LABEL: "公司名稱",
  COMPANY_NAME_PLACEHOLDER: "公司名稱",
  COMPANY_REGISTRATION_ERROR: "請輸入你的公司商業登記號碼",
  COMPANY_REGISTRATION_PLACEHOLDER: "公司商業登記號碼",
  COMPANY_SEARCH_PLACEHOLDER: "輸入公司名稱或編號...",
  COMPANY_SECTION: "公司",
  COMPANY_TELL_MORE_INSTRUCTIONS: "告訴我們更多關於貴公司的資訊",
  COMPANY_UEN_NOT_FOUND:
    "未找到或未列出公司或UEN？ 在\u003ca\u003e這裡\u003c/a\u003e找到它，或者只是在\u003cb\u003e這裡\u003c/b\u003e請求上市批准",
  COMPANY_UEN_REQUEST: "公司或 UEN 未找到或未列出？ 在此處申請上市批准",
  COMPANY_WELCOME_BE_LISTED: "確認您要列出的公司詳細信息",
  COMPLETE_PAYMENT_DESCRIPTION: "查看詳細資訊並完成付款",
  COMPLETE_PAYMENT_HERO: "完成你的{render}付款",
  CONFIRM_ACCOUNT_NUMBER_LABEL: "確認帳戶",
  CONFIRM_ACCOUNT_NUMBER_PLACEHOLDER: "確認賬戶",
  CONFIRM_BANK_POLICY_LABEL:
    "我們建議你先向保險公司確認與你的保單相關的銀行資料。",
  CONFIRM_BUTTON: "確認",
  CONFIRM_EMAIL_LABEL: "確認電子郵件地址",
  CONFIRM_EMAIL_PLACEHOLDER: "確認電子郵件地址",
  CONFIRM_PASSWORD_ERROR: "確認_密碼_錯誤",
  CONFIRM_PASSWORD_PLACEHOLDER: "確認密碼",
  CONFIRM_SEND_BUTTON: "確認並發送",
  CONFIRM_SEND_HERO: "確認和發送",
  CONTACT_SUPPORT: "聯繫支援團隊",
  COUNTRY_1: "新加坡",
  COUNTRY_10: "越南",
  COUNTRY_100: "伊拉克",
  COUNTRY_101: "愛爾蘭",
  COUNTRY_102: "馬恩島",
  COUNTRY_103: "以色列",
  COUNTRY_104: "意大利",
  COUNTRY_105: "象牙海岸",
  COUNTRY_106: "牙買加",
  COUNTRY_107: "日本",
  COUNTRY_108: "澤西島",
  COUNTRY_109: "喬丹",
  COUNTRY_11: "南極洲",
  COUNTRY_110: "哈薩克斯坦",
  COUNTRY_111: "肯尼亞",
  COUNTRY_112: "基里巴斯",
  COUNTRY_113: "科索沃",
  COUNTRY_114: "科威特",
  COUNTRY_115: "吉爾吉斯斯坦",
  COUNTRY_116: "寮國",
  COUNTRY_117: "拉脫維亞",
  COUNTRY_118: "黎巴嫩",
  COUNTRY_119: "萊索托",
  COUNTRY_12: "安提瓜和巴布達",
  COUNTRY_120: "利比里亞",
  COUNTRY_121: "利比亞",
  COUNTRY_122: "列支敦士登",
  COUNTRY_123: "立陶宛",
  COUNTRY_124: "盧森堡",
  COUNTRY_125: "澳門",
  COUNTRY_126: "馬其頓",
  COUNTRY_127: "馬達加斯加",
  COUNTRY_128: "馬拉維",
  COUNTRY_129: "馬爾代夫",
  COUNTRY_13: "阿根廷",
  COUNTRY_130: "馬里",
  COUNTRY_131: "馬耳他",
  COUNTRY_132: "馬紹爾群島",
  COUNTRY_133: "毛里塔尼亞",
  COUNTRY_134: "毛里求斯",
  COUNTRY_135: "馬約特島",
  COUNTRY_136: "墨西哥",
  COUNTRY_137: "密克羅尼西亞",
  COUNTRY_138: "摩爾多瓦",
  COUNTRY_139: "摩納哥",
  COUNTRY_14: "亞美尼亞",
  COUNTRY_140: "蒙古",
  COUNTRY_141: "黑山",
  COUNTRY_142: "蒙特塞拉特",
  COUNTRY_143: "摩洛哥",
  COUNTRY_144: "莫桑比克",
  COUNTRY_145: "緬甸",
  COUNTRY_146: "納米比亞",
  COUNTRY_147: "瑙魯",
  COUNTRY_148: "尼泊爾",
  COUNTRY_149: "荷蘭",
  COUNTRY_15: "阿魯巴島",
  COUNTRY_150: "荷屬安的列斯群島",
  COUNTRY_151: "新喀裡多尼亞",
  COUNTRY_152: "新西蘭",
  COUNTRY_153: "尼加拉瓜",
  COUNTRY_154: "尼日爾",
  COUNTRY_155: "尼日利亞",
  COUNTRY_156: "紐埃島",
  COUNTRY_157: "北韓",
  COUNTRY_158: "北馬里亞納群島",
  COUNTRY_159: "挪威",
  COUNTRY_16: "澳洲",
  COUNTRY_160: "阿曼",
  COUNTRY_161: "巴基斯坦",
  COUNTRY_162: "帕勞",
  COUNTRY_163: "巴勒斯坦",
  COUNTRY_164: "巴拿馬",
  COUNTRY_165: "巴布亞新幾內亞",
  COUNTRY_166: "巴拉圭",
  COUNTRY_167: "秘魯",
  COUNTRY_168: "菲律賓",
  COUNTRY_169: "皮特凱恩",
  COUNTRY_17: "奧地利",
  COUNTRY_170: "波蘭",
  COUNTRY_171: "葡萄牙",
  COUNTRY_172: "波多黎各",
  COUNTRY_173: "卡塔爾",
  COUNTRY_174: "剛果共和國",
  COUNTRY_175: "留尼旺",
  COUNTRY_176: "羅馬尼亞",
  COUNTRY_177: "俄羅斯",
  COUNTRY_178: "盧旺達",
  COUNTRY_179: "聖巴泰勒米",
  COUNTRY_18: "阿塞拜疆",
  COUNTRY_180: "聖赫勒拿島",
  COUNTRY_181: "聖基茨和尼維斯",
  COUNTRY_182: "聖盧西亞",
  COUNTRY_183: "聖馬丁",
  COUNTRY_184: "聖皮埃爾和密克隆",
  COUNTRY_185: "聖文森特和格林納丁斯",
  COUNTRY_186: "薩摩亞",
  COUNTRY_187: "聖馬力諾",
  COUNTRY_188: "聖多美和普林西比",
  COUNTRY_189: "沙特阿拉伯",
  COUNTRY_19: "巴哈馬",
  COUNTRY_190: "塞內加爾",
  COUNTRY_191: "塞爾維亞",
  COUNTRY_192: "塞舌爾",
  COUNTRY_193: "塞拉利昂",
  COUNTRY_194: "聖馬丁島",
  COUNTRY_195: "斯洛伐克",
  COUNTRY_196: "斯洛文尼亞",
  COUNTRY_197: "所羅門群島",
  COUNTRY_198: "索馬里",
  COUNTRY_199: "南非",
  COUNTRY_2: "馬來西亞",
  COUNTRY_20: "巴林",
  COUNTRY_200: "南韓",
  COUNTRY_201: "南蘇丹",
  COUNTRY_202: "西班牙",
  COUNTRY_203: "斯里蘭卡",
  COUNTRY_204: "蘇丹",
  COUNTRY_205: "蘇里南",
  COUNTRY_206: "斯瓦爾巴和揚-馬延",
  COUNTRY_207: "斯威士蘭",
  COUNTRY_208: "瑞典",
  COUNTRY_209: "瑞士",
  COUNTRY_21: "孟加拉",
  COUNTRY_210: "敘利亞",
  COUNTRY_211: "台灣",
  COUNTRY_212: "塔吉克斯坦",
  COUNTRY_213: "坦桑尼亞",
  COUNTRY_214: "泰國",
  COUNTRY_215: "多哥",
  COUNTRY_216: "托克勞",
  COUNTRY_217: "湯加",
  COUNTRY_218: "特立尼達和多巴哥",
  COUNTRY_219: "突尼斯",
  COUNTRY_22: "巴巴多斯",
  COUNTRY_220: "土耳其",
  COUNTRY_221: "土庫曼斯坦",
  COUNTRY_222: "特克斯和凱科斯群島",
  COUNTRY_223: "圖瓦盧",
  COUNTRY_224: "美屬維爾京群島",
  COUNTRY_225: "烏干達",
  COUNTRY_226: "烏克蘭",
  COUNTRY_227: "阿拉伯聯合酋長國",
  COUNTRY_228: "英國",
  COUNTRY_229: "美國",
  COUNTRY_23: "白俄羅斯",
  COUNTRY_230: "烏拉圭",
  COUNTRY_231: "烏茲別克斯坦",
  COUNTRY_232: "瓦努阿圖",
  COUNTRY_233: "梵蒂岡",
  COUNTRY_234: "委內瑞拉",
  COUNTRY_235: "瓦利斯和富圖納",
  COUNTRY_236: "西撒哈拉",
  COUNTRY_237: "也門",
  COUNTRY_238: "贊比亞",
  COUNTRY_239: "津巴布韋",
  COUNTRY_24: "比利時",
  COUNTRY_240: "阿富汗",
  COUNTRY_25: "伯利茲",
  COUNTRY_26: "貝寧",
  COUNTRY_27: "百慕大",
  COUNTRY_28: "不丹",
  COUNTRY_29: "玻利維亞",
  COUNTRY_3: "香港",
  COUNTRY_30: "波斯尼亞和黑塞哥維那",
  COUNTRY_31: "博茨瓦納",
  COUNTRY_32: "巴西",
  COUNTRY_33: "英屬印度洋領土",
  COUNTRY_34: "英屬維爾京群島",
  COUNTRY_35: "文萊",
  COUNTRY_36: "保加利亞",
  COUNTRY_37: "布基納法索",
  COUNTRY_38: "布隆迪",
  COUNTRY_39: "柬埔寨",
  COUNTRY_4: "阿爾巴尼亞",
  COUNTRY_40: "喀麥隆",
  COUNTRY_41: "加拿大",
  COUNTRY_42: "佛得角",
  COUNTRY_43: "開曼群島",
  COUNTRY_44: "中非共和國",
  COUNTRY_45: "乍得",
  COUNTRY_46: "智利",
  COUNTRY_47: "中國",
  COUNTRY_48: "聖誕島",
  COUNTRY_49: "科科斯群島",
  COUNTRY_5: "阿爾及利亞",
  COUNTRY_50: "哥倫比亞",
  COUNTRY_51: "科摩羅",
  COUNTRY_52: "庫克群島",
  COUNTRY_53: "哥斯達黎加",
  COUNTRY_54: "克羅地亞",
  COUNTRY_55: "古巴",
  COUNTRY_56: "庫拉索",
  COUNTRY_57: "塞浦路斯",
  COUNTRY_58: "捷克共和國",
  COUNTRY_59: "剛果民主共和國",
  COUNTRY_6: "美屬薩摩亞",
  COUNTRY_60: "丹麥",
  COUNTRY_61: "吉布提",
  COUNTRY_62: "多米尼克",
  COUNTRY_63: "多米尼加共和國",
  COUNTRY_64: "東帝汶",
  COUNTRY_65: "厄瓜多爾",
  COUNTRY_66: "埃及",
  COUNTRY_67: "薩爾瓦多",
  COUNTRY_68: "赤道幾內亞",
  COUNTRY_69: "厄立特里亞",
  COUNTRY_7: "安道爾",
  COUNTRY_70: "愛沙尼亞",
  COUNTRY_71: "埃塞俄比亞",
  COUNTRY_72: "福克蘭群島",
  COUNTRY_73: "法羅群島",
  COUNTRY_74: "斐濟",
  COUNTRY_75: "芬蘭",
  COUNTRY_76: "法國",
  COUNTRY_77: "法屬波利尼西亞",
  COUNTRY_78: "加蓬",
  COUNTRY_79: "岡比亞",
  COUNTRY_8: "安哥拉",
  COUNTRY_80: "格魯吉亞",
  COUNTRY_81: "德國",
  COUNTRY_82: "加納",
  COUNTRY_83: "直布羅陀",
  COUNTRY_84: "希臘",
  COUNTRY_85: "格陵蘭",
  COUNTRY_86: "格林納達",
  COUNTRY_87: "關島",
  COUNTRY_88: "危地馬拉",
  COUNTRY_89: "格恩西島",
  COUNTRY_9: "安圭拉",
  COUNTRY_90: "幾內亞",
  COUNTRY_91: "幾內亞比紹",
  COUNTRY_92: "圭亞那",
  COUNTRY_93: "海地",
  COUNTRY_94: "洪都拉斯",
  COUNTRY_95: "匈牙利",
  COUNTRY_96: "冰島",
  COUNTRY_97: "印度",
  COUNTRY_98: "印尼",
  COUNTRY_99: "伊朗",
  COUNTRY_LABEL_AU: "澳大利亞",
  COUNTRY_LABEL_HK: "香港",
  COUNTRY_LABEL_MY: "全部",
  COUNTRY_LABEL_SG: "新加坡",
  COUPON_APPLIED_SUCCESS: "優惠券代碼已用",
  COUPON_APPLY_BUTTON: "使用",
  COUPON_CODE_CC_VISA_ERROR:
    "哎呀! 你的信用卡不適用於此推廣活動。請使用新加坡簽發的Visa卡。",
  COUPON_CODE_CC_VISA_MASTERCARD_ERROR:
    "哎呀！你的信用卡不適用於此推廣活動。請使用新加坡發行的Visa或Mastercard卡。",
  COUPON_CODE_INVALID_ERROR: "哎呀! 此推廣代碼無效。",
  COUPON_CODE_SCHEDULE_ERROR:
    "請設定你的付款而其扣賬日期不遲於2019年11月30日。",
  COUPON_FLY_SUCCESS: "好耶！你離乘坐商務艙的奢華飛行又近了一步！",
  COUPON_LOCK_SUCCESS: "恭喜！你已經成功鎖定優惠手續費",
  COUPON_NOT_FOUND: "促銷代碼無效",
  COUPON_REWARD_SUCCESS:
    "使用此優惠券代碼，你已經獲得了 {render1} 的手續費，{br}我們一般手續費是 {render2}",
  COUPON_SAVE_SUCCESS: "恭喜！你已經成功節省",
  CREATE_BUTTON: "建立",
  CREATE_COLLECTION_ACCOUNT_HERO: "建立收款賬戶",
  CREATE_INVOICE_BUTTON: "建立帳單",
  CREATE_INVOICE_HERO: "建立帳單",
  CREATE_INVOICE_MENU_ITEM: "建立帳單",
  CREATE_ORDER_INVOICE_BUTTON: "建立訂單帳單",
  CREATE_ORDER_MENU_ITEM: "建立訂單帳單",
  CREDIT_CARD_CONFIRMATION: "你是否有信用卡？",
  CREDIT_CARD_STATEMENT_INSTRUCTIONS:
    "這些資料可能會出現在你客戶的信用卡賬單上",
  CREDIT_CARD_STATEMENT_INSTRUCTIONS_EXTRA:
    "我們建議使用客戶能夠識別的描述，例如你的企業名稱",
  CREDIT_CARD_STATEMENT_SECTION: "信用卡賬單細節",
  CRN_COLUMN: "商業登記號碼（BRN/CRN）",
  CRN_PLACEHOLDER: "商業登記號碼(BRN/CRN)",
  CURRENCY_CONFIRMATION:
    "若以美金進行結算，你可能會被你的銀行收取匯款費用。{br}該費用會因銀行而異。",
  CURRENCY_CUSTOM: "{currency}{amount, number, money}",
  CURRENCY_HKD_BUTTON: "更改為港幣",
  CURRENCY_LABEL: "貨幣",
  CURRENCY_PLACEHOLDER: "選擇該賬戶的貨幣",
  CUSTOMERS_HERO: "客戶",
  CUSTOMERS_MENU_ITEM: "客戶",
  CUSTOMERS_MOBILE_HEADER: "手機號碼",
  CUSTOMER_ADDRESS_1_LABEL: "地址第一行",
  CUSTOMER_ADDRESS_1_PLACEHOLDER: "地址第一行",
  CUSTOMER_ADDRESS_2_LABEL: "地址第二行",
  CUSTOMER_ADDRESS_2_PLACEHOLDER: "地址第二行",
  CUSTOMER_ADD_OPTION: "添加新客戶",
  CUSTOMER_BUSINESS_REGISTRATION_NUMBER_LABEL: "商業登記號碼(BRN/CRN)",
  CUSTOMER_BUSINESS_REGISTRATION_NUMBER_PLACEHOLDER: "商業登記號碼(BRN/CRN)",
  CUSTOMER_EMAIL_LABEL: "客戶電郵地址",
  CUSTOMER_EMAIL_PLACEHOLDER: "客戶電郵地址",
  CUSTOMER_LABEL: "客戶",
  CUSTOMER_MOBILE_NUMBER_LABEL: "客戶的手提號碼",
  CUSTOMER_MOBILE_NUMBER_PLACEHOLDER: "客戶的手提號碼",
  CUSTOMER_NAME_LABEL: "客戶名稱",
  CUSTOMER_NAME_PLACEHOLDER: "客戶名稱",
  CUSTOMER_PAYS_LABEL: "客戶支付",
  CUSTOMER_PHONE_LABEL: "客戶電話號碼",
  CUSTOMER_PHONE_PLACEHOLDER: "客戶電話號碼",
  CUSTOMER_PLACEHOLDER: "選擇客戶或添加新客戶",
  CUSTOMER_TYPE_LABEL: "個人或商業",
  DASHBOARD_MENU_ITEM: "控制界面",
  DBS_BANK_AMPERSAND_ERROR: "請刪除“\u0026”符號，然後重試。",
  DEBIT_CARD_NOT_SUPPORTED:
    "我們不再接受截至2021年8月26日的借記卡。請使用Visa，MasterCard或銀聯信用卡。",
  DELETE_BUTTON: "刪除",
  DELETE_CARD_DESCRIBE: "刪除此卡同時也會刪除任何相關的定期付款。",
  DELETE_CARD_HERO: "你想刪除此卡嗎？",
  DELETE_COMPANY_CONFIRMATION: "你想刪除這間公司嗎？",
  DELETE_COMPANY_DESCRIPTION: "這間公司將從你的公司列表中刪除",
  DELETE_CUSTOMER_HERO: "您確定要刪除這個客戶嗎？",
  DELETE_CUSTOMER_INSTRUCTIONS:
    "你想以後向該客戶發送任何收款要求，你需要再次添加該客戶。",
  DELETE_PAYEE: "你確定要刪除這個{render}嗎？",
  DETAILS_COMPLETED_PAYMENTS: "\u003cb\u003e已完成\u003c/b\u003e付款",
  DETAILS_CONFIRMED: "所有資料已輸入及確認，現在可以進行下一步了！",
  DETAILS_MAKE_PAYMENT_NOW: "立即安排新的付款。",
  DETAILS_NOTIFICATION: "通知",
  DETAILS_NO_COMPLETED_PAYMENTS: "未有任何已完成的付款。",
  DETAILS_NO_SCHEDULED_PAYMENTS: "未有任何定期付款。",
  DETAILS_PAYMENTS_COLLECTED: "收款\u003cb\u003e已收妥\u003c/b\u003e",
  DETAILS_PAYMENTS_DEPOSITED: "收款\u003cb\u003e已存入\u003c/b\u003e",
  DETAILS_PAYMENTS_IN_PROGRESS: "付款\u003cb\u003e進行中\u003c/b\u003e",
  DETAILS_PAYMENTS_ON_HOLD: "\u003cb\u003e暫停\u003c/b\u003e付款",
  DETAILS_PAYMENTS_REQUESTED: "收款\u003cb\u003e已發送\u003c/b\u003e",
  DETAILS_PLEASE_CHECK: "請檢查並更新你客戶的{render}",
  DETAILS_PLEASE_CHECK_EMAIL: "電郵地址",
  DETAILS_PLEASE_CHECK_MOBILE: "手機號碼",
  DETAILS_REQUEST_FAILED:
    "收款要求（發票號碼\u003cspan\u003erender\u003c/span\u003e）失敗。",
  DETAILS_REQUEST_PAYMENT_NOW: "立即發送收款要求。",
  DETAILS_SCHEDULED_PAYMENTS: "\u003cb\u003e定期\u003c/b\u003e付款",
  DETAILS_SCHEDULE_PAYMENT_NOW: "立即安排定期付款。",
  DONE_BUTTON: "完成",
  DOWNLOAD_CSV_BUTTON: "下載CSV範本",
  DROPDOWN_AUSTRALIA: "澳大利亞",
  DROPDOWN_HONG_KONG: "香港",
  DROPDOWN_MALAYSIA: "馬來西亞",
  DROPDOWN_SELECT_COUNTRY: "選擇國家",
  DROPDOWN_SINGAPORE: "新加坡",
  DUE_DATE_LABEL: "截止日期",
  DYNAMIC_CVV_CARD_NOT_ALLOWED_FOR_RECURRING_PAYMENT_ERROR:
    "此卡類型不適用於定期付款或具有未來收費日期的付款。 請使用另一張卡。",
  DYNAMIC_CVV_CARD_TYPE_NOT_SUPPORT_ERROR:
    "此卡類型不適用於您的付款。 請使用另一張卡。",
  EDIT: "編輯",
  EDIT_ALL_BUTTON: "編輯全部",
  EDIT_BUTTON: "編輯",
  EDIT_CUSTOMER_HERO: "編輯客戶",
  EDIT_EMPLOYEE_LABEL: "編輯僱員",
  EDIT_SCHEDULED_PAYMENTS_DESCRIPTION:
    "編輯 {render} 的細節之前，你需要先取消相關的付款。",
  EDIT_SCHEDULED_PAYMENTS_WARNING: "這個 {render} 與一筆定期付款有關。",
  EDIT_SUPPLIER_HERO: "編輯供應商",
  EMAIL_ACCOUNT_APPROVED_BODY: "似乎一切已經準備就緒！",
  EMAIL_ACCOUNT_APPROVED_BODY_01: "你好{{.FirstName}}",
  EMAIL_ACCOUNT_APPROVED_BODY_02: "似乎一切已經準備就緒！",
  EMAIL_ACCOUNT_APPROVED_BODY_03: "你的",
  EMAIL_ACCOUNT_APPROVED_BODY_04:
    "賬戶已驗證，你收到的款項現正等待存入。你可以輕鬆地在你的控制界面上查看你的下一次結算到期的狀態。",
  EMAIL_ACCOUNT_APPROVED_INSTRUCTIONS:
    "您的Fetch帳戶已通過驗證，您的付款現已生效。 您可以在儀表板上輕鬆跟踪下一次結算到期的狀態。",
  EMAIL_ACCOUNT_APPROVED_SUBJECT: "ipaymy Fetch帳戶已驗證",
  EMAIL_ADDRESS_PLACEHOLDER: "電郵地址",
  EMAIL_ALREADY_EXISTS: "該電子郵件已經註冊。",
  EMAIL_ALREADY_VERIFIED: "這個電子郵件已經被驗證了",
  EMAIL_ALREADY_VERIFIED_ERROR: "你的電子郵件已經通過驗證。你現在可以登錄。",
  EMAIL_BEST_SIGNATURE: "祝好，ipaymy法規團隊",
  EMAIL_BE_PAID: "收款",
  EMAIL_BUSINESS_RECEIPT_BODY:
    " 感謝你通過ipaymy支付{{.Currency}}{{.InvoiceAmount}}給{{.CustomerName}}! 請查看以下帳單詳情。",
  EMAIL_BUSINESS_RECEIPT_BODY_01:
    "你好，{{.CustomerName}}! 感謝你通過ipaymy支付{{.Currency}}{{.Amount}}給{{.CustomerName}}! 請查看以下帳單詳情。        ",
  EMAIL_BUSINESS_RECEIPT_BODY_02: "[Intentionally left blank]",
  EMAIL_BUSINESS_RECEIPT_BODY_03: "[Intentionally left blank]",
  EMAIL_BUSINESS_RECEIPT_DATE_PAID: "付款日期",
  EMAIL_BUSINESS_RECEIPT_DETAILS: "帳單細節",
  EMAIL_BUSINESS_RECEIPT_HERO: "從{{.CompanyName}}收到的帳單",
  EMAIL_BUSINESS_RECEIPT_LABEL: "帳單",
  EMAIL_BUSINESS_RECEIPT_ORDER_NUMBER: "訂單號",
  EMAIL_BUSINESS_RECEIPT_POWERED_BY: "支付方式{{.由ipaymy提供}}",
  EMAIL_BUSINESS_RECEIPT_SUPPORT_EMAIL: "如需支援，請電郵至",
  EMAIL_CANCEL_PAYMENT_COLLECTION_BODY:
    "你好{{.CustomerName}}！看起來這個付款金額為{{.PaymentAmount}}，帳單號碼為#{{.DefaultComments}}的付款請求已被{{.CompanyName}}取消。你無需採取進一步行動。",
  EMAIL_CANCEL_PAYMENT_COLLECTION_HERO: "付款請求已被取消",
  EMAIL_CANCEL_PAYMENT_COLLECTION_TRY:
    "今天就開始用你的信用卡支付租金，薪金，帳單及更多其他的付款種類。",
  EMAIL_CDD_ACCOUNT_APPROVED_BODY_02:
    "您的公司信息已經過驗證，您的付款現已生效。 您可以在儀表板上輕鬆跟踪下一次結算到期的狀態。",
  EMAIL_CDD_ACCOUNT_APPROVED_SUBJECT: "ipaymy帳戶已驗證",
  EMAIL_CDD_ACCOUNT_DECLINED_BODY_02:
    "您的公司驗證已被拒絕。 我們很遺憾地通知您，您的公司尚未獲准在ipaymy上付款或收款。",
  EMAIL_CDD_ACCOUNT_DECLINE_SUBJECT: "ipaymy無法驗證您的帳戶詳細信息",
  EMAIL_CDD_ACCOUNT_DOCUMENT_REQUEST_BODY_02:
    "我們正在與您取得聯繫，因為我們注意到您已經在ipaymy創建了一個帳戶。 我們很高興有您加入，我們只需要收集更多信息以驗證您的公司。 不幸的是，我們無法根據提供的信息驗證這一點。",
  EMAIL_CDD_ACCOUNT_DOCUMENT_REQUEST_BODY_04:
    "請您提供一份您的政府登記副本（日期在過去 3 個月內）。",
  EMAIL_CDD_ACCOUNT_DOCUMENT_REQUEST_MEANTIME_01:
    "同時，您可以開始向您的客戶開具發票。 但是，我們將需要此信息才能使您的帳戶完全啟動和運行。",
  EMAIL_CDD_ACCOUNT_DOCUMENT_REQUEST_SUBJECT: "您的ipaymy帳戶所需的其他信息",
  EMAIL_CDD_DECLINED_VERIFY_QUESTIONS:
    "如果您對此驗證有任何疑問，請通過以下方式聯繫我們 ",
  EMAIL_CDD_FETCH_DECLINED_BODY_02:
    "您的Fetch驗證已被拒絕。 我們很遺憾地通知您，您的公司尚未獲准通過Fetch收款。",
  EMAIL_CDD_FETCH_DECLINE_SUBJECT: "ipaymy無法驗證您的Fetch詳細信息",
  EMAIL_COLLECTED_REFUND_AMOUNT: "退款金額",
  EMAIL_COLLECTED_REFUND_CARD: "對你的",
  EMAIL_COLLECTED_REFUND_COMPANY: "已退款給你的",
  EMAIL_COLLECTED_REFUND_COMPANY_OF: "已發出退款",
  EMAIL_COLLECTED_REFUND_DETAILS: "退款詳情",
  EMAIL_COLLECTED_REFUND_FEE: "費用調整",
  EMAIL_COLLECTED_REFUND_RECEIPT: "給收款人",
  EMAIL_COLLECTED_REFUND_TEN: "退款將在10個工作日內反映在你的賬單上",
  EMAIL_COLLECTED_REFUND_TOTAL: "退款總額",
  EMAIL_COMMISSION: "佣金",
  EMAIL_COMPLETED_HERO: "太好了! 已收到款項",
  EMAIL_COMPLETED_INSTRUCTIONS:
    "你好{{.FirstName}}! 你的收款人已經收到了你的付款，詳情如下。",
  EMAIL_CONFIRM_DOES_NOT_MATCH: "輸入的電子郵件不匹配。",
  EMAIL_COPYRIGHT: "保留所有權利。",
  EMAIL_CREDIT_ENDED_BODY:
    "時間過得真快! 提提你，免手續費收款期限已經完結。之後標準手續費將適用於任何以信用卡或借記卡支付予{{.CompanyName}}的款項。",
  EMAIL_CREDIT_ENDED_BODY_01:
    "時間過得真快! 提提你，免手續費收款期限已經完結。之後標準手續費將適用於任何以信用卡或借記卡支付予{{.CompanyName}}的款項。",
  EMAIL_CREDIT_ENDED_BODY_02:
    "好消息是你可以繼續使用我們的服務而無需任何額外的訂閲費或隱藏費用。今天就登錄ipaymy建立下一張帳單吧。",
  EMAIL_CREDIT_ENDED_INSTRUCTIONS:
    "好消息是你可以繼續使用我們的服務而無需任何額外的訂閲費或隱藏費用。今天就登錄ipaymy建立下一張帳單吧。",
  EMAIL_CREDIT_ENDED_MONTH_BODY:
    "看來你很忙啊! 你已經達到了每月的免費收款額上限：{{.CreditCurrency}}{{.CreditAmount}}。之後，我們將收取由2.8%起的標準手續費。",
  EMAIL_CREDIT_ENDED_MONTH_BODY_01:
    "看來你很忙啊! 你已經達到了每月的免費收款額上限",
  EMAIL_CREDIT_ENDED_MONTH_BODY_02: "之後，我們將收取由2.8%起的標準手續費。",
  EMAIL_CREDIT_ENDED_MONTH_BODY_03: "好消息是，從{{.NextDate}}開始",
  EMAIL_CREDIT_ENDED_MONTH_BODY_04:
    "你可以免費收取{{.CreditCurrency}}{{.CreditAmount}}等值的款項！",
  EMAIL_CREDIT_ENDED_MONTH_BODY_05: "[Intentionally left blank]",
  EMAIL_CREDIT_ENDED_MONTH_DASHBOARD: "在ipaymy控制界面中查看",
  EMAIL_CREDIT_ENDED_MONTH_INSTRUCTIONS:
    "好消息是，從{{.NextDate}}開始你可以免費收取{{.CreditCurrency}}{{.CreditAmount}}等值的款項！",
  EMAIL_CREDIT_ENDED_MONTH_SUBJECT: "太好啦！你最大限度地節省了開支!",
  EMAIL_CREDIT_ENDED_SUBJECT: "讓美好的事情延續",
  EMAIL_CREDIT_ENTERED_BODY: "太好啦，你剛剛領取了免費收款優惠！",
  EMAIL_CREDIT_ENTERED_BODY_01: "太好啦，你剛剛領取了免費收款優惠！",
  EMAIL_CREDIT_ENTERED_BODY_02:
    "ipaymy將免除首3個月每月{{.CreditCurrency}}{{.CreditAmount}}以信用卡或借記卡付款予你公司的款項手續費。總共價值{{.CreditCurrency}}{{.TotalCreditAmount}}的0%手續費收款額！",
  EMAIL_CREDIT_ENTERED_BODY_03: "[Intentionally left blank]",
  EMAIL_CREDIT_ENTERED_BODY_04: "[Intentionally left blank]",
  EMAIL_CREDIT_ENTERED_BODY_05: "[Intentionally left blank]",
  EMAIL_CREDIT_ENTERED_BODY_06: "此處有些提示讓你開始使用：",
  EMAIL_CREDIT_ENTERED_HELP: "需要幫助開始？今天安排入門指導！",
  EMAIL_CREDIT_ENTERED_INSTRUCTIONS:
    "ipaymy將免除首3個月每月{{.CreditCurrency}}{{.CreditAmount}}，以信用卡或借記卡付款予你公司的款項手續費。那是總共價值為{{.CreditCurrency}}{{.TotalCreditAmount}}的0%手續費的收款額！",
  EMAIL_CREDIT_ENTERED_NEW_BODY:
    "你知道嗎？你已經鎖定了另一個月的免手續費首款額{{.CreditCurrency}}{{.CreditAmount}}，以信用卡或借記卡付款予{{.CompanyName}}的款項。",
  EMAIL_CREDIT_ENTERED_NEW_BODY_01:
    "你知道嗎？你已經鎖定了另一個月的免手續費首款額",
  EMAIL_CREDIT_ENTERED_NEW_BODY_02: "以信用卡或借記卡付款予{{.CompanyName}}。",
  EMAIL_CREDIT_ENTERED_NEW_BODY_03:
    "請務必在{{.ThisMonthEndDate}}之前充分利用此特別優惠。",
  EMAIL_CREDIT_ENTERED_NEW_BODY_04: "今天就登錄ipaymy建立下一張帳單吧。",
  EMAIL_CREDIT_ENTERED_NEW_CREATE_BUTTON: "建立帳單",
  EMAIL_CREDIT_ENTERED_NEW_INSTRUCTIONS:
    "請務必在{{.ThisMonthEndDate}}之前充分利用此特別優惠。今天就登錄ipaymy建立下一張帳單吧。",
  EMAIL_CREDIT_ENTERED_NEW_SUBJECT: "你有更多的免費收款額！",
  EMAIL_CREDIT_ENTERED_SCHEDULE_BUTTON: "安排入門指導",
  EMAIL_CREDIT_ENTERED_SUBJECT: "你剛剛解鎖了一個精彩的推廣優惠",
  EMAIL_CREDIT_ENTERED_TIPS: "此處有些提示讓你開始使用",
  EMAIL_CREDIT_ENTERED_TIP_1:
    "每個月，你的企業可以完全免手續費收取{{.CreditCurrency}}{{.CreditAmount}}的信用卡或借記卡付款。",
  EMAIL_CREDIT_ENTERED_TIP_1_01:
    "每個月，你的企業可以完全免手續費收取{{.CreditCurrency}}{{.CreditAmount}}的信用卡付款。        ",
  EMAIL_CREDIT_ENTERED_TIP_1_02: "[Intentionally left blank]",
  EMAIL_CREDIT_ENTERED_TIP_2:
    "當你的第一個客戶用卡付款予你時，該計劃就會生效。",
  EMAIL_CREDIT_ENTERED_TIP_3:
    "當你達到每月的免費限額時，你將會收到電郵通知，之後會按ipaymy的標準手續費計算。",
  EMAIL_CUSTOMER_PAID_BODY:
    "好消息!{{.CustomerName}}已經付款給你：{{.Currency}}{{.Amount}}。款項將存入你的{{.BankName}}戶口，戶口最後四位數字為{{.BankAccountNumberLast4}}，存款日期為：{{.PayoutDate}}。",
  EMAIL_CUSTOMER_PAID_BODY_01: "好消息!",
  EMAIL_CUSTOMER_PAID_BODY_02: "已經付款給你",
  EMAIL_CUSTOMER_PAID_BODY_03: "款項將存入你的",
  EMAIL_CUSTOMER_PAID_BODY_04: "戶口最後四位數字為",
  EMAIL_CUSTOMER_PAID_BODY_05: "在",
  EMAIL_CUSTOMER_PAID_DASHBOARD: "在ipaymy控制界面中查看",
  EMAIL_CUSTOMER_PAID_DETAILS: "付款詳情",
  EMAIL_CUSTOMER_PAID_HERO: "{{.CustomerName}}已經付款給你!",
  EMAIL_CUSTOMER_PAID_SUBJECT: "%s已支付",
  EMAIL_DEPOSITED_BODY:
    "你的款項已經存入你的{{.BankName}}戶口，戶口最後四位數字為{{.BankAccountNumberLast4}}。",
  EMAIL_DEPOSITED_BODY_01: "你好",
  EMAIL_DEPOSITED_BODY_02: "你的款項已經存入你的",
  EMAIL_DEPOSITED_BODY_03: "戶口最後四位數字為",
  EMAIL_DEPOSITED_HERO: "恭喜你！你的款項已經存入。",
  EMAIL_DEPOSITED_TOTAL: "存款總額",
  EMAIL_DOCUMENT_REQUEST_ACCOUNT: "填寫完整的銀行賬號以作驗證",
  EMAIL_DOCUMENT_REQUEST_BODY:
    "我們正在與您取得聯繫，因為我們注意到您已經使用ipaymy創建了一個Fetch帳戶。 我們很高興您能加入，只需收集更多信息即可驗證您的帳戶詳細信息。 不幸的是，我們無法根據提供的信息驗證這一點。",
  EMAIL_DOCUMENT_REQUEST_BODY_01: "你好{{.FirstName}}",
  EMAIL_DOCUMENT_REQUEST_BODY_02:
    "我們聯繫你是因為留意到到你已經在ipaymy建立了一個",
  EMAIL_DOCUMENT_REQUEST_BODY_03:
    "賬戶。感謝你的加入，只需要多一點的資料以驗證你的賬戶細節。抱歉，目前我們無法根據你已提供的資料來完成賬戶驗證。",
  EMAIL_DOCUMENT_REQUEST_BODY_04:
    "請您提供一份您的銀行對賬單副本（過去3個月內的日期）。 此銀行帳戶必須屬於您為其創建Fetch帳戶的公司。 銀行對賬單必須包括：",
  EMAIL_DOCUMENT_REQUEST_COMPANY: "貴公司的名稱",
  EMAIL_DOCUMENT_REQUEST_MEANTIME:
    "同時，您可以開始向您的客戶開具發票。 但是，我們需要這些信息才能讓您的Fetch帳戶完全啟動並運行。",
  EMAIL_DOCUMENT_REQUEST_MEANTIME_01:
    "同時，你可以開始向你的客戶發送帳單。而我們仍需要這些資訊，以啓用你的",
  EMAIL_DOCUMENT_REQUEST_MEANTIME_02: "賬戶以完成設定待用",
  EMAIL_DOCUMENT_REQUEST_RESPOND: "你可以通過直接回覆此電郵來提供這些資訊。",
  EMAIL_DOCUMENT_REQUEST_SIGNATURE_01: "祝好",
  EMAIL_DOCUMENT_REQUEST_SIGNATURE_02: "ipaymy法規團隊",
  EMAIL_DOCUMENT_REQUEST_STATEMENT:
    "請提供最近3個月內的銀行賬戶月結單。 你的銀行月結單必須包括：",
  EMAIL_DOCUMENT_REQUEST_SUBJECT: "您的ipaymy Fetch帳戶所需的其他信息",
  EMAIL_DOCUMENT_REQUEST_THANKS:
    "感謝你的理解，如果你有任何問題，請隨時聯繫我們。",
  EMAIL_DOES_NOT_EXIST: "電子郵件不存在。",
  EMAIL_ERROR: "請輸入有效的電郵地址",
  EMAIL_FAQ: "常見問題",
  EMAIL_FETCH: "Fetch",
  EMAIL_GREETING: "你好{{.FirstName}}",
  EMAIL_GREETING_COMPANY: "你好{{.CompanyName}}",
  EMAIL_HERE_LINK: "此處。",
  EMAIL_INSTRUCTIONS: "請提供電郵地址以便收取所有有關存入你賬戶的款項資訊",
  EMAIL_LABEL: "電子郵件地址",
  EMAIL_NOT_EXIST: "我們的數據庫中不存在此電子郵件地址。",
  EMAIL_OVERDUE_BODY:
    "你的付款要求來自{{.CompanyName}}，賬單 {{.InvoiceNumber}}，已過期2天。",
  EMAIL_OVERDUE_BODY_01: "你好 {{.CustomerName}}！收到付款要求來自",
  EMAIL_OVERDUE_BODY_02: "賬單 {{.InvoiceNumber}}",
  EMAIL_OVERDUE_BODY_03: "已過期2天",
  EMAIL_OVERDUE_HERO: "你的付款已過期。",
  EMAIL_OVERDUE_SUBJECT: "你對%s的付款過期未付",
  EMAIL_PLACEHOLDER: "電郵地址",
  EMAIL_RECEIPT_AMOUNT: "金額",
  EMAIL_RECEIPT_BANK_FEE: "銀行費用",
  EMAIL_RECEIPT_CARD: "信用卡",
  EMAIL_RECEIPT_COMPANY: "公司",
  EMAIL_RECEIPT_COUNTRY: "收款人國家",
  EMAIL_RECEIPT_DESCRIPTION: "描述",
  EMAIL_RECEIPT_DETAILS: "詳細內容",
  EMAIL_RECEIPT_DISCOUNT: "折扣",
  EMAIL_RECEIPT_FEE: "手續費",
  EMAIL_RECEIPT_FEE_TOTAL: "費用總計",
  EMAIL_RECEIPT_HERO: "付款成功",
  EMAIL_RECEIPT_INSTRUCTIONS:
    "謝謝你使用ipaymy! 你的收款人將不遲於以下日子收到款項：",
  EMAIL_RECEIPT_ITEM: "項目",
  EMAIL_RECEIPT_NAME: "名稱",
  EMAIL_RECEIPT_NUMBER: "帳單號碼",
  EMAIL_RECEIPT_PRICE: "價格",
  EMAIL_RECEIPT_TOTAL: "總計",
  EMAIL_REFERRER_BODY:
    "太好啦，{{.RefereeName}} 已經使用ipaymy支付了他們的第一筆付款！",
  EMAIL_REFERRER_BODY_DETAILS: "一直推薦一直賺！",
  EMAIL_REFERRER_BODY_INSTRUCTIONS:
    "使用促銷代碼{{.ReferrerCouponName}}，獲得{{.DiscountAmount}}的ipaymy交易費優惠，請在此日期前付款以獲取此優惠：{{.ReferrerCouponExpiryDate}}",
  EMAIL_REFERRER_REDEEM_BUTTON: "兌換優惠",
  EMAIL_REFERRER_TC_1: "推廣代碼僅對已加入ipaymy推薦計劃的用戶有效",
  EMAIL_REFERRER_TC_2: "付款必須使用本地發行的Visa卡或MasterCard。",
  EMAIL_REFERRER_TC_3: "推廣代碼只對一次性付款有效",
  EMAIL_REFERRER_TC_4: "扣款日期必須在或早於{{.ReferrerCouponExpiryDate}}",
  EMAIL_REFUND_AMOUNT: "退款金額",
  EMAIL_REFUND_BODY:
    "你好{{.FirstName}}，你的付款包含以下詳細信息，已成功退款。",
  EMAIL_REFUND_BODY_WITH_CUSTOMER:
    "你好{{.FirstName}}，你的付款來自{{.CustomerName}}包含以下詳細信息，已成功退款。        ",
  EMAIL_REFUND_FEE: "退款",
  EMAIL_REFUND_HERO: "付款已退還",
  EMAIL_REFUND_INSTRUCTIONS:
    "這封郵件是為了確認退款已由ipaymy發出。可能需要5到10個工作日出現在你的月結單上。",
  EMAIL_REFUND_INSTRUCTIONS_01:
    '這封郵件是為了確認退款已由ipaymy.{{end}}{{if eq .CustomerName ""}}發給你的客戶{{if ne .CustomerName ""}}。',
  EMAIL_REFUND_INSTRUCTIONS_02: "可能需要5到10個工作日出現在你的月結單上",
  EMAIL_REFUND_INSTRUCTIONS_WITH_CUSTOMER:
    "這封郵件是為了確認退款已發給你的客戶。可能需要5到10個工作日出現在他們的月結單上。",
  EMAIL_REFUND_INSTRUCTIONS_WITH_CUSTOMER_01:
    "這封郵件是為了確認退款已發給你的客戶。",
  EMAIL_REFUND_INSTRUCTIONS_WITH_CUSTOMER_02:
    "可能需要5到10個工作日出現在他們的月結單上",
  EMAIL_REFUND_NEW_TOTAL: "新總數",
  EMAIL_REFUND_SUBJECT: "ipaymy %s 已退款",
  EMAIL_REMINDER_BODY:
    "你好 {{.CustomerName}}！收到付款要求來自{{.CompanyName}}，賬單 {{.InvoiceNumber}}，付款截止日期為：{{.DueDate}}。",
  EMAIL_REMINDER_DISREGARD: "如果你已經支付你的賬單，請忽略此訊息。",
  EMAIL_REMINDER_HERO: "你的付款即將到期。",
  EMAIL_REMINDER_INSTRUCTIONS_01: "你好 {{.CustomerName}}！收到付款要求來自",
  EMAIL_REMINDER_INSTRUCTIONS_02:
    "賬單 {{.InvoiceNumber}}將於{{.DueDate}}到期。",
  EMAIL_REMINDER_SUBJECT: "您今天支付給%s的款項",
  EMAIL_REQUEST_BODY:
    "你好{{.CustomerName}}, {{.CompanyName}}發出付款要求{{.InvoiceNumber}}。總額為{{.Currency}}{{.Amount}}，付款截止日期為：{{.DueDate}}。",
  EMAIL_REQUEST_DOWNLOAD_01: "你可以在此查看或下載",
  EMAIL_REQUEST_DOWNLOAD_02: "你的帳單",
  EMAIL_REQUEST_EMAIL_CHAT_01: "支付方式由ipaymy提供。如需支援，請電郵至",
  EMAIL_REQUEST_EMAIL_CHAT_02: "或經即時對話與我們聯絡",
  EMAIL_REQUEST_HERO: "你收到了一個付款要求。",
  EMAIL_REQUEST_INSTRUCTIONS_01: "你好{{.CustomerName}}",
  EMAIL_REQUEST_INSTRUCTIONS_02: "要求支付{{.InvoiceNumber}}。總金額為",
  EMAIL_REQUEST_INSTRUCTIONS_03: "到期時間為",
  EMAIL_REQUEST_INVOICE_NUMBER: "帳單號碼",
  EMAIL_REQUEST_NOTE: "須知來自",
  EMAIL_REQUEST_PAY_BUTTON: "立即支付",
  EMAIL_REQUEST_SUBJECT: "%s發出付款要求%s",
  EMAIL_REQUEST_TOTAL: "總計",
  EMAIL_SCHEDULED_FAILED: "哎呀... 付款失敗",
  EMAIL_SCHEDULED_FAILED_INSTRUCTIONS:
    "你好{{.FirstName}}，你的付款無法處理，原因如下：{{.FailureReason}}",
  EMAIL_SCHEDULED_FAILED_PAYMENT_BUTTON: "安排另一筆付款",
  EMAIL_SCHEDULED_FAILED_PAYOUT: "付款日期",
  EMAIL_SECTION: "電郵地址",
  EMAIL_SENT: "已將郵件發送到{email}",
  EMAIL_SUPPLIER_NOTIFICATION_BODY_01:
    "好消息！{{.RecipientName}} 已經收到了來自 {{.UserFullName}}通過ipaymy支付的金額為{{.PaymentCurrency}}{{.PaymentAmount}}的付款。這筆付款正在處理中，並將於{{.PayoutDate}}直接存入你的{{.PaymentBank}} 帳號{{.PaymentAccountNumber}}。",
  EMAIL_SUPPLIER_NOTIFICATION_BODY_02:
    '為了確保你不錯過這筆付款，你可以在你的銀行賬戶月結單上查找來自"IPAYMY TECHNOLOGIES PTE LTD" 的對帳單說明"{{.BeneficiaryStatement}}”。',
  EMAIL_SUPPLIER_NOTIFICATION_BODY_03:
    "ipaymy是一個使中小型企業可以使用他們已有的信用卡支付租金，薪金，帳單甚至稅款的在線平台。我們安全的平台可使你輕鬆向目前不接受信用卡付款的供應商付款，及輕鬆管理付款。",
  EMAIL_SUPPLIER_NOTIFICATION_BODY_04:
    "如果你有興趣了解有關使用ipaymy付款或收款的更多信息，你可以電郵hello@ipaymy.com，我們的團隊隨時可以提供幫助。",
  EMAIL_SUPPORT: "支援",
  EMAIL_TCS: "條款及細則",
  EMAIL_TEMPLATE_CANCEL_PAYMENT_REQUEST_SUBJECT: "付款要求已取消",
  EMAIL_TEMPLATE_MARK_BULK_COLLECTED_PAYMENT_AS_COMPLETE_SUBJECT:
    "你的付款已通過ipaymy存入",
  EMAIL_TEMPLATE_MARK_PAYMENT_AS_COMPLETE_SUBJECT: "ipaymy %d-%d 付款完成",
  EMAIL_TEMPLATE_PARTNER_MARK_PAYMENT_AS_COMPLETE_SUBJECT:
    "你的付款已通過ipaymy存入",
  EMAIL_TEMPLATE_PARTNER_PAYMENT_COLLECTION_SUCCESS_CUSTOMER_SUBJECT:
    "%s 帳單 %s",
  EMAIL_TEMPLATE_RECEIPT_SUBJECT: "ipaymy帳單%s",
  EMAIL_TEMPLATE_REFERRER_SUBJECT: "%s,你已經獲得%s",
  EMAIL_TEMPLATE_SCHEDULE_FAILED_SUBJECT: "您的預定付款失敗",
  EMAIL_TEMPLATE_SUPPLIER_NOTIFICATION_SUBJECT: "%s已經通過ipaymy收到賬單付款",
  EMAIL_TEMPLATE_WALLEX_BUSINESS_KYC_APPROVED_SUBJECT:
    "ipaymy已驗證你公司的詳細信息",
  EMAIL_TEMPLATE_WALLEX_BUSINESS_KYC_REJECTED_SUBJECT:
    "ipaymy無法驗證你公司的詳細信息",
  EMAIL_TEMPLATE_WALLEX_BUSINESS_KYC_UNDER_REVIEW_SUBJECT:
    "ipaymy需驗證你公司的詳細信息",
  EMAIL_TEMPLATE_WALLEX_KYC_APPROVED_SUBJECT: "ipaymy已驗證你的身份",
  EMAIL_TEMPLATE_WALLEX_KYC_REJECTED_SUBJECT: "ipaymy無法驗證你的身份",
  EMAIL_TEMPLATE_WALLEX_KYC_UNDER_REVIEW_SUBJECT: "ipaymy需驗證你的身份",
  EMAIL_TERMS_OF_USE: "使用條款",
  EMAIL_TRANSACTION_ACCOUNT: "收款人賬號",
  EMAIL_TRANSACTION_ADDRESS: "地址：新加坡羅賓遜路144號，#06-02號, 068908",
  EMAIL_TRANSACTION_AMOUNT: "轉賬金額",
  EMAIL_TRANSACTION_BANK: "收款人銀行",
  EMAIL_TRANSACTION_DATE: "付款日期",
  EMAIL_TRANSACTION_HERO: "交易詳情",
  EMAIL_TRANSACTION_IPAYMY: "ipaymy科技私人有限公司",
  EMAIL_TRANSACTION_PAGE: "{{.Total}}的{{.Page}}頁面​​",
  EMAIL_TRANSACTION_PURPOSE: "目的",
  EMAIL_TRANSACTION_THANKS: "謝謝你使用ipaymy!",
  EMAIL_TRANSACTION_UEN: "UEN: 201535772K",
  EMAIL_TRANSACTION_WEBSITE: "網站",
  EMAIL_VERIFICATION_REQUIRED: "請先確認你的電子郵件。",
  EMAIL_VERIFIED: "已成功驗證電郵地址！你現在可以登入。",
  EMAIL_VIEW_INVOICE_INSTRUCTIONS: "如你想要查看或下載帳單，請點擊",
  EMPLOYEES_HERO: "僱員",
  EMPLOYEES_INSTRUCTIONS:
    "這是你的登記僱員。有新僱員嗎？{br}點擊 「添加新僱員 」來添加。",
  EMPLOYEES_MENU_ITEM: "僱員",
  EMPLOYEE_ACCOUNT_NUMBER_HEADER: "帳號",
  EMPLOYEE_ACCOUNT_PLACEHOLDER: "僱員的銀行賬戶號碼",
  EMPLOYEE_AMOUNT_HEADER: "金額",
  EMPLOYEE_BANK_HEADER: "銀行",
  EMPLOYEE_COUNTRY_HEADER: "國家",
  EMPLOYEE_DELETE_HERO: "你想刪除該僱員嗎？",
  EMPLOYEE_DELETE_INSTRUCTIONS: "該僱員將從你的僱員列表中刪除",
  EMPLOYEE_FULL_NAME_LABEL: "僱員全名",
  EMPLOYEE_FULL_NAME_PLACEHOLDER: "僱員全名",
  EMPLOYEE_HEADER: "僱員",
  EMPLOYEE_HKID_HEADER: "香港身份證號碼",
  EMPLOYEE_ID_HEADER: "身分證件",
  EMPLOYEE_LABEL: "僱員",
  EMPLOYEE_NOTE_HEADER: "僱員須知",
  EMPLOYEE_NRIC_HEADER: "登記號碼",
  EMPLOYEE_SCHEDULED_PAYMENT_HERO: "該僱員與一筆定期付款有關。",
  EMPLOYEE_SCHEDULED_PAYMENT_INSTRUCTIONS:
    "在編輯僱員資料之前，你需要先取消相關的付款。",
  ENTER_EMAIL_PLACEHOLDER: "輸入電子郵件",
  ENTER_INVOICE_AMOUNT_HEADER: "輸入帳單金額並上傳帳單",
  ENTER_INVOICE_DETAILS_HERO: "輸入帳單細節",
  ENTER_PASSWORD_ERROR:
    "請選擇至少由8個字符組成的高强度密碼，包括字母、數字和符號。",
  ENTER_PASSWORD_PLACEHOLDER: "請輸入密碼",
  ENTER_PIN:
    "請輸入已發送到你以\u003cb\u003e{render}.\u003c/b\u003e結尾手機號碼的驗證PIN碼。",
  ERROR_ADDING_CARD:
    "我們在處理您的卡時遇到了一些問題。 請再試一次，或通過support@ipaymy.com聯繫我們。",
  ERROR_ADYEN_CARD_UNACCEPTED: "我們目前只接受MasterCard和 Visa卡。",
  ERROR_BRAINTREE_CARD_UNACCEPTED: "我們目前只接受Visa和Mastercard。",
  ERROR_BT_2000:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_2001:
    "由於信用額度不足，我們無法完成你的付款。你所使用的信用卡可能已達最高信用額度。請調整付款金額或使用其他信用卡。",
  ERROR_BT_2002: "此交易因超過提款限額而失敗。請嘗試另一張卡。",
  ERROR_BT_2003: "此交易因超過提款限額而失敗。請嘗試另一張卡。",
  ERROR_BT_2004: "信用卡的有效期不正確。檢查到期日或使用其他卡。",
  ERROR_BT_2005: "卡號不正確。請檢查卡號或使用其他卡。",
  ERROR_BT_2006: "卡的有效期無效。 檢查到期日期或使用其他卡。",
  ERROR_BT_2007:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2008:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2009:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2010: "銀行卡的CVC/CVV不正確。請檢查卡的安全碼或使用其他卡。",
  ERROR_BT_2011:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2012:
    "該交易無法被授權。請嘗試使用其他卡或聯繫你的發卡機構了解更多資訊。",
  ERROR_BT_2013:
    "該交易無法被授權。請嘗試使用其他卡或聯繫你的發卡機構了解更多資訊。",
  ERROR_BT_2014:
    "該交易無法被授權。請嘗試使用其他卡或聯繫你的發卡機構了解更多資訊。",
  ERROR_BT_2015:
    "該交易無法被授權。請嘗試使用其他卡或聯繫你的發卡機構了解更多資訊。",
  ERROR_BT_2016:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2017:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2018:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2019:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2020:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2021:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2022:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2023:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2024:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2025:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2026:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2027:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2028:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2029:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2030:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2031:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2032:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2033:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2034:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2035:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2036:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2037:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2038:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2039:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2040:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2041:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_2042:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2043:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_2044:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_2045:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2046:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_2047:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_2048:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2049:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2050:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_2051:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2052:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2053:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_2054:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2055:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2056:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2057:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_2058:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2059:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2060:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2061:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2062:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2063:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2064:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2065:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2066:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2067:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2068:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2069:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2070:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2071:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2072:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2073:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2074:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2075:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2076:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2077:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2078:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2079:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2080:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2081:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2082:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2083:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2084:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2085:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2086:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2087:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2088:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2089:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2090:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2091:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2092:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2093:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2094:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2095:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2096:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2097:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2098:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2099:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_2100:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2101:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2102:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2103:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  ERROR_BT_2104:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2105:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2106:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2107:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2108:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2109:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2110:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2111:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2112:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2113:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2114:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2115:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2116:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2117:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2118:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2119:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2120:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2121:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2122:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2123:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2124:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2125:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2126:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2127:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2128:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2129:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2130:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2131:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2132:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2133:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2134:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2135:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2136:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2137:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2138:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2139:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2140:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2141:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2142:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2143:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2144:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2145:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2146:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2147:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2148:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2149:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2150:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2151:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2152:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2153:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2154:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2155:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2156:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2157:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2158:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2159:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2160:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2161:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2162:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2163:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2164:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2165:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2166:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2167:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2168:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2169:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2170:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2171:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2172:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2173:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2174:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2175:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2176:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2177:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2178:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2179:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2180:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2181:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2182:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2183:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2184:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2185:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2186:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2187:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2188:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2189:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2190:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2191:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2192:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2193:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2194:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2195:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2196:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2197:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2198:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2199:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2200:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2201:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2202:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2203:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2204:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2205:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2206:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2207:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2208:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2209:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2210:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2211:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2212:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2213:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2214:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2215:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2216:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2217:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2218:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2219:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2220:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2221:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2222:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2223:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2224:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2225:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2226:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2227:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2228:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2229:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2230:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2231:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2232:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2233:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2234:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2235:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2236:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2237:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2238:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2239:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2240:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2241:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2242:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2243:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2244:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2245:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2246:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2247:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2248:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2249:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2250:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2251:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2252:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2253:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2254:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2255:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2256:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2257:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2258:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2259:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2260:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2261:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2262:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2263:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2264:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2265:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2266:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2267:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2268:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2269:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2270:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2271:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2272:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2273:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2274:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2275:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2276:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2277:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2278:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2279:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2280:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2281:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2282:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2283:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2284:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2285:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2286:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2287:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2288:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2289:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2290:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2291:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2292:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2293:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2294:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2295:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2296:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2297:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2298:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2299:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2300:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2301:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2302:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2303:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2304:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2305:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2306:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2307:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2308:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2309:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2310:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2311:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2312:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2313:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2314:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2315:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2316:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2317:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2318:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2319:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2320:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2321:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2322:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2323:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2324:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2325:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2326:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2327:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2328:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2329:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2330:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2331:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2332:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2333:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2334:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2335:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2336:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2337:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2338:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2339:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2340:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2341:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2342:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2343:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2344:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2345:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2346:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2347:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2348:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2349:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2350:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2351:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2352:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2353:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2354:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2355:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2356:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2357:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2358:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2359:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2360:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2361:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2362:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2363:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2364:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2365:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2366:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2367:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2368:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2369:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2370:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2371:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2372:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2373:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2374:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2375:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2376:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2377:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2378:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2379:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2380:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2381:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2382:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2383:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2384:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2385:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2386:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2387:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2388:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2389:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2390:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2391:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2392:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2393:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2394:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2395:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2396:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2397:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2398:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2399:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2400:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2401:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2402:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2403:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2404:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2405:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2406:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2407:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2408:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2409:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2410:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2411:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2412:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2413:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2414:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2415:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2416:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2417:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2418:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2419:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2420:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2421:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2422:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2423:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2424:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2425:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2426:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2427:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2428:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2429:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2430:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2431:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2432:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2433:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2434:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2435:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2436:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2437:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2438:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2439:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2440:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2441:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2442:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2443:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2444:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2445:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2446:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2447:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2448:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2449:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2450:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2451:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2452:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2453:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2454:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2455:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2456:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2457:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2458:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2459:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2460:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2461:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2462:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2463:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2464:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2465:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2466:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2467:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2468:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2469:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2470:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2471:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2472:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2473:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2474:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2475:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2476:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2477:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2478:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2479:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2480:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2481:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2482:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2483:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2484:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2485:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2486:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2487:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2488:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2489:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2490:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2491:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2492:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2493:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2494:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2495:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2496:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2497:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2498:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2499:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2500:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2501:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2502:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2503:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2504:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2505:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2506:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2507:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2508:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2509:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2510:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2511:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2512:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2513:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2514:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2515:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2516:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2517:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2518:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2519:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2520:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2521:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2522:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2523:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2524:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2525:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2526:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2527:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2528:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2529:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2530:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2531:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2532:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2533:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2534:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2535:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2536:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2537:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2538:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2539:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2540:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2541:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2542:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2543:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2544:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2545:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2546:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2547:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2548:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2549:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2550:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2551:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2552:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2553:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2554:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2555:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2556:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2557:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2558:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2559:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2560:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2561:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2562:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2563:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2564:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2565:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2566:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2567:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2568:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2569:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2570:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2571:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2572:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2573:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2574:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2575:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2576:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2577:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2578:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2579:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2580:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2581:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2582:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2583:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2584:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2585:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2586:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2587:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2588:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2589:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2590:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2591:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2592:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2593:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2594:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2595:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2596:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2597:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2598:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2599:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2600:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2601:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2602:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2603:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2604:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2605:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2606:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2607:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2608:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2609:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2610:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2611:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2612:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2613:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2614:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2615:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2616:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2617:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2618:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2619:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2620:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2621:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2622:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2623:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2624:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2625:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2626:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2627:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2628:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2629:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2630:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2631:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2632:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2633:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2634:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2635:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2636:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2637:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2638:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2639:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2640:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2641:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2642:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2643:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2644:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2645:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2646:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2647:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2648:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2649:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2650:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2651:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2652:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2653:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2654:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2655:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2656:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2657:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2658:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2659:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2660:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2661:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2662:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2663:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2664:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2665:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2666:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2667:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2668:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2669:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2670:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2671:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2672:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2673:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2674:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2675:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2676:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2677:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2678:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2679:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2680:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2681:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2682:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2683:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2684:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2685:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2686:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2687:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2688:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2689:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2690:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2691:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2692:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2693:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2694:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2695:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2696:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2697:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2698:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2699:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2700:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2701:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2702:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2703:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2704:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2705:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2706:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2707:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2708:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2709:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2710:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2711:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2712:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2713:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2714:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2715:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2716:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2717:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2718:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2719:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2720:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2721:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2722:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2723:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2724:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2725:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2726:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2727:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2728:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2729:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2730:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2731:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2732:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2733:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2734:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2735:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2736:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2737:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2738:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2739:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2740:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2741:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2742:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2743:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2744:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2745:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2746:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2747:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2748:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2749:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2750:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2751:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2752:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2753:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2754:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2755:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2756:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2757:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2758:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2759:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2760:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2761:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2762:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2763:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2764:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2765:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2766:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2767:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2768:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2769:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2770:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2771:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2772:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2773:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2774:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2775:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2776:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2777:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2778:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2779:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2780:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2781:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2782:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2783:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2784:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2785:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2786:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2787:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2788:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2789:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2790:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2791:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2792:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2793:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2794:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2795:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2796:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2797:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2798:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2799:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2800:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2801:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2802:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2803:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2804:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2805:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2806:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2807:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2808:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2809:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2810:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2811:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2812:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2813:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2814:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2815:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2816:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2817:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2818:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2819:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2820:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2821:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2822:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2823:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2824:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2825:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2826:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2827:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2828:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2829:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2830:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2831:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2832:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2833:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2834:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2835:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2836:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2837:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2838:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2839:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2840:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2841:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2842:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2843:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2844:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2845:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2846:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2847:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2848:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2849:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2850:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2851:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2852:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2853:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2854:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2855:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2856:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2857:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2858:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2859:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2860:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2861:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2862:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2863:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2864:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2865:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2866:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2867:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2868:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2869:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2870:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2871:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2872:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2873:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2874:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2875:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2876:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2877:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2878:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2879:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2880:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2881:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2882:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2883:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2884:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2885:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2886:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2887:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2888:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2889:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2890:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2891:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2892:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2893:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2894:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2895:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2896:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2897:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2898:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2899:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2900:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2901:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2902:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2903:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2904:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2905:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2906:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2907:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2908:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2909:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2910:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2911:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2912:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2913:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2914:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2915:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2916:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2917:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2918:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2919:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2920:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2921:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2922:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2923:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2924:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2925:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2926:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2927:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2928:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2929:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2930:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2931:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2932:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2933:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2934:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2935:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2936:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2937:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2938:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2939:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2940:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2941:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2942:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2943:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2944:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2945:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2946:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2947:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2948:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2949:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2950:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2951:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2952:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2953:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2954:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2955:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2956:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2957:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2958:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2959:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2960:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2961:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2962:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2963:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2964:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2965:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2966:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2967:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2968:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2969:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2970:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2971:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2972:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2973:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2974:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2975:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2976:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2977:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2978:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2979:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2980:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2981:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2982:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2983:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2984:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2985:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2986:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2987:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2988:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2989:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2990:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2991:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2992:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2993:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2994:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2995:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2996:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2997:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2998:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_DECLINED_2999:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_BT_PROCESSOR_NETWORK_UNAVAILABLE_3000:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  ERROR_CARD_BRAND_UNACCEPTED: "我們目前不接受此卡品牌。",
  ERROR_CARD_UNACCEPTED: "我們目前只接受{accepted_card_types}",
  ERROR_CKO_CARD_UNACCEPTED: "我們目前只接受Visa和Mastercard。",
  ERROR_COLLECTED_ACCOUNT_EXIST: "該公司已收款",
  ERROR_CONFIRM_AMOUNT_REQUIRED: "請確認金額",
  ERROR_CONNECTION_TIMEOUT: "網絡連接中斷。檢查你的網絡連接並重試。",
  ERROR_COUNTRY_REQUIRED: "請選擇你的國家",
  ERROR_COUPON_AU_VISA:
    "哎呀！ 促銷代碼無效。 請通過右下角的實時聊天與我們聯繫。",
  ERROR_COUPON_BOC_CARD_ONLY: "促銷代碼對您的卡無效，請改用其他卡。",
  ERROR_COUPON_CHARGED_ACCOUNT: "哎呀! 此促銷不可用於這筆付款。",
  ERROR_COUPON_CHARGE_DATE_EXPIRED: "哎呀! 此促銷不可用於此扣賬日期。",
  ERROR_COUPON_CHARGE_DATE_SOONER: "哎呀! 此促銷不可用於此扣賬日期。",
  ERROR_COUPON_EXCEED_USAGE: "你的帳戶已達到兌換此促銷代碼的上限。",
  ERROR_COUPON_EXPIRED: "該優惠券已過期或已被完全兌換。",
  ERROR_COUPON_HK_VISA:
    "哎呀！ 促銷代碼無效。 請通過右下角的實時聊天與我們聯繫。",
  ERROR_COUPON_INVALID_CARD: "促銷代碼對您的卡無效，請改用其他卡。",
  ERROR_COUPON_INVALID_PAYMENT_TYPE: "促銷代碼僅適用於納稅。",
  ERROR_COUPON_INVOICE_PURPOSE_NOT_MATCH: "此促銷不可用於帳單付款。",
  ERROR_COUPON_MY_VISA:
    "哎呀！ 促銷代碼無效。 請通過右下角的實時聊天與我們聯繫。",
  ERROR_COUPON_NOT_FOUND: "促銷代碼無效。",
  ERROR_COUPON_NO_AVAILABLE_USE:
    "哎呀！ 促銷代碼無效。 請通過右下角的實時聊天與我們聯繫。",
  ERROR_COUPON_NO_AVAILABLE_USE_2: "促銷代碼已超過其使用限制。",
  ERROR_COUPON_NO_AVAILABLE_USE_3: "促銷代碼已超過其使用限制。",
  ERROR_COUPON_RENT_PURPOSE_NOT_MATCH: "此促銷不可用於租金付款。",
  ERROR_COUPON_SALARY_PURPOSE_NOT_MATCH: "此促銷不可用於薪金付款。",
  ERROR_COUPON_SG_VISA:
    "哎呀！ 促銷代碼無效。 請通過右下角的實時聊天與我們聯繫。",
  ERROR_COUPON_UEN_NOT_IN_LIST: "哎呀! 此促銷不可用於這筆付款。",
  ERROR_EMAIL_REQUIRED: "請輸入有效的電子郵件地址",
  ERROR_EXISTING_EMAIL: "此郵箱已註冊，請使用其他郵箱",
  ERROR_EXISTING_MOBILE_NUMBER: "此手機號碼已註冊，請使用其他號碼",
  ERROR_FIELD_REQUIRED: "這是必填欄。",
  ERROR_FIRST_NAME_REQUIRED: "請輸入你的姓氏",
  ERROR_HK_CARD_UNACCEPTED: "我們目前只接受Visa或Mastercard。",
  ERROR_INCORRECT_2FA_PASSWORD: "請輸入正確的密碼。",
  ERROR_INCORRECT_OLD_PASSWORD: "請輸入正確的舊密碼。",
  ERROR_INVALID_CARD: "銀行卡信息無效。",
  ERROR_INVALID_EMAIL_ADDRESS: "請輸入有效的電子郵件地址。",
  ERROR_INVOICE_SUPPORTING_DOCUMENT_REQUIRED: "請上傳賬單副本。",
  ERROR_LAST_NAME_REQUIRED: "請輸入你的姓氏",
  ERROR_MAX_LENGTH: "請輸入少於{max_length}個字符。",
  ERROR_MIN_LENGTH: "必須包括至少{min_length}個字符。",
  ERROR_MIN_PASSWORD_LENGTH: "密碼至少需要{min_length}個字符",
  ERROR_MOBILE_NUMBER_REQUIRED: "請輸入你的手提電話號碼",
  ERROR_MULTIPLE_SUPPLIERS_AMEX:
    "美國運通目前無法向多個供應商付款。請選擇一個供應商或另一個卡片品牌.",
  ERROR_MY_CARD_UNACCEPTED: "我們目前只接受MasterCard和Visa卡。",
  ERROR_NO_SELECTED_CARD: "請先選擇或添加一張銀行卡。",
  ERROR_NO_SELECTED_PAYEE: "請選擇收款人。",
  ERROR_OPERATION_PENDING_APPROVAL: "此付款正在等待其他操作的批准。",
  ERROR_PASSWORD_CONFIRM_REQUIRED: "請確保密碼相符",
  ERROR_PASSWORD_LENGTH:
    "請輸入至少由 1 個字母、1 個數字和 1 個符號組成的至少 8 個字符的密碼",
  ERROR_PASSWORD_REQUIRED: "請輸入一個更強的密碼",
  ERROR_PAYMENT_PROCESSED: "支付已處理完畢",
  ERROR_PROMO_CODE_FIRST_PAYMENT_ONLY:
    "哎呀！ 促銷代碼僅對您的第一次付款有效。",
  ERROR_PROMO_CODE_HIGHER_DEFAULT_RATE:
    "好消息，不需要促銷代碼。 您已經拿到最低價了！",
  ERROR_PROMO_CODE_NOT_FOR_INVOICE_PAYMENT: "促銷代碼對發票付款無效。",
  ERROR_PROMO_CODE_NOT_FOR_RENTAL_PAYMENT: "促銷代碼不適用於租金付款。",
  ERROR_PROMO_CODE_NOT_FOR_SALARY_PAYMENT: "促銷代碼不適用於工資支付。",
  ERROR_PROMO_CODE_ONE_TIME_PAYMENT_ONLY:
    "哎呀！ 看起來這是一個經常性的付款。 此促銷代碼僅適用於一次性付款。",
  ERROR_PROMO_CODE_RECURRING_PAYMENT_ONLY:
    "哎呀！ 看起來這是一次性付款。 此促銷代碼僅適用於定期付款。",
  ERROR_REFUND_PENDING_APPROVAL: "此款有待審批退款操作",
  ERROR_SCHEDULE_TYPE_REQUIRED: "請選擇單次付款或定期付款。",
  ERROR_SG_CARD_UNACCEPTED: "我們目前只接受 Visa、Mastercard 和中國銀聯。",
  ERROR_STATEMENT_MIN_LENGTH: "帳單描述必須包括至少22個字符。",
  ERROR_STRIPE_CARD_UNACCEPTED:
    "我們目前只接受MasterCard, Visa卡和American Express。",
  ERROR_TENANCY_AGREEMENT_REQUIRED: "請上傳你的租約副本。",
  ERROR_TERM_AGREEMENT_REQUIRED: "請勾選本方框同意我們的條款及細則",
  ERROR_UNSUPPORTED_EXPEDITE: "不支持加速操作",
  ERROR_UNSUPPORTED_REFUND: "不支持退款操作",
  ERROR_UPLOAD_FAILED: "我們在上傳您的文件時遇到了問題。 請再試一次。",
  ERROR_VALID_MOBILE_NUMBER: "請輸入有效的手機號碼",
  EXCEEDS_MAXIMUM_AMOUNT: "請輸入有效的金額，最多可輸入999,999.99。",
  EXCEED_MAX_AMOUNT_ERROR: "輸入的金額超過了交易限額，請輸入一個較小的金額。",
  EXPIRED_EMAIL_TOKEN: "你的電子郵件驗證已過有效期。請重試。",
  EXPIRED_PASSWORD_RESET_TOKEN: "您的令牌已過期。 請重新設置您的密碼。",
  EXPORT_BUTTON: "導出",
  FEATURE_UNAVAILABLE_ERROR: "該功能目前不可用。",
  FILE_DRAG_LABEL: "拖放你的文件到這裡上傳或",
  FILE_INVALID_TYPE: "文件類型無效",
  FILE_MAXIMUM_SIZE: "最大文件大小",
  FILE_SELECT_LABEL: "選擇要上傳的文件",
  FILE_SUPPORTED_TYPES: "支持的文件類型",
  FILTER_BUTTON: "篩選",
  FILTER_CHARGE_DATE_HEADER: "扣賬日期",
  FILTER_CLEAR_BUTTON: "清除",
  FILTER_DONE_BUTTON: "完成",
  FILTER_FROM_LABEL: "從",
  FILTER_HEADER: "篩選",
  FILTER_STATUS_CANCELLED: "已取消",
  FILTER_STATUS_COMPLETED: "已完成",
  FILTER_STATUS_DECLINED: "已拒絕",
  FILTER_STATUS_HEADER: "狀態",
  FILTER_STATUS_ON_HOLD: "等候接聽",
  FILTER_STATUS_PROGRESS: "進行中",
  FILTER_STATUS_REFUNDED: "退款",
  FILTER_TO_LABEL: "至",
  FIND_ABN: "不知道ABN嗎？你可以點擊",
  FIND_CRN: "不知道商業登記號碼(BRN/CRN)？你可以點擊",
  FIND_UEN: "不知道獨特實體號碼(UEN)？你可以點擊",
  FIRST_EXPIRING_PAYMENT_REMINDER_SUBJECT: "提醒：您向%s安排的定期付款即將結束",
  FIRST_NAME_ERROR: "請輸入你的名字",
  FIRST_NAME_PLACEHOLDER: "名字",
  FIRST_UPCOMING_PAYMENT_REMINDER_SUBJECT:
    "提醒：您有一筆即將支付給%s的預定付款",
  FORGOT_PASSWORD_EMAIL_BUTTON: "重設密碼",
  FORGOT_PASSWORD_EMAIL_EXPIRY: "此驗證郵件在{{.ExpiredIn}}有效。",
  FORGOT_PASSWORD_EMAIL_HERO: "忘記密碼？",
  FORGOT_PASSWORD_EMAIL_INSTRUCTIONS: "請點擊下面的按鈕來重設密碼。",
  FORGOT_PASSWORD_EMAIL_SUBJECT: "重設密碼",
  FORGOT_PASSWORD_INSTRUCTIONS: "忘記密碼？ {RESET_PASSWORD_LINK}。",
  FREE_PAYMENTS_MESSAGE:
    "你目前享受0%的交易費用，因為你已經申請了免費支付優惠。",
  GET_HELP_BUTTON: "求助",
  GET_STARTED: "立即推薦",
  GREETING_NAVBAR: "你好，{render}!",
  GST_REGISTRATION_NUMBER: "消費稅註冊號",
  GST_REGISTRATION_NUMBER_PLACEHOLDER: "消費稅註冊號",
  HANDSHAKE_CUSTOMER_TAG_ACTIVE: "活躍客戶",
  HANDSHAKE_CUSTOMER_TAG_ACTIVE_DEFINITION:
    "過去90天內完成了至少一筆付款的客戶。",
  HANDSHAKE_CUSTOMER_TAG_NEW: "新增客戶",
  HANDSHAKE_CUSTOMER_TAG_NEW_DEFINITION: "過去30天內收到首次付款請求的客戶。",
  HANDSHAKE_CUSTOMER_TAG_RISKY: "風險客戶",
  HANDSHAKE_CUSTOMER_TAG_RISKY_DEFINITION:
    "過去90天內完成的付款少於全部所需付款50％的客戶。",
  HANDSHAKE_CUSTOMER_TAG_VIP: "貴賓客戶",
  HANDSHAKE_CUSTOMER_TAG_VIP_DEFINITION:
    "過去90天內按時完成所有全額付款的客戶。",
  HERE_LINK: "此處。",
  HIDE_DETAILS_BUTTON: "隱藏詳情",
  HISTORY_HERO: "收到款項",
  HISTORY_INVOICE_HEADER: "賬單",
  HISTORY_MENU_ITEM: "歷史",
  HISTORY_NET_HEADER: "淨值",
  HKID_LABEL: "香港身份證號碼",
  HKID_PLACEHOLDER: "僱員香港身份證號碼",
  ID_LABEL: "身分證件",
  ID_PLACEHOLDER: "僱員身分證件",
  IMAGE_MORE_FREE_PAYMENTS: "你有更多的免費收款額度!",
  IMAGE_NEW_MONTH: "新的一月",
  IMAGE_SWEET: "太好了！你已經用盡了免費收款額度。",
  IMAGE_TRANSACTION_FEE: "手續費",
  IMAGE_VICTORY_LAP: "屬於你的里程碑，你成爲收款專家!",
  IMAGE_WORKS_PAYS: "易用如信用卡，好用如現金",
  IMAGE_ZERO_PERCENT: "0%手續費",
  INCENTIVES_EARLY_DEADLINE: "提前還款期限",
  INCENTIVES_EARLY_DESCRIPTION:
    "我們將在發票日期後兩個工作日通過電子郵件向您的客戶發送此優惠，發票上至少有15個淨到期日。",
  INCENTIVES_EARLY_FEE: "提前付款折扣",
  INCENTIVES_HALF_DESCRIPTION:
    "如果您的客戶未在到期日之前全額付款，我們將在到期日後一天通過電子郵件發送報價，立即支付發票總金額的一半，並在到期日的同一天支付剩餘的一半 下個月。",
  INCENTIVES_IMMEDIATE_DEADLINE: "即時付款截止日期",
  INCENTIVES_IMMEDIATE_DESCRIPTION:
    "我們將在到期日前三個工作日通過電子郵件向您的客戶發送此優惠，發票上至少有30個淨到期日。",
  INCENTIVES_IMMEDIATE_FEE: "立即付款折扣",
  INCENTIVES_LATE_DEADLINE: "逾期付款期限",
  INCENTIVES_LATE_DESCRIPTION:
    "如果您的客戶未在到期日之前全額付款，我們將在到期日後一天通過電子郵件發送通知，告知我們已對逾期付款收取此費用。",
  INCENTIVES_LATE_FEE: "滯納金",
  INCENTIVES_THREE_DESCRIPTION:
    "如果您的客戶未在到期日之前全額付款，我們將在到期日後一天通過電子郵件發送報價，立即支付發票總金額的三分之一，並在同一天支付剩餘的三分之一 接下來的兩個月。",
  INCENTIVES_THREE_INSTALLMENTS_DEADLINE: "分三期付款截止",
  INCENTIVES_TWO_INSTALLMENTS_DEADLINE: "一半現在，一半後截止日期",
  INSTALLMENT_NUMBER_LABEL: "{total}的第{number}期",
  INSTANT_REQUEST_BUTTON: "即時收款要求",
  INSTANT_REQUEST_MENU_ITEM: "即時申請",
  INSTRUCTION_DESCRIPTION_OF_GOODS: "貨物描述",
  INSTRUCTION_INVOICE_AMOUNT: "賬單金額",
  INSTRUCTION_SUPPLIER_COMPANY_NAME: "供應商公司名稱",
  INSURANCE_LEARN_MORE: "你可以了解更多有關保險繳款的資訊",
  INTEND_MULTIPLE_PAYMENTS_LABEL: "我打算向此供應商繳付多筆款項",
  INTEND_MULTIPLE_PAYMENTS_TOOLTIP:
    "此處確認你打算向此供應商安排定期付款，你或符合資格享有較低手續費。",
  INTERNATIONAL_LABEL: "國際",
  INT_PAYMENT_MISSING_RECIPIENT_DATA:
    "我們注意到您缺少此國際付款收款人的以下必需數據：",
  INT_PAYMENT_UNSUPPORTED_ERROR:
    "請登錄我們的新平台web.ipaymy.com進行國際支付。",
  INVALID_ABN: "請輸入有效的澳大利亞業務編號（ABN）。",
  INVALID_ALPHANUMERIC: "請在此處僅輸入字母和數字。",
  INVALID_AMOUNT: "這看起來不像是一個有效的金額。",
  INVALID_AMOUNT_CONVERSION: "這看起來不像是一個有效的金額。",
  INVALID_ANZBSGSX_3_NUMBER_1:
    "澳新銀行賬戶號碼需要10或11位數字，請輸入有效的澳新銀行賬戶號碼。",
  INVALID_BANKSWIFT: "銀行Swift代碼無效",
  INVALID_BANK_CODE_FORMAT: "以有效格式輸入您的銀行代碼。",
  INVALID_BANK_DOCUMENTS_REQUIRED: "需要銀行文件",
  INVALID_BANK_NOT_SUPPORTED: "ipaymy不支持向該銀行進行支付",
  INVALID_BANK_NUMBER: "銀行賬號無效",
  INVALID_BANK_NUMBER_NOT_SUPPORTED: "ipaymy不支持向此銀行帳號付款。",
  INVALID_BEASHKHH_123_NUMBER_1: "請輸入有效的東亞銀行有限公司賬戶號碼。",
  INVALID_BKCHHKHH_120_NUMBER_1: "請輸入一個有效的中國銀行(香港)賬戶號碼。",
  INVALID_BKCHSGSG_6_NUMBER_1: "中國銀行的賬戶號碼需要15位數字。",
  INVALID_BNPASGSG_142_NUMBER_1: "請輸入一個有效的BNP Paribas帳戶號碼。",
  INVALID_BOC_NUMBER: "請輸入一個有效的中國銀行賬戶號碼。",
  INVALID_BOFASG2X_21_NUMBER_1: "美國銀行的賬號需要8位數。",
  INVALID_BRN: "請輸入有效的公司註冊編號(CR)。",
  INVALID_BRN_AFFILIATED_ENTITIES: "不可向你的企業或任何關聯實體進行帳單付款。",
  INVALID_CCBQHKAX_125_NUMBER_1:
    "請輸入有效的中國建設銀行(亞洲)股份有限公司賬戶號碼。",
  INVALID_CIBBSGSG_12_NUMBER_1: "聯昌銀行賬戶號碼需要10或16或17位數。",
  INVALID_CITIHKAX_128_NUMBER_1:
    "請輸入一個有效的Citibank (Hong Kong) Limited帳戶號碼。",
  INVALID_CITIHKAX_128_NUMBER_2: "請輸入有效的花旗銀行(香港)有限公司賬戶號碼。",
  INVALID_CITIHKAX_128_NUMBER_3: "請輸入有效的花旗銀行(香港)有限公司賬戶號碼。",
  INVALID_CITIHKHX_129_NUMBER_1: "請輸入有效的花旗銀行賬戶號碼。",
  INVALID_CITISGSG_4_NUMBER_1: "花旗銀行的賬號需要10位數。",
  INVALID_CITISGSG_NUMBER:
    "這看起來不像是一個有效的花旗銀行賬戶號碼。應該是十位數",
  INVALID_CITISGSL_117_NUMBER_1: "花旗銀行賬戶號碼需要10位數。",
  INVALID_CIYUHKHH_126_NUMBER_1: "請輸入有效的集友銀行股份有限公司賬戶號碼。",
  INVALID_COMMHKHK_121_NUMBER_1: "請輸入有效的交通銀行股份有限公司賬戶號碼。",
  INVALID_COMMHKHK_122_NUMBER_1: "請輸入有效的交通銀行股份有限公司賬戶號碼。",
  INVALID_CREDENTIALS: "電子郵件/密碼組合無效，請重試。",
  INVALID_DATE: "無效日期",
  INVALID_DBSSHKHH_132_NUMBER_1: "請輸入有效的星展銀行有限公司賬戶號碼。",
  INVALID_DBSSSGSG_19_NUMBER_1: "新加坡郵政儲蓄銀行賬號需要9位數。",
  INVALID_DBSSSGSG_19_NUMBER_2: "請輸入有效的新加坡邮政储蓄银行賬戶號碼。",
  INVALID_DBSSSGSG_1_NUMBER_1: "星展銀行賬戶號碼需要9或10位數字。",
  INVALID_DBSSSGSG_1_NUMBER_2: "請輸入有效的星展銀行賬戶號碼。",
  INVALID_DBSSSGSG_NUMBER:
    "這看起來不像是一個有效的星展銀行賬戶號碼。應該是十位數",
  INVALID_DHBKHKHH_131_NUMBER_1: "請輸入有效的星展銀行(香港)有限公司賬戶號碼。",
  INVALID_DSBAHKHH_130_NUMBER_1: "請輸入有效的大新銀行有限公司賬戶號碼。",
  INVALID_DUE_DATE: "輸入有效格式的未來發票到期日。",
  INVALID_DUPLICATED_COLLECTION_INVOICED_DOCUMENT:
    "你只可以為每個發票編號上傳一張對應的發票。",
  INVALID_EMAIL_ADDRESS: "請輸入一個有效的電子郵件地址。",
  INVALID_EMAIL_TOKEN: "請先驗證你的電子郵件。",
  INVALID_EMAIL_USED: "電子郵件地址無效",
  INVALID_EMPLOYEE_ID: "請輸入有效的員工ID。",
  INVALID_EXCEED_FILE_SIZE: "文件大小超過16MB.。",
  INVALID_EXCEED_FILE_SIZE_5MB: "文件大小超過5MB。",
  INVALID_FILE_NAME_LENGTH:
    "文件名超過60個字符的限制。請重新命名並上傳一個新文件。",
  INVALID_FILE_UPLOAD_AMOUNT: "你必須上傳至少{file_amount}個文件。",
  INVALID_GROSS_AMOUNT: "金額無效",
  INVALID_HASEHKHH_133_NUMBER_1: "請輸入有效的恆生銀行有限公司賬戶號碼。",
  INVALID_HKIC: "請輸入有效的香港身份證。",
  INVALID_HSBCHKHHHKH_134_NUMBER_1:
    "請輸入有效的香港上海匯豐銀行有限公司賬戶號碼。",
  INVALID_HSBCSGS2_5_NUMBER_1:
    "匯豐銀行新加坡分行的賬戶號碼需要12位數字，請輸入有效的匯豐銀行新加坡分行賬戶號碼。",
  INVALID_HSBCSGS2_5_NUMBER_2: "請輸入有效的匯豐銀行新加坡賬戶號碼。",
  INVALID_HSBCSGSG_22_NUMBER_1:
    "匯豐銀行賬戶號碼需要12位數字，請輸入有效的匯豐銀行新加坡賬戶號碼。",
  INVALID_HSBCSGSG_22_NUMBER_2: "請輸入有效的匯豐銀行賬戶號碼。",
  INVALID_HSBCSGSG_NUMBER_1: "匯豐銀行賬戶號碼需要12位數字。",
  INVALID_HSBCSGSG_NUMBER_2: "請輸入有效的匯豐銀行賬戶號碼。",
  INVALID_IBAN: "IBAN無效",
  INVALID_INCORPORATION_DATE_ERROR: "請輸入一個有效的日期。",
  INVALID_IOBASGSGS_NUMBER_1: "印度海外銀行的賬戶號碼需要15位數字。",
  INVALID_IOBASGSG_15_NUMBER_1: "印度海外銀行賬號需要15位數。",
  INVALID_KWHKHKHH_124_NUMBER_1:
    "請輸入有效的中國花旗銀行國際有限公司賬戶號碼。",
  INVALID_LCHBHKHH_127_NUMBER_1: "請輸入有效的創興銀行有限公司賬戶號碼。",
  INVALID_LENGTH_HK_NOTE: "註釋必須不超過35個字符。",
  INVALID_LENGTH_MALAYSIA_NOTE: "註釋必須不超過20個字符。",
  INVALID_LENGTH_NOTE: "註釋不得超過%s字符。",
  INVALID_LENGTH_OTHER_COUNTRY_NOTE: "註釋必須不超過35個字符。",
  INVALID_LENGTH_SINGAPORE_NOTE: "註釋必須不超過35個字符。",
  INVALID_MAXIMUM_VALUE: "最高為{value}。",
  INVALID_MBBESGSG_8_NUMBER_1: "馬來亞銀行銀行賬戶號碼需要11位數。",
  INVALID_MBBESGSG_NUMBER_1: "馬來亞銀行賬戶號碼需要11位數字。",
  INVALID_MHCBSGSG_17_NUMBER_1: "瑞穗銀行賬號需要11位數。",
  INVALID_MHCBSGSG_NUMBER_1: "瑞穗銀行賬戶號碼需要11位數。",
  INVALID_MINIMUM_SALARY_AMOUNT_MY: "最低工資金額為馬幣RM30。",
  INVALID_MOBILE_USED: "這個電話號碼的賬戶已經存在。",
  INVALID_MYKAD_NUMBER: "請輸入有效的大馬卡。",
  INVALID_MY_INVOICE_PAYEE_AMOUNT: "最低發票金額馬幣RM30。",
  INVALID_NOTE_TO:
    "使用字符無效。請注意，你的收款人須知只能包含字母和數字字符。",
  INVALID_NRICFIN: "請輸入有效的身份證。",
  INVALID_NYCBHKHH_136_NUMBER_1: "請輸入有效的南洋商業銀行有限公司賬號。",
  INVALID_NYCBHKHH_136_NUMBER_2: "請輸入有效的南洋商業銀行有限公司賬戶號碼。",
  INVALID_OCBCSGSG_2_NUMBER_1:
    "華僑銀行賬戶號碼需要10或12位數字，請輸入有效的華僑銀行賬戶號碼。",
  INVALID_OCBCSGSG_2_NUMBER_2: "請輸入一個有效的華僑銀行賬戶號碼。",
  INVALID_OCBCSGSG_NUMBER:
    "這看起來不像是一個有效的華僑城銀行帳戶號碼。應該是7七位數。如果你看到十位數字，如 「5501089550」，請去掉前3位數字(550)，這是分行代碼。",
  INVALID_OCBCSGSG_NUMBER_1: "華僑銀行賬戶號碼需要10或12位數字。",
  INVALID_OCBCSGSG_NUMBER_2: "請輸入有效的華僑銀行賬戶號碼。",
  INVALID_PASSWORD_STRENGTH: "請選擇至少8個字符的密碼，包括字母、數字和符號。",
  INVALID_PAYMENT_DUE_TO_SCREENING_REJECT: "由於篩選拒絕而導致的無效付款",
  INVALID_PAYOUT_DATE_ERROR:
    "哎呀，看來我們遇到了一個問題。請嘗試重新安排你的付款。",
  INVALID_PHONE_NUMBER: "請提供一個有效的手提電話號碼。",
  INVALID_POSTAL_CODE_MY: "請輸入有效的郵政編碼",
  INVALID_PROMO_CODE:
    "哎呀！ 促銷代碼無效。 請通過右下角的實時聊天與我們聯繫。",
  INVALID_PROMO_CODE_CHARGE_DATE: "哎呀！ 促銷代碼在此收費日期無效。",
  INVALID_RATE_NUMBER: "請輸入一個有效的費率。",
  INVALID_RECIPIENT_NAME: "使用字符無效。收款人名稱只能包含字母和數字字符。",
  INVALID_REQUEST_BODY: "本體無效。",
  INVALID_REQUEST_BODY_STRUCTURE_ERROR: "部分信息不正確",
  INVALID_RHBBSGSG_13_NUMBER_1: "興業銀行的賬戶號碼需要11位數字。",
  INVALID_RHBBSGSG_NUMBER_1: "興業銀行的賬戶號碼需要11位數字。",
  INVALID_SALARY_AMOUNT: "請提供該員工的有效工資數額",
  INVALID_SALARY_AMOUNT_HK: "請提供該僱員的有效工資金額",
  INVALID_SBINSGSG_11_NUMBER_1: "印度國家銀行的賬戶號碼需要14位數字。",
  INVALID_SBINSGSG_11_NUMBER_2: "請輸入有效的印度國家銀行賬號。",
  INVALID_SBINSGSG_NUMBER_1: "印度國家銀行的賬戶號碼需要14位數字。",
  INVALID_SBINSGSG_NUMBER_2: "請輸入一個有效的印度國家銀行賬戶號碼。",
  INVALID_SCBKHKHH_138_NUMBER_1: "請輸入有效的上海商業銀行有限公司賬戶號碼。",
  INVALID_SCBKHKHH_138_NUMBER_2: "請輸入有效的上海商業銀行有限公司賬戶號碼。",
  INVALID_SCBLHKHH_139_NUMBER_1: "請輸入有效的渣打銀行(香港)有限公司賬戶號碼。",
  INVALID_SCBLHKHH_140_NUMBER_1: "請輸入有效的永隆銀行有限公司賬戶號碼。",
  INVALID_SCBLSGSG_10_NUMBER_1: "渣打銀行賬戶號碼需要10位數。",
  INVALID_SCBLSGSG_NUMBER_1: "渣打銀行的賬戶號碼需要10位數。",
  INVALID_SG_INVOICE_PAYEE_AMOUNT: "最低帳單金額為S$15。",
  INVALID_SSM: "請輸入有效的SSM號碼。",
  INVALID_SSM_NOT_SUPPORTED: "請輸入有效的公司註冊號。",
  INVALID_STATEMENT_DESCRIPTOR: "無效字符。帳單描述只可以包括字母數字字符。",
  INVALID_STATEMENT_DESCRIPTOR_ALPHABET: "付款說明必須包括至少一個字母字符。",
  INVALID_STATEMENT_DESCRIPTOR_LENGTH: "單描述不可超過22個字符。",
  INVALID_SUPPORTING_DOCUMENTS_RENT_COUNT: "你已經達到上傳證明文件的最大數量。",
  INVALID_SUPPORTING_DOCUMENTS_RENT_REQUIRED: "請至少上傳一份證明文件。",
  INVALID_SUPPORTING_DOCUMENTS_SALARY_COUNT:
    "你已經達到上傳證明文件的最大數量。",
  INVALID_UBHKHKHH_135_NUMBER_1:
    "請輸入有效的中國工商銀行(亞洲)有限公司賬戶號碼。",
  INVALID_UEN: "請輸入有效的UEN。",
  INVALID_UEN_AFFILIATED_ENTITIES: "帳單支付不能支付給貴公司或任何關聯實體。",
  INVALID_UEN_NOT_SUPPORTED: "ipaymy目前不支持向該供應商付款。",
  INVALID_UOVBSGSG_16_NUMBER_1: "遠東銀行銀行賬戶號碼需要10位數。",
  INVALID_UOVBSGSG_7_NUMBER_1: "大華銀行帳號需要10位或12位數字。",
  INVALID_UOVBSGSG_NUMBER_1: "大華銀行帳號需要10位或12位數字。",
  INVALID_WIHBHKHH_137_NUMBER_1: "請輸入有效的華僑永亨銀行有限公司賬戶號碼。",
  INVOICES_BUTTON: "賬單",
  INVOICE_AMOUNT_DUE_LABEL: "應付金額",
  INVOICE_AMOUNT_HEADER: "金額",
  INVOICE_BILL_TO_LABEL: "賬單至",
  INVOICE_CC_LABEL: "CC",
  INVOICE_DATE_CUSTOM_OPTION: "自定義",
  INVOICE_DATE_LABEL: "帳單日期",
  INVOICE_DATE_NET_15_OPTION: "15日淨值",
  INVOICE_DATE_NET_30_OPTION: "30日淨值",
  INVOICE_DATE_NET_45_OPTION: "45日淨值",
  INVOICE_DATE_NET_60_OPTION: "60天淨值",
  INVOICE_DATE_PLACEHOLDER: "應收賬款",
  INVOICE_DISCOUNT_HEADER: "折扣",
  INVOICE_DUE_DATE_LABEL: "發票到期日",
  INVOICE_DUE_LABEL: "到期日",
  INVOICE_EXAMPLE_COMPANY_ADDRESS: "新加坡羅賓遜路1號，新加坡068908",
  INVOICE_EXAMPLE_COMPANY_NAME: "XYZ公司",
  INVOICE_EXAMPLE_DATE: "帳單日期：2020年2月12日",
  INVOICE_EXAMPLE_DUE_DATE: "2020年2月27日",
  INVOICE_EXAMPLE_GST_REGISTRATION_NUMBER: "消費稅註冊號",
  INVOICE_EXAMPLE_GST_TAX: "消費稅（7%）",
  INVOICE_INSTRUCTIONS: "賬單\u003cspan\u003e必須包括：\u003c/span\u003e",
  INVOICE_ITEM_ADD_BUTTON: "添加項目",
  INVOICE_ITEM_DESCRIPTION_HEADER: "項目描述",
  INVOICE_ITEM_NEW_OPTION: "添加新項目",
  INVOICE_ITEM_PLACEHOLDER: "點擊選擇或添加項目",
  INVOICE_LABEL: "帳單",
  INVOICE_LOGO_PREVIEW: "商標預覽",
  INVOICE_MESSAGE_LABEL: "留言內容",
  INVOICE_MULTIPLE_CUSTOMER_TOOLTIP: "向同一位客戶發出多個付款請求。",
  INVOICE_MULTIPLE_SUPPLIER_TOOLTIP: "向同一供應商支付多筆帳單款項。",
  INVOICE_NEW_ITEM_DISCOUNT_LABEL: "折扣",
  INVOICE_NEW_ITEM_DISCOUNT_PLACEHOLDER: "點擊{render1}並更改為{render2}",
  INVOICE_NEW_ITEM_HERO: "新的商品/服務",
  INVOICE_NEW_ITEM_NAME_LABEL: "名稱",
  INVOICE_NEW_ITEM_NAME_PLACEHOLDER: "名稱",
  INVOICE_NEW_ITEM_PCT_DISCOUNT_PLACEHOLDER: "例如1.5",
  INVOICE_NEW_ITEM_QUANTITY_PLACEHOLDER: "例如10",
  INVOICE_NEW_ITEM_SELECT_TAX_PLACEHOLDER: "選擇稅項",
  INVOICE_NEW_ITEM_TAX_NAME_LABEL: "稅項名稱",
  INVOICE_NEW_ITEM_TAX_NAME_PLACEHOLDER: "稅項名稱",
  INVOICE_NEW_ITEM_TAX_RATE_LABEL: "稅率",
  INVOICE_NEW_ITEM_TAX_RATE_PLACEHOLDER: "例如1.5",
  INVOICE_NEW_TAX_HERO: "新稅種",
  INVOICE_NOTES_LABEL: "備註（選填）",
  INVOICE_NOTE_OPTIONAL_COMPANY_TCS: "可選區域，其中包括你公司的條款及細則。",
  INVOICE_NUMBER_AUTO_NEXT_LABEL: "下一個數字",
  INVOICE_NUMBER_AUTO_OPTION: "使用自動生成的帳單號碼。",
  INVOICE_NUMBER_AUTO_PREFIX_LABEL: "帳單號碼前綴",
  INVOICE_NUMBER_HEADER: "帳單號碼",
  INVOICE_NUMBER_INSTRUCTIONS: "你想如何生成帳單號碼？",
  INVOICE_NUMBER_LABEL: "帳單號碼/說明",
  INVOICE_NUMBER_MANUAL_OPTION: "每次都要手動添加帳單號碼。",
  INVOICE_NUMBER_PLACEHOLDER: "帳單號碼/說明",
  INVOICE_ORDER_LABEL: "帳單/訂單號",
  INVOICE_ORDER_PLACEHOLDER: "帳單/訂單號",
  INVOICE_PREVIEW: "帳單預覽",
  INVOICE_PURPOSE_LABEL: "輸入賬單金額及上傳賬單",
  INVOICE_QUANTITY_HEADER: "數量",
  INVOICE_REPLY_TO_LABEL: "回复",
  INVOICE_REQUIRED_ERROR: "需要發票。",
  INVOICE_SUBTOTAL_LABEL: "小計",
  INVOICE_TAX_HEADER: "稅收",
  INVOICE_TAX_NEW_OPTION: "添加新稅種",
  INVOICE_TAX_PLACEHOLDER: "選擇一個稅種",
  INVOICE_TCS_LABEL: "條款及細則（選填）",
  INVOICE_TOTAL_LABEL: "應付總金額",
  INVOICE_TO_LABEL: "至",
  INVOICE_UNIT_PRICE_HEADER: "單位價格",
  IPAYMY_LEARN_MORE_LINK: "認識ipaymy",
  IPAYMY_RESTRICTED_BUSINESS_CHECKBOX:
    '通過我們註冊，即表示你確認不會使用ipaymy或我們的服務接受與任何\u003ca\u003e"受限制的業務"\u003c/a\u003e有關的款項，或用於任何非法活動。',
  IPAYMY_TCS_CHECKBOX:
    "我同意ipaymy的收款\u003ca\u003e條款及細則.\u003c/a\u003e",
  IPAYMY_TERM_ERROR: "為了使用本服務，你必須同意這些條款。",
  IRAS_TAX_TOOLTIP: "為方便起見，已添加IRAS為您繳付稅款的供應商。",
  IRAS_TOOLTIP: "點擊此處，即可輕鬆繳稅到\u003cb\u003eIRAS.\u003c/b\u003e",
  LABEL_ADD_CARD_TO_WALLET: "增加一張信用卡",
  LABEL_ADD_COMPANY: "添加新公司",
  LABEL_AGREE_TC: "我同意ipaymy的條款和條件以及隱私政策。",
  LABEL_AGREE_TC_1: "我同意ipaymy {term_and_condition}和{privacy_policy}。",
  LABEL_AGREE_TC_2: "條款及細則",
  LABEL_AGREE_TC_3: "私隱政策",
  LABEL_BENEFICIARY_STATEMENT: "業主須知",
  LABEL_BTN_ADD_NEW_COMPANY: "添加新公司",
  LABEL_BTN_SIGN_OUT: "登出",
  LABEL_CARD_CHARGED: "帳戶號碼需要8位數字。",
  LABEL_CARD_CVC_INSTRUCTION: "3位數安全碼通常置於信用卡背面。",
  LABEL_CARD_HOLDER: "持卡人姓名",
  LABEL_CARD_TO_BE_CHARGED: "將被扣款的信用卡",
  LABEL_CHANGE_PASSWORD: "更改密碼",
  LABEL_CHECKOUT_CARD_STATEMENT: "對賬單的說明",
  LABEL_CHECKOUT_COUPON_CODE: "促銷代碼",
  LABEL_CHECKOUT_FEE: "費用 ({percentage}%)",
  LABEL_CHECKOUT_PAY_WITH_ANOTHER_CARD: "用其他卡支付",
  LABEL_COMPANY_REGISTRATION_NUMBER: "公司註冊號",
  LABEL_CREATE_INVOICE: "建立你的帳單",
  LABEL_DASHBOARD_PAYMENT_EMPTY: "未有任何付款。",
  LABEL_EDIT_COMPANY: "編輯公司",
  LABEL_EDIT_PROPERTY: "編輯物業資料",
  LABEL_EMPLOYEE_SUMMARY: "僱員總結",
  LABEL_END_OF_DAY: "工作日結束時",
  LABEL_GET_STARTED_MAKING_PAYMENT: "開始支付吧",
  LABEL_GET_STARTED_MAKING_PAYMENT_1: "開始",
  LABEL_GET_STARTED_MAKING_PAYMENT_2: "通過支付",
  LABEL_INVOICE_SCHEDULE_BENIFICIARY:
    "你的卡扣款日期為：{render1}。你的供應商將在此{render2}收到款項：{render3}。",
  LABEL_INVOICE_SCHEDULE_BENIFICIARY_TODAY:
    "你的卡將在今天被扣款。你的供應商將在此{render1}收到款項：{render2}。",
  LABEL_IPM_BUSINESS_ACCOUNT: "ipaymy商業賬戶",
  LABEL_IPM_PERSONAL_ACCOUNT: "ipaymy個人賬戶",
  LABEL_MAKE_PAYMENT_RECURRYING: "讓你的付款有回報",
  LABEL_MENU_ADMIN: "管理員",
  LABEL_MENU_COMPANIES: "公司",
  LABEL_MENU_DASHBOARD: "儀表板",
  LABEL_MENU_EMPLOYEES: "僱員",
  LABEL_MENU_MY_WALLET: "我的錢包",
  LABEL_MENU_PAYMENTS: "支付",
  LABEL_MENU_PROPERTIES: "屬性",
  LABEL_MENU_SCHEDULED: "計劃",
  LABEL_MENU_SUPPLIERS: "供應商",
  LABEL_MENU_SUPPORT: "支持",
  LABEL_MONEY_TRANSFER: "轉賬",
  LABEL_MY_ACCOUNT: "我的賬戶",
  LABEL_MY_COMPANIES: "我的公司",
  LABEL_NOTE_TO_LANDLORD: "業主須知",
  LABEL_OLD_CARD_TOKEN_ARCHIVED_NOTIFICATION:
    "我們最近更新了我們的平台，這要求您將現有卡重新添加到您的錢包中。 這些更改不會影響任何已安排的付款。",
  LABEL_PAGINATION: "Page {{.idx}} of {{.length}}",
  LABEL_PASSWORD: "確認密碼",
  LABEL_PAYMENT_DETAIL_DISCOUNT: "折扣",
  LABEL_PAYMENT_DETAIL_FEE: "手續費",
  LABEL_PAYMENT_DETAIL_FEE_TOTAL: "費用總計",
  LABEL_PAYMENT_SCHEDULE_SUCCESS_1: "耶！支付成功計劃！",
  LABEL_PAYMENT_SCHEDULE_SUCCESS_2:
    "你的信用卡將在{charged_date}日被扣取費用，你的房東將在{payout_date}日收到款項。。",
  LABEL_PAYMENT_SCHEDULE_SUCCESS_2_RENT:
    "你的銀行卡將在{charged_date}被收取費用，你的房東將在{payout_date}收到款項。",
  LABEL_PAYMENT_SCHEDULE_SUCCESS_3:
    "你的銀行將在{charged_date}被扣取費用，你的{recipient}將在{payout_date}收到付款。",
  LABEL_PAY_YOUR_EMPLOYEE: "支付薪金",
  LABEL_PAY_YOUR_LANDLORD: "支付租金",
  LABEL_PAY_YOUR_SUPPLIER: "支付供應商{br}賬單",
  LABEL_PAY_YOUR_TAX: "繳稅",
  LABEL_RECOMMENT_ADD_CARD_DESC:
    "通過在錢包中添加一張銀行卡，令支付變得快速和簡單。",
  LABEL_RECOMMENT_SCHEDULE_PAYMENT_DESC:
    "使用分期付款可節省時間並享受更優惠的費率。",
  LABEL_RECOMMENT_SUPPORT_DESC: "通過即時聊天或電子郵件聯繫我們。",
  LABEL_RECOMMENT_VIEW_PAYMENT_DESC: "查看和管理付款信息。",
  LABEL_RECOMMENT_VIEW_PAYMENT_TITLE: "查看付款狀態",
  LABEL_REGISTER_SELECT_COUNTRY: "選擇你的位置",
  LABEL_REMEMBER_ME: "記住賬號",
  LABEL_RENT_BENEFICIARY_STATEMENT: "業主須知",
  LABEL_RENT_SCHEDULE_BENIFICIARY:
    "你的卡扣款日期為：{render1}。你的業主將在此{render2}收到款項：{render3}。",
  LABEL_RENT_SCHEDULE_BENIFICIARY_TODAY:
    "你的卡將在今天被扣款。你的業主將在此{render1}收到款項：{render2}。",
  LABEL_SALARY_SCHEDULE_BENIFICIARY:
    "你的卡扣款日期為：{render1}。你的僱員將在此{render2}收到款項：{render3}。",
  LABEL_SALARY_SCHEDULE_BENIFICIARY_TODAY:
    "你的卡將在今天被扣款。你的僱員將在此{render1}收到款項：{render2}。",
  LABEL_SCHEDULE_BENIFICIARY:
    "你的信用卡將在{charged_date}日被扣取費用，你的房東將在{payout_date}日收到款項。",
  LABEL_SCHEDULE_BENIFICIARY_TODAY:
    "你的銀行卡將在今天被扣取費用，你的房東將在{payout_date}工作日結束前收到資金。",
  LABEL_SCHEDULE_YOUR_PAYMENT: "安排你的付款",
  LABEL_SCHEDULE_YOUR_PAYMENTS: "安排你的付款",
  LABEL_SELECT_PAYOUT_DATE: "選擇支付日期",
  LABEL_SELECT_PAYOUT_DATES: "選擇付款日期",
  LABEL_SMS_REQUEST: "短訊收款要求",
  LABEL_TRANSACTION_SUCCESSFUL:
    "你的卡已成功扣款。你的款項將在{payout_date}時支付。",
  LABEL_TRANSACTION_SUCCESSFUL_RECEIPT_NUMBER: "帳單號碼：{render}",
  LABEL_TRY_IPM_FOR_BUSINESS: "試試ipaymy業務",
  LABEL_UPLOAD_CSV_FILE_TIP:
    "提示：將 「賬號 」單元格保存為TEXT格式，文件類型保存為CSV。",
  LABEL_UPLOAD_INVOICE: "上傳你的帳單",
  LANDLORD_NAME_LABEL: "業主姓名",
  LANDLORD_NAME_PLACEHOLDER: "業主姓名",
  LANDLORD_NOTE_PLACEHOLDER: "收款人賬單上的備注",
  LAST_NAME_ERROR: "請輸入你的姓氏",
  LAST_NAME_PLACEHOLDER: "姓氏",
  LEGEND_INCOMING_PAYMENT: "\u003cb\u003e將收到\u003c/b\u003e的款項",
  LEGEND_OUTGOING_PAYMENT: "\u003cb\u003e對外\u003c/b\u003e支付",
  LIMIT_PAYMENT_UNIONPAY_7399:
    "請注意，如果您選擇使用中國銀聯信用卡付款，則所有薪水或發票付款最高限額為5,000美元或等值貨幣。",
  LOADING_STATUS: "加載中...",
  MADE_MULTIPLE_PAYMENTS_LABEL: "已經向此供應商支付多筆款項",
  MADE_MULTIPLE_PAYMENTS_TOOLTIP:
    "由於你已經向此供應商安排定期付款，你或符合資格享有較低手續費。",
  MALAYSIAN_LABEL: "馬來西亞",
  MASTERCARD_LABEL: "Mastercard",
  MAYBE_LATER: "稍後試試",
  MINIMUM_INVOICE_AMOUNT_AU: "最低發票金額A$15。",
  MINIMUM_INVOICE_AMOUNT_AU_PAYMENT_COLLECTION: "最低發票金額A$30。",
  MINIMUM_INVOICE_AMOUNT_HK: "最低發票金額HK$80。",
  MINIMUM_INVOICE_AMOUNT_HK_PAYMENT_COLLECTION: "最低帳單金額為HK$50。",
  MINIMUM_INVOICE_AMOUNT_HK_USD: "最低發票金額US$10。",
  MINIMUM_INVOICE_AMOUNT_MY: "最低帳單金額為RM30。",
  MINIMUM_INVOICE_AMOUNT_MY_PAYMENT_COLLECTION: "最低帳單金額為RM100。",
  MINIMUM_INVOICE_AMOUNT_SG: "最低發票金額為新幣S$15。",
  MINIMUM_INVOICE_AMOUNT_SG_PAYMENT_COLLECTION: "最低帳單金額為S$30。",
  MINIMUM_INVOICE_AMOUNT_USD_PAYMENT_COLLECTION: "最低帳單金額為US$1.00。",
  MINIMUM_RENT_AMOUNT_AU: "最低租金金額為新幣A$200。",
  MINIMUM_RENT_AMOUNT_HK: "最低租金為HK$1,700。",
  MINIMUM_RENT_AMOUNT_HK_USD: "最低租金金額為US$200。",
  MINIMUM_RENT_AMOUNT_MY: "最低租金金額為RM500。",
  MINIMUM_RENT_AMOUNT_SING: "最低租金金額為新幣S$300。",
  MINIMUM_SALARY_AMOUNT_AU: "最低薪金金額為A$15。",
  MINIMUM_SALARY_AMOUNT_HK: "最低薪金金額為HK$80。",
  MINIMUM_SALARY_AMOUNT_HK_USD: "最低薪金金額為US$10。",
  MINIMUM_SALARY_AMOUNT_MY: "最低薪資金額為馬幣RM30。",
  MINIMUM_SALARY_AMOUNT_SG: "最低薪金額為S$15。",
  MINIMUM_SALARY_BUSINESS_AMOUNT_AU: "最低薪金金額為A$15。",
  MINIMUM_SALARY_BUSINESS_AMOUNT_HK: "最低薪金金額為HK$80。",
  MINIMUM_SALARY_BUSINESS_AMOUNT_HK_USD: "最低薪金金額為US$10。",
  MINIMUM_SALARY_BUSINESS_AMOUNT_MY: "最低薪資金額為馬幣RM30。",
  MINIMUM_SALARY_BUSINESS_AMOUNT_SG: "最低薪金額為S$15。",
  MISSING_INCORPORATION_DATE_ERROR: "國際支付需要註冊日期。",
  MISSING_REGION_OR_CITY_ERROR: "請為此付款選擇一個地區和城市。",
  MOBILE_NUMBER_ERROR: "請輸入你的手機號碼",
  MOBILE_NUMBER_PLACEHOLDER: "手機號碼",
  MONEY_TRANSFER_BUTTON: "轉賬",
  MORE_BUTTON: "更多",
  MULTIPLE_EMPLOYEES_ONE_CURRENCY:
    "你只能用\u003cb\u003e一種\u003c/b\u003e貨幣同時向多名僱員支付薪金。",
  MULTIPLE_PROPERTIES_ONE_CURRENCY:
    "你只能用\u003cb\u003e一種\u003c/b\u003e貨幣同時支付多個物業的租金。",
  MULTIPLE_RECIPIENTS: "多位收款者",
  MYINFO_ERROR_HERO: "我們無法從Myinfo存取你的資料。",
  MYINFO_ERROR_INSTRUCTIONS:
    "你需要驗證你的身分才能付款。如果你需要其他幫助，可以稍後訪問Myinfo門戶網站\u003ca1\u003emyinfo.gov.sg\u003c/a1\u003e重試或者聯絡\u003ca2\u003esupport@myinfo.gov.sg\u003c/a2\u003e。",
  MYINFO_HERO: "使用Myinfo驗證你的身份",
  MYINFO_INSTRUCTIONS:
    "通過Singpass連接Myinfo，你的個人資料將被自動存取，並且用以預先填寫相關欄目，從而使身份驗證更快，更方便。",
  MYINFO_LOGIN_BUTTON: "通過Singpass登錄",
  MYINFO_VERIFICATION_ADDRESS: "註冊地址",
  MYINFO_VERIFICATION_DOB: "出生日期",
  MYINFO_VERIFICATION_HERO: "請注意，你無法編輯存取自Myinfo的資料。",
  MYINFO_VERIFICATION_MOBILE: "手機號碼",
  MYINFO_VERIFICATION_NAME_LABEL: "姓名",
  MYINFO_VERIFICATION_NATIONALITY: "國籍",
  MYINFO_VERIFICATION_NRIC_LABEL: "國民身份證號/外國人身份號",
  MYINFO_VERIFICATION_PASSPORT_EXPIRY: "護照到期日",
  MYINFO_VERIFICATION_PASSPORT_NUMBER: "護照號碼",
  MYINFO_VERIFICATION_PASS_EXPIRY: "護照到期日",
  MYINFO_VERIFICATION_PASS_TYPE: "護照類型",
  MYINFO_VERIFICATION_RESIDENTIAL_STATUS: "居留身份",
  MYINFO_VERIFICATION_SEX: "性別",
  MYINFO_VERIRIFCATION_REQUIRED: "需要驗證Myinfo",
  MY_FEATURE_UNSUPPORTED_ERROR: "此功能不支持馬來西亞用戶",
  MY_LABEL_END_OF_DAY: "工作日結束之前",
  NET_AMOUNT_LABEL: "淨額",
  NEW_BADGE: "新產品",
  NEW_GREETING_NAVBAR: "你好，{render}!",
  NEW_LABEL_INVOICE_SCHEDULE_BENIFICIARY:
    "您的卡將在{render1}收取費用。 您的供應商將通過{render2}收到資金：{render3}",
  NEW_LABEL_INVOICE_SCHEDULE_BENIFICIARY_TODAY:
    "今天將從您的卡中扣款。 您的供應商將在{render1}收到資金：{render2}",
  NEW_LABEL_RENT_SCHEDULE_BENIFICIARY:
    "您的卡將在{render1}收取費用。 您的房東將在{render2}之前收到資金：{render3}",
  NEW_LABEL_RENT_SCHEDULE_BENIFICIARY_TODAY:
    "今天將從您的卡中扣款。 您的房東將在{render1}收到資金：{render2}",
  NEW_LABEL_SALARY_BUSINESS_SCHEDULE_BENIFICIARY:
    "您的卡將在{render1}收取費用。 您的資金將在{render2}之前存入您的商業銀行賬戶：{render3}",
  NEW_LABEL_SALARY_BUSINESS_SCHEDULE_BENIFICIARY_TODAY:
    "今天將從您的卡中扣款。 您的資金將在{render1}之前存入您的商業銀行賬戶：{render2}",
  NEW_LABEL_SALARY_SCHEDULE_BENIFICIARY:
    "您的卡將在{render1}收取費用。 您的員工將通過{render2}收到資金：{render3}",
  NEW_LABEL_SALARY_SCHEDULE_BENIFICIARY_TODAY:
    "今天將從您的卡中扣款。 您的員工將通過{render1}收到資金：{render2}",
  NEW_LABEL_TRANSACTION_SUCCESSFUL_RECEIPT_NUMBER: "帳單號碼：{render}",
  NEW_PASSWORD_PLACEHOLDER: "新密碼",
  NEW_TOTAL_HEADER: "新總金額",
  NEW_VERIFICATION_LINK:
    "你所使用的電郵驗證鏈接已經過期，請點擊下面的按鈕以獲得新的鏈接。",
  NEXT_BUTTON: "下一步",
  NOTE_CREATE_SUPPLIER_ABN_APPROVED:
    "您的ABN{regno}申請已獲批准。 現在將處理對該供應商的任何暫停付款。",
  NOTE_CREATE_SUPPLIER_ABN_DECLINED:
    "您的ABN{regno}申請已被拒絕。 請聯繫我們的實時聊天以獲取更多信息。",
  NOTE_CREATE_SUPPLIER_ABN_REQUEST:
    "目前正在審核您的ABN申請{regno}，這通常不會超過幾個工作小時。 請注意，在我們驗證您的申請請求時，將暫停向該供應商付款。",
  NOTE_CREATE_SUPPLIER_CRN_APPROVED:
    "您的CRN{regno}申請已獲批准。 現在將處理對該供應商的任何暫停付款。",
  NOTE_CREATE_SUPPLIER_CRN_DECLINED:
    "您的CRN{regno}申請已被拒絕。 請聯繫我們的實時聊天以獲取更多信息。",
  NOTE_CREATE_SUPPLIER_CRN_REQUEST:
    "目前正在審核您的CRN申請{regno}，這通常不會超過幾個工作小時。 請注意，在我們驗證您的申請請求時，將暫停向該供應商付款。",
  NOTE_CREATE_SUPPLIER_UEN_APPROVED:
    "您的UEN{regno}申請已獲批准。 現在將處理對該供應商的任何暫停付款。",
  NOTE_CREATE_SUPPLIER_UEN_DECLINED:
    "您的UEN{regno}申請已被拒絕。 請聯繫我們的實時聊天以獲取更多信息。",
  NOTE_CREATE_SUPPLIER_UEN_REQUEST:
    "目前正在審核您的UEN申請{regno}，這通常不會超過幾個工作小時。 請注意，在我們驗證您的申請請求時，將暫停向該供應商付款。",
  NOTE_LABEL: "員工須知",
  NOTE_PLACEHOLDER: "你僱員的銀行對賬單上出現的描述",
  NOTICE_OF_ASSESSMENT_LABEL:
    "評估通知書 \u003cspan\u003e或\u003c/span\u003e 帳號對賬單",
  NOTIFY_SUPPLIER_CHECKBOX: "你需要通知供應商你已經安排付款嗎？",
  NOT_AFFILIATED_CHECKBOX:
    "本人確認本人與該企業無任何關係，並且所有支付給該供應商的款項均為合法商品及服務。",
  NOT_INPROGRESS_PAYMENT_ERROR: "此付款狀態不在進行中",
  NO_CHECKBOX: "沒有",
  NO_PIN_RECEIVED: "還未收到PIN碼？",
  NRIC_LABEL: "國民身份證號碼",
  NRIC_PLACEHOLDER: "僱員國民身份證號碼",
  NUMBER_OF_MOBILE_VERIFICATION_ATTEMPTS_EXCEEDED:
    "手提電話驗證的次數被取消。請聯繫{contact_link}取得協助。",
  NUMBER_OF_MOBILE_VERIFICATION_ATTEMPTS_EXCEEDED_ERROR:
    "超過了移動驗證的嘗試次數。請在5分鐘內再試一次。",
  ONLY_MALAYSIA_ISSUER: "請使用有效的馬來西亞信用卡。",
  ONLY_MALAYSIA_ISSUER_MASTERCARD: "我們目前只接受馬來西亞發行的Mastercard。",
  ONLY_VISA_MASTERCARD: "我們目前只接受Visa和Mastercard。",
  ONLY_VISA_MASTERCARD_AMEX: "我們目前只接受Visa、Mastercard和Amex。",
  ONLY_VISA_MASTERCARD_UNIONPAY: "我們目前只接受Visa、Mastercard和UnionPay。",
  ONLY_VISA_MASTERCARD_UNIONPAY_AMEX:
    "我們目前只接受Visa、Mastercard、UnionPay和Amex。",
  OPTIONAL_NOTE_TO_SUPPLIER: "供應商須知（可選）",
  ORDER_NUMBER_LABEL: "訂單號",
  OVERDUE_PAYMENT: "您的付款已超過發票到期日。",
  PASSWORD_CONFIRM_DOES_NOT_MATCH: "密碼不匹配",
  PASSWORD_MINIMUM_ERROR:
    "請選擇至少由8個字符組成的高强度密碼，包括字母、數字和符號。",
  PASSWORD_PLACEHOLDER: "請輸入密碼",
  PASSWORD_RESET_INSTRUCTIONS: "我們將向您發送重置密碼的說明。",
  PAYEE_ALREADY_EXISTS: "您已添加此收件人。",
  PAYEE_BANK_ACCOUNT_HOLDER: "銀行賬戶持有人姓名",
  PAYEE_DETAILS_BANK_ACCOUNT: "帳戶",
  PAYEE_DETAILS_BANK_NAME: "銀行",
  PAYEE_EMPLOYEE: "僱員",
  PAYEE_NOT_FOUND_ERROR:
    "我們找不到您的收款人。 請通過support@ipaymy.com聯繫我們了解詳情。",
  PAYEE_PROPERTY: "物業地址",
  PAYEE_SUPPLIER: "供應商",
  PAYER_EXIST: "該客戶已經存在。",
  PAYING: "支付",
  PAYMENTS_ACCOUNT_HEADER: "帳號",
  PAYMENTS_BANK_HEADER: "銀行",
  PAYMENTS_CARD_LABEL: "已被扣款的信用卡",
  PAYMENTS_COMPANY_LABEL: "公司",
  PAYMENTS_DESCRIPTION: "查看你的付款詳情和狀態，或安排新的付款。",
  PAYMENTS_DESCRIPTION_LABEL: "說明",
  PAYMENTS_EXCHANGE_RATE_LABEL: "匯率",
  PAYMENTS_HERO: "我的付款",
  PAYMENTS_MENU_ITEM: "付款",
  PAYMENTS_NOTE_TRANSFER: "付款參考",
  PAYMENTS_PROMOTIONS_ITEM: "推廣",
  PAYMENTS_PURPOSE_INVOICE: "賬單付款",
  PAYMENTS_PURPOSE_RENTAL: "租金付款",
  PAYMENTS_PURPOSE_SALARY: "薪金付款",
  PAYMENTS_PURPOSE_TRANSFER: "匯款",
  PAYMENTS_RECEIPT_HEADER: "帳單號碼",
  PAYMENTS_RECEIPT_LABEL: "帳單",
  PAYMENTS_TOTAL_LABEL: "總金額",
  PAYMENTS_VIEW_BUTTON: "查看",
  PAYMENT_ARRIVE_LABEL: "你的付款將在(SGT)到達",
  PAYMENT_BLOCKED_ERROR:
    "ipaymy不支持用此卡支付。你可以聯繫我們以獲得更多資訊。",
  PAYMENT_CANCELLED_DESCRIPTION:
    "哎呀! 看起來這個付款請求已被\u003cspan\u003ecompany name\u003c/span\u003e取消。{br}你無需採取進一步行動。",
  PAYMENT_CANCELLED_HERO: "付款請求已取消",
  PAYMENT_CARD_UNSUPPORTED_3DS_ERROR:
    "ipaymy不支持用此卡支付。你可以聯繫我們以獲得更多資訊。",
  PAYMENT_COLLECTION_CARD_HEADER: "輸入信用卡信息",
  PAYMENT_COLLECTION_FEE_TOOLTIP: "這是商家接受信用卡付款所收取的手續費。",
  PAYMENT_COLLECTION_HERO: "付款概覽",
  PAYMENT_COLLECTION_PAY_INVOICE_BUTTON: "立即支付帳單",
  PAYMENT_COLLECTION_PCI:
    "ipaymy是一個安全的平台。我們所有的交易都是通過行業領先的PCI兼容網絡的加密處理，以保護您的私隱。",
  PAYMENT_CONFIRMATION_CONTACT: "如有疑問，請聯繫我們",
  PAYMENT_CONFIRMATION_GO_TO_IPAYMY:
    "\u003ca\u003e前往ipaymy\u003c/a\u003e\u003cp\u003e開始使用ipaymy的一站式現金流管理平台。\u003c/p\u003e",
  PAYMENT_CONFIRMATION_LEARN: "了解更多關於",
  PAYMENT_CONFIRMATION_RECEIPT: "帳單號碼",
  PAYMENT_CONFIRMATION_REDIRECT_MESSAGE:
    "如果你在10秒內未重定向到{render}，\u003ca\u003e點擊此處\u003c/a\u003e。",
  PAYMENT_CONFIRMATION_SUCCESS: "支付成功",
  PAYMENT_CONFIRMATION_THANKS:
    "感謝你透過ipaymy付款，你的帳單已經發送到你的電子郵箱，內附詳細付款資料。",
  PAYMENT_CONFIRMATION_TRY:
    "\u003ca\u003e現在就試試ipaymy\u003c/a\u003e\u003cp\u003e今天就開始使用你的銀行卡來支付{br}租金、工資、發票和更多的費用。\u003c/p\u003e",
  PAYMENT_CONFIRMATION_TRY_BUTTON: "現在試用ipaymy",
  PAYMENT_DESCRIPTION_INVALID_AMEX:
    "請細化您的付款說明以供Amex使用，或保留您的原始付款說明並選擇其他卡品牌。",
  PAYMENT_DESCRIPTION_LABEL: "付款信息",
  PAYMENT_DETAILS_HERO: "付款詳情",
  PAYMENT_DETAILS_TOOLTIP: "點擊此處查看詳情。",
  PAYMENT_FAILED_EMAIL_REASON:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過support@ipaymy.com與我們聯繫。",
  PAYMENT_FAILED_HERO: "哎呀! 付款失敗。",
  PAYMENT_INTEREST_BE_PAID:
    "\u003cb\u003e收款\u003c/b\u003e收款客人的信用卡款項",
  PAYMENT_INTEREST_BOTH: "兩者皆是",
  PAYMENT_INTEREST_ERROR: "請選擇至少一項。",
  PAYMENT_INTEREST_INSTRUCTIONS:
    "為了給你提供更個性化的體驗，我們想知道你對ipaymy哪項服務感興趣？",
  PAYMENT_INTEREST_PAY: "\u003cb\u003e付款\u003c/b\u003e用我的信用卡支付費用",
  PAYMENT_MAKE_ALREADY_DESCRIPTION: "你已經完成了這筆付款。",
  PAYMENT_MAKE_ALREADY_HERO: "已完成付款",
  PAYMENT_MAKE_ALREADY_LOGIN_BUTTON: "登入",
  PAYMENT_MAKE_ALREADY_THANKS: "謝謝你通過ipaymy付款。",
  PAYMENT_OVERDUE_HERO: "逾期付款",
  PAYMENT_OVERDUE_MESSAGE: "此付款已過期，付款鏈接不再可用。",
  PAYMENT_PAID_DATE: "付款日期",
  PAYMENT_PAID_HERO: "標記為已支付",
  PAYMENT_PAID_ORDER_NUMBER: "訂單號碼",
  PAYMENT_PROCESSED_ERROR: "付款已被處理。",
  PAYMENT_REFERENCE_LABEL: "付款參考",
  PAYMENT_REQUEST_FAILED:
    "\u003cspan1\u003e提示：\u003c/span1\u003e付款請求（帳單號碼 {render1}）發送失敗。檢查並更新你客戶的{render2}\u003cspan2\u003e請點擊這裡\u003c/span2\u003e。",
  PAYMENT_SUCCESSFUL_HERO: "太好了！付款成功！",
  PAYMENT_SUCCESSFUL_INSTRUCTIONS: "你的信用卡已成功被扣款。你的付款將在",
  PAYMENT_SUMMARY_LABEL: "付款摘要",
  PAYMENT_TECHNICAL_ISSUE_ERROR:
    "您的付款存在技術問題。 您可以隨時聯繫我們了解更多詳情。",
  PAYMENT_TO_LABEL: "支付給{value}",
  PAYSLIP_EMPLOYEE_ID: "僱員身分證件",
  PAYSLIP_EMPLOYEE_ID_HKID: "僱員的身份證號碼（即香港身份證號碼）",
  PAYSLIP_EMPLOYEE_ID_MYKAD: "僱員的身份證號碼（即MyKad號碼）",
  PAYSLIP_EMPLOYEE_ID_NRIC: "僱員的身份證號碼（即NRIC號碼）",
  PAYSLIP_EMPLOYEE_NAME: "僱員姓名",
  PAYSLIP_LABEL: "\u003cspan\u003e糧單或僱傭合同\u003c/span\u003e必須包括",
  PAY_BROWSER_WARNING: "在付款完成之前，請避免關閉此瀏覽器窗口或刷新此頁面。",
  PAY_BY_LABEL: "支付方式",
  PAY_CALENDAR_SUBTITLE: "本月付款的總金額",
  PAY_CALENDAR_TITLE: "已支付款項",
  PAY_FEATURE_DISABLED_ERROR:
    "在我們對產品進行一些改進的時候，付款暫時停用。謝謝你的耐心等候。我們會在重啓後儘快通知你。",
  PAY_IN_LABEL: "\u003cb\u003e支付\u003c/b\u003e",
  PAY_IN_NOW_LABEL: "\u003cb\u003e付款\u003c/b\u003e並立即向收款人付款",
  PAY_LABEL: "付款",
  PAY_MENU_ITEM: "支付",
  PAY_NEED_HELP_LABEL: "需要幫助？",
  PAY_NOW_BUTTON: "立即支付",
  PAY_RECOMMENDED_ACTIONS_SECTION: "\u003cb\u003e建議\u003c/b\u003e行動",
  PAY_TAX_TOOLTIP: "點擊此處繳稅",
  PAY_WAITING_TOO_LONG: "等太久？ 單擊此處重試。",
  PERSONAL_UNAVAILABLE: "目前{br}不可用",
  POPUP_ACCEPT_PAYMENTS_BODY:
    "立即使用ipaymy的\u003cstrong\u003eBe Paid\u003c/strong\u003e{br}收款服務向客戶發送電子帳單，即可享受0%手續費的{br}{render}收款額。",
  POPUP_AWESOME_PAYMENTS_BODY:
    "太好啦，免費收款額來啦! 稍後你將收到一封電郵，內容會有更多相關細節。 ",
  POPUP_BE_PAID_BUTTON: "今天立即收款",
  POPUP_CLAIM_BUTTON: "領取優惠",
  POPUP_CONGRATULATIONS_BODY:
    "恭喜，你合資格獲得\u003cstrong\u003e...\u003c/strong\u003e的免費收款額! 你想現在領取這個限時優惠？ ",
  POPUP_DASHBOARD_BODY:
    "你是否想改善你的控制界面，以便管理客戶的非接觸式付款？",
  POPUP_DASHBOARD_HERO: "改善你的控制界面",
  POPUP_EASY_PAYMENTS_BODY: "簡單、快捷、免費的信用卡收款直接存入銀行賬戶。",
  POPUP_FREE_PAYMENTS_HERO:
    "我們想要\u003cspan2\u003e免費\u003c/span2\u003e送你{br}\u003cspan1\u003e...\u003c/span1\u003e!",
  POPUP_GOT_IT_BUTTON: "明白",
  POPUP_NOT_NOW_BUTTON: "暫不需要",
  POPUP_NO_THANKS_BUTTON: "不，謝謝",
  POPUP_START_ACCEPTING_BODY:
    "立即使用ipaymy的\u003cstrong\u003eBe Paid\u003c/strong\u003e{br}收款服務向客戶發送電子帳單，即可享受0%手續費的{br}{render}收款額。",
  POPUP_YES_BUTTON: "是的",
  POSTAL_CODE_LABEL: "郵政編碼",
  POSTAL_CODE_PLACEHOLDER: "郵政編碼",
  PO_NUMBER_LABEL: "採購單號碼",
  PO_NUMBER_PLACEHOLDER: "採購單號碼",
  PROGRAM_ENTERED_EARLY_ERROR: "早前已登入計劃",
  PROGRAM_NOT_FOUND: "找不到程序",
  PROMO_CODE_PAYMENT_ROUTING_FAILED:
    "這筆付款出了點問題。 請通過右下角的實時聊天與我們聯繫。",
  PROPERTIES_HERO: "我的物業",
  PROPERTIES_MENU_ITEM: "物業",
  PROPERTY_ACCOUNT_NUMBER_HEADER: "帳號",
  PROPERTY_ADDRESS_HEADER: "物業地址",
  PROPERTY_AMOUNT_HEADER: "金額",
  PROPERTY_BANK_HEADER: "銀行",
  PROPERTY_COUNTRY_HEADER: "國家",
  PROPERTY_DELETE_HERO: "你想刪除這個物業嗎？",
  PROPERTY_DELETE_INSTRUCTIONS: "此物業將從你的物業中刪除",
  PROPERTY_LANDLORD_HEADER: "業主",
  PROPERTY_NOTE_HEADER: "業主須知",
  PROPERTY_SCHEDULED_PAYMENT_HERO: "此物業與一筆定期付款有關。",
  PROPERTY_SCHEDULED_PAYMENT_INSTRUCTIONS:
    "在編輯物業資料之前，你需要先取消相關的付款。",
  PURPOSE_BANK_LABEL: "銀行賬戶",
  PURPOSE_COLLECTION: "收款",
  PURPOSE_EMPLOYEE_LABEL: "僱員",
  PURPOSE_INTERNATIONAL: "國際付款",
  PURPOSE_INVOICE: "賬單",
  PURPOSE_MONEY_TRANSFER: "轉賬",
  PURPOSE_PARTNERSHIP: "合作夥伴",
  PURPOSE_PROPERTY_LABEL: "物業",
  PURPOSE_RENT: "租金",
  PURPOSE_SALARY: "薪金",
  PURPOSE_SALARY_BUSINESS: "工資業務",
  PURPOSE_SUPPLIER_LABEL: "供應商",
  REFERRALS_MENU_ITEM: "推薦",
  REFERRAL_BUSINESS_LABEL: "商業推薦人數",
  REFERRAL_BUSINESS_NOTE:
    "每成功推荐一個商業賬戶，雙方都將收到一個價值為{render}的促銷代碼。",
  REFERRAL_BUSINESS_TOOLTIP: "指成功支付合格款項的商業賬戶推薦人總數。",
  REFERRAL_CODE_ERROR: "請輸入你的推薦碼",
  REFERRAL_CODE_PLACEHOLDER: "推薦碼",
  REFERRAL_COPIED_LABEL: "已複製",
  REFERRAL_COPY_LABEL: "複製",
  REFERRAL_COPY_LINK: "複製以下鏈接，分享給你的推薦人。",
  REFERRAL_ENJOY_STEP: "享受獎勵",
  REFERRAL_HERO:
    "每次推薦都能賺取高達\u003cstrong\u003e...\u003c/strong\u003e。",
  REFERRAL_INSTRUCTIONS:
    "每有一位合格的推薦人使用ipaymy支付，我們就會給你們兩位一個價值\u003cstrong\u003e...\u003c/strong\u003e的促銷代碼。*",
  REFERRAL_JOIN_STEP: "立即加入",
  REFERRAL_PERSONAL_LABEL: "個人推薦人數",
  REFERRAL_PERSONAL_NOTE:
    "每成功推荐一個個人賬戶，雙方都將收到一個價值為{render}的促銷代碼。",
  REFERRAL_PERSONAL_TOOLTIP: "指已成功支付合格款項的個人賬戶推薦人總數。",
  REFERRAL_SHARE_STEP: "分享你的鏈接",
  REFERRAL_SIGNUPS_LABEL: "註冊人數",
  REFERRAL_SIGNUPS_TOOLTIP: "這是指從你的獨特推薦鏈接中新註冊的總人數。",
  REFERRAL_TCS_1: "你的推薦人必須是一個新用戶，並且之前沒有註冊過ipaymy賬戶。",
  REFERRAL_TCS_2:
    "要成功推薦，你的推薦人需要在ipaymy上完成合格的支付。請點擊\u003ca\u003e這裡\u003c/a\u003e，了解更多關於我們接受的付款類型。",
  REFERRAL_TCS_3: "推薦的促銷代碼有效期為自發布之日起30天。",
  REFERRAL_TCS_4: "ipaymy保留不向擁有多個賬戶的用戶頒發獎勵的權利。",
  REFERRAL_TCS_LABEL: "條款和條件適用",
  REFERRAL_TCS_VIEW:
    "查看完整的條款和條件請點擊 \u003ca\u003e這裡\u003c/a\u003e。",
  REFER_FRIEND_BODY:
    "對於你推薦的每個使用ipaymy完成合格支付的個人{br}或商業用戶，我們都會給你們雙方促銷代碼，{br}其價值可高達\u003cb\u003e{amount}\u003c/b\u003e！",
  REFER_FRIEND_HERO:
    "推薦朋友或公司，\u003cb\u003e獲取高達{br}{amount}的獎勵！\u003c/b\u003e",
  REFUNDED_AMOUNT_HEADER: "退款金額",
  REFUNDED_FEE_HEADER: "退費",
  REGION_LABEL: "地區",
  REGION_PLACEHOLDER: "地區",
  REGISTER_COMPANY_TOOLTIP: "請先註冊一間公司。",
  REGISTER_FIRST: "請先註冊公司",
  REGISTRATION_GRAPHIC_ACCOUNT_TYPE_BUSINESS: "商業用戶",
  REGISTRATION_GRAPHIC_ACCOUNT_TYPE_PERSONAL: "個人用戶",
  REGISTRATION_GRAPHIC_BUSINESS_LINE_1:
    "支付營運開支，如辦公室租金、工資、廠商發票等",
  REGISTRATION_GRAPHIC_BUSINESS_LINE_2: "向顧客發送單據和收取信用卡付款",
  REGISTRATION_GRAPHIC_BUSINESS_LINE_3: "先進操作業面，管理應收帳款/應付帳款",
  REGISTRATION_GRAPHIC_BUSINESS_LINE_4: "專屬客戶服務經理",
  REGISTRATION_GRAPHIC_BUSINESS_LINE_5: "尊享商業用戶優惠價格",
  REGISTRATION_GRAPHIC_BUSINESS_SIGNUP: "商業用戶註冊",
  REGISTRATION_GRAPHIC_FOOTNOTE: "*因應國家、銀行及信用卡類別而調整。",
  REGISTRATION_GRAPHIC_HERO: "選擇適合你的帳戶",
  REGISTRATION_GRAPHIC_PERSONAL_LINE_1: "支付個人開支，如房租、學費、管理費等",
  REGISTRATION_GRAPHIC_PERSONAL_LINE_2: "簡易操作業面，管理每月開支",
  REGISTRATION_GRAPHIC_PERSONAL_LINE_3: "尊享信用卡特別優惠",
  REGISTRATION_GRAPHIC_PERSONAL_SIGNUP: "個人用戶註冊",
  REGISTRATION_NO_COMPANY_NUMBER: "我目前尚未有公司註冊編號。",
  REGISTRATION_NUMBER_COLUMN: "登記號碼",
  REGISTRATION_NUMBER_LABEL: "商業登記號碼",
  REGISTRATION_NUMBER_PLACEHOLDER: "商業登記號碼",
  REMOVE_BUTTON: "刪除",
  RENTAL_AMOUNT_LABEL: "租金金額",
  RENT_BUTTON: "租金",
  REQUEST_ANOTHER_PAYMENT_BUTTON: "申請另一種支付方式",
  REQUEST_CONFIRM_EMAIL_LABEL: "確認新電子郵件",
  REQUEST_CONFIRM_EMAIL_PLACEHOLDER: "確認新電子郵件",
  REQUEST_CONFIRM_MOBILE_LABEL: "確認手機號碼",
  REQUEST_CONFIRM_MOBILE_PLACEHOLDER: "請確認新手機號碼",
  REQUEST_NEW_EMAIL_LABEL: "新電子郵件 ",
  REQUEST_NEW_EMAIL_PLACEHOLDER: "新電子郵件 ",
  REQUEST_NEW_MOBILE_LABEL: "新手機號碼",
  REQUEST_NEW_MOBILE_PLACEHOLDER: "新手機號碼",
  REQUEST_OLD_EMAIL_LABEL: "舊電子郵件 ",
  REQUEST_OLD_MOBILE_LABEL: "舊手機號碼 ",
  REQUEST_PAYMENT_BUTTON: "發送收款要求",
  REQUEST_PAYMENT_HERO: "\u003cb\u003e要求收取\u003c/b\u003e款項",
  REQUEST_RESEND_BUTTON: "重新發送付款請求",
  REQUEST_RESENT: "你的收款要求已重發給你的客戶。",
  REQUEST_RESENT_EMAIL: "你的收款要求已重新發送至更新的電郵地址。",
  REQUEST_RESENT_MOBILE: "你的收款要求已重新發送至更新的手機號碼。",
  REQUEST_SENT_COPIED_CONFIRMATION: "已複製",
  REQUEST_SENT_COPY_BUTTON: "複製",
  REQUEST_SENT_HERO: "耶！請求成功發送!",
  REQUEST_SENT_INSTRUCTIONS:
    "你的支付請求已經發送至你的{render}。一旦你收到付款，你將收到一封電子郵件通知。",
  REQUEST_SENT_LINK: "複製以下鏈接，並與你的{render}分享",
  REQUEST_TO_LIST_NEW_COMPANY: "申請上市新公司",
  REQUEST_UPDATE_EMAIL_HERO: "更新客戶的電子郵件",
  REQUEST_UPDATE_MOBILE_HERO: "更新客戶的手機號碼",
  REQUIRED_DOCUMENT_CAPITAL: "注資",
  REQUIRED_DOCUMENT_FUNDS: "業務營運所需資金",
  REQUIRED_DOCUMENT_HEADER: "所需文件",
  REQUIRED_DOCUMENT_MESSAGE:
    "請上傳一份關於向 貴公司海外實體轉移資金目的的聲明。此聲明應以 貴公司信頭的信紙撰寫。{br}可接受的用途舉例如下：",
  REQUIRED_FIELD: "這是必填欄。",
  REQUIRED_FIELD_EMAIL: "需要一封電郵",
  REQUIRED_FIELD_MOBILE_NUMBER: "需要手機號碼",
  REQUIRED_FIELD_PASSWORD: "需要密碼",
  RESEND_EMAIL_BUTTON: "重新發送電子郵件",
  RESEND_PIN: "重新發送驗證碼",
  RESEND_VERIFICATION_EMAIL: "重新發送驗證電郵",
  RESENT_PIN: "我們已經向你發送了一個新的PIN碼，可能需要幾分鐘時間。",
  RESET_EMAIL_ADDRESS:
    "輸入你註冊時使用電郵地址，我們將向你發送重置密碼的指令。",
  RESET_INSTRUCTIONS: "重置密碼的指引已經發送給你。請檢查你的電郵。",
  RESET_PASSWORD_BUTTON: "重置密碼",
  RESET_PASSWORD_LINK: "重置密碼",
  RESTRICTED_BUSINESS_INSTRUCTIONS:
    '請確認你所選擇的業務不屬於"限制業務"的範圍',
  RESTRICTED_FINANCIAL_EXAMPLE_1: "投資和信貸服務",
  RESTRICTED_FINANCIAL_EXAMPLE_2: "資金和法律服務",
  RESTRICTED_FINANCIAL_EXAMPLE_3: "虛擬貨幣或儲值",
  RESTRICTED_FINANCIAL_HEADER: "金融和專業服務，如",
  RESTRICTED_HIGH_RISK_EXAMPLE_1: "破產律師",
  RESTRICTED_HIGH_RISK_EXAMPLE_2: "電腦技術支援和信息技術服務台",
  RESTRICTED_HIGH_RISK_EXAMPLE_3: "靈異服務",
  RESTRICTED_HIGH_RISK_EXAMPLE_4: "旅行預訂服務和俱樂部",
  RESTRICTED_HIGH_RISK_EXAMPLE_5: "航空公司、機票和郵輪",
  RESTRICTED_HIGH_RISK_EXAMPLE_6: "時鐘租房",
  RESTRICTED_HIGH_RISK_EXAMPLE_7: "預付電話卡、電話服務和手提電話",
  RESTRICTED_HIGH_RISK_EXAMPLE_8: "電話營銷、電信設備和電話銷售",
  RESTRICTED_HIGH_RISK_EXAMPLE_9: "取消抵押品贖回權和身份盜竊保護",
  RESTRICTED_HIGH_RISK_HEADER: "高風險企業，如",
  RESTRICTED_IP_EXAMPLE_1: "成人內容和服務",
  RESTRICTED_IP_EXAMPLE_2: "偽造或未經授權的貨物",
  RESTRICTED_IP_EXAMPLE_3: "代寫學術論文",
  RESTRICTED_IP_EXAMPLE_4: "賭博",
  RESTRICTED_IP_EXAMPLE_5: "侵犯知識產權或專有權利",
  RESTRICTED_IP_EXAMPLE_6: "受管製或非法產品或服務",
  RESTRICTED_IP_HEADER: "侵犯知識產權、受管製或非法產品和服務，如",
  RESTRICTED_OTHER_EXAMPLE_1: "集會",
  RESTRICTED_OTHER_EXAMPLE_2: "毒品用具，仿製毒品及藥物",
  RESTRICTED_OTHER_EXAMPLE_3: "大使館、領事館、其他外國政府",
  RESTRICTED_OTHER_EXAMPLE_4: "多層次營銷",
  RESTRICTED_OTHER_EXAMPLE_5: "用於解密、解擾或干擾信號的產品",
  RESTRICTED_OTHER_EXAMPLE_6: "社交媒體活動（即粉絲、點讚等的銷售）",
  RESTRICTED_OTHER_EXAMPLE_7: "電子遊戲或虛擬世界積分",
  RESTRICTED_OTHER_EXAMPLE_8:
    "任何企業或組織，如果a.從事、鼓勵、促進或慶祝對人身或財產的非法暴力或人身傷害，或b.從事、鼓勵、促進或慶祝基於種族、宗教、殘疾、性別、性取向、民族血統或任何其他不可改變的特徵的非法暴力",
  RESTRICTED_OTHER_HEADER: "其他產品或服務包括",
  RESTRICTED_QUESTIONS:
    "如果你不清楚你的業務能否使用ipaymy的收款服務，請\u003ca\u003e與我們聯繫。\u003c/a\u003e",
  RESTRICTED_UNFAIR_EXAMPLE_1: "快速致富計劃",
  RESTRICTED_UNFAIR_EXAMPLE_2: "方便發布和刪除內容的平台",
  RESTRICTED_UNFAIR_EXAMPLE_3: "無增值服務",
  RESTRICTED_UNFAIR_HEADER: "不公平、掠奪性或欺騙性行爲，如",
  RETRY_BUTTON: "重試",
  REVIEW_DETAILS_HERO: "查看詳情並發送收款要求",
  SALARIES_BUTTON: "薪金",
  SALARY_LABEL: "薪金",
  SAVE_AND_EXIT_BUTTON: "保存和退出",
  SAVE_BUTTON: "保存更改",
  SAVING_AND_EXITING_BUTTON: "保存和退出...",
  SCHEDULED_ACTIVE_PAYMENTS_BUTTON: "有效付款",
  SCHEDULED_AMOUNT_HEADER: "金額",
  SCHEDULED_ARCHIVED_PAYMENTS_BUTTON: "存檔付款",
  SCHEDULED_AUTHORIZED_BODY:
    "在授權過程中，您可能會在銀行對帳單上看到{br}一筆{render}待處理的費用。{br}請注意，這只是暫時的，隨後會被{br}自動撤銷。",
  SCHEDULED_AUTHORIZED_HERO: "太好了！付款已被授權",
  SCHEDULED_AUTHORIZED_INSTRUCTIONS:
    "你將在30分鐘內收到一封\u003cb\u003e確認電郵\u003c/b\u003e 及包括付款全額的\u003cb\u003e帳單\u003c/b\u003e。",
  SCHEDULED_BANK_LABEL: "銀行",
  SCHEDULED_CANCEL_HERO: "你確定要取消這個定期付款嗎？",
  SCHEDULED_CANCEL_INSTRUCTIONS:
    "如果你取消的話，你可能無法再享受這個優惠價格。",
  SCHEDULED_CARD_HEADER: "卡",
  SCHEDULED_CATEGORY_HEADER: "種類",
  SCHEDULED_CHARGE_DATE_HEADER: "扣賬日期",
  SCHEDULED_COPY_TOOLTIP:
    "開始日期和結束日期是你的收款人將收到款項的第一天和最後一天。你的信用卡將在這些日期前一個工作日被扣除款項。",
  SCHEDULED_DESCRIPTION: "查看和管理你的計劃支付",
  SCHEDULED_EDIT_ALL_HERO:
    "編輯\u003cb\u003e所有即將到來的付款\u003c/b\u003e的細節",
  SCHEDULED_EDIT_COUPON_LABEL: "優惠券代碼",
  SCHEDULED_EDIT_ERROR_MESSAGE: "您的付款詳情尚未成功更新。請再試一次。",
  SCHEDULED_EDIT_ONE_HERO: "編輯\u003cb\u003e此筆付款\u003c/b\u003e的細節",
  SCHEDULED_EDIT_SELECT_CARD: "選擇信用卡",
  SCHEDULED_END_HEADER: "結束日期",
  SCHEDULED_FEE_HEADER: "費用",
  SCHEDULED_FREQUENCY_HEADER: "頻率",
  SCHEDULED_HERO: "定期付款",
  SCHEDULED_MENU_ITEM: "計劃",
  SCHEDULED_MULTIPLE_RECIPIENTS: "多名收款人",
  SCHEDULED_NEW_PAYMENT_BUTTON: "安排新的付款",
  SCHEDULED_NEXT_PAYMENT_BUTTON: "查看下一筆付款",
  SCHEDULED_NOTE_SUPPLIER_HEADER: "供應商須知",
  SCHEDULED_NO_BUTTON: "取消",
  SCHEDULED_PAYEE_INVOICE_LABEL: "供應商",
  SCHEDULED_PAYEE_RENTAL_LABEL: "物業地址",
  SCHEDULED_PAYEE_SALARY_LABEL: "僱員",
  SCHEDULED_PAYMENTS_BUTTON: "定期付款",
  SCHEDULED_PAYMENT_DETAILS_HERO: "付款詳情",
  SCHEDULED_PAYOUT_DATE_HEADER: "付款日期",
  SCHEDULED_RECIPIENT_HEADER: "收款人",
  SCHEDULED_START_HEADER: "開始日期",
  SCHEDULED_STATUS_HEADER: "狀態",
  SCHEDULED_TOTAL_HEADER: "總數",
  SCHEDULED_UPDATED_MESSAGE: "你的付款資料已成功更新。",
  SCHEDULED_YES_BUTTON: "確定",
  SCHEDULE_END_DATE_LABEL: "結束日期",
  SCHEDULE_FREQUENCY_LABEL: "頻率",
  SCHEDULE_FREQUENCY_MONTHLY_ITEM: "每月",
  SCHEDULE_FREQUENCY_PLACEHOLDER: "選擇付款頻率",
  SCHEDULE_FREQUENCY_WEEKLY_ITEM: "每星期",
  SCHEDULE_PAYMENT_BUTTON: "安排付款",
  SCHEDULE_PAYMENT_HERO: "\u003cb\u003e安排\u003c/b\u003e支付",
  SCHEDULE_START_DATE_LABEL: "開始日期",
  SCHEDULE_TYPE_LABEL: "付款類型",
  SCHEDULE_TYPE_ONE_TIME_ITEM: "單次付款",
  SCHEDULE_TYPE_RECURRING_ITEM: "定期付款",
  SEARCH_BANK_NO_RESULTS:
    "找不到匹配的銀行。 嘗試其他搜索，或與我們聯繫以獲取支持。",
  SEARCH_BANK_PLACEHOLDER: "銀行名稱，分行或BSB",
  SEARCH_COMPANY_NAME_LABEL: "公司名稱查詢",
  SEARCH_COMPANY_NAME_PLACEHOLDER: "公司名稱",
  SEARCH_COMPANY_NO_RESULTS:
    "找不到匹配的公司。 嘗試其他搜索，或與我們聯繫以獲取支持。",
  SELECT_ACCOUNT_CREATED: "成功建立賬戶",
  SELECT_COMPANY_INSTRUCTIONS: "選擇你想收取款項的公司",
  SELECT_COMPANY_PLACEHOLDER: "選擇公司",
  SELECT_CUSTOMER_HERO: "選擇客戶",
  SELECT_EMPLOYEE_HERO: "選擇你的僱員",
  SELECT_PROPERTY_HERO: "選擇你的物業",
  SEND_BUTTON: "發送",
  SEND_BUTTON_TOOLTIP: "請輸入所有收款要求的細節。",
  SEND_INVOICE_HERO: "發出帳單",
  SEND_PASSWORD_REQUEST_BUTTON: "發送請求密碼指指引",
  SETTINGS_ACCOUNT_AU_DESCRIPTION: "這必須是在澳大利亞接受澳元的銀行賬戶。",
  SETTINGS_ACCOUNT_HK_DESCRIPTION: "這必須是接受港幣的香港銀行賬戶。",
  SETTINGS_ACCOUNT_MY_DESCRIPTION: "必須是接受馬幣的馬來西亞銀行賬戶。",
  SETTINGS_ACCOUNT_NUMBER: "帳戶號碼",
  SETTINGS_ACCOUNT_SG_DESCRIPTION: "必須是接受新幣的新加坡銀行賬戶。",
  SETTINGS_BANK_LABEL: "銀行",
  SETTINGS_BANK_STATEMENT_LABEL: "銀行對賬單",
  SETTINGS_EMAIL_DESCRIPTION:
    "請提供電郵地址以便收取所有有關存入你賬戶的款項資訊",
  SETTINGS_FEE_CHANGE_HERO: "更改的手續費分佈設定僅適用於新的收款要求。",
  SETTINGS_FEE_CHANGE_INSTRUCTIONS: "目前待收款項的手續費不會變更。",
  SETTINGS_FEE_CHANGE_OKAY_BUTTON: "好",
  SETTINGS_HERO: "帳戶設定",
  SETTINGS_MENU_ITEM: "設置",
  SETTINGS_SUCCESS: "你的賬戶設定已成功更新。",
  SETTING_BANK_CHANGE_MESSAGE:
    "如需更新您的銀行賬戶資訊，請透過\u003cstyling1\u003esupport@ipaymy.com\u003c/styling1\u003e 聯繫我們",
  SETTING_BANK_DESCRIPTION: "你希望將款項存入的銀行賬戶",
  SIGNIN_BUTTON: "登入",
  SIGNIN_ERROR: "電郵地址/密碼無效，請重新輸入。",
  SIGNIN_HERO: "登入",
  SIGNIN_LINK: "登入",
  SIGNIN_SIGNUP_OPTION: "或{SIGNIN_LINK}",
  SIGNUP_BUSINESS: "商業用戶",
  SIGNUP_BUTTON: "註冊",
  SIGNUP_CLOSE: "關閉",
  SIGNUP_HERO: "註冊",
  SIGNUP_PERSONAL: "個人用戶",
  SIGNUP_SIGNIN_OPTION: "或\u003cstyling1\u003e註冊\u003c/styling1\u003e。",
  SIGNUP_SUCCESSFUL: "註冊成功",
  SIGNUP_THANKS:
    "感謝你的註冊! 我們已經向你發送了一封電郵，並提供進一步的驗證細節。請檢查你的收件箱及啟用你的帳戶!",
  SMS_MENU_ITEM: "短訊收款要求",
  SMS_REQUEST_BUTTON: "短訊收款要求",
  SMS_REQUEST_HEADER: "輸入你的客戶資料",
  SMS_REQUEST_HERO: "輸入詳細資訊並發送",
  SMS_REQUEST_HERO_PAYMENT_INVOICE: "帳單",
  SMS_REQUEST_HERO_PAYMENT_REQUEST: "收款要求",
  SMS_SENDER_INSTRUCTIONS: "這些信息可能會出現在你的客戶收到的短訊收款要求中",
  SMS_SENDER_INSTRUCTIONS_EXTRA:
    "輸入你希望客戶在短信中看到的發送人資訊，我們建議使用客戶能夠識別的名稱，例如你的公司名稱。",
  SMS_SENDER_LABEL: "短訊發送人身份",
  SMS_SENDER_PLACEHOLDER: "短訊發送人身份",
  SMS_SENDER_SECTION: "短訊發送人身份",
  SPLIT_FEE_HEADER: "你想怎麼分攤手續費",
  SPLIT_FEE_INSTRUCTIONS: "拖動滑塊來確定你的公司要支付多少錢",
  SPLIT_FEE_SECTION: "你和你的客戶攤分手續費",
  STATEMENT_DESCRIPTION_PLACEHOLDER: "對賬單說明",
  STATEMENT_DESCRIPTOR_LABEL: "對賬單說明",
  STATE_LABEL: "州/省",
  STATE_PLACEHOLDER: "州/省",
  STATUS_DRAFT_LABEL: "草稿",
  STATUS_SCHEDULED_LABEL: "計劃",
  STEP_1_OF_2: "第1步，共2步",
  STEP_1_OF_3: "第1步，共3步",
  STEP_1_OF_4: "第1步，共4步",
  STEP_2_OF_2: "第2步，共2步",
  STEP_2_OF_3: "第2步，共3步",
  STEP_2_OF_4: "第2步，共4步",
  STEP_3_OF_3: "第3步，共3步",
  STEP_3_OF_4: "第3步，共4步",
  STEP_4_OF_4: "第4步，共4步",
  STRIPE_ERROR_AMOUNT_TOO_LARGE:
    "指定的金額大於可用的最高金額。請使用較低的金額，然後再試。",
  STRIPE_ERROR_CARD_DETAIL_INVALID: "無效信用卡，請提供有效卡號。",
  STRIPE_ERROR_CARD_NOT_SUPPORTED:
    "此卡不能用於此類型的消費。請嘗試其他卡或聯繫你的發卡機構了解更多資訊。",
  STRIPE_ERROR_CARD_NOT_SUPPORT_001:
    "001。您的卡不支持此付款方式。請聯繫客戶支持以獲取更多詳細信息。",
  STRIPE_ERROR_CARD_NOT_SUPPORT_002:
    "002。您的卡不支持此付款方式。請聯繫客戶支持以獲取更多詳細信息。",
  STRIPE_ERROR_CARD_UNAUTHORIZED_APPROVE_WITH_ID:
    "該交易無法被授權。請嘗試使用其他卡或聯繫你的發卡機構了解更多資訊。",
  STRIPE_ERROR_CARD_UNAUTHORIZED_INVALID_ACCT:
    "該交易無法被授權。請嘗試使用其他卡或聯繫你的發卡機構了解更多資訊。",
  STRIPE_ERROR_CARD_UNAUTHORIZED_NEW_ACCT_INFO_AVAIL:
    "該交易無法被授權。請嘗試使用其他卡或聯繫你的發卡機構了解更多資訊。",
  STRIPE_ERROR_CARD_UNAUTHORIZED_PROCESSED_ERROR:
    "該交易無法被授權。請嘗試使用其他卡或聯繫你的發卡機構了解更多資訊。",
  STRIPE_ERROR_CARD_UNAUTHORIZED_REENTER_TXN:
    "該交易無法被授權。請嘗試使用其他卡或聯繫你的發卡機構了解更多資訊。",
  STRIPE_ERROR_CARD_UNAUTHORIZED_REVOCATION:
    "該交易不能被授權。請嘗試其他卡或聯繫你的發卡機構以獲取更多資訊。",
  STRIPE_ERROR_CARD_UNAUTHORIZED_REVOCATIONS:
    "該交易無法被授權。請嘗試使用其他卡或聯繫你的發卡機構了解更多資訊。",
  STRIPE_ERROR_CARD_UNAUTHORIZED_SERVICE_NOT_ALLOWED:
    "該交易無法被授權。請嘗試使用其他卡或聯繫你的發卡機構了解更多資訊。",
  STRIPE_ERROR_CARD_UNAUTHORIZED_STOP_PAYMENT_ORDER:
    "該交易無法被授權。請嘗試使用其他卡或聯繫你的發卡機構了解更多資訊。",
  STRIPE_ERROR_CARD_UNAUTHORIZED_TRY_AGAIN_LATER:
    "該交易無法被授權。請嘗試使用其他卡或聯繫你的發卡機構了解更多資訊。",
  STRIPE_ERROR_CARD_UNAUTHORIZED_TXN_NOT_ALLOWED:
    "該交易無法被授權。請嘗試使用其他卡或聯繫你的發卡機構了解更多資訊。",
  STRIPE_ERROR_CHARGE_ALREADY_REFUNDED: "你試圖退還的費用已經被退還。",
  STRIPE_ERROR_CHARGE_DISPUTED: "你試圖退還的費用已經被扣除。",
  STRIPE_ERROR_DECLINED:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  STRIPE_ERROR_DECLINED_CALL_ISSUER:
    "交易被拒絕。請聯繫您的發卡機構了解更多信息。",
  STRIPE_ERROR_DECLINED_CARD_VELOCITY_EXCEEDED:
    "此交易被拒絕了。請與發卡機構聯繫以獲取更多資訊。",
  STRIPE_ERROR_DECLINED_DO_NOT_TRY_AGAIN:
    "此交易被拒絕了。請與發卡機構聯繫以獲取更多資訊。",
  STRIPE_ERROR_DECLINED_LOST_CARD:
    "此交易被拒絕了。請與發卡機構聯繫以獲取更多資訊。",
  STRIPE_ERROR_DECLINED_PICKUP_CARD:
    "此交易被拒絕了。請與發卡機構聯繫以獲取更多資訊。",
  STRIPE_ERROR_DECLINED_RESTRICTED_CARD:
    "此交易被拒絕了。請與發卡機構聯繫以獲取更多資訊。",
  STRIPE_ERROR_DECLINED_SECURITY_VIOLATION:
    "此交易被拒絕了。請與發卡機構聯繫以獲取更多資訊。",
  STRIPE_ERROR_DECLINED_STOLEN_CARD:
    "此交易被拒絕了。請與發卡機構聯繫以獲取更多資訊。",
  STRIPE_ERROR_DO_NOT_HONOR:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  STRIPE_ERROR_DO_NOT_HONOR_GENERIC_DECLINED:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  STRIPE_ERROR_DO_NOT_HONOR_NO_ACTION_TAKEN:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  STRIPE_ERROR_DUPLICATE_TRANSACTION:
    "最近有一筆金額和信用卡信息相同的交易被提交。交易被拒絕，以確保沒有發生重複支付。",
  STRIPE_ERROR_EXCEEDING_WITHDRAW_LIMIT:
    "此交易因超過提款限額而失敗。請嘗試另一張卡。",
  STRIPE_ERROR_EXPIRED_CARD: "信用卡的有效期不正確。檢查到期日或使用其他卡。",
  STRIPE_ERROR_FRAUDULENT: "此交易被拒絕了。請與您的發行人聯繫以獲取更多資訊。",
  STRIPE_ERROR_INCORRECT_CVC:
    "銀行卡的CVC/CVV不正確。請檢查卡的安全碼或使用其他卡。",
  STRIPE_ERROR_INCORRECT_NUMBER: "卡號不正確。請檢查卡號或使用其他卡。",
  STRIPE_ERROR_INSUFFICIENT_FUND:
    "由於信用額度不足，我們無法完成你的付款。你所使用的信用卡可能已達最高信用額度。請調整付款金額或使用其他信用卡。",
  STRIPE_ERROR_INVALID_CVC:
    "銀行卡的CVC/CVV不正確。請檢查卡的安全碼或使用其他卡。",
  STRIPE_ERROR_INVALID_EXPIRY_MONTH:
    "信用卡的有效期不正確。檢查到期日或使用其他卡。",
  STRIPE_ERROR_INVALID_EXPIRY_YEAR:
    "信用卡的有效期不正確。檢查到期日或使用其他卡。",
  STRIPE_ERROR_ISSUER_NOT_AVAILABLE:
    "該交易不能被授權。請嘗試其他卡或聯繫你的發卡機構以獲取更多資訊。",
  STRIPE_ERROR_NOT_PERMITTED:
    "該卡不適用於此類型的消費。請嘗試其他卡或聯繫你的發卡機構以獲取更多資訊。",
  STRIPE_ERROR_UNSUPPORTED_CURRENCY:
    "此卡不支持指定的貨幣。請嘗試使用另一張卡或聯繫你的發卡機構了解更多資訊。",
  SUBMIT_BUTTON: "提交",
  SUBTOTAL_LABEL: "小計",
  SUCCESS_ADD_ACCOUNT: "登錄你的其他賬戶或註冊一個新的賬戶。",
  SUCCESS_APPLIED_COUPON_REMOVED: "成功刪除優惠券。",
  SUCCESS_CARD_CREATED: "銀行卡成功添加到你的錢包中。",
  SUCCESS_CARD_DELETED: "銀行卡刪除成功。",
  SUCCESS_CARD_EDITED: "銀行卡在你的錢包中編輯成功。",
  SUCCESS_COMPANY_DELETED: "成功刪除公司。",
  SUCCESS_COUPON_APPLIED: "你的優惠券已使用了。",
  SUCCESS_LOG_OUT: "成功登出。",
  SUCCESS_PASSWORD_EDITED: "密碼更新成功。",
  SUCCESS_PAYEE_DELETED: "已成功刪除收款人",
  SUCCESS_PAYEE_EDITED: "已成功編輯收款人",
  SUCCESS_PAYMENT_CANCELED: "取消付款成功",
  SUCCESS_PAYMENT_MARKED_AS_COMPLETE: "成功標記為已完成",
  SUCCESS_RATE_EDITED: "更新評價",
  SUCCESS_SWITCH_ACCOUNT: "登錄你的其他賬戶或註冊一個新的賬戶。",
  SUGGESTED_BANK_ACCOUNT_LABEL: "建議的銀行和賬號",
  SUPPLIERS_HERO: "供應商",
  SUPPLIERS_MENU_ITEM: "供應商",
  SUPPLIER_ABN_HEADER: "澳大利亞商業編號（ABN）",
  SUPPLIER_ABN_LABEL: "供應商的澳大利亞業務編號（ABN）",
  SUPPLIER_ABN_PLACEHOLDER: "供應商的澳大利亞業務編號（ABN）",
  SUPPLIER_ACCOUNT_NUMBER_HEADER: "帳號",
  SUPPLIER_ACCOUNT_NUMBER_PLACEHOLDER: "帳戶號碼",
  SUPPLIER_ALREADY_EXISTS: "供應商已經存在",
  SUPPLIER_BANK_ACCOUNT_LABEL: "帳戶號碼",
  SUPPLIER_BANK_ACCOUNT_PLACEHOLDER: "供應商的銀行賬號",
  SUPPLIER_BANK_HEADER: "銀行",
  SUPPLIER_BANK_PLACEHOLDER: "銀行",
  SUPPLIER_BUSINESS_REGISTRATION_NUMBER_LABEL: "供應商的商業登記號",
  SUPPLIER_BUSINESS_REGISTRATION_NUMBER_PLACEHOLDER: "供應商的商業登記號",
  SUPPLIER_COLUMN: "供應商",
  SUPPLIER_COMPANY_NAME_LABEL: "供應商的公司名稱",
  SUPPLIER_COMPANY_NAME_PLACEHOLDER: "供應商的公司名稱",
  SUPPLIER_COUNTRY_HEADER: "國家",
  SUPPLIER_CRN_HEADER: "商業登記號碼（BRN/CRN）",
  SUPPLIER_CRN_LABEL: "供應商的商業登記號碼(BRN/CRN)",
  SUPPLIER_CRN_PLACEHOLDER: "供應商的商業登記號碼(BRN/CRN)",
  SUPPLIER_DELETE_HERO: "你想刪除該供應商嗎？",
  SUPPLIER_DELETE_INSTRUCTIONS: "這間供應商將被從你的供應商名單中刪除",
  SUPPLIER_EMAIL_LABEL: "供應商的電郵地址",
  SUPPLIER_EMAIL_PLACEHOLDER: "供應商的電郵地址",
  SUPPLIER_HEADER: "供應商",
  SUPPLIER_NAME_LABEL: "供應商名稱",
  SUPPLIER_NAME_PLACEHOLDER: "供應商名稱",
  SUPPLIER_NOTE_LABEL: "供應商須知",
  SUPPLIER_NOTE_PLACEHOLDER: "供應商須知",
  SUPPLIER_PLACEHOLDER: "例如S1234567A所得稅",
  SUPPLIER_REGISTRATION_NUMBER_HEADER: "登記號",
  SUPPLIER_SCHEDULED_PAYMENT_HERO:
    "在編輯供應商詳細信息之前，你需要取消相關的付款。",
  SUPPLIER_SCHEDULED_PAYMENT_INSTRUCTIONS: "該供應商與計劃付款有關。",
  SUPPLIER_UEN_HEADER: "獨特實體號碼（UEN）",
  SUPPLIER_UEN_LABEL: "供應商的獨特實體號碼(UEN)",
  SUPPLIER_UEN_PLACEHOLDER: "供應商的獨特實體號碼(UEN)",
  SUPPORT_MENU_ITEM: "支援",
  SWIFT_CODE_LABEL: "SWIFT代碼",
  SWIFT_CODE_PLACEHOLDER: "SWIFT代碼",
  SYSTEM_UPGRADES: "目前我們的系統正在進行升級，很快就可以支付了。謝謝你的理解",
  TABLE_ACTION: "動作",
  TABLE_AMOUNT: "金額",
  TABLE_CARD: "信用卡",
  TABLE_CHARGE_DATE: "扣賬日期",
  TABLE_CUSTOMER: "客戶",
  TABLE_DATE_REQUEST: "發出日期",
  TABLE_DUE_DATE: "截止日期",
  TABLE_EMAIL: "電郵地址",
  TABLE_ESTIMATED_FEE: "估計費用",
  TABLE_ESTIMATED_NET_AMOUNT: "估計淨額",
  TABLE_FREQUENCY: "頻率",
  TABLE_INVOICE_NO: "帳單號碼",
  TABLE_INVOICE_NUMBER: "帳單號碼",
  TABLE_NOTE: "收款人須知",
  TABLE_ORDER_NUMBER: "訂單號碼",
  TABLE_PAYMENT_CANCELLED: "取消付款",
  TABLE_PAYMENT_DECLINED: "付款被拒",
  TABLE_PAYOUT_DATE: "付款日期",
  TABLE_RECEIPT_NUMBER: "帳單號碼",
  TABLE_TYPE: "開支類別",
  TAX_BUTTON: "稅款",
  TAX_REFERENCE_LABEL: "稅款參考號碼",
  TAX_TYPE_LABEL: "和稅種",
  TENANCY_AGREEMENT: "\u003cspan\u003e租約\u003c/span\u003e必須包括",
  TENANCY_BANK_ACCOUNT: "銀行賬戶",
  TENANCY_CONFIRM: "本人確認按照租約向業主直接支付租金。",
  TENANCY_LANDLORD_NAME: "業主姓名",
  TENANCY_RENTAL_AMOUNT: "租金金額",
  TERMS_LABEL: "條款",
  TEST_PARAMS: "Test with param {{.firstname}}",
  TOO_MANY_EMAIL_VERIFICATION_REQUESTS: "電子郵件驗證請求過多",
  TOO_MANY_LOGIN_ATTEMPTS: "你已經達到了登錄次數的上限。請在5分鐘內再試一次。",
  TOO_MANY_LOGIN_ATTEMPTS_2:
    "你已經達到了最大的登錄次數。請聯繫{contact_link}尋求幫助。",
  TOO_MANY_MOBILE_PHONE_VERIFICATION_ERROR: "登錄嘗試失敗過多，請",
  TOP_ACCOUNT_ADD: "添加賬戶",
  TOP_ACCOUNT_CHANGE_PASSWORD: "更改密碼",
  TOP_ACCOUNT_MENU_BUSINESS: "ipaymy企業賬戶",
  TOP_ACCOUNT_MENU_MY_ACCOUNT: "我的賬戶",
  TOP_ACCOUNT_MENU_PERSONAL: "ipaymy個人賬戶",
  TOP_ACCOUNT_SIGN_OUT: "登出",
  TOP_COMPANIES_MENU_ADD_BUTTON: "添加新公司",
  TOP_COMPANIES_MENU_HEADER: "我的公司",
  TOP_COMPANIES_MENU_REGISTRATION_LABEL: "註冊號碼",
  TOP_REFER_BUTTON:
    "\u003ctspan1\u003e推薦\u003c/tspan1\u003e\u003ctspan2\u003e並\u003c/tspan2\u003e\u003ctspan3\u003e獲取\u003c/tspan3\u003e",
  TOP_TRY_IPAYMY_BUSINESS: "試用ipaymy企業版",
  TOP_TRY_IPAYMY_PERSONAL: "試用ipaymy個人版",
  TRY_AGAIN_BUTTON: "再試一次",
  TRY_IPAYMY_BUSINESS_CTA: "試用ipaymy商業版",
  TRY_IPAYMY_PERSONAL_CTA: "嘗試使用ipaymy",
  UEN_COLUMN: "新加坡獨特實體號碼（UEN）",
  UEN_PLACEHOLDER: "獨特實體號碼(UEN)",
  UNAUTHORIZED_CARD: "此銀行卡無法被授權。請重試或使用其他卡。",
  UNAVAILABLE_EMAIL_USED: "請輸入您用於註冊ipaymy帳戶的電子郵件地址。",
  UNIONPAY_COMMON_FEE_LABEL: "China UnionPay",
  UNIONPAY_HKD_FEE_LABEL: "China UnionPay (HKD)",
  UNIONPAY_LABEL: "China UnionPay",
  UNIONPAY_USD_FEE_LABEL: "China UnionPay (USD)",
  UNIT_NUMBER_LABEL: "單位號",
  UNIT_NUMBER_PLACEHOLDER: "單位號",
  UPDATE_BUTTON: "更新",
  UPDATE_COMPANY_ERROR: "更新公司失敗",
  UPDATE_PASSWORD_BUTTON: "更新密碼",
  UPLOAD_CSV_HERO: "上傳CSV文件",
  UPLOAD_CSV_INSTRUCTIONS:
    "請確保所提供的收款人銀行和賬號信息皆正確。這些都是處理付款的主要細節。如果通過所提供的CSV範本上傳，請確認所有賬號都已被準確地採集 - 特別是任何以零(0)開頭的賬號。",
  UPLOAD_DOCUMENT_APPLIES_ALL_SELECTION: "所有",
  UPLOAD_DOCUMENT_APPLIES_LABEL: "適用於",
  UPLOAD_DOCUMENT_COLLECTED_ACCOUNT: "添加KYC文檔",
  UPLOAD_FILE_LABEL: "上傳文件...",
  UPLOAD_ID_HERO_AU: "請上傳你的身分證件照片",
  UPLOAD_ID_HERO_MY: "請上傳你的NRIC的照片",
  UPLOAD_ID_INSTRUCTIONS_AU:
    "出於法規和反洗錢目的，以及為了保護你的帳戶，我們需要在你進行首次付款之前先驗證你的個人身份證件。有效身分證件可為：目前正在使用的護照，駕駛證，或者其他政府签发的身份證件。",
  UPLOAD_ID_INSTRUCTIONS_MY:
    "出於法規和反洗錢目的，以及為了保護你的帳戶，我們需要在你進行首次付款之前先驗證你的個人身份證件。",
  UPLOAD_ID_LIST_1_AU: "請上傳以下內容：",
  UPLOAD_ID_LIST_1_MY: "請上傳以下內容：",
  UPLOAD_ID_LIST_2_AU: "1. 有效身分證件正面和背面的清晰照片。",
  UPLOAD_ID_LIST_2_MY: "1. 有效NRIC正面和背面的清晰照片",
  UPLOAD_ID_LIST_3_AU:
    "2. 你本人手持有效身分證件的清晰照片。身分證件的細節和你的臉部必須清晰可見",
  UPLOAD_ID_LIST_3_MY:
    "2. 你本人手持NRIC的清晰照片。NRIC的細節和你的臉部必須清晰可見。",
  UPLOAD_ID_LIST_4_AU:
    "要獲得帳戶批准，你的身分證件詳細信息將需要與所添加卡的詳細信息相匹配。",
  UPLOAD_ID_LIST_4_MY:
    "要獲得帳戶批准，你的NRIC詳細信息將需要與所添加卡的詳細信息相匹配。",
  UPLOAD_ID_PHOTO_BACK_AU: "身分證件背面照片",
  UPLOAD_ID_PHOTO_BACK_MY: "NRIC背面照片",
  UPLOAD_ID_PHOTO_FRONT_AU: "身分證件正面照片",
  UPLOAD_ID_PHOTO_FRONT_MY: "NRIC正面照片",
  UPLOAD_ID_PHOTO_HOLDING_AU: "你手持身分證件的照片。",
  UPLOAD_ID_PHOTO_HOLDING_MY: "你手持NRIC的照片",
  UPLOAD_INVOICE_BUTTON: "上傳帳單",
  UPLOAD_INVOICE_LABEL: "上載帳單以發送給客戶(選填)",
  UPLOAD_INVOICE_MENU_ITEM: "上傳帳單",
  UPLOAD_ORDER_INVOICE_BUTTON: "上傳訂單帳單",
  UPLOAD_ORDER_MENU_ITEM: "上傳訂單帳單",
  USD_SALARY_ACCEPT_BUTTON: "同意",
  USD_SALARY_CHANGE_BUTTON: "改以港幣支付",
  USD_SALARY_INSTRUCTIONS:
    "若以美金進行結算，你可能會被你的銀行收取匯款費用。{br}收費因銀行而異。",
  VERIFICATION_LINK_EXPIRED: "驗證鏈接已過期",
  VERIFY_EMAIL_BUTTON: "驗證電郵地址",
  VERIFY_EMAIL_BUTTON_INSTRUCTIONS:
    "如果以上按鈕無效，請點擊下面的鏈接或將鏈接複製粘貼到瀏覽器中",
  VERIFY_EMAIL_EXPIRY: "此驗證郵件僅在{{.ExpirationHours}}小時內有效。",
  VERIFY_EMAIL_HERO: "驗證電郵地址",
  VERIFY_EMAIL_INSTRUCTIONS:
    "你好，{{.FirstName}}! 感謝你的註冊! 請點擊下面的按鈕來驗證你的電郵地址。",
  VERIFY_EMAIL_QUESTIONS: "如果你對本次交易有任何疑問，請在此聯繫我們",
  VERIFY_EMAIL_SUBJECT: "歡迎！完成註冊",
  VIEW_DETAILS_BUTTON: "查看詳情",
  VIEW_DETAILS_TOOLTIP: "查看詳情",
  VIOLATE_COUPON_START_DATE: "請在不遲於%s的收費日期安排您的付款。",
  VISA_LABEL: "Visa和",
  VISA_MASTERCARD_FEE_LABEL: "Visa和Mastercard",
  WALLET_DESCRIPTION: "查看、管理你現有的信用卡或添加新卡。",
  WALLET_HERO: "我的錢包",
  WALLET_MENU_ITEM: "我的錢包",
  WALLET_NEW_CARD_BUTTON: "添加新卡",
  WALLEX_QUOTE_INVALID_FIELD: "此字段包含無效數據。",
  WP_3DS_DECLINED_ERROR: "我們的身份驗證提供程序返回了一個錯誤。",
  WP_CSE_ERROR_101: "卡號是必填項",
  WP_CSE_ERROR_102: "卡號應包含 12 到 20 個數字字符",
  WP_CSE_ERROR_103: "輸入的卡號無效",
  WP_CSE_ERROR_201: "安全碼無效",
  WP_CSE_ERROR_301: "不包括到期月份",
  WP_CSE_ERROR_302: "到期月份必須正好包含 2 個數字",
  WP_CSE_ERROR_303: "到期月份應介於 01 和 12 之間",
  WP_CSE_ERROR_304: "不包括到期年份",
  WP_CSE_ERROR_305: "到期年份必須正好包含 4 個數字",
  WP_CSE_ERROR_306: "到期月份和到期年份一起必須是未來的日期",
  WP_CSE_ERROR_401: "持卡人姓名為必填項",
  WP_CSE_ERROR_402: "持卡人姓名不能超過三十 (30) 個字符",
  WP_DO_NOT_HONOR:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  WP_ERROR_3DS_FAILED:
    "該交易無法被授權。請嘗試使用其他卡或聯繫你的發卡機構了解更多資訊。",
  WP_ERROR_3DS_NOT_SUPPORT:
    "ipaymy不支持用此卡支付。你可以聯繫我們以獲得更多資訊。",
  WP_ERROR_DO_NOT_HONOR:
    "請檢查你的信用卡的詳細資料或使用其他卡。如需了解更多資訊，請聯繫你的發卡機構。",
  WP_INTERNAL_TECHNICAL_ERROR:
    "哎呀！ 支付失敗。 您的付款請求似乎存在技術問題。 請通過 support@ipaymy.com 與我們聯繫。",
  WP_REFUSED_ERROR_0: "得到正式認可的",
  WP_REFUSED_ERROR_1: "請參閱發卡機構或轉介，呼叫授權中心",
  WP_REFUSED_ERROR_10: "部分批准",
  WP_REFUSED_ERROR_1044: "批准，保持第一次檢查",
  WP_REFUSED_ERROR_1045: "檢查確定，沒有轉換",
  WP_REFUSED_ERROR_1046: "無效的 RTTN",
  WP_REFUSED_ERROR_1047: "金額大於限制",
  WP_REFUSED_ERROR_1048: "未付款項，未通過 NEG",
  WP_REFUSED_ERROR_1049: "重複支票號碼",
  WP_REFUSED_ERROR_1050: "微信錯誤",
  WP_REFUSED_ERROR_1051: "支票太多",
  WP_REFUSED_ERROR_1198: "轉發給發行人",
  WP_REFUSED_ERROR_12: "無效交易",
  WP_REFUSED_ERROR_1201: "轉發給發行人",
  WP_REFUSED_ERROR_1263: "無法授權",
  WP_REFUSED_ERROR_1295: "未知",
  WP_REFUSED_ERROR_13: "金額無效或金額無效（貨幣換算溢出）",
  WP_REFUSED_ERROR_14: "無效的卡號或無效的帳號（沒有這樣的號碼）",
  WP_REFUSED_ERROR_15: "發行人無效",
  WP_REFUSED_ERROR_19: "重新進入交易",
  WP_REFUSED_ERROR_2: "參考發卡機構，特殊情況",
  WP_REFUSED_ERROR_20: "錯誤或未採取任何行動（無法事先退出）",
  WP_REFUSED_ERROR_25: "無法在文件或帳戶中找到記錄",
  WP_REFUSED_ERROR_28: "文件暫時不可用",
  WP_REFUSED_ERROR_3: "無效的商家或無效的商家或服務提供商",
  WP_REFUSED_ERROR_30: "格式錯誤或錯誤",
  WP_REFUSED_ERROR_34:
    "我們在處理您的卡時遇到了一些問題。 請嘗試另一張卡，或通過support@ipaymy.com聯繫我們。",
  WP_REFUSED_ERROR_39: "沒有信用賬戶",
  WP_REFUSED_ERROR_397: "簽證上不允許的附加費金額",
  WP_REFUSED_ERROR_398: "不支持附加費",
  WP_REFUSED_ERROR_4: "捕獲卡或拒絕，保留卡",
  WP_REFUSED_ERROR_41: "遺失卡或領取卡（遺失卡）",
  WP_REFUSED_ERROR_43: "被盜卡或取卡卡（被盜卡）",
  WP_REFUSED_ERROR_442: "請求消息中的收單機構識別碼未在 CAFIS 註冊",
  WP_REFUSED_ERROR_443: "CAFIS 系統錯誤。 再試一次",
  WP_REFUSED_ERROR_444: "收單系統忙。 再試一次",
  WP_REFUSED_ERROR_445: "收單系統錯誤。 再試一次",
  WP_REFUSED_ERROR_446: "收單系統已關閉。 再試一次",
  WP_REFUSED_ERROR_447: "CAFIS 系統錯誤。 再試一次",
  WP_REFUSED_ERROR_448: "Illegal encoding format. Try again",
  WP_REFUSED_ERROR_449: "CAFIS 系統錯誤。 再試一次",
  WP_REFUSED_ERROR_450: "CAFIS 系統錯誤。 再試一次",
  WP_REFUSED_ERROR_451: "Mesej nasihat sudah diterima",
  WP_REFUSED_ERROR_452: "CAFIS 在向收單方發送消息時檢測到超時。 再試一次",
  WP_REFUSED_ERROR_453: "CAFIS 系統錯誤。 再試一次",
  WP_REFUSED_ERROR_454: "CAFIS 系統錯誤。 再試一次",
  WP_REFUSED_ERROR_455: "CAFIS 系統錯誤。 再試一次",
  WP_REFUSED_ERROR_456: "CAFIS 系統錯誤。 再試一次",
  WP_REFUSED_ERROR_457: "CAFIS 系統錯誤。 再試一次",
  WP_REFUSED_ERROR_458: "收單方不支持該服務",
  WP_REFUSED_ERROR_459: "CAFIS 系統錯誤。 再試一次",
  WP_REFUSED_ERROR_460: "CAFIS 系統錯誤。 再試一次",
  WP_REFUSED_ERROR_5: "不尊重或拒絕",
  WP_REFUSED_ERROR_51: "資金不足或資金不足/超過信用額度",
  WP_REFUSED_ERROR_52: "沒有支票賬戶",
  WP_REFUSED_ERROR_53: "沒有儲蓄賬戶",
  WP_REFUSED_ERROR_54: "過期卡或被拒絕、過期卡",
  WP_REFUSED_ERROR_55: "PIN碼無效或錯誤",
  WP_REFUSED_ERROR_57: "發卡行/持卡人不允許交易",
  WP_REFUSED_ERROR_577: "該卡無法使用。",
  WP_REFUSED_ERROR_578: "交易待定。",
  WP_REFUSED_ERROR_579: "PIN 碼不正確",
  WP_REFUSED_ERROR_58: "不允許收單方/終端進行交易",
  WP_REFUSED_ERROR_580: "安全密碼不正確",
  WP_REFUSED_ERROR_581: "未設置安全碼",
  WP_REFUSED_ERROR_582: "JIS2 條帶信息無效",
  WP_REFUSED_ERROR_583: "該卡當天已刷滿。 （不充足的資金）",
  WP_REFUSED_ERROR_584: "金額超過當天限額。 （不充足的資金）",
  WP_REFUSED_ERROR_586: "該卡無效。 （MOD 10 檢查失敗）",
  WP_REFUSED_ERROR_587: "該卡無效（丟失/被盜）。",
  WP_REFUSED_ERROR_588: "該卡無效。 （MOD 10 檢查失敗）",
  WP_REFUSED_ERROR_589: "消息元素“主帳號”值無效（MOD 10 檢查失敗）",
  WP_REFUSED_ERROR_590: "消息元素“商家類型”值無效",
  WP_REFUSED_ERROR_591: "消息元素“交易金額”值無效",
  WP_REFUSED_ERROR_592: "消息元素“Tax and Postage”值無效",
  WP_REFUSED_ERROR_593: "獎金計數值無效",
  WP_REFUSED_ERROR_594: "獎金月份值無效",
  WP_REFUSED_ERROR_595: "獎金金額值無效",
  WP_REFUSED_ERROR_596: "首付款月份值無效",
  WP_REFUSED_ERROR_597: "分期付款計數值無效",
  WP_REFUSED_ERROR_598: "分期付款金額值無效",
  WP_REFUSED_ERROR_599: "首次付款金額值無效",
  WP_REFUSED_ERROR_6: "錯誤",
  WP_REFUSED_ERROR_600: "消息元素“服務代碼”、“業務代碼”和“消息代碼”值無效",
  WP_REFUSED_ERROR_601: "消息元素“支付部門”值無效",
  WP_REFUSED_ERROR_602: "消息元素“詢價部門”值無效",
  WP_REFUSED_ERROR_603: "消息元素“取消分部”值無效",
  WP_REFUSED_ERROR_604: "消息元素“原始支付部門”值無效",
  WP_REFUSED_ERROR_605: "卡已過期。",
  WP_REFUSED_ERROR_606: "該卡不適用於該服務。",
  WP_REFUSED_ERROR_607: "收單服務完成。",
  WP_REFUSED_ERROR_608: "無效卡（丟失/被盜）有錯誤。",
  WP_REFUSED_ERROR_609: "由於某種原因無法處理請求消息。",
  WP_REFUSED_ERROR_61: "超過提款金額限制",
  WP_REFUSED_ERROR_610: "收到不支持事務的請求消息",
  WP_REFUSED_ERROR_611: "來自尚未建立聯繫的中心的請求消息。",
  WP_REFUSED_ERROR_62: "限制卡或限制卡（在國家排除表中）",
  WP_REFUSED_ERROR_622: "消息元素“服務代碼”設置錯誤",
  WP_REFUSED_ERROR_623: "消息元素“業務代碼”設置錯誤",
  WP_REFUSED_ERROR_624: "消息元素“消息代碼”設置錯誤",
  WP_REFUSED_ERROR_625: "消息元素“處理器代碼”設置錯誤",
  WP_REFUSED_ERROR_626: "消息元素“商家代碼”設置錯誤",
  WP_REFUSED_ERROR_627: "消息元素“事務標識符”設置錯誤",
  WP_REFUSED_ERROR_628: "消息元素“處理器事務日期-時間”設置錯誤",
  WP_REFUSED_ERROR_629: "消息元素“卡接收器終端標識”設置錯誤",
  WP_REFUSED_ERROR_63: "無法授權 OR ERROR",
  WP_REFUSED_ERROR_630: "消息元素“加密方法代碼”設置錯誤",
  WP_REFUSED_ERROR_631: "消息元素“Key Encryption Key Index in Use”設置錯誤",
  WP_REFUSED_ERROR_632: "消息元素“處理器認證密鑰索引”設置錯誤",
  WP_REFUSED_ERROR_633: "消息元素“消息加密密鑰”設置錯誤",
  WP_REFUSED_ERROR_634: "消息元素“消息驗證碼”設置錯誤",
  WP_REFUSED_ERROR_635: "消息元素“收單機構識別碼”設置錯誤",
  WP_REFUSED_ERROR_636: "消息元素“主賬號”設置錯誤",
  WP_REFUSED_ERROR_637: "消息元素“到期日期”設置錯誤",
  WP_REFUSED_ERROR_638: "消息元素“Track-2 Data”設置錯誤",
  WP_REFUSED_ERROR_639: "消息元素“PIN 數據”設置錯誤",
  WP_REFUSED_ERROR_64: "無法授權",
  WP_REFUSED_ERROR_640: "消息元素“商家類型”設置錯誤",
  WP_REFUSED_ERROR_641: "消息元素“交易金額”設置錯誤",
  WP_REFUSED_ERROR_642: "消息元素“稅和郵資”設置錯誤",
  WP_REFUSED_ERROR_643: "消息元素“服務點數據代碼”設置錯誤",
  WP_REFUSED_ERROR_644: "消息元素“支付部門”設置錯誤",
  WP_REFUSED_ERROR_645: "消息元素“取消分割”設置錯誤",
  WP_REFUSED_ERROR_646: "消息元素“原始終端處理序列號”設置錯誤",
  WP_REFUSED_ERROR_647: "消息元素“原支付分部”設置錯誤",
  WP_REFUSED_ERROR_65: "超過提款次數限製或要求身份驗證",
  WP_REFUSED_ERROR_658: "消息元素“原始處理器事務日期-時間”設置錯誤",
  WP_REFUSED_ERROR_659: "消息元素“原始交易標識符”設置錯誤",
  WP_REFUSED_ERROR_660: "消息元素“自由字段”設置錯誤",
  WP_REFUSED_ERROR_661: "消息元素“終端處理序列號”設置錯誤",
  WP_REFUSED_ERROR_662: "消息元素“安全代碼”設置錯誤",
  WP_REFUSED_ERROR_663: "消息元素“電子商務指標”設置錯誤",
  WP_REFUSED_ERROR_664: "消息元素“XID”設置錯誤",
  WP_REFUSED_ERROR_665: "消息元素“Accountholder Authentication Value”設置錯誤",
  WP_REFUSED_ERROR_666: "消息元素“事務狀態”設置錯誤",
  WP_REFUSED_ERROR_667: "消息元素“消息版本號”設置錯誤",
  WP_REFUSED_ERROR_668: "消息元素“CAVV 算法”設置錯誤",
  WP_REFUSED_ERROR_669: "消息元素“重複標誌”設置錯誤",
  WP_REFUSED_ERROR_670: "元素塊（例如“一般交易信息”設置錯誤）",
  WP_REFUSED_ERROR_671: "消息元素組合“服務代碼”和“消息代碼”設置不正確",
  WP_REFUSED_ERROR_672: "消息元素“處理器認證密鑰索引”設置錯誤",
  WP_REFUSED_ERROR_673: "有不止一項原始交易要撤銷。無法撤銷交易",
  WP_REFUSED_ERROR_674:
    "消息元素“Processor Code”和“Merchant Code”的組合設置不正確",
  WP_REFUSED_ERROR_675: "全球齒輪系統錯誤。再試一次。",
  WP_REFUSED_ERROR_676: "全球齒輪系統錯誤。交易結果不明。",
  WP_REFUSED_ERROR_677: "全球齒輪系統錯誤。交易結果不明。",
  WP_REFUSED_ERROR_678: "全球齒輪系統錯誤。再試一次。",
  WP_REFUSED_ERROR_679: "全球齒輪系統錯誤。交易結果不明。",
  WP_REFUSED_ERROR_68: "暫停",
  WP_REFUSED_ERROR_680: "全球齒輪系統錯誤。交易結果不明。",
  WP_REFUSED_ERROR_681: "全球齒輪系統錯誤。再試一次。",
  WP_REFUSED_ERROR_682: "全球齒輪系統錯誤。交易結果不明。",
  WP_REFUSED_ERROR_683: "全球齒輪系統錯誤。交易結果不明。",
  WP_REFUSED_ERROR_694: "全球齒輪系統錯誤。交易結果不明。",
  WP_REFUSED_ERROR_695: "全球齒輪系統錯誤。交易結果不明。",
  WP_REFUSED_ERROR_696: "全球齒輪系統錯誤。交易結果不明。",
  WP_REFUSED_ERROR_697: "全球齒輪系統錯誤。交易結果不明。",
  WP_REFUSED_ERROR_698: "全球齒輪系統錯誤。交易結果不明。",
  WP_REFUSED_ERROR_699: "全球齒輪系統錯誤。交易結果不明。",
  WP_REFUSED_ERROR_7: "提貨卡，特殊情況",
  WP_REFUSED_ERROR_70: "聯繫發卡機構",
  WP_REFUSED_ERROR_700: "全球齒輪系統錯誤。交易結果不明。",
  WP_REFUSED_ERROR_701: "全球齒輪系統錯誤。交易結果不明。",
  WP_REFUSED_ERROR_702: "全球齒輪系統錯誤。交易結果不明。",
  WP_REFUSED_ERROR_703: "全球齒輪系統錯誤。再試一次。",
  WP_REFUSED_ERROR_704: "全球齒輪系統錯誤。交易結果不明。",
  WP_REFUSED_ERROR_705: "全球齒輪系統錯誤。再試一次。",
  WP_REFUSED_ERROR_71: "PIN未更改",
  WP_REFUSED_ERROR_75: "超過允許的PIN嘗試次數",
  WP_REFUSED_ERROR_76: "無效/不存在或指定無效/不存在",
  WP_REFUSED_ERROR_77: "無效/不存在或指定無效/不存在",
  WP_REFUSED_ERROR_78: "指定的帳戶無效/不存在（一般）",
  WP_REFUSED_ERROR_79: "已撤銷 (Visa) 或生命週期原因 (Mastercard)",
  WP_REFUSED_ERROR_8: "憑身份證榮譽或憑身份證批准交易",
  WP_REFUSED_ERROR_80: "簽證交易：信用發卡機構不可用。",
  WP_REFUSED_ERROR_82:
    "負 CAM、dCVV、iCVV 或 CVV 結果（簽證）或政策原因（萬事達卡）",
  WP_REFUSED_ERROR_828: "無法授權",
  WP_REFUSED_ERROR_83: "STIP 無法批准（簽證）或欺詐安全相關原因（萬事達卡）",
  WP_REFUSED_ERROR_831: "現金服務不可用",
  WP_REFUSED_ERROR_832: "現金返還請求超出發行人限制",
  WP_REFUSED_ERROR_833: "超過最大天數限制重新提交的交易",
  WP_REFUSED_ERROR_835: "因 CVV2 故障而下降",
  WP_REFUSED_ERROR_836: "交易金額大於預授權",
  WP_REFUSED_ERROR_84: "無效的授權生命週期",
  WP_REFUSED_ERROR_85: "沒有拒絕。 僅對 AVS 有效，平衡 Inq 或無理由拒絕請求",
  WP_REFUSED_ERROR_86: "無法驗證 PIN",
  WP_REFUSED_ERROR_88: "無法授權",
  WP_REFUSED_ERROR_89: "不可接受的 PIN - 交易被拒絕 - 重試或不符合接收條件",
  WP_REFUSED_ERROR_902: "無效的賬單信息",
  WP_REFUSED_ERROR_905: "無法授權",
  WP_REFUSED_ERROR_906: "無法授權",
  WP_REFUSED_ERROR_91: "授權系統或發行人系統無效或授權系統或發行人系統無效",
  WP_REFUSED_ERROR_92: "無法路由事務或無法找到用於路由的目的地",
  WP_REFUSED_ERROR_93: "交易無法完成違反法律",
  WP_REFUSED_ERROR_937: "卡認證失敗",
  WP_REFUSED_ERROR_94: "檢測到重複傳輸",
  WP_REFUSED_ERROR_96: "系統錯誤或無法授權",
  WP_REFUSED_ERROR_972: "停止付款指令",
  WP_REFUSED_ERROR_973: "撤銷授權令",
  WP_REFUSED_ERROR_975: "撤銷所有授權令",
  WP_REFUSED_ERROR_98: "錯誤",
  WP_REFUSED_ERROR_99: "錯誤",
  WP_TOKENISATION_ERROR: "您的卡已被發卡行拒絕，請確保您的卡信息有效。",
  WRONG_MOBILE_PASS_CODE_ERROR: "您輸入的代碼無效，請重試或{RESEND_PIN}。",
  XERO_ACCESS_INSTRUCTIONS:
    "需要登入你的Xero連接的ipaymy賬戶？ \u003cstyling1\u003e此處登錄\u003c/styling1\u003e。",
  YES_CHECKBOX: "有",
  YOUR_BRANDING_COLOR: "你的品牌顏色",
  YOUR_COMPANY_LOGO: "你的公司商標",
  YOU_PAY_LABEL: "你支付"
};
export default t;
