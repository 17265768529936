const normalize = <T extends { id: number | string }>(
  list: T[]
): { [id: string]: T } => {
  const m = {};
  list.forEach(item => {
    m[item.id.toString()] = item;
  });

  return m;
};

export default normalize;
