import React from "react";
import Image from "antd/es/image";

const Component = (props: any) => {
  const { preview = false, src, ...rest } = props;

  return <Image src={src} preview={preview} {...rest} />;
};

export default Component;
