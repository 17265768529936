import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import DesktopModalLayout from "src/bepaid/components/Modal/components/DesktopModalLayout";
import { FetchModalID } from "src/ipm-shared/components/GlobalUI/actions";

import BusinessInformation from "src/bepaid/layouts/Menu/components/BusinessInformation";
import MainLayout from "src/bepaid/layouts/MainLayout";

import styles from "./BusinessInformationModal.module.scss";

const View = (props: any) => {
  const toggleModal = () => {
    props.toggleModal(FetchModalID.UPDATE_BUSINESS_INFORMATION);
  };

  const onSaved = () => {
    toggleModal();
  };

  const renderContent = () => {
    return (
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <BusinessInformation onSaved={onSaved} />
        </div>
      </div>
    );
  };

  return (
    <>
      <BrowserView>
        <DesktopModalLayout
          title={"Business information"}
          onClose={toggleModal}
        >
          {renderContent()}
        </DesktopModalLayout>
      </BrowserView>
      <MobileView>
        <MainLayout
          header={{
            title: "Business information",
            hideMenu: true,
            onClose: toggleModal
          }}
        >
          {renderContent()}
        </MainLayout>
      </MobileView>
    </>
  );
};

export default View;
