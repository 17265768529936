import React, { useEffect } from "react";
import Radio from "antd/es/radio";
import classnames from "classnames";

import styles from "./SelectGroup.module.scss";

function View({
  name,
  form = "default",
  onChange = () => null,
  setControl = () => null,
  value,
  options = [],
  defaultValue = "",
  className = "",
  disabled = false,
  optionType = "button",
  buttonStyle = "solid"
}: any) {
  const onChangeCustom = (e: any) => {
    const v = e.target.value;

    if (name) {
      setControl({
        name,
        form,
        value: v
      });
    }

    onChange(e);
  };

  useEffect(() => {
    if (defaultValue && name) {
      setControl({
        name,
        form,
        value: defaultValue
      });
    }
  }, []);

  return (
    <div className={classnames(styles.wrapper, className)}>
      <Radio.Group
        disabled={disabled}
        className={styles.groupOptionAddItem}
        options={options}
        onChange={onChangeCustom}
        value={value}
        defaultValue={defaultValue}
        optionType={optionType}
        buttonStyle={buttonStyle}
      />
    </div>
  );
}

export default View;
