// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SwitchPlatform_wrapper__26m4s{width:100%}.SwitchPlatform_wrapper__26m4s .SwitchPlatform_customSelectGroup__3-jne .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within{-webkit-box-shadow:unset !important;box-shadow:unset !important}.SwitchPlatform_wrapper__26m4s .SwitchPlatform_customSelectGroup__3-jne .ant-radio-button-wrapper-checked{background-color:#fff !important;border:3px solid #e8effc !important}.SwitchPlatform_wrapper__26m4s .SwitchPlatform_customSelectGroup__3-jne .ant-radio-button-wrapper-checked span{color:#34405b !important}.SwitchPlatform_isDesktop__1MrHy{margin-bottom:3rem}", "",{"version":3,"sources":["webpack://./src/bepaid/components/SwitchPlatform/SwitchPlatform.module.scss"],"names":[],"mappings":"AAAA,+BAAS,UAAU,CAAC,sLAAkJ,mCAA0B,CAA1B,2BAA2B,CAAC,0GAAsE,gCAAgC,CAAC,mCAAmC,CAAC,+GAA2E,wBAAwB,CAAC,iCAAW,kBAAkB","sourcesContent":[".wrapper{width:100%}.wrapper .customSelectGroup :global .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within{box-shadow:unset !important}.wrapper .customSelectGroup :global .ant-radio-button-wrapper-checked{background-color:#fff !important;border:3px solid #e8effc !important}.wrapper .customSelectGroup :global .ant-radio-button-wrapper-checked span{color:#34405b !important}.isDesktop{margin-bottom:3rem}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "SwitchPlatform_wrapper__26m4s",
	"customSelectGroup": "SwitchPlatform_customSelectGroup__3-jne",
	"isDesktop": "SwitchPlatform_isDesktop__1MrHy"
};
export default ___CSS_LOADER_EXPORT___;
