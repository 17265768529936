export const COUNTRY_OPTION_FORM = "country_option_form";
export const COUNTRY_OPTION_LIST = [];

export const pushItemsToIndex = (
  list: any[] = [],
  items: any[] = [],
  index = 0
) => {
  return [...list.slice(0, index), ...items, ...list.slice(index, list.length)];
};
