import { connect } from "react-redux";

import { RootState } from "src/ipm-shared/store/model/reducers";
import * as formActions from "src/ipm-shared/components/Form/actions";

import * as selectors from "src/bepaid/store/model/Fetch/selectors";
import * as actions from "src/bepaid/store/model/Fetch/actions";

import View from "./View";

const mapStateToProps = (state: RootState) => ({
  collectedAccount: selectors.getCollectedAccount(state),
  currencyId: selectors.getAccountCurrencyId(state),
  getControl: (name: string, form: string) =>
    selectors.getControl(state, name, form),
  getFilesControl: selectors.getFileControl(
    state,
    selectors.getControlsPattern(state, /^document_tag_/),
    "document_tag_"
  ),
  getInvoiceSelected: selectors.getInvoiceSelected(state),
  getSelectedCustomers: selectors.getSelectedCustomers(state),
  invoiceLineItems: selectors.getInvoiceLineItems(state),
  otherSettings: selectors.getCollectionOtherSettings(state),
  taxes: selectors.getTaxes(state)
});

const mapDispatchToProps = {
  removeForm: actions.removeForm,
  selectAllItemIds: actions.selectAllItemIds,
  setControl: formActions.setControl,
  resetFormErrors: formActions.resetFormErrors,
  toggleModal: actions.toggleModal,
  getCustomerList: actions.getCustomerList
};

export default connect(mapStateToProps, mapDispatchToProps)(View);
