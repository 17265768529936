import React from "react";
import classNames from "classnames";
import Typography from "antd/es/typography";

import styles from "../RecipientDetail.module.scss";

import { useProcessPayeeDetail } from "../useProcessPaymentDetail";
import BankId from "src/ipm-shared/components/BankId";
import Image from "src/bepaid/components/Image";
import CountryUtil, { FLAGS } from "src/ipm-shared/Utils/Country";

const ContentCollapsed = (props: any) => {
  const { selectedPayee, hasWallexPayment } = props;
  const {
    bankAccountHolderName,
    supplierName,
    supplierEmail,
    bankId,
    bankRawName,
    accountNumber,
    bankCountryId,
    swiftCode,
    bsbCode,
    ifscCode,
    bankCode,
    aba,
    sortCode,
    iban,
    cnaps
  } = useProcessPayeeDetail(selectedPayee);

  return (
    <>
      <div className={classNames(styles.row, styles.firstRow)}>
        <span>Bank account holder name</span>
        {hasWallexPayment && (
          <div className={styles.flag}>
            <Image
              src={
                FLAGS[CountryUtil.getCountryCodeFromCountryId(bankCountryId)]
              }
            />
          </div>
        )}
      </div>
      <div className={styles.row}>
        <Typography.Text strong={true} ellipsis={true}>
          {bankAccountHolderName}
        </Typography.Text>
      </div>
      <hr className={styles.hr1px} />
      <div>
        <div className={classNames(styles.row, styles.verticalRow)}>
          <span>Bank</span>
          {bankId ? (
            <span>
              <BankId
                name={`${bankId}_bank`}
                valueShowOnly={true}
                defaultValue={bankId}
              />
            </span>
          ) : (
            <span>{bankRawName}</span>
          )}
        </div>
        <div className={classNames(styles.row, styles.verticalRow)}>
          <span>Account number</span>
          <Typography.Text ellipsis={true}>{accountNumber}</Typography.Text>
        </div>

        <div className={classNames(styles.row, styles.verticalRow)}>
          <span>SWIFT code</span>
          <Typography.Text ellipsis={true}>{swiftCode}</Typography.Text>
        </div>
        {bsbCode && (
          <div className={classNames(styles.row, styles.verticalRow)}>
            <span>BSB code</span>
            <Typography.Text ellipsis={true}>{bsbCode}</Typography.Text>
          </div>
        )}
        {ifscCode && (
          <div className={classNames(styles.row, styles.verticalRow)}>
            <span>IFSC code</span>
            <Typography.Text ellipsis={true}>{ifscCode}</Typography.Text>
          </div>
        )}
        {bankCode && (
          <div className={classNames(styles.row, styles.verticalRow)}>
            <span>Bank code</span>
            <Typography.Text ellipsis={true}>{bankCode}</Typography.Text>
          </div>
        )}
        {aba && (
          <div className={classNames(styles.row, styles.verticalRow)}>
            <span>ABA/Routing Number</span>
            <Typography.Text ellipsis={true}>{aba}</Typography.Text>
          </div>
        )}
        {sortCode && (
          <div className={classNames(styles.row, styles.verticalRow)}>
            <span>Sort code</span>
            <Typography.Text ellipsis={true}>{sortCode}</Typography.Text>
          </div>
        )}
        {iban && (
          <div className={classNames(styles.row, styles.verticalRow)}>
            <span>IBAN</span>
            <Typography.Text ellipsis={true}>{iban}</Typography.Text>
          </div>
        )}
        {cnaps && (
          <div className={classNames(styles.row, styles.verticalRow)}>
            <span>CNAPS</span>
            <Typography.Text ellipsis={true}>{cnaps}</Typography.Text>
          </div>
        )}

        {supplierName && (
          <div className={classNames(styles.row, styles.verticalRow)}>
            <span>Supplier's Name</span>
            <Typography.Text ellipsis={true}>{supplierName}</Typography.Text>
          </div>
        )}
        {supplierEmail && (
          <div className={classNames(styles.row, styles.verticalRow)}>
            <span>Supplier's email address</span>
            <Typography.Text ellipsis={true}>{supplierEmail}</Typography.Text>
          </div>
        )}
      </div>
    </>
  );
};

export default ContentCollapsed;
