import { RouteProps } from "react-router";
import { connect } from "react-redux";

import { RootState } from "src/ipm-shared/store/model/reducers";

import * as actions from "src/bepaid/store/model/Fetch/actions";
import * as selectors from "src/bepaid/store/model/Fetch/selectors";

import Component from "./View";

const mapStateToProps = (state: RootState) => ({
  isFetchingCustomer: selectors.getIsFetching(state),
  listCustomer: selectors.getCustomerList(state),
  statistics: selectors.getInvoiceStatistics(state)
});

const mapDispatchToProps = {
  selectCustomers: actions.selectCustomers
};

export type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteProps;

export default connect(mapStateToProps, mapDispatchToProps)(Component);
