/* tslint:disable:object-literal-sort-keys jsx-no-lambda */
import React, { useEffect, useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import * as queryString from "query-string";
import useQuery from "src/bepaid/hooks/useQuery";

import { history } from "src/ipm-shared/store";
import { FetchModalID } from "src/ipm-shared/components/GlobalUI/actions";
import { PLATFORM } from "src/ipm-shared/components/GlobalUI/reducers";

import ROUTES from "src/bepaid/routes";
import Image from "src/bepaid/components/Image";
import { invokeMessage } from "src/bepaid/components/Message";
import MainLayout from "src/bepaid/layouts/MainLayout";
import ProfileHeading from "src/bepaid/layouts/MainLayout/components/ProfileHeading";
import ButtonsHeading from "src/bepaid/layouts/MainLayout/components/ButtonsHeading";
import MobileMainButton from "src/bepaid/layouts/MainLayout/components/MobileMainButton";

import PaymentsSearchAndFilter from "../PaymentsSearchAndFilter";
import PaymentsListContent from "../PaymentsListContent";

import PaymentSearch from "src/bepaid/assets/images/invoices/invoices_search.svg";
import PaymentFilter from "src/bepaid/assets/images/invoices/invoices_filter.svg";

import styles from "../../Payments.module.scss";
import { PAYMENT_STATUS } from "src/ipm-shared/store/model/Payment/const";

const PaymentsMobile: React.FC<any> = props => {
  const qs = useQuery();
  const isIndividual = qs.paymentType === "individual";

  return (
    <MainLayout
      header={{
        title: "Payments",
        onBack: () => history.push(ROUTES.ROOT),
        rightIcons: (
          <>
            <Image
              src={PaymentSearch}
              className={styles.icon}
              onClick={() => {
                const urlParams = new URLSearchParams(window.location.search);
                urlParams.set(
                  "key",
                  isIndividual ? "payments" : "payments_schedules"
                );
                history.push({
                  pathname: `${ROUTES.SEARCH}`,
                  search: "?" + urlParams.toString()
                });
              }}
            />
            <Image
              src={PaymentFilter}
              className={styles.icon}
              onClick={() => {
                const urlParams = new URLSearchParams(window.location.search);
                urlParams.set(
                  "key",
                  isIndividual ? "payments" : "payments_schedules"
                );
                history.push({
                  pathname: `${ROUTES.FILTER}`,
                  search: "?" + urlParams.toString()
                });
              }}
            />
          </>
        ),
        hasBorder: false
      }}
      footer={<MobileMainButton platform={PLATFORM.PAY} />}
    >
      <PaymentsListContent {...props} />
    </MainLayout>
  );
};

const PaymentsDesktop = (props: any) => {
  useEffect(() => {
    if (props.openCreateInvoice) {
      props.toggleModal(FetchModalID.CREATE_INVOICE);
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("noRedirectIframe") === "y") {
      return;
    }
    if (props.nf_schedule_id) {
      props.selectSchedule(Number(props.nf_schedule_id), (data: any) => {
        const paymentEditUrl = localStorage.getItem("paymentEditUrl");
        invokeMessage("success", "Changes saved.");
        if (paymentEditUrl) {
          history.push(paymentEditUrl);
        } else {
          history.push(
            ROUTES.PAYMENT_DETAILS.replace(":id", data.paymentId.toString()) +
              "?idType=id"
          );
        }
      });
    }
  }, [props.nf_schedule_id]);

  return (
    <div>
      <MainLayout
        header={{
          leftHeader: <ProfileHeading />,
          rightHeader: <ButtonsHeading />
        }}
        body={{
          leftBody: <PaymentsListContent {...props} />,
          rightBody: <PaymentsSearchAndFilter />
        }}
      />
    </div>
  );
};

const View = (props: any) => {
  const [isReady, setReady] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("noRedirectIframe") === "y") {
      return;
    }

    if (
      !window.location.search.includes("?") &&
      !Boolean(/^\/payments\/.+$/.test(window.location.pathname))
    ) {
      const defaultFilter = {
        paymentType: "individual",
        statuses: String(PAYMENT_STATUS.COMPLETED)
      };

      history.push(
        `${window.location.pathname}?${queryString.stringify(defaultFilter)}`
      );
    }
    setReady(true);
  }, []);

  return (
    <>
      <BrowserView>
        <PaymentsDesktop isReady={isReady} {...props} />
      </BrowserView>
      <MobileView>
        <PaymentsMobile isReady={isReady} {...props} />
      </MobileView>
    </>
  );
};

export default View;
