import classNames from "classnames";
import React from "react";
import styles from "./Progress.module.scss";

const Component = (props: any) => {
  const {
    stepIndex,
    steps = [
      {
        icon: () => null,
        iconPassed: () => null,
        percent: 0
      }
    ]
  } = props;
  const step = steps[stepIndex];
  const renderIcon = (item: any) => {
    if (item.percent <= step.percent) {
      return item.iconPassed();
    }
    return item.icon();
  };
  return (
    <div>
      <div className={styles.progressBar}>
        <div
          className={styles.progressBarSuccess}
          style={{ width: `${step.percent}%` }}
        />
      </div>
      <div className={styles.progressLabel}>
        {steps.map((item: any) => {
          return (
            <>
              <span
                className={styles.percentIcon}
                style={{ left: `${item.percent}%` }}
              >
                {renderIcon(item)}
              </span>
              <span
                className={classNames(styles.percentLabel, {
                  [styles.finishLabel]: item.percent === 100,
                  [styles.startLabel]: item.percent === 0
                })}
                style={{ left: `${item.percent}%` }}
              >
                {item.percent}%
              </span>
            </>
          );
        })}
      </div>
    </div>
  );
};

export default Component;
