import { connect } from "react-redux";
import React, { useEffect, useState } from "react";

import { FORM } from "src/ipm-shared/store/model/Payee/const";
import ExpandBox from "../../ExpandBox";
import IconUpload from "src/bepaid/assets/images/common/icon_upload.svg";
import FormStyles from "src/bepaid/components/Form/CommonForm.module.scss";
import InputFile from "src/bepaid/components/Form/controls/InputFile";
import Form from "src/bepaid/components/Form";
import InputText from "src/bepaid/components/Form/controls/InputText";

import CurrencyUtil from "src/ipm-shared/Utils/Currency";
// import FileUtils from "src/ipm-shared/Utils/Files";
import _isEmpty from "lodash-es/isEmpty";
import _get from "lodash-es/get";

import * as actions from "../actions";
import * as selectors from "../selectors";

import styles from "../../ExpandBox/ExpandBox.module.scss";
import SelectGroup from "src/bepaid/components/SelectGroup";
import { RootState } from "src/ipm-shared/store/model/reducers";
import { useMakePaymentUrlParams } from "src/bepaid/pages/Payments/hooks";
import { TAX_TITLE } from "../../../const";

import InputFileDropZone from "src/bepaid/components/Form/controls/InputFile/components/InputFileDropZone";

const InvoiceForm = ({
  accountCountryCode,
  accountCurrencyId,
  payee,
  extraPayeeUid,
  setExtraPayeeValue,
  validateAmount,
  checkValidNoteToRecipient,
  entity = "supplier",
  filesControl,
  paymentCurrencyCode,
  isWallex,
  selectedPayment
}: any) => {
  const [currencyId, setCurrencyId] = useState(
    isWallex
      ? CurrencyUtil.convertFromCurrencyCode(paymentCurrencyCode).id
      : accountCurrencyId
  );

  const [supportingDocuments, setSupportingDocuments] = useState<any>({
    fetch: false,
    value: []
  });

  const selectedPaymentPayee = extraPayeeUid
    ? _get(selectedPayment, "payees", []).find(
        (p: any) => p.uid === extraPayeeUid
      )
    : _get(selectedPayment, "payees", []).find((p: any) => p.id === payee.id);

  const onChangeCurrencyCustom = (e: any) => {
    setCurrencyId(e.target.value);
  };

  const setAmountValue = (id: number, e: any) => {
    const amount = e.target.value;

    if (extraPayeeUid) {
      setExtraPayeeValue(id, extraPayeeUid, "defaultAmount", amount);
    }

    validateAmount({
      amount: parseFloat(amount) * 100,
      form: FORM,
      fieldName: name,
      isWallex: isWallex
    });
  };

  const onChangeComments = (id: number, e: any) => {
    const comment = e.target.value;
    checkValidNoteToRecipient(comment, e.target.name, isWallex, entity, () => {
      if (extraPayeeUid) {
        setExtraPayeeValue(id, extraPayeeUid, "defaultComments", comment);
      }
    });
  };

  const HKD = CurrencyUtil.convertFromCurrencyCode("HKD");
  const USD = CurrencyUtil.convertFromCurrencyCode("USD");

  useEffect(() => {
    // if (!_isEmpty(filesControl)) {
    //   console.log(filesControl);
    //   console.log(payee.id);
    //   console.log(extraPayeeUid);
    //   const data = FileUtils.getInfoFromKeys(
    //     _get(
    //       filesControl,
    //       `${extraPayeeUid ? `${payee.id}_${extraPayeeUid}` : `${payee.id}`}`,
    //       []
    //     )
    //   );
    //   setSupportingDocuments({
    //     ...supportingDocuments,
    //     fetch: true,
    //     value: data
    //   });
    // } else {
    //   setSupportingDocuments({
    //     ...supportingDocuments,
    //     fetch: true
    //   });
    // }
    setSupportingDocuments({
      ...supportingDocuments,
      fetch: true
    });
  }, []);

  const amountInputTextProps = {
    labelNoColon: true,
    name: extraPayeeUid
      ? `supplier_amount_${payee.id}_${extraPayeeUid}`
      : `supplier_amount_${payee.id}`,
    placeholder: `0.00`,
    label: "Amount",
    prefix: CurrencyUtil.convertFromId(currencyId as number).$,
    form: FORM,
    pattern: "__MONEY__",
    revertValueOnMount: true,
    reserveValueOnUnmount: true,
    onChange: setAmountValue.bind(null, payee.id)
  };

  const defaultCommentsInputTextProps = {
    labelNoColon: true,
    name: extraPayeeUid
      ? `default_comments_${payee.id}_${extraPayeeUid}`
      : `default_comments_${payee.id}`,
    placeholder:
      entity === "tax"
        ? _get(TAX_TITLE[accountCountryCode], "placeholder")
        : "Description to appear on your supplier’s statement",
    label:
      entity === "tax"
        ? _get(TAX_TITLE[accountCountryCode], "label")
        : "Note to supplier",
    form: FORM,
    maxlength: 35,
    pattern: "__STATEMENT__",
    revertValueOnMount: true,
    reserveValueOnUnmount: true,
    onChange: onChangeComments.bind(null, payee.id)
  };

  return (
    <Form requiredMark={false} layout="vertical">
      <div className={styles.inputMoney}>
        <div hidden={true}>
          {accountCountryCode === "HK" && (
            <SelectGroup
              name={"hk_multiple_currency_id"}
              form={FORM}
              className={styles.inputMoneyCustomSelect}
              options={[
                { label: HKD.$, value: HKD.id },
                { label: USD.$, value: USD.id }
              ]}
              onChange={onChangeCurrencyCustom}
              value={currencyId}
              defaultValue={HKD.id}
            />
          )}
        </div>

        {selectedPaymentPayee ? (
          <InputText
            {...amountInputTextProps}
            defaultValue={_get(selectedPaymentPayee, "defaultAmount") / 100}
          />
        ) : (
          <InputText {...amountInputTextProps} defaultValue={""} />
        )}

        {/* <InputText
          labelNoColon={true}
          name={
            extraPayeeUid
              ? `supplier_amount_${payee.id}_${extraPayeeUid}`
              : `supplier_amount_${payee.id}`
          }
          placeholder={`0.00`}
          label="Amount"
          prefix={CurrencyUtil.convertFromId(currencyId as number).$}
          defaultValue={
            selectedPaymentPayee
              ? _get(selectedPaymentPayee, "defaultAmount") / 100
              : ""
          }
          form={FORM}
          pattern={"__MONEY__"}
          revertValueOnMount={true}
          reserveValueOnUnmount={true}
          onChange={setAmountValue.bind(null, payee.id)}
        /> */}
      </div>

      {selectedPaymentPayee ? (
        <InputText
          {...defaultCommentsInputTextProps}
          defaultValue={_get(selectedPaymentPayee, "defaultComments", "")}
        />
      ) : (
        <InputText {...defaultCommentsInputTextProps} defaultValue={""} />
      )}

      {/* <InputText
        labelNoColon={true}
        name={
          extraPayeeUid
            ? `default_comments_${payee.id}_${extraPayeeUid}`
            : `default_comments_${payee.id}`
        }
        placeholder={
          entity === "tax"
            ? _get(TAX_TITLE[accountCountryCode], "placeholder")
            : "Description to appear on your supplier’s statement"
        }
        label={
          entity === "tax"
            ? _get(TAX_TITLE[accountCountryCode], "label")
            : "Note to supplier"
        }
        defaultValue={_get(selectedPaymentPayee, "defaultComments", "")}
        form={FORM}
        pattern={"__STATEMENT__"}
        revertValueOnMount={true}
        reserveValueOnUnmount={true}
        onChange={onChangeComments.bind(null, payee.id)}
      /> */}

      {entity === "tax" && (
        <a
          className={styles.link}
          target="_blank"
          href={_get(TAX_TITLE[accountCountryCode], "link")}
        >
          Learn more about making a tax payment.
        </a>
      )}

      {entity !== "tax" && (
        <>
          <p className={styles.invoiceFieldInstruction}>
            <b>Invoice</b> and/or <b>supporting documents</b> (where applicable
            based on your payment nature). <b>Invoice</b> must include:
          </p>
          <ul>
            <li>
              <span>Supplier Company Name </span>
            </li>
            <li>
              <span>Description of Goods</span>
            </li>
            <li>
              <span>Invoice Amount</span>
            </li>
          </ul>
        </>
      )}
      {supportingDocuments.fetch && (
        <InputFile
          formItemLabel={
            entity === "tax"
              ? "Notice of Assessment or Statement of Account:"
              : ""
          }
          name={`supporting_documents_${
            extraPayeeUid ? `${payee.id}_${extraPayeeUid}` : payee.id
          }`}
          btnClassName={FormStyles.boxShadow}
          allowedFileTypes={["jpg", "png", "heic", "pdf"]}
          purpose="invoice"
          form={FORM}
          canDrag={true}
          multiple={true}
          label={entity === "tax" ? "Upload NOA" : "Upload invoice"}
          icon={IconUpload}
          renderComponent={InputFileDropZone}
          revertValueOnMount={true}
          reserveValueOnUnmount={true}
        />
      )}
      <InputText
        className={styles.inputDocumentTag}
        form={FORM}
        name={`document_tag_supporting_documents_${
          extraPayeeUid ? `${payee.id}_${extraPayeeUid}` : payee.id
        }`}
        defaultValue={extraPayeeUid ? `${payee.id}_${extraPayeeUid}` : payee.id}
        reserveValueOnUnmount={true}
      />
    </Form>
  );
};

const View = (props: any) => {
  const { paymentType } = useMakePaymentUrlParams();
  const isWallex = paymentType === "international";
  const expandBoxCustomProps = !isWallex
    ? {
        onClickDelete: props.onClickDeletePayee
      }
    : {};

  return (
    <ExpandBox name={props.payee.name} {...expandBoxCustomProps}>
      <InvoiceForm {...props} isWallex={isWallex} />
    </ExpandBox>
  );
};

const mapDispatchToProps = {
  validateAmount: actions.validateAmount,
  checkValidNoteToRecipient: actions.checkValidNoteToRecipient
};

const mapStateToProps = (state: RootState) => ({
  filesControl: selectors.getFileControl(
    state,
    selectors.getControlsPattern(state, /^document_tag_/),
    "document_tag_"
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(View);
