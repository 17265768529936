import React from "react";
import classNames from "classnames";
import styles from "./PaymentDetail.module.scss";
import Typography from "antd/es/typography";
import BankId from "src/ipm-shared/components/BankId";
import _get from "lodash-es/get";
import paymentUtil from "./utils/payment";
import PaymentUtil from "src/ipm-shared/Utils/Payment";

export const RecipientDetail = ({ recipient, payment }: any) => {
  const recipientTitle = paymentUtil.getRecipientTitle(payment);
  const internationalData = _get(recipient, "international", null);
  return (
    <div className={styles.rowsContainer}>
      <div className={classNames(styles.row, styles.verticalRow)}>
        <span>{recipientTitle}</span>
        <Typography.Text strong ellipsis>
          {internationalData && payment.purposeId === 5
            ? internationalData.bankAccountHolderName
            : PaymentUtil.getInsurancePayeeName(recipient.name)}
        </Typography.Text>
      </div>
      {recipient.address && (
        <div className={classNames(styles.row, styles.verticalRow)}>
          <span>Address</span>
          <Typography.Text strong ellipsis>
            {[recipient.postalCode, recipient.unitNumber, recipient.address]
              .filter(Boolean)
              .join(", ")}
          </Typography.Text>
        </div>
      )}
      <div className={classNames(styles.row, styles.verticalRow)}>
        <span>Bank</span>
        {recipient.bankId ? (
          <span>
            <BankId
              name={`${recipient.bankId}_bank`}
              valueShowOnly={true}
              defaultValue={recipient.bankId}
            />
          </span>
        ) : (
          <span>{recipient.international.bankRawName}</span>
        )}
      </div>
      <div className={classNames(styles.row, styles.verticalRow)}>
        <span>Account number</span>
        <span>{recipient.accountNumber}</span>
      </div>
      {recipient.defaultComments && (
        <div className={classNames(styles.row, styles.verticalRow)}>
          <span>Note to {recipientTitle.toLocaleLowerCase()}</span>

          <Typography.Text strong ellipsis>
            {recipient.defaultComments}
          </Typography.Text>
        </div>
      )}
    </div>
  );
};
