import { createAction } from "typesafe-actions";
import { AccountsManagerType } from "./reducer";

export const fetchAccountsManager = createAction("adminAccountsManager/FETCH");

export const setAccountsManager = createAction(
  "adminAccountsManager/SET_ACCOUNTS_MANAGER",
  resolve => (args: {
    accounts?: AccountsManagerType[];
    isFetching: boolean;
  }) => resolve(args)
);

export const assignAccountManager = createAction(
  "adminAccountsManager/ASSIGN_MANAGER",
  resolve => (args: { userId: number; accountManagerId: number }) =>
    resolve(args)
);
