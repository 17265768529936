import { RouteProps } from "react-router";
import { connect } from "react-redux";

import { RootState } from "src/ipm-shared/store/model/reducers";

import * as selectors from "src/bepaid/store/model/Fetch/selectors";
import * as actions from "src/bepaid/store/model/Fetch/actions";

import Component from "./View";

const mapStateToProps = (state: RootState) => ({
  accountCountryCode: selectors.getAccountCountryCode(state),
  accountCurrencyId: selectors.getAccountCurrencyId(state),
  accountProfile: selectors.getCurrentAccountProfile(state),
  collectedAccount: selectors.getCollectedAccount(state),
  currentCompany: selectors.getCompany(state),
  hasCompany: selectors.hasCompany(state),
  hasToken: selectors.hasToken(state),
  invoiceInsights: selectors.getInvoiceInsights(state),
  isHongKongAccount: selectors.isHongKongAccount(state),
  isSingaporeAccount: selectors.isSingaporeAccount(state),
  paymentPaidCurrencyCode: selectors.getCurrentPaidCurrency(state),
  getControl: (name: string, form: string) =>
    selectors.getControl(state, name, form)
});

const mapDispatchToProps = {
  addCompany: actions.addCompanySubmit,
  changeUserSetting: actions.changeUserSetting,
  createCollectedAccount: actions.createCollectedAccount,
  editCompany: actions.editCompanySubmit,
  exchangeToken: actions.exchangeToken,
  fetchAccountProfiles: actions.fetchAccountProfiles,
  fetchCompanies: actions.fetchCompanies,
  fetchMainCollectedAccount: actions.fetchMainCollectedAccount,
  getInvoiceInsights: actions.getInvoiceInsights,
  setControl: actions.setControl,
  parseServerErrors: actions.parseServerErrors,
  toggleModal: actions.toggleModal,
  fetchMyInfoSingPass: actions.fetchMyInfoSingPass
};

export type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteProps;

export default connect(mapStateToProps, mapDispatchToProps)(Component);
