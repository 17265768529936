import React, { useState } from "react";
import classNames from "classnames";
import { isDesktop } from "react-device-detect";

import Image from "src/bepaid/components/Image";
import Button from "src/bepaid/components/Form/controls/Button";
import IconDelete from "src/bepaid/assets/images/common/trash_big.svg";

import styles from "./CancelPaymentRequestModal.module.scss";
import { invokeMessage } from "src/bepaid/components/Message";

function CancelPaymentRequestModal(props: any) {
  const invoiceDetail = props.extraInfo;

  const [isCancelling, setIsCancelling] = useState(false);

  const handleCancelRequest = () => {
    setIsCancelling(true);
    props.cancelPaymentRequest(invoiceDetail.id, (err: any) => {
      if (!err) {
        props.toggleModal(props.modalId);
        props.getInvoiceDetail(invoiceDetail.id);
        invokeMessage("success", "Request cancelled.");
      } else {
        invokeMessage("error", "Request failed");
      }
      setIsCancelling(false);
    });
  };

  const onNo = () => {
    props.toggleModal(props.modalId);
  };

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.wrapperDesktop]: isDesktop
      })}
    >
      <div className={styles.titleContainer}>
        <Image src={IconDelete} />
        <h4 className={styles.title}>Cancel this request?</h4>
      </div>

      <div>
        <p className={styles.description}>
          This will permanently delete this payment request and it cannot be
          undone.
        </p>
      </div>

      <div className={styles.groupBtn}>
        <Button type="primary" onClick={onNo} loading={isCancelling}>
          {!isCancelling ? "No, keep request" : "Cancelling request..."}
        </Button>
        <Button
          type="secondary"
          onClick={handleCancelRequest}
          hidden={isCancelling}
        >
          Yes, cancel request
        </Button>
      </div>
    </div>
  );
}

export default CancelPaymentRequestModal;
