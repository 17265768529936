import React, { useEffect } from "react";

import { connect } from "react-redux";
import { RootState } from "src/ipm-shared/store/model/reducers";
import { history } from "src/ipm-shared/store";

import * as selectors from "src/bepaid/store/model/Fetch/selectors";
import * as actions from "src/bepaid/store/model/Fetch/actions";

import ROUTES from "../routes";

const mapStateToProps = (state: RootState) => ({
  hasIncorporationDate: selectors.getHasIncorporationDate(state),
  isSingaporeAccount: selectors.isSingaporeAccount(state),
  isBusinessAccount: selectors.getIsBusinessAccount(state)
});

const mapDispatchToProps = {
  toggleModal: actions.toggleModal,
  closeModal: actions.closeModal
};

export type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

const withRequireIncorporationDate = (
  WrapperComponent: React.ComponentType
) => {
  const HOC = (props: any) => {
    const showIncorporationBeforeCreateIntPayment = () => {
      if (
        props.isSingaporeAccount &&
        props.isBusinessAccount &&
        !props.hasIncorporationDate &&
        window.location.pathname.split("/").includes("international")
      ) {
        history.push(`${ROUTES.INCORPORATION_DATE}`);
      }
    };

    useEffect(() => {
      showIncorporationBeforeCreateIntPayment();
    }, []);

    return <WrapperComponent {...props} />;
  };

  return connect(mapStateToProps, mapDispatchToProps)(HOC);
};

export default withRequireIncorporationDate;
