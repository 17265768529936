import {
  loadStripe,
  Stripe,
  StripeConstructorOptions
} from "@stripe/stripe-js";
import CardUtils from "src/ipm-shared/Utils/Card";

const stripeObjs: {
  [key: string]: Stripe;
} = {};
export const getStripe = async (
  acquirerId: number,
  mid?: string
): Promise<Stripe | null> => {
  const key = `${acquirerId}-${mid}`;
  if (stripeObjs.hasOwnProperty(key)) {
    console.log(stripeObjs[key], "stripeObjs[key]");
    return stripeObjs[key];
  }

  let options: StripeConstructorOptions | undefined;
  if (mid) {
    options = {
      stripeAccount: mid
    };
  }

  const stripe = await loadStripe(
    CardUtils.getStripePublicKey(acquirerId),
    options
  );

  if (!stripe) {
    return null;
  }

  stripeObjs[key] = stripe;
  return stripe;
};
