import { getType } from "typesafe-actions";
import * as types from "./types";
import * as actions from "./actions";
import _isEmpty from "lodash-es/isEmpty";
import normalize from "src/ipm-shared/Utils/NormalizeObject";

import bankList from "src/ipm-shared/store/metadata/bank";
import bankBSBList from "src/ipm-shared/store/metadata/bank_bsb";

const normalizeBySwiftCode = (
  list: Array<{
    id: number;
    name: string;
    swiftCode: string;
    isDeleted: boolean;
  }>
): { [swiftCode: string]: Bank[] } => {
  const m = {};
  list.forEach(item => {
    const swiftCode = item.swiftCode.toString();
    if (m[swiftCode]) {
      m[swiftCode].push(item);
    } else {
      m[swiftCode] = [item];
    }
  });

  return m;
};

type Bank = {
  id: number;
  isDeleted: boolean;
  displayName: string | null;
  name: string;
  swiftCode: string;
  countryId: number;
};

type BankBSB = {
  id: number;
  isDeleted: boolean;
  bankId: number;
  bsb: string;
  bankCode: string;
  branchName: string;
  branchAddress: string;
  branchCity: string;
  branchState: string;
  branchPostalCode: string;
};

export type BanksState = {
  readonly isFetching: boolean;
  readonly byId: { [id: string]: Bank };
  readonly bySwiftCode: { [swiftCode: string]: Bank[] };
  readonly bankBSB: {
    readonly byId: { [id: string]: BankBSB };
  };
};

const defaultState: BanksState = {
  bankBSB: {
    byId: normalize(
      bankBSBList.map(b => ({
        bankCode: b.bank_code,
        bankId: b.bank_id,
        branchAddress: b.branch_address,
        branchCity: b.branch_city,
        branchName: b.branch_name,
        branchPostalCode: b.branch_postal_code,
        branchState: b.branch_state,
        bsb: b.bsb,
        id: b.id,
        isDeleted: Boolean(b.deleted_at)
      }))
    )
  },
  byId: normalize(
    bankList.map(b => ({
      countryId: b.country_id,
      displayName: b.display_name,
      id: b.id,
      isDeleted: b.is_deleted,
      name: b.name,
      swiftCode: b.swift_code
    }))
  ),
  bySwiftCode: normalizeBySwiftCode(
    bankList.map(b => ({
      countryId: b.country_id,
      displayName: b.display_name,
      id: b.id,
      isDeleted: b.is_deleted,
      name: b.name,
      swiftCode: b.swift_code
    }))
  ),
  isFetching: false
};

export default (state: BanksState, action: types.Action) => {
  if (_isEmpty(state)) {
    state = defaultState;
  }

  switch (action.type) {
    case getType(actions.setBanks):
      const banks = {};
      const banksSwiftCode = {};
      action.payload.banks.forEach(bank => {
        banks[bank.id] = bank;
        if (!bank.isDeleted) {
          if (!_isEmpty(banksSwiftCode[bank.swiftCode])) {
            banksSwiftCode[bank.swiftCode].push(bank);
          } else {
            banksSwiftCode[bank.swiftCode] = [bank];
          }
        }
      });
      return {
        ...state,
        byId: banks,
        bySwiftCode: banksSwiftCode
      };
  }
  return state;
};
