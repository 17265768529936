import { connect } from "react-redux";
import View from "./View";

import { RootState } from "src/ipm-shared/store/model/reducers";

import * as GlobalUISelectors from "src/ipm-shared/components/GlobalUI/selectors";
import * as selectors from "src/bepaid/store/model/Fetch/selectors";
import * as actions from "src/bepaid/store/model/Fetch/actions";

const mapStateToProps = (state: RootState, props: any) => ({
  extraInfo: GlobalUISelectors.getExtraInfo(state, props.modalId),
  selectedPayee: selectors.getSelectedPayeeDetail(state)
});

const mapDispatchToProps = {
  toggleModal: actions.toggleModal,
  selectPayee: actions.selectPayee
};

export type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(View);
