import { RouteProps } from "react-router";
import { connect } from "react-redux";

import { RootState } from "src/ipm-shared/store/model/reducers";

import * as selectors from "./selectors";
import * as actions from "./actions";

import Component from "./View";

const mapStateToProps = (state: RootState) => ({
  collectedAccount: selectors.getCollectedAccount(state),
  currencyId: selectors.getCurrentCurrencyId(state),
  currencySymbol: selectors.getCurrentCurrencySymbol(state),
  getCustomerByIds: selectors.getCustomerByIds(state),
  hasToken: selectors.hasToken(state),
  listPayments: selectors.getPayments(state),
  listSchedules: selectors.getSchedules(state),
  otherSettings: selectors.getCollectionOtherSettings(state),
  statistics: selectors.getInvoiceStatistics(state),
  taxes: selectors.getTaxes(state),
  // isFetching: selectors.shouldDisplayLoadingPayments(state),
  dashboardStatistics: selectors.getDashboardStatistics(state),
  isFirstFetchData: selectors.getFirstFetchDataByKey(state)("payments"),
  hasWallexPayment: selectors.getHasWallexPayment(state),
  totalPayment: selectors.getPaymentsTotal(state)
});

const mapDispatchToProps = {
  fetchMainCollectedAccount: actions.fetchMainCollectedAccount,
  getInvoiceList: actions.getInvoiceList,
  getInvoiceStatistics: actions.getInvoiceStatistics,
  handleCheckRemindDraftInvoice: actions.checkRemindDraftInvoice,
  selectAllItemIds: actions.selectAllItemIds,
  selectCustomers: actions.selectCustomers,
  selectInvoice: actions.selectInvoice,
  setControl: actions.setControl,
  toggleModal: actions.toggleModal,
  updateDraftInvoiceSetting: actions.updateDraftInvoiceSetting,
  fetchPayments: actions.fetchPaymentsHistoryList,
  fetchSchedules: actions.fetchSchedules,
  getDashboardStatistics: actions.getDashboardStatistics,
  setFirstTimeFetchData: actions.setFirstTimeFetchData,
  selectSchedule: actions.selectSchedule,
  getExportPayments: actions.getExportPayments
};

export type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteProps;

export default connect(mapStateToProps, mapDispatchToProps)(Component);
