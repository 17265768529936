/**
 * Actions
 *
 * ActionCreators are like the "pub" of pubsub. It's just broadcasting an event (action).
 * So, don't couple it with a specific reducer. Let ANY reducer listen to
 * an action.
 *
 * `typesafe-actions` has a nice utility function so we don't need to define action types as
 * constants. Use it together with `getType(type)` in the reducers.
 */
import { LoadingFunc } from "src/ipm-shared/Utils/ReduxSagaEffects";
import { createAction } from "typesafe-actions";
import {
  PaymentType,
  RefundType,
  EditPaymentPayeeRequest,
  ScheduleType,
  UpcomingPaymentType,
  FeesVerboseType,
  PaymentState,
  LogChargeAttempt,
  IpmMerchantType,
  InsurerType
} from "./reducers";

// Legacy
// export const fetchPayments = createAction("payments/FETCH", resolve => () =>
//   resolve()
// );
type cbType = (err?: any, data?: any) => void;

export const setSelectedPayment = createAction(
  "payments/SET_SELECTED_PAYMENT",
  resolve => (args: { payment?: PaymentType }) => resolve(args)
);

export const resetSelectedPayment = createAction("payments/RESET_SELECTED");

export const setPayments = createAction(
  "payments/SET",
  resolve => (args: {
    isFetching: boolean;
    payments?: PaymentType[];
    total?: number;
    sumPaymentAmount?: number;
    sumPaymentTotal?: number;
    totalBankPayout?: number;
    totalStripeTransactions?: number;
    totalBatch?: number;
  }) => resolve(args)
);

export const fetchDashboardPayments = createAction(
  "payments/FETCH_DASHBOARD",
  resolve => () => resolve()
);

export const appendPayments = createAction(
  "payments/APPEND",
  resolve => (args: { isFetching: boolean; payments: PaymentType[] }) =>
    resolve(args)
);

export const setRefundRequests = createAction(
  "payments/SET_PAYMENTS_REFUND",
  resolve => (args: {
    isFetching: boolean;
    refundRequests: RefundType[] | EditPaymentPayeeRequest[];
  }) => resolve(args)
);

export const fetchAdminPayments = createAction(
  "payments/ADMIN_FETCH",
  resolve => (offset: number = 0) => resolve({ offset })
);

export const adminExportPayment = createAction(
  "payments/ADMIN_EXPORT_PAYMENT",
  resolve => (type: "dbs") => resolve({ type })
);

export const adminExportDailyPayment = createAction(
  "payments/ADMIN_EXPORT_DAILY_PAYMENT"
);

export const markPaymentAsCompleted = createAction(
  "payments/ADMIN_MARK_AS_COMPLETED",
  resolve => (args: { accountId: number; paymentId: number }) => resolve(args)
);

export const markPaymentAsCompletedWithDownloadCSV = createAction(
  "payments/ADMIN_MARK_AS_COMPLETED_WITHOUT_DOWNLOAD_FILE",
  resolve => (args: { accountId: number; paymentId: number }) => resolve(args)
);

export const markBulkPaymentsAsCompleted = createAction(
  "payments/ADMIN_MARK_BULK_PAYMENTS_AS_COMPLETED",
  resolve => (
    payments: Array<{
      account_id: number;
      payment_id: number;
    }>
  ) => resolve(payments)
);

export const markBulkPaymentsAsCompletedWithDownloadCSV = createAction(
  "payments/ADMIN_MARK_BULK_PAYMENTS_AS_COMPLETED_WITH_DOWNLOAD_CSV",
  resolve => (
    payments: Array<{
      account_id: number;
      payment_id: number;
    }>
  ) => resolve(payments)
);

export const selectPayment = createAction(
  "payments/SELECT_PAYMENT",
  resolve => (
    id: number,
    cb?: cbType,
    options?: {
      withPaymentActivity?: boolean;
      idType?: string;
      withSchedule?: boolean;
    },
    loadingFunc?: LoadingFunc
  ) => resolve({ id, cb, ...(options || {}), loadingFunc })
);

export const selectNextPayment = createAction("payments/SELECT_NEXT_PAYMENT");

export const adminSelectPayment = createAction(
  "payments/ADMIN_SELECT_PAYMENT",
  resolve => (id: number) => resolve({ id })
);

export const adminSelectNextPayment = createAction(
  "payments/ADMIN_SELECT_NEXT_PAYMENT"
);

export const adminSelectRefundRequestPaymentDetail = createAction(
  "payments/SELECT_REFUND_REQUEST_PAYMENT_DETAIL",
  resolve => (id: number) => resolve({ id })
);

export const adminSelectNextRefundRequest = createAction(
  "payments/SELECT_NEXT_REFUND_REQUEST"
);

export const adminEditPaymentPayee = createAction(
  "payments/ADMIN_EDIT_PAYMENT_PAYEE",
  resolve => (isSuccess: any) => resolve({ isSuccess })
);

export const updatePaymentDetail = createAction(
  "payments/UPDATE_PAYMENT_DETAIL",
  resolve => (id: number, payment?: PaymentType) => resolve({ payment, id })
);

export const cancelSchedule = createAction(
  "payments/CANCEL_SCHEDULE",
  resolve => (
    id: number,
    cb?: (err?: any) => void,
    loadingFunc?: LoadingFunc
  ) => resolve({ id, cb, loadingFunc })
);

export const cancelPayments = createAction("payments/CANCEL_PAYMENTS");
export const cancelPayment = createAction(
  "payments/CANCEL_PAYMENT",
  resolve => (
    id: number,
    cb?: (err?: any) => void,
    loadingFunc?: LoadingFunc
  ) => resolve({ id, cb, loadingFunc })
);
export const fullyRefund = createAction(
  "payments/FULLY_REFUND",
  resolve => (id: number, holdRequestId?: number | null) =>
    resolve({ id, holdRequestId })
);

export const partialRefundPrincipal = createAction(
  "payments/PARTIAL_REFUND_PRINCIPAL",
  resolve => (
    id: number,
    payees: {},
    currentFee: number,
    holdRequestId?: number | null
  ) => resolve({ id, payees, currentFee, holdRequestId })
);

export const partialRefundFee = createAction(
  "payment/PARTIAL_REFUND_FEE",
  resolve => (id: number, holdRequestId?: number | null) =>
    resolve({ id, holdRequestId })
);

export const fetchPendingRefundRequests = createAction(
  "payments/ADMIN_GET_PENDING_REFUND_REQUESTS"
);

export const processRefund = createAction(
  "payments/PROCESS_REFUND",
  resolve => (paymentId: number, requestId: number, status: string) =>
    resolve({ paymentId, requestId, status })
);

export const expeditePayoutDate = createAction(
  "payments/EXPEDITE_PAYOUT_DATE",
  resolve => (paymentId: number) => resolve({ paymentId })
);

export const holdPayment = createAction(
  "payment/HOLD_PAYMENT",
  resolve => (paymentId: number) => resolve({ paymentId })
);

export const adminCancelPayment = createAction(
  "payment/ADMIN_CANCEL_PAYMENT",
  resolve => (paymentId: number, statusId: number, userEmail?: string) =>
    resolve({ paymentId, statusId, userEmail })
);

export const adminCheckPayment = createAction(
  "payment/CHECK_PAYMENT",
  resolve => (cb: (res: object) => void) => resolve({ cb })
);

export const setPaymentActivity = createAction(
  "payment/SET_ACTIVITY",
  resolve => (activity: PaymentState["activity"]) => resolve({ activity })
);

export const setSumPaymentInThisMonth = createAction(
  "payment/SET_AMOUNT_THIS_MONTH",
  resolve => (sumPaymentInThisMonth: PaymentState["sumPaymentInThisMonth"]) =>
    resolve({ sumPaymentInThisMonth })
);

// Scheduled Payment
export const fetchSchedules = createAction(
  "payments/FETCH_SCHEDULES",
  resolve => (
    isActive: string = "y",
    offset: number = 0,
    pagecount?: number,
    cb?: cbType,
    loadingFunc?: LoadingFunc,
    cardId?: number,
    withoutPayout?: boolean
  ) =>
    resolve({
      isActive,
      offset,
      pagecount,
      cb,
      loadingFunc,
      cardId,
      withoutPayout
    })
);

export const setSchedules = createAction(
  "payments/SET_SCHEDULES",
  resolve => (args: {
    isFetching: boolean;
    schedules: ScheduleType[];
    total?: number;
  }) => resolve(args)
);

export const setScheduleDetail = createAction(
  "payments/SET_UPCOMING_PAYMENT",
  resolve => (args: {
    payments: UpcomingPaymentType[];
    paymentSetting: PaymentType;
    lastPayout: string;
    scheduleId: number;
    scheduleFrequency: string;
    isActive: boolean;
  }) => resolve(args)
);

export const selectSchedule = createAction(
  "payments/SELECT_SCHEDULE",
  resolve => (id: number, cb?: (data: any) => void) => resolve({ id, cb })
);

export const selectNextSchedule = createAction("payments/SELECT_NEXT_SCHEDULE");

export const selectPayments = createAction(
  "payments/SELECT_PAYMENTS",
  resolve => (ids: number[]) => resolve({ ids })
);

export const editPayments = createAction(
  "payments/EDIT_PAYMENTS",
  resolve => (
    ids: number[],
    cancelDuplicatePayment: boolean,
    cb?: (err: any, data: any) => void,
    loadingFunc?: LoadingFunc,
    scheduleId?: number[],
    cardId?: number,
    isDeleteCard?: boolean,
    notReOpenScheduleDetailModal?: boolean
  ) =>
    resolve({
      ids,
      cancelDuplicatePayment,
      cb,
      loadingFunc,
      scheduleId,
      cardId,
      isDeleteCard,
      notReOpenScheduleDetailModal
    })
);

export const checkFeeVerbose = createAction(
  "payments/CHECK_FEE_VERBOSE",
  resolve => (
    cardId: number,
    couponCode: string,
    cb?: (coupon: string, fee: number) => void
  ) => resolve({ cardId, couponCode, cb })
);

export const setFeesVerbose = createAction(
  "payments/SET_FEES_VERBOSE",
  resolve => (feesVerbose: { [paymentId: number]: FeesVerboseType }) =>
    resolve({ feesVerbose })
);

export const couponUsageVerbose = createAction(
  "payments/COUPON_USAGE_VERBOSE",
  resolve => (cardId: number, cb?: (couponCode: string) => void) =>
    resolve({ cardId, cb })
);

export const validatePayee = createAction("payments/VALIDATE_PAYEE");

export const depositInternationalPayments = createAction(
  "payments/DEPOSIT_INTERNATIONAL_PAYMENT",
  resolve => (paymentId: number[]) => resolve({ paymentId })
);

export const setUpdatedPaymentStatus = createAction(
  "payments/UPDATED_PAYMENT_STATUS",
  resolve => (updatedPaymentStatus: { success: boolean; failed: boolean }) =>
    resolve({ updatedPaymentStatus })
);

export const fetchPaymentsHistoryList = createAction(
  "payments/FETCH_PAYMENTS_HISTORY_LIST",
  resolve => (
    offset: number = 0,
    paycount?: number,
    cb?: cbType,
    loadingFunc?: LoadingFunc
  ) => resolve({ offset, paycount, cb, loadingFunc })
);

export const fetchPaymentsHistoryExport = createAction(
  "payments/FETCH_PAYMENTS_HISTORY_EXPORT"
);

export const fetchLogChargeAttempt = createAction(
  "payments/FETCH_LOG_CHARGE_ATTEMPT",
  resolve => (id: number) => resolve({ id })
);

export const setLogChargeAttempt = createAction(
  "payments/SET_LOG_CHARGE_ATTEMPT",
  resolve => (logChargeAttempt: LogChargeAttempt) =>
    resolve({ logChargeAttempt })
);

export const updateMetadataPayment = createAction(
  "payments/UPDATE_METADATA_PAYMENT",
  resolve => (id: number, metadata: any, cb?: cbType) =>
    resolve({ id, metadata, cb })
);

export const setMetadataPayment = createAction(
  "payments/SET_METADATA_PAYMENT",
  resolve => (metadata: any) => resolve({ metadata })
);

export const fetchIpmMerchantCategories = createAction(
  "payments/FETCH_IPM_MERCHANT_CATEGORIES",
  resolve => (
    cardBrandId?: number,
    purposeId?: number,
    justGetData?: boolean,
    cb?: cbType
  ) => resolve({ cardBrandId, purposeId, justGetData, cb })
);

export const setIpmMerchantCategories = createAction(
  "payments/SET_IPM_MERCHANT_CATEGORIES",
  reslove => (ipmMerchantCategories: IpmMerchantType) =>
    reslove({ ipmMerchantCategories })
);

export const setInsurerList = createAction(
  "payments/SET_INSURER_LIST",
  reslove => (insurerList: InsurerType) => reslove({ insurerList })
);
