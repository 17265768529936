// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ButtonIcon_wrapper__3NiSL{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:justify;justify-content:space-between;-ms-flex-align:center;align-items:center;width:100%;min-height:50px;border:none;border-radius:.625rem;padding:.75rem;-webkit-box-shadow:0 1px 6px #00000029;box-shadow:0 1px 6px #00000029;position:relative}.ButtonIcon_wrapper__3NiSL .ButtonIcon_icon__Gupqr{position:absolute;right:.25rem;top:.25rem}", "",{"version":3,"sources":["webpack://./src/bepaid/components/Form/controls/ButtonIcon/ButtonIcon.module.scss"],"names":[],"mappings":"AAAA,2BAAS,mBAAY,CAAZ,YAAY,CAAC,sBAAkB,CAAlB,kBAAkB,CAAC,qBAA6B,CAA7B,6BAA6B,CAAC,qBAAkB,CAAlB,kBAAkB,CAAC,UAAU,CAAC,eAAe,CAAC,WAAW,CAAC,qBAAqB,CAAC,cAAc,CAAC,sCAA8B,CAA9B,8BAA8B,CAAC,iBAAiB,CAAC,mDAAe,iBAAiB,CAAC,YAAY,CAAC,UAAU","sourcesContent":[".wrapper{display:flex;flex-direction:row;justify-content:space-between;align-items:center;width:100%;min-height:50px;border:none;border-radius:.625rem;padding:.75rem;box-shadow:0 1px 6px #00000029;position:relative}.wrapper .icon{position:absolute;right:.25rem;top:.25rem}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "ButtonIcon_wrapper__3NiSL",
	"icon": "ButtonIcon_icon__Gupqr"
};
export default ___CSS_LOADER_EXPORT___;
