import React from "react";
import classNames from "classnames";

import Image from "src/bepaid/components/Image";
import Button from "src/bepaid/components/Form/controls/Button";
import Favourite from "src/bepaid/components/Favourite";

import IconClose from "src/bepaid/assets/images/common/icon_close.svg";
import ArrowBackIcon from "src/bepaid/assets/images/common/icon_arrow_back.svg";
import IconMenuDot from "src/bepaid/assets/images/common/icon_menu_dot.svg";

import styles from "./DesktopModalLayout.module.scss";

interface IProps {
  title?: React.ReactNode;
  children?: any;
  onClose?: () => void;
  onSubmit?: () => void;
  onMoreAction?: () => void;
  onFavouriteAction?: () => void;
  isFavourite?: boolean;
  isTax?: boolean;
  submitLabel?: string;
  progress?: any;
  hasBorder?: any;
  onBack?: () => void;
  maxWidth450?: boolean;
  loading?: boolean;
  submitButtonClassName?: string;
  headingClassName?: string;
  btnSubmit?: any;
  bodyClassName?: string;
  disabled?: boolean;
  noHeader?: boolean;
  id?: string;
  banner?: any;
}

const View = (props: IProps) => {
  const {
    title,
    children,
    onClose,
    onSubmit,
    onMoreAction,
    onFavouriteAction,
    isFavourite,
    isTax,
    btnSubmit = null,
    submitLabel = "Submit",
    progress,
    hasBorder = true,
    onBack,
    loading = false,
    maxWidth450 = false,
    submitButtonClassName = "",
    bodyClassName = "",
    headingClassName = "",
    disabled = false,
    noHeader = false,
    id,
    banner = null
  } = props;

  return (
    <div
      id={id}
      className={classNames(styles.wrapper, {
        [styles.maxWidth450]: maxWidth450
      })}
    >
      {!noHeader && (
        <div
          className={classNames(styles.heading, headingClassName, {
            [styles.hasBorder]: !progress && hasBorder,
            [styles.hasProgress]: progress,
            [styles.noTitle]: !Boolean(title)
          })}
        >
          {onBack && (
            <div className={styles.goBackBtn}>
              <Image onClick={onBack} src={ArrowBackIcon} />
            </div>
          )}
          {!!onFavouriteAction && (
            <div className={styles.favouriteIcon} onClick={onFavouriteAction}>
              <Favourite isFavourite={isFavourite} />
            </div>
          )}

          {title && <h1 className={styles.title}>{title}</h1>}

          {onClose && (
            <div className={styles.closeIcon}>
              <Image src={IconClose} onClick={onClose} />
            </div>
          )}

          {onMoreAction && !isTax && (
            <div className={styles.moreActionIcon}>
              <Image src={IconMenuDot} onClick={onMoreAction} />
            </div>
          )}
        </div>
      )}
      {progress && <div className={styles.progress}>{progress}</div>}
      <div
        id="modal-body-content"
        className={classNames(styles.body, bodyClassName, {
          [styles.bodyHasSubmitBtn]: Boolean(onSubmit)
        })}
      >
        {!!banner && <div className={styles.bannerContainer}>{banner}</div>}
        {children}
      </div>

      <div
        hidden={!onSubmit && !btnSubmit}
        className={classNames(styles.submitBtn, submitButtonClassName)}
      >
        {btnSubmit ? (
          btnSubmit
        ) : (
          <Button
            type="primary"
            htmlType="button"
            onClick={onSubmit}
            loading={loading || disabled}
          >
            {submitLabel}
          </Button>
        )}
      </div>
    </div>
  );
};

export default View;
