import * as React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

import { RootState } from "src/ipm-shared/store/model/reducers";
import * as selectors from "src/ipm-shared/store/model/CollectedAccount/selectors";
import IPMContext from "src/ipm-shared/Utils/IPMContext";

const REQUIRE_COLLECTED_ACCOUNT_STATES = {
  loading: "LOADING",
  redirectKyc: "REDIRECT_TO_KYC",
  open: "OPEN"
};

const requireCollectedAccountComponent = (
  Component: any,
  redirect: boolean = true,
  redirectUrl?: string
) => {
  class ComponentLoader extends React.Component<any, any> {
    public render() {
      if (!this.props.hasFetchedCollectedAccount) {
        return null;
      }

      if (!this.props.hasCollectedAccount) {
        if (redirect) {
          if (IPMContext.isPayFetchPlatform()) {
            if (redirectUrl) {
              return (
                <Redirect
                  to={`/kyc?redirect_url=${encodeURIComponent(redirectUrl)}`}
                />
              );
            }
            return <Redirect to={"/kyc"} />;
          }
          return <Redirect to={"/collected-account/create"} />;
        } else {
          return null;
        }
      }

      return (
        <>
          <Component {...this.props} />
        </>
      );
    }
  }

  return connect((state: RootState) => ({
    hasCollectedAccount: selectors.hasCollectedAccount(state),
    hasFetchedCollectedAccount: selectors.getIsFetchedMainCollectedAccount(
      state
    )
  }))(ComponentLoader);
};

const requireCollectedAccountState = ({
  hasCollectedAccount,
  hasFetchedCollectedAccount
}: any) => {
  if (!hasFetchedCollectedAccount) {
    return REQUIRE_COLLECTED_ACCOUNT_STATES.loading;
  }

  if (!hasCollectedAccount) {
    return REQUIRE_COLLECTED_ACCOUNT_STATES.redirectKyc;
  }

  return REQUIRE_COLLECTED_ACCOUNT_STATES.open;
};

export {
  requireCollectedAccountComponent,
  REQUIRE_COLLECTED_ACCOUNT_STATES,
  requireCollectedAccountState
};
