import { connect } from "react-redux";

import { RootState } from "src/ipm-shared/store/model/reducers";

import * as actions from "src/bepaid/store/model/Fetch/actions";
import * as selectors from "src/bepaid/store/model/Fetch/selectors";

import View from "./View";

const mapStateToProps = (state: RootState) => ({
  collectedAccount: selectors.getCollectedAccount(state),
  currencyId: selectors.getAccountCurrencyId(state),
  getControl: (name: string, form: string) =>
    selectors.getControl(state, name, form),
  getCurrenciesById: selectors.getCurrenciesById(state),
  getInvoiceSelected: selectors.getInvoiceSelected(state),
  getItems: selectors.getItems(state),
  getSelectedItemsIds: selectors.getSelectedItemsIds(state),
  getSelectedTaxesIds: selectors.getSelectedTaxesIds(state),
  invoiceLineItems: selectors.getInvoiceLineItems(state),
  otherSettings: selectors.getCollectionOtherSettings(state),
  taxes: selectors.getTaxes(state)
});

const mapDispatchToProps = {
  addNewItem: actions.addNewItem,
  addNewTax: actions.addNewTax,
  editItem: actions.editItem,
  editTax: actions.editTax,
  fetchItems: actions.fetchItems,
  fetchTaxes: actions.fetchTaxes,
  selectAllItemIds: actions.selectAllItemIds,
  selectTaxId: actions.selectTaxId,
  setControl: actions.setControl,
  setupInvoiceNumber: actions.setupInvoiceNumber,
  updateInvoiceLineItem: actions.updateInvoiceLineItem,
  parseServerErrors: actions.parseServerErrors
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  withRef: true
})(View);
