import Component from "./View";
import * as actions from "src/bepaid/store/model/Fetch/actions";
import * as selector from "src/bepaid/store/model/Fetch/selectors";
import { connect } from "react-redux";

import { RootState } from "src/ipm-shared/store/model/reducers";

const mapStateToProps = (state: RootState) => ({
  hasOpenedModal: selector.hasOpenedModal(state)
});

const mapDispatchToProps = {
  switchPlatform: actions.switchPlatform,
  closeModal: actions.closeModal,
  resetAllModals: actions.resetAllModals
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
