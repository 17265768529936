import React from "react";
import _get from "lodash-es/get";
import Select from "antd/es/select";
import Form from "src/bepaid/components/Form";
import { connect } from "react-redux";

import SharedSelect from "src/ipm-shared/components/Form/controls/Select";
import styles from "./Select.module.scss";
import T from "src/ipm-shared/Utils/Intl";
import * as actions from "src/ipm-shared/components/Form/actions";

import SelectDrawerView from "./SelectDrawer/View";

const Component = (props: any) => {
  const {
    name,
    options,
    label,
    defaultValue,
    helperText = null,
    formItemClassName = null,
    colon = false
  } = props;

  const renderComponent = (sharedProps: any) => {
    // INFO: props in custom component should be a higher priority
    const handleChange = (...params: any[]) => {
      if (sharedProps.onChange) {
        sharedProps.onChange(...params);
      }
      if (props.onChange) {
        props.onChange(...params);
      }
    };

    const commonProps = {
      ...sharedProps,
      ...props,
      onChange: handleChange
    };

    const { control = {} } = sharedProps;

    const { errors = [] } = control;
    const errorMessage = _get(errors, "0.code", "");
    const hasError = errors.length > 0;

    return (
      <>
        <Form.Item
          className={formItemClassName}
          label={label}
          colon={colon}
          {...(hasError
            ? {
                help: T.transl(errorMessage),
                validateStatus: "error"
              }
            : {})}
        >
          <Select {...commonProps} defaultValue={defaultValue} />
        </Form.Item>
        {helperText && (
          <p
            className={hasError ? styles.helperTextHasError : styles.helperText}
          >
            {helperText.txt}
          </p>
        )}
      </>
    );
  };

  return (
    <SharedSelect
      {...props}
      name={name}
      options={options}
      renderComponent={renderComponent}
    />
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  setControl: actions.setControl
};

export const SelectDrawer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectDrawerView);

export default connect(mapStateToProps, mapDispatchToProps)(Component);
