import { connect } from "react-redux";

import { RootState } from "src/ipm-shared/store/model/reducers";
import * as actions from "src/bepaid/store/model/Fetch/actions";
import * as selectors from "src/bepaid/store/model/Fetch/selectors";

import View from "./View";

const mapStateToProps = (state: RootState, props: any) => ({
  extraInfo: selectors.getExtraInfo(state, props.modalId),
  selectedPaymentDetail: selectors.getSelectedPaymentDetail(state),
  selectedScheduleDetail: selectors.getScheduleDetail(state)
});

const mapDispatchToProps = {
  selectPayment: actions.selectPayment,
  cancelSchedule: actions.cancelSchedule,
  cancelPayment: actions.cancelPayment
};

export type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(View);
