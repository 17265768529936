import { connect } from "react-redux";
import Component from "./View";
import { RootState } from "src/ipm-shared/store/model/reducers";
import * as selectors from "./selectors";
import * as actions from "./actions";
import * as ReactRouter from "react-router";
import { InvoicePayee } from "src/ipm-shared/store/model/Payee/reducers";
import { PayeeTypeEx } from "src/ipm-shared/store/model/Payee/selectors";
import { requireProfileComponent } from "src/ipm-shared/HOCs/RequiredProfile";

const mapStateToProps = (state: RootState, props: any) => ({
  accountCountryCode: selectors.getAccountCountryCode(state),
  accountCurrencyId: selectors.getAccountCurrencyId(state),
  accountCountryId: selectors.getAccountCountryId(state),
  paymentCurrencyCode: selectors.getPaymentCurrencyCode(state),
  currentCurrencySymbol: selectors.getCurrentCurrencySymbol(state),
  extraPayees: selectors.getExtraPayees(state),
  getCurrenciesById: selectors.getCurrenciesById(state),
  isHongKongAccount: selectors.isHongKongAccount(state),
  isSingaporeAccount: selectors.isSingaporeAccount(state),
  isWallexPayment: selectors.isWallexPayment(state),
  selectedPayees: selectors.getSelectedPayees<InvoicePayee & PayeeTypeEx>(
    state
  ),
  selectedPayeesId: selectors.selectedPayee(state),
  selectedPayment: selectors.getBepaidSelectedPaymentDetail(state),
  ipmMerchantCategories: selectors.getIpmMerchantCategories(state),
  progress: props.progress,
  titleLabel: props.titleLabel,
  onClose: props.onClose,
  getControl: (name: string, form: string) =>
    selectors.getControl(state, name, form),
  isCryptoPaymentMethod: selectors.isCryptoPaymentMethod(state)
});

const mapDispatchToProps = {
  closeModal: actions.closeModal,
  deleteExtraPayee: actions.deleteExtraPayee,
  removeControlPattern: actions.removeControlPattern,
  setControl: actions.setControl,
  setErrors: actions.setErrors,
  toggleModal: actions.toggleModal,
  updateExtraPayee: actions.updateExtraPayee,
  updateExtraPayees: actions.updateExtraPayees,
  updatePayeeCurrencyId: actions.updatePayeeCurrencyId,
  validateAmount: actions.validateAmount,
  validatePaymentPayeeSubmit: actions.validatePaymentPayeeSubmit,
  addPaymentRequestSubmit: actions.addPaymentRequestSubmit,
  fetchDateConditions: actions.fetchDateConditions,
  fetchIpmMerchantCategories: actions.fetchIpmMerchantCategories
};

export type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  ReactRouter.RouterProps &
  ReactRouter.RouteComponentProps<any>;

export default requireProfileComponent(
  connect(mapStateToProps, mapDispatchToProps)(Component)
); // Need to use requireProfileComponent because it's used in saga
