// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Card_wrapper__3bM0b .ant-card{border-radius:.75rem;-webkit-box-shadow:0 0 .375rem #00000029;box-shadow:0 0 .375rem #00000029}.Card_wrapper__3bM0b .ant-card-body{padding:1rem}", "",{"version":3,"sources":["webpack://./src/bepaid/components/Card/Card.module.scss"],"names":[],"mappings":"AAAA,+BAA2B,oBAAoB,CAAC,wCAA+B,CAA/B,gCAAgC,CAAC,oCAAgC,YAAY","sourcesContent":[".wrapper :global .ant-card{border-radius:.75rem;box-shadow:0 0 .375rem #00000029}.wrapper :global .ant-card-body{padding:1rem}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "Card_wrapper__3bM0b"
};
export default ___CSS_LOADER_EXPORT___;
