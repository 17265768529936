import * as React from "react";

const { Consumer, Provider } = React.createContext<string>("164779");

class LinkedInProvider extends React.Component<any> {
  public render() {
    return (
      <Provider value={"164779"}>{this.props.children}</Provider>
    );
  }
}

export { Consumer };
export default LinkedInProvider;
