import { RootState } from "../reducers";
import ReducerFactory from "src/ipm-shared/Utils/ReduxReducer";
import dateconditions from "./reducers";
ReducerFactory.registerReducer({ dateconditions });
import _uniq from "lodash-es/uniq";

export const getEarliestDate = (state: RootState) =>
  state.dateconditions.earliest;

export const getExclusionDates = (state: RootState) =>
  state.dateconditions.exclusions;

export const getPspNonWorkingDates = (state: RootState) =>
  state.dateconditions.pspNonWorkingDates;

export const getChargeDate = (state: RootState) =>
  state.dateconditions.chargeDate;

export const getIsChargeToday = (state: RootState) =>
  state.dateconditions.isChargeToday;

export const getInComingPayments = (state: RootState) =>
  state.dateconditions.inComingPayments;

export const getOutComingPayments = (state: RootState) =>
  _uniq(state.dateconditions.outComingPayments);

export const getFlashPayDate = (state: RootState) =>
  state.dateconditions.flashPayDate;
