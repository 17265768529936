// import { RouteProps } from "react-router";
import { connect } from "react-redux";
import * as hoc from "src/ipm-shared/HOCs";

import Component from "./View";
import { requestResendVerifyEmail } from "src/ipm-shared/store/model/Auth/actions";

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = {
  requestResendVerifyEmail
};

// export type IProps = ReturnType<typeof mapStateToProps> &
//   typeof mapDispatchToProps &
//   RouteProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(hoc.withQueryString(Component));
