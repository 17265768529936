import React from "react";

import classNames from "classnames";
import DateUtils from "src/ipm-shared/Utils/Date";

import CalendarIcon from "src/bepaid/assets/images/common/calendar.svg";

import Image from "../../Image";

import styles from "./CalendarInput.module.scss";

const CalendarInput = (props: any) => {
  const {
    icon,
    label = "Date",
    value = new Date(),
    onClick,
    btnClass,
    iconClass,
    labelClass,
    optionDisplay,
    placeHolder = ""
  } = props;

  return (
    <div className={styles.wrapper}>
      <p className={labelClass}>{label}</p>
      <button className={"btn-select-date"} onClick={onClick}>
        <div className={classNames(btnClass)}>
          <div className={classNames(styles.icon, iconClass)}>
            <Image src={icon || CalendarIcon} />
          </div>
          <span
            className={classNames({
              [styles.placeHolderText]: !!placeHolder
            })}
          >
            {placeHolder
              ? placeHolder
              : optionDisplay
              ? DateUtils.formatLocaleDate(value.toDateString(), optionDisplay)
              : value.toDateString()}
          </span>
        </div>
      </button>
    </div>
  );
};

export default CalendarInput;
