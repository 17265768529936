import { connect } from "react-redux";

import Component from "./View";
import { RootState } from "src/ipm-shared/store/model/reducers";
import * as GlobalUISelectors from "src/ipm-shared/components/GlobalUI/selectors";
import * as selectors from "src/ipm-shared/store/model/PaymentRequest/selectors";
import * as actions from "src/bepaid/store/model/Fetch/actions";

const mapStateToProps = (state: RootState, props: any) => ({
  extraInfo: GlobalUISelectors.getExtraInfo(state, props.modalId),
  paymentRequestPayees: selectors.getPaymentRequestPayees(state)
});

const mapDispatchToProps = {
  toggleModal: actions.toggleModal
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
