import React, { useState } from "react";

import ROUTES from "src/bepaid/routes";
import { history } from "src/ipm-shared/store";

import { invokeMessage } from "src/bepaid/components/Message";

import DeleteModal from "src/bepaid/components/Modal/components/DeleteModal";

const View = (props: any) => {
  const invoiceSelected = props.extraInfo;

  // delete customer modal state
  const [isLoadingDeleting, setLoadingDeleting] = useState(false);

  const closeModal = (isCancelled = false) => {
    if (props.modalId) {
      props.toggleModal(props.modalId);
    } else {
      props.onClose(isCancelled);
    }
  };

  const handleCancelDraft = () => {
    setLoadingDeleting(true);
    props.cancelDraftPayment(invoiceSelected.id, (err?: any) => {
      if (err) {
        invokeMessage("error", "Request failed.");
      } else {
        invokeMessage("success", "Draft invoice deleted.");
      }
      setLoadingDeleting(false);
      props.fetchMainCollectedAccount();
      closeModal(true);
      history.push(ROUTES.INVOICES);
    });
  };

  return (
    <DeleteModal
      title={"Delete this draft invoice?"}
      isLoading={isLoadingDeleting}
      description={
        "This will permanently delete this draft invoice and it cannot be undone."
      }
      yesLabel={"Yes, delete draft invoice"}
      yesLabelLoading={"Deleting draft invoice…"}
      noLabel={"No, keep draft invoice"}
      onYes={handleCancelDraft}
      onNo={closeModal.bind(null, false)}
    />
  );
};

export default View;
