import { RouteProps } from "react-router";
import { connect } from "react-redux";

import { RootState } from "src/ipm-shared/store/model/reducers";

import * as selectors from "src/bepaid/store/model/Fetch/selectors";
import * as actions from "src/bepaid/store/model/Fetch/actions";

import Component from "./View";

const mapStateToProps = (state: RootState) => ({
  collectedAccount: selectors.getCollectedAccount(state),
  getControl: (name: string, form: string) =>
    selectors.getControl(state, name, form),
  getDocumentTags: selectors.getControlsPattern(state, /^document_tag_/),
  getFilesControl: selectors.getFileControl(
    state,
    selectors.getControlsPattern(state, /^document_tag_/),
    "document_tag_"
  ),
  getInvoiceDraftSelected: selectors.getInvoiceDraftSelected(state),
  getInvoiceSelected: selectors.getInvoiceSelected(state),
  getSelectedCustomers: selectors.getSelectedCustomers(state),
  getSelectedCustomersId: selectors.getSelectedCustomersId(state),
  hasCustomerAlready: selectors.hasCustomerAlready(state),
  hasToken: selectors.hasToken(state),
  invoiceLineItems: selectors.getInvoiceLineItems(state),
  taxes: selectors.getTaxes(state),
  isOpenDraftPayment: selectors.isOpenDraftPayment(state)
});

const mapDispatchToProps = {
  addCustomer: actions.addCustomer,
  fetchCustomers: actions.fetchCustomers,
  fetchItems: actions.fetchItems,
  fetchMainCollectedAccount: actions.fetchMainCollectedAccount,
  fetchTaxes: actions.fetchTaxes,
  getInvoiceList: actions.getInvoiceList,
  handleCheckRemindDraftInvoice: actions.checkRemindDraftInvoice,
  removeForm: actions.removeForm,
  selectAllItemIds: actions.selectAllItemIds,
  selectCustomers: actions.selectCustomers,
  sendPaymentRequest: actions.sendPaymentRequest,
  setControl: actions.setControl,
  submitCollectionInvoice: actions.submitCollectionInvoice,
  toggleModal: actions.toggleModal,
  selectInvoice: actions.selectInvoice,
  getCustomerList: actions.getCustomerList
};

export type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteProps;

export default connect(mapStateToProps, mapDispatchToProps)(Component);
