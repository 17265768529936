import { useState } from "react";
import * as DateFns from "date-fns";

export const initDatePicker = (currentDate?: { from: any; to: any }) => ({
  currentDate: {
    from: currentDate?.from ? new Date(currentDate.from) : new Date(),
    to: currentDate?.to
      ? new Date(currentDate.to)
      : DateFns.addDays(new Date(), 1)
  },
  isOpen: false
});

export const useDatePicker = (currentDate?: { from: any; to: any }) => {
  const [datePicker, setDatePicker] = useState<{
    currentDate: any;
    isOpen: boolean;
  }>(initDatePicker(currentDate));

  const openCalendar = () => {
    setDatePicker({
      ...datePicker,
      isOpen: true
    });
  };

  const onSelectDate = (d: any) => {
    if (d) {
      if (DateFns.isSameDay(d.from, d.to)) {
        d.to = DateFns.addDays(d.from, 1);
      }

      setDatePicker({
        currentDate: d,
        isOpen: false
      });
    } else {
      setDatePicker({
        ...datePicker,
        isOpen: false
      });
    }
  };

  return {
    openCalendar,
    onSelectDate,
    datePicker,
    setDatePicker
  };
};
