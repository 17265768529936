import { ActionType } from "typesafe-actions";
import { call, put } from "redux-saga/effects";
import * as actions from "./actions";
import RestClient from "src/ipm-shared/services/Rest";
import { reTryTakeLatest } from "src/ipm-shared/Utils/ReduxSagaEffects";
import _get from "lodash-es/get";
import HttpRequestError from "src/ipm-shared/Utils/Exceptions/HttpRequestError";

const watchedSagas = [
  reTryTakeLatest(actions.fetchCurrencies, handleFetchCurrencies)
];
export default watchedSagas;
export function* handleFetchCurrencies(
  action: ActionType<typeof actions.fetchCurrencies>
) {
  yield put(
    actions.setCurrencies({
      currencies: [
        {
          code: "SGD",
          id: 1,
          symbol: "S$"
        },
        {
          code: "MYR",
          id: 2,
          symbol: "RM"
        },
        {
          code: "HKD",
          id: 3,
          symbol: "HK$"
        },
        {
          code: "USD",
          id: 73,
          symbol: "US$"
        }
      ],
      isFetching: true
    })
  );

  const res: Response = yield call(RestClient.send, {
    service: "get_currency"
  });

  if (!res) {
    yield put(
      actions.setCurrencies({
        currencies: [
          {
            code: "SGD",
            id: 1,
            symbol: "S$"
          },
          {
            code: "MYR",
            id: 2,
            symbol: "RM"
          },
          {
            code: "HKD",
            id: 3,
            symbol: "HK$"
          },
          {
            code: "USD",
            id: 73,
            symbol: "US$"
          }
        ],
        isFetching: false
      })
    );
    throw new HttpRequestError("Failed to fetch");
  }

  const data: any[] = _get(res, "data.currencies", []);

  yield put(
    actions.setCurrencies({
      currencies: data.map(c => ({
        code: c.code,
        id: c.id,
        symbol: c.prefix_symbol
      })),
      isFetching: false
    })
  );
}
