import { RouteProps } from "react-router";
import { connect } from "react-redux";
import { RootState } from "src/ipm-shared/store/model/reducers";
import * as selectors from "src/bepaid/store/model/Fetch/selectors";
import * as actions from "src/bepaid/store/model/Fetch/actions";

import Component from "./View";

const mapStateToProps = (state: RootState) => ({
  acquirerId: selectors.getPaymentCollectionAcquirerId(state),
  accountCountryId: selectors.getPaymentCollectionPaymentCountryId(state),
  getCountriesById: selectors.getCountriesById(state),
  getCurrenciesById: selectors.getCurrenciesById(state),
  isPartnershipPaymentCollectionRequest: selectors.isPartnershipPaymentCollectionRequest(
    state
  ),
  paymentCollectionRequest: selectors.getPaymentCollectionRequest(state)
});

const mapDispatchToProps = {
  fetchPaymentCollectionRequest: actions.fetchPaymentCollectionRequest
};

export type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteProps;

export default connect(mapStateToProps, mapDispatchToProps)(Component);
