import React from "react";

import styles from "./CryptoSelector.module.scss";
import Select from "antd/es/select";
import classNames from "classnames";
import ImageUtils from "src/ipm-shared/Utils/Images";
import {
  PAYMENT_METHOD_TYPES,
  PaymentMethodData
} from "src/ipm-shared/store/model/PaymentRequest/const";
const { Option } = Select;

const CryptoSelector = (props: any) => {
  const { label } = props;
  let cryptos = PaymentMethodData.filter(
    p => p.payment_method_type === PAYMENT_METHOD_TYPES.CRYPTO
  )
    .map(c => ({
      ...c,
      value: c.id
    }))
    .sort((a, b) => a.order! - b.order!);

  return (
    <label className={classNames(styles.selectLabel)}>
      <span>{label ? label : "Currency selected"}</span>
      <Select {...props} validateStatus={"error"}>
        {cryptos.map((crypto: any) => {
          const { id, value, label } = crypto;
          return (
            <Option value={value} key={id}>
              <div className={styles.option}>
                <div>
                  <img
                    alt={"crypto icon"}
                    src={ImageUtils.getCryptoTypeIcon(id)}
                  />
                  <span>{label}</span>
                </div>
              </div>
            </Option>
          );
        })}
      </Select>
    </label>
  );
};
export default CryptoSelector;
