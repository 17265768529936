/**
 * Selectors
 *
 * It's like "views" in relational databases.
 * Store the most efficient format in the reducer state.
 * But to retrieve into easy to consume form, use selector.
 */
import { RootState } from "src/ipm-shared/store/model/reducers";
import _get from "lodash-es/get";
import ReducerFactory from "src/ipm-shared/Utils/ReduxReducer";
import company, { CompanyType } from "./reducers";
ReducerFactory.registerReducer({ company });

export const getCompanies = (state: RootState) => state.company.companies;
export const getOtherCompanies = (state: RootState) =>
  state.company.companies.filter(c => !c.isCurrent);

export const shouldDisplayLoadingCompanies = (state: RootState) =>
  state.company.isFetching;

// Company
export const getCompany = (state: RootState) =>
  state.company.companies.filter(c => c.isCurrent)[0];

export const hasFetchedCompany = (state: RootState) => state.company.hasFetched;

export const getEditedCompany = (state: RootState) =>
  _get(
    state.company.companies.filter(c => c.id === state.company.editedId),
    "0",
    {}
  ) as CompanyType;

export const getPredefinedUen = (state: RootState) =>
  state.company.predefinedUen;
