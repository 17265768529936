import React from "react";
import { connect } from "react-redux";
import _get from "lodash-es/get";
import _trim from "lodash-es/trim";
import Typography from "antd/es/typography";
import Avatar from "antd/es/avatar";

import { RootState } from "src/ipm-shared/store/model/reducers";
import { FetchModalID } from "src/ipm-shared/components/GlobalUI/actions";

import * as selectors from "src/bepaid/store/model/Fetch/selectors";
import * as actions from "src/bepaid/store/model/Fetch/actions";

import AvatarDefault from "src/bepaid/assets/images/dashboard/avatar_default.svg";

import styles from "./ProfileHeading.module.scss";

const ProfileHeading = (props: any) => {
  const { accountProfile, toggleModal } = props;

  const firstName = _trim(_get(accountProfile, "displayedFirstName", ""));
  const email = _get(accountProfile, "displayedEmail", "");

  return (
    <div className={styles.profile}>
      <div onClick={() => toggleModal(FetchModalID.UPDATE_PERSONAL_INFO)}>
        <Avatar src={AvatarDefault} />
      </div>
      <div className={styles.info}>
        <Typography.Text className={styles.nameText}>{`Hello${
          firstName ? `, ${firstName}` : ""
        }!`}</Typography.Text>
        <Typography.Text className={styles.emailText}>{email}</Typography.Text>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  accountProfile: selectors.getCurrentAccountProfile(state)
});

const mapDispatchToProps = {
  toggleModal: actions.toggleModal
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileHeading);
