export type PAYMENT_ENTITY =
  | "employee"
  | "landlord"
  | "supplier"
  | "bank_account";

export const purposeByEntity = {
  landlord: "rent",
  employee: "salary",
  supplier: "invoice",
  tax: "invoice",
  self_transfer: "self_transfer",
  bank_accounts: "bank_accounts",
  business_employee: "salary_business",
  insurance: "insurance"
};

export const PAYMENT_TYPE = {
  DOMESTIC: "domestic",
  INTERNATIONAL: "international"
};

export const TITLE = {
  currency: {
    select: "Select currency"
  },
  self_transfer: {
    add: "Add bank account",
    select: "Select bank account",
    schedule: "Schedule payment",
    checkout: "Review and pay",
    bank_account_details: "Payment details"
  },
  tax: {
    payment_details: "Payment details",
    schedule: "Schedule payment",
    checkout: "Review and pay"
  },
  landlord: {
    select: "Select landlord",
    add: "Add landlord",
    add_bank_account: "Add bank account",
    schedule: "Schedule payment",
    checkout: "Review and pay"
  },
  employee: {
    select: "Select employee",
    add: "Add employee",
    schedule: "Schedule payment",
    checkout: "Review and pay"
  },
  supplier: {
    select: "Select supplier",
    add: "Add supplier",
    add_bank_account: "Add bank account",
    invoice_details: "Invoice details",
    schedule: "Schedule payment",
    checkout: "Review and pay"
  },
  company: {
    add: "Add company"
  },
  business_employee: {
    select: "Select bank account",
    add: "Add bank account",
    schedule: "Schedule payment",
    checkout: "Review and pay"
  },
  insurance: {
    insurance_details: "Payment details",
    schedule: "Schedule payment",
    checkout: "Review and pay"
  }
};

export const PROGRESS = {
  tax: {
    payment_details: 100 / 3,
    schedule: 200 / 3,
    checkout: 300 / 3
  },
  landlord: {
    select: 100 / 3,
    add: 100 / 3,
    schedule: 200 / 3,
    checkout: 300 / 3
  },
  employee: {
    select: 100 / 3,
    add: 100 / 3,
    schedule: 200 / 3,
    checkout: 300 / 3
  },
  supplier: {
    select: 100 / 4,
    add: 100 / 4,
    invoice_details: 200 / 4,
    schedule: 300 / 4,
    checkout: 400 / 4
  },
  business_employee: {
    select: 100 / 4,
    add: 100 / 4,
    salary_details: 200 / 4,
    schedule: 300 / 4,
    checkout: 400 / 4
  },
  insurance: {
    insurance_details: 100 / 3,
    schedule: 200 / 3,
    checkout: 300 / 3
  }
};
