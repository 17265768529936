// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FormGroup_wrapper__26tf5{position:relative;width:100%;margin:auto;border:.5px solid #fff;padding:1.5rem 1rem;border-radius:.625rem;-webkit-box-shadow:0 1px 6px #00000029;box-shadow:0 1px 6px #00000029}.FormGroup_wrapper__26tf5 .FormGroup_formGroupTitle__2DAu0{border-bottom:solid 1px #e4e4e4;padding-bottom:1rem;margin-bottom:1rem}", "",{"version":3,"sources":["webpack://./src/bepaid/components/FormGroup/FormGroup.module.scss"],"names":[],"mappings":"AAAA,0BAAS,iBAAiB,CAAC,UAAU,CAAC,WAAW,CAAC,sBAAsB,CAAC,mBAAmB,CAAC,qBAAqB,CAAC,sCAA6B,CAA7B,8BAA8B,CAAC,2DAAyB,+BAA+B,CAAC,mBAAmB,CAAC,kBAAkB","sourcesContent":[".wrapper{position:relative;width:100%;margin:auto;border:.5px solid #fff;padding:1.5rem 1rem;border-radius:.625rem;box-shadow:0 1px 6px #00000029}.wrapper .formGroupTitle{border-bottom:solid 1px #e4e4e4;padding-bottom:1rem;margin-bottom:1rem}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "FormGroup_wrapper__26tf5",
	"formGroupTitle": "FormGroup_formGroupTitle__2DAu0"
};
export default ___CSS_LOADER_EXPORT___;
