const scrollTopWindow = () => {
  window.scrollTo(0, 0);
};

const scrollTopElement = (element: any, top: number = 0) => {
  if (element) {
    element.scrollTo({
      top,
      behavior: "smooth"
    });
  }
};

const scrollTopElementId = (elementId: string, top: number = 0) => {
  const element = document.getElementById(elementId);
  scrollTopElement(element, top);
};

const isLoadmore = (element: any) => {
  return (
    Math.abs(element.scrollTop + element.offsetHeight - element.scrollHeight) <
    20
  );
};

export default {
  scrollTopWindow,
  scrollTopElement,
  scrollTopElementId,
  isLoadmore
};
