/* tslint:disable:object-literal-sort-keys jsx-no-lambda */
import React from "react";
import { BrowserView, MobileView } from "react-device-detect";

import { history } from "src/ipm-shared/store";
import { FetchModalID } from "src/ipm-shared/components/GlobalUI/actions";
import { PLATFORM } from "src/ipm-shared/components/GlobalUI/reducers";

import useQuery from "src/bepaid/hooks/useQuery";
import ROUTES from "src/bepaid/routes";
import Image from "src/bepaid/components/Image";
import MainLayout from "src/bepaid/layouts/MainLayout";
import ProfileHeading from "src/bepaid/layouts/MainLayout/components/ProfileHeading";
import ButtonsHeading from "src/bepaid/layouts/MainLayout/components/ButtonsHeading";
import MobileMainButton from "src/bepaid/layouts/MainLayout/components/MobileMainButton";

import InvoiceSearch from "src/bepaid/assets/images/invoices/invoices_search.svg";
import InvoiceFilter from "src/bepaid/assets/images/invoices/invoices_filter.svg";

import InvoiceSearchAndFilter from "../InvoiceSearchAndFilter";
import InvoiceListContent from "../InvoiceListContent";

import styles from "../../Invoices.module.scss";

const InvoicesMobile: React.FC<any> = props => {
  const qs = useQuery();
  const isDraftInvoice = qs.statuses === "1" && qs.key === "invoices";

  return (
    <MainLayout
      header={{
        title: isDraftInvoice ? "Draft invoices" : "Invoices",
        onBack: () =>
          isDraftInvoice
            ? history.push(ROUTES.INVOICES)
            : history.push(ROUTES.ROOT),
        rightIcons: (
          <>
            <Image
              src={InvoiceSearch}
              className={styles.icon}
              onClick={() => {
                const urlParams = new URLSearchParams(window.location.search);
                urlParams.set("key", "invoices");
                history.push({
                  pathname: `${ROUTES.SEARCH}`,
                  search: "?" + urlParams.toString()
                });
              }}
            />
            <Image
              src={InvoiceFilter}
              className={styles.icon}
              onClick={() => {
                const urlParams = new URLSearchParams(window.location.search);
                urlParams.set("key", "invoices");
                history.push({
                  pathname: `${ROUTES.FILTER}`,
                  search: "?" + urlParams.toString()
                });
              }}
            />
          </>
        ),
        hasBorder: false
      }}
      footer={<MobileMainButton platform={PLATFORM.FETCH} />}
    >
      <InvoiceListContent {...props} />
    </MainLayout>
  );
};

const InvoicesDesktop = (props: any) => {
  React.useEffect(() => {
    if (props.openCreateInvoice) {
      props.openModal(FetchModalID.CREATE_INVOICE);
    }
  }, []);

  return (
    <div>
      <MainLayout
        header={{
          leftHeader: <ProfileHeading />,
          rightHeader: <ButtonsHeading />
        }}
        body={{
          leftBody: <InvoiceListContent {...props} />,
          rightBody: <InvoiceSearchAndFilter />
        }}
      />
    </div>
  );
};

const View = (props: any) => {
  return (
    <>
      <BrowserView>
        <InvoicesDesktop {...props} />
      </BrowserView>
      <MobileView>
        <InvoicesMobile {...props} />
      </MobileView>
    </>
  );
};

export default View;
