import { ActionType } from "typesafe-actions";
import { call, put, race, select, take } from "redux-saga/effects";
import { delay } from "redux-saga";
import _get from "lodash-es/get";
import _isEmpty from "lodash-es/isEmpty";
import * as formActions from "src/ipm-shared/components/Form/actions";
import T from "src/ipm-shared/Utils/Intl";
import RestClient from "src/ipm-shared/services/Rest";
import { catchTakeEvery } from "src/ipm-shared/Utils/ReduxSagaEffects";
import { RootState } from "src/ipm-shared/store/model/reducers";
import * as actions from "./actions";
import * as formSelectors from "./selectors";
import * as paymentSelectors from "src/ipm-shared/store/model/PaymentCollectionRequest/selectors";

const selectors = {
  ...paymentSelectors,
  ...formSelectors
};

const watchedSagas = [
  // Don't change effect. It should be takeEvery because we have multiple upload at once.
  catchTakeEvery(actions.uploadFile, handleFileUpload),
  catchTakeEvery(actions.deleteFile, handleDeleteFile)
];
export default watchedSagas;

export function* handleFileUpload(
  action: ActionType<typeof actions.uploadFile>
) {
  const extraInfo = {
    fileName: action.payload.value.name,
    fileObj: action.payload.value,
    fileSize: action.payload.value.size,
    fileUrl: "",
    purpose: action.payload.purpose,
    uploading: false
  };

  yield put(
    formActions.setControl({
      extraInfo,
      form: action.payload.form,
      name: action.payload.name
    })
  );

  if (action.payload.storeFileDisabled) {
    if (action.payload.callbackSuccess) {
      action.payload.callbackSuccess("default", action.payload.name, extraInfo);
    }
    return;
  }

  extraInfo.uploading = true;

  if (action.payload.showGlobalLoader) {
    yield put(actions.showGlobalLoader());
  }

  const formData = new FormData();
  formData.append("purpose", action.payload.purpose);
  formData.append("file", action.payload.value);
  formData.append(
    "filename",
    action.payload.fileName
      ? action.payload.fileName
      : action.payload.value.name
  );
  if (action.payload.isAdmin && action.payload.accountId) {
    formData.append("account_id", action.payload.accountId.toString());
  }
  const state: RootState = yield select();
  const isCryptoPaymenCollectiontMethod = selectors.isCryptoPaymenCollectiontMethod(
    state
  );
  if (isCryptoPaymenCollectiontMethod) {
    const { paymentId } = selectors.getPaymentCollectionRequest(state);
    formData.append("payment_id", `${paymentId}`);
  }

  let cancel;
  let res;
  let timeout;

  try {
    const service = action.payload.isAdmin
      ? "admin_upload_file"
      : isCryptoPaymenCollectiontMethod
      ? "public_upload_file"
      : "upload_file";
    const raceResult: Response = yield race({
      cancel: take(`CANCEL_UPLOAD_FILE_${action.payload.name}`),
      res: call(RestClient.send, {
        body: formData,
        service,
        timeout: -1
      }),
      timeout: call(delay, 300000)
    });

    cancel = _get(raceResult, "cancel");
    timeout = _get(raceResult, "timeout");
    res = _get(raceResult, "res");
  } catch (e) {
    // Catch err_connection_refused
    timeout = true;
  }

  extraInfo.uploading = false;

  if (cancel) {
    yield put(formActions.removeControl(action.payload.name));
    return;
  }

  if (timeout) {
    yield put(
      formActions.setControl({
        errors: [
          {
            code: "ERROR_CONNECTION_TIMEOUT",
            message: T.transl("ERROR_CONNECTION_TIMEOUT")
          }
        ],
        extraInfo,
        form: action.payload.form,
        name: action.payload.name,
        value: action.payload.value.name
      })
    );
    return;
  }

  if (action.payload.showGlobalLoader) {
    yield put(actions.hideGlobalLoader());
  }

  if (!res) {
    yield put(
      formActions.setControl({
        errors: [
          {
            code: "ERROR_UPLOAD_FAILED",
            message: T.transl("ERROR_UPLOAD_FAILED")
          }
        ],
        extraInfo,
        form: action.payload.form,
        name: action.payload.name,
        value: undefined
      })
    );

    return;
  }

  const json = _get(res, "data", {});
  const errors = _get(res, "errors", undefined);

  if (!_isEmpty(errors)) {
    yield put(
      formActions.setControl({
        errors: [
          ...Object.values(errors.fields).map((value: string) => ({
            code: value,
            message: T.transl(value)
          })),
          ...errors.form.map((value: string) => ({
            code: value,
            message: T.transl(value)
          }))
        ],
        extraInfo,
        form: action.payload.form,
        name: action.payload.name, // action.payload.name
        value: undefined
      })
    );
    return;
  }

  try {
    extraInfo.fileUrl = res.url;

    yield put(
      formActions.setControl({
        errors: [],
        extraInfo,
        form: action.payload.form,
        name: action.payload.name,
        value: json.key
      })
    );

    if (action.payload.callbackSuccess) {
      action.payload.callbackSuccess(json.key, action.payload.name, extraInfo);
    }
  } catch (e) {
    window.Logger.guestError(e);
  }
}

export function* handleDeleteFile(
  action: ActionType<typeof actions.deleteFile>
) {
  const state: RootState = yield select();
  const { key = "", form = "", name = "" } = action.payload;

  let value = _get(selectors.getControl(state, name, form) as any, "value");

  // remove file from form store
  const list = value.split(",");
  if (list.includes(key)) {
    list.splice(list.indexOf(key), 1);
    value = list.length ? list.join(",") : undefined;
  }

  yield put(
    formActions.setControl({
      errors: [],
      extraInfo: {},
      form,
      name,
      value
    })
  );
}
