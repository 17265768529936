import React, { useState } from "react";
import { isDesktop } from "react-device-detect";
import Form from "src/bepaid/components/Form";
import _get from "lodash/get";

import classNames from "classnames";

import CurrencyUtil from "src/ipm-shared/Utils/Currency";
import RefundReasonData from "src/ipm-shared/store/metadata/refund_reason";
import { FetchModalID } from "src/ipm-shared/components/GlobalUI/actions";

import Button from "src/bepaid/components/Form/controls/Button";
import Select from "src/bepaid/components/Form/controls/Select";
import InputText from "src/bepaid/components/Form/controls/InputText";
import { invokeMessage } from "src/bepaid/components/Message";

import styles from "./RefundPayment.module.scss";

const FORM = "refund_payment";

function InvoiceRefundPaymentModal(props: any) {
  const invoiceDetail = props.extraInfo;

  const prefixCurrency = CurrencyUtil.convertFromId(props.currencyId as number)
    .$;

  const maximumAmount = +Number(
    _get(invoiceDetail, "payees[0].grossAmount", 0) / 100
  );

  const [loading, setLoading] = useState(false);
  const [errorAmount, setErrorAmount] = useState<any>(null);

  const [refundAmount, setRefundAmount] = useState<number | null>(
    maximumAmount
  );
  const [reason, setReason] = useState<string | null>(null);
  const [otherReason, setOtherReason] = useState<string | null>(null);

  const onChangeRefundAmount = (e: any) => {
    setRefundAmount(e.target.value ? Number(e.target.value) : null);

    if (Number(e.target.value) <= 0) {
      setErrorAmount("The refund amount must be more than zero.");
    } else if (Number(e.target.value) > +maximumAmount) {
      setErrorAmount(
        <span>
          The maximum is{" "}
          <b>
            {prefixCurrency}
            {maximumAmount.toFixed(2)}
          </b>
        </span>
      );
    } else {
      setErrorAmount(null);
    }
  };

  const onChangeReason = (key: string, v: any) => {
    if (key === "selectReason") {
      setOtherReason("");
      return setReason(v);
    }

    if (key === "otherReason") {
      return setOtherReason(v.target.value);
    }
  };

  const onSubmit = () => {
    const data = {
      otherReason,
      reason,
      refundAmount
    };

    props.refundCollectionPayment(
      invoiceDetail.id,
      {
        data,
        // tslint:disable-next-line:object-literal-sort-keys
        cb: (err?: any) => {
          if (!err) {
            props.getInvoiceDetail(invoiceDetail.id);
            invokeMessage("success", "Payment refunded.");
          } else {
            invokeMessage("error", "Request failed.");
          }
          props.toggleModal(FetchModalID.REFUND_PAYMENT);
        }
      },
      setLoading
    );
  };

  const onNo = () => {
    props.toggleModal(FetchModalID.REFUND_PAYMENT);
  };

  return (
    <>
      <div
        className={classNames(styles.wrapper, {
          [styles.wrapperDesktop]: isDesktop
        })}
      >
        <div className={styles.titleContainer}>
          <h4 className={styles.title}>Refund payment</h4>
        </div>

        <div>
          <Form
            layout="vertical"
            size={"small"}
            requiredMark={false}
            className={styles.Form}
          >
            <InputText
              className={styles.refundAmountInput}
              name={"refundAmount"}
              form={FORM}
              placeholder="0.00"
              label={"Refund amount"}
              type={"number"}
              prefix={CurrencyUtil.convertFromId(props.currencyId as number).$}
              value={refundAmount}
              defaultValue={maximumAmount}
              onChange={onChangeRefundAmount}
              forcedHasError={errorAmount !== null}
              helperText={{
                showInEmptyMode: true,
                txt: errorAmount
              }}
            />

            <Select
              name="reason_refund"
              form={FORM}
              placeholder="Select reason for refund"
              label="Reason for refund"
              onChange={onChangeReason.bind(null, "selectReason")}
              options={RefundReasonData.map(rs => ({
                label: rs.label,
                value: rs.name
              }))}
            />

            <div hidden={reason !== "other"}>
              <InputText
                type={"textarea"}
                form={FORM}
                name={"other_reason"}
                label={"Tell us more about your reason"}
                placeholder={"Type here"}
                row={4}
                onChange={onChangeReason.bind(null, "otherReason")}
              />
            </div>
          </Form>
        </div>

        <div className={styles.groupBtn}>
          <Button
            type="primary"
            onClick={onSubmit}
            loading={loading}
            disabled={
              +(refundAmount || 0) > +maximumAmount || +(refundAmount || 0) <= 0
            }
          >
            {loading ? "Refunding payment..." : "Refund payment"}
          </Button>
          <Button type="secondary" onClick={onNo}>
            Close
          </Button>
        </div>
      </div>
    </>
  );
}

export default InvoiceRefundPaymentModal;
