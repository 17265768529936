import React, { useEffect, useState, useRef } from "react";
import classNames from "classnames";
import _get from "lodash-es/get";
import * as DateFns from "date-fns";
import _isEmpty from "lodash-es/isEmpty";
import { BrowserView, MobileView } from "react-device-detect";

import Divider from "antd/es/divider";
import Form from "src/bepaid/components/Form";
import Radio from "antd/es/radio";
import Typography from "antd/es/typography";

import { FetchModalID } from "src/ipm-shared/components/GlobalUI/actions";

import CurrencyUtil from "src/ipm-shared/Utils/Currency";
import T from "src/ipm-shared/Utils/Intl";
import FormErrors from "src/ipm-shared/components/Form/helpers/Errors";
import { CREATE_INVOICE_FORM } from "src/ipm-shared/store/model/CollectedAccount/const";

import usePrevious from "src/bepaid/hooks/usePrevious";
import InputText from "src/bepaid/components/Form/controls/InputText";
import InputFile from "src/bepaid/components/Form/controls/InputFile";
import CalendarInput from "src/bepaid/components/Calendar/components/CalendarInput";
import Select, {
  SelectDrawer
} from "src/bepaid/components/Form/controls/Select";
import Calendar from "src/bepaid/components/Calendar";
import Drawer from "src/bepaid/components/Drawer";
import Button from "src/bepaid/components/Form/controls/Button";
import Skeleton from "src/bepaid/components/Skeleton";

import IconUpload from "src/bepaid/assets/images/common/icon_upload.svg";
import CalendarIcon from "src/bepaid/assets/images/common/calendar.svg";

import Additional from "./Additional";

import FormStyles from "src/bepaid/components/Form/CommonForm.module.scss";
import styles from "./EnterInvoice.module.scss";

import FileUtils from "src/ipm-shared/Utils/Files";

export const TermsOptions = [
  {
    key: "INVOICE_DATE_PLACEHOLDER",
    value: 0
  },
  {
    key: "INVOICE_DATE_NET_15_OPTION",
    value: 15
  },
  {
    key: "INVOICE_DATE_NET_30_OPTION",
    value: 30
  },
  {
    key: "INVOICE_DATE_NET_45_OPTION",
    value: 45
  },
  {
    key: "INVOICE_DATE_NET_60_OPTION",
    value: 60
  },
  {
    key: "INVOICE_DATE_CUSTOM_OPTION",
    value: -1
  }
];
const radioItems = [
  {
    label: "Upload invoice",
    description: "Upload your own invoice",
    value: "upload"
  },
  {
    label: "Use invoice template",
    description: "Use our professional invoice template",
    value: "create"
  },
  {
    label: "Send quick invoice",
    description: "Send a quick invoice with minimal detail",
    value: "quick"
  }
];
const View = (props: any) => {
  const { boxShadow } = FormStyles as any;
  const [openMoreOption] = useState(true);
  const getSelectedCustomer = _get(props.getSelectedCustomers, "[0]", null);
  const commonFormItemProps = {
    reserveValueOnUnmount: false,
    revertValueOnMount: true
  };
  const [invoiceSelected, setInvoiceSelected] = useState(
    props.getInvoiceSelected
  );

  const prevInvoiceSelected = usePrevious(props.invoiceSelected);

  useEffect(() => {
    props.getCustomerList();
  }, []);

  useEffect(() => {
    if (!props.invoiceSelected || !getSelectedCustomer) {
      return;
    }
    props.setControl({
      errors: [],
      form: CREATE_INVOICE_FORM,
      name: `supplier_amount_${getSelectedCustomer.id}`,
      value: _get(props.invoiceSelected, "paymentAmount", 0) / 100
    });
    props.setControl({
      errors: [],
      form: CREATE_INVOICE_FORM,
      name: `payment_request_id_${getSelectedCustomer.id}`,
      value: _get(props.invoiceSelected, "paymentRequestId")
    });
    setInvoiceSelected(props.invoiceSelected);
    const dueDate = _get(
      props.getControl(
        `due_date_${getSelectedCustomer.id}`,
        CREATE_INVOICE_FORM
      ),
      "value"
    );
    const invoiceDate = _get(
      props.getControl(
        `invoice_date_${getSelectedCustomer.id}`,
        CREATE_INVOICE_FORM
      ),
      "value"
    );
    const term = _get(
      props.getControl(`terms_${getSelectedCustomer.id}`, CREATE_INVOICE_FORM),
      "value"
    );

    setCalendar({
      ...calendar,
      value: {
        ...calendar.value,
        dueDate: dueDate ? new Date(dueDate) : new Date(),
        invoiceDate: invoiceDate ? new Date(invoiceDate) : new Date(),
        term: term ? term : 0
      }
    });
    setTempCalendar({
      ...calendar,
      value: {
        ...calendar.value,
        dueDate: dueDate ? new Date(dueDate) : new Date(),
        invoiceDate: invoiceDate ? new Date(invoiceDate) : new Date(),
        term: term ? term : 0
      }
    });
  }, [props.invoiceSelected, prevInvoiceSelected]);

  const [supportingDocuments, setSupportingDocuments] = useState({
    fetch: false,
    value: _get(invoiceSelected, "supportingDocuments", [])
  });
  const [optionInvoice, setOptionInvoice] = useState(
    _get(
      props.getControl(`to_create_or_upload_invoice`, CREATE_INVOICE_FORM),
      "value",
      "upload"
    )
  );
  useEffect(() => {
    const documentTags = props.getFilesControl;
    if (!_isEmpty(documentTags)) {
      const data = FileUtils.getInfoFromKeys(
        _get(documentTags, `${getSelectedCustomer.id.toString()}`, [])
      );
      setSupportingDocuments({
        ...supportingDocuments,
        fetch: true,
        value: data
      });
    } else {
      setSupportingDocuments({
        ...supportingDocuments,
        fetch: true
      });
    }
    if (!getSelectedCustomer) {
      return;
    }
    if (!invoiceSelected) {
      const dueDate = _get(
        props.getControl(
          `due_date_${getSelectedCustomer.id}`,
          CREATE_INVOICE_FORM
        ),
        "value"
      );
      const invoiceDate = _get(
        props.getControl(
          `invoice_date_${getSelectedCustomer.id}`,
          CREATE_INVOICE_FORM
        ),
        "value"
      );
      const term = _get(
        props.getControl(
          `terms_${getSelectedCustomer.id}`,
          CREATE_INVOICE_FORM
        ),
        "value"
      );
      setCalendar({
        ...calendar,
        value: {
          ...calendar.value,
          dueDate: dueDate ? new Date(dueDate) : new Date(),
          invoiceDate: invoiceDate ? new Date(invoiceDate) : new Date(),
          term: term ? term : 0
        }
      });
      setTempCalendar({
        ...calendar,
        value: {
          ...calendar.value,
          dueDate: dueDate ? new Date(dueDate) : new Date(),
          invoiceDate: invoiceDate ? new Date(invoiceDate) : new Date(),
          term: term ? term : 0
        }
      });
    } else {
      setOptionInvoice(
        _get(
          props.getControl(`to_create_or_upload_invoice`, CREATE_INVOICE_FORM),
          "value",
          "upload"
        )
      );
    }
    return;
  }, []);

  useEffect(() => {
    props.setControl({
      errors: [],
      form: CREATE_INVOICE_FORM,
      name: `customerName`,
      value: _get(getSelectedCustomer, "name")
    });
    props.setControl({
      errors: [],
      form: CREATE_INVOICE_FORM,
      name: `customer`,
      value: _get(getSelectedCustomer, "id")
    });
  }, [_get(getSelectedCustomer, "id")]);

  useEffect(() => {
    if (!getSelectedCustomer) {
      return;
    }
    props.resetFormErrors(CREATE_INVOICE_FORM);
    if (props.checkAmountDue) {
      const currentAmountDue = _get(
        props.getControl(
          `supplier_amount_${getSelectedCustomer.id}`,
          CREATE_INVOICE_FORM
        ),
        "value"
      );
      props.checkAmountDue(
        currentAmountDue,
        getSelectedCustomer.id,
        props.invoiceLineItems,
        props.taxes,
        true,
        "discount_tax_per_item"
      );
    }
  }, [props.invoiceLineItems]);
  useEffect(() => {
    if (!_isEmpty(props.getFilesControl)) {
      props.resetFormErrors(CREATE_INVOICE_FORM);
    }
  }, [_isEmpty(props.getFilesControl)]);
  const [calendar, setCalendar] = useState({
    current: "dueDate",
    isOpen: false,
    value: {
      dueDate: new Date(),
      invoiceDate: new Date(),
      term: 0
    }
  });
  const [tempCalendar, setTempCalendar] = useState({
    current: "dueDate",
    isOpen: false,
    value: {
      dueDate: new Date(),
      invoiceDate: new Date(),
      term: 0
    }
  });
  useEffect(() => {
    if (!getSelectedCustomer) {
      return;
    }
    if (!invoiceSelected) {
      return;
    }

    const payee = _get(invoiceSelected, "payees[0]");

    if (payee) {
      const invoiceSelectedOption = _get(
        invoiceSelected,
        "payees[0].to_create_or_upload_invoice",
        "upload"
      );
      setOptionInvoice(invoiceSelectedOption);
      props.setControl({
        errors: [],
        form: CREATE_INVOICE_FORM,
        name: `to_create_or_upload_invoice`,
        value: invoiceSelectedOption
      });
      setCalendar({
        ...calendar,
        value: {
          ...calendar.value,
          dueDate:
            payee.due_date && new Date(payee.due_date) > new Date()
              ? new Date(payee.due_date)
              : new Date(),
          invoiceDate:
            payee.invoice_date && new Date(payee.invoice_date) > new Date()
              ? new Date(payee.invoice_date)
              : new Date(),
          term: _get(
            TermsOptions.find(item => T.transl(item.key) === payee.terms),
            "value",
            0
          )
        }
      });
    }
  }, [_get(invoiceSelected, "payees[0]")]);
  useEffect(() => {
    if (!getSelectedCustomer) {
      return;
    }
    props.setControl({
      errors: [],
      form: CREATE_INVOICE_FORM,
      name: `due_date_${getSelectedCustomer.id}`,
      value: calendar.value.dueDate.toISOString()
    });
  }, [calendar.value.dueDate]);
  useEffect(() => {
    if (!getSelectedCustomer) {
      return;
    }
    props.setControl({
      errors: [],
      form: CREATE_INVOICE_FORM,
      name: `invoice_date_${getSelectedCustomer.id}`,
      value: calendar.value.invoiceDate.toISOString()
    });
  }, [calendar.value.invoiceDate]);
  useEffect(() => {
    if (!getSelectedCustomer) {
      return;
    }
    props.setControl({
      errors: [],
      form: CREATE_INVOICE_FORM,
      name: `terms_${getSelectedCustomer.id}`,
      value: calendar.value.term
    });
  }, [calendar.value.term]);
  const openCalendar = (type: string) => () => {
    setCalendar({
      ...calendar,
      current: type,
      isOpen: true
    });
    setTempCalendar({
      ...calendar,
      current: type,
      isOpen: true
    });
  };
  const handleSelectDate = (d: any) => {
    if (tempCalendar.current === "invoiceDate") {
      const newDueDate = calculateDueDateFromTerm(
        tempCalendar.value.term,
        tempCalendar.value.invoiceDate
      );
      setCalendar({
        ...calendar,
        current: tempCalendar.current,
        isOpen: false,
        value: {
          ...tempCalendar.value,
          dueDate: newDueDate
        }
      });
      return;
    }
    setCalendar({
      ...calendar,
      current: tempCalendar.current,
      isOpen: false,
      value: {
        ...tempCalendar.value
      }
    });
  };

  const handleOnClickDate = (d: any) => {
    setTempCalendar({
      ...tempCalendar,
      isOpen: false,
      value: {
        ...tempCalendar.value,
        [tempCalendar.current]: d,
        term: tempCalendar.current === "dueDate" ? -1 : tempCalendar.value.term
      }
    });
  };
  const handleCloseSelectDate = () => {
    setCalendar({
      ...calendar,
      isOpen: false
    });
  };

  const handleSubmitChangeTerm = (term: any) => {
    handleChangeTerm(term, false);
  };
  const calculateDueDateFromTerm = (term: any, initDate: Date) => {
    let newDueDate = initDate;
    switch (term) {
      case 0:
        newDueDate = initDate;
        break;
      case 15:
        newDueDate = DateFns.addDays(initDate, 15);
        break;
      case 30:
        newDueDate = DateFns.addDays(initDate, 30);
        break;
      case 45:
        newDueDate = DateFns.addDays(initDate, 45);
        break;
      case 60:
        newDueDate = DateFns.addDays(initDate, 60);
        break;
      default:
    }
    return newDueDate;
  };
  const handleChangeTerm = (term: any, temp: boolean = true) => {
    let newDueDate = calendar.value.dueDate;
    if (term === -1) {
      if (temp) {
        setTempCalendar({
          ...tempCalendar,
          current: "dueDate",
          isOpen: true
        });
      } else {
        setCalendar({
          ...calendar,
          current: "dueDate",
          isOpen: true
        });
      }
      return;
    } else {
      newDueDate = calculateDueDateFromTerm(term, calendar.value.invoiceDate);
    }

    if (temp) {
      setTempCalendar({
        ...tempCalendar,
        value: {
          ...tempCalendar.value,
          dueDate: newDueDate,
          term
        }
      });
    } else {
      setCalendar({
        ...calendar,
        value: {
          ...calendar.value,
          dueDate: newDueDate,
          term
        }
      });
    }
  };

  const handleChangeTermBtn = (term: number) => () => {
    handleChangeTerm(term, true);
  };
  const fileRef = useRef<any>();
  const templateRef = useRef<any>();
  const quickTemplateRef = useRef<any>();

  const handleChangeOptionInvoice = (e: any) => {
    e.preventDefault();
    const fileRefInstance = _get(fileRef, "current.wrappedInstance");
    const templateRefInstance = _get(templateRef, "current.wrappedInstance");
    const quickTemplateRefInstance = _get(
      quickTemplateRef,
      "current.wrappedInstance"
    );

    if (e.target.value === "upload") {
      if (
        fileRefInstance &&
        templateRefInstance &&
        !templateRefInstance.hasData() &&
        quickTemplateRefInstance &&
        !quickTemplateRefInstance.hasData()
      ) {
        setOptionInvoice(e.target.value);
        props.setControl({
          errors: [],
          form: CREATE_INVOICE_FORM,
          name: `to_create_or_upload_invoice`,
          value: e.target.value
        });
        return;
      }
    } else if (e.target.value === "create") {
      if (
        templateRefInstance &&
        fileRefInstance &&
        !fileRefInstance.hasFile() &&
        quickTemplateRefInstance &&
        !quickTemplateRefInstance.hasData()
      ) {
        setOptionInvoice(e.target.value);
        props.setControl({
          errors: [],
          form: CREATE_INVOICE_FORM,
          name: `to_create_or_upload_invoice`,
          value: e.target.value
        });
        return;
      }
    } else if (e.target.value === "quick") {
      if (
        quickTemplateRefInstance &&
        fileRefInstance &&
        !fileRefInstance.hasFile() &&
        templateRefInstance &&
        !templateRefInstance.hasData()
      ) {
        setOptionInvoice(e.target.value);
        props.setControl({
          errors: [],
          form: CREATE_INVOICE_FORM,
          name: `to_create_or_upload_invoice`,
          value: e.target.value
        });
        return;
      }
    }

    props.toggleModal(FetchModalID.CHANGE_OPTION_INVOICE_MODAL, {
      currentMode: optionInvoice,
      nextMode: e.target.value,
      callBackChange: (cb: any) => {
        if (e.target.value === "create") {
          fileRef.current.wrappedInstance.onClickDelete();
          quickTemplateRef.current.wrappedInstance.removeForm();
        } else if (e.target.value === "upload") {
          templateRef.current.wrappedInstance.removeForm();
          quickTemplateRef.current.wrappedInstance.removeForm();
        } else if (e.target.value === "quick") {
          templateRef.current.wrappedInstance.removeForm();
          fileRef.current.wrappedInstance.onClickDelete();
        }
        setOptionInvoice(e.target.value);
        props.setControl({
          errors: [],
          form: CREATE_INVOICE_FORM,
          name: `to_create_or_upload_invoice`,
          value: e.target.value
        });
        cb();
      }
    });
  };
  const renderUploadForm = () => (
    <InputFile
      ref={(ref: any) => {
        fileRef.current = ref;
      }}
      btnClassName={boxShadow}
      allowedFileTypes={["jpg", "png", "heic", "pdf"]}
      name={`supporting_documents_${getSelectedCustomer.id}`}
      purpose="invoice"
      form={CREATE_INVOICE_FORM}
      maxFileAmount={1}
      label={"Upload invoice"}
      icon={IconUpload}
      defaultValue={supportingDocuments.value}
      helperText={{
        showInEmptyMode: true,
        txt: (
          <span className={"purple_text"}>
            Maximum file size: <b>16 MB</b>. Supported file types:{" "}
            <b>JPG, PDF, PNG</b>.
          </span>
        )
      }}
      {...commonFormItemProps}
      revertValueOnMount={true}
      reserveValueOnUnmount={true}
    />
  );
  const renderTemplateForm = () => (
    <Additional
      ref={(ref: any) => {
        templateRef.current = ref;
      }}
      {...props}
      form={CREATE_INVOICE_FORM}
    />
  );
  const renderQuickTemplateForm = () => (
    <Additional
      ref={(ref: any) => {
        quickTemplateRef.current = ref;
      }}
      {...props}
      form={CREATE_INVOICE_FORM}
      isQuickInvoice={true}
    />
  );
  const renderInvoiceDetail = () => {
    switch (optionInvoice) {
      case "upload":
        if (supportingDocuments.fetch) {
          return (
            <>
              <div
                className={classNames(styles.moreOption, {
                  [styles.hidden]: true
                })}
              >
                {renderTemplateForm()}
                {renderQuickTemplateForm()}
              </div>
              {renderUploadForm()}
            </>
          );
        }
      case "create":
        return (
          <>
            <div className={styles.hidden}>
              {renderUploadForm()}
              {renderQuickTemplateForm()}
            </div>
            <div
              className={classNames(styles.moreOption, {
                [styles.hidden]: !openMoreOption
              })}
            >
              {renderTemplateForm()}
            </div>
          </>
        );
      case "quick":
        return (
          <>
            <div className={styles.hidden}>
              {renderUploadForm()}
              {renderTemplateForm()}
            </div>
            <div
              className={classNames(styles.moreOption, {
                [styles.hidden]: !openMoreOption
              })}
            >
              {renderQuickTemplateForm()}
            </div>
          </>
        );
      default:
        return null;
    }
  };
  if (!getSelectedCustomer) {
    return <Skeleton loading={true} />;
  }
  return (
    <div className={classNames(FormStyles.content, styles.wrapper)}>
      <Form
        layout="vertical"
        requiredMark={false}
        className={FormStyles.personalFormDetail}
      >
        <InputText
          hidden={true}
          className={styles.hidden}
          form={CREATE_INVOICE_FORM}
          name="payment_request_id"
          defaultValue={_get(invoiceSelected, "paymentRequestId", 0)}
          {...commonFormItemProps}
        />
        <InputText
          form={CREATE_INVOICE_FORM}
          name="customerName"
          placeholder="Customer name"
          label="Customer name"
          disabled={!!getSelectedCustomer.name}
          defaultValue={getSelectedCustomer.name}
          {...commonFormItemProps}
        />
        <InputText
          hidden={true}
          className={styles.hidden}
          form={CREATE_INVOICE_FORM}
          name="customer"
          defaultValue={getSelectedCustomer.id}
          {...commonFormItemProps}
          reserveValueOnUnmount={true}
        />
        <InputText
          form={CREATE_INVOICE_FORM}
          className={styles.amountInput}
          name={`supplier_amount_${getSelectedCustomer.id}`}
          placeholder="0.00"
          label="Amount due"
          type={"number"}
          prefix={CurrencyUtil.convertFromId(props.currencyId as number).$}
          pattern={"__MONEY__"}
          defaultValue={
            invoiceSelected
              ? _get(invoiceSelected, "paymentAmount", 0) / 100
              : undefined
          }
          {...commonFormItemProps}
          reserveValueOnUnmount={true}
        />
        <InputText
          hidden={true}
          className={styles.hidden}
          form={CREATE_INVOICE_FORM}
          name={`invoice_date_${getSelectedCustomer.id}`}
          defaultValue={calendar.value.invoiceDate.toISOString()}
          {...commonFormItemProps}
          reserveValueOnUnmount={true}
        />
        <InputText
          hidden={true}
          className={styles.hidden}
          form={CREATE_INVOICE_FORM}
          name={`mail_reply_to_${getSelectedCustomer.id}`}
          defaultValue={_get(
            props.collectedAccount,
            "payeeData.recipientEmail"
          )}
          {...commonFormItemProps}
          reserveValueOnUnmount={true}
        />
        <InputText
          hidden={true}
          className={styles.hidden}
          form={CREATE_INVOICE_FORM}
          name={`mail_to_${getSelectedCustomer.id}`}
          defaultValue={_get(getSelectedCustomer, "email")}
          {...commonFormItemProps}
          reserveValueOnUnmount={true}
        />
        <div className={styles.calendar}>
          <CalendarInput
            icon={CalendarIcon}
            label={"Invoice date"}
            onClick={openCalendar("invoiceDate")}
            value={calendar.value.invoiceDate}
            btnClass={styles.calendarInput}
            iconClass={styles.iconCalendar}
            optionDisplay={{
              day: "numeric",
              month: "short",
              weekday: "long",
              year: "numeric"
            }}
            {...commonFormItemProps}
          />
        </div>
        <BrowserView>
          <Select
            label={"Terms"}
            className={styles.selectInput}
            defaultValue={calendar.value.term}
            name="terms"
            onChange={handleSubmitChangeTerm}
            options={TermsOptions.map(item => {
              return {
                label: T.transl(item.key),
                value: item.value
              };
            })}
            {...commonFormItemProps}
          />
        </BrowserView>
        <MobileView>
          <SelectDrawer
            label={"Terms"}
            defaultValue={calendar.value.term}
            name="terms"
            onChange={handleSubmitChangeTerm}
            options={TermsOptions.map(item => {
              return {
                label: T.transl(item.key),
                value: item.value
              };
            })}
            {...commonFormItemProps}
          />
        </MobileView>

        <InputText
          hidden={true}
          className={styles.hidden}
          form={CREATE_INVOICE_FORM}
          name={`due_date_${getSelectedCustomer.id}`}
          defaultValue={calendar.value.dueDate.toISOString()}
          {...commonFormItemProps}
          reserveValueOnUnmount={true}
        />
        <div className={styles.calendar}>
          <CalendarInput
            icon={CalendarIcon}
            label={"Due date"}
            onClick={openCalendar("dueDate")}
            value={calendar.value.dueDate}
            btnClass={styles.calendarInput}
            iconClass={styles.iconCalendar}
            optionDisplay={{
              day: "numeric",
              month: "short",
              weekday: "long",
              year: "numeric"
            }}
            {...commonFormItemProps}
          />
        </div>
      </Form>
      <p className={styles.textInfo}>
        You can either upload your own invoice or use our professional invoice
        template.
      </p>

      <Form
        layout="vertical"
        requiredMark={false}
        className={FormStyles.personalFormDetail}
      >
        <div className={styles.wrapperOptionInvoice}>
          <Radio.Group
            className={styles.groupOptionInvoice}
            onChange={handleChangeOptionInvoice}
            value={optionInvoice}
          >
            {radioItems.map((item, index) => {
              const { label, description, value } = item;
              return (
                <>
                  <div className={styles.radioItem}>
                    <Radio value={value}>
                      <b>{label}</b>
                      <br />
                      <Typography.Text className={styles.description}>
                        {description}
                      </Typography.Text>
                    </Radio>
                  </div>
                  {optionInvoice === value && (
                    <div className={styles.itemInvoiceDetail}>
                      {renderInvoiceDetail()}
                    </div>
                  )}
                  {index < radioItems.length - 1 && (
                    <Divider className={styles.marginZero} />
                  )}
                </>
              );
            })}
          </Radio.Group>
        </div>
      </Form>
      <div id="form-error-wrapper">
        <FormErrors
          className={styles.wrapperFormError}
          form={CREATE_INVOICE_FORM}
        />
      </div>
      <InputText
        hidden={true}
        className={styles.hidden}
        form={CREATE_INVOICE_FORM}
        name={`to_create_or_upload_invoice`}
        defaultValue={optionInvoice}
        {...commonFormItemProps}
        reserveValueOnUnmount={true}
      />
      <InputText
        hidden={true}
        className={styles.hidden}
        form={CREATE_INVOICE_FORM}
        name={`calculate_method`}
        defaultValue={"discount_tax_per_item"}
        {...commonFormItemProps}
        reserveValueOnUnmount={true}
      />
      <InputText
        hidden={true}
        className={styles.hidden}
        form={CREATE_INVOICE_FORM}
        name={`document_tag_supporting_documents_${getSelectedCustomer.id}`}
        defaultValue={getSelectedCustomer.id}
        {...commonFormItemProps}
        reserveValueOnUnmount={true}
      />
      <Drawer
        height="auto"
        placement="bottom"
        visible={calendar.isOpen}
        closable={true}
        closeIcon={null}
        onClose={handleCloseSelectDate}
      >
        <p
          className={classNames(styles.titleCalendar, {
            [styles.titleCalendarDesktop]: true
          })}
        >
          {calendar.current === "invoiceDate"
            ? "Select invoice date"
            : "Select invoice due date"}
        </p>
        {calendar.current === "dueDate" && (
          <div className={styles.wrapperBtnTerms}>
            {TermsOptions.map((term: any) => {
              return (
                <div key={term.value} className={styles.wrapperBtn}>
                  <Button
                    type="secondary"
                    onClick={handleChangeTermBtn(term.value)}
                    className={classNames(styles.btnTerm, {
                      [styles.activeTerm]:
                        term.value === tempCalendar.value.term
                    })}
                  >
                    {T.transl(term.key)}
                  </Button>
                </div>
              );
            })}
          </div>
        )}
        <Calendar
          onSubmit={handleSelectDate}
          onClickDay={handleOnClickDate}
          range={null}
          disabledDays={{ before: new Date() }}
          selectDate={
            tempCalendar.current === "invoiceDate"
              ? tempCalendar.value.invoiceDate
              : tempCalendar.value.dueDate
          }
          type={"customers"}
          labelDate={
            tempCalendar.current === "invoiceDate"
              ? "Invoice Date"
              : "Invoice Due Date"
          }
        />
      </Drawer>
    </div>
  );
};

export default View;
