import * as React from "react";
import T from "src/ipm-shared/Utils/Intl";
import {
  CardNumber,
  Cvv,
  ExpiryDate,
  FrameCardValidationChangedEvent,
  FrameElement,
  FramePaymentMethodChangedEvent,
  Frames
} from "frames-react";
import FormErrors from "src/ipm-shared/components/Form/helpers/Errors";
import { ADD_CARD_FORM } from "src/ipm-shared/store/model/Card/const";
import {
  FrameCardTokenizedEvent,
  PaymentMethod
} from "frames-react/types/types";
import BaseCardFormView, { IBaseProps, IBaseState } from "../lib/BaseCardForm";
import { CardInfo } from "src/ipm-shared/store/model/Card/types";
import CardUtils from "src/ipm-shared/Utils/Card";
// import {
//   CardNumberSurffix,
//   SurffixPaymentMethod
// } from "../components/CardNumberSurffix";
import { CvvSurffix } from "../components/CvvSurffix";
import { CardFormVersion } from "../type";
import classNames from "classnames";

type IProps = IBaseProps;
type IState = IBaseState & { selectedPaymentMethod?: PaymentMethod };

class CkoCardFormView extends BaseCardFormView<IProps, IState> {
  private unmounted: boolean;

  constructor(props: IProps) {
    super(props);
    this.state = {
      cardFieldFocus: "",
      errors: {
        cardNumber: ""
      },
      flipped: false,
      readyToShowForm: false,
      selectedPaymentMethod: undefined
    };
    if (!window.CKOFramesScriptAdded) {
      const script = document.createElement("script");

      script.src = "https://cdn.checkout.com/js/framesv2.min.js";
      script.async = true;
      script.onload = () => {
        window.CKOFramesScriptLoaded = true;
        if (!this.unmounted) {
          this.setState({
            readyToShowForm: true
          });
        }
      };
      window.CKOFramesScriptAdded = true;
      document.body.appendChild(script);
    } else {
      if (!window.CKOFramesScriptLoaded) {
        let interval: number;
        interval = window.setInterval(() => {
          if (window.CKOFramesScriptLoaded) {
            this.setState({
              readyToShowForm: true
            });
            clearInterval(interval);
          }
        }, 300);
      } else {
        this.setState({
          readyToShowForm: true
        });
      }
    }
  }

  public componentDidMount() {
    super.componentDidMount();
  }

  public componentWillUnmount() {
    this.unmounted = true;
  }

  public render() {
    const {
      modalView = false,
      cardFormVersion = CardFormVersion.LEGACY
    } = this.props;

    if (!this.state.readyToShowForm) {
      return null;
    }

    const framesBaseStyles = {
      [CardFormVersion.LEGACY]: {
        base: {
          color: "#495057",
          fontSize: "14px",
          padding: "10px"
        }
      },
      [CardFormVersion.BEPAID]: {
        base: {
          fontFamily: "sans-serif",
          color: "#242424",
          fontSize: "16px",
          padding: "10px",
          height: "40px",
          borderRadius: "5px",
          letterSpacing: "0",
          fontWeight: 400
        },
        placeholder: {
          base: {
            color: "#74747B",
            letterSpacing: "0",
            fontWeight: 400
          }
        }
      }
    };

    const localization = {
      [CardFormVersion.BEPAID]: {
        cardNumberPlaceholder: "1234 1234 1234 1234",
        expiryMonthPlaceholder: "MM",
        expiryYearPlaceholder: "YY",
        cvvPlaceholder: "CVC/CVV"
      }
    };

    const isBepaid = cardFormVersion === CardFormVersion.BEPAID;

    return (
      <>
        <div className={"CkoCardForm"}>
          <div className="">
            {modalView && <h3>{T.transl("CARD_DETAILS_HEADING")}</h3>}

            {super.renderCardholderName(cardFormVersion)}

            <Frames
              config={{
                localization: localization[cardFormVersion],
                publicKey: CardUtils.getCkoPublicKey(this.props.acquirerId),
                style: framesBaseStyles[cardFormVersion]
              }}
              cardTokenized={this.onCardTokenized}
              frameFocus={this.onFrameFocus}
              frameBlur={this.onBlur}
              cardValidationChanged={this.onCardValidationChange}
              paymentMethodChanged={e => {
                this.onCardMethodChange(e);
                this.setState({ selectedPaymentMethod: e.paymentMethod });
              }}
            >
              <div className={"form-group"}>
                <span className="label">{T.transl("CARD_NUMBER_LABEL")}</span>
                <div className="surffix-container">
                  <CardNumber
                    className={classNames({
                      ["form-control--invalid"]: Boolean(
                        this.state.errors.cardNumber
                      )
                    })}
                  />
                  {/* {isBepaid && (
                    <CardNumberSurffix
                      selecetedPaymentMethod={
                        this.state.selectedPaymentMethod
                          ?.toLowerCase()
                          .trim() as SurffixPaymentMethod
                      }
                    />
                  )} */}
                </div>

                {this.state.errors.cardNumber && (
                  <span className="invalid-feedback d-block">
                    {this.state.errors.cardNumber}
                  </span>
                )}
              </div>

              <div className={"form-group"}>
                <span className="label">{T.transl("CARD_EXPIRY_LABEL")}</span>
                <ExpiryDate />
              </div>

              <div className={"form-group"}>
                <span className="label">{T.transl("CARD_CVC_LABEL")}</span>
                <div className="surffix-container">
                  <Cvv />
                  {isBepaid && <CvvSurffix />}
                </div>
              </div>

              <FormErrors form={ADD_CARD_FORM} className="fz-12 mb-3" />
            </Frames>
          </div>
        </div>
      </>
    );
  }

  // Used in wrapper
  public retokenize = async (): Promise<
    | {
        cardInfo: CardInfo;
        cardholderName?: string;
      }
    | undefined
  > => {
    return new Promise(resolve => {
      Frames.submitCard()
        .then(e => {
          resolve({
            cardInfo: {
              cardBin: e.bin,
              cardBrand: e.scheme,
              cardExpiryMonth: parseInt(e.expiry_month, 10),
              cardExpiryYear: parseInt(e.expiry_year, 10),
              cardIssuerCountry: !!e.issuer_country
                ? e.issuer_country
                : undefined,
              cardLast4: e.last4,
              cardToken: e.token,
              cardType: e.card_type
            },
            cardholderName: this.state.cardHolderName
          });
          window.Frames.enableSubmitForm();
        })
        .catch(e => {
          console.error(e);
          resolve(undefined);
        });
    });
  };

  private onFrameFocus = (e: FrameElement) => {
    let baseElementName: any = "";
    switch (e.element) {
      case "card-number":
        baseElementName = "cardNumber";
        break;
      case "expiry-date":
        baseElementName = "cardExp";
        break;
      case "cvv":
        baseElementName = "cardCvv";
        break;
    }

    this.onFocus(baseElementName);
  };

  private onCardTokenized = (e: FrameCardTokenizedEvent) => {
    this.onTokenized({
      cardBin: e.bin,
      cardBrand: e.scheme,
      cardExpiryMonth: parseInt(e.expiry_month, 10),
      cardExpiryYear: parseInt(e.expiry_year, 10),
      cardIssuerCountry: !!e.issuer_country ? e.issuer_country : undefined,
      cardLast4: e.last4,
      cardToken: e.token,
      cardType: e.card_type
    });
  };

  private onCardMethodChange = (e: FramePaymentMethodChangedEvent) => {
    this.validateCardBrand(e.paymentMethod);
  };
  private onCardValidationChange = (e: FrameCardValidationChangedEvent) => {
    this.onCardTokenizationCleared();
    if (e.isValid) {
      Frames.submitCard().then(res => {
        window.Frames.enableSubmitForm();
      });
    } else {
      // Invalid card.sth like that
    }
  };
}

export default CkoCardFormView;
