import * as React from "react";
import { IBaseProps, IBaseState } from "./lib/Base";
import _isNull from "lodash-es/isNull";
import _isUndefined from "lodash-es/isUndefined";
import BaseControl from "./lib/Base";
import { connect } from "react-redux";
import * as actions from "../actions";
import * as selectors from "../selectors";
import { RootState } from "src/ipm-shared/store/model/reducers";
import DatePicker from "src/ipm-shared/components/DatePicker";
import { format } from "date-fns";
import withPropsChecker from "./lib/withPropsChecker";
import { Placement } from "reactstrap/lib/Popper";

type IInputTextProps = IBaseProps & {
  additionalModifiers?: {
    [modifierKey: string]: Date | Date[];
  };
  allowBeforeIntialDate?: boolean;
  disableBefore?: Date | string;
  adminDisableBefore?: Date | string;
  isAdmin?: boolean;
  label?: string;
  earliestDate?: Date;
  disableBeforeDate?: Date | string;
  disableAfterDate?: Date | string;
  displayFormat?: string;
  expeditePayoutDate?: boolean;
  placeholder?: string;
  disabled?: boolean;
  formClass?: string;
  collectedPayment?: boolean;
  showOutsideDays?: boolean;
  allowWeekends?: boolean;
  fromMonth?: Date | string;
  toMonth?: Date | string;
  allowAllDays?: boolean;
  placement?: Placement;
  footerDescription?: JSX.Element;
};

const mapStateToProps = (
  state: RootState,
  props: IInputTextProps
): {
  control: ReturnType<typeof selectors.getControl>;
  disableBeforeDate?: Date;
  disableAfterDate?: Date;
} => {
  let disableBeforeDate: Date | undefined;
  if (typeof props.disableBefore === "string") {
    const referValue = selectors.getControl(state, props.disableBefore).value;
    if (referValue) {
      disableBeforeDate = new Date(referValue);
    }
  }
  return {
    control: selectors.getControl(state, props.name),
    disableBeforeDate
  };
};

const mapDispatchToProps = {
  removeControl: actions.removeControl,
  resetControlErrors: actions.resetControlErrors,
  setControl: actions.setControl
};

type IProps = IBaseProps &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  IInputTextProps & {
    disabledDates?: Date[];
    format?: string;
  };

type IState = IBaseState;

/**
 * This is one of common controls in the entire app.
 * Use this when you want to show a drop-down list.
 *
 * @base BaseControl.tsx
 */

const TODAY = new Date();

class InputDate extends BaseControl<IProps, IState> {
  public static defaultProps = {
    ...BaseControl.defaultProps
  };

  public componentDidMount() {
    let { defaultValue } = this.props;
    const { name, form, control } = this.props;

    if (this.shouldRevertValueOnMount(control)) {
      return;
    }

    if (defaultValue === "__TODAY__") {
      defaultValue = TODAY.toISOString();
    }

    // Init control
    this.props.setControl({
      errors: [],
      form,
      group: this.props.group,
      name,
      value: defaultValue
    });
  }

  public componentWillUnmount() {
    this.alive = false;

    if (!this.props.reserveValueOnUnmount) {
      this.props.removeControl(this.props.name);
    } else {
      this.props.resetControlErrors(this.props.name);
    }
  }

  public render() {
    const {
      additionalModifiers,
      control,
      disabledDates = [],
      allowBeforeIntialDate,
      disableBeforeDate,
      labelOnly,
      earliestDate,
      disableAfterDate
    } = this.props;

    if (control.notFound) {
      return null;
    }

    if (labelOnly) {
      if (this.props.labelOnlyRenderer) {
        return this.renderLabelOnly(control);
      } else {
        return (
          <span>
            {control.value && this.props.format
              ? format(new Date(control.value), this.props.format)
              : control.value}
          </span>
        );
      }
    }

    const disabled = this.props.disabled;
    return (
      <>
        <div className={this.props.className}>
          <DatePicker
            earliestDate={earliestDate}
            key={control.value as string}
            initialDate={control.value ? new Date(control.value) : undefined}
            disabledDates={disabledDates}
            onChange={this.onChange}
            allowBeforeIntialDate={allowBeforeIntialDate}
            disableBefore={disableBeforeDate}
            disableAfter={disableAfterDate}
            adminDisableBefore={this.props.adminDisableBefore}
            additionalModifiers={additionalModifiers}
            isAdmin={this.props.isAdmin}
            label={this.props.label}
            displayFormat={this.props.displayFormat}
            expeditePayoutDate={this.props.expeditePayoutDate}
            placeholder={this.props.placeholder}
            disabled={disabled}
            formClass={this.props.formClass}
            collectedPayment={this.props.collectedPayment}
            showOutsideDays={this.props.showOutsideDays}
            allowWeekends={this.props.allowWeekends}
            fromMonth={this.props.fromMonth}
            toMonth={this.props.toMonth}
            allowAllDays={this.props.allowAllDays}
            placement={this.props.placement}
            footerDescription={this.props.footerDescription}
          />
        </div>
      </>
    );
  }

  private onChange = (date: Date) => {
    const { name, form, onChangeCustom } = this.props;
    let value;
    try {
      value =
        _isUndefined(date) || _isNull(date) ? undefined : date.toISOString();
    } catch (e) {
      value = undefined;
    }
    this.props.setControl({
      errors: [],
      form,
      name,
      value
    });
    if (onChangeCustom && this.alive) {
      onChangeCustom(value);
    }
  };
}

export default withPropsChecker(
  connect(mapStateToProps, mapDispatchToProps)(InputDate),
  ["defaultValue", "earliestDate"]
);
