/* tslint:disable:jsx-no-lambda object-literal-sort-keys no-empty no-unused-expression */
import React from "react";
import * as uuidv1 from "uuid/v1";
import _get from "lodash-es/get";
import _trim from "lodash-es/trim";
import _truncate from "lodash-es/truncate";
import { MobileView, BrowserView } from "react-device-detect";
import classNames from "classnames";
import Typography from "antd/es/typography";

import { history } from "src/ipm-shared/store";
import { PLATFORM } from "src/ipm-shared/components/GlobalUI/reducers";
import {
  requireCollectedAccountState,
  REQUIRE_COLLECTED_ACCOUNT_STATES
} from "src/ipm-shared/HOCs/RequiredCollectedAccount";
import { FetchModalID } from "src/ipm-shared/components/GlobalUI/actions";

import ROUTES from "src/bepaid/routes";
import Image from "src/bepaid/components/Image";
import Drawer from "src/bepaid/components/Drawer";
import { WALLEX_KYC_STATUS } from "src/ipm-shared/store/model/OnBoarding/consts";

// import PersonalInformation from "./components/PersonalInformation";
// import ChangeEmail from "./components/ChangeEmail";
// import ChangePassword from "./components/ChangePassword";
// import Companies from "./components/Companies";
// import Wallet from "./components/Wallet";
// import CloseAccount from "./components/CloseAccount";
// import BusinessInformation from "./components/BusinessInformation";
// import AccountSettings from "./components/AccountSettings";
// import DraftInvoiceSetting from "./components/DraftInvoiceSetting";
import SwitchCompany from "./components/SwitchCompany";
import SwitchPlatform from "../../components/SwitchPlatform";

import MenuIcon from "src/bepaid/assets/images/dashboard/icon_menu.svg";
import NextIcon from "src/bepaid/assets/images/common/icon_next.svg";
import CloseIcon from "src/bepaid/assets/images/common/icon_close.svg";
import ArrowBackIcon from "src/bepaid/assets/images/common/icon_arrow_back.svg";
import FetchLogo from "src/bepaid/assets/images/fetch_logo.svg";
import PayLogo from "src/bepaid/assets/images/pay_logo.svg";

import DesktopMenuBody from "./DesktopMenuBody";
import { createMobileMenuMaps } from "./utils";

import styles from "./Menu.module.scss";

const setMenuStateObject = (
  header: { title?: any; onBack?: any } | any,
  body: any,
  footer?: any
) => ({
  header,
  body,
  footer
});

const initialValues = setMenuStateObject(null, null, "default");

const MobileMenuItem = ({
  title,
  isExpand = false,
  onClick = () => {},
  onClose = () => {},
  isLast = false
}: any) => (
  <>
    <div
      className={classNames(styles.menuItem, {
        [styles.last]: isLast
      })}
      onClick={() => {
        onClick && onClick();
        onClose && onClose();
      }}
    >
      <Typography.Text className={styles.text}>{title}</Typography.Text>
      {isExpand && <Image src={NextIcon} />}
    </div>
  </>
);

// @ts-ignore
const MobileMenuBody = ({
  menuState,
  setMenuState,
  onClickLogout,
  requireKYC,
  isBusinessAccount,
  platform,
  onClose,
  toggleModal,
  wallexKycStatus,
  isSingaporeAccount
}: any) => {
  if (menuState.body) {
    return <div className={styles.body}>{menuState.body}</div>;
  }

  const renderProfileMenu = () => {
    const subMenus = [
      {
        title: "Personal information",
        // content: <PersonalInformation />
        onClick: () => toggleModal(FetchModalID.UPDATE_PERSONAL_INFO)
      },
      {
        title: "Change email",
        onClick: () => toggleModal(FetchModalID.CHANGE_EMAIL)
      },
      {
        title: "Change password",
        onClick: () => toggleModal(FetchModalID.CHANGE_PASSWORD)
      }
    ];

    // const renderSubMenus = (title: any, content: any) => {
    //   const obj = setMenuStateObject(
    //     { title, onBack: () => setMenuState(profileMenuState) },
    //     content
    //   );
    //   setMenuState(obj);
    // };

    const profileMenuState = setMenuStateObject(
      { title: "Profile", onBack: () => setMenuState(initialValues) },
      <>
        {subMenus.map(item => (
          <MobileMenuItem
            key={uuidv1()}
            title={item.title}
            isExpand={true}
            onClick={() => item.onClick()}
          />
        ))}
      </>
    );
    setMenuState(profileMenuState);
  };

  // const renderCompaniesMenu = () => {
  //   const companiesMenuState = setMenuStateObject(
  //     { title: "Companies", onBack: () => setMenuState(initialValues) },
  //     toggleModal(FetchModalID.LIST_COMPANIES)
  //   );
  //   setMenuState(companiesMenuState);
  // };

  const renderRecipientsMenu = () => {
    const subMenus = [
      {
        title: "Landlords",
        onClick: () => history.push(ROUTES.RECIPIENTS_LANDLORDS)
      },
      {
        title: "Employees",
        onClick: () => history.push(ROUTES.RECIPIENTS_EMPLOYEES)
      },
      {
        title: "Suppliers",
        onClick: () => history.push(ROUTES.RECIPIENTS_SUPPLIERS)
      }
    ];

    if (isSingaporeAccount && wallexKycStatus === WALLEX_KYC_STATUS.APPROVED) {
      subMenus.push({
        title: "Bank accounts",
        onClick: () => history.push(ROUTES.RECIPIENTS_BANK_ACCOUNTS)
      });
    }

    const recipientsMenuState = setMenuStateObject(
      { title: "Recipients", onBack: () => setMenuState(initialValues) },
      <>
        {subMenus.map(item => (
          <MobileMenuItem
            key={uuidv1()}
            title={item.title}
            onClick={() => item.onClick()}
            onClose={onClose}
          />
        ))}
      </>
    );
    setMenuState(recipientsMenuState);
  };

  const renderInvoiceSettingsMenu = () => {
    const subMenus = [
      {
        title: "Business information",
        onClick: () => toggleModal(FetchModalID.UPDATE_BUSINESS_INFORMATION)
      },
      // { title: "Invoice template", content: null }
      {
        title: "Draft invoice",
        onClick: () => toggleModal(FetchModalID.UPDATE_DRAFT_INVOICE_SETTING)
      }
    ];

    // const renderSubMenus = (title: any, content: any) => {
    //   const obj = setMenuStateObject(
    //     { title, onBack: () => setMenuState(invoiceSettingsMenuState) },
    //     content
    //   );
    //   setMenuState(obj);
    // };

    const invoiceSettingsMenuState = setMenuStateObject(
      {
        title: "Invoice settings",
        onBack: () => setMenuState(initialValues)
      },
      <>
        {subMenus.map(item => (
          <MobileMenuItem
            key={uuidv1()}
            title={item.title}
            onClick={() => item.onClick()}
            isExpand={true}
          />
        ))}
      </>
    );
    setMenuState(invoiceSettingsMenuState);
  };

  const fetchMenuMaps = [
    { title: "Dashboard", onClick: () => history.push(ROUTES.ROOT) },
    { title: "Invoices", onClick: () => history.push(ROUTES.INVOICES) },
    { title: "Customers", onClick: () => history.push(ROUTES.CUSTOMERS) },
    { title: "Insights", onClick: () => history.push(ROUTES.INSIGHTS) },
    {
      title: "Profile",
      onClick: () => {
        renderProfileMenu();
      },
      isExpand: true
    },
    {
      title: "Companies",
      onClick: () => toggleModal(FetchModalID.LIST_COMPANIES),
      isExpand: true
    },
    {
      title: "Fetch settings",
      onClick: () => toggleModal(FetchModalID.UPDATE_ACCOUNT_SETTINGS),
      isExpand: true
    },
    {
      title: "Invoice settings",
      onClick: () => {
        renderInvoiceSettingsMenu();
      },
      isExpand: true
    },
    {
      title: "Support",
      onClick: () => window.open(ROUTES.SUPPORT, "_blank")
    },
    { title: "Sign out", onClick: onClickLogout }
  ];

  const payMenuMaps = [
    { title: "Dashboard", onClick: () => history.push(ROUTES.ROOT) },
    { title: "Payments", onClick: () => history.push(ROUTES.PAYMENTS) },
    {
      title: "Recipients",
      onClick: () => {
        renderRecipientsMenu();
      },
      isExpand: true
    },
    { title: "Insights", onClick: () => history.push(ROUTES.INSIGHTS_PAY) },
    {
      title: "Profile",
      onClick: () => {
        renderProfileMenu();
      },
      isExpand: true
    },
    {
      title: "Wallet",
      onClick: () => toggleModal(FetchModalID.LIST_CARD_MODAL),
      isExpand: true
    },
    { title: "Referrals", onClick: () => history.push(ROUTES.REFERRAL) },
    {
      title: "Support",
      onClick: () => window.open(ROUTES.SUPPORT, "_blank")
    },
    { title: "Sign out", onClick: onClickLogout }
  ];

  const { transformedMenus: bodyMenuMaps } = createMobileMenuMaps(
    platform === PLATFORM.PAY
      ? !isBusinessAccount
        ? payMenuMaps.filter(menu => menu.title !== "Companies")
        : payMenuMaps
      : fetchMenuMaps
  );

  return (
    <div className={styles.body}>
      {bodyMenuMaps.map((item, index) => (
        <MobileMenuItem
          key={uuidv1()}
          {...item}
          isLast={index === bodyMenuMaps.length - 1}
          onClose={!_get(item, "isExpand", false) && onClose}
        />
      ))}
    </div>
  );
};

const MobileMenu = (mobileMenuProps: any) => {
  const {
    accountProfile,
    companies,
    company,
    onClickLogout,
    requireKYC,
    isBusinessAccount,
    platform,
    toggleModal,
    wallexKycStatus,
    isSingaporeAccount
  } = mobileMenuProps;

  const [menuVisible, setMenuVisible] = React.useState(false);
  const [switchCompanyVisible, setSwitchCompanyVisible] = React.useState(false);

  const handleMenuOpen = () => {
    setMenuVisible(true);
  };

  const handleMenuClose = () => {
    setMenuVisible(false);
    setMenuState(initialValues);
  };

  const [menuState, setMenuState] = React.useState(initialValues);

  const MobileMenuHeader = () => {
    const firstName = _trim(_get(accountProfile, "displayedFirstName", ""));
    const email = _get(accountProfile, "displayedEmail", "");
    const companyName = _get(company, "name", "");

    if (menuState.header) {
      return (
        <div className={classNames(styles.header, styles.horizontal)}>
          {menuState.header.onBack && (
            <Image
              onClick={() => {
                menuState.header.onBack();
              }}
              src={ArrowBackIcon}
            />
          )}
          {menuState.header.title && (
            <div
              className={classNames(styles.title, {
                [styles.hasBackIcon]: !!menuState.header.onBack
              })}
            >
              {menuState.header.title}
            </div>
          )}
        </div>
      );
    }

    return (
      <div className={styles.header}>
        <div className={styles.profile}>
          <Typography.Text className={styles.nameText}>{`Hello${
            firstName ? `, ${firstName}` : ""
          }!`}</Typography.Text>
          <Typography.Text className={styles.emailText}>
            {email}
          </Typography.Text>
        </div>
        <div className={styles.company}>
          <Typography.Text className={styles.text}>
            {_truncate(companyName, { length: 15 })}
          </Typography.Text>
          {/*Hide the "Switch company" button when the user has only one company*/}
          {companies.length > 1 && (
            <Typography.Text
              className={styles.text}
              // @ts-ignore
              onClick={() => setSwitchCompanyVisible(true)}
            >
              Switch company
            </Typography.Text>
          )}
        </div>
        <div className={styles.switchPlatform}>
          <SwitchPlatform />
        </div>
      </div>
    );
  };

  // const renderMobileMenuFooter = () => {
  //   const renderCloseAccount = () => {
  //     const closeAccountState = setMenuStateObject(
  //       { title: "Close account", onBack: () => setMenuState(initialValues) },
  //       <CloseAccount handleKeepAccount={() => setMenuState(initialValues)} />
  //     );
  //     setMenuState(closeAccountState);
  //   };

  //   return menuState.footer === "default" ? (
  //     <div className={styles.footer} onClick={renderCloseAccount}>
  //       <Typography.Text className={styles.text}>Close account</Typography.Text>
  //     </div>
  //   ) : (
  //     menuState.footer
  //   );
  // };

  return (
    <>
      <div className={styles.menuIcon}>
        <Image src={MenuIcon} onClick={handleMenuOpen} />
      </div>
      <Drawer
        title={<MobileMenuHeader />}
        // footer={renderMobileMenuFooter()}
        className={styles.wrapper}
        closable={true}
        showCloseIcon={false}
        closeIcon={<Image src={CloseIcon} />}
        onClose={handleMenuClose}
        visible={menuVisible}
        roundedBorder={false}
      >
        <MobileMenuBody
          isBusinessAccount={isBusinessAccount}
          platform={platform}
          menuState={menuState}
          setMenuState={setMenuState}
          onClickLogout={onClickLogout}
          requireKYC={requireKYC}
          onClose={handleMenuClose}
          toggleModal={toggleModal}
          wallexKycStatus={wallexKycStatus}
          isSingaporeAccount={isSingaporeAccount}
        />
      </Drawer>
      {/* Do not render the "Switch company" section if the user has only one company */}
      {companies.length > 1 && (
        <SwitchCompany
          visible={switchCompanyVisible}
          onClose={() => setSwitchCompanyVisible(false)}
        />
      )}
    </>
  );
};

const DesktopMenu = (desktopMenuProps: any) => {
  const {
    companies,
    company,
    onClickLogout,
    toggleModal,
    requireKYC,
    platform,
    switchPlatform,
    openedModalIds,
    wallexKycStatus,
    isSingaporeAccount,
    accountUserRoleId
  } = desktopMenuProps;

  const DesktopMenuHeader = () => (
    <div className={styles.header}>
      <Image
        src={platform === PLATFORM.PAY ? PayLogo : FetchLogo}
        className={styles.logo}
      />
    </div>
  );

  // const DesktopMenuFooter = () => (
  //   <div className={styles.footer}>
  //     <Typography.Link className={styles.text}>Close account</Typography.Link>
  //   </div>
  // );

  return (
    <div className={classNames(styles.wrapper, styles.desktop)}>
      <DesktopMenuHeader />
      <div className={styles.switchPlatform}>
        <SwitchPlatform />
      </div>
      <DesktopMenuBody
        // @ts-ignore
        platform={platform}
        company={company}
        companies={companies}
        toggleModal={toggleModal}
        requireKYC={requireKYC}
        onClickLogout={onClickLogout}
        switchPlatform={switchPlatform}
        openedModalIds={openedModalIds}
        wallexKycStatus={wallexKycStatus}
        isSingaporeAccount={isSingaporeAccount}
        accountUserRoleId={accountUserRoleId}
        match={{
          url: window.location.pathname
        }}
      />
      {/*<DesktopMenuFooter />*/}
    </div>
  );
};

const MenuView: React.FC<any> = props => {
  const handleClickLogout = () => {
    props.logout((err?: any) => {
      if (!err) {
        history.push(ROUTES.LOGIN);
      }
    });
  };

  const requireKYC = (done: () => void, redirectUrl?: string) => {
    const requiredCollectedState = requireCollectedAccountState({
      hasCollectedAccount: props.hasCollectedAccount,
      hasFetchedCollectedAccount: props.hasFetchedCollectedAccount
    });

    if (requiredCollectedState === REQUIRE_COLLECTED_ACCOUNT_STATES.open) {
      done();
    }

    if (
      requiredCollectedState === REQUIRE_COLLECTED_ACCOUNT_STATES.redirectKyc
    ) {
      if (redirectUrl) {
        history.push(
          `${ROUTES.KYC}?redirect_url=${encodeURIComponent(redirectUrl)}`
        );
      } else {
        history.push(`${ROUTES.KYC}`);
      }
    }
  };

  return (
    <>
      <BrowserView>
        <DesktopMenu
          {...props}
          onClickLogout={handleClickLogout}
          requireKYC={requireKYC}
        />
      </BrowserView>
      <MobileView>
        <MobileMenu
          {...props}
          onClickLogout={handleClickLogout}
          requireKYC={requireKYC}
        />
      </MobileView>
    </>
  );
};

export default MenuView;
