import { connect } from "react-redux";

import { RootState } from "src/ipm-shared/store/model/reducers";

import View from "./View";
import * as actions from "src/bepaid/store/model/Fetch/actions";
import * as selectors from "src/bepaid/store/model/Fetch/selectors";

const mapStateToProps = (state: RootState, props: any) => ({
  canAccessCryptoPayment: selectors.canAccessCryptoPayment(state),
  hasIdVerification: selectors.getHasIdVerification(state),
  isSingaporeAccount: selectors.isSingaporeAccount(state)
});

const mapDispatchToProps = {
  setPaymentMethodType: actions.setPaymentMethodType
};

export type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(View);
