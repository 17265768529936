import React, { useEffect, useState } from "react";
import _get from "lodash-es/get";
import { isDesktop } from "react-device-detect";

import { FORM } from "src/ipm-shared/store/model/Payee/const";
import store from "src/ipm-shared/store";
import { getBanksOptionsByCountryId } from "src/ipm-shared/store/model/Bank/selectors";
import T from "src/ipm-shared/Utils/Intl";

import Form from "src/bepaid/components/Form";
import InputText from "src/bepaid/components/Form/controls/InputText";
import Image from "src/bepaid/components/Image";
import InputFile from "src/bepaid/components/Form/controls/InputFile";
import InputFileDropZone from "src/bepaid/components/Form/controls/InputFile/components/InputFileDropZone";
import Select from "src/bepaid/components/Form/controls/Select";
import FormErrors from "src/ipm-shared/components/Form/helpers/Errors";
import Skeleton from "src/bepaid/components/Skeleton";
import SearchCompanyForm from "src/bepaid/components/SearchCompanyForm";

import IconArrowDown from "src/bepaid/assets/images/common/drop_down_arrow_grey.svg";
import IconUpload from "src/bepaid/assets/images/common/icon_upload.svg";

import CountryUtil from "src/ipm-shared/Utils/Country";
import BankBSB from "src/bepaid/components/BankBSB";
import CurrencyUtil from "src/ipm-shared/Utils/Currency";

import styles from "../../RecipientModal.module.scss";

export const RecipientEmployeeForm = (props: any) => {
  const {
    countryId,
    editMode,
    editedId,
    fetchPayees,
    initName,
    isBizSalary
  } = props;
  const [loading, setLoading] = useState(false);
  const [editedPayee, setEditedPayee] = useState(null);

  const filterBank = getBanksOptionsByCountryId(store.getState(), countryId);

  const descriptionPayslip = CountryUtil.getPayslipEmployeeFromCountryId(
    countryId
  );

  const countryCode = CountryUtil.getCountryCodeFromCountryId(countryId);

  const getEmployeeIDText = () => {
    let employeeIDText = "";
    switch (countryCode) {
      case "MY":
        employeeIDText = "PAYSLIP_EMPLOYEE_ID_MYKAD";
        break;
      case "HK":
        employeeIDText = "PAYSLIP_EMPLOYEE_ID_HKID";
        break;
      case "AU":
        employeeIDText = "PAYSLIP_EMPLOYEE_ID";
        break;

      case "SG":
      default:
        employeeIDText = "PAYSLIP_EMPLOYEE_ID_NRIC";
        break;
    }
    return T.transl(employeeIDText);
  };

  const getInputUEN = () => {
    let inputUEN = {};
    switch (countryCode) {
      case "MY":
        inputUEN = {
          label: "Business Registration Number",
          linkHereLabel: "Find all businesses registered in Malaysia",
          placeholder: "Business Registration Number",
          disabled: false
        };
        break;
      case "HK":
        inputUEN = {
          label: "Business Registration Number (BRN)",
          linkHereLabel: "Find all businesses registered in Hong Kong",
          placeholder: "Business Registration Number (BRN)",
          disabled: true
        };
        break;
      case "AU":
        inputUEN = {
          label: "Australian Business Number (ABN)",
          linkHereLabel: "Find all businesses registered in Australia",
          placeholder: "Australian Business Number (ABN)",
          disabled: true
        };
        break;
      case "SG":
      default:
        inputUEN = {
          label: "Unique Entity Number (UEN)",
          linkHereLabel: "Find all entities registered in Singapore",
          placeholder: "Unique Entity Number (UEN)",
          disabled: true
        };
        break;
    }
    return inputUEN;
  };

  const fetchEditedPayee = () => {
    fetchPayees({
      type: "salary",
      getAllPayees: true,
      noRedirect: true,
      pageCount: 1000,
      cb: (payees: any) => {
        const payee = payees.find((p: any) => +p.id === +editedId);

        setEditedPayee(payee);
      },
      loadingFunc: setLoading
    });
  };

  useEffect(() => {
    if (editMode) {
      fetchEditedPayee();
    }
  }, []);

  const supportingDocumentsProps = {
    inputFileClassName: !isDesktop && styles.inputFileListWrapper,
    allowedFileTypes: ["jpg", "png", "heic", "pdf"],
    name: `supporting_documents`,
    purpose: "tenancy_agreement",
    form: FORM,
    label: "Upload invoice",
    icon: IconUpload,
    canDrag: true,
    multiple: true,
    renderComponent: InputFileDropZone,
    revertValueOnMount: true,
    reserveValueOnUnmount: true
  };

  return (
    <div className={styles.wrapperForm}>
      <Skeleton loading={loading}>
        <Form
          name="company-form"
          layout="vertical"
          requiredMark={false}
          className={styles.form}
        >
          <FormErrors form={FORM} />
          {editedPayee && (
            <div hidden={true}>
              <InputText
                name={"payee_id"}
                form={FORM}
                defaultValue={_get(editedPayee, "id", "")}
              />
            </div>
          )}
          {!isBizSalary ? (
            <>
              <InputText
                name={"recipient_name"}
                placeholder={"Employee’s full name"}
                form={FORM}
                label={"Employee’s full name"}
                defaultValue={_get(editedPayee, "name", "" || initName)}
                // className={styles.codeSection}
              />

              <InputText
                disabled={editMode}
                name={"identification_number"}
                placeholder={getEmployeeIDText()}
                form={FORM}
                label={getEmployeeIDText()}
                defaultValue={_get(editedPayee, "data.id_number", "")}
                // className={styles.codeSection}
              />
            </>
          ) : (
            <>
              <SearchCompanyForm
                form={FORM}
                fields={{
                  autoCompleteField: {
                    label: "Company name",
                    name: "recipient_name",
                    placeholder: "Company name",
                    default: _get(editedPayee, "name") || initName
                  },
                  inputTextField: {
                    name: "registration_number",
                    ...getInputUEN()
                  }
                }}
                input={{
                  companyName: _get(editedPayee, "name") || initName,
                  registrationNumber: _get(
                    editedPayee,
                    "data.registration_number"
                  )
                }}
                shouldDisplayRegField={true}
              />
              <InputText
                name={"bank_account_holder_name"}
                form={FORM}
                label={"Company bank account holder name"}
                placeholder={"Company bank account holder's full name"}
                defaultValue={
                  _get(editedPayee, "data.bank_account_holder_name") || initName
                }
              />
            </>
          )}

          {countryCode === "AU" ? (
            <>
              <BankBSB
                form={FORM}
                name="bank_bsb_id"
                placeholder="Enter bank name, branch, or BSB"
                label="Bank name, branch, or BSB"
                defaultValue={
                  _get(editedPayee, "data.bank_bsb_id") === 0
                    ? null
                    : _get(editedPayee, "data.bank_bsb_id")
                }
                suffixIcon={<Image src={IconArrowDown} />}
                // className={styles.select}
              />
              <div hidden={true}>
                <BankBSB
                  form={FORM}
                  name="bank_id"
                  defaultValue={
                    _get(editedPayee, "bank") === 0
                      ? null
                      : _get(editedPayee, "bank")
                  }
                />
              </div>
            </>
          ) : (
            <Select
              form={FORM}
              name="bank_id"
              placeholder="Select bank"
              label="Bank"
              options={filterBank}
              defaultValue={
                _get(editedPayee, "bank") === 0
                  ? null
                  : _get(editedPayee, "bank")
              }
              suffixIcon={<Image src={IconArrowDown} />}
              // className={styles.select}
            />
          )}

          <InputText
            name={"account_number"}
            placeholder={"Account number"}
            form={FORM}
            label={"Account number"}
            defaultValue={_get(editedPayee, "account_number", "")}
            // className={styles.codeSection}
          />

          {!isBizSalary ? (
            <>
              <InputText
                name={"default_amount"}
                placeholder={`0.00`}
                pattern={"__MONEY__"}
                form={FORM}
                label={"Salary"}
                prefix={
                  CurrencyUtil.convertFromId(props.currencyId as number).$
                }
                defaultValue={_get(editedPayee, "amount", 0) / 100 || ""}
                // className={styles.codeSection}
              />

              <InputText
                name={"default_comments"}
                pattern={"__STATEMENT__"}
                placeholder={
                  "Description to appear on your employee’s statement"
                }
                form={FORM}
                label={"Note to employee"}
                defaultValue={_get(editedPayee, "comments", "")}
                // className={styles.codeSection}
              />
              <div className={styles.includeTexts}>
                <p>
                  <b>Payslip</b> or <b>Employment Contract</b> must include
                </p>
                <ul>
                  <li>Employee Name</li>
                  <li>{descriptionPayslip}</li>
                </ul>
              </div>
            </>
          ) : (
            <>
              <div className={styles.includeTexts}>
                <p>
                  <b>Company’s Bank Account Statement</b> must include:
                </p>
                <ul>
                  <li>Company Name</li>
                  <li>Bank Account Details</li>
                </ul>
              </div>
            </>
          )}

          {editMode ? (
            _get(editedPayee, "data.supporting_documents") ? (
              <InputFile
                {...supportingDocumentsProps}
                defaultValue={_get(editedPayee, "data.files", [])}
              />
            ) : null
          ) : (
            <InputFile {...supportingDocumentsProps} defaultValue={[]} />
          )}
        </Form>
      </Skeleton>
    </div>
  );
};
