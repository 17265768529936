import React from "react";
import Carousel from "antd/es/carousel";

// import "antd/dist/antd.css";
import styles from "./Carousel.module.scss";

const Component = (props: any) => {
  const { children, ...rest } = props;

  return (
    <Carousel
      className={styles.wrapper}
      dotPosition="bottom"
      dots={{ className: styles.dots }}
      {...rest}
    >
      {children}
    </Carousel>
  );
};

export default Component;
