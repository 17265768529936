// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PaymentDetailModal_wrapper__3gy0U{display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;padding:0 1.5rem}.PaymentDetailModal_wrapperMobile__1qXdi{padding:0}.PaymentDetailModal_groupBtn__2cWgm{max-width:60%;margin:auto;padding:0 0 1rem !important;display:-ms-flexbox;display:flex;grid-gap:1rem;gap:1rem}.PaymentDetailModal_groupBtn__2cWgm>div{width:100%}.PaymentDetailModal_groupBtn__2cWgm .ant-btn span{font-weight:500 !important}.PaymentDetailModal_groupBtn__2cWgm.PaymentDetailModal_isMobile__3m5ZB{max-width:100%}", "",{"version":3,"sources":["webpack://./src/bepaid/shared/modals/PaymentDetailExtendModal/PaymentDetailModal.module.scss"],"names":[],"mappings":"AAAA,mCAAS,mBAAY,CAAZ,YAAY,CAAC,oBAAsB,CAAtB,sBAAsB,CAAC,gBAAgB,CAAC,yCAAe,SAAS,CAAC,oCAAU,aAAa,CAAC,WAAW,CAAC,2BAA2B,CAAC,mBAAY,CAAZ,YAAY,CAAC,aAAO,CAAP,QAAQ,CAAC,wCAAc,UAAU,CAAC,kDAAgC,0BAA0B,CAAC,uEAAmB,cAAc","sourcesContent":[".wrapper{display:flex;justify-content:center;padding:0 1.5rem}.wrapperMobile{padding:0}.groupBtn{max-width:60%;margin:auto;padding:0 0 1rem !important;display:flex;gap:1rem}.groupBtn>div{width:100%}.groupBtn :global .ant-btn span{font-weight:500 !important}.groupBtn.isMobile{max-width:100%}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "PaymentDetailModal_wrapper__3gy0U",
	"wrapperMobile": "PaymentDetailModal_wrapperMobile__1qXdi",
	"groupBtn": "PaymentDetailModal_groupBtn__2cWgm",
	"isMobile": "PaymentDetailModal_isMobile__3m5ZB"
};
export default ___CSS_LOADER_EXPORT___;
