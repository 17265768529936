/* tslint:disable:object-literal-sort-keys jsx-no-lambda */
import React, { useState } from "react";
import _get from "lodash-es/get";
import _trim from "lodash-es/trim";
import _truncate from "lodash-es/truncate";
import * as DateFns from "date-fns";
import Typography from "antd/es/typography";
import { history } from "src/ipm-shared/store";

import { PLATFORM } from "src/ipm-shared/components/GlobalUI/reducers";

import ROUTES from "src/bepaid/routes";
import List from "src/bepaid/components/List";
import PaymentUtil from "src/ipm-shared/Utils/Payment";

import { INVOICE_ACTIVITIES, PAYMENT_ACTIVITIES } from "./const";

import styles from "./RecentActivities.module.scss";

const RecentActivities: React.FC<any> = props => {
  const {
    isBgOfPopup,
    isFirstFetchDataFetch,
    isFirstFetchDataPay,
    getInvoiceRecentActivities,
    getPaymentRecentActivities,
    payFetchRecentActivities
  } = props;

  const [loading, setLoading] = useState(false);
  // const [limit, setLimit] = React.useState(0);
  const [pagination, setPagination] = React.useState({
    offset: 0,
    pageCount: 10
  });

  let shouldFetchData = true;

  if (props.platform === PLATFORM.FETCH) {
    shouldFetchData = !(isBgOfPopup && isFirstFetchDataFetch);
  }

  if (props.platform === PLATFORM.PAY) {
    shouldFetchData = !(isBgOfPopup && isFirstFetchDataPay);
  }

  React.useEffect(() => {
    if (!shouldFetchData) {
      return;
    }

    // setData([]);

    if (props.platform === PLATFORM.FETCH) {
      getInvoiceRecentActivities(
        pagination,
        (err: any, res: any) => {},
        setLoading
      );
    }

    if (props.platform === PLATFORM.PAY) {
      getPaymentRecentActivities(
        pagination,
        (err: any, res: any) => {},
        setLoading
      );
    }
  }, [pagination, props.platform]);

  React.useEffect(() => {
    if (!shouldFetchData) {
      return;
    }

    return () => {
      setPagination({
        offset: 0,
        pageCount: 10
      });
    };
  }, [props.platform]);

  const handleOnChangePage = (offset: number) => {
    setPagination({ ...pagination, offset });
  };

  if (props.platform === PLATFORM.FETCH) {
    return (
      <List
        data={payFetchRecentActivities.data}
        total={payFetchRecentActivities.total}
        loading={loading}
        header={
          <Typography.Text className={styles.header}>
            Recent activity
          </Typography.Text>
        }
        renderItem={(item: any) => (
          <div className={styles.item}>
            <Typography.Text className={styles.info}>
              {`${_trim(`${item.firstName} ${item.lastName}`)}, `}
              <Typography.Link
                className={styles.link}
                onClick={() => {
                  history.push(
                    ROUTES.INVOICE_DETAILS.replace(":id", item.invoiceId)
                  );
                }}
              >
                {item.invoiceNumber}
              </Typography.Link>
              {`: ${_get(INVOICE_ACTIVITIES[item.activity], "pastTenseLabel")}`}
            </Typography.Text>
            <Typography.Text className={styles.date}>
              {item.date ? DateFns.format(item.date, "DD MMMM YYYY") : "-"}
            </Typography.Text>
          </div>
        )}
        emptyDOM={
          <div className={styles.emptyData}>
            Your recent activity will appear here after you send your first
            invoice.
          </div>
        }
        pagination={{
          ...pagination,
          onChange: handleOnChangePage
        }}
      />
    );
  }

  if (props.platform === PLATFORM.PAY) {
    const limit = (item: any) => {
      return (
        48 -
        item.type.length -
        item.activity.length -
        _trim(item.receiptNumber).toString().length
      );
    };

    return (
      <List
        data={payFetchRecentActivities.data}
        total={payFetchRecentActivities.total}
        loading={loading}
        header={
          <Typography.Text className={styles.header}>
            Recent activity
          </Typography.Text>
        }
        renderItem={(item: any) => (
          <div className={styles.item}>
            <Typography.Text className={styles.info}>
              {item.activity !== "cancelled" &&
                item.activity !== "schedule_cancelled" && (
                  <Typography.Link
                    className={styles.link}
                    onClick={() => {
                      history.push(
                        ROUTES.PAYMENT_DETAILS.replace(
                          ":id",
                          item.receiptNumber
                        ) + "?idType=regNo"
                      );
                    }}
                  >
                    {`${_trim(`${item.receiptNumber}`)}, `}
                  </Typography.Link>
                )}
              {`${_truncate(
                _trim(
                  `${PaymentUtil.getInsurancePayeeName(item.recipientName)}`
                ),
                {
                  length: limit(item)
                }
              )}`}
              {`: ${_get(
                PAYMENT_ACTIVITIES,
                `${item.type}.${item.activity}.label`
              )}`}{" "}
              {item.activity === "cancelled" && (
                <Typography.Link
                  className={styles.cancelled}
                  onClick={() => {
                    history.push(
                      ROUTES.PAYMENT_DETAILS.replace(
                        ":id",
                        item.receiptNumber
                      ) + "?idType=regNo&statuses=n"
                    );
                  }}
                >
                  cancelled
                </Typography.Link>
              )}
              {item.activity === "schedule_cancelled" && (
                <Typography.Link
                  className={styles.cancelled}
                  onClick={() => {
                    history.push(
                      ROUTES.PAYMENT_SCHEDULE_DETAILS.replace(
                        ":id",
                        item.scheduleId
                      ) + "?idType=regNo&statuses=n"
                    );
                  }}
                >
                  cancelled
                </Typography.Link>
              )}
            </Typography.Text>
            <Typography.Text className={styles.date}>
              {item.date ? DateFns.format(item.date, "DD MMMM YYYY") : "-"}
            </Typography.Text>
          </div>
        )}
        emptyDOM={
          <div className={styles.emptyData}>
            Your recent activity will appear here after you make your first
            payment.
          </div>
        }
        pagination={{
          ...pagination,
          onChange: handleOnChangePage
        }}
      />
    );
  }

  return null;
};

export default RecentActivities;
