import * as React from "react";

import T from "src/ipm-shared/Utils/Intl";

import Checkbox from "src/ipm-shared/components/Form/controls/Checkbox";
import InputDate from "src/ipm-shared/components/Form/controls/InputDate";

import {
  IProps as IPropsRoot,
  FILTER_DEFAULT_VALUES,
  FILTER_DEFAULT_STATUSES,
  getFormValue
} from "./index";

type IProps = IPropsRoot & {
  form: string;
  onClear: () => void;
  onSubmit: () => void;
};

class FilterContent extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  public render() {
    return (
      <div className="menu-content">
        <div className="header">
          <span>{T.transl("FILTER_HEADER")}</span>
          <div>
            <button
              className="btn btn-sm btn-actions btn-clear"
              onClick={this.cancel}
            >
              {T.transl("FILTER_CLEAR_BUTTON")}
            </button>
            <button
              className="btn btn-sm btn-actions btn-done ml-2"
              onClick={this.submit}
            >
              {T.transl("FILTER_DONE_BUTTON")}
            </button>
          </div>
        </div>
        <div className="content">
          {this.renderChargeDates()}
          {this.renderStatus()}
        </div>
      </div>
    );
  }

  private getValues = () => {
    const { rootState: state, form } = this.props;

    return {
      chargeDateLower: getFormValue(
        state,
        `charge_date_lower_${form}`,
        form,
        FILTER_DEFAULT_VALUES.chargeDateLower
      ),
      chargeDateUpper: getFormValue(
        state,
        `charge_date_upper_${form}`,
        form,
        FILTER_DEFAULT_VALUES.chargeDateUpper
      ),
      statuses: getFormValue(
        state,
        `status_ids_${form}`,
        form,
        FILTER_DEFAULT_STATUSES
      )
    };
  };

  private renderChargeDates = (): React.ReactElement<any> => {
    const { form } = this.props;
    const { chargeDateLower = "", chargeDateUpper = "" } = this.getValues();

    return (
      <div className="section">
        <div className="title border-bottom">
          <div>
            <p>{T.transl("FILTER_CHARGE_DATE_HEADER")}</p>
          </div>
        </div>
        <div className="filter-export-date border-bottom">
          <div className="d-flex flex-column mr-2">
            <span>{T.transl("FILTER_FROM_LABEL")}</span>
            <InputDate
              name={`charge_date_lower_${form}`}
              defaultValue={chargeDateLower}
              form={form}
              isAdmin={true}
              reserveValueOnUnmount={true}
            />
          </div>
          <div className="d-flex flex-column ml-2">
            <span>{T.transl("FILTER_TO_LABEL")}</span>
            <InputDate
              name={`charge_date_upper_${form}`}
              defaultValue={chargeDateUpper}
              form={form}
              isAdmin={true}
              reserveValueOnUnmount={true}
            />
          </div>
        </div>
      </div>
    );
  };

  private renderStatus = (): React.ReactElement<any> => {
    const { form } = this.props;
    const { statuses = "" } = this.getValues();

    return (
      <div className="section">
        <div className="title border-bottom">
          <div>
            <p>{T.transl("FILTER_STATUS_HEADER")}</p>
          </div>
        </div>
        <div className="filter-export-status">
          {FILTER_DEFAULT_VALUES.statusList.map(status => (
            <Checkbox
              key={status.id}
              className="ipm-custom-checkbox blue-dark-checked"
              id={status.id}
              name={`status_ids_${form}`}
              form={form}
              label={status.label}
              reserveValueOnUnmount={true}
              presentedValue={status.presentedValue}
              defaultChecked={(statuses as string)
                .split(",")
                .includes(status.presentedValue)}
            />
          ))}
        </div>
      </div>
    );
  };

  private setControlValues = (
    chargeDateLower: string,
    chargeDateUpper: string,
    statuses: string
  ): void => {
    const { form } = this.props;

    this.props.setControl({
      form,
      name: `charge_date_lower_${form}`,
      value: chargeDateLower
    });

    this.props.setControl({
      form,
      name: `charge_date_upper_${form}`,
      value: chargeDateUpper
    });

    this.props.setControl({
      form,
      name: `status_ids_${form}`,
      value: statuses
    });
  };

  private cancel = (): void => {
    const { chargeDateLower, chargeDateUpper } = FILTER_DEFAULT_VALUES;

    // need to reset all
    this.setControlValues(
      chargeDateLower,
      chargeDateUpper,
      FILTER_DEFAULT_STATUSES
    );

    if (this.props.onClear) {
      this.props.onClear();
    }
  };

  private submit = (): void => {
    if (this.props.onSubmit) {
      this.props.onSubmit();
    }
  };
}

export default FilterContent;
