// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RetrieveBusinessDescription_wrapperDesktop__23nkM{padding:0 4rem}.RetrieveBusinessDescription_wrapper__23JF5 .RetrieveBusinessDescription_formTitle__2PBR6{position:absolute;top:-8px;margin-left:.75rem;padding:1px 4px;background:#fff;z-index:10}.RetrieveBusinessDescription_wrapper__23JF5 .RetrieveBusinessDescription_formWrapper__2jc4p{text-align:left}.RetrieveBusinessDescription_wrapper__23JF5 .RetrieveBusinessDescription_section__FOfws{margin-bottom:2rem}.RetrieveBusinessDescription_wrapper__23JF5 .RetrieveBusinessDescription_section__FOfws .RetrieveBusinessDescription_sectionText__d4Fdd{font-size:1rem;color:#000}.RetrieveBusinessDescription_button__1kq8n{margin:auto;padding:0 4rem;margin-top:2rem}", "",{"version":3,"sources":["webpack://./src/bepaid/pages/MyInfo/components/RetrieveBusinessDescription/RetrieveBusinessDescription.module.scss"],"names":[],"mappings":"AAAA,mDAAgB,cAAc,CAAC,0FAAoB,iBAAiB,CAAC,QAAQ,CAAC,kBAAkB,CAAC,eAAe,CAAC,eAAe,CAAC,UAAU,CAAC,4FAAsB,eAAe,CAAC,wFAAkB,kBAAkB,CAAC,wIAA+B,cAAc,CAAC,UAAU,CAAC,2CAAQ,WAAW,CAAC,cAAc,CAAC,eAAe","sourcesContent":[".wrapperDesktop{padding:0 4rem}.wrapper .formTitle{position:absolute;top:-8px;margin-left:.75rem;padding:1px 4px;background:#fff;z-index:10}.wrapper .formWrapper{text-align:left}.wrapper .section{margin-bottom:2rem}.wrapper .section .sectionText{font-size:1rem;color:#000}.button{margin:auto;padding:0 4rem;margin-top:2rem}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapperDesktop": "RetrieveBusinessDescription_wrapperDesktop__23nkM",
	"wrapper": "RetrieveBusinessDescription_wrapper__23JF5",
	"formTitle": "RetrieveBusinessDescription_formTitle__2PBR6",
	"formWrapper": "RetrieveBusinessDescription_formWrapper__2jc4p",
	"section": "RetrieveBusinessDescription_section__FOfws",
	"sectionText": "RetrieveBusinessDescription_sectionText__d4Fdd",
	"button": "RetrieveBusinessDescription_button__1kq8n"
};
export default ___CSS_LOADER_EXPORT___;
