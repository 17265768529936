import React from "react";

import CloseIcon from "src/bepaid/assets/images/common/icon_close_white.svg";
import PromoBg from "src/bepaid/assets/images/promo/amex_promo_background.svg";
import PromoGif from "src/bepaid/assets/gifs/amex_cards.gif";
import Button from "antd/es/button";
import Image from "src/bepaid/components/Image";

import { isMobile } from "react-device-detect";
import { FetchModalID } from "../PaymentsCreationModal/components/SchedulePayment/actions";

import classNames from "classnames";
import styles from "./PromoPopupModal.module.scss";

const View = (props: any) => {
  const {
    onYes,
    onNo,
    isLoading = false,
    yesLabel,
    yesLabelLoading,
    yesButtonClassname = "",
    noLabel,
    popupBgImageUrl,
    popupBgImageClassname = "",
    hidePopupImage
  } = props.extraInfo;

  const closePopup = () => {
    if (onNo) {
      onNo();
    } else {
      props.toggleModal(FetchModalID.PROMO_POPUP);
      props.changeUserSetting({
        enable: false,
        property: "amex_promo_modal",
        refreshDisable: true
      });
    }
  };
  return (
    <div
      className={classNames(styles.promoPopupContainer, {
        [styles.isMobile]: isMobile
      })}
    >
      <div className={styles.promoPopupContent}>
        <div className={styles.closeButton}>
          <Image src={CloseIcon} width={20} height={20} onClick={closePopup} />
        </div>
        <div
          className={classNames(
            styles.promoPopupBgImage,
            popupBgImageClassname
          )}
        >
          <Image src={popupBgImageUrl ? popupBgImageUrl : PromoBg} />
        </div>

        {!hidePopupImage ? (
          <div className={styles.promoPopupImage}>
            <Image src={PromoGif} alt="" width={180} height={120} />
          </div>
        ) : null}

        {!!onYes ? (
          <Button
            className={classNames(styles.button, yesButtonClassname)}
            onClick={onYes}
            loading={isLoading}
          >
            {isLoading ? yesLabelLoading : yesLabel}
          </Button>
        ) : (
          <Button
            className={styles.button}
            href="http://help.ipaymy.com/en/articles/6652262-american-express-card-policy"
            target="_blank"
          >
            Learn more
          </Button>
        )}

        {!!onNo ? (
          <Button className={styles.secondaryButton} onClick={onNo}>
            {noLabel}
          </Button>
        ) : null}
      </div>
    </div>
  );
};

export default View;
