/* tslint:disable:object-literal-sort-keys */
import React from "react";
import format from "date-fns/format";

import _get from "lodash-es/get";
import _find from "lodash-es/find";

import Row from "antd/es/row";
import Col from "antd/es/col";
import Typography from "antd/es/typography";

import { history } from "src/ipm-shared/store";

import useQuery from "src/bepaid/hooks/useQuery";
import ROUTES from "src/bepaid/routes";
import Skeleton from "src/bepaid/components/Skeleton";
import paymentUtil from "src/bepaid/shared/modals/PaymentDetailModal/components/PaymentDetail/utils/payment";
import PaymentUtil from "src/ipm-shared/Utils/Payment";

import CardDetails from "../CardDetails";
import styles from "../../Payments.module.scss";
import PaymentMethodData from "src/ipm-shared/store/metadata/payment_method";
import { PAYMENT_METHOD_TYPES } from "src/ipm-shared/store/model/PaymentRequest/const";

const TabContent = (props: any) => {
  const { loading, list, hasFilter, isShowSchedule } = props;
  const qs = useQuery();
  const isIndividual = qs.paymentType === "individual" && !isShowSchedule;

  const renderTitlePaymentCard = (payment: any) => {
    if (isIndividual) {
      const n = _get(payment, "receiptNumber", "");
      return `${paymentUtil.getPaymentPurpose(payment)}${n ? `: ${n}` : ""}`;
    } else {
      const startDate = _get(payment, "scheduleStartDate", "");
      const endDate = _get(payment, "scheduleEndDate", "");
      return `${paymentUtil.getPaymentPurpose(payment)}${
        startDate
          ? `: from ${format(startDate, "D MMMM YYYY")} to ${format(
              endDate,
              "D MMMM YYYY"
            )}`
          : ""
      }`;
    }
  };

  const renderDescriptionPaymentCard = (payment: any) => {
    if (_get(payment, "purposeId", 0) === 5) {
      return _get(payment, "payees[0].bankAccountHolderName", "");
    }
    if (isIndividual) {
      return PaymentUtil.getInsurancePayeeName(
        _get(payment, "payees[0].name", "")
      );
    } else {
      return _get(payment, "payees[0].recipientName", "");
    }
  };

  const onSelectPayment = (payment: any) => () => {
    if (isIndividual) {
      const search = new URLSearchParams(history.location.search);
      if (payment.receiptNumber) {
        search.set("idType", "regNo");
      } else {
        search.set("idType", "id");
      }

      const url =
        `${ROUTES.PAYMENTS}/${payment.receiptNumber || `${payment.id}`}` +
        "/?" +
        search.toString();

      history.push(url, {
        prevRoute: ROUTES.PAYMENT_DETAILS
      });
    } else {
      const search = new URLSearchParams(history.location.search);

      const url =
        `${ROUTES.PAYMENT_SCHEDULE_DETAILS.replace(
          ":id",
          payment.scheduleId
        )}` +
        "/?" +
        search.toString();

      history.push(url, {
        prevRoute: ROUTES.PAYMENT_SCHEDULE_DETAILS
      });
    }
  };

  const getPaymentMethodDetails = (payment: any) =>
    PaymentMethodData.find(
      data => data.id === _get(payment, "cryptoPaymentDetail.id")
    );

  return (
    <>
      <Row className={styles.listWrapper}>
        <Skeleton loading={loading}>
          {list.length > 0 ? (
            list.map((item: any, index: number) => (
              <Col key={index} span={24}>
                <CardDetails
                  amount={_get(item, "paymentAmount", "")}
                  payoutDate={_get(
                    item,
                    "payoutDate",
                    new Date().toISOString()
                  )}
                  payeeAmount={_get(item, "payees.length", 0) - 1}
                  description={renderDescriptionPaymentCard(item)}
                  title={renderTitlePaymentCard(item)}
                  cardId={_get(
                    item,
                    `cards[${item?.cards?.length - 1}].id`,
                    ""
                  )}
                  countryId={_get(item, "payees[0].countryId", 1)}
                  paymentMethodDetails={getPaymentMethodDetails(item)}
                  isCryptoPaymentMethod={
                    _get(item, "paymentMethodType") ===
                    PAYMENT_METHOD_TYPES.CRYPTO
                  }
                  isShowSchedule={isShowSchedule}
                  onClick={onSelectPayment(item)}
                />
              </Col>
            ))
          ) : hasFilter ? (
            <div className={styles.noResults}>
              <Typography.Text>No results found.</Typography.Text>
            </div>
          ) : null}
        </Skeleton>
      </Row>
    </>
  );
};

export default TabContent;
