// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".List_wrapper__1Q4eY .ant-list-empty-text{padding:0}.List_wrapper__1Q4eY.List_hasPagination__1l_Vw .ant-list-header{padding-bottom:2rem}.List_wrapper__1Q4eY.List_hasPagination__1l_Vw .ant-list-items{overflow:auto;max-height:60vh}.List_wrapper__1Q4eY .List_defaultEmptySection__1O3CR{padding:1rem}.List_wrapper__1Q4eY .List_header__3BN2n{display:-ms-flexbox;display:flex;-ms-flex-pack:justify;justify-content:space-between;-ms-flex-align:center;align-items:center}", "",{"version":3,"sources":["webpack://./src/bepaid/components/List/List.module.scss"],"names":[],"mappings":"AAAA,0CAAsC,SAAS,CAAC,gEAAgD,mBAAmB,CAAC,+DAA+C,aAAa,CAAC,eAAe,CAAC,sDAA8B,YAAY,CAAC,yCAAiB,mBAAY,CAAZ,YAAY,CAAC,qBAA6B,CAA7B,6BAA6B,CAAC,qBAAiB,CAAjB,kBAAkB","sourcesContent":[".wrapper :global .ant-list-empty-text{padding:0}.wrapper.hasPagination :global .ant-list-header{padding-bottom:2rem}.wrapper.hasPagination :global .ant-list-items{overflow:auto;max-height:60vh}.wrapper .defaultEmptySection{padding:1rem}.wrapper .header{display:flex;justify-content:space-between;align-items:center}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "List_wrapper__1Q4eY",
	"hasPagination": "List_hasPagination__1l_Vw",
	"defaultEmptySection": "List_defaultEmptySection__1O3CR",
	"header": "List_header__3BN2n"
};
export default ___CSS_LOADER_EXPORT___;
