import React from "react";
import * as DateFns from "date-fns";

import ShortCurrency from "src/ipm-shared/components/ShortCurrency";
import DateUtils from "src/ipm-shared/Utils/Date";

import styles from "./IncentivesReview.module.scss";
const optionDate = {
  day: "numeric",
  month: "short",
  weekday: "long",
  year: "numeric"
};
function IncentivesReview(props: any) {
  const { data = [], invoice, taxes } = props;
  const dataShow: any[] = [];
  let amountDue = invoice.grossAmount;
  const itemsInvoice: any[] = [];
  if (invoice.items) {
    Object.keys(invoice.items).map(key => {
      itemsInvoice.push(invoice.items[key]);
    });
  }
  if (itemsInvoice.length > 0) {
    amountDue = 0;
    itemsInvoice.forEach((item: any) => {
      let itemAmount = item.amount;
      if (
        invoice.calculateMethod === "discount_all_item" ||
        invoice.calculateMethod === "discount_tax_all_item"
      ) {
        itemAmount = itemAmount * item.quantity;
      }
      if (item.discount) {
        if (item.discountType === "amount") {
          itemAmount = itemAmount - item.discount;
        } else if (item.discountType === "percentage") {
          itemAmount = itemAmount - (itemAmount * item.discount) / 100;
        }
      }
      if (item.taxId && taxes.length > 0) {
        const data = taxes.find((t: any) => t.id === item.taxId);
        itemAmount = itemAmount * (1 + data.rate / 100);
      }
      if (
        invoice.calculateMethod === "discount_tax_per_item" ||
        invoice.calculateMethod === null
      ) {
        amountDue += itemAmount * item.quantity;
      } else {
        amountDue += itemAmount;
      }
    });
  }
  data.forEach((item: any) => {
    if (item.open) {
      if (item.id === "P1") {
        dataShow.push({
          title: item.name,
          value: null
        });
        if (!invoice.dueDate) {
          return;
        }
        const firstDate = DateFns.addDays(new Date(invoice.dueDate), 1);
        if (firstDate) {
          dataShow.push({
            subTitle: "1st payment, to be paid on",
            title: `${DateUtils.formatLocaleDate(
              firstDate.toISOString(),
              optionDate
            )}`,
            value: amountDue - Math.round(amountDue * 0.5)
          });
          dataShow.push({
            subTitle: "2nd payment, to be paid on",
            title: `${DateUtils.formatLocaleDate(
              DateFns.addMonths(firstDate, 1).toISOString(),
              optionDate
            )}`,
            value: Math.round(amountDue * 0.5)
          });
        }
      } else if (item.id === "P2") {
        dataShow.push({
          title: item.name,
          value: null
        });
        if (!invoice.dueDate) {
          return;
        }
        const firstDate = DateFns.addDays(new Date(invoice.dueDate), 1);
        if (firstDate) {
          dataShow.push({
            subTitle: "1st payment, to be paid on",
            title: `${DateUtils.formatLocaleDate(
              firstDate.toISOString(),
              optionDate
            )}`,
            value: amountDue - 2 * Math.round(amountDue * 0.33)
          });
          dataShow.push({
            subTitle: "2nd payment, to be paid on",
            title: `${DateUtils.formatLocaleDate(
              DateFns.addMonths(firstDate, 1).toISOString(),
              optionDate
            )}`,
            value: Math.round(amountDue * 0.33)
          });
          dataShow.push({
            subTitle: "3rd payment, to be paid on",
            title: `${DateUtils.formatLocaleDate(
              DateFns.addMonths(firstDate, 2).toISOString(),
              optionDate
            )}`,
            value: Math.round(amountDue * 0.33)
          });
        }
      } else if (item.id === "D1") {
        dataShow.push({
          title: `Late payment fee (${Math.round(item.rate) / 100}%)`,
          value: Math.round(invoice.grossAmount * (item.rate / 10000))
        });
      } else {
        dataShow.push({
          title: `${item.name} (${Math.round(item.rate) / 100}%)`,
          value: Math.round(amountDue * (item.rate / 10000))
        });
      }
    }
  });
  return (
    <div className={styles.wrapper}>
      {dataShow.map((item: any, idx: number) => {
        return (
          <div key={idx} className={styles.incentivesItem}>
            <div>
              {item.subTitle && (
                <p className={styles.subTitle}>{item.subTitle}</p>
              )}

              <p className={styles.title}>{item.title}</p>
            </div>
            <div>
              {item.value && (
                <p className={styles.value}>
                  {" "}
                  <ShortCurrency
                    value={item.value}
                    currencyId={props.currencyId}
                  />
                </p>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default IncentivesReview;
