import React from "react";
import PaymentsList from "../Payments/components/PaymentsList";
import useQuery from "src/bepaid/hooks/useQuery";

const PaymentsCreationBackground = (props: any) => {
  const qs = useQuery();
  return <PaymentsList {...props} nf_schedule_id={qs.nf_schedule_id} />;
};

export default PaymentsCreationBackground;
