import React from "react";
import classNames from "classnames";
import Tag from "antd/es/tag";

import styles from "./Tag.module.scss";

const Component = (props: any) => {
  const {
    type = "default",
    closable = false,
    children = null,
    icon = null,
    onClick = null
  } = props;

  const customStyles = {
    [styles.active]: type === "active",
    [styles.vip]: type === "vip",
    [styles.new]: type === "new",
    [styles.risky]: type === "risky",
    [styles.count]: type === "count"
  };

  return (
    <div onClick={onClick} className={classNames(styles.wrapper, customStyles)}>
      <Tag closable={closable} icon={icon}>
        {children}
      </Tag>
    </div>
  );
};

export default Component;
