import * as React from "react";
import { connect } from "react-redux";
import { RootState } from "src/ipm-shared/store/model/reducers";
import * as selectors from "src/ipm-shared/store/model/AccountProfile/selectors";

const requireProfileComponent = (Component: any) => {
  class ComponentLoader extends React.Component<any, any> {
    public render() {
      if (!this.props.hasFetchedAccountProfile) {
        return null;
      }
      return (
        <>
          <Component {...this.props} />
        </>
      );
    }
  }

  return connect((state: RootState) => ({
    hasFetchedAccountProfile: selectors.hasFetchedAccountProfile(state)
  }))(ComponentLoader);
};

export { requireProfileComponent };
