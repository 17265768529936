import * as React from "react";
import classNames from "classnames";
import {
  CardCVCElement,
  CardExpiryElement,
  CardNumberElement,
  ReactStripeElements,
  injectStripe
} from "react-stripe-elements";
import T from "src/ipm-shared/Utils/Intl";
import BaseCardFormView, {
  IBaseProps,
  IBaseState
} from "../../lib/BaseCardForm";
import { ADD_CARD_FORM } from "src/ipm-shared/store/model/Card/const";
import FormErrors from "src/ipm-shared/components/Form/helpers/Errors";
import { CardInfo } from "src/ipm-shared/store/model/Card/types";
import { CardFormVersion } from "../../type";
// import {
//   CardNumberSurffix,
//   SurffixPaymentMethod
// } from "../../components/CardNumberSurffix";
import { CvvSurffix } from "../../components/CvvSurffix";
import { BRAND } from "src/ipm-shared/Utils/Images";

type IProps = IBaseProps & {
  stripe?: ReactStripeElements.StripeProps;
  isDisabledChinaUnionPay?: boolean;
  selectedCardBrand?: number;
};
type IState = IBaseState & {
  cardNumberReady: boolean;
  cardExpiryReady: boolean;
  cardCvcReady: boolean;
  selectedPaymentMethod?: string;
};

class StripeCardFormView extends BaseCardFormView<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      cardCvcReady: false,
      cardExpiryReady: false,
      cardFieldFocus: "",
      cardNumberReady: false,
      errors: {
        cardNumber: ""
      },
      flipped: false,
      readyToShowForm: false,
      selectedPaymentMethod: undefined
    };
  }

  public componentDidMount() {
    super.componentDidMount();
  }

  public render() {
    const {
      cardFormVersion = CardFormVersion.LEGACY
      // isDisabledChinaUnionPay
    } = this.props;
    // const { selectedPaymentMethod } = this.state;

    const stylesByFormVersion = {
      [CardFormVersion.BEPAID]: {
        base: {
          color: "#242424",
          fontSize: "16px",
          fontFamily: "sans-serif",
          "::placeholder": {
            color: "#74747B"
          }
        },
        invalid: {
          color: "#242424"
        }
      }
    };

    const placeHolderByFormVersion = {
      [CardFormVersion.BEPAID]: {
        cvv: "CVC/CVV",
        expiryDay: "MM/YY",
        cardNumber: {
          [BRAND.VISA]: "4111 1111 1111 1111",
          [BRAND.MASTER_CARD]: "5111 1111 1111 1111",
          [BRAND.UNION_PAY]: "6211 1111 1111 1111",
          [BRAND.AMEX]: "3111 111111 11111"
        }
      }
    };

    const isBepaid = cardFormVersion === CardFormVersion.BEPAID;
    return (
      <>
        <div
          className={"StripeCardForm"}
          style={{
            display: this.state.readyToShowForm ? "block" : "none"
          }}
        >
          <div>
            {super.renderCardholderName(cardFormVersion)}
            <div className="form-group form-card-number">
              <span className="label">{T.transl("CARD_NUMBER_LABEL")}</span>
              <div className="surffix-container">
                <CardNumberElement
                  placeholder={
                    placeHolderByFormVersion[cardFormVersion].cardNumber[
                      this.props.selectedCardBrand || BRAND.VISA
                    ]
                  }
                  style={stylesByFormVersion[cardFormVersion]}
                  className={classNames("form-control", {
                    ["form-control--invalid"]: Boolean(
                      this.state.errors.cardNumber
                    )
                  })}
                  onChange={this.onChangeCardInfo}
                  onFocus={this.onFocus.bind(this, "cardNumber")}
                />
                {/* {isBepaid && (
                  <CardNumberSurffix
                    supportedCards={
                      isDisabledChinaUnionPay
                        ? ["visa", "mastercard"]
                        : ["visa", "mastercard", "unionpay"]
                    }
                    selecetedPaymentMethod={
                      selectedPaymentMethod as SurffixPaymentMethod
                    }
                  />
                )} */}
              </div>
              {this.state.errors.cardNumber && (
                <span className="invalid-feedback d-block">
                  {this.state.errors.cardNumber}
                </span>
              )}
            </div>
            <div className="form-group w-100">
              <span className="label">{T.transl("CARD_EXPIRY_LABEL")}</span>
              <CardExpiryElement
                placeholder={
                  placeHolderByFormVersion[cardFormVersion].expiryDay
                }
                style={stylesByFormVersion[cardFormVersion]}
                className={"form-control"}
                onChange={this.onChangeCardInfo}
                onFocus={this.onFocus.bind(this, "cardExp")}
              />
            </div>
            <div className="form-group cvc-form form-cvc-cvv">
              <span className="label">{T.transl("CARD_CVC_LABEL")}</span>
              <div className="surffix-container">
                <CardCVCElement
                  placeholder={placeHolderByFormVersion[cardFormVersion].cvv}
                  style={stylesByFormVersion[cardFormVersion]}
                  className={"form-control"}
                  onChange={this.onChangeCardInfo}
                  onFocus={this.onFocus.bind(this, "cardCvc")}
                />
                {isBepaid && <CvvSurffix />}
              </div>
              <span className="question-icon" />
              {!isBepaid && (
                <div className="question-content">
                  {T.transl("LABEL_CARD_CVC_INSTRUCTION")}
                </div>
              )}
            </div>
            <FormErrors form={ADD_CARD_FORM} className="fz-12 mb-3" />
          </div>
        </div>
      </>
    );
  }

  // Used in wrapper
  public retokenize = async (): Promise<
    | {
        cardInfo: CardInfo;
        cardholderName?: string;
      }
    | undefined
  > => {
    return new Promise((resolve, reject) => {
      this.props.stripe
        ?.createToken({ name: this.state.cardHolderName })
        .then(e => {
          if (e.error) {
            this.onCardTokenizationFailed(e.error);
            reject(e.error);
            return;
          }

          if (e.token && e.token.card) {
            resolve({
              cardInfo: {
                cardBin: "", // Stripe does not provide
                cardBrand: e.token.card.brand,
                cardExpiryMonth: e.token.card.exp_month,
                cardExpiryYear: e.token.card.exp_year,
                cardIssuerCountry: e.token.card.country,
                cardLast4: e.token.card.last4,
                cardToken: e.token.id,
                cardTokenType: "stripe_payment_card",
                cardType: "" // Stripe does not provide
              },
              cardholderName: this.state.cardHolderName
            });
          }
        });
    });
  };

  private onChangeCardInfo = (
    value: ReactStripeElements.ElementChangeResponse
  ) => {
    const ready = {
      cardCvcReady: this.state.cardCvcReady,
      cardExpiryReady: this.state.cardExpiryReady,
      cardNumberReady: this.state.cardNumberReady
    };

    if (value.elementType === "cardNumber") {
      const valid = this.validateCardBrand(value.brand);
      if (!valid) {
        return;
      }
    }

    if (value.elementType === "cardNumber") {
      const brandName = value.brand;
      this.setState({ selectedPaymentMethod: brandName });
    }

    ready[value.elementType + "Ready"] = value.complete;

    this.setState(
      {
        cardCvcReady: ready.cardCvcReady,
        cardExpiryReady: ready.cardExpiryReady,
        cardNumberReady: ready.cardNumberReady
      },
      () => {
        if (
          this.state.cardExpiryReady &&
          this.state.cardNumberReady &&
          this.state.cardCvcReady
        ) {
          this.props.stripe
            ?.createToken({ name: this.state.cardHolderName })
            .then(e => {
              if (e.error) {
                this.onCardTokenizationFailed(e.error);
                return;
              }

              if (e.token && e.token.card) {
                this.onTokenized({
                  cardBin: "", // Stripe does not provide
                  cardBrand: e.token.card.brand,
                  cardExpiryMonth: e.token.card.exp_month,
                  cardExpiryYear: e.token.card.exp_year,
                  cardIssuerCountry: e.token.card.country,
                  cardLast4: e.token.card.last4,
                  cardToken: e.token.id,
                  cardTokenType: "stripe_payment_card",
                  cardType: "" // Stripe does not provide
                });
              }
            });
        } else {
          this.onCardTokenizationCleared();
        }
      }
    );
  };
}

export default injectStripe(StripeCardFormView, {
  withRef: true
});
