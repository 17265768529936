import React from "react";
import styles from "./InputFileDropZone.module.scss";

const View = (props: any) => {
  const { styles: stylesWrapper, helperNote } = props || {};

  return (
    <div
      className={styles.dropFileZone}
      style={stylesWrapper ? stylesWrapper : {}}
    >
      <p>Drag and drop your files here</p>

      <div className={styles.dropFileZoneOr}>
        <span>Or</span>
      </div>

      <div className={styles.browseFiles}>
        <button>Browse files</button>
      </div>

      <p className={styles.dropFileZoneNote}>
        {helperNote
          ? helperNote
          : "Maximum file size: 16 MB. Supported file types: JPG, PDF, PNG."}
      </p>
    </div>
  );
};

export default View;
