import React, { useState } from "react";
import _get from "lodash-es/get";
import classNames from "classnames";
import T from "src/ipm-shared/Utils/Intl";

import { COMPANY_PAGE_COUNT } from "src/bepaid/layouts/Menu/components/Companies/const";

import AutoComplete from "../AutoComplete";
import InputText from "../Form/controls/InputText";

import styles from "./SearchCompanyForm.module.scss";

interface IProps {
  fields: {
    autoCompleteField: {
      name: string;
      label: string;
      placeholder: string;
    };
    inputTextField: {
      name: string;
      label: string;
      placeholder: string;
      linkHereLabel: string;
      disabled?: boolean;
    };
  };
  [key: string]: any;
}

const View = (props: IProps) => {
  const {
    // connect props
    getLinkFindUen,
    // normal props
    input,
    form,
    supplierOptInCheckBox,
    fields,
    shouldDisplayRegField
  } = props;

  const { autoCompleteField, inputTextField } = fields;
  const [companyOptions, setCompanyOptions] = useState([]);
  const [disabledUenInput, setDisabledUenInput] = useState(
    inputTextField.disabled
  );
  const isMY = props.countryId === 2;
  const [displayedRegField, setDisplayedRegField] = useState(
    isMY || shouldDisplayRegField
  );
  const [loading, setLoading] = useState(false);

  const onSearchCompanyName = async (value: string) => {
    setCompanyOptions([]);

    props.fetchSearchCompanyName(
      {
        countryId: props.countryId,
        offset: 0,
        pageCount: COMPANY_PAGE_COUNT,
        value
      },
      (data: any, editable: boolean) => {
        setDisabledUenInput(!Boolean(editable && data.length === 0));

        if (data.length > 0) {
          props.setControl({
            errors: [],
            name: autoCompleteField.name
          });
        } else {
          props.setControl({
            errors: [],
            form,
            name: inputTextField.name,
            value: editable ? "none" : ""
          });
        }
        const list = data.map((item: any) => {
          return {
            label: `${item.name} (${item.regno})`,
            value: {
              company_name: item.name,
              regno: item.regno
            }
          };
        });
        // off loading
        setCompanyOptions(list);
      },
      setLoading
    );
  };

  const onSelectCompany = (value: string) => {
    const item: any = companyOptions.find((o: any) => o.value.regno === value);

    setDisplayedRegField(true);

    props.setControl({
      errors: [],
      form,
      name: autoCompleteField.name,
      value: item.value.company_name
    });
    props.setControl({
      errors: [],
      form,
      name: "company_name",
      value: item.value.company_name
    });
    props.setControl({
      errors: [],
      form,
      name: inputTextField.name,
      value: item.value.regno
    });
    if (props.onChangeInput) {
      props.onChangeInput("companyName", item.value.company_name);
      props.onChangeInput("registrationNumber", item.value.regno);
    }
  };

  const handleChangeInput = (name: string) => (e: any) => {
    props.onChangeInput(name, e.target.value);
  };

  return (
    <div>
      {!isMY ? (
        <>
          <AutoComplete
            form={form}
            options={companyOptions.map((o: any) => ({
              label: o.label,
              value: o.value.regno
            }))}
            defaultValue={_get(input, "companyName", "")}
            onSearch={onSearchCompanyName}
            onSelect={onSelectCompany}
            description={
              <>
                {!shouldDisplayRegField && (
                  <small
                    className={classNames("text-muted", {
                      [styles.textDescription]: true
                    })}
                  >
                    <span className={styles.linkHereLabel}>
                      {inputTextField.linkHereLabel}{" "}
                    </span>
                    <a href={getLinkFindUen} target="_blank">
                      {T.transl("HERE_LINK")}.
                    </a>
                  </small>
                )}
              </>
            }
            loading={loading}
            {...autoCompleteField}
          />
          <InputText
            form={form}
            defaultValue={_get(input, "companyName")}
            label={"Supplier's company name"}
            name={"company_name"}
            disabled={true}
            hidden={!displayedRegField || shouldDisplayRegField}
            className={
              (!displayedRegField || shouldDisplayRegField) && styles.hidden
            }
            description={supplierOptInCheckBox ? supplierOptInCheckBox : null}
          />
        </>
      ) : (
        <InputText
          form={form}
          defaultValue={_get(input, "companyName")}
          {...autoCompleteField}
        />
      )}

      <InputText
        form={form}
        defaultValue={_get(input, "registrationNumber")}
        onChange={handleChangeInput("registrationNumber")}
        description={
          isMY || shouldDisplayRegField ? (
            <small
              className={classNames("text-muted", {
                [styles.textDescription]: true
              })}
            >
              <span className={styles.linkHereLabel}>
                {inputTextField.linkHereLabel}{" "}
              </span>
              <a href={getLinkFindUen} target="_blank">
                {T.transl("HERE_LINK")}.
              </a>
            </small>
          ) : null
        }
        {...inputTextField}
        disabled={disabledUenInput}
        hidden={!displayedRegField}
        className={!displayedRegField && styles.hidden}
      />
    </div>
  );
};

export default View;
