import React, { useState } from "react";
import _isEmpty from "lodash-es/isEmpty";
import _get from "lodash-es/get";
import * as queryString from "query-string";

import { FetchModalID } from "src/ipm-shared/components/GlobalUI/actions";
import { history } from "src/ipm-shared/store";

import KYCContents from "src/bepaid/pages/KYC/components/KYCContents";
import ROUTES from "src/bepaid/routes";
import * as localStorageUtil from "src/bepaid/utils/localStorage";

import styles from "./KYC.module.scss";

const View = (props: any) => {
  const qs = queryString.parse(window.location.search);
  let { redirect_url }: any = qs;

  const toggleModal = () => {
    props.toggleModal(FetchModalID.KYC_MODAL);
    history.push(ROUTES.ROOT);
  };

  const onSaved = () => {
    toggleModal();
  };

  const [firstLogin, setFirstLogin] = useState("");

  const showKYCPromptFirstLogin = () => {
    setFirstLogin("1");
  };

  const showKYCPromptBeforeCreateInvoice = () => {
    if (props.isSingaporeAccount && props.isPersonalAccount) {
      props.fetchMyInfoSingPass(({ confirmed = false, info }: any) => {
        if (!confirmed) {
          let pathFinal = `${ROUTES.MY_INFO}?redirect_url=${ROUTES.KYC}`;
          if (redirect_url) {
            pathFinal += encodeURIComponent(`?redirect_url=${redirect_url}`);
          }
          history.push(pathFinal);
        } else {
          setFirstLogin("0");
        }
      });
    } else {
      setFirstLogin("0");
    }
  };

  React.useEffect(() => {
    const { collectedAccount } = props;
    const isCollectedAccount = !_isEmpty(collectedAccount);
    const isDraftCollectedAccount =
      isCollectedAccount && collectedAccount.draft;
    const hasBizfile =
      isCollectedAccount &&
      !_isEmpty(
        _get(collectedAccount, "payeeData.kycDocuments.b_acra_bizfile", [])
      );
    const hasMyinfoBiz =
      isCollectedAccount &&
      !_isEmpty(_get(collectedAccount, "payeeData.myinfoBiz", null));
    if (!isCollectedAccount) {
      showKYCPromptFirstLogin();
    } else if (isDraftCollectedAccount) {
      if (hasBizfile || hasMyinfoBiz) {
        showKYCPromptBeforeCreateInvoice();
      } else {
        showKYCPromptFirstLogin();
      }
    }
  }, []);

  const renderContent = () => (
    <div className={styles.content}>
      <KYCContents
        onSaved={onSaved}
        firstLogin={firstLogin}
        redirectUrl={redirect_url}
      />
    </div>
  );

  if (!firstLogin || !localStorageUtil.isDisplayedSplashScreen()) {
    return null;
  }
  return <div className={styles.wrapper}>{renderContent()}</div>;
};

export default View;
