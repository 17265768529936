import React, { useEffect, useState } from "react";
import _get from "lodash-es/get";
import { isDesktop } from "react-device-detect";

import { FORM } from "src/ipm-shared/store/model/Payee/const";
import store from "src/ipm-shared/store";
import { getBanksOptionsByCountryId } from "src/ipm-shared/store/model/Bank/selectors";
import CurrencyUtil from "src/ipm-shared/Utils/Currency";
import CountryUtil from "src/ipm-shared/Utils/Country";

import Form from "src/bepaid/components/Form";
import InputText from "src/bepaid/components/Form/controls/InputText";
import Checkbox from "src/bepaid/components/Form/controls/Checkbox";
import InputFileDropZone from "src/bepaid/components/Form/controls/InputFile/components/InputFileDropZone";
import IconUpload from "src/bepaid/assets/images/common/icon_upload.svg";
import Skeleton from "src/bepaid/components/Skeleton";
import FormErrors from "src/ipm-shared/components/Form/helpers/Errors";

import styles from "../../RecipientModal.module.scss";

import DomesticRecipientLandlordFormContent from "./DomesticRecipientLandlordFromContent";
import IntRecipientLandlordFormContent from "./IntRecipientLandlordFormContent";

export const RecipientLandlordForm = (props: any) => {
  const {
    isHongKongAccount,
    isAustraliaAccount,
    residentialAddress,
    countryId,
    editMode,
    editedId,
    fetchPayees,
    initName,
    accountCountryCode,
    currencyId,
    paymentCountryCode,
    paymentCurrencyCode,
    isWallex,
    isBusinessAccount
  } = props;
  const [loading, setLoading] = useState(false);
  const [editedPayee, setEditedPayee] = useState(null);

  const accountCountryId = CountryUtil.getCountryIdFromCountryCode(
    accountCountryCode
  );
  const accountCurrencyCode = CountryUtil.getCurrencyCodeFromCountryId(
    accountCountryId
  );

  const filterBank = getBanksOptionsByCountryId(
    store.getState(),
    _get(editedPayee, "is_international")
      ? _get(editedPayee, "country_id")
      : countryId
  );

  const [currencyCode, setCurrencyCode] = useState(accountCurrencyCode);

  const rentCurrency = CurrencyUtil.convertFromCurrencyCode(currencyCode);

  const onChangeCurrencyCustom = (e: any) => {
    setCurrencyCode(e.target.value);
  };
  const onChangePostalCode = (code: any) => {
    props.getAddressByPostalCode(code);
  };

  useEffect(() => {
    if (isHongKongAccount && rentCurrency?.id) {
      props.setControl({
        errors: [],
        form: FORM,
        name: "hk_payee_multiple_currency",
        value: rentCurrency?.id
      });
    }
  }, [rentCurrency?.id, isHongKongAccount]);

  const fetchEditedPayee = () => {
    fetchPayees({
      type: "rent",
      getAllPayees: true,
      noRedirect: true,
      pageCount: 1000,
      cb: (payees: any) => {
        const payee = payees.find((payee: any) => +payee.id === +editedId);
        const paymentCountryCode = CountryUtil.getCountryCodeFromCountryId(
          _get(payee, "data.bank_country_id")
        );

        props.setPaymentCountryCode(paymentCountryCode);
        props.setPaymentCurrencyCode(
          CurrencyUtil.convertFromId(payee.currency_id).code
        );
        setEditedPayee(payee);
      },
      loadingFunc: setLoading
    });
  };

  useEffect(() => {
    if (editMode) {
      fetchEditedPayee();
    }
  }, []);

  const supportingDocumentsProps = {
    inputFileClassName: !isDesktop && styles.inputFileListWrapper,
    allowedFileTypes: ["jpg", "png", "heic", "pdf"],
    name: `supporting_documents`,
    purpose: "tenancy_agreement",
    form: FORM,
    label: "Upload invoice",
    icon: IconUpload,
    canDrag: true,
    multiple: true,
    renderComponent: InputFileDropZone
  };

  const commonFormItemProps = {
    reserveValueOnUnmount: true,
    revertValueOnMount: true
  };

  const domesticProps = {
    FORM,
    editMode,
    editedPayee,
    initName,
    isHongKongAccount,
    isAustraliaAccount,
    onChangePostalCode,
    residentialAddress,
    accountCountryCode,
    filterBank,
    onChangeCurrencyCustom,
    supportingDocumentsProps,
    currencyCode,
    currencyId,
    setControl: props.setControl
  };

  const internationalProps = {
    FORM,
    editMode,
    editedPayee,
    initName,
    supportingDocumentsProps,
    paymentCountryCode,
    paymentCurrencyCode,
    commonFormItemProps,
    filterBank,
    getControl: props.getControl,
    setControl: props.setControl,
    resetErrors: props.resetErrors
  };

  return (
    <div className={styles.wrapperForm}>
      <Skeleton loading={loading}>
        <Form
          name="company-form"
          layout="vertical"
          requiredMark={false}
          className={styles.form}
        >
          <FormErrors form={FORM} />
          {editedPayee && (
            <div hidden={true}>
              <InputText
                name={"payee_id"}
                form={FORM}
                defaultValue={_get(editedPayee, "id", "")}
              />
            </div>
          )}

          {_get(editedPayee, "is_international") || isWallex ? (
            <IntRecipientLandlordFormContent {...internationalProps} />
          ) : (
            <DomesticRecipientLandlordFormContent {...domesticProps} />
          )}
        </Form>
        {paymentCountryCode === "US" && (
          <p className={styles.noteFedwire}>
            {`* `}Please note that Fedwire payments are not supported.
          </p>
        )}
        {isWallex ? (
          <Checkbox
            className={styles.checkboxForm}
            form={FORM}
            name={"confirm_tenancy_agreement"}
            presentedValue="checked"
            singleSelect={true}
            defaultChecked={true}
            label={
              "I confirm I am paying rent directly to my landlord as per my tenancy agreement."
            }
            hidden={true}
            {...commonFormItemProps}
          />
        ) : (
          <>
            <Checkbox
              className={styles.checkboxForm}
              form={FORM}
              name={"confirm_tenancy_agreement"}
              presentedValue="checked"
              singleSelect={true}
              label={
                "I confirm I am paying rent directly to my landlord as per my tenancy agreement."
              }
            />
            {isBusinessAccount && (
              <Checkbox
                wrapperClassName={styles.checkboxFormBusiness}
                form={FORM}
                name={"rent_confirm_tenancy_agreement_business"}
                presentedValue="checked"
                singleSelect={true}
                label={
                  "I understand that if the landlord is a registered business, I can confirm that I and/or the business making payment have no affiliation to it."
                }
                defaultChecked={!isBusinessAccount}
              />
            )}
          </>
        )}
      </Skeleton>
    </div>
  );
};
