import { getType } from "typesafe-actions";
import * as types from "./types";
import * as actions from "./actions";
import _isEmpty from "lodash-es/isEmpty";

export type Card = {
  id: number;
  name: string;
  last4: string;
  bankId: number;
  brandId: number;
  expiryMonth: string;
  expiryYear: string;
  isWallet?: boolean;
  acquirerId: number;
  isFavourite?: boolean;
  cardNickName?: string;
  oldAcquirerId: number;
};

export type CardsState = {
  readonly isFetching: boolean;
  readonly byId: { [id: string]: Card };
  readonly selectedId: number;
  readonly token: string;
  readonly editedId: number;
};

const defaultState: CardsState = {
  byId: {},
  editedId: -1,
  isFetching: false,
  selectedId: 0,
  token: ""
};

export default (state: CardsState, action: types.Action) => {
  if (_isEmpty(state)) {
    state = defaultState;
  }

  switch (action.type) {
    case getType(actions.setCardIsFetching):
      return {
        ...state,
        isFetching: action.payload
      };

    case getType(actions.setCards):
      const m = {};
      action.payload.cards.forEach(card => {
        m[card.id] = card;
      });

      return {
        ...state,
        byId: m,
        isFetching: action.payload.isFetching,
        selectedId: action.payload.selectedId
      };
    case getType(actions.setSelectedCard):
      return {
        ...state,
        selectedId: action.payload.id
      };

    case getType(actions.selectCardToEdit):
      return {
        ...state,
        editedId: action.payload.id
      };

    // case getType(actions.storeToken):
    //   return { ...state, token: action.payload };
  }
  return state;
};
