/* tslint:disable */
// tslint:disable-object-literal-sort-keys
const t = [
  {
    id: "I1",
    name: "Early payment discount",
    default_rate: 150,
    deadline_label: "INCENTIVES_EARLY_DEADLINE",
    fee_label: "INCENTIVES_EARLY_FEE"
  },
  {
    id: "I2",
    name: "Immediate payment discount",
    default_rate: 200,
    deadline_label: "INCENTIVES_IMMEDIATE_DEADLINE",
    fee_label: "INCENTIVES_IMMEDIATE_FEE"
  },
  {
    id: "P1",
    name: "Half now, half later",
    default_rate: 0,
    deadline_label: "INCENTIVES_TWO_INSTALLMENTS_DEADLINE",
    fee_label: null
  },
  {
    id: "P2",
    name: "Pay in three installments",
    default_rate: 0,
    deadline_label: "INCENTIVES_THREE_INSTALLMENTS_DEADLINE",
    fee_label: null
  },
  {
    id: "D1",
    name: "Late payment remediation",
    default_rate: 250,
    deadline_label: "INCENTIVES_LATE_DEADLINE",
    fee_label: "INCENTIVES_LATE_FEE"
  }
];
export default t;
