import React, { useEffect, useMemo, useRef, useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import DesktopModalLayout from "src/bepaid/components/Modal/components/DesktopModalLayout";
import MainLayout from "src/bepaid/layouts/MainLayout";

import Skeleton from "antd/es/skeleton";
import Progress from "src/bepaid/components/Progress";
import CardSelector from "src/bepaid/components/CardSelector";
import Button from "src/bepaid/components/Form/controls/Button";

import { invokeMessage } from "src/bepaid/components/Message";

import { history } from "src/ipm-shared/store";

import { FetchModalID } from "src/ipm-shared/components/GlobalUI/actions";
import { CHECKOUT_FORM } from "src/ipm-shared/store/model/Card/const";
import FormErrors from "src/ipm-shared/components/Form/helpers/Errors";
import { PAY_WITH_ANOTHER_CARD_VALUE } from "src/bepaid/components/CardSelector/View";

import { useMakePaymentUrlParams } from "src/bepaid/pages/Payments/hooks";
import { purposeByEntity } from "src/bepaid/pages/Payments/const";

import T from "src/ipm-shared/Utils/Intl";
import ScrollUtil from "src/ipm-shared/Utils/Scroll";
import PaymentUtil from "src/ipm-shared/Utils/Payment";

import Invoice from "./components/Invoice";
import ApplyPromoCode from "./components/ApplyPromoteCode";
import ChkAgreeTerms from "./components/ChkAgreeTerms";

import _get from "lodash-es/get";

import { BRAND, CRYPTO } from "src/ipm-shared/Utils/Images";

import { PURPOSE } from "src/ipm-shared/components/Purpose/const";
import CardUtil from "src/ipm-shared/Utils/Card";
import FeatureGateUtil from "src/ipm-shared/components/FeatureGating/Util";
import { getRecipientPurpose } from "src/bepaid/utils/recipient";
import CryptoSelector from "src/bepaid/components/CryptoSelector";
import Checkbox from "src/bepaid/components/Form/controls/Checkbox";

import SGFlashpayFeesBg from "src/bepaid/assets/images/promo/sg_flashpay_fees_backgound.png";
import HKFlashpayFeesBg from "src/bepaid/assets/images/promo/hk_flashpay_fees_backgound.png";
import AUFlashpayFeesBg from "src/bepaid/assets/images/promo/au_flashpay_fees_backgound.png";

import * as Images from "src/ipm-shared/components/Images";

import classNames from "classnames";
import styles from "./CheckoutPaymentModal.module.scss";

const purposeTypes: Array<string> = ["Rent", "Supplier", "Tax"];

const View = (props: any) => {
  const {
    shouldDisplayLoadingCards,
    fetchPaymentRequest,
    fetchPayees,
    toggleModal,
    closeModal,
    openModal,
    firstCardIdInWallet,
    // hasAtLeastOneCard,
    selectedId,
    cards,
    wpMasterCard,
    wpVisaCard,
    onClose,
    getControl,
    ipmMerchantCategories,
    validateIpmMerchantCategoryId,
    hasError,
    setControl,
    fetchIpmMerchantCategories,
    // selectedPayees,
    worldpayCards,
    availableAcquirers,
    isPersonalAccount,
    isSingaporeAccount,
    isHongKongAccount,
    getCurrentUserId,
    isCryptoPaymentMethod,
    paymentMethodType,
    setPaymentMethodType,
    selectPaymentMethod,
    canAccessCryptoPayment,
    fetchInsurancePayees,
    isDisplayFlashpayFeesPopup,
    countryCode,
    isAustraliaAccount
  } = props;

  const { token, entity, paymentType, action } = useMakePaymentUrlParams();
  const isWallex = paymentType === "international";

  const [confirmedPayment, setConfirmPayment] = useState(false);
  const [disablePayment, setDisablePayment] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isPaymentRequestToolong, setIsPaymentRequestToolong] = useState(false);
  const timer = useRef<any>();

  const [loadingFee, setLoadingFee] = useState(false);
  const [applyingCode, setIsApplyingCode] = useState(false);

  const [selectedCardId, selectCardId] = useState<string>(
    firstCardIdInWallet || PAY_WITH_ANOTHER_CARD_VALUE
  );

  const [selectedCryptoId, selectCryptoId] = useState<number>(CRYPTO.BTC);

  const ipmMerchantValue = _get(
    getControl("ipm_merchant_category_id", CHECKOUT_FORM),
    "value"
  );
  const paymentRequestPayees = props.paymentRequest?.payees || [];

  const isOneTimePayment = !props.paymentRequest?.isScheduled;

  const isFlashPayPayment = props.paymentRequest?.isFlashPay;

  const isChargeToDay = props.paymentRequest?.isChargeToDay;

  const [selectedSelection, setSelectedSelection] = useState(ipmMerchantValue);

  const isAmexMcc = selectedSelection?.includes("_amex");

  const selectedCard = cards.find((c: any) => c.id === selectedCardId);

  const isAmexCard = selectedCard?.brandId === BRAND.AMEX;
  const isUnionCard = selectedCard?.brandId === BRAND.UNION_PAY;

  const [isCardSelectorOpen, openCardselector] = useState(false);

  const [isChecked, setIsChecked] = useState(false);

  const [lastSelectedCryptoId, setLastSelectedCryptoId] = useState(CRYPTO.BTC);

  const [isOpenedFlashpayFeePopup, setIsOpenedFlashpayFeePopup] = useState(
    true
  );

  const SGT = new Date(
    new Date().toLocaleDateString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      timeZone: "Asia/Singapore"
    })
  );
  const isCutOffTime =
    SGT.getHours() < 16 || (SGT.getHours() === 16 && SGT.getMinutes() < 30);

  const FlashpayFeesBg = {
    SG: SGFlashpayFeesBg,
    HK: HKFlashpayFeesBg,
    AU: AUFlashpayFeesBg
  };

  const isAmexPayment =
    purposeTypes.includes(
      getRecipientPurpose({
        ...props.paymentRequest.payees[0],
        purposeId: props.paymentRequest.purposeId
      })
    ) && !isCryptoPaymentMethod;

  const [isAcceptAmexChecked, setIsAcceptAmexChecked] = useState(
    !(isPersonalAccount && isSingaporeAccount) ||
      !isOneTimePayment ||
      !isAmexPayment ||
      isAmexMcc ||
      !isFlashPayPayment
  );

  const findRecurse = (node: any, value: any): any => {
    if (node.value === value) return node;

    var result;
    for (var i = 0; i < node.children?.length; i++) {
      result = findRecurse(node.children[i], value);
      if (result) return result;
    }
    return null;
  };
  let ipmSelected: any = null;
  if (!props.isBusiness) {
    for (const o of ipmMerchantCategories) {
      if (ipmSelected) break;
      ipmSelected = findRecurse(o, selectedSelection);
    }
  } else {
    ipmMerchantCategories.forEach((item: any) => {
      if (item.children.length > 0) {
        for (let i = 0; i < item.children.length; i++) {
          if (item.children[i].value === selectedSelection) {
            ipmSelected = item.children[i];
            return;
          }
        }
      }
      if (ipmSelected) {
        return;
      }
      if (item.value === selectedSelection) {
        ipmSelected = item;
        return;
      }
    });
  }
  let isOnlyWorldPayCard = false;
  let isOnlyWorldpayMasterCard = false;
  let isOnlyWorldpayVisa = false;
  let allowAmexPayment = false;
  let onlySingleAmexPayment = true;
  if (props.isPersonalAccount) {
    allowAmexPayment = true;
  }
  let ignoreWPMCC = false;
  let disableWPMCC = false;
  let payeeAmount = 0;
  if (paymentRequestPayees.length > 0) {
    paymentRequestPayees.forEach((item: any) => {
      if (item) {
        if (entity === "supplier") {
          if (item.isAmexMCC === true) {
            allowAmexPayment = true;
          } else {
            allowAmexPayment = false;
          }
        }
        if (item.isWPMCC === true) {
          isOnlyWorldpayMasterCard = true;
          isOnlyWorldpayVisa = true;
        }
        if (item.defaultAmount > payeeAmount) {
          payeeAmount = item.defaultAmount;
          if (item.ignoreWPMCC === true) {
            ignoreWPMCC = true;
          } else {
            ignoreWPMCC = false;
          }
        }
      }
    });
  }
  isOnlyWorldpayMasterCard = true;
  availableAcquirers.forEach((item: any) => {
    if (item.card_brand_id === 0) {
      isOnlyWorldpayMasterCard = false;
      isOnlyWorldpayVisa = false;
      ignoreWPMCC = true;
      disableWPMCC = true;
    }
  });
  if (isOnlyWorldpayMasterCard) {
    // Do nothing
  } else {
    if (ignoreWPMCC) {
      isOnlyWorldpayMasterCard = false;
    } else {
      if (PaymentUtil.convertIpmMccId(selectedSelection) > 0 && ipmSelected) {
        if (
          ipmSelected.card_brand_ids &&
          ipmSelected.card_brand_ids.includes(BRAND.MASTER_CARD)
        ) {
          isOnlyWorldpayMasterCard = true;
        }
      }
    }
  }

  if (isOnlyWorldpayVisa) {
    // Do nothing
  } else {
    if (ignoreWPMCC) {
      isOnlyWorldpayVisa = false;
    } else {
      if (PaymentUtil.convertIpmMccId(selectedSelection) > 0 && ipmSelected) {
        if (
          ipmSelected.card_brand_ids &&
          ipmSelected.card_brand_ids.includes(BRAND.VISA)
        ) {
          let mccCheck = Number(selectedSelection.split("_")[1]);
          if (mccCheck === 11 || mccCheck === 35 || mccCheck === 25) {
            isOnlyWorldpayVisa = true;
          } else {
            isOnlyWorldpayVisa = true;
          }
        }
      }
    }
  }

  if (isWallex) {
    isOnlyWorldpayMasterCard = true;
    isOnlyWorldpayVisa = true;
    allowAmexPayment = false;
  }

  if (
    props.features &&
    !FeatureGateUtil.verifyFeature(props.features, "AMEX_ALLOW_PAYMENT")
  ) {
    allowAmexPayment = false;
  }

  if (paymentRequestPayees && paymentRequestPayees.length > 1) {
    onlySingleAmexPayment = false;
  }

  // Route to 7399R for Visa payment from account sandeeparath@gmail.com
  if (getCurrentUserId == "48636") {
    isOnlyWorldpayVisa = true;
  }
  // const hasAtLeastOneWPCard = isOnlyWorldPayCard
  //   ? worldpayCards.length > 0
  //   : !(isOnlyWorldpayMasterCard && !wpMasterCard.length) ||
  //     !(isOnlyWorldpayVisa && !wpVisaCard.length);

  const stripeFavouriteCard = cards.find(
    (card: any) => card.isFavourite && CardUtil.isStripe(cards.acquirerId)
  )?.id;
  let WPFavouriteCard: number = 0;
  if (isOnlyWorldPayCard) {
    WPFavouriteCard = worldpayCards.find((card: any) => card.isFavourite)?.id;
  }
  if (isOnlyWorldpayVisa) {
    WPFavouriteCard = wpVisaCard.find((card: any) => card.isFavourite)?.id;
  }
  if (isOnlyWorldpayMasterCard) {
    WPFavouriteCard = wpMasterCard.find((card: any) => card.isFavourite)?.id;
  }

  // const oldCards = useRef(
  //   isOnlyWorldPayCard
  //     ? worldpayCards
  //     : isOnlyWorldpayMasterCard
  //     ? wpMasterCard
  //     : cards
  // );
  // const [oldCards, setOldCards] = useState([]);

  const handleSelectedCard = (cardId: any) => {
    props.selectCard(
      cardId,
      token,
      {
        cb: (err?: any) => {
          if (err) {
            window.Logger.error("selectCard:", err);
          }
        }
      },
      (is: boolean) => {
        setLoadingFee(is);
      }
    );
    selectCardId(cardId);

    let cardBrandId = 0;
    if (cards.length > 0) {
      cardBrandId = _get(
        cards.find((c: any) => c.id === cardId),
        "brandId",
        0
      );
    }
    if (cardBrandId === 0 && worldpayCards.length > 0) {
      cardBrandId = _get(
        worldpayCards.find((c: any) => c.id === cardId),
        "brandId",
        0
      );
    }
    if (cardBrandId === 0 && wpMasterCard.length > 0) {
      cardBrandId = _get(
        wpMasterCard.find((c: any) => c.id === cardId),
        "brandId",
        0
      );
    }
    if (cardBrandId > 0) {
      handleValidateIpmMerchantId(selectedSelection, cardBrandId);
      handleValidateSingleAmexPayment(cardBrandId);
    }
  };

  const handleApplyCoupon = () => {
    const loadingFunc = (is: boolean) => {
      setLoadingFee(is);
      setIsApplyingCode(is);
    };

    props.applyCoupon(
      {
        cb: (err?: any) => {
          window.Logger.error("applyCoupon", err);
        }
      },
      loadingFunc
    );
  };

  const cardSelectorOnChange = (value: string) => {
    if (value === PAY_WITH_ANOTHER_CARD_VALUE) {
      toggleModal(FetchModalID.ADD_NEW_CARD_MODAL, {
        isWorldpayMasterCard: isOnlyWorldpayMasterCard,
        isWorldpayVisa: isOnlyWorldpayVisa,
        isWorldPayCard: isOnlyWorldPayCard,
        isProduction: props.isProduction,
        isInternational: isWallex,
        allowAmexPayment: allowAmexPayment && onlySingleAmexPayment
      });
      return;
    }

    handleSelectedCard(value);
    handleScroll();
  };

  const cryptoSelectorOnChange = (value: any) => {
    selectPaymentMethod(
      value,
      token,
      {
        cb: (err?: any) => {
          if (err) {
            window.Logger.error("selectPaymentMethod:", err);
          }
        }
      },
      (is: boolean) => {
        setLoadingFee(is);
      }
    );
    selectCryptoId(value);
    if (value !== CRYPTO.BINANCE) {
      setLastSelectedCryptoId(value);
    }
    handleScroll();
  };

  const cardSelectorOnVisibleChange = (open: boolean) => {
    let finalCard = cards;
    if (isOnlyWorldpayVisa) {
      finalCard = finalCard.filter((card: any) => {
        if (card.brandId === BRAND.VISA) {
          if (CardUtil.isSGCard(card.acquirerId)) {
            return (
              CardUtil.isWorldpay(card.acquirerId) &&
              !CardUtil.isStripe(card.oldAcquirerId)
            );
          }
        }
        return true;
      });
    } else {
      finalCard = finalCard.filter((card: any) => {
        if (card.brandId === BRAND.VISA) {
          if (CardUtil.isSGCard(card.acquirerId) && !disableWPMCC) {
            return (
              !CardUtil.isWorldpay(card.acquirerId) ||
              CardUtil.isStripe(card.oldAcquirerId) ||
              (CardUtil.isWorldpay(card.acquirerId) &&
                !CardUtil.isWorldpay(card.oldAcquirerId))
            );
          }
        }
        return true;
      });
    }
    if (isOnlyWorldpayMasterCard) {
      finalCard = finalCard.filter((card: any) => {
        if (card.brandId === BRAND.MASTER_CARD) {
          if (CardUtil.isSGCard(card.acquirerId)) {
            return (
              CardUtil.isWorldpay(card.acquirerId) &&
              !CardUtil.isStripe(card.oldAcquirerId)
            );
          }
        }
        return true;
      });
    } else {
      finalCard = finalCard.filter((card: any) => {
        if (card.brandId === BRAND.MASTER_CARD) {
          if (CardUtil.isSGCard(card.acquirerId) && !disableWPMCC) {
            return (
              !CardUtil.isWorldpay(card.acquirerId) ||
              CardUtil.isStripe(card.oldAcquirerId) ||
              (CardUtil.isWorldpay(card.acquirerId) &&
                !CardUtil.isWorldpay(card.oldAcquirerId))
            );
          }
        }
        return true;
      });
    }
    if (allowAmexPayment) {
      finalCard = finalCard.filter((card: any) => {
        if (card.brandId === BRAND.AMEX) {
          return true;
        }
        return true;
      });
    } else {
      finalCard = finalCard.filter((card: any) => {
        if (card.brandId === BRAND.AMEX) {
          return false;
        }
        return true;
      });
    }
    if (finalCard.length === 0) {
      toggleModal(FetchModalID.ADD_NEW_CARD_MODAL, {
        isWorldpayMasterCard: isOnlyWorldpayMasterCard,
        isWorldpayVisa: isOnlyWorldpayVisa,
        isWorldPayCard: isOnlyWorldPayCard,
        isProduction: props.isProduction,
        isInternational: isWallex,
        allowAmexPayment: allowAmexPayment
      });
      return;
    }

    openCardselector(open);
  };

  const onChangeConfirmPayment = (check: any) => {
    setIsChecked(check);
    if (hasError(CHECKOUT_FORM)) {
      return;
    }
    // setConfirmPayment(e.target.checked);
  };

  const onChangeAcceptAmexConfirmCheckbox = (e: any) => {
    setIsAcceptAmexChecked(e.target.checked);
  };

  const handleScroll = () => {
    ScrollUtil.scrollTopElementId("modal-body-content");
  };

  const handleValidateIpmMerchantId = (
    ipmMerchantId?: string,
    cardBrandId?: number
  ) => {
    if (cardBrandId === BRAND.UNION_PAY || entity !== "supplier" || isWallex) {
      setControl({
        errors: [],
        form: CHECKOUT_FORM,
        name: "ipm_merchant_category_id",
        value: undefined
      });
    } else {
      setControl({
        errors: [],
        form: CHECKOUT_FORM,
        name: "ipm_merchant_category_id",
        value: ipmMerchantId
      });
      validateIpmMerchantCategoryId(ipmMerchantId, cardBrandId);
    }
  };

  const handleValidateSingleAmexPayment = (cardBrandId?: number) => {
    if (cardBrandId === BRAND.AMEX && paymentRequestPayees.length > 1) {
      setDisablePayment(true);
      switch (entity) {
        case "landlord":
          setControl({
            errors: [
              {
                code: "ERROR_MULTIPLE_LANDLORDS_AMEX",
                message: T.transl("ERROR_MULTIPLE_LANDLORDS_AMEX")
              }
            ],
            form: CHECKOUT_FORM,
            name: "selected_card",
            value: undefined
          });
        case "supplier":
          setControl({
            errors: [
              {
                code: "ERROR_MULTIPLE_SUPPLIERS_AMEX",
                message: T.transl("ERROR_MULTIPLE_SUPPLIERS_AMEX")
              }
            ],
            form: CHECKOUT_FORM,
            name: "selected_card",
            value: undefined
          });
        case "tax":
          setControl({
            errors: [
              {
                code: "ERROR_MULTIPLE_TAX_AMEX",
                message: T.transl("ERROR_MULTIPLE_TAX_AMEX")
              }
            ],
            form: CHECKOUT_FORM,
            name: "selected_card",
            value: undefined
          });
      }
    } else {
      setDisablePayment(false);
      setControl({
        errors: [],
        form: CHECKOUT_FORM,
        name: "selected_card",
        value: undefined
      });
    }
  };

  const fetchPaymentDescriptions = (
    cardBrandId?: number,
    justGetData?: boolean,
    cb?: any
  ) => {
    fetchIpmMerchantCategories(cardBrandId, PURPOSE.INVOICE, justGetData, cb);
  };

  useEffect(() => {
    if (selectedCard) {
      handleValidateSingleAmexPayment(selectedCard.brandId);
    }
  }, [paymentRequestPayees.length]);

  useEffect(() => {
    const purpose = purposeByEntity[entity];

    if (token) {
      // fetch payment requests
      fetchPaymentRequest(token, {
        cb: (err?: any) => {
          if (!err) {
            return;
          }
        }
      });
      // fetch payees
      if (purpose === "insurance") {
        fetchInsurancePayees({});
      } else {
        fetchPayees({ type: purpose, isWallex, pageCount: 1000 });
      }
      setIsOpenedFlashpayFeePopup(false);
    } else {
      invokeMessage("error", "Wrong token!");
    }
  }, []);

  useEffect(() => {
    fetchPaymentDescriptions(undefined, false);
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (selectedSelection) {
      urlParams.set("mcc", selectedSelection);
      history.replace({
        pathname: `${window.location.pathname}`,
        search: "?" + urlParams.toString()
      });
    } else {
      if (urlParams.get("mcc")) {
        setSelectedSelection(urlParams.get("mcc"));
      }
    }

    if (paymentMethodType !== "") {
      urlParams.set("payment_method_type", paymentMethodType);
      history.replace({
        pathname: `${window.location.pathname}`,
        search: "?" + urlParams.toString()
      });
    } else {
      if (urlParams.get("payment_method_type")) {
        setPaymentMethodType(urlParams.get("payment_method_type"));
      }
    }
  }, [selectedCard, paymentMethodType]);

  useEffect(() => {
    selectPaymentMethod(
      canAccessCryptoPayment && isCryptoPaymentMethod && !isWallex
        ? selectedCryptoId
        : 1,
      token,
      {
        cb: (err?: any) => {
          if (err) {
            window.Logger.error("selectPaymentMethod:", err);
          }
        }
      },
      (is: boolean) => {
        setLoadingFee(is);
      }
    );
  }, [isCryptoPaymentMethod, canAccessCryptoPayment]);

  useEffect(() => {
    if (isCryptoPaymentMethod) return;
    if (selectedId && !shouldDisplayLoadingCards) {
      let finalCard = cards;
      if (isOnlyWorldPayCard) {
        finalCard = finalCard.filter((card: any) => {
          return CardUtil.isWorldpay(card.acquirerId);
        });
      }
      if (isOnlyWorldpayVisa) {
        finalCard = finalCard.filter((card: any) => {
          if (card.brandId === BRAND.VISA) {
            if (CardUtil.isSGCard(card.acquirerId)) {
              return (
                CardUtil.isWorldpay(card.acquirerId) &&
                !CardUtil.isStripe(card.oldAcquirerId)
              );
            }
          }
          return true;
        });
      } else {
        finalCard = finalCard.filter((card: any) => {
          if (card.brandId === BRAND.VISA) {
            if (CardUtil.isSGCard(card.acquirerId) && !disableWPMCC) {
              return (
                !CardUtil.isWorldpay(card.acquirerId) ||
                CardUtil.isStripe(card.oldAcquirerId) ||
                (CardUtil.isWorldpay(card.acquirerId) &&
                  !CardUtil.isWorldpay(card.oldAcquirerId))
              );
            }
          }
          return true;
        });
      }
      if (isOnlyWorldpayMasterCard) {
        finalCard = finalCard.filter((card: any) => {
          if (card.brandId === BRAND.MASTER_CARD) {
            if (CardUtil.isSGCard(card.acquirerId)) {
              return (
                CardUtil.isWorldpay(card.acquirerId) &&
                !CardUtil.isStripe(card.oldAcquirerId)
              );
            }
          }
          return true;
        });
      } else {
        finalCard = finalCard.filter((card: any) => {
          if (card.brandId === BRAND.MASTER_CARD) {
            if (CardUtil.isSGCard(card.acquirerId) && !disableWPMCC) {
              return (
                !CardUtil.isWorldpay(card.acquirerId) ||
                CardUtil.isStripe(card.oldAcquirerId) ||
                (CardUtil.isWorldpay(card.acquirerId) &&
                  !CardUtil.isWorldpay(card.oldAcquirerId))
              );
            }
          }
          return true;
        });
      }
      if (allowAmexPayment) {
        finalCard = finalCard.filter((card: any) => {
          if (card.brandId === BRAND.AMEX) {
            return true;
          }
          return true;
        });
      } else {
        finalCard = finalCard.filter((card: any) => {
          if (card.brandId === BRAND.AMEX) {
            return false;
          }
          return true;
        });
      }
      // setOldCards(finalCard);
      if (finalCard.length > 0) {
        if (
          isOnlyWorldPayCard ||
          isOnlyWorldpayVisa ||
          isOnlyWorldpayMasterCard
        ) {
          if (finalCard.find((card: any) => card.id === selectedId)) {
            handleSelectedCard(selectedId);
          } else if (
            finalCard.find((card: any) => card.id === WPFavouriteCard)
          ) {
            handleSelectedCard(WPFavouriteCard);
          } else {
            handleSelectedCard(finalCard[0].id);
          }
        } else {
          if (finalCard.find((card: any) => card.id === selectedId)) {
            handleSelectedCard(selectedId);
          } else if (
            finalCard.find((card: any) => card.id === stripeFavouriteCard)
          ) {
            handleSelectedCard(stripeFavouriteCard);
          } else if (
            finalCard.find((card: any) => card.id === WPFavouriteCard)
          ) {
            handleSelectedCard(WPFavouriteCard);
          } else {
            handleSelectedCard(finalCard[0].id);
          }
        }
      } else {
        props.selectCard(0);
        selectCardId(PAY_WITH_ANOTHER_CARD_VALUE);
        return;
      }
    }
  }, [
    selectedId,
    WPFavouriteCard,
    stripeFavouriteCard,
    isOnlyWorldpayVisa,
    isOnlyWorldpayMasterCard,
    allowAmexPayment
  ]);

  useEffect(() => {
    if (isCryptoPaymentMethod) return;
    let finalCard = cards;
    if (isOnlyWorldpayVisa) {
      finalCard = finalCard.filter((card: any) => {
        if (card.brandId === BRAND.VISA) {
          if (CardUtil.isSGCard(card.acquirerId)) {
            return (
              CardUtil.isWorldpay(card.acquirerId) &&
              !CardUtil.isStripe(card.oldAcquirerId)
            );
          }
        }
        return true;
      });
    } else {
      finalCard = finalCard.filter((card: any) => {
        if (card.brandId === BRAND.VISA) {
          if (CardUtil.isSGCard(card.acquirerId)) {
            return (
              !CardUtil.isWorldpay(card.acquirerId) ||
              CardUtil.isStripe(card.oldAcquirerId) ||
              (CardUtil.isWorldpay(card.acquirerId) &&
                !CardUtil.isWorldpay(card.oldAcquirerId))
            );
          }
        }
        return true;
      });
    }
    if (isOnlyWorldpayMasterCard) {
      finalCard = finalCard.filter((card: any) => {
        if (card.brandId === BRAND.MASTER_CARD) {
          if (CardUtil.isSGCard(card.acquirerId)) {
            return (
              CardUtil.isWorldpay(card.acquirerId) &&
              !CardUtil.isStripe(card.oldAcquirerId)
            );
          }
        }
        return true;
      });
    } else {
      finalCard = finalCard.filter((card: any) => {
        if (card.brandId === BRAND.MASTER_CARD) {
          if (CardUtil.isSGCard(card.acquirerId)) {
            return (
              !CardUtil.isWorldpay(card.acquirerId) ||
              CardUtil.isStripe(card.oldAcquirerId) ||
              (CardUtil.isWorldpay(card.acquirerId) &&
                !CardUtil.isWorldpay(card.oldAcquirerId))
            );
          }
        }
        return true;
      });
    }
    if (allowAmexPayment) {
      finalCard = finalCard.filter((card: any) => {
        if (card.brandId === BRAND.AMEX) {
          return true;
        }
        return true;
      });
    } else {
      finalCard = finalCard.filter((card: any) => {
        if (card.brandId === BRAND.AMEX) {
          return false;
        }
        return true;
      });
    }

    if (finalCard.length > 0) {
      handleSelectedCard(finalCard[0].id);
      // if (finalCard.length >= oldCards.length) {
      // }
    } else {
      props.selectCard(0);
      selectCardId(PAY_WITH_ANOTHER_CARD_VALUE);
    }
  }, [cards.length]);

  useEffect(() => {
    if (hasError(CHECKOUT_FORM)) {
      setConfirmPayment(false);
      return;
    }
    if (isChecked && isAcceptAmexChecked) {
      setConfirmPayment(true);
    } else {
      setConfirmPayment(false);
    }
  }, [selectedSelection, selectedCard, isChecked, isAcceptAmexChecked]);

  useEffect(() => {
    if (isCryptoPaymentMethod) {
      setIsAcceptAmexChecked(true);
      return;
    }
    if (isAmexPayment && isPersonalAccount && isOneTimePayment) {
      if (isAmexMcc || !isFlashPayPayment) {
        setIsAcceptAmexChecked(true);
      } else {
        setIsAcceptAmexChecked(!isAmexCard);
      }
    } else {
      setIsAcceptAmexChecked(true);
    }
  }, [
    isAmexCard,
    isAmexMcc,
    isOneTimePayment,
    isCryptoPaymentMethod,
    isFlashPayPayment,
    isAmexPayment,
    isPersonalAccount
  ]);

  // useEffect(() => {
  //   if (hasFormError && selectedCard?.brandId === BRAND.AMEX) {
  //     if (loadingFee) {
  //       setShowSelect(false);
  //     } else {
  //       fetchPaymentDescriptions(
  //         selectedCard?.brandId,
  //         true,
  //         (err?: any, data?: any) => {
  //           if (!err) {
  //             setOptions(data);
  //           }
  //         }
  //       );
  //       setShowSelect(true);
  //     }
  //   } else if (selectedCard?.brandId !== BRAND.AMEX) {
  //     setOptions(initOptions);
  //     setShowSelect(false);
  //   }
  // }, [selectedCard, hasFormError, loadingFee]);

  useEffect(() => {
    if (timer.current) {
      return () => clearTimeout(timer.current);
    }
    return;
  }, []);

  const handleCloseFlashpayFeesPopup = () => {
    closeModal(FetchModalID.PROMO_POPUP);
  };

  const handleAcceptFlashpayFees = () => {
    setControl({
      errors: [],
      form: CHECKOUT_FORM,
      name: "is_accept_flash_pay",
      value: "checked"
    });
    handleSelectedCard(selectedCardId);
    handleCloseFlashpayFeesPopup();
  };

  useEffect(() => {
    if (
      !loadingFee &&
      cards.length > 0 &&
      !isOpenedFlashpayFeePopup &&
      isDisplayFlashpayFeesPopup &&
      (isSingaporeAccount || isHongKongAccount || isAustraliaAccount) &&
      isOneTimePayment &&
      typeof selectedCardId === "number" &&
      selectedCardId !== 0 &&
      isCutOffTime &&
      isChargeToDay &&
      !isWallex
    ) {
      const extraInfoData = {
        onYes: handleAcceptFlashpayFees,
        onNo: handleCloseFlashpayFeesPopup,
        yesLabel: "Accept offer",
        yesLabelLoading: "Accepting...",
        yesButtonClassname: styles.yesButton,
        noLabel: "No thanks",
        popupBgImageUrl: FlashpayFeesBg[countryCode],
        popupBgImageClassname: styles.flashpayFeesPopupBgImage,
        hidePopupImage: true
      };
      openModal(FetchModalID.PROMO_POPUP, extraInfoData);
      setIsOpenedFlashpayFeePopup(true);
    }
  }, [
    loadingFee,
    cards.length,
    isOpenedFlashpayFeePopup,
    isDisplayFlashpayFeesPopup,
    isSingaporeAccount,
    isOneTimePayment,
    selectedCardId,
    isCutOffTime,
    isChargeToDay
  ]);

  const submitLabel = useMemo(() => {
    return loading ? "Paying..." : "Pay now";
  }, [loading]);

  const onBack = () => {
    history.goBack();
  };

  const handleError = (err: any, data?: any) => {
    if (err?.message) {
      invokeMessage("error", err.message);
    }
  };

  const onSubmitCheckout = () => {
    localStorage.setItem(
      "checkoutUrl",
      window.location.pathname + window.location.search
    );
    setLoading(true);
    timer.current = setTimeout(() => {
      setIsPaymentRequestToolong(true);
    }, 15000);
    props.fulfilPaymentSubmit((err?: any) => {
      if (err) {
        handleError(err);
        setLoading(false);
      }
    });
  };

  const moveForward = () => {
    onSubmitCheckout();
  };

  const onClickRetry = () => {
    setLoading(false);
    setIsPaymentRequestToolong(false);
  };

  const renderBtnSubmit = () => {
    return (
      <>
        <Button
          id={"ipaymy_btn_checkout_payment"}
          wrapperClassName={styles.wrapperButton}
          onClick={moveForward}
          type="primary"
          htmlType="button"
          loading={loading || !confirmedPayment || disablePayment}
        >
          {submitLabel}
        </Button>
        {isPaymentRequestToolong ? (
          <a className={styles.helperText} onClick={onClickRetry}>
            {T.transl("PAY_WAITING_TOO_LONG")}
          </a>
        ) : (
          <p
            className={styles.helperText}
            hidden={!loading || action !== "checkout"}
          >
            {T.transl("PAY_BROWSER_WARNING")}
          </p>
        )}
      </>
    );
  };

  const renderDesktopView = () => {
    return (
      <DesktopModalLayout
        maxWidth450={true}
        onSubmit={moveForward}
        title={props.titleLabel}
        onClose={onClose}
        onBack={onBack}
        progress={
          <Progress colors={["#937CF2", "#A581E8"]} value={props.progress} />
        }
        submitButtonClassName={""}
        btnSubmit={renderBtnSubmit()}
      >
        {renderContent()}
      </DesktopModalLayout>
    );
  };

  const renderMobileView = () => {
    return (
      <MainLayout
        header={{
          title: props.titleLabel,
          hideMenu: true,
          onBack,
          onClose,
          progress: (
            <Progress colors={["#937CF2", "#A581E8"]} value={props.progress} />
          )
        }}
        footer={renderBtnSubmit()}
      >
        {renderContent()}
      </MainLayout>
    );
  };

  const renderContent = () => {
    return (
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <div id="form-error-wrapper">
            <FormErrors form={CHECKOUT_FORM} />
          </div>
          <div hidden>
            <Checkbox
              id="is_accept_flash_pay"
              name={"is_accept_flash_pay"}
              presentedValue={"checked"}
              defaultChecked={false}
              form={CHECKOUT_FORM}
              label={""}
            />
          </div>
          <Invoice
            {...props}
            loadingFee={loadingFee}
            isPersonalAccount={isPersonalAccount}
            onChangeAcceptAmexConfirmCheckbox={
              onChangeAcceptAmexConfirmCheckbox
            }
            isOneTimePayment={isOneTimePayment}
            isFlashPayPayment={isFlashPayPayment}
            isAmexPayment={isAmexPayment}
            isAmexCard={isAmexCard}
            isAmexMcc={isAmexMcc}
            isUnionCard={isUnionCard}
          />
          <Skeleton loading={shouldDisplayLoadingCards}>
            <div className={styles.cardSelector}>
              {isCryptoPaymentMethod && !isWallex && canAccessCryptoPayment ? (
                <>
                  <CryptoSelector
                    name="selected_crypto"
                    label={"Currency selected"}
                    value={
                      selectedCryptoId === CRYPTO.BINANCE
                        ? lastSelectedCryptoId
                        : selectedCryptoId
                    }
                    onChange={cryptoSelectorOnChange}
                  />
                  {!isSingaporeAccount && (
                    <div className={styles.binanceSelector}>
                      <span>{"Or pay with"}</span>
                      <Button
                        className={classNames(styles.binanceButton, {
                          [styles.selected]: selectedCryptoId === CRYPTO.BINANCE
                        })}
                        htmlType="button"
                        onClick={() => {
                          cryptoSelectorOnChange(CRYPTO.BINANCE);
                        }}
                      >
                        <div className={styles.binanceButtonContent}>
                          <img
                            alt={"crypto icon"}
                            src={Images.binance_logo_dark}
                          />
                          <span>Binance</span>
                        </div>
                      </Button>
                    </div>
                  )}
                </>
              ) : (
                <CardSelector
                  open={isCardSelectorOpen}
                  value={selectedCardId}
                  onChange={cardSelectorOnChange}
                  onDropdownVisibleChange={cardSelectorOnVisibleChange}
                  isOnlyWorldpayMasterCard={isOnlyWorldpayMasterCard}
                  isOnlyWorldpayVisa={isOnlyWorldpayVisa}
                  isOnlyWorldPayCard={isOnlyWorldPayCard}
                  allowAmexPayment={allowAmexPayment}
                  name="selected_card"
                  label={"Card selected"}
                />
              )}
            </div>
          </Skeleton>

          {/* {entity === "supplier" && !isWallex && (
            <div
              className={styles.paymentDescriptionSelector}
              hidden={!showSelect}
            >
              <SelectMultiLevelCustom
                form={CHECKOUT_FORM}
                name={"ipm_merchant_category_id"}
                placeholder={"Select payment description"}
                label={"Payment description"}
                options={options}
                defaultValue={defaultSelection}
                suffixIcon={<Image src={IconArrowDown} />}
                onChange={handleSelectPaymentDescription}
                reserveValueOnUnmount={true}
              />
            </div>
          )} */}

          <ApplyPromoCode
            applyCoupon={handleApplyCoupon}
            loading={applyingCode}
          />

          <ChkAgreeTerms
            onChange={onChangeConfirmPayment}
            isCryptoPaymentMethod={
              isCryptoPaymentMethod && !isWallex && canAccessCryptoPayment
            }
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <BrowserView>{renderDesktopView()}</BrowserView>
      <MobileView>{renderMobileView()}</MobileView>
    </>
  );
};

export default View;
