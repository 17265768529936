import { RouteProps } from "react-router";
import { connect } from "react-redux";

import { RootState } from "src/ipm-shared/store/model/reducers";

// import * as selectors from "src/bepaid/store/model/Fetch/selectors";
import * as actions from "src/bepaid/store/model/Fetch/actions";
import Component from "./View";

const mapStateToProps = (state: RootState) => ({
  // residentialAddress: selectors.getResidentialAddress(state)
});

const mapDispatchToProps = {
  setControl: actions.setControl
};

export type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteProps & { qs: { [param: string]: any }; checkoutUrl?: any };

export default connect(mapStateToProps, mapDispatchToProps)(Component);
