import BaseRestService, {
  RestResponse,
  SendParams,
  DefinedService
} from "./Base";
import * as actions from "src/ipm-shared/store/model/actions";
import * as selectors from "src/ipm-shared/store/model/selectors";
import * as oboe from "oboe";
import store from "src/ipm-shared/store";
import _throttle from "lodash-es/throttle";
import FileUtils from "src/ipm-shared/Utils/Files";
import UrlHelper from "src/ipm-shared/Utils/UrlHelper";
import Is from "src/ipm-shared/Utils/Is";

type SendParamsEx = SendParams & {
  showGlobalLoader?: boolean;
  noRedirect?: boolean;
};

type DownloadParamsEx = SendParams & {
  fileName: string;
  dataOnly?: boolean;
};
class RestService extends BaseRestService {
  public send = (args: SendParamsEx): Promise<any> => {
    if (!args.token) {
      args.token = selectors.getToken(store.getState());
    }

    if (args.token && (args as DefinedService).service !== "logout") {
      const accessTokenAppId = selectors.getAccessTokenAppId(store.getState());
      const appId = process.env.REACT_APP_FETCH_APP_ID || "";
      if (accessTokenAppId !== appId) {
        store.dispatch(actions.stop());
        // if (window.stop) {
        //   window.stop();
        // }
        window.location.href = `/login${UrlHelper.getQsRedirectUrlUserLoggedOut()}`;
        throw new Error("Your session has been expired");
      }
    }
    if (args.showGlobalLoader) {
      store.dispatch(actions.showGlobalLoader());
    }
    return (
      super
        .handleSend(args)

        // Filter response and notify errors using Redux actions.
        // TODO: Better, should normalize response for specific services.
        .then((response: RestResponse) => {
          if (args.showGlobalLoader) {
            store.dispatch(actions.hideGlobalLoader());
          }

          if (response.headers.get("x-location")) {
            const redirectUrl = response.headers.get("x-location") as string;
            const redirectMethod = response.headers.get("x-method") as string;

            if (redirectMethod && redirectMethod === "POST") {
              response.json().then(respJson => {
                const form = document.createElement("form");
                document.body.appendChild(form);
                form.method = "post";
                form.action = redirectUrl;
                for (const name in respJson) {
                  if (name in respJson) {
                    const input = document.createElement("input");
                    input.type = "hidden";
                    input.name = name;
                    input.value = respJson[name];
                    form.appendChild(input);
                  }
                }
                form.submit();
              });
            } else {
              if (!args.noRedirect) {
                window.location.href = response.headers.get(
                  "x-location"
                ) as string;
              }
            }

            if (!args.noRedirect) {
              throw new Error("Need to redirect");
            }
          }

          if (
            (200 <= response.status && response.status <= 300) ||
            response.status === 422
          ) {
            return response;
          }

          switch (response.status) {
            case 401:
              store.dispatch(actions.stop());
              // if (window.stop) {
              //   window.stop();
              // }
              // @ts-ignore
              if (window.heap && Is.live()) {
                // @ts-ignore
                window.heap.resetIdentity();
              }

              if (window.location.pathname !== "/login") {
                window.location.href = `/login${UrlHelper.getQsRedirectUrlUserLoggedOut()}`;
              }

              throw new Error("Your session has been expired");
            case 403:
              throw new Error("Permission denied");

            case 500:
            default:
              throw new Error("Server errors");
          }
        })
        .then(response => {
          if (args.noRedirect && response.headers.get("x-location")) {
            return {
              xLocation: response.headers.get("x-location") as string
            };
          } else {
            return response.json();
          }
        })
        .catch(_throttle(handleRequestError.bind(undefined, args), 3000))
    );
  };

  public download = (args: DownloadParamsEx) => {
    if (!args.token) {
      args.token = selectors.getToken(store.getState());
    }

    return super
      .handleSend(args)

      .then((response: RestResponse) => {
        if (200 <= response.status && response.status <= 300) {
          return response.blob().then(blob => {
            if (!args.dataOnly) {
              const blobUrl = window.URL.createObjectURL(blob);
              FileUtils.forceDownload(blobUrl, args.fileName);
            }
            return blob;
          });
        }

        if (response.status === 422) {
          return response.json();
        }
        throw new Error("Server errors");
      })
      .catch(reason => {
        window.Logger.guestError(reason);
      });
  };

  public fetchJsonStream = (args: SendParamsEx) => {
    if (!args.token) {
      args.token = selectors.getToken(store.getState());
    }

    if (args.showGlobalLoader) {
      store.dispatch(actions.showGlobalLoader());
    }

    args.timeout = -1;
    return (
      super
        .handleSend(args)

        // Filter response and notify errors using Redux actions.
        // TODO: Better, should normalize response for specific services.
        .then((response: Response) => {
          if (args.showGlobalLoader) {
            store.dispatch(actions.hideGlobalLoader());
          }

          if (200 === response.status) {
            return response;
          }

          throw new Error("Need a response");
        })
        .catch(_throttle(handleRequestError.bind(undefined, args), 3000))
    );
  };

  public parseJSONAsStream = (args: SendParamsEx) => {
    if (!args.token) {
      args.token = selectors.getToken(store.getState());
    }

    args.timeout = -1;

    const service = BaseRestService.getServiceInfo(args);
    const { url, method } = service;

    return oboe({
      body: args.body
        ? args.body instanceof FormData
          ? args.body
          : JSON.stringify(args.body)
        : undefined,
      headers: {
        authorization: `Bearer ${args.token}`
      },
      method,
      url,
      withCredentials: true
    });
  };
}

const handleRequestError = (args: SendParamsEx, reason: any) => {
  if (args.showGlobalLoader) {
    store.dispatch(actions.hideGlobalLoader());
  }

  // May log down the error.
  // store.dispatch(guiAtions.toast(reason.message));

  window.Logger.log(reason);
};

export default new RestService();
