import React from "react";

const useConstructor = (callback = () => {}) => {
  const [hasBeenCalled, setHasBeenCalled] = React.useState(false);

  if (hasBeenCalled) {
    return;
  }

  callback();
  setHasBeenCalled(true);
};

export default useConstructor;
