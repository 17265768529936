import { connect } from "react-redux";
import Component from "./View";
import { RootState } from "src/ipm-shared/store/model/reducers";
import * as formSelectors from "src/ipm-shared/components/Form/selectors";
import {
  CRYPTO_OPTION,
  FEE_PAYER
} from "src/ipm-shared/store/model/CollectedAccount/const";
import * as selectors from "./selectors";

import * as actions from "./actions";
import { CollectionRates } from "src/ipm-shared/store/model/CollectedAccount/reducers";

export const getMinMaxFeeRate = (
  feePayer: number,
  reqRate: number,
  collectionRate: number
): number => {
  if (feePayer === FEE_PAYER.COMPANY_PAY) {
    return collectionRate;
  } else if (feePayer === FEE_PAYER.CUSTOMER_PAY) {
    return 0;
  }

  if (collectionRate < reqRate) {
    return collectionRate;
  }

  return reqRate;
};

const mapStateToProps = (state: RootState, props: CollectionSplitFeeProps) => {
  const requesterInternationalRateControlName = `requester_international_rate${
    props.extraName ? `_${props.extraName}` : ""
  }`;
  const requesterNationalRateControlName = `requester_national_rate${
    props.extraName ? `_${props.extraName}` : ""
  }`;
  const requesterCryptoRateControlName = `requester_crypto_rate`;
  return {
    accountCountryCode: selectors.getAccountCountryCode(state),
    collectionRates: selectors
      .getCollectionRates(state)
      .filter(
        r =>
          r.brand_id === props.brandId &&
          (r.currency_id === props.currencyId || r.brand_id === CRYPTO_OPTION)
      )[0],
    feeControls: {
      international: parseInt(
        formSelectors.getControl(state, requesterInternationalRateControlName)
          .value as string,
        10
      ),
      national: parseInt(
        formSelectors.getControl(state, requesterNationalRateControlName)
          .value as string,
        10
      ),
      crypto_rate: parseInt(
        formSelectors.getControl(state, requesterCryptoRateControlName)
          .value as string,
        10
      )
    }
  };
};

const mapDispatchToProps = {
  setControl: actions.setControl
};

export type CollectionSplitFeeProps = {
  form: string;
  defaultValue?: CollectionRates;
  hasActiveProgram?: boolean;
  extraName?: string;
  className?: string;
  brandId: number;
  currencyId: number;
  onChangeCustom?: (args: any) => any;
  renderer?: (
    props: CollectionSplitFeePropsWithConnectedProps,
    handleSplitRate: (type: string, values: number) => void
  ) => JSX.Element | null;
};

export type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  CollectionSplitFeeProps;

export type CollectionSplitFeePropsWithConnectedProps = IProps;
export default connect(mapStateToProps, mapDispatchToProps)(Component);
