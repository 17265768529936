/* tslint:disable:no-string-literal object-literal-sort-keys */
import _get from "lodash-es/get";
import _find from "lodash-es/find";
import _isEmpty from "lodash-es/isEmpty";
import * as DateFns from "date-fns";
import { ActionType } from "typesafe-actions";
import { call, put, select } from "redux-saga/effects";

import {
  catchTakeLatest,
  reTryTakeLatest
} from "src/ipm-shared/Utils/ReduxSagaEffects";
import RestClient from "src/ipm-shared/services/Rest";
import HttpRequestError from "src/ipm-shared/Utils/Exceptions/HttpRequestError";
import { RootState } from "src/ipm-shared/store/model/reducers";
import * as globalUISelectors from "src/ipm-shared/components/GlobalUI/selectors";
import { PLATFORM } from "src/ipm-shared/components/GlobalUI/reducers";
import DateUtil from "src/ipm-shared/Utils/Date";
import { history } from "src/ipm-shared/store";
import { requireCollectedAccountState } from "src/ipm-shared/HOCs/RequiredCollectedAccount";

import { DEFAULT_PAYMENT_STATUS_IDS } from "src/ipm-shared/store/model/Payment/const";

import ROUTES from "src/bepaid/routes";

import * as fetchActions from "./actions";
import * as fetchSelectors from "./selectors";
import { DURATIONS } from "../../../pages/Insights/const";
import { INVOICES_PAGE_COUNT } from "./const";

const actions = {
  ...fetchActions
};

const selectors = {
  ...fetchSelectors
};

const watchedSagas = [
  reTryTakeLatest(actions.getDashboardStatistics, handleGetDashboardStatistics),
  reTryTakeLatest(actions.getInvoiceStatistics, handleGetInvoiceStatistics),
  catchTakeLatest(actions.getInvoiceList, handleGetInvoiceList),
  reTryTakeLatest(actions.getCustomerStatistics, handleGetCustomerStatistics),
  reTryTakeLatest(actions.getCustomerList, handleGetCustomerList),
  reTryTakeLatest(actions.getCustomerActivities, handleGetCustomerActivities),
  reTryTakeLatest(actions.getInvoiceInsights, handleGetInvoiceInsights),
  reTryTakeLatest(actions.getPayInsights, handleGetPayInsights),
  // catchTakeLatest(actions.sendPaymentCollectionRequest), handleSendPaymentRequest),
  catchTakeLatest(
    actions.checkRemindDraftInvoice,
    handleCheckRemindDraftInvoice
  ),
  reTryTakeLatest(
    actions.getInvoiceRecentActivities,
    handleGetInvoiceRecentActivities
  ),
  reTryTakeLatest(actions.getExportPayments, handleGetExportPayments),
  reTryTakeLatest(
    actions.getPaymentRecentActivities,
    handleGetPaymentRecentActivities
  ),
  catchTakeLatest(actions.checkKYC, handleCheckKYC)
];

export default watchedSagas;

export function* handleGetFetchStatistics(duration?: string) {
  let params: any = {
    service: "get_fetch_statistics",
    timeout: 30000
  };

  if (duration && duration !== DURATIONS["all_time"].value) {
    const query = { duration };
    params = { ...params, query };
  }

  const res: Response = yield call(RestClient.send, {
    ...params,
    query: {
      ...(params.query || {}),
      fetch_statistics: "y"
    }
  });

  if (!res) {
    throw new HttpRequestError(": Failed to get fetch statistic");
  }

  try {
    const data: any = _get(res, "fetch_statistics", {});

    // Customer Statistics
    yield put(
      actions.setCustomerStatistics({
        numberActiveCustomers: _get(data, "number_of_active_customers", 0),
        numberNewCustomers: _get(data, "number_of_new_customers", 0),
        totalCustomers: _get(data, "total_customers", 0),
        totalInvoices: _get(data, "total_invoices", 0)
      })
    );

    // Dashboard Statistics
    yield put(
      actions.setDashboardStatistics({
        numberActiveCustomers: _get(data, "number_of_active_customers", 0),
        numberInvoiceSent: _get(data, "number_of_invoices_sent", 0),
        totalPaymentsReceived: _get(data, "total_payments_received", 0)
      })
    );

    // Invoice Insights
    yield put(
      actions.setInvoiceInsights({
        averageDaysPayment: _get(data, "average_days_to_payment", 0),
        averageInvoiceAmount: _get(data, "average_invoice_amount", 0),
        onTimeInvoices: _get(data, "on_time_invoices", 0),
        percentagePunctualInvoices: _get(
          data,
          "percentage_of_punctual_invoices",
          0
        ),
        totalCustomers: _get(data, "total_customers", 0),
        totalInvoices: _get(data, "total_invoices", 0)
      })
    );

    // Invoice Statistics
    yield put(
      actions.setInvoiceStatistics({
        numberInvoiceSent: _get(data, "number_of_invoices_sent", 0),
        percentagePunctualInvoices: _get(
          data,
          "percentage_of_punctual_invoices",
          0
        ),
        totalNumberOfInvoices: _get(data, "total_number_of_invoices", 0),
        totalPaymentsReceived: _get(data, "total_payments_received", 0)
      })
    );

    return true;
  } catch (e) {
    window.Logger.error("handleGetFetchStatistics: ", e.message);
  }

  return false;
}

export function* handleGetPayStatistics(duration?: string) {
  let params: any = {
    service: "get_pay_statistics",
    timeout: 30000
  };

  if (duration && duration !== DURATIONS["all_time"].value) {
    const query = { duration };
    params = { ...params, query };
  }

  const res: Response = yield call(RestClient.send, {
    ...params,
    query: {
      ...(params.query || {}),
      pay_statistics: "y",
      payee_statistics: "y",
      pay_insights: "y"
    }
  });

  if (!res) {
    throw new HttpRequestError(": Failed to get pay statistic");
  }

  try {
    const data: any = _get(res, "pay_statistics", {});
    const payeeStatistics: any = _get(res, "payee_statistics", {});
    const payInsight: any = _get(res, "pay_insights", {});

    const numberLandlords =
      _get(
        _find(payeeStatistics, (item: any) => _get(item, "purpose_id") === 1),
        "total"
      ) || 0;
    const numberEmployees =
      _get(
        _find(payeeStatistics, (item: any) => _get(item, "purpose_id") === 2),
        "total"
      ) || 0;

    const numberSuppliers =
      _get(
        _find(payeeStatistics, (item: any) => _get(item, "purpose_id") === 3),
        "total"
      ) || 0;

    const numberBankAccounts =
      _get(
        _find(payeeStatistics, (item: any) => _get(item, "purpose_id") === 5),
        "total"
      ) || 0;

    // Dashboard Statistics

    yield put(
      actions.setDashboardStatistics({
        numberOfActiveRecipients: _get(data, "number_of_active_recipients", 0),
        numberOfPaymentsMade: _get(data, "number_of_payments_made", 0),
        numberOfRecurringPayments: _get(data, "number_of_recurring_payments"),
        numberOfInProgressPayments: _get(
          data,
          "number_of_in_progress_payments"
        ),
        totalPaymentsMade: _get(data, "total_payments_made", 0),
        numberLandlords,
        numberEmployees,
        numberSuppliers,
        numberBankAccounts
      })
    );
    // Pay Insights
    yield put(
      actions.setPayInsights({
        totalPaymentAmount: _get(payInsight, "total_payment_amount", 0),
        averagePaymentAmount: _get(payInsight, "average_payment_amount", 0),
        recurringPayment: _get(
          payInsight,
          "percentage_of_recurring_payments",
          0
        ),
        oneTimePayment: _get(payInsight, "percentage_of_one_time_payments", 0)
      })
    );
    return true;
  } catch (e) {
    window.Logger.error("handleGetPayStatistics: ", e.message);
  }

  return false;
}

export function* handleGetDashboardStatistics(
  action: ActionType<typeof actions.getDashboardStatistics>
) {
  const state: RootState = yield select();
  const platform = globalUISelectors.getPlatform(state);

  try {
    // checking platform here

    if (platform === PLATFORM.FETCH) {
      yield handleGetFetchStatistics();
      yield put(
        fetchActions.setFirstTimeFetchData({
          fetch_dashboard: true
        })
      );
    }

    if (platform === PLATFORM.PAY) {
      yield handleGetPayStatistics();
      yield put(
        fetchActions.setFirstTimeFetchData({
          pay_dashboard: true
        })
      );
    }
  } catch (e) {
    window.Logger.error("handleGetDashboardStatistics: ", e.message);
  }
}

export function* handleGetInvoiceStatistics(
  action: ActionType<typeof actions.getInvoiceStatistics>
) {
  const { cb = (err?: any) => null } = action.payload;

  try {
    yield handleGetFetchStatistics();

    cb();
  } catch (e) {
    window.Logger.error("handleGetInvoiceStatistics: ", e.message);
  }
}

export function* handleGetInvoiceList(
  action: ActionType<typeof actions.getInvoiceList>
) {
  const { query: qs, cb = (err?: any) => null } = action.payload;

  let param: any = {
    ...qs
  };

  let from;
  let to;

  if (qs && !_isEmpty(qs)) {
    switch (qs.dateQueryKey) {
      case "last_week": {
        from = DateFns.startOfWeek(new Date()).toISOString();
        to = new Date().toISOString();
        break;
      }
      case "last_month": {
        from = DateFns.startOfMonth(new Date()).toISOString();
        to = new Date().toISOString();
        break;
      }
      case "last_three_months": {
        from = DateFns.startOfQuarter(new Date()).toISOString();
        to = new Date().toISOString();
        break;
      }
      case "last_six_months": {
        from = DateFns.subQuarters(
          DateFns.startOfQuarter(new Date()),
          1
        ).toISOString();
        to = new Date().toISOString();
        break;
      }
      case "custom": {
        from = qs.from;
        to = qs.to;
        break;
      }
    }
  }

  if (!_get(param, "statuses")) {
    let statuses = "2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18"; // https://app.clubhouse.io/ipaymy/story/15207/ensure-our-list-of-invoices-on-the-invoices-dashboard-displays-all-recent-invoices-excluding-drafts
    if (_get(param, "search_keyword")) {
      statuses += ",1";
    }
    param = {
      ...param,
      statuses
    };
  }

  const query = {
    ...param,
    has_draft: "n",
    offset: action.payload.offset || 0,
    page_count: action.payload.pageCount || INVOICES_PAGE_COUNT,
    purposes: 4,
    ...(from
      ? {
          charge_date_lower: from,
          charge_date_upper: to
        }
      : {})
  };

  const res: Response = yield call(RestClient.send, {
    query,
    service: "get_payment_collection",
    timeout: 30000
  });

  if (!res) {
    throw new HttpRequestError("Fetch: Failed to get invoice list");
  }

  try {
    const data: any = _get(res, "data") || [];
    const total: number = _get(res, "total", 0);
    if (_get(param, "is_modal_draft") === "y") {
      yield put(
        actions.setInvoiceListModal(
          data.map((item: any) => ({
            customerName: `${_get(item, "payer.first_name")} ${_get(
              item,
              "payer.last_name"
            )}`,
            id: item.id,
            invoiceAmount: _get(item, "payment_amount"),
            invoiceNote: _get(item, "invoice_note"),
            invoiceNumber: _get(item, "payees.0.payment_description"),
            payees: _get(item, "payees"),
            payer: _get(item, "payer"),
            paymentAmount: _get(item, "payment_amount"),
            paymentRequestId: _get(item, "payment_request_id"),
            paymentStatusId: _get(item, "payment_status_id"),
            payoutDate: _get(item, "payout_date"),
            termAndCondition: _get(item, "term_and_condition"),
            supportingDocuments: _get(item, "supporting_documents"),
            step: _get(item, "step", null),
            receiptNumber: _get(item, "receipt_number")
          }))
        )
      );
    } else {
      yield put(
        actions.setInvoiceList(
          data.map((item: any) => ({
            customerName: `${_get(item, "payer.first_name")} ${_get(
              item,
              "payer.last_name"
            )}`,
            id: item.id,
            invoiceAmount: _get(item, "payment_amount"),
            invoiceNote: _get(item, "invoice_note"),
            invoiceNumber: _get(item, "payees.0.payment_description"),
            payees: _get(item, "payees"),
            payer: _get(item, "payer"),
            paymentAmount: _get(item, "payment_amount"),
            paymentRequestId: _get(item, "payment_request_id"),
            paymentStatusId: _get(item, "payment_status_id"),
            payoutDate: _get(item, "payout_date"),
            termAndCondition: _get(item, "term_and_condition"),
            supportingDocuments: _get(item, "supporting_documents"),
            step: _get(item, "step", null),
            receiptNumber: _get(item, "receipt_number")
          })),
          total
        )
      );
    }

    yield put(
      fetchActions.setFirstTimeFetchData({
        invoices: true
      })
    );

    cb();
  } catch (e) {
    window.Logger.error("handleGetInvoiceList: ", e.message);
  }
}

export function* handleGetCustomerStatistics(
  action: ActionType<typeof actions.getCustomerStatistics>
) {
  const { cb = (err?: any) => null } = action.payload;

  try {
    yield handleGetFetchStatistics();

    cb();
  } catch (e) {
    window.Logger.error("handleGetCustomerStatistics: ", e.message);
  }
}

export function* handleGetCustomerList(
  action: ActionType<typeof actions.getCustomerList>
) {
  const { cb = (err?: any, data?: any) => null } = action.payload;
  let { query: qs } = action.payload;

  let params: any = {
    service: "get_customer",
    timeout: 30000
  };

  // const forceRefresh = Number(qs?.offset || 0) === 0;

  const defaultQs = {
    o_created_at: "desc",
    to_create_or_upload_invoice: "y"
  };

  if (qs && !_isEmpty(qs)) {
    let from;
    let to;

    switch (qs.dateQueryKey) {
      case "last_week": {
        from = DateFns.startOfWeek(new Date()).toISOString();
        to = new Date().toISOString();
        break;
      }
      case "last_month": {
        from = DateFns.startOfMonth(new Date()).toISOString();
        to = new Date().toISOString();
        break;
      }
      case "last_three_months": {
        from = DateFns.startOfQuarter(new Date()).toISOString();
        to = new Date().toISOString();
        break;
      }
      case "last_six_months": {
        from = DateFns.subQuarters(
          DateFns.startOfQuarter(new Date()),
          1
        ).toISOString();
        to = new Date().toISOString();
        break;
      }
      case "custom": {
        from = qs.from;
        to = qs.to;
        break;
      }
    }

    qs = {
      ...qs,
      ...(from
        ? {
            from,
            to
          }
        : {})
    };
  }

  params = {
    ...params,
    query: {
      ...defaultQs,
      ...(qs || {})
    }
  };

  const res: Response = yield call(RestClient.send, params);

  if (!res) {
    throw new HttpRequestError("Fetch: Failed to get customer list");
  }

  try {
    const { data = [] }: any = res || {};

    const payloadData = (data || []).map((item: any) => ({
      addressLine1: _get(item, "address_line_1"),
      addressLine2: _get(item, "address_line_2"),
      companyName: _get(item, "company_name"),
      email: _get(item, "email"),
      firstName: _get(item, "first_name"),
      id: item.id,
      lastName: _get(item, "last_name"),
      mobileCountryId: _get(item, "mobile_country_id"),
      mobileNumber: _get(item, "mobile_number"),
      name: `${_get(item, "first_name")} ${_get(item, "last_name")}`,
      phoneCountryId: _get(item, "phone_country_id"),
      phoneNumber: _get(item, "phone_number"),
      registrationNumber: _get(item, "registration_number"),
      tags: _get(item, "tags") || [],
      primaryCommunicationMethod: _get(
        item,
        "payer_data.primary_communication_method",
        ""
      ),
      reviewStatus: _get(item, "review_status")
    }));

    yield put(
      actions.setCustomerList(payloadData, {
        forceRefresh: action.payload.forceRefresh
      })
    );

    const total: number = _get(res, "total", 0);

    yield put(
      actions.setCustomers({
        customers: data.map((c: any) => ({
          addressLine1: _get(c, "address_line_1", null),
          addressLine2: _get(c, "address_line_2", null),
          companyName: c.company_name,
          email: c.email,
          id: c.id,
          mobileCountryId: c.mobile_country_id,
          mobileNumber: c.mobile_number,
          name: `${c.first_name} ${c.last_name}`,
          phoneNumber: c.phone_number,
          registrationNumber: c.registration_number,
          primaryCommunicationMethod: _get(
            c,
            "payer_data.primary_communication_method",
            ""
          ),
          reviewStatus: _get(c, "review_status")
        })),
        isFetching: false,
        total,
        forceRefresh: false
      })
    );
    cb(null, payloadData);
  } catch (e) {
    window.Logger.error("handleGetCustomerList: ", e.message);
  }
}

export function* handleGetInvoiceInsights(
  action: ActionType<typeof actions.getInvoiceInsights>
) {
  try {
    if (action.payload.duration) {
      yield handleGetFetchStatistics(action.payload.duration);
    }
  } catch (e) {
    window.Logger.error("handleGetInvoiceInsights: ", e.message);
  }
}

export function* handleGetPayInsights(
  action: ActionType<typeof actions.getPayInsights>
) {
  try {
    if (action.payload.duration) {
      yield handleGetPayStatistics(action.payload.duration);
    }
  } catch (e) {
    window.Logger.error("handleGetPayInsights: ", e.message);
  }
}

export function* handleGetCustomerActivities(
  action: ActionType<typeof actions.getCustomerActivities>
) {
  const { query: qs, cb = (err?: any, data?: any) => null } = action.payload;

  const query = {
    ...(qs || {})
  };

  const res: Response = yield call(RestClient.send, {
    params: {
      id: action.payload.id
    },
    query,
    service: "get_customer_activities",
    timeout: 30000
  });

  if (!res) {
    throw new HttpRequestError("Fetch: Failed to get customer activities");
  }

  try {
    const { data = [] }: any = res || {};
    const activity = data
      .map((item: any) => {
        let message = "";
        let date = "";
        const dueDate = new Date(item.due_date);
        let duration = 0;
        switch (item.payment_status_id) {
          case 0:
            message = "Customer added";
            date = DateUtil.formatLocaleDate(item.charge_date, {
              weekday: "short",
              year: "numeric",
              month: "short",
              day: "numeric"
            });
            break;
          case 12:
            const today = new Date();
            duration = DateUtil.getDifferenceInDays(
              today.getTime(),
              dueDate.getTime()
            );
            if (duration < 0) {
              message = item.payment_description + " overdue";
            } else {
              message = item.payment_description + " requested";
            }
            date = DateUtil.formatLocaleDate(item.charge_date, {
              weekday: "short",
              year: "numeric",
              month: "short",
              day: "numeric"
            });
            break;
          case 9:
          case 10:
            message = item.payment_description + " refunded";
            date = DateUtil.formatLocaleDate(item.charge_date, {
              weekday: "short",
              year: "numeric",
              month: "short",
              day: "numeric"
            });
            break;
          case 3:
          case 4:
          case 16:
          case 17:
            const chargeDate = new Date(item.charge_date);
            duration = DateUtil.getDifferenceInDays(
              chargeDate.getTime(),
              dueDate.getTime()
            );
            if (duration < 0) {
              message =
                item.payment_description +
                " paid " +
                Math.abs(duration) +
                " days late";
            } else {
              message = item.payment_description + " paid on time";
            }
            date = DateUtil.formatLocaleDate(item.charge_date, {
              weekday: "short",
              year: "numeric",
              month: "short",
              day: "numeric"
            });
            break;
          default:
            return null;
        }
        return {
          date,
          message
        };
      })
      .filter((item: any) => item !== null);
    cb(null, activity);
  } catch (e) {
    window.Logger.error("handleGetCustomerActivities: ", e.message);
  }
}

// export function* handleSendPaymentRequest(
//   action: ActionType<typeof actions.sendPaymentRequest>
// ) {
//   // const state: RootState = yield select();
//
//   const {} = action.payload;
//
//   const body = {
//     purpose: "collection",
//     requests: [
//       {
//         payees: [
//           {
//             account_number: "100000000",
//             bank_bsb_id: 0,
//             bank_code: null,
//             bank_id: 1,
//             bsb_code: null,
//             comments: "1",
//             currency_id: 1,
//             due_date: "2021-06-08T07:43:42.357Z",
//             gross_amount: 100000,
//             id: 1571,
//             payment_description: "1",
//             recipient_name: "DART GLOBAL FORWARDING PTE. LTD.",
//             incentives: [
//               {
//                 discount_rate: 150,
//                 incentive_type_id: "I1"
//               }
//             ]
//           }
//         ],
//         payer_id: 597,
//         requester_rates: [
//           {
//             brand_id: 2,
//             national: 0,
//             international: 0,
//             fee_payer: 3,
//             int_fee_payer: 3
//           },
//           {
//             brand_id: 5,
//             national: 0,
//             international: 0,
//             fee_payer: 3,
//             int_fee_payer: 3
//           }
//         ],
//         to_create_or_upload_invoice: "upload"
//       }
//     ]
//   };
// }

export function* handleGetInvoiceRecentActivities(
  action: ActionType<typeof actions.getInvoiceRecentActivities>
) {
  const { query: qs, cb = (err?: any, data?: any) => null } = action.payload;

  const { offset = 0, pageCount = 10 } = qs;

  yield put(fetchActions.setPayFetchRecentActivities({}));

  const params: any = {
    service: "get_dashboard_recent_activities",
    timeout: 30000,
    query: {
      offset,
      page_count: pageCount,
      fetch_recent_activities: "y"
    }
  };

  const res: Response = yield call(RestClient.send, params);

  if (!res) {
    throw new HttpRequestError(
      "Fetch: Failed to get invoice recent activities"
    );
  }

  try {
    const data = _get(res, "data.fetch_recent_activities", []);
    const total = _get(res, "data.total_fetch_recent_activities", 0);

    const payloadData = {
      total,
      data: (data || []).map((item: any) => ({
        firstName: _get(item, "customer_first_name"),
        lastName: _get(item, "customer_last_name"),
        invoiceNumber: _get(item, "invoice_number"),
        invoiceId: _get(item, "invoice_id"),
        date: _get(item, "date"),
        activity: _get(item, "activity"),
        type: _get(item, "type"),
        isDone: _get(item, "is_done")
      }))
    };

    yield put(
      fetchActions.setFirstTimeFetchData({
        fetch_activities: true
      })
    );
    yield put(fetchActions.setPayFetchRecentActivities(payloadData));
    cb(null, payloadData);
  } catch (e) {
    window.Logger.error("handleGetInvoiceRecentActivities: ", e.message);
  }
}

export function* handleGetPaymentRecentActivities(
  action: ActionType<typeof actions.getPaymentRecentActivities>
) {
  const { query: qs, cb = (err?: any, data?: any) => null } = action.payload;

  const { offset = 0, pageCount = 10 } = qs;

  yield put(fetchActions.setPayFetchRecentActivities({}));

  const params: any = {
    service: "get_dashboard_recent_activities",
    timeout: 30000,
    query: {
      offset,
      page_count: pageCount,
      pay_recent_activities: "y"
    }
  };

  const res: Response = yield call(RestClient.send, params);

  if (!res) {
    throw new HttpRequestError(
      "Fetch: Failed to get invoice recent activities"
    );
  }

  try {
    const data = _get(res, "data.pay_recent_activities", []);
    const total = _get(res, "data.total_pay_recent_activities", 0);

    const payloadData = {
      total,
      data: (data || []).map((item: any) => ({
        recipientName: _get(item, "recipient_name"),
        firstName: _get(item, "customer_first_name"),
        lastName: _get(item, "customer_last_name"),
        receiptNumber: _get(item, "receipt_number"),
        invoiceId: _get(item, "invoice_id"),
        date: _get(item, "date"),
        activity: _get(item, "activity"),
        type: _get(item, "type"),
        isDone: _get(item, "is_done"),
        scheduleId: _get(item, "schedule_id"),
        totalPayment: _get(item, "total_payment")
      }))
    };

    yield put(
      fetchActions.setFirstTimeFetchData({
        pay_activities: true
      })
    );
    yield put(fetchActions.setPayFetchRecentActivities(payloadData));
    cb(null, payloadData);
  } catch (e) {
    window.Logger.error("handleGetPaymentRecentActivities: ", e.message);
    // cb(e);
  }
}

export function* handleCheckRemindDraftInvoice(
  action: ActionType<typeof actions.checkRemindDraftInvoice>
) {
  const { cb = (err?: any) => null } = action.payload;
  const state: RootState = yield select();
  yield put(actions.fetchMainCollectedAccount());
  if (selectors.isOpenDraftPayment(state)) {
    history.push(ROUTES.INVOICES_DRAFT);
    cb(null, true);
  } else {
    // history.push(`${ROUTES.CREATE_INVOICES_STEP1}?add_customer=y`);
    cb(null, false);
  }
}

export function* handleCheckKYC(action: ActionType<typeof actions.checkKYC>) {
  const state: RootState = yield select();
  const hasCollectedAccount = selectors.hasCollectedAccount(state);
  const hasFetchedCollectedAccount = selectors.getIsFetchedMainCollectedAccount(
    state
  );
  const isSingaporeAccount = selectors.isSingaporeAccount(state);
  const isBusinessAccount = selectors.getIsBusinessAccount(state);
  if (isSingaporeAccount && isBusinessAccount) {
    const collectedAccount = selectors.getCollectedAccount(state);
    const isCollectedAccount = !_isEmpty(collectedAccount);
    const isDraftCollectedAccount =
      isCollectedAccount && collectedAccount && collectedAccount.draft;
    const hasBizfile =
      isCollectedAccount &&
      !_isEmpty(
        _get(collectedAccount, "payeeData.kycDocuments.b_acra_bizfile", [])
      );
    const hasMyinfoBiz =
      isCollectedAccount &&
      !_isEmpty(_get(collectedAccount, "payeeData.myinfoBiz", null));
    if (!isCollectedAccount) {
      history.push(
        `${ROUTES.MY_INFO}?is_biz=${
          isBusinessAccount ? "y" : "n"
        }&redirect_url=${encodeURIComponent(
          `${ROUTES.KYC}?redirect_url=${encodeURIComponent(
            `${ROUTES.CREATE_INVOICES}?draft=y`
          )}`
        )}`
      );
      return;
    } else if (isDraftCollectedAccount) {
      if (hasBizfile || hasMyinfoBiz) {
        history.push(
          `${ROUTES.KYC}?redirect_url=${encodeURIComponent(
            `${ROUTES.CREATE_INVOICES}?draft=y`
          )}`
        );
        return;
      } else {
        history.push(
          `${ROUTES.MY_INFO}?is_biz=${
            isBusinessAccount ? "y" : "n"
          }&redirect_url=${encodeURIComponent(
            `${ROUTES.KYC}?redirect_url=${encodeURIComponent(
              `${ROUTES.CREATE_INVOICES}?draft=y`
            )}`
          )}`
        );
      }
      return;
    }
    history.push(`${ROUTES.CREATE_INVOICES}?draft=y`);
    return;
  } else {
    // Other countries
    const requiredCollectedState = requireCollectedAccountState({
      hasCollectedAccount,
      hasFetchedCollectedAccount
    });

    if (requiredCollectedState === "OPEN") {
      if (action.payload.cb) {
        action.payload.cb(null, null);
        return;
      }
      history.push(`${ROUTES.CREATE_INVOICES}?draft=y`);
      return;
    }

    if (requiredCollectedState === "REDIRECT_TO_KYC") {
      history.push(
        `${ROUTES.KYC}?redirect_url=${encodeURIComponent(
          `${ROUTES.CREATE_INVOICES}?draft=y`
        )}`
      );
    }
  }
}

export function* handleGetExportPayments(
  action: ActionType<typeof actions.getExportPayments>
) {
  const { query } = action.payload;

  const defaultStatusIds = DEFAULT_PAYMENT_STATUS_IDS.toString();

  const {
    from: chargeDateLower,
    to: chargeDateUpper
  } = DateUtil.getFromAndToByDateQueryKey(query.charge_date_key, {
    from: query.charge_date_from,
    to: query.charge_date_to
  });

  const {
    from: payoutDateLower,
    to: payoutDateUpper
  } = DateUtil.getFromAndToByDateQueryKey(query.payout_date_key, {
    from: query.payout_date_from,
    to: query.payout_date_to
  });

  const qs = {
    offset: 0,
    page_count: 1000,
    template: "normal",
    purposes: query.purposes || "1,2,3,5,7,8",
    statuses: query.statuses || defaultStatusIds,
    search_keyword: query.search_keyword || null,
    ...(query.charge_date_key
      ? {
          charge_date_lower: chargeDateLower,
          charge_date_upper: chargeDateUpper
        }
      : {}),
    ...(query.payout_date_key
      ? {
          payout_date_lower: payoutDateLower,
          payout_date_upper: payoutDateUpper
        }
      : {}),
    ...(query.frequency ? { frequency: query.frequency } : {})
  };

  RestClient.download({
    dataOnly: true,
    fileName: "",
    method: "GET",
    service: "get_payments_history_export",
    query: qs
  })
    .then(blob => {
      const blobUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.download = "ipaymy_payments.csv";
      link.href = blobUrl;
      link.click();
    })
    .catch(err => {
      window.Logger.error("handleExportFileError: ", err.message);
    });
}
