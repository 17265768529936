import React from "react";

import Form from "antd/es/form";
const { Item } = Form;

const CusTomForm = ({ children, ...props }: any) => {
  const onKeyUp = (event: any) =>
    event.keyCode === 13 ? props.onFinish && props.onFinish() : null;

  return (
    <Form {...props} onKeyUp={onKeyUp} tabIndex={0}>
      {children}
    </Form>
  );
};

CusTomForm.Item = Item;

export default CusTomForm;
