// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Personal_wrapper__1tlaE{height:100%}.Personal_wrapper__1tlaE .Personal_content__2hHYb{max-width:450px;text-align:left;height:100%;width:100%;margin:auto;padding-bottom:2rem}", "",{"version":3,"sources":["webpack://./src/bepaid/pages/Profile/components/PersonalModal/Personal.module.scss"],"names":[],"mappings":"AAAA,yBAAS,WAAW,CAAC,kDAAkB,eAAe,CAAC,eAAe,CAAC,WAAW,CAAC,UAAU,CAAC,WAAW,CAAC,mBAAmB","sourcesContent":[".wrapper{height:100%}.wrapper .content{max-width:450px;text-align:left;height:100%;width:100%;margin:auto;padding-bottom:2rem}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "Personal_wrapper__1tlaE",
	"content": "Personal_content__2hHYb"
};
export default ___CSS_LOADER_EXPORT___;
