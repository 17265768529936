import { connect } from "react-redux";
import _get from "lodash-es/get";

import { RootState } from "src/ipm-shared/store/model/reducers";
import * as actions from "src/bepaid/store/model/Fetch/actions";
import * as FormSelectors from "src/ipm-shared/components/Form/selectors";
import { INVOICE_DETAIL_FORM } from "src/ipm-shared/store/model/Customer/const";

import * as selectors from "src/bepaid/store/model/Fetch/selectors";

import View from "./View";

const mapStateToProps = (state: RootState) => ({
  collectedAccount: selectors.getCollectedAccount(state),
  currencyId: selectors.getAccountCurrencyId(state),
  countryCode: selectors.getAccountCountryCode(state),
  formState: FormSelectors.getControls(state, INVOICE_DETAIL_FORM),
  getControl: (name: string, form: string) =>
    selectors.getControl(state, name, form),
  paymentSetting: selectors.getSchedulePaymentSetting(state),
  selectedPaymentDetail: selectors.getSelectedPaymentDetail(state),
  chargeDate: selectors.getChargeDate(state),
  earliestDate: selectors.getEarliestDate(state),
  exclusionDates: selectors.getExclusionDates(state),
  pspNonWorkingDates: selectors.getPspNonWorkingDates(state),
  hasAtLeastOneCard: selectors.getCards(state).length > 0,
  selectedScheduleDetail: selectors.getScheduleDetail(state),
  extraInfo: selectors.getExtraInfo(
    state,
    actions.ModalID.EDIT_PAYMENT_DETAIL_MODAL
  ),
  upcomingPayment: selectors.getUpcomingPayment(state),
  currentCurrencySymbol: selectors.getCurrentCurrencySymbol(state),
  isPersonalAccount: selectors.getIsPersonalAccount(state),
  features: selectors.getFeatures(state),
  availableAcquirers: selectors.getAvailableAcquirers(state),
  isSingaporeAccount: selectors.isSingaporeAccount(state),
  isHongKongAccount: selectors.isHongKongAccount(state)
});

const mapDispatchToProps = {
  setControl: actions.setControl,
  toggleModal: actions.toggleModal,
  selectSchedule: actions.selectSchedule,
  fetchDateConditions: actions.fetchDateConditions
};

export default connect(mapStateToProps, mapDispatchToProps)(View);
