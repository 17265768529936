import { connect } from "react-redux";

import View from "./View";
import { RootState } from "src/ipm-shared/store/model/reducers";
import * as selectors from "./selectors";

const mapStateToProps = (state: RootState, props: any) => ({
  selectedPayee: selectors.getSelectedPayeeDetail(state),
  accountCountryCode: selectors.getAccountCountryCode(state),
  hasWallexPayment: selectors.getHasWallexPayment(state)
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(View);
