import { createAction } from "typesafe-actions";

import { LoadingFunc } from "src/ipm-shared/Utils/ReduxSagaEffects";

import { PayeeType } from "../Payee/reducers";

type cbType = (err?: any, data?: any) => void;

export const applyCoupon = createAction(
  "coupon/APPLY",
  resolve => (options?: { cb: cbType }, loadingFunc?: LoadingFunc) =>
    resolve({
      options,
      loadingFunc
    })
);

export const deleteCouponUsed = createAction(
  "coupon/DELETE_COUPON_USED",
  resolve => (paymentToken: string) => resolve({ paymentToken })
);

export const selectCard = createAction(
  "payment_request/SELECT_CARD",
  resolve => (
    id: number,
    paymentToken?: string,
    options?: { cb: cbType },
    loadingFunc?: LoadingFunc
  ) => resolve({ id, paymentToken, options, loadingFuncCus: loadingFunc })
);

export const fulfilPaymentSubmit = createAction(
  "payment_request/PAYMENT_SUBMIT",
  resolve => (cb?: cbType, loadingFunc?: LoadingFunc) =>
    resolve({ cb, loadingFunc })
);

export const approveClient3dsPayment = createAction(
  "payment_request/PAYMENT_3DS_APPROVED",
  resolve => (threedsToken: string) => resolve({ threedsToken })
);

export const addPaymentRequestSubmit = createAction(
  "payment_request/ADD",
  resolve => (
    purpose: string,
    isWallex?: boolean,
    options?: {
      cb: cbType;
    },
    loadingFunc?: LoadingFunc
  ) => {
    return resolve({ purpose, options, isWallex, loadingFunc });
  }
);

export const validatePaymentPayeeSubmit = createAction(
  "payment_request/VALIDATE_PAYMENT_PAYEE",
  resolve => (
    purpose: string,
    isWallex?: boolean,
    options?: {
      cb: cbType;
    },
    loadingFunc?: LoadingFunc
  ) => resolve({ purpose, isWallex, options, loadingFunc })
);

export const editPaymentRequestSubmit = createAction(
  "payment_request/EDIT",
  resolve => (purpose: string) => resolve({ purpose })
);

export const setPaymentRequest = createAction(
  "payment_request/SET_CURRENT",
  resolve => (args: {
    channelFees: number;
    currencyId: number;
    isScheduled?: boolean;
    isFlashPay?: boolean;
    isChargeToDay?: boolean;
    payees: Array<Partial<PayeeType>>;
    purposeId: number;
    statementDescriptor: string;
    subtotal: number;
    token: string;
    paidCard: number;
  }) => resolve(args)
);

export const setFees = createAction(
  "payment_request/SET_FEE",
  resolve => (args: {
    amountOff?: number;
    fee: number;
    savings: number;
    rate: number;
    rateBeforeCoupon: number;
    total: number;
    coupon: string;
    exchangeRate: number;
    paymentAmount: number;
    payeeQuoteToken?: string;
    flashPayFee?: number;
    isDeductedRate?: boolean;
    gstFee?: number;
    bankPayoutFee?: number;
    minimumTransactionFee?: number;
  }) => resolve(args)
);

export const setCurrentPurpose = createAction(
  "payment_request/SET_CURRENT_PURPOSE",
  resolve => (purpose: number) => resolve({ purpose })
);

export const fetchPaymentRequest = createAction(
  "payment_request/FETCH",
  resolve => (token: string) => resolve({ token })
);

export const fetchPaymentFee = createAction(
  "payment_request/FETCH_FEE",
  resolve => (
    paymentToken: string,
    hasCouponApplied: boolean = false,
    options?: { cb: cbType },
    loadingFunc?: LoadingFunc
  ) =>
    resolve({
      paymentToken,
      hasCouponApplied,
      options,
      loadingFuncCus: loadingFunc
    })
);

export const setCoupon = createAction(
  "payment_request/SET_COUPON",
  resolve => (coupon: string) => resolve({ coupon })
);

export const validateAmount = createAction(
  "payments/VALIDATE_AMOUNT",
  resolve => (args: {
    amount: number;
    fieldName: string;
    form?: string;
    bePaid?: boolean;
    isWallet?: boolean;
  }) => resolve(args)
);

export const getExchangeRate = createAction("payments/GET_EXCHANGE_RATE");

export const setExchangeRate = createAction(
  "payments/SET_EXCHANGE_RATE",
  resolve => (exchangeRate: number) => resolve({ exchangeRate })
);

export const setPaymentCountryCode = createAction(
  "payment_request/SET_PAYMENT_COUNTRY_CODE",
  resolve => (code: string) => resolve({ code })
);

export const setPaymentMethodType = createAction(
  "payment_request/SET_PAYMENT_METHOD_TYPE",
  resolve => (methodType: string) => resolve({ methodType })
);

export const selectPaymentMethod = createAction(
  "payment_request/SELECT_PAYMENT_METHOD",
  resolve => (
    id: number,
    paymentToken?: string,
    options?: { cb: cbType },
    loadingFunc?: LoadingFunc
  ) => resolve({ id, paymentToken, options, loadingFuncCus: loadingFunc })
);

export const setPaymentMethodId = createAction(
  "payment_request/SET_SELECTED_PAYMENT_METHOD",
  resolve => (args: { id: number }) => resolve(args)
);

export const setPaymentCurrencyCode = createAction(
  "payment_request/SET_PAYMENT_CURRENCY_CODE",
  resolve => (code: string) => resolve({ code })
);

export const validateIpmMerchantCategoryId = createAction(
  "payment_request/VALIDATE_IPM_MERCHANT_CATEGORY_ID",
  resolve => (
    ipmMerchantCategoryId?: string,
    cardBrandId?: number,
    cb?: cbType
  ) => resolve({ ipmMerchantCategoryId, cardBrandId, cb })
);
