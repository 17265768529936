/**
 * Selectors
 *
 * It's like "views" in relational databases.
 * Store the most efficient format in the reducer state.
 * But to retrieve into easy to consume form, use selector.
 */
import { RootState } from "src/ipm-shared/store/model/reducers";
import fbpixelReducer from "./reducers";
import ReducerFactory from "src/ipm-shared/Utils/ReduxReducer";

ReducerFactory.registerReducer({ fbpixel: fbpixelReducer });

// Import used selectors here
export const isPixelReady = (state: RootState) => {
  return state.fbpixel.pixelReady;
};

export * from "src/ipm-shared/store/model/AccountProfile/selectors";
