// import { RouteProps } from "react-router";
import { connect } from "react-redux";

import Component from "./View";
import { RESET_PASSWORD_FORM } from "src/ipm-platform/pages/ResetPassword/const";

import * as actions from "src/ipm-shared/store/model/Auth/actions";
import * as selectors from "src/ipm-shared/components/Form/selectors";

import * as hoc from "src/ipm-shared/HOCs";
import * as ReactRouter from "react-router";

const mapStateToProps = (state: any) => ({
  controls: selectors.getControls(state, RESET_PASSWORD_FORM)
});

const mapDispatchToProps = {
  setNewPasswordSubmit: actions.setNewPasswordSubmit
};

export type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  ReactRouter.RouteProps & { qs: { [param: string]: any } };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(hoc.withQueryString(Component));
