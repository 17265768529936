import React from "react";
import classNames from "classnames";
import Typography from "antd/es/typography";

import styles from "../RecipientDetail.module.scss";

import CountryUtil from "src/ipm-shared/Utils/Country";
import { useProcessPayeeDetail } from "../useProcessPaymentDetail";
import BankId from "src/ipm-shared/components/BankId";
import Image from "src/bepaid/components/Image";
import { FLAGS } from "src/ipm-shared/Utils/Country";

const ContentCollapsed = (props: any) => {
  const { selectedPayee, accountCountryCode, hasWallexPayment } = props;
  const {
    name,
    supplierName,
    supplierEmail,
    bankId,
    accountNumber,
    idNumber,
    bankCountryId,
    bankRawName
  } = useProcessPayeeDetail(selectedPayee);

  return (
    <>
      <div className={classNames(styles.row, styles.firstRow)}>
        <span>Supplier company name</span>
        {hasWallexPayment && (
          <div className={styles.flag}>
            <Image
              src={
                FLAGS[CountryUtil.getCountryCodeFromCountryId(bankCountryId)]
              }
            />
          </div>
        )}
      </div>
      <div className={styles.row}>
        <Typography.Text strong={true} ellipsis={true}>
          {name}
        </Typography.Text>
      </div>
      <hr className={styles.hr1px} />
      <div>
        {supplierName && (
          <div className={classNames(styles.row, styles.verticalRow)}>
            <span>Supplier contact name</span>
            <Typography.Text ellipsis={true}>{supplierName}</Typography.Text>
          </div>
        )}
        {supplierEmail && (
          <div className={classNames(styles.row, styles.verticalRow)}>
            <span>Supplier contact address</span>
            <Typography.Text ellipsis={true}>{supplierEmail}</Typography.Text>
          </div>
        )}
        <div className={classNames(styles.row, styles.verticalRow)}>
          <span>
            {CountryUtil.getCountryRegistrationCodeFromCountryCode(
              accountCountryCode
            )}
          </span>
          <Typography.Text ellipsis={true}>{idNumber}</Typography.Text>
        </div>
        <div className={classNames(styles.row, styles.verticalRow)}>
          <span>Bank</span>
          {bankId ? (
            <span>
              <BankId
                name={`${bankId}_bank`}
                valueShowOnly={true}
                defaultValue={bankId}
              />
            </span>
          ) : (
            <span>{bankRawName}</span>
          )}
        </div>
        <div className={classNames(styles.row, styles.verticalRow)}>
          <span>Account number</span>
          <Typography.Text ellipsis={true}>{accountNumber}</Typography.Text>
        </div>
      </div>
    </>
  );
};

export default ContentCollapsed;
