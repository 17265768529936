import { connect } from "react-redux";
import * as actions from "src/bepaid/store/model/Fetch/actions";

import View from "./View";
import { RootState } from "src/ipm-shared/store/model/reducers";

import { InvoicePayee } from "src/ipm-shared/store/model/Payee/reducers";
import * as selectors from "./selectors";
import { PayeeTypeEx } from "src/ipm-shared/store/model/Payee/selectors";

const mapStateToProps = (state: RootState, props: any) => ({
  extraPayees: selectors.getExtraPayees(state),
  selectedPayees: selectors.getSelectedPayees<InvoicePayee & PayeeTypeEx>(
    state
  ),
  selectedPayeesId: selectors.selectedPayee(state),
  accountCountryCode: selectors.getAccountCountryCode(state),
  accountCountryId: selectors.getAccountCountryId(state),
  accountCurrencyId: selectors.getAccountCurrencyId(state),
  selectedPayment: selectors.getBepaidSelectedPaymentDetail(state),
  isCryptoPaymentMethod: selectors.isCryptoPaymentMethod(state)
});

const mapDispatchToProps = {
  deleteExtraPayee: actions.deleteExtraPayee,
  removeControlPattern: actions.removeControlPattern,
  updateExtraPayee: actions.updateExtraPayee,
  updateExtraPayees: actions.updateExtraPayees,
  fetchPayees: actions.fetchPayees,
  selectPayees: actions.selectPayees,
  validatePaymentPayeeSubmit: actions.validatePaymentPayeeSubmit,
  addPaymentRequestSubmit: actions.addPaymentRequestSubmit,
  fetchDateConditions: actions.fetchDateConditions
};

export default connect(mapStateToProps, mapDispatchToProps)(View);
