/**
 * Actions
 *
 * ActionCreators are like the "pub" of pubsub. It's just broadcasting an event (action).
 * So, don't couple it with a specific reducer. Let ANY reducer listen to
 * an action.
 *
 * `typesafe-actions` has a nice utility function so we don't need to define action types as
 * constants. Use it together with `getType(type)` in the reducers.
 */
import { createAction } from "typesafe-actions";
import { LoadingFunc } from "../../../../ipm-shared/Utils/ReduxSagaEffects";

export * from "src/ipm-shared/store/model/actions";
export * from "src/ipm-shared/store/model/Auth/actions";
export * from "src/ipm-shared/components/Form/actions";

type cbType = (err?: any, data?: any) => void;

export const setLoading = createAction(
  "fetchPreStart/SET_LOADING",
  resolve => (isLoading: boolean) => resolve(isLoading)
);

export const register = createAction(
  "fetchPreStart/REGISTER",
  resolve => (args: {
    recaptchaToken: string;
    recaptchaVersion: string;
    cb?: (error: any, data: any) => void;
  }) => resolve(args)
);

export const reSendCode = createAction("fetchPreStart/RESEND_CODE");

export const verifyOtp = createAction(
  "fetchPreStart/VERIFY_OTP",
  resolve => (args: {
    smsOtp: string;
    cb?: cbType;
    loadingFunc?: LoadingFunc;
  }) => resolve(args)
);

export const resendVerifyOtp = createAction(
  "fetchPreStart/RE_SEND_VERIFY_OTP",
  resolve => (cb?: cbType, loadingFunc?: LoadingFunc) =>
    resolve({
      cb,
      loadingFunc
    })
);
