import { connect } from "react-redux";

import { RootState } from "src/ipm-shared/store/model/reducers";
import * as actions from "src/ipm-shared/components/Form/actions";

import * as selectors from "src/bepaid/store/model/Fetch/selectors";

import View from "./View";

const mapStateToProps = (state: RootState) => ({
  getControl: (name: string, form: string) =>
    selectors.getControl(state, name, form),
  getInvoiceSelected: selectors.getInvoiceSelected(state),
  getSelectedCustomers: selectors.getSelectedCustomers(state)
});

const mapDispatchToProps = {
  setControl: actions.setControl
};

export default connect(mapStateToProps, mapDispatchToProps)(View);
