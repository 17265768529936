import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import _isEmpty from "lodash-es/isEmpty";
import _get from "lodash-es/get";

import { RootState } from "src/ipm-shared/store/model/reducers";
import { history } from "src/ipm-shared/store";
import { PLATFORM } from "src/ipm-shared/components/GlobalUI/reducers";

import * as selectors from "src/bepaid/store/model/Fetch/selectors";
import * as actions from "src/bepaid/store/model/Fetch/actions";
import ROUTES from "src/bepaid/routes";
import Button from "src/bepaid/components/Form/controls/Button";
import Image from "src/bepaid/components/Image";

import ArrowDownPay from "src/bepaid/assets/images/common/drop_down_arrow_pay.svg";
import ArrowDownPayWhite from "src/bepaid/assets/images/common/drop_down_arrow_pay_white.svg";

// import PaymentUtil from "src/ipm-shared/Utils/Payment";

import styles from "./ButtonsHeading.module.scss";
import FeatureGate from "src/ipm-shared/components/FeatureGating/FeatureGate";
import FeatureGateUtil from "src/ipm-shared/components/FeatureGating/Util";
// import { WALLEX_KYC_STATUS } from "src/ipm-shared/store/model/OnBoarding/consts";

const PRIMARY_BUTTON_TEXT = "Make payment";

const PAY_BUTTONS = {
  secondary: {
    employees: {
      text: "Add employee",
      onClickType: "redirect_url",
      path: ROUTES.RECIPIENTS_EMPLOYEES_CREATE
    },
    landlords: {
      text: "Add landlord",
      onClickType: "redirect_url",
      path: ROUTES.RECIPIENTS_LANDLORDS_CREATE
    },
    suppliers: {
      text: "Add supplier",
      onClickType: "redirect_url",
      path: ROUTES.RECIPIENTS_SUPPLIERS_CREATE
    },
    bank_accounts: {
      text: "Add bank account",
      onClickType: "redirect_url",
      path: ROUTES.RECIPIENTS_BANK_ACCOUNTS_CREATE
    }
  },
  primary: {
    employees: {
      text: PRIMARY_BUTTON_TEXT,
      onClickType: "redirect_url",
      path: ROUTES.PAYMENTS_CREATION.replace(":paymentType", "domestic")
        .replace(":entity", "employee")
        .replace(":action", "select")
    },
    landlords: {
      text: PRIMARY_BUTTON_TEXT,
      onClickType: "redirect_url",
      path: ROUTES.PAYMENTS_CREATION.replace(":paymentType", "domestic")
        .replace(":entity", "landlord")
        .replace(":action", "select")
    },
    suppliers: {
      text: PRIMARY_BUTTON_TEXT,
      onClickType: "redirect_url",
      path: ROUTES.PAYMENTS_CREATION.replace(":paymentType", "domestic")
        .replace(":entity", "supplier")
        .replace(":action", "select")
    },
    bank_accounts: {
      text: PRIMARY_BUTTON_TEXT,
      onClickType: "redirect_url",
      path: ROUTES.PAYMENTS_CREATION.replace(":paymentType", "domestic")
        .replace(":entity", "bank_account")
        .replace(":action", "select")
    }
  },
  paymentType: {
    domestic: {
      redirectPath: ROUTES.PAYMENTS_CREATION.replace(":paymentType", "domestic")
        .replace(":entity", "entity")
        .replace(":action", "select")
    },
    international: {
      redirectPath: ROUTES.PAYMENTS_CREATION.replace(
        ":paymentType",
        "international"
      )
        .replace(":entity", "country")
        .replace(":action", "select")
    }
  }
};

const ButtonsHeading = (props: any) => {
  const { type, recipientPath, toggleModal } = props;

  const [dropdownType, setDropdownType] = useState<string | null>(null);
  // const isShowIntPayment = wallexKycStatus === WALLEX_KYC_STATUS.APPROVED;
  const isShowIntPayment = true;
  const isShowAddRecipientButton = recipientPath !== "bank_accounts";

  const addCustomer = () => {
    history.push(ROUTES.CUSTOMER_CREATION);
    // toggleModal(FetchModalID.ADD_CUSTOMER);
  };

  const openCreateInvoice = () => {
    props.checkKYC();
  };

  const goToRoute = (route: string) => () => {
    localStorage.setItem(
      "historyState",
      JSON.stringify({
        type,
        recipientPath,
        dropdownType,
        previousPath: window.location.pathname
      })
    );

    history.push(route);
  };

  const onClickSecondaryButton = () => {
    if (props.platform === PLATFORM.FETCH) {
      return;
    }

    if (props.platform === PLATFORM.PAY) {
      const { onClickType, path, modalId } =
        PAY_BUTTONS.secondary[recipientPath] || {};
      if (!onClickType) {
        setDropdownType("recipient");
        return;
      }

      if (onClickType === "redirect_url") {
        history.push(path);
      }

      if (onClickType === "toggle_modal") {
        toggleModal(modalId);
      }
    }
  };

  const invokePaymentSGFlow = () => {
    if (props.platform === PLATFORM.FETCH) {
      return;
    }
    if (props.platform === PLATFORM.PAY) {
      setDropdownType("payment");
    }
  };

  const invokePaymentFlow = () => {
    if (props.platform === PLATFORM.FETCH) {
      return;
    }

    if (props.platform === PLATFORM.PAY) {
      onChoosePaymentType("domestic");
    }
  };

  const onClickMakePayment = () => {
    if (
      props.isSingaporeAccount &&
      isShowIntPayment &&
      FeatureGateUtil.verifyFeature(props.features, "WALLEX_FLOW")
    ) {
      invokePaymentSGFlow();
    } else {
      invokePaymentFlow();
    }
  };
  const handleMyinfo = (
    path = "",
    redirectPath = "",
    paymentType = "",
    isBusiness = false
  ) => {
    props.fetchMyInfoSingPass(({ confirmed = false, info }: any) => {
      props.setConfirmedMyInfo(!!confirmed);
      if (!confirmed) {
        if (paymentType !== "domestic") {
          props.fetchMyInfoSingPass(({ confirmed = false, info }: any) => {
            props.setConfirmedMyInfo(!!confirmed);
            if (!confirmed) {
              history.push(
                `${ROUTES.MY_INFO}?is_biz=${
                  isBusiness ? "y" : "n"
                }&redirect_url=${encodeURIComponent(redirectPath)}`
              );
            } else {
              goToRoute(
                `${path && paymentType === "domestic" ? path : redirectPath}`
              )();
            }
          }, false);
        }
        history.push(
          `${ROUTES.MY_INFO}?is_biz=${
            isBusiness ? "y" : "n"
          }&redirect_url=${encodeURIComponent(redirectPath)}`
        );
      } else {
        goToRoute(
          `${path && paymentType === "domestic" ? path : redirectPath}`
        )();
      }
    }, isBusiness);
  };
  const onChoosePaymentType = (paymentType = "") => {
    let { redirectPath } = PAY_BUTTONS.paymentType[paymentType];
    const { path } = PAY_BUTTONS.primary[recipientPath] || {};
    if (props.isSingaporeAccount) {
      if (paymentType == "domestic") {
        redirectPath = ROUTES.PAYMENTS_CREATION.replace(
          ":paymentType",
          "domestic"
        )
          .replace(":entity", "method")
          .replace(":action", "select");
      }

      const { collectedAccount } = props;
      const isCollectedAccount = !_isEmpty(collectedAccount);
      const isDraftCollectedAccount =
        isCollectedAccount && collectedAccount.draft;
      const hasBizfile =
        isCollectedAccount &&
        !_isEmpty(
          _get(collectedAccount, "payeeData.kycDocuments.b_acra_bizfile", [])
        );
      const hasMyinfoBiz =
        isCollectedAccount &&
        !_isEmpty(_get(collectedAccount, "payeeData.myinfoBiz", null));
      if (!isCollectedAccount) {
        handleMyinfo(path, redirectPath, paymentType, !props.isPersonalAccount);
        return;
      } else if (isDraftCollectedAccount) {
        if (hasMyinfoBiz) {
          goToRoute(
            `${path && paymentType === "domestic" ? path : redirectPath}`
          )();
          return;
        } else if (hasBizfile && !hasMyinfoBiz) {
          handleMyinfo(path, redirectPath, paymentType, false);
          return;
        } else {
          handleMyinfo(
            path,
            redirectPath,
            paymentType,
            !props.isPersonalAccount
          );
          return;
        }
      }
      goToRoute(
        `${path && paymentType === "domestic" ? path : redirectPath}`
      )();
    } else {
      // if (!props.isMalaysiaAccount) {
      redirectPath = ROUTES.PAYMENTS_CREATION.replace(
        ":paymentType",
        "domestic"
      )
        .replace(":entity", "method")
        .replace(":action", "select");
      // }
      goToRoute(
        `${path && paymentType === "domestic" ? path : redirectPath}`
      )();
    }
  };

  useEffect(() => {
    const handleClick = (e: any) => {
      const condition1 = document
        .getElementById("recipient_dropdown")
        ?.contains(e.target);
      const condition2 = document
        .getElementById("recipient_button_add")
        ?.contains(e.target);
      const condition3 = document
        .getElementById("ipaymy_btn_make_payment")
        ?.contains(e.target);

      if (!(condition2 || condition3)) {
        if (!condition1) {
          setDropdownType(null);
        }
      }
    };

    window.addEventListener("click", handleClick);

    return () => {
      window.removeEventListener("click", handleClick);
    };
  }, [dropdownType]);

  // const renderGlobalPromotionContent = () => {
  //   if (props.isSingaporeAccount) {
  //     if (!wallexKycStatus) {
  //       return (
  //         <>
  //           <a
  //             href="mailto:support@ipaymy.com?subject=Interested%20in%20paying%20abroad"
  //             target="_blank"
  //           >
  //             <div className={styles.globeIcon} />
  //             Interested in paying abroad?
  //           </a>
  //         </>
  //       );
  //     } else if (PaymentUtil.isWallexKycInProgress(wallexKycStatus)) {
  //       return (
  //         <>
  //           <a className={styles.hideHover}>
  //             <div className={styles.globeIcon} />
  //             Reviewing your request to pay abroad...
  //           </a>
  //         </>
  //       );
  //     }
  //   }
  //   return <></>;
  // };

  return (
    <div className={styles.buttons}>
      {props.platform === PLATFORM.FETCH && (
        <>
          <div className={styles.buttonContainer}>
            <Button type="secondary" htmlType="button" onClick={addCustomer}>
              Add customer
            </Button>
          </div>
          <FeatureGate feature="NORMAL_PAYMENT">
            <div
              className={classNames(styles.buttonContainer, styles.primaryBtn)}
            >
              <Button
                id={"ipaymy_btn_create_invoice"}
                type="primary"
                htmlType="button"
                onClick={openCreateInvoice}
              >
                Create invoice
              </Button>
            </div>
          </FeatureGate>
        </>
      )}

      {props.platform === PLATFORM.PAY && (
        <>
          <div className={styles.buttonContainer}>
            {isShowAddRecipientButton && (
              <Button
                id={"recipient_button_add"}
                type="secondary"
                htmlType="button"
                className={styles.buttonAddRecipient}
                onClick={onClickSecondaryButton}
              >
                {type === "recipients" ? (
                  PAY_BUTTONS.secondary[recipientPath].text
                ) : (
                  <>
                    Add recipient <Image src={ArrowDownPay} />
                  </>
                )}
              </Button>
            )}
            {type !== "recipients" && dropdownType === "recipient" && (
              <div className={styles.dropdown} id={"recipient_dropdown"}>
                <button
                  className={styles.dropdownItem}
                  onClick={goToRoute(ROUTES.RECIPIENTS_LANDLORDS_CREATE)}
                >
                  Landlord
                </button>
                {!props.isPersonalAccount && (
                  <button
                    className={styles.dropdownItem}
                    onClick={goToRoute(ROUTES.RECIPIENTS_EMPLOYEES_CREATE)}
                  >
                    Employee
                  </button>
                )}
                <button
                  className={styles.dropdownItem}
                  onClick={goToRoute(ROUTES.RECIPIENTS_SUPPLIERS_CREATE)}
                >
                  Supplier
                </button>
              </div>
            )}
          </div>

          <FeatureGate feature="NORMAL_PAYMENT">
            <div
              className={classNames(styles.buttonContainer, styles.primaryBtn)}
            >
              {/* <div
              className={classNames(
                styles.globalPromotion,
                styles.desktopSmall
              )}
            >
              {renderGlobalPromotionContent()}
            </div> */}
              <Button
                id={"ipaymy_btn_make_payment"}
                type="primary"
                htmlType="button"
                className={styles.buttonAddRecipient}
                onClick={onClickMakePayment}
              >
                {props.isSingaporeAccount &&
                isShowIntPayment &&
                FeatureGateUtil.verifyFeature(props.features, "WALLEX_FLOW") ? (
                  <>
                    Make payment <Image src={ArrowDownPayWhite} />
                  </>
                ) : (
                  <>Make payment</>
                )}
              </Button>
              {/* <div
              className={classNames(styles.globalPromotion, styles.desktopBig)}
            >
              {renderGlobalPromotionContent()}
            </div> */}

              {dropdownType === "payment" && (
                <div className={styles.dropdown} id={"recipient_dropdown"}>
                  <button
                    id={"ipaymy_btn_make_payment_domestic"}
                    className={styles.dropdownItem}
                    onClick={onChoosePaymentType.bind(null, "domestic")}
                  >
                    Domestic
                  </button>
                  <button
                    id={"ipaymy_btn_make_payment_international"}
                    className={styles.dropdownItem}
                    onClick={onChoosePaymentType.bind(null, "international")}
                  >
                    International
                  </button>
                </div>
              )}
            </div>
          </FeatureGate>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  platform: selectors.getPlatform(state),
  isPersonalAccount: selectors.getIsPersonalAccount(state),
  isMalaysiaAccount: selectors.isMalaysiaAccount(state),
  isSingaporeAccount: selectors.isSingaporeAccount(state),
  wallexKycStatus: selectors.getWallexKycStatus(state),
  collectedAccount: selectors.getCollectedAccount(state),
  features: selectors.getFeatures()
});

const mapDispatchToProps = {
  toggleModal: actions.toggleModal,
  fetchMyInfoSingPass: actions.fetchMyInfoSingPass,
  setConfirmedMyInfo: actions.setConfirmedMyInfo,
  checkKYC: actions.checkKYC
};

export default connect(mapStateToProps, mapDispatchToProps)(ButtonsHeading);
