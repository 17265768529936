import React, { useState } from "react";
import _get from "lodash-es/get";
import classnames from "classnames";

import SharedSelect from "src/ipm-shared/components/Form/controls/Select";
import T from "src/ipm-shared/Utils/Intl";

import ActionDetailDrawer from "src/bepaid/components/ActionDetailDrawer";
import Image from "src/bepaid/components/Image";

import IconArrowDown from "src/bepaid/assets/images/common/arrow_down_fresh.svg";

import styles from "./SelectDrawer.module.scss";

const SelectDrawerViewComponent = (props: any) => {
  const {
    defaultValue,
    errorMessage,
    hasError,
    helperText,
    label,
    commonProps
  } = props;

  const [isOpenOptions, setOpenOptions] = useState(false);

  const actionsDrawer = commonProps.options.map((opt: any) => {
    return {
      key: String(opt.value),
      label: opt.label,
      // tslint:disable-next-line:object-literal-sort-keys
      func: (optData: any) => {
        commonProps.onChange(opt.value);
      }
    };
  });

  const selectedOption = commonProps.options.find(
    (opt: any) =>
      String(opt.value) === String(commonProps.control?.value || defaultValue)
  );

  return (
    <div className={styles.wrapper}>
      <p className={styles.label}>{label}</p>

      <b
        className={classnames(styles.selectButton, {
          [styles.selectButtonError]: hasError
        })}
        onClick={setOpenOptions.bind(null, true)}
      >
        <div className={styles.inputValue}>
          {selectedOption?.label || "Select an option"}
        </div>
        <Image src={IconArrowDown} />
      </b>

      {(helperText || hasError) && (
        <p className={hasError ? styles.helperTextHasError : styles.helperText}>
          {hasError ? T.transl(errorMessage) : helperText.txt}
        </p>
      )}

      <ActionDetailDrawer
        height={"auto"}
        visible={isOpenOptions}
        actions={actionsDrawer}
        onClose={setOpenOptions.bind(null, false)}
      />
    </div>
  );
};

const SelectDrawer = (props: any) => {
  const { name, options, label, defaultValue, helperText = null } = props;

  const renderComponent = (sharedProps: any) => {
    // INFO: props in custom component should be a higher priority
    const handleChange = (...params: any[]) => {
      if (sharedProps.onChange) {
        sharedProps.onChange(...params);
      }
      if (props.onChange) {
        props.onChange(...params);
      }
    };

    const commonProps = {
      ...sharedProps,
      ...props,
      onChange: handleChange
    };

    const { control = {} } = sharedProps;

    const { errors = [] } = control;
    const errorMessage = _get(errors, "0.code", "");
    const hasError = errors.length > 0;

    const componentProps = {
      commonProps,
      defaultValue,
      errorMessage,
      hasError,
      helperText,
      label
    };

    return <SelectDrawerViewComponent {...componentProps} />;
  };

  return (
    <SharedSelect
      {...props}
      name={name}
      options={options}
      renderComponent={renderComponent}
    />
  );
};

export default SelectDrawer;
