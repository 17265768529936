import React from "react";

import RecipientSupplierForm from "src/bepaid/shared/modals/RecipientAddEditPageModal/components/RecipientSupplierForm";
import RecipientEmployeeForm from "src/bepaid/shared/modals/RecipientAddEditPageModal/components/RecipientEmployeeForm";
import RecipientLandlordForm from "src/bepaid/shared/modals/RecipientAddEditPageModal/components/RecipientLandlordForm";
import RecipientBankAccountForm from "src/bepaid/shared/modals/RecipientAddEditPageModal/components/RecipientBankAccountForm";
import { useMakePaymentUrlParams } from "src/bepaid/pages/Payments/hooks";
import useQuery from "src/bepaid/hooks/useQuery";

const propsByEnity = {
  supplier: {
    defaultIntentMultiplePaymentsChecked: true
  },
  business_employee: {
    isBizSalary: true
  }
};

const View = (props: any) => {
  const { entity, step } = props;
  const { paymentType } = useMakePaymentUrlParams();
  const isWallex = paymentType === "international";
  const qs = useQuery();
  const initName = qs.name;

  const commonProps = {
    countryId: props.countryId,
    currencyId: props.currencyId,
    getAddressByPostalCode: props.getAddressByPostalCode,
    residentialAddress: props.residentialAddress,
    setControl: props.setControl,
    paymentCountryCode: props.paymentCountryCode,
    paymentCurrencyCode: props.paymentCurrencyCode,
    getControl: props.getControl,
    resetErrors: props.resetErrors,
    isBusinessAccount: props.isBusinessAccount,
    entity,
    initName,
    isWallex
  };

  const entityProps = propsByEnity[entity] || {};

  const getSupplierComponent = () => {
    if (paymentType === "domestic" || step === 1) {
      return RecipientSupplierForm;
    }

    if (step === 2) {
      return RecipientBankAccountForm;
    }

    return null;
  };

  const getLandlordComponent = () => {
    if (paymentType === "domestic" || step === 1) {
      return RecipientLandlordForm;
    }

    if (step === 2) {
      return RecipientBankAccountForm;
    }

    return null;
  };

  const FormComponent = {
    employee: RecipientEmployeeForm,
    supplier: getSupplierComponent(),
    landlord: getLandlordComponent(),
    self_transfer: RecipientBankAccountForm,
    business_employee: RecipientEmployeeForm
  }[entity];

  return (
    <div>
      <FormComponent
        accountCountryCode={props.accountCountryCode}
        isHongKongAccount={props.isHongKongAccount}
        {...entityProps}
        {...commonProps}
      />
    </div>
  );
};

export default View;
