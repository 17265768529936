/**
 * Actions
 *
 * ActionCreators are like the "pub" of pubsub. It's just broadcasting an event (action).
 * So, don't couple it with a specific reducer. Let ANY reducer listen to
 * an action.
 *
 * `typesafe-actions` has a nice utility function so we don't need to define action types as
 * constants. Use it together with `getType(type)` in the reducers.
 */
import { createAction } from "typesafe-actions";

import { LoadingFunc } from "src/ipm-shared/Utils/ReduxSagaEffects";

import { ExtraPayee, PayeeType, ExtraInfo } from "./reducers";
import { PURPOSE } from "./const";

export const bulkAddPayeeSubmit = createAction(
  "payees/BULK_ADD_PAYEE_SUBMIT",
  resolve => (
    purpose: PURPOSE,
    csvData: string[][],
    ids: string[],
    loadingFunc?: LoadingFunc
  ) => resolve({ purpose, csvData, ids, loadingFunc })
);
export const addSupplierSubmit = createAction(
  "payees/ADD_SUPPLIER_SUBMIT",
  resolve => (
    getAllPayees?: boolean,
    args?: {
      isWallex?: boolean;
      cb?: (err?: any) => void;
    },
    loadingFunc?: LoadingFunc
  ) => resolve({ getAllPayees, args, loadingFunc })
);
export const editSupplierSubmit = createAction(
  "payees/EDIT_SUPPLIER_SUBMIT",
  resolve => (
    getAllPayees?: boolean,
    args?: {
      cb?: (err?: any) => void;
    },
    loadingFunc?: LoadingFunc
  ) => resolve({ getAllPayees, args, loadingFunc })
);

export const addLandlordSubmit = createAction(
  "payees/ADD_LANDLORD_SUBMIT",
  resolve => (
    getAllPayees?: boolean,
    args?: {
      isWallex?: boolean;
      cb?: (err?: any) => void;
    },
    loadingFunc?: LoadingFunc
  ) => resolve({ getAllPayees, args, loadingFunc })
);
export const editLandlordSubmit = createAction(
  "payees/EDIT_LANDLORD_SUBMIT",
  resolve => (
    getAllPayees?: boolean,
    args?: {
      cb?: (err?: any) => void;
    },
    loadingFunc?: LoadingFunc
  ) => resolve({ getAllPayees, args, loadingFunc })
);
export const addEmployeeSubmit = createAction(
  "payees/ADD_EMPLOYEE_SUBMIT",
  resolve => (
    getAllPayees?: boolean,
    args?: {
      cb?: () => void;
    },
    loadingFunc?: LoadingFunc
  ) => resolve({ getAllPayees, args, loadingFunc })
);
export const editEmployeeSubmit = createAction(
  "payees/EDIT_EMPLOYEE_SUBMIT",
  resolve => (
    getAllPayees?: boolean,
    args?: {
      cb?: (err?: any) => void;
    },
    loadingFunc?: LoadingFunc
  ) => resolve({ getAllPayees, args, loadingFunc })
);
export const addBusinessEmployeeSubmit = createAction(
  "payees/ADD_BUSINESS_EMPLOYEE_SUBMIT",
  resolve => (
    getAllPayees?: boolean,
    args?: {
      cb?: () => void;
    },
    loadingFunc?: LoadingFunc
  ) => resolve({ getAllPayees, args, loadingFunc })
);
export const addSelfTransferSubmit = createAction(
  "payees/ADD_SELF_TRANSFER_SUBMIT",
  resolve => (
    isWallex?: boolean,
    args?: {
      cb?: (err?: any) => void;
    },
    loadingFunc?: LoadingFunc
  ) => resolve({ isWallex, args, loadingFunc })
);
export const editSelfTransferSubmit = createAction(
  "payees/EDIT_SELF_TRANSFER_SUBMIT",
  resolve => (
    getAllPayees?: boolean,
    args?: {
      isWallex?: boolean;
      cb?: (err?: any) => void;
    },
    loadingFunc?: LoadingFunc
  ) => resolve({ getAllPayees, args, loadingFunc })
);

// note to supplier
export const noteToSupplier = createAction(
  "payees/NOTE_TO_SUPPLIER_FORM",
  resolve => (purpose: PURPOSE) => resolve({ purpose })
);

export const fetchPayees = createAction(
  "payees/FETCH",
  resolve => (args: {
    type: PURPOSE;
    searchKeyword?: string;
    offset?: number;
    pageCount?: number;
    cb?: (payees: any[], payeesFormated?: any[]) => void;
    loadingFunc?: LoadingFunc;
    showGlobalLoader?: boolean;
    getAllPayees?: boolean;
    noRedirect?: boolean;
    isWallex?: boolean;
  }) => resolve(args)
);

export const deletePayee = createAction(
  "payees/DELETE",
  resolve => (
    id: number,
    purpose: PURPOSE,
    getAllPayees?: boolean,
    noRedirect?: boolean,
    successCb?: () => void,
    loadingFunc?: LoadingFunc
  ) =>
    resolve({ id, purpose, getAllPayees, loadingFunc, successCb, noRedirect })
);

export const hidePayee = createAction(
  "payees/HIDE",
  resolve => (
    id: number,
    purpose: PURPOSE,
    getAllPayees?: boolean,
    loadingFunc?: LoadingFunc
  ) => resolve({ id, purpose, getAllPayees, loadingFunc })
);

export const setPayees = createAction(
  "payees/SET",
  resolve => (args: {
    isFetching: boolean;
    purpose: PURPOSE;
    payees?: PayeeType[];
    total: number;
  }) => resolve(args)
);

export const selectPayees = createAction(
  "payees/SELECT",
  resolve => (args: {
    purpose: PURPOSE;
    ids: number[];
    removedIds: number[] | "all";
  }) => resolve(args)
);

export const selectPayee = createAction(
  "payees/SELECT_PAYEE_DETAIL",
  resolve => (id: number, loadingFunc?: any) => resolve({ id, loadingFunc })
);

export const setSelectedPayee = createAction(
  "payees/SET_SELECTED_PAYEE",
  resolve => (payee: PayeeType) => resolve({ payee })
);

export const resetSelectedPayees = createAction(
  "payees/RESET_SELECTED",
  resolve => (purpose: PURPOSE) => resolve({ purpose })
);

export const editPayee = createAction(
  "payees/SELECT_TO_EDIT",
  resolve => (id: number) => resolve(id)
);

export const updateExtraPayee = createAction(
  "payees/UPDATE_EXTRA_PAYEE",
  resolve => (payeeId: number, uid: string, payee: ExtraPayee) =>
    resolve({ payeeId, uid, payee })
);

export const updateExtraPayees = createAction(
  "payees/UPDATE_EXTRA_PAYEES",
  resolve => (payees: object) => resolve({ payees })
);

export const deleteExtraPayee = createAction(
  "payees/DELETE_EXTRA_PAYEE",
  resolve => (payeeId: number, uid: string) => resolve({ payeeId, uid })
);

export const deleteExtraPayeesByPayeeId = createAction(
  "payees/DELETE_EXTRA_PAYEES_BY_PAYEE_ID",
  resolve => (id: number) => resolve({ id })
);

export const resetExtraPayees = createAction("payees/RESET_EXTRA_PAYEES");

export const checkValidNoteToRecipient = createAction(
  "payees/CHECK_VALID",
  resolve => (
    value: string,
    defaultCommentsFieldName: string,
    isWallex: boolean | undefined,
    purpose: string | undefined,
    cb: (arg?: any) => void
  ) => resolve({ value, defaultCommentsFieldName, isWallex, purpose, cb })
);

export const checkPayee = createAction(
  "payees/CHECK_PAYEE",
  resolve => (args: {
    payeeId: number;
    purpose: PURPOSE;
    cb?: (extraInfo: ExtraInfo) => void;
  }) => resolve(args)
);

export const setExtraInfo = createAction(
  "payee/SET_EXTRA_INFO",
  resolve => (args: {
    payeeId: number;
    extraInfo: ExtraInfo;
    purpose: PURPOSE;
  }) => resolve(args)
);

export const updatePayeeCurrencyId = createAction(
  "payee/UPDATE_PAYEE_CURRENCY_ID",
  resolve => (purpose: PURPOSE, currencyId: number) =>
    resolve({ purpose, currencyId })
);

export const updateEditingPayeeCurrencyId = createAction(
  "payee/UPDATE_EDITING_PAYEE_CURRENCY_ID",
  resolve => (purpose: PURPOSE, currencyId: number | undefined) =>
    resolve({ purpose, currencyId })
);

export const fetchPayeeRegions = createAction(
  "payees/FETCH_PAYEE_REGIONS",
  resolve => (args: { value?: string; cb?: (err?: any, data?: any) => void }) =>
    resolve(args)
);

export const fetchInsurancePayees = createAction(
  "payees/FETCH_INSURANCE_PAYEES",
  resolve => (args: {
    cb?: (payees: any[]) => void;
    loadingFunc?: LoadingFunc;
  }) => resolve(args)
);
