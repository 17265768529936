import DateUtils from "src/ipm-shared/Utils/Date";
import _get from "lodash-es/get";

export const extractDateFromQueryParam = (query: any, key: string) =>
  new Date(Number(_get(query, key, Date.now() / 1000)) * 1000);

export const formatDate = (date: Date) =>
  DateUtils.formatLocaleDate(date.toISOString(), {
    day: "numeric",
    month: "short",
    year: "numeric"
  });
