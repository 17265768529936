/**
 * Actions
 *
 * ActionCreators are like the "pub" of pubsub. It's just broadcasting an event (action).
 * So, don't couple it with a specific reducer. Let ANY reducer listen to
 * an action.
 *
 * `typesafe-actions` has a nice utility function so we don't need to define action types as
 * constants. Use it together with `getType(type)` in the reducers.
 */
import { createAction } from "typesafe-actions";

import { LoadingFunc } from "src/ipm-shared/Utils/ReduxSagaEffects";

import {
  CollectedAccountState,
  CollectionRates,
  InvoiceLineItemType,
  ItemType,
  MainCollectedAccountData,
  TaxType
} from "./reducers";
import { OtherSettingsCollectedAccount } from "./reducers";
import { COLLECTED_ACCOUNT_STATUS } from "./const";

type cbType = (err?: any, data?: any) => void;

export const createCollectedAccount = createAction(
  "collectedAccount/CREATE",
  resolve => (
    draft?: boolean,
    step?: number,
    cb?: cbType,
    loadingFunc?: LoadingFunc,
    isFirstLogin?: boolean
  ) => resolve({ draft, step, cb, loadingFunc, isFirstLogin })
);

export const updateCollectedAccount = createAction(
  "collectedAccount/UPDATE",
  resolve => (
    args: { feeSettings: CollectionRates[]; form?: string },
    cb?: cbType,
    loadingFunc?: LoadingFunc
  ) => resolve({ args, cb, loadingFunc })
);

export const updateAdditionalInvoiceSetting = createAction(
  "collectedAccount/UPDATE_ADDITIONAL_INVOICE_SETTING",
  resolve => (form?: string, cb?: cbType, loadingFunc?: LoadingFunc) =>
    resolve({ form, cb, loadingFunc })
);

export const updateDraftInvoiceSetting = createAction(
  "collectionAccount/UPDATE_DRAFT_INVOICE_SETTING",
  resolve => (form?: string, cb?: cbType, loadingFunc?: LoadingFunc) =>
    resolve({ form, cb, loadingFunc })
);
export const fetchNextInvoiceNumber = createAction(
  "collectedAccount/FETCH_NEXT_INVOICE_NUMBER"
);

export const setupInvoiceNumber = createAction(
  "collectedAccount/SET_UP_INVOICE_NUMBER",
  resolve => (cb?: cbType, loadingFunc?: any) => resolve({ cb, loadingFunc })
);

export const fetchTaxes = createAction(
  "collectedAccount/FETCH_TAXES",
  resolve => (uid?: string, forceRefresh?: boolean) =>
    resolve({ uid, forceRefresh })
);

export const setTaxes = createAction(
  "collectedAccount/SET_TAXES",
  resolve => (args: { isFetching: boolean; taxes: TaxType[] }) => resolve(args)
);

export const addNewTax = createAction(
  "collectedAccount/ADD_NEW_TAX",
  resolve => (
    args: {
      uid: string;
      fromModalID?: number;
      cb?: (id: number) => void;
    },
    loadingFunc?: any
  ) => resolve(args, loadingFunc)
);

export const editTax = createAction(
  "collectedAccount/EDIT_NEW_TAX",
  resolve => (
    args: {
      id: number;
      uid: string;
      fromModalID?: number;
      cb?: (id: number) => void;
    },
    loadingFunc?: any
  ) => resolve(args, loadingFunc)
);

export const fetchItems = createAction(
  "collectedAccount/FETCH_ITEMS",
  resolve => (uid?: string, forceRefresh?: boolean) =>
    resolve({ uid, forceRefresh })
);

export const setItems = createAction(
  "collectedAccount/SET_ITEMS",
  resolve => (args: { isFetching: boolean; items: ItemType[] }) => resolve(args)
);

export const addNewItem = createAction(
  "collectedAccount/ADD_NEW_ITEM",
  resolve => (uid: string, cb?: (id: number) => void, loadingFunc?: any) => {
    return resolve({ uid, cb, loadingFunc });
  }
);

export const editItem = createAction(
  "collectedAccount/EDIT_ITEM",
  resolve => (
    uid: string,
    itemId: number,
    cb?: (id: number) => void,
    loadingFunc?: any
  ) => resolve({ uid, itemId, cb, loadingFunc })
);

export const updateInvoiceLineItem = createAction(
  "collectedAccount/UPDATE_INVOICE_LINE_ITEM",
  resolve => (args: { uid: string; item: InvoiceLineItemType }) => resolve(args)
);

export const deleteInvoiceLineItem = createAction(
  "collectedAccount/DELETE_INVOICE_LINE_ITEM",
  resolve => (uid: string) => resolve({ uid })
);

export const selectItemId = createAction(
  "collectedAccount/SELECT_ITEM_ID",
  resolve => (args: { name: string; id: number }) => resolve(args)
);

export const deleteSelectItemId = createAction(
  "collectedAccount/DELETE_SELECTED_ITEM_ID",
  resolve => (name: string) => resolve({ name })
);

export const selectAllItemIds = createAction(
  "collectedAccount/SELECT_ALL_SELECTED_ITEM",
  resolve => (data: any) => resolve({ data })
);

export const selectTaxId = createAction(
  "collectedAccount/SELECT_TAX_ID",
  resolve => (args: { name: string; id: number }) => resolve(args)
);

export const deleteSelectedTaxId = createAction(
  "collectedAccount/DELETE_SELECTED_TAX_ID",
  resolve => (name: string) => resolve({ name })
);

export const setCollectedAccounts = createAction(
  "collectedAccount/SET_COLLECTED_ACCOUNTS",
  resolve => (
    collectedAccounts: CollectedAccountState["list"],
    total: number
  ) => resolve({ collectedAccounts, total })
);

export const fetchAdminCollectedAccounts = createAction(
  "collectedAccount/FETCH_ADMIN_COLLECTED_ACCOUNTS",
  resolve => (
    status: typeof COLLECTED_ACCOUNT_STATUS[keyof typeof COLLECTED_ACCOUNT_STATUS],
    offset?: number,
    pageCount?: number
  ) => resolve({ status, offset, pageCount })
);

export const updateCollectedAccountStatus = createAction(
  "collectedAccount/UPDATE_COLLECTED_ACCOUNT_STATUS",
  resolve => (
    id: number,
    prevStatus: typeof COLLECTED_ACCOUNT_STATUS[keyof typeof COLLECTED_ACCOUNT_STATUS],
    status: typeof COLLECTED_ACCOUNT_STATUS[keyof typeof COLLECTED_ACCOUNT_STATUS]
  ) => resolve({ id, prevStatus, status })
);

export const updateCollectedAccountDocument = createAction(
  "collectedAccount/UPDATE_COLLECTED_ACCOUNT_DOCUMENT"
);

export const displayLoading = createAction(
  "collectedAccount/DISPLAY_LOADING",
  resolve => (value: boolean) => resolve({ value })
);

export const fetchMainCollectedAccount = createAction(
  "collectedAccount/FETCH_MAIN",
  resolve => (cb?: cbType) => resolve({ cb })
);

export const setMainCollectedAccount = createAction(
  "collectedAccount/SET_MAIN_COLLECTED_ACCOUNT",
  resolve => (ca: {
    data?: MainCollectedAccountData;
    hasActiveProgram: boolean;
    isFetched: boolean;
    rates: CollectionRates[];
    hasDraftPayment: boolean;
    accountUserRoleId: number;
    cryptoRate?: number;
  }) => resolve(ca)
);

export const updateOtherSettingsCollectedAccount = createAction(
  "collectedAccount/UPDATE_OTHER_SETTINGS_COLLECTED_ACCOUNT",
  resolve => (args: OtherSettingsCollectedAccount) => resolve(args)
);

export const updateNextNumberInvoiceCollectedAccount = createAction(
  "collectedAccount/UPDATE_NEXT_NUMBER_INVOICE_COLLECTED_ACCOUNT",
  resolve => (nextNumber: string) => resolve({ nextNumber })
);

export const setHasActiveProgram = createAction(
  "collectedAccount/SET_HAS_ACTIVE_PROGRAM",
  resolve => (args: {
    hasActiveProgram: boolean;
    requesterInternationalRate?: number;
    requesterNationalRate?: number;
  }) => resolve(args)
);

export const checkHasActiveProgram = createAction(
  "collectedAccount/HAS_ACTIVE_PROGRAM",
  resolve => (cb?: cbType) => resolve({ cb })
);
