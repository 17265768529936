// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RecentActivities_header__MlByI{font-size:1.25rem;font-weight:700;color:#242424}.RecentActivities_item__2VgMp{width:100%;display:-ms-flexbox;display:flex;-ms-flex-direction:column-reverse;flex-direction:column-reverse;-ms-flex-pack:justify;justify-content:space-between;font-size:1rem;padding:.5rem 0}.RecentActivities_item__2VgMp .RecentActivities_info__seDvt{color:#000;line-height:1.2}.RecentActivities_item__2VgMp .RecentActivities_date__AvHvb{min-width:-webkit-fit-content;min-width:-moz-fit-content;min-width:fit-content;color:#74747b}.RecentActivities_item__2VgMp .RecentActivities_link__Y7N5w{font-weight:500}.RecentActivities_item__2VgMp .RecentActivities_cancelled__24e3Z{color:#dc3545}.RecentActivities_emptyData__1W_ox{text-align:left;color:#000;font-size:1rem;padding:1.5rem 0}", "",{"version":3,"sources":["webpack://./src/bepaid/pages/Dashboard/components/RecentActivities/RecentActivities.module.scss"],"names":[],"mappings":"AAAA,gCAAQ,iBAAiB,CAAC,eAAe,CAAC,aAAa,CAAC,8BAAM,UAAU,CAAC,mBAAY,CAAZ,YAAY,CAAC,iCAA6B,CAA7B,6BAA6B,CAAC,qBAA6B,CAA7B,6BAA6B,CAAC,cAAc,CAAC,eAAe,CAAC,4DAAY,UAAU,CAAC,eAAe,CAAC,4DAAY,6BAAqB,CAArB,0BAAqB,CAArB,qBAAqB,CAAC,aAAa,CAAC,4DAAY,eAAe,CAAC,iEAAiB,aAAa,CAAC,mCAAW,eAAe,CAAC,UAAU,CAAC,cAAc,CAAC,gBAAgB","sourcesContent":[".header{font-size:1.25rem;font-weight:700;color:#242424}.item{width:100%;display:flex;flex-direction:column-reverse;justify-content:space-between;font-size:1rem;padding:.5rem 0}.item .info{color:#000;line-height:1.2}.item .date{min-width:fit-content;color:#74747b}.item .link{font-weight:500}.item .cancelled{color:#dc3545}.emptyData{text-align:left;color:#000;font-size:1rem;padding:1.5rem 0}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "RecentActivities_header__MlByI",
	"item": "RecentActivities_item__2VgMp",
	"info": "RecentActivities_info__seDvt",
	"date": "RecentActivities_date__AvHvb",
	"link": "RecentActivities_link__Y7N5w",
	"cancelled": "RecentActivities_cancelled__24e3Z",
	"emptyData": "RecentActivities_emptyData__1W_ox"
};
export default ___CSS_LOADER_EXPORT___;
