import * as React from "react";
import ImageUtils from "src/ipm-shared/Utils/Images";
import { IProps } from "./index";
import { ADD_FORM } from "src/ipm-shared/store/model/Card/const";

interface IState {
  flipped: boolean;
}

class CardLogoView extends React.Component<IProps, IState> {
  public render() {
    const { cardBrand } = this.props;

    if (cardBrand > -1) {
      return (
        <img
          className="card-icon"
          src={ImageUtils.getCardTypeIcon(cardBrand)}
          alt="Card Icon"
        />
      );
    }

    return null;
  }

  public componentWillUnmount() {
    this.props.setControl({
      form: ADD_FORM,
      name: "brand",
      value: -1
    });
  }
}

export default CardLogoView;
