import Typography from "antd/es/typography";
import React from "react";

import Row from "antd/es/row";
import Col from "antd/es/col";

import Card from "src/bepaid/components/Card";
import Image from "src/bepaid/components/Image";

import styles from "./CardDetails.module.scss";
import CountryUtil from "src/ipm-shared/Utils/Country";
import { FLAGS } from "src/ipm-shared/Utils/Country";

const CardDetails = ({
  icon,
  title,
  description,
  onClick,
  bankCountryId
}: any) => {
  return (
    <Card className={styles.cardDetails}>
      <Row align="middle" justify="start" wrap={false}>
        <Col span={4} onClick={onClick} className={styles.imgBox}>
          <Image src={icon} />
        </Col>
        <Col span={14} className={styles.titleData} onClick={onClick}>
          <Row align="middle" justify="start">
            <Col className={styles.titleWrapper}>
              <Typography.Text className={styles.title}>
                {title}
              </Typography.Text>
            </Col>
            <Col>
              <Typography.Text className={styles.description}>
                {description}
              </Typography.Text>
            </Col>
          </Row>
        </Col>
        {bankCountryId && (
          <Col span={6} onClick={onClick} className={styles.flag}>
            <Row align="middle" justify="end">
              <Image
                src={
                  FLAGS[CountryUtil.getCountryCodeFromCountryId(bankCountryId)]
                }
              />
            </Row>
          </Col>
        )}
      </Row>
    </Card>
  );
};

export default CardDetails;
