import * as React from "react";
import CardUtils from "src/ipm-shared/Utils/Card";
import {
  CardCvcElement,
  Elements,
  ElementsConsumer
} from "@stripe/react-stripe-js";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import T from "src/ipm-shared/Utils/Intl";

type IProps = {
  acquirerId: number;
};
type IState = {
  stripePromise: Promise<Stripe | null>;
};
class StripeCardCVVForm extends React.Component<IProps, IState> {
  // @ts-ignore
  private elements: stripeJS.StripeElements;

  public constructor(props: IProps) {
    super(props);

    this.state = {
      stripePromise: loadStripe(
        CardUtils.getStripePublicKey(this.props.acquirerId)
      )
    };
  }
  public render() {
    const stripeKey = CardUtils.getStripePublicKey(this.props.acquirerId);

    if (stripeKey !== "") {
      return (
        <div className={"form-content"}>
          <div className="form-group cvc-form form-cvc-cvv">
            <span className="label">{T.transl("CARD_CVC_LABEL")}</span>
            <Elements stripe={this.state.stripePromise}>
              <ElementsConsumer>
                {({ elements, stripe }) => {
                  this.elements = elements;
                  return (
                    <CardCvcElement className="form-control stripe-card-cvv-form" />
                  );
                }}
              </ElementsConsumer>
            </Elements>
          </div>
        </div>
      );
    }

    return <div className={"text-center"}>Invalid request</div>;
  }

  public getCardCVVElement() {
    return this.elements.getElement(CardCvcElement);
  }
}

export default StripeCardCVVForm;
