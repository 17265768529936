/* tslint:disable:object-literal-sort-keys jsx-no-lambda */
import React, { useMemo, useState } from "react";
import { Redirect } from "react-router-dom";
import * as copy from "copy-to-clipboard";
import * as DateFns from "date-fns";
import { BrowserView, MobileView } from "react-device-detect";
import _get from "lodash-es/get";

import { history } from "src/ipm-shared/store";
import { FetchModalID } from "src/ipm-shared/components/GlobalUI/actions";
import {
  PAYMENT_STATUS,
  PAYMENT_GROUP_STATUS
} from "src/ipm-shared/store/model/Payment/const";

import ROUTES from "src/bepaid/routes";
import { invokeMessage } from "src/bepaid/components/Message";
import MainLayout from "src/bepaid/layouts/MainLayout";
import Image from "src/bepaid/components/Image";
import ActionDetailDrawer from "src/bepaid/components/ActionDetailDrawer";

import IconMenuDot from "src/bepaid/assets/images/common/icon_menu_dot.svg";

import styles from "./InvoiceDetail.module.scss";
import InvoiceDetailContent from "./InvoiceDetailContent";
import DesktopModalLayout from "src/bepaid/components/Modal/components/DesktopModalLayout";

const showActions = true;

const Invoices: React.FC<any> = props => {
  const { hasToken } = props;

  const [openActions, setOpenActions] = useState(false);

  // Loading state
  const [loading, setLoading] = useState(false);

  /**
   * Open Modal state
   */

  const handleToggleMoreAction = () => {
    setOpenActions(!openActions);
  };

  const isHideRefund = useMemo(() => {
    if (!props.invoiceDetail) {
      return true;
    }

    const diff = DateFns.differenceInHours(
      props.invoiceDetail.scheduledChargeDate,
      Date.now()
    );

    if (
      ![
        PAYMENT_STATUS.PAID,
        PAYMENT_STATUS.UNDER_REVIEW,
        PAYMENT_STATUS.IN_PROGRESS_2,
        PAYMENT_STATUS.COMPLETED
      ].includes(props.invoiceDetail.paymentStatusId)
    ) {
      return true;
    }

    return !(diff >= 0 && diff <= 24);
  }, [props.invoiceDetail]);

  const isHideMarkAsPaid = useMemo(() => {
    if (!props.invoiceDetail) {
      return true;
    }

    return ![PAYMENT_STATUS.REQUEST, PAYMENT_STATUS.OVERDUE].includes(
      props.invoiceDetail.paymentStatusId
    );
  }, [props.invoiceDetail]);

  const isHideResendPayment = useMemo(() => {
    const phoneNumber = _get(props.invoiceDetail, "payer.mobile_number", "");
    if (!props.invoiceDetail || !phoneNumber) {
      return true;
    }

    return !PAYMENT_GROUP_STATUS.sent.includes(
      props.invoiceDetail.paymentStatusId
    );
  }, [props.invoiceDetail]);

  const isHideCancelPayment = useMemo(() => {
    if (!props.invoiceDetail) {
      return true;
    }

    return ![PAYMENT_STATUS.REQUEST, PAYMENT_STATUS.OVERDUE].includes(
      props.invoiceDetail.paymentStatusId
    );
  }, [props.invoiceDetail]);

  const actions = [
    {
      key: "copy_payment_link",
      label: "Copy payment link",
      func: (data: any) => {
        handleCopyPaymentLink();
      }
    },
    ...(!isHideResendPayment
      ? [
          {
            key: "remind_customer",
            label: "Remind customer",
            func: (data: any) => {
              handleRemindCustomer();
            }
          }
        ]
      : []),
    ...(!isHideCancelPayment
      ? [
          {
            key: "cancel_request",
            label: "Cancel request",
            func: (data: any) => {
              props.toggleModal(
                FetchModalID.CANCEL_PAYMENT_REQUEST,
                props.invoiceDetail
              );
            }
          }
        ]
      : []),
    ...(!isHideMarkAsPaid
      ? [
          {
            key: "mark_as_paid",
            label: "Mark as paid",
            func: (data: any) => {
              props.toggleModal(
                FetchModalID.MARK_PAYMENT_AS_PAID,
                props.invoiceDetail
              );
            }
          }
        ]
      : []),
    ...(!isHideRefund
      ? [
          {
            key: "refund_customer",
            label: "Refund payment",
            func: (data: any) => {
              props.toggleModal(
                FetchModalID.REFUND_PAYMENT,
                props.invoiceDetail
              );
            }
          }
        ]
      : [])
  ];

  const handleCopyPaymentLink = () => {
    copy("props.invoiceDetail.payUrl");

    if (props.invoiceDetail.payUrl) {
      copy(props.invoiceDetail.payUrl);
      invokeMessage("success", "Payment link copied.");
    } else {
      invokeMessage("error", "Payment link not found.");
    }
  };

  const handleRemindCustomer = () => {
    props.remindPaymentRequest(props.invoiceDetail.id, (err?: any) => {
      if (err) {
        invokeMessage("success", "Payment reminder failed.");
      } else {
        invokeMessage("success", "Payment reminder sent.");
      }
    });
  };

  const fetchInvoiceDetail = () => {
    const done = (i => () => {
      i += 1;
      if (i === 1) {
        setLoading(false); // ok
      }
    })(0);

    if (props.match?.params?.id) {
      setLoading(true); // ok

      props.getInvoiceDetail(props.match.params.id, (err: any, data: any) => {
        done();
      });
    }
  };

  React.useEffect(() => {
    fetchInvoiceDetail();
  }, []);

  return !hasToken ? (
    <Redirect to={ROUTES.LOGIN_REDIRECT} />
  ) : (
    <>
      <BrowserView>
        <DesktopModalLayout
          title={"Invoice details"}
          maxWidth450={true}
          onClose={() => history.push(ROUTES.INVOICES)}
          onMoreAction={handleToggleMoreAction}
        >
          <div className={styles.wrapper}>
            <InvoiceDetailContent
              loading={loading}
              invoiceDetail={props.invoiceDetail}
            />
          </div>
        </DesktopModalLayout>
      </BrowserView>
      <MobileView>
        <MainLayout
          header={{
            title: "Invoice details",
            hideMenu: true,
            onClose: () => history.push(ROUTES.INVOICES),
            rightIcons: showActions && (
              <Image src={IconMenuDot} onClick={handleToggleMoreAction} />
            )
          }}
        >
          <div className={styles.wrapper}>
            <InvoiceDetailContent
              loading={loading}
              invoiceDetail={props.invoiceDetail}
            />
          </div>
        </MainLayout>
      </MobileView>

      <ActionDetailDrawer
        contentFullWidth={true}
        type={"invoice"}
        height={"auto"}
        visible={openActions}
        data={props.invoiceDetail}
        actions={actions}
        onClose={handleToggleMoreAction}
      />
    </>
  );
};

export default Invoices;
