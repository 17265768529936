import React from "react";
import { LANGUAGE_OPTIONS } from "../Utils/Language";

// All contexts will provide here

export const LanguageContext = React.createContext({
  changeLanguage: (lang: string) => {
    console.log("User change language", lang);
  },
  currentLanguageValue: {
    label: "English",
    value: "en"
  },
  languageOptions: Object.values(LANGUAGE_OPTIONS),
  locale: "en"
});
