// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CardSelector_selectLabel__ilKe8{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;text-align:left}.CardSelector_selectLabel__ilKe8>span{margin-bottom:.5rem;font-size:.875rem;color:#242424}.CardSelector_option__1q1Hq{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:justify;justify-content:space-between;-ms-flex-align:center;align-items:center}.CardSelector_option__1q1Hq span{font-size:1rem;color:#242424}.CardSelector_option__1q1Hq img{height:15px;width:25px;margin-right:.5rem}.CardSelector_errorText__23oJF{font-size:.75rem;color:#c40698;margin-top:4px}", "",{"version":3,"sources":["webpack://./src/bepaid/components/CardSelector/CardSelector.module.scss"],"names":[],"mappings":"AAAA,iCAAa,mBAAY,CAAZ,YAAY,CAAC,yBAAqB,CAArB,qBAAqB,CAAC,eAAe,CAAC,sCAAkB,mBAAmB,CAAC,iBAAiB,CAAC,aAAa,CAAC,4BAAQ,mBAAY,CAAZ,YAAY,CAAC,sBAAkB,CAAlB,kBAAkB,CAAC,qBAA6B,CAA7B,6BAA6B,CAAC,qBAAiB,CAAjB,kBAAkB,CAAC,iCAAa,cAAc,CAAC,aAAa,CAAC,gCAAY,WAAW,CAAC,UAAU,CAAC,kBAAkB,CAAC,+BAAW,gBAAgB,CAAC,aAAa,CAAC,cAAc","sourcesContent":[".selectLabel{display:flex;flex-direction:column;text-align:left}.selectLabel>span{margin-bottom:.5rem;font-size:.875rem;color:#242424}.option{display:flex;flex-direction:row;justify-content:space-between;align-items:center}.option span{font-size:1rem;color:#242424}.option img{height:15px;width:25px;margin-right:.5rem}.errorText{font-size:.75rem;color:#c40698;margin-top:4px}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectLabel": "CardSelector_selectLabel__ilKe8",
	"option": "CardSelector_option__1q1Hq",
	"errorText": "CardSelector_errorText__23oJF"
};
export default ___CSS_LOADER_EXPORT___;
