import React from "react";

import { history } from "src/ipm-shared/store";
import useQuery from "src/bepaid/hooks/useQuery";
import {
  BrowserView,
  MobileView,
  isDesktop,
  isMobile
} from "react-device-detect";
import classNames from "classnames";
import { invokeMessage } from "src/bepaid/components/Message";
import ROUTES from "src/bepaid/routes";
import { FetchModalID } from "src/ipm-shared/components/GlobalUI/actions";
import Typography from "antd/es/typography";
import Form from "src/bepaid/components/Form";
import Image from "src/bepaid/components/Image";
import Button from "src/bepaid/components/Form/controls/Button";
import styles from "./PaymentDuplicateModal.module.scss";
import QuestionSingpassIcon from "src/bepaid/assets/images/verify/question_singpass_icon.svg";
import DesktopModalLayout from "src/bepaid/components/Modal/components/DesktopModalLayout";

const View = (props: any) => {
  const { extraInfo, submitEditPayments, toggleModal } = props;
  const { paymentId = null } = extraInfo;
  const qs = useQuery();
  const [loading, setLoading] = React.useState(false);

  const part1 = "You have a similar payment in the selected timeframe.";
  const part2 = (
    <Typography.Text className={styles.itemText}>
      Would you like to replace it with this payment?
    </Typography.Text>
  );

  const closeModal = () => {
    toggleModal(FetchModalID.PAYMENT_DUPLICATE_MODAL);
    const urlParams = new URLSearchParams(window.location.search);
    if (qs.is_schedule === "y") {
      const url = ROUTES.PAYMENT_SCHEDULE_DETAILS.replace(
        ":id",
        extraInfo.scheduleId
      );
      history.push({
        pathname: url,
        search: "?" + urlParams.toString()
      });
      return;
    }
    if (paymentId) {
      urlParams.set("idType", "id");
      const url = ROUTES.PAYMENT_DETAILS.replace(":id", paymentId);
      history.push({
        pathname: url,
        search: "?" + urlParams.toString()
      });
    } else {
      const url = ROUTES.PAYMENTS;
      history.push({
        pathname: url,
        search: "?" + urlParams.toString()
      });
    }
  };

  const handleCancelDuplicate = () => {
    if (!paymentId) {
      return;
    }

    setLoading(true);

    setTimeout(() => {
      submitEditPayments([Number(paymentId)], true, (err: any, data: any) => {
        closeModal();
        invokeMessage(
          err ? "error" : "success",
          err
            ? "Unable to replace payment. Please try it again!"
            : "Payment has been replaced."
        );
      });
    }, 2000);
  };

  const renderContent = () => {
    return (
      <Form
        name="payment-duplicate-form"
        layout="vertical"
        requiredMark={true}
        className={styles.form}
      >
        <div className={styles.formWrapper}>
          <div className={styles.title}>
            <div className={styles.itemSection}>
              <Image src={QuestionSingpassIcon} className={styles.image} />
            </div>
            <div className={styles.itemSection}>
              <Typography.Text className={styles.heroText}>
                {part1}
              </Typography.Text>
            </div>
          </div>
          <div className={styles.itemSection}>
            <Typography.Text className={styles.itemText}>
              {part2}
            </Typography.Text>
          </div>
        </div>
      </Form>
    );
  };

  const renderButton = () => (
    <div className={styles.button}>
      <Button
        type="primary"
        htmlType="submit"
        loading={loading}
        onClick={handleCancelDuplicate}
      >
        {loading ? "Replacing..." : "Replace it with this payment"}
      </Button>
    </div>
  );

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.wrapperDesktop]: isDesktop,
        [styles.wrapperMobile]: isMobile
      })}
    >
      <BrowserView>
        <DesktopModalLayout
          hasBorder={false}
          onClose={closeModal}
          btnSubmit={renderButton()}
        >
          {renderContent()}
        </DesktopModalLayout>
      </BrowserView>
      <MobileView>
        {renderContent()}
        {renderButton()}
      </MobileView>
    </div>
  );
};

export default View;
