import React, { useMemo } from "react";
import classNames from "classnames";
import Radio from "antd/es/radio";
import Space from "antd/es/space";

import T from "src/ipm-shared/Utils/Intl";

import FormStyles from "src/bepaid/components/Form/CommonForm.module.scss";
import Counter from "src/bepaid/components/Counter";
import _get from "lodash-es/get";

import styles from "./LatePayment.module.scss";

const View = (props: any) => {
  const { invoiceOverView, normalText } = styles as any;

  const commonFormItemProps = {
    reserveValueOnUnmount: true,
    revertValueOnMount: true
  };

  const onChangeInstallmentPlan = (e: any) => {
    ["P1", "P2"].forEach((id: string) => {
      if (id === e.target.value) {
        props.onSetIncentive(id, {
          open: true
        });
      } else {
        props.onSetIncentive(id, {
          open: false
        });
      }
    });
    props.onSetIncentive("D1", {
      open: false
    });
  };

  const onChangeLaterPaymentFee = (e: any) => {
    props.onSetIncentive(e.target.value, {
      open: true
    });
    props.onSetIncentive("P1", {
      open: false
    });
    props.onSetIncentive("P2", {
      open: false
    });
  };

  const onChangeLatePaymentCounter = (v: number) => {
    props.onSetIncentive("D1", {
      rate: v * 100
    });
  };

  const D1 = props.incentives.find((item: any) => item.id === "D1");
  const P1 = props.incentives.find((item: any) => item.id === "P1");
  const P2 = props.incentives.find((item: any) => item.id === "P2");

  const defaultValues = useMemo(
    () => ({
      D1,
      P1,
      P2
    }),
    []
  );

  return (
    <div className={classNames(FormStyles.content, styles.wrapper)}>
      <div className={FormStyles.personalFormDetail}>
        <div className={invoiceOverView}>
          <p className={classNames(normalText, styles.boldText)}>
            Installment plan
          </p>
          <hr />
        </div>
        <div>
          <Radio.Group
            onChange={onChangeInstallmentPlan}
            defaultValue={
              _get(defaultValues.P1, "open", false)
                ? "P1"
                : _get(defaultValues.P2, "open", false)
                ? "P2"
                : ""
            }
            value={
              _get(P1, "open", false)
                ? "P1"
                : _get(P2, "open", false)
                ? "P2"
                : ""
            }
          >
            <Space direction="vertical">
              <Radio value={"P1"}>
                <div className={styles.titles}>Half now, half later.</div>
                <div className={styles.content}>
                  {T.transl("INCENTIVES_HALF_DESCRIPTION")}
                </div>
              </Radio>
              <Radio value={"P2"}>
                <div className={styles.titles}>Pay in three installments.</div>
                <div className={styles.content}>
                  {T.transl("INCENTIVES_THREE_DESCRIPTION")}
                </div>
              </Radio>
            </Space>
          </Radio.Group>
          <p className={styles.headerText}>Late payment fee</p>
          <hr />
          <Radio.Group
            onChange={onChangeLaterPaymentFee}
            defaultValue={_get(defaultValues.D1, "open", false) ? "D1" : ""}
            value={_get(D1, "open", false) ? "D1" : ""}
          >
            <Radio value={"D1"}>
              <div className={styles.content}>
                {T.transl("INCENTIVES_LATE_DESCRIPTION")}
              </div>
            </Radio>
          </Radio.Group>

          {_get(D1, "open", true) && (
            <div className={styles.counter}>
              <Counter
                {...commonFormItemProps}
                defaultValue={_get(D1, "rate", 0) / 100}
                onChange={onChangeLatePaymentCounter}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default View;
