import * as React from "react";
import Loader from "react-loaders";
import * as RL from "react-loaders";

/**
 * If `loading` is true, will display a loader, otherwise, it will display the children.
 */
interface IProps {
  active: boolean;
  type?: RL.LoaderType;
  color?: string;
  className?: string;
}

const Component: React.SFC<IProps> = props => (
  <Loader
    type={props.type || "line-scale"}
    active={props.active}
    // @ts-ignore
    color={props.color}
    innerClassName={props.className}
  />
);

export default Component;
