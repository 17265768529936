import { getType } from "typesafe-actions";
import * as types from "./types";
import * as actions from "./actions";
import _isEmpty from "lodash-es/isEmpty";

export type BusinessType = {
  id: number;
  name: string;
};

export type BusinessIndustryType = {
  id: number;
  name: string;
  groupId: number;
  groupName: string;
};

export type BusinessOccupationType = {
  id: number;
  name: string;
  groupId: number;
  groupName: string;
};

export type BusinessState = {
  readonly isFetching: boolean;
  readonly businessTypes: BusinessType[];
  readonly businessIndustries: BusinessIndustryType[];
  readonly businessOccupations: BusinessOccupationType[];
};

const defaultState: BusinessState = {
  businessIndustries: [],
  businessTypes: [],
  businessOccupations: [],
  isFetching: false
};

export default (state: BusinessState, action: types.Action) => {
  if (_isEmpty(state)) {
    state = defaultState;
  }

  switch (action.type) {
    case getType(actions.setBusinessTypes):
      return {
        ...state,
        businessTypes: action.payload.businessTypes,
        isFetching: action.payload.isFetching
      };
    case getType(actions.setBusinessIndustries):
      return {
        ...state,
        businessIndustries: action.payload.businessIndustries,
        isFetching: action.payload.isFetching
      };
    case getType(actions.setBusinessOccupations):
      return {
        ...state,
        businessOccupations: action.payload.businessOccupations,
        isFetching: action.payload.isFetching
      };
  }
  return state;
};
