import React from "react";
// import { Route, Switch } from "react-router-dom";
// import withPopup from "src/bepaid/HOC/withPopup";
import ROUTES from "src/bepaid/routes";
import { stripTrailingSlash } from "src/bepaid/utils/stripTrailingSlash";

// import { FetchModalID } from "src/ipm-platform/layout/actions";

import Dashboard from "../Dashboard";

import Employees from "../Recipients/Employees";
import Landlords from "../Recipients/Landlords";
import Suppliers from "../Recipients/Suppliers";

import PaymentsList from "./components/PaymentsList";

const View = (props: any) => {
  const { type, recipientPath, previousPath } = JSON.parse(
    localStorage.getItem("historyState") || "{}"
  );

  if (stripTrailingSlash(previousPath) === ROUTES.PAYMENTS) {
    return <PaymentsList {...props} />;
  }

  if (type === "recipients") {
    if (recipientPath === "employees") {
      return <Employees {...props} />;
    }

    if (recipientPath === "landlords") {
      return <Landlords {...props} />;
    }

    if (recipientPath === "suppliers") {
      return <Suppliers {...props} />;
    }
  }

  return <Dashboard {...props} />;
};

export default View;
