import React from "react";

import classNames from "classnames";
import DateUtils from "src/ipm-shared/Utils/Date";

import CalendarIcon from "src/bepaid/assets/images/common/calendar.svg";

import Image from "../Image";

import styles from "./CalendarInput.module.scss";

const renderErrorMessage = (control: any) => {
  return (
    <>
      {control.displayError &&
        control.errors.map((error: any, idx: number) => (
          <span key={idx} className="invalid-feedback d-block">
            {error.message}
          </span>
        ))}
    </>
  );
};

const View = (props: any) => {
  const {
    icon,
    label = "Date",
    value = new Date(),
    onClick,
    btnClass,
    iconClass,
    labelClass,
    optionDisplay,
    placeHolder = "",
    control
  } = props;

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.hasError]: control?.errors?.length > 0 && control?.displayError
      })}
    >
      <p className={labelClass}>{label}</p>
      <button className={"btn-select-date"} onClick={onClick}>
        <div className={classNames(btnClass)}>
          <div className={classNames(styles.icon, iconClass)}>
            <Image src={icon || CalendarIcon} />
          </div>
          <span
            className={classNames({
              [styles.placeHolderText]: !!placeHolder
            })}
          >
            {placeHolder
              ? placeHolder
              : optionDisplay
              ? DateUtils.formatLocaleDate(value.toDateString(), optionDisplay)
              : value.toDateString()}
          </span>
        </div>
      </button>
      {renderErrorMessage(control)}
    </div>
  );
};

export default View;
