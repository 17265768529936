import React from "react";
import _get from "lodash-es/get";
import ShortCurrency from "src/ipm-shared/components/ShortCurrency";

import CurrencyUtil from "src/ipm-shared/Utils/Currency";
import PaymentUtil from "src/ipm-shared/Utils/Payment";

import styles from "./AddressList.module.scss";

const List = (props: any) => {
  const { isHongKongAccount, items, entity, getCheckoutFormControl } = props;

  const HKD = CurrencyUtil.convertFromCurrencyCode("HKD");
  const getCurrentAmount = (payee: any, uid?: any) => {
    const { currentAmount } = payee;
    if (
      entity === "supplier" ||
      entity === "tax" ||
      entity === "business_employee" ||
      entity === "insurance"
    ) {
      const control = getCheckoutFormControl(
        !uid
          ? `supplier_amount_${payee.id}`
          : `supplier_amount_${payee.id}_${uid}`
      );
      const value = +control?.value || 0;
      return Math.round(value * 100);
    }
    return currentAmount;
  };

  const getCurrencyId = (payee: any, uid?: string) => {
    if (isHongKongAccount) {
      return HKD.id;
    }
    return payee.currencyId;
  };

  return (
    <div>
      {items.map((item: any, idx: number) => {
        const { address, name, extraPayees } = item;

        return (
          <React.Fragment key={idx}>
            <div className={styles.listItem}>
              {entity === "landlord" && (
                <div>
                  Address: <b>{address}</b>{" "}
                </div>
              )}
              {entity === "employee" && (
                <div>
                  Employee: <b>{name}</b>{" "}
                </div>
              )}

              {entity === "supplier" && (
                <div>
                  Supplier: <b>{name}</b>{" "}
                </div>
              )}

              {entity === "tax" && (
                <div>
                  Recipient: <b>{name}</b>{" "}
                </div>
              )}

              {entity === "business_employee" && (
                <div>
                  Payor name: <b>{name}</b>{" "}
                </div>
              )}

              {entity === "insurance" && (
                <div>
                  Insurer: <b>{PaymentUtil.getInsurancePayeeName(name)}</b>{" "}
                </div>
              )}

              <div>
                <span>
                  {entity === "business_employee"
                    ? "Total salary amount: "
                    : "Amount: "}
                </span>
                <b>
                  <ShortCurrency
                    value={getCurrentAmount(item)}
                    currencyId={getCurrencyId(item)}
                  />
                </b>
              </div>
            </div>

            {Object.entries(extraPayees || []).map(([uid, payee]: any) => (
              <div key={uid} className={styles.listItem}>
                {entity === "landlord" && (
                  <div>
                    Address: <b>{payee.address}</b>{" "}
                  </div>
                )}
                {entity === "employee" && (
                  <div>
                    Employee: <b>{payee.name}</b>{" "}
                  </div>
                )}

                {entity === "supplier" && (
                  <div>
                    Supplier: <b>{payee.name}</b>{" "}
                  </div>
                )}

                {entity === "tax" && (
                  <div>
                    Recipient: <b>{payee.name}</b>{" "}
                  </div>
                )}

                {entity === "insurance" && (
                  <div>
                    Insurer:{" "}
                    <b>{PaymentUtil.getInsurancePayeeName(payee.name)}</b>{" "}
                  </div>
                )}

                <div>
                  <span>Amount: </span>
                  <b>
                    <ShortCurrency
                      value={getCurrentAmount(payee, uid)}
                      currencyId={getCurrencyId(item, uid)}
                    />
                  </b>
                </div>
              </div>
            ))}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default List;
