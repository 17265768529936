export const CHANGE_EMAIL_FORM = "CHANGE_EMAIL_FORM";
export const CHANGE_PASSWORD_FORM = "CHANGE_PASSWORD_FORM";

export const REGISTER_FORM = "REGISTER_FORM";
export const PERSONAL_REGISTER_FORM = "PERSONAL_REGISTER_FORM";

export const LOGIN_FORM = "login_form";
export const VERIFY_FORM = "verify_form";

export const RESET_PASSWORD_FORM = "RESET_PASSWORD_FORM";
