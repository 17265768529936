// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EditPaymentDetail_wrapper__2AbjU{padding:.5rem 0 1.5rem;text-align:left}.EditPaymentDetail_wrapper__2AbjU .EditPaymentDetail_hidden__m4kAm{display:none;height:0}.EditPaymentDetail_wrapper__2AbjU hr{height:2px;background-color:#7b7e80;border:none}.EditPaymentDetail_wrapper__2AbjU p,.EditPaymentDetail_wrapper__2AbjU span,.EditPaymentDetail_wrapper__2AbjU label{color:#000 !important}.EditPaymentDetail_wrapper__2AbjU .EditPaymentDetail_boldHr__36rBn{background-color:#000}.EditPaymentDetail_wrapper__2AbjU .EditPaymentDetail_spacingBlock__1AVQh{margin-bottom:1.5rem}.EditPaymentDetail_wrapper__2AbjU .EditPaymentDetail_spacingBlock__1AVQh div{color:#000 !important}.EditPaymentDetail_cardSelector__1MlN0{margin-bottom:1rem}.EditPaymentDetail_capitalizeFirstLetter__26JpT span::first-letter,.EditPaymentDetail_capitalizeFirstLetter__26JpT label::first-letter{text-transform:capitalize !important}", "",{"version":3,"sources":["webpack://./src/bepaid/shared/modals/PaymentDetailEditModal/components/EditPaymentDetail/EditPaymentDetail.module.scss"],"names":[],"mappings":"AAAA,kCAAS,sBAAsB,CAAC,eAAe,CAAC,mEAAiB,YAAY,CAAC,QAAQ,CAAC,qCAAY,UAAU,CAAC,wBAAwB,CAAC,WAAW,CAAC,mHAAwC,qBAAqB,CAAC,mEAAiB,qBAAqB,CAAC,yEAAuB,oBAAoB,CAAC,6EAA2B,qBAAqB,CAAC,uCAAc,kBAAkB,CAAC,uIAAqF,oCAAoC","sourcesContent":[".wrapper{padding:.5rem 0 1.5rem;text-align:left}.wrapper .hidden{display:none;height:0}.wrapper hr{height:2px;background-color:#7b7e80;border:none}.wrapper p,.wrapper span,.wrapper label{color:#000 !important}.wrapper .boldHr{background-color:#000}.wrapper .spacingBlock{margin-bottom:1.5rem}.wrapper .spacingBlock div{color:#000 !important}.cardSelector{margin-bottom:1rem}.capitalizeFirstLetter span::first-letter,.capitalizeFirstLetter label::first-letter{text-transform:capitalize !important}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "EditPaymentDetail_wrapper__2AbjU",
	"hidden": "EditPaymentDetail_hidden__m4kAm",
	"boldHr": "EditPaymentDetail_boldHr__36rBn",
	"spacingBlock": "EditPaymentDetail_spacingBlock__1AVQh",
	"cardSelector": "EditPaymentDetail_cardSelector__1MlN0",
	"capitalizeFirstLetter": "EditPaymentDetail_capitalizeFirstLetter__26JpT"
};
export default ___CSS_LOADER_EXPORT___;
