// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AutoComplete_wrapper___7XBd{margin-bottom:1.5rem}.AutoComplete_wrapper___7XBd .ant-select{width:100%}.AutoComplete_wrapper___7XBd .ant-select-selection-search input{padding-right:30px !important}.AutoComplete_wrapper___7XBd .AutoComplete_inputTextWrapper__1isJ5{position:relative}.AutoComplete_wrapper___7XBd .AutoComplete_inputTextWrapper__1isJ5 .AutoComplete_searchIcon__lduoh{position:absolute;top:13px;right:14px}.AutoComplete_wrapper___7XBd .AutoComplete_inputTextWrapper__1isJ5 .AutoComplete_loadingWrapper__1ZO4Q{width:100%;height:100%}", "",{"version":3,"sources":["webpack://./src/bepaid/components/AutoComplete/AutoComplete.module.scss"],"names":[],"mappings":"AAAA,6BAAS,oBAAoB,CAAC,yCAA6B,UAAU,CAAC,gEAAoD,6BAA6B,CAAC,mEAA2B,iBAAiB,CAAC,mGAAuC,iBAAiB,CAAC,QAAQ,CAAC,UAAU,CAAC,uGAA2C,UAAU,CAAC,WAAW","sourcesContent":[".wrapper{margin-bottom:1.5rem}.wrapper :global .ant-select{width:100%}.wrapper :global .ant-select-selection-search input{padding-right:30px !important}.wrapper .inputTextWrapper{position:relative}.wrapper .inputTextWrapper .searchIcon{position:absolute;top:13px;right:14px}.wrapper .inputTextWrapper .loadingWrapper{width:100%;height:100%}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "AutoComplete_wrapper___7XBd",
	"inputTextWrapper": "AutoComplete_inputTextWrapper__1isJ5",
	"searchIcon": "AutoComplete_searchIcon__lduoh",
	"loadingWrapper": "AutoComplete_loadingWrapper__1ZO4Q"
};
export default ___CSS_LOADER_EXPORT___;
