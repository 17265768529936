import React from "react";
import _get from "lodash-es/get";

import useQuery from "src/bepaid/hooks/useQuery";

import CreateInvoiceContents from "./components/CreateInvoiceContent";

import styles from "./CreateInvoiceModal.module.scss";
import FeatureGate from "src/ipm-shared/components/FeatureGating/FeatureGate";

const CreateInvoiceModal = (props: any) => {
  const qs = useQuery();
  const checkDraft = qs.draft === "y";
  return (
    <FeatureGate feature="NORMAL_PAYMENT">
      <div className={styles.wrapper}>
        <CreateInvoiceContents checkDraft={checkDraft} />
      </div>
    </FeatureGate>
  );
};

export default CreateInvoiceModal;
