import React from "react";
import _get from "lodash-es/get";
import classnames from "classnames";
import Typography from "antd/es/typography";

import styles from "../RecipientDetail.module.scss";

import { useProcessPayeeDetail } from "../useProcessPaymentDetail";
import ShortCurrency from "src/ipm-shared/components/ShortCurrency";

const ContentCollapsed = (props: any) => {
  const { selectedPayee } = props;
  const {
    name,
    purpose,
    purposeTitle,
    currencyId,
    receiptDefaultAmount
  } = useProcessPayeeDetail(selectedPayee);

  return (
    <>
      <div className={classnames(styles.row, styles.firstRow)}>
        <span>{purposeTitle}</span>
      </div>
      <div className={styles.row}>
        <Typography.Text strong={true} ellipsis={true}>
          {name}
        </Typography.Text>
      </div>
      <hr className={styles.hr1px} />
      <div className={styles.row}>
        <span>{purpose}</span>
        <span>
          <ShortCurrency value={receiptDefaultAmount} currencyId={currencyId} />
        </span>
      </div>
    </>
  );
};

export default ContentCollapsed;
