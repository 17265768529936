import * as React from "react";
import { connect } from "react-redux";
import * as selectors from "src/ipm-shared/store/model/Bank/selectors";
import { RootState } from "src/ipm-shared/store/model/reducers";
import { IBaseProps } from "../Form/controls/lib/Base";
import {
  getBanksIdOptions,
  mapSwiftCodeToId
} from "src/ipm-shared/store/model/Bank/selectors";
import store from "src/ipm-shared/store";
import _get from "lodash-es/get";
import { SWIFT_CODE_LABEL_LIST } from "./const";
import T from "src/ipm-shared/Utils/Intl";
import SelectSuggestion from "../Form/controls/SelectSuggestion";

const mapStateToProps = (state: RootState) => ({
  banksOptions: selectors.getBanksIdOptions(state)
});

type IProps = ReturnType<typeof mapStateToProps> &
  IBaseProps & {
    onChangeCustom?: (key: number) => void;
    defaultValue?: string | number;
    labelOnly?: boolean;
    labelHidden?: boolean;
    isAdmin?: boolean;
    defaultValueFormat?: "bank_swift_code" | "bank_swift_code|name";
    countryId?: string | number;
    isEditAble?: boolean;
    requiredConfirmation?: boolean;
    valueShowOnly?: boolean;
  };

class BankId extends React.Component<IProps> {
  public render() {
    let filterBanks;
    if (this.props.isAdmin) {
      filterBanks = this.props.banksOptions;
    } else {
      filterBanks = this.props.banksOptions.filter(bank => !bank.isDeleted);
    }

    if (this.props.countryId) {
      filterBanks = filterBanks.filter(bank => {
        if (typeof this.props.countryId === "number") {
          return bank.countryId === this.props.countryId;
        }

        return bank.countryId === parseInt(this.props.countryId as string, 10);
      });
    }

    const options = this.composeBankSwiftCode(filterBanks);
    const defaultOption = this.props.banksOptions.find(
      o => +o.value === +this.props.defaultValue
    );

    if (this.props.valueShowOnly) {
      return defaultOption ? defaultOption.label : "...";
    }

    return (
      <React.Fragment>
        {!this.props.labelHidden && (
          <span className={"label"} hidden={this.props.labelOnly === true}>
            {T.transl("BANK_LABEL")}
          </span>
        )}

        <SelectSuggestion
          className="bank-dropdown"
          defaultValue={
            typeof this.props.defaultValue === "number"
              ? this.props.defaultValue
              : this.getId(options, this.props.defaultValue)
          }
          options={options}
          onChangeCustom={this.props.onChangeCustom}
          labelOnly={this.props.labelOnly}
          placeholder={T.transl("BANK_PLACEHOLDER")}
          name={this.props.name}
          form={this.props.form}
          group={this.props.group}
          required={this.props.required}
          requiredMessage={this.props.requiredMessage}
          displayError={this.props.displayError}
          errorStyle={this.props.errorStyle}
          id={this.props.id}
          revertValueOnMount={this.props.revertValueOnMount}
          reserveValueOnUnmount={this.props.reserveValueOnUnmount}
        />
      </React.Fragment>
    );
  }

  private composeBankSwiftCode = (
    banks: ReturnType<typeof getBanksIdOptions>
  ) => {
    // Currently just HSBC bank only
    let banksList;

    banksList = banks
      .map(bank => ({
        label:
          bank.displayName !== null
            ? bank.displayName
            : bank.label +
              (SWIFT_CODE_LABEL_LIST.indexOf(bank.swiftCode) > -1
                ? ` - ${bank.swiftCode.replace("XXX", "")}`
                : ""),
        value: bank.value
      }))
      .sort((a, b) => (a.label < b.label ? -1 : 1));

    return banksList;
  };

  private getId = (
    options: Array<{ label: string; value: number }>,
    value: string
  ) => {
    if (this.props.defaultValueFormat) {
      if (this.props.defaultValueFormat.indexOf("bank_swift_code") > -1) {
        const { id, ok } = mapSwiftCodeToId(store.getState(), value);
        if (ok) {
          return id;
        }
      }

      if (this.props.defaultValueFormat.indexOf("name") > -1) {
        const { id, ok } = this.findIdFromLabel(options, value);
        if (ok) {
          return id;
        }
      }
    }

    return value;
  };

  private findIdFromLabel = (
    options: Array<{ label: string; value: number }>,
    value: string
  ) => {
    if (value) {
      value = value.toUpperCase().trim();
      const id = _get(
        options.filter(o => {
          return value.toUpperCase() === o.label.toUpperCase();
        }),
        "0.value",
        undefined
      );

      return {
        id: id ? id : value,
        ok: id !== undefined
      };
    }

    return {
      id: value,
      ok: false
    };
  };
}

export default connect(mapStateToProps)(BankId);
