import * as React from "react";
import { connect } from "react-redux";
import * as selectors from "../selectors";
import { RootState } from "src/ipm-shared/store/model/reducers";
import Is from "src/ipm-shared/Utils/Is";
import { Consumer } from "../LinkedInProvider";
import Cookies from "js-cookie";

const mapStateToProps = (state: RootState) => ({
  hasToken: selectors.hasToken(state),
  isBusinessAccount: selectors.getIsBusinessAccount(state)
  // pixelReady: selectors.isPixelReady(state)
});

type IProps = ReturnType<typeof mapStateToProps> & {
  assumeAccountType?: "personal" | "business";
  onlyForType?: "personal" | "business";
  title: string;
  allowGuest?: boolean;
  fireOnce?: boolean;
  email?: string;
};

class LinkedInTrack extends React.Component<IProps> {
  private fired: boolean;

  constructor(props: IProps) {
    super(props);

    if (props.fireOnce) {
      const ipmTrackCookieValue = Cookies.get(
        `${window.location.pathname}-${props.email}-linkedin-fired`
      );

      if (ipmTrackCookieValue) {
        if (ipmTrackCookieValue === "true") {
          this.fired = true;
        }
      }
    }
  }

  public setIPMTrackCookie() {
    Cookies.set(
      `${window.location.pathname}-${this.props.email}-linkedin-fired`,
      "true"
    );
  }

  public render() {
    return (
      <Consumer>
        {(partnerId: string) => {
          if (partnerId === "") {
            return null;
          }

          if (
            (!this.props.hasToken && !this.props.allowGuest) || // Pixel is not ready for authenticated user
            !Is.live() // Is not live
          ) {
            return null;
          }

          if (this.fired) {
            return null;
          }

          const url = this.getTrackCustomActionUrl(this.props.title, partnerId);
          if (url) {
            return (
              <img
                height="1"
                width="1"
                style={{ display: "none" }}
                alt=""
                src={url}
              />
            );
          }

          return null;
        }}
      </Consumer>
    );
  }

  private getTrackCustomActionUrl(
    event: string,
    partnerId: string
  ): null | string {
    if (this.props.onlyForType) {
      let accountType = this.props.assumeAccountType;
      if (!accountType) {
        if (this.props.isBusinessAccount) {
          accountType = "business";
        } else {
          accountType = "personal";
        }
      }
      if (this.props.onlyForType === accountType) {
        return this.fireEvent(event, partnerId);
      }
    } else {
      return this.fireEvent(event, partnerId);
    }

    return null;
  }

  private fireEvent(event: string, partnerId: string): null | string {
    let conversionId = "";
    switch (event) {
      case "Lead Personal":
        conversionId = "2713748";
        break;
      case "Lead Business":
        conversionId = "2713756";
        break;
      case "View":
        conversionId = "874115";
        break;
      case "Purchase":
        conversionId = "874123";
        break;
      case "CollectionPurchase":
        conversionId = "903419";
        break;
      default:
        return null;
    }

    if (conversionId === "") {
      return null;
    }

    this.setIPMTrackCookie();
    return `https://px.ads.linkedin.com/collect/?pid=${partnerId}&conversionId=${conversionId}&fmt=gif`;
  }
}

export default connect(mapStateToProps)(LinkedInTrack);
