import { getType } from "typesafe-actions";
import * as types from "./types";
import * as actions from "./actions";
import _isEmpty from "lodash-es/isEmpty";

export type AccountsManagerType = {
  userId: number;
  deletedAt?: string;
  firstName: string;
  lastName: string;
  isBusiness?: boolean;
  assignmentType?: number;
  countryId?: number;
  amRoleName?: string;
};

export type AccountsManagerState = {
  readonly isFetching: boolean;
  readonly accounts: AccountsManagerType[];
};

const defaultState: AccountsManagerState = {
  accounts: [],
  isFetching: false
};

export default (state: AccountsManagerState, action: types.Action) => {
  if (_isEmpty(state)) {
    state = defaultState;
  }

  switch (action.type) {
    case getType(actions.setAccountsManager):
      return {
        ...state,
        accounts: action.payload.accounts || state.accounts,
        isFetching: action.payload.isFetching
      };
  }
  return state;
};
