/**
 * Selectors
 *
 * It's like "views" in relational databases.
 * Store the most efficient format in the reducer state.
 * But to retrieve into easy to consume form, use selector.
 */
import { createSelector } from "reselect";
import { RootState } from "src/ipm-shared/store/model/reducers";
import { AccountProfile, defaultProfile } from "./reducers";
import _get from "lodash-es/get";
import {
  getAccountCountryCode,
  getCurrentCountry,
  getCurrentCurrency,
  getCurrentPaidCurrency,
  getCurrentCurrencyCode
} from "../Auth/selectors";
import { getCountryCodeFromCountryId } from "../Country/selectors";
import IPMContext from "src/ipm-shared/Utils/IPMContext";
export * from "src/ipm-shared/store/model/Auth/selectors";

// Accounts
export const getCurrentAccountProfile = createSelector(
  (state: RootState) => state.accounts.currentEmail,
  (state: RootState) => state.accounts.byId,
  (currentEmail, byId): AccountProfile => {
    if (!byId) {
      return defaultProfile;
    }
    const profile = byId[currentEmail];
    return {
      ...defaultProfile,
      ...profile
    };
  }
);

export const getSecondaryAccountProfiles = createSelector(
  (state: RootState) => state.accounts.currentEmail,
  (state: RootState) => state.accounts.byId,
  (currentEmail, byId) => {
    if (!byId) {
      return [];
    }
    const emails = Object.keys(byId);
    if (emails.length <= 1) {
      return []; // Means user only has 1 primary account, 0 secondary accounts.
    }
    return emails
      .filter(email => email !== currentEmail)
      .map(email => byId[email]);
  }
);

export const shouldDisplayLoadingProfile = (state: RootState) =>
  state.accounts.isFetching;

export const hasFetchedAccountProfile = (state: RootState) =>
  state.accounts.hasFetched;

export const isDisplayReferral = (state: RootState) => {
  const currentEmail = state.accounts.currentEmail;
  const getCurrentEmail = _get(state.accounts.byId, currentEmail, "");
  let referral;
  if (getCurrentEmail) {
    referral = getCurrentEmail.userSettingRequired;
  }
  return _get(referral, "referral", false);
};

export const isCrossBorder = (state: RootState): boolean => {
  return getAccountCountryCode(state) !== getCurrentCountry(state);
};

// @Deprecated
// Note: this feature has been deprecated. Previously we have ipaymy cross-border payment.
// So we allow SG user to send payment to MY and HK. Now we removed that. We use wallex for that kind of business
// export const hasCrossBorderPayment = (state: RootState): boolean =>
//   _get(getCurrentAccountProfile(state), "crossBorderCardFlag", false);

export const isDisplayCrossBorderNewIcon = (
  state: RootState
): boolean | undefined =>
  _get(getCurrentAccountProfile(state), "crossBorderNewIcon", false);

export const isDisplayEditScheduleIndicator = (state: RootState): boolean =>
  _get(
    getCurrentAccountProfile(state),
    "userSettingRequired.editScheduleIndicator",
    false
  );

export const getWallexKycStatus = (state: RootState): number =>
  _get(getCurrentAccountProfile(state), "wallexKycStatus", 0);

export const getMobileCountryCode = (state: RootState): string | undefined => {
  const ap = getCurrentAccountProfile(state);
  const mobileCountryId = ap.mobileCountryId;
  return getCountryCodeFromCountryId(state, mobileCountryId);
};

export const getMobileNumber = (state: RootState): string | undefined =>
  _get(getCurrentAccountProfile(state), "mobileNumber", undefined);

export const isCloseKycStatusBar = (state: RootState): boolean =>
  // By default, close = true to hide kyc status bar,
  // then after profile is fetched, it could be displayed back
  _get(
    getCurrentAccountProfile(state),
    "userSettingRequired.closeKycStatusBar",
    true
  );

export const isWallexPayment = (state: RootState): boolean => {
  if (!IPMContext.isPayFetchPlatform()) {
    return (
      getAccountCountryCode(state) === "SG" &&
      getCurrentCurrency(state) !== getCurrentPaidCurrency(state)
    );
  } else {
    return (
      getAccountCountryCode(state) === "SG" &&
      getCurrentCurrencyCode(state) !== getCurrentPaidCurrency(state)
    );
  }
};

export const hasCreditCard = (state: RootState): boolean | null =>
  _get(getCurrentAccountProfile(state), "hasCreditCard", false);

export const myCreditCard = (state: RootState): boolean =>
  _get(
    getCurrentAccountProfile(state),
    "userSettingRequired.myCreditCard",
    false
  );

export const isDisplayIrasPopup = (state: RootState): boolean =>
  _get(getCurrentAccountProfile(state), "userSettingRequired.irasPopup", false);

export const isHongKongAccount = (state: RootState): boolean =>
  getAccountCountryCode(state) === "HK";

export const isSingaporeAccount = (state: RootState): boolean =>
  getAccountCountryCode(state) === "SG";

export const isAustraliaAccount = (state: RootState): boolean =>
  getAccountCountryCode(state) === "AU";

export const isMalaysiaAccount = (state: RootState): boolean =>
  getAccountCountryCode(state) === "MY";

export const isNeedAddSettingInvoice = (state: RootState): boolean =>
  _get(
    getCurrentAccountProfile(state),
    "userSettingRequired.additionalInvoiceSetting",
    false
  );

export const isDisplaySGMyInfoPopup = (state: RootState): boolean =>
  _get(getCurrentAccountProfile(state), "userSettingRequired.sgMyInfo", false);

export const getHasWallexPayment = (state: RootState): number =>
  _get(getCurrentAccountProfile(state), "hasWallexPayment", false);

export const getHasIncorporationDate = (state: RootState): number =>
  _get(getCurrentAccountProfile(state), "hasIncorporationDate", false);

export const getHasIdVerification = (state: RootState): number =>
  _get(getCurrentAccountProfile(state), "hasIdVerification", false);

// BE PAID

export const getUserRequiredSetting = (
  state: RootState
): AccountProfile["userSettingRequired"] | undefined =>
  _get(getCurrentAccountProfile(state), "userSettingRequired", undefined);

export const isNewUser = (state: RootState): boolean => {
  const time = "2022-02-28 00:00:00.000000";
  const userCreatedAt = _get(
    getCurrentAccountProfile(state),
    "createdAt",
    null
  );

  // @ts-ignore
  return new Date(userCreatedAt).getTime() >= new Date(time).getTime();
};

export const getAvailableAcquirers = (state: RootState) => {
  const accountProfile = getCurrentAccountProfile(state);

  return _get(accountProfile, "availableAcquirers", []);
};

export const isDisplayFlashpayFeesPopup = (state: RootState): boolean =>
  _get(
    getCurrentAccountProfile(state),
    "userSettingRequired.flashpayPromoModal",
    false
  );
