// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CvvSurffix_popover__3jPc1{-webkit-transform:translate3d(0, 0, 200px) !important;transform:translate3d(0, 0, 200px) !important}.CvvSurffix_popover__3jPc1 .ant-popover-arrow span,.CvvSurffix_popover__3jPc1 .ant-popover-inner{background:#34405bcc}.CvvSurffix_popover__3jPc1 .ant-popover-arrow span .ant-popover-inner-content,.CvvSurffix_popover__3jPc1 .ant-popover-inner .ant-popover-inner-content{color:#fff}.CvvSurffix_container__2-bpy{cursor:pointer;position:absolute;top:12px;right:15px}", "",{"version":3,"sources":["webpack://./src/ipm-shared/components/CardForm/components/CvvSurffix/CvvSurffix.module.scss"],"names":[],"mappings":"AAAA,2BAAS,qDAA4C,CAA5C,6CAA6C,CAAC,iGAA6E,oBAAoB,CAAC,uJAAmI,UAAU,CAAC,6BAAW,cAAc,CAAC,iBAAiB,CAAC,QAAQ,CAAC,UAAU","sourcesContent":[".popover{transform:translate3d(0, 0, 200px) !important}.popover :global .ant-popover-arrow span,.popover :global .ant-popover-inner{background:#34405bcc}.popover :global .ant-popover-arrow span .ant-popover-inner-content,.popover :global .ant-popover-inner .ant-popover-inner-content{color:#fff}.container{cursor:pointer;position:absolute;top:12px;right:15px}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popover": "CvvSurffix_popover__3jPc1",
	"container": "CvvSurffix_container__2-bpy"
};
export default ___CSS_LOADER_EXPORT___;
