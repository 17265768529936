import React, { useEffect, useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";

import { history } from "src/ipm-shared/store";
import {
  FORM,
  INTERNATIONAL_FORM
} from "src/ipm-shared/store/model/Payee/const";
import _get from "lodash-es/get";

import ROUTES from "src/bepaid/routes";
import Button from "src/bepaid/components/Form/controls/Button";
import DesktopModalLayout from "src/bepaid/components/Modal/components/DesktopModalLayout";
import { invokeMessage } from "src/bepaid/components/Message";
import MainLayout from "src/bepaid/layouts/MainLayout";
import { getEntityByType, typeByEntity } from "src/bepaid/utils/recipient";

import RecipientLandlordForm from "./components/RecipientLandlordForm";
import RecipientEmployeeForm from "./components/RecipientEmployeeForm";
import RecipientSupplierForm from "./components/RecipientSupplierForm";
import RecipientBankAccountForm from "./components/RecipientBankAccountForm";

import styles from "./RecipientModal.module.scss";

const View = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [payee, setPayee] = useState(null);

  const purpose = getEntityByType(props.extraInfo.type);
  const payeeId = parseInt(props.extraInfo.payeeId);

  const disabled =
    purpose === "rent"
      ? (!props.controls["confirm_tenancy_agreement"] ||
          props.controls["confirm_tenancy_agreement"]?.value) &&
        (!props.controls["rent_confirm_tenancy_agreement_business"] ||
          props.controls["rent_confirm_tenancy_agreement_business"]?.value)
        ? false
        : true
      : !props.controls["confirm_tenancy_agreement"] ||
        props.controls["confirm_tenancy_agreement"]?.value
      ? false
      : true;

  const modalInfo = {
    employee: {
      title: "Add employee",
      titleLoading: "Adding employee...",
      editModeTitle: "Employee details",
      editModeSubmitTitle: "Save changes",
      editModeTitleLoading: "Saving changes...",
      urlBack: ROUTES.RECIPIENTS_EMPLOYEES
    },
    landlord: {
      title: "Add landlord",
      titleLoading: "Adding landlord...",
      editModeTitle: "Landlord details",
      editModeSubmitTitle: "Save changes",
      editModeTitleLoading: "Saving changes...",
      urlBack: ROUTES.RECIPIENTS_LANDLORDS
    },
    supplier: {
      title: "Add supplier",
      titleLoading: "Adding supplier...",
      editModeTitle: "Supplier details",
      editModeSubmitTitle: "Save changes",
      editModeTitleLoading: "Saving changes...",
      urlBack: ROUTES.RECIPIENTS_SUPPLIERS
    },
    bank_account: {
      title: "Add bank accounts",
      titleLoading: "Adding bank accounts...",
      editModeTitle: "Bank accounts details",
      editModeSubmitTitle: "Save changes",
      editModeTitleLoading: "Saving changes...",
      urlBack: ROUTES.RECIPIENTS_BANK_ACCOUNTS
    }
  }[props.extraInfo.type];

  const toggleModal = () => {
    history.push(modalInfo.urlBack);
  };

  const toggleModalUpdate = (onClose?: boolean) => {
    props.removeForm(
      [typeByEntity.suppliers, typeByEntity.landlords].includes(
        props.extraInfo.type
      )
        ? FORM
        : INTERNATIONAL_FORM
    );
    if (props.editMode && !onClose) {
      fetchPayees();
    }
    history.goBack();
  };

  const fetchPayees = () => {
    let ids: any[] = [];
    const isInternational = _get(payee, "is_international");
    props.fetchPayees({
      type: purpose,
      getAllPayees: true,
      noRedirect: true,
      pageCount: 1000,
      cb: (payees: any) => {
        setPayee(payees.find((payee: any) => +payee.id === +payeeId));
      },
      loadingFunc: setLoading
    });
    if (!props.selectedPayee.includes(payeeId)) {
      if (isInternational) {
        ids = [payeeId];
        props.selectPayees({
          ids,
          purpose,
          removedIds: [...props.selectedPayee].filter(
            (id: any) => +id !== +payeeId
          )
        });
      } else {
        ids = [...props.selectedPayee, payeeId];
        props.selectPayees({
          ids,
          purpose,
          removedIds: []
        });
      }
    }
  };

  const onSaved = () => {
    if (props.extraInfo.type === typeByEntity.employees) {
      if (props.editMode) {
        props.editEmployeeSubmit(
          true,
          {
            cb: () => {
              toggleModalUpdate();
              invokeMessage("success", "Changes saved.");
            }
          },
          setLoading
        );
      } else {
        props.addEmployeeSubmit(
          true,
          {
            cb: () => {
              toggleModal();
              invokeMessage("success", "Employee added.");
            }
          },
          setLoading
        );
      }
    }

    if (props.extraInfo.type === typeByEntity.suppliers) {
      if (props.editMode) {
        props.editSupplierSubmit(
          true,
          {
            cb: () => {
              toggleModalUpdate();
              invokeMessage("success", "Changes saved.");
            }
          },
          setLoading
        );
      } else {
        props.addSupplierSubmit(
          true,
          {
            cb: (err?: any) => {
              if (!err) {
                toggleModal();
                invokeMessage("success", "Supplier added.");
              }
            }
          },
          setLoading
        );
      }
    }

    if (props.extraInfo.type === typeByEntity.landlords) {
      if (props.editMode) {
        props.editLandlordSubmit(
          true,
          {
            cb: () => {
              toggleModalUpdate();
              invokeMessage("success", "Changes saved.");
            }
          },
          setLoading
        );
      } else {
        props.addLandlordSubmit(
          true,
          {
            cb: (err?: any) => {
              if (!err) {
                toggleModal();
                invokeMessage("success", "Landlord added.");
              }
            }
          },
          setLoading
        );
      }
    }

    if (props.extraInfo.type === typeByEntity["bank-accounts"]) {
      if (props.editMode) {
        props.editSelfTransferSubmit(
          true,
          {
            isWallex: true,
            cb: () => {
              toggleModalUpdate();
              invokeMessage("success", "Changes saved.");
            }
          },
          setLoading
        );
      } else {
        // props.addSelfTransferSubmit(
        //   true,
        //   {
        //     cb: (err?: any) => {
        //       if (!err) {
        //         toggleModal();
        //         invokeMessage("success", "Bank account added.");
        //       }
        //     }
        //   },
        //   setLoading
        // );
      }
    }
  };

  const renderContent = () => {
    const commonProps = {
      countryId: props.countryId,
      getAddressByPostalCode: props.getAddressByPostalCode,
      residentialAddress: props.residentialAddress,
      setControl: props.setControl,
      getControl: props.getControl,
      accountCountryCode: props.accountCountryCode,
      editMode: props.editMode,
      editedId: props.extraInfo.payeeId,
      fetchPayees: props.fetchPayees,
      currencyId: props.currencyId,
      paymentCountryCode: props.paymentCountryCode,
      paymentCurrencyCode: props.paymentCurrencyCode,
      isBusinessAccount: props.isBusinessAccount
    };

    const wallexProps = {
      setPaymentCountryCode: props.setPaymentCountryCode,
      setPaymentCurrencyCode: props.setPaymentCurrencyCode
    };

    return (
      <div className={styles.content}>
        {props.extraInfo.type === typeByEntity.landlords && (
          <RecipientLandlordForm
            isHongKongAccount={props.isHongKongAccount}
            isAustraliaAccount={props.isAustraliaAccount}
            {...commonProps}
            {...wallexProps}
          />
        )}
        {props.extraInfo.type === typeByEntity.employees && (
          <RecipientEmployeeForm {...commonProps} />
        )}
        {props.extraInfo.type === typeByEntity.suppliers && (
          <RecipientSupplierForm {...commonProps} {...wallexProps} />
        )}

        {props.extraInfo.type === typeByEntity["bank-accounts"] && (
          <RecipientBankAccountForm
            {...commonProps}
            {...wallexProps}
            entity={"self_transfer"}
          />
        )}
      </div>
    );
  };

  const renderSubmitButton = () => {
    return (
      <Button
        type="primary"
        htmlType="button"
        onClick={onSaved}
        loading={loading || disabled}
        autoScrollToFirstError={true}
      >
        {`${
          loading
            ? props.editMode
              ? modalInfo.editModeTitleLoading
              : modalInfo.titleLoading
            : props.editMode
            ? modalInfo.editModeSubmitTitle
            : modalInfo.title
        }`}
      </Button>
    );
  };

  const renderDesktopView = () => {
    return (
      <>
        <div className={styles.wrapper}>
          <DesktopModalLayout
            title={props.editMode ? modalInfo.editModeTitle : modalInfo.title}
            maxWidth450={true}
            onClose={toggleModalUpdate.bind(null, true)}
            onSubmit={onSaved}
            btnSubmit={renderSubmitButton()}
          >
            {renderContent()}
          </DesktopModalLayout>
        </div>
      </>
    );
  };

  const renderMobileView = () => {
    return (
      <MainLayout
        header={{
          title: props.editMode ? modalInfo.editModeTitle : modalInfo.title,
          hideMenu: true,
          onClose: toggleModalUpdate.bind(null, true)
        }}
        footer={renderSubmitButton()}
      >
        <div className={styles.wrapper}>{renderContent()}</div>
      </MainLayout>
    );
  };

  useEffect(() => {
    props.editPayee(props.extraInfo.payeeId, setLoading);
  }, [props.extraInfo.payeeId]);

  // useEffect(() => {
  //   const errorsEls = document.getElementsByClassName("ant-form-item-has-error");
  //   if (!loading && errorsEls.length > 0) {
  //     errorsEls[0].scrollIntoView({ behavior: 'smooth' });
  //   }
  // }, [loading]);

  return (
    <>
      <BrowserView>{renderDesktopView()}</BrowserView>
      <MobileView>{renderMobileView()}</MobileView>
    </>
  );
};

export default View;
