/* tslint:disable */
// tslint:disable-object-literal-sort-keys
const t = [
  {
    id: 1,
    name: "duplicate_charge",
    label: "Duplicate Charge",
    note: "Duplicate Charge"
  },
  {
    id: 2,
    name: "incorrect_fee",
    label: "Incorrect Fee applied",
    note: "Incorrect Fee applied"
  },
  {
    id: 3,
    name: "customer_request",
    label: "Customer request",
    note: "Customer request"
  },
  { id: 4, name: "other", label: "Other", note: "Other" }
];
export default t;
