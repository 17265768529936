import _get from "lodash-es/get";

const INTERCOM_PRODUCTION_APP_ID = "go8gqcvi";
// const INTERCOM_DEV_APP_ID = "jes2hif1";

const PAY_FETCH_TOUR_IDS = {
  DEV: {},
  PROD: {
    PERSONAL: {
      NEW_USER: {
        PAY_TOUR_ID: 305012
      },
      EXISTING_USER: {
        PAY_TOUR_ID: 314190
      }
    },
    BUSINESS: {
      NEW_USER: {
        PAY_TOUR_ID: 317168,
        FETCH_TOUR_ID: 305690
      },
      EXISTING_USER: {
        PAY_TOUR_ID: 317656,
        FETCH_TOUR_ID: 314192
      }
    }
  }
};

const HIGHLIGHT_FETCH_TOUR = 364061;

const getPayFetchTourId = (
  isProduction = false,
  isBusinessAccount = false,
  isNewUser = false,
  isFetchTour = false
): number => {
  // const envType = isProduction ? "PROD" : "DEV";
  const envType = "PROD";
  const accountType = isBusinessAccount ? "BUSINESS" : "PERSONAL";
  const userType = isNewUser ? "NEW_USER" : "EXISTING_USER";
  const tourId = isFetchTour ? "FETCH_TOUR_ID" : "PAY_TOUR_ID";

  return PAY_FETCH_TOUR_IDS[envType][accountType][userType][tourId];
};

// Intercom: Start Pay tour
export const startPayTour = (props: any) => {
  const personalPayTourSetting = _get(
    props.userRequiredSetting,
    "personalFirstLoginTourInPayFetch",
    false
  );

  const personalPayTourSettingCondition =
    props.isPersonalAccount && personalPayTourSetting;

  const businessPayTourSetting = _get(
    props.userRequiredSetting,
    "businessFirstLoginPayTourInPayFetch",
    false
  );

  const businessPayTourSettingCondition =
    props.isBusinessAccount &&
    (props.hasCollectedAccountFirstStep || props.hasCollectedAccount) &&
    businessPayTourSetting;

  if (!personalPayTourSettingCondition && !businessPayTourSettingCondition) {
    return;
  }

  const tourId = getPayFetchTourId(
    props.isProduction,
    props.isBusinessAccount,
    props.isNewUser,
    false
  );

  IntercomUtil.startTour(tourId);
  props.changeUserSetting({
    enable: false,
    property: props.isBusinessAccount
      ? "business_first_login_pay_tour_in_pay_fetch"
      : "personal_first_login_tour_in_pay_fetch",
    refreshDisable: true
  });
};

// Intercom: Start Fetch tour
export const startFetchTour = (props: any) => {
  const businessFetchTourSetting = _get(
    props.userRequiredSetting,
    "businessFirstLoginFetchTourInPayFetch",
    false
  );

  const businessFetchTourSettingCondition =
    props.isBusinessAccount &&
    (props.hasCollectedAccountFirstStep || props.hasCollectedAccount) &&
    businessFetchTourSetting;

  if (!businessFetchTourSettingCondition) {
    return;
  }

  const tourId = getPayFetchTourId(
    props.isProduction,
    props.isBusinessAccount,
    props.isNewUser,
    true
  );

  IntercomUtil.startTour(tourId);
  props.changeUserSetting({
    enable: false,
    property: "business_first_login_fetch_tour_in_pay_fetch",
    refreshDisable: true
  });
};

export const startHighlightFetchTour = () => {
  IntercomUtil.startTour(HIGHLIGHT_FETCH_TOUR);
};

const IntercomUtil = {
  guestInit: () => {
    try {
      if (!window.Intercom) {
        return;
      }
      window.Intercom("boot", {
        app_id: INTERCOM_PRODUCTION_APP_ID
      });
    } catch (e) {
      window.Logger.guestError("Intercom guest boot failed: ", e.toString());
    }
  },
  userInit: (profile: any) => {
    try {
      if (!window.Intercom) {
        return;
      }
      window.Intercom("boot", {
        // app_id: profile.isProduction
        //   ? INTERCOM_PRODUCTION_APP_ID
        //   : INTERCOM_DEV_APP_ID,
        app_id: INTERCOM_PRODUCTION_APP_ID,
        created_at: profile.createdAt,
        email: profile.displayedEmail, // Email address,
        name: `${profile.displayedFirstName} ${profile.displayedLastName}`,
        user_hash: profile.intercomIdentifier // Full name
      });
    } catch (e) {
      window.Logger.guestError("Intercom user boot failed: ", e.toString());
    }
  },
  shutdown: () => {
    try {
      if (!window.Intercom) {
        return;
      }
      window.Intercom("shutdown");
    } catch (e) {
      window.Logger.guestError("Intercom shutdown failed: ", e.toString());
    }
  },
  startTour: (tourId: number) => {
    try {
      if (!window.Intercom) {
        return;
      }
      window.Intercom("startTour", tourId);
    } catch (e) {
      window.Logger.guestError("Intercom start tour failed: ", e.toString());
    }
  },
  getVisitorId: () => {
    try {
      if (!window.Intercom) {
        return;
      }
      return window.Intercom("getVisitorId");
    } catch (e) {
      window.Logger.guestError(
        "Intercom get visitor id failed: ",
        e.toString()
      );
    }
  },
  trackEvent: (eventName: string, eventData: any) => {
    try {
      if (!window.Intercom) {
        return;
      }
      window.Intercom("trackEvent", eventName, eventData);
    } catch (e) {
      window.Logger.guestError("Intercom track event failed: ", e.toString());
    }
  },

  sendMessage: (message: any) => {
    try {
      if (!window.Intercom) {
        return;
      }
      window.Intercom("update", message);
    } catch (e) {
      window.Logger.guestError("Intercom send message failed: ", e.toString());
    }
  },
  hide: () => {
    try {
      if (!window.Intercom) {
        return;
      }
      window.Intercom("hide");
    } catch (e) {
      window.Logger.guestError("Intercom hide failed: ", e.toString());
    }
  }
};

export default IntercomUtil;
