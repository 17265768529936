import { connect } from "react-redux";

import { RootState } from "src/ipm-shared/store/model/reducers";
import { FetchModalID } from "src/ipm-shared/components/GlobalUI/actions";
import * as actions from "./actions";
import * as selectors from "src/ipm-shared/store/model/selectors";

import Component from "./View";

const mapStateToProps = (state: RootState) => ({
  extraInfo: selectors.getExtraInfo(state, FetchModalID.PAYMENT_DUPLICATE_MODAL)
});

const mapDispatchToProps = {
  toggleModal: actions.toggleModal,
  submitEditPayments: actions.editPayments
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
