/* tslint:disable:object-literal-sort-keys jsx-no-lambda */
import React from "react";
import _sortBy from "lodash-es/sortBy";

import ActionDetailDrawer from "src/bepaid/components/ActionDetailDrawer";
import * as localStorageUtil from "src/bepaid/utils/localStorage";

const SwitchCompany = (props: any) => {
  const { companies, exchangeToken, visible = false, onClose = null } = props;

  const handleSwitchCompany = (value: number) => {
    // When change company should redirect to country which account registered.
    exchangeToken({
      cb: () => {
        localStorageUtil.setDisplaySplashScreen(true);
        window.location.reload();
      },
      companyId: value,
      purpose: "switch_company"
    });
  };

  const actionsDrawer = _sortBy(companies, c => !c.isCurrent)
    .filter(opt => opt.name)
    .map((opt: any) => {
      return {
        key: String(opt.id),
        label: opt.name,
        // tslint:disable-next-line:object-literal-sort-keys
        func: (optData: any) => {
          handleSwitchCompany(opt.id);
        }
      };
    });

  return (
    <ActionDetailDrawer
      height={"auto"}
      placement="bottom"
      visible={visible}
      actions={actionsDrawer}
      onClose={onClose}
    />
  );
};

export default SwitchCompany;
