import React, { useState } from "react";
import Form from "src/bepaid/components/Form";
import styles from "./UserAddNewModal.module.scss";
import InputText from "src/bepaid/components/Form/controls/InputText";
import { BrowserView, MobileView } from "react-device-detect";
import DesktopModalLayout from "src/bepaid/components/Modal/components/DesktopModalLayout";
import MainLayout from "src/bepaid/layouts/MainLayout";
import { FetchModalID } from "../PaymentDuplicateModal/actions";
import Button from "src/bepaid/components/Form/controls/Button";
import _isEmpty from "lodash-es/isEmpty";
import { ADD_USER_FORM } from "src/ipm-shared/store/model/AccountUsers/const";
import { invokeMessage } from "src/bepaid/components/Message";

const UserAddNewModal = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");

  const closeModal = () => {
    props.toggleModal(FetchModalID.USER_ADD_NEW_MODAL);
  };

  const onChangeEmail = (e: any) => {
    setEmail(e.target.value);
  };

  const saveUser = () => {
    props.sendAccountUserInvite(
      ADD_USER_FORM,
      (err: any) => {
        if (err) {
          invokeMessage(
            "error",
            "Submit failed, please contact us for more details."
          );
        }
      },
      setLoading
    );
  };

  const renderSubmitButton = () => {
    return (
      <Button
        type="primary"
        htmlType="button"
        onClick={saveUser}
        loading={_isEmpty(email) || loading}
      >
        {loading ? "Sending" : "Send invite"}
      </Button>
    );
  };

  const renderContent = () => {
    return (
      <div>
        <Form
          name="add-new-user-form"
          layout="vertical"
          requiredMark={false}
          className={styles.form}
        >
          <InputText
            name="email"
            pattern="__EMAIL__"
            placeholder="Email"
            form={"ADD_USER_FORM"}
            label="Team member email address"
            onChange={onChangeEmail}
          />
        </Form>
      </div>
    );
  };
  return (
    <>
      <BrowserView>
        <DesktopModalLayout
          maxWidth450={true}
          title={"Add new user"}
          onClose={closeModal}
          btnSubmit={renderSubmitButton()}
          onSubmit={saveUser}
        >
          <div className={styles.wrapper}>{renderContent()}</div>
        </DesktopModalLayout>
      </BrowserView>

      <MobileView>
        <MainLayout
          header={{
            title: "Add new user",
            onClose: closeModal,
            hideMenu: true
          }}
          footer={renderSubmitButton()}
        >
          <div className={styles.wrapper}>{renderContent()}</div>
        </MainLayout>
      </MobileView>
    </>
  );
};

export default UserAddNewModal;
