/**
 * Actions
 *
 * ActionCreators are like the "pub" of pubsub. It's just broadcasting an event (action).
 * So, don't couple it with a specific reducer. Let ANY reducer listen to
 * an action.
 *
 * `typesafe-actions` has a nice utility function so we don't need to define action types as
 * constants. Use it together with `getType(type)` in the reducers.
 */
import { createAction } from "typesafe-actions";
import { MyInfoType, UserType } from "./reducer";

export const fetchUsers = createAction(
  "adminUser/FETCH",
  resolve => (offset: number = 0) => resolve({ offset })
);

export const setUsers = createAction(
  "adminUser/SET_USERS",
  resolve => (args: {
    isFetching: boolean;
    users?: UserType[];
    total: number;
  }) => resolve(args)
);

export const adminSelectMyInfo = createAction(
  "adminUser/SELECT_MY_INFO",
  resolve => (userId: number) => resolve({ userId })
);

export const setMyInfo = createAction(
  "adminUser/SET_MY_INFO",
  resolve => (myInfo: MyInfoType) => resolve({ myInfo })
);

export const adminRequestLogin = createAction(
  "adminUser/REQUEST_LOGIN",
  resolve => (
    appId?: string,
    cb?: (value: any) => void,
    getTokenOnly?: boolean
  ) => resolve({ appId, cb, getTokenOnly })
);

export const resendVerifyEmail = createAction(
  "adminUser/RESEND_VERIFY_EMAIL",
  resolve => (email: string, afterFetchCb: () => void) =>
    resolve({ email, afterFetchCb })
);

export const adminGetResetLink = createAction(
  "adminUser/GET_RESET_LINK",
  resolve => (email: string) => resolve({ email })
);

export const setResetLink = createAction(
  "adminUser/SESET_LINK",
  resolve => (resetLink: string) => resolve({ resetLink })
);

export const adminGetEmailConfirmLink = createAction(
  "adminUser/GET_EMAIL_CONFIRM_LINK",
  resolve => (email: string) => resolve({ email })
);

export const setEmailConfirmLink = createAction(
  "adminUser/SESET_EMAIL_CONFIRM_LINK",
  resolve => (emailConfirmLink: string) => resolve({ emailConfirmLink })
);

export const adminBlockUser = createAction(
  "adminUser/BLOCK_USER",
  resolve => (userId: number, blockSuccessCb: () => void) =>
    resolve({ userId, blockSuccessCb })
);

export const adminUnBlockUser = createAction(
  "adminUser/UNBLOCK_USER",
  resolve => (userId: number, blockSuccessCb: () => void) =>
    resolve({ userId, blockSuccessCb })
);

export const updateUsers = createAction(
  "adminUser/UPDATE_USERS",
  resolve => (users: UserType[]) => resolve({ users })
);
