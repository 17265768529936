/* tslint:disable:object-literal-sort-keys jsx-no-lambda */
import React from "react";
import { Redirect } from "react-router-dom";
import { BrowserView, MobileView, isDesktop } from "react-device-detect";
import _get from "lodash-es/get";
import classNames from "classnames";
import Flex from "antd-mobile/es/flex";
import Typography from "antd/es/typography";

import { history } from "src/ipm-shared/store";
import ShortCurrency from "src/ipm-shared/components/ShortCurrency";
import { PLATFORM } from "src/ipm-shared/components/GlobalUI/reducers";

import ROUTES from "src/bepaid/routes";
import Card from "src/bepaid/components/Card";
import Progress from "src/bepaid/components/Progress";
import Tab from "src/bepaid/components/Tabs";
import Skeleton from "src/bepaid/components/Skeleton";
import OverviewBox from "src/bepaid/components/OverviewBox";
// import Button from "src/bepaid/components/Form/controls/Button";
import List from "src/bepaid/components/List";
import MainLayout from "src/bepaid/layouts/MainLayout";
import ProfileHeading from "src/bepaid/layouts/MainLayout/components/ProfileHeading";
import ButtonsHeading from "src/bepaid/layouts/MainLayout/components/ButtonsHeading";
import MobileMainButton from "src/bepaid/layouts/MainLayout/components/MobileMainButton";

import { DURATIONS } from "./const";

import styles from "./Insights.module.scss";

const Insights: React.FC<any> = props => {
  const {
    currencySymbol,
    hasToken,
    invoiceInsights,
    getInvoiceInsights
  } = props;

  const [duration, setDuration] = React.useState(DURATIONS.last_month.value);
  const [loading, setLoading] = React.useState(false);

  const {
    averageDaysPayment = 0,
    averageInvoiceAmount = 0,
    percentagePunctualInvoices = 0,
    onTimeInvoices = 0,
    totalCustomers = 0,
    totalInvoices = 0
  } = invoiceInsights;

  const isEmptyData = !totalCustomers && !totalInvoices;

  React.useEffect(() => {
    getInvoiceInsights(duration, setLoading);
  }, [duration]);

  const Content = () => {
    const result = [
      {
        key: 1,
        label:
          "Average number of days between sending an invoice and getting paid:",
        value: averageDaysPayment
      },
      {
        key: 2,
        label: "Average invoice amount:",
        value: averageInvoiceAmount
      },
      {
        key: 3,
        label: "Percentage of invoices paid in full and on time:",
        value: percentagePunctualInvoices,
        others: { onTimeInvoices, totalInvoices }
      }
    ];

    return (
      <>
        {result.map((item: any, index: number) => (
          <Card
            key={index}
            className={classNames(styles.card, {
              [styles.first]: index === 0
            })}
          >
            <Skeleton
              loading={loading}
              paragraph={{
                rows: 1
              }}
            >
              <Flex justify="center" align="stretch" direction={"column"}>
                <Flex.Item
                  span={24}
                  className={classNames(styles.title, {
                    [styles.noWrap]: item.key === 3
                  })}
                >
                  <Typography.Text>{item.label}</Typography.Text>
                </Flex.Item>
                <Flex.Item
                  span={24}
                  className={classNames(styles.amount, {
                    [styles.invoicePercentage]: item.key === 3
                  })}
                >
                  {item.key === 2 && (
                    <Typography.Text className={styles.currency}>
                      {currencySymbol}
                    </Typography.Text>
                  )}
                  <Typography.Text>
                    {item.key === 2 ? (
                      <ShortCurrency
                        value={item.value}
                        removedZeroDecimal={true}
                        fractionDigits={0}
                      />
                    ) : (
                      item.value
                    )}
                  </Typography.Text>
                  {item.key === 3 && (
                    <>
                      <Typography.Text className={styles.percentage}>
                        %
                      </Typography.Text>
                      <div className={styles.progress}>
                        <Typography.Text className={styles.invoices}>
                          {`${_get(item, "others.onTimeInvoices")}/${_get(
                            item,
                            "others.totalInvoices"
                          )}`}
                        </Typography.Text>
                        <Progress
                          colors={["#937CF2", "#240998"]}
                          value={item.value}
                        />
                      </div>
                    </>
                  )}
                </Flex.Item>
              </Flex>
            </Skeleton>
          </Card>
        ))}
      </>
    );
  };

  const tabs = Object.values(DURATIONS).map((item: any, idx: number) => ({
    name: item.label,
    content: isDesktop ? null : <Content />
  }));

  const handleChangeTab = (idx: number) => {
    setDuration(Object.values(DURATIONS)[idx].value);
  };

  const renderContent = () => {
    return (
      <div className={styles.content}>
        {isEmptyData ? (
          <OverviewBox
            isEmptyData={true}
            emptyTitle={"Send your first invoice and get paid."}
            emptyDescription={
              "Statistics on your invoice performance will appear here."
            }
          />
        ) : !isDesktop ? (
          <Tab tabs={tabs} onChange={handleChangeTab} />
        ) : (
          <Content />
        )}
      </div>
    );
  };

  const renderDesktopView = () => {
    return (
      <div className={classNames(styles.wrapper, styles.desktop)}>
        <MainLayout
          header={{
            leftHeader: <ProfileHeading />,
            rightHeader: <ButtonsHeading />
          }}
          body={{
            leftBody: renderContent(),
            rightBody: (
              <Card className={styles.timeFrameBox}>
                <List
                  header={
                    <Typography.Text className={styles.header}>
                      Time Frame
                    </Typography.Text>
                  }
                  emptyDOM={
                    <div className={styles.timeFrame}>
                      <Tab
                        tabs={tabs}
                        onChange={handleChangeTab}
                        fullWidth={true}
                        size={"large"}
                      />
                    </div>
                  }
                />
              </Card>
            )
          }}
        />
      </div>
    );
  };

  const renderMobileView = () => {
    return (
      <MainLayout
        header={{
          title: "Insights",
          onBack: () => history.push(ROUTES.ROOT),
          hasBorder: !isEmptyData
        }}
        footer={<MobileMainButton platform={PLATFORM.FETCH} />}
      >
        <div className={styles.wrapper}>{renderContent()}</div>
      </MainLayout>
    );
  };

  return !hasToken ? (
    <Redirect to={ROUTES.LOGIN_REDIRECT} />
  ) : (
    <>
      <BrowserView>{renderDesktopView()}</BrowserView>
      <MobileView>{renderMobileView()}</MobileView>
    </>
  );
};

export default Insights;
