import React from "react";
import _get from "lodash-es/get";
import T from "src/ipm-shared/Utils/Intl";

import InputText from "src/bepaid/components/Form/controls/InputText";
import Checkbox from "src/bepaid/components/Checkbox";

import SearchCompanyForm from "src/bepaid/components/SearchCompanyForm";
import Divider from "antd/es/divider";
import Form from "src/bepaid/components/Form";

import styles from "../../RecipientModal.module.scss";

const DomesticRecipientSupplierFormContent = ({
  editedPayee,
  FORM,
  initName,
  inputUEN,
  inputSearch
}: any) => {
  return (
    <Form
      name="company-form"
      layout="vertical"
      requiredMark={false}
      className={styles.companyForm}
    >
      <div>
        <span className={styles.additionalInfoLabel}>Contact details</span>
        <Divider />

        <SearchCompanyForm
          form={FORM}
          supplierOptInCheckBox={
            <Checkbox
              className={styles.supplierOptInCheckBox}
              id="supplier_opt_in"
              name={"supplier_opt_in"}
              presentedValue="checked"
              defaultChecked={true}
              form={FORM}
              label={T.transl("INTEND_MULTIPLE_PAYMENTS_LABEL")}
            />
          }
          fields={{
            autoCompleteField: {
              name: "recipient_name", // "uen_search_company"
              default: _get(editedPayee, "name") || initName,
              ...inputSearch
            },
            inputTextField: {
              name: "registration_number",
              ...inputUEN
            }
          }}
          input={{
            companyName: _get(editedPayee, "name") || initName,
            registrationNumber: _get(editedPayee, "data.registration_number")
          }}
          shouldDisplayRegField={false}
        />

        <InputText
          name={"recipient_contact_name"}
          placeholder={"Supplier contact name"}
          form={FORM}
          label={"Supplier contact name"}
          defaultValue={_get(editedPayee, "data.recipient_contact_name", "")}
          // {...commonFormItemProps}
          // className={styles.codeSection}
        />
        <InputText
          name={"recipient_email"}
          placeholder={"Supplier contact email"}
          form={FORM}
          label={"Supplier contact email"}
          defaultValue={_get(editedPayee, "data.recipient_email", "")}
          // {...commonFormItemProps}
          // className={styles.codeSection}
        />
      </div>
    </Form>
  );
};

export default DomesticRecipientSupplierFormContent;
