import React from "react";
import Form from "src/bepaid/components/Form";
import { BrowserView, isDesktop, MobileView } from "react-device-detect";
import MainLayout from "src/bepaid/layouts/MainLayout";
import Button from "src/bepaid/components/Form/controls/Button";
import DesktopModalLayout from "src/bepaid/components/Modal/components/DesktopModalLayout";
import classNames from "classnames";
import _get from "lodash-es/get";
import _trim from "lodash-es/trim";
import { startHighlightFetchTour } from "src/ipm-shared/Utils/Intercom";
import { PLATFORM } from "src/ipm-shared/components/GlobalUI/reducers";
import ROUTES from "src/bepaid/routes";
import { history } from "src/ipm-shared/store";

import styles from "./PayFetchTour.module.scss";
import Typography from "antd/es/typography";

const options = [
  {
    label: "Make a payment",
    value: PLATFORM.PAY
  },
  {
    label: "Send an invoice",
    value: PLATFORM.FETCH
  }
];

const PayFetchTour: React.FC<any> = props => {
  const firstName = _trim(_get(props.accountProfile, "displayedFirstName", ""));

  const handleSelectTour = (value: any) => {
    props.switchPlatform(value);
    history.push(ROUTES.ROOT);
    if (
      value === PLATFORM.PAY &&
      props.userRequiredSetting.fetchHighlightTour &&
      isDesktop
    ) {
      props.changeUserSetting({
        enable: false,
        property: "fetch_highlight_tour",
        refreshDisable: true
      });
      setTimeout(() => {
        startHighlightFetchTour();
      }, 500);
    }
  };

  const onClose = () => {
    history.push(ROUTES.ROOT);
    props.changeUserSetting({
      enable: false,
      property: "fetch_highlight_tour",
      refreshDisable: true
    });
    props.changeUserSetting({
      enable: false,
      property: "show_suggestion_modal",
      refreshDisable: true
    });
  };

  const renderContent = () => {
    return (
      <>
        <div
          className={classNames(styles.wrapper, {
            [styles.mobile]: !isDesktop
          })}
        >
          <div className={styles.title}>
            <span>{`Hey ${firstName}! What would you like to do today?`}</span>
          </div>
          <Form
            name="company-form"
            layout="vertical"
            requiredMark={false}
            className={styles.form}
          >
            <div className={styles.optionsWrapper}>
              {options.map(item => (
                <div
                  key={item.value}
                  className={styles.itemWrapper}
                  onClick={handleSelectTour.bind(null, item.value)}
                >
                  <Typography.Text className={styles.text}>
                    {item.label}
                  </Typography.Text>
                </div>
              ))}
            </div>

            <Button type="secondary" onClick={onClose}>
              {"Don’t ask me again"}
            </Button>
          </Form>
        </div>
      </>
    );
  };

  const renderDesktopView = () => {
    return (
      <DesktopModalLayout
        title={`Welcome back, ${firstName}!`}
        maxWidth450={true}
      >
        {renderContent()}
      </DesktopModalLayout>
    );
  };

  const renderMobileView = () => {
    return (
      <MainLayout
        header={{
          title: `Welcome back, ${firstName}!`,
          hideMenu: true
        }}
      >
        {renderContent()}
      </MainLayout>
    );
  };
  return (
    <>
      <BrowserView>{renderDesktopView()}</BrowserView>
      <MobileView>{renderMobileView()}</MobileView>
    </>
  );
};

export default PayFetchTour;
