import * as React from "react";
import { IProps } from "./index";
import VisaIcon from "src/ipm-shared/images/brand-card/visa-icon.svg";
import MasterIcon from "src/ipm-shared/images/brand-card/master-icon.svg";
import UnionPayIcon from "src/ipm-shared/images/brand-card/colored-china-unionpay-icon.svg";
import AmexIcon from "src/ipm-shared/images/brand-card/amex-icon.svg";
import { Range, getTrackBackground } from "react-range";
import FieldError from "src/ipm-shared/components/Form/helpers/FieldErrors";
import T from "src/ipm-shared/Utils/Intl";

import IconCelebration from "src/ipm-shared/images/icon-celebration.svg";
import NumberUtil from "src/ipm-shared/Utils/Number";

class CollectionSplitFee extends React.Component<IProps> {
  public componentDidMount() {
    // Init split fee
    const {
      defaultValue = { national: 0, international: 0, crypto_rate: 0 }
    } = this.props;

    const requesterInternationalRateControlName = `requester_international_rate${
      this.props.extraName ? `_${this.props.extraName}` : ""
    }`;
    const requesterNationalRateControlName = `requester_national_rate${
      this.props.extraName ? `_${this.props.extraName}` : ""
    }`;
    const requesterCryptoRateControlName = `requester_crypto_rate`;

    this.props.setControl({
      form: this.props.form,
      name: requesterNationalRateControlName,
      value: defaultValue.national
    });

    this.props.setControl({
      form: this.props.form,
      name: requesterInternationalRateControlName,
      value: defaultValue.international
    });
    if (typeof defaultValue.crypto_rate === "number") {
      this.props.setControl({
        form: this.props.form,
        name: requesterCryptoRateControlName,
        value: defaultValue.crypto_rate
      });
    }
  }

  public render() {
    const { className, collectionRates, feeControls } = this.props;

    if (!collectionRates) {
      return null;
    }

    if (this.props.renderer) {
      return this.props.renderer(this.props, this.handleSplitRate);
    }

    return (
      <>
        <div
          className={`collection-split-fee-section ${
            className ? className : ""
          }`}
        >
          <p className={"copy-info text-primary mb-4 fz-12"}>
            {`${T.transl("SPLIT_FEE_INSTRUCTIONS")}:`}
          </p>
          {this.renderRangeDOM(
            collectionRates.national,
            feeControls.national,
            "national"
          )}
          <hr />
          {this.renderRangeDOM(
            collectionRates.international,
            feeControls.international,
            "international"
          )}

          <FieldError
            name={`requester_national_rate${
              this.props.extraName ? `_${this.props.extraName}` : ""
            }`}
            className={"mt-2"}
          />
          <FieldError
            name={`requester_international_rate${
              this.props.extraName ? `_${this.props.extraName}` : ""
            }`}
            className={"mt-2"}
          />
        </div>
        {this.props.hasActiveProgram && (
          <div className="mt-2 collection-split-fee-notification">
            <span>
              {T.transl("FREE_PAYMENTS_MESSAGE")}
              <img src={IconCelebration} alt={"Icon Celebration"} />
            </span>
          </div>
        )}
      </>
    );
  }

  // NOTE: the real value is in *100 format. For e.g: 200
  // When we display, easily /100 and toFix 2
  private renderRangeDOM = (
    defaultRate: number,
    value: number,
    type: string
  ) => {
    if (defaultRate === 0 || isNaN(value)) {
      return null; // This could be in initial stage. Should not render anything.
      // Also prevent this error: RangeError: min (0) is equal/bigger than max (0)
      // Btw, this should never happen
    }

    if (defaultRate < value) {
      value = defaultRate;
    }
    return (
      <div className={"range-slide-section py-2"}>
        {this.props.brandId === 2 && type === "national" && (
          <p className={"copy-pay text-black d-flex align-items-center"}>
            <span className={"fw-600 mr-1"}>{`${T.transl(
              "PAY_BY_LABEL"
            )}:`}</span>
            <img className={"mr-1"} width={28} src={VisaIcon} alt="Visa Icon" />
            <img
              className={"mr-1"}
              width={28}
              src={MasterIcon}
              alt="Master Icon"
            />
            <span className={"fw-600 mr-1"}>{this.generateCopy()}</span>{" "}
            {T.transl("VISA_LABEL")} {T.transl("MASTERCARD_LABEL")}{" "}
            <b className={"ml-1"}>
              (0 - {NumberUtil.formatRate(defaultRate)}
              %)
            </b>
          </p>
        )}
        {this.props.brandId === 2 && type === "international" && (
          <p className={"copy-pay text-black d-flex align-items-center"}>
            <span className={"fw-600 mr-1"}>{`${T.transl(
              "PAY_BY_LABEL"
            )}:`}</span>
            <img className={"mr-1"} width={28} src={VisaIcon} alt="Visa Icon" />
            <img
              className={"mr-1"}
              width={28}
              src={MasterIcon}
              alt="Master Icon"
            />
            <span className={"fw-600 mr-1"}>
              {" "}
              {T.transl("INTERNATIONAL_LABEL")}
            </span>{" "}
            {`${T.transl("VISA_LABEL")} ${T.transl("MASTERCARD_LABEL")}`}
            <b className={"ml-1"}>
              (0 - {NumberUtil.formatRate(defaultRate)}%)
            </b>
          </p>
        )}
        {this.props.brandId === 5 && type === "national" && (
          <p className={"copy-pay text-black d-flex align-items-center"}>
            <span className={"fw-600 mr-1"}>{`${T.transl(
              "PAY_BY_LABEL"
            )}:`}</span>
            <img
              className={"mr-1"}
              width={28}
              src={UnionPayIcon}
              alt="Union Icon"
            />
            <span className={"fw-600 mr-1"}>{this.generateCopy()}</span>{" "}
            {T.transl("UNIONPAY_LABEL")}{" "}
            <b className={"ml-1"}>
              (0 - {NumberUtil.formatRate(defaultRate)}
              %)
            </b>
          </p>
        )}
        {this.props.brandId === 5 && type === "international" && (
          <p className={"copy-pay text-black d-flex align-items-center"}>
            <span className={"fw-600 mr-1"}>{`${T.transl(
              "PAY_BY_LABEL"
            )}:`}</span>
            <>
              <img
                className={"mr-1"}
                width={28}
                src={UnionPayIcon}
                alt="Union Icon"
              />
              <span className={"fw-600 mr-1"}>
                {" "}
                {T.transl("INTERNATIONAL_LABEL")}
              </span>{" "}
              {`${T.transl("UNIONPAY_LABEL")}`}
              <b className={"ml-1"}>
                (0 - {NumberUtil.formatRate(defaultRate)}%)
              </b>
            </>
          </p>
        )}
        {this.props.brandId === 4 && type === "national" && (
          <p className={"copy-pay text-black d-flex align-items-center"}>
            <span className={"fw-600 mr-1"}>{`${T.transl(
              "PAY_BY_LABEL"
            )}:`}</span>
            <img className={"mr-1"} width={28} src={AmexIcon} alt="Amex" />
            <span className={"fw-600 mr-1"}>{this.generateCopy()}</span>{" "}
            {T.transl("AMEX_LABEL")}{" "}
            <b className={"ml-1"}>
              (0 - {NumberUtil.formatRate(defaultRate)}
              %)
            </b>
          </p>
        )}
        {this.props.brandId === 4 && type === "international" && (
          <p className={"copy-pay text-black d-flex align-items-center"}>
            <span className={"fw-600 mr-1"}>{`${T.transl(
              "PAY_BY_LABEL"
            )}:`}</span>
            <>
              <img
                className={"mr-1"}
                width={28}
                src={AmexIcon}
                alt="Amex Icon"
              />
              <span className={"fw-600 mr-1"}>
                {" "}
                {T.transl("INTERNATIONAL_LABEL")}
              </span>{" "}
              {`${T.transl("AMEX_LABEL")}`}
              <b className={"ml-1"}>
                (0 - {NumberUtil.formatRate(defaultRate)}%)
              </b>
            </>
          </p>
        )}

        <div className={"range-slide"}>
          <Range
            step={10}
            min={0}
            // MUST: init max = 100 in case not receive collectionRates: { national, international } data yet
            max={defaultRate}
            values={[value]}
            onChange={this.handleSplitRate.bind(this, type)}
            /* tslint:disable-next-line:jsx-no-lambda */
            renderTrack={({ props, children }) => (
              <div
                className={"range-track"}
                {...props}
                style={{
                  ...props.style,
                  background: getTrackBackground({
                    colors: ["#27c0cc", "#ffffff"],
                    max: defaultRate,
                    min: 0,
                    values: [value]
                  }),
                  height: "8px",
                  width: "100%"
                }}
              >
                {children}
              </div>
            )}
            /* tslint:disable-next-line:jsx-no-lambda */
            renderThumb={({ props }) => (
              <div
                className={"circle-thumb"}
                {...props}
                style={{
                  ...props.style,
                  height: "30px",
                  width: "30px"
                }}
              >
                <span className={"circle-inner"} />
              </div>
            )}
          />
        </div>
        <div className={"text-black d-flex justify-content-between"}>
          <span>
            {`${T.transl("YOU_PAY_LABEL")}:`}{" "}
            <b>{NumberUtil.formatRate(value)}%</b>
          </span>
          <span>
            {`${T.transl("CUSTOMER_PAYS_LABEL")}:`}{" "}
            <b>{NumberUtil.formatRate(defaultRate - value)}%</b>
          </span>
        </div>
      </div>
    );
  };

  private generateCopy = () => {
    switch (this.props.accountCountryCode) {
      case "SG":
        return T.transl("COUNTRY_LABEL_SG");
      case "MY":
        return T.transl("COUNTRY_LABEL_MY");
      case "HK":
        return T.transl("COUNTRY_LABEL_HK");
      case "AU":
        return T.transl("COUNTRY_LABEL_AU"); // TODO: Add AU label
      default:
        return "";
    }
  };

  private handleSplitRate = (type: string, values: number) => {
    const value = values[0];

    this.props.setControl({
      form: this.props.form,
      name:
        type === "crypto_rate"
          ? `requester_crypto_rate`
          : `requester_${type}_rate${
              this.props.extraName ? `_${this.props.extraName}` : ""
            }`,
      value
    });

    if (this.props.onChangeCustom) {
      this.props.onChangeCustom({
        requesterRate: {
          international: this.props.feeControls.international,
          national: this.props.feeControls.national,
          crypto_rate: this.props.feeControls.crypto_rate,
          [type]: value
        }
      });
    }
  };
}

export default CollectionSplitFee;
