/* tslint:disable:object-literal-sort-keys */
export enum PURPOSE {
  RENTAL = 1,
  SALARY,
  INVOICE,
  TAX = "TAX",
  COLLECTION = 4,
  SELF_TRANSFER,
  PARTNERSHIP,
  SALARY_BUSINESS = 7,
  INSURANCE
}

export const ADD_FORM = "add_payment_form";
export const SEARCH_FORM = "admin_payment_search_form";
export const REFUND_FORM = "refund_form";
export const EXPEDITE_FORM = "expedite_form";
export const CONFIRM_FORM = "confirm_form";
export const PAYMENT_CHECK_SEARCH_FORM = "payment_check_search_form";
export const PAYMENT_PAYEE_EDIT_FORM = "payment_payee_edit_form";
export const VERIFY_IDENTITY_FORM = "verify_identity_form";
export const TAX_PAYMENT_ACCOUNT_NUMBER = ["0010468669", "316385"];

// Scheduled
export const SCHEDULE_EDIT_FORM = "schedule_edit_form";
export const SCHEDULE_EDIT_COUPON_FORM = "schedule_edit_coupon_form";

export const SCHEDULE_STATUS = {
  ACTIVE: "y",
  INACTIVE: "n"
};

export const SCHEDULE_FREQUENCY = {
  WEEKLY: "weekly",
  MONTHLY: "monthly",
  BIWEEKLY: "biweekly",
  QUARTERLY: "quarterly",
  BIANNALLY: "biannually"
};

export enum PAYMENT_STATUS {
  DRAFT = 1,
  IN_PROGRESS, // Is schedule
  IN_PROGRESS_2,
  COMPLETED,
  DECLINED,
  CANCELLED_DUE_TO_DELETED_CARD,
  CANCELLED_DUE_TO_DELETED_SCHEDULE,
  CANCELLED_DUE_TO_DELETED_PROPERTY,
  FULLY_REFUNDED_WITH_FEE,
  FULLY_REFUNDED_WITHOUT_FEE,
  ON_HOLD,
  REQUEST,
  REFUNDED = 14,
  INT_PENDING = 15,
  PAID,
  UNDER_REVIEW = 17,
  OVERDUE,
  ON_SCREENING,
  BLOCKED = 26
}

export const PAYMENT_FREQUENCY = {
  ONE_TIME: "one-time",
  RECURRING: "recurring"
};

export const PAYMENT_FAVOURITE = {
  YES: true,
  NO: false
};

export const PAYMENT_GROUP_STATUS = {
  scheduled: [PAYMENT_STATUS.IN_PROGRESS],
  sent: [PAYMENT_STATUS.REQUEST],
  under_review: [PAYMENT_STATUS.ON_HOLD],
  in_progress: [PAYMENT_STATUS.IN_PROGRESS_2],
  completed: [PAYMENT_STATUS.COMPLETED],
  overdue: [PAYMENT_STATUS.OVERDUE],
  refunded: [
    PAYMENT_STATUS.FULLY_REFUNDED_WITH_FEE,
    PAYMENT_STATUS.FULLY_REFUNDED_WITHOUT_FEE,
    PAYMENT_STATUS.REFUNDED
  ],
  cancelled: [
    PAYMENT_STATUS.DECLINED,
    PAYMENT_STATUS.CANCELLED_DUE_TO_DELETED_CARD,
    PAYMENT_STATUS.CANCELLED_DUE_TO_DELETED_SCHEDULE,
    PAYMENT_STATUS.CANCELLED_DUE_TO_DELETED_PROPERTY,
    PAYMENT_STATUS.BLOCKED
  ],
  other: [
    PAYMENT_STATUS.DRAFT, // 1
    PAYMENT_STATUS.IN_PROGRESS, // 2
    PAYMENT_STATUS.DECLINED, // 5
    PAYMENT_STATUS.PAID, // 16
    PAYMENT_STATUS.OVERDUE // 18
  ],
  on_screening: [PAYMENT_STATUS.ON_SCREENING]
};

export const PAYMENT_GROUP_STATUS_LABEL = {
  sent: "Sent",
  under_review: "Under Review",
  in_progress: "In Progress",
  completed: "Completed",
  overdue: "Overdue",
  refunded: "Refunded",
  cancelled: "Cancelled",
  other: "Other",
  on_screening: "On Screening"
};

export const REFUND_TYPE = {
  PARTIAL_REFUND_FEE: "partial_refund_fee",
  PARTIAL_REFUND_PRINCIPAL: "partial_refund_principal",
  REFUND_WITHOUT_FEE: "refund_without_fee",
  REFUND_WITH_FEE: "refund_with_fee"
};

export const PAGE_COUNT = 30;
export const ADMIN_PAGE_COUNT = 200;
export const SCHEDULED_PAGE_COUNT = 50;

export const DEFAULT_PAYMENT_STATUS_IDS = [
  PAYMENT_STATUS.IN_PROGRESS,
  PAYMENT_STATUS.IN_PROGRESS_2,
  PAYMENT_STATUS.ON_HOLD,
  PAYMENT_STATUS.INT_PENDING,
  PAYMENT_STATUS.UNDER_REVIEW,
  PAYMENT_STATUS.COMPLETED,
  PAYMENT_STATUS.PAID,
  PAYMENT_STATUS.FULLY_REFUNDED_WITH_FEE,
  PAYMENT_STATUS.FULLY_REFUNDED_WITHOUT_FEE,
  PAYMENT_STATUS.DECLINED,
  PAYMENT_STATUS.ON_SCREENING,
  PAYMENT_STATUS.BLOCKED
];
