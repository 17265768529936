import React from "react";
import { BrowserView, MobileView } from "react-device-detect";

import { FetchModalID } from "src/ipm-shared/store/model/actions";
import { history } from "src/ipm-shared/store";

import ROUTES from "src/bepaid/routes";
import FetchPopup from "src/bepaid/assets/images/new_fetch_popup.png";
// import FetchPopupMY from "src/bepaid/assets/images/fetch_popup_my.png";
import Image from "src/bepaid/components/Image";
import DesktopModalLayout from "src/bepaid/components/Modal/components/DesktopModalLayout";
import MainLayout from "src/bepaid/layouts/MainLayout";
import Button from "src/bepaid/components/Form/controls/Button";

import styles from "./BenefitsOfFetchModal.module.scss";

function InvoiceDetailModal(props: any) {
  // const isNotMYAccount = props.accountCountryCode !== "MY";
  const buttonContent = "Try it free now";
  const toggleModal = () => {
    history.push(ROUTES.ROOT);
    props.toggleModal(FetchModalID.BENEFITS_OF_FETCH_MODAL);
  };

  const onClickSubmit = () => {
    window.open("mailto:support@ipaymy.com", "_blank");
    toggleModal();
  };

  const renderContent = () => (
    <div className={styles.wrapper}>
      <h3 className={styles.title}>
        Small businesses get paid faster with Fetch.
      </h3>

      <p className={styles.description}>
        Make chasing payments a thing of the past. Go from invoice to getting
        paid fast with the powerful technology of Fetch, all in one easy-to-use
        app. Learn more.
      </p>

      <Image src={FetchPopup} />
    </div>
  );

  return (
    <>
      <BrowserView>
        <DesktopModalLayout
          hasBorder={false}
          maxWidth450={true}
          submitLabel={buttonContent}
          onClose={toggleModal}
          onSubmit={onClickSubmit}
        >
          {renderContent()}
        </DesktopModalLayout>
      </BrowserView>
      <MobileView>
        <MainLayout
          header={{
            hasBorder: false,
            hideMenu: true,
            onClose: toggleModal
          }}
          footer={
            <Button type="primary" htmlType="submit" onClick={onClickSubmit}>
              {buttonContent}
            </Button>
          }
        >
          {renderContent()}
        </MainLayout>
      </MobileView>
    </>
  );
}

export default InvoiceDetailModal;
