import * as React from "react";
import { IProps } from ".";
import { FetchModalID } from "../PaymentsCreationModal/components/SchedulePayment/actions";
import styles from "./TripleAPaymentModal.module.scss";
import classNames from "classnames";
import Image from "src/bepaid/components/Image";
import IconClose from "src/bepaid/assets/images/common/icon_close.svg";
import { isMobile } from "react-device-detect";

const TripleAPaymentModal = (props: IProps) => {
  const { toggleModal, extraInfo } = props;

  const handleClose = () => {
    toggleModal(FetchModalID.TRIPLEA_PAYMENT_MODAL);
    extraInfo.onClose();
  };

  return (
    <>
      <div
        className={classNames(styles.tripleAPaymentContainer, {
          [styles.isMobile]: isMobile
        })}
      >
        <div className={styles.closeButton}>
          <Image src={IconClose} width={15} height={15} onClick={handleClose} />
        </div>
        <iframe
          className={classNames(styles.tripleAPaymentIframe)}
          src={extraInfo.hostedUrl}
        ></iframe>
      </div>
    </>
  );
};

export default TripleAPaymentModal;
