import { connect } from "react-redux";

import { RootState } from "src/ipm-shared/store/model/reducers";
import * as actions from "src/bepaid/store/model/Fetch/actions";
import * as selectors from "src/ipm-shared/components/GlobalUI/selectors";

import View from "./View";

const mapStateToProps = (state: RootState, props: any) => ({
  extraInfo: props.modalId
    ? selectors.getExtraInfo(state, props.modalId)
    : props.extraInfo
});

const mapDispatchToProps = {
  cancelDraftPayment: actions.cancelPaymentRequest,
  fetchMainCollectedAccount: actions.fetchMainCollectedAccount
};

export type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(View);
