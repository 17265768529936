import React, { useEffect, useState } from "react";
import { isDesktop, isMobile } from "react-device-detect";
import DayPicker, {
  DateUtils,
  DayModifiers,
  RangeModifier
} from "react-day-picker";

import "react-day-picker/lib/style.css";
import Button from "../Form/controls/Button";
import InputCalendar from "src/bepaid/components/InputCalendar";
import Tooltip from "src/ipm-shared/components/Tooltip";

import Image from "../Image";
import NextIcon from "src/bepaid/assets/images/common/icon_next.svg";
import FlashIcon from "src/bepaid/assets/images/common/icon_flash.svg";
import FlashIconHover from "src/bepaid/assets/images/common/icon_flash_hover.svg";
import FlashIconDisable from "src/bepaid/assets/images/common/icon_flash_disable.svg";

import classnames from "classnames";
import styles from "./Calendar.module.scss";
import isWeekend from "date-fns/is_weekend";
import addDays from "date-fns/add_days";
import BepaidDateUtils from "src/ipm-shared/Utils/Date";

export default function DatePicker(props: any) {
  const {
    type,
    disabledDays = [],
    pspNonWorkingDays = [],
    earliestDate,
    flashPayDate,
    loading = false,
    selectDate,
    onSelectDate,
    onClickDay,
    submitText,
    calendarType = "default",
    label,
    showTooltip = false,
    flashPayInfo,
    showFlashPay
  } = props;
  const renderSubmitText = () => {
    if (submitText) {
      return submitText;
    }
    if (loading) {
      return "Select...";
    }
    return "Select";
  };

  const [range, setRange] = useState<RangeModifier>({
    from: new Date(),
    to: addDays(new Date(), 1)
  });

  const [dateSelected, setSelectDate] = useState<any>(selectDate);

  const [hasError, setHasError] = useState(true);
  const [inputDate, setInputDate] = useState({
    year: "",
    month: "",
    day: ""
  });

  const defaultNotes = (
    <div className={styles.notes}>
      <div className={classnames(styles.noteItem, styles.noteTodayDate)}>
        <div />
        Today's Date
      </div>
      <div className={classnames(styles.noteItem, styles.noteInvoiceDate)}>
        <div />
        {props.labelDate ? props.labelDate : "Date Added"}
      </div>
    </div>
  );

  useEffect(() => {
    if (
      calendarType !== "default" &&
      inputDate.year &&
      inputDate.month &&
      inputDate.day
    ) {
      setSelectDate(
        new Date(`${inputDate.year}-${inputDate.month}-${inputDate.day}`)
      );
    } else {
      setSelectDate(selectDate || earliestDate);
    }
  }, [calendarType, inputDate, selectDate, earliestDate]);

  const onDayClick = (day: Date, modifiers: DayModifiers) => {
    if (modifiers.disabled) {
      return;
    }

    setSelectDate(day);

    if (props.range) {
      const r = DateUtils.addDayToRange(day, range);
      setRange(r);
    }

    if (onSelectDate) {
      onSelectDate(day);
    }

    if (onClickDay) {
      onClickDay(day);
    }
  };

  const renderFlashPayDateDesTooltip = (disabled?: boolean) => {
    return (
      <div className="flash-pay-date-description">
        <b>Flash Pay</b>
        <span>
          {disabled
            ? flashPayInfo.flashPayAmountTooltipDisable
            : flashPayInfo.flashPayAmountTooltip}
        </span>
      </div>
    );
  };

  const renderDay = (d: Date, modifiers: DayModifiers) => {
    const isShowTooltip =
      BepaidDateUtils.isTwoDateEqual(d, new Date(dateSelected)) && showTooltip;

    const isFlashPayDate = BepaidDateUtils.isTwoDateEqual(
      d,
      new Date(flashPayDate)
    );

    const flashIconSrc = modifiers.disabled
      ? FlashIconDisable
      : BepaidDateUtils.isTwoDateEqual(
          new Date(dateSelected),
          new Date(flashPayDate)
        )
      ? FlashIconHover
      : FlashIcon;

    return (
      <button
        className={classnames(styles.dayContent, {
          [styles.flashPayDay]: isFlashPayDate,
          [styles.dayDisable]: modifiers.disabled
        })}
      >
        {flashPayDate && isFlashPayDate && showFlashPay ? (
          <>
            <Tooltip
              target={"flash-pay-date"}
              displayIcon={false}
              description={renderFlashPayDateDesTooltip(modifiers.disabled)}
              className={styles.tooltip}
              innerClassName={
                isFlashPayDate
                  ? `flashPayDay ${isMobile ? `isMobile` : ``}`
                  : ``
              }
            />
            <Image src={flashIconSrc} />
            <span id={"flash-pay-date"}>
              <b>{`${d.getDate()}`}</b>
            </span>
          </>
        ) : isShowTooltip ? (
          <>
            <Tooltip
              target={"selected-date"}
              alwaysShow={true}
              displayIcon={false}
              description={"Earliest available payout date for Amex payments"}
              className={styles.tooltip}
            />
            <span id={"selected-date"}>{`${d.getDate()}`}</span>
          </>
        ) : (
          d.getDate()
        )}
      </button>
    );
  };

  const getDisabledDates = () => {
    const disabledDates: any[] = Array.isArray(disabledDays)
      ? [...disabledDays]
      : [disabledDays];

    if (showFlashPay) {
      disabledDates.push({
        before: props.allowBeforeIntialDate ? new Date() : flashPayDate // To disable all days before the flash pay date.
      });

      const SGT = new Date(
        new Date().toLocaleDateString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
          timeZone: "Asia/Singapore"
        })
      );

      if (
        SGT.getHours() > 16 ||
        (SGT.getHours() === 16 && SGT.getMinutes() > 30)
      ) {
        disabledDates.push(new Date(flashPayDate));
      }
    } else {
      disabledDates.push({
        before: props.allowBeforeIntialDate ? new Date() : earliestDate // To disable all days before the earliest date.
      });
    }

    pspNonWorkingDays.forEach((day: Date) => {
      disabledDates.push(day);
    });

    disabledDates.push({
      daysOfWeek: props.allowWeekends ? [] : [0, 6]
    });

    return disabledDates;
  };

  const getHolidays = () => {
    let holiday = Array.isArray(disabledDays)
      ? [...disabledDays]
      : [disabledDays];

    let pspNonWorking = Array.isArray(pspNonWorkingDays)
      ? [...pspNonWorkingDays]
      : [pspNonWorkingDays];

    pspNonWorking.forEach((pbDay: Date) => {
      let count = 0;
      holiday.forEach((day: Date) => {
        if (day.getTime() === pbDay.getTime()) {
          count++;
        }
      });

      if (count == 1) {
        holiday = holiday.filter(
          (day: Date) => day.getTime() !== pbDay.getTime()
        );
      }
    });

    let filterHoliday = holiday.filter((day: Date) => !isWeekend(day));

    return filterHoliday;
  };

  const onChange = (e: any) => {
    const input = e.target;
    setInputDate((prevState: any) => {
      return {
        ...prevState,
        [input.name]: input.value
      };
    });
  };

  return (
    <div
      className={classnames(styles.wrapper, {
        [styles.wrapperDesktop]: isDesktop,
        [props.wrapperClassName]: Boolean(props.wrapperClassName),
        [styles.hideTodayBorder]: props.isEndDayNote
      })}
      data-color={type}
    >
      {calendarType === "default" ? (
        <>
          <DayPicker
            selectedDays={props.range ? [range] : dateSelected}
            month={dateSelected ? new Date(dateSelected) : new Date()}
            renderDay={renderDay}
            onDayClick={onDayClick}
            navbarElement={<Navbar />}
            {...(props.dayPickerProps || {})}
            modifiers={{
              disabled: getDisabledDates(),
              highlighted: dateSelected,
              holiday: getHolidays(),
              today: new Date(),
              ...(props.dayPickerProps?.modifiers || {})
            }}
          />

          {props.notes ? props.notes : defaultNotes}
        </>
      ) : (
        <>
          <InputCalendar
            label={label}
            wrapperClassName={styles.inputCalendarWrapper}
            formClassName={styles.form}
            setHasError={setHasError}
            onChange={onChange}
            isGreaterThanNextDate={true}
          />
        </>
      )}

      <div className={styles.wrapperInner}>
        <Button
          type="primary"
          htmlType="button"
          onClick={props.onSubmit.bind(
            null,
            props.range ? range : dateSelected
          )}
          disabled={loading || (calendarType !== "default" && hasError)}
        >
          {renderSubmitText()}
        </Button>
      </div>
    </div>
  );
}

function Navbar({ onPreviousClick, onNextClick, className }: any) {
  return (
    <div className={className}>
      <button
        className={classnames(styles.navBtn, styles.navLeft)}
        onClick={() => onPreviousClick()}
      >
        <Image src={NextIcon} />
      </button>
      <button
        className={classnames(styles.navBtn, styles.navRight)}
        onClick={() => onNextClick()}
      >
        <Image src={NextIcon} />
      </button>
    </div>
  );
}
