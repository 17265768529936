import React, { useEffect, useState } from "react";
import _get from "lodash-es/get";

import T from "src/ipm-shared/Utils/Intl";
import store from "src/ipm-shared/store";
import { getBanksOptionsByCountryId } from "src/ipm-shared/store/model/Bank/selectors";
import CountryUtil from "src/ipm-shared/Utils/Country";
import CurrencyUtil from "src/ipm-shared/Utils/Currency";
import Skeleton from "src/bepaid/components/Skeleton";
import Form from "src/bepaid/components/Form";
import InputText from "src/bepaid/components/Form/controls/InputText";
import Divider from "antd/es/divider";
import Checkbox from "src/bepaid/components/Checkbox";
import FormErrors from "src/ipm-shared/components/Form/helpers/Errors";
import BankBSB from "src/bepaid/components/BankBSB";
import Image from "src/bepaid/components/Image";
import Select from "src/bepaid/components/Form/controls/Select";

import DomesticRecipientSupplierFormContent from "./DomesticRecipientSupplierFormContent";
import IntRecipientSupplierFormContent from "./IntRecipientSupplierFormContent";
import { FORM } from "src/ipm-shared/store/model/Payee/const";

import IconArrowDown from "src/bepaid/assets/images/common/drop_down_arrow_grey.svg";

import styles from "../../RecipientModal.module.scss";

export const RecipientSupplierForm = (props: any) => {
  const {
    countryId,
    editMode,
    editedId,
    fetchPayees,
    initName,
    paymentCountryCode,
    isWallex
  } = props;
  const [editedPayee, setEditedPayee] = useState({});
  const [loading, setLoading] = useState(false);
  const isIntForm = _get(editedPayee, "is_international") || isWallex;

  const commonFormItemProps = {
    reserveValueOnUnmount: true,
    revertValueOnMount: true
  };

  const isSG = countryId === CountryUtil.getCountryIdFromCountryCode("SG");
  const isMY = countryId === CountryUtil.getCountryIdFromCountryCode("MY");
  const isHK = countryId === CountryUtil.getCountryIdFromCountryCode("HK");
  const isAU = countryId === CountryUtil.getCountryIdFromCountryCode("AU");

  let inputSearch = {};
  let inputUEN = {};

  if (isSG) {
    inputSearch = {
      label: T.transl("COMPANY_SEARCH_LABEL"),
      placeholder: T.transl("COMPANY_SEARCH_NAME_PLACEHOLDER")
    };
    inputUEN = {
      label: T.transl("SUPPLIER_UEN_LABEL"),
      linkHereLabel: "Find all businesses registered in Singapore",
      placeholder: T.transl("SUPPLIER_UEN_PLACEHOLDER"),
      disabled: true
    };
  }

  if (isHK) {
    inputSearch = {
      label: T.transl("COMPANY_SEARCH_LABEL"),
      placeholder: T.transl("COMPANY_SEARCH_NAME_PLACEHOLDER")
    };
    inputUEN = {
      label: T.transl("SUPPLIER_CRN_LABEL"),
      linkHereLabel: "Find all businesses registered in Hong Kong",
      placeholder: T.transl("SUPPLIER_CRN_PLACEHOLDER"),
      disabled: true
    };
  }

  if (isMY) {
    inputSearch = {
      label: T.transl("SUPPLIER_COMPANY_NAME_LABEL"),
      placeholder: T.transl("COMPANY_SEARCH_PLACEHOLDER")
    };
    inputUEN = {
      label: T.transl("LABEL_COMPANY_REGISTRATION_NUMBER"),
      linkHereLabel: "Find all businesses registered in Malaysia",
      placeholder: T.transl("COMPANY_REGISTRATION_PLACEHOLDER"),
      disabled: false
    };
  }

  if (isAU) {
    inputSearch = {
      label: T.transl("COMPANY_SEARCH_LABEL"),
      placeholder: T.transl("COMPANY_SEARCH_NAME_PLACEHOLDER")
    };
    inputUEN = {
      label: T.transl("SUPPLIER_ABN_LABEL"),
      linkHereLabel: "Find all businesses registered in Australia",
      placeholder: T.transl("SUPPLIER_ABN_PLACEHOLDER"),
      disabled: true
    };
  }
  const filterBank = getBanksOptionsByCountryId(
    store.getState(),
    _get(editedPayee, "is_international")
      ? _get(editedPayee, "country_id")
      : countryId
  );

  const fetchEditedPayee = () => {
    fetchPayees({
      type: "invoice",
      getAllPayees: true,
      noRedirect: true,
      pageCount: 1000,
      cb: (payees: any) => {
        const payee = payees.find((p: any) => +p.id === +editedId);
        const paymentCountryCode = CountryUtil.getCountryCodeFromCountryId(
          _get(payee, "data.bank_country_id")
        );

        setEditedPayee(payee);
        props.setPaymentCountryCode(paymentCountryCode);
        props.setPaymentCurrencyCode(
          CurrencyUtil.convertFromId(payee.currency_id).code
        );
      },
      loadingFunc: setLoading
    });
  };

  useEffect(() => {
    if (editMode) {
      fetchEditedPayee();
    }
  }, []);

  const domesticProps = {
    editedPayee,
    FORM,
    initName,
    inputUEN,
    inputSearch,
    accountCountryCode: props.accountCountryCode,
    filterBank
  };

  const internationalProps = {
    editedPayee,
    FORM,
    commonFormItemProps,
    paymentCountryCode,
    initName,
    filterBank
  };

  return (
    <div className={styles.wrapperForm}>
      <Skeleton loading={loading}>
        <Form
          name="company-form"
          layout="vertical"
          requiredMark={false}
          className={styles.form}
        >
          <FormErrors form={FORM} />
          {editedPayee && (
            <div hidden={true}>
              <InputText
                name={"payee_id"}
                form={FORM}
                defaultValue={_get(editedPayee, "id", "")}
              />
            </div>
          )}
          {isIntForm ? (
            <IntRecipientSupplierFormContent {...internationalProps} />
          ) : (
            <DomesticRecipientSupplierFormContent {...domesticProps} />
          )}
        </Form>

        {!isIntForm && (
          <Form
            name="company-form"
            layout="vertical"
            requiredMark={false}
            className={styles.form}
          >
            <div>
              <span className={styles.additionalInfoLabel}>
                Payment details
              </span>
              <Divider />

              {props.accountCountryCode === "AU" ? (
                <>
                  <BankBSB
                    form={FORM}
                    name="bank_bsb_id"
                    placeholder="Enter bank name, branch, or BSB"
                    label="Bank name, branch, or BSB"
                    defaultValue={
                      _get(editedPayee, "data.bank_bsb_id") === 0
                        ? null
                        : _get(editedPayee, "data.bank_bsb_id")
                    }
                    suffixIcon={<Image src={IconArrowDown} />}
                  />
                  <div hidden={true}>
                    <InputText
                      form={FORM}
                      name="bank_id"
                      defaultValue={
                        _get(editedPayee, "bank") === 0
                          ? null
                          : _get(editedPayee, "bank")
                      }
                    />
                  </div>
                  <div hidden={true}>
                    <InputText
                      form={FORM}
                      name="bank_swift_code"
                      defaultValue={
                        _get(editedPayee, "data.bank_raw_swift_code") === null
                          ? null
                          : _get(editedPayee, "data.bank_raw_swift_code")
                      }
                    />
                  </div>
                </>
              ) : (
                <Select
                  form={FORM}
                  name="bank_id"
                  placeholder="Select bank"
                  label="Bank"
                  options={filterBank}
                  defaultValue={
                    _get(editedPayee, "bank") === 0
                      ? null
                      : _get(editedPayee, "bank")
                  }
                  suffixIcon={<Image src={IconArrowDown} />}
                  // className={styles.select}
                />
              )}
              <InputText
                name={"account_number"}
                placeholder={"Account number"}
                form={FORM}
                label={"Account number"}
                defaultValue={_get(editedPayee, "account_number", "")}
                // className={styles.codeSection}
              />

              <div hidden={true}>
                <Checkbox
                  presentedValue={"invoice_notify"}
                  id="invoice_notify"
                  name="should_notify_recipient_initiated"
                  form={FORM}
                  label={T.transl("NOTIFY_SUPPLIER_CHECKBOX")}
                  defaultChecked={true}
                />
              </div>
            </div>
          </Form>
        )}
        {paymentCountryCode === "US" && (
          <p className={styles.noteFedwire}>
            {`* `}Please note that Fedwire payments are not supported.
          </p>
        )}
        {isIntForm ? (
          <div hidden={true}>
            <Checkbox
              presentedValue="checked"
              form={FORM}
              className={styles.checkboxForm}
              name={"confirm_tenancy_agreement"}
              id="confirm_tenancy_agreement"
              defaultChecked={true}
              {...commonFormItemProps}
            />
          </div>
        ) : (
          <Checkbox
            presentedValue="checked"
            form={FORM}
            className={styles.checkboxForm}
            name={"confirm_tenancy_agreement"}
            id="confirm_tenancy_agreement"
          >
            I confirm I am not affiliated with this business and all payments
            made to this supplier are for legitimate goods and services.
          </Checkbox>
        )}
      </Skeleton>
    </div>
  );
};
