import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import Flex from "antd-mobile/es/flex";
import Form from "src/bepaid/components/Form";
import Typography from "antd/es/typography";

import InputText from "src/bepaid/components/Form/controls/InputText";
import Button from "src/bepaid/components/Form/controls/Button";
import Image from "src/bepaid/components/Image";
import Card from "src/bepaid/components/Card";
import { invokeMessage } from "src/bepaid/components/Message";
import GuestLayout from "src/bepaid/layouts/GuestLayout";

import HeaderLogo from "src/bepaid/assets/images/desktop/header_ipaymy_logo.svg";

import { RESET_PASSWORD_FORM } from "src/ipm-shared/store/model/Auth/const";
import { IProps } from "./index";

import styles from "./SetNewPassword.module.scss";

const initialValues = { password: "", confirmPassword: "" };

const FormComponent: React.FC<IProps> = (props: any) => {
  const [loading, setLoading] = React.useState(false);
  // @ts-ignore
  const [passwordRuleError, setPasswordRuleError] = React.useState(false);

  const onFinish = (values: any) => {
    props.setNewPasswordSubmit({
      cb: (err?: any) => {
        if (!err) {
          invokeMessage("success", "Password reset! Please sign in.");
        }
        setLoading(false); // ok
      }
    });
  };

  const enterLoading = () => {
    setLoading(true); // ok
  };

  const hasError = React.useMemo(() => {
    try {
      return Boolean(
        props.controls.password.errors.length ||
          props.controls.password_confirm.errors.length
      );
    } catch (err) {
      return false;
    }
  }, [props.controls]);

  return (
    <Form
      name="set-new-password-form"
      layout="vertical"
      initialValues={initialValues}
      requiredMark={false}
      onFinish={onFinish}
      className={styles.form}
    >
      <div hidden={true}>
        <InputText
          name="token"
          defaultValue={props.qs.token}
          form={RESET_PASSWORD_FORM}
        />
      </div>

      <InputText
        label="Password"
        type="password"
        name="password"
        placeholder="Password"
        form={RESET_PASSWORD_FORM}
        className={styles.itemPassword}
      />

      {!passwordRuleError && (
        <Form.Item className={styles.passwordRuleSection}>
          <Typography.Text className={styles.passwordRuleText}>
            At least 8 characters consisting of at least one letter, one number
            and one symbol
          </Typography.Text>
        </Form.Item>
      )}

      <InputText
        label="Confirm Password"
        type="password"
        name="password_confirm"
        form={RESET_PASSWORD_FORM}
        placeholder="Password"
        className={styles.itemConfirmPassword}
      />

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          loading={!hasError && loading}
          onClick={enterLoading}
        >
          {!hasError && loading ? "Resetting password..." : "Reset password"}
        </Button>
      </Form.Item>
    </Form>
  );
};

const SetNewPassword: React.FC<IProps> = props => {
  const renderContent = () => {
    return (
      <div className={styles.wrapper}>
        <Flex justify="center" align="center">
          <Flex.Item span={24}>
            <FormComponent {...props} />
          </Flex.Item>
        </Flex>
      </div>
    );
  };

  const renderDesktopView = () => {
    return (
      <div className={styles.desktopWrapper}>
        <Card>{renderContent()}</Card>
      </div>
    );
  };

  return (
    <GuestLayout
      header={<Image src={HeaderLogo} />}
      title={
        <Typography.Text className={styles.title}>
          Set new password
        </Typography.Text>
      }
    >
      <BrowserView>{renderDesktopView()}</BrowserView>
      <MobileView>{renderContent()}</MobileView>
    </GuestLayout>
  );
};

export default SetNewPassword;
