/**
 * Selectors
 *
 * It's like "views" in relational databases.
 * Store the most efficient format in the reducer state.
 * But to retrieve into easy to consume form, use selector.
 */
import ReducerFactory from "src/ipm-shared/Utils/ReduxReducer";
import reducer from "./reducers";
import { RootState } from "src/ipm-shared/store/model/reducers";

ReducerFactory.registerReducer({
  fetchPreStart: reducer
});

export * from "src/ipm-shared/components/Form/selectors";

export const isRegisterLoading = (state: RootState) =>
  state.fetchPreStart.isLoading;

export const isGlobalLoading = (state: RootState) =>
  state.fetchPreStart.isLoading;
