export const ADD_FORM = "add_promo_code_form";
export const SEARCH_FORM = "search_promo_form";
export const WHITELIST_FORM = "whitelist_promo_form";

export const PAGE_COUNT = 200;
export const enum COUNTRTY_WHITELIST {
  SGD = "1_1",
  MYR = "2_2",
  HKD = "3_3",
  HKD_USA = "3_73",
  AU = "16_11"
}
