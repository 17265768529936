import ROUTES from "src/bepaid/routes";

export const getSearches = (key: string) => {
  const data: string = String(localStorage.getItem(key) || "");
  try {
    const json = JSON.parse(data);
    return Array.isArray(json) ? json : [];
  } catch {
    return [];
  }
};

export const removeSearchHistory = (key: string, value: string) => {
  let data = getSearches(key);
  data = data.filter(t => t !== value);
  localStorage.setItem(key, JSON.stringify(data));
};

export const clearSearchHistory = (key: string) => {
  localStorage.setItem(key, "");
};

export const saveSearchHistory = (key: string, value: string) => {
  const data = getSearches(key);

  data.push(value);

  localStorage.setItem(key, JSON.stringify(data));
};

export const INPUT_HOLDER = {
  customers: {
    placeholder: "Search customers…",
    tooltipText: "Enter customer name or business name…"
  },
  invoices: {
    placeholder: "Search invoices…",
    tooltipText: "Enter customer name, company name or invoice number…"
  },
  landlords: {
    placeholder: "Search landlords…",
    tooltipText: "Enter landlord name, property address or note to landlord…"
  },
  employees: {
    placeholder: "Search employees…",
    tooltipText: "Enter employee name, employee ID or note to employee…"
  },
  suppliers: {
    placeholder: "Search suppliers…",
    tooltipText: "Enter supplier name, UEN or note to supplier…"
  },
  payments: {
    placeholder: "Search payments…",
    tooltipText: "Enter recipient name, receipt number or note to recipient…"
  },
  payments_schedules: {
    placeholder: "Search payments…",
    tooltipText:
      "Enter bank account holder name, account number or payment reference…"
  },
  bank_accounts: {
    placeholder: "Search bank accounts…",
    tooltipText:
      "Enter bank account holder name, account number or payment reference…"
  }
};

export const TITLE = {
  customers: "Search",
  invoices: "Search",
  payments: "Search",
  payments_schedules: "Search"
};

export const REDIRECT_URI = {
  customers: ROUTES.CUSTOMERS,
  invoices: ROUTES.INVOICES,
  payments: ROUTES.PAYMENTS,
  payments_schedules: ROUTES.PAYMENTS,
  landlords: ROUTES.RECIPIENTS_LANDLORDS,
  employees: ROUTES.RECIPIENTS_EMPLOYEES,
  suppliers: ROUTES.RECIPIENTS_SUPPLIERS,
  bank_accounts: ROUTES.RECIPIENTS_BANK_ACCOUNTS
};
