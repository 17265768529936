import React, { useEffect, useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import MainLayout from "src/bepaid/layouts/MainLayout";
import ProfileHeading from "src/bepaid/layouts/MainLayout/components/ProfileHeading";
import ButtonsHeading from "src/bepaid/layouts/MainLayout/components/ButtonsHeading";
import Avatar from "antd/es/avatar";
import AvatarDefault from "src/bepaid/assets/images/dashboard/avatar_default.svg";
import MobileMainButton from "src/bepaid/layouts/MainLayout/components/MobileMainButton";
import Image from "src/bepaid/components/Image";
import styles from "./Users.module.scss";
import classNames from "classnames";
import IconDelete from "src/bepaid/assets/images/common/icon_delete_shadow.svg";
import Button from "src/bepaid/components/Form/controls/Button";
import { FetchModalID } from "../Payments/components/PaymentsList/actions";
import Skeleton from "src/bepaid/components/Skeleton";

const Users: React.FC<any> = props => {
  const { toggleModal, listAccountUser } = props;

  const [loading, setLoading] = useState(false);

  const handleDelete = (userId: number, accountId: number) => {
    toggleModal(FetchModalID.USER_DELETE_MODAL, { userId, accountId });
  };

  const addNewUser = () => {
    toggleModal(FetchModalID.USER_ADD_NEW_MODAL);
  };

  useEffect(() => {
    props.fetchListAccountUser({ loadingFunc: setLoading });
  }, []);

  const renderContent = () => {
    return (
      <div className={styles.contentWrapper}>
        <div className={styles.content}>
          <Skeleton loading={loading}>
            <div className={styles.button}>
              <Button type="secondary" htmlType="button" onClick={addNewUser}>
                Add new user
              </Button>
            </div>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Email</th>
                  <th>Access</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {listAccountUser &&
                  listAccountUser.map((user: any) => (
                    <tr key={user.userId}>
                      <td>{user.firstName}</td>
                      <td>{user.lastName}</td>
                      <td>{user.email}</td>
                      <td>{user.roleId == 4 ? "Admin" : "Member"}</td>
                      <td>
                        {user.roleId != 4 && (
                          <div className={styles.trash}>
                            <Image
                              onClick={() =>
                                handleDelete(user.userId, user.accountId)
                              }
                              src={IconDelete}
                            />
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </Skeleton>
        </div>
      </div>
    );
  };
  const renderDesktopView = () => {
    return (
      <div className={classNames(styles.wrapper, styles.desktop)}>
        <MainLayout
          header={{
            leftHeader: <ProfileHeading />,
            rightHeader: <ButtonsHeading />
          }}
          body={{
            fullBody: renderContent()
          }}
          fullContent
        />
      </div>
    );
  };
  const renderMobileView = () => {
    return (
      <MainLayout
        header={{
          title: "Dashboard",
          rightIcons: <Avatar src={AvatarDefault} />,
          hasBorder: false
        }}
        footer={<MobileMainButton platform={props.platform} />}
      >
        <div className={styles.wrapper}>{renderContent()}</div>
      </MainLayout>
    );
  };
  return (
    <>
      <BrowserView>{renderDesktopView()}</BrowserView>
      <MobileView>{renderMobileView()}</MobileView>
    </>
  );
};

export default Users;
