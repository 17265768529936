import { RouteProps } from "react-router";
import { connect } from "react-redux";
import { RootState } from "src/ipm-shared/store/model/reducers";
import * as selectors from "src/bepaid/store/model/Fetch/selectors";
import * as actions from "src/bepaid/store/model/Fetch/actions";

import Component from "./View";

const mapStateToProps = (state: RootState) => ({
  currencySymbol: selectors.getCurrentCurrencySymbol(state),
  dashboardStatistics: selectors.getDashboardStatistics(state),
  hasToken: selectors.hasToken(state),
  platform: selectors.getPlatform(state),
  isPersonalAccount: selectors.getIsPersonalAccount(state),
  isBusinessAccount: selectors.getIsBusinessAccount(state),
  hasCollectedAccount: selectors.hasCollectedAccount(state),
  hasCollectedAccountFirstStep: selectors.hasCollectedAccountFirstStep(state),
  isFirstFetchDataFetch: selectors.getFirstFetchDataByKey(state)(
    "fetch_dashboard"
  ),
  isFirstFetchDataPay: selectors.getFirstFetchDataByKey(state)("pay_dashboard"),
  isProduction: selectors.getIsProduction(state),
  isNewUser: selectors.isNewUser(state),
  userRequiredSetting: selectors.getUserRequiredSetting(state),
  hasOpenedModal: selectors.hasOpenedModal(state),
  isSingaporeAccount: selectors.isSingaporeAccount(state),
  wallexKycStatus: selectors.getWallexKycStatus(state),
  accountCountryCode: selectors.getAccountCountryCode(state)
  // isKYCModalOpened: selectors.isGivenModalOpened(
  //   state,
  //   actions.FetchModalID.KYC_MODAL
  // )
});

const mapDispatchToProps = {
  getDashboardStatistics: actions.getDashboardStatistics,
  changeUserSetting: actions.changeUserSetting
};

export type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteProps;

export default connect(mapStateToProps, mapDispatchToProps)(Component);
