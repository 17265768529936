import React from "react";
import { isDesktop } from "react-device-detect";
import Skeleton from "antd/lib/skeleton";
import classNames from "classnames";
import _get from "lodash-es/get";
import _isEmpty from "lodash-es/isEmpty";

import { FetchModalID } from "src/ipm-shared/components/GlobalUI/actions";
import { history } from "src/ipm-shared/store";
import ScrollUtil from "src/ipm-shared/Utils/Scroll";

import ROUTES from "src/bepaid/routes";
import ActionDetailDrawer from "src/bepaid/components/ActionDetailDrawer";
import SchedulePayments from "src/bepaid/shared/modals/PaymentDetailModal/components/PaymentDetail/SchedulePayments";

import ContentCollapsed from "./ContentCollapsed";

import styles from "./Schedule.module.scss";

const View = (props: any) => {
  const {
    loading,
    selectedScheduleDetail,
    actionDrawer,
    openActionDrawer
  } = props;
  const schedulePayments = _get(selectedScheduleDetail, "upcomingPayment");
  const paymentDetail = _get(selectedScheduleDetail, "paymentSetting");
  if (!paymentDetail) {
    return null;
  }
  if (!loading && !paymentDetail) {
    return <div>Schedule not found</div>;
  }

  const actionsOnePayment = [
    {
      key: "edit",
      label: "Edit this schedule",
      func: (_: any) => {
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.set("is_schedule", "y");
        localStorage.setItem(
          "paymentEditUrl",
          window.location.pathname + window.location.search
        );
        const url = ROUTES.SCHEDULE_EDIT.replace(
          ":id",
          paymentDetail.scheduleId
        );
        history.push({
          pathname: url,
          search: "?" + urlParams.toString()
        });
        return;
      }
    },
    {
      key: "cancel",
      label: "Cancel this schedule",
      func: (_: any) => {
        props.toggleModal(FetchModalID.CANCEL_PAYMENT_MODAL, {
          scheduleId: paymentDetail.scheduleId,
          isScheduleDetail: "y"
        });
      }
    }
  ];

  const handleToggleOptionForOnePayment = (id: number) => () => {
    openActionDrawer({
      ...actionDrawer,
      value: id,
      visibleDrawer: false,
      visibleOnePaymentDrawer: !actionDrawer.visibleOnePaymentDrawer
    });
  };
  const handleScroll = () => {
    ScrollUtil.scrollTopElementId("modal-body-content");
  };
  const renderInfo = () => {
    return (
      <>
        <ContentCollapsed {...props} />
      </>
    );
  };
  return (
    <div className={classNames(styles.wrapper, styles.wrapperDesktop)}>
      <Skeleton loading={props.loading}>{renderInfo()}</Skeleton>

      {!_isEmpty(paymentDetail) && (
        <>
          <SchedulePayments
            paymentDetail={{
              isActive: _get(selectedScheduleDetail, "isActive", false),
              payments: _get(schedulePayments, "payments", []),
              frequency: _get(
                selectedScheduleDetail,
                "scheduleFrequency",
                "Monthly"
              )
            }}
            currentPaymentId={paymentDetail.id}
            onScroll={handleScroll}
            currencyId={paymentDetail.currencyId}
            isSchedule={"y"}
          />
        </>
      )}

      <ActionDetailDrawer
        contentFullWidth={isDesktop}
        height={"auto"}
        visible={actionDrawer.visibleOnePaymentDrawer}
        data={paymentDetail}
        actions={actionsOnePayment}
        onClose={handleToggleOptionForOnePayment(0)}
        type="invoice"
      />
    </div>
  );
};

export default View;
