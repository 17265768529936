/* tslint:disable:object-literal-sort-keys no-unused-expression */
import React, { useState } from "react";
import { isMobile } from "react-device-detect";
import classNames from "classnames";
import _get from "lodash/get";
import Flex from "antd-mobile/es/flex";
import Checkbox from "antd/es/checkbox";
import Typography from "antd/es/typography";

import Is from "src/ipm-shared/Utils/Is";
import Country from "src/ipm-shared/Utils/Country";
import {
  ACCOUNT_TYPE,
  PAYMENT_INTEREST
} from "src/ipm-shared/store/model/AccountProfile/const";
import T from "src/ipm-shared/Utils/Intl";

import ROUTES from "src/bepaid/routes";
import useQuery from "src/bepaid/hooks/useQuery";
import Form from "src/bepaid/components/Form";
import InputText from "src/bepaid/components/Form/controls/InputText";
import Select from "src/bepaid/components/Form/controls/Select";
import Button from "src/bepaid/components/Form/controls/Button";
import Image from "src/bepaid/components/Image";
import { REGISTER_FORM } from "src/bepaid/store/model/FetchPreStart/const";

import IconArrowDown from "src/bepaid/assets/images/common/drop_down_arrow_grey.svg";

import styles from "./SignUp.module.scss";

const initialValues = { email: "", mobileNumber: "", password: "" };

const mobileCountryCodeOptions = Country.getMobileCountriesCodeOptions([
  "AU",
  "HK",
  "MY",
  "SG"
]);
const countryCodeOptions = Country.getCountriesCodeOptions([
  "AU",
  "HK",
  "MY",
  "SG"
]);
const accountTypeOptions = [
  {
    label: "Business - business payments only",
    value: ACCOUNT_TYPE.BUSINESS
  },
  {
    label: "Personal - personal payments only",
    value: ACCOUNT_TYPE.PERSONAL
  }
];

const paymentInterestOptions = [
  {
    label: "Pay - Pay expenses with an existing credit card",
    value: PAYMENT_INTEREST.PAY
  },
  {
    label: "Fetch - Send invoices for free and accept credit card payments",
    value: PAYMENT_INTEREST.BE_PAID
  },
  {
    label: "Pay and Fetch",
    value: PAYMENT_INTEREST.BOTH
  }
];

const FormComponent = (props: any) => {
  const { getControl, register, isLoading } = props;

  const qs = useQuery();

  // @ts-ignore
  const [passwordRuleError, setPasswordRuleError] = React.useState(false);
  const [confirmation, setConfirmation] = useState(false);

  const getDefaultCountryCode = (): string => {
    let defaultCountry: string = "SG";
    if (Is.AUVisitor()) {
      defaultCountry = "AU";
    } else if (Is.MYVisitor()) {
      defaultCountry = "MY";
    } else if (Is.HKVisitor()) {
      defaultCountry = "HK";
    }

    return defaultCountry;
  };

  const renderLabel = (flag: any, label: string) => (
    <Flex align="center">
      <Image src={flag} style={{ width: "32px" }} />
      <Typography.Text style={{ marginLeft: "0.5rem" }}>
        {label}
      </Typography.Text>
    </Flex>
  );

  const getMobileCountryCodeOptions = () => {
    return mobileCountryCodeOptions.map(
      ({ code, flag, label, value }: any) => ({
        isDefaultValue: getDefaultCountryCode() === code,
        label,
        value
      })
    );
  };

  const getCountryOptions = () => {
    return countryCodeOptions.map(({ code, flag, label, value }: any) => ({
      isDefaultValue: getDefaultCountryCode() === code,
      label: renderLabel(flag, label),
      value
    }));
  };

  const getMobileCountryCodeDefaultValue = () => {
    return _get(
      getMobileCountryCodeOptions().find(
        (c: any) => c.value === _get(qs, "country", "") || c.isDefaultValue
      ),
      "value",
      mobileCountryCodeOptions[0].value
    );
  };

  const getCountryDefaultValue = () => {
    return _get(
      getCountryOptions().find(
        (c: any) => c.value === _get(qs, "country", "") || c.isDefaultValue
      ),
      "value",
      getCountryOptions()[0].value
    );
  };

  const [isShowRecaptcha, setShowRecaptcha] = useState(false);
  const [recaptchaV2, setRecaptchaV2] = useState(null);

  const onChangeCountryCode = (code: string) => {
    props.setControl({
      name: "mobile_country_code",
      form: REGISTER_FORM,
      value: code
    });
  };

  const onFinish = (values: any) => {
    confirmation &&
      !isLoading &&
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute("6LcLrp4UAAAAANE0GZb5OySN8G5-FB8yOGoDEqoi", {
            action: "homepage"
          })
          .then((token: string) => {
            register({
              recaptchaToken: token,
              recaptchaVersion: "v3",
              cb: (err: any, data: any) => {
                if (err && err === "You are not verified.") {
                  if (recaptchaV2 != null) {
                    window.grecaptcha.reset(recaptchaV2);
                  }
                  const container = document.getElementById("v2_container");
                  if (container) {
                    setShowRecaptcha(true);
                    setRecaptchaV2(
                      window.grecaptcha.render(container, {
                        callback: (tokenV2: string) => {
                          register({
                            recaptchaToken: tokenV2,
                            recaptchaVersion: "v2"
                          });
                        },
                        sitekey: "6Ld4eu4ZAAAAAKZIGaWjjoBlrh_Bqmwv99-QAWFN"
                      })
                    );
                  }
                }
              }
            });
          });
      });
  };

  const ContinueToLogin = () => {
    return (
      <Form.Item className={styles.continueToLoginSection}>
        <Typography.Text className={styles.continueToLoginLinkText}>
          If it’s yours, please{" "}
          <Typography.Link href={ROUTES.LOGIN}>
            continue to the sign-in page.
          </Typography.Link>
        </Typography.Text>
      </Form.Item>
    );
  };

  const emailErrorCode = _get(getControl("email"), "errors[0].code");
  const inviteCode = _get(qs, "aur", undefined);

  // @ts-ignore
  return (
    <Form
      name="register-form"
      layout="vertical"
      initialValues={initialValues}
      requiredMark={false}
      onFinish={onFinish}
      className={styles.form}
    >
      <div
        className={classNames(styles.itemFormName, {
          [styles.stackFormName]: isMobile
        })}
      >
        <InputText
          label="First name"
          name="first_name"
          form={REGISTER_FORM}
          placeholder="First name"
          className={classNames({ [styles.itemName]: !isMobile })}
          defaultValue={_get(qs, "first_name", "")}
        />

        <InputText
          label="Last name"
          name="last_name"
          form={REGISTER_FORM}
          placeholder="Last name"
          className={classNames({ [styles.itemName]: !isMobile })}
          defaultValue={_get(qs, "last_name", "")}
        />
      </div>

      <InputText
        label="Email"
        name="email"
        pattern="__EMAIL__"
        form={REGISTER_FORM}
        placeholder="Email"
        // className={styles.itemEmail}
        defaultValue={_get(qs, "email", "")}
      />

      {emailErrorCode === "EMAIL_ALREADY_EXISTS" && <ContinueToLogin />}

      <InputText
        label="Password"
        name="password"
        form={REGISTER_FORM}
        type="password"
        placeholder="Password"
        // className={styles.itemPassword}
      />

      {!passwordRuleError && (
        <div className={styles.passwordRuleSection}>
          <Typography.Text className={styles.passwordRuleText}>
            At least 8 characters consisting of at least one letter, one number
            and one symbol
          </Typography.Text>
        </div>
      )}

      {!inviteCode && (
        <>
          <div>
            <div className={styles.itemFormCountry}>
              <Select
                label="Country"
                name="country_code"
                form={REGISTER_FORM}
                options={getCountryOptions()}
                defaultValue={getCountryDefaultValue()}
                formItemClassName={styles.countrySelect}
                onChange={onChangeCountryCode}
                suffixIcon={<Image src={IconArrowDown} />}
              />

              <div className={styles.itemMobileNumber}>
                <InputText
                  prefix={
                    <Select
                      name="mobile_country_code"
                      form={REGISTER_FORM}
                      options={getMobileCountryCodeOptions()}
                      defaultValue={getMobileCountryCodeDefaultValue()}
                      formItemClassName={styles.phoneCodeSelection}
                      value={_get(
                        getControl("mobile_country_code"),
                        "value",
                        "SG"
                      )}
                      suffixIcon={<Image src={IconArrowDown} />}
                    />
                  }
                  label="Mobile number"
                  name="mobile"
                  form={REGISTER_FORM}
                  placeholder="Mobile number"
                  className={styles.phoneNumberText}
                  defaultValue={_get(qs, "mobile", "")}
                />
              </div>
            </div>
          </div>

          <div className={styles.accountTypeSelect}>
            <Select
              label="Which account type would you like to sign up for?"
              name="account_type"
              form={REGISTER_FORM}
              options={accountTypeOptions}
              defaultValue={accountTypeOptions[0].value}
              suffixIcon={<Image src={IconArrowDown} />}
            />
          </div>

          {_get(getControl("account_type"), "value") ===
            ACCOUNT_TYPE.BUSINESS && (
            <div className={styles.paymentInterestSelect}>
              <Select
                label="What is your main purpose for using ipaymy?"
                name="payment_interest"
                form={REGISTER_FORM}
                options={paymentInterestOptions}
                placeholder="Select your main purpose"
                // defaultValue={paymentInterestOptions[0].value}
                suffixIcon={<Image src={IconArrowDown} />}
              />
            </div>
          )}
        </>
      )}

      <div
        id="v2_container"
        className={classNames(styles.hidden, {
          [styles.showRecaptcha]: isShowRecaptcha
        })}
      />

      <div hidden={true}>
        <InputText
          name="referral_code"
          placeholder={T.transl("REFERRAL_CODE_PLACEHOLDER")}
          form={REGISTER_FORM}
          required={false}
          autoComplete="nope"
          deboundBrowerAutocomplete={true}
          requiredMessage={T.transl("REFERRAL_CODE_ERROR")}
          defaultValue={_get(qs, "mwr", "")}
        />
      </div>

      <Form.Item className={styles.centerText}>
        <Checkbox
          name="confirmation"
          checked={confirmation}
          onChange={() => setConfirmation(!confirmation)}
        >
          <Typography.Text className={styles.agreementText}>
            By continuing you agree to our{isMobile ? <br /> : " "}
            <Typography.Link
              href="https://ipaymy.com/terms-conditions"
              target="_blank"
            >
              Terms and Conditions
            </Typography.Link>{" "}
            and{" "}
            <Typography.Link
              href="https://ipaymy.com/privacy-policy"
              target="_blank"
            >
              Privacy Policy
            </Typography.Link>
            .
          </Typography.Text>
        </Checkbox>
      </Form.Item>
      <Form.Item>
        <Button
          id={"ipaymy_btn_signup"}
          type="primary"
          htmlType="submit"
          loading={isLoading || !confirmation}
        >
          {isLoading ? "Signing up..." : "Sign up"}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default FormComponent;
