export const MY_INFO_MODES = {
  initial: 0,
  login: 1,
  loginBusiness: 2,
  success: 3
};

export const MY_INFO_RESULTS = {
  success: "1",
  failed: "0"
};

export const MY_INFO_VALID = {
  success: "1",
  failed: "0"
};
