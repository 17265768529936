// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SchedulePaymentModal_wrapper__-lKaz{max-width:400px;margin:auto}.SchedulePaymentModal_wrapper__-lKaz .SchedulePaymentModal_textBlack__2OZVF{color:#000}.SchedulePaymentModal_wrapper__-lKaz .SchedulePaymentModal_listContainer__2l-1q{margin:2.5rem 0}.SchedulePaymentModal_wrapper__-lKaz .SchedulePaymentModal_beneficiaryStatement__bJJtG{margin-bottom:1rem}.SchedulePaymentModal_wrapper__-lKaz .SchedulePaymentModal_beneficiaryStatement__bJJtG .SchedulePaymentModal_listPayoutDates__j7dcB{padding-left:2rem;margin:0}", "",{"version":3,"sources":["webpack://./src/bepaid/shared/modals/PaymentsCreationModal/components/SchedulePayment/SchedulePaymentModal.module.scss"],"names":[],"mappings":"AAAA,qCAAS,eAAe,CAAC,WAAW,CAAC,4EAAoB,UAAU,CAAC,gFAAwB,eAAe,CAAC,uFAA+B,kBAAkB,CAAC,oIAAgD,iBAAiB,CAAC,QAAQ","sourcesContent":[".wrapper{max-width:400px;margin:auto}.wrapper .textBlack{color:#000}.wrapper .listContainer{margin:2.5rem 0}.wrapper .beneficiaryStatement{margin-bottom:1rem}.wrapper .beneficiaryStatement .listPayoutDates{padding-left:2rem;margin:0}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "SchedulePaymentModal_wrapper__-lKaz",
	"textBlack": "SchedulePaymentModal_textBlack__2OZVF",
	"listContainer": "SchedulePaymentModal_listContainer__2l-1q",
	"beneficiaryStatement": "SchedulePaymentModal_beneficiaryStatement__bJJtG",
	"listPayoutDates": "SchedulePaymentModal_listPayoutDates__j7dcB"
};
export default ___CSS_LOADER_EXPORT___;
