import { getType } from "typesafe-actions";
import * as types from "./types";
import * as actions from "./actions";
import _isEmpty from "lodash-es/isEmpty";

export type KycMetaData = {
  isMain: boolean;
  userDetail: {
    residentialStatus: "citizen" | "foreigner" | "permanent_resident";
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    gender: "male" | "female";
    identificationType: "nric" | "passport";
    identificationNumber: string;
    issueDate: string;
    expiryDate: string;
    residentialAddress: string;
    unitNumber: string;
    postalCode: string;
    employeeStatus: number;
    employeePosition: number;
    employeeIndustry: number;
    mobileCountryCode: string;
    mobileNumber: number;
    countryOfBirth: string;
    nationality: string;
    countryOfResidence: string;
    countryCode: string;
  };
  kycDocuments: {
    frontNric: string[];
    backNric: string[];
    holdingNricPhoto: string[];
    frontPassport: string[];
    holdingPassportPhoto: string[];
    poa: string[];
    bLetterOfAuthorization: string[];
    bAcraBizfile: string[];
    // bPoba: string[];
    bBoardResolution: string[];
    bStructuredChart: string[];
  };
  isDirectorShareHolder: boolean;
};

export type KycData = {
  step: number;
  metadata: KycMetaData[];
};

export type onBoardingKycState = {
  readonly kycData: KycData;
  readonly isFetching: boolean;
  readonly residentialAddress: string;
  readonly employeeData: {
    position: { [id: number]: string };
    status: { [id: number]: string };
    industry: { [id: number]: string };
  };
  readonly hasFetched?: boolean;
};

const defaultState: onBoardingKycState = {
  employeeData: {
    industry: {},
    position: {},
    status: {}
  },
  hasFetched: false,
  isFetching: false,
  kycData: {
    metadata: [],
    step: 0
  },
  residentialAddress: ""
};

export default (state: onBoardingKycState, action: types.Action) => {
  if (_isEmpty(state)) {
    state = defaultState;
  }

  switch (action.type) {
    case getType(actions.setWallexKycData):
      return {
        ...state,
        hasFetched: action.payload.hasFetched,
        kycData: action.payload.kycData
      };
    case getType(actions.setEmployeeStuff):
      return {
        ...state,
        employeeData: {
          ...state.employeeData,
          [action.payload.type]: action.payload.employeeData
        }
      };

    case getType(actions.setResidentialAddress):
      return {
        ...state,
        residentialAddress: action.payload.residentialAddress
      };
  }
  return state;
};
