import * as React from "react";
import BaseCardFormView, { IBaseProps, IBaseState } from "../lib/BaseCardForm";
import { CardInfo } from "src/ipm-shared/store/model/Card/types";
import CardUtil from "src/ipm-shared/Utils/Card";
import InputText from "src/ipm-shared/components/Form/controls/InputText";
import T from "src/ipm-shared/Utils/Intl";
import FormErrors from "src/ipm-shared/components/Form/helpers/Errors";
import { ADD_CARD_FORM } from "src/ipm-shared/store/model/Card/const";
import { CardFormVersion } from "../type";
import { CvvSurffix } from "../components/CvvSurffix";
import RestClient from "src/ipm-shared/services/Rest";
import _get from "lodash-es/get";
import _isEmpty from "lodash-es/isEmpty";
import { BRAND } from "src/ipm-shared/Utils/Images";
// import { CardNumberSurffix } from "../components/CardNumberSurffix";

interface IProps extends IBaseProps {
  selectedCardBrand?: number;
}

type IState = IBaseState & {
  cardNumberReady?: boolean;
  cardExpiryDateReady?: boolean;
  cardCvcReady?: boolean;
  cardLast4: string;
  cardBin: string;
  additionalCardBin: string;
  cardExpiryMonth: number;
  cardExpiryYear: number;
  cardBrand: string;
  cardIssuerCountry: string;
  isAmex: boolean;
};

class WorldpayCardForm extends BaseCardFormView<IProps, IState> {
  private unmounted: boolean;
  constructor(props: IProps) {
    super(props);
    this.state = {
      cardFieldFocus: "",
      errors: {
        cardNumber: ""
      },
      flipped: false,
      readyToShowForm: false,
      cardCvcReady: true,
      cardLast4: "",
      cardBin: "",
      additionalCardBin: "",
      cardExpiryMonth: 0,
      cardExpiryYear: 0,
      cardBrand: "",
      cardIssuerCountry: "",
      isAmex: this.props.selectedCardBrand === BRAND.AMEX
    };
  }

  // Used in wrapper
  public createTmpCard = async (
    paymentToken: string
  ): Promise<
    | undefined
    | {
        cardInfo: CardInfo;
        cardholderName?: string;
      }
  > => {
    return this.retokenize().then(async (data: any) => {
      const res = await RestClient.send({
        service: "wp_create_tmp_card",
        query: {
          pt: paymentToken
        },
        body: {
          card_token: _get(data, "cardInfo.cardToken", ""),
          card_holder_name: this.state.cardHolderName,
          acquirer_id: this.props.acquirerId
        }
      });
      if (!res) {
        return undefined;
      }
      const errors = _get(res, "errors", {});

      if (!_isEmpty(errors)) {
        window.Logger.error("createTmpCard: ", errors);
        return errors;
      }
      this.setState({
        ...this.state,
        cardBrand: _get(res, "data.card_brand", ""),
        cardIssuerCountry: _get(res, "data.issuer_country_code", "")
      });
      return {
        cardInfo: {
          cardBin: this.state.cardBin
            ? this.state.cardBin
            : _get(res, "data.bin", ""),
          additionalCardBin: this.state.cardBin,
          cardBrand: _get(res, "data.card_brand", ""),
          cardExpiryMonth: this.state.cardExpiryMonth,
          cardExpiryYear: this.state.cardExpiryYear,
          cardIssuerCountry: _get(res, "data.issuer_country_code", ""),
          cardLast4: this.state.cardLast4
            ? this.state.cardLast4
            : _get(res, "data.last4", ""),
          cardToken: _get(data, "cardInfo.cardToken", ""),
          cardTokenType: "wp_cse_data"
        },
        cardholderName: this.state.cardHolderName
      };
    });
  };

  // Used in wrapper
  public retokenize = async (): Promise<
    | undefined
    | {
        cardInfo: CardInfo;
        cardholderName?: string;
      }
  > => {
    try {
      if (
        !this.state.cardCvcReady ||
        !this.state.cardNumberReady ||
        !this.state.cardExpiryDateReady ||
        this.state.cardHolderName === ""
      ) {
        return undefined;
      }

      const encryptedData = this.encryptCard();

      return {
        cardInfo: {
          cardBin: this.state.cardBin,
          additionalCardBin: this.state.additionalCardBin,
          cardBrand: this.state.cardBrand,
          cardExpiryMonth: this.state.cardExpiryMonth,
          cardExpiryYear: this.state.cardExpiryYear,
          cardIssuerCountry: this.state.cardIssuerCountry,
          cardLast4: this.state.cardLast4,
          cardToken: encryptedData,
          cardTokenType: "wp_cse_data"
        },
        cardholderName: this.state.cardHolderName
      };
    } catch (e) {
      console.error(e);
    }

    return undefined;
  };

  public async componentDidMount() {
    super.componentDidMount();

    if (!window.WorldpayScriptAdded) {
      const script = document.createElement("script");

      script.src =
        "https://secure.worldpay.com/resources/cse/js/worldpay-cse-1.latest.min.js";
      script.async = true;
      script.onload = () => {
        window.WorldpayScriptLoaded = true;
        if (!this.unmounted) {
          this.setState(
            {
              readyToShowForm: true
            },
            () => {
              this.initWP();
            }
          );
        }
      };
      window.WorldpayScriptAdded = true;
      document.body.appendChild(script);
    } else {
      if (!window.WorldpayScriptLoaded) {
        let interval: number;
        interval = window.setInterval(() => {
          if (window.WorldpayScriptLoaded) {
            this.setState(
              {
                readyToShowForm: true
              },
              this.initWP
            );
            clearInterval(interval);
          }
        }, 300);
      } else {
        this.setState(
          {
            readyToShowForm: true
          },
          this.initWP
        );
      }
    }
  }

  public render() {
    const { cardFormVersion = CardFormVersion.LEGACY } = this.props;
    const isBepaid = cardFormVersion === CardFormVersion.BEPAID;
    const placeHolderByFormVersion = {
      [CardFormVersion.BEPAID]: {
        cvv: "CVC/CVV",
        expiryDay: "MM/YY",
        cardNumber: {
          [BRAND.VISA]: "4111 1111 1111 1111",
          [BRAND.MASTER_CARD]: "5111 1111 1111 1111",
          [BRAND.UNION_PAY]: "6211 1111 1111 1111",
          [BRAND.AMEX]: "3111 111111 11111"
        }
      }
    };

    return (
      <>
        <form className="WorldPayCardForm" data-worldpay="payment-form">
          <div>
            {super.renderCardholderName(cardFormVersion)}
            <div className="form-group form-card-number">
              <span className="label">{T.transl("CARD_NUMBER_LABEL")}</span>
              <div className="surffix-container">
                <InputText
                  name="wp_card_number"
                  pattern={this.state.isAmex ? "__CARD_AMEX__" : "__CARD__"}
                  id="wp_card_number"
                  placeholder={
                    this.props.selectedCardBrand
                      ? placeHolderByFormVersion[cardFormVersion].cardNumber[
                          this.props.selectedCardBrand || BRAND.VISA
                        ]
                      : T.transl("CARD_NUMBER_LABEL")
                  }
                  onChangeCustom={this.onCardInfoChange.bind(
                    this,
                    "wp_card_number"
                  )}
                  maxLength={this.state.isAmex ? 17 : 19}
                />
                {/* {isBepaid && (
                  <CardNumberSurffix
                    supportedCards={["visa", "mastercard", "amex"]}
                    // selecetedPaymentMethod={
                    //   selectedPaymentMethod as SurffixPaymentMethod
                    // }
                  />
                )} */}
              </div>
            </div>
            <div className="form-group w-100">
              <span className="label">{T.transl("CARD_EXPIRY_LABEL")}</span>
              <input hidden={true} id="wp_card_exp_month" />
              <input hidden={true} id="wp_card_exp_year" />
              <InputText
                name="wp_card_exp_date"
                pattern="__CARD_EXPIRY_DATE__"
                id="wp_card_exp_date"
                placeholder={
                  placeHolderByFormVersion[cardFormVersion].expiryDay
                }
                maxLength={5}
                onChangeCustom={this.onCardInfoChange.bind(
                  this,
                  "wp_card_expiry_date"
                )}
              />
            </div>
            <div className="form-group cvc-form form-cvc-cvv">
              <span className="label">{T.transl("CARD_CVC_LABEL")}</span>
              <div className="surffix-container">
                <InputText
                  name="wp_card_cvv"
                  pattern="__CARD_CVV__"
                  id="wp_card_cvv"
                  placeholder={placeHolderByFormVersion[cardFormVersion].cvv}
                  maxLength={4}
                  onChangeCustom={this.onCardInfoChange.bind(
                    this,
                    "wp_card_cvv"
                  )}
                />
                {isBepaid && <CvvSurffix />}
              </div>
              {!isBepaid && (
                <>
                  <span className="question-icon" />
                  <div className="question-content">
                    {T.transl("LABEL_CARD_CVC_INSTRUCTION")}
                  </div>
                </>
              )}
            </div>
            <FormErrors form={ADD_CARD_FORM} className="fz-12 mb-3" />
          </div>
        </form>
      </>
    );
  }

  private initWP = () => {
    window.Worldpay.setPublicKey(
      CardUtil.getWorldpayPublicKey(this.props.acquirerId)
    );
  };

  private onCardInfoChange = (
    field: "wp_card_number" | "wp_card_expiry_date" | "wp_card_cvv",
    value: string
  ) => {
    const cardReady = {
      cardNumberReady: this.state.cardNumberReady,
      cardCvcReady: this.state.cardCvcReady,
      cardExpiryDateReady: this.state.cardExpiryDateReady,
      cardLast4: this.state.cardLast4,
      cardBin: this.state.cardBin,
      additionalCardBin: this.state.additionalCardBin,
      cardExpiryMonth: this.state.cardExpiryMonth,
      cardExpiryYear: this.state.cardExpiryYear
    };

    switch (field) {
      case "wp_card_number":
        const trimValue = value.replace(/ /g, "");
        cardReady.cardLast4 = trimValue.slice(
          trimValue.length - 4,
          trimValue.length
        );
        if (trimValue.length > 6) {
          cardReady.cardBin = trimValue.slice(0, 6);
          if (trimValue.length > 9) {
            cardReady.additionalCardBin = trimValue.slice(6, 9);
          }
        } else {
          cardReady.cardBin = "";
          cardReady.additionalCardBin = "";
        }
        cardReady.cardNumberReady =
          (this.state.isAmex
            ? trimValue.length === 15 || trimValue === "34343434343434"
            : trimValue.length === 16) &&
          Boolean(trimValue) &&
          Boolean(cardReady.cardLast4) &&
          Boolean(cardReady.cardBin);
        break;
      case "wp_card_cvv":
        cardReady.cardCvcReady = Boolean(
          value?.length === 3 || value?.length === 4
        );
        break;
      case "wp_card_expiry_date":
        const [month, year] = value.split("/");
        cardReady.cardExpiryMonth = Number(month);
        cardReady.cardExpiryYear = Number(year);
        (document.getElementById(
          "wp_card_exp_month"
        ) as HTMLInputElement).value = month;
        (document.getElementById(
          "wp_card_exp_year"
        ) as HTMLInputElement).value = year;

        cardReady.cardExpiryDateReady = Boolean(year) && Boolean(month);
        break;
    }

    this.setState(
      {
        ...cardReady,
        errors: {
          cardNumber: ""
        }
      },
      async () => {
        if (
          !this.state.cardCvcReady ||
          !this.state.cardNumberReady ||
          !this.state.cardExpiryDateReady ||
          this.state.cardHolderName === ""
        ) {
          if (this.props.onCardTokenizationCleared)
            this.props.onCardTokenizationCleared();
          return;
        }

        const encryptedData = this.encryptCard();
        if (!encryptedData) {
          return;
        }

        this.onTokenized({
          cardBin: this.state.cardBin,
          additionalCardBin: this.state.additionalCardBin,
          cardBrand: this.state.cardBrand,
          cardExpiryMonth: this.state.cardExpiryMonth,
          cardExpiryYear: this.state.cardExpiryYear,
          cardIssuerCountry: this.state.cardIssuerCountry,
          cardLast4: this.state.cardLast4,
          cardToken: encryptedData,
          cardTokenType: "wp_cse_data"
        });
      }
    );
  };

  private errorHandler = (args: any) => {
    // https://developer.worldpay.com/docs/wpg/troubleshoot/clientsideencryptionerrors#error-handler-codes
    const errString = T.transl(`WP_CSE_ERROR_${args[0]}`);
    const err = new Error(errString);
    this.onCardTokenizationFailed(err);
  };

  private encryptCard = () => {
    const sensitiveData = {
      cardNumber: (document.getElementById(
        "wp_card_number"
      ) as HTMLInputElement)?.value,
      cvc: (document.getElementById("wp_card_cvv") as HTMLInputElement)?.value,
      expiryMonth: (document.getElementById(
        "wp_card_exp_month"
      ) as HTMLInputElement)?.value,
      expiryYear: (document.getElementById(
        "wp_card_exp_year"
      ) as HTMLInputElement)?.value,
      cardHolderName: this.state.cardHolderName
    };
    sensitiveData.cardNumber = sensitiveData.cardNumber.replace(/ /g, "");
    if (sensitiveData.expiryYear.length === 2) {
      sensitiveData.expiryYear = "20" + sensitiveData.expiryYear;
    }
    // tslint:disable-next-line:forin
    for (const key in sensitiveData) {
      let value = sensitiveData[key];
      if (!value) {
        value = "";
      }
    }
    const encryptedData = window.Worldpay.encrypt(
      sensitiveData,
      this.errorHandler
    );
    if (!encryptedData) {
      return undefined;
    }

    return encryptedData;
  };
}

export default WorldpayCardForm;
