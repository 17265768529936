/**
 * These code will be postfixed to bank name in bank drop-down list
 * @type {string[]}
 */
export const SWIFT_CODE_LABEL_LIST = [
  "HSBCSGS2XXX",
  "HSBCSGSGXXX",
  "MBBESGSGXXX",
  "MBBESGS2XXX",
  "CITISGSGXXX",
  "CITISGSLXXX",
  "SCBLSGSGXXX",
  "SCBLSG22XXX"
];
