import React, { useCallback, useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";

import { FetchModalID } from "src/ipm-shared/components/GlobalUI/actions";
import { ADD_CARD_FORM } from "src/ipm-shared/store/model/Card/const";

import MainLayout from "src/bepaid/layouts/MainLayout";
import DesktopModalLayout from "src/bepaid/components/Modal/components/DesktopModalLayout";
import AddNewCard from "src/bepaid/layouts/Menu/components/AddNewCard";

import styles from "./AddNewCardModal.module.scss";
import _get from "lodash-es/get";

const View = (props: any) => {
  const [btnSubmit, setBtnSubmit] = useState(null);

  const closeModal = () => {
    props.toggleModal(FetchModalID.ADD_NEW_CARD_MODAL);
  };

  const handleFavouriteAction = () => {
    props.setControl({
      form: ADD_CARD_FORM,
      name: "is_favourite_card",
      value: !props.isFavouriteCard
    });
  };

  const renderSubmitButton = useCallback((btn: any) => {
    setBtnSubmit(btn);
  }, []);

  const renderContent = () => {
    return (
      <AddNewCard
        onSaved={closeModal}
        renderSubmitButton={renderSubmitButton}
        extraInfo={props.extraInfo}
      />
    );
  };

  return (
    <>
      <BrowserView>
        <DesktopModalLayout
          maxWidth450={true}
          title={"Add new card"}
          onClose={closeModal}
          btnSubmit={btnSubmit}
          isFavourite={!!props.isFavouriteCard}
          onFavouriteAction={handleFavouriteAction}
        >
          <div className={styles.wrapper}>{renderContent()}</div>
        </DesktopModalLayout>
      </BrowserView>

      <MobileView>
        <MainLayout
          header={{
            title: "Add new card",
            onClose: closeModal,
            hideMenu: true,
            isFavourite: !!props.isFavouriteCard,
            onFavouriteAction: handleFavouriteAction
          }}
          footer={btnSubmit}
        >
          <div className={styles.wrapper}>{renderContent()}</div>
        </MainLayout>
      </MobileView>
    </>
  );
};

export default View;
