import { match, matchPath } from "react-router";
import { FetchModalID } from "src/ipm-shared/components/GlobalUI/actions";
import * as uuidv1 from "uuid/v1";
import findLast from "lodash-es/findLast";

type MenuKey = string | FetchModalID;

export interface IMenu {
  title: string;
  onClick?: (param?: MenuKey | any) => void;
  path?: string;
  modalId?: string | number;
  subMenus?: IMenu[];
  key?: string;
}

export const getMenuSelectedKeys = (
  menuKeys: MenuKey[],
  openedModalIds: FetchModalID[],
  match: match
): string[] => {
  if (openedModalIds) {
    const key = findLast(openedModalIds, id => {
      return menuKeys.includes(id);
    });

    if (key) return [String(key)];
  }

  const key = menuKeys.find(k => {
    if (typeof k != "string") return;
    return matchPath(k, match.url);
  });

  if (key) return [String(key)];
  return [];
};

export const getMenuKey = ({ path, modalId }: IMenu) =>
  path || modalId || uuidv1();

export const createModifiedOnClick = (menu: IMenu) => {
  const { path, modalId, onClick } = menu;

  const newOnClick = (e: any) => {
    if (!onClick) return;

    if (path) onClick(path);
    else if (modalId) onClick(modalId);
    else onClick();
  };
  return newOnClick;
};

/**
 * assign uuid_v1 for single tier menu
 * if contain sub-menu, follow pattern
 *   - sub-2
 *      - sub-2-1
 * call onClick with either
 *   - path
 *   - modalId
 */
export const createDesktopMenuMaps = (menus: IMenu[]) => {
  const menuKeys: (string | FetchModalID)[] = [];

  const transformedMenus = menus.map((menu, menuIndex) => {
    if (!menu.subMenus) {
      const menuKey = getMenuKey(menu);
      menuKeys.push(menuKey);

      return {
        ...menu,
        key: menuKey,
        onClick: createModifiedOnClick(menu)
      };
    }

    return {
      ...menu,
      id: `sub-${menuIndex}`,
      subMenus: menu.subMenus?.map(subMenu => {
        const subMenuKey = getMenuKey(subMenu);

        menuKeys.push(subMenuKey);

        return {
          ...subMenu,
          key: subMenuKey,
          onClick: createModifiedOnClick(subMenu)
        };
      })
    };
  });

  return {
    transformedMenus: transformedMenus as IMenu[],
    menuKeys
  };
};
