export const PATTERN = {
  bank_account_number: "__BANK_ACCOUNT_NUMBER__",
  card: "__CARD__",
  email: "__EMAIL__",
  global_bank_account_number: "__GLOBAL_BANK_ACCOUNT_NUMBER__",
  mobile_number: "__MOBILE_NUMBER__",
  money: "__MONEY__",
  number: "__NUMBER__",
  only_alphabets: "__ONLY_ALPHABETS__",
  password: "__PASSWORD",
  percentage: "__PERCENTAGE__",
  recipient_name: "__RECIPIENT_NAME__",
  sms_name: "__SMS_NAME__",
  statement: "__STATEMENT__"
};

export function getPattern(pattern: string): RegExp | undefined {
  switch (pattern) {
    case PATTERN.number:
      return /^[0-9]*$/;
    case PATTERN.card:
      return /^[0-9 ]*$/;
    case PATTERN.money:
      // I would like a solution where I can validate "after" user has finished typing.
      // e.g. "100." is incomplete and invalid, but I need to type this to get to "100.00"
      return /^([0-9]+\.?([0-9]{1,2})?)?$/;
    case PATTERN.bank_account_number:
      return /^[0-9-]*$/;
    case PATTERN.global_bank_account_number:
      return /^[A-Za-z0-9]*$/;
    case PATTERN.statement:
      return /^[A-Za-z0-9- ]*$/;
    case PATTERN.sms_name:
      return /^[A-Za-z0-9 ]*$/;
    case PATTERN.only_alphabets:
      return /^[A-Za-z ]*$/;
    case PATTERN.percentage:
      return /^([0-9]{1,2}\.?([0-9]{1,2})?)?$/;
    case PATTERN.recipient_name:
      return /^[a-zA-Z0-9()+\-\?'/:,. ]*$/;
    case PATTERN.email:
      return /(^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))+@ipaymy.com$)|(^(([^+<>()\[\]\\.,;:\s@"]+(\.[^+<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$)/;
    case PATTERN.password:
      return /^(?=.*[A-Za-z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]{8,}$/;
    case PATTERN.mobile_number:
      return /^[0-9]{8,12}|none$/;

    default:
      return undefined;
  }
}

export function isValidatePattern(value: string, patternName: string): boolean {
  const pattern = getPattern(patternName);

  if (pattern) {
    return pattern.test(value);
  }

  return false;
}

export function transformTextFirstUppercase(txt: string, split: string = "_") {
  const listWords = txt.split(split).join(" ");
  return `${listWords.charAt(0).toUpperCase()}${listWords.slice(1)}`;
}
