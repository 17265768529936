import React from "react";
import { BrowserView, MobileView, isDesktop } from "react-device-detect";
import _get from "lodash-es/get";
import * as uuidv1 from "uuid/v1";
import classNames from "classnames";
import Typography from "antd/es/typography";
import * as queryString from "query-string";

import { history } from "src/ipm-shared/store";
import { FetchModalID } from "src/ipm-shared/components/GlobalUI/actions";
import T from "src/ipm-shared/Utils/Intl";

import Form from "src/bepaid/components/Form";
import InputText from "src/bepaid/components/Form/controls/InputText";
import Button from "src/bepaid/components/Form/controls/Button";
import FormGroup from "src/bepaid/components/FormGroup";
import { invokeMessage } from "src/bepaid/components/Message";
import DesktopModalLayout from "src/bepaid/components/Modal/components/DesktopModalLayout";
import MainLayout from "src/bepaid/layouts/MainLayout";
import { MY_INFO_MODES } from "../../const";
import _isEmpty from "lodash-es/isEmpty";

import styles from "./RetrievedIdentityBusiness.module.scss";
import ROUTES from "src/bepaid/routes";

const RetrievedIdentityBusiness: React.FC<any> = props => {
  const {
    redirectUrl,
    residentialAddress,
    fetchMyInfoSingPass,
    confirmMyInfoSingPass,
    getAddressByPostalCode,
    toggleModal,
    openModal,
    onClose,
    closeModal,
    setControl
  } = props;

  const [loading, setLoading] = React.useState(false);
  const [myInfoData, setMyInfoData] = React.useState({
    entityName: "",
    entityType: "",
    entityStatus: "",
    businessConstitution: "",
    companyType: "",
    uen: "",
    countryOfIncorporation: "",
    ownership: "",
    registrationDate: "",
    businessExpiryDate: "",
    primaryActivityCode: "",
    primaryActivityDescription: "",
    secondaryActivityCode: "",
    secondaryActivityDescription: "",
    standardAddress: "",
    address: "",
    previousNames: "",
    previousUENs: "",
    shareholdersList: "",
    appointmentsList: "",
    nric: "",
    principalName: "",
    aliasName: "",
    sex: "",
    dob: "",
    nationality: "",
    registeredAddress: ""
  });

  React.useEffect(() => {
    const qs = queryString.parse(window.location.search);
    const { business_industry }: any = qs;
    fetchMyInfoSingPass(({ confirmed, info }: any) => {
      if (info.postalCode) {
        getAddressByPostalCode(info.postalCode);
      }
      if (info.mobileNumber === "none") {
        info.mobileNumber = "-";
      }

      setMyInfoData(info);
      setControl({
        errors: [],
        form: "my-info-retrieved-identity-form",
        name: "business_industry",
        value: Number(business_industry)
      });
    }, true);
  }, []);

  const onSubmit = () => {
    setLoading(true);
    confirmMyInfoSingPass(
      ({ confirmed = false, error = null }) => {
        if (confirmed) {
          props.fetchMainCollectedAccount((err: any, data: any) => {
            setLoading(false);
            if (!err) {
              toggleModal(FetchModalID.MY_INFO_VERIFICATION_MODAL);
              invokeMessage(
                "success",
                "Your information has been successfully retrieved from Myinfo."
              );
              if (redirectUrl) {
                history.push(redirectUrl);
              }
            }
          });
        } else {
          setLoading(false);
          openModal(FetchModalID.MY_INFO_VERIFICATION_MODAL, {
            mode: MY_INFO_MODES.login
          });
          if (_get(error, "form[0]") === "SINGPASS_CONFIRMED_ANOTHER_USER") {
            openModal(FetchModalID.MY_INFO_VERIFICATION_ERROR_MODAL, {
              message1:
                "Looks like there's already an account registered with this Singpass ID.",
              message2: (
                <Typography.Text className={styles.itemText}>
                  Please sign in using that account or contact us at{" "}
                  <Typography.Link href="mailto:support@ipaymy.com">
                    support@ipaymy.com
                  </Typography.Link>{" "}
                  for further assistance.
                </Typography.Text>
              ),
              message3: null
            });
          } else if (_get(error, "form[0]") === "BLOCKED_USER_MYINFO_ERROR") {
            openModal(FetchModalID.MY_INFO_VERIFICATION_ERROR_MODAL, {
              message1: T.transl(
                _get(error, "form[0]", "BLOCKED_USER_MYINFO_ERROR")
              ),
              message2: null,
              message3: null
            });
          } else if (_get(error, "form[0]")) {
            openModal(FetchModalID.MY_INFO_VERIFICATION_ERROR_MODAL, {
              message1: "We had some trouble retrieving your business details.",
              message2: (
                <Typography.Text>
                  Please sign in using that account or contact us at{" "}
                  <Typography.Link href="mailto:support@ipaymy.com">
                    support@ipaymy.com
                  </Typography.Link>{" "}
                  for further assistance.
                </Typography.Text>
              ),
              message3: null
            });
          } else if (
            _get(error, "fields[registration_number][0]") === "COMPANY_EXISTED"
          ) {
            openModal(FetchModalID.MY_INFO_VERIFICATION_ERROR_MODAL, {
              message1: T.transl(
                _get(error, "fields[registration_number][0]", "COMPANY_EXISTED")
              ),
              message2: (
                <Typography.Text>
                  Please sign in using that account or contact us at{" "}
                  <Typography.Link href="mailto:support@ipaymy.com">
                    support@ipaymy.com
                  </Typography.Link>{" "}
                  for further assistance.
                </Typography.Text>
              ),
              message3: null
            });
          }
        }
      },
      null,
      true
    );
  };

  const get = (value: string) => {
    return _get(myInfoData, value) || "-";
  };

  const getPersonalData = () => {
    return [
      {
        name: "principal_name",
        label: "Principal name",
        value: get("principalName")
      },
      {
        name: "alias_name",
        label: "Alias name",
        value: get("aliasName")
      },
      {
        name: "dob",
        label: "Day of birth",
        value: get("dob")
      },
      {
        name: "sex",
        label: "Sex",
        value: get("sex")
      },
      {
        name: "nric",
        label: "NRIC/FIN",
        value: get("nric")
      },
      {
        name: "nationality",
        label: "Nationality",
        value: get("nationality")
      },
      {
        name: "registered_address",
        label: "Registered address",
        value: get("registeredAddress")
      }
    ];
  };

  const getCoporateEntityData = () => {
    const getAddressValue = () => {
      return residentialAddress || get("address");
    };

    return [
      {
        name: "entity_profile",
        label: "Entity profile",
        children: [
          {
            name: "uen",
            label: "UEN",
            value: get("uen")
          },
          {
            name: "entity_name",
            label: "Entity name",
            value: get("entityName")
          },
          {
            name: "entity_type",
            label: "Entity type",
            value: get("entityType")
          },
          {
            name: "entity_status",
            label: "Entity status",
            value: get("entityStatus")
          },
          {
            name: "business_constitution",
            label: "Business constitution",
            value: get("businessConstitution")
          },
          {
            name: "company_type",
            label: "Company type",
            value: get("companyType")
          },
          {
            name: "country_of_incorporation",
            label: "Country of incorporation",
            value: get("countryOfIncorporation")
          },
          {
            name: "ownership",
            label: "Ownership",
            value: get("ownership")
          },
          {
            name: "registration_date",
            label: "Registration date",
            value: get("registrationDate")
          },
          {
            name: "business_exipry_date",
            label: "Business expiry date",
            value: get("businessExpiryDate")
          },
          {
            name: "primary_activity_code",
            label: "Primary activity code",
            value: get("primaryActivityCode")
          },
          {
            name: "primary_activity_description",
            label: "Primary activity description",
            value: get("primaryActivityDescription")
          },
          {
            name: "secondary_activity_code",
            label: "Secondary activity code",
            value: get("secondaryActivityCode")
          },
          {
            name: "secondary_activity_description",
            label: "Secondary activity description",
            value: get("secondaryActivityDescription")
          }
        ]
      },
      {
        name: "entity_address",
        label: "Entity address",
        children: [
          {
            name: "standard_address",
            label: "Standard",
            value: get("standardAddress")
          },
          {
            name: "address_sg",
            label: "Address SG",
            value: getAddressValue()
          },
          {
            name: "address_overseas",
            label: "Address Overseas",
            value: get("address")
          }
        ]
      }
    ];
  };

  const getInfo = (el: any, value: string) => {
    return _get(el, value) || "-";
  };

  const getShareHolders = () => {
    return {
      name: "shareholders_list",
      label: "Shareholders",
      value: get("shareholdersList")
    };
  };

  const getAppointments = () => {
    return {
      name: "appointments_list",
      label: "Appointments",
      value: get("appointmentsList")
    };
  };

  const getPreviousUENs = () => {
    return {
      value: !_isEmpty(get("previousUENs")) ? get("previousUENs") : [{}]
    };
  };
  const getPreviousNames = () => {
    return {
      value: !_isEmpty(get("previousNames")) ? get("previousNames") : [{}]
    };
  };

  const renderContent = () => (
    <>
      <div className={styles.section}>
        <span className={styles.sectionText}>
          Please note that information retrieved from Myinfo cannot be edited.
        </span>
      </div>

      <Form
        name="my-info-retrieved-identity-form"
        layout="vertical"
        requiredMark={false}
        className={styles.form}
      >
        <div className={styles.formWrapper}>
          <div className={styles.formContent}>
            <p className={styles.infoTitle}>Personal data:</p>
            <div className={styles.personalData}>
              {getPersonalData().map(({ name, label, value }) => (
                <div key={uuidv1()} className={styles.formItem}>
                  <InputText
                    name={name}
                    disabled={true}
                    label={label}
                    defaultValue={value}
                  />
                </div>
              ))}
            </div>

            <p className={styles.infoTitle}>Coporate data:</p>
            <div className={styles.coporateData}>
              {getCoporateEntityData().map(({ label, children }) => (
                <>
                  <FormGroup
                    title={label}
                    key={uuidv1()}
                    className={styles.formItem}
                  >
                    <>
                      {children?.map(({ name, label, value }) => (
                        <InputText
                          name={name}
                          disabled={true}
                          label={label}
                          defaultValue={value}
                        />
                      ))}
                    </>
                  </FormGroup>
                </>
              ))}

              <FormGroup
                title={getShareHolders().label}
                className={styles.formItem}
              >
                {[...getShareHolders().value].map((e: any, idx: number) => (
                  <>
                    <InputText
                      name={`idno_${idx}`}
                      disabled={true}
                      label={`ID no. ${idx + 1}`}
                      defaultValue={getInfo(e, "idno")}
                    />
                    <InputText
                      name={`person_name_${idx}`}
                      disabled={true}
                      label={"Person name"}
                      defaultValue={getInfo(e, "person_name")}
                    />
                    <InputText
                      name={`nationality_${idx}`}
                      disabled={true}
                      label={"Nationality"}
                      defaultValue={getInfo(e, "nationality")}
                    />
                    <InputText
                      name={`corppass_email_${idx}`}
                      disabled={true}
                      label={"Corppass email"}
                      defaultValue={getInfo(e, "corppass_email")}
                    />
                    <InputText
                      name={`corppass_mobile_number_${idx}`}
                      disabled={true}
                      label={"Corppass mobile number"}
                      defaultValue={getInfo(e, "corppass_mobileno")}
                    />
                    <InputText
                      name={`share_type${idx}`}
                      disabled={true}
                      label={"Share type"}
                      defaultValue={getInfo(e, "share_type")}
                    />
                    <InputText
                      name={`allocation${idx}`}
                      disabled={true}
                      label={"Allocation"}
                      defaultValue={getInfo(e, "allocation")}
                    />
                    <InputText
                      name={`currency${idx}`}
                      disabled={true}
                      label={"Currency"}
                      defaultValue={getInfo(e, "currency")}
                    />
                  </>
                ))}
              </FormGroup>

              <FormGroup
                title={getAppointments().label}
                className={styles.formItem}
              >
                {[...getAppointments().value].map((e: any, idx: number) => (
                  <>
                    <InputText
                      name={`idno_${idx}`}
                      disabled={true}
                      label={`ID no. ${idx + 1}`}
                      defaultValue={getInfo(e, "idno")}
                    />
                    <InputText
                      name={`person_name_${idx}`}
                      disabled={true}
                      label={"Person name"}
                      defaultValue={getInfo(e, "person_name")}
                    />
                    <InputText
                      name={`nationality_${idx}`}
                      disabled={true}
                      label={"Nationality"}
                      defaultValue={getInfo(e, "nationality")}
                    />
                    <InputText
                      name={`category_${idx}`}
                      disabled={true}
                      label={"Category"}
                      defaultValue={getInfo(e, "category")}
                    />
                    <InputText
                      name={`position_${idx}`}
                      disabled={true}
                      label={"Position"}
                      defaultValue={getInfo(e, "position")}
                    />
                    <InputText
                      name={`appointment_date${idx}`}
                      disabled={true}
                      label={"Appointment date"}
                      defaultValue={getInfo(e, "appointment_date")}
                    />
                  </>
                ))}
              </FormGroup>

              <FormGroup
                title={"Previous UENs and names"}
                className={styles.formItem}
              >
                {[...getPreviousUENs().value].map((e: any, idx: number) => (
                  <>
                    <InputText
                      name={`previous_uen_${idx}`}
                      disabled={true}
                      label={"Previous UEN"}
                      defaultValue={getInfo(e, "previous_uen")}
                    />
                  </>
                ))}
                {[...getPreviousNames().value].map((e: any, idx: number) => (
                  <>
                    <InputText
                      name={`history_name_${idx}`}
                      disabled={true}
                      label={"History name"}
                      defaultValue={getInfo(e, "history_name")}
                    />
                    <InputText
                      name={`history_name_effective_date_${idx}`}
                      disabled={true}
                      label={"History name effective date"}
                      defaultValue={getInfo(e, "history_name_effective_date")}
                    />
                  </>
                ))}
              </FormGroup>
            </div>
          </div>
        </div>
      </Form>
    </>
  );

  const renderButton = () => (
    <div className={styles.button}>
      <Button
        type="primary"
        htmlType="submit"
        loading={loading}
        onClick={onSubmit}
      >
        {loading ? "Verifying..." : "Verify"}
      </Button>
    </div>
  );

  const onBack = () => {
    // Call API remove myinfo biz awaiting verify
    setLoading(true);
    confirmMyInfoSingPass(
      () => {
        history.push(`${ROUTES.MY_INFO}?is_biz=y`);
        // window.location.reload();
        closeModal(FetchModalID.MY_INFO_VERIFICATION_MODAL);
        openModal(FetchModalID.MY_INFO_VERIFICATION_MODAL);
      },
      null,
      true,
      true
    );
  };

  return (
    <>
      <BrowserView>
        <DesktopModalLayout
          title={"Verify your identity"}
          onClose={onClose}
          onBack={onBack}
          onSubmit={onSubmit}
          btnSubmit={renderButton()}
        >
          <div
            className={classNames(styles.wrapper, {
              [styles.wrapperDesktop]: isDesktop
            })}
          >
            {renderContent()}
          </div>
        </DesktopModalLayout>
      </BrowserView>
      <MobileView>
        <MainLayout
          header={{
            title: "Verify your identity",
            onClose,
            hideMenu: true
          }}
          footer={renderButton()}
        >
          <div className={styles.wrapper}>{renderContent()}</div>
        </MainLayout>
      </MobileView>
    </>
  );
};

export default RetrievedIdentityBusiness;
