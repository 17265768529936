import React from "react";
import classNames from "classnames";

import Image from "src/bepaid/components/Image";
import Favourite from "src/bepaid/components/Favourite";
import ImageUtils from "src/ipm-shared/Utils/Images";

import IconDelete from "src/bepaid/assets/images/common/icon_delete_shadow.svg";
import { isEmpty } from "lodash-es";
import styles from "./ListCardModal.module.scss";

export interface IListItem {
  id: number;
  brandId: number;
  last4: string;
  name: string;
  expiryMonth: string;
  expiryYear: string;
  isFavourite?: boolean;
  cardNickName?: string;
}

export interface IListItemProps {
  item: IListItem;
  onDelete: (cardId: number) => void;
  editCard: any;
}

export interface IListProps {
  items: IListItem[];
  onDelete: (cardId: number) => void;
  editCard: any;
}

const ListCardItem = ({
  item: {
    id: cardId,
    brandId,
    last4: last4Digits,
    name: cardHolderName,
    expiryMonth,
    expiryYear,
    isFavourite = false,
    cardNickName
  },
  onDelete,
  editCard
}: IListItemProps) => {
  const handleDelete = () => {
    onDelete(cardId);
  };

  const handleFavourite = (isFavourite: boolean) => {
    try {
      editCard({
        additionalData: {
          isFavourite: !isFavourite,
          cardNickName
        },
        clearIsFavourite: true,
        cardId
      });
    } catch (e) {
      window.Logger.error("editFavouriteCardForm: ", e);
    }
  };

  return (
    <div className={styles.listItem}>
      <div className={styles.iconContainer}>
        <img
          alt={"brand icon"}
          src={ImageUtils.getCardTypeIcon(brandId)}
          className={styles.icon}
        />
      </div>
      <div className={styles.spaceRight}>
        <div className={styles.last4Digits}>-{last4Digits}</div>
        <div className={classNames("mt-5", styles.cardHolderName)}>
          {!isEmpty(cardNickName) ? cardNickName : cardHolderName}
        </div>
        <div
          className={styles.favourite}
          onClick={handleFavourite.bind(null, isFavourite)}
        >
          <Favourite isFavourite={isFavourite} />
        </div>
      </div>
      <div>{`${expiryMonth}/${expiryYear}`}</div>
      <div className={styles.trash}>
        <Image onClick={handleDelete} src={IconDelete} />
      </div>
    </div>
  );
};

export const ListCards = ({ editCard, items, onDelete }: IListProps) => (
  <div className={styles.list}>
    {items.map(item => (
      <ListCardItem
        editCard={editCard}
        key={item.id}
        onDelete={onDelete}
        item={item}
      />
    ))}
  </div>
);
