import React from "react";
import classNames from "classnames";

import Typography from "antd/es/typography";
import Flex from "antd-mobile/es/flex";

import Image from "src/bepaid/components/Image";
import Favourite from "src/bepaid/components/Favourite";
import Menu from "src/bepaid/layouts/Menu";

import ArrowBackIcon from "src/bepaid/assets/images/common/icon_arrow_back.svg";
import CloseIcon from "src/bepaid/assets/images/common/icon_close.svg";

import styles from "./TitleHeading.module.scss";

interface IProps {
  onBack?: () => void | undefined;
  onClose?: () => void | undefined;
  center?: boolean;
  title?: string;
  rightIcons?: any;
  hideMenu?: boolean;
  progress?: any;
  onFavouriteAction?: (isFavourite: boolean) => void;
  isFavourite?: boolean;
}

const Index = (props: IProps) => {
  const {
    onBack,
    onClose,
    center,
    title,
    rightIcons,
    hideMenu,
    progress,
    onFavouriteAction: onFavouriteAction,
    isFavourite
  } = props;

  return (
    <>
      <Flex justify="between" align="center" className={styles.wrapper}>
        {title && (
          <Flex.Item className={styles.title}>
            {onBack && (
              <div className={styles.goBackBtn}>
                <Image onClick={onBack} src={ArrowBackIcon} />
              </div>
            )}
            {!!onFavouriteAction && (
              <div
                className={styles.favouriteIcon}
                onClick={() => onFavouriteAction(!isFavourite)}
              >
                <Favourite isFavourite={isFavourite} />
              </div>
            )}
            <div className={classNames({ [styles.titleCenter]: center })}>
              <Typography.Text
                className={classNames({
                  [styles.marginLeftTitle]: !!onBack && !center
                })}
              >
                {title}
              </Typography.Text>
            </div>
          </Flex.Item>
        )}
        <Flex.Item className={styles.rightSide}>
          <div className={styles.rightIcons}>
            {rightIcons}
            {!hideMenu && <Menu />}
            {onClose && <Image onClick={onClose} src={CloseIcon} />}
          </div>
        </Flex.Item>
      </Flex>
      {progress && <div className={styles.progress}>{progress}</div>}
    </>
  );
};

export default Index;
