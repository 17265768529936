import Features from "./features";

const FeatureGateUtil = {
  verifyFeature: (featureMask: number, feature: string): boolean => {
    const idx = Features[feature];
    /* tslint:disable:no-bitwise */
    return idx >= 0 && ((2 ** idx) & featureMask) > 0;
  }
};

export default FeatureGateUtil;
