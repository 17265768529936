/**
 * Actions
 *
 * ActionCreators are like the "pub" of pubsub. It's just broadcasting an event (action).
 * So, don't couple it with a specific reducer. Let ANY reducer listen to
 * an action.
 *
 * `typesafe-actions` has a nice utility function so we don't need to define action types as
 * constants. Use it together with `getType(type)` in the reducers.
 */
import { createAction } from "typesafe-actions";
import { LoadingFunc } from "../../../ipm-shared/Utils/ReduxSagaEffects";

export const requestResetPasswordSubmit = createAction(
  "auth/REQUEST_RESET_PASSWORD",
  resolve => (ars?: {
    redirectUrl: string;
    cb?: (err?: any) => void;
    loadingFunc?: LoadingFunc;
  }) => resolve(ars)
);

export const setNewPasswordSubmit = createAction(
  "auth/REQUEST_SET_NEW_PASSWORD",
  resolve => (ars?: { cb: (err?: any) => void }) => resolve(ars)
);

export * from "src/ipm-shared/store/model/actions";
