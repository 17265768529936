import { connect } from "react-redux";
import { RootState } from "src/ipm-shared/store/model/reducers";

import Component from "./View";

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
