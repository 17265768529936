/**
 * Selectors
 *
 * It's like "views" in relational databases.
 * Store the most efficient format in the reducer state.
 * But to retrieve into easy to consume form, use selector.
 */
import { RootState } from "src/ipm-shared/store/model/reducers";
import _includes from "lodash-es/includes";

// Return true if at least 1 modal opened
export const hasOpenedModal = (state: RootState) =>
  state.globalUI.opened.length > 0;
export const getMostRecentModalId = (state: RootState) =>
  state.globalUI.opened[0];

export const getOpenedModalIds = (state: RootState) => state.globalUI.opened;

export const shouldDisplayLoader = (state: RootState) =>
  state.globalUI.showLoader;

export const getLoaderMessage = (state: RootState) =>
  state.globalUI.loaderMessage;

export const getExtraInfo = (state: RootState, modalId: number) =>
  state.globalUI.extraInfo[modalId] || {};

export const getSettings = (state: RootState) => state.globalUI.settings;

// Return true if given modal opened
export const isGivenModalOpened = (state: RootState, modalId: number) =>
  _includes(state.globalUI.opened, modalId);

export const getPlatform = (state: RootState) => state.globalUI.platform;

export * from "src/ipm-shared/store/model/AccountProfile/selectors";
