import React, { useEffect, useMemo, useState } from "react";
// import PaymentItem from "./PaymentItem";
import { BrowserView, MobileView } from "react-device-detect";
import DesktopModalLayout from "src/bepaid/components/Modal/components/DesktopModalLayout";
import MainLayout from "src/bepaid/layouts/MainLayout";

import ROUTES from "src/bepaid/routes";
import { history } from "src/ipm-shared/store";

import FetchSkeleton from "src/bepaid/components/Skeleton";
import Button from "src/bepaid/components/Form/controls/Button";
import Progress from "src/bepaid/components/Progress";
import { invokeMessage } from "src/bepaid/components/Message";

import * as uuidv1 from "uuid/v1";
import _get from "lodash-es/get";
import _isEmpty from "lodash-es/isEmpty";
import _lowerCase from "lodash-es/lowerCase";

import { purposeByEntity } from "src/bepaid/pages/Payments/const";
import { useMakePaymentUrlParams } from "src/bepaid/pages/Payments/hooks";

import InvoiceItem from "../InvoiceDetail/InvoiceItem";

import styles from "../ExpandBox/ExpandboxContainer.module.scss";
import PaymentUtil from "src/ipm-shared/Utils/Payment";

const View = (props: any) => {
  const [loadingPayees, setLoadingPayees] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    entity,
    paymentType,
    replaceRouteWithParams
  } = useMakePaymentUrlParams();
  const [calcPayment, setCalcPayment] = useState<any>({});
  const purpose = purposeByEntity[entity];
  const isWallex = paymentType === "international";

  const handleClickAddExtraPayee = (payee: any) => {
    const {
      accountNumber,
      bankBSBId,
      bankId,
      bankCode,
      bsbCode,
      currencyId,
      defaultAmount,
      defaultComments,
      id,
      name,
      isUnsupported,
      unsupportedMessage
    } = payee;

    let uid = uuidv1();
    let updateAmount = defaultAmount;
    if (payee.uid) {
      uid = payee.uid;
      updateAmount = defaultAmount / 100;
    }

    props.updateExtraPayee(id, uid, {
      accountNumber,
      bankBSBId,
      bankCode,
      bankId,
      bsbCode,
      currencyId,
      defaultAmount: updateAmount,
      defaultComments,
      files: [],
      id,
      isUnsupported,
      name,
      refundedAmount: 0,
      uid,
      unsupportedMessage
    });
  };

  const setExtraPayeeValue = (
    payeeId: number,
    uid: string,
    prop: any,
    value: string
  ) => {
    props.updateExtraPayee(payeeId, uid, {
      ...props.selectedPayees.filter((p: any) => p.id === payeeId)[0]
        .extraPayees[uid],
      [prop]: value
    });
  };

  const handleClickRemoveExtraPayee = (payee: any, uid: string) => {
    props.removeControlPattern(new RegExp(`${payee.id}_${uid}`));
    props.deleteExtraPayee(payee.id, uid);
  };

  const renderExtraPayees = (payee: any) => {
    return Object.keys(payee.extraPayees).map((uid: string) => {
      return (
        <InvoiceItem
          key={uid}
          payee={payee}
          extraPayeeUid={uid}
          setExtraPayeeValue={setExtraPayeeValue}
          onClickDeletePayee={handleClickRemoveExtraPayee.bind(
            null,
            payee,
            uid
          )}
          accountCountryCode={props.accountCountryCode}
          accountCurrencyId={props.accountCurrencyId}
          entity={"tax"}
          selectedPayment={props.selectedPayment}
        />
      );
    });
  };

  useEffect(() => {
    if (_isEmpty(props.selectedPayment)) {
      // Fetch tax payee here for SG
      props.fetchPayees({
        type: purpose,
        noRedirect: true,
        isWallex: paymentType === "international",
        pageCount: 1000,
        cb: (data: any) => {
          const taxes = data.filter(
            (payee: any) => payee.ipaymy_event === "TAX2019"
          );

          props.selectPayees({
            ids: taxes.map((payee: any) => payee.id),
            purpose,
            removedIds: "all"
          });
        },
        loadingFunc: setLoadingPayees
      });
    } else {
      if (props.selectedPayees.length < 1) {
        history.push(
          replaceRouteWithParams(ROUTES.PAYMENTS_CREATION, {
            newPaymentType: paymentType,
            newEntity: "entity",
            newAction: "select"
          })
        );
        invokeMessage("error", "Payee not found.");
      } else {
        // Synchronize Tax's payee id
        const calcPayees = _get(props.selectedPayment, "payees", []).map(
          (payee: any) => {
            return {
              ...payee,
              id: props.selectedPayees[0].id
            };
          }
        );

        setCalcPayment({ ...props.selectedPayment, payees: calcPayees });

        calcPayees.map((payee: any) => {
          if (payee.uid) {
            handleClickAddExtraPayee(payee);
          }
        });
      }
    }
  }, []);

  const handleError = (err: any, data?: any) => {
    if (err?.message) {
      invokeMessage("error", err.message);
    }
  };

  const redirectToCheckout = () => {
    props.addPaymentRequestSubmit(
      purpose,
      false,
      {
        cb: (err?: any, data?: any) => {
          const checkoutUrl = data
            ? `${replaceRouteWithParams(ROUTES.PAYMENTS_CREATION, {
                newAction: "checkout"
              })}/?token=${data?.token}`
            : "/";
          if (err) {
            handleError(err, { checkoutUrl });
          } else {
            history.push(checkoutUrl, {
              prevRoute: window.location.pathname
            });
          }
        }
      },
      setLoading
    );
  };

  const moveForward = () => {
    props.validatePaymentPayeeSubmit(
      purpose,
      false,
      {
        cb: (err?: any) => {
          if (err) {
            invokeMessage("error", err.message);
          } else {
            if (props.isCryptoPaymentMethod && !isWallex) {
              const options = {
                cb: (err?: any, data?: any) => {
                  if (err) {
                    invokeMessage("error", "Bad request!");
                  }
                  if (data) {
                    redirectToCheckout();
                  }
                }
              };
              const bankIds = props.selectedPayees.map((p: any) => p.bankId);
              if (
                props.selectedPayees[0] &&
                PaymentUtil.isUSDPaymentInHK(
                  props.accountCountryId,
                  props.selectedPayees[0].currencyId as number,
                  props.selectedPayees[0].countryId
                )
              ) {
                props.fetchDateConditions(
                  new Date(),
                  bankIds,
                  3, // HK
                  73, // USD
                  73, // USD,
                  undefined,
                  props.selectedPayees.map((p: any) => p.id),
                  options,
                  setLoading
                );
              } else {
                props.fetchDateConditions(
                  new Date(),
                  bankIds,
                  null,
                  null,
                  null,
                  undefined,
                  props.selectedPayees.map((p: any) => p.id),
                  options,
                  setLoading
                );
              }
            } else {
              history.push(
                replaceRouteWithParams(ROUTES.PAYMENTS_CREATION, {
                  newAction: "schedule"
                })
              );
            }
          }
        }
      },
      setLoading
    );
  };

  const submitLabel = useMemo(() => {
    return loading ? "Moving forward..." : "Move forward";
  }, [loading]);

  const onBack = () => {
    history.goBack();
  };

  const renderBtnSubmit = () => {
    return (
      <>
        <Button
          wrapperClassName={styles.wrapperButton}
          onClick={moveForward}
          type="primary"
          htmlType="button"
          loading={loading}
        >
          {submitLabel}
        </Button>
      </>
    );
  };

  const renderDesktopView = () => {
    return (
      <DesktopModalLayout
        maxWidth450={true}
        onSubmit={moveForward}
        title={props.titleLabel}
        onClose={props.onClose}
        onBack={onBack}
        progress={
          <Progress colors={["#937CF2", "#A581E8"]} value={props.rogress} />
        }
        submitButtonClassName={""}
        btnSubmit={renderBtnSubmit()}
      >
        {renderContent()}
      </DesktopModalLayout>
    );
  };

  const renderMobileView = () => {
    return (
      <MainLayout
        header={{
          title: props.titleLabel,
          hideMenu: true,
          onBack,
          onClose: props.onClose,
          progress: (
            <Progress colors={["#937CF2", "#A581E8"]} value={props.progress} />
          )
        }}
        footer={renderBtnSubmit()}
      >
        {renderContent()}
      </MainLayout>
    );
  };

  const renderContent = () => {
    return (
      <div className={styles.wrapper}>
        <FetchSkeleton loading={loadingPayees}>
          <div className={styles.groupContainer}>
            {props.selectedPayees.map((payee: any) => {
              return (
                <div key={payee.id}>
                  <InvoiceItem
                    payee={payee}
                    accountCountryCode={props.accountCountryCode}
                    accountCurrencyId={props.accountCurrencyId}
                    entity={"tax"}
                    selectedPayment={calcPayment}
                  />
                  {renderExtraPayees(payee)}

                  <button
                    onClick={handleClickAddExtraPayee.bind(null, payee)}
                    className={styles.btnAdd}
                  >
                    Make multiple tax payments.
                  </button>
                </div>
              );
            })}

            {/*{taxPayments.map((_, index) => (*/}
            {/*  <PaymentItem key={index}/>*/}
            {/*))}*/}
          </div>
        </FetchSkeleton>
      </div>
    );
  };

  return (
    <>
      <BrowserView>{renderDesktopView()}</BrowserView>
      <MobileView>{renderMobileView()}</MobileView>
    </>
  );
};

export default View;
