import React, { useCallback } from "react";
import _get from "lodash-es/get";

import SelectBusinessTypes from "src/ipm-shared/components/SelectBusiness/BusinessTypes";
import SelectBusinessIndustries from "src/ipm-shared/components/SelectBusiness/BusinessIndustries";

import T from "src/ipm-shared/Utils/Intl";

import SearchCompanyForm from "src/bepaid/components/SearchCompanyForm";
import Select from "src/bepaid/components/Form/controls/Select";
import SelectMultiLevel from "src/bepaid/components/Form/controls/SelectMultiLevel";

import { COUNTRY_CODES } from "../../const";

import styles from "./CompanyForm.module.scss";

const Component = (props: any) => {
  const {
    accountCountryCode = COUNTRY_CODES.SINGAPORE,
    disableBusiness = false,
    disableName = false,
    disableBusinessDescription = false,
    data: { companyData, formName, formOptions }
  } = props;

  const objByCountry = {
    [COUNTRY_CODES.SINGAPORE]: {
      codeTitle: T.transl("UEN_PLACEHOLDER"),
      noticeText: "Find all businesses registered in Singapore",
      noticeUrl: "https://www.uen.gov.sg/ueninternet/faces/pages/uenSrch.jspx",
      disabled: true
    },
    [COUNTRY_CODES.HONGKONG]: {
      codeTitle: T.transl("CRN_PLACEHOLDER"),
      noticeText: "Find all businesses registered in Hong Kong",
      noticeUrl: "https://www.icris.cr.gov.hk/csci",
      disabled: true
    },
    [COUNTRY_CODES.MALAYSIA]: {
      codeTitle: T.transl("LABEL_COMPANY_REGISTRATION_NUMBER"),
      noticeText: "Find all companies registered in Malaysia",
      noticeUrl: "https://www.ssm.com.my/Pages/Quick_Link_backup/e-Search.aspx",
      disabled: false
    },
    [COUNTRY_CODES.AUSTRALIA]: {
      codeTitle: T.transl("ABN_PLACEHOLDER"),
      noticeText: "Find all businesses registered in Australia",
      noticeUrl: "https://abr.business.gov.au",
      disabled: true
    }
  };

  const WrapperSelectMultiLevel = useCallback((propsWrapper: any) => {
    const handleChangeSelect = (value: any) => {
      props.setControl({
        errors: [],
        form: formName,
        name: _get(formOptions, "names.businessIndustry"),
        value: Number(value)
      });
    };

    return (
      <SelectMultiLevel
        key={"WrapperSelectMultiLevel"}
        {...propsWrapper}
        onChange={handleChangeSelect}
      />
    );
  }, []);
  // @ts-ignore
  return (
    <div className={props.className}>
      {disableName ? null : (
        <>
          <SearchCompanyForm
            form={formName}
            getLinkFindUen={objByCountry[accountCountryCode].noticeUrl}
            fields={{
              autoCompleteField: {
                label: "Your company name",
                name: _get(formOptions, "names.companyName"), // "uen_search_company"
                placeholder: T.transl("COMPANY_SEARCH_PLACEHOLDER"),
                default: _get(companyData, "name")
              },
              inputTextField: {
                name: _get(formOptions, "names.regNo"),
                linkHereLabel: objByCountry[accountCountryCode].noticeText,
                placeholder: objByCountry[accountCountryCode].codeTitle,
                label: objByCountry[accountCountryCode].codeTitle,
                className: styles.codeSection,
                disabled: objByCountry[accountCountryCode].disabled,
                classNames: styles.regNoSection
              }
            }}
            input={{
              companyName: _get(companyData, "name"),
              registrationNumber: _get(companyData, "code")
            }}
            shouldDisplayRegField={true}
          />
        </>
      )}

      {!disableBusiness && (
        <>
          <SelectBusinessTypes
            // @ts-ignore
            SelectComponent={Select}
            name={_get(formOptions, "names.businessType")}
            label="Business type"
            form={formName}
            className={styles.select}
            defaultValue={
              _get(companyData, "payeeData.businessType", null) === 0
                ? null
                : _get(companyData, "payeeData.businessType")
            }
            reserveValueOnUnmount={true}
            revertValueOnMount={true}
          />
          <div hidden={disableBusinessDescription}>
            <SelectBusinessIndustries
              // @ts-ignore
              SelectComponent={WrapperSelectMultiLevel}
              name={_get(formOptions, "names.businessIndustry")}
              label="Business description"
              form={formName}
              className={styles.select}
              defaultValue={
                _get(companyData, "payeeData.businessIndustry", null) === 0
                  ? null
                  : _get(companyData, "payeeData.businessIndustry")
              }
              reserveValueOnUnmount={true}
              revertValueOnMount={true}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Component;
