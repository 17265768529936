import React from "react";
import _get from "lodash-es/get";

import {
  COUNTRY_REGISTRATION_NUMBER,
  COUNTRY_NATIONAL_REGION,
  COUNTRY_NATIONAL_REGION_WITH_ID,
  COUNTRY_NATIONAL_CITY
} from "src/ipm-shared/store/model/Payee/const";

import InputText from "src/bepaid/components/Form/controls/InputText";
import SelectRegionCity from "src/bepaid/components/SelectRegionCity";
import CountryUtil from "src/ipm-shared/Utils/Country";
import _isEmpty from "lodash-es/isEmpty";

import RecipientBankAccountFormContent from "../RecipientBankAccountForm/RecipientBankAccountFormContent";

const IntRecipientSupplierFormContent = ({
  editedPayee,
  FORM,
  commonFormItemProps,
  paymentCountryCode,
  initName,
  filterBank
}: any) => {
  const countryCode = !_isEmpty(editedPayee)
    ? CountryUtil.getCountryCodeFromCountryId(_get(editedPayee, "country_id"))
    : paymentCountryCode;
  const addProps = !_isEmpty(editedPayee) ? {} : commonFormItemProps;

  const recipientBankAccountFormContentProps = {
    editedPayee,
    ADD_FORM: FORM,
    commonFormItemProps,
    entity: "supplier",
    initName,
    countryCode,
    filterBank
  };

  return (
    <>
      {editedPayee && (
        <div hidden={true}>
          <InputText
            name={"payee_id"}
            form={FORM}
            defaultValue={_get(editedPayee, "id", "")}
            {...addProps}
          />
        </div>
      )}
      <InputText
        name={"company_name"}
        form={FORM}
        label={"Supplier’s company name"}
        placeholder={"Supplier’s company name"}
        defaultValue={_get(editedPayee, "name", "") || initName}
        {...addProps}
      />
      {COUNTRY_REGISTRATION_NUMBER.includes(countryCode) && (
        <InputText
          name={"registration_number"}
          form={FORM}
          label={"Supplier’s company registration number"}
          placeholder={"Supplier’s company registration number"}
          defaultValue={_get(editedPayee, "data.registration_number")}
          {...addProps}
        />
      )}
      <InputText
        name={"address"}
        form={FORM}
        label={"Address"}
        placeholder={"Address"}
        defaultValue={_get(editedPayee, "data.address")}
        {...addProps}
      />
      {COUNTRY_NATIONAL_CITY.includes(paymentCountryCode) && (
        <InputText
          name={"city"}
          form={FORM}
          label={"City"}
          placeholder={"City"}
          defaultValue={_get(editedPayee, "data.city", "")}
          {...addProps}
        />
      )}
      {COUNTRY_NATIONAL_REGION.includes(paymentCountryCode) && (
        <InputText
          name={"region"}
          form={FORM}
          label={"Region"}
          placeholder={"Region"}
          defaultValue={_get(editedPayee, "data.region", "")}
          {...commonFormItemProps}
        />
      )}
      <InputText
        name={"state_or_province"}
        form={FORM}
        label={"State/Province"}
        placeholder={"State/Province"}
        defaultValue={_get(editedPayee, "data.state_or_province", "")}
        {...addProps}
      />
      {/* {COUNTRY_NATIONAL_REGION.includes(countryCode) && (
        <InputText
          name={"region"}
          form={FORM}
          label={"Region"}
          placeholder={"Region"}
          defaultValue={_get(editedPayee, "data.region", "")}
          {...addProps}
        />
      )} */}
      {COUNTRY_NATIONAL_REGION_WITH_ID.includes(countryCode) && (
        <SelectRegionCity
          form={FORM}
          fields={{
            regionField: {
              label: "Region",
              name: "region",
              placeholder: "Region",
              defaultValue: _get(editedPayee, "data.region", ""),
              extendField: "region_id"
            },
            cityField: {
              label: "City",
              name: "city",
              placeholder: "City",
              defaultValue: _get(editedPayee, "data.city", ""),
              extendField: "city_id"
            }
          }}
          {...addProps}
        />
      )}
      <InputText
        name={"postal_code"}
        form={FORM}
        label={"Postal Code"}
        placeholder={"Postal Code"}
        defaultValue={_get(editedPayee, "data.postal_code", "")}
        {...addProps}
      />

      {!_isEmpty(editedPayee) && (
        <RecipientBankAccountFormContent
          {...recipientBankAccountFormContentProps}
        />
      )}
    </>
  );
};

export default IntRecipientSupplierFormContent;
