import React from "react";
import Popover from "antd/es/popover";
import styles from "./CvvSurffix.module.scss";
import classNames from "classnames";
import QuestionIconLight from "src/ipm-shared/images/question-icon-light.svg";
import T from "src/ipm-shared/Utils/Intl";

export const CvvSurffix = ({ className = "" }: { className?: string }) => (
  <Popover
    trigger="click"
    overlayClassName={classNames(styles.popover, className)}
    content={<div>{T.transl("LABEL_CARD_CVC_INSTRUCTION")}</div>}
  >
    <img
      className={classNames(styles.container)}
      width={15}
      height={15}
      src={QuestionIconLight}
    />
  </Popover>
);
