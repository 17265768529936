import React from "react";

import Image from "src/bepaid/components/Image";

import StarGrayIcon from "src/bepaid/assets/images/payment-activity/star_gray.svg";
import StarPurpleIcon from "src/bepaid/assets/images/payment-activity/star_purple.svg";

const Favourite = (props: { isFavourite: boolean | undefined }) => {
  const { isFavourite = false } = props;
  return <Image src={isFavourite ? StarPurpleIcon : StarGrayIcon} />;
};

export default Favourite;
