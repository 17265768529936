import React from "react";
import IpmSharedCheckbox, {
  ICheckboxProps
} from "src/ipm-shared/components/Form/controls/Checkbox";
import AntDesignCheckbox from "antd/es/checkbox";
import styles from "./Checkbox.module.scss";

const Checkbox: React.FC<ICheckboxProps> = props => (
  <IpmSharedCheckbox
    {...props}
    renderComponent={(renderProps: any) => {
      const control = renderProps.control;

      return (
        <div>
          <AntDesignCheckbox {...renderProps} className={props.className}>
            {props.label || props.children}
          </AntDesignCheckbox>
          {control.displayError &&
            control.errors.map((error: any, idx: number) => (
              <p key={idx} className={styles.error}>
                {error.message}
              </p>
            ))}
        </div>
      );
    }}
  />
);

export default Checkbox;
