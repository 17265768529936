import React, { useState } from "react";
import Checkbox from "antd/es/checkbox";
import _lowerCase from "lodash-es/lowerCase";
import { PaymentEntity } from "src/bepaid/pages/Payments/types";
// import InfoIconPurple from "src/ipm-shared/images/info-icon-purple.svg";

import styles from "./List.module.scss";
import { purposeByEntity } from "src/bepaid/pages/Payments/const";
import Image from "src/bepaid/components/Image";
import ROUTES from "src/bepaid/routes";
import { history } from "src/ipm-shared/store";
import iconPencil from "src/bepaid/assets/images/common/icon_pencil.svg";
import ShortCurrency from "src/ipm-shared/components/ShortCurrency";
import { invokeMessage } from "src/bepaid/components/Message";

interface IList {
  entity: PaymentEntity;
  options: any[];
  onChangeCustom: any;
  paymentType: string;
  selectedPayee: Array<number>;
}

const List = ({
  entity,
  options = [],
  onChangeCustom,
  paymentType,
  selectedPayee
}: IList) => {
  const purpose = purposeByEntity[entity];
  let ids: any[] = [];

  const [selectedIds, setIds] = useState<any[]>(selectedPayee);

  const onChange = (option: any) => (e: any) => {
    const { checked } = e.target;

    if (option === "all") {
      if (checked) {
        ids = options.filter(o => o.reviewStatus !== "D").map(o => o.id);
      } else {
        ids = [];
      }

      onChangeCustom({
        ids,
        purpose,
        removedIds: "all"
      });
    } else {
      selectRecipient(option, checked);
    }
    setIds(ids);
  };

  const selectRecipient = (option: any, checked: boolean) => {
    if (checked) {
      if (paymentType !== "international") {
        ids = [...selectedIds, option.id];
        onChangeCustom({
          ids,
          purpose,
          removedIds: []
        });
      } else {
        ids = [option.id];
        onChangeCustom({
          ids,
          purpose,
          removedIds: [...selectedIds].filter((id: any) => +id !== +option.id)
        });
      }
    } else {
      ids = [...selectedIds].filter((id: any) => +id !== +option.id);
      onChangeCustom({
        ids,
        purpose,
        removedIds: [+option.id]
      });
    }
  };

  const isSelectedOption = (option: any) => selectedIds.includes(option.id);
  const normalizedEntity =
    entity === "self_transfer" ? "bank account" : _lowerCase(entity);

  const updateEntity = (option: any) => {
    const entityMap = {
      self_transfer: "bank-accounts"
    };

    history.push(
      ROUTES.RECIPIENTS_DETAILS_UPDATE.replace(":id", option.id).replace(
        ":entity",
        entityMap[entity] || entity + "s"
      )
    );
  };

  const onClick = (isDeclined: any) => (e: any) => {
    isDeclined &&
      invokeMessage(
        "error",
        "This party has not been approved due to external and internal regulatory requirements."
      );
  };

  return (
    <div className={styles.wrapper}>
      {options.length === 0 && <p>No results found.</p>}

      {options.length > 0 && paymentType !== "international" && (
        <div className={styles.checkBoxWrapper}>
          <Checkbox
            className={styles.checkbox}
            name={"checkboxToS"}
            onChange={onChange("all")}
          >
            Select all {normalizedEntity}s
          </Checkbox>
        </div>
      )}

      {options.map((o: any, idx: number) => {
        const isDeclined = o.reviewStatus === "D";
        return (
          <div
            className={styles.listItem}
            key={idx}
            onClick={onClick(isDeclined)}
          >
            <Checkbox
              checked={isSelectedOption(o)}
              className={styles.checkbox}
              name={"checkboxToS"}
              onChange={onChange(o)}
              disabled={isDeclined}
            >
              <div className={styles.checkboxContent}>
                <div className={styles.leftContent}>
                  <p className={styles.listItemName}>{o.name}</p>
                  <p className={styles.listItemDescription}>{o.description}</p>
                </div>
                {/* {paymentType === "international" && (
                <img className={styles.infoIcon} src={InfoIconPurple} />
              )} */}
                {["landlord", "employee"].includes(entity) && (
                  <span className={styles.currency}>
                    <ShortCurrency value={o.amount} currencyId={o.currencyId} />
                  </span>
                )}
                {o.allowEdit && !isDeclined && (
                  <Image
                    src={iconPencil}
                    alt={`update ${entity}`}
                    onClick={updateEntity.bind(null, o)}
                  />
                )}
              </div>
            </Checkbox>
          </div>
        );
      })}
    </div>
  );
};

export default List;
