import { connect } from "react-redux";
import View from "./View";

import { RootState } from "src/ipm-shared/store/model/reducers";

import * as selectors from "src/bepaid/store/model/Fetch/selectors";
import * as selectorsUI from "src/ipm-shared/components/GlobalUI/selectors";
import * as actions from "src/bepaid/store/model/Fetch/actions";

const mapStateToProps = (state: RootState, props: any) => ({
  collectedAccount: selectors.getCollectedAccount(state),
  extraInfo: selectorsUI.getExtraInfo(state, props.modalId),
  getCustomerByIds: selectors.getCustomerByIds(state),
  hasToken: selectors.hasToken(state),
  list: selectors.getInvoiceListModal(state),
  statistics: selectors.getInvoiceStatistics(state)
});

const mapDispatchToProps = {
  fetchMainCollectedAccount: actions.fetchMainCollectedAccount,
  selectCustomers: actions.selectCustomers,
  getInvoiceList: actions.getInvoiceList,
  selectAllItemIds: actions.selectAllItemIds,
  selectInvoice: actions.selectInvoice,
  setControl: actions.setControl,
  getInvoiceStatistics: actions.getInvoiceStatistics,
  toggleModal: actions.toggleModal,
  updateDraftInvoiceSetting: actions.updateDraftInvoiceSetting
};

export type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(View);
