/* tslint:disable:object-literal-sort-keys jsx-no-lambda */
import React from "react";
import { Redirect } from "react-router-dom";
import {
  BrowserView,
  MobileView,
  isDesktop,
  isMobile
} from "react-device-detect";
import classNames from "classnames";
import Flex from "antd-mobile/es/flex";
import Typography from "antd/es/typography";
import _get from "lodash-es/get";

import { history } from "src/ipm-shared/store";
import ShortCurrency from "src/ipm-shared/components/ShortCurrency";
import { PLATFORM } from "src/ipm-shared/components/GlobalUI/reducers";

import ROUTES from "src/bepaid/routes";
import BarChart from "src/bepaid/components/BarChart";
import Card from "src/bepaid/components/Card";
import Tab from "src/bepaid/components/Tabs";
import Skeleton from "src/bepaid/components/Skeleton";
import OverviewBox from "src/bepaid/components/OverviewBox";
// import Button from "src/bepaid/components/Form/controls/Button";
import List from "src/bepaid/components/List";
import MainLayout from "src/bepaid/layouts/MainLayout";
import ProfileHeading from "src/bepaid/layouts/MainLayout/components/ProfileHeading";
import ButtonsHeading from "src/bepaid/layouts/MainLayout/components/ButtonsHeading";
import MobileMainButton from "src/bepaid/layouts/MainLayout/components/MobileMainButton";

import { DURATIONS } from "./const";
import styles from "./InsightsPay.module.scss";

const barOptions: any = {
  layout: {
    padding: {
      top: 12
    }
  },
  plugins: {
    legend: {
      display: isDesktop,
      position: "right" as const,
      align: "end",
      fullSize: true,
      onClick: () => {},
      fit: {
        width: 30
      },
      labels: {
        padding: 15,
        usePointStyle: true,
        pointStyle: "rectRounded",
        font: {
          size: 14
        }
      }
    },
    datalabels: {
      anchor: "end",
      align: "top",
      formatter: (value: number, context: any) => {
        return value.toFixed(0) + context.dataset.measure;
      }
    },
    tooltip: {
      yAlign: "top",
      xAlign: "left",
      callbacks: {
        label: function(item: any) {
          const { dataset, raw } = item;
          let label = dataset.label + ": " + raw.toFixed(0) + dataset.measure;
          return label;
        }
      }
    }
  },
  scales: {
    y: {
      beginAtZero: true,
      suggestedMax: 100,
      ticks: {
        stepSize: 20,
        display: false
      },
      grid: {
        borderColor: "#B1ABAB",
        drawTicks: false
      }
    },
    x: {
      ticks: {
        display: isMobile
      },
      grid: {
        display: false
      }
    }
  }
};

const InsightsPay: React.FC<any> = props => {
  const { currencySymbol, hasToken, payInsights, getPayInsights } = props;

  const [duration, setDuration] = React.useState(DURATIONS.last_month.value);
  const [loading, setLoading] = React.useState(false);
  const [legendWidthChart, setLegendWidthChart] = React.useState(0);

  const {
    totalPaymentAmount = 0,
    averagePaymentAmount = 0,
    recurringPayment = 0,
    oneTimePayment = 0
  } = payInsights;

  const isOnlyOneTimePayment = recurringPayment === 0 && oneTimePayment === 100;

  const zeroStatus =
    !totalPaymentAmount &&
    !averagePaymentAmount &&
    !recurringPayment &&
    !oneTimePayment;

  const labels = isMobile ? ["One-time", "Recurring"] : [""];
  const getDatasets = () =>
    isMobile
      ? [
          {
            key: 0,
            data: [oneTimePayment, recurringPayment],
            backgroundColor: ["#9582DE", "#3C22AA"],
            categoryPercentage: 0.6,
            barPercentage: 0.4,
            measure: ["%"]
          }
        ]
      : [
          {
            key: 0,
            label: "One-time",
            data: [oneTimePayment],
            backgroundColor: ["#9582DE", "#3C22AA"],
            categoryPercentage: 0.6,
            barPercentage: 0.4,
            measure: ["%"]
          },
          {
            key: 1,
            label: "Recurring",
            data: [recurringPayment],
            backgroundColor: ["#3C22AA"],
            categoryPercentage: 0.6,
            barPercentage: 0.4,
            measure: ["%"]
          }
        ];

  const chartData = {
    labels: labels,
    datasets: getDatasets()
  };
  React.useEffect(() => {
    const barChartElement = document.getElementById("insight-pay-bar-chart");
    const widthChart = _get(barChartElement, "offsetWidth", 0);
    if (isDesktop) setLegendWidthChart(widthChart / 10);
    else setLegendWidthChart(0);
  }, [chartData]);

  React.useEffect(() => {
    getPayInsights(duration, setLoading);
  }, [duration]);

  const Content = () => {
    const result = [
      {
        key: 1,
        label: "Total payment amount:",
        value: totalPaymentAmount
      },
      {
        key: 2,
        label: "Average payment amount:",
        value: averagePaymentAmount
      },
      {
        key: 3,
        label: "Percentage of one-time and recurring payments:",
        value: chartData
      }
    ];

    return (
      <>
        {result.map((item: any, index: number) => (
          <Card
            key={index}
            className={classNames(styles.card, { [styles.first]: index === 0 })}
          >
            <Skeleton
              loading={loading}
              paragraph={{
                rows: 1
              }}
            >
              <Flex justify="center" align="stretch" direction={"column"}>
                {item.key !== 3 ? (
                  <>
                    <Flex.Item span={24} className={styles.title}>
                      <Typography.Text>{item.label}</Typography.Text>
                    </Flex.Item>
                    <Flex.Item span={24} className={styles.amount}>
                      <Typography.Text className={styles.currency}>
                        {currencySymbol}
                      </Typography.Text>
                      <Typography.Text>
                        <ShortCurrency
                          value={item.value}
                          removedZeroDecimal={true}
                          fractionDigits={0}
                        />
                      </Typography.Text>
                    </Flex.Item>
                  </>
                ) : isOnlyOneTimePayment ? (
                  <>
                    <Flex.Item span={24} className={styles.title}>
                      <Typography.Text className={styles.bold}>
                        Did you know that you can schedule weekly and monthly
                        payments?
                      </Typography.Text>
                    </Flex.Item>
                    <Typography.Text className={styles.content}>
                      Schedule a payment now and you'll see the statistics on
                      your one-time and recurring payments here.
                    </Typography.Text>
                  </>
                ) : (
                  <>
                    <Flex.Item span={24} className={styles.title}>
                      <Typography.Text>{item.label}</Typography.Text>
                    </Flex.Item>

                    <Flex.Item
                      id="insight-pay-bar-chart"
                      className={styles.strike}
                    >
                      <BarChart
                        data={item.value}
                        options={barOptions}
                        widthLegend={legendWidthChart}
                      />
                    </Flex.Item>
                  </>
                )}
              </Flex>
            </Skeleton>
          </Card>
        ))}
      </>
    );
  };

  const tabs = Object.values(DURATIONS).map((item: any, idx: number) => ({
    name: item.label,
    content: isDesktop ? null : <Content />
  }));

  const handleChangeTab = (idx: number) => {
    setDuration(Object.values(DURATIONS)[idx].value);
  };

  const renderContent = () => {
    return (
      <div className={styles.content}>
        {zeroStatus ? (
          <OverviewBox
            isEmptyData={true}
            emptyTitle={"Make your first payment."}
            emptyDescription={"Statistics on your payments will appear here."}
          />
        ) : !isDesktop ? (
          <Tab tabs={tabs} onChange={handleChangeTab} />
        ) : (
          <Content />
        )}
      </div>
    );
  };

  const renderDesktopView = () => {
    return (
      <div className={classNames(styles.wrapper, styles.desktop)}>
        <MainLayout
          className={styles.mainLayout}
          header={{
            leftHeader: <ProfileHeading />,
            rightHeader: <ButtonsHeading />
          }}
          body={{
            leftBody: renderContent(),
            rightBody: (
              <Card className={styles.timeFrameBox}>
                <List
                  header={
                    <Typography.Text className={styles.header}>
                      Time Frame
                    </Typography.Text>
                  }
                  emptyDOM={
                    <div className={styles.timeFrame}>
                      <Tab
                        tabs={tabs}
                        onChange={handleChangeTab}
                        fullWidth={true}
                        size={"large"}
                      />
                    </div>
                  }
                />
              </Card>
            )
          }}
        />
      </div>
    );
  };

  const renderMobileView = () => {
    return (
      <MainLayout
        header={{
          title: "Insights",
          onBack: () => history.push(ROUTES.ROOT),
          hasBorder: !zeroStatus
        }}
        footer={<MobileMainButton platform={PLATFORM.PAY} />}
      >
        <div className={styles.wrapper}>{renderContent()}</div>
      </MainLayout>
    );
  };

  return !hasToken ? (
    <Redirect to={ROUTES.LOGIN_REDIRECT} />
  ) : (
    <>
      <BrowserView>{renderDesktopView()}</BrowserView>
      <MobileView>{renderMobileView()}</MobileView>
    </>
  );
};

export default InsightsPay;
