import { RouteProps } from "react-router";
import { connect } from "react-redux";

import Component from "./View";
import { unsubscribeEmail } from "src/ipm-shared/store/model/Auth/actions";

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = {
  unsubscribeEmail
};

export type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteProps & { qs: { [param: string]: any } };

export default connect(mapStateToProps, mapDispatchToProps)(Component);
