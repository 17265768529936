import { RouteProps } from "react-router";
import { connect } from "react-redux";

import { RootState } from "src/ipm-shared/store/model/reducers";
import * as selectors from "src/bepaid/store/model/Fetch/selectors";
import * as actions from "src/bepaid/store/model/Fetch/actions";
import { requireProfileComponent } from "src/ipm-shared/HOCs/RequiredProfile";

import Component from "./View";

const mapStateToProps = (state: RootState) => ({
  accountProfile: selectors.getCurrentAccountProfile(state),
  companies: selectors.getCompanies(state),
  company: selectors.getCompany(state),
  hasCollectedAccount: selectors.hasCollectedAccount(state),
  hasFetchedCollectedAccount: selectors.getIsFetchedMainCollectedAccount(state),
  platform: selectors.getPlatform(state),
  openedModalIds: selectors.getOpenedModalIds(state),
  isBusinessAccount: selectors.getIsBusinessAccount(state),
  isSingaporeAccount: selectors.isSingaporeAccount(state),
  wallexKycStatus: selectors.getWallexKycStatus(state),
  accountUserRoleId: selectors.getAccountUserRoleId(state)
});

const mapDispatchToProps = {
  logout: actions.logout,
  switchPlatform: actions.switchPlatform,
  toggleModal: actions.toggleModal
};

export type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteProps;

export default requireProfileComponent(
  connect(mapStateToProps, mapDispatchToProps)(Component)
);
