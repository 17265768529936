// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InputText_labelNoColon__2u4QW .ant-form-item-label>label::after{content:none}.InputText_wrapper__34i6N{opacity:1;width:100%}.InputText_wrapper__34i6N .ant-upload{width:100%}.InputText_wrapper__34i6N .InputText_btnUpload__19EoB{width:100%;height:50px;border-radius:5px;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:justify;justify-content:space-between}.InputText_fieldOptional__1A7A7 .ant-form-item-label label{color:#626262}.InputText_helperText__1n2SK{margin-top:-0.6875rem !important;color:#74747b !important;font-size:.75rem !important;line-height:.875rem}.InputText_helperText__1n2SK .purple_text{color:#5a6be8 !important}.InputText_helperTextHasError__d_XsR{margin-top:0 !important;color:#74747b !important;font-size:.75rem !important}", "",{"version":3,"sources":["webpack://./src/bepaid/components/Form/controls/InputText/InputText.module.scss"],"names":[],"mappings":"AAAA,iEAAwD,YAAY,CAAC,0BAAS,SAAS,CAAC,UAAU,CAAC,sCAA6B,UAAU,CAAC,sDAAoB,UAAU,CAAC,WAAW,CAAC,iBAAiB,CAAC,mBAAY,CAAZ,YAAY,CAAC,qBAAkB,CAAlB,kBAAkB,CAAC,qBAA4B,CAA5B,6BAA6B,CAAC,2DAAkD,aAAa,CAAC,6BAAY,gCAAgC,CAAC,wBAAwB,CAAC,2BAA2B,CAAC,mBAAmB,CAAC,0CAAiC,wBAAwB,CAAC,qCAAoB,uBAAuB,CAAC,wBAAwB,CAAC,2BAA2B","sourcesContent":[".labelNoColon :global .ant-form-item-label>label::after{content:none}.wrapper{opacity:1;width:100%}.wrapper :global .ant-upload{width:100%}.wrapper .btnUpload{width:100%;height:50px;border-radius:5px;display:flex;align-items:center;justify-content:space-between}.fieldOptional :global .ant-form-item-label label{color:#626262}.helperText{margin-top:-0.6875rem !important;color:#74747b !important;font-size:.75rem !important;line-height:.875rem}.helperText :global .purple_text{color:#5a6be8 !important}.helperTextHasError{margin-top:0 !important;color:#74747b !important;font-size:.75rem !important}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"labelNoColon": "InputText_labelNoColon__2u4QW",
	"wrapper": "InputText_wrapper__34i6N",
	"btnUpload": "InputText_btnUpload__19EoB",
	"fieldOptional": "InputText_fieldOptional__1A7A7",
	"helperText": "InputText_helperText__1n2SK",
	"helperTextHasError": "InputText_helperTextHasError__d_XsR"
};
export default ___CSS_LOADER_EXPORT___;
