import * as React from "react";
import classNames from "classnames";
import { IBaseProps, IBaseState } from "./lib/Base";
import _findIndex from "lodash-es/findIndex";
import BaseControl from "./lib/Base";
import Dropdown from "reactstrap/lib/Dropdown";
import DropdownToggle from "reactstrap/lib/DropdownToggle";
import DropdownMenu from "reactstrap/lib/DropdownMenu";
import DropdownItem from "reactstrap/lib/DropdownItem";
import { connect } from "react-redux";
import * as actions from "../actions";
import * as selectors from "../selectors";
import { RootState } from "src/ipm-shared/store/model/reducers";
import { ControlErrorType, ControlType, ControlValueType } from "../types";
import withPropsChecker from "./lib/withPropsChecker";
import T from "src/ipm-shared/Utils/Intl";
import ClonedValue from "src/ipm-shared/components/Form/helpers/ClonedValue";
import InputText from "src/ipm-shared/components/Form/controls/InputText";

type ISelectOption = {
  label: string;
  children: ISelectOptionChildren[];
};

type ISelectOptionChildren = {
  label: string;
  value: string;
};

export type ISelectProps = IBaseProps & {
  options: ISelectOption[];
  labelClassName?: string;
  itemClassName?: string;
  fixedLabel?: string;
  preventFromInitChange?: boolean;
  renderComponent?: any;
};

const mapStateToProps = (
  state: RootState,
  props: ISelectProps
): {
  control: ReturnType<typeof selectors.getControl>;
} => ({
  control: selectors.getControl(state, props.name)
});

const mapDispatchToProps = {
  removeControl: actions.removeControl,
  resetControlErrors: actions.resetControlErrors,
  setControl: actions.setControl
};

type ISelectState = IBaseState & {
  dropdownShown: boolean;
  dropdownChildren: {
    [idx: number]: boolean;
  };
};

type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  ISelectProps;

/**
 * This is one of common controls in the entire app.
 * Use this when you want to show a drop-down list.
 *
 * @base BaseControl.tsx
 */
class SelectMultiLevel extends BaseControl<IProps, ISelectState> {
  public static defaultProps = {
    ...BaseControl.defaultProps
  };

  constructor(props: IProps) {
    super(props);
    this.state = {
      dropdownChildren: {},
      dropdownShown: false
    };
  }

  public componentDidMount() {
    const { name, defaultValue, form, displayError, control } = this.props;

    if (this.shouldRevertValueOnMount(control)) {
      return;
    }

    // Init control
    this.props.setControl({
      errors: [],
      form,
      group: this.props.group,
      name,
      value: defaultValue
    });

    this.doValidate(defaultValue, name, displayError, true);
  }

  public componentWillUnmount() {
    this.alive = false;

    if (!this.props.reserveValueOnUnmount) {
      this.props.removeControl(this.props.name);
    } else {
      this.props.resetControlErrors(this.props.name);
    }
  }

  public render() {
    const {
      control,
      errorStyle,
      labelClassName,
      fixedLabel,
      options,
      renderComponent = null
    } = this.props;
    const label = this.getLabel();

    if (control.notFound) {
      return null;
    }

    if (this.props.labelOnly) {
      return this.customRenderLabelOnly(control, this.props.id, errorStyle);
    }

    if (renderComponent) {
      const selectProps = {
        control: this.props.control
      };
      return renderComponent(selectProps);
    }

    return (
      <div className="select-multi-dropdown w-100">
        <Dropdown
          className={this.props.className}
          isOpen={this.state.dropdownShown}
          toggle={this.toggleDropDown}
        >
          <DropdownToggle
            disabled={this.props.disabled}
            tag="span"
            className={classNames("form-control", labelClassName, {
              "is-invalid text-danger":
                control.displayError && control.errors.length > 0
            })}
            caret={true}
            tabIndex={0}
          >
            {fixedLabel ? fixedLabel : label}
          </DropdownToggle>

          <DropdownMenu tag="ul">
            <InputText
              className={"form-control form-control-search"}
              type="text"
              name={"search_value"}
              placeholder="Search..."
              onChangeCustom={this.filterList}
            />

            <ClonedValue name="search_value">
              {(value: any) => (
                <>
                  {options
                    .filter(p => {
                      return this.filter(p, value);
                    })
                    .map((o, idx) => {
                      return this.renderDropdownItem(o, idx);
                    })}
                </>
              )}
            </ClonedValue>
          </DropdownMenu>

          {this.renderErrorMessage(control)}
        </Dropdown>
      </div>
    );
  }

  private filter = (p: ISelectOption, value: string) => {
    if (!value) {
      return true;
    }
    if (p.label.toLowerCase().search(value.toLowerCase()) !== -1) {
      return true;
    }
    p.children = p.children.filter(
      c => c.label.toLowerCase().search(value.toLowerCase()) !== -1
    );
    return p.children.length > 0;
  };

  private filterList = (searchValue: string) => {
    const dropdownChildrenObject = {};

    this.props.options.map((o, idx) => {
      dropdownChildrenObject[idx] = false;
    });

    this.setState({
      dropdownChildren: dropdownChildrenObject
    });

    if (searchValue === "") {
      return;
    }

    this.props.options
      .filter(p => {
        return this.filter(p, searchValue);
      })
      .map((p, idx) => {
        dropdownChildrenObject[idx] = true;
      });

    this.setState({
      dropdownChildren: dropdownChildrenObject
    });
  };

  private getLabel(): ISelectOptionChildren["label"] {
    const { options, control, placeholder } = this.props;

    let values: ISelectOption["children"] = [];
    options
      .map(o => o.children)
      .map(a => {
        values = values.concat(a);
      });

    if (options.length === 0) {
      return placeholder || "";
    }
    let label: ISelectOptionChildren["label"] = "";

    values.map((o: ISelectOptionChildren) => {
      if (o.value === control.value) {
        label = o.label;
      }
    });

    if (label === "") {
      label = placeholder || options[0].children[0].label;
    }

    return label;
  }

  private renderDropdownItem = (
    optionsChildren: ISelectOption,
    idx: number
  ) => {
    return (
      <ul
        className={classNames("dropdown-multi-level", {
          "is-open": this.state.dropdownChildren[idx]
        })}
        key={idx}
      >
        {optionsChildren.children.length > 0 && (
          <>
            <li
              className={"dropdown-item parent-label"}
              onClick={this.toggleDropDownChildren.bind(this, idx)}
            >
              <span className={"text-white"} title={`${optionsChildren.label}`}>
                {optionsChildren.label}
              </span>
            </li>

            <ul>
              {optionsChildren.children.map((c: any, index: number) => {
                return (
                  <DropdownItem
                    key={index}
                    tag="li"
                    onClick={this.handleChoose.bind(
                      this,
                      optionsChildren,
                      index
                    )}
                    className={this.props.itemClassName}
                  >
                    <span title={`${c.label}`}>{c.label}</span>
                  </DropdownItem>
                );
              })}
            </ul>
          </>
        )}
      </ul>
    );
  };

  private toggleDropDownChildren = (idx: number) => {
    const { dropdownChildren } = this.state;
    this.setState(preState => ({
      dropdownChildren: {
        ...preState.dropdownChildren,
        [idx]: dropdownChildren ? !dropdownChildren[idx] : true
      }
    }));
  };

  private toggleDropDown = () => {
    this.setState({
      dropdownChildren: {},
      dropdownShown: !this.state.dropdownShown
    });
  };
  private handleChoose = (
    optionsChildren: ISelectOption,
    key: number,
    e: React.ChangeEvent<any>
  ) => () => {
    e.preventDefault();
    this.onChooseOption(optionsChildren, key);
  };
  private onChooseOption = (
    optionsChildren: ISelectOption,
    keyChildren: number
  ) => {
    const { name, form } = this.props;
    const value = optionsChildren.children[keyChildren].value;

    this.props.setControl({
      errors: [],
      form,
      name,
      value
    });

    this.doValidate(value, name);
  };

  private doValidate = (
    value: ControlValueType,
    name: string,
    displayError: boolean = true,
    isInitChange: boolean = false
  ) => {
    let it = this.validate(value);
    let result: any;
    const errors: ControlErrorType[] = [];

    while (!(result = it.next()).done) {
      errors.push(result.value);
    }

    it = SelectMultiLevel.baseValidate(this.props, value);
    while (!(result = it.next()).done) {
      errors.push(result.value);
    }

    if (errors.length === 0) {
      if (isInitChange && this.props.preventFromInitChange) {
        // do nothing
      } else {
        if (this.props.onChangeCustom && this.alive) {
          this.props.onChangeCustom(value);
        }
      }
    } else {
      this.props.setControl({
        displayError,
        errors,
        name
      });
    }
  };

  private *validate(value: ControlValueType) {
    const { required, requiredMessage, options } = this.props;
    let values: ISelectOption["children"] = [];

    options
      .map(o => o.children)
      .map(a => {
        values = values.concat(a);
      });

    if (required) {
      // Case: Default value does not belong to select options
      if (value && _findIndex(values, o => o.value === value) === -1) {
        yield {
          code: "REQUIRED",
          message: requiredMessage || T.transl("REQUIRED_FIELD")
        };
      }
    }
  }

  private customRenderLabelOnly = (
    control: ControlType,
    id?: string,
    errorStyle?: string
  ) => {
    let output = this.renderLabelOnly(control, id, errorStyle, false);
    if (!output) {
      output = <span title={`${this.getLabel()}`}>{this.getLabel()}</span>;
    }

    return output;
  };
}

export default withPropsChecker(
  connect(mapStateToProps, mapDispatchToProps)(SelectMultiLevel),
  ["defaultValue", "options"]
);
