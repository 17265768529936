/* tslint:disable:object-literal-sort-keys */
import _get from "lodash-es/get";
import _isEmpty from "lodash-es/isEmpty";
import { ActionType, getType } from "typesafe-actions";
import { call, put, select, takeLatest } from "redux-saga/effects";
import * as queryString from "query-string";

import RestClient from "src/ipm-shared/services/Rest";
import { RootState } from "src/ipm-shared/store/model/reducers";
import Is from "src/ipm-shared/Utils/Is";
import { history } from "src/ipm-shared/store";
import { catchTakeLatest } from "src/ipm-shared/Utils/ReduxSagaEffects";
import * as formActions from "src/ipm-shared/components/Form/actions";
import { ACCOUNT_TYPE } from "src/ipm-shared/store/model/AccountProfile/const";
// import GATrackScript from "src/ipm-shared/components/GAProvider/GATrackScript";

import useQuery from "src/bepaid/hooks/useQuery";
import { invokeMessage } from "src/bepaid/components/Message";

import * as actions from "./actions";
import * as selectors from "./selectors";
import { REGISTER_FORM } from "./const";
import ROUTES from "src/bepaid/routes";

const watchedSagas = [
  catchTakeLatest(actions.register, handleRegister),
  takeLatest(getType(actions.verifyOtp), handleVerifyOtp),
  takeLatest(getType(actions.resendVerifyOtp), handleResendVerifyOtp)
];

export default watchedSagas;

// const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

export function* handleRegister(action: ActionType<typeof actions.register>) {
  try {
    const state: RootState = yield select();

    const formState = selectors.getControls(state, REGISTER_FORM);

    const qs = useQuery();
    let qsOrigin = _get(qs, "utm_origin", _get(qs, "origin", "")) as any;
    qsOrigin = qsOrigin
      .replaceAll(/undefined/gi, "")
      .trim()
      .toLowerCase();
    let utmCampaign = _get(qs, "utm_campaign", "") as any;
    utmCampaign = utmCampaign
      .replaceAll(/undefined/gi, "")
      .trim()
      .toLowerCase();

    let utmMedium = _get(qs, "utm_medium", "") as any;
    utmMedium = utmMedium
      .replaceAll(/undefined/gi, "")
      .trim()
      .toLowerCase();
    let utmContent = _get(qs, "utm_content", "") as any;
    utmContent = utmContent
      .replaceAll(/undefined/gi, "")
      .trim()
      .toLowerCase();
    let utmTerm = _get(qs, "utm_term", "") as any;
    utmTerm = utmTerm
      .replaceAll(/undefined/gi, "")
      .trim()
      .toLowerCase();
    let utmSource = _get(qs, "utm_source", "") as any;
    utmSource = utmSource
      .replaceAll(/undefined/gi, "")
      .trim()
      .toLowerCase();

    const isLive = Is.live();

    const accountType =
      _get(formState, "account_type.value") || ACCOUNT_TYPE.BUSINESS;
    const paymentInterest = _get(formState, "payment_interest.value") || "";

    const email = _get(formState, "email.value");

    const inviteCode = _get(qs, "aur", undefined) as any;

    yield put(actions.setLoading(true));

    const res = yield call(RestClient.send, {
      body: {
        first_name: _get(formState, "first_name.value"),
        last_name: _get(formState, "last_name.value"),
        account_type: accountType,
        payment_interest:
          accountType === ACCOUNT_TYPE.BUSINESS ? paymentInterest : null,
        allow_weak: true,
        by_pass_phone_number_code: isLive ? undefined : "jBLrg*:X~6~Vz*x#9*%J",
        cb_no_prefilled_company: true,
        country_code: _get(formState, "country_code.value"),
        email,
        is_production: isLive,
        google_recaptcha_token: action.payload.recaptchaToken,
        recaptcha_version: action.payload.recaptchaVersion,
        mobile: _get(formState, "mobile.value"),
        mobile_country_code: _get(formState, "mobile_country_code.value"),
        password: _get(formState, "password.value"),
        password_confirm: _get(formState, "password.value"),
        referral_code: _get(formState, "referral_code.value"),
        invite_code: inviteCode,
        origin: qsOrigin,
        utm_campaign: utmCampaign,
        utm_medium: utmMedium,
        utm_content: utmContent,
        utm_term: utmTerm,
        utm_source: utmSource,
        partnership: _get(qs, "utm_campaign"),
        register_url: window.location.href,
        platform: "fetch"
      },
      service: "create_account"
    });

    yield put(actions.setLoading(false));

    if (!res) {
      return;
    }

    const errors = _get(res, "errors");

    if (!_isEmpty(errors)) {
      const formError = _get(errors, "form", [])[0];
      if (formError === "You are not verified.") {
        return;
      }

      yield put(formActions.parseServerErrors(errors, REGISTER_FORM));
      return;
    }

    invokeMessage("success", "Sign-up successful! Please check your inbox.");

    const cb = () => {
      history.push({
        pathname: `${ROUTES.LOGIN}`,
        search: `?${queryString.stringify({
          email,
          account_type: accountType,
          post_register: "y"
        })}`
      });
    };

    // if (isLive) {
    //   GATrackScript.trackPostRegistrationAction(cb);
    // } else {
    //   cb();
    // }
    cb();
  } catch (err) {
    yield put(actions.setLoading(false));
    console.error(err);
  }
}

export function* handleVerifyOtp(action: ActionType<typeof actions.verifyOtp>) {
  yield put(
    actions.verifyMobile(
      action.payload.smsOtp,
      { ...action.payload },
      action.payload.loadingFunc
    )
  );
}

export function* handleResendVerifyOtp(
  action: ActionType<typeof actions.resendVerifyOtp>
) {
  const { cb = () => null } = action.payload || {};
  yield put(actions.requestResendVerifyMobile(cb, action.payload.loadingFunc));
}
