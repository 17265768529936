import React from "react";

import ShortCurrency from "src/ipm-shared/components/ShortCurrency";

import styles from "./InvoiceOverview.module.scss";
import classNames from "classnames";

const InvoiceOverviewCollapsed = ({ invoice = {} }: any) => {
  return (
    <div className={styles.wrapper}>
      <p className={styles.normalText}>Customer</p>
      <p className={styles.boldText}>{invoice.customerName}</p>

      <hr />

      <div className={styles.flexBetween}>
        <p className={styles.boldText}>Total Amount Due</p>
        <p className={styles.boldText}>
          <ShortCurrency
            value={invoice.totalAmount}
            currencyId={invoice.currencyId}
          />
        </p>
      </div>
    </div>
  );
};

const InvoiceOverviewExpand = ({ invoice = {} }: any) => {
  return (
    <div className={styles.wrapper}>
      <p className={styles.normalText}>Customer</p>
      <p className={styles.boldText}>{invoice.customerName}</p>

      {invoice.receiptNumber ? (
        <>
          <div className={classNames(styles.flexBetween, styles.paddingTop)}>
            <p className={styles.helperText}>Invoice number</p>
            <p className={styles.helperText}>{invoice.invoiceNumber}</p>
          </div>
          <div className={classNames(styles.flexBetween, styles.paddingBottom)}>
            <p className={styles.helperText}>Receipt Number</p>
            <p className={styles.helperText}>{invoice.receiptNumber}</p>
          </div>
        </>
      ) : (
        <div className={styles.flexBetween}>
          <p className={styles.normalText}>Invoice number</p>
          <p className={styles.normalText}>{invoice.invoiceNumber}</p>
        </div>
      )}

      <hr className={styles.hrLine} />

      <div className={styles.flexBetween}>
        <p className={styles.boldText}>Total Amount Due</p>
        <p className={styles.boldText}>
          <ShortCurrency
            value={invoice.totalAmount}
            currencyId={invoice.currencyId}
          />
        </p>
      </div>

      <hr className={styles.hrLine} />

      <div className={styles.flexBetween}>
        <p className={styles.normalText}>Invoice Due Date</p>
        <p className={styles.normalText}>{invoice.dueDate}</p>
      </div>

      {invoice.receiptNumber && (
        <>
          <hr className={styles.hrLine} />

          <div className={classNames(styles.flexBetween, styles.paddingTop)}>
            <p className={styles.helperText}>Charge Date</p>
            <p className={styles.helperText}>{invoice.chargeDate}</p>
          </div>

          <div className={styles.flexBetween}>
            <p className={styles.helperText}>Payout Date</p>
            <p className={styles.helperText}>{invoice.payoutDate}</p>
          </div>
        </>
      )}
    </div>
  );
};

export default {
  InvoiceOverviewCollapsed,
  InvoiceOverviewExpand
};
