import React from "react";
import _get from "lodash-es/get";

import InputText from "src/bepaid/components/Form/controls/InputText";
import Image from "src/bepaid/components/Image";
import SelectRegionCity from "src/bepaid/components/SelectRegionCity";
import IconArrowDown from "src/bepaid/assets/images/common/drop_down_arrow_grey.svg";

import Select from "src/bepaid/components/Form/controls/Select";
import {
  RELATIONSHIP,
  BANK_ACCOUNT_TYPE
} from "src/ipm-shared/store/model/International/consts";
import IBANToolTip from "src/bepaid/components/IBANToolTip";
import {
  COUNTRY_NATIONAL_ID_NUMBER_PAY_FETCH,
  COUNTRY_NATIONAL_SELECT_BANK_ID,
  COUNTRY_BUSINESS_RELATIONSHIP,
  COUNTRY_NATIONAL_BSB_CODE,
  COUNTRY_NATIONAL_IFSC,
  COUNTRY_NATIONAL_BANK_CODE,
  COUNTRY_NATIONAL_ABA,
  COUNTRY_NATIONAL_SORT_CODE,
  COUNTRY_NATIONAL_IBAN,
  COUNTRY_NATIONAL_CNAPS,
  COUNTRY_NATIONAL_REGION_WITH_ID,
  COUNTRY_NATIONAL_CITY,
  COUNTRY_NATIONAL_REGION
} from "src/ipm-shared/store/model/Payee/const";

const RecipientBankAccountFormContent = ({
  editedPayee,
  ADD_FORM,
  commonFormItemProps,
  entity,
  initName,
  countryCode,
  filterBank
}: any) => {
  return (
    <>
      <InputText
        name={"bank_account_holder_name"}
        form={ADD_FORM}
        label={"Bank account holder name"}
        placeholder={"Bank account holder name"}
        defaultValue={
          _get(editedPayee, "data.bank_account_holder_name", undefined) ||
          (entity !== "supplier" ? initName : "")
        }
        {...commonFormItemProps}
      />
      {COUNTRY_NATIONAL_ID_NUMBER_PAY_FETCH.includes(countryCode) && (
        <InputText
          name={"national_identification_number"}
          form={ADD_FORM}
          label={"National ID Number"}
          placeholder={"National ID Number"}
          defaultValue={_get(
            editedPayee,
            "data.national_identification_number",
            undefined
          )}
          {...commonFormItemProps}
        />
      )}
      {COUNTRY_NATIONAL_SELECT_BANK_ID.includes(countryCode) ? (
        <Select
          form={ADD_FORM}
          name={"bank_id"}
          placeholder={"Bank"}
          label={"Bank"}
          options={filterBank}
          defaultValue={
            _get(editedPayee, "bank") === 0 ? null : _get(editedPayee, "bank")
          }
          suffixIcon={<Image src={IconArrowDown} />}
          {...commonFormItemProps}
        />
      ) : (
        <InputText
          name="bank_raw_name"
          placeholder={"Bank"}
          label={"Bank"}
          form={ADD_FORM}
          defaultValue={_get(editedPayee, "data.bank_raw_name", undefined)}
          {...commonFormItemProps}
        />
      )}
      {countryCode === "JP" && (
        <Select
          name={"bank_account_type"}
          form={ADD_FORM}
          label={"Bank account type"}
          placeholder={"Bank account type"}
          defaultValue={_get(editedPayee, "data.bank_account_type", undefined)}
          options={BANK_ACCOUNT_TYPE}
          suffixIcon={<Image src={IconArrowDown} />}
          {...commonFormItemProps}
        />
      )}
      {countryCode === "CN" && (
        <>
          <InputText
            name={"bank_account_holder_name_in_chinese_characters"}
            placeholder={"Bank account holder name in Chinese characters"}
            label={"Bank account holder name in Chinese characters"}
            form={ADD_FORM}
            defaultValue={_get(
              editedPayee,
              "data.bank_account_holder_name_in_chinese_characters",
              undefined
            )}
            {...commonFormItemProps}
          />
          <InputText
            name={"branch_name"}
            form={ADD_FORM}
            label={"Branch name"}
            placeholder={"Branch name"}
            defaultValue={_get(editedPayee, "data.branch_name")}
            {...commonFormItemProps}
          />
        </>
      )}
      <InputText
        name={"bank_swift_code"}
        placeholder={"SWIFT Code"}
        label={"SWIFT Code"}
        form={ADD_FORM}
        defaultValue={_get(editedPayee, "data.bank_raw_swift_code", undefined)}
        {...commonFormItemProps}
      />
      <InputText
        name={"account_number"}
        form={ADD_FORM}
        label={"Account number"}
        placeholder={"Account number"}
        defaultValue={_get(editedPayee, "account_number")}
        {...commonFormItemProps}
      />
      {countryCode === "JP" && (
        <>
          <InputText
            name={"branch_code"}
            form={ADD_FORM}
            label={"Branch code"}
            placeholder={"Branch code"}
            defaultValue={_get(editedPayee, "data.branch_code")}
            {...commonFormItemProps}
          />
        </>
      )}
      {entity === "self_transfer" && (
        <>
          <InputText
            name={"address"}
            form={ADD_FORM}
            label={"Address"}
            placeholder={"Address"}
            defaultValue={_get(editedPayee, "data.address", "")}
            {...commonFormItemProps}
          />
          {COUNTRY_NATIONAL_CITY.includes(countryCode) &&
            countryCode !== "CN" && (
              <InputText
                name={"city"}
                form={ADD_FORM}
                label={"City"}
                placeholder={"City"}
                defaultValue={_get(editedPayee, "data.city", "")}
                {...commonFormItemProps}
              />
            )}
          {COUNTRY_NATIONAL_REGION.includes(countryCode) && (
            <InputText
              name={"region"}
              form={ADD_FORM}
              label={"Region"}
              placeholder={"Region"}
              defaultValue={_get(editedPayee, "data.region", "")}
              {...commonFormItemProps}
            />
          )}
          <InputText
            name={"state_or_province"}
            form={ADD_FORM}
            label={"State/Province"}
            placeholder={"State/Province"}
            defaultValue={_get(editedPayee, "data.state_or_province", "")}
            {...commonFormItemProps}
          />
          <InputText
            name={"postal_code"}
            form={ADD_FORM}
            label={"Postal Code"}
            placeholder={"Postal Code"}
            defaultValue={_get(editedPayee, "data.postal_code", "")}
            {...commonFormItemProps}
          />
          {COUNTRY_NATIONAL_REGION_WITH_ID.includes(countryCode) && (
            <SelectRegionCity
              form={ADD_FORM}
              fields={{
                regionField: {
                  label: "Region",
                  name: "region",
                  placeholder: "Region",
                  defaultValue: _get(editedPayee, "data.region", ""),
                  extendField: "region_id"
                },
                cityField: {
                  label: "City",
                  name: "city",
                  placeholder: "City",
                  defaultValue: _get(editedPayee, "data.city", ""),
                  extendField: "city_id"
                }
              }}
              {...commonFormItemProps}
            />
          )}
        </>
      )}

      {COUNTRY_BUSINESS_RELATIONSHIP.includes(countryCode) && (
        <Select
          name={"relationship"}
          form={ADD_FORM}
          label={"Relationship"}
          placeholder={"Relationship"}
          defaultValue={_get(editedPayee, "data.relationship", undefined)}
          options={RELATIONSHIP}
          suffixIcon={<Image src={IconArrowDown} />}
          {...commonFormItemProps}
        />
      )}
      {COUNTRY_NATIONAL_BSB_CODE.includes(countryCode) && (
        <InputText
          name={"bsb_code"}
          form={ADD_FORM}
          label={"BSB Code"}
          placeholder={"BSB Code"}
          defaultValue={_get(editedPayee, "data.bsb_code", "")}
          {...commonFormItemProps}
        />
      )}
      {COUNTRY_NATIONAL_IFSC.includes(countryCode) && (
        <InputText
          name={"ifsc_code"}
          form={ADD_FORM}
          label={"Indian Financial System Code (IFSC)"}
          placeholder={"Indian Financial System Code (IFSC)"}
          defaultValue={_get(editedPayee, "data.ifsc_code", "")}
          {...commonFormItemProps}
        />
      )}
      {COUNTRY_NATIONAL_BANK_CODE.includes(countryCode) && (
        <InputText
          name={"bank_code"}
          form={ADD_FORM}
          label={"Bank Code"}
          placeholder={"Bank Code"}
          defaultValue={_get(editedPayee, "data.bank_code", "")}
          {...commonFormItemProps}
        />
      )}
      {COUNTRY_NATIONAL_ABA.includes(countryCode) && (
        <InputText
          name={"aba"}
          form={ADD_FORM}
          label={"ABA/Routing Number"}
          placeholder={"ABA/Routing Number"}
          defaultValue={_get(editedPayee, "data.aba", "")}
          {...commonFormItemProps}
        />
      )}
      {COUNTRY_NATIONAL_SORT_CODE.includes(countryCode) && (
        <InputText
          name={"sort_code"}
          form={ADD_FORM}
          label={"Sort Code"}
          placeholder={"Sort Code"}
          defaultValue={_get(editedPayee, "data.sort_code", "")}
          {...commonFormItemProps}
        />
      )}
      {COUNTRY_NATIONAL_IBAN.includes(countryCode) && (
        <>
          <IBANToolTip countryCode={countryCode} />
          <InputText
            name={"iban"}
            form={ADD_FORM}
            label={"IBAN"}
            placeholder={"IBAN"}
            defaultValue={_get(editedPayee, "data.iban", "")}
            {...commonFormItemProps}
          />
        </>
      )}
      {COUNTRY_NATIONAL_CNAPS.includes(countryCode) && (
        <InputText
          name={"cnaps"}
          form={ADD_FORM}
          label={"CNAPS"}
          placeholder={"CNAPS"}
          defaultValue={_get(editedPayee, "data.cnaps", "")}
          {...commonFormItemProps}
        />
      )}
    </>
  );
};

export default RecipientBankAccountFormContent;
