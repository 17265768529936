import { getType } from "typesafe-actions";
import * as types from "./types";
import * as actions from "./actions";
import _isEmpty from "lodash-es/isEmpty";
import { AccountType } from "./actions";

export const defaultProfile: AccountProfile = {
  accountCountryCode: "",
  acquirerId: -1,
  countryCode: "",
  countryId: -1,
  currencyCode: "",
  currencyId: -1,
  email: "",
  emailAwaitingVerification: "",
  features: 0,
  firstName: "",
  isProduction: false,
  lastName: "",
  mobileCountryId: 0,
  mobileNumber: "",
  paymentPaidCurrencyCode: "",
  paymentPaidCurrencyId: 0,
  prefilledCompany: undefined,
  type: "personal" as AccountType,
  userSettingRequired: {
    accountType: false,
    bePaidTncConfirmation: false,
    closeKycStatusBar: false,
    creditProgramOfferV1Enabled: false,
    creditProgramV1Notification: false,
    crossBorder: false,
    crossBorderCardFlag: false,
    crossBorderNewIcon: false,
    dashboardTheme: undefined,
    editScheduleIndicator: false,
    fb199: false,
    firstMySignup: false,
    intPayEnabledNotification: false,
    intPayReminderNotification: false,
    irasPopup: false,
    myCreditCard: false,
    nextDayPayout: false,
    nricPhoto: false,
    paymentInterestConfirmation: false,
    pointsGuarantee: false,
    referral: false,
    rentPromo: false,
    sgMyInfo: false,
    sgTax2019: false,
    twoDayPayoutAnnouncement: false,
    oldCardTokenArchivedNotification: false,
    kycFirstTimePopup: false,
    personalFirstLoginTourInPayFetch: true,
    businessFirstLoginPayTourInPayFetch: true,
    businessFirstLoginFetchTourInPayFetch: true,
    fetchHighlightTour: false,
    showSuggestionModal: false,
    amexPromoModal: false,
    additionalDataModal: false,
    flashpayPromoModal: false
  },

  displayedEmail: "",
  displayedFirstName: window.localStorage.getItem("displayed_first_name") || "",
  displayedLastName: window.localStorage.getItem("displayed_last_name") || "",
  hasCompany: true,
  hasCreditCard: true,
  roleId: 3,
  wallexKycStatus: 0,
  canAccessCryptoPayment: false
};

export type AccountProfile = {
  accountCountryCode: string;
  acquirerId: number;
  collectedAccountCurrencyId?: number;
  paymentPaidCurrencyCode: string;
  paymentPaidCurrencyId: number;
  currencyId: number;
  countryCode: string;
  countryId: number;
  currencyCode: string;
  mobileCountryId: number;
  mobileNumber: string;
  email: string;
  emailAwaitingVerification?: string;
  displayedEmail: string;
  firstName: string;
  displayedFirstName: string;
  lastName: string;
  displayedLastName: string;
  hasCompany?: boolean;
  hasCreditCard: boolean | null;
  type: actions.AccountType;
  roleId: number;
  features: number; // Bit-mask
  userSettingRequired: {
    accountType: boolean;
    bePaidTncConfirmation: boolean;
    closeKycStatusBar: boolean;
    creditProgramOfferV1Enabled: boolean;
    creditProgramV1Notification: boolean;
    crossBorder: boolean;
    crossBorderCardFlag: boolean;
    crossBorderNewIcon: boolean;
    dashboardTheme?: string;
    editScheduleIndicator: boolean;
    fb199: boolean;
    firstMySignup: boolean;
    intPayEnabledNotification: boolean;
    intPayReminderNotification: boolean;
    irasPopup: boolean;
    myCreditCard: boolean;
    nextDayPayout: boolean;
    nricPhoto: boolean;
    paymentInterestConfirmation: boolean;
    pointsGuarantee: boolean;
    referral: boolean;
    rentPromo: boolean;
    sgMyInfo: boolean;
    sgTax2019: boolean;
    twoDayPayoutAnnouncement: boolean;
    oldCardTokenArchivedNotification: boolean;
    kycFirstTimePopup: boolean;
    personalFirstLoginTourInPayFetch: boolean;
    businessFirstLoginPayTourInPayFetch: boolean;
    businessFirstLoginFetchTourInPayFetch: boolean;
    fetchHighlightTour: boolean;
    showSuggestionModal: boolean;
    amexPromoModal: boolean;
    additionalDataModal: boolean;
    flashpayPromoModal: boolean;
  };
  createdAt?: string;
  prefilledCompany?: string;
  intercomIdentifier?: string;
  isProduction: boolean;
  wallexKycStatus: number;
  canAccessCryptoPayment: boolean;
};

export type AccountProfileBasic = {
  email: string;
  firstName: string;
  lastName: string;
};

export type AccountProfilesState = {
  readonly isFetching: boolean;
  readonly byId: { [email: string]: AccountProfile };
  readonly currentEmail: string;
  readonly hasFetched: boolean;
};

const defaultState: AccountProfilesState = {
  byId: {},
  currentEmail: "",
  hasFetched: false,
  isFetching: false
};

export default (state: AccountProfilesState, action: types.Action) => {
  if (_isEmpty(state)) {
    state = defaultState;
  }
  const email = state.currentEmail;
  switch (action.type) {
    case getType(actions.setCurrentAccountProfile):
      return {
        ...state,
        currentEmail: action.payload.email
      };
    case getType(actions.setAccountProfiles):
      const m = {};
      action.payload.profiles.map(profile => {
        m[profile.email] = profile;
      });
      return {
        ...state,
        byId: m,
        hasFetched: action.payload.hasFetched,
        isFetching: action.payload.isFetching
      };

    // case getType(actions.setCrossBorderCardFlag):
    //   if (state.byId[email] !== undefined) {
    //     return {
    //       ...state,
    //       byId: {
    //         [email]: {
    //           ...state.byId[email],
    //           userSettingRequired: {
    //             ...state.byId[email].userSettingRequired,
    //             crossBorderCardFlag: action.payload.crossBorderCardFlag
    //           }
    //         }
    //       }
    //     };
    //   }
    //   break;

    case getType(actions.updateUserSetting):
      if (state.byId[email] !== undefined && action.payload.prop !== "") {
        return {
          ...state,
          byId: {
            [email]: {
              ...state.byId[email],
              userSettingRequired: {
                ...state.byId[email].userSettingRequired,
                [action.payload.prop]: action.payload.value
              }
            }
          }
        };
      }
      break;

    case getType(actions.setCurrentCountry):
      if (state.byId[email] !== undefined) {
        return {
          ...state,
          byId: {
            [email]: {
              ...state.byId[email],
              countryCode: action.payload?.code,
              countryId: action.payload?.id
            }
          }
        };
      }
      break;

    case getType(actions.setCurrentCurrency):
      if (state.byId[email] !== undefined) {
        return {
          ...state,
          byId: {
            [email]: {
              ...state.byId[email],
              currencyCode: action.payload?.code,
              currencyId: action.payload?.id
            }
          }
        };
      }
      break;
  }
  return state;
};
