import React from "react";
// import { withRouter } from "react-router";

import _get from "lodash-es/get";
import _truncate from "lodash-es/truncate";
import classNames from "classnames";
import Menu from "antd/es/menu";
import Typography from "antd/es/typography";

import { history } from "src/ipm-shared/store";
import { FetchModalID } from "src/ipm-shared/components/GlobalUI/actions";
import ROUTES, { isPayUrl } from "src/bepaid/routes";
import { PLATFORM } from "src/ipm-shared/components/GlobalUI/reducers";
// import { ACCOUNT_TYPE } from "src/ipm-shared/store/model/AccountProfile/const";

import { createDesktopMenuMaps, getMenuSelectedKeys, IMenu } from "./utils";

import styles from "../Menu.module.scss";
import { WALLEX_KYC_STATUS } from "src/ipm-shared/store/model/OnBoarding/consts";

const DesktopMenuBody = ({
  platform,
  company,
  companies,
  toggleModal,
  requireKYC,
  onClickLogout,
  switchPlatform,
  openedModalIds,
  match,
  isBusinessAccount = false,
  wallexKycStatus,
  isSingaporeAccount,
  accountUserRoleId
}: any) => {
  const companyName = _get(company, "name", "");

  const currentSubMenuSelected = {
    [ROUTES.RECIPIENTS_LANDLORDS]: ["sub-2"],
    [ROUTES.RECIPIENTS_EMPLOYEES]: ["sub-2"],
    [ROUTES.RECIPIENTS_SUPPLIERS]: ["sub-2"],
    [ROUTES.RECIPIENTS_BANK_ACCOUNTS]: ["sub-2"]
  }[window.location.pathname];
  const [openKeys, setOpenKeys] = React.useState<string[]>(
    currentSubMenuSelected || []
  );

  const handleToggleModalKYC = (modalId: string) => {
    requireKYC(() => {
      toggleModal(modalId);
    });
  };

  const FETCH_MENU_MAPS: IMenu[] = [
    {
      title: "Dashboard",
      onClick: history.push,
      path: ROUTES.ROOT
    },
    {
      title: "Invoices",
      onClick: history.push,
      path: ROUTES.INVOICES
    },
    {
      title: "Customers",
      path: ROUTES.CUSTOMERS,
      onClick: history.push
    },
    {
      title: "Insights",
      path: ROUTES.INSIGHTS,
      onClick: history.push
    },
    {
      title: "Profile",
      subMenus: [
        {
          title: "Personal information",
          onClick: toggleModal,
          modalId: FetchModalID.UPDATE_PERSONAL_INFO
        },
        {
          title: "Change email",
          onClick: toggleModal,
          modalId: FetchModalID.CHANGE_EMAIL
        },
        {
          title: "Change password",
          onClick: toggleModal,
          modalId: FetchModalID.CHANGE_PASSWORD
        },
        {
          title: "Users",
          onClick: history.push,
          path: ROUTES.USERS
        }
      ]
    },
    {
      title: "Companies",
      onClick: toggleModal,
      modalId: FetchModalID.LIST_COMPANIES
    },
    {
      title: "Fetch settings",
      onClick: handleToggleModalKYC,
      modalId: FetchModalID.UPDATE_ACCOUNT_SETTINGS
    },
    {
      title: "Invoice settings",
      subMenus: [
        {
          title: "Business information",
          onClick: handleToggleModalKYC,
          modalId: FetchModalID.UPDATE_BUSINESS_INFORMATION
        },
        {
          title: "Draft invoice",
          onClick: handleToggleModalKYC,
          modalId: FetchModalID.UPDATE_DRAFT_INVOICE_SETTING
        }
        // { title: "Invoice template", onClick: () => history.push(ROUTES.ROOT) }
      ]
    },
    {
      title: "Support",
      onClick: () => window.open(ROUTES.SUPPORT, "_blank")
    },
    { title: "Sign out", onClick: onClickLogout }
  ];

  const recipientsSubMenus = [
    {
      title: "Landlords",
      onClick: history.push,
      path: ROUTES.RECIPIENTS_LANDLORDS
    },
    {
      title: "Employees",
      onClick: history.push,
      path: ROUTES.RECIPIENTS_EMPLOYEES
    },
    {
      title: "Suppliers",
      onClick: history.push,
      path: ROUTES.RECIPIENTS_SUPPLIERS
    }
  ];

  if (isSingaporeAccount && wallexKycStatus === WALLEX_KYC_STATUS.APPROVED) {
    recipientsSubMenus.push({
      title: "Bank accounts",
      onClick: history.push,
      path: ROUTES.RECIPIENTS_BANK_ACCOUNTS
    });
  }

  const PAY_MENU_MAPS: IMenu[] = [
    {
      title: "Dashboard",
      onClick: history.push,
      path: ROUTES.ROOT
    },
    {
      title: "Payments",
      onClick: history.push,
      path: ROUTES.PAYMENTS
    },
    {
      title: "Recipients",
      subMenus: recipientsSubMenus
    },
    {
      title: "Insights",
      onClick: history.push,
      path: ROUTES.INSIGHTS_PAY
    },
    {
      title: "Profile",
      subMenus: [
        {
          title: "Personal information",
          onClick: toggleModal,
          modalId: FetchModalID.UPDATE_PERSONAL_INFO
        },
        {
          title: "Change email",
          onClick: toggleModal,
          modalId: FetchModalID.CHANGE_EMAIL
        },
        {
          title: "Change password",
          onClick: toggleModal,
          modalId: FetchModalID.CHANGE_PASSWORD
        },
        {
          title: "Users",
          onClick: history.push,
          path: ROUTES.USERS
        }
      ]
    },
    {
      title: "Companies",
      onClick: (modalId: string) => {
        toggleModal(modalId);
      },
      modalId: FetchModalID.LIST_COMPANIES
    },
    {
      title: "Wallet",
      onClick: toggleModal,
      modalId: FetchModalID.LIST_CARD_MODAL
    },
    {
      title: "Referrals",
      onClick: history.push,
      path: ROUTES.REFERRAL
    },
    {
      title: "Support",
      onClick: () => window.open(ROUTES.SUPPORT, "_blank")
    },
    { title: "Sign out", onClick: onClickLogout }
  ];

  const { transformedMenus: bodyMenuMaps, menuKeys } = createDesktopMenuMaps(
    platform === PLATFORM.PAY
      ? !isBusinessAccount
        ? PAY_MENU_MAPS.filter(menu => menu.title !== "Companies")
        : PAY_MENU_MAPS
      : FETCH_MENU_MAPS
  );

  const menuSelectedKeys = getMenuSelectedKeys(menuKeys, openedModalIds, match);

  const rootSubmenuKeys = bodyMenuMaps.map((item, index) => {
    if (item.subMenus) {
      return `sub-${index}`;
    }
    return null;
  });

  const onOpenChange = (keys: string[]) => {
    const latestOpenKey: any = keys.find(
      // @ts-ignore
      key => openKeys.indexOf(key) === -1
    );
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      // @ts-ignore
      setOpenKeys(keys);
    } else {
      // @ts-ignore
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  // const showAddCompanyModal = () => {
  //   toggleModal(FetchModalID.ADD_COMPANY);
  // };

  const switchCompany = () => {
    toggleModal(FetchModalID.LIST_COMPANIES, {
      switchCompany: true
    });
  };

  React.useEffect(() => {
    if (currentSubMenuSelected) {
      if (isPayUrl(window.location.pathname)) {
        switchPlatform(PLATFORM.PAY);
      } else {
        switchPlatform(PLATFORM.FETCH);
      }
    }

    setOpenKeys(currentSubMenuSelected || []);
  }, []);

  return (
    <div className={styles.body}>
      {isBusinessAccount && companies.length > 0 && (
        <div className={styles.company}>
          <>
            <Typography.Text className={styles.name}>
              {_truncate(companyName, { length: 20 })}
            </Typography.Text>
            {companies.length > 1 && (
              <Typography.Link
                className={styles.purple}
                onClick={switchCompany}
              >
                Switch company
              </Typography.Link>
            )}
          </>
        </div>
      )}
      <div className={styles.menu}>
        <Menu
          selectedKeys={menuSelectedKeys}
          mode="inline"
          openKeys={openKeys}
          onOpenChange={onOpenChange}
        >
          {bodyMenuMaps.map((item, index) => {
            if (item.subMenus) {
              return (
                <Menu.SubMenu key={`sub-${index}`} title={item.title}>
                  {item.subMenus.map(subItem => {
                    return (
                      !(
                        (!isBusinessAccount && subItem.title === "Employees") ||
                        (subItem.title === "Users" && accountUserRoleId !== 4)
                      ) && (
                        <Menu.Item
                          key={String(subItem.key)}
                          onClick={subItem.onClick}
                        >
                          {subItem.title}
                        </Menu.Item>
                      )
                    );
                  })}
                </Menu.SubMenu>
              );
            }
            return (
              <Menu.Item
                key={item.key}
                className={classNames({
                  [styles.lastItem]: index === bodyMenuMaps.length - 1
                })}
                onClick={item.onClick}
              >
                {item.title}
              </Menu.Item>
            );
          })}
        </Menu>
      </div>
    </div>
  );
};

export default DesktopMenuBody;
