import React, { useEffect } from "react";

import { connect } from "react-redux";
import { RootState } from "src/ipm-shared/store/model/reducers";

import * as selectors from "src/bepaid/store/model/Fetch/selectors";
import * as actions from "src/bepaid/store/model/Fetch/actions";
import * as queryString from "query-string";

const mapStateToProps = (state: RootState) => ({
  isAustraliaAccount: selectors.isAustraliaAccount(state),
  acquirerId: selectors.getAcquirerId(state)
});

const mapDispatchToProps = {
  toggleModal: actions.toggleModal,
  closeModal: actions.closeModal
};

export type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

const withPopup = (
  WrapperComponent: any,
  {
    modalId,
    extraInfo,
    pushExtraInfoFromProps,
    refModalIds = []
  }: {
    modalId: number;
    extraInfo?: any;
    pushExtraInfoFromProps?: any;
    refModalIds?: number[];
  }
) => {
  const HOC = (props: any) => {
    useEffect(() => {
      const qs = queryString.parse(window.location.search);
      const modalIdOnQuery = parseInt(qs.m as string, 10);

      if (modalIdOnQuery && refModalIds.includes(modalIdOnQuery)) {
        return () => {
          props.closeModal(modalIdOnQuery);
        };
      }

      if (refModalIds && refModalIds.includes(modalIdOnQuery)) {
        // no need to open modalId
      } else {
        let extraInfoData = extraInfo;
        if (pushExtraInfoFromProps) {
          extraInfoData = {
            ...extraInfoData,
            ...pushExtraInfoFromProps(props)
          };
        }

        props.toggleModal(modalId, extraInfoData);
      }

      return () => {
        props.closeModal(modalId);

        if (modalIdOnQuery) {
          props.closeModal(modalIdOnQuery);
        }
      };
    }, [window.location.pathname]);

    return <WrapperComponent {...props} isBgOfPopup={true} />;
  };

  return connect(mapStateToProps, mapDispatchToProps)(HOC);
};

export default withPopup;
