interface IProfileCustomer {
  name: string;
  email: string;
  mobileNumber: string;
  phoneNumber: string;
  companyName: string;
  registrationNumber: string;
  addressLine1: string;
  addressLine2: string;
  customerCommunicationMethod: string;
}

export const getCompletedProfileCustomer = (data: IProfileCustomer) => {
  const P28 = data.customerCommunicationMethod
    ? data.customerCommunicationMethod === "email"
      ? ["name", "email"]
      : ["name", "mobileNumber"]
    : ["name", "email"];
  const P56 = [
    ...P28,
    data.customerCommunicationMethod
      ? data.customerCommunicationMethod === "email"
        ? "mobileNumber"
        : "email"
      : "mobileNumber"
  ];
  const P70 = [...P56, "phoneNumber"];
  const P84 = [...P70, "companyName", "registrationNumber"];
  const P100 = [...P84, ["addressLine1", "addressLine2"]];

  const steps = [
    {
      percent: 0,
      requiredFields: []
    },
    {
      percent: 28,
      requiredFields: P28
    },
    {
      percent: 56,
      requiredFields: P56
    },
    {
      percent: 70,
      requiredFields: P70
    },
    {
      percent: 84,
      requiredFields: P84
    },
    {
      percent: 100,
      requiredFields: P100
    }
  ];

  const step = steps.reverse().find(stp => {
    const { requiredFields } = stp;

    return requiredFields.every(field => {
      if (Array.isArray(field)) {
        return (
          (data[field[0]] && data[field[0]] !== "") ||
          (data[field[1]] && data[field[1]] !== "")
        );
      } else {
        return data[field] && data[field] !== "";
      }
    });
  });

  return step ? step.percent : 0;
};
