// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CompanyForm_regNoSection__2i21M{margin-bottom:1.5rem;margin-top:1.5rem}.CompanyForm_regNoSection__2i21M .ant-form-item{margin-bottom:.5rem !important}.CompanyForm_regNoSection__2i21M .CompanyForm_codeNoticeText__3J9VL{font-size:.75rem;color:#74747b}.CompanyForm_codeSection__2vD8a{padding-top:.5rem}.CompanyForm_centerText__3R54g{text-align:center}.CompanyForm_select__2GvIM{width:100%;border-color:#74747b}", "",{"version":3,"sources":["webpack://./src/bepaid/layouts/Menu/components/Companies/components/CompanyForm/CompanyForm.module.scss"],"names":[],"mappings":"AAAA,iCAAc,oBAAoB,CAAC,iBAAiB,CAAC,gDAAqC,8BAA8B,CAAC,oEAA8B,gBAAgB,CAAC,aAAa,CAAC,gCAAa,iBAAiB,CAAC,+BAAY,iBAAiB,CAAC,2BAAQ,UAAU,CAAC,oBAAoB","sourcesContent":[".regNoSection{margin-bottom:1.5rem;margin-top:1.5rem}.regNoSection :global .ant-form-item{margin-bottom:.5rem !important}.regNoSection .codeNoticeText{font-size:.75rem;color:#74747b}.codeSection{padding-top:.5rem}.centerText{text-align:center}.select{width:100%;border-color:#74747b}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"regNoSection": "CompanyForm_regNoSection__2i21M",
	"codeNoticeText": "CompanyForm_codeNoticeText__3J9VL",
	"codeSection": "CompanyForm_codeSection__2vD8a",
	"centerText": "CompanyForm_centerText__3R54g",
	"select": "CompanyForm_select__2GvIM"
};
export default ___CSS_LOADER_EXPORT___;
