import { connect } from "react-redux";
import React, { useEffect, useState } from "react";

import { FORM } from "src/ipm-shared/store/model/Payee/const";
import ExpandBox from "../../ExpandBox";
import IconUpload from "src/bepaid/assets/images/common/icon_upload.svg";
import FormStyles from "src/bepaid/components/Form/CommonForm.module.scss";
import InputFile from "src/bepaid/components/Form/controls/InputFile";
import Form from "src/bepaid/components/Form";
import InputText from "src/bepaid/components/Form/controls/InputText";

import CurrencyUtil from "src/ipm-shared/Utils/Currency";
import FileUtils from "src/ipm-shared/Utils/Files";
import { useMakePaymentUrlParams } from "src/bepaid/pages/Payments/hooks";
import T from "src/ipm-shared/Utils/Intl";
import _isEmpty from "lodash-es/isEmpty";
import _get from "lodash-es/get";

import * as actions from "../actions";
import * as selectors from "../selectors";

import styles from "../../ExpandBox/ExpandBox.module.scss";
import SelectGroup from "src/bepaid/components/SelectGroup";
import { RootState } from "src/ipm-shared/store/model/reducers";

const BankAccountForm = ({
  accountCountryCode,
  paymentCurrencyCode,
  payee,
  validateAmount,
  checkValidNoteToRecipient,
  filesControl,
  selectedPayment
}: any) => {
  const [currencyId, setCurrencyId] = useState(
    CurrencyUtil.convertFromCurrencyCode(paymentCurrencyCode).id
  );
  const selectedPaymentPayee = _get(selectedPayment, "payees", []).find(
    (p: any) => p.id === payee.id
  );
  const { entity } = useMakePaymentUrlParams();

  const [supportingDocuments, setSupportingDocuments] = useState<any>({
    fetch: false,
    value: []
  });

  const onChangeCurrencyCustom = (e: any) => {
    setCurrencyId(e.target.value);
  };

  const setAmountValue = (id: number, e: any) => {
    const amount = e.target.value;

    validateAmount({
      amount: parseFloat(amount) * 100,
      form: FORM,
      fieldName: name,
      isWallet: true
    });
  };

  const onChangeComments = (id: number, e: any) => {
    const comment = e.target.value;
    checkValidNoteToRecipient(comment, e.target.name, true, entity, () => {});
  };

  const HKD = CurrencyUtil.convertFromCurrencyCode("HKD");
  const USD = CurrencyUtil.convertFromCurrencyCode("USD");

  useEffect(() => {
    if (!_isEmpty(filesControl)) {
      const data = FileUtils.getInfoFromKeys(
        _get(filesControl, `${payee.id}`, [])
      );
      setSupportingDocuments({
        ...supportingDocuments,
        fetch: true,
        value: data
      });
    } else {
      setSupportingDocuments({
        ...supportingDocuments,
        fetch: true
      });
    }
  }, []);

  return (
    <Form requiredMark={false} layout="vertical">
      <div className={styles.inputMoney}>
        <div hidden={true}>
          {accountCountryCode === "HK" && (
            <SelectGroup
              name={"hk_multiple_currency_id"}
              form={FORM}
              className={styles.inputMoneyCustomSelect}
              options={[
                { label: HKD.$, value: HKD.id },
                { label: USD.$, value: USD.id }
              ]}
              onChange={onChangeCurrencyCustom}
              value={currencyId}
              defaultValue={HKD.id}
            />
          )}
        </div>

        <InputText
          labelNoColon={true}
          name={`supplier_amount_${payee.id}`}
          placeholder={`0.00`}
          label="Amount"
          prefix={CurrencyUtil.convertFromId(currencyId as number).$}
          defaultValue={
            selectedPaymentPayee
              ? _get(selectedPaymentPayee, "defaultAmount") / 100
              : ""
          }
          form={FORM}
          pattern={"__MONEY__"}
          revertValueOnMount={true}
          reserveValueOnUnmount={true}
          onChange={setAmountValue.bind(null, payee.id)}
        />
      </div>

      <InputText
        labelNoColon={true}
        name={`default_comments_${payee.id}`}
        placeholder={"Payment reference"}
        label={"Payment reference"}
        defaultValue={_get(selectedPaymentPayee, "defaultComments", "")}
        form={FORM}
        pattern={"__STATEMENT__"}
        revertValueOnMount={true}
        reserveValueOnUnmount={true}
        onChange={onChangeComments.bind(null, payee.id)}
      />
      <>
        <p className={styles.helperText}>
          {T.transl("BANK_STATEMENT_MESSAGE_INT")}
        </p>
      </>
      <>
        <p className={styles.invoiceFieldInstruction}>
          <b>{T.transl("BANK_STATEMENT_HEADER")}</b> must include:
        </p>
        <ul>
          <li>
            <span>{T.transl("BANK_STATEMENT_NAME")}</span>
          </li>
          <li>
            <span>{T.transl("BANK_STATEMENT_ACCOUNT")}</span>
          </li>
          <li>
            <span>{T.transl("BANK_STATEMENT_DATE")}</span>
          </li>
        </ul>
      </>
      {supportingDocuments.fetch && (
        <InputFile
          formItemLabel={""}
          name={`supporting_documents_${payee.id}`}
          btnClassName={FormStyles.boxShadow}
          allowedFileTypes={["jpg", "png", "heic", "pdf"]}
          purpose="self_transfer"
          form={FORM}
          maxFileAmount={1}
          label={"Upload Bank Statement"}
          icon={IconUpload}
          defaultValue={supportingDocuments.value}
          helperText={{
            showInEmptyMode: true,
            txt: (
              <span className={styles.inputFileHelperText}>
                Maximum file size: <b>16 MB</b>. Supported file types:{" "}
                <b>JPG, PDF, PNG</b>.
              </span>
            )
          }}
          revertValueOnMount={true}
          reserveValueOnUnmount={true}
        />
      )}
      <InputText
        hidden={true}
        form={FORM}
        name={`document_tag_supporting_documents_${payee.id}`}
        defaultValue={payee.id}
        reserveValueOnUnmount={true}
      />
    </Form>
  );
};

const View = (props: any) => {
  return (
    <ExpandBox
      name={`${_get(
        props.payee,
        "international.bankAccountHolderName"
      )}: ${_get(props.payee, "international.bankRawName")} - ${_get(
        props.payee,
        "accountNumber"
      )}`}
    >
      <BankAccountForm {...props} />
    </ExpandBox>
  );
};

const mapDispatchToProps = {
  validateAmount: actions.validateAmount,
  checkValidNoteToRecipient: actions.checkValidNoteToRecipient
};

const mapStateToProps = (state: RootState) => ({
  filesControl: selectors.getFileControl(
    state,
    selectors.getControlsPattern(state, /^document_tag_/),
    "document_tag_"
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(View);
