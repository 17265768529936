export const PAYMENT_METHOD_TYPES = {
  CARD: "card",
  CRYPTO: "crypto"
};

export const PaymentMethodData = [
  { id: 1, name: "cash", label: "Cash", note: "", payment_method_type: "card" },
  {
    id: 2,
    name: "cheque",
    label: "Cheque",
    note: "",
    payment_method_type: "card"
  },
  {
    id: 3,
    name: "bank_transfer",
    label: "Bank Transfer",
    note: "",
    payment_method_type: "card"
  },
  {
    id: 4,
    name: "other",
    label: "Other",
    note: "",
    payment_method_type: "card"
  },
  {
    id: 5,
    name: "Bitcoin",
    label: "BTC",
    note: "",
    order: 1,
    payment_method_type: "crypto"
  },
  {
    id: 6,
    name: "Lightning Bitcoin",
    label: "LNBTC",
    note: "",
    order: 2,
    payment_method_type: "crypto"
  },
  {
    id: 7,
    name: "Ethereum",
    label: "ETH",
    note: "",
    order: 3,
    payment_method_type: "crypto"
  },
  {
    id: 8,
    name: "USDC",
    label: "USDC",
    note: "",
    order: 4,
    payment_method_type: "crypto"
  },
  {
    id: 9,
    name: "USDT",
    label: "USDT",
    note: "",
    order: 6,
    payment_method_type: "crypto"
  },
  {
    id: 11,
    name: "Tron USDT",
    label: "USDT - TRC20",
    note: "",
    order: 7,
    payment_method_type: "crypto"
  }
];
