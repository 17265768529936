import React, { useEffect, useMemo, useState } from "react";
import _get from "lodash-es/get";
import _isEmpty from "lodash-es/isEmpty";
import _omit from "lodash-es/omit";

import AutoComplete from "../AutoComplete";

interface IProps {
  fields: {
    regionField: {
      name: string;
      label: string;
      placeholder: string;
      defaultValue: string;
      extendField: string;
    };
    cityField: {
      name: string;
      label: string;
      placeholder: string;
      defaultValue: string;
      extendField: string;
    };
  };
  [key: string]: any;
}

const View = (props: IProps) => {
  const { form, fields } = props;
  const commonFormItemProps = {
    reserveValueOnUnmount: props.reserveValueOnUnmount,
    revertValueOnMount: props.revertValueOnMount
  };
  const { regionField, cityField } = fields;
  const cityControl = props.getControl("city", form);
  const regionControl = props.getControl("region", form);

  const [regionOptions, setRegionOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);

  const [isSelectedRegion, setIsSelectedRegion] = useState(false);

  const [regionInputText, setRegionInputText] = useState("");
  const [cityInputText, setCityInputText] = useState("");

  const [defaultRegionValue, setDefaultRegionValue] = useState("");
  const [defaultCityValue, setDefaultCityValue] = useState("");

  useEffect(() => {
    props.fetchPayeeRegions({
      cb: (err?: any, data?: any) => {
        if (data.length > 0) {
          props.setControl({
            errors: [],
            name: regionField.name
          });
        }
        const list = data.map((item: any) => {
          return {
            label: `${item.name}`,
            value: {
              region: item.name,
              id: item.id
            },
            cities: item.cities
          };
        });

        setRegionOptions(list);
      }
    });
  }, []);

  useEffect(() => {
    if (!_isEmpty(regionOptions)) {
      if (regionField.defaultValue && cityField.defaultValue) {
        const itemRegion: any = regionOptions.find(
          (o: any) => o.value.region === regionField.defaultValue
        );

        const itemCity = _get(itemRegion, "cities", []).find(
          (city: any) => city.name === cityField.defaultValue
        );

        if (itemRegion && itemCity) {
          setDefaultRegionValue(regionField.defaultValue);
          setDefaultCityValue(cityField.defaultValue);
          setIsSelectedRegion(true);

          setCityOptions(
            itemRegion.cities.map((o: any) => {
              return {
                label: `${o.name}`,
                value: {
                  city: o.name,
                  id: o.id
                }
              };
            })
          );

          props.setControl({
            errors: [],
            form,
            name: regionField.extendField,
            value: itemRegion.value.id
          });
          props.setControl({
            errors: [],
            form,
            name: cityField.extendField,
            value: itemCity.id
          });
        } else {
          setDefaultRegionValue("");
          setDefaultCityValue("");
        }
      } else if (regionControl.value) {
        const itemRegion: any = regionOptions.find(
          (o: any) => o.value.region === regionControl.value
        );

        setCityOptions(
          itemRegion.cities.map((o: any) => {
            return {
              label: `${o.name}`,
              value: {
                city: o.name,
                id: o.id
              }
            };
          })
        );

        setDefaultCityValue(cityControl.value);
        setIsSelectedRegion(true);
      }
    }
  }, [regionOptions, regionField.defaultValue, cityField.defaultValue]);

  const onSearchRegionName = (value: string) => {
    setRegionInputText(value);
  };

  const onSelectRegion = (value: string) => {
    const item: any = regionOptions.find((o: any) => o.value.region === value);

    setCityOptions(
      item.cities.map((o: any) => {
        return {
          label: `${o.name}`,
          value: {
            city: o.name,
            id: o.id
          }
        };
      })
    );
    setIsSelectedRegion(true);

    props.setControl({
      errors: [],
      form,
      name: regionField.name,
      value: item.value.region
    });
    props.setControl({
      errors: [],
      form,
      name: regionField.extendField,
      value: item.value.id
    });

    setDefaultCityValue("");
    props.setControl({
      errors: [],
      form,
      name: cityField.name,
      value: undefined
    });
    props.setControl({
      errors: [],
      form,
      name: cityField.extendField,
      value: undefined
    });
  };

  const onSearchCityName = (value: string) => {
    setCityInputText(value);
  };

  const onChangeCityName = (value: string) => {
    setDefaultCityValue(value);
  };

  const onSelectCity = (value: string) => {
    const item: any = cityOptions.find((o: any) => o.value.city === value);

    props.setControl({
      errors: [],
      form,
      name: cityField.name,
      value: item.value.city
    });
    props.setControl({
      errors: [],
      form,
      name: cityField.extendField,
      value: item.value.id
    });
  };

  const regionOptionsFilter = useMemo(() => {
    const list = regionOptions;

    if (regionInputText.trim()) {
      return list.filter((l: any) => {
        return (
          String(l.label)
            .toLowerCase()
            .indexOf(regionInputText.toLowerCase()) > -1
        );
      });
    }

    return list;
  }, [regionInputText, regionOptions]);

  const cityOptionsFilter = useMemo(() => {
    const list = cityOptions;

    if (cityInputText.trim()) {
      return list.filter((l: any) => {
        return (
          String(l.label)
            .toLowerCase()
            .indexOf(cityInputText.toLowerCase()) > -1
        );
      });
    }

    return list;
  }, [cityInputText, cityOptions]);

  return (
    <div>
      <AutoComplete
        form={form}
        options={regionOptionsFilter.map((o: any) => ({
          label: o.label,
          value: o.value.region
        }))}
        onSearch={onSearchRegionName}
        onSelect={onSelectRegion}
        {..._omit(regionField, ["defaultValue"])}
        defaultValue={defaultRegionValue}
        {...commonFormItemProps}
      />
      <AutoComplete
        form={form}
        options={cityOptionsFilter.map((o: any) => ({
          label: o.label,
          value: o.value.city
        }))}
        value={defaultCityValue}
        onSearch={onSearchCityName}
        onSelect={onSelectCity}
        onChange={onChangeCityName}
        {..._omit(cityField, ["defaultValue"])}
        defaultValue={defaultCityValue}
        disabled={!isSelectedRegion}
        {...commonFormItemProps}
      />
    </div>
  );
};

export default View;
