export const KYC_FORM = "kyc_form";

export const RESIDENTIAL_STATUS = {
  FOREIGNER: "foreigner",
  PERMANENT_RESIDENT: "permanent_resident",
  SINGAPOREAN_CITIZEN: "citizen"
};

export const RESIDENTIAL_STATUS_LIST = [
  {
    code: "A", // ALIEN
    label: "Foreigner",
    value: "foreigner"
  },
  {
    code: "C", // CITIZEN
    label: "Citizen",
    value: "citizen"
  },
  {
    code: "P", // PR (permanent resident)
    label: "Permanent Resident",
    value: "permanent_resident"
  },
  {
    code: "U", // UNKNOWN
    label: "Unknown",
    value: "unknown"
  },
  {
    code: "N", // NOT APPLICABLE
    label: "Not Applicable",
    value: "notApplicable"
  }
  // < BLANK > (for FIN holders only)
];

export const GENDER = [
  {
    code: "M",
    label: "Male",
    value: "male"
  },
  {
    code: "F",
    label: "Female",
    value: "female"
  }
];

export const PASS_TYPE = [
  {
    code: "RPass",
    label: "Work Permit",
    value: "workPermit"
  },
  {
    code: "SPass",
    label: "S Pass",
    value: "sPass"
  },
  {
    code: "P1Pass",
    label: "Employment Pass",
    value: "p1Pass"
  },
  {
    code: "P2Pass",
    label: "Employment Pass",
    value: "p2Pass"
  },
  {
    code: "QPass",
    label: "Employment Pass",
    value: "qPass"
  },
  {
    code: "PEP",
    label: "Personalised Employment Pass",
    value: "personalisedEmploymentPass"
  },
  {
    code: "WHP",
    label: "Work Holiday Pass",
    value: "workHolidayPass"
  },
  {
    code: "TEP",
    label: "Training Employment Pass",
    value: "trainingEmploymentPass"
  },
  {
    code: "Entre",
    label: "Entre Pass",
    value: "entrePass"
  },
  {
    code: "DP",
    label: "Dependent Pass",
    value: "dependentPass"
  },
  {
    code: "LTVP",
    label: "Long Term Visit Pass",
    value: "longTermVisitPass"
  },
  {
    code: "LOC",
    label: "Letter of Consent",
    value: "letterOfConsent"
  },
  {
    code: "MWP",
    label: "Miscellaneous Work Pass",
    value: "miscellaneousWorkPass"
  }
];

export enum WALLEX_KYC_STATUS {
  REQUESTED = 1,
  SIGNUP,
  UPDATE_USER_DETAIL,
  UPLOAD_DOCS,
  SCREENING,
  PENDING,
  APPROVED,
  REJECTED,
  REVIEW,
  DOCUMENTS_NEED_RESUBMISSION,
  PENDING_PHONE,
  PENDING_DOCS,
  PENDING_VERIFICATION,
  INACTIVE
}

export const COUNTRY_MULTIPLE_CURRENCY = [
  "MY",
  "HK",
  "IN",
  "JP",
  "CN",
  "ID",
  "FR"
];

export const APAC_COUNTRIES = [
  "AU",
  "CN",
  "HK",
  "IN",
  "ID",
  "JP",
  "MY",
  "NZ",
  "PH"
];

export const NORTH_AMERICA_COUNTRIES = ["US"];

export const UNITED_KINGDOM = ["GB"];

export const EUROPE_COUNTRIES = [
  "AT",
  "BE",
  "CY",
  "EE",
  "FI",
  "FR",
  "DE",
  "GR",
  "IE",
  "IT",
  "LV",
  "LT",
  "LU",
  "MT",
  "NL",
  "PT",
  "SK",
  "SI",
  "ES"
];
