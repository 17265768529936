import { connect } from "react-redux";
import View from "./View";

import { RootState } from "src/ipm-shared/store/model/reducers";

import * as GlobalUISelectors from "src/ipm-shared/components/GlobalUI/selectors";

import * as actions from "src/bepaid/store/model/Fetch/actions";

const mapStateToProps = (state: RootState, props: any) => ({
  extraInfo: GlobalUISelectors.getExtraInfo(state, props.modalId)
});

const mapDispatchToProps = {
  toggleModal: actions.toggleModal
};

export type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(View);
