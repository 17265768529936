// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Progress_progressBar__umeU1{height:6px;background:#efefef}.Progress_progressBar__umeU1 .Progress_progressBarSuccess__w2Ig5{background:#5a6be8;height:6px}.Progress_progressLabel__1lJw8{margin:10px 0;position:relative;height:20px}.Progress_progressLabel__1lJw8 .Progress_percentLabel__2mFJ9{position:absolute;-webkit-transform:translateX(-8px);-ms-transform:translateX(-8px);transform:translateX(-8px)}.Progress_progressLabel__1lJw8 .Progress_startLabel__2UssM{-webkit-transform:translateX(2px);-ms-transform:translateX(2px);transform:translateX(2px)}.Progress_progressLabel__1lJw8 .Progress_finishLabel__2CG1A{-webkit-transform:translateX(-34px);-ms-transform:translateX(-34px);transform:translateX(-34px)}.Progress_progressLabel__1lJw8 .Progress_percentIcon__1BhEK{position:absolute;-webkit-transform:translateX(-8px);-ms-transform:translateX(-8px);transform:translateX(-8px);top:-30px}", "",{"version":3,"sources":["webpack://./src/bepaid/components/ProgressStep/Progress.module.scss"],"names":[],"mappings":"AAAA,6BAAa,UAAU,CAAC,kBAAkB,CAAC,iEAAiC,kBAAkB,CAAC,UAAU,CAAC,+BAAe,aAAa,CAAC,iBAAiB,CAAC,WAAW,CAAC,6DAA6B,iBAAiB,CAAC,kCAAyB,CAAzB,8BAAyB,CAAzB,0BAA0B,CAAC,2DAA2B,iCAAwB,CAAxB,6BAAwB,CAAxB,yBAAyB,CAAC,4DAA4B,mCAA0B,CAA1B,+BAA0B,CAA1B,2BAA2B,CAAC,4DAA4B,iBAAiB,CAAC,kCAA0B,CAA1B,8BAA0B,CAA1B,0BAA0B,CAAC,SAAS","sourcesContent":[".progressBar{height:6px;background:#efefef}.progressBar .progressBarSuccess{background:#5a6be8;height:6px}.progressLabel{margin:10px 0;position:relative;height:20px}.progressLabel .percentLabel{position:absolute;transform:translateX(-8px)}.progressLabel .startLabel{transform:translateX(2px)}.progressLabel .finishLabel{transform:translateX(-34px)}.progressLabel .percentIcon{position:absolute;transform:translateX(-8px);top:-30px}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"progressBar": "Progress_progressBar__umeU1",
	"progressBarSuccess": "Progress_progressBarSuccess__w2Ig5",
	"progressLabel": "Progress_progressLabel__1lJw8",
	"percentLabel": "Progress_percentLabel__2mFJ9",
	"startLabel": "Progress_startLabel__2UssM",
	"finishLabel": "Progress_finishLabel__2CG1A",
	"percentIcon": "Progress_percentIcon__1BhEK"
};
export default ___CSS_LOADER_EXPORT___;
