import React, { useEffect, useMemo, useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import DesktopModalLayout from "src/bepaid/components/Modal/components/DesktopModalLayout";
import MainLayout from "src/bepaid/layouts/MainLayout";

import ROUTES from "src/bepaid/routes";
import { history } from "src/ipm-shared/store";

import FetchSkeleton from "src/bepaid/components/Skeleton";
import Button from "src/bepaid/components/Form/controls/Button";
import Progress from "src/bepaid/components/Progress";

import * as uuidv1 from "uuid/v1";
import _get from "lodash-es/get";
import _isEmpty from "lodash-es/isEmpty";
import _lowerCase from "lodash-es/lowerCase";

import { useMakePaymentUrlParams } from "src/bepaid/pages/Payments/hooks";

import InsuranceItem from "./InsuranceItem";
// import NumberUtil from "src/ipm-shared/Utils/Number";

import styles from "../ExpandBox/ExpandboxContainer.module.scss";
import { FORM } from "src/ipm-shared/store/model/Payee/const";
import { purposeByEntity } from "src/bepaid/pages/Payments/const";
import { invokeMessage } from "src/bepaid/components/Message";

const View = (props: any) => {
  const [loadingPayees, setLoadingPayees] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmAccountNumber, setConfirmAccountNumber] = useState(true);

  const { entity, replaceRouteWithParams } = useMakePaymentUrlParams();
  const purpose = purposeByEntity[entity];

  const payee = props.selectedPayees[0];
  const extraPayees = _get(props.extraPayees, payee?.id, undefined);

  const handleClickAddExtraPayee = (payee: any) => {
    const {
      accountNumber,
      bankBSBId,
      bankId,
      bankCode,
      bsbCode,
      currencyId,
      defaultAmount,
      defaultComments,
      id,
      name,
      isUnsupported,
      unsupportedMessage
    } = payee;

    let uid = uuidv1();
    let updateAmount = defaultAmount;
    if (payee.uid) {
      uid = payee.uid;
      updateAmount = defaultAmount / 100;
    }

    props.updateExtraPayee(id, uid, {
      accountNumber,
      bankBSBId,
      bankCode,
      bankId,
      bsbCode,
      currencyId,
      defaultAmount: updateAmount,
      defaultComments,
      files: [],
      id,
      isUnsupported,
      name,
      refundedAmount: 0,
      uid,
      unsupportedMessage
    });
  };

  const setExtraPayeeValue = (
    payeeId: number,
    uid: string,
    prop: any,
    value: string
  ) => {
    props.updateExtraPayee(payeeId, uid, {
      ...props.selectedPayees.filter((p: any) => p.id === payeeId)[0]
        .extraPayees[uid],
      [prop]: value
    });
  };

  const handleClickRemoveExtraPayee = (payee: any, uid: string) => {
    props.removeControlPattern(new RegExp(`${payee.id}_${uid}`));
    props.deleteExtraPayee(payee.id, uid);
  };

  const renderExtraPayees = (payee: any) => {
    return Object.keys(payee.extraPayees).map((uid: string) => {
      return (
        <InsuranceItem
          key={uid}
          payee={payee}
          extraPayeeUid={uid}
          setExtraPayeeValue={setExtraPayeeValue}
          onClickDeletePayee={handleClickRemoveExtraPayee.bind(
            null,
            payee,
            uid
          )}
          accountCountryCode={props.accountCountryCode}
          accountCurrencyId={props.accountCurrencyId}
          accountCountryId={props.accountCountryId}
          insurerList={props.insurerList}
        />
      );
    });
  };

  useEffect(() => {
    props.fetchInsurancePayees({
      cb: (data: any) => {},
      loadingFunc: setLoadingPayees
    });
  }, []);

  useEffect(() => {
    if (payee) {
      let accountNumberConfirmValues = [];
      accountNumberConfirmValues.push(
        _get(props.formState, `confirm_account_number_${payee.id}.value`)
      );
      if (extraPayees !== undefined) {
        Object.keys(extraPayees).map(key => {
          if (key in extraPayees) {
            accountNumberConfirmValues.push(
              _get(
                props.formState,
                `confirm_account_number_${payee.id}_${key}.value`
              )
            );
          }
        });
      }
      if (accountNumberConfirmValues.some(v => v !== "checked")) {
        setConfirmAccountNumber(false);
      } else {
        setConfirmAccountNumber(true);
      }
    }
  }, [payee, extraPayees]);

  // const validateField = () => {
  //   const errors = {
  //     fields: {},
  //     form: []
  //   };
  //   let supportingDocuments = [];
  //   supportingDocuments.push(
  //     _get(props.formState, `supporting_documents_${payee.id}`)
  //   );
  //   let supplierAmounts = [];
  //   supplierAmounts.push(_get(props.formState, `supplier_amount_${payee.id}`));
  //   if (extraPayees !== undefined) {
  //     Object.keys(extraPayees).map(key => {
  //       if (key in extraPayees) {
  //         supportingDocuments.push(
  //           _get(props.formState, `supporting_documents_${payee.id}_${key}`)
  //         );
  //         supplierAmounts.push(
  //           _get(props.formState, `supplier_amount_${payee.id}_${key}`)
  //         );
  //       }
  //     });
  //   }

  //   supportingDocuments.forEach(supportingDocument => {
  //     if (supportingDocument && !supportingDocument.value) {
  //       errors.fields[supportingDocument.name] = ["REQUIRED_FIELD"];
  //     }
  //   });

  //   supplierAmounts.forEach(supplierAmount => {
  //     if (
  //       supplierAmount &&
  //       (!supplierAmount.value ||
  //         NumberUtil.amountStringToInt(supplierAmount?.value) < 1500)
  //     ) {
  //       errors.fields[supplierAmount.name] = ["MINIMUM_INVOICE_AMOUNT_SG"];
  //     }
  //   });

  //   if (!_isEmpty(errors.fields)) {
  //     props.parseServerErrors(errors);
  //     return false;
  //   }

  //   return true;
  // };

  const moveForward = () => {
    props.validatePaymentPayeeSubmit(
      purpose,
      false,
      {
        cb: (err?: any) => {
          if (err) {
            invokeMessage("error", err.message);
          } else {
            history.push(
              replaceRouteWithParams(ROUTES.PAYMENTS_CREATION, {
                newAction: "schedule"
              })
            );
          }
        }
      },
      setLoading
    );

    // setLoading(true); // ok

    // setTimeout(() => {
    //   if (!validateField()) {
    //     return setLoading(false);
    //   }
    //   history.push(
    //     replaceRouteWithParams(ROUTES.PAYMENTS_CREATION, {
    //       newAction: "schedule"
    //     })
    //   );
    // }, 500);
  };

  const submitLabel = useMemo(() => {
    return loading ? "Moving forward..." : "Move forward";
  }, [loading]);

  const onBack = () => {
    props.removeForm(FORM);
    history.goBack();
  };

  const renderBtnSubmit = () => {
    return (
      <>
        <Button
          wrapperClassName={styles.wrapperButton}
          onClick={moveForward}
          type="primary"
          htmlType="button"
          loading={loading || !confirmAccountNumber}
        >
          {submitLabel}
        </Button>
      </>
    );
  };

  const renderDesktopView = () => {
    return (
      <DesktopModalLayout
        maxWidth450={true}
        onSubmit={moveForward}
        title={props.titleLabel}
        onClose={props.onClose}
        onBack={onBack}
        progress={
          <Progress colors={["#937CF2", "#A581E8"]} value={props.rogress} />
        }
        submitButtonClassName={""}
        btnSubmit={renderBtnSubmit()}
      >
        {renderContent()}
      </DesktopModalLayout>
    );
  };

  const renderMobileView = () => {
    return (
      <MainLayout
        header={{
          title: props.titleLabel,
          hideMenu: true,
          onBack,
          onClose: props.onClose,
          progress: (
            <Progress colors={["#937CF2", "#A581E8"]} value={props.progress} />
          )
        }}
        footer={renderBtnSubmit()}
      >
        {renderContent()}
      </MainLayout>
    );
  };

  const renderContent = () => {
    return (
      <div className={styles.wrapper}>
        <FetchSkeleton loading={loadingPayees}>
          <div className={styles.groupContainer}>
            <div>
              <InsuranceItem
                payee={payee}
                accountCountryCode={props.accountCountryCode}
                accountCurrencyId={props.accountCurrencyId}
                accountCountryId={props.accountCountryId}
                // list={props.list}
                insurerList={props.insurerList}
              />
              {!_isEmpty(payee) && renderExtraPayees(payee)}

              {!_isEmpty(payee) && (
                <button
                  onClick={handleClickAddExtraPayee.bind(null, payee)}
                  className={styles.btnAdd}
                >
                  Make multiple insurance payments to this insurer.
                </button>
              )}
            </div>
          </div>
        </FetchSkeleton>
      </div>
    );
  };

  return (
    <>
      <BrowserView>{renderDesktopView()}</BrowserView>
      <MobileView>{renderMobileView()}</MobileView>
    </>
  );
};

export default View;
