// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TripleAPaymentModal_tripleAPaymentContainer__3aKpX{position:relative;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-align:center;align-items:center;max-height:750px;max-width:350px;margin:2rem auto 0}.TripleAPaymentModal_tripleAPaymentContainer__3aKpX .TripleAPaymentModal_closeButton__etkPM{cursor:pointer;position:absolute;top:10px;right:10px;z-index:20}.TripleAPaymentModal_tripleAPaymentContainer__3aKpX .TripleAPaymentModal_tripleAPaymentIframe__3w1om{border:0;height:700px;width:350px}.TripleAPaymentModal_tripleAPaymentContainer__3aKpX.TripleAPaymentModal_isMobile__FY_ks .TripleAPaymentModal_tripleAPaymentIframe__3w1om{height:700px}", "",{"version":3,"sources":["webpack://./src/bepaid/shared/modals/TripleAPaymentModal/TripleAPaymentModal.module.scss"],"names":[],"mappings":"AAAA,oDAAyB,iBAAiB,CAAC,mBAAY,CAAZ,YAAY,CAAC,yBAAqB,CAArB,qBAAqB,CAAC,qBAAkB,CAAlB,kBAAkB,CAAC,gBAAgB,CAAC,eAAe,CAAC,kBAAkB,CAAC,4FAAsC,cAAc,CAAC,iBAAiB,CAAC,QAAQ,CAAC,UAAU,CAAC,UAAU,CAAC,qGAA+C,QAAQ,CAAC,YAAY,CAAC,WAAW,CAAC,yIAAwD,YAAY","sourcesContent":[".tripleAPaymentContainer{position:relative;display:flex;flex-direction:column;align-items:center;max-height:750px;max-width:350px;margin:2rem auto 0}.tripleAPaymentContainer .closeButton{cursor:pointer;position:absolute;top:10px;right:10px;z-index:20}.tripleAPaymentContainer .tripleAPaymentIframe{border:0;height:700px;width:350px}.tripleAPaymentContainer.isMobile .tripleAPaymentIframe{height:700px}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tripleAPaymentContainer": "TripleAPaymentModal_tripleAPaymentContainer__3aKpX",
	"closeButton": "TripleAPaymentModal_closeButton__etkPM",
	"tripleAPaymentIframe": "TripleAPaymentModal_tripleAPaymentIframe__3w1om",
	"isMobile": "TripleAPaymentModal_isMobile__FY_ks"
};
export default ___CSS_LOADER_EXPORT___;
