import React, { useState } from "react";
import queryString from "query-string";
import { isDesktop } from "react-device-detect";
import classNames from "classnames";

import { history } from "src/ipm-shared/store";

import Button from "src/bepaid/components/Form/controls/Button";
import SearchContent from "src/bepaid/pages/Search/components/SearchContent";
import FilterContent from "src/bepaid/pages/Filter/components/FilterContent";

import { REDIRECT_URI, saveSearchHistory } from "src/bepaid/pages/Search/utils";

import styles from "./InvoiceSearchAndFilter.module.scss";

const View = (props: any) => {
  // Search
  const SEARCH_KEY: string = String("invoices");
  const [searchKeyword, setSearchKeyword] = React.useState<string>("");

  const uri = REDIRECT_URI[SEARCH_KEY] || "";

  const onChangeSearch = (v: string) => {
    setSearchKeyword(v);
  };

  const onClickHistorySearch = (v: string) => {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set("search_keyword", v);
    history.push({
      pathname: `${uri}`,
      search: "?" + urlParams.toString()
    });
  };

  const onClickSearch = () => {
    if (searchKeyword.trim()) {
      saveSearchHistory(SEARCH_KEY, searchKeyword);
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.set("search_keyword", searchKeyword);
      history.push({
        pathname: `${uri}`,
        search: "?" + urlParams.toString()
      });
    }
  };

  // Filter
  const [filterState, setFilterState] = useState<any>(null);

  const onApplyFilter = () => {
    if (filterState) {
      const urlParams = new URLSearchParams(window.location.search);
      const searchKeywordFromQuery = urlParams.get("search_keyword");

      history.push(
        `${uri}/?${queryString.stringify({
          ...filterState,
          search_keyword: searchKeywordFromQuery
        })}`
      );
    }
  };

  return (
    <div className={styles.wrapper}>
      <div
        className={classNames(styles.searchWrapper, {
          [styles.wrapperDesktop]: isDesktop
        })}
      >
        <div className={styles.searchHeading}>
          <h3 className={styles.title}>Search</h3>
          <Button
            type="secondary"
            htmlType="button"
            className={styles.button}
            onClick={onClickSearch}
          >
            Search
          </Button>
        </div>

        <SearchContent
          searchKey={"invoices"}
          onChangeSearch={onChangeSearch}
          onClickHistory={onClickHistorySearch}
        />
      </div>

      <div
        className={classNames(styles.searchWrapper, styles.filterWrapper, {
          [styles.wrapperDesktop]: isDesktop
        })}
      >
        <div className={styles.searchHeading}>
          <h3 className={styles.title}>Filter</h3>
          <Button
            type="secondary"
            htmlType="button"
            className={styles.button}
            onClick={onApplyFilter}
          >
            Apply
          </Button>
        </div>

        <div className={styles.filterContent}>
          <FilterContent
            filterKey={"invoices"}
            filterState={filterState}
            onChangeFilter={setFilterState}
          />
        </div>
      </div>
    </div>
  );
};

export default View;
