/**
 * Actions
 *
 * ActionCreators are like the "pub" of pubsub. It's just broadcasting an event (action).
 * So, don't couple it with a specific reducer. Let ANY reducer listen to
 * an action.
 *
 * `typesafe-actions` has a nice utility function so we don't need to define action types as
 * constants. Use it together with `getType(type)` in the reducers.
 */
import { createAction } from "typesafe-actions";

import { LoadingFunc } from "src/ipm-shared/Utils/ReduxSagaEffects";

import { CompanyType, PredefinedUenType } from "./reducers";

export * from "src/ipm-shared/components/GlobalUI/actions";

export const clearCompany = createAction("company/CLEAR");

export const addCompanySubmit = createAction(
  "company/ADD",
  resolve => (callback?: (err: any) => void, loadingFunc?: LoadingFunc) =>
    resolve({ callback, loadingFunc })
);
export const editCompanySubmit = createAction(
  "company/EDIT",
  resolve => (callback?: (err: any) => void, loadingFunc?: LoadingFunc) =>
    resolve({ callback, loadingFunc })
);

export const fetchCompanies = createAction(
  "company/FETCH_ALL",
  resolve => (viewDetail?: boolean, loadingFunc?: LoadingFunc) =>
    resolve({ viewDetail, loadingFunc })
);

export const fetchUEN = createAction(
  "company/FETCH_UEN",
  resolve => (
    uen: string,
    registrationFieldName: string,
    checker: string,
    purposeId?: number,
    formError?: string,
    id?: number,
    callback?: (args: {}) => void,
    noError?: boolean,
    loadingFunc?: LoadingFunc
  ) =>
    resolve({
      callback,
      checker,
      formError,
      id,
      noError,
      purposeId,
      registrationFieldName,
      uen,
      loadingFunc
    })
);

export const setPredefinedUen = createAction(
  "company/SET_PREDEFINED_UEN",
  resolve => (args: { predefinedUen: PredefinedUenType[] }) => resolve(args)
);

export const setCompanies = createAction(
  "company/SET_COMPANIES",
  resolve => (args: { isFetching: boolean; companies?: CompanyType[] }) =>
    resolve(args)
);

export const deleteCompany = createAction(
  "company/DELETE",
  resolve => (args: { id: number }) => resolve(args)
);

export const selectCompanyToEdit = createAction(
  "company/SELECT_TO_EDIT",
  resolve => (id: number) => resolve(id)
);

export const hasCreditCard = createAction(
  "company/HAS_CREDIT_CARD",
  resolve => (value?: boolean) => resolve({ value })
);

export const fetchSearchCompanyName = createAction(
  "company/FETCH_SEARCH_COMPANY_NAME",
  resolve => (
    arg: {
      countryId: number;
      offset?: number;
      pageCount?: number;
      value?: string;
      name?: string;
    },
    callback?: (args: any[], editable?: boolean) => void,
    loadingFunc?: LoadingFunc
  ) => resolve({ arg, callback, loadingFunc })
);

export const updateCompanyIncorporationDate = createAction(
  "company/UPDATE_COMPANY_INCORPORATION_DATE",
  resolve => (
    companyId?: number,
    callback?: (err?: any) => void,
    loadingFunc?: LoadingFunc
  ) => resolve({ companyId, callback, loadingFunc })
);
