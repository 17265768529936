const t = {
  ABN_COLUMN: "ABN",
  ABN_PLACEHOLDER: "Nombor perniagaan australia (ABN)",
  ACCEPT_BUTTON: "Terima",
  ACCOUNT_NUMBER_CONFIRM_DOES_NOT_MATCH:
    "Akaun bank yang dimasukkan tidak sepadan.",
  ACCOUNT_NUMBER_LABEL: "Nombor Akaun",
  ACCOUNT_NUMBER_PLACEHOLDER: "Nombor Akaun Bank Benefisiari",
  ACTION_CANCEL: "Batal Permintaan",
  ACTION_CANCEL_HERO:
    "Adakah anda ingin membatalkan permintaan pembayaran ini?",
  ACTION_CANCEL_INSTRUCTIONS:
    "Pelanggan anda akan dimaklumkan bahawa permintaan pembayaran anda telah dibatalkan.",
  ACTION_CANCEL_NO: "Tidak",
  ACTION_CANCEL_YES: "Ya, Batal",
  ACTION_COLUMN: "Tindakan",
  ACTION_COPY_LINK: "Salin Capaian",
  ACTION_COPY_LINK_CONFIRMED: "Disalin",
  ACTION_MARK_PAID: "Tandakan sebagai Telah Dibayar",
  ACTION_RESEND_EMAIL_SUCCESS:
    "Permintaan pembayaran anda telah dihantar semula ke alamat emel yang dikemas kini.",
  ACTION_RESEND_MOBILE_SUCCESS:
    "Permintaan pembayaran anda telah dihantar semula ke telefon mudah alih yang dikemas kini.",
  ACTION_RESEND_REMIND: "Ingatkan Pelanggan",
  ACTION_RESEND_REQUEST: "Hantar Semula Permintaan",
  ACTION_RESEND_SENT: "Telah Dihantar",
  ACTION_RESEND_SUCCESS:
    "Permintaan pembayaran anda telah dihantar semula ke pelanggan anda.",
  ACTIVITY_COMPLETED_PAYMENTS: "Pembayaran Telah Lengkap",
  ACTIVITY_PAYMENTS_COLLECTED: "Pembayaran Yang Telah Diterima",
  ACTIVITY_PAYMENTS_DEPOSITED: "Pembayaran Yang Telah Dideposit",
  ACTIVITY_PAYMENTS_IN_PROGRESS: "Pembayaran Yand Sedang Dalam Proses",
  ACTIVITY_PAYMENTS_REQUEST: "Pembayaran Diminta",
  ACTIVITY_SCHEDULED_PAYMENTS: "Pembayaran Berjadual",
  ACTIVITY_TITLE: "\u003cb\u003eAKTIVITI\u003c/b\u003e, DALAM 30 HARI LALU",
  ADDITIONAL_INFORMATION_SECTION: "Maklumat Tambahan",
  ADDRESS_LABEL: "Alamat",
  ADDRESS_PLACEHOLDER: "Alamat",
  ADD_BUTTON: "Tambah",
  ADD_CARDS_CTA: "TAMBAH KAD KE DOMPET SAYA",
  ADD_CARD_BUTTON: "Tambah kad untuk pembayaran ini",
  ADD_COMPANY_BUTTON: "Tambah Syarikat Baru",
  ADD_COMPANY_WELCOME:
    "Selamat Datang ke ipaymy! Sahkan butiran syarikat anda untuk mulakan",
  ADD_CUSTOMER_BUTTON: "Tambah Pelanggan",
  ADD_EMPLOYEE: "TAMBAH PEKERJA ANDA",
  ADD_EMPLOYEE_BUTTON: "Tambah Pekerja",
  ADD_EMPLOYEE_LABEL: "Tambah Pekerja Baru",
  ADD_LANDLORD_HERO: "TAMBAH TUAN RUMAH ANDA",
  ADD_MULTIPLE_EMPLOYEES: "Tambah Beberapa Pekerja",
  ADD_MULTIPLE_SUPPLIERS_BUTTON: "Tambah Beberapa Pembekal",
  ADD_NEW_CUSTOMER_BUTTON: "Tambah Pelanggan Baru",
  ADD_NEW_CUSTOMER_HERO: "Tambah Pelanggan Baru",
  ADD_NEW_SUPPLIER_BUTTON: "Tambah Pembekal Baru",
  ADD_NEW_SUPPLIER_HERO: "Tambah Pembekal Baru",
  ADD_PROPERTY_BUTTON: "Tambah Harta",
  ADD_PROPERTY_LABEL: "Tambah Harta Baru",
  ADD_SUPPLIER: "TAMBAH PEMBEKAL ANDA",
  ADMIN_EDIT_PAYEE_ERROR:
    "\u003cp\u003eTerdapat masalah dengan penyuntingan. Sila cuba lagi.\u003c/p\u003e",
  ADMIN_EDIT_PAYEE_SUCCESS: "Pengeditan disimpan.",
  ADMIN_MENU_ITEM: "Pentadbir",
  ALERTS_MENU_ITEM: "Amaran Saya",
  ALREADY_PROCESSED_OR_DELETED_PAYMENT_ERROR:
    "Pembayaran telah lengkap atau telah dipadam",
  ALREADY_REFUNDED_OR_DELETED_PAYMENT_ERROR:
    "Pembayaran telah dipulang atau dipadam",
  AMEX_COMMON_FEE_LABEL: "Amex",
  AMEX_LABEL: "Amex",
  AMOUNT_DOES_NOT_MATCH_ITEMS_SUBTOTALS:
    "Jumlah ini tidak sesuai dengan subtotal item anda, sila periksa butiran item atau buang semua item yang dipilih.",
  AMOUNT_EXCEED_ERROR: "Jumlah bayaran ini melebihi maksimum kami.",
  AMOUNT_LABEL: "Amaun",
  APPLY_ALL_BUTTON: "Terpakai untuk Semua",
  APPLY_ALL_INSTRUCTIONS:
    "Masukkan nota di sini jika anda ingin memakai nota yang sama untuk semua pembekal anda.",
  APPROVAL_REQUEST: "Permintaan Kelulusan",
  AT_LEAST_ONE_PAYMENT_TYPE: "Pilih sekurang-kurangnya satu jenis pembayaran.",
  AU_POSTAL_CODE_MAX_LENGTH_ERROR: "Sila masukkan kurang daripada 4 aksara.",
  AU_T1_DEADLINE:
    "Jika anda membuat pembayaran sebelum 5:00 PTG AEST hari ini, anda akan dapat menjelaskan pembayaran anda pada hari perniagaan berikutnya.",
  BACK_BUTTON: "Kembali",
  BANK_ACCOUNT_HOLDER_COLUMN: "Nama Pemegang Akaun Bank",
  BANK_ACCOUNT_HOLDER_LABEL: "Nama Pemegang Akaun Bank",
  BANK_ACCOUNT_HOLDER_PLACEHOLDER: "Nama Pemegang Akaun Bank",
  BANK_DETAILS_INSTRUCTIONS:
    "Sila berikan butiran akaun bank anda di mana wang akan didepositkan. Ini mestilah satu akaun bank di",
  BANK_DETAILS_INSTRUCTIONS_AU: "Australia yang menerima AUD.",
  BANK_DETAILS_INSTRUCTIONS_HK: "Hong Kong yang menerima HKD.",
  BANK_DETAILS_INSTRUCTIONS_MY: "Malaysia yang menerima RM.",
  BANK_DETAILS_INSTRUCTIONS_SG: "Singapura yang menerima SGD.",
  BANK_DETAILS_SECTION: "Butiran Bank",
  BANK_LABEL: "Bank",
  BANK_NAME_LABEL: "Nama Bank",
  BANK_PLACEHOLDER: "Pilih Bank",
  BANK_RECIPIENT_GENERAL_ERROR:
    "Uh oh - ada sesuatu yang tidak kena. Sila semak butiran bank penerima anda.",
  BANK_STATEMENT_ACCOUNT: "Nombor Akaun",
  BANK_STATEMENT_DATE: "Tarikh Penyata",
  BANK_STATEMENT_HEADER: "Penyata Bank",
  BANK_STATEMENT_INSTRUCTIONS:
    "Muat naik satu salinan penyata bank anda (bertarikh dalam masa 3 bulan lalu). Akaun bank ini mesti dimiliki oleh syarikat yang anda cipta untuk akaun Menerima Bayaran anda. Penyata bank hendaklah mengandungi",
  BANK_STATEMENT_INSTRUCTIONS_EXTRA:
    "Sila pastikan dokumen anda mengandungi maklumat di atas untuk mengelakkan sebarang penangguhan dalam pembayaran",
  BANK_STATEMENT_MESSAGE:
    "Sila muat naik satu salinan penyata bank terkini (dalam masa 30 hari) untuk mengesahkan ketersediaan dan sumber dana. Penyata anda hendaklah mengandungi:",
  BANK_STATEMENT_MESSAGE_INT:
    "Sila muat naik salinan penyata bank terkini anda (dalam tempoh 3 bulan yang lalu) untuk akaun penerimaan di luar negara anda. Kenyataan anda hendaklah mengandungi:",
  BANK_STATEMENT_NAME: "Nama Anda",
  BANK_STATEMENT_PURPOSE_LABEL:
    "Masukkan Butiran Pembayaran dan Muat Naik Penyata Bank",
  BETA_BADGE: "BETA",
  BE_PAID_CALENDAR_SUBTITLE: "Jumlah Amaun Diterima Bulan Ini",
  BE_PAID_CALENDAR_TITLE: "Pembayaran Diterima",
  BE_PAID_LABEL: "Dibayar",
  BLOCKED_USER_MYINFO_ERROR: "Akaun ini telah digantung.",
  BT_INTERNAL_TECHNICAL_ISSUE:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  BUSINESS_DESCRIPTION_LABEL: "Keterangan Perniagaan",
  BUSINESS_DESCRIPTION_PLACEHOLDER: "Pilih Industri Perniagaan",
  BUSINESS_INDUSTRY_1: "Perisian",
  BUSINESS_INDUSTRY_10: "Buku",
  BUSINESS_INDUSTRY_100: "Kontraktor urusniaga khas",
  BUSINESS_INDUSTRY_101: "Perkhidmatan telekomunikasi",
  BUSINESS_INDUSTRY_102: "Kontraktor A/C dan alat pemanasan",
  BUSINESS_INDUSTRY_103: "Perkhidmatan bangunan lain",
  BUSINESS_INDUSTRY_104: "Pusat servis logam",
  BUSINESS_INDUSTRY_105: "Sanitasi dan pembersihan khusus",
  BUSINESS_INDUSTRY_106: "Bahan binaan",
  BUSINESS_INDUSTRY_107: "Peralatan komersil",
  BUSINESS_INDUSTRY_108: "Kedai bunga dan bekalan bunga",
  BUSINESS_INDUSTRY_109: "Makanan dan minuman",
  BUSINESS_INDUSTRY_11: "Muzik atau media lain",
  BUSINESS_INDUSTRY_110: "Tekstil",
  BUSINESS_INDUSTRY_111: "Peralatan dan bekalan perkakasan",
  BUSINESS_INDUSTRY_112: "Pembekal industri",
  BUSINESS_INDUSTRY_113: "Perabot pejabat dan komersial",
  BUSINESS_INDUSTRY_114: "Bekalan pejabat dan alat tulis",
  BUSINESS_INDUSTRY_115: "Peralatan fotografi",
  BUSINESS_INDUSTRY_116: "Peralatan paip dan pemanasan",
  BUSINESS_INDUSTRY_117: "Peranti perubatan dan peralatan makmal",
  BUSINESS_INDUSTRY_12: "Permainan",
  BUSINESS_INDUSTRY_13: "Blog dan kandungan bertulis",
  BUSINESS_INDUSTRY_14: "Lain-lain barangan digital",
  BUSINESS_INDUSTRY_16: "Restoran dan kehidupan malam",
  BUSINESS_INDUSTRY_17: "Kedai barangan runcit",
  BUSINESS_INDUSTRY_18: "Penyedia makanan",
  BUSINESS_INDUSTRY_19: "Lain-lain makanan dan hidangan",
  BUSINESS_INDUSTRY_2: "Pakaian dan aksesori",
  BUSINESS_INDUSTRY_20: "Rundingan",
  BUSINESS_INDUSTRY_21: "Percetakan dan penerbitan",
  BUSINESS_INDUSTRY_22: "Wakil dan peguam",
  BUSINESS_INDUSTRY_23: "Persediaan perakaunan, pengauditan, atau percukaian",
  BUSINESS_INDUSTRY_24: "Memperbaiki komputer",
  BUSINESS_INDUSTRY_25: "Makmal ujian",
  BUSINESS_INDUSTRY_26: "Perkhidmatan kenderaan",
  BUSINESS_INDUSTRY_27: "Sewaan kereta",
  BUSINESS_INDUSTRY_28: "Jualan kereta",
  BUSINESS_INDUSTRY_29: "Mencari pelanggan berpotensi",
  BUSINESS_INDUSTRY_3: "Barangan dan perabot kediaman",
  BUSINESS_INDUSTRY_30: "Pemasaran Langsung",
  BUSINESS_INDUSTRY_31: "Utiliti",
  BUSINESS_INDUSTRY_32: "Perkhidmatan kerajaan",
  BUSINESS_INDUSTRY_33: "Tele-pemasaran",
  BUSINESS_INDUSTRY_34: "Perkhidmatan rundingan gadaian",
  BUSINESS_INDUSTRY_35: "Perkhidmatan jaminan",
  BUSINESS_INDUSTRY_36: "Lain-lain perkhidmatan pemasaran",
  BUSINESS_INDUSTRY_37: "Lain-lain perkhidmatan perniagaan",
  BUSINESS_INDUSTRY_38: "Persatuan sivik, persaudaraan atau sosial",
  BUSINESS_INDUSTRY_39: "Organisasi kebajikan atau perkhidmatan sosial",
  BUSINESS_INDUSTRY_4: "Barangan elektronik kediaman",
  BUSINESS_INDUSTRY_40: "Organisasi keagamaan",
  BUSINESS_INDUSTRY_41: "Kelab eksklusif",
  BUSINESS_INDUSTRY_42: "Organisasi pollitik",
  BUSINESS_INDUSTRY_43: "Lain-lain keahlian organisasi",
  BUSINESS_INDUSTRY_44: "Perkhidmatan peribadi",
  BUSINESS_INDUSTRY_45: "Studio fotografi",
  BUSINESS_INDUSTRY_46: "Spa kesihatan dan kecantikan",
  BUSINESS_INDUSTRY_47: "Salun atau  tukang gunting",
  BUSINESS_INDUSTRY_48: "Perkhidmatan lanskap",
  BUSINESS_INDUSTRY_49: "Tukang urut",
  BUSINESS_INDUSTRY_5: "Barangan dan aksesori kenderaan",
  BUSINESS_INDUSTRY_50: "Perkhidmatan kaunseling",
  BUSINESS_INDUSTRY_51: "Latihan kesihatan dan kesejahteraan",
  BUSINESS_INDUSTRY_52: "Perkhidmatan dobi atau cucian",
  BUSINESS_INDUSTRY_53: "Perkhidmatan temujanji",
  BUSINESS_INDUSTRY_54: "Lain-lain perkhidmatan peribadi",
  BUSINESS_INDUSTRY_55: "Perkongsian kenderaan",
  BUSINESS_INDUSTRY_56: "Teksi dan limosin",
  BUSINESS_INDUSTRY_57: "Perkhidmata Kurier",
  BUSINESS_INDUSTRY_58: "Tempat parkir",
  BUSINESS_INDUSTRY_59: "Penghantar Barangan",
  BUSINESS_INDUSTRY_6: "Lain-lain barang dagangan",
  BUSINESS_INDUSTRY_60: "Perkapalan atau penghantaran",
  BUSINESS_INDUSTRY_61: "Pengangkutan komuter",
  BUSINESS_INDUSTRY_62: "Kapal persiaran",
  BUSINESS_INDUSTRY_63: "Penerbangan dan pengangkutan udara",
  BUSINESS_INDUSTRY_64: "Lain-lain perkhidmatan pengangkutan",
  BUSINESS_INDUSTRY_65: "Sewaan hartnah",
  BUSINESS_INDUSTRY_66: "Hotel, rumah tumpangan atau motel",
  BUSINESS_INDUSTRY_67: "Penginapan perkongsian masa",
  BUSINESS_INDUSTRY_68: "Pelancongan dan penginapan lain",
  BUSINESS_INDUSTRY_70: "Peralatan perubatan dan makmal",
  BUSINESS_INDUSTRY_71: "Doktor dan pakar perubatan",
  BUSINESS_INDUSTRY_72: "Pakar optik dan cermin mata",
  BUSINESS_INDUSTRY_73: "Doktor gigi dan pakar ortodontik",
  BUSINESS_INDUSTRY_74: "Kiropraktor",
  BUSINESS_INDUSTRY_75: "Penjagaan atau kemudahan penjagaan peribadi",
  BUSINESS_INDUSTRY_76: "Hospital",
  BUSINESS_INDUSTRY_77: "Perkhidmatan kesihatan mental",
  BUSINESS_INDUSTRY_78: "Kehidupan dengan bantuan",
  BUSINESS_INDUSTRY_79: "Perkhidmatan veterinar",
  BUSINESS_INDUSTRY_8: "Perisian sebagai satu perkhidmatan",
  BUSINESS_INDUSTRY_80: "Organisasi perubatan",
  BUSINESS_INDUSTRY_81: "Tele-perubatan dan tele-kesihatan",
  BUSINESS_INDUSTRY_82: "Lain-lain perkhidmatan perubatan",
  BUSINESS_INDUSTRY_83: "Perkhidmatan penjagaan kanak-kanak",
  BUSINESS_INDUSTRY_84: "Kolej atau universiti",
  BUSINESS_INDUSTRY_85: "Vokasi atau sekolah perdagangan",
  BUSINESS_INDUSTRY_86: "Sekolah rendah atau menengah",
  BUSINESS_INDUSTRY_87: "Lain-lain perkhidmatan pendidikan",
  BUSINESS_INDUSTRY_89: "Tiket acara",
  BUSINESS_INDUSTRY_9: "Aplikasi",
  BUSINESS_INDUSTRY_90: "Tarikan pelancong",
  BUSINESS_INDUSTRY_91: "Kem rekreasi",
  BUSINESS_INDUSTRY_92: "Ahli muzik, kumpulan, orkestra",
  BUSINESS_INDUSTRY_93: "Taman hiburan, karnival atau sarkas",
  BUSINESS_INDUSTRY_94: "Panggung wayang",
  BUSINESS_INDUSTRY_95: "Lain-lain hiburan dan rekreasi",
  BUSINESS_INDUSTRY_97: "Kontraktor am",
  BUSINESS_INDUSTRY_98: "Kontraktor elektrikal",
  BUSINESS_INDUSTRY_99: "Kontraktor pertukangan",
  BUSINESS_INDUSTRY_GROUP_1: "Peruncitan",
  BUSINESS_INDUSTRY_GROUP_10: "Perkhidmatan Perubatan",
  BUSINESS_INDUSTRY_GROUP_11: "Hiburan dan Rekreasi",
  BUSINESS_INDUSTRY_GROUP_12: "Perkhidmatan Bangunan",
  BUSINESS_INDUSTRY_GROUP_2: "Perkhidmatan Profesional",
  BUSINESS_INDUSTRY_GROUP_3: "Organisasi Keahlian",
  BUSINESS_INDUSTRY_GROUP_4: "Pengangkutan",
  BUSINESS_INDUSTRY_GROUP_5: "Pelancongan dan Perhotelan",
  BUSINESS_INDUSTRY_GROUP_6: "Pendidikan",
  BUSINESS_INDUSTRY_GROUP_7: "Produk Digital",
  BUSINESS_INDUSTRY_GROUP_8: "Makan dan Minuman",
  BUSINESS_INDUSTRY_PLACEHOLDER: "Sila Pilih Industri Anda",
  BUSINESS_TYPE_1: "Corporation",
  BUSINESS_TYPE_2: "Syarikat liabiliti terhad (LLC)",
  BUSINESS_TYPE_3: "Organisasi tanpa keuntungan",
  BUSINESS_TYPE_4: "Perkongsian",
  BUSINESS_TYPE_5: "Pemilik Tunggal",
  BUSINESS_TYPE_LABEL: "Jenis Perniagaan",
  CALCULATE_PAYMENT_AMOUNT: "Kira jumlah bayaran",
  CALCULATING_PAYMENT_AMOUNT: "Mengira jumlah bayaran",
  CALENDAR_CHARGE_DATE_LEGEND:
    "Tarikh Caj: Kad anda akan dikenakan caj pada hari ini",
  CALENDAR_CURRENT_PAYOUT_DATE_LEGEND: "Tarikh Pembayaran Semasa",
  CALENDAR_DUE_DATE_LEGEND:
    "Tarikh cukup tempoh: hari pelanggan anda perlu membuat bayaran",
  CALENDAR_HOLIDAY_LEGEND: "Cuti Umum",
  CALENDAR_PAYOUT_DATE_LEGEND:
    "Tarikh Pembayaran: Penerima anda akan menerima pembayaran pada hari ini",
  CALENDAR_SELECT_DATE_ERROR: "Sila pilih satu tarikh",
  CANCELLED: "Dibatalkan",
  CANCELLED_DUE_TO_DELETED_CARD:
    "Pembayaran ini telah dibatalkan kerana kad berkaitan telah dipadam.",
  CANCELLED_DUE_TO_DELETED_PROPERTY:
    "Pembayaran ini telah dibatalkan kerana hartanah berkaitan telah dipadam",
  CANCELLED_DUE_TO_DELETED_SCHEDULE:
    "Pembayaran ini telah dibatalkan atas permintaan pengguna",
  CANCEL_BUTTON: "Batal",
  CANNOT_ADD_LATE_PAYMENT_REMEDIATION_AND_INSTALLMENT_PLAN_IN_A_PAYMENT_REQUEST:
    "Pilih sama ada pelan ansuran atau yuran pembayaran lewat.",
  CANNOT_APPLY_COUPON_ERROR: "Kod kupon tidak sah.",
  CARD_ADD_LABEL: "Tambah kad untuk membayar",
  CARD_BRAND_AMEX_LABEL: "Amex",
  CARD_BRAND_CHINA_UNIONPAY_LABEL: "China UnionPay",
  CARD_BRAND_MASTERCARD_LABEL: "Mastercard",
  CARD_BRAND_VISA_AND_MASTER_LABEL: "Visa dan Mastercard",
  CARD_BRAND_VISA_LABEL: "Visa",
  CARD_CONFIRMATION_CHECKBOX:
    "Saya bersetuju dengan \u003ca\u003esyarat perkhidmatan\u003c/a\u003e dan mengesahkan bahawa jumlah pembayaran dan semua butiran pembayaran adalah betul.",
  CARD_CONFIRMATION_CHECKBOX_WALLEX:
    "Saya bersetuju dengan \u003ca\u003esyarat perkhidmatan\u003c/a\u003e dan mengesahkan bahawa jumlah pembayaran dan semua butiran pembayaran betul, dan saya faham pembayaran saya akan sampai pada",
  CARD_CVC_LABEL: "CVC/CVV",
  CARD_CVC_PLACEHOLDER: "CVC/CVV",
  CARD_DETAILS_HEADING: "Butiran Kad",
  CARD_ENCRYPTED_MESSAGE: "Butiran kad yang anda masukkan adalah tersulit.",
  CARD_EXPIRED: "Sila gunakan kad dengan tarikh tamat tempoh yang sah",
  CARD_EXPIRED_ERROR: "Sila gunakan kad dengan tarikh tamat tempoh yang sah",
  CARD_EXPIRY_LABEL: "Tarikh tamat tempoh",
  CARD_EXPIRY_PLACEHOLDER: "MM/YY",
  CARD_IMAGE_EXPIRY: "tarikh tamat tempoh",
  CARD_IMAGE_HOLDER: "NAMA PEMEGANG KAD",
  CARD_IMAGE_NUMBER: "nombor kad",
  CARD_IMAGE_YOUR_NAME: "NAMA ANDA",
  CARD_INFORMATION_DESCRIPTION:
    "Sila masukkan butiran kad anda di bawah. Semua transaksi adalah selamat dan tersulit.",
  CARD_INFORMATION_HEADING: "Maklumat Kad",
  CARD_LICENSE_FEE_NOTE:
    "Amaun yuran lesen bergantung pada kegunaan kad kredit.",
  CARD_NAME_PLACEHOLDER: "Nama pemegang kad",
  CARD_NUMBER_LABEL: "Nombor kad",
  CARD_NUMBER_PLACEHOLDER: "1234 1234 1234 1234",
  CARD_PAY_BUTTON: "Bayar",
  CARD_REAUTHORIZED_MESSAGE:
    "Tidak nampak kad anda? Kad anda perlu diberi kebenaran semula untuk menghantar pembayaran ke penerima di dalam negara ini.",
  CARD_RECORDS_NOTE:
    'Ini adalah keterangan yang akan ada dalam penyata kad kredit anda. Ianya juga ialah nota yang akan dipaparkan dalam ruang "Keterangan" dalam papan pemuka dan butiran pembayaran.',
  CARD_RECORDS_PLACEHOLDER: "Maksima 22 aksara",
  CARD_RECORDS_TOOLTIP:
    'Ini adalah nota dalaman untuk rekod anda mengenai tujuan pembayaran ini. Ini akan dipaparkan sebagai "Keterangan" pembayaran anda dalam papan pemuka dan butiran pembayaran.',
  CARD_SAVE_CHECKBOX: "Simpan maklumat saya untuk pembayaran akan datang",
  CARD_SELECTED_LABEL: "Kad Telah Dipilih",
  CARD_SELECTED_PLACEHOLDER: "Pilih Kad",
  CARD_SELECTED_SECTION: "Kad Telah Dipilih",
  CARD_SELECT_LABEL: "Pilih kad untuk membayar",
  CARD_SLIDER_ADD_INSTRUCTIONS: "KLIK DI SINI UNTUK MENAMBAH KAD BARU",
  CARD_SLIDER_AUTHORIZED_TOOLTIP:
    "Kad telah diluluskan untuk membuat pembayaran kepada penerima ini di dalam negara ini.",
  CARD_SLIDER_BUTTON: "Edit Butiran",
  CARD_TOTAL_LABEL: "Jumlah",
  CARD_WAS_DELETED: "Kad ini tidak tersedia lagi. Sila pilih kad lain.",
  CHANGE_PASSWORD_CONFIRM: "Sahkan kata laluan baru",
  CHANGE_PASSWORD_CONFIRM_PLACEHOLDER:
    "Sila masukkan kata laluan baru anda semula",
  CHANGE_PASSWORD_HERO: "TUKAR KATA LALUAN",
  CHANGE_PASSWORD_NEW: "Kata laluan baru",
  CHANGE_PASSWORD_NEW_PLACEHOLDER: "Sila masukkan kata laluan baru anda",
  CHANGE_PASSWORD_OLD: "Kata laluan lama",
  CHANGE_PASSWORD_OLD_PLACEHOLDER: "Sila masukkan kata laluan lama anda",
  CHANGE_PASSWORD_SUCCESS: "Telah Dikemas Kini",
  CHARACTER_LIMIT_60: "Masukkan tidak lebih daripada 60 aksara di sini.",
  CHECKBOX_SELECT_ALL_LABEL: "Pilih Semua",
  CHECKOUT_GENERAL_ISSUE:
    "Nampaknya terdapat masalah teknikal dengan permintaan pembayaran anda. Sila cuba lagi atau hubungi kami di \u003ca\u003esupport@ipaymy.com\u003c/a\u003e.",
  CHECK_SPAM:
    'Tidak mendapat emel? Semak folder spam/junk, klik "Hantar emel semula" atau',
  CITY_LABEL: "Bandar",
  CITY_PLACEHOLDER: "Bandar",
  CKO_ERROR_3DS_MALFUNCTION:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_3DS_NOT_CONFIGURED:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_3DS_NOT_ENABLED_FOR_CARD:
    "Pembayaran yang dibuat pada kad ini tidak disokong oleh ipaymy. Anda boleh  menghubungi kami untuk maklumat lanjut.",
  CKO_ERROR_3DS_NOT_SUPPORTED:
    "Pembayaran yang dibuat pada kad ini tidak disokong oleh ipaymy. Anda boleh  menghubungi kami untuk maklumat lanjut.",
  CKO_ERROR_3DS_PAYMENT_REQUIRED:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_3DS_VERSION_INVALID:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_3DS_VERSION_NOT_SUPPORTED:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_ADDRESS_INVALID:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  CKO_ERROR_AMOUNT_EXCEEDS_BALANCE:
    "Pembayaran gagal kerana kredit tidak mencukupi. Anda mungkin telah mencapai had kredit maksimum pada kad kredit ini. Sila sesuaikan jumlah pembayaran atau gunakan kad kredit lain.",
  CKO_ERROR_AMOUNT_INVALID:
    "Transaksi tidak dapat diluluskan. Sila cuba kad lain atau hubungi pengeluar kad untuk maklumat lanjut.",
  CKO_ERROR_API_CALLS_QUOTA_EXCEEDED:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_BILLING_DESCRIPTOR_CITY_INVALID:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_BILLING_DESCRIPTOR_CITY_REQUIRED:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_BILLING_DESCRIPTOR_NAME_INVALID:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_BILLING_DESCRIPTOR_NAME_REQUIRED:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_BUSINESS_INVALID:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_BUSINESS_SETTINGS_MISSING:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_CAPTURE_VALUE_GREATER_THAN_AUTHORIZED:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_CAPTURE_VALUE_GREATER_THAN_REMAINING_AUTHORIZED:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_CARD_AUTHORIZATION_FAILED:
    "Transaksi tidak dapat diluluskan. Sila cuba kad lain atau hubungi pengeluar kad untuk maklumat lanjut.",
  CKO_ERROR_CARD_DISABLED:
    "Transaksi tidak dapat diluluskan. Sila cuba kad lain atau hubungi pengeluar kad untuk maklumat lanjut.",
  CKO_ERROR_CARD_EXPIRED:
    "Tarikh tamat tempoh kad tidak tepat. Semak tarikh tamat tempoh kad atau guna kad yang lain.",
  CKO_ERROR_CARD_EXPIRY_MONTH_INVALID:
    "Tarikh tamat tempoh kad tidak tepat. Semak tarikh tamat tempoh kad atau guna kad yang lain.",
  CKO_ERROR_CARD_EXPIRY_MONTH_REQUIRED:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_CARD_EXPIRY_YEAR_INVALID:
    "Tarikh tamat tempoh kad tidak tepat. Semak tarikh tamat tempoh kad atau guna kad yang lain.",
  CKO_ERROR_CARD_EXPIRY_YEAR_REQUIRED:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_CARD_HOLDER_INVALID:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_CARD_NOT_FOUND:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_CARD_NUMBER_INVALID:
    "Nombor kad tidak tepat. Semak nombor kad atau guna kad yang lain.",
  CKO_ERROR_CARD_NUMBER_REQUIRED:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_CHANNEL_DETAILS_INVALID:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_CHANNEL_URL_MISSING:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_CHARGE_DETAILS_INVALID:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_CITY_INVALID:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_COUNTRY_ADDRESS_INVALID:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_COUNTRY_INVALID:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_COUNTRY_PHONE_CODE_INVALID:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_COUNTRY_PHONE_CODE_LENGTH_INVALID:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_CURRENCY_INVALID:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_CURRENCY_REQUIRED:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_CUSTOMER_ALREADY_EXISTS:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_CUSTOMER_EMAIL_INVALID:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_CUSTOMER_ID_INVALID:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_CUSTOMER_MISMATCH:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_CUSTOMER_NOT_FOUND:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_CUSTOMER_NUMBER_INVALID:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_CUSTOMER_PLAN_EDIT_FAILED:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_CUSTOMER_PLAN_ID_INVALID:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_CVV_INVALID:
    "CVC/CVV kad tidak tepat. Semak kod keselamatan kad atau guna kad lain.",
  CKO_ERROR_DO_NOT_HONOR:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  CKO_ERROR_EMAIL_INVALID:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_EMAIL_IN_USE:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_EMAIL_REQUIRED:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_ENDPOINT_INVALID:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_EXPIRY_DATE_FORMAT_INVALID:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_FAIL_URL_INVALID:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_FIRST_NAME_REQUIRED:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_IP_ADDRESS_INVALID:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  CKO_ERROR_ISSUER_NETWORK_UNAVAILABLE:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  CKO_ERROR_LAST_NAME_REQUIRED:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_METADATA_KEY_INVALID:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_PARAMETER_INVALID:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_PASSWORD_INVALID:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_PAYMENTS_NOT_SUPPORTED:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_PAYMENT_EXPIRED:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_PAYMENT_INVALID:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_PAYMENT_METHOD_INVALID:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_PAYMENT_METHOD_NOT_SUPPORTED:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_PAYMENT_SOURCE_DOES_NOT_EXIST:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_PAYMENT_SOURCE_REQUIRED:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_PAYMENT_TYPE_INVALID:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_PHONE_NUMBER_INVALID:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_PHONE_NUMBER_LENGTH_INVALID:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_PREVIOUS_PAYMENT_ID_INVALID:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_PROCESSING_ERROR:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_PROCESSING_KEY_REQUIRED:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_PROCESSING_VALUE_REQUIRED:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_RECIPIENT_ACCOUNT_NUMBER_INVALID:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_RECIPIENT_ACCOUNT_NUMBER_REQUIRED:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_RECIPIENT_DOB_INVALID:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_RECIPIENT_DOB_REQUIRED:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_RECIPIENT_LAST_NAME_REQUIRED:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_RECIPIENT_ZIP_INVALID:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_RECIPIENT_ZIP_REQUIRED:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_RECURRING_PLAN_EXISTS:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_RECURRING_PLAN_NOT_EXIST:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_RECURRING_PLAN_REMOVAL_FAILED:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_REFUNDS_NOT_SUPPORTED:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_REQUEST_INVALID:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_REQUEST_JSON_INVALID:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_RISK_ENABLED_REQUIRED:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_SERVER_API_NOT_ALLOWED:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_SERVICE_UNAVAILABLE:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_SOURCE_EMAIL_INVALID:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_SOURCE_EMAIL_REQUIRED:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_SOURCE_ID_INVALID:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_SOURCE_ID_OR_EMAIL_REQUIRED:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_SOURCE_ID_REQUIRED:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_SOURCE_ID_UNKNOWN:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_SOURCE_INVALID:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_SOURCE_OR_DESTINATION_REQUIRED:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_SOURCE_TOKEN_INVALID:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_SOURCE_TOKEN_REQUIRED:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_SOURCE_TOKEN_TYPE_INVALID:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_SOURCE_TOKEN_TYPE_REQUIRED:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_SOURCE_TYPE_REQUIRED:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_SUCCESS_URL_INVALID:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_TOKEN_EXPIRED:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_TOKEN_INVALID:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_TOKEN_IN_USE:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_TOKEN_REQUIRED:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_TOKEN_TYPE_REQUIRED:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_TOKEN_USED:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_VOID_AMOUNT_INVALID:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_WALLET_ID_INVALID:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_ERROR_ZIP_INVALID:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CKO_INTERNAL_TECHNICAL_ISSUE:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  CLOSE_ACCOUNT_REASON_1: "Saya mahu mendaftarkan akaun Fetch baru.",
  CLOSE_ACCOUNT_REASON_2:
    "Saya tidak bersedia membayar untuk menggunakan aplikasinya.",
  CLOSE_ACCOUNT_REASON_3:
    "Sukar mendapat sokongan ketika saya memerlukan pertolongan.",
  CLOSE_ACCOUNT_REASON_4: "Saya menggunakan aplikasi lain.",
  CLOSE_ACCOUNT_REASON_5: "Aplikasi ini sukar digunakan.",
  CLOSE_ACCOUNT_REASON_6: "Aplikasi ini berbeza dari yang saya harapkan.",
  CLOSE_ACCOUNT_REASON_7:
    "Aplikasi ini tidak banyak membantu saya dengan mendapat bayaran.",
  CLOSE_ACCOUNT_REASON_8: "Saya tidak perlu mengeluarkan invois lagi.",
  CLOSE_ACCOUNT_REASON_9: "Yang lain",
  CLOSE_BUTTON: "Tutup",
  COMING_SOON: "Akan Datang",
  COMMENT_HELP_COMPANY_REQUEST:
    "Anda dapat menggunakan ipaymy segera setelah anda mengemukakan permintaan lisitng Syarikat Baru, walaupun perlu diketahui bahawa semasa penyenaraian disetujui, pembayaran akan ditahan. ipaymy akan memberitahu anda setelah penyenaraian diluluskan, dan biasanya selewat-lewatnya selewat-lewatnya 1 hari bekerja.",
  COMPANIES_HERO: "SYARIKAT-SYARIKAT SAYA",
  COMPANIES_INSTRUCTIONS:
    'Berikut adalah syarikat-syarikat berdaftar anda. Ada yang baru?{br}Klik "Tambah Syarikat Baru" untuk menambahnya.',
  COMPANIES_MENU_ITEM: "Syarikat-syarikat",
  COMPANY_ABN_NOT_FOUND:
    "Syarikat atau ABN tidak dijumpai atau disenaraikan? Dapatkannya \u003ca\u003edi sini\u003c/a\u003e atau minta persetujuan penyenaraian \u003cb\u003edi sini\u003c/b\u003e",
  COMPANY_ADDRESS_1_PLACEHOLDER: "Alamat Syarikat Baris 1",
  COMPANY_ADDRESS_2_PLACEHOLDER: "Alamat Syarikat Baris 2",
  COMPANY_ADDRESS_LABEL: "Alamat Syarikat",
  COMPANY_ALREADY_EXISTS_ERROR: "Syarikat ini telah wujud.",
  COMPANY_CRN_NOT_FOUND:
    "Syarikat atau CRN tidak dijumpai atau disenaraikan? Dapatkannya \u003ca\u003edi sini\u003c/a\u003e atau minta persetujuan penyenaraian \u003cb\u003edi sini\u003c/b\u003e",
  COMPANY_EMAIL_PLACEHOLDER: "Alamat Emel Syarikat",
  COMPANY_EXISTED: "Syarikat ini telah wujud. Sila hubungi pentadbir anda.",
  COMPANY_LOCATION_LABEL: "Di manakah terletaknya lokasi syarikat anda?",
  COMPANY_NAME_COLUMN: "Nama Syarikat",
  COMPANY_NAME_CONFIRMATION_LABEL: "Pengesahan Nama Syarikat",
  COMPANY_NAME_LABEL: "Nama Syarikat",
  COMPANY_NAME_PLACEHOLDER: "Nama Syarikat",
  COMPANY_REGISTRATION_ERROR: "Sila masukkan nombor pendaftaran syarikat anda",
  COMPANY_REGISTRATION_PLACEHOLDER: "Nombor Pendaftaran Syarikat",
  COMPANY_SEARCH_PLACEHOLDER: "Masukkan nama atau nombor syarikat...",
  COMPANY_SECTION: "Syarikat",
  COMPANY_TELL_MORE_INSTRUCTIONS:
    "Beritahu kami sedikit mengenai syarikat anda",
  COMPANY_UEN_NOT_FOUND:
    "Syarikat atau UEN tidak dijumpai atau disenaraikan? Dapatkannya \u003ca\u003edi sini\u003c/a\u003e atau minta persetujuan penyenaraian \u003cb\u003edi sini\u003c/b\u003e",
  COMPANY_UEN_REQUEST:
    "Syarikat atau UEN tidak dijumpai atau disenaraikan? Minta kelulusan penyenaraian di sini",
  COMPANY_WELCOME_BE_LISTED: "Sahkan butiran syarikat anda untuk disenaraikan",
  COMPLETE_PAYMENT_DESCRIPTION: "Semak butiran dan lengkapkan pembayaran anda.",
  COMPLETE_PAYMENT_HERO: "LENGKAPKAN PEMBAYARAN {render} ANDA",
  CONFIRM_ACCOUNT_NUMBER_LABEL: "Sahkan Nombor Akaun",
  CONFIRM_ACCOUNT_NUMBER_PLACEHOLDER: "Sahkan Nombor Akaun",
  CONFIRM_BANK_POLICY_LABEL:
    "Kami sarankan anda sahkan butiran bank berkaitan dasar anda dengan pembekal insurans anda.",
  CONFIRM_BUTTON: "Sah",
  CONFIRM_EMAIL_LABEL: "Sahkan Emel",
  CONFIRM_EMAIL_PLACEHOLDER: "Sahkan Emel",
  CONFIRM_PASSWORD_ERROR:
    "Kata laluan yang disahkan tidak sepadan dengan kata laluan",
  CONFIRM_PASSWORD_PLACEHOLDER: "Sahkan Kata Laluan",
  CONFIRM_SEND_BUTTON: "Sah dan Hantar",
  CONFIRM_SEND_HERO: "SAH DAN HANTAR",
  CONTACT_SUPPORT: "Dapatkan Sokongan",
  COUNTRY_1: "Singapura",
  COUNTRY_10: "Vietnam",
  COUNTRY_100: "Iraq",
  COUNTRY_101: "Ireland",
  COUNTRY_102: "Isle of Man",
  COUNTRY_103: "Israel",
  COUNTRY_104: "Itali",
  COUNTRY_105: "Ivory Coast",
  COUNTRY_106: "Jamaica",
  COUNTRY_107: "Jepun",
  COUNTRY_108: "Jersey",
  COUNTRY_109: "Jordan",
  COUNTRY_11: "Antartika",
  COUNTRY_110: "Kazakhstan",
  COUNTRY_111: "Kenya",
  COUNTRY_112: "Kiribati",
  COUNTRY_113: "Kosovo",
  COUNTRY_114: "Kuwait",
  COUNTRY_115: "Kyrgyzstan",
  COUNTRY_116: "Laos",
  COUNTRY_117: "Latvia",
  COUNTRY_118: "Lebanon",
  COUNTRY_119: "Lesotho",
  COUNTRY_12: "Antigua dan Barbuda",
  COUNTRY_120: "Liberia",
  COUNTRY_121: "Libya",
  COUNTRY_122: "Liechtenstein",
  COUNTRY_123: "Lithuania",
  COUNTRY_124: "Luxembourg",
  COUNTRY_125: "Macau",
  COUNTRY_126: "Macedonia",
  COUNTRY_127: "Madagascar",
  COUNTRY_128: "Malawi",
  COUNTRY_129: "Maldives",
  COUNTRY_13: "Argentina",
  COUNTRY_130: "Mali",
  COUNTRY_131: "Malta",
  COUNTRY_132: "Marshall Islands",
  COUNTRY_133: "Mauritania",
  COUNTRY_134: "Mauritius",
  COUNTRY_135: "Mayotte",
  COUNTRY_136: "Mexico",
  COUNTRY_137: "Micronesia",
  COUNTRY_138: "Moldova",
  COUNTRY_139: "Monaco",
  COUNTRY_14: "Armenia",
  COUNTRY_140: "Mongolia",
  COUNTRY_141: "Montenegro",
  COUNTRY_142: "Montserrat",
  COUNTRY_143: "Maghribi",
  COUNTRY_144: "Mozambique",
  COUNTRY_145: "Myanmar",
  COUNTRY_146: "Namibia",
  COUNTRY_147: "Nauru",
  COUNTRY_148: "Nepal",
  COUNTRY_149: "Belanda",
  COUNTRY_15: "Aruba",
  COUNTRY_150: "Netherlands Antilles",
  COUNTRY_151: "New Caledonia",
  COUNTRY_152: "New Zealand",
  COUNTRY_153: "Nicaragua",
  COUNTRY_154: "Niger",
  COUNTRY_155: "Nigeria",
  COUNTRY_156: "Niue",
  COUNTRY_157: "Korea Utara",
  COUNTRY_158: "Kepulauan Mariana Utara",
  COUNTRY_159: "Norway",
  COUNTRY_16: "Australia",
  COUNTRY_160: "Oman",
  COUNTRY_161: "Pakistan",
  COUNTRY_162: "Palau",
  COUNTRY_163: "Palestin",
  COUNTRY_164: "Panama",
  COUNTRY_165: "Papua New Guinea",
  COUNTRY_166: "Paraguay",
  COUNTRY_167: "Peru",
  COUNTRY_168: "Filipina",
  COUNTRY_169: "Pitcairn",
  COUNTRY_17: "Austria",
  COUNTRY_170: "Poland",
  COUNTRY_171: "Portugis",
  COUNTRY_172: "Puerto Rico",
  COUNTRY_173: "Qatar",
  COUNTRY_174: "Republik Kongo",
  COUNTRY_175: "Perjumpaan semula",
  COUNTRY_176: "Romania",
  COUNTRY_177: "Rusia",
  COUNTRY_178: "Rwanda",
  COUNTRY_179: "Saint Barthelemy",
  COUNTRY_18: "Azerbaijan",
  COUNTRY_180: "Saint Helena",
  COUNTRY_181: "Saint Kitts dan Nevis",
  COUNTRY_182: "Saint Lucia",
  COUNTRY_183: "Saint Martin",
  COUNTRY_184: "Saint Pierre dan Miquelon",
  COUNTRY_185: "Saint Vincent dan the Grenadines",
  COUNTRY_186: "Samoa",
  COUNTRY_187: "San Marino",
  COUNTRY_188: "Sao Tome dan Principe",
  COUNTRY_189: "Arab Saudi",
  COUNTRY_19: "Bahamas",
  COUNTRY_190: "Senegal",
  COUNTRY_191: "Serbia",
  COUNTRY_192: "Seychelles",
  COUNTRY_193: "Sierra Leone",
  COUNTRY_194: "Sint Maarten",
  COUNTRY_195: "Slovakia",
  COUNTRY_196: "Slovenia",
  COUNTRY_197: "Kepulauan Solomon",
  COUNTRY_198: "Somalia",
  COUNTRY_199: "Afrika Selatan",
  COUNTRY_2: "Malaysia",
  COUNTRY_20: "Bahrain",
  COUNTRY_200: "Korea Selatan",
  COUNTRY_201: "Sudan Selatan",
  COUNTRY_202: "Sepanyol",
  COUNTRY_203: "Sri Lanka",
  COUNTRY_204: "Sudan",
  COUNTRY_205: "Suriname",
  COUNTRY_206: "Svalbard dan Jan Mayen",
  COUNTRY_207: "Swaziland",
  COUNTRY_208: "Sweden",
  COUNTRY_209: "Switzerland",
  COUNTRY_21: "Bangladesh",
  COUNTRY_210: "Syria",
  COUNTRY_211: "Taiwan",
  COUNTRY_212: "Tajikistan",
  COUNTRY_213: "Tanzania",
  COUNTRY_214: "Thailand",
  COUNTRY_215: "Togo",
  COUNTRY_216: "Tokelau",
  COUNTRY_217: "Tonga",
  COUNTRY_218: "Trinidad dan Tobago",
  COUNTRY_219: "Tunisia",
  COUNTRY_22: "Barbados",
  COUNTRY_220: "Turki",
  COUNTRY_221: "Turkmenistan",
  COUNTRY_222: "Kepulauan Turks dan Caicos",
  COUNTRY_223: "Tuvalu",
  COUNTRY_224: "Kepulauan Virgin Amerika Syarikat",
  COUNTRY_225: "Uganda",
  COUNTRY_226: "Ukraine",
  COUNTRY_227: "Emirate Arab Bersatu",
  COUNTRY_228: "United Kingdom",
  COUNTRY_229: "Amerika Syarikat",
  COUNTRY_23: "Belarus",
  COUNTRY_230: "Uruguay",
  COUNTRY_231: "Uzbekistan",
  COUNTRY_232: "Vanuatu",
  COUNTRY_233: "Vatican",
  COUNTRY_234: "Venezuela",
  COUNTRY_235: "Wallis dan Futuna",
  COUNTRY_236: "Sahara Barat",
  COUNTRY_237: "Yemen",
  COUNTRY_238: "Zambia",
  COUNTRY_239: "Zimbabwe",
  COUNTRY_24: "Belgium",
  COUNTRY_240: "Afghanistan",
  COUNTRY_25: "Belize",
  COUNTRY_26: "Benin",
  COUNTRY_27: "Bermuda",
  COUNTRY_28: "Bhutan",
  COUNTRY_29: "Bolivia",
  COUNTRY_3: "Hong Kong",
  COUNTRY_30: "Bosnia dan Herzegovina",
  COUNTRY_31: "Botswana",
  COUNTRY_32: "Brazil",
  COUNTRY_33: "Wilayah Lautan Hindi British",
  COUNTRY_34: "Kepulauan Virgin British",
  COUNTRY_35: "Brunei",
  COUNTRY_36: "Bulgaria",
  COUNTRY_37: "Burkina Faso",
  COUNTRY_38: "Burundi",
  COUNTRY_39: "Cambodia",
  COUNTRY_4: "Albania",
  COUNTRY_40: "Cameroon",
  COUNTRY_41: "Kanada",
  COUNTRY_42: "Cape Verde",
  COUNTRY_43: "Pulau Cayman",
  COUNTRY_44: "Republik Afrika Tengah",
  COUNTRY_45: "Chad",
  COUNTRY_46: "Chile",
  COUNTRY_47: "China",
  COUNTRY_48: "Pulau Krismas",
  COUNTRY_49: "Kepulauan Cocos",
  COUNTRY_5: "Algeria",
  COUNTRY_50: "Colombia",
  COUNTRY_51: "Comoros",
  COUNTRY_52: "Kepulauan Cook",
  COUNTRY_53: "Costa Rica",
  COUNTRY_54: "Croatia",
  COUNTRY_55: "Cuba",
  COUNTRY_56: "Curacao",
  COUNTRY_57: "Cyprus",
  COUNTRY_58: "Republik Czech",
  COUNTRY_59: "Republik Demokratik Kongo",
  COUNTRY_6: "Samoa Amerika",
  COUNTRY_60: "Denmark",
  COUNTRY_61: "Djibouti",
  COUNTRY_62: "Dominica",
  COUNTRY_63: "Republik Dominican",
  COUNTRY_64: "Timor Timur",
  COUNTRY_65: "Ecuador",
  COUNTRY_66: "Mesir",
  COUNTRY_67: "El Salvador",
  COUNTRY_68: "Guinea Khatulistiwa",
  COUNTRY_69: "Eritrea",
  COUNTRY_7: "Andorra",
  COUNTRY_70: "Estonia",
  COUNTRY_71: "Ethiopia",
  COUNTRY_72: "Kepulauan Falkland",
  COUNTRY_73: "Kepulauan Faroe",
  COUNTRY_74: "Fiji",
  COUNTRY_75: "Finland",
  COUNTRY_76: "Perancis",
  COUNTRY_77: "Polinesia Perancis",
  COUNTRY_78: "Gabon",
  COUNTRY_79: "Gambia",
  COUNTRY_8: "Angola",
  COUNTRY_80: "Georgia",
  COUNTRY_81: "Jerman",
  COUNTRY_82: "Ghana",
  COUNTRY_83: "Gibraltar",
  COUNTRY_84: "Greece",
  COUNTRY_85: "Greenland",
  COUNTRY_86: "Grenada",
  COUNTRY_87: "Guam",
  COUNTRY_88: "Guatemala",
  COUNTRY_89: "Guernsey",
  COUNTRY_9: "Anguilla",
  COUNTRY_90: "Guinea",
  COUNTRY_91: "Guinea-Bissau",
  COUNTRY_92: "Guyana",
  COUNTRY_93: "Haiti",
  COUNTRY_94: "Honduras",
  COUNTRY_95: "Hungary",
  COUNTRY_96: "Iceland",
  COUNTRY_97: "India",
  COUNTRY_98: "Indonesia",
  COUNTRY_99: "Iran",
  COUNTRY_LABEL_AU: "Australia",
  COUNTRY_LABEL_HK: "HK",
  COUNTRY_LABEL_MY: "Semua",
  COUNTRY_LABEL_SG: "Singapura",
  COUPON_APPLIED_SUCCESS: "Kod Kupon Digunakan",
  COUPON_APPLY_BUTTON: "Terpakai",
  COUPON_CODE_CC_VISA_ERROR:
    "Alamak! Kad kredit anda tidak sah untuk promosi ini. Sila gunakan Visa yang dikeluarkan di Singapura.",
  COUPON_CODE_CC_VISA_MASTERCARD_ERROR:
    "Alamak! Kad kredit anda tidak sah untuk promosi ini. Sila gunakan Visa yang dikeluarkan di Singapura atau Mastercard yang dikeluarkan di Singapura.",
  COUPON_CODE_INVALID_ERROR: "Alamak! Kod promo ini tidak sah.",
  COUPON_CODE_SCHEDULE_ERROR:
    "Sila jadual pembayaran anda dengan tarikh caj dikenakan tidak lewat daripada 30 Nov 2019.",
  COUPON_FLY_SUCCESS:
    "Hebat! Anda selangkah hampir untuk terbang dengan bergaya dalam Kelas Perniagaan!",
  COUPON_LOCK_SUCCESS: "Tahniah! Anda telah mendapat kadar anda!",
  COUPON_NOT_FOUND: "Kod promo tidak sah.",
  COUPON_REWARD_SUCCESS:
    "Anda telah diberi ganjaran dengan kadar {render1} melalui penggunaan kod kupon,{br}kadar biasa kami ialah {render2}",
  COUPON_SAVE_SUCCESS: "Tahniah! Anda telah simpan",
  CREATE_BUTTON: "Buat",
  CREATE_COLLECTION_ACCOUNT_HERO: "BUAT AKAUN KUTIPAN PEMBAYARAN",
  CREATE_INVOICE_BUTTON: "Buat Invois",
  CREATE_INVOICE_HERO: "BUAT INVOIS",
  CREATE_INVOICE_MENU_ITEM: "Buat Invois",
  CREATE_ORDER_INVOICE_BUTTON: "Buat Invois Tempahan",
  CREATE_ORDER_MENU_ITEM: "Buat Invois Tempahan",
  CREDIT_CARD_CONFIRMATION: "Adakah Anda Mempunyai Kad Kredit?",
  CREDIT_CARD_STATEMENT_INSTRUCTIONS:
    "Maklumat in akan ada dalam penyata kad kredit pelanggan anda",
  CREDIT_CARD_STATEMENT_INSTRUCTIONS_EXTRA:
    "Kami sarankan menggunak keterangan yang dikenali oleh pelanggan anda, seperti nama perniagaan anda",
  CREDIT_CARD_STATEMENT_SECTION: "Butiran Penyata Kad Kredit",
  CRN_COLUMN: "BRN/CRN",
  CRN_PLACEHOLDER: "Nombor pendaftaran perniagaan (BRN/CRN)",
  CURRENCY_CONFIRMATION:
    "Untuk penyelesaian yang dibuat dalam USD, anda mungkin tertakluk pada yuran pengiriman ke dalam oleh bank anda.{br}Amaun ini berbeza-beza mengikut bank.",
  CURRENCY_CUSTOM: "{mata wang}{amaun, nombor, wang}",
  CURRENCY_HKD_BUTTON: "Tukar ke HKD",
  CURRENCY_LABEL: "Mata Wang",
  CURRENCY_PLACEHOLDER: "Pilih Mata Wang untuk Akaun ini",
  CUSTOMERS_HERO: "PELANGAN-PELANGGAN",
  CUSTOMERS_MENU_ITEM: "Pelangan-pelanggan",
  CUSTOMERS_MOBILE_HEADER: "Nombor Telefon Mudah Alih",
  CUSTOMER_ADDRESS_1_LABEL: "Baris Alamat 1",
  CUSTOMER_ADDRESS_1_PLACEHOLDER: "Baris Alamat 1",
  CUSTOMER_ADDRESS_2_LABEL: "Baris Alamat 2",
  CUSTOMER_ADDRESS_2_PLACEHOLDER: "Baris Alamat 2",
  CUSTOMER_ADD_OPTION: "Tambah Pelanggan Baru",
  CUSTOMER_BUSINESS_REGISTRATION_NUMBER_LABEL:
    "Nombor pendaftaran perniagaan (BRN/CRN)",
  CUSTOMER_BUSINESS_REGISTRATION_NUMBER_PLACEHOLDER:
    "Nombor pendaftaran perniagaan (BRN/CRN)",
  CUSTOMER_EMAIL_LABEL: "Emel Pelanggan",
  CUSTOMER_EMAIL_PLACEHOLDER: "Emel Pelanggan",
  CUSTOMER_LABEL: "Pelanggan",
  CUSTOMER_MOBILE_NUMBER_LABEL: "Nombor Telefon Mudah Alih Pelanggan",
  CUSTOMER_MOBILE_NUMBER_PLACEHOLDER: "Nombor Telefon Mudah Alih Pelanggan",
  CUSTOMER_NAME_LABEL: "Nama Pelanggan",
  CUSTOMER_NAME_PLACEHOLDER: "Nama Pelanggan",
  CUSTOMER_PAYS_LABEL: "Pelanggan Membayar",
  CUSTOMER_PHONE_LABEL: "Nombor Telefon Pelanggan",
  CUSTOMER_PHONE_PLACEHOLDER: "Nombor Telefon Pelanggan",
  CUSTOMER_PLACEHOLDER: "Pilih Pelanggan atau Tambah Pelanggan Baru",
  CUSTOMER_TYPE_LABEL: "Individu atau Perniagaan",
  DASHBOARD_MENU_ITEM: "Papan Pemuka",
  DBS_BANK_AMPERSAND_ERROR: 'Sila alih keluar simbol "\u0026" dan cuba lagi.',
  DEBIT_CARD_NOT_SUPPORTED:
    "Kami tidak lagi menerima kad debit pada 26 Ogos 2021. Sila gunakan kad kredit Visa, MasterCard, atau UnionPay.",
  DELETE_BUTTON: "Padam",
  DELETE_CARD_DESCRIBE:
    "Memadam kad ini juga akan memadam sebarang pembayaran berjadual yang berkaitan.",
  DELETE_CARD_HERO: "Adakah anda ingin memadam kad ini?",
  DELETE_COMPANY_CONFIRMATION: "Adakah anda ingin memadam syarikat ini?",
  DELETE_COMPANY_DESCRIPTION:
    "Syarikat ini akan dikeluarkan dari syarikat-syarikat anda",
  DELETE_CUSTOMER_HERO: "Adakah anda pasti anda ingin memadam pelanggan ini?",
  DELETE_CUSTOMER_INSTRUCTIONS:
    "Anda perlu menambah pelanggan ini semula jika anda ingin menghantar sebarang permintaan pembayaran kepada pelanggan ini nanti.",
  DELETE_PAYEE: "Adakah anda pasti anda ingin memadam {render} ini?",
  DETAILS_COMPLETED_PAYMENTS:
    "PEMBAYARAN \u003cb\u003eTELAH LENGKAP\u003c/b\u003e",
  DETAILS_CONFIRMED:
    "Semua butiran telah dimasuk dan disahkan, anda kini boleh meneruskan langkah seterusnya!",
  DETAILS_MAKE_PAYMENT_NOW: "Buat satu pembayaran baru sekarang.",
  DETAILS_NOTIFICATION: "Pemberitahuan",
  DETAILS_NO_COMPLETED_PAYMENTS: "Masih tiada pembayaran lengkap.",
  DETAILS_NO_SCHEDULED_PAYMENTS: "Tiada jadual pembayaran lagi.",
  DETAILS_PAYMENTS_COLLECTED:
    "PEMBAYARAN \u003cb\u003eTELAH DIKUTIP\u003c/b\u003e",
  DETAILS_PAYMENTS_DEPOSITED:
    "PEMBAYARAN \u003cb\u003eTELAH DIDEPOSIT\u003c/b\u003e",
  DETAILS_PAYMENTS_IN_PROGRESS:
    "PEMBAYARAN \u003cb\u003eSEDANG DIPROSES\u003c/b\u003e",
  DETAILS_PAYMENTS_ON_HOLD: "Pembayaran \u003cb\u003eDitahan\u003c/b\u003e",
  DETAILS_PAYMENTS_REQUESTED:
    "PEMBAYARAN \u003cb\u003e TELAH DIMINTA\u003c/b\u003e",
  DETAILS_PLEASE_CHECK: "Sila semak dan kemas kini pelanggan anda {render}",
  DETAILS_PLEASE_CHECK_EMAIL: "alamat emel",
  DETAILS_PLEASE_CHECK_MOBILE: "nombor telefon mudah alih",
  DETAILS_REQUEST_FAILED:
    "Permintaan pembayaran (Nombor Invois \u003cspan\u003erender\u003c/span\u003e) gagal.",
  DETAILS_REQUEST_PAYMENT_NOW: "Minta pembayaran sekarang.",
  DETAILS_SCHEDULED_PAYMENTS: "PEMBAYARAN \u003cb\u003eBERJADUAL\u003c/b\u003e",
  DETAILS_SCHEDULE_PAYMENT_NOW: "Jadual pembayaran sekarang",
  DONE_BUTTON: "Telah Dibuat",
  DOWNLOAD_CSV_BUTTON: "Muat turun Templat CSV",
  DROPDOWN_AUSTRALIA: "Australia",
  DROPDOWN_HONG_KONG: "Hong Kong",
  DROPDOWN_MALAYSIA: "Malaysia",
  DROPDOWN_SELECT_COUNTRY: "Pilih negara",
  DROPDOWN_SINGAPORE: "Singapura",
  DUE_DATE_LABEL: "Tarikh cukup tempoh",
  DYNAMIC_CVV_CARD_NOT_ALLOWED_FOR_RECURRING_PAYMENT_ERROR:
    "Jenis kad ini tidak sah untuk pembayaran berulang atau pembayaran dengan tarikh caj masa hadapan. Sila gunakan kad lain.",
  DYNAMIC_CVV_CARD_TYPE_NOT_SUPPORT_ERROR:
    "Jenis kad ini tidak sah untuk pembayaran anda. Sila gunakan kad lain.",
  EDIT: "Edit",
  EDIT_ALL_BUTTON: "Edit Semua",
  EDIT_BUTTON: "Edit",
  EDIT_CUSTOMER_HERO: "Edit Pelanggan",
  EDIT_EMPLOYEE_LABEL: "Edit Pekerja",
  EDIT_SCHEDULED_PAYMENTS_DESCRIPTION:
    "Anda perlu membatalkan pembayaran berkaitan sebelum mengedit butiran {render}.",
  EDIT_SCHEDULED_PAYMENTS_WARNING:
    "{render} ini berkaitan dengan pembayaran berjadual.",
  EDIT_SUPPLIER_HERO: "Edit Pembekal",
  EMAIL_ACCOUNT_APPROVED_BODY:
    "Nampaknya kita telah mempunyai semua yang kita perlukan sekarang!",
  EMAIL_ACCOUNT_APPROVED_BODY_01: "Hai {{.FirstName}}",
  EMAIL_ACCOUNT_APPROVED_BODY_02:
    "Nampaknya kita telah mempunyai semua yang kita perlukan sekarang!",
  EMAIL_ACCOUNT_APPROVED_BODY_03: "Anda",
  EMAIL_ACCOUNT_APPROVED_BODY_04:
    "akaun telah disahkan, pembayaran anda kini aktif. Kami boleh mengesan status dengan mudah apabila bayaran seterusnya perlu dibayar di papan pemuka anda.",
  EMAIL_ACCOUNT_APPROVED_INSTRUCTIONS:
    "Akaun Fetch anda telah disahkan dan pembayaran anda kini aktif. Anda boleh menjejaki status dengan mudah apabila penyelesaian anda yang seterusnya perlu dibayar pada papan pemuka anda.",
  EMAIL_ACCOUNT_APPROVED_SUBJECT: "ipaymy Fetch akaun disahkan",
  EMAIL_ADDRESS_PLACEHOLDER: "Alamat Emel",
  EMAIL_ALREADY_EXISTS: "Emel ini telah didaftar.",
  EMAIL_ALREADY_VERIFIED: "Emel ini telah disahkan",
  EMAIL_ALREADY_VERIFIED_ERROR:
    "Emel anda telah disahkan. Anda boleh mendaftar masuk sekarang.",
  EMAIL_BEST_SIGNATURE: "Terbaik, Pasukan Pematuhan ipaymy",
  EMAIL_BE_PAID: "Dibayar",
  EMAIL_BUSINESS_RECEIPT_BODY:
    "Terima kasih untuk pembayaran {{.Currency}}{{.InvoiceAmount}} kepada {{.CompanyName}} melalui ipaymy! Sila lihat butiran resit anda di bawah.",
  EMAIL_BUSINESS_RECEIPT_BODY_01:
    "Hai {{.CustomerName}}! Terima kasih atas pembayaran anda",
  EMAIL_BUSINESS_RECEIPT_BODY_02: "kepada",
  EMAIL_BUSINESS_RECEIPT_BODY_03:
    "melalui ipaymy! Sila lihat butiran resit anda di bawah.",
  EMAIL_BUSINESS_RECEIPT_DATE_PAID: "Tarikh Bayaran",
  EMAIL_BUSINESS_RECEIPT_DETAILS: "Butiran Resit",
  EMAIL_BUSINESS_RECEIPT_HERO: "Resit dari {{.CompanyName}}",
  EMAIL_BUSINESS_RECEIPT_LABEL: "Resit",
  EMAIL_BUSINESS_RECEIPT_ORDER_NUMBER: "Nombor Pesanan",
  EMAIL_BUSINESS_RECEIPT_POWERED_BY: "Pembayaran dikuasakan oleh",
  EMAIL_BUSINESS_RECEIPT_SUPPORT_EMAIL: "Untuk sokongan, sila hantar emel ke",
  EMAIL_CANCEL_PAYMENT_COLLECTION_BODY:
    "Hai {{.CustomerName}}! Nampak seperti permintaan pembayaran {{.PaymentAmount}} untuk Invois # {{.DefaultComments}} telah dibatalkan oleh {{.CompanyName}}. Tiada apa-apa tindakan diperlukan di sini.",
  EMAIL_CANCEL_PAYMENT_COLLECTION_HERO:
    "Permintaan pembayaran telah dibatalkan",
  EMAIL_CANCEL_PAYMENT_COLLECTION_TRY:
    "Mulakan hari ini dan guna kad kredit anda untuk membayar sewa, gaji, invois dan lebih lagi.",
  EMAIL_CDD_ACCOUNT_APPROVED_BODY_02:
    "Maklumat syarikat anda telah disahkan dan pembayaran anda kini aktif. Anda boleh menjejaki status dengan mudah apabila penyelesaian anda yang seterusnya perlu dibayar pada papan pemuka anda.",
  EMAIL_CDD_ACCOUNT_APPROVED_SUBJECT: "akaun ipaymy disahkan",
  EMAIL_CDD_ACCOUNT_DECLINED_BODY_02:
    "Pengesahan syarikat anda telah ditolak. Dukacita dimaklumkan bahawa syarikat anda belum diluluskan untuk membuat atau menerima pembayaran di ipaymy.",
  EMAIL_CDD_ACCOUNT_DECLINE_SUBJECT:
    "ipaymy tidak dapat mengesahkan butiran akaun anda",
  EMAIL_CDD_ACCOUNT_DOCUMENT_REQUEST_BODY_02:
    "Kami sedang berhubung kerana kami mendapati anda telah membuat akaun dengan ipaymy. Kami teruja untuk menyertai anda, dan kami hanya perlu mengumpul sedikit lagi maklumat untuk mengesahkan syarikat anda. Malangnya, kami tidak dapat mengesahkan perkara ini berdasarkan maklumat yang diberikan.",
  EMAIL_CDD_ACCOUNT_DOCUMENT_REQUEST_BODY_04:
    "Tolong berikan salinan pendaftaran kerajaan anda (bertarikh dalam tempoh 3 bulan yang lalu).",
  EMAIL_CDD_ACCOUNT_DOCUMENT_REQUEST_MEANTIME_01:
    "Sementara itu, anda boleh mula mengeluarkan invois kepada pelanggan anda. Walau bagaimanapun, kami memerlukan maklumat ini untuk membolehkan akaun anda berfungsi sepenuhnya.",
  EMAIL_CDD_ACCOUNT_DOCUMENT_REQUEST_SUBJECT:
    "Maklumat tambahan diperlukan untuk akaun ipaymy anda",
  EMAIL_CDD_DECLINED_VERIFY_QUESTIONS:
    "Sekiranya anda mempunyai sebarang pertanyaan tentang pengesahan ini sila hubungi kami di ",
  EMAIL_CDD_FETCH_DECLINED_BODY_02:
    "Pengesahan Ambil anda telah ditolak. Dukacita kami maklumkan bahawa syarikat anda belum diluluskan untuk mengutip bayaran melalui Fetch.",
  EMAIL_CDD_FETCH_DECLINE_SUBJECT:
    "ipaymy tidak dapat mengesahkan butiran Ambil anda",
  EMAIL_COLLECTED_REFUND_AMOUNT: "Amaun Bayaran Balik",
  EMAIL_COLLECTED_REFUND_CARD: "kepada anda",
  EMAIL_COLLECTED_REFUND_COMPANY: "telah membuat pulangan kepada anda",
  EMAIL_COLLECTED_REFUND_COMPANY_OF: "telah membuat pulangan",
  EMAIL_COLLECTED_REFUND_DETAILS: "Butiran Bayaran Balik",
  EMAIL_COLLECTED_REFUND_FEE: "Pelarasan Yuran",
  EMAIL_COLLECTED_REFUND_RECEIPT: "untuk Resit",
  EMAIL_COLLECTED_REFUND_TEN:
    "Sila benarkan 10 hari bekerja bagi pembayaran ini dimasukkan dalam penyata anda.",
  EMAIL_COLLECTED_REFUND_TOTAL: "Jumlah Pemulangan",
  EMAIL_COMMISSION: "Komisen",
  EMAIL_COMPLETED_HERO: "Yeah! Pembayaran Telah Diterima",
  EMAIL_COMPLETED_INSTRUCTIONS:
    "Helo {{.FirstName}}! Pembayaran anda dengan butiran berikut telah diterima oleh penerima.",
  EMAIL_CONFIRM_DOES_NOT_MATCH: "Emel yang dimasukkan tidak sepadan.",
  EMAIL_COPYRIGHT: "Semua hakcipta terpelihara.",
  EMAIL_CREDIT_ENDED_BODY:
    "Masa pasti akan berlalu! Kami ingin memberi amaran bahawa anda telah mencapai penghujung tempoh pengecualian yuran transaksi Menerima Bayaran kami. Selepas ini yuran transaksi standard akan terpakai pada sebarang pembayaran yang dibuat dengan kad kredit atau debit kepada {{.CompanyName}}.",
  EMAIL_CREDIT_ENDED_BODY_01:
    "Masa pasti akan berlalu! Kami ingin memberi amaran bahawa anda telah mencapai penghujung tempoh pengecualian yuran transaksi Menerima Bayaran kami. Selepas ini yuran transaksi standard akan terpakai pada sebarang pembayaran yang dibuat dengan kad kredit atau debit kepada {{.CompanyName}}.",
  EMAIL_CREDIT_ENDED_BODY_02:
    "Berita baiknya ialah anda boleh terus menggunakan perkhidmatan kami tanpa yuran langgan tambahan atau yuran tersembunyi. Sertai ipaymy dan cipta invois anda seterusnya hari ini.",
  EMAIL_CREDIT_ENDED_INSTRUCTIONS:
    "Berita baiknya ialah anda boleh terus menggunakan perkhidmatan kami tanpa yuran langgan tambahan atau yuran tersembunyi. Sertai ipaymy dan cipta invois anda seterusnya hari ini.",
  EMAIL_CREDIT_ENDED_MONTH_BODY:
    "Nampaknya seperti anda sedang sibuk! Anda telah mencapai pembayaran percuma bulanan sebanyak {{.CreditCurrency}}{{.CreditAmount}}. Selepas ini, yuran transaksi standard kami bermula dari 2.8% akan terpakai.",
  EMAIL_CREDIT_ENDED_MONTH_BODY_01:
    "Nampaknya seperti anda sedang sibuk! Anda telah mencapai pembayaran percuma bulanan sebanyak",
  EMAIL_CREDIT_ENDED_MONTH_BODY_02:
    "Selepas ini, yuran transaksi standard kami bermula dari 2.8% akan terpakai.",
  EMAIL_CREDIT_ENDED_MONTH_BODY_03: "Berita baik bermula pada",
  EMAIL_CREDIT_ENDED_MONTH_BODY_04: "anda boleh memproses yang lain",
  EMAIL_CREDIT_ENDED_MONTH_BODY_05: "nilai transaksi secara percuma!",
  EMAIL_CREDIT_ENDED_MONTH_DASHBOARD: "Lihat dalam Papan Pemuka ipaymy",
  EMAIL_CREDIT_ENDED_MONTH_INSTRUCTIONS:
    "Berita baik bermula pada {{.NextDate}} anda boleh memproses transaksi lain bernilai {{.CreditCurrency}}{{.CreditAmount}} secara percuma!",
  EMAIL_CREDIT_ENDED_MONTH_SUBJECT:
    "Bagus! Anda telak memaksimakan simpanan anda!",
  EMAIL_CREDIT_ENDED_SUBJECT: "Semua perkara baik tidak perlu berakhir",
  EMAIL_CREDIT_ENTERED_BODY:
    "Hebat, anda baru sahaja menuntut pembayaran percuma anda!",
  EMAIL_CREDIT_ENTERED_BODY_01:
    "Hebat, anda baru sahaja menuntut pembayaran percuma anda!",
  EMAIL_CREDIT_ENTERED_BODY_02:
    "ipaymy akan mengecualikan yuran transaksi bagi yang pertama",
  EMAIL_CREDIT_ENTERED_BODY_03:
    "pembayaran kad kredit atau debit dibuat ke perniagaan anda setiap bulan untuk 3 bulan akan datang.",
  EMAIL_CREDIT_ENTERED_BODY_04: "Jumlah adalah",
  EMAIL_CREDIT_ENTERED_BODY_05:
    "nilai pembayaran invois pada 0% yuran transaksi!",
  EMAIL_CREDIT_ENTERED_BODY_06: "Beberapa petua berguna untuk anda bermula",
  EMAIL_CREDIT_ENTERED_HELP:
    "Perlukan bantuan untuk bermula? Jadual sesi penyertaan dengan kami hari ini!",
  EMAIL_CREDIT_ENTERED_INSTRUCTIONS:
    "ipaymy akan mengecualikan yuran transaksi bagi {{.CreditCurrency}}{{.CreditAmount}} yang pertama pembayaran kad kredit atau debit yang dibuat kepada perniagaan anda setiap bulan untuk 3 bulan akan datang. Ini adalah sebanyak {{.CreditCurrency}}{{.TotalCreditAmount}} nilai pembayaran invois pada 0% yuran transaksi!",
  EMAIL_CREDIT_ENTERED_NEW_BODY:
    "Cuba teka? Anda telah mendapat sebulan lagi untuk pengecualian yuran transaksi bagi {{.CreditCurrency}} pertama {{.CreditAmount}} pembayaran kad kredit atau debit card yang dibuat kepada {{.CompanyName}}.",
  EMAIL_CREDIT_ENTERED_NEW_BODY_01:
    "Cuba teka? Anda telah mendapat sebulan lagi untuk pengecualian yuran transaksi bagi pertama",
  EMAIL_CREDIT_ENTERED_NEW_BODY_02:
    "pembayaran kad kredit atau debit dibuat kepada {{.CompanyName}}.",
  EMAIL_CREDIT_ENTERED_NEW_BODY_03:
    "Pastikan anda mengguna sepenuhnya tawaran istimewa ini sebelum",
  EMAIL_CREDIT_ENTERED_NEW_BODY_04:
    "Sertai ipaymy dan cipta invois anda seterusnya hari ini.",
  EMAIL_CREDIT_ENTERED_NEW_CREATE_BUTTON: "Buat Invois",
  EMAIL_CREDIT_ENTERED_NEW_INSTRUCTIONS:
    "Pastikan anda mengguna sepenuhnya tawaran istimewa ini sebelum {{.ThisMonthEndDate}}. Sertai ipaymy dan cipta invois anda seterusnya hari ini.",
  EMAIL_CREDIT_ENTERED_NEW_SUBJECT: "Anda mempunyai lebih pembayaran percuma!",
  EMAIL_CREDIT_ENTERED_SCHEDULE_BUTTON: "Sesi Berjadual",
  EMAIL_CREDIT_ENTERED_SUBJECT: "Anda telah memperolehi promosi hebat",
  EMAIL_CREDIT_ENTERED_TIPS: "Beberapa petua berguna untuk anda bermula",
  EMAIL_CREDIT_ENTERED_TIP_1:
    "Setiap bulan, perniagaan anda boleh menerima nilai {{.CreditCurrency}}{{.CreditAmount}} pembayaran kad secara percuma",
  EMAIL_CREDIT_ENTERED_TIP_1_01: "Setiap bulan, perniagaan anda boleh menerima",
  EMAIL_CREDIT_ENTERED_TIP_1_02: "nilai pembayaran kad percuma sepenuhnya",
  EMAIL_CREDIT_ENTERED_TIP_2:
    "Program akan berkuatkuasa apabila pelanggan pertama anda membayar anda menggunakan kad",
  EMAIL_CREDIT_ENTERED_TIP_3:
    "Anda akan dimaklumkan melalui emel apabila anda telah mencapai had percuma bulanan anda, selepas itu yuran transaksi standard ipaymy akan terpakai.",
  EMAIL_CUSTOMER_PAID_BODY:
    "Berita baik! {{.CustomerName}} telah membayar anda {{.Currency}}{{.Amount}}. Wang akan didepositkan ke dalam akaun {{.BankName}} anda yang berakhir dengan {{.BankAccountNumberLast4}} pada {{.PayoutDate}}.",
  EMAIL_CUSTOMER_PAID_BODY_01: "Berita baik!",
  EMAIL_CUSTOMER_PAID_BODY_02: "telah membayar anda",
  EMAIL_CUSTOMER_PAID_BODY_03: "Wang akan didepositkan ke dalam",
  EMAIL_CUSTOMER_PAID_BODY_04: "akaun berakhir dalam",
  EMAIL_CUSTOMER_PAID_BODY_05: "pada",
  EMAIL_CUSTOMER_PAID_DASHBOARD: "Lihat dalam Papan Pemuka ipaymy",
  EMAIL_CUSTOMER_PAID_DETAILS: "Butiran Pembayaran",
  EMAIL_CUSTOMER_PAID_HERO: "{{.CustomerName}} telah membayar anda!",
  EMAIL_CUSTOMER_PAID_SUBJECT: "%s dibayar",
  EMAIL_DEPOSITED_BODY:
    "Pembayaran anda telah didepositkan ke akaun {{.BankName}} anda yang berakhir dengan {{.BankAccountNumberLast4}}.",
  EMAIL_DEPOSITED_BODY_01: "Hai",
  EMAIL_DEPOSITED_BODY_02: "Pembayaran anda telah didepositkan ke",
  EMAIL_DEPOSITED_BODY_03: "akaun berakhir dalam",
  EMAIL_DEPOSITED_HERO: "Tahniah! Pembayaran anda telah didepositkan.",
  EMAIL_DEPOSITED_TOTAL: "Jumlah Amaun Yang Didepositkan",
  EMAIL_DOCUMENT_REQUEST_ACCOUNT:
    "Lengkapkan nombor akaun bank untuk pengesahan",
  EMAIL_DOCUMENT_REQUEST_BODY:
    "Kami sedang berhubung kerana kami mendapati anda telah membuat akaun Fetch dengan ipaymy. Kami teruja untuk menyertai anda, dan hanya perlu mengumpul sedikit lagi maklumat untuk mengesahkan butiran akaun anda. Malangnya, kami tidak dapat mengesahkan perkara ini berdasarkan maklumat yang diberikan.",
  EMAIL_DOCUMENT_REQUEST_BODY_01: "Hi {{.FirstName}}",
  EMAIL_DOCUMENT_REQUEST_BODY_02:
    "Kami menghubungi anda kerana kami mendapati bahawa anda telah mencipta satu",
  EMAIL_DOCUMENT_REQUEST_BODY_03:
    "akaun denagn ipaymy. Kami amat gembira dengan penyertaan anda dan kami hanya perlu beberapa maklumat lagi untuk mengesahkan butiran akaun anda. Malangnya, kami tidak boleh mengesahkan ini berdasarkan maklumat yang telah diberi.",
  EMAIL_DOCUMENT_REQUEST_BODY_04:
    "Bolehkah anda memberikan salinan penyata bank anda (bertarikh dalam tempoh 3 bulan yang lalu). Akaun bank ini mestilah milik syarikat yang anda cipta akaun Fetch anda. Penyata bank mesti mengandungi:",
  EMAIL_DOCUMENT_REQUEST_COMPANY: "Nama syarikat anda",
  EMAIL_DOCUMENT_REQUEST_MEANTIME:
    "Sementara itu, anda boleh mula mengeluarkan invois kepada pelanggan anda. Walau bagaimanapun, kami memerlukan maklumat ini untuk membolehkan akaun Fetch anda berfungsi sepenuhnya.",
  EMAIL_DOCUMENT_REQUEST_MEANTIME_01:
    "Sementara itu, anda boleh mula mengeluarkan invois kepada pelanggan anda. Tetapi, kami memerlukan maklumat ini bagi mendapatkan",
  EMAIL_DOCUMENT_REQUEST_MEANTIME_02: "akaun telah sedia dan boleh digunakan.",
  EMAIL_DOCUMENT_REQUEST_RESPOND:
    "Anda boleh memberi maklumat ini dengan membalas emel ini secara terus.",
  EMAIL_DOCUMENT_REQUEST_SIGNATURE_01: "Terbaik",
  EMAIL_DOCUMENT_REQUEST_SIGNATURE_02: "Pasukan Pematuhan ipaymy",
  EMAIL_DOCUMENT_REQUEST_STATEMENT:
    "Bolehkah anda menyediakan kami penyata akaun bank yang dikeluarkan dalam masa 3 bulan yang lalu? Penyata bank anda mestilah mempunyai:",
  EMAIL_DOCUMENT_REQUEST_SUBJECT:
    "Maklumat tambahan diperlukan untuk akaun ipaymy Fetch anda",
  EMAIL_DOCUMENT_REQUEST_THANKS:
    "Terima kasih kerana memahami dan sila hubungi kami sekiranya anda mempunyai sebarang soalan.",
  EMAIL_DOES_NOT_EXIST:
    "Akaun dengan kombinasi alamat emel dan kata laluan ini tidak wujud.",
  EMAIL_ERROR: "Sila masukkan alamat emel yang sah",
  EMAIL_FAQ: "Soalan Lazim",
  EMAIL_FETCH: "Fetch",
  EMAIL_GREETING: "Hi {{.FirstName}}",
  EMAIL_GREETING_COMPANY: "Hai {{.CompanyName}}",
  EMAIL_HERE_LINK: "di sini",
  EMAIL_INSTRUCTIONS:
    "Sila berikan emel di mana semua maklumat mengenai pembayaran yang dibuat dalam akaun anda dihantar",
  EMAIL_LABEL: "Emel",
  EMAIL_NOT_EXIST: "Alamat e-mel ini tidak terdapat dalam pangkalan data kami.",
  EMAIL_OVERDUE_BODY:
    "Permintaan pembayaran anda dari {{.CompanyName}} untuk {{.InvoiceNumber}} telah melampaui tempoh selama 2 hari.",
  EMAIL_OVERDUE_BODY_01:
    "Helo {{.CustomerName}}! Permintaan pembayaran anda dari",
  EMAIL_OVERDUE_BODY_02: "untuk {{.InvoiceNumber}} adalah",
  EMAIL_OVERDUE_BODY_03: "Tertunggak 2 hari.",
  EMAIL_OVERDUE_HERO: "Pembayaran anda telah melampaui tempoh.",
  EMAIL_OVERDUE_SUBJECT: "Pembayaran anda kepada %s telah melampaui tempoh",
  EMAIL_PLACEHOLDER: "Emel",
  EMAIL_RECEIPT_AMOUNT: "Amaun",
  EMAIL_RECEIPT_BANK_FEE: "Yuran Bank",
  EMAIL_RECEIPT_CARD: "Kad",
  EMAIL_RECEIPT_COMPANY: "Syarikat",
  EMAIL_RECEIPT_COUNTRY: "Negara Penerima",
  EMAIL_RECEIPT_DESCRIPTION: "Keterangan",
  EMAIL_RECEIPT_DETAILS: "Butiran",
  EMAIL_RECEIPT_DISCOUNT: "Diskaun",
  EMAIL_RECEIPT_FEE: "Yuran",
  EMAIL_RECEIPT_FEE_TOTAL: "Jumlah Yuran",
  EMAIL_RECEIPT_HERO: "Pembayaran Berjaya",
  EMAIL_RECEIPT_INSTRUCTIONS:
    "Terima kasih kerana mengguna ipaymy! Penerima anda akan menerima wang pada atau sebelum",
  EMAIL_RECEIPT_ITEM: "Item",
  EMAIL_RECEIPT_NAME: "Nama",
  EMAIL_RECEIPT_NUMBER: "Nombor Resit",
  EMAIL_RECEIPT_PRICE: "Harga",
  EMAIL_RECEIPT_TOTAL: "Jumlah",
  EMAIL_REFERRER_BODY:
    "Berita baik, {{.RefereeName}} baru sahaja membuat pembayaran pertamanya melalui ipaymy!",
  EMAIL_REFERRER_BODY_DETAILS: "Terus merujuk, terus memperolehi!",
  EMAIL_REFERRER_BODY_INSTRUCTIONS:
    "Guna kod promo {{.ReferrerCouponName}} untuk amaun berikut ditolak dari yuran transaksi ipaymy bagi pembayaran anda seterusnya sebelum {{.ReferrerCouponExpiryDate}}",
  EMAIL_REFERRER_REDEEM_BUTTON: "Tuntut Tawaran",
  EMAIL_REFERRER_TC_1:
    "Kod promo hanya sah untuk pengguna yang mengikuti Program Rujukan ipaymy",
  EMAIL_REFERRER_TC_2:
    "Pembayaran mesti dibuat dengan kad Visa atau Mastercard tempatan",
  EMAIL_REFERRER_TC_3: "Kod promo sah untuk pembayaran satu kali",
  EMAIL_REFERRER_TC_4:
    "Tarikh caj mestilah pada atau sebelum {{.ReferrerCouponExpiryDate}}",
  EMAIL_REFUND_AMOUNT: "Amaun Yang Dibayar Balik",
  EMAIL_REFUND_BODY:
    "Hai {{.FirstName}}, pembayaran anda dengan butiran berikut telah berjaya dipulangkan.",
  EMAIL_REFUND_BODY_WITH_CUSTOMER:
    "Hai {{.FirstName}}, pembayaran anda dari {{.CustomerName}} dengan butiran berikut telah berjaya dipulangkan.",
  EMAIL_REFUND_FEE: "Yuran Yang Dibayar Balik",
  EMAIL_REFUND_HERO: "Pulangan Pembayaran",
  EMAIL_REFUND_INSTRUCTIONS:
    "Emel ini adalah untuk mengesahkan bahawa satu pemulangan telah dibuat oleh ipaymy. Ia mengambil masa 5 ke 10 hari bekerja untuk ia masuk ke penyata mereka.",
  EMAIL_REFUND_INSTRUCTIONS_01:
    "Emel ini adalah untuk mengesahkan bahawa satu pemulangan telah dibuat oleh ipaymy.",
  EMAIL_REFUND_INSTRUCTIONS_02:
    "Ia mengambil masa 5 ke 10 hari bekerja untuk ia masuk ke penyata mereka.",
  EMAIL_REFUND_INSTRUCTIONS_WITH_CUSTOMER:
    "Emel ini adalah untuk mengesahkan bahawa satu pemulangan telah dibuat kepada pelanggan anda. Ia mengambil masa 5 ke 10 hari bekerja untuk ia masuk ke penyata mereka.",
  EMAIL_REFUND_INSTRUCTIONS_WITH_CUSTOMER_01:
    "Emel ini adalah untuk mengesahkan bahawa satu pemulangan telah dibuat kepada pelanggan anda.",
  EMAIL_REFUND_INSTRUCTIONS_WITH_CUSTOMER_02:
    "Ia mengambil masa 5 ke 10 hari bekerja untuk ia masuk ke penyata mereka.",
  EMAIL_REFUND_NEW_TOTAL: "Jumlah Baru",
  EMAIL_REFUND_SUBJECT: "ipaymy %s Dipulangkan",
  EMAIL_REMINDER_BODY:
    "Helo {{.CustomerName}}! Permintaan pembayaran anda dari {{.CompanyName}} untuk {{.InvoiceNumber}} perlu dibayar sebelum atau pada {{.DueDate}}.",
  EMAIL_REMINDER_DISREGARD:
    "Jika anda telah membayar invois anda, sila abaikan mesej ini.",
  EMAIL_REMINDER_HERO: "Pembayaran anda perlu dibayar tidak lama lagi.",
  EMAIL_REMINDER_INSTRUCTIONS_01:
    "Helo {{.CustomerName}}! Permintaan pembayaran anda dari",
  EMAIL_REMINDER_INSTRUCTIONS_02:
    "untuk {{.InvoiceNumber}} perlu dibayar sebelum atau pada",
  EMAIL_REMINDER_SUBJECT: "Pembayaran anda kepada %s dijelaskan hari ini",
  EMAIL_REQUEST_BODY:
    "Hai {{.CustomerName}}, {{.CompanyName}} meminta pembayaran untuk {{.InvoiceNumber}}. Jumlah amaun adalah {{.Currency}}{{.Amount}} dan perlu dibayar pada atau sebelum {{.DueDate}}.",
  EMAIL_REQUEST_DOWNLOAD_01: "Anda boleh lihat atau muat turun",
  EMAIL_REQUEST_DOWNLOAD_02: "invois anda di sini",
  EMAIL_REQUEST_EMAIL_CHAT_01:
    "Pembayaran dikuasakan oleh ipaymy. Untuk sokongan, sila hantar emel ke",
  EMAIL_REQUEST_EMAIL_CHAT_02: "atau sembang dengan kami di",
  EMAIL_REQUEST_HERO: "Anda telah menerima permintaan pembayaran.",
  EMAIL_REQUEST_INSTRUCTIONS_01: "Hai {{.CustomerName}}",
  EMAIL_REQUEST_INSTRUCTIONS_02:
    "meminta pembayaran bagi {{.InvoiceNumber}}. Jumlah amaun ialah",
  EMAIL_REQUEST_INSTRUCTIONS_03: "dan perlu dibayar sebelum atau pada",
  EMAIL_REQUEST_INVOICE_NUMBER: "Nombor Invois",
  EMAIL_REQUEST_NOTE: "Nota dari",
  EMAIL_REQUEST_PAY_BUTTON: "Bayar Sekarang",
  EMAIL_REQUEST_SUBJECT: "%s meminta pembayaran untuk %s",
  EMAIL_REQUEST_TOTAL: "Jumlah",
  EMAIL_SCHEDULED_FAILED: "Alamak... Pembayaran Gagal",
  EMAIL_SCHEDULED_FAILED_INSTRUCTIONS:
    "Hai {{.FirstName}}, pembayaran anda tidak boleh diproses kerana sebab berikut: {{.FailureReason}}",
  EMAIL_SCHEDULED_FAILED_PAYMENT_BUTTON: "Buat Pembayaran Lain",
  EMAIL_SCHEDULED_FAILED_PAYOUT: "Tarikh Pembayaran",
  EMAIL_SECTION: "Emel",
  EMAIL_SENT: "Satu emel telah dihantar ke {emel}",
  EMAIL_SUPPLIER_NOTIFICATION_BODY_01:
    "Berita baik! {{.RecipientName}} telah menerima pembayaran invois sebanyak {{.PaymentCurrency}}{{.PaymentAmount}} dari {{.UserFullName}} melalui ipaymy. Wang dalam proses dan akan didepositkan terus ke dalam {{.PaymentBank}} anda bernombor {{.PaymentAccountNumber}} pada {{.PayoutDate}}.",
  EMAIL_SUPPLIER_NOTIFICATION_BODY_02:
    'Untuk memastikan anda tidak terlepas pembayaran, lihat keterangan "{{.BeneficiaryStatement}}" dari "IPAYMY TECHNOLOGIES PTE LTD" pada penyata bank anda.',
  EMAIL_SUPPLIER_NOTIFICATION_BODY_03:
    "ipaymy adalah platform atas talian yang membolehkan PKS untuk membayar sewa, gaji, invois malah cukai menggunakan kad kredit yang mereka miliki hari ini. Platform kami yang selamat menjadikannya mudah untuk dilakukan dan mengurus pembayaran kepada pembekal yang pada masa ini tidak menerima pembayaran kad kredit.",
  EMAIL_SUPPLIER_NOTIFICATION_BODY_04:
    "Jika anda berminat untuk mempelajari lebih lanjut mengenai ipaymy untuk membuat atau mengutip pembayaran, pasukan kami sedia untuk membantu di hello@ipaymy.com",
  EMAIL_SUPPORT: "Sokongan",
  EMAIL_TCS: "Terma\u0026Syarat",
  EMAIL_TEMPLATE_CANCEL_PAYMENT_REQUEST_SUBJECT:
    "Permintaan pembayaran telah dibatalkan",
  EMAIL_TEMPLATE_MARK_BULK_COLLECTED_PAYMENT_AS_COMPLETE_SUBJECT:
    "Pembayaran anda telah didepositkan melalui ipaymy",
  EMAIL_TEMPLATE_MARK_PAYMENT_AS_COMPLETE_SUBJECT: "%d-%d ipaymy telah Lengkap",
  EMAIL_TEMPLATE_PARTNER_MARK_PAYMENT_AS_COMPLETE_SUBJECT:
    "Pembayaran anda telah didepositkan melalui ipaymy",
  EMAIL_TEMPLATE_PARTNER_PAYMENT_COLLECTION_SUCCESS_CUSTOMER_SUBJECT:
    "%s Resit %s",
  EMAIL_TEMPLATE_RECEIPT_SUBJECT: "ipaymy Resit %s",
  EMAIL_TEMPLATE_REFERRER_SUBJECT: "%s, anda telah memperolehi %s",
  EMAIL_TEMPLATE_SCHEDULE_FAILED_SUBJECT: "Pembayaran berjadual anda gagal",
  EMAIL_TEMPLATE_SUPPLIER_NOTIFICATION_SUBJECT:
    "%s telah menerima pembayaran invois melalui ipaymy",
  EMAIL_TEMPLATE_WALLEX_BUSINESS_KYC_APPROVED_SUBJECT:
    "ipaymy telah Mengesahkan Butiran Syarikat",
  EMAIL_TEMPLATE_WALLEX_BUSINESS_KYC_REJECTED_SUBJECT:
    "ipaymy tidak dapat Mengesahkan Butiran Syarikat anda",
  EMAIL_TEMPLATE_WALLEX_BUSINESS_KYC_UNDER_REVIEW_SUBJECT:
    "ipaymy sedang Mengesahkan Butiran Syarikat anda",
  EMAIL_TEMPLATE_WALLEX_KYC_APPROVED_SUBJECT:
    "ipaymy telah Mengesahkan Identiti anda",
  EMAIL_TEMPLATE_WALLEX_KYC_REJECTED_SUBJECT:
    "ipaymy tidak dapat Mengesahkan Identiti anda",
  EMAIL_TEMPLATE_WALLEX_KYC_UNDER_REVIEW_SUBJECT:
    "ipaymy sedan Mengesahkan Identiti anda",
  EMAIL_TERMS_OF_USE: "Terma-terma Penggunaan",
  EMAIL_TRANSACTION_ACCOUNT: "Nombor Akaun Penerima",
  EMAIL_TRANSACTION_ADDRESS:
    "Alamat: 144 Robinson Road, #06-02, Singapura 068908",
  EMAIL_TRANSACTION_AMOUNT: "Amaun Dipindahkan",
  EMAIL_TRANSACTION_BANK: "Bank Penerima",
  EMAIL_TRANSACTION_DATE: "Tarikh Pembayaran",
  EMAIL_TRANSACTION_HERO: "Butiran Transaksi",
  EMAIL_TRANSACTION_IPAYMY: "ipaymy Technologies Pte. Ltd.",
  EMAIL_TRANSACTION_PAGE: "Halaman {{.Page}} dari {{.Total}}",
  EMAIL_TRANSACTION_PURPOSE: "Tujuan",
  EMAIL_TRANSACTION_THANKS: "Terima kasih kerana mengguna ipaymy!",
  EMAIL_TRANSACTION_UEN: "UEN: 201535772K",
  EMAIL_TRANSACTION_WEBSITE: "Laman web",
  EMAIL_VERIFICATION_REQUIRED: "Sila sahkan emel anda terlebih dahulu.",
  EMAIL_VERIFIED:
    "Pengesahan emel berjaya! Anda boleh mendaftar masuk sekarang.",
  EMAIL_VIEW_INVOICE_INSTRUCTIONS:
    "Anda boleh melihat atau memuat turun invois anda",
  EMPLOYEES_HERO: "PEKERJA-PEKERJA",
  EMPLOYEES_INSTRUCTIONS:
    'Berikut adalah pekerja-pekerja berdaftar anda. Ada yang baru?{br}Klik "Tambah Pekerja Baru" untuk menambahnya.',
  EMPLOYEES_MENU_ITEM: "Pekerja-pekerja",
  EMPLOYEE_ACCOUNT_NUMBER_HEADER: "Nombor Akaun",
  EMPLOYEE_ACCOUNT_PLACEHOLDER: "Nombor akaun bank pekerja",
  EMPLOYEE_AMOUNT_HEADER: "Amaun",
  EMPLOYEE_BANK_HEADER: "Bank",
  EMPLOYEE_COUNTRY_HEADER: "Negara",
  EMPLOYEE_DELETE_HERO: "Adakah anda ingin memadam pekerja ini?",
  EMPLOYEE_DELETE_INSTRUCTIONS:
    "Pekerja ini akan dikeluarkan dari pekerja-pekerja anda",
  EMPLOYEE_FULL_NAME_LABEL: "Nama Penuh Pekerja",
  EMPLOYEE_FULL_NAME_PLACEHOLDER: "Nama Penuh Pekerja",
  EMPLOYEE_HEADER: "Pekerja",
  EMPLOYEE_HKID_HEADER: "HKID",
  EMPLOYEE_ID_HEADER: "ID",
  EMPLOYEE_LABEL: "Pekerja",
  EMPLOYEE_NOTE_HEADER: "Nota kepada Pekerja",
  EMPLOYEE_NRIC_HEADER: "NRIC",
  EMPLOYEE_SCHEDULED_PAYMENT_HERO:
    "Pekerja ini berkaitan dengan pembayaran berjadual.",
  EMPLOYEE_SCHEDULED_PAYMENT_INSTRUCTIONS:
    "Anda perlu membatalkan pembayaran berkaitan sebelum mengedit butiran pekerja.",
  ENTER_EMAIL_PLACEHOLDER: "Masukkan email",
  ENTER_INVOICE_AMOUNT_HEADER: "Masukkan Amaun Invois dan Muat Naik Invois",
  ENTER_INVOICE_DETAILS_HERO: "MASUKKAN BUTIRAN INVOIS",
  ENTER_PASSWORD_ERROR:
    "Sila pilih kata laluan yang lebih kukuh dengan sekurang-kurangnya 8 aksara yang mengandungi huruf, nombor dan simbol.",
  ENTER_PASSWORD_PLACEHOLDER: "Masukkan Kata Laluan",
  ENTER_PIN:
    "Sila masukkah PIN pengesahan yang dihantar ke nombor telefon anda yang berakhir dengan \u003cb\u003e{render}.\u003c/b\u003e",
  ERROR_ADDING_CARD:
    "Kami menghadapi masalah dengan kad anda. Sila cuba lagi, atau hubungi kami di support@ipaymy.com.",
  ERROR_ADYEN_CARD_UNACCEPTED:
    "Kami hanya menerima MasterCard dan Visa buat masa ini.",
  ERROR_BRAINTREE_CARD_UNACCEPTED: "Kami hanya menerima Visa dan Mastercard.",
  ERROR_BT_2000:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_2001:
    "Pembayaran gagal kerana kredit tidak mencukupi. Anda mungkin telah mencapai had kredit maksimum pada kad kredit ini. Sila sesuaikan jumlah pembayaran atau gunakan kad kredit lain.",
  ERROR_BT_2002:
    "Transaksi ini gagal keran melebihi had pengeluaran. Sila guna kad yang lain.",
  ERROR_BT_2003:
    "Transaksi ini gagal keran melebihi had pengeluaran. Sila guna kad yang lain.",
  ERROR_BT_2004:
    "Tarikh tamat tempoh kad tidak tepat. Semak tarikh tamat tempoh kad atau guna kad yang lain.",
  ERROR_BT_2005:
    "Nombor kad tidak tepat. Semak nombor kad atau guna kad yang lain.",
  ERROR_BT_2006:
    "Tamat kad tidak sah. Periksa tarikh luput atau gunakan kad lain.",
  ERROR_BT_2007:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2008:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2009:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2010:
    "CVC/CVV kad tidak tepat. Semak kod keselamatan kad atau guna kad lain.",
  ERROR_BT_2011:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2012:
    "Transaksi tidak dapat diluluskan. Sila cuba kad lain atau hubungi pengeluar kad untuk maklumat lanjut.",
  ERROR_BT_2013:
    "Transaksi tidak dapat diluluskan. Sila cuba kad lain atau hubungi pengeluar kad untuk maklumat lanjut.",
  ERROR_BT_2014:
    "Transaksi tidak dapat diluluskan. Sila cuba kad lain atau hubungi pengeluar kad untuk maklumat lanjut.",
  ERROR_BT_2015:
    "Transaksi tidak dapat diluluskan. Sila cuba kad lain atau hubungi pengeluar kad untuk maklumat lanjut.",
  ERROR_BT_2016:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2017:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2018:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2019:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2020:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2021:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2022:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2023:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2024:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2025:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2026:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2027:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2028:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2029:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2030:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2031:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2032:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2033:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2034:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2035:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2036:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2037:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2038:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2039:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2040:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2041:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_2042:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2043:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_2044:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_2045:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2046:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_2047:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_2048:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2049:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2050:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_2051:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2052:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2053:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_2054:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2055:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2056:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2057:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_2058:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2059:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2060:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2061:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2062:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2063:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2064:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2065:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2066:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2067:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2068:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2069:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2070:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2071:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2072:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2073:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2074:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2075:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2076:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2077:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2078:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2079:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2080:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2081:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2082:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2083:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2084:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2085:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2086:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2087:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2088:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2089:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2090:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2091:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2092:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2093:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2094:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2095:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2096:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2097:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2098:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2099:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_2100:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2101:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2102:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2103:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  ERROR_BT_2104:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2105:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2106:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2107:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2108:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2109:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2110:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2111:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2112:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2113:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2114:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2115:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2116:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2117:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2118:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2119:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2120:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2121:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2122:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2123:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2124:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2125:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2126:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2127:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2128:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2129:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2130:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2131:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2132:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2133:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2134:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2135:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2136:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2137:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2138:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2139:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2140:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2141:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2142:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2143:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2144:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2145:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2146:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2147:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2148:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2149:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2150:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2151:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2152:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2153:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2154:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2155:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2156:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2157:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2158:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2159:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2160:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2161:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2162:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2163:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2164:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2165:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2166:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2167:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2168:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2169:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2170:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2171:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2172:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2173:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2174:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2175:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2176:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2177:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2178:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2179:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2180:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2181:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2182:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2183:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2184:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2185:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2186:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2187:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2188:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2189:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2190:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2191:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2192:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2193:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2194:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2195:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2196:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2197:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2198:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2199:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2200:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2201:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2202:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2203:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2204:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2205:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2206:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2207:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2208:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2209:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2210:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2211:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2212:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2213:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2214:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2215:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2216:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2217:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2218:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2219:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2220:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2221:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2222:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2223:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2224:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2225:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2226:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2227:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2228:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2229:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2230:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2231:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2232:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2233:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2234:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2235:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2236:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2237:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2238:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2239:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2240:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2241:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2242:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2243:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2244:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2245:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2246:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2247:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2248:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2249:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2250:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2251:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2252:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2253:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2254:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2255:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2256:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2257:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2258:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2259:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2260:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2261:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2262:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2263:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2264:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2265:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2266:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2267:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2268:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2269:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2270:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2271:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2272:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2273:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2274:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2275:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2276:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2277:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2278:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2279:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2280:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2281:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2282:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2283:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2284:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2285:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2286:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2287:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2288:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2289:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2290:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2291:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2292:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2293:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2294:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2295:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2296:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2297:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2298:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2299:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2300:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2301:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2302:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2303:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2304:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2305:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2306:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2307:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2308:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2309:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2310:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2311:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2312:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2313:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2314:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2315:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2316:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2317:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2318:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2319:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2320:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2321:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2322:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2323:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2324:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2325:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2326:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2327:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2328:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2329:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2330:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2331:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2332:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2333:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2334:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2335:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2336:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2337:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2338:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2339:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2340:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2341:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2342:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2343:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2344:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2345:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2346:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2347:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2348:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2349:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2350:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2351:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2352:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2353:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2354:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2355:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2356:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2357:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2358:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2359:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2360:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2361:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2362:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2363:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2364:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2365:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2366:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2367:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2368:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2369:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2370:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2371:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2372:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2373:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2374:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2375:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2376:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2377:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2378:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2379:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2380:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2381:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2382:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2383:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2384:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2385:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2386:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2387:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2388:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2389:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2390:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2391:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2392:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2393:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2394:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2395:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2396:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2397:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2398:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2399:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2400:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2401:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2402:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2403:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2404:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2405:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2406:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2407:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2408:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2409:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2410:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2411:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2412:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2413:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2414:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2415:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2416:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2417:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2418:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2419:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2420:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2421:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2422:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2423:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2424:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2425:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2426:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2427:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2428:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2429:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2430:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2431:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2432:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2433:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2434:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2435:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2436:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2437:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2438:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2439:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2440:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2441:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2442:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2443:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2444:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2445:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2446:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2447:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2448:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2449:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2450:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2451:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2452:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2453:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2454:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2455:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2456:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2457:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2458:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2459:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2460:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2461:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2462:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2463:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2464:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2465:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2466:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2467:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2468:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2469:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2470:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2471:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2472:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2473:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2474:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2475:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2476:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2477:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2478:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2479:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2480:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2481:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2482:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2483:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2484:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2485:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2486:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2487:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2488:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2489:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2490:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2491:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2492:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2493:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2494:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2495:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2496:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2497:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2498:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2499:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2500:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2501:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2502:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2503:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2504:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2505:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2506:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2507:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2508:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2509:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2510:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2511:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2512:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2513:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2514:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2515:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2516:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2517:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2518:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2519:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2520:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2521:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2522:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2523:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2524:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2525:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2526:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2527:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2528:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2529:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2530:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2531:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2532:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2533:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2534:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2535:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2536:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2537:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2538:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2539:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2540:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2541:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2542:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2543:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2544:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2545:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2546:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2547:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2548:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2549:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2550:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2551:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2552:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2553:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2554:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2555:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2556:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2557:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2558:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2559:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2560:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2561:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2562:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2563:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2564:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2565:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2566:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2567:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2568:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2569:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2570:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2571:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2572:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2573:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2574:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2575:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2576:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2577:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2578:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2579:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2580:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2581:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2582:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2583:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2584:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2585:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2586:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2587:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2588:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2589:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2590:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2591:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2592:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2593:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2594:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2595:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2596:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2597:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2598:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2599:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2600:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2601:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2602:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2603:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2604:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2605:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2606:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2607:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2608:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2609:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2610:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2611:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2612:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2613:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2614:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2615:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2616:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2617:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2618:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2619:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2620:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2621:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2622:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2623:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2624:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2625:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2626:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2627:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2628:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2629:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2630:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2631:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2632:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2633:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2634:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2635:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2636:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2637:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2638:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2639:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2640:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2641:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2642:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2643:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2644:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2645:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2646:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2647:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2648:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2649:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2650:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2651:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2652:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2653:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2654:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2655:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2656:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2657:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2658:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2659:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2660:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2661:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2662:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2663:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2664:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2665:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2666:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2667:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2668:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2669:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2670:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2671:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2672:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2673:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2674:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2675:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2676:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2677:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2678:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2679:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2680:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2681:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2682:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2683:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2684:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2685:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2686:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2687:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2688:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2689:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2690:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2691:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2692:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2693:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2694:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2695:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2696:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2697:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2698:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2699:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2700:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2701:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2702:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2703:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2704:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2705:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2706:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2707:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2708:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2709:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2710:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2711:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2712:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2713:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2714:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2715:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2716:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2717:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2718:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2719:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2720:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2721:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2722:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2723:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2724:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2725:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2726:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2727:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2728:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2729:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2730:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2731:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2732:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2733:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2734:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2735:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2736:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2737:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2738:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2739:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2740:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2741:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2742:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2743:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2744:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2745:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2746:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2747:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2748:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2749:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2750:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2751:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2752:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2753:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2754:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2755:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2756:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2757:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2758:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2759:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2760:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2761:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2762:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2763:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2764:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2765:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2766:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2767:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2768:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2769:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2770:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2771:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2772:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2773:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2774:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2775:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2776:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2777:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2778:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2779:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2780:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2781:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2782:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2783:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2784:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2785:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2786:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2787:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2788:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2789:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2790:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2791:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2792:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2793:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2794:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2795:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2796:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2797:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2798:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2799:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2800:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2801:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2802:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2803:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2804:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2805:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2806:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2807:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2808:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2809:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2810:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2811:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2812:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2813:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2814:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2815:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2816:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2817:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2818:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2819:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2820:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2821:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2822:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2823:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2824:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2825:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2826:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2827:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2828:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2829:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2830:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2831:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2832:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2833:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2834:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2835:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2836:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2837:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2838:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2839:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2840:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2841:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2842:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2843:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2844:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2845:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2846:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2847:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2848:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2849:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2850:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2851:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2852:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2853:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2854:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2855:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2856:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2857:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2858:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2859:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2860:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2861:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2862:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2863:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2864:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2865:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2866:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2867:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2868:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2869:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2870:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2871:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2872:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2873:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2874:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2875:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2876:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2877:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2878:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2879:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2880:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2881:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2882:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2883:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2884:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2885:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2886:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2887:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2888:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2889:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2890:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2891:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2892:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2893:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2894:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2895:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2896:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2897:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2898:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2899:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2900:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2901:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2902:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2903:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2904:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2905:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2906:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2907:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2908:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2909:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2910:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2911:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2912:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2913:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2914:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2915:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2916:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2917:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2918:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2919:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2920:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2921:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2922:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2923:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2924:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2925:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2926:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2927:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2928:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2929:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2930:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2931:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2932:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2933:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2934:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2935:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2936:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2937:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2938:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2939:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2940:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2941:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2942:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2943:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2944:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2945:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2946:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2947:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2948:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2949:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2950:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2951:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2952:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2953:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2954:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2955:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2956:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2957:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2958:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2959:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2960:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2961:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2962:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2963:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2964:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2965:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2966:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2967:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2968:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2969:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2970:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2971:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2972:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2973:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2974:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2975:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2976:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2977:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2978:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2979:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2980:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2981:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2982:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2983:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2984:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2985:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2986:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2987:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2988:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2989:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2990:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2991:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2992:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2993:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2994:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2995:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2996:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2997:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2998:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_DECLINED_2999:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_BT_PROCESSOR_NETWORK_UNAVAILABLE_3000:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  ERROR_CARD_BRAND_UNACCEPTED:
    "Pada masa ini kami tidak menerima jenama kad ini.",
  ERROR_CARD_UNACCEPTED:
    "Kami hanya menerima {accepted_card_types} buat masa ini.",
  ERROR_CKO_CARD_UNACCEPTED: "Kami hanya menerima Visa dan Mastercard.",
  ERROR_COLLECTED_ACCOUNT_EXIST:
    "Syarikat ini telah mempunyai akaun Kutipan Pembayaran.",
  ERROR_CONFIRM_AMOUNT_REQUIRED: "Sila sahkan amaun",
  ERROR_CONNECTION_TIMEOUT:
    "Tiada sambungan internet. Periksa sambungan internet anda dan cuba semula.",
  ERROR_COUNTRY_REQUIRED: "Sila pilih negara anda",
  ERROR_COUPON_AU_VISA:
    "Aduh! Kod promosi tidak sah. Sila hubungi kami melalui sembang langsung di sudut kanan bawah.",
  ERROR_COUPON_BOC_CARD_ONLY:
    "Kod promosi tidak sah untuk kad anda, sila gunakan kad lain.",
  ERROR_COUPON_CHARGED_ACCOUNT:
    "Alamak! Promosi ini tidak sah untuk pembayaran ini.",
  ERROR_COUPON_CHARGE_DATE_EXPIRED:
    "Alamak! Promosi ini tidak sah untuk tarikh caj ini.",
  ERROR_COUPON_CHARGE_DATE_SOONER:
    "Alamak! Promosi ini tidak sah untuk tarikh caj ini.",
  ERROR_COUPON_EXCEED_USAGE: "Akaun anda telah menuntut kod promo ini.",
  ERROR_COUPON_EXPIRED:
    "Kupon telah tamat tempoh atau telah dituntut sepenuhnya.",
  ERROR_COUPON_HK_VISA:
    "Aduh! Kod promosi tidak sah. Sila hubungi kami melalui sembang langsung di sudut kanan bawah.",
  ERROR_COUPON_INVALID_CARD:
    "Kod promosi tidak sah untuk kad anda, sila gunakan kad lain.",
  ERROR_COUPON_INVALID_PAYMENT_TYPE:
    "Kod promo hanya sah untuk pembayaran cukai.",
  ERROR_COUPON_INVOICE_PURPOSE_NOT_MATCH:
    "Promosi ini tidak sah untuk pembayaran invois.",
  ERROR_COUPON_MY_VISA:
    "Aduh! Kod promosi tidak sah. Sila hubungi kami melalui sembang langsung di sudut kanan bawah.",
  ERROR_COUPON_NOT_FOUND: "Kod promo tidak sah.",
  ERROR_COUPON_NO_AVAILABLE_USE:
    "Aduh! Kod promosi tidak sah. Sila hubungi kami melalui sembang langsung di sudut kanan bawah.",
  ERROR_COUPON_NO_AVAILABLE_USE_2:
    "Kod promosi telah digunakan melebihi had penggunaannya.",
  ERROR_COUPON_NO_AVAILABLE_USE_3:
    "Kod promosi telah digunakan melebihi had penggunaannya.",
  ERROR_COUPON_RENT_PURPOSE_NOT_MATCH:
    "Promosi ini tidak sah untuk pembayaran sewa.",
  ERROR_COUPON_SALARY_PURPOSE_NOT_MATCH:
    "Promosi ini tidak sah untuk pembayaran gaji.",
  ERROR_COUPON_SG_VISA:
    "Aduh! Kod promosi tidak sah. Sila hubungi kami melalui sembang langsung di sudut kanan bawah.",
  ERROR_COUPON_UEN_NOT_IN_LIST:
    "Alamak! Promosi ini tidak sah untuk pembayaran ini.",
  ERROR_EMAIL_REQUIRED: "Sila masukkan alamat emel yang sah",
  ERROR_EXISTING_EMAIL: "E-mel ini sudah didaftarkan, sila gunakan e-mel lain",
  ERROR_EXISTING_MOBILE_NUMBER:
    "Nombor telefon bimbit ini sudah didaftarkan, sila gunakan nombor lain",
  ERROR_FIELD_REQUIRED: "Ruang ini perlu diisi.",
  ERROR_FIRST_NAME_REQUIRED: "Sila masukkan nama pertama anda",
  ERROR_HK_CARD_UNACCEPTED:
    "Kami hanya menerima Visa dan Mastercard buat masa ini.",
  ERROR_INCORRECT_2FA_PASSWORD: "Sila masukkan kata laluan yang betul.",
  ERROR_INCORRECT_OLD_PASSWORD: "Sila masukkan kata laluan lama yang betul.",
  ERROR_INVALID_CARD: "Butiran kad tidak sah.",
  ERROR_INVALID_EMAIL_ADDRESS: "Sila masukkan alamat emel yang sah.",
  ERROR_INVOICE_SUPPORTING_DOCUMENT_REQUIRED:
    "Sila muat naik satu salinan invois anda.",
  ERROR_LAST_NAME_REQUIRED: "Sila masukkan nama akhir anda",
  ERROR_MAX_LENGTH: "Sila masukkan kurang daripada {max_length} aksara.",
  ERROR_MIN_LENGTH: "Hendaklah sekurang-kurangnya {min_length} aksara.",
  ERROR_MIN_PASSWORD_LENGTH:
    "Kata laluan perlu sekurang-kurangnya {min_length} aksara",
  ERROR_MOBILE_NUMBER_REQUIRED: "Sila masukkan nombor telefon mudah alih anda",
  ERROR_MULTIPLE_SUPPLIERS_AMEX:
    "Amex pada masa ini tidak tersedia untuk membayar berbilang pembekal. Sila pilih satu pembekal atau jenama kad lain.",
  ERROR_MY_CARD_UNACCEPTED:
    "Kami hanya menerima Visa dan Mastercard buat masa ini.",
  ERROR_NO_SELECTED_CARD: "Sila pilih atau tambah kad terlebih dahulu.",
  ERROR_NO_SELECTED_PAYEE: "Sila pilih satu penerima.",
  ERROR_OPERATION_PENDING_APPROVAL:
    "Pembayaran ini sedang menunggu kelulusan untuk operasi lain",
  ERROR_PASSWORD_CONFIRM_REQUIRED: "Sila pastikan kata laluan sepadan",
  ERROR_PASSWORD_LENGTH:
    "Masukkan kata laluan dengan sekurang-kurangnya 8 aksara yang terdiri daripada sekurang-kurangnya satu huruf, satu nombor dan satu simbol",
  ERROR_PASSWORD_REQUIRED: "Sila masukkan kata laluan yang lebih kukuh",
  ERROR_PAYMENT_PROCESSED: "Pembayaran telah diproses.",
  ERROR_PROMO_CODE_FIRST_PAYMENT_ONLY:
    "Aduh! Kod promosi hanya sah untuk pembayaran pertama anda.",
  ERROR_PROMO_CODE_HIGHER_DEFAULT_RATE:
    "Berita baik, tiada kod promosi diperlukan. Anda sudah menerima kadar terendah!",
  ERROR_PROMO_CODE_NOT_FOR_INVOICE_PAYMENT:
    "Kod promosi tidak sah untuk pembayaran invois.",
  ERROR_PROMO_CODE_NOT_FOR_RENTAL_PAYMENT:
    "Kod promosi tidak sah untuk pembayaran sewa.",
  ERROR_PROMO_CODE_NOT_FOR_SALARY_PAYMENT:
    "Kod promo tidak sah untuk pembayaran gaji.",
  ERROR_PROMO_CODE_ONE_TIME_PAYMENT_ONLY:
    "Aduh! Nampaknya ini adalah bayaran berulang. Kod promosi ini hanya sah untuk pembayaran sekali sahaja.",
  ERROR_PROMO_CODE_RECURRING_PAYMENT_ONLY:
    "Aduh! Nampaknya ini adalah bayaran sekali sahaja. Kod promo ini hanya sah untuk pembayaran berulang.",
  ERROR_REFUND_PENDING_APPROVAL:
    "Pembayaran ini mempunyai operasi pemulangan yang belum diluluskan",
  ERROR_SCHEDULE_TYPE_REQUIRED:
    "Sila pilih pembayaran satu-kali atau pembayaran berulang.",
  ERROR_SG_CARD_UNACCEPTED:
    "Pada masa ini kami hanya menerima Visa, Mastercard dan China UnionPay.",
  ERROR_STATEMENT_MIN_LENGTH:
    "Keterangan penyata hendaklah sekurang-kurangnya {min_length} aksara.",
  ERROR_STRIPE_CARD_UNACCEPTED:
    "Kami hanya menerima MasterCard, Visa dan American Express buat masa ini.",
  ERROR_TENANCY_AGREEMENT_REQUIRED:
    "Sila muat naik satu salinan perjanjian penyewaan anda",
  ERROR_TERM_AGREEMENT_REQUIRED:
    "Sila tandakan kotak untuk bersetuju dengan terma-terma dan syarat-syarat kami",
  ERROR_UNSUPPORTED_EXPEDITE: "Operasi segera tidak disokong",
  ERROR_UNSUPPORTED_REFUND: "Operasi pemulangan tidak disokong",
  ERROR_UPLOAD_FAILED:
    "Kami menghadapi masalah memuat naik fail anda. Sila cuba lagi.",
  ERROR_VALID_MOBILE_NUMBER: "Sila masukkan nombor telefon bimbit yang sah",
  EXCEEDS_MAXIMUM_AMOUNT: "Sila masukkan amaun yang sah sehingga 999,999.99",
  EXCEED_MAX_AMOUNT_ERROR:
    "Amaun yang dimasukkan melebihi had transaksi, sila masukkan amaun yang lebih kecil.",
  EXPIRED_EMAIL_TOKEN:
    "Token emel pengesahan anda telah tamat tempoh. Sila cuba lagi.",
  EXPIRED_PASSWORD_RESET_TOKEN:
    "Token anda telah tamat tempoh. Sila tetapkan semula kata laluan anda sekali lagi.",
  EXPORT_BUTTON: "Export",
  FEATURE_UNAVAILABLE_ERROR: "Ciri ini tidak tersedia buat masa ini.",
  FILE_DRAG_LABEL: "Tarik dan lepaskan fail anda disini untuk muat naik atau",
  FILE_INVALID_TYPE: "Jenis fail tidak sah",
  FILE_MAXIMUM_SIZE: "Saiz fail maksima",
  FILE_SELECT_LABEL: "Pilih fail untuk dimuat naik",
  FILE_SUPPORTED_TYPES: "Jenis fail yang disokong",
  FILTER_BUTTON: "Tapis",
  FILTER_CHARGE_DATE_HEADER: "Tukar Tarikh",
  FILTER_CLEAR_BUTTON: "Padam",
  FILTER_DONE_BUTTON: "Telah Dibuat",
  FILTER_FROM_LABEL: "Dari",
  FILTER_HEADER: "Tapis",
  FILTER_STATUS_CANCELLED: "Dibatalkan",
  FILTER_STATUS_COMPLETED: "Telah lengkap",
  FILTER_STATUS_DECLINED: "Ditolak",
  FILTER_STATUS_HEADER: "Status",
  FILTER_STATUS_ON_HOLD: "Ditahan",
  FILTER_STATUS_PROGRESS: "Dalam Proses",
  FILTER_STATUS_REFUNDED: "Telah Dibayar Balik",
  FILTER_TO_LABEL: "Kepada",
  FIND_ABN: "Tidak tahun apa ABN? Anda boleh menemuinya ",
  FIND_CRN:
    "Tidak tahu Nombor Pendaftaran Syarikat (BRN/CRN)? Anda boleh menemuinya",
  FIND_UEN: "Tidak tahun apa UEN? Anda boleh menemuinya",
  FIRST_EXPIRING_PAYMENT_REMINDER_SUBJECT:
    "Peringatan: Pembayaran berulang anda yang dijadualkan kepada %s akan tamat tidak lama lagi",
  FIRST_NAME_ERROR: "Sila masukkan nama pertama anda",
  FIRST_NAME_PLACEHOLDER: "Nama Pertama",
  FIRST_UPCOMING_PAYMENT_REMINDER_SUBJECT:
    "Peringatan: Anda mempunyai pembayaran berjadual akan datang kepada %s",
  FORGOT_PASSWORD_EMAIL_BUTTON: "Tetapkan Semula Kata Laluan",
  FORGOT_PASSWORD_EMAIL_EXPIRY: "Emel pengesahan ini sah untuk {{.ExpiredIn}}.",
  FORGOT_PASSWORD_EMAIL_HERO: "Terlupa Kata Laluan?",
  FORGOT_PASSWORD_EMAIL_INSTRUCTIONS:
    "Sila klik butang di bawah untuk menetap semula kata laluan anda.",
  FORGOT_PASSWORD_EMAIL_SUBJECT: "Tetapkan semula kata laluan anda",
  FORGOT_PASSWORD_INSTRUCTIONS:
    "Terlupa kata laluan anda? {RESET_PASSWORD_LINK}",
  FREE_PAYMENTS_MESSAGE:
    "Anda semasa ini menikmati yuran transaksi 0% kerana anda telah menuntut Tawaran Pembayaran Percuma anda.",
  GET_HELP_BUTTON: "Dapatkan Bantuan",
  GET_STARTED: "Bermula",
  GREETING_NAVBAR: "Helo, {name}!",
  GST_REGISTRATION_NUMBER: "Nombor Pendaftaran GST",
  GST_REGISTRATION_NUMBER_PLACEHOLDER: "Nombor Pendaftaran GST",
  HANDSHAKE_CUSTOMER_TAG_ACTIVE: "Pelanggan aktif",
  HANDSHAKE_CUSTOMER_TAG_ACTIVE_DEFINITION:
    "Pelanggan yang telah membuat sekurang-kurang satu bayaran dalam 90 yang lalu.",
  HANDSHAKE_CUSTOMER_TAG_NEW: "Pelanggan baru",
  HANDSHAKE_CUSTOMER_TAG_NEW_DEFINITION:
    "Pelanggan yang menerima permintaan bayaran pertama dalam 30 hari yang lalu.",
  HANDSHAKE_CUSTOMER_TAG_RISKY: "Pelanggan berisiko",
  HANDSHAKE_CUSTOMER_TAG_RISKY_DEFINITION:
    "Pelanggan yang hanya menyelesaikan 50% daripada semua permintaan bayaran dalam 90 hari yang lalu.",
  HANDSHAKE_CUSTOMER_TAG_VIP: "Pelanggan VIP",
  HANDSHAKE_CUSTOMER_TAG_VIP_DEFINITION:
    "Pelanggan yang telah menyelesaikan semua bayaran dengan penuh dalam 90 hari yang lalu.",
  HERE_LINK: "di sini",
  HIDE_DETAILS_BUTTON: "Sembunyi Butiran",
  HISTORY_HERO: "KUTIPAN PEMBAYARAN",
  HISTORY_INVOICE_HEADER: "Invois",
  HISTORY_MENU_ITEM: "Sejarah",
  HISTORY_NET_HEADER: "Bersih",
  HKID_LABEL: "HKID",
  HKID_PLACEHOLDER: "HKID Pekerja",
  ID_LABEL: "ID",
  ID_PLACEHOLDER: "ID Pekerja",
  IMAGE_MORE_FREE_PAYMENTS: "Anda mempunyai lebih pembayaran percuma!",
  IMAGE_NEW_MONTH: "Bulan Baru",
  IMAGE_SWEET: "Bagus! Anda telah memaksimakan pembayaran percuma anda.",
  IMAGE_TRANSACTION_FEE: "Yuran Transaksi",
  IMAGE_VICTORY_LAP: "Raikan kejayaan, anda seorang pakar dalam menginvois!",
  IMAGE_WORKS_PAYS: "Berfungsi seperti kad kredit. Bayar seperti tunai.",
  IMAGE_ZERO_PERCENT: "Yuran Transaksi 0%",
  INCENTIVES_EARLY_DEADLINE: "Tarikh akhir pembayaran awal",
  INCENTIVES_EARLY_DESCRIPTION:
    "Kami akan menghantar tawaran ini kepada pelanggan anda melalui e-mel dua hari perniagaan selepas tarikh invois pada invois dengan sekurang-kurangnya 15 tarikh akhir bersih.",
  INCENTIVES_EARLY_FEE: "Diskaun bayaran awal",
  INCENTIVES_HALF_DESCRIPTION:
    "Jika pelanggan anda tidak membuat pembayaran penuh pada tarikh tamat tempoh, kami akan menghantar tawaran melalui e-mel sehari selepas tarikh tamat tempoh untuk membayar separuh daripada jumlah invois serta-merta, dan membayar separuh lagi pada hari yang sama bulan berikutnya.",
  INCENTIVES_IMMEDIATE_DEADLINE: "Tarikh akhir pembayaran segera",
  INCENTIVES_IMMEDIATE_DESCRIPTION:
    "Kami akan menghantar tawaran ini kepada pelanggan anda melalui e-mel tiga hari perniagaan sebelum tarikh tamat invois dengan sekurang-kurangnya 30 tarikh tamat bersih.",
  INCENTIVES_IMMEDIATE_FEE: "Diskaun bayaran segera",
  INCENTIVES_LATE_DEADLINE: "Tarikh akhir pembayaran lewat",
  INCENTIVES_LATE_DESCRIPTION:
    "Jika pelanggan anda tidak membuat pembayaran penuh sebelum tarikh tamat tempoh, kami akan menghantar pemberitahuan melalui e-mel sehari selepas tarikh tamat bahawa kami telah menggunakan yuran ini untuk pembayaran lewat.",
  INCENTIVES_LATE_FEE: "Yuran pembayaran lewat",
  INCENTIVES_THREE_DESCRIPTION:
    "Jika pelanggan anda tidak membuat pembayaran penuh pada tarikh tamat tempoh, kami akan menghantar tawaran melalui e-mel sehari selepas tarikh tamat tempoh untuk membayar satu pertiga daripada jumlah invois serta-merta, dan membayar baki pertiga pada hari yang sama dua bulan berikutnya.",
  INCENTIVES_THREE_INSTALLMENTS_DEADLINE:
    "Bayar dalam tiga tarikh akhir ansuran",
  INCENTIVES_TWO_INSTALLMENTS_DEADLINE:
    "Separuh sekarang, separuh kemudian tarikh akhir",
  INSTALLMENT_NUMBER_LABEL: "Ansuran {number} daripada {total}",
  INSTANT_REQUEST_BUTTON: "Permintaan Segera",
  INSTANT_REQUEST_MENU_ITEM: "Permintaan Segera",
  INSTRUCTION_DESCRIPTION_OF_GOODS: "Keterangan Barangan",
  INSTRUCTION_INVOICE_AMOUNT: "Amaun Invois",
  INSTRUCTION_SUPPLIER_COMPANY_NAME: "Nama Syarikat Pembekal",
  INSURANCE_LEARN_MORE:
    "Anda boleh mempelajari lebih lanjut mengenai pembayaran insurans",
  INTEND_MULTIPLE_PAYMENTS_LABEL:
    "Saya berniat untuk membuat beberapa pembayaran kepada pembekal ini",
  INTEND_MULTIPLE_PAYMENTS_TOOLTIP:
    "Dengan mengesahkan niat anda untuk membuat pembayaran berulang kepada pembekal ini, anda mungkin layak untuk yuran yang lebih rendah sekali sekala.",
  INTERNATIONAL_LABEL: "Antarabangsa",
  INT_PAYMENT_MISSING_RECIPIENT_DATA:
    "Kami mendapati anda kehilangan data yang diperlukan berikut untuk penerima pembayaran antarabangsa anda ini:",
  INT_PAYMENT_UNSUPPORTED_ERROR:
    "Sila log masuk ke platform baharu kami di web.ipaymy.com untuk membuat pembayaran antarabangsa.",
  INVALID_ABN:
    "Sila masukkan Nombor Perniagaan Australia Pembekal (ABN) yang sah.",
  INVALID_ALPHANUMERIC: "Sila masukkan huruf dan nombor sahaja di sini.",
  INVALID_AMOUNT: "Itu tidak kelihatan seperti amaun yang sah.",
  INVALID_AMOUNT_CONVERSION: "Itu tidak kelihatan seperti amaun yang sah.",
  INVALID_ANZBSGSX_3_NUMBER_1: "Nombor akaun ANZ memerlukan 10 atau 11 digit.",
  INVALID_BANKSWIFT: "Kod swift bank tidak sah",
  INVALID_BANK_CODE_FORMAT: "Masukkan kod bank anda dalam format yang sah.",
  INVALID_BANK_DOCUMENTS_REQUIRED: "Dokumen bank diperlukan",
  INVALID_BANK_NOT_SUPPORTED:
    "ipaymy tidak boleh menyokong pembayaran kepada bank ini",
  INVALID_BANK_NUMBER: "Nombor akaun bank tidak sah",
  INVALID_BANK_NUMBER_NOT_SUPPORTED:
    "ipaymy tidak boleh menyokong pembayaran ke nombor akaun bank ini.",
  INVALID_BEASHKHH_123_NUMBER_1:
    "Sila masukkan nombor akaun Bank of East Asia yang sah.",
  INVALID_BKCHHKHH_120_NUMBER_1:
    "Sila masukkan nombor akaun Bank of China (Hong Kong) yang sah.",
  INVALID_BKCHSGSG_6_NUMBER_1:
    "Nombor akaun Bank of China memerlukan 15 digit.",
  INVALID_BNPASGSG_142_NUMBER_1:
    "Sila masukkan nombor akaun BNP Paribas yang sah.",
  INVALID_BOC_NUMBER: "Sila masukkan nombor akaun Bank of China yang sah.",
  INVALID_BOFASG2X_21_NUMBER_1:
    "Nombor akaun Bank of America memerlukan 8 digit.",
  INVALID_BRN: "Sila masukkan Nombor Pendaftaran Syarikat (BRN/CRN) yang sah.",
  INVALID_BRN_AFFILIATED_ENTITIES:
    "Pembayaran invois tidak boleh dibuat kepada perniagaan atau sebarang entiti bersekutu anda.",
  INVALID_CCBQHKAX_125_NUMBER_1:
    "Sila masukkan nombor akaun China Construction Bank (Asia) Corporation Limited yang sah.",
  INVALID_CIBBSGSG_12_NUMBER_1:
    "Nombor akaun Bank CIMB memerlukan 10 atau 16 atau 17 digit.",
  INVALID_CITIHKAX_128_NUMBER_1:
    "Sila masukkan nombor akaun Citibank (Hong Kong) Limited yang sah.",
  INVALID_CITIHKAX_128_NUMBER_2:
    "Sila masukkan nombor akaun Citibank (Hong Kong) Limited yang sah.",
  INVALID_CITIHKAX_128_NUMBER_3:
    "Sila masukkan nombor akaun Citibank (Hong Kong) Limited yang sah.",
  INVALID_CITIHKHX_129_NUMBER_1:
    "Sila masukkan nombor akaun CITIBANK, N.A yang sah.",
  INVALID_CITISGSG_4_NUMBER_1: "Nombor akaun Citibank memerlukan 10 digit.",
  INVALID_CITISGSG_NUMBER:
    "Itu tidak kelihatan seperti nombor akaun bank citibank yang sah. Ia sepatutnya 10 digit",
  INVALID_CITISGSL_117_NUMBER_1: "Nombor akaun Citibank memerlukan 10 digit.",
  INVALID_CIYUHKHH_126_NUMBER_1:
    "Sila masukkan nombor akaun Chiyu Banking Corporation Limited yang sah.",
  INVALID_COMMHKHK_121_NUMBER_1:
    "Sila masukkan nombor akaun Bank of Communications Co., Ltd. yang sah.",
  INVALID_COMMHKHK_122_NUMBER_1:
    "Sila masukkan nombor akaun Bank of Communications Co., Ltd. yang sah.",
  INVALID_CREDENTIALS: "Kombinasi emel/kata laluan tidak sah, sila cuba lagi.",
  INVALID_DATE: "Tarikh Tidak Sah",
  INVALID_DBSSHKHH_132_NUMBER_1:
    "Sila masukkan nombor akaun DBS BANK LTD. yang sah.",
  INVALID_DBSSSGSG_19_NUMBER_1: "Nombor akaun POSB Bank memerlukan 9 digit.",
  INVALID_DBSSSGSG_19_NUMBER_2:
    "Sila masukkan nombor akaun POSB Bank yang sah.",
  INVALID_DBSSSGSG_1_NUMBER_1:
    "Nombor akaun Bank DBS memerlukan 9 atau 10 digit.",
  INVALID_DBSSSGSG_1_NUMBER_2: "Sila masukkan nombor akaun DBS Bank yang sah.",
  INVALID_DBSSSGSG_NUMBER:
    "Itu tidak kelihatan seperti nombor akaun bank DBS yang sah. Ia sepatutnya 10 digit",
  INVALID_DHBKHKHH_131_NUMBER_1:
    "Sila masukkan nombor akaun DBS Bank (Hong Kong) Limited yang sah.",
  INVALID_DSBAHKHH_130_NUMBER_1:
    "Sila masukkan nombor akaun Dah Sing Bank Limited yang sah.",
  INVALID_DUE_DATE:
    "Masukkan tarikh akhir invois yang akan datang dalam format yang sah.",
  INVALID_DUPLICATED_COLLECTION_INVOICED_DOCUMENT:
    "Hanya satu invois boleh dimuat naik untuk setiap nombor invois",
  INVALID_EMAIL_ADDRESS: "Sila masukkan alamat emel yang sah.",
  INVALID_EMAIL_TOKEN: "Sila sahkan emel anda terlebih dahulu.",
  INVALID_EMAIL_USED: "Alamat emel tidak sah",
  INVALID_EMPLOYEE_ID: "Sila masukkan ID pekerja yang sah.",
  INVALID_EXCEED_FILE_SIZE: "Saiz fail melebihi 16MB.",
  INVALID_EXCEED_FILE_SIZE_5MB: "Saiz fail melebihi 5MB.",
  INVALID_FILE_NAME_LENGTH:
    "Nama fail melebihi had 60 aksara Sila namakan semula dan muat naik fail baru.",
  INVALID_FILE_UPLOAD_AMOUNT:
    "Anda hendaklah memuat naik sekurang-kurangnya {file_amount} fail.",
  INVALID_GROSS_AMOUNT: "Amaun tidak sah",
  INVALID_HASEHKHH_133_NUMBER_1:
    "Sila masukkan nombor akaun Hang Seng Bank, Limited yang sah.",
  INVALID_HKIC: "Sila masukkan HKID yang sah.",
  INVALID_HSBCHKHHHKH_134_NUMBER_1:
    "Sila masukkan nombor akaun The Hongkong and Shanghai Banking Corporation Limited yang sah.",
  INVALID_HSBCSGS2_5_NUMBER_1:
    "Nombor akaun Bank HSBC Singapura memerlukan 12 digit.",
  INVALID_HSBCSGS2_5_NUMBER_2:
    "Sila masukkan nombor akaun Bank HSBC Singapura yang sah.",
  INVALID_HSBCSGSG_22_NUMBER_1: "Nombor akaun HSBC Bank memerlukan 12 digit.",
  INVALID_HSBCSGSG_22_NUMBER_2:
    "Sila masukkan nombor akaun HSBC Bank yang sah.",
  INVALID_HSBCSGSG_NUMBER_1: "Nombor akaun HSBC Bank memerlukan 12 digit.",
  INVALID_HSBCSGSG_NUMBER_2: "Sila masukkan nombor akaun HSBC Bank yang sah.",
  INVALID_IBAN: "IBAN tidak sah",
  INVALID_INCORPORATION_DATE_ERROR: "Sila masukkan tarikh yang sah.",
  INVALID_IOBASGSGS_NUMBER_1:
    "Nombor akaun Indian Overseas Bank memerlukan 15 digit.",
  INVALID_IOBASGSG_15_NUMBER_1:
    "Nombor akaun Indian Overseas Bank memerlukan 15 digit.",
  INVALID_KWHKHKHH_124_NUMBER_1:
    "Sila masukkan nombor akaun China Citi Bank International Limited yang sah.",
  INVALID_LCHBHKHH_127_NUMBER_1:
    "Sila masukkan nombor akaun Chong Hing Bank Limited yang sah.",
  INVALID_LENGTH_HK_NOTE: "Nota hendaklah lebih daripada 35 aksara",
  INVALID_LENGTH_MALAYSIA_NOTE: "Nota hendaklah lebih daripada 20 aksara",
  INVALID_LENGTH_NOTE: "Nota hendaklah lebih daripada %s aksara",
  INVALID_LENGTH_OTHER_COUNTRY_NOTE: "Nota hendaklah lebih daripada 35 aksara",
  INVALID_LENGTH_SINGAPORE_NOTE: "Nota hendaklah lebih daripada 35 aksara",
  INVALID_MAXIMUM_VALUE: "Maksima adalah {value}.",
  INVALID_MBBESGSG_8_NUMBER_1: "Nombor akaun Maybank memerlukan 11 digit.",
  INVALID_MBBESGSG_NUMBER_1: "Nombor akaun Maybank memerlukan 11 digit.",
  INVALID_MHCBSGSG_17_NUMBER_1: "Nombor akaun Mizhou Bank memerlukan 11 digit.",
  INVALID_MHCBSGSG_NUMBER_1: "Nombor akaun Mizhou Bank memerlukan 11 digit.",
  INVALID_MINIMUM_SALARY_AMOUNT_MY: "Amaun gaji minimum ialah RM30.",
  INVALID_MOBILE_USED: "Akaun dengan nombor telefon ini telah wujud.",
  INVALID_MYKAD_NUMBER: "Sila masukkan MyKad yang sah.",
  INVALID_MY_INVOICE_PAYEE_AMOUNT: "Amaun invois minimum RM30.",
  INVALID_NOTE_TO:
    "Aksara tidak sah digunakan. Nota kepada penerima anda hanya boleh mengandungi aksara abjad dan angka.",
  INVALID_NRICFIN: "Sila masukkan NRIC yang sah.",
  INVALID_NYCBHKHH_136_NUMBER_1:
    "Sila masukkan nombor akaun Nanyang Commercial Bank Limited yang sah.",
  INVALID_NYCBHKHH_136_NUMBER_2:
    "Sila masukkan nombor akaun Nanyang Commercial Bank Limited yang sah.",
  INVALID_OCBCSGSG_2_NUMBER_1:
    "Nombor akaun OCBC Bank memerlukan 10 atau 12 digit.",
  INVALID_OCBCSGSG_2_NUMBER_2: "Sila masukkan nombor akaun OCBC Bank yang sah.",
  INVALID_OCBCSGSG_NUMBER:
    'Itu tidak kelihatan seperti nombor akaun bank OCBC yang sah. Ia sepatutnya 7 digit. Jika anda nampak angka 10 digit seperti "5501089550", sila buang 3 digit pertama (550) iaitu Kod Cawangan',
  INVALID_OCBCSGSG_NUMBER_1:
    "Nombor akaun OCBC Bank memerlukan 10 atau 12 digit.",
  INVALID_OCBCSGSG_NUMBER_2: "Sila masukkan nombor akaun OCBC Bank yang sah.",
  INVALID_PASSWORD_STRENGTH:
    "Sila pilih kata laluan yang lebih kukuh dengan sekurang-kurangnya 8 aksara yang mengandungi huruf, nombor dan simbol.",
  INVALID_PAYMENT_DUE_TO_SCREENING_REJECT:
    "Pembayaran tidak sah kerana penolakan saringan",
  INVALID_PAYOUT_DATE_ERROR:
    "Alamak! nampaknya kita menghadapi satu masalah. Sila cuba menjadual semula pembayaran anda.",
  INVALID_PHONE_NUMBER: "Sila berikan nombor telefon mudah alih yang sah",
  INVALID_POSTAL_CODE_MY: "Sila masukkan poskod yang sah",
  INVALID_PROMO_CODE:
    "Aduh! Kod promosi tidak sah. Sila hubungi kami melalui sembang langsung di sudut kanan bawah.",
  INVALID_PROMO_CODE_CHARGE_DATE:
    "Aduh! Kod promosi tidak sah untuk tarikh caj ini.",
  INVALID_RATE_NUMBER: "Sila masukkan kadar yang sah.",
  INVALID_RECIPIENT_NAME:
    "Aksara tidak sah digunakan. Nama penerma hanya boleh mengandungi aksara abjad dan angka.",
  INVALID_REQUEST_BODY: "Bahagian tidak sah.",
  INVALID_REQUEST_BODY_STRUCTURE_ERROR: "Beberapa maklumat adalah tidak tepat",
  INVALID_RHBBSGSG_13_NUMBER_1: "Nombor akaun RHB Bank memerlukan 11 digit.",
  INVALID_RHBBSGSG_NUMBER_1: "Nombor akaun RHB Bank memerlukan 11 digit.",
  INVALID_SALARY_AMOUNT: "Sila berikan amaun gaji yang sah untuk pekerja ini",
  INVALID_SALARY_AMOUNT_HK:
    "Sila berikan amaun gaji yang sah untuk pekerja ini",
  INVALID_SBINSGSG_11_NUMBER_1:
    "Nombor akaun State Bank of India memerlukan 14 digit.",
  INVALID_SBINSGSG_11_NUMBER_2:
    "Sila masukkan nombor akaun State Bank of India yang sah.",
  INVALID_SBINSGSG_NUMBER_1:
    "Nombor akaun State Bank of India memerlukan 14 digit.",
  INVALID_SBINSGSG_NUMBER_2:
    "Sila masukkan nombor akaun State Bank of India yang sah.",
  INVALID_SCBKHKHH_138_NUMBER_1:
    "Sila masukkan nombor akaun Shanghai Commercial Bank Limited yang sah.",
  INVALID_SCBKHKHH_138_NUMBER_2:
    "Sila masukkan nombor akaun Shanghai Commercial Bank Limited yang sah.",
  INVALID_SCBLHKHH_139_NUMBER_1:
    "Sila masukkan nombor akaun Standard Chartered Bank (Hong Kong) Limited yang sah.",
  INVALID_SCBLHKHH_140_NUMBER_1:
    "Sila masukkan nombor akaun Wing Lung Bank Limited Limited  yang sah.",
  INVALID_SCBLSGSG_10_NUMBER_1:
    "Nombor akaun Standard Chartered Bank memerlukan 10 digit.",
  INVALID_SCBLSGSG_NUMBER_1:
    "Nombor akaun Standard Chartered Bank memerlukan 10 digit.",
  INVALID_SG_INVOICE_PAYEE_AMOUNT: "Amaun invois minimum S$15.",
  INVALID_SSM: "Sila masukkan nombor SSM yang sah.",
  INVALID_SSM_NOT_SUPPORTED:
    "Sila masukkan nombor pendaftaran syarikat yang sah.",
  INVALID_STATEMENT_DESCRIPTOR:
    "Aksara tidak sah. Keterangan penyata hanya menerima aksara abjad angka.",
  INVALID_STATEMENT_DESCRIPTOR_ALPHABET:
    "Keterangan pembayaran mesti mengandungi sekurang-kurangnya 1 aksara huruf.",
  INVALID_STATEMENT_DESCRIPTOR_LENGTH:
    "Keterangan penyata adalah terhad kepada 22 aksara sahaja.",
  INVALID_SUPPORTING_DOCUMENTS_RENT_COUNT:
    "Anda telah mencapai bilangan maksima bagi muat naik dokumen sokongan",
  INVALID_SUPPORTING_DOCUMENTS_RENT_REQUIRED:
    "Sila muat naik sekurang-kurangnya satu dokumen sokongan.",
  INVALID_SUPPORTING_DOCUMENTS_SALARY_COUNT:
    "Anda telah mencapai bilangan maksima bagi muat naik dokumen sokongan",
  INVALID_UBHKHKHH_135_NUMBER_1:
    "Sila masukkan nombor akaun Industrial and Commercial Bank of China (Asia)yang sah.",
  INVALID_UEN: "Sila masukkan UEN yang sah.",
  INVALID_UEN_AFFILIATED_ENTITIES:
    "Pembayaran invois tidak boleh dibuat kepada perniagaan atau sebarang entiti bersekutu anda.",
  INVALID_UEN_NOT_SUPPORTED:
    "ipaymy tidak menyokong pembayaran kepada pembekal ini buat masa ini.",
  INVALID_UOVBSGSG_16_NUMBER_1:
    "Nombor akaun Far Eastern Bank memerlukan 10 digit.",
  INVALID_UOVBSGSG_7_NUMBER_1:
    "Nombor akaun UOB Bank memerlukan 10 atau 12 digit.",
  INVALID_UOVBSGSG_NUMBER_1:
    "Nombor akaun UOB Bank memerlukan 10 atau 12 digit.",
  INVALID_WIHBHKHH_137_NUMBER_1:
    "Sila masukkan nombor akaun OCBC Wing Hang Bank Limited  yang sah.",
  INVOICES_BUTTON: "Invois-invois",
  INVOICE_AMOUNT_DUE_LABEL: "Amaun Perlu Dibayar",
  INVOICE_AMOUNT_HEADER: "Amaun",
  INVOICE_BILL_TO_LABEL: "Dibil Ke",
  INVOICE_CC_LABEL: "CC",
  INVOICE_DATE_CUSTOM_OPTION: "Kastam",
  INVOICE_DATE_LABEL: "Tarikh Invois",
  INVOICE_DATE_NET_15_OPTION: "15 Hari Bersih",
  INVOICE_DATE_NET_30_OPTION: "30 Hari Bersih",
  INVOICE_DATE_NET_45_OPTION: "45 Hari Bersih",
  INVOICE_DATE_NET_60_OPTION: "60 Hari Bersih",
  INVOICE_DATE_PLACEHOLDER: "Dijelaskan Semasa Penerimaan",
  INVOICE_DISCOUNT_HEADER: "Diskaun",
  INVOICE_DUE_DATE_LABEL: "Tarikh akhir invois",
  INVOICE_DUE_LABEL: "Tarikh cukup tempoh",
  INVOICE_EXAMPLE_COMPANY_ADDRESS: "1 Robinson Rd Singapore, Singapore 068908",
  INVOICE_EXAMPLE_COMPANY_NAME: "Syarikat XYZ",
  INVOICE_EXAMPLE_DATE: "Tarikh Invois: 12 Feb 2020",
  INVOICE_EXAMPLE_DUE_DATE: "27 Feb 2020",
  INVOICE_EXAMPLE_GST_REGISTRATION_NUMBER: "No. Pend. GST",
  INVOICE_EXAMPLE_GST_TAX: "GST (7%)",
  INVOICE_INSTRUCTIONS:
    "Invois \u003cspan\u003e mesti mempunyai:\u003c/span\u003e",
  INVOICE_ITEM_ADD_BUTTON: "Tambah Baris Item",
  INVOICE_ITEM_DESCRIPTION_HEADER: "Keterangan Item",
  INVOICE_ITEM_NEW_OPTION: "Tambah Item Baru",
  INVOICE_ITEM_PLACEHOLDER: "Klik untuk memilih atau menambah item",
  INVOICE_LABEL: "INVOIS",
  INVOICE_LOGO_PREVIEW: "PraPandang{br}Logo",
  INVOICE_MESSAGE_LABEL: "Mesej",
  INVOICE_MULTIPLE_CUSTOMER_TOOLTIP:
    "Minta beberapa pembayaran dari pelanggan yang sama.",
  INVOICE_MULTIPLE_SUPPLIER_TOOLTIP:
    "Bayar beberapa invois ke pembekal yang sama",
  INVOICE_NEW_ITEM_DISCOUNT_LABEL: "Diskaun",
  INVOICE_NEW_ITEM_DISCOUNT_PLACEHOLDER:
    "Tekan {render1} untuk menukarnya ke {render2}",
  INVOICE_NEW_ITEM_HERO: "ITEM BARU",
  INVOICE_NEW_ITEM_NAME_LABEL: "Nama",
  INVOICE_NEW_ITEM_NAME_PLACEHOLDER: "Nama",
  INVOICE_NEW_ITEM_PCT_DISCOUNT_PLACEHOLDER: "cth. 1.5",
  INVOICE_NEW_ITEM_QUANTITY_PLACEHOLDER: "cth. 10",
  INVOICE_NEW_ITEM_SELECT_TAX_PLACEHOLDER: "Pilih cukai",
  INVOICE_NEW_ITEM_TAX_NAME_LABEL: "Nama Cukai",
  INVOICE_NEW_ITEM_TAX_NAME_PLACEHOLDER: "Nama Cukai",
  INVOICE_NEW_ITEM_TAX_RATE_LABEL: "Kadar",
  INVOICE_NEW_ITEM_TAX_RATE_PLACEHOLDER: "cth. 1.5",
  INVOICE_NEW_TAX_HERO: "CUKAI BARU",
  INVOICE_NOTES_LABEL: "Nota-nota (Pilihan)",
  INVOICE_NOTE_OPTIONAL_COMPANY_TCS:
    "Ruang pilihan untuk memasukkan terma-terma dan syarat-syarat syarikat anda.",
  INVOICE_NUMBER_AUTO_NEXT_LABEL: "Nombor Seterusnya",
  INVOICE_NUMBER_AUTO_OPTION: "Guna nombor invois yang dijana secara automatik",
  INVOICE_NUMBER_AUTO_PREFIX_LABEL: "Awalan",
  INVOICE_NUMBER_HEADER: "NOMBOR INVOIS",
  INVOICE_NUMBER_INSTRUCTIONS:
    "Bagaimana anda ingin menjana nombor invois anda?",
  INVOICE_NUMBER_LABEL: "Nombor Invois",
  INVOICE_NUMBER_MANUAL_OPTION:
    "Tambah nombor invois secara manual setiap kali.",
  INVOICE_NUMBER_PLACEHOLDER: "Nombor Invois",
  INVOICE_ORDER_LABEL: "Nombor Invois/Tempahan",
  INVOICE_ORDER_PLACEHOLDER: "Nombor Invois/Tempahan",
  INVOICE_PREVIEW: "PRAPANDANG INVOIS",
  INVOICE_PURPOSE_LABEL: "Masukkan Amaun Invois dan Muat Naik Invois",
  INVOICE_QUANTITY_HEADER: "Kuantiti",
  INVOICE_REPLY_TO_LABEL: "Balas Kepada",
  INVOICE_REQUIRED_ERROR: "Invois diperlukan.",
  INVOICE_SUBTOTAL_LABEL: "Jumlah kecil",
  INVOICE_TAX_HEADER: "Cukai",
  INVOICE_TAX_NEW_OPTION: "Tambah Cukai Baru",
  INVOICE_TAX_PLACEHOLDER: "Pilih cukai",
  INVOICE_TCS_LABEL: "Terma-terma dan Syarat-syarat (Pilihan)",
  INVOICE_TOTAL_LABEL: "Jumlah Amaun Perlu Dibayar",
  INVOICE_TO_LABEL: "Kepada",
  INVOICE_UNIT_PRICE_HEADER: "Harga Unit",
  IPAYMY_LEARN_MORE_LINK: "Pelajari lebih lanjut mengenai ipaymy",
  IPAYMY_RESTRICTED_BUSINESS_CHECKBOX:
    'Dengan mendaftar dengan kami, anda mengesahkan bahawa anda tidak akan menggunakan ipaymy atau Perkhidmatan kami untuk menerima pembayaran berkaitan dengan sebarang \u003ca\u003e"Perniagaan Yang Dilarang"\u003c/a\u003e atau untuk sebarang aktiviti haram.',
  IPAYMY_TCS_CHECKBOX:
    "Saya bersetuju dengan \u003ca\u003eTerma-terma dan Syarat-syarat\u003c/a\u003e Kutipan Pembayaran ipaymy.",
  IPAYMY_TERM_ERROR:
    "Untuk menggunakan perkhidmatan ini, anda mesti bersetuju dengan terma ini.",
  IRAS_TAX_TOOLTIP:
    "Untuk kemudahan anda, kami telah menabah IRAS sebagai pembekal bagi pembayaran cukai anda.",
  IRAS_TOOLTIP:
    "Klik di sini untuk membayar cukai ke \u003cb\u003eIRAS.\u003c/b\u003e dengan mudah",
  LABEL_ADD_CARD_TO_WALLET: "TAMBAH KAD KREDIT",
  LABEL_ADD_COMPANY: "Tambah Syarikat Baru",
  LABEL_AGREE_TC:
    "Saya bersetuju dengan Terma-terma dan Syarat-syarat, dan Dasar Privasi ipaymy.",
  LABEL_AGREE_TC_1:
    "Saya bersetuju dengan {term_and_condition} dan {privacy_policy} ipaymy.",
  LABEL_AGREE_TC_2: "Terma-terma dan Syarat-syarat",
  LABEL_AGREE_TC_3: "Dasar Privasi",
  LABEL_BENEFICIARY_STATEMENT: "Nota kepada Tuan Rumah",
  LABEL_BTN_ADD_NEW_COMPANY: "Tambah Syarikat Baru",
  LABEL_BTN_SIGN_OUT: "Daftar Keluar",
  LABEL_CARD_CHARGED: "Kad Telah Dikenakan Caj.",
  LABEL_CARD_CVC_INSTRUCTION:
    "Kod 3-digit biasanya didapati di belakang kad anda.",
  LABEL_CARD_HOLDER: "Nama pemegang kad",
  LABEL_CARD_TO_BE_CHARGED: "Kad yang akan dikenakan Caj",
  LABEL_CHANGE_PASSWORD: "Tukar Kata Laluan",
  LABEL_CHECKOUT_CARD_STATEMENT: "Keterangan Penyata Kad Anda",
  LABEL_CHECKOUT_COUPON_CODE: "Kod promosi",
  LABEL_CHECKOUT_FEE: "({percentage}%) Yuran",
  LABEL_CHECKOUT_PAY_WITH_ANOTHER_CARD: "Bayar Dengan Kad Lain",
  LABEL_COMPANY_REGISTRATION_NUMBER: "Nombor pendaftaran syarikat",
  LABEL_CREATE_INVOICE: "Buat invois anda",
  LABEL_DASHBOARD_PAYMENT_EMPTY: "Masih bukan pembayaran.",
  LABEL_EDIT_COMPANY: "Edit Syarikat",
  LABEL_EDIT_PROPERTY: "Edit Hartanah",
  LABEL_EMPLOYEE_SUMMARY: "Ringkasan Pekerja",
  LABEL_END_OF_DAY: "hujung hari bekerja",
  LABEL_GET_STARTED_MAKING_PAYMENT: "MULAKAN DENGAN MEMBUAT PEMBAYARAN",
  LABEL_GET_STARTED_MAKING_PAYMENT_1: "MULAKAN",
  LABEL_GET_STARTED_MAKING_PAYMENT_2: "DENGAN MEMBUAT PEMBAYARAN",
  LABEL_INVOICE_SCHEDULE_BENIFICIARY:
    "Kad anda akan dikenakan caj pada {charged_date}. Pembekal anda akan menerima dana ini sebelum {end_of_day} pada {payout_date}.",
  LABEL_INVOICE_SCHEDULE_BENIFICIARY_TODAY:
    "Kad anda akan dikenakan caj hari ini. Pembekal anda akan menerima dana ini sebelum {end_of_day} pada {payout_date}.",
  LABEL_IPM_BUSINESS_ACCOUNT: "Akaun Perniagaan ipaymy",
  LABEL_IPM_PERSONAL_ACCOUNT: "Akaun Peribadi ipaymy",
  LABEL_MAKE_PAYMENT_RECURRYING: "JADIKAN PEMBAYARAN ANDA BERULANG",
  LABEL_MENU_ADMIN: "Pentadbir",
  LABEL_MENU_COMPANIES: "Syarikat-syarikat",
  LABEL_MENU_DASHBOARD: "Papan Pemuka",
  LABEL_MENU_EMPLOYEES: "Pekerja-pekerja",
  LABEL_MENU_MY_WALLET: "Dompet Saya",
  LABEL_MENU_PAYMENTS: "Pembayaran",
  LABEL_MENU_PROPERTIES: "Hartanah",
  LABEL_MENU_SCHEDULED: "Berjadual",
  LABEL_MENU_SUPPLIERS: "Pembekal-pembekal",
  LABEL_MENU_SUPPORT: "Sokongan",
  LABEL_MONEY_TRANSFER: "Pemindahan Wang",
  LABEL_MY_ACCOUNT: "Akaun Saya",
  LABEL_MY_COMPANIES: "Syarikat-syarikat Saya",
  LABEL_NOTE_TO_LANDLORD: "Nota kepada Tuan Rumah",
  LABEL_OLD_CARD_TOKEN_ARCHIVED_NOTIFICATION:
    "Kami baru-baru ini mengemas kini platform kami, yang mengharuskan anda menambahkan semula kad yang ada ke dompet anda. Perubahan ini tidak akan mempengaruhi pembayaran yang telah dijadualkan.",
  LABEL_PAGINATION: "Halaman{{.idx}} dari {{.length}}",
  LABEL_PASSWORD: "Sahkan Kata Laluan",
  LABEL_PAYMENT_DETAIL_DISCOUNT: "Diskaun",
  LABEL_PAYMENT_DETAIL_FEE: "Yuran",
  LABEL_PAYMENT_DETAIL_FEE_TOTAL: "Jumlah Yuran",
  LABEL_PAYMENT_SCHEDULE_SUCCESS_1:
    "Yeah! Pembayaran telah berjaya dijadualkan!",
  LABEL_PAYMENT_SCHEDULE_SUCCESS_2:
    "Kad anda akan dikenakan caj pada{charged_date} dan tuan rumah anda akan menerima pembayaran pada {payout_date}.",
  LABEL_PAYMENT_SCHEDULE_SUCCESS_2_RENT:
    "Kad anda akan dikenakan caj pada{charged_date} dan tuan rumah anda akan menerima pembayaran pada {payout_date}.",
  LABEL_PAYMENT_SCHEDULE_SUCCESS_3:
    "Kad anda akan dikenakan caj pada{charged_date} dan {recipient} anda akan menerima pembayaran pada {payout_date}.",
  LABEL_PAY_YOUR_EMPLOYEE: "Bayar pekerja anda",
  LABEL_PAY_YOUR_LANDLORD: "Bayar tuan rumah anda",
  LABEL_PAY_YOUR_SUPPLIER: "Bayar pembekal anda",
  LABEL_PAY_YOUR_TAX: "Bayar cukai anda",
  LABEL_RECOMMENT_ADD_CARD_DESC:
    "Lakukan pembayaran dengan pantas dan mudah dengan menambah kad anda ke dalam dompet anda.",
  LABEL_RECOMMENT_SCHEDULE_PAYMENT_DESC:
    "Menjimat masa dan nikmati kadar lebih baik dengan pembayaran berulang.",
  LABEL_RECOMMENT_SUPPORT_DESC:
    "Hubungi kami melalui sembang langsung atau emel",
  LABEL_RECOMMENT_VIEW_PAYMENT_DESC: "Papar dan uruskan maklumat pembayaran",
  LABEL_RECOMMENT_VIEW_PAYMENT_TITLE: "LIHAT STATUS PEMBAYARAN",
  LABEL_REGISTER_SELECT_COUNTRY: "Pilih lokasi anda",
  LABEL_REMEMBER_ME: "Ingat saya",
  LABEL_RENT_BENEFICIARY_STATEMENT: "Nota kepada Tuan Rumah",
  LABEL_RENT_SCHEDULE_BENIFICIARY:
    "Kad anda akan dikenakan caj pada {charged_date}. Tuan rumah anda akan menerima dana ini sebelum {end_of_day} pada {payout_date}.",
  LABEL_RENT_SCHEDULE_BENIFICIARY_TODAY:
    "Kad anda akan dikenakan caj hari ini. Tuan rumah anda akan menerima dana ini sebelum {end_of_day} pada {payout_date}.",
  LABEL_SALARY_SCHEDULE_BENIFICIARY:
    "Kad anda akan dikenakan caj pada {charged_date}. Pekerja anda akan menerima dana ini sebelum {end_of_day} pada {payout_date}.",
  LABEL_SALARY_SCHEDULE_BENIFICIARY_TODAY:
    "Kad anda akan dikenakan caj hari ini. Pekerja anda akan menerima dana ini sebelum {end_of_day} pada {payout_date}.",
  LABEL_SCHEDULE_BENIFICIARY:
    "Kad anda akan dikenakan caj {charged_date}. Tuan rumah anda akan menerima wang tersebut sebelum tamat hari bekerja pada {payout_date}.",
  LABEL_SCHEDULE_BENIFICIARY_TODAY:
    "Kad anda akan dikenakan caj hari ini. Tuan rumah anda akan menerima wang tersebut sebelum tamat hari bekerja pada {payout_date}.",
  LABEL_SCHEDULE_YOUR_PAYMENT: "Jadualkan pembayaran anda",
  LABEL_SCHEDULE_YOUR_PAYMENTS: "Jadualkan pembayaran anda",
  LABEL_SELECT_PAYOUT_DATE: "Pilih Tarikh Pembayaran",
  LABEL_SELECT_PAYOUT_DATES: "Pilih Tarikh Pembayaran",
  LABEL_SMS_REQUEST: "Permintaan SMS",
  LABEL_TRANSACTION_SUCCESSFUL:
    "Kad anda telah berjaya dikenakan caj. Pembayaran anda akan dibuat pada atau sebelum {payout_date}.",
  LABEL_TRANSACTION_SUCCESSFUL_RECEIPT_NUMBER: "Nombor Resit: {receipt_number}",
  LABEL_TRY_IPM_FOR_BUSINESS: "Cuba ipaymy untuk Perniagaan",
  LABEL_UPLOAD_CSV_FILE_TIP:
    'Petua: Simpan sel "Nombor Akaun" sebagai format TEKS dan simpan jenis fail sebagai CSV',
  LABEL_UPLOAD_INVOICE: "Muat naik invois anda",
  LANDLORD_NAME_LABEL: "Nama Tuan Rumah",
  LANDLORD_NAME_PLACEHOLDER: "Nama Tuan Rumah",
  LANDLORD_NOTE_PLACEHOLDER:
    "Keterangan untuk diletakkan dalam penyata benefisiari anda",
  LAST_NAME_ERROR: "Sila masukkan nama akhir anda",
  LAST_NAME_PLACEHOLDER: "Nama Akhir",
  LEGEND_INCOMING_PAYMENT: "Pembayaran \u003cb\u003eMasuk\u003c/b\u003e",
  LEGEND_OUTGOING_PAYMENT: "Pembayaran \u003cb\u003eKeluar\u003c/b\u003e",
  LIMIT_PAYMENT_UNIONPAY_7399:
    "Harap maklum bahawa jumlah maksimum AS$5.000 atau yang setara berlaku untuk semua pembayaran gaji atau invois jika anda memilih untuk membayar dengan kad kredit China UnionPay.",
  LOADING_STATUS: "Memuatkan...",
  MADE_MULTIPLE_PAYMENTS_LABEL:
    "Beberapa pembayaran telah dibuat kepada pembekal ini",
  MADE_MULTIPLE_PAYMENTS_TOOLTIP:
    "Oleh kerana anda telah membuat pembayaran berulang kepada pembekal ini anda mungkin layak untuk yuran yang lebih rendah sekali sekala",
  MALAYSIAN_LABEL: "Rakyat Malaysia",
  MASTERCARD_LABEL: "Mastercard",
  MAYBE_LATER: "Mungkin Kemudian",
  MINIMUM_INVOICE_AMOUNT_AU: "Amaun invois minimum A$15.",
  MINIMUM_INVOICE_AMOUNT_AU_PAYMENT_COLLECTION: "Amaun invois minimum A$30.",
  MINIMUM_INVOICE_AMOUNT_HK: "Amaun invois minimum HK$80.",
  MINIMUM_INVOICE_AMOUNT_HK_PAYMENT_COLLECTION: "Amaun invois minimum HK$50.",
  MINIMUM_INVOICE_AMOUNT_HK_USD: "Amaun invois minimum US$10.",
  MINIMUM_INVOICE_AMOUNT_MY: "Amaun invois minimum adalah RM30.",
  MINIMUM_INVOICE_AMOUNT_MY_PAYMENT_COLLECTION: "Amaun invois minimum RM100.",
  MINIMUM_INVOICE_AMOUNT_SG: "Amaun invois minimum S$15.",
  MINIMUM_INVOICE_AMOUNT_SG_PAYMENT_COLLECTION: "Amaun invois minimum S$30.",
  MINIMUM_INVOICE_AMOUNT_USD_PAYMENT_COLLECTION:
    "Amaun invois minimum US$1.00.",
  MINIMUM_RENT_AMOUNT_AU: "Amaun sewa minimum A$200.",
  MINIMUM_RENT_AMOUNT_HK: "Amaun sewa minimum HK$1,700.",
  MINIMUM_RENT_AMOUNT_HK_USD: "Amaun sewa minimum US$200.",
  MINIMUM_RENT_AMOUNT_MY: "Amaun sewa minimum RM500.",
  MINIMUM_RENT_AMOUNT_SING: "Amaun sewa minimum S$300.",
  MINIMUM_SALARY_AMOUNT_AU: "Amaun gaji minimum ialah A$15.",
  MINIMUM_SALARY_AMOUNT_HK: "Amaun gaji minimum ialah HK$80.",
  MINIMUM_SALARY_AMOUNT_HK_USD: "Amaun gaji minimum ialah US$10.",
  MINIMUM_SALARY_AMOUNT_MY: "Amaun gaji minimum ialah RM30.",
  MINIMUM_SALARY_AMOUNT_SG: "Amaun gaji minimum ialah S$15.",
  MINIMUM_SALARY_BUSINESS_AMOUNT_AU: "Amaun gaji minimum ialah A$15.",
  MINIMUM_SALARY_BUSINESS_AMOUNT_HK: "Amaun gaji minimum ialah HK$80.",
  MINIMUM_SALARY_BUSINESS_AMOUNT_HK_USD: "Amaun gaji minimum ialah US$10.",
  MINIMUM_SALARY_BUSINESS_AMOUNT_MY: "Amaun gaji minimum ialah RM30.",
  MINIMUM_SALARY_BUSINESS_AMOUNT_SG: "Amaun gaji minimum ialah S$15.",
  MISSING_INCORPORATION_DATE_ERROR:
    "Tarikh penubuhan diperlukan untuk pembayaran antarabangsa.",
  MISSING_REGION_OR_CITY_ERROR:
    "Sila pilih wilayah dan bandar untuk pembayaran ini.",
  MOBILE_NUMBER_ERROR: "Sila masukkan nombor telefon mudah alih anda",
  MOBILE_NUMBER_PLACEHOLDER: "Nombor Telefon Mudah Alih",
  MONEY_TRANSFER_BUTTON: "Pemindahan Wang",
  MORE_BUTTON: "More",
  MULTIPLE_EMPLOYEES_ONE_CURRENCY:
    "Anda hanya boleh membayar berbilang pekerja dalam \u003cb\u003esatu\u003c/b\u003e mata wang",
  MULTIPLE_PROPERTIES_ONE_CURRENCY:
    "Anda hanya boleh membayar berbilang hartanah dalam \u003cb\u003esatu\u003c/b\u003e mata wang",
  MULTIPLE_RECIPIENTS: "Berbilang Penerima",
  MYINFO_ERROR_HERO: "Kami tidak dapat memperolehi maklumat anda dari Myinfo.",
  MYINFO_ERROR_INSTRUCTIONS:
    "Anda perlu mengesahkan identiti anda untuk membuat pembayaran. {br}Anda boleh mencuba semula nanti dengan melawati portal Myinfo di \u003ca1\u003emyinfo.gov.sg\u003c/a1\u003eatau hubungi \u003ca2\u003esupport@myinfo.gov.sg\u003c/a2\u003e{br}jika anda memerlukan bantuan tambahan.",
  MYINFO_HERO: "Sahkan identiti anda mengguna Myinfo",
  MYINFO_INSTRUCTIONS:
    "Dengan mengakses Myinfo melalui Singpass, data peribadi anda akan diambil secara automatik dan ruang berkenaan akan diisi, menjadikan pengesahan identiti anda lebih cepat dan lebih mudah.",
  MYINFO_LOGIN_BUTTON: "Log masuk melalui Singpass",
  MYINFO_VERIFICATION_ADDRESS: "Alamat Berdaftar",
  MYINFO_VERIFICATION_DOB: "Tarikh Lahir",
  MYINFO_VERIFICATION_HERO:
    "Sila ambil perhatian bahawa maklumat yang diambil dari Myinfo tidak boleh diedit",
  MYINFO_VERIFICATION_MOBILE: "Nombor Telefon Mudah Alih",
  MYINFO_VERIFICATION_NAME_LABEL: "Nama",
  MYINFO_VERIFICATION_NATIONALITY: "Kewarganegaraan",
  MYINFO_VERIFICATION_NRIC_LABEL: "NRIC/FIN",
  MYINFO_VERIFICATION_PASSPORT_EXPIRY: "Tarikh Tamat Tempoh Pasport",
  MYINFO_VERIFICATION_PASSPORT_NUMBER: "Nombor Pasport",
  MYINFO_VERIFICATION_PASS_EXPIRY: "Tarikh Tamat Tempoh Pas",
  MYINFO_VERIFICATION_PASS_TYPE: "Jenis Pas",
  MYINFO_VERIFICATION_RESIDENTIAL_STATUS: "Status Pemastautin",
  MYINFO_VERIFICATION_SEX: "Jantina",
  MYINFO_VERIRIFCATION_REQUIRED: "Pengesahan Myinfo diperlukan",
  MY_FEATURE_UNSUPPORTED_ERROR:
    "Ciri ini tidak disokong untuk pasaran Malaysia",
  MY_LABEL_END_OF_DAY: "hujung hari bekerja",
  NET_AMOUNT_LABEL: "Amaun Bersih",
  NEW_BADGE: "BARU",
  NEW_GREETING_NAVBAR: "Helo, {render}!",
  NEW_LABEL_INVOICE_SCHEDULE_BENIFICIARY:
    "Kad anda akan dicaj pada {render1}. Pembekal anda akan menerima dana sebelum {render2} pada:{render3}",
  NEW_LABEL_INVOICE_SCHEDULE_BENIFICIARY_TODAY:
    "Kad anda akan dicaj hari ini. Pembekal anda akan menerima dana sebelum {render1} pada:{render2}",
  NEW_LABEL_RENT_SCHEDULE_BENIFICIARY:
    "Kad anda akan dicaj pada {render1}. Tuan tanah anda akan menerima dana sebelum {render2} pada:{render3}",
  NEW_LABEL_RENT_SCHEDULE_BENIFICIARY_TODAY:
    "Kad anda akan dicaj hari ini. Tuan tanah anda akan menerima dana sebelum {render1} pada:{render2}",
  NEW_LABEL_SALARY_BUSINESS_SCHEDULE_BENIFICIARY:
    "Kad anda akan dicaj pada {render1}. Dana anda akan berada dalam akaun bank perniagaan anda sebelum {render2} pada:{render3}",
  NEW_LABEL_SALARY_BUSINESS_SCHEDULE_BENIFICIARY_TODAY:
    "Kad anda akan dicaj hari ini. Dana anda akan berada dalam akaun bank perniagaan anda sebelum {render1} pada:{render2}",
  NEW_LABEL_SALARY_SCHEDULE_BENIFICIARY:
    "Kad anda akan dicaj pada {render1}. Pekerja anda akan menerima dana sebelum {render2} pada:{render3}",
  NEW_LABEL_SALARY_SCHEDULE_BENIFICIARY_TODAY:
    "Kad anda akan dicaj hari ini. Pekerja anda akan menerima dana sebelum {render1} pada:{render2}",
  NEW_LABEL_TRANSACTION_SUCCESSFUL_RECEIPT_NUMBER: "Nombor Resit: {render}",
  NEW_PASSWORD_PLACEHOLDER: "Kata laluan baru",
  NEW_TOTAL_HEADER: "Jumlah Baru",
  NEW_VERIFICATION_LINK:
    "Link pengesahan emel yang anda gunakan telah tamat tempoh, klik butang di bawah untuk mendapatkan satu link baru.",
  NEXT_BUTTON: "Seterusnya",
  NOTE_CREATE_SUPPLIER_ABN_APPROVED:
    "Permohonan ABN{regno} anda telah diluluskan. Sebarang pembayaran tertangguh kepada pembekal ini kini akan diproses.",
  NOTE_CREATE_SUPPLIER_ABN_DECLINED:
    "Permohonan ABN{regno} anda telah ditolak. Sila hubungi sembang langsung kami untuk mendapatkan maklumat lanjut.",
  NOTE_CREATE_SUPPLIER_ABN_REQUEST:
    "Permohonan ABN anda {regno} sedang disemak pada masa ini, ini biasanya tidak akan mengambil masa lebih daripada beberapa jam perniagaan. Sila ambil perhatian bahawa sebarang pembayaran kepada pembekal ini akan ditangguhkan sementara kami mengesahkan permintaan permohonan anda.",
  NOTE_CREATE_SUPPLIER_CRN_APPROVED:
    "Permohonan CRN{regno} anda telah diluluskan. Sebarang pembayaran tertangguh kepada pembekal ini kini akan diproses.",
  NOTE_CREATE_SUPPLIER_CRN_DECLINED:
    "Permohonan CRN{regno} anda telah ditolak. Sila hubungi sembang langsung kami untuk mendapatkan maklumat lanjut.",
  NOTE_CREATE_SUPPLIER_CRN_REQUEST:
    "Permohonan CRN anda {regno} sedang disemak pada masa ini, ini biasanya tidak akan mengambil masa lebih daripada beberapa jam perniagaan. Sila ambil perhatian bahawa sebarang pembayaran kepada pembekal ini akan ditangguhkan sementara kami mengesahkan permintaan permohonan anda.",
  NOTE_CREATE_SUPPLIER_UEN_APPROVED:
    "Permohonan UEN{regno} anda telah diluluskan. Sebarang pembayaran tertangguh kepada pembekal ini kini akan diproses.",
  NOTE_CREATE_SUPPLIER_UEN_DECLINED:
    "Permohonan UEN{regno} anda telah ditolak. Sila hubungi sembang langsung kami untuk mendapatkan maklumat lanjut.",
  NOTE_CREATE_SUPPLIER_UEN_REQUEST:
    "Permohonan UEN anda {regno} sedang disemak pada masa ini, ini biasanya tidak akan mengambil masa lebih daripada beberapa jam perniagaan. Sila ambil perhatian bahawa sebarang pembayaran kepada pembekal ini akan ditangguhkan sementara kami mengesahkan permintaan permohonan anda.",
  NOTE_LABEL: "Nota kepada pekerja",
  NOTE_PLACEHOLDER:
    "Keterangan untuk diletakkan dalam penyata bank pekerja anda",
  NOTICE_OF_ASSESSMENT_LABEL:
    "Notis Taksiran \u003cspan\u003eor\u003c/span\u003e Penyata Akaun",
  NOTIFY_SUPPLIER_CHECKBOX:
    "Adakah anda ingin memberitahu pembekal anda bahawa satu pembayaran sudah mula dibuat?",
  NOT_AFFILIATED_CHECKBOX:
    "Saya mengesahkan saya tidak bersekutu dengan perniagaan ini dan semua pembayaran yang dibuat kepada pembekal ini adalah untuk barangan dan perkhidmatan yang sah.",
  NOT_INPROGRESS_PAYMENT_ERROR:
    "Status pembayaran ini ialah tidak dalam proses",
  NO_CHECKBOX: "Tidak",
  NO_PIN_RECEIVED: "Tidak menerima PIN?",
  NRIC_LABEL: "NRIC",
  NRIC_PLACEHOLDER: "NRIC Pekerja",
  NUMBER_OF_MOBILE_VERIFICATION_ATTEMPTS_EXCEEDED:
    "Bilangan cubaan pengesahan telefon mudah alih telah melebihi. Sila {contact_link} untuk bantuan.",
  NUMBER_OF_MOBILE_VERIFICATION_ATTEMPTS_EXCEEDED_ERROR:
    "Bilangan cubaan pengesahan telefon mudah alih telah melebihi. Sila {contact_link} untuk bantuan.",
  ONLY_MALAYSIA_ISSUER: "Sila gunakan kad kredit Malaysia yang sah.",
  ONLY_MALAYSIA_ISSUER_MASTERCARD:
    "Kami hanya menerima Mastercard yang dikeluarkan di Malaysia buat masa ini.",
  ONLY_VISA_MASTERCARD: "Kami hanya menerima Visa dan Mastercard.",
  ONLY_VISA_MASTERCARD_AMEX: "Kami hanya menerima Visa, Mastercard dan Amex.",
  ONLY_VISA_MASTERCARD_UNIONPAY:
    "Kami hanya menerima Visa, Mastercard dan China UnionPay.",
  ONLY_VISA_MASTERCARD_UNIONPAY_AMEX:
    "Kami hanya menerima Visa, Mastercard, China UnionPay, dan Amex.",
  OPTIONAL_NOTE_TO_SUPPLIER: "Nota kepada Pembekal (Pilihan)",
  ORDER_NUMBER_LABEL: "Nombor Pesanan ",
  OVERDUE_PAYMENT: "Pembayaran anda melebihi tarikh akhir invois.",
  PASSWORD_CONFIRM_DOES_NOT_MATCH: "Kata laluan tidak sepadan",
  PASSWORD_MINIMUM_ERROR:
    "Sila pilih kata laluan yang lebih kukuh dengan sekurang-kurangnya 8 aksara yang mengandungi huruf, nombor dan simbol.",
  PASSWORD_PLACEHOLDER: "Kata Laluan",
  PASSWORD_RESET_INSTRUCTIONS:
    "Kami akan menghantar arahan untuk menetapkan semula kata laluan anda.",
  PAYEE_ALREADY_EXISTS: "Anda telah pun menambahkan penerima ini.",
  PAYEE_BANK_ACCOUNT_HOLDER: "Nama Pemegang Akaun Bank",
  PAYEE_DETAILS_BANK_ACCOUNT: "Akaun",
  PAYEE_DETAILS_BANK_NAME: "Bank",
  PAYEE_EMPLOYEE: "Pekerja",
  PAYEE_NOT_FOUND_ERROR:
    "Kami tidak dapat mencari penerima pembayaran anda. Sila hubungi kami melalui support@ipaymy.com untuk butiran.",
  PAYEE_PROPERTY: "Alamat Hartanah",
  PAYEE_SUPPLIER: "Pembekal",
  PAYER_EXIST: "Pelanggan ini telah wujud.",
  PAYING: "Membayar",
  PAYMENTS_ACCOUNT_HEADER: "No. Akaun",
  PAYMENTS_BANK_HEADER: "Bank",
  PAYMENTS_CARD_LABEL: "Kad Telah Dikenakan Caj",
  PAYMENTS_COMPANY_LABEL: "Syarikat",
  PAYMENTS_DESCRIPTION:
    "Lihat butiran dan status pembayaran anda atau buat satu pembayaran baru.",
  PAYMENTS_DESCRIPTION_LABEL: "Keterangan",
  PAYMENTS_EXCHANGE_RATE_LABEL: "Kadar Tukaran",
  PAYMENTS_HERO: "PEMBAYARAN SAYA",
  PAYMENTS_MENU_ITEM: "Pembayaran",
  PAYMENTS_NOTE_TRANSFER: "Rujukan Pembayaran",
  PAYMENTS_PROMOTIONS_ITEM: "Promosi",
  PAYMENTS_PURPOSE_INVOICE: "Pembayaran Invois",
  PAYMENTS_PURPOSE_RENTAL: "Pembayaran Sewa",
  PAYMENTS_PURPOSE_SALARY: "Pembayaran Gaji",
  PAYMENTS_PURPOSE_TRANSFER: "Pemindahan Wang",
  PAYMENTS_RECEIPT_HEADER: "No. Resit",
  PAYMENTS_RECEIPT_LABEL: "Resit",
  PAYMENTS_TOTAL_LABEL: "Jumlah Amaun",
  PAYMENTS_VIEW_BUTTON: "Papar",
  PAYMENT_ARRIVE_LABEL: "Pembayaran anda akan tiba pada atau sebelum (SGT)",
  PAYMENT_BLOCKED_ERROR:
    "Pembayaran yang dibuat pada kad ini tidak disokong oleh ipaymy. Anda boleh  menghubungi kami untuk maklumat lanjut.",
  PAYMENT_CANCELLED_DESCRIPTION:
    "Alamak! Nampaknya seperti permintaan pembayaran ini telah dibatalkan oleh \u003cspan\u003enama syarikat\u003c/span\u003e{br}Tiada apa-apa tindakan diperlukan di sini!",
  PAYMENT_CANCELLED_HERO: "PERMINTAAN PEMBAYARAN TELAH DIBATALKAN",
  PAYMENT_CARD_UNSUPPORTED_3DS_ERROR:
    "Pembayaran yang dibuat pada kad ini tidak disokong oleh ipaymy. Anda boleh  menghubungi kami untuk maklumat lanjut.",
  PAYMENT_COLLECTION_CARD_HEADER: "Masukkan Butiran Kad",
  PAYMENT_COLLECTION_FEE_TOOLTIP:
    "Ini adalah yuran pemprosesan yang dikenakan oleh pedagang untuk penerimaan pembayaran kad kredit.",
  PAYMENT_COLLECTION_HERO: "Ringkasan Pembayaran Untuk Pembayaran Anda ke",
  PAYMENT_COLLECTION_PAY_INVOICE_BUTTON: "Bayar Invois Sekarang",
  PAYMENT_COLLECTION_PCI:
    "ipaymy adalah platform yang selamat. Semua pembayaran kami dibuat menggunakan penyulitan terkemuka industri melalui rangkaian pematuhan PCI untuk memastikan privasi anda dilindungi.",
  PAYMENT_CONFIRMATION_CONTACT: "Untuk pertanyaan, sila hubungi kami di",
  PAYMENT_CONFIRMATION_GO_TO_IPAYMY:
    "\u003ca\u003ePergi ke ipaymy\u003c/a\u003e\u003cp\u003eMulakan dengan platform pengurusan aliran tunai sehenti ipaymy.\u003c/p\u003e",
  PAYMENT_CONFIRMATION_LEARN: "Pelajari lebih lanjut mengenai",
  PAYMENT_CONFIRMATION_RECEIPT: "Nombor Resit",
  PAYMENT_CONFIRMATION_REDIRECT_MESSAGE:
    "Jika anda tidak dibawa ke {render} dalam masa 10 saat, \u003ca\u003eklik di sini\u003c/a\u003e.",
  PAYMENT_CONFIRMATION_SUCCESS: "PEMBAYARAN BERJAYA",
  PAYMENT_CONFIRMATION_THANKS:
    "Terima kasih kerana membayar melalui ipaymy, satu resit dengan butiran pembayaran telah dihantar ke emel anda.",
  PAYMENT_CONFIRMATION_TRY:
    "\u003ca\u003eCuba ipaymy sekarang\u003c/a\u003eMulakan hari ini dan gunakan kad kredit anda untuk membayar{br} sewa, gaji, invois, dan lebih lagi.\u003c/p\u003e",
  PAYMENT_CONFIRMATION_TRY_BUTTON: "Cuba ipaymy sekarang",
  PAYMENT_DESCRIPTION_INVALID_AMEX:
    "Sila perhalusi perihalan pembayaran anda untuk digunakan dengan Amex, atau simpan perihalan pembayaran asal anda dan pilih jenama kad lain.",
  PAYMENT_DESCRIPTION_LABEL: "Keterangan Pembayaran",
  PAYMENT_DETAILS_HERO: "Butiran Pembayaran",
  PAYMENT_DETAILS_TOOLTIP: "Klik untuk melihat butiran lanjut",
  PAYMENT_FAILED_EMAIL_REASON:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  PAYMENT_FAILED_HERO: "Alamak! Pembayaran Gagal.",
  PAYMENT_INTEREST_BE_PAID:
    "\u003cb\u003eMenerima Pembayaran\u003c/b\u003e dari pelanggan saya melalui penerimaan kad kredit",
  PAYMENT_INTEREST_BOTH: "Kedua-dua",
  PAYMENT_INTEREST_ERROR: "Sila pilih satu respons.",
  PAYMENT_INTEREST_INSTRUCTIONS:
    "Bagi memberi anda lebih pengalaman peribadi, kami ingin mengetahui apa minat anda dalam kegunaan ipaymy?",
  PAYMENT_INTEREST_PAY:
    "\u003cb\u003eBayar\u003c/b\u003e perbelanjaan saya dengan kad kredit saya",
  PAYMENT_MAKE_ALREADY_DESCRIPTION: "Anda telah pun membuat pembayaran ini.",
  PAYMENT_MAKE_ALREADY_HERO: "PEMBAYARAN TELAH DIBUAT",
  PAYMENT_MAKE_ALREADY_LOGIN_BUTTON: "Log Masuk",
  PAYMENT_MAKE_ALREADY_THANKS: "Terima kasih kerana membayar melalui ipaymy.",
  PAYMENT_OVERDUE_HERO: "BAYARAN LANJUTAN",
  PAYMENT_OVERDUE_MESSAGE:
    "Pembayaran ini sudah terlambat dan pautan pembayaran tidak lagi tersedia.",
  PAYMENT_PAID_DATE: "Tarikh DIbayar",
  PAYMENT_PAID_HERO: "TANDAKAN PEMBAYARAN SEBAGAI TELAH DIBAYAR",
  PAYMENT_PAID_ORDER_NUMBER: "Nombor Tempahan:",
  PAYMENT_PROCESSED_ERROR: "Pembayaran telah diproses.",
  PAYMENT_REFERENCE_LABEL: "Rujukan Pembayaran",
  PAYMENT_REQUEST_FAILED:
    "\u003cspan1\u003ePemberitahuan:\u003c/span1\u003e Permintaan pembayaran (nombor invois {render1}) gagal. Sila semak dan kemas kini {render2} pelanggan anda \u003cspan2\u003edisini\u003c/span2\u003e.",
  PAYMENT_SUCCESSFUL_HERO: "Yeah! Transaksi berjaya!",
  PAYMENT_SUCCESSFUL_INSTRUCTIONS:
    "Kad anda telah berjaya dikenakan caj. Pembayaran anda akan dibuat pada atau sebelum",
  PAYMENT_SUMMARY_LABEL: "Ringkasan Pembayaran",
  PAYMENT_TECHNICAL_ISSUE_ERROR:
    "Terdapat masalah teknikal dengan pembayaran anda. Anda boleh menghubungi kami untuk butiran lanjut pada bila-bila masa.",
  PAYMENT_TO_LABEL: "Pembayaran kepada {value}",
  PAYSLIP_EMPLOYEE_ID: "ID Pekerja",
  PAYSLIP_EMPLOYEE_ID_HKID: "Nombor ID Pekerja (contoh: Nombor HKID)",
  PAYSLIP_EMPLOYEE_ID_MYKAD: "Nombor ID Pekerja (contoh: Nombor MyKad)",
  PAYSLIP_EMPLOYEE_ID_NRIC: "Nombor ID Pekerja (contoh: Nombor NRIC)",
  PAYSLIP_EMPLOYEE_NAME: "Nama Pekerja",
  PAYSLIP_LABEL:
    "\u003cspan\u003eSlip Gaji atau Kontrak Kerja\u003c/span\u003e mesti disertakan",
  PAY_BROWSER_WARNING:
    "Sila elakkan menutup tetingkap penyemak imbas ini atau memuat semula halaman ini sehingga pembayaran selesai.",
  PAY_BY_LABEL: "Bayar melalui",
  PAY_CALENDAR_SUBTITLE: "Jumlah Amaun Dibayar Bulan Ini",
  PAY_CALENDAR_TITLE: "Pembayaran Yang Telah Dibuat",
  PAY_FEATURE_DISABLED_ERROR:
    "Pembayaran dihentikan sementara sementara kami membuat beberapa penambahbaikan produk. Terima kasih atas kesabaran anda. Kami akan memaklumkan anda sebaik sahaja pembayaran tersedia.",
  PAY_IN_LABEL: "\u003cb\u003eBAYAR\u003c/b\u003eDALAM",
  PAY_IN_NOW_LABEL:
    "\u003cb\u003eBAYAR\u003c/b\u003e DAN BUAT PEMBAYARAN SEKARANG KE PENERIMA DALAM",
  PAY_LABEL: "BAYAR",
  PAY_MENU_ITEM: "Bayar",
  PAY_NEED_HELP_LABEL: "PERLUKAN BANTUAN?",
  PAY_NOW_BUTTON: "Bayar Sekarang",
  PAY_RECOMMENDED_ACTIONS_SECTION:
    "TINDAKAN \u003cb\u003eDISYORKAN\u003c/b\u003e",
  PAY_TAX_TOOLTIP: "Bayar Cukai Disini!",
  PAY_WAITING_TOO_LONG:
    "Menunggu terlalu lama? Klik di sini untuk mencuba lagi.",
  PERSONAL_UNAVAILABLE: "Tidak Tersedia{br}Semasa Ini",
  POPUP_ACCEPT_PAYMENTS_BODY:
    "Menerima pembayaran invois dari pelanggan anda menggunakan ipaymy \u003cstrong\u003eMenerima Bayaran\u003c/strong\u003e hantar invois produk dan nikmati kadar 0% pada {render} dalam pembayaran.",
  POPUP_AWESOME_PAYMENTS_BODY:
    "Berita baik, pembayaran percuma akan ada tidak lama lagi! {br}Anda akan menerima satu emel dengan{br}lebih butiran sebentar lagi.",
  POPUP_BE_PAID_BUTTON: "Dibayar hari ini",
  POPUP_CLAIM_BUTTON: "Tuntut Tawaran",
  POPUP_CONGRATULATIONS_BODY:
    "Tahniah, anda layak untuk \u003cstrong\u003e...\u003c/strong\u003e{br}dalam pembayaran percuma! Adakah anda ingin menuntut {br}tawaran masa terhad ini sekarang?",
  POPUP_DASHBOARD_BODY:
    "Adakah anda ingin mengoptimumkan papan pemuka anda bagi menguruskan pembayaran tanpa sentuhan dari pelanggan-pelanggan anda?",
  POPUP_DASHBOARD_HERO: "Optimumkan papan pemuka anda",
  POPUP_EASY_PAYMENTS_BODY:
    "Pembayaran kad \u003cstrong\u003eMudah, Pantas dan Percuma\u003c/strong\u003e{br}terus ke akaun bank anda.",
  POPUP_FREE_PAYMENTS_HERO:
    "Kami ingin menghantar anda{br}\u003cspan1\u003e...\u003c/span1\u003e secara \u003cspan2\u003ePERCUMA!\u003c/span2\u003e",
  POPUP_GOT_IT_BUTTON: "Faham",
  POPUP_NOT_NOW_BUTTON: "Bukan Sekarang",
  POPUP_NO_THANKS_BUTTON: "Tidak mengapa",
  POPUP_START_ACCEPTING_BODY:
    "Mula menerima pembayaran invois dari {br}pelanggan anda menggunakan ipaymy \u003cstrong\u003eMenerima Bayaran\u003c/strong\u003e{br}hantar invois produk dan nikmati kadar 0% pada{br}{render} dalam pembayaran.",
  POPUP_YES_BUTTON: "Ya",
  POSTAL_CODE_LABEL: "Poskod",
  POSTAL_CODE_PLACEHOLDER: "Poskod",
  PO_NUMBER_LABEL: "Nombor P.O",
  PO_NUMBER_PLACEHOLDER: "Nombor P.O",
  PROGRAM_ENTERED_EARLY_ERROR: "Program telah dimasukkan sebelum ini",
  PROGRAM_NOT_FOUND: "Program tidak dijumpai",
  PROMO_CODE_PAYMENT_ROUTING_FAILED:
    "Kesilapan telah berlaku untuk pembayaran ini. Sila hubungi kami melalui sembang langsung di sudut kanan bawah.",
  PROPERTIES_HERO: "HARTANAH SAYA",
  PROPERTIES_MENU_ITEM: "Hartanah",
  PROPERTY_ACCOUNT_NUMBER_HEADER: "Nombor Akaun",
  PROPERTY_ADDRESS_HEADER: "Alamat Hartanah",
  PROPERTY_AMOUNT_HEADER: "Amaun",
  PROPERTY_BANK_HEADER: "Bank",
  PROPERTY_COUNTRY_HEADER: "Negara",
  PROPERTY_DELETE_HERO: "Adakah anda ingin memadam hartanah ini?",
  PROPERTY_DELETE_INSTRUCTIONS:
    "Hartanah ini akan dikeluarkan dari hartanah anda",
  PROPERTY_LANDLORD_HEADER: "Tuan rumah",
  PROPERTY_NOTE_HEADER: "Nota kepada Tuan Rumah",
  PROPERTY_SCHEDULED_PAYMENT_HERO:
    "Hartanah ini berkaitan dengan pembayaran berjadual.",
  PROPERTY_SCHEDULED_PAYMENT_INSTRUCTIONS:
    "Anda perlu membatalkan pembayaran berkaitan sebelum mengedit butiran hartanah.",
  PURPOSE_BANK_LABEL: "akaun bank",
  PURPOSE_COLLECTION: "Kutipan",
  PURPOSE_EMPLOYEE_LABEL: "pekerja",
  PURPOSE_INTERNATIONAL: "Antarabangsa",
  PURPOSE_INVOICE: "Invois-invois",
  PURPOSE_MONEY_TRANSFER: "Pemindahan Wang",
  PURPOSE_PARTNERSHIP: "Perkongsian",
  PURPOSE_PROPERTY_LABEL: "hartanah",
  PURPOSE_RENT: "Sewa",
  PURPOSE_SALARY: "Gaji",
  PURPOSE_SALARY_BUSINESS: "Perniagaan Gaji",
  PURPOSE_SUPPLIER_LABEL: "pembekal",
  REFERRALS_MENU_ITEM: "Rujukan",
  REFERRAL_BUSINESS_LABEL: "Bilangan Rujukan Perniagaan",
  REFERRAL_BUSINESS_NOTE:
    "Untuk setiap akaun rujukan Perniagaan yang berjaya, kedua-dua pihak akan menerima kod promosi bernilai {render}.",
  REFERRAL_BUSINESS_TOOLTIP:
    "Ini merujuk kepada jumlah bilangan rujukan akaun Perniagaan yang telah berjaya membuat pembayaran yang layak.",
  REFERRAL_CODE_ERROR: "Sila masukkan kod rujukan anda",
  REFERRAL_CODE_PLACEHOLDER: "Kod Rujukan",
  REFERRAL_COPIED_LABEL: "Disalin",
  REFERRAL_COPY_LABEL: "Salin",
  REFERRAL_COPY_LINK: "Salin link di bawah dan kongsikan dengan rujukan anda.",
  REFERRAL_ENJOY_STEP: "Nikmati Ganjaran",
  REFERRAL_HERO:
    "Perolehi sehingga \u003cstrong\u003e...\u003c/strong\u003e untuk setiap rujukan.",
  REFERRAL_INSTRUCTIONS:
    "Untuk setiap rujukan yang layak yang membuat pembayaran dengan ipaymy, kami akan memberi kod promosi bernilai sehingga \u003cstrong\u003e...\u003c/strong\u003e kepada anda berdua.*",
  REFERRAL_JOIN_STEP: "Sertai Sekarang",
  REFERRAL_PERSONAL_LABEL: "Bilangan Rujukan Peribadi",
  REFERRAL_PERSONAL_NOTE:
    "Untuk setiap akaun rujukan Peribadi yang berjaya, kedua-dua pihak akan menerima kod promosi bernilai {render}.",
  REFERRAL_PERSONAL_TOOLTIP:
    "Ini merujuk kepada jumlah bilangan rujukan akaun Peribadi yang telah berjaya membuat pembayaran yang layak.",
  REFERRAL_SHARE_STEP: "Kongsi Link Anda",
  REFERRAL_SIGNUPS_LABEL: "Bilangan Pendaftaran",
  REFERRAL_SIGNUPS_TOOLTIP:
    "Ini merujuk kepada jumlah bilangan pendaftaran baru dari link rujukan unik anda.",
  REFERRAL_TCS_1:
    "Rujukan anda hendaklah pengguna baru yang tidak mendaftar akaun ipaymy sebelum ini",
  REFERRAL_TCS_2:
    "Untuk rujukan berjaya, rujukan anda perlu ada pembayaran lengkap yang layak pada ipaymy. Sila klik \u003ca\u003edi sini\u003ca/\u003e untuk mengetahui lebih lanjut mengenai jenis-jenis pembayaran yang kami terima.",
  REFERRAL_TCS_3:
    "Kod Promo Rujukan sah untuk 30 hari dari tarikh ia dikeluarkan",
  REFERRAL_TCS_4:
    "ipaymy berhak untuk tidak memberi ganjaran bagi pengguna yang mempunyai beberapa akaun",
  REFERRAL_TCS_LABEL: "Terma dan syarat terpakai",
  REFERRAL_TCS_VIEW:
    "Lihat Terma-terma dan Syarat-syarat lengkap \u003ca\u003edi sini\u003c/a\u003e.",
  REFER_FRIEND_BODY:
    "Untuk setiap individu atau perniagaan yang anda{br}rujuk yang melakukan pembayaran dengan ipaymy,{br}kami akan memberikan anda berdua kod promosi{br}bernilai hingga \u003cb\u003e{amount}\u003c/b\u003e!",
  REFER_FRIEND_HERO:
    "\u003cb\u003eRujuk Rakan atau Perniagaan\u003c/b\u003e dan{br}\u003cb\u003eDapatkan hingga {amount}!\u003c/b\u003e",
  REFUNDED_AMOUNT_HEADER: "Amaun Yang Dibayar Balik",
  REFUNDED_FEE_HEADER: "Yuran Yang Dibayar Balik",
  REGION_LABEL: "Wilayah",
  REGION_PLACEHOLDER: "Wilayah",
  REGISTER_COMPANY_TOOLTIP: "Sila daftar syarikat terlebih dahulu.",
  REGISTER_FIRST: "Sila daftar syarikat anda terlebih dahulu",
  REGISTRATION_GRAPHIC_ACCOUNT_TYPE_BUSINESS: "Akaun Perniagaan",
  REGISTRATION_GRAPHIC_ACCOUNT_TYPE_PERSONAL: "Akaun Peribadi",
  REGISTRATION_GRAPHIC_BUSINESS_LINE_1:
    "Bayar perbelanjaan perniagaan seperti sewa pejabat, gaji, invois, dan lain-lain",
  REGISTRATION_GRAPHIC_BUSINESS_LINE_2:
    "Hantar invois dan kumpulkan pembayaran melalui kad kredit dari pelanggan anda",
  REGISTRATION_GRAPHIC_BUSINESS_LINE_3: "Papan pemuka untuk menguruskan AR/AP",
  REGISTRATION_GRAPHIC_BUSINESS_LINE_4:
    "Pegawai Perkhidmatan pelanggann yang berdedikasi",
  REGISTRATION_GRAPHIC_BUSINESS_LINE_5:
    "Nikmati harga yang disesuaikan untuk perniagaan anda",
  REGISTRATION_GRAPHIC_BUSINESS_SIGNUP: "Daftar untuk akaun perniagaan",
  REGISTRATION_GRAPHIC_FOOTNOTE:
    "*Bergantung kepada skim negara, bank dan kad kredit.",
  REGISTRATION_GRAPHIC_HERO:
    "Sila pilih akaun yang bersesuaian untuk kegunaan anda",
  REGISTRATION_GRAPHIC_PERSONAL_LINE_1:
    "Bayar perbelanjaan peribadi seperti sewa, tuisyen, kos kondo dan lain-lain lagi",
  REGISTRATION_GRAPHIC_PERSONAL_LINE_2:
    "Papan pemuka untuk memudahkan pengurusan perbelanjaan bulanan",
  REGISTRATION_GRAPHIC_PERSONAL_LINE_3:
    "Nikmati promosi khas dari kad kredit anda",
  REGISTRATION_GRAPHIC_PERSONAL_SIGNUP: "Daftar untuk akaun peribadi",
  REGISTRATION_NO_COMPANY_NUMBER:
    "Saya tidak ada nombor pendaftaran syarikat (SSM) pada masa ini.",
  REGISTRATION_NUMBER_COLUMN: "Nombor Pendaftaran",
  REGISTRATION_NUMBER_LABEL: "Nombor Pendaftaran",
  REGISTRATION_NUMBER_PLACEHOLDER: "Nombor Pendaftaran",
  REMOVE_BUTTON: "Buang",
  RENTAL_AMOUNT_LABEL: "Amaun Sewa (S$)",
  RENT_BUTTON: "Sewa",
  REQUEST_ANOTHER_PAYMENT_BUTTON: "Minta Pembayaran Lain",
  REQUEST_CONFIRM_EMAIL_LABEL: "Sahkan Emel Baru",
  REQUEST_CONFIRM_EMAIL_PLACEHOLDER: "Sahkan Emel Baru",
  REQUEST_CONFIRM_MOBILE_LABEL: "Sahkan Nombor Telefon Mudah Alih",
  REQUEST_CONFIRM_MOBILE_PLACEHOLDER: "Sahkan Nombor Mudah Alih Baru",
  REQUEST_NEW_EMAIL_LABEL: "Emel Baru",
  REQUEST_NEW_EMAIL_PLACEHOLDER: "Emel Baru",
  REQUEST_NEW_MOBILE_LABEL: "Nombor Telefon Mudah Alih Baru",
  REQUEST_NEW_MOBILE_PLACEHOLDER: "Nombor Telefon Mudah Alih Baru",
  REQUEST_OLD_EMAIL_LABEL: "Email Lama",
  REQUEST_OLD_MOBILE_LABEL: "Nombor Telefon Mudah Alih Lama",
  REQUEST_PAYMENT_BUTTON: "Minta Pembayaran",
  REQUEST_PAYMENT_HERO: "\u003cb\u003eMINTA\u003c/b\u003e PEMBAYARAN",
  REQUEST_RESEND_BUTTON: "Hantar Semula Permintaan Pembayaran",
  REQUEST_RESENT:
    "Permintaan pembayaran anda telah dihantar semula ke pelanggan anda.",
  REQUEST_RESENT_EMAIL:
    "Permintaan pembayaran anda telah dihantar semula ke alamat emel yang dikemas kini.",
  REQUEST_RESENT_MOBILE:
    "Permintaan pembayaran anda telah dihantar semula ke telefon mudah alih yang dikemas kini.",
  REQUEST_SENT_COPIED_CONFIRMATION: "Disalin",
  REQUEST_SENT_COPY_BUTTON: "Salin",
  REQUEST_SENT_HERO: "Yeah! Permintaan telah berjaya dihantar!",
  REQUEST_SENT_INSTRUCTIONS:
    "Permintaan pembayaran anda telah dihantar ke {render} anda. Anda akan menerima emel pemberitahuan setelah anda dibayar.",
  REQUEST_SENT_LINK: "Salin link di bawah dan kongsikan dengan {render} anda",
  REQUEST_TO_LIST_NEW_COMPANY: "Permintaan untuk Menyenaraikan Syarikat Baru",
  REQUEST_UPDATE_EMAIL_HERO: "KEMAS KINI EMEL PELANGGAN",
  REQUEST_UPDATE_MOBILE_HERO: "KEMAS KINI NOMBOR TELEFON MUDAH ALIH PELANGGAN",
  REQUIRED_DOCUMENT_CAPITAL: "Suntikan Modal",
  REQUIRED_DOCUMENT_FUNDS: "Dana Diperlukan untuk Operasi",
  REQUIRED_DOCUMENT_HEADER: "Dokumen Yang Diperlukan",
  REQUIRED_DOCUMENT_MESSAGE:
    "Sila muat naik penyata mengenai tujuan pemindahan dana ke entiti perniagaan anda di luar negara. Ini perlu dinyatakan pada kepala surat syarikat.{br}Contoh-contoh tujuan yang diterima ialah:",
  REQUIRED_FIELD: "Ruang ini perlu diisi.",
  REQUIRED_FIELD_EMAIL: "E-mel diperlukan",
  REQUIRED_FIELD_MOBILE_NUMBER: "Nombor telefon bimbit diperlukan",
  REQUIRED_FIELD_PASSWORD: "Kata laluan diperlukan",
  RESEND_EMAIL_BUTTON: "Hantar semula emel",
  RESEND_PIN: "hantar semula kod",
  RESEND_VERIFICATION_EMAIL: "Hantar semula emel pengesahan",
  RESENT_PIN:
    "Kami telah menghantar satu PIN baru kepada anda. Ini mungkin mengambil masa beberapa minit.",
  RESET_EMAIL_ADDRESS:
    "Masukkan alamat emel yang anda gunakan semasa mendaftar, kami akan menghantar arahan bagaimana menetap semula kata laluan anda.",
  RESET_INSTRUCTIONS:
    "Arahan untuk menetap semula kata laluan anda telah dihantar. Sila semak emel anda.",
  RESET_PASSWORD_BUTTON: "Tetapkan Semula Kata Laluan",
  RESET_PASSWORD_LINK: "Tetapkan semula di sini.",
  RESTRICTED_BUSINESS_INSTRUCTIONS:
    'Sila sahkan bahawa perniagaan yang telah anda pilih tidak tergolong dalam skop "Perniagaan Yang Dilarang"',
  RESTRICTED_FINANCIAL_EXAMPLE_1: "Perkhidmatan pelaburan \u0026 kredit",
  RESTRICTED_FINANCIAL_EXAMPLE_2: "Perkhidmatan wang dan perundangan",
  RESTRICTED_FINANCIAL_EXAMPLE_3: "Mata wang atau nilai simpanan maya",
  RESTRICTED_FINANCIAL_HEADER: "Perkhidmatan kewangan dan profesional seperti",
  RESTRICTED_HIGH_RISK_EXAMPLE_1: "Peguam-peguam kebankrapan",
  RESTRICTED_HIGH_RISK_EXAMPLE_2: "Sokongan teknikal komputer dan bantuan IT",
  RESTRICTED_HIGH_RISK_EXAMPLE_3: "Perkhidmatan Psikik",
  RESTRICTED_HIGH_RISK_EXAMPLE_4: "Perkhidmatan tempahan perjalanan dan kelab",
  RESTRICTED_HIGH_RISK_EXAMPLE_5:
    "Penerbangan, Tambang Penerbangan dan Pelayaran",
  RESTRICTED_HIGH_RISK_EXAMPLE_6: "Perkongsian Masa",
  RESTRICTED_HIGH_RISK_EXAMPLE_7:
    "Kad telefon prabayar, perkhidmatan telefon dan telefon mudah alih",
  RESTRICTED_HIGH_RISK_EXAMPLE_8:
    "Tele-pemasaran, peralatan telekomunikasi dan jualan telefon",
  RESTRICTED_HIGH_RISK_EXAMPLE_9:
    "Perlindungan perampasan dan kecurian identiti",
  RESTRICTED_HIGH_RISK_HEADER: "Perniagaan Berisiko Tinggi seperti",
  RESTRICTED_IP_EXAMPLE_1: "Kandungan dan perkhidmatan dewasa",
  RESTRICTED_IP_EXAMPLE_2: "Barangan palsu atau tidak diluluskan",
  RESTRICTED_IP_EXAMPLE_3: "Penjana Esei",
  RESTRICTED_IP_EXAMPLE_4: "Perjudian",
  RESTRICTED_IP_EXAMPLE_5: "Pelanggaran harta intelektual atau hak proprietari",
  RESTRICTED_IP_EXAMPLE_6: "Produk atau perkhidmatan berperaturan atau haram",
  RESTRICTED_IP_HEADER:
    "Pelanggaran IP, produk dan perkhidmatan yang berperaturan atau haram seperti",
  RESTRICTED_OTHER_EXAMPLE_1: "Pengagregatan",
  RESTRICTED_OTHER_EXAMPLE_2:
    "Alatan dadah, bahan yang direka untuk meniru dadah terlarang dan farmaseutikal lain",
  RESTRICTED_OTHER_EXAMPLE_3: "Kedutaan, konsulat, lain-lain kerajaan asing",
  RESTRICTED_OTHER_EXAMPLE_4: "Pemasaran beberapa peringkat",
  RESTRICTED_OTHER_EXAMPLE_5:
    "Produk direkabentuk untuk menyahsulit, menyahkod atau menyesak isyarat",
  RESTRICTED_OTHER_EXAMPLE_6:
    "Aktiviti media sosial (seperti jualan pengikut, kesukaan dan sebagainya)",
  RESTRICTED_OTHER_EXAMPLE_7: "Kredit permainan video atau dunia maya",
  RESTRICTED_OTHER_EXAMPLE_8:
    "Mana-mana perniagaan atau organisasi yang a. terlibat dalam, menggalak, mempromosi atau merai keganasan yang melanggar undang-undang atau kecederaan fizikal kepada manusia atau harta benda, atau b. terlibat dalam, menggalak, mempromosi atau merai keganasan yang melanggar undang-undang terhadap mana-mana golongan berdasarkan bangsa, agama, ketidakupayaan, jantina, orientasi seksual, tempat asal, atau sebarang ciri-ciri hukum alam yang lain.",
  RESTRICTED_OTHER_HEADER: "Lain-lain produk atau perkhidmatan termasuk",
  RESTRICTED_QUESTIONS:
    "Jika anda mempunyai sebarang soalan mengenai sekiranya perniagaan anda boleh disokong melalui produk kutipan pembayaran ipaymy, sila \u003ca\u003ehubungi kami.\u003c/a\u003e",
  RESTRICTED_UNFAIR_EXAMPLE_1: "Skim cepat kaya",
  RESTRICTED_UNFAIR_EXAMPLE_2:
    "Platform kemudahan penerbitan dan pemindaan isi kandungan",
  RESTRICTED_UNFAIR_EXAMPLE_3: "Perkhidmatan tiada tambahan nilai",
  RESTRICTED_UNFAIR_HEADER:
    "Amalan-amalan tidak adil, pemangsa atau mengelirukan seperti",
  RETRY_BUTTON: "Cuba semula",
  REVIEW_DETAILS_HERO: "Semak Butiran dan Hantar Permintaan",
  SALARIES_BUTTON: "Penggajian",
  SALARY_LABEL: "Gaji",
  SAVE_AND_EXIT_BUTTON: "Simpan dan Keluar",
  SAVE_BUTTON: "Simpan Perubahan",
  SAVING_AND_EXITING_BUTTON: "Menyimpan dan keluar...",
  SCHEDULED_ACTIVE_PAYMENTS_BUTTON: "Pembayaran Aktif",
  SCHEDULED_AMOUNT_HEADER: "Amaun",
  SCHEDULED_ARCHIVED_PAYMENTS_BUTTON: "Pembayaran Yang Diarkibkan",
  SCHEDULED_AUTHORIZED_BODY:
    "Sebagai sebahagian daripada proses kelulusan, anda mungkin nampak {br}satu {render} caj dalam proses pada penyata bank anda.{br}Sila ambil perhatian bahawa ini adalah sementara dan akan {br}dipulangkan secara automotik.",
  SCHEDULED_AUTHORIZED_HERO: "Yeah! Pembayaran telah diluluskan.",
  SCHEDULED_AUTHORIZED_INSTRUCTIONS:
    "Anda akan menerima \u003cb\u003esatu emel pengesahan\u003c/b\u003e dan \u003cb\u003eresit\u003c/b\u003e untuk amaun penuh dalam masa \u003cb\u003e30 minit\u003c/b\u003e lagi.",
  SCHEDULED_BANK_LABEL: "Bank",
  SCHEDULED_CANCEL_HERO:
    "Adakah anda pasti anda ingin membatalkan jadual pembayaran ini?",
  SCHEDULED_CANCEL_INSTRUCTIONS:
    "Anda mungkin tidak dapat menikmati kadar diskaun ini lagi jika anda membatalkannya.",
  SCHEDULED_CARD_HEADER: "Kad",
  SCHEDULED_CATEGORY_HEADER: "Kategori",
  SCHEDULED_CHARGE_DATE_HEADER: "Tukar Tarikh",
  SCHEDULED_COPY_TOOLTIP:
    "Tarikh mula dan tarikh akhir adalah hari pertama dan hari terakhir benefisiari anda akan menerima wang tersebut. Kad anda akan dikenakan caj 1 hari bekerja sebelum tarikh-tarikh ini.",
  SCHEDULED_DESCRIPTION: "Papar dan uruskan pembayaran berjaduak anda",
  SCHEDULED_EDIT_ALL_HERO:
    "Edit Butiran Pembayaran untuk \u003cb\u003eSemua Pembayaran Akan Datang\u003c/b\u003e",
  SCHEDULED_EDIT_COUPON_LABEL: "Kod Kupon",
  SCHEDULED_EDIT_ERROR_MESSAGE:
    "Butiran pembayaran anda telah gagal untuk dikemas kini. Sila cuba lagi.",
  SCHEDULED_EDIT_ONE_HERO:
    "Edit Butiran Pembayaran untuk \u003cb\u003eSatu Pembayaran Ini\u003c/b\u003e",
  SCHEDULED_EDIT_SELECT_CARD: "Pilih Kad",
  SCHEDULED_END_HEADER: "Tarikh Akhir",
  SCHEDULED_FEE_HEADER: "Yuran",
  SCHEDULED_FREQUENCY_HEADER: "Kekerapan",
  SCHEDULED_HERO: "PEMBAYARAN BERJADUAL",
  SCHEDULED_MENU_ITEM: "Berjadual",
  SCHEDULED_MULTIPLE_RECIPIENTS: "Berbilang Penerima",
  SCHEDULED_NEW_PAYMENT_BUTTON: "Buat Satu Pembayaran Baru",
  SCHEDULED_NEXT_PAYMENT_BUTTON: "Lihat Pembayaran Seterusnya",
  SCHEDULED_NOTE_SUPPLIER_HEADER: "Nota kepada Pembekal",
  SCHEDULED_NO_BUTTON: "Tidak",
  SCHEDULED_PAYEE_INVOICE_LABEL: "Pembekal",
  SCHEDULED_PAYEE_RENTAL_LABEL: "Alamat Hartanah",
  SCHEDULED_PAYEE_SALARY_LABEL: "Pekerja",
  SCHEDULED_PAYMENTS_BUTTON: "Pembayaran Berjadual",
  SCHEDULED_PAYMENT_DETAILS_HERO: "Butiran Pembayaran",
  SCHEDULED_PAYOUT_DATE_HEADER: "Tarikh Pembayaran",
  SCHEDULED_RECIPIENT_HEADER: "Penerima",
  SCHEDULED_START_HEADER: "Tarikh Mula",
  SCHEDULED_STATUS_HEADER: "Status",
  SCHEDULED_TOTAL_HEADER: "Jumlah",
  SCHEDULED_UPDATED_MESSAGE:
    "Butiran pembayaran anda telah berjaya dikemas kini.",
  SCHEDULED_YES_BUTTON: "Ya",
  SCHEDULE_END_DATE_LABEL: "Tarikh Akhir",
  SCHEDULE_FREQUENCY_LABEL: "Kekerapan",
  SCHEDULE_FREQUENCY_MONTHLY_ITEM: "Bulanan",
  SCHEDULE_FREQUENCY_PLACEHOLDER: "Pilih Kekerapan Jadual",
  SCHEDULE_FREQUENCY_WEEKLY_ITEM: "Mingguan",
  SCHEDULE_PAYMENT_BUTTON: "Jadual Pembayaran",
  SCHEDULE_PAYMENT_HERO: "\u003cb\u003eJADUAL\u003c/b\u003e PEMBAYARAN",
  SCHEDULE_START_DATE_LABEL: "Tarikh Mula",
  SCHEDULE_TYPE_LABEL: "Jenis Jadual",
  SCHEDULE_TYPE_ONE_TIME_ITEM: "Pembayaran Satu Kali",
  SCHEDULE_TYPE_RECURRING_ITEM: "Pembayaran Berulang",
  SEARCH_BANK_NO_RESULTS:
    "Tiada bank yang sepadan. Cuba carian lain, atau hubungi kami untuk mendapatkan sokongan.",
  SEARCH_BANK_PLACEHOLDER: "Nama Bank, Cawangan, atau BSB",
  SEARCH_COMPANY_NAME_LABEL: "Pencarian Nama Syarikat",
  SEARCH_COMPANY_NAME_PLACEHOLDER: "Nama Syarikat",
  SEARCH_COMPANY_NO_RESULTS:
    "Syarikat tidak sepadan. Cuba carian lain, atau hubungi kami untuk mendapatkan sokongan.",
  SELECT_ACCOUNT_CREATED: "(Akaun telah dicipta.)",
  SELECT_COMPANY_INSTRUCTIONS:
    "Pilih syarikat mana yang ingin anda membuat kutipan pembayaran",
  SELECT_COMPANY_PLACEHOLDER: "Pilih Syarikat",
  SELECT_CUSTOMER_HERO: "PILIH PELANGGAN",
  SELECT_EMPLOYEE_HERO: "PILIH PEKERJA ANDA",
  SELECT_PROPERTY_HERO: "PILIH HARTANAH ANDA",
  SEND_BUTTON: "Hantar",
  SEND_BUTTON_TOOLTIP: "Sila masukkan semua butiran yang diminta",
  SEND_INVOICE_HERO: "HANTAR INVOIS",
  SEND_PASSWORD_REQUEST_BUTTON: "Hantar Permintaan Arahan Kata Laluan",
  SETTINGS_ACCOUNT_AU_DESCRIPTION:
    "Ini mestilah akaun bank di Australia yang menerima AUD.",
  SETTINGS_ACCOUNT_HK_DESCRIPTION:
    "Ini mestilah akaun bank di Hong Kong yang menerima HKD.",
  SETTINGS_ACCOUNT_MY_DESCRIPTION:
    "Ini mestilah satu akaun bank di Malaysia yang menerima MYR.",
  SETTINGS_ACCOUNT_NUMBER: "Nombor Akaun",
  SETTINGS_ACCOUNT_SG_DESCRIPTION:
    "Ini mestilah satu akaun bank di Singapura yang menerima SGD.",
  SETTINGS_BANK_LABEL: "Bank",
  SETTINGS_BANK_STATEMENT_LABEL: "Penyata Bank",
  SETTINGS_EMAIL_DESCRIPTION:
    "Sila berikan emel di mana semua maklumat mengenai pembayaran yang dibuat dalam akaun anda dihantar",
  SETTINGS_FEE_CHANGE_HERO:
    "Perubahan pada struktur yuran akan hanya dikenakan kepada permintaan baru.",
  SETTINGS_FEE_CHANGE_INSTRUCTIONS:
    "Yuran bagi permintaan pembayaran yang belum selesai ini tidak akan dikemas kini.",
  SETTINGS_FEE_CHANGE_OKAY_BUTTON: "Okey",
  SETTINGS_HERO: "Penetapan Akaun",
  SETTINGS_MENU_ITEM: "Tetapan",
  SETTINGS_SUCCESS: "Tetapan akaun anda telah berjaya dikemas kini.",
  SETTING_BANK_CHANGE_MESSAGE:
    "Bagi mengemas kini butiran akaun bank anda, sila hubungi kami di \u003ca\u003esupport@ipaymy.com\u003c/a\u003e",
  SETTING_BANK_DESCRIPTION: "Akaun bank di mana anda ingin wang didepositkan",
  SIGNIN_BUTTON: "Daftar masuk",
  SIGNIN_ERROR: "Kombinasi emel/kata laluan tidak sah, sila cuba lagi.",
  SIGNIN_HERO: "daftar masuk",
  SIGNIN_LINK: "daftar masuk",
  SIGNIN_SIGNUP_OPTION: "atau {SIGNIN_LINK}",
  SIGNUP_BUSINESS: "Perniagaan",
  SIGNUP_BUTTON: "Daftar",
  SIGNUP_CLOSE: "Tutup",
  SIGNUP_HERO: "Daftar",
  SIGNUP_PERSONAL: "Peribadi",
  SIGNUP_SIGNIN_OPTION: "atau \u003cstyling1\u003edaftar\u003c/styling1\u003e",
  SIGNUP_SUCCESSFUL: "Pendaftaran Berjaya",
  SIGNUP_THANKS:
    "Terima kasih kerana mendaftar Satu emel telah dihantar kepada anda dengan butiran pengesahan lanjut. Semak peti masuk anda untuk mengaktifkan akaun anda sekarang!",
  SMS_MENU_ITEM: "Permintaan SMS",
  SMS_REQUEST_BUTTON: "Permintaan SMS",
  SMS_REQUEST_HEADER: "Masukkan Butiran Pelanggan Anda",
  SMS_REQUEST_HERO: "MASUKKAN BUTIRAN DAN HANTAR",
  SMS_REQUEST_HERO_PAYMENT_INVOICE: "INVOIS",
  SMS_REQUEST_HERO_PAYMENT_REQUEST: "PERMINTAAN PEMBAYARAN",
  SMS_SENDER_INSTRUCTIONS:
    "Maklumat in akan ada dalam permintaan SMS yang akan diterima oleh pelanggan anda",
  SMS_SENDER_INSTRUCTIONS_EXTRA:
    "Nyatakan ID penghantar akan dipaparkan kepada pelanggan anda di dalam permintaan SMS, kami sarankan menggunakan nama yang dikenali oleh pelanggan anda, seperti nama perniagaan anda.",
  SMS_SENDER_LABEL: "ID Penghantar SMS",
  SMS_SENDER_PLACEHOLDER: "ID Penghantar SMS",
  SMS_SENDER_SECTION: "ID Penghantar SMS",
  SPLIT_FEE_HEADER: "Bagaimana Anda Ingin Membahagikan Yuran",
  SPLIT_FEE_INSTRUCTIONS:
    "Tarik gelangsar untuk menentukan berapa banyak akan dibayar oleh syarikat anda",
  SPLIT_FEE_SECTION: "Bahagikan Yuran Antara Anda dan Pelanggan Anda",
  STATEMENT_DESCRIPTION_PLACEHOLDER: "Keterangan Penyata",
  STATEMENT_DESCRIPTOR_LABEL: "Keterangan Penyata",
  STATE_LABEL: "Negeri/Wilayah",
  STATE_PLACEHOLDER: "Negeri/Wilayah",
  STATUS_DRAFT_LABEL: "Draf",
  STATUS_SCHEDULED_LABEL: "Berjadual",
  STEP_1_OF_2: "Langkah 1 daripada 2",
  STEP_1_OF_3: "Langkah 1 daripada 3",
  STEP_1_OF_4: "Langkah 1 daripada 4",
  STEP_2_OF_2: "Langkah 2 daripada 2",
  STEP_2_OF_3: "Langkah 2 daripada 3",
  STEP_2_OF_4: "Langkah 2 daripada 4",
  STEP_3_OF_3: "Langkah 3 daripada 3",
  STEP_3_OF_4: "Langkah 3 daripada 4",
  STEP_4_OF_4: "Langkah 4 daripada 4",
  STRIPE_ERROR_AMOUNT_TOO_LARGE:
    "Amaun yang dinyatakan adalah lebih besar daripada amaun maksima yang dibenarkan. Gunakan amaun yang lebih kecil dan cuba semula",
  STRIPE_ERROR_CARD_DETAIL_INVALID:
    "Butiran kad tidak sah, sila berikan nombor kad yang sah.",
  STRIPE_ERROR_CARD_NOT_SUPPORTED:
    "Kad ini tidak sah untuk pembelian jenis ini.  Sila cuba kad lain atau hubungi pengeluar kad untuk maklumat lanjut.",
  STRIPE_ERROR_CARD_NOT_SUPPORT_001:
    "001. Kad anda tidak menyokong jenis pembayaran ini. Sila hubungi sokongan pelanggan untuk maklumat lebih lanjut.",
  STRIPE_ERROR_CARD_NOT_SUPPORT_002:
    "002. Kad anda tidak menyokong jenis pembayaran ini. Sila hubungi sokongan pelanggan untuk maklumat lebih lanjut.",
  STRIPE_ERROR_CARD_UNAUTHORIZED_APPROVE_WITH_ID:
    "Transaksi tidak dapat diluluskan. Sila cuba kad lain atau hubungi pengeluar kad untuk maklumat lanjut.",
  STRIPE_ERROR_CARD_UNAUTHORIZED_INVALID_ACCT:
    "Transaksi tidak dapat diluluskan. Sila cuba kad lain atau hubungi pengeluar kad untuk maklumat lanjut.",
  STRIPE_ERROR_CARD_UNAUTHORIZED_NEW_ACCT_INFO_AVAIL:
    "Transaksi tidak dapat diluluskan. Sila cuba kad lain atau hubungi pengeluar kad untuk maklumat lanjut.",
  STRIPE_ERROR_CARD_UNAUTHORIZED_PROCESSED_ERROR:
    "Transaksi tidak dapat diluluskan. Sila cuba kad lain atau hubungi pengeluar kad untuk maklumat lanjut.",
  STRIPE_ERROR_CARD_UNAUTHORIZED_REENTER_TXN:
    "Transaksi tidak dapat diluluskan. Sila cuba kad lain atau hubungi pengeluar kad untuk maklumat lanjut.",
  STRIPE_ERROR_CARD_UNAUTHORIZED_REVOCATION:
    "Transaksi tidak dapat diluluskan. Sila cuba kad lain atau hubungi pengeluar kad untuk maklumat lanjut.",
  STRIPE_ERROR_CARD_UNAUTHORIZED_REVOCATIONS:
    "Transaksi tidak dapat diluluskan. Sila cuba kad lain atau hubungi pengeluar kad untuk maklumat lanjut.",
  STRIPE_ERROR_CARD_UNAUTHORIZED_SERVICE_NOT_ALLOWED:
    "Transaksi tidak dapat diluluskan. Sila cuba kad lain atau hubungi pengeluar kad untuk maklumat lanjut.",
  STRIPE_ERROR_CARD_UNAUTHORIZED_STOP_PAYMENT_ORDER:
    "Transaksi tidak dapat diluluskan. Sila cuba kad lain atau hubungi pengeluar kad untuk maklumat lanjut.",
  STRIPE_ERROR_CARD_UNAUTHORIZED_TRY_AGAIN_LATER:
    "Transaksi tidak dapat diluluskan. Sila cuba kad lain atau hubungi pengeluar kad untuk maklumat lanjut.",
  STRIPE_ERROR_CARD_UNAUTHORIZED_TXN_NOT_ALLOWED:
    "Transaksi tidak dapat diluluskan. Sila cuba kad lain atau hubungi pengeluar kad untuk maklumat lanjut.",
  STRIPE_ERROR_CHARGE_ALREADY_REFUNDED:
    "Caj yang anda cuba untuk pulangkan telah dipulangkan.",
  STRIPE_ERROR_CHARGE_DISPUTED:
    "Caj yang anda cuba untuk pulangkan telah dicaj semula.",
  STRIPE_ERROR_DECLINED:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  STRIPE_ERROR_DECLINED_CALL_ISSUER:
    "Traksaksi telah ditolak. Sila hubungi pengeluar kad anda untuk maklumat lanjut.",
  STRIPE_ERROR_DECLINED_CARD_VELOCITY_EXCEEDED:
    "Traksaksi telah ditolak. Sila hubungi pengeluar kad anda untuk maklumat lanjut.",
  STRIPE_ERROR_DECLINED_DO_NOT_TRY_AGAIN:
    "Traksaksi telah ditolak. Sila hubungi pengeluar kad anda untuk maklumat lanjut.",
  STRIPE_ERROR_DECLINED_LOST_CARD:
    "Traksaksi telah ditolak. Sila hubungi pengeluar kad anda untuk maklumat lanjut.",
  STRIPE_ERROR_DECLINED_PICKUP_CARD:
    "Traksaksi telah ditolak. Sila hubungi pengeluar kad anda untuk maklumat lanjut.",
  STRIPE_ERROR_DECLINED_RESTRICTED_CARD:
    "Traksaksi telah ditolak. Sila hubungi pengeluar kad anda untuk maklumat lanjut.",
  STRIPE_ERROR_DECLINED_SECURITY_VIOLATION:
    "Traksaksi telah ditolak. Sila hubungi pengeluar kad anda untuk maklumat lanjut.",
  STRIPE_ERROR_DECLINED_STOLEN_CARD:
    "Traksaksi telah ditolak. Sila hubungi pengeluar kad anda untuk maklumat lanjut.",
  STRIPE_ERROR_DO_NOT_HONOR:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  STRIPE_ERROR_DO_NOT_HONOR_GENERIC_DECLINED:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  STRIPE_ERROR_DO_NOT_HONOR_NO_ACTION_TAKEN:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  STRIPE_ERROR_DUPLICATE_TRANSACTION:
    "Satu transaksi dengan amaun dan maklumat kad kredit yang sama telah dihantar baru-baru ini. Transaksi dibatal bagi memastikan tiada pembayaran yang sama terjadi.",
  STRIPE_ERROR_EXCEEDING_WITHDRAW_LIMIT:
    "Transaksi ini gagal keran melebihi had pengeluaran. Sila guna kad yang lain.",
  STRIPE_ERROR_EXPIRED_CARD:
    "Tarikh tamat tempoh kad tidak tepat. Semak tarikh tamat tempoh kad atau guna kad yang lain.",
  STRIPE_ERROR_FRAUDULENT:
    "Traksaksi telah ditolak. Sila hubungi pengeluar kad anda untuk maklumat lanjut.",
  STRIPE_ERROR_INCORRECT_CVC:
    "CVC/CVV kad tidak tepat. Semak kod keselamatan kad atau guna kad lain.",
  STRIPE_ERROR_INCORRECT_NUMBER:
    "Nombor kad tidak tepat. Semak nombor kad atau guna kad yang lain.",
  STRIPE_ERROR_INSUFFICIENT_FUND:
    "Pembayaran gagal kerana kredit tidak mencukupi. Anda mungkin telah mencapai had kredit maksimum pada kad kredit ini. Sila sesuaikan jumlah pembayaran atau gunakan kad kredit lain.",
  STRIPE_ERROR_INVALID_CVC:
    "CVC/CVV kad tidak tepat. Semak kod keselamatan kad atau guna kad lain.",
  STRIPE_ERROR_INVALID_EXPIRY_MONTH:
    "Tarikh tamat tempoh kad tidak tepat. Semak tarikh tamat tempoh kad atau guna kad yang lain.",
  STRIPE_ERROR_INVALID_EXPIRY_YEAR:
    "Tarikh tamat tempoh kad tidak tepat. Semak tarikh tamat tempoh kad atau guna kad yang lain.",
  STRIPE_ERROR_ISSUER_NOT_AVAILABLE:
    "Transaksi tidak dapat diluluskan. Sila cuba semula atau guna kad yang lain.  Jika gagal sila hubungi pengeluar kad anda.",
  STRIPE_ERROR_NOT_PERMITTED:
    "Kad ini tidak sah untuk pembelian jenis ini.  Sila cuba kad lain atau hubungi pengeluar kad untuk maklumat lanjut.",
  STRIPE_ERROR_UNSUPPORTED_CURRENCY:
    "Kad tidak menyokong mata wang yang dinyatakan.  Sila cuba kad lain atau hubungi pengeluar kad untuk maklumat lanjut.",
  SUBMIT_BUTTON: "Hantar",
  SUBTOTAL_LABEL: "Jumlah kecil",
  SUCCESS_ADD_ACCOUNT:
    "Log masuk ke akaun anda yang lain atau daftar yang baru.",
  SUCCESS_APPLIED_COUPON_REMOVED: "Kupon telah berjaya dibuang.",
  SUCCESS_CARD_CREATED: "Kad berjaya ditambah ke dalam dompet anda.",
  SUCCESS_CARD_DELETED: "Kad berjaya dipadam.",
  SUCCESS_CARD_EDITED: "Kad berjaya diedit dalam dompet anda.",
  SUCCESS_COMPANY_DELETED: "Syarikat telah berjaya dipadam",
  SUCCESS_COUPON_APPLIED: "Kupon anda telah digunakan.",
  SUCCESS_LOG_OUT: "Berjaya log keluar.",
  SUCCESS_PASSWORD_EDITED: "Kata laluan berjaya dikemas kini",
  SUCCESS_PAYEE_DELETED: "Penerima telah berjaya dipadam",
  SUCCESS_PAYEE_EDITED: "Penerima telah berjaya diedit",
  SUCCESS_PAYMENT_CANCELED: "Pembayaran berjaya dibatalkan",
  SUCCESS_PAYMENT_MARKED_AS_COMPLETE: "Berjaya Ditanda sebagai Lengkap",
  SUCCESS_RATE_EDITED: "Kadar dikemas kini",
  SUCCESS_SWITCH_ACCOUNT:
    "Log masuk ke akaun anda yang lain atau daftar yang baru.",
  SUGGESTED_BANK_ACCOUNT_LABEL: "Bank Yang Dicadangkan dan Nombor Akaun",
  SUPPLIERS_HERO: "PEMBEKAL-PEMBEKAL",
  SUPPLIERS_MENU_ITEM: "Pembekal-pembekal",
  SUPPLIER_ABN_HEADER: "ABN",
  SUPPLIER_ABN_LABEL: "Nombor Perniagaan Australia Pembekal (ABN)",
  SUPPLIER_ABN_PLACEHOLDER: "Nombor Perniagaan Australia Pembekal (ABN)",
  SUPPLIER_ACCOUNT_NUMBER_HEADER: "Nombor Akaun",
  SUPPLIER_ACCOUNT_NUMBER_PLACEHOLDER: "Nombor Akaun",
  SUPPLIER_ALREADY_EXISTS: "Pembekal telah wujud",
  SUPPLIER_BANK_ACCOUNT_LABEL: "Nombor Akaun",
  SUPPLIER_BANK_ACCOUNT_PLACEHOLDER: "Nombor Akaun Bank Pembekal",
  SUPPLIER_BANK_HEADER: "Bank",
  SUPPLIER_BANK_PLACEHOLDER: "Bank",
  SUPPLIER_BUSINESS_REGISTRATION_NUMBER_LABEL:
    "Nombor Pendaftaran Perniagaan Pembekal",
  SUPPLIER_BUSINESS_REGISTRATION_NUMBER_PLACEHOLDER:
    "Nombor Pendaftaran Perniagaan Pembekal",
  SUPPLIER_COLUMN: "Pembekal",
  SUPPLIER_COMPANY_NAME_LABEL: "Nama Syarikat Pembekal",
  SUPPLIER_COMPANY_NAME_PLACEHOLDER: "Nama Syarikat Pembekal",
  SUPPLIER_COUNTRY_HEADER: "Negara",
  SUPPLIER_CRN_HEADER: "BRN/CRN",
  SUPPLIER_CRN_LABEL: "Nombor Pendaftaran Syarikat (BRN/CRN) Pembekal",
  SUPPLIER_CRN_PLACEHOLDER: "Nombor Pendaftaran Syarikat (BRN/CRN) Pembekal",
  SUPPLIER_DELETE_HERO: "Adakah anda ingin memadam pembekal ini?",
  SUPPLIER_DELETE_INSTRUCTIONS:
    "Pembekal ini akan dikeluarkan dari pembekal anda",
  SUPPLIER_EMAIL_LABEL: "Alamat Emel Pembekal",
  SUPPLIER_EMAIL_PLACEHOLDER: "Alamat Emel Pembekal",
  SUPPLIER_HEADER: "Pembekal",
  SUPPLIER_NAME_LABEL: "Nama pembekal",
  SUPPLIER_NAME_PLACEHOLDER: "Nama pembekal",
  SUPPLIER_NOTE_LABEL: "Nota kepada pembekal",
  SUPPLIER_NOTE_PLACEHOLDER: "Nota kepada Pembekal",
  SUPPLIER_PLACEHOLDER: "cth. S1234567A Cukai Pendapatan",
  SUPPLIER_REGISTRATION_NUMBER_HEADER: "Nombor Pendaftaran",
  SUPPLIER_SCHEDULED_PAYMENT_HERO:
    "Anda perlu membatalkan pembayaran berkaitan sebelum mengedit butiran pembekal.",
  SUPPLIER_SCHEDULED_PAYMENT_INSTRUCTIONS:
    "Pembekal ini berkaitan dengan pembayaran berjadual.",
  SUPPLIER_UEN_HEADER: "UEN",
  SUPPLIER_UEN_LABEL: "Nombor Entiti Unik (UEN) Pembekal",
  SUPPLIER_UEN_PLACEHOLDER: "Nombor Entiti Unik (UEN) Pembekal",
  SUPPORT_MENU_ITEM: "Sokongan",
  SWIFT_CODE_LABEL: "Kod SWIFT",
  SWIFT_CODE_PLACEHOLDER: "Kod SWIFT",
  SYSTEM_UPGRADES:
    "Sistem kami pada masa sedang dalam peningkatan, pembayaran akan tersedia tidak lama lagi. Terima kasih di atas kerjasama anda",
  TABLE_ACTION: "Tindakan",
  TABLE_AMOUNT: "Amaun",
  TABLE_CARD: "Kad",
  TABLE_CHARGE_DATE: "Tukar Tarikh",
  TABLE_CUSTOMER: "Pelanggan",
  TABLE_DATE_REQUEST: "Tarikh Diminta",
  TABLE_DUE_DATE: "Tarikh cukup tempoh",
  TABLE_EMAIL: "Emel",
  TABLE_ESTIMATED_FEE: "Anggaran Yuran",
  TABLE_ESTIMATED_NET_AMOUNT: "Anggaran Amaun Bersih",
  TABLE_FREQUENCY: "Kekerapan",
  TABLE_INVOICE_NO: "No. Invois",
  TABLE_INVOICE_NUMBER: "Nombor Invois",
  TABLE_NOTE: "Nota kepada Penerima",
  TABLE_ORDER_NUMBER: "Nombor Tempahan",
  TABLE_PAYMENT_CANCELLED: "Pembayaran dibatalkan",
  TABLE_PAYMENT_DECLINED: "Pembayaran ditolak",
  TABLE_PAYOUT_DATE: "Tarikh Pembayaran",
  TABLE_RECEIPT_NUMBER: "Nombor Resit",
  TABLE_TYPE: "Jenis",
  TAX_BUTTON: "Cukai",
  TAX_REFERENCE_LABEL: "Nombor Rujukan Cukai",
  TAX_TYPE_LABEL: "dan Jenis Cukai",
  TENANCY_AGREEMENT:
    "\u003cspan\u003ePerjanjian Sewa\u003c/span\u003e mesti disertakan",
  TENANCY_BANK_ACCOUNT: "akaun bank",
  TENANCY_CONFIRM:
    "Saya mengesahkan bahawa saya membayar sewa secara terus ke tuan rumah seperti perjanjian sewaan saya.",
  TENANCY_LANDLORD_NAME: "Nama Tuan Rumah",
  TENANCY_RENTAL_AMOUNT: "Amaun Sewa",
  TERMS_LABEL: "Terma-terma",
  TEST_PARAMS: "Uji tanpa param",
  TOO_MANY_EMAIL_VERIFICATION_REQUESTS:
    "Terlalu banyak permintaan pengesahan emel",
  TOO_MANY_LOGIN_ATTEMPTS:
    "Anda telah mencapai bilangan maksima bagi cubaan log masuk. Sila tukar kata laluan anda dan cuba semula.",
  TOO_MANY_LOGIN_ATTEMPTS_2:
    "Anda telah mencapai amaun maksima bagi cubaan log masuk  Sila hubungi {contact_link} untuk bantuan.",
  TOO_MANY_MOBILE_PHONE_VERIFICATION_ERROR:
    "Terlalu banyak cubaan log masuk gagal, sila",
  TOP_ACCOUNT_ADD: "Tambah Akaun",
  TOP_ACCOUNT_CHANGE_PASSWORD: "Tukar Kata Laluan",
  TOP_ACCOUNT_MENU_BUSINESS: "Akaun Perniagaan ipaymy",
  TOP_ACCOUNT_MENU_MY_ACCOUNT: "Akaun Saya",
  TOP_ACCOUNT_MENU_PERSONAL: "Akaun Peribadi ipaymy",
  TOP_ACCOUNT_SIGN_OUT: "Daftar Keluar",
  TOP_COMPANIES_MENU_ADD_BUTTON: "Tambah Syarikat Baru",
  TOP_COMPANIES_MENU_HEADER: "Syarikat-syarikat Saya",
  TOP_COMPANIES_MENU_REGISTRATION_LABEL: "No. Pendaftaran",
  TOP_REFER_BUTTON:
    "\u003ctspan1\u003eRujuk\u003c/tspan1\u003e\u003ctspan2\u003e dan\u003c/tspan2\u003e\u003ctspan3\u003eDapatkan\u003c/tspan3\u003e",
  TOP_TRY_IPAYMY_BUSINESS: "Cuba ipaymy untuk Perniagaan",
  TOP_TRY_IPAYMY_PERSONAL: "Cuba ipaymy untuk Peribadi",
  TRY_AGAIN_BUTTON: "Cuba Lagi",
  TRY_IPAYMY_BUSINESS_CTA: "Cuba ipaymy untuk Perniagaan",
  TRY_IPAYMY_PERSONAL_CTA: "Cuba ipaymy",
  UEN_COLUMN: "UEN",
  UEN_PLACEHOLDER: "Nombor entiti unik (UEN)",
  UNAUTHORIZED_CARD:
    "Kad tidak dapat diluluskan. Sila cuba lagi atau gunakan kad yang lain.",
  UNAVAILABLE_EMAIL_USED:
    "Sila masukkan alamat e-mel yang anda gunakan untuk mendaftar akaun ipaymy anda.",
  UNIONPAY_COMMON_FEE_LABEL: "China UnionPay",
  UNIONPAY_HKD_FEE_LABEL: "China UnionPay (HKD)",
  UNIONPAY_LABEL: "China UnionPay",
  UNIONPAY_USD_FEE_LABEL: "China UnionPay (USD)",
  UNIT_NUMBER_LABEL: "Nombor Unit",
  UNIT_NUMBER_PLACEHOLDER: "Nombor Unit",
  UPDATE_BUTTON: "Kemas kini",
  UPDATE_COMPANY_ERROR: "Gagal mengemas kini syarikat",
  UPDATE_PASSWORD_BUTTON: "Kemas Kini Kata Laluan",
  UPLOAD_CSV_HERO: "Muat naik Fail CSV",
  UPLOAD_CSV_INSTRUCTIONS:
    "Sila pastikan Bank dan Nombor Akaun penerima yang diberi untuk pembayaran adalah tepat. Ini adalah butiran utama yang akan digunakan untuk memproses pembayaran. Jika memuat naik menggunakan templat CSV yang disediakan, sila sahkan semua nombor akaun telah dimasukkan dengan betul - khususnya sebarang nombor akaun yang bermula dengan sifar (0).",
  UPLOAD_DOCUMENT_APPLIES_ALL_SELECTION: "Semua",
  UPLOAD_DOCUMENT_APPLIES_LABEL: "Terpakai pada",
  UPLOAD_DOCUMENT_COLLECTED_ACCOUNT: "Tambahkan Dokumen KYC",
  UPLOAD_FILE_LABEL: "Muat naik fail...",
  UPLOAD_ID_HERO_AU: "Muat Naik Gambar ID Anda",
  UPLOAD_ID_HERO_MY:
    "Muat naik foto anda yang memegang kad pengenal IC dan kedua-dua dokumen rasmi anda",
  UPLOAD_ID_INSTRUCTIONS_AU:
    "Untuk tujuan pematuhan dan anti pencucian wang, dan untuk melindungi akaun anda, kami diminta untuk mengesahkan dokumen identiti peribadi anda sebelum meneruskan pembayaran pertama anda. ID yang sah adalah pasport, lesen memandu, atau kad pengenalan semasa.",
  UPLOAD_ID_INSTRUCTIONS_MY:
    "Untuk tujuan pematuhan dan pencegahan penggubahan wang haram, dan untuk keselamatan akaun anda, kami perlu untuk mengesahkan dokumen identiti peribadi anda sebelum meneruskan pembayaran pertama anda.",
  UPLOAD_ID_LIST_1_AU: "Sila muat naik yang berikut:",
  UPLOAD_ID_LIST_1_MY: "Sila muat naik yang berikut:",
  UPLOAD_ID_LIST_2_AU:
    "1. Foto yang jelas dan fokus di bahagian depan dan belakang ID anda yang sah",
  UPLOAD_ID_LIST_2_MY:
    "1. Foto bahagian hadapan dan belakang Kad Pengenalan yang jelas",
  UPLOAD_ID_LIST_3_AU:
    "2. Foto dimana anda yang memegang dokumen ID anda yang jelas. Maklumat-maklumat dalam dokumen ID dan wajah anda mesti kelihatan dengan jelas.",
  UPLOAD_ID_LIST_3_MY:
    "2. Foto yang jelas di mana anda memegang kad pengenalan anda. Maklumat Kad Pengenalan dan wajah anda mesti kelihatan dengan jelas.",
  UPLOAD_ID_LIST_4_AU:
    "Untuk kelulusan akaun, butiran ID anda mesti sepadan dengan butiran kad yang ditambahkan.",
  UPLOAD_ID_LIST_4_MY:
    "Untuk kelulusan akaun, butiran Kad Pengenalan anda mesti sepadan dengan butiran kad kredit yang dimasukkan.",
  UPLOAD_ID_PHOTO_BACK_AU: "Foto ID Belakang",
  UPLOAD_ID_PHOTO_BACK_MY: "Foto bahagian belakang Kad Pengenalan",
  UPLOAD_ID_PHOTO_FRONT_AU: "Foto ID Depan",
  UPLOAD_ID_PHOTO_FRONT_MY: "Foto bahagian hapadan Kad Pengenalan",
  UPLOAD_ID_PHOTO_HOLDING_AU: "Foto dimana memegang dokumen ID anda",
  UPLOAD_ID_PHOTO_HOLDING_MY:
    "Foto yang jelas di mana anda memegang kad pengenalan anda.",
  UPLOAD_INVOICE_BUTTON: "Muat naik Invois",
  UPLOAD_INVOICE_LABEL:
    "Muat naik invois untuk dihantar kepada pelanggan (Pilihan)",
  UPLOAD_INVOICE_MENU_ITEM: "Muat naik Invois",
  UPLOAD_ORDER_INVOICE_BUTTON: "Muat Naik Invois Tempahan",
  UPLOAD_ORDER_MENU_ITEM: "Muat Naik Invois Tempahan",
  USD_SALARY_ACCEPT_BUTTON: "Terima",
  USD_SALARY_CHANGE_BUTTON: "Tukar ke HKD",
  USD_SALARY_INSTRUCTIONS:
    "Untuk penyelesaian yang dibuat dalam USD, anda mungkin tertakluk pada yuran pengiriman ke dalam oleh bank anda.{br}Amaun ini berbeza-beza mengikut bank.",
  VERIFICATION_LINK_EXPIRED: "Link pengesahan telah tamat tempoh",
  VERIFY_EMAIL_BUTTON: "Sahkan Alamat Emel",
  VERIFY_EMAIL_BUTTON_INSTRUCTIONS:
    "Jika butang di atas tidak berfungsi, sila klik link di bawah atau salin dan tampal link ke dalam pelayar web",
  VERIFY_EMAIL_EXPIRY:
    "Emel pengesahan ini sah untuk {{.ExpirationHours}} jam sahaja.",
  VERIFY_EMAIL_HERO: "Sahkan Alamat Emel",
  VERIFY_EMAIL_INSTRUCTIONS:
    "Helo, {{.FirstName}}! Terima kasih kerana mendaftar! Sila klik pada butang di bawah untuk mengesahkan alamat emel anda.",
  VERIFY_EMAIL_QUESTIONS:
    "Jika sekiranya anda mempunyai sebarang persoalan mengenai transaksi ini, sila hubungi kami di",
  VERIFY_EMAIL_SUBJECT: "Selamat Datang! Lengkapkan pendaftaran anda",
  VIEW_DETAILS_BUTTON: "Lihat Butiran",
  VIEW_DETAILS_TOOLTIP: "Lihat Butiran",
  VIOLATE_COUPON_START_DATE:
    "Sila jadualkan pembayaran anda dengan tarikh caj tidak lewat daripada %s.",
  VISA_LABEL: "Visa dan",
  VISA_MASTERCARD_FEE_LABEL: "Visa dan Mastercard",
  WALLET_DESCRIPTION: "Lihat, urus kad sedia ada atau tambah yang baru.",
  WALLET_HERO: "DOMPET SAYA",
  WALLET_MENU_ITEM: "Dompet Saya",
  WALLET_NEW_CARD_BUTTON: "Tambah Kad Baru",
  WALLEX_QUOTE_INVALID_FIELD: "Medan ini mengandungi data tidak sah.",
  WP_3DS_DECLINED_ERROR:
    "Ralat telah dikembalikan oleh pembekal pengesahan kami.",
  WP_CSE_ERROR_101: "Nombor kad adalah wajib",
  WP_CSE_ERROR_102:
    "Nombor kad hendaklah mengandungi antara 12 dan 20 aksara angka",
  WP_CSE_ERROR_103: "Nombor kad yang dimasukkan tidak sah",
  WP_CSE_ERROR_201: "Kod keselamatan tidak sah",
  WP_CSE_ERROR_301: "Bulan luput tidak termasuk",
  WP_CSE_ERROR_302: "Bulan luput mesti mengandungi tepat 2 nombor",
  WP_CSE_ERROR_303: "Bulan luput hendaklah antara 01 dan 12",
  WP_CSE_ERROR_304: "Tahun luput tidak termasuk",
  WP_CSE_ERROR_305: "Tahun luput mesti mengandungi tepat 4 nombor",
  WP_CSE_ERROR_306:
    "Bulan luput dan tahun luput bersama mestilah tarikh pada masa hadapan",
  WP_CSE_ERROR_401: "Nama pemegang kad adalah wajib",
  WP_CSE_ERROR_402:
    "Nama pemegang kad tidak boleh melebihi tiga puluh (30) aksara",
  WP_DO_NOT_HONOR:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  WP_ERROR_3DS_FAILED:
    "Transaksi tidak dapat diluluskan. Sila cuba kad lain atau hubungi pengeluar kad untuk maklumat lanjut.",
  WP_ERROR_3DS_NOT_SUPPORT:
    "Pembayaran yang dibuat pada kad ini tidak disokong oleh ipaymy. Anda boleh  menghubungi kami untuk maklumat lanjut.",
  WP_ERROR_DO_NOT_HONOR:
    "Sila semak butiran kad anda atau gunakan kad yang lain. Untuk maklumat lanjut, hubungi pengeluar kad anda.",
  WP_INTERNAL_TECHNICAL_ERROR:
    "Alamak! Pembayaran gagal. Nampaknya ada masalah teknikal dengan permintaan pembayaran anda. Sila hubungi kami melalui support@ipaymy.com.",
  WP_REFUSED_ERROR_0: "Diluluskan",
  WP_REFUSED_ERROR_1:
    "Rujuk kepada pengeluar kad ATAU Dirujuk, Pusat Kebenaran Panggilan",
  WP_REFUSED_ERROR_10: "Kelulusan Separa",
  WP_REFUSED_ERROR_1044: "Kelulusan, simpan semak dahulu",
  WP_REFUSED_ERROR_1045: "Semak OK, tiada penukaran",
  WP_REFUSED_ERROR_1046: "RTTN tidak sah",
  WP_REFUSED_ERROR_1047: "Jumlah lebih besar daripada had",
  WP_REFUSED_ERROR_1048: "Item tidak berbayar, NEG gagal",
  WP_REFUSED_ERROR_1049: "Nombor cek pendua",
  WP_REFUSED_ERROR_1050: "ralat MICR",
  WP_REFUSED_ERROR_1051: "Terlalu banyak cek",
  WP_REFUSED_ERROR_1198: "Majukan kepada penerbit",
  WP_REFUSED_ERROR_12: "Transaksi tidak sah",
  WP_REFUSED_ERROR_1201: "Majukan kepada penerbit",
  WP_REFUSED_ERROR_1263: "Tidak dapat memberi kebenaran",
  WP_REFUSED_ERROR_1295: "Tidak diketahui",
  WP_REFUSED_ERROR_13:
    "Jumlah tidak sah ATAU Jumlah tidak sah (limpahan penukaran mata wang)",
  WP_REFUSED_ERROR_14:
    "Nombor kad tidak sah ATAU Nombor akaun tidak sah (tiada nombor sedemikian)",
  WP_REFUSED_ERROR_15: "Pengeluar tidak sah",
  WP_REFUSED_ERROR_19: "Masukkan semula transaksi",
  WP_REFUSED_ERROR_2: "Rujuk kepada pengeluar kad, syarat khas",
  WP_REFUSED_ERROR_20:
    "RALAT ATAU Tiada tindakan diambil (tidak dapat berundur terlebih dahulu)",
  WP_REFUSED_ERROR_25: "Tidak dapat mengesan rekod dalam fail atau akaun",
  WP_REFUSED_ERROR_28: "Fail tidak tersedia buat sementara waktu",
  WP_REFUSED_ERROR_3:
    "Pedagang tidak sah ATAU Pedagang atau pembekal perkhidmatan tidak sah",
  WP_REFUSED_ERROR_30: "Ralat format ATAU RALAT",
  WP_REFUSED_ERROR_34:
    "Kami menghadapi masalah dengan kad anda. Sila cuba kad lain, atau hubungi kami di support@ipaymy.com.",
  WP_REFUSED_ERROR_39: "Tiada akaun kredit",
  WP_REFUSED_ERROR_397: "Amaun surcaj tidak dibenarkan pada Visa",
  WP_REFUSED_ERROR_398: "Surcaj tidak disokong",
  WP_REFUSED_ERROR_4: "Tangkap kad ATAU Ditolak, Kekalkan Kad",
  WP_REFUSED_ERROR_41: "Kad hilang ATAU kad Pickup (kad hilang)",
  WP_REFUSED_ERROR_43: "Kad curi ATAU kad Pickup (kad curi)",
  WP_REFUSED_ERROR_442:
    "Kod Pengenalan Institusi Pemeroleh dalam mesej permintaan tidak didaftarkan di CAFIS",
  WP_REFUSED_ERROR_443: "Ralat Sistem CAFIS. Cuba lagi",
  WP_REFUSED_ERROR_444: "Sistem pemeroleh sibuk. Cuba lagi",
  WP_REFUSED_ERROR_445: "Ralat sistem pemeroleh. Cuba lagi",
  WP_REFUSED_ERROR_446: "Sistem pemeroleh telah ditutup. Cuba lagi",
  WP_REFUSED_ERROR_447: "Ralat Sistem CAFIS. Cuba lagi",
  WP_REFUSED_ERROR_448: "Format pengekodan haram. Cuba lagi",
  WP_REFUSED_ERROR_449: "Ralat Sistem CAFIS. Cuba lagi",
  WP_REFUSED_ERROR_450: "Ralat Sistem CAFIS. Cuba lagi",
  WP_REFUSED_ERROR_451: "Mesej nasihat sudah diterima",
  WP_REFUSED_ERROR_452:
    "CAFIS mengesan tamat masa apabila ia menghantar mesej kepada pemeroleh. Cuba lagi",
  WP_REFUSED_ERROR_453: "Ralat Sistem CAFIS. Cuba lagi",
  WP_REFUSED_ERROR_454: "Ralat Sistem CAFIS. Cuba lagi",
  WP_REFUSED_ERROR_455: "Ralat Sistem CAFIS. Cuba lagi",
  WP_REFUSED_ERROR_456: "Ralat Sistem CAFIS. Cuba lagi",
  WP_REFUSED_ERROR_457: "Ralat Sistem CAFIS. Cuba lagi",
  WP_REFUSED_ERROR_458: "Pemeroleh tidak menyokong perkhidmatan",
  WP_REFUSED_ERROR_459: "Ralat Sistem CAFIS. Cuba lagi",
  WP_REFUSED_ERROR_460: "Ralat Sistem CAFIS. Cuba lagi",
  WP_REFUSED_ERROR_5: "Jangan hormat ATAU Ditolak",
  WP_REFUSED_ERROR_51:
    "Dana tidak mencukupi ATAU Dana tidak mencukupi/melebihi had kredit",
  WP_REFUSED_ERROR_52: "Tiada akaun semak",
  WP_REFUSED_ERROR_53: "Tiada akaun simpanan",
  WP_REFUSED_ERROR_54: "Kad tamat tempoh ATAU Ditolak, Kad Tamat Tempoh",
  WP_REFUSED_ERROR_55: "PIN ATAU RALAT tidak sah",
  WP_REFUSED_ERROR_57:
    "Transaksi tidak dibenarkan kepada pengeluar/pemegang kad",
  WP_REFUSED_ERROR_577: "Kad tidak boleh digunakan.",
  WP_REFUSED_ERROR_578: "Urus niaga belum selesai.",
  WP_REFUSED_ERROR_579: "PIN tidak betul",
  WP_REFUSED_ERROR_58: "Transaksi tidak dibenarkan kepada pemeroleh/terminal",
  WP_REFUSED_ERROR_580: "Kod Keselamatan tidak betul",
  WP_REFUSED_ERROR_581: "Kod Keselamatan tidak ditetapkan",
  WP_REFUSED_ERROR_582: "Maklumat jalur JIS2 tidak sah",
  WP_REFUSED_ERROR_583: "Kad dimaksimumkan untuk hari itu. (kekurangan dana)",
  WP_REFUSED_ERROR_584:
    "Jumlahnya melebihi had untuk hari tersebut. (kekurangan dana)",
  WP_REFUSED_ERROR_586: "Kad itu tidak sah. (Semakan MOD 10 gagal)",
  WP_REFUSED_ERROR_587: "Kad tidak sah (hilang/dicuri).",
  WP_REFUSED_ERROR_588: "Kad itu tidak sah. (Semakan MOD 10 gagal)",
  WP_REFUSED_ERROR_589:
    'Nilai elemen mesej "Nombor Akaun Utama" tidak sah (semakan MOD 10 gagal)',
  WP_REFUSED_ERROR_590: 'Nilai "Jenis Pedagang" elemen mesej adalah tidak sah',
  WP_REFUSED_ERROR_591: 'Nilai "Amaun Transaksi" elemen mesej adalah tidak sah',
  WP_REFUSED_ERROR_592: 'Nilai elemen mesej "Cukai dan Pos" adalah tidak sah',
  WP_REFUSED_ERROR_593: "Nilai Kiraan Bonus tidak sah",
  WP_REFUSED_ERROR_594: "Nilai Bulan Bonus tidak sah",
  WP_REFUSED_ERROR_595: "Nilai Amaun Bonus tidak sah",
  WP_REFUSED_ERROR_596: "Nilai Bulan Pembayaran Pertama tidak sah",
  WP_REFUSED_ERROR_597: "Nilai Kiraan Ansuran tidak sah",
  WP_REFUSED_ERROR_598: "Nilai Amaun Ansuran tidak sah",
  WP_REFUSED_ERROR_599: "Nilai Amaun Pembayaran Pertama tidak sah",
  WP_REFUSED_ERROR_6: "Ralat",
  WP_REFUSED_ERROR_600:
    'Elemen mesej nilai "Kod Perkhidmatan", "Kod Perniagaan" dan "Kod Mesej" adalah tidak sah',
  WP_REFUSED_ERROR_601: 'Nilai elemen mesej "Bahagian Pembayaran" tidak sah',
  WP_REFUSED_ERROR_602:
    'Nilai "Bahagian Siasatan" elemen mesej adalah tidak sah',
  WP_REFUSED_ERROR_603:
    'Nilai elemen mesej "Batalkan Bahagian" adalah tidak sah',
  WP_REFUSED_ERROR_604:
    'Nilai elemen mesej "Bahagian Pembayaran Asal" tidak sah',
  WP_REFUSED_ERROR_605: "Kad itu tamat tempoh.",
  WP_REFUSED_ERROR_606: "Kad tidak boleh digunakan untuk perkhidmatan.",
  WP_REFUSED_ERROR_607: "Perkhidmatan pemeroleh selesai.",
  WP_REFUSED_ERROR_608: "Kad yang tidak sah (hilang/dicuri) mempunyai ralat.",
  WP_REFUSED_ERROR_609:
    "Mesej permintaan tidak boleh diproses atas sebab tertentu.",
  WP_REFUSED_ERROR_61: "Melebihi had jumlah pengeluaran",
  WP_REFUSED_ERROR_610:
    "Mesej permintaan yang mana transaksi tidak disokong diterima",
  WP_REFUSED_ERROR_611:
    "Mesej permintaan daripada pusat yang belum membuat kenalan.",
  WP_REFUSED_ERROR_62:
    "Kad terhad ATAU kad terhad (dalam jadual Pengecualian Negara)",
  WP_REFUSED_ERROR_622: 'Ralat tetapan "Kod Perkhidmatan" elemen mesej',
  WP_REFUSED_ERROR_623: 'Ralat tetapan elemen mesej "Kod Perniagaan".',
  WP_REFUSED_ERROR_624: 'Ralat tetapan "Kod Mesej" elemen mesej',
  WP_REFUSED_ERROR_625: 'Ralat tetapan "Kod Pemproses" unsur mesej',
  WP_REFUSED_ERROR_626: 'Ralat tetapan elemen mesej "Kod Pedagang".',
  WP_REFUSED_ERROR_627: 'Ralat tetapan elemen mesej "Pengecam Transaksi".',
  WP_REFUSED_ERROR_628:
    'Ralat tetapan elemen mesej "Tarikh-Masa Transaksi Pemproses".',
  WP_REFUSED_ERROR_629:
    'Ralat tetapan elemen mesej "Pengenalan Terminal Penerima Kad".',
  WP_REFUSED_ERROR_63: "Tidak dapat memberi kebenaran ATAU RALAT",
  WP_REFUSED_ERROR_630: 'Ralat tetapan elemen mesej "Kod Kaedah Penyulitan".',
  WP_REFUSED_ERROR_631:
    'Elemen mesej ralat tetapan "Indeks Kunci Penyulitan Utama dalam Penggunaan".',
  WP_REFUSED_ERROR_632:
    'Ralat tetapan elemen mesej "Indeks Kunci Pengesahan Pemproses".',
  WP_REFUSED_ERROR_633: 'Ralat tetapan elemen mesej "Kunci Penyulitan Mesej".',
  WP_REFUSED_ERROR_634: 'Ralat tetapan elemen mesej "Kod Pengesahan Mesej".',
  WP_REFUSED_ERROR_635:
    'Ralat tetapan elemen mesej "Kod Pengenalan Institusi Pemeroleh".',
  WP_REFUSED_ERROR_636: 'Ralat tetapan elemen mesej "Nombor Akaun Utama".',
  WP_REFUSED_ERROR_637: 'Ralat tetapan elemen mesej "Tarikh Tamat".',
  WP_REFUSED_ERROR_638: 'Ralat tetapan elemen mesej "Data Track-2".',
  WP_REFUSED_ERROR_639: 'Ralat tetapan "Data PIN" elemen mesej',
  WP_REFUSED_ERROR_64: "Tidak dapat memberi kebenaran",
  WP_REFUSED_ERROR_640: 'Ralat tetapan "Jenis Pedagang" elemen mesej',
  WP_REFUSED_ERROR_641: 'Ralat tetapan elemen mesej "Amaun Transaksi".',
  WP_REFUSED_ERROR_642: 'Ralat tetapan elemen mesej "Cukai dan Pos".',
  WP_REFUSED_ERROR_643:
    'Ralat tetapan elemen mesej "Kod Data Titik Perkhidmatan".',
  WP_REFUSED_ERROR_644: 'Ralat tetapan elemen mesej "Bahagian Pembayaran".',
  WP_REFUSED_ERROR_645: 'Ralat tetapan elemen mesej "Batalkan Bahagian".',
  WP_REFUSED_ERROR_646:
    'Ralat tetapan elemen mesej "Nombor Siri Pemprosesan Terminal Asal".',
  WP_REFUSED_ERROR_647:
    'Ralat tetapan elemen mesej "Bahagian Pembayaran Asal".',
  WP_REFUSED_ERROR_65:
    "Melebihi had kiraan pengeluaran ATAU Pengesahan diminta",
  WP_REFUSED_ERROR_658:
    'Elemen mesej ralat tetapan "Tarikh-Masa Transaksi Pemproses Asal".',
  WP_REFUSED_ERROR_659: 'Ralat tetapan elemen mesej "Pengecam Transaksi Asal".',
  WP_REFUSED_ERROR_660: 'Ralat tetapan elemen mesej "Medan Percuma".',
  WP_REFUSED_ERROR_661:
    'Ralat tetapan elemen mesej "Nombor Siri Pemprosesan Terminal".',
  WP_REFUSED_ERROR_662: 'Ralat tetapan "Kod Keselamatan" elemen mesej',
  WP_REFUSED_ERROR_663:
    'Ralat tetapan elemen mesej "Penunjuk Perdagangan Elektronik".',
  WP_REFUSED_ERROR_664: 'Ralat tetapan elemen mesej "XID".',
  WP_REFUSED_ERROR_665:
    'Ralat tetapan elemen mesej "Nilai Pengesahan Pemegang Akaun".',
  WP_REFUSED_ERROR_666: 'Ralat tetapan "Status Transaksi" unsur mesej',
  WP_REFUSED_ERROR_667: 'Ralat tetapan elemen mesej "Nombor Versi Mesej".',
  WP_REFUSED_ERROR_668: 'Ralat tetapan "Algoritma CAVV" elemen mesej',
  WP_REFUSED_ERROR_669: 'Ralat tetapan elemen mesej "Bendera Berulang".',
  WP_REFUSED_ERROR_670:
    'Blok Elemen (cth ralat tetapan "Maklumat Transaksi Am")',
  WP_REFUSED_ERROR_671:
    'Gabungan tetapan elemen mesej "Kod Perkhidmatan" dan "Kod Mesej" adalah tidak betul',
  WP_REFUSED_ERROR_672:
    'Ralat tetapan elemen mesej "Indeks Kunci Pengesahan Pemproses".',
  WP_REFUSED_ERROR_673:
    "Terdapat lebih daripada satu transaksi asal untuk diterbalikkan. Tidak dapat membalikkan transaksi",
  WP_REFUSED_ERROR_674:
    'Gabungan tetapan elemen mesej "Kod Pemproses" dan "Kod Pedagang" adalah tidak betul',
  WP_REFUSED_ERROR_675: "Ralat Sistem GEAR Global. Cuba lagi.",
  WP_REFUSED_ERROR_676:
    "Ralat Sistem GEAR Global. Keputusan urus niaga tidak jelas.",
  WP_REFUSED_ERROR_677:
    "Ralat Sistem GEAR Global. Keputusan urus niaga tidak jelas.",
  WP_REFUSED_ERROR_678: "Ralat Sistem GEAR Global. Cuba lagi.",
  WP_REFUSED_ERROR_679:
    "Ralat Sistem GEAR Global. Keputusan urus niaga tidak jelas.",
  WP_REFUSED_ERROR_68: "Masa tamat",
  WP_REFUSED_ERROR_680:
    "Ralat Sistem GEAR Global. Keputusan urus niaga tidak jelas.",
  WP_REFUSED_ERROR_681: "Ralat Sistem GEAR Global. Cuba lagi.",
  WP_REFUSED_ERROR_682:
    "Ralat Sistem GEAR Global. Keputusan urus niaga tidak jelas.",
  WP_REFUSED_ERROR_683:
    "Ralat Sistem GEAR Global. Keputusan urus niaga tidak jelas.",
  WP_REFUSED_ERROR_694:
    "Ralat Sistem GEAR Global. Keputusan urus niaga tidak jelas.",
  WP_REFUSED_ERROR_695:
    "Ralat Sistem GEAR Global. Keputusan urus niaga tidak jelas.",
  WP_REFUSED_ERROR_696:
    "Ralat Sistem GEAR Global. Keputusan urus niaga tidak jelas.",
  WP_REFUSED_ERROR_697:
    "Ralat Sistem GEAR Global. Keputusan urus niaga tidak jelas.",
  WP_REFUSED_ERROR_698:
    "Ralat Sistem GEAR Global. Keputusan urus niaga tidak jelas.",
  WP_REFUSED_ERROR_699:
    "Ralat Sistem GEAR Global. Keputusan urus niaga tidak jelas.",
  WP_REFUSED_ERROR_7: "Kad pikap, keadaan istimewa",
  WP_REFUSED_ERROR_70: "Pengeluar Kad Kenalan",
  WP_REFUSED_ERROR_700:
    "Ralat Sistem GEAR Global. Keputusan urus niaga tidak jelas.",
  WP_REFUSED_ERROR_701:
    "Ralat Sistem GEAR Global. Keputusan urus niaga tidak jelas.",
  WP_REFUSED_ERROR_702:
    "Ralat Sistem GEAR Global. Keputusan urus niaga tidak jelas.",
  WP_REFUSED_ERROR_703: "Ralat Sistem GEAR Global. Cuba lagi.",
  WP_REFUSED_ERROR_704:
    "Ralat Sistem GEAR Global. Keputusan urus niaga tidak jelas.",
  WP_REFUSED_ERROR_705: "Ralat Sistem GEAR Global. Cuba lagi.",
  WP_REFUSED_ERROR_71: "PIN Tidak Berubah",
  WP_REFUSED_ERROR_75: "Bilangan percubaan PIN yang dibenarkan melebihi",
  WP_REFUSED_ERROR_76: "Tidak sah/tiada ATAU Tidak sah/tiada dinyatakan",
  WP_REFUSED_ERROR_77: "Tidak sah/tiada ATAU Tidak sah/tiada dinyatakan",
  WP_REFUSED_ERROR_78: "Akaun tidak sah/tiada dinyatakan (umum)",
  WP_REFUSED_ERROR_79:
    "Sudah diterbalikkan (Visa) ATAU sebab kitaran hayat (Mastercard)",
  WP_REFUSED_ERROR_8:
    "Honor dengan ID ATAU Transaksi yang diluluskan dengan ID",
  WP_REFUSED_ERROR_80: "Urus niaga Visa: pengeluar kredit tidak tersedia.",
  WP_REFUSED_ERROR_82:
    "Keputusan CAM, dCVV, iCVV atau CVV negatif (Visa) ATAU Sebab dasar (Mastercard)",
  WP_REFUSED_ERROR_828: "Tidak dapat memberi kebenaran",
  WP_REFUSED_ERROR_83:
    "STIP tidak boleh meluluskan (Visa) ATAU sebab berkaitan Keselamatan Penipuan (Mastercard)",
  WP_REFUSED_ERROR_831: "Perkhidmatan tunai tidak tersedia",
  WP_REFUSED_ERROR_832: "Permintaan pulangan tunai melebihi had pengeluar",
  WP_REFUSED_ERROR_833: "Transaksi diserahkan semula melebihi had hari maks",
  WP_REFUSED_ERROR_835: "Tolak untuk kegagalan CVV2",
  WP_REFUSED_ERROR_836: "Jumlah urus niaga lebih besar daripada pra-kebenaran",
  WP_REFUSED_ERROR_84: "Kitaran Hayat Kebenaran Tidak Sah",
  WP_REFUSED_ERROR_85:
    "Tidak ditolak. Sah untuk AVS sahaja, baki Inq ATAU Tiada sebab untuk menolak permintaan",
  WP_REFUSED_ERROR_86: "Tidak Dapat Mengesahkan PIN",
  WP_REFUSED_ERROR_88: "Tidak dapat memberi kebenaran",
  WP_REFUSED_ERROR_89:
    "PIN Tidak Boleh Diterima - Transaksi Ditolak - Cuba Semula ATAU Tidak Layak untuk menerima",
  WP_REFUSED_ERROR_902: "Maklumat pengebil tidak sah",
  WP_REFUSED_ERROR_905: "Tidak dapat memberi kebenaran",
  WP_REFUSED_ERROR_906: "Tidak dapat memberi kebenaran",
  WP_REFUSED_ERROR_91:
    "Sistem Kebenaran atau sistem penerbit tidak beroperasi ATAU Sistem Kebenaran atau sistem penerbit inop",
  WP_REFUSED_ERROR_92:
    "Tidak dapat menghalakan transaksi ATAU Destinasi tidak dapat ditemui untuk penghalaan",
  WP_REFUSED_ERROR_93:
    "Transaksi tidak boleh diselesaikan melanggar undang-undang",
  WP_REFUSED_ERROR_937: "Pengesahan Kad gagal",
  WP_REFUSED_ERROR_94: "Penghantaran pendua dikesan",
  WP_REFUSED_ERROR_96: "Ralat sistem ATAU Tidak dapat memberi kebenaran",
  WP_REFUSED_ERROR_972: "Hentikan Pesanan Pembayaran",
  WP_REFUSED_ERROR_973: "Pembatalan Perintah Kebenaran",
  WP_REFUSED_ERROR_975: "Pembatalan Semua Perintah Kebenaran",
  WP_REFUSED_ERROR_98: "RALAT",
  WP_REFUSED_ERROR_99: "RALAT",
  WP_TOKENISATION_ERROR:
    "Kad anda telah ditolak oleh bank pengeluar, sila pastikan maklumat kad anda sah.",
  WRONG_MOBILE_PASS_CODE_ERROR:
    "Anda telah memasukkan kod yang tidak sah, sila cuba lagi atau {RESEND_PIN}.",
  XERO_ACCESS_INSTRUCTIONS:
    "Perlukan akses ke akaun ipaymy anda dengan sambugan Xero? \u003cstyling1\u003eDaftar masuk di sini.\u003c/styling1\u003e",
  YES_CHECKBOX: "Ya",
  YOUR_BRANDING_COLOR: "Warna Jenama Anda",
  YOUR_COMPANY_LOGO: "Logo Syarikat Anda",
  YOU_PAY_LABEL: "Anda Membayar"
};
export default t;
