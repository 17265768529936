/**
 * Actions
 *
 * ActionCreators are like the "pub" of pubsub. It's just broadcasting an event (action).
 * So, don't couple it with a specific reducer. Let ANY reducer listen to
 * an action.
 *
 * `typesafe-actions` has a nice utility function so we don't need to define action types as
 * constants. Use it together with `getType(type)` in the reducers.
 */
import { createAction } from "typesafe-actions";
import { PromoCodeType, UserWhiteList, BankType } from "./reducer";

export const fetchPromoCode = createAction(
  "adminPromo/FETCH",
  resolve => (offset?: number) => resolve({ offset })
);

export const setPromoCode = createAction(
  "adminCoupon/SET_COUPONS",
  resolve => (args: {
    isFetching: boolean;
    promoCodes: PromoCodeType[];
    total: number;
  }) => resolve(args)
);

export const addPromoCode = createAction("promoCode/ADD");

export const editPromoCode = createAction(
  "promoCode/EDIT",
  resolve => (id: number) => resolve({ id })
);

export const fetchWhitelist = createAction(
  "promoCode/FETCH_WHITELIST",
  resolve => (promoId: number, cb: (values: string[]) => any) =>
    resolve({ promoId, cb })
);

export const addWhitelist = createAction(
  "promoCode/FETCH_BASIC_USER_FROM_EMAIL"
);

export const setWhiteList = createAction(
  "promoCode/SET_USER_WHITE_LIST",
  resolve => (args: {
    isFetchingWhitelist: boolean;
    whitelist: UserWhiteList[];
  }) => resolve(args)
);

export const removeWhitelist = createAction(
  "promoCode/SET_REMOVE_WHITE_LIST",
  resolve => (email: string) => resolve({ email })
);

export const fetchCountryBanks = createAction("promoCode/FETCH_BANKS");

export const setBanks = createAction(
  "promoCode/SET_BANKS",
  resolve => (args: { banks: BankType[] }) => resolve(args)
);
