import { getType } from "typesafe-actions";
import * as types from "./types";
import * as actions from "./actions";
import _isEmpty from "lodash-es/isEmpty";

type CardBrand = {
  id: number;
  name: string;
  imgUrl: string;
};

export type CardBrandsState = {
  readonly isFetching: boolean;
  readonly byId: { [id: string]: CardBrand };
};

const defaultState: CardBrandsState = {
  byId: {
    1: { id: 1, name: "Unknown", imgUrl: "" },
    2: { id: 2, name: "Visa", imgUrl: "" },
    3: { id: 3, name: "MasterCard", imgUrl: "" },
    4: { id: 4, name: "Amex", imgUrl: "" },
    5: { id: 5, name: "UnionPay", imgUrl: "" }
  },
  isFetching: false
};

export default (state: CardBrandsState, action: types.Action) => {
  if (_isEmpty(state)) {
    state = defaultState;
  }
  switch (action.type) {
    case getType(actions.setCardBrands):
      const m = {};
      action.payload.cardBrands.forEach(brand => {
        m[brand.id] = brand;
      });
      return {
        ...state,
        byId: m
      };
  }
  return state;
};
