import React, { useEffect, useState } from "react";
import _get from "lodash-es/get";

import InputText from "src/bepaid/components/Form/controls/InputText";
import InputFile from "src/bepaid/components/Form/controls/InputFile";
import SelectRegionCity from "src/bepaid/components/SelectRegionCity";
import _isEmpty from "lodash-es/isEmpty";
import styles from "../../RecipientModal.module.scss";
import classNames from "classnames";
import {
  COUNTRY_NATIONAL_REGION,
  COUNTRY_REGISTRATION_NUMBER,
  COUNTRY_NATIONAL_ID_NUMBER,
  COUNTRY_NATIONAL_REGION_WITH_ID,
  COUNTRY_NATIONAL_CITY
} from "src/ipm-shared/store/model/Payee/const";

import CurrencyUtil from "src/ipm-shared/Utils/Currency";
import RecipientBankAccountFormContent from "../RecipientBankAccountForm/RecipientBankAccountFormContent";

const IntRecipientLandlordFormContent = ({
  FORM,
  editMode,
  editedPayee,
  initName,
  supportingDocumentsProps,
  paymentCountryCode,
  paymentCurrencyCode,
  commonFormItemProps,
  filterBank,
  getControl,
  setControl,
  resetErrors
}: any) => {
  const [isBusiness, setIsBusiness] = useState<boolean | undefined>(
    _get(editedPayee, "data.is_business", undefined)
  );
  const addProps = !_isEmpty(editedPayee) ? {} : commonFormItemProps;
  const recipientBankAccountFormContentProps = {
    editedPayee,
    ADD_FORM: FORM,
    commonFormItemProps: addProps,
    entity: "landlord",
    initName,
    countryCode: paymentCountryCode,
    filterBank
  };

  const onchangeType = (value: boolean) => {
    resetErrors(FORM);
    setIsBusiness(value);
  };

  useEffect(() => {
    const { errors: fileControlErrors } = getControl(
      "supporting_documents",
      FORM
    );

    if (paymentCountryCode === "CN") {
      setIsBusiness(true);
    }

    if (!getControl("is_business", FORM).notFound) {
      setIsBusiness(getControl("is_business", FORM).value === "true");
    }

    if (fileControlErrors.length > 0) {
      setTimeout(() => {
        setControl({
          name: "supporting_documents",
          form: FORM,
          errors: fileControlErrors
        });
      }, 200);
    }
  }, []);

  return (
    <>
      {!editMode && paymentCountryCode !== "CN" && (
        <>
          <ul className={styles.list}>
            <li
              key={1}
              onClick={onchangeType.bind(null, false)}
              className={classNames(styles.entityTypeItem, {
                [styles.selected]: isBusiness === false
              })}
            >
              Individual
            </li>
            <li
              key={2}
              onClick={onchangeType.bind(null, true)}
              className={classNames(styles.entityTypeItem, {
                [styles.selected]: isBusiness === true
              })}
            >
              Business
            </li>
          </ul>
        </>
      )}

      {(isBusiness !== undefined || paymentCountryCode === "CN") && (
        <>
          <div hidden={true}>
            <InputText
              name={"is_business"}
              form={FORM}
              defaultValue={isBusiness}
              {...addProps}
            />
          </div>
          {isBusiness === false && (
            <>
              <InputText
                name={"first_name"}
                form={FORM}
                label={"Legal First Name"}
                placeholder={"Legal First Name"}
                defaultValue={
                  _get(editedPayee, "data.first_name", "") || initName
                }
                {...addProps}
              />
              <InputText
                name={"last_name"}
                form={FORM}
                label={"Legal Last Name"}
                placeholder={"Legal Last Name"}
                defaultValue={
                  _get(editedPayee, "data.last_name", "") || initName
                }
                {...addProps}
              />
              {COUNTRY_NATIONAL_ID_NUMBER.includes(paymentCountryCode) && (
                <InputText
                  name={"national_identification_number"}
                  pattern={"__NUMBER__"}
                  form={FORM}
                  label="National ID Number"
                  placeholder="National ID Number"
                  defaultValue={_get(
                    editedPayee,
                    "data.national_identification_number",
                    ""
                  )}
                  {...addProps}
                />
              )}
            </>
          )}
          {isBusiness === true && (
            <>
              <InputText
                name={"company_name"}
                form={FORM}
                label={"Company name"}
                placeholder={"Company name"}
                defaultValue={_get(editedPayee, "name", "") || initName}
                {...addProps}
              />
              {COUNTRY_REGISTRATION_NUMBER.includes(paymentCountryCode) && (
                <InputText
                  name={"registration_number"}
                  form={FORM}
                  label={"Registration Number"}
                  placeholder={"Registration Number"}
                  defaultValue={_get(editedPayee, "data.business_registration")}
                  {...addProps}
                />
              )}
            </>
          )}

          <InputText
            name={"address"}
            form={FORM}
            label={"Address"}
            placeholder={"Address"}
            defaultValue={_get(editedPayee, "data.address", "")}
            {...addProps}
          />
          {COUNTRY_NATIONAL_CITY.includes(paymentCountryCode) &&
            paymentCountryCode !== "TH" && (
              <InputText
                name={"city"}
                form={FORM}
                label={"City"}
                placeholder={"City"}
                defaultValue={_get(editedPayee, "data.city", "")}
                {...addProps}
              />
            )}
          {COUNTRY_NATIONAL_REGION.includes(paymentCountryCode) && (
            <InputText
              name={"region"}
              form={FORM}
              label={"Region"}
              placeholder={"Region"}
              defaultValue={_get(editedPayee, "data.region", "")}
              {...commonFormItemProps}
            />
          )}
          <InputText
            name={"state_or_province"}
            form={FORM}
            label={"State/Province"}
            placeholder={"State/Province"}
            defaultValue={_get(editedPayee, "data.state_or_province", "")}
            {...addProps}
          />
          {/* {COUNTRY_NATIONAL_REGION.includes(paymentCountryCode) && (
            <InputText
              name={"region"}
              form={FORM}
              label={"Region"}
              placeholder={"Region"}
              defaultValue={_get(editedPayee, "data.region", "")}
              {...addProps}
            />
          )} */}
          {COUNTRY_NATIONAL_REGION_WITH_ID.includes(paymentCountryCode) && (
            <SelectRegionCity
              form={FORM}
              fields={{
                regionField: {
                  label: "Region",
                  name: "region",
                  placeholder: "Region",
                  defaultValue: _get(editedPayee, "data.region", ""),
                  extendField: "region_id"
                },
                cityField: {
                  label: "City",
                  name: "city",
                  placeholder: "City",
                  defaultValue: _get(editedPayee, "data.city", ""),
                  extendField: "city_id"
                }
              }}
              {...addProps}
            />
          )}

          <InputText
            name={"postal_code"}
            form={FORM}
            label={"Postal Code"}
            placeholder={"Postal Code"}
            defaultValue={_get(editedPayee, "data.postal_code", "")}
            {...addProps}
          />

          {editMode && (
            <RecipientBankAccountFormContent
              {...recipientBankAccountFormContentProps}
            />
          )}

          <InputText
            name={"default_amount"}
            placeholder={`0.00`}
            pattern={"__MONEY__"}
            form={FORM}
            label={"Rent"}
            prefix={
              CurrencyUtil.convertFromCurrencyCode(
                paymentCurrencyCode as string
              ).$
            }
            defaultValue={_get(editedPayee, "amount", 0) / 100 || ""}
            {...addProps}
            // className={styles.codeSection}
          />

          <InputText
            name={"default_comments"}
            placeholder={"Description to appear on your landlord’s statement"}
            form={FORM}
            label={"Note to landlord"}
            defaultValue={_get(editedPayee, "comments", "")}
            {...addProps}
            // className={styles.codeSection}
          />

          <div className={styles.includeTexts}>
            <p>
              <b>Tenancy Agreement</b> must include:
            </p>
            <ul>
              <li>Landlord Name</li>
              <li>Bank Account</li>
              <li>Rental Amount</li>
            </ul>
          </div>

          {editMode ? (
            _get(editedPayee, "data.supporting_documents") ? (
              <InputFile
                {...supportingDocumentsProps}
                {...addProps}
                defaultValue={_get(editedPayee, "data.files", [])}
              />
            ) : null
          ) : (
            <InputFile
              {...supportingDocumentsProps}
              {...addProps}
              defaultValue={[]}
            />
          )}
        </>
      )}
    </>
  );
};

export default IntRecipientLandlordFormContent;
