import { connect } from "react-redux";

import { RootState } from "src/ipm-shared/store/model/reducers";
import { FetchModalID } from "src/ipm-shared/components/GlobalUI/actions";
import * as actions from "src/ipm-shared/store/model/Payee/actions";
import * as gActions from "src/ipm-shared/store/model/actions";
import * as selectors from "src/ipm-shared/store/model/selectors";
import * as payeeSelectors from "src/ipm-shared/store/model/Payee/selectors";

import Component from "./View";

const mapStateToProps = (state: RootState) => ({
  payee: payeeSelectors.getPayeeById(
    state,
    selectors.getExtraInfo(state, FetchModalID.DELETE_RECIPIENT_MODAL).payeeId
  )
});

const mapDispatchToProps = {
  deletePayee: actions.deletePayee,
  toggleModal: gActions.toggleModal
};

export type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
export default connect(mapStateToProps, mapDispatchToProps)(Component);
