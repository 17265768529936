import React, { useEffect, useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import DesktopModalLayout from "src/bepaid/components/Modal/components/DesktopModalLayout";
import MainLayout from "src/bepaid/layouts/MainLayout";

import Button from "src/bepaid/components/Form/controls/Button";
import ShortCurrency from "src/ipm-shared/components/ShortCurrency";

import ROUTES from "src/bepaid/routes";
import { history } from "src/ipm-shared/store";
import { useMakePaymentUrlParams } from "src/bepaid/pages/Payments/hooks";

import _get from "lodash-es/get";
import _isEmpty from "lodash-es/isEmpty";

import CountryUtil from "src/ipm-shared/Utils/Country";

import classnames from "classnames";
import styles from "./SelectCurrency.module.scss";

const Item = (props: any) => {
  const { itemData, option, currencyId } = props;
  return (
    <div>
      <b className={styles.header}>
        {itemData
          ? `${itemData.describe} (${itemData.currencySymbol})`
          : `United States Dollar (US$)`}
      </b>
      <hr />
      <div className={styles.feesContainer}>
        <div>
          {itemData ? itemData.countryNameCard : `Singapore`} Visa and
          Mastercard: <b>{_get(option, "fee", 0) / 100}%</b>
        </div>
        <div>
          All other cards: <b>{_get(option, "otherFee", 0) / 100}%</b>
        </div>
        <div>
          {`Bank fee: `}
          <b>
            <ShortCurrency
              value={_get(option, "channelFee", 0)}
              currencyId={currencyId}
            />
          </b>
        </div>
      </div>
      <div className={styles.time}>
        Your payment will be received as fast as{" "}
        <b>{_get(option, "processingDay", 0)} business days.</b>
      </div>
      <span className={styles.note}>
        {_get(option, "channelFee", 0) > 0 ? (
          <p>
            {`* `}
            <ShortCurrency
              value={_get(option, "channelFee", 0)}
              currencyId={currencyId}
            />
            {` is the bank transfer fee of each payout.`}
          </p>
        ) : (
          <p>* No bank transfer fee is required.</p>
        )}
      </span>
      {!itemData && (
        <p className={styles.noteFedwire}>
          {`* `}Please note that Fedwire payments are not supported.
        </p>
      )}
    </div>
  );
};

const View = (props: any) => {
  const { paymentCountryCode, accountCurrencyId } = props;
  const [item, setItem] = useState<any>({});
  const [selectedSide, selectSelectedSide] = useState<number>(0);
  const [loading, setLoading] = useState(false);

  const { replaceRouteWithParams } = useMakePaymentUrlParams(
    ROUTES.PAYMENTS_CREATION
  );

  const onBack = () => {
    history.goBack();
  };

  const moveForward = () => {
    setLoading(true); // ok

    setTimeout(() => {
      if (selectedSide === 2) {
        props.setPaymentCurrencyCode(CountryUtil.getCurrencyCode("US"));
      } else {
        props.setPaymentCurrencyCode(
          CountryUtil.getCurrencyCode(paymentCountryCode)
        );
      }
      history.push(
        replaceRouteWithParams(ROUTES.PAYMENTS_CREATION, {
          newEntity: "entity",
          newAction: "select"
        })
      );
    }, 500);
  };

  const renderContent = () => {
    return (
      <>
        {!_isEmpty(item.option1) && !_isEmpty(item.option2) && (
          <div className={styles.contentWrapper}>
            <p className={styles.helperText}>
              Select the currency you want your recipient to receive. Your card
              will be charged in SGD (S$).
            </p>
            <ul className={styles.list}>
              <li
                key={1}
                onClick={selectSelectedSide.bind(null, 1)}
                className={classnames(styles.currencyItem, {
                  [styles.selected]: selectedSide === 1
                })}
              >
                <Item
                  option={item.option1}
                  itemData={item.itemData}
                  currencyId={accountCurrencyId}
                />
              </li>
              <li
                key={2}
                onClick={selectSelectedSide.bind(null, 2)}
                className={classnames(styles.currencyItem, {
                  [styles.selected]: selectedSide === 2
                })}
              >
                <Item option={item.option2} currencyId={accountCurrencyId} />
              </li>
            </ul>
          </div>
        )}
      </>
    );
  };

  const paymentCalculatorBasic = (args: {
    currencyId?: number;
    countryId?: number;
    option: number;
    paidCurrencyId: number;
  }) => {
    props.paymentCalculatorBasic({
      cb: (values: any) => {
        if (args.option === 1) {
          setItem((prevState: any) => ({
            ...prevState,
            option1: values
          }));
        } else {
          setItem((prevState: any) => ({
            ...prevState,
            option2: values
          }));
        }
      },
      countryId: args.countryId,
      currencyId: args.currencyId,
      paidCurrencyId: args.paidCurrencyId
    });
  };

  const handleCurrentDisplay = () => {
    paymentCalculatorBasic({
      currencyId: CountryUtil.getCurrencyId(paymentCountryCode),
      countryId: CountryUtil.getCountryId(paymentCountryCode),
      option: 1,
      paidCurrencyId: accountCurrencyId
    });
    paymentCalculatorBasic({
      currencyId: CountryUtil.getCurrencyId("US"),
      countryId: CountryUtil.getCountryId(paymentCountryCode),
      option: 2,
      paidCurrencyId: accountCurrencyId
    });
  };

  const handleSymbolCurrency = () => {
    let data = {
      countryNameCard: "",
      currencySymbol: "",
      describe: "",
      paidCurrency: "",
      paidCurrencyCode: ""
    };
    switch (paymentCountryCode) {
      case "MY":
        data = {
          countryNameCard: "Singapore",
          currencySymbol: "RM",
          describe: "Malaysian Ringgit",
          paidCurrency: "SGD(S$)",
          paidCurrencyCode: "SGD"
        };
        break;
      case "HK":
        data = {
          countryNameCard: "Singapore",
          currencySymbol: "HK$",
          describe: "Hong Kong Dollar",
          paidCurrency: "SGD(S$)",
          paidCurrencyCode: "SGD"
        };
        break;
      case "IN":
        data = {
          countryNameCard: "Singapore",
          currencySymbol: "₹",
          describe: "Indian Rupee",
          paidCurrency: "SGD(S$)",
          paidCurrencyCode: "SGD"
        };
        break;
      case "CN":
        data = {
          countryNameCard: "Singapore",
          currencySymbol: "CN¥",
          describe: "Chinese Yuan",
          paidCurrency: "SGD(S$)",
          paidCurrencyCode: "SGD"
        };
        break;
      case "ID":
        data = {
          countryNameCard: "Singapore",
          currencySymbol: "Rp",
          describe: "Indonesian Rupiah",
          paidCurrency: "SGD(S$)",
          paidCurrencyCode: "SGD"
        };
        break;
      case "JP":
        data = {
          countryNameCard: "Singapore",
          currencySymbol: "JP¥",
          describe: "Japanese Yen",
          paidCurrency: "SGD(S$)",
          paidCurrencyCode: "SGD"
        };
        break;
      case "FR":
        data = {
          countryNameCard: "Singapore",
          currencySymbol: "€",
          describe: "Euro",
          paidCurrency: "SGD(S$)",
          paidCurrencyCode: "SGD"
        };
        break;
      default:
        break;
    }
    setItem((prevState: any) => ({
      ...prevState,
      itemData: data
    }));
  };

  useEffect(() => {
    if (paymentCountryCode) {
      handleCurrentDisplay();
      handleSymbolCurrency();
    } else {
      history.push(
        replaceRouteWithParams(ROUTES.PAYMENTS_CREATION, {
          newEntity: "country",
          newAction: "select"
        })
      );
    }
  }, []);

  const renderDesktopView = () => {
    return (
      <DesktopModalLayout
        id={"ipaymy_select_currency_international_payment"}
        title={"Select currency"}
        onClose={props.onClose}
        onBack={onBack}
        maxWidth450={true}
        submitLabel={loading ? "Moving forward..." : "Move forward"}
        onSubmit={moveForward}
        loading={selectedSide === 0 || loading}
      >
        {renderContent()}
      </DesktopModalLayout>
    );
  };

  const renderMobileView = () => {
    return (
      <MainLayout
        id={"ipaymy_select_currency_international_payment"}
        header={{
          title: "Select currency",
          hideMenu: true,
          onClose: props.onClose,
          onBack
        }}
        footer={
          <Button
            wrapperClassName={styles.wrapperButton}
            className={styles.button}
            onClick={moveForward}
            type="primary"
            htmlType="button"
            loading={selectedSide === 0 || loading}
          >
            {loading ? "Moving forward..." : "Move forward"}
          </Button>
        }
      >
        {renderContent()}
      </MainLayout>
    );
  };

  return (
    <>
      <BrowserView>{renderDesktopView()}</BrowserView>
      <MobileView>{renderMobileView()}</MobileView>
    </>
  );
};

export default View;
