// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InvoiceDetail_wrapper__3ua8t{max-width:450px;margin:auto}.InvoiceDetail_wrapper__3ua8t .InvoiceDetail_centerText__1KS58{text-align:center}.InvoiceDetail_wrapper__3ua8t .InvoiceDetail_icon__1hPek{padding:0 .25rem}.InvoiceDetail_wrapper__3ua8t .InvoiceDetail_content__2rxFj{height:100%}.InvoiceDetail_wrapper__3ua8t .InvoiceDetail_content__2rxFj .InvoiceDetail_overviewBoxWrapper__eWPZa{margin-bottom:1rem}.InvoiceDetail_wrapper__3ua8t .InvoiceDetail_menuIconDesktop__2BLGg{position:absolute;top:30px;right:62px;cursor:pointer}", "",{"version":3,"sources":["webpack://./src/bepaid/shared/modals/InvoiceDetailModal/components/InvoiceDetail/InvoiceDetail.module.scss"],"names":[],"mappings":"AAAA,8BAAS,eAAe,CAAC,WAAW,CAAC,+DAAqB,iBAAiB,CAAC,yDAAe,gBAAgB,CAAC,4DAAkB,WAAW,CAAC,qGAAsC,kBAAkB,CAAC,oEAA0B,iBAAiB,CAAC,QAAQ,CAAC,UAAU,CAAC,cAAc","sourcesContent":[".wrapper{max-width:450px;margin:auto}.wrapper .centerText{text-align:center}.wrapper .icon{padding:0 .25rem}.wrapper .content{height:100%}.wrapper .content .overviewBoxWrapper{margin-bottom:1rem}.wrapper .menuIconDesktop{position:absolute;top:30px;right:62px;cursor:pointer}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "InvoiceDetail_wrapper__3ua8t",
	"centerText": "InvoiceDetail_centerText__1KS58",
	"icon": "InvoiceDetail_icon__1hPek",
	"content": "InvoiceDetail_content__2rxFj",
	"overviewBoxWrapper": "InvoiceDetail_overviewBoxWrapper__eWPZa",
	"menuIconDesktop": "InvoiceDetail_menuIconDesktop__2BLGg"
};
export default ___CSS_LOADER_EXPORT___;
