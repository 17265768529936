import React, { useEffect, useState } from "react";
import { isDesktop } from "react-device-detect";
import classnames from "classnames";
import Checkbox from "antd/es/checkbox";

import { PURPOSE } from "src/ipm-shared/components/Purpose/const";

import Image from "src/bepaid/components/Image";
import CalendarInput from "src/bepaid/components/Calendar/components/CalendarInput";
import Drawer from "src/bepaid/components/Drawer";
import Calendar from "src/bepaid/components/Calendar";
import useQuery from "src/bepaid/hooks/useQuery";

import { initDatePicker, useDatePicker } from "./useDatePicker";
import {
  CUSTOMERS_FILTER_OPTIONS,
  INVOICE_FILTER_OPTIONS,
  RECIPIENTS_FILTER_OPTIONS,
  PAYMENT_FILTER_OPTIONS,
  SCHEDULE_FILTER_OPTIONS,
  IOption
} from "../../const";

import CheckIconBlack from "src/bepaid/assets/images/common/icon_check_black.svg";
import CalendarIcon from "src/bepaid/assets/images/common/calendar.svg";
import CalendarInvoiceIcon from "src/bepaid/assets/images/common/calendar-invoices.svg";

import styles from "./FilterContent.module.scss";

const FILTER_OPTIONS_BY_KEY = {
  customers: CUSTOMERS_FILTER_OPTIONS,
  invoices: INVOICE_FILTER_OPTIONS,
  payments: PAYMENT_FILTER_OPTIONS,
  payments_schedules: SCHEDULE_FILTER_OPTIONS,
  recipients: RECIPIENTS_FILTER_OPTIONS,
  landlords: RECIPIENTS_FILTER_OPTIONS,
  employees: RECIPIENTS_FILTER_OPTIONS,
  suppliers: RECIPIENTS_FILTER_OPTIONS,
  bank_accounts: RECIPIENTS_FILTER_OPTIONS
};

type IProps = {
  filterState: any;
  onChangeFilter: any;
  filterKey?: string;
  isPersonalAccount?: boolean;
  accountCountryCode?: string;
  hasWallexPayment?: boolean;
};

const COLORS = {
  customers: {
    iconCalendarButton: CalendarIcon,
    selectedItem: "#F0F7FC",
    title: "#D5E9FF"
  },
  invoices: {
    iconCalendarButton: CalendarInvoiceIcon,
    selectedItem: "#F5F7F2",
    title: "#E7EFD9"
  },
  payments: {
    iconCalendarButton: CalendarInvoiceIcon,
    selectedItem: "#F5F7F2",
    title: "#E7EFD9"
  },
  payments_schedules: {
    iconCalendarButton: CalendarInvoiceIcon,
    selectedItem: "#F5F7F2",
    title: "#E7EFD9"
  },
  recipients: {
    iconCalendarButton: CalendarIcon,
    selectedItem: "#F0F7FC",
    title: "#D5E9FF"
  },
  landlords: {
    iconCalendarButton: CalendarIcon,
    selectedItem: "#F0F7FC",
    title: "#D5E9FF"
  },
  employees: {
    iconCalendarButton: CalendarIcon,
    selectedItem: "#F0F7FC",
    title: "#D5E9FF"
  },
  suppliers: {
    iconCalendarButton: CalendarIcon,
    selectedItem: "#F0F7FC",
    title: "#D5E9FF"
  },
  bank_accounts: {
    iconCalendarButton: CalendarIcon,
    selectedItem: "#F0F7FC",
    title: "#D5E9FF"
  }
};

const initFilter = {
  DATE: null,
  PUN: null,
  STATUS: null,
  TAG: null
};

const View = (props: IProps) => {
  const qs = useQuery();

  const SEARCH_KEY: string = String(qs.key || props.filterKey);
  const FILTER_OPTIONS: IOption[] = FILTER_OPTIONS_BY_KEY[SEARCH_KEY] || [];

  const colors = COLORS[SEARCH_KEY];

  const [filters, setFilters] = useState<any>(initFilter);

  const {
    datePicker: customDatepicker,
    onSelectDate: onSelectCustomDate,
    openCalendar: openCustomCalendar,
    setDatePicker: setCustomDatePicker
  } = useDatePicker();

  const {
    datePicker: payoutDatePicker,
    onSelectDate: onSelectPayoutDate,
    openCalendar: openPayoutDateCalendar,
    setDatePicker: setPayoutDatePicker
  } = useDatePicker();

  const {
    datePicker: chargeDatePicker,
    onSelectDate: onSelectChargeDate,
    openCalendar: openChargeDateCalendar,
    setDatePicker: setChargeDatePicker
  } = useDatePicker();

  const datePickerStateByFilter = {
    DATE: {
      datePicker: customDatepicker,
      openCalendar: openCustomCalendar,
      onSelectDate: onSelectCustomDate
    },
    PAYOUT_DATE: {
      datePicker: payoutDatePicker,
      openCalendar: openPayoutDateCalendar,
      onSelectDate: onSelectPayoutDate
    },
    CHARGE_DATE: {
      datePicker: chargeDatePicker,
      openCalendar: openChargeDateCalendar,
      onSelectDate: onSelectChargeDate
    }
  };

  const setFiltersByKey = (parent: IOption) => (e: any) => {
    const { key, children = [] } = parent;

    const { checked, value: selectedId } = e.target;

    const opt = checked && children.find(o => o.id === selectedId);

    if (key) {
      setFilters((prev: any) => {
        return {
          ...prev,
          [key]: opt || null
        };
      });
    }
  };

  const onChangeFilter = () => {
    if (props.onChangeFilter) {
      const {
        TAG: tag,
        PUN: pun,
        DATE: date,
        STATUS: status,
        FAVOURITE: favouriteOpt,
        PURPOSE: purposeOpt,
        PAYOUT_DATE: payoutDateOpt,
        CHARGE_DATE: chargeDateOpt,
        FREQUENCY: frequencyOpt,
        DESTINATION: destinationOpt
      } = filters;

      const tagOpt = tag;
      const punOpt = pun;
      const dateOpt = date;
      const statusOpt = status;

      let from;
      let to;

      switch (dateOpt && dateOpt.key) {
        case "last_week": {
          // from = DateFns.startOfWeek(new Date());
          // to = new Date();
          break;
        }
        case "last_month": {
          // from = DateFns.startOfMonth(new Date());
          // to = new Date();
          break;
        }
        case "last_three_months": {
          // from = DateFns.startOfQuarter(new Date());
          // to = new Date();
          break;
        }
        case "last_six_months": {
          // from = DateFns.subQuarters(DateFns.startOfQuarter(new Date()), 1);
          // to = new Date();
          break;
        }
        case "custom": {
          from = customDatepicker.currentDate.from;
          to = customDatepicker.currentDate.to;
          break;
        }
      }

      const query = {
        ...qs,
        ...(dateOpt && dateOpt.key
          ? {
              dateQueryKey: dateOpt.key,
              from: from && new Date(from).toISOString(),
              to: to && new Date(to).toISOString()
            }
          : {}),
        ...{
          customers: {
            punctual: punOpt && punOpt.key,
            tag: tagOpt && tagOpt.key
          },
          invoices: {
            statuses: statusOpt && statusOpt.key
          },
          payments: {
            statuses: statusOpt && statusOpt.key,
            favourite: favouriteOpt && favouriteOpt.key,
            purposes: purposeOpt && purposeOpt.key,
            frequency: frequencyOpt && frequencyOpt.key,
            payout_date_key: payoutDateOpt && payoutDateOpt.key,
            destination: destinationOpt && destinationOpt.key,
            ...(payoutDateOpt?.key === "custom"
              ? {
                  payout_date_from: payoutDatePicker.currentDate.from.toISOString(),
                  payout_date_to: payoutDatePicker.currentDate.to.toISOString()
                }
              : {}),
            charge_date_key: chargeDateOpt && chargeDateOpt.key,
            ...(chargeDateOpt?.key === "custom"
              ? {
                  charge_date_from: chargeDatePicker.currentDate.from.toISOString(),
                  charge_date_to: chargeDatePicker.currentDate.to.toISOString()
                }
              : {})
          },
          payments_schedules: {
            statuses: statusOpt && statusOpt.key,
            purposes: purposeOpt && purposeOpt.key,
            frequency: frequencyOpt && frequencyOpt.key,
            payout_date_key: payoutDateOpt && payoutDateOpt.key,
            ...(payoutDateOpt?.key === "custom"
              ? {
                  payout_date_from: payoutDatePicker.currentDate.from.toISOString(),
                  payout_date_to: payoutDatePicker.currentDate.to.toISOString()
                }
              : {})
          }
        }[SEARCH_KEY]
      };

      props.onChangeFilter(query);
    }
  };

  const getPaymentFilterChildren = (data: any) => {
    // Remove "Tax" and "Salary" filters from the “Payments” dashboard of personal accounts
    if (props.isPersonalAccount) {
      if (
        data.key ===
        `${String(PURPOSE.SALARY)},${String(PURPOSE.SALARY_BUSINESS)}`
      ) {
        return null;
      }
    }

    // Remove "Tax" from the “Payments” dashboard in Malaysia, and Hong Kong
    if (
      props.accountCountryCode !== "SG" &&
      props.accountCountryCode !== "AU"
    ) {
      if (data.key === String(PURPOSE.TAX)) {
        return null;
      }
    }

    // Remove "Insurance" from the “Payments” dashboard in Australia, Malaysia, and Hong Kong
    if (props.accountCountryCode !== "SG") {
      if (data.key === String(PURPOSE.INSURANCE)) {
        return null;
      }
    }

    // Remove "Money Transfer" from the “Payments” dashboard if not have wallex payment
    if (!props.hasWallexPayment) {
      if (data.key === String(PURPOSE.SELF_TRANSFER)) {
        return null;
      }
    }

    return data;
  };

  const getPaymentFilterParent = (data: any) => {
    // Remove "Destination" from the “Payments” dashboard if not have wallex payment
    if (!props.hasWallexPayment) {
      return null;
    }
    return data;
  };

  useEffect(() => {
    onChangeFilter();
    // if (
    //   filters.DATE ||
    //   filters.PUN ||
    //   filters.STATUS ||
    //   filters.TAG ||
    //   filters.PURPOSE ||
    //   filters.FREQUENCY ||
    //   filters.CHARGE_DATE ||
    //   filters.PAYOUT_DATE
    // ) {
    // }
  }, [
    filters,
    payoutDatePicker.currentDate,
    chargeDatePicker.currentDate,
    customDatepicker.currentDate
  ]);

  useEffect(() => {
    if (props.filterState === null) {
      setFilters(initFilter);
    }
  }, [props.filterState]);

  useEffect(() => {
    setCustomDatePicker(initDatePicker({ from: qs.from, to: qs.to }));
  }, [qs.from, qs.to]);

  useEffect(() => {
    setChargeDatePicker(
      initDatePicker({ from: qs.charge_date_from, to: qs.charge_date_to })
    );
  }, [qs.charge_date_from, qs.charge_date_to]);

  useEffect(() => {
    setPayoutDatePicker(
      initDatePicker({ from: qs.payout_date_from, to: qs.payout_date_to })
    );
  }, [qs.payout_date_from, qs.payout_date_to]);

  useEffect(() => {
    setFilters(initFilter);
  }, [qs.paymentType]);

  useEffect(() => {
    [
      "charge_date_key",
      "frequency",
      "payout_date_key",
      "purposes",
      "statuses",
      "punctual",
      "dateQueryKey",
      "tag"
    ].forEach((filterKey: string) => {
      const filter = {
        statuses: "STATUS",
        charge_date_key: "CHARGE_DATE",
        payout_date_key: "PAYOUT_DATE",
        purposes: "PURPOSE",
        frequency: "FREQUENCY",
        punctual: "PUN",
        dateQueryKey: "DATE",
        tag: "TAG"
      };

      if (filters[filter[filterKey]]) {
        return;
      }

      if (!qs[filterKey] || qs[filterKey] === "") {
        setFilters((oldState: any) => {
          return {
            ...oldState,
            [filter[filterKey]]: null
          };
        });
      } else {
        const parent = FILTER_OPTIONS.find(o => o.key === filter[filterKey]);
        const selectedChild = parent?.children?.find(
          c => c.key === qs[filterKey]
        );

        if (selectedChild) {
          setFilters((oldState: any) => {
            return {
              ...oldState,
              [filter[filterKey]]: selectedChild
            };
          });
        }
      }
    });
  }, [JSON.stringify(qs)]);

  return (
    <div
      className={classnames(styles.wrapper, {
        [styles.desktopWrapper]: isDesktop
      })}
    >
      <div className={styles.layoutContent}>
        {FILTER_OPTIONS.map(parent => {
          const parentKey = String(parent.key);
          const datePickerState = datePickerStateByFilter[parentKey];
          if (SEARCH_KEY === "payments" && parentKey === "DESTINATION") {
            parent = getPaymentFilterParent(parent);
          }

          return (
            parent && (
              <div key={parent.id}>
                <button
                  className={styles.parentTitle}
                  style={{
                    backgroundColor: colors.title
                  }}
                >
                  {parent.name}
                </button>

                <div className={styles.childrenGroup}>
                  {parent.children &&
                    parent.children.map((option, i) => {
                      const isActivated =
                        filters[parent.key as string]?.id === option.id;

                      // filter payment by account type or account country
                      if (
                        SEARCH_KEY === "payments" &&
                        parent.key === "PURPOSE"
                      ) {
                        option = getPaymentFilterChildren(option);
                      }

                      return (
                        option && (
                          <div
                            key={option.id}
                            className={styles.childrenOption}
                            style={{
                              backgroundColor: isActivated
                                ? colors.selectedItem
                                : "#fff"
                            }}
                          >
                            <Checkbox
                              value={option.id}
                              checked={isActivated}
                              className={styles.checkbox}
                              onChange={setFiltersByKey(parent)}
                            >
                              {option.name}
                            </Checkbox>
                            {isActivated && (
                              <div className={styles.checkIcon}>
                                <Image src={CheckIconBlack} />
                              </div>
                            )}
                          </div>
                        )
                      );
                    })}
                </div>
                {["DATE", "PAYOUT_DATE", "CHARGE_DATE"].includes(parentKey) &&
                  filters[parentKey]?.key === "custom" && (
                    <div className={styles.dateButton}>
                      <CalendarInput
                        icon={colors.iconCalendarButton}
                        label={
                          SEARCH_KEY === "payments_schedules"
                            ? "First Payout Date"
                            : "From"
                        }
                        onClick={datePickerState.openCalendar}
                        value={datePickerState.datePicker.currentDate.from}
                      />
                      <div className={styles.space} />
                      <CalendarInput
                        icon={colors.iconCalendarButton}
                        label={
                          SEARCH_KEY === "payments_schedules"
                            ? "Schedule End Date"
                            : "To"
                        }
                        onClick={datePickerState.openCalendar}
                        value={datePickerState.datePicker.currentDate.to}
                      />
                      <Drawer
                        height="69%"
                        placement="bottom"
                        visible={datePickerState.datePicker.isOpen}
                        closable={true}
                        closeIcon={null}
                        onClose={datePickerState.onSelectDate.bind(null, null)}
                      >
                        <Calendar
                          selectedRange={datePickerState.datePicker.currentDate}
                          onSubmit={datePickerState.onSelectDate}
                          range={true}
                          type={SEARCH_KEY}
                        />
                      </Drawer>
                    </div>
                  )}
              </div>
            )
          );
        })}
      </div>
    </div>
  );
};

export default View;
