import { RouteProps } from "react-router";
import { connect } from "react-redux";
import { RootState } from "src/ipm-shared/store/model/reducers";
import * as selectors from "src/bepaid/store/model/Fetch/selectors";
import * as actions from "src/bepaid/store/model/Fetch/actions";

import Component from "./View";

const mapStateToProps = (state: RootState) => ({
  currencySymbol: selectors.getCurrentCurrencySymbol(state),
  hasToken: selectors.hasToken(state),
  invoiceInsights: selectors.getInvoiceInsights(state)
});

const mapDispatchToProps = {
  getInvoiceInsights: actions.getInvoiceInsights
};

export type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteProps;

export default connect(mapStateToProps, mapDispatchToProps)(Component);
