import { matchPath } from "react-router";
import ROUTES from "src/bepaid/routes";
import * as queryString from "query-string";

export type PaymentEntity = "landlord" | "employee" | "supplier" | "tax";
export type UrlParams = {
  entity: PaymentEntity;
  id: string;
};

export const useMakePayeeUrlParams = (
  matchRoute: string = ROUTES.RECIPIENTS_DETAILS
) => {
  const match = matchPath<UrlParams>(location.pathname, {
    path: matchRoute
  });

  const { entity, id } = match?.params || ({} as UrlParams);

  const replaceRouteWithParams = (
    route: string,
    options?: {
      newEntity?: string;
      newId?: string;
    }
  ) => {
    const { newEntity = entity, newId = id } = options || {};

    let newRoute = route;
    if (newEntity) {
      newRoute = newRoute.replace(":entity", newEntity);
    }

    if (newId) {
      newRoute = newRoute.replace(":id", newId);
    }

    return newRoute;
  };

  const getToken = () => {
    const qs = queryString.parse(window.location.search);
    return qs.token;
  };

  return {
    query: queryString.parse(window.location.search),
    entity,
    id,
    token: getToken(),
    replaceRouteWithParams
  };
};
