import { connect } from "react-redux";
import View from "./View";
import { RootState } from "src/ipm-shared/store/model/reducers";
import { FORM } from "src/ipm-shared/store/model/Payee/const";

import * as actions from "../actions";
import * as selectors from "../selectors";

const mapStateToProps = (state: RootState) => ({
  filesControl: selectors.getFileControl(
    state,
    selectors.getControlsPattern(state, /^document_tag_/),
    "document_tag_"
  ),
  formState: selectors.getControls(state, FORM)
});

const mapDispatchToProps = {
  validateAmount: actions.validateAmount,
  checkValidNoteToRecipient: actions.checkValidNoteToRecipient,
  selectPayees: actions.selectPayees,
  setControl: actions.setControl,
  removeControlPattern: actions.removeControlPattern
};

export default connect(mapStateToProps, mapDispatchToProps)(View);
