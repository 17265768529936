import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { RootState } from "src/ipm-shared/store/model/reducers";

import * as selectors from "src/bepaid/store/model/Fetch/selectors";

import ROUTES from "../routes";

const mapStateToProps = (state: RootState) => ({
  accountUserRoleId: selectors.getAccountUserRoleId(state)
});

const mapDispatchToProps = {};

const withAccountUserRole = (WrapperComponent: React.ComponentType) => {
  const HOC = (props: any) => {
    return props.accountUserRoleId != 4 ? (
      <Redirect to={ROUTES.ROOT} />
    ) : (
      <WrapperComponent {...props} />
    );
  };

  return connect(mapStateToProps, mapDispatchToProps)(HOC);
};

export default withAccountUserRole;
