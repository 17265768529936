import React from "react";
import { isDesktop } from "react-device-detect";
import _get from "lodash-es/get";
import Skeleton from "antd/lib/skeleton";

import { FetchModalID } from "src/ipm-shared/components/GlobalUI/actions";
import { history } from "src/ipm-shared/store";
import ScrollUtil from "src/ipm-shared/Utils/Scroll";
import PaymentUtil from "src/ipm-shared/Utils/Payment";

import ExpandBox from "src/bepaid/components/ExpandBox";
import ActionDetailDrawer from "src/bepaid/components/ActionDetailDrawer";
import ROUTES from "src/bepaid/routes";

import ContentExpanded from "./ContentExpanded";
import ContentCollapsed from "./ContentCollapsed";
import SchedulePayments from "./SchedulePayments";

import styles from "./PaymentDetail.module.scss";

const View = (props: any) => {
  const { loading, selectedPayment, actionDrawer, openActionDrawer } = props;
  if (!selectedPayment) {
    return null;
  }
  if (!loading && !selectedPayment) {
    return <div>Payment not found</div>;
  }

  const actions = [
    {
      key: "edit",
      label: "Edit these recurring payments",
      func: (_: any) => {
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.set("is_schedule", "y");
        localStorage.setItem(
          "paymentEditUrl",
          window.location.pathname + window.location.search
        );
        const url = ROUTES.SCHEDULE_EDIT.replace(
          ":id",
          selectedPayment.scheduleId
        );
        history.push({
          pathname: url,
          search: "?" + urlParams.toString()
        });
        return;
      }
    },
    {
      key: "cancel",
      label: "Cancel these recurring payments",
      func: (_: any) => {
        props.toggleModal(FetchModalID.CANCEL_PAYMENT_MODAL, {
          scheduleId: selectedPayment.scheduleId
        });
      }
    }
  ];
  const actionsOnePayment = [
    {
      key: "edit",
      label: "Edit this payment",
      func: (data: any) => {
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.delete("is_schedule");
        const url = ROUTES.PAYMENT_EDIT.replace(":id", data.id);
        history.push({
          pathname: url,
          search: "?" + urlParams.toString()
        });
        return;
      }
    },
    {
      key: "cancel",
      label: "Cancel this payment",
      func: (data: any) => {
        props.toggleModal(FetchModalID.CANCEL_PAYMENT_MODAL, {
          paymentId: data.id
        });
      }
    }
  ];

  const handleToggleOptionForOnePayment = (id: number) => () => {
    openActionDrawer({
      ...actionDrawer,
      value: id,
      visibleDrawer: false,
      visibleOnePaymentDrawer: !actionDrawer.visibleOnePaymentDrawer
    });
  };

  const handleToggleMoreAction = () => {
    openActionDrawer({
      ...actionDrawer,
      visibleOnePaymentDrawer: false,
      visibleDrawer: !actionDrawer.visibleDrawer
    });
  };
  const handleScroll = () => {
    ScrollUtil.scrollTopElementId("modal-body-content");
  };
  const handleOpenScheduleDetail = () => {
    const search = new URLSearchParams(history.location.search);

    const url =
      `${ROUTES.PAYMENT_SCHEDULE_DETAILS.replace(
        ":id",
        selectedPayment.scheduleId
      )}` +
      "?" +
      search.toString();

    history.push(url, {
      prevRoute: ROUTES.PAYMENT_DETAILS
    });
  };

  return (
    <div className={styles.wrapper}>
      <Skeleton loading={props.loading}>
        <ExpandBox
          defaultExpand={false}
          originalContent={<ContentCollapsed {...props} />}
          expandContent={<ContentExpanded {...props} />}
        />
      </Skeleton>

      {selectedPayment && selectedPayment.scheduleFrequency !== "00:00:00" && (
        <>
          <SchedulePayments
            paymentDetail={{
              ...selectedPayment.schedulePayments,
              frequency: PaymentUtil.convertFrequencyString(
                selectedPayment.scheduleFrequency
              )
            }}
            openActionDrawer={handleToggleMoreAction}
            currentPaymentId={selectedPayment.id}
            onScroll={handleScroll}
            currencyId={selectedPayment.currencyId}
            openScheduleDetail={handleOpenScheduleDetail}
          />
        </>
      )}
      <ActionDetailDrawer
        contentFullWidth={isDesktop}
        height={"auto"}
        visible={actionDrawer.visibleDrawer}
        data={null}
        actions={actions}
        onClose={handleToggleMoreAction}
        type="invoice"
      />
      <ActionDetailDrawer
        contentFullWidth={isDesktop}
        height={"auto"}
        visible={actionDrawer.visibleOnePaymentDrawer}
        data={selectedPayment}
        actions={actionsOnePayment}
        onClose={handleToggleOptionForOnePayment(0)}
        type="invoice"
      />
    </div>
  );
};

export default View;
