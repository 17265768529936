import React from "react";
import classNames from "classnames";
import Typography from "antd/es/typography";

import styles from "../RecipientDetail.module.scss";

import { useProcessPayeeDetail } from "../useProcessPaymentDetail";
import ShortCurrency from "src/ipm-shared/components/ShortCurrency";
import BankId from "src/ipm-shared/components/BankId";
import DateUtils from "src/ipm-shared/Utils/Date";

const ContentCollapsed = (props: any) => {
  const { selectedPayee, accountCountryCode } = props;
  const {
    name,
    purpose,
    purposeTitle,
    currencyId,
    receiptDefaultAmount,
    postalCode,
    unitNumber,
    address,
    bankId,
    accountNumber,
    note,
    bankRawName,
    rentalDueDate,
    tenancyExpiryDate
  } = useProcessPayeeDetail(selectedPayee);

  return (
    <>
      <div className={classNames(styles.row, styles.firstRow)}>
        <span>{purposeTitle}</span>
      </div>
      <div className={styles.row}>
        <Typography.Text strong={true} ellipsis={true}>
          {name}
        </Typography.Text>
      </div>
      <hr className={styles.hr1px} />
      <div>
        {postalCode && (
          <div
            className={classNames(styles.row, styles.verticalRow)}
            hidden={accountCountryCode === "HK"}
          >
            <span>Postal Code</span>
            <Typography.Text ellipsis={true}>{postalCode}</Typography.Text>
          </div>
        )}
        {unitNumber && (
          <div className={classNames(styles.row, styles.verticalRow)}>
            <span>Unit number</span>
            <Typography.Text ellipsis={true}>{unitNumber}</Typography.Text>
          </div>
        )}

        <div className={classNames(styles.row, styles.verticalRow)}>
          <span>Address</span>
          <Typography.Text ellipsis={true}>{address}</Typography.Text>
        </div>

        <div className={classNames(styles.row, styles.verticalRow)}>
          <span>Bank</span>
          {bankId ? (
            <span>
              <BankId
                name={`${bankId}_bank`}
                valueShowOnly={true}
                defaultValue={bankId}
              />
            </span>
          ) : (
            <span>{bankRawName}</span>
          )}
        </div>
        <div className={classNames(styles.row, styles.verticalRow)}>
          <span>Account number</span>
          <Typography.Text ellipsis={true}>{accountNumber}</Typography.Text>
        </div>
        <div className={classNames(styles.row, styles.verticalRow)}>
          <span>{purpose}</span>
          <Typography.Text ellipsis={true}>
            <ShortCurrency
              value={receiptDefaultAmount}
              currencyId={currencyId}
            />
          </Typography.Text>
        </div>
        <div className={classNames(styles.row, styles.verticalRow)}>
          <span>Monthly due date</span>
          <Typography.Text ellipsis={true}>
            {rentalDueDate ? rentalDueDate : "-"}
          </Typography.Text>
        </div>
        <div className={classNames(styles.row, styles.verticalRow)}>
          <span>Tenancy expiry date</span>
          <Typography.Text ellipsis={true}>
            {tenancyExpiryDate ? DateUtils.formatDate(tenancyExpiryDate) : "-"}
          </Typography.Text>
        </div>
        {note && (
          <div className={classNames(styles.row, styles.verticalRow)}>
            <span>Note to landlord</span>
            <Typography.Text ellipsis={true}>{note}</Typography.Text>
          </div>
        )}
      </div>
    </>
  );
};

export default ContentCollapsed;
