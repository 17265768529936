import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import Typography from "antd/es/typography";
import Flex from "antd-mobile/es/flex";

import FacebookTrack from "src/ipm-shared/components/FacebookPixelProvider/FacebookTrack";

import Image from "src/bepaid/components/Image";
import ROUTES from "src/bepaid/routes";
import { getUtmLink } from "src/bepaid/utils/getUtmParams";
import GuestLayout from "src/bepaid/layouts/GuestLayout";
import Card from "src/bepaid/components/Card";

import HeaderLogo from "src/bepaid/assets/images/desktop/header_ipaymy_logo.svg";

import FormComponent from "./FormComponent";

import styles from "./SignUp.module.scss";

const Registration: React.FC<any> = props => {
  const renderContent = () => {
    return (
      <div className={styles.wrapper}>
        <Flex>
          <Flex.Item>
            <FormComponent {...props} />
          </Flex.Item>
        </Flex>

        <Flex>
          <Flex.Item className={styles.centerText}>
            <Typography.Text className={styles.signInText}>
              Already have an account?{" "}
              <Typography.Link
                className={styles.strongLink}
                href={getUtmLink(ROUTES.LOGIN)}
              >
                Sign in.
              </Typography.Link>
            </Typography.Text>
          </Flex.Item>
        </Flex>
      </div>
    );
  };

  const renderDesktopView = () => {
    return (
      <div className={styles.desktopWrapper}>
        <Card>{renderContent()}</Card>
      </div>
    );
  };

  const renderMobileView = () => {
    return (
      <div className={styles.wrapperMobile}>
        <Card>{renderContent()}</Card>
      </div>
    );
  };

  return (
    <>
      <GuestLayout
        header={<Image src={HeaderLogo} />}
        title={
          <Typography.Text className={styles.title}>
            Welcome to <b>ipaymy</b>
          </Typography.Text>
        }
      >
        <BrowserView>{renderDesktopView()}</BrowserView>
        <MobileView>{renderMobileView()}</MobileView>
      </GuestLayout>
      <FacebookTrack accountTypeCheck={true} title={"ViewContent"} />
    </>
  );
};

export default Registration;
