import { getType } from "typesafe-actions";
import * as types from "./types";
import * as actions from "./actions";
import _isEmpty from "lodash-es/isEmpty";
import * as uuidv1 from "uuid/v1";
import _get from "lodash-es/get";

export type CollectionRates = {
  brand_id: number;
  currency_id?: number;
  national: number; // in *100 format. For e.g: 200
  international: number; // in *100 format. For e.g: 200
  fee_payer?: number;
  int_fee_payer?: number;
  crypto_rate?: number;
  crypto_rate_payer?: number;
};

export type Incentives = {
  incentive_type_id: string;
  rate: number;
};

export type OtherSettingsCollectedAccount = {
  generateOrManualIN: "generate" | "manual" | null;
  invoiceNumberPrefix: string;
  invoiceNumberTemplate: string;
};

export type TaxType = {
  id: number;
  name: string;
  rate: number;
};

export type DiscountTypeType = "percentage" | "amount";
export type ItemType = {
  id: number;
  name: string;
  unitPrice: number;
  taxId?: number;
  discount?: number;
  discountType?: DiscountTypeType;
};

export type InvoiceLineItemType = {
  itemId: number;
  uid: string;
  name: string;
  quantity: number;
  unitPrice: number;
  discount?: number;
  discountType?: DiscountTypeType;
  taxName?: string;
  taxRate?: number;
  taxId?: number;
  amount: number;
};

export type MainCollectedAccountData = {
  accountId: number;
  bankBSBId: string;
  bankCode: string;
  bsbCode: string;
  accountNumber: string;
  bankId: number;
  companyName: string;
  currencyId: number;
  draft?: boolean;
  id: number;
  payeeData: {
    bankDocuments: Array<{
      key: string;
      name: string;
      size: number;
      url: string;
    }>;
    businessIndustry: number;
    businessType: number;
    companySmsName: string;
    confirmedEmail: string;
    confirmedAccountNumber: string;
    confirmedTnc: boolean;
    confirmedPolicy: boolean;
    defaultCryptoRatePayer: number;
    defaultFeePayer: number;
    defaultIntFeePayer: number;
    defaultStatementDescriptor: string;
    paymentDescription: string;
    recipientEmail: string;
    remindDraftInvoice: boolean | null;
    requesterRates: CollectionRates[];
    toCreateOrUploadInvoice: "create" | "upload" | null;
    gstRegistrationNumber: string | null;
    companyAddressLine1: string | null;
    companyAddressLine2: string | null;
    brandingColor: string | null;
    companyLogo: string | null;
    companyLogoView: Array<{
      key: string;
      name: string;
      size: number;
      url: string;
    }>;
    kycDocuments: {
      b_acra_bizfile: Array<{
        key: string;
        name: string;
        size: number;
        url: string;
      }>;
      b_letter_of_authorization: Array<{
        key: string;
        name: string;
        size: number;
        url: string;
      }> | null;
      b_poba: Array<{
        key: string;
        name: string;
        size: number;
        url: string;
      }> | null;
      b_structured_chart: Array<{
        key: string;
        name: string;
        size: number;
        url: string;
      }> | null;
    };
    myinfoBiz?: any;
  };
  otherSettings?: OtherSettingsCollectedAccount;
  purposeId: number;
  registrationNumber: string;
  status: string;
};

export type CollectedAccountState = {
  readonly isUpdatedStatus: boolean;
  readonly taxes: TaxType[];
  readonly items: ItemType[];
  readonly invoiceLineItems: {
    [uid: string]: InvoiceLineItemType;
  };
  readonly selectedItemsIds: {
    [uid: string]: number;
  };
  readonly selectedTaxesIds: {
    [uid: string]: number;
  };
  readonly isFetching: boolean;
  readonly isDisplayLoading: boolean;
  readonly totalCollectedAccounts: number;
  readonly list: Array<{
    id: number;
    email: string;
    updatedAt: Date;
    accountId: number;
    accountNumber: string;
    companyName: string;
    countryLabel: string;
    status?: string;
    payeeData: {
      bankDocumentsView: Array<{
        key: string;
        name: string;
        size: number;
        url: string;
      }>;
      businessIndustry: number;
      businessType: number;
      companySmsName: string;
      confirmedEmail: string;
      confirmedAccountNumber: string;
      confirmedTnc: boolean;
      confirmedPolicy: boolean;
      defaultCryptoRatePayer: number;
      defaultFeePayer: number;
      defaultIntFeePayer: number;
      defaultStatementDescriptor: string;
      paymentDescription: string;
      recipientEmail: string;
      requesterInternationalRate: number;
      requesterNationalRate: number;
      requeterRates: Array<{
        brand_id: number;
        national: number;
        international: number;
        fee_payer: number;
        int_fee_payer: number;
        crypto_rate: number;
        crypto_rate_payer: number;
      }> | null;
      toCreateOrUploadInvoice: "create" | "upload" | null;
      gstRegistrationNumber: string | null;
      companyAddressLine1: string | null;
      companyAddressLine2: string | null;
      brandingColor: string | null;
      companyLogo: string | null;
      companyLogoView: Array<{
        key: string;
        name: string;
        size: number;
        url: string;
      }>;
    };
    approvedAt?: Date;
    hasCollected: boolean;
    hasRequest: boolean;
  }>;

  readonly mainCollectedAccount: {
    data?: MainCollectedAccountData;
    hasActiveProgram: boolean;
    isFetched: boolean;
    rates: CollectionRates[];
    hasDraftPayment?: boolean;
    accountUserRoleId?: number;
  };
};

export const emptyInvoiceLineItem = {
  amount: 0,
  discount: undefined,
  discountType: "percentage" as DiscountTypeType,
  itemId: 0,
  name: "",
  quantity: 1,
  taxId: 0,
  taxName: undefined,
  taxRate: undefined,
  uid: uuidv1(),
  unitPrice: 0
};

const defaultState: CollectedAccountState = {
  invoiceLineItems: {
    [emptyInvoiceLineItem.uid]: emptyInvoiceLineItem
  },
  isDisplayLoading: false,
  isFetching: false,
  isUpdatedStatus: false,
  items: [],
  list: [],
  mainCollectedAccount: {
    data: undefined,
    hasActiveProgram: false,
    isFetched: false,
    rates: [
      {
        brand_id: 0,
        fee_payer: 0,
        int_fee_payer: 0,
        international: 0,
        national: 0,
        crypto_rate: 0
      }
    ]
  },
  selectedItemsIds: {},
  selectedTaxesIds: {},
  taxes: [],
  totalCollectedAccounts: 0
};

export default (state: CollectedAccountState, action: types.Action) => {
  if (_isEmpty(state)) {
    state = defaultState;
  }

  switch (action.type) {
    case getType(actions.setTaxes):
      return {
        ...state,
        isFetching: action.payload.isFetching,
        taxes: action.payload.taxes
      };

    case getType(actions.setItems):
      return {
        ...state,
        isFetching: action.payload.isFetching,
        items: action.payload.items
      };

    case getType(actions.updateInvoiceLineItem):
      return {
        ...state,
        invoiceLineItems: {
          ...state.invoiceLineItems,
          [action.payload.uid]: {
            ...action.payload.item,
            uid: action.payload.uid
          }
        }
      };

    case getType(actions.deleteInvoiceLineItem):
      delete state.invoiceLineItems[action.payload.uid];

      return {
        ...state,
        invoiceLineItems: { ...state.invoiceLineItems }
      };

    case getType(actions.selectItemId):
      return {
        ...state,
        selectedItemsIds: {
          ...state.selectedItemsIds,
          [action.payload.name]: action.payload.id
        }
      };

    case getType(actions.deleteSelectItemId):
      delete state.selectedItemsIds[action.payload.name];

      return {
        ...state,
        selectItemsIds: { ...state.selectedItemsIds }
      };

    case getType(actions.selectTaxId):
      return {
        ...state,
        selectedTaxesIds: {
          ...state.selectedTaxesIds,
          [action.payload.name]: action.payload.id
        }
      };

    case getType(actions.deleteSelectedTaxId):
      delete state.selectedTaxesIds[action.payload.name];

      return {
        ...state,
        selectedTaxesIds: { ...state.selectedTaxesIds }
      };

    case getType(actions.setCollectedAccounts):
      return {
        ...state,
        list: action.payload.collectedAccounts,
        totalCollectedAccounts: action.payload.total
      };
    case getType(actions.displayLoading):
      return {
        ...state,
        isDisplayLoading: action.payload.value
      };

    case getType(actions.setMainCollectedAccount):
      return {
        ...state,
        mainCollectedAccount: {
          ...state.mainCollectedAccount,
          data: action.payload.data
            ? {
                ...state.mainCollectedAccount.data,
                ...action.payload.data
              }
            : state.mainCollectedAccount.data,
          hasActiveProgram: action.payload.hasActiveProgram,
          hasDraftPayment: action.payload.hasDraftPayment,
          accountUserRoleId: action.payload.accountUserRoleId,
          isFetched: action.payload.isFetched,
          rates: action.payload.rates
        }
      };

    case getType(actions.updateOtherSettingsCollectedAccount):
      if (state.mainCollectedAccount.data !== undefined) {
        return {
          ...state,
          mainCollectedAccount: {
            ...state.mainCollectedAccount,
            data: {
              ...state.mainCollectedAccount.data,
              otherSettings: action.payload
            }
          }
        };
      }
      break;

    case getType(actions.updateNextNumberInvoiceCollectedAccount):
      if (state.mainCollectedAccount.data !== undefined) {
        const otherSettings = _get(
          state.mainCollectedAccount.data,
          "otherSettings",
          {}
        );
        return {
          ...state,
          mainCollectedAccount: {
            ...state.mainCollectedAccount,
            data: {
              ...state.mainCollectedAccount.data,
              otherSettings: {
                ...otherSettings,
                invoiceNumberTemplate: action.payload.nextNumber
              }
            }
          }
        };
      }
      break;

    case getType(actions.setHasActiveProgram):
      if (state.mainCollectedAccount !== undefined) {
        return {
          ...state,
          mainCollectedAccount: {
            ...state.mainCollectedAccount,
            hasActiveProgram: action.payload.hasActiveProgram
          }
        };
      }
      return state;
    case getType(actions.selectAllItemIds):
      const data = action.payload.data;
      const newSelected = {};
      const newSelectedIds = {};
      const invoiceLineItemsNew = {};
      data.forEach((item: any) => {
        newSelected[item.uid] = item.id;
        if (item.taxId) {
          newSelectedIds[item.uid] = item.taxId;
        }
        invoiceLineItemsNew[item.uid] = {
          ...state.invoiceLineItems[item.uid],
          itemId: item.id,
          uid: item.uid,
          name: item.name,
          quantity: item.quantity ? item.quantity : 1,
          unitPrice: item.unitPrice,
          discount: item.discount,
          discountType: item.discountType,
          taxId: item.taxId,
          taxName: item.taxName,
          taxRate: item.taxRate,
          amount: item.unitPrice
        };
      });
      return {
        ...state,
        invoiceLineItems: {
          ...invoiceLineItemsNew
        },
        selectedItemsIds: {
          ...newSelected
        },
        selectedTaxesIds: {
          ...newSelectedIds
        }
      };
  }
  return state;
};
