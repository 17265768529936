import _get from "lodash-es/get";
import _isUndefined from "lodash-es/isUndefined";
import _concat from "lodash-es/concat";
import { getType } from "typesafe-actions";
import * as types from "./types";
import * as actions from "./actions";

export enum ValidationState {
  UNVALIDATED = 1,
  INVALID,
  VALID
}

type Field = {
  value: string;
  values?: any[];
  validationState: ValidationState;
};

export type FormsState = {
  readonly byId: { [form: string]: { [field: string]: Field } };
  readonly currentForm: string;
};

const defaultState: FormsState = {
  byId: {},
  currentForm: ""
};

export default (state = defaultState, action: types.Action) => {
  switch (action.type) {
    case getType(actions.setFormInput):
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.form]: {
            ...state.byId[action.payload.form],
            [action.payload.field]: {
              validationState: ValidationState.UNVALIDATED,
              value: action.payload.value
            }
          }
        },
        currentForm: action.payload.form
      };
    case getType(actions.setInvalidationState):
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.form]: {
            ...state.byId[action.payload.form],
            [action.payload.field]: {
              ...state.byId[action.payload.form][action.payload.field],
              validationState: ValidationState.INVALID
            }
          }
        },
        currentForm: action.payload.form
      };
    case getType(actions.appendFormInput):
      const values = _get(
        state.byId,
        `${action.payload.form}.${action.payload.field}.values`,
        []
      ) as any[] | undefined;
      let nextValues: any[];
      if (_isUndefined(values)) {
        nextValues = [action.payload.value];
      } else {
        nextValues = _concat(values, [action.payload.value]);
      }
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.form]: {
            ...state.byId[action.payload.form],
            [action.payload.field]: {
              validationState: ValidationState.UNVALIDATED,
              values: nextValues
            }
          }
        },
        currentForm: action.payload.form
      };
  }
  return state;
};
