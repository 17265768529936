import { connect } from "react-redux";
import * as cardSelectors from "src/ipm-shared/store/model/Card/selectors";
import { RootState } from "src/ipm-shared/store/model/reducers";
import * as selectors from "./selectors";
import * as actions from "./actions";
import { requireProfileComponent } from "src/ipm-shared/HOCs/RequiredProfile";
import { PURPOSE } from "src/ipm-shared/store/model/Payee/const";

import Component from "./View";

const mapStateToProps = (state: RootState, props: any) => ({
  cards: selectors.getCards(state, undefined, false, false, false, true),
  wpMasterCard: selectors.getCards(state, undefined, true, false, false, false),
  wpVisaCard: selectors.getCards(state, undefined, false, true, false, false),
  worldpayCards: selectors.getCards(state, undefined, true, true, true, false),
  earliestDate: selectors.getEarliestDate(state),
  shouldDisplayLoadingCards: selectors.shouldDisplayLoadingCards(state),
  firstCardIdInWallet: cardSelectors.getCards(state)[0]?.id,
  fees: selectors.getFees(state),
  getPayeesByPurpose: (purpose: PURPOSE) => selectors.getPayees(state, purpose),
  isLoadingPaymentDetails: selectors.shouldDisplayLoadingPayments(state),
  hasAtLeastOneCard: cardSelectors.getCards(state).length > 0,
  paymentRequest: selectors.getCurrentPaymentRequest(state),
  selectedId: selectors.getSelectedCard(state),
  countryCode: selectors.getAccountCountryCode(state),
  ipmMerchantCategories: selectors.getIpmMerchantCategories(state),
  getControl: (name: string, form: string) =>
    selectors.getControl(state, name, form),
  hasError: (formName: string) => selectors.hasError(state, formName, true),
  isProduction: selectors.getIsProduction(state),
  selectedPayees: selectors.getSelectedPayees(state),
  availableAcquirers: selectors.getAvailableAcquirers(state),
  isPersonalAccount: selectors.getIsPersonalAccount(state),
  isSingaporeAccount: selectors.isSingaporeAccount(state),
  features: selectors.getFeatures(state),
  getCurrentUserId: selectors.getCurrentUserId(state),
  paymentMethodType: selectors.getPaymentMethodType(state),
  isCryptoPaymentMethod: selectors.isCryptoPaymentMethod(state),
  canAccessCryptoPayment: selectors.canAccessCryptoPayment(state),
  isHongKongAccount: selectors.isHongKongAccount(state),
  isAustraliaAccount: selectors.isAustraliaAccount(state),
  isDisplayFlashpayFeesPopup: selectors.isDisplayFlashpayFeesPopup(state)
});

const mapDispatchToProps = {
  applyCoupon: actions.applyCoupon,
  fetchPayees: actions.fetchPayees,
  fetchPaymentRequest: actions.fetchPaymentRequest,
  fulfilPaymentSubmit: actions.fulfilPaymentSubmit,
  selectCard: actions.selectCard,
  toggleModal: actions.toggleModal,
  closeModal: actions.closeModal,
  openModal: actions.openModal,
  setControl: actions.setControl,
  validateIpmMerchantCategoryId: actions.validateIpmMerchantCategoryId,
  fetchIpmMerchantCategories: actions.fetchIpmMerchantCategories,
  setPaymentMethodType: actions.setPaymentMethodType,
  selectPaymentMethod: actions.selectPaymentMethod,
  fetchInsurancePayees: actions.fetchInsurancePayees
};

export default requireProfileComponent(
  connect(mapStateToProps, mapDispatchToProps)(Component)
);
