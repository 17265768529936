import React, { useState } from "react";
import classNames from "classnames";
import styles from "./StyledCalendar.module.scss";
import Drawer from "src/bepaid/components/Drawer";
import Calendar from "src/bepaid/components/Calendar";
import { isDesktop } from "react-device-detect";

const notes = (isEndDayNote: Boolean) => (
  <div className={styles.notesContainer}>
    <div className={classNames(styles.noteItem, styles.payoutDate)}>
      <div />
      {isEndDayNote ? (
        <span>
          Schedule End Date: Your schedule will end on this date, and your last
          payout will occur on or before this date, depending on your first
          payout date and your schedule frequency.
        </span>
      ) : (
        <span>
          Payout Date: Your recipient will receive the payment on this day
        </span>
      )}
    </div>
    <div
      className={classNames(styles.noteItem, {
        [styles.hidden]: isEndDayNote
      })}
    >
      <div />
      Charge Date: Your card will be charged on this day
    </div>
    <div className={classNames(styles.noteItem, styles.publicHoliday)}>
      <div />
      Public Holiday
    </div>
  </div>
);

const StyledCalendar = ({
  loading,
  isOpen,
  onClose,
  onSubmit,
  disabledDates,
  pspNonWorkingDates,
  earliestDate,
  flashPayDate,
  additionalModifiers,
  header,
  selectDate,
  onSelectDate,
  submitText,
  allowWeekends,
  isEndDayNote,
  isAUDeadline,
  isCustomCalendar,
  label,
  showTooltip,
  flashPayInfo,
  showFlashPay
}: any) => {
  const dayPickerProps = {
    weekdaysLong: ["S", "M", "T", "W", "T", "F", "S"],
    weekdaysShort: ["S", "M", "T", "W", "T", "F", "S"],
    className: styles.calendar,

    ...(additionalModifiers ? additionalModifiers : {})
  };

  const [calendarType, setCalendarType] = useState("default");

  const onClickChangeCalendarType = (value: string) => {
    setCalendarType(value);
  };

  return (
    <Drawer
      height="auto"
      placement="bottom"
      visible={isOpen}
      closable={true}
      closeIcon={null}
      onClose={onClose}
      // className={styles.wrapper}
    >
      <div
        className={classNames(styles.titleCalendar, {
          [styles.titleCalendarDesktop]: isDesktop
        })}
      >
        {header}
        {isCustomCalendar && calendarType === "default" && (
          <div
            className={styles.changeCalendarTypeBtn}
            onClick={onClickChangeCalendarType.bind(null, "custom")}
          >
            <p className={styles.changeCalendarTypeText}>
              Click here to enter a schedule end date instead.
            </p>
          </div>
        )}
        {isCustomCalendar && calendarType === "custom" && (
          <div
            className={styles.changeCalendarTypeBtn}
            onClick={onClickChangeCalendarType.bind(null, "default")}
          >
            <p className={styles.changeCalendarTypeText}>
              Click here to select a date from the calendar instead.
            </p>
          </div>
        )}
      </div>
      <Calendar
        submitText={submitText}
        loading={loading}
        selectDate={selectDate}
        onSelectDate={onSelectDate}
        dayPickerProps={dayPickerProps}
        earliestDate={earliestDate}
        flashPayDate={flashPayDate}
        disabledDays={disabledDates}
        pspNonWorkingDays={pspNonWorkingDates}
        notes={notes(isEndDayNote)}
        isEndDayNote={isEndDayNote}
        type={"payment"}
        onSubmit={onSubmit}
        range={false}
        allowWeekends={allowWeekends}
        label={label}
        calendarType={calendarType}
        showTooltip={showTooltip}
        flashPayInfo={flashPayInfo}
        showFlashPay={showFlashPay}
      />
    </Drawer>
  );
};

export default StyledCalendar;
