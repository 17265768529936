import React, { useState } from "react";
import { BrowserView, isDesktop, MobileView } from "react-device-detect";
import DesktopModalLayout from "src/bepaid/components/Modal/components/DesktopModalLayout";
import MainLayout from "src/bepaid/layouts/MainLayout";

import Button from "src/bepaid/components/Form/controls/Button";
import InputFile from "src/bepaid/components/Form/controls/InputFile";
import InputFileDropZone from "src/bepaid/components/Form/controls/InputFile/components/InputFileDropZone";
import IconUpload from "src/bepaid/assets/images/common/icon_upload.svg";

import Form from "src/bepaid/components/Form";
import FormErrors from "src/ipm-shared/components/Form/helpers/Errors";
import _isEmpty from "lodash-es/isEmpty";
import { VERIFY_IDENTITY_FORM } from "src/ipm-shared/store/model/Payment/const";
import { FetchModalID } from "src/ipm-shared/components/GlobalUI/actions";
import classnames from "classnames";
import styles from "./CryptoVerifyIdentity.module.scss";
import Typography from "antd/es/typography";

const View = (props: any) => {
  const [loading, setLoading] = useState(false);

  const validateField = () => {
    const errors = {
      fields: {},
      form: []
    };
    const supportingDocuments = props.getControl(
      "supporting_documents",
      VERIFY_IDENTITY_FORM
    );
    if (supportingDocuments && !supportingDocuments.value) {
      errors.fields["supporting_documents"] = ["REQUIRED_FIELD"];
    }
    if (!_isEmpty(errors.fields)) {
      props.parseServerErrors(errors);
      return false;
    }

    return true;
  };

  const onClose = () => {
    props.toggleModal(FetchModalID.CRYPTO_VERIFY_IDENTITY);
  };

  const moveForward = () => {
    setLoading(true); // ok

    setTimeout(() => {
      if (!validateField()) {
        return setLoading(false);
      }
      onClose();
      props.extraInfo.onSubmit();
    }, 500);
  };

  const renderContent = () => {
    return (
      <>
        <div
          className={classnames(styles.wrapper, {
            [styles.desktop]: isDesktop
          })}
        >
          <div className={styles.title}>
            <b>
              In order to proceed and to comply with evolving global
              regulations, we need to verify your identity.
            </b>
          </div>
          <div className={styles.wrapperForm}>
            <Form
              name="company-form"
              layout="vertical"
              requiredMark={false}
              className={styles.form}
            >
              <FormErrors form={VERIFY_IDENTITY_FORM} />
              <div className={styles.includeTexts}>
                <p>
                  Please upload a clear and in focus photo of the front of your
                  valid ID (National ID/Passport/Driver’s License.)
                </p>
                <p className={styles.helperText}>
                  Please ensure the details on your ID must be clearly visible.
                </p>
              </div>
              <InputFile
                allowedFileTypes={["jpg", "png", "heic", "pdf"]}
                name={"supporting_documents"}
                purpose={"id_verification"}
                form={VERIFY_IDENTITY_FORM}
                label={"Upload your front ID photo"}
                icon={IconUpload}
                canDrag={true}
                multiple={false}
                maxFileAmount={1}
                renderComponent={InputFileDropZone}
                defaultValue={[]}
                helperNote={
                  "Minimum file size: 2MB. Maximum file size: 16 MB. Supported file types: JPG, PDF, PNG."
                }
              />
            </Form>
          </div>
        </div>
      </>
    );
  };

  const renderBtnSubmit = () => {
    return (
      <div className={styles.groupButton}>
        <Button
          id={"ipaymy_btn_select_payment_option"}
          wrapperClassName={styles.wrapperButton}
          className={styles.button}
          onClick={moveForward}
          type="primary"
          htmlType="button"
          loading={loading}
        >
          {loading ? "Moving forward..." : "Move forward"}
        </Button>
        <div className={styles.helperText}>
          By selecting "Move forward" you agree to ipaymy's{" "}
          <Typography.Link
            href="https://www.ipaymy.com/privacy-policy"
            target="_blank"
            underline={true}
          >
            Privacy Policy.
          </Typography.Link>
        </div>
      </div>
    );
  };

  const renderDesktopView = () => {
    return (
      <DesktopModalLayout
        id={"ipaymy_verify_identity"}
        title={"Verify your identity"}
        onClose={onClose}
        // onBack={onBack}
        maxWidth450={true}
        onSubmit={moveForward}
        btnSubmit={renderBtnSubmit()}
      >
        {renderContent()}
      </DesktopModalLayout>
    );
  };

  const renderMobileView = () => {
    return (
      <MainLayout
        id={"ipaymy_verify_identity"}
        header={{
          title: "Verify your identity",
          hideMenu: true,
          onClose: onClose
          // onBack: onBack
        }}
        footer={renderBtnSubmit()}
      >
        {renderContent()}
      </MainLayout>
    );
  };

  return (
    <>
      <BrowserView>{renderDesktopView()}</BrowserView>
      <MobileView>{renderMobileView()}</MobileView>
    </>
  );
};

export default View;
