import React from "react";
import { isDesktop } from "react-device-detect";
import classNames from "classnames";
import Flex from "antd-mobile/es/flex";
import Typography from "antd/es/typography";
import Row from "antd/es/row";
import Col from "antd/es/col";

import Carousel from "../Carousel";
import Card from "../Card";

import styles from "./OverviewBox.module.scss";

const SummaryBox = (props: any) => {
  const { data = [], type = "" } = props;

  const Detail = ({
    item: { label, value, currency, percentage, isBigger, noPadding }
  }: any) => (
    <div
      className={classNames(styles.detail, {
        [styles.isBigger]: isBigger,
        [styles.noPadding]: noPadding
      })}
    >
      {noPadding ? (
        <Row justify="center">{value}</Row>
      ) : (
        <Row justify="center">
          <Col
            span={24}
            className={classNames(styles.title, styles.centerText)}
          >
            <Typography.Text>{label}</Typography.Text>
          </Col>
          <Col
            span={24}
            className={classNames(styles.amount, styles.centerText, {
              [styles.invoices]: type === "invoices"
            })}
          >
            {currency && (
              <Typography.Text className={styles.currency}>
                {currency}
              </Typography.Text>
            )}
            <Typography.Text>{value}</Typography.Text>
            {percentage && (
              <Typography.Text className={styles.percentage}>%</Typography.Text>
            )}
          </Col>
        </Row>
      )}
    </div>
  );

  return (
    <Carousel autoplay={true}>
      {data.map((item: any, index: number) => (
        <div key={index}>
          <Detail item={item} />
        </div>
      ))}
    </Carousel>
  );
};

const EmptyBox = (props: any) => {
  const { title, description } = props;

  return (
    <Flex justify="center" align="center" direction={"column"}>
      <Flex.Item
        span={24}
        className={classNames(styles.title, styles.centerText)}
      >
        <Typography.Text>{title}</Typography.Text>
      </Flex.Item>
      <Flex.Item
        span={24}
        className={classNames(styles.description, styles.centerText)}
      >
        <Typography.Text>{description}</Typography.Text>
      </Flex.Item>
    </Flex>
  );
};

const Component = (props: any) => {
  const {
    isEmptyData = true,
    emptyTitle,
    emptyDescription,
    data,
    type = ""
  } = props;
  return (
    <Card
      className={classNames(styles.wrapper, {
        [styles.emptyBox]: isEmptyData,
        [styles.desktop]: isDesktop
      })}
    >
      {isEmptyData ? (
        <EmptyBox title={emptyTitle} description={emptyDescription} />
      ) : (
        <SummaryBox data={data} type={type} />
      )}
    </Card>
  );
};

export default Component;
