import React, { useEffect, useState } from "react";

import { FORM } from "src/ipm-shared/store/model/Payee/const";
import ExpandBox from "../../ExpandBox";
import IconUpload from "src/bepaid/assets/images/common/icon_upload.svg";
import IconArrowDown from "src/bepaid/assets/images/common/drop_down_arrow_grey.svg";
import FormStyles from "src/bepaid/components/Form/CommonForm.module.scss";
import InputFile from "src/bepaid/components/Form/controls/InputFile";
import InputFileDropZone from "src/bepaid/components/Form/controls/InputFile/components/InputFileDropZone";
import Form from "src/bepaid/components/Form";
import InputText from "src/bepaid/components/Form/controls/InputText";
import Select from "src/bepaid/components/Form/controls/Select";
import Image from "src/bepaid/components/Image";
import Checkbox from "src/bepaid/components/Form/controls/Checkbox";
import SelectMultiLevelCustom from "src/bepaid/components/Form/controls/SelectMultiLevelCustom";

import CurrencyUtil from "src/ipm-shared/Utils/Currency";
import { getBanksOptionsByCountryId } from "src/ipm-shared/store/model/Bank/selectors";
import FileUtils from "src/ipm-shared/Utils/Files";
import _isEmpty from "lodash-es/isEmpty";
import _get from "lodash-es/get";
import IconPencil from "src/bepaid/assets/images/common/icon_pencil.svg";
import { useMakePaymentUrlParams } from "src/bepaid/pages/Payments/hooks";

import store from "src/ipm-shared/store";
import { history } from "src/ipm-shared/store";
import styles from "../../ExpandBox/ExpandBox.module.scss";
import ROUTES from "src/bepaid/routes";
import classNames from "classnames";

const InsuranceForm = ({
  accountCurrencyId,
  payee,
  validateAmount,
  checkValidNoteToRecipient,
  filesControl,
  accountCountryId,
  selectPayees,
  // list,
  extraPayeeUid,
  setExtraPayeeValue,
  formState,
  setControl,
  removeControlPattern,
  insurerList
}: any) => {
  const [allowEdit, setAllowEdit] = useState(false);
  const { entity, replaceRouteWithParams } = useMakePaymentUrlParams();
  const [defaultValues, setDefaultValues] = useState<any>();
  const [key, setKey] = useState(0);

  const filterBank = getBanksOptionsByCountryId(
    store.getState(),
    accountCountryId
  );

  const filterInsurances = insurerList.concat({
    value: "OTHER",
    label: "Others",
    children: []
  });

  const [supportingDocuments, setSupportingDocuments] = useState<any>({
    fetch: false,
    value: []
  });

  const setAmountValue = (id: number, e: any) => {
    const amount = e.target.value;
    if (extraPayeeUid) {
      setExtraPayeeValue(id, extraPayeeUid, "defaultAmount", amount);
    }
    validateAmount({
      amount: parseFloat(amount) * 100,
      form: FORM,
      fieldName: name,
      isWallet: true
    });
  };

  const onChangeComments = (id: number, e: any) => {
    const comment = e.target.value;
    checkValidNoteToRecipient(comment, e.target.name, false, entity, () => {
      if (extraPayeeUid) {
        setExtraPayeeValue(id, extraPayeeUid, "defaultComments", comment);
      }
    });
  };

  const onChangeInsurance = (value: string) => {
    if (value === "OTHER") {
      return history.push(
        replaceRouteWithParams(ROUTES.PAYMENTS_CREATION, {
          newEntity: "supplier",
          newAction: "add"
        })
      );
    }
    setAllowEdit(false);
    if (!_isEmpty(payee)) {
      removeControlPattern(new RegExp(`${payee?.id}`));
    }
    const ids = [value];
    selectPayees({ purpose: "insurance", ids, removedIds: "all" });
  };

  const onOpenEditAccountNumber = (name: string) => {
    setAllowEdit(prev => {
      if (!prev) {
        setControl({
          FORM,
          name: name,
          value: ""
        });
        return !prev;
      }
      return prev;
    });
  };

  useEffect(() => {
    if (!_isEmpty(filesControl)) {
      const data = FileUtils.getInfoFromKeys(
        _get(filesControl, `${payee?.id}`, [])
      );
      setSupportingDocuments({
        ...supportingDocuments,
        fetch: true,
        value: data
      });
    } else {
      setSupportingDocuments({
        ...supportingDocuments,
        fetch: true
      });
    }
  }, []);

  useEffect(() => {
    if (payee?.id) {
      setKey(payee.id);
      setDefaultValues({
        accountNumber: _get(payee, "accountNumber", ""),
        defaultAmount: _get(formState, `supplier_amount_insurance.value`, ""),
        defaultComments: _get(
          formState,
          `default_comments_insurance.value`,
          ""
        ),
        bankId: _get(payee, "bankId", null)
      });
    }
  }, [payee]);

  const amountInputTextProps = {
    labelNoColon: true,
    name: extraPayeeUid
      ? `supplier_amount_${payee?.id}_${extraPayeeUid}`
      : payee?.id
      ? `supplier_amount_${payee?.id}`
      : `supplier_amount_insurance`,
    placeholder: `0.00`,
    label: "Amount",
    prefix: CurrencyUtil.convertFromId(accountCurrencyId as number).$,
    form: FORM,
    pattern: "__MONEY__",
    defaultValue: extraPayeeUid
      ? null
      : payee?.id
      ? defaultValues?.defaultAmount
      : null,
    revertValueOnMount: true,
    reserveValueOnUnmount: true,
    onChange: setAmountValue.bind(null, payee?.id)
  };

  const defaultCommentsInputTextProps = {
    name: extraPayeeUid
      ? `default_comments_${payee?.id}_${extraPayeeUid}`
      : payee?.id
      ? `default_comments_${payee?.id}`
      : "default_comments_insurance",
    placeholder: "Insurance policy number",
    label: "Insurance policy number",
    form: FORM,
    maxlength: 35,
    pattern: "__STATEMENT__",
    revertValueOnMount: true,
    reserveValueOnUnmount: true,
    onChange: onChangeComments.bind(null, payee?.id),
    defaultValue: extraPayeeUid
      ? ""
      : payee?.id
      ? defaultValues?.defaultComments
      : ""
  };
  `account_number_${
    extraPayeeUid ? `${payee?.id}_${extraPayeeUid}` : payee?.id
  }`;
  const accountNumberInputTextProps = {
    name: `account_number_${
      extraPayeeUid
        ? `${payee?.id}_${extraPayeeUid}`
        : payee?.id
        ? payee?.id
        : "account_number_insurance"
    }`,
    placeholder: "Account number",
    form: FORM,
    label: "Account number",
    defaultValue: _get(payee, "accountNumber", ""),
    showEditIcon: true,
    disabled: !allowEdit,
    suffix: (
      <Image
        src={IconPencil}
        alt={`update account_number_insurance`}
        onClick={() =>
          onOpenEditAccountNumber(
            `confirm_account_number_${
              extraPayeeUid
                ? `${payee?.id}_${extraPayeeUid}`
                : payee?.id
                ? payee?.id
                : "insurance"
            }`
          )
        }
      />
    ),
    reserveValueOnUnmount: true,
    revertValueOnMount: true,
    className: classNames(styles.allowEditInputText, {
      [styles.hasConfirmCheckbox]: allowEdit
    })
  };

  const bankIdSelectProps = {
    form: FORM,
    name: `bank_id_${
      extraPayeeUid
        ? `${payee?.id}_${extraPayeeUid}`
        : payee?.id
        ? payee?.id
        : "bank_id_insurance"
    }`,
    placeholder: "Select bank",
    label: "Bank",
    options: filterBank,
    suffixIcon: <Image src={IconArrowDown} />,
    defaultValue: defaultValues?.bankId,
    reserveValueOnUnmount: true,
    revertValueOnMount: true
  };

  const supportingDocumentsInputFileProps = {
    formItemLabel: "",
    name: `supporting_documents_${
      extraPayeeUid
        ? `${payee?.id}_${extraPayeeUid}`
        : payee?.id
        ? payee?.id
        : "insurance"
    }`,
    btnClassName: FormStyles.boxShadow,
    allowedFileTypes: ["jpg", "png", "heic", "pdf"],
    purpose: "bank_statement",
    form: FORM,
    label: "Upload Bank Statement",
    icon: IconUpload,
    canDrag: true,
    multiple: true,
    renderComponent: InputFileDropZone,
    revertValueOnMount: true,
    reserveValueOnUnmount: true
  };

  return (
    <Form requiredMark={false} layout="vertical">
      {!extraPayeeUid && (
        <SelectMultiLevelCustom
          form={FORM}
          name={"insurance_id"}
          placeholder={"Insurance provider"}
          label={"Insurance provider"}
          options={filterInsurances}
          showSearch={false}
          allowClear={false}
          suffixIcon={<Image src={IconArrowDown} />}
          onChange={onChangeInsurance}
          defaultValue={_get(payee, "id")}
          reserveValueOnUnmount={true}
          revertValueOnMount={true}
          payeeNum={0}
        />
      )}
      {_isEmpty(payee) && (
        <>
          <div className={styles.inputMoney}>
            <InputText {...amountInputTextProps} />
          </div>
          <InputText {...defaultCommentsInputTextProps} />
          <Select {...bankIdSelectProps} />
          <InputText {...accountNumberInputTextProps} />
          <p className={styles.invoiceFieldInstruction}>
            Your insurance policy document:
          </p>
          {supportingDocuments.fetch && (
            <InputFile {...supportingDocumentsInputFileProps} />
          )}
        </>
      )}
      {!_isEmpty(payee) && (
        <div key={key}>
          <div className={styles.inputMoney}>
            <InputText {...amountInputTextProps} />
          </div>
          <InputText {...defaultCommentsInputTextProps} />
          <Select {...bankIdSelectProps} />
          <InputText {...accountNumberInputTextProps} />
          <Checkbox
            hidden={!allowEdit}
            name={`confirm_account_number_${
              extraPayeeUid
                ? `${payee?.id}_${extraPayeeUid}`
                : payee?.id
                ? payee?.id
                : "insurance"
            }`}
            presentedValue="checked"
            singleSelect={true}
            defaultChecked={!allowEdit}
            wrapperClassName={styles.checkboxForm}
            form={FORM}
            label={
              "I confirm these bank details are relevant to my insurer’s policy."
            }
          />
          <p className={styles.invoiceFieldInstruction}>
            Your insurance policy document:
          </p>
          {supportingDocuments.fetch && (
            <InputFile {...supportingDocumentsInputFileProps} />
          )}
        </div>
      )}
    </Form>
  );
};

const View = (props: any) => {
  return (
    <ExpandBox
      name={props.payee?.name}
      hideExpandBtn={true}
      onlyShowChildren={true}
      onClickDelete={props.onClickDeletePayee}
    >
      <InsuranceForm {...props} />
    </ExpandBox>
  );
};

export default View;
