import { connect } from "react-redux";

import { RootState } from "src/ipm-shared/store/model/reducers";
import * as selectors from "src/bepaid/store/model/Fetch/selectors";

import View from "./View";
import * as actions from "src/bepaid/store/model/Fetch/actions";

const mapStateToProps = (state: RootState, props: any) => ({
  collectedAccount: selectors.getCollectedAccount(state),
  isSingaporeAccount: selectors.isSingaporeAccount(state),
  isPersonalAccount: selectors.getIsPersonalAccount(state)
});

const mapDispatchToProps = {
  fetchMyInfoSingPass: actions.fetchMyInfoSingPass,
  toggleModal: actions.toggleModal,
  openModal: actions.openModal
};

export type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(View);
