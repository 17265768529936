import * as React from "react";

interface IProps {
  className: string;
  width?: number;
  height?: number;
}

class IconQuestionError extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }
  public render() {
    const { className, width, height } = this.props;
    return (
      <span
        className={`icon-question-error ${className}`}
        dangerouslySetInnerHTML={{
          __html: `<svg width="${width ? width : 14}" height="${
            height ? height : 14
          }" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12.91 12.91'><defs><style>.icon-question-error .cls-1{fill:none;stroke:#9f2159;stroke-miterlimit:10;stroke-width:0.81px;}.icon-question-error .cls-2{fill:#9f2159;}</style></defs><g id='Layer_2' data-name='Layer 2'><g id='Layer_1-2' data-name='Layer 1'><circle class='cls-1' cx='6.45' cy='6.45' r='6.05'/><path class='cls-2' d='M5.39,8.88c-.28,1.08,0,1.32.42,1.32a4.24,4.24,0,0,0,2.4-1.32L8,8.57A2,2,0,0,1,7.07,9C7,9,6.94,8.9,7,8.54l.53-2c.2-.74.13-1.21-.27-1.21a5.39,5.39,0,0,0-2.6,1.3l.2.33a3,3,0,0,1,1-.42c.09,0,.08.12,0,.43Z'/><path class='cls-2' d='M8.09,3.43a.67.67,0,0,0-.77-.74,1,1,0,0,0-1.11.93c0,.47.25.75.81.75A1,1,0,0,0,8.09,3.43Z'/></g></g></svg>`
        }}
      />
    );
  }
}

export default IconQuestionError;
