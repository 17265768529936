import { RouteProps } from "react-router";
import { connect } from "react-redux";

import { RootState } from "src/ipm-shared/store/model/reducers";
import * as PaymentActions from "src/ipm-shared/store/model/Payment/actions";
import * as PaymentSelectors from "src/ipm-shared/store/model/Payment/selectors";
import * as PaymentPanelsActions from "src/ipm-platform/pages/Dashboard/BePaid/PaymentPanels/actions";
import * as FetchActions from "src/bepaid/store/model/Fetch/actions";

import * as selectors from "src/bepaid/store/model/Fetch/selectors";

import Component from "./View";

const mapStateToProps = (state: RootState) => ({
  currencyId: selectors.getAccountCurrencyId(state),
  hasToken: selectors.hasToken(state),
  invoiceDetail: PaymentSelectors.getSelectedPaymentDetail(state)
});

const mapDispatchToProps = {
  getInvoiceDetail: PaymentActions.selectPayment,
  remindPaymentRequest: PaymentPanelsActions.remindPaymentRequest,
  toggleModal: FetchActions.toggleModal
};

export type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteProps;

export default connect(mapStateToProps, mapDispatchToProps)(Component);
