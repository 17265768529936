import { connect } from "react-redux";
import Component from "./View";
import { RootState } from "src/ipm-shared/store/model/reducers";
import * as actions from "src/ipm-shared/store/model/AccountUsers/actions";
import * as FormAction from "src/ipm-shared/components/Form/actions";
import * as GlobalUISelectors from "src/ipm-shared/components/GlobalUI/selectors";

const mapStateToProps = (state: RootState, props: any) => ({
  extraInfo: GlobalUISelectors.getExtraInfo(state, props.modalId)
});

const mapDispatchToProps = {
  toggleModal: FormAction.toggleModal,
  deleteAccountUser: actions.deleteAccountUser
};

// Fixed template
export type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Component);
