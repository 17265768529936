import * as React from "react";
import { connect } from "react-redux";
import * as selectors from "../selectors";
import * as actions from "../actions";
import { RootState } from "src/ipm-shared/store/model/reducers";
import { ControlErrorType } from "../types";
import Tooltip from "reactstrap/lib/Tooltip";

const mapStateToProps = (
  state: RootState,
  props: {
    name: string;
  }
): {
  errors: ControlErrorType[];
  displayError?: boolean;
} => {
  try {
    return {
      errors: selectors.getControl(state, props.name).errors
    };
  } catch (e) {
    return {
      errors: []
    };
  }
};

const mapDispatchToProps = {
  resetFormErrors: actions.resetFormErrors
};

type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps & {
    className?: string;
    errorStyle?: "__TOOLTIP__";
    name: string;
    id?: string;
    overrideMessage?: (errors: ControlErrorType) => React.ReactNode;
  };

type IState = {
  errorTooltipLabel?: boolean;
};
/**
 * This is one of common controls in the entire app.
 * Use this when you want to show Field errors.
 */
class FieldErrors extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      errorTooltipLabel: false
    };
  }
  public render() {
    const { errors, id } = this.props;
    if (this.props.errorStyle === "__TOOLTIP__") {
      if (errors.length === 0) {
        return null;
      }

      return (
        <React.Fragment>
          <div className={"d-flex csv-label-only" + this.props.className}>
            {id && (
              <i
                id={`tooltip-label-${id}`}
                className="tooltip-feedback-icon label-only"
                onClick={this.preventFromClickTooltip}
              >
                <img
                  width="12"
                  height="12"
                  src="data:image/svg+xml;charset=UTF-8,%3csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11.02 12.52'%3e%3cdefs%3e%3cstyle%3e.cls-1%7bfill:%23a6babc;%7d.cls-2%7bfont-size:8.21px;fill:%23fff;font-family:Helvetica-Bold, Helvetica;font-weight:700;%7d%3c/style%3e%3c/defs%3e%3ctitle%3eArtwork9_53714e77-0e54-4404-bf5e-6ddab1c33db2%3c/title%3e%3ccircle class='cls-1' cx='5.51' cy='5.51' r='5.51'/%3e%3ctext class='cls-2' transform='matrix(1, 0, 0, 1, 2.94, 8.56)'%3e?%3c/text%3e%3c/svg%3e"
                />
              </i>
            )}
          </div>

          {id && (
            <Tooltip
              placement="top-start"
              delay={0}
              target={`tooltip-label-${id}`}
              className="csv-error-tooltip"
              isOpen={this.state.errorTooltipLabel}
              toggle={this.errorTooltipLabel}
            >
              {errors.map((error, idx) => (
                <span key={idx} className="d-block">
                  {this.props.overrideMessage
                    ? this.props.overrideMessage(error)
                    : error.message}
                </span>
              ))}
            </Tooltip>
          )}
        </React.Fragment>
      );
    }
    return (
      <div className={"fz-12 " + this.props.className}>
        {errors.map((error, idx) => (
          <span key={idx} className="form-invalid-feedback">
            {error.message}
          </span>
        ))}
      </div>
    );
  }

  private preventFromClickTooltip = (e: React.MouseEvent<any>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  private errorTooltipLabel = () => {
    this.setState({
      errorTooltipLabel: !this.state.errorTooltipLabel
    });
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FieldErrors);
