import React, { useEffect, useState } from "react";
import StyledCalendar from "../StyledCalendar";
import CalendarInput from "src/bepaid/components/Calendar/components/CalendarInput";
import styles from "./ScheduleForm.module.scss";
import DateUtils from "src/ipm-shared/Utils/Date";

const RecurringDatesSelect = ({
  loading,
  value,
  onSelectRecurringStartDate, // this for on click a date in calendar
  onSelectRecurringEndDate,
  selectDate,
  earliestDate,
  exclusionDates,
  pspNonWorkingDates,
  chargeDate,
  chargeDateEnd,
  showTooltip,
  label,
  disableSelectNextYear,
  showHelperText
}: any) => {
  const [isFirstPayoutDatePickerOpen, openFirstPayoutDatePicker] = useState(
    false
  );
  const [isLastPayoutDatePickerOpen, openLastPayoutDatePicker] = useState(
    false
  );

  const selectFirstPaymentDate = (d: Date) => {
    openFirstPayoutDatePicker(false);
    selectDate(d, value.lastDate);
  };

  const selectLastPaymentDate = (d: Date) => {
    openLastPayoutDatePicker(false);
    selectDate(value.firstDate, d);
  };

  useEffect(() => {
    if (disableSelectNextYear) return;
    if (
      earliestDate &&
      (!value.firstDate ||
        (value.firstDate &&
          new Date(earliestDate).getTime() >
            new Date(value.firstDate).getTime()))
    ) {
      selectDate(earliestDate, value.lastDate);
    }
  }, [earliestDate, value.firstDate]);

  useEffect(() => {
    if (disableSelectNextYear) return;
    if (!value.lastDate) {
      selectDate(
        value.firstDate,
        DateUtils.getDayNextYear(new Date().toString())
      );
    }
  }, [value.lastDate]);

  return (
    <React.Fragment>
      <CalendarInput
        label={label}
        labelClass={styles.calendarLabel}
        btnClass={styles.calendarInput}
        iconClass={styles.iconCalendar}
        value={value.firstDate}
        onClick={openFirstPayoutDatePicker.bind(null, true)}
      />

      <StyledCalendar
        selectDate={value.firstDate}
        submitText={loading ? "Selecting payout date..." : "Select"}
        loading={loading}
        isOpen={isFirstPayoutDatePickerOpen}
        onSelectDate={onSelectRecurringStartDate}
        onSubmit={selectFirstPaymentDate}
        onClose={openFirstPayoutDatePicker.bind(null, false)}
        earliestDate={earliestDate}
        disabledDates={exclusionDates}
        pspNonWorkingDates={pspNonWorkingDates}
        additionalModifiers={{
          chargeDate,
          publicHoliday: exclusionDates,
          modifiers: {
            today: chargeDate || new Date()
          }
        }}
        header="Select the first payout date"
        showTooltip={showTooltip}
      />

      <CalendarInput
        label="Schedule end date"
        labelClass={styles.calendarLabel}
        btnClass={styles.calendarInput}
        iconClass={styles.iconCalendar}
        value={value.lastDate}
        onClick={openLastPayoutDatePicker.bind(null, true)}
      />
      {showHelperText && (
        <p className={styles.helperText}>
          If scheduled payments fall on a public holiday or weekend, your
          recipient will receive the payment one business day before the due
          date.
        </p>
      )}

      <StyledCalendar
        selectDate={value.lastDate}
        isOpen={isLastPayoutDatePickerOpen}
        onSelectDate={onSelectRecurringEndDate}
        onSubmit={selectLastPaymentDate}
        onClose={openLastPayoutDatePicker.bind(null, false)}
        earliestDate={earliestDate}
        disabledDates={exclusionDates}
        pspNonWorkingDates={pspNonWorkingDates}
        additionalModifiers={{
          chargeDate: chargeDateEnd,
          publicHoliday: exclusionDates,
          modifiers: {
            today: chargeDateEnd || new Date()
          }
        }}
        header="Select schedule end date"
        isEndDayNote={true}
        isCustomCalendar={true}
        label={"Schedule end date"}
      />
    </React.Fragment>
  );
};

export default RecurringDatesSelect;
