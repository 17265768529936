/* tslint:disable:object-literal-sort-keys */
import React, { useState } from "react";
import format from "date-fns/format";
import { Redirect } from "react-router-dom";
import classNames from "classnames";
import queryString from "query-string";
import uuidV1 from "uuid/v1";
import _get from "lodash-es/get";
import _find from "lodash-es/find";
import { isDesktop } from "react-device-detect";
import { BrowserView, MobileView } from "react-device-detect";

import Row from "antd/es/row";
import Col from "antd/es/col";
import Typography from "antd/es/typography";
import Checkbox from "antd/es/checkbox";

import ShortCurrency from "src/ipm-shared/components/ShortCurrency";
import Pagination from "src/bepaid/components/Pagination";
import { history } from "src/ipm-shared/store";
import IncentiveType from "src/ipm-shared/store/metadata/incentive_type";
import {
  CREATE_INVOICE_FORM,
  DRAFT_INVOICE_SETTING_FORM,
  TermsOptions
} from "src/ipm-shared/store/model/CollectedAccount/const";
import { INCENTIVES_DETAIL_FORM } from "src/ipm-shared/store/model/Customer/const";
import { FetchModalID } from "src/ipm-shared/components/GlobalUI/actions";

import useQuery from "src/bepaid/hooks/useQuery";
import ROUTES from "src/bepaid/routes";
import Card from "src/bepaid/components/Card";
import Image from "src/bepaid/components/Image";
import Skeleton from "src/bepaid/components/Skeleton";
import OverviewBox from "src/bepaid/components/OverviewBox";
import FilterTags from "src/bepaid/components/FilterTags";

import InvoiceAvatar from "src/bepaid/assets/images/invoices/invoices_avatar.svg";
import NextIcon from "src/bepaid/assets/images/common/desktop/next_black_noborder.svg";

import { INVOICES_PAGE_COUNT } from "src/bepaid/store/model/Fetch/const";

import styles from "../../Invoices.module.scss";

const incentiveDefault = IncentiveType.map((item: any) => {
  return {
    ...item,
    open: false,
    rate: item.default_rate
  };
});

const initPagination = {
  offset: 0,
  pageCount: INVOICES_PAGE_COUNT
};

const InvoiceListContent = (props: any) => {
  const {
    currencyId,
    currencySymbol,
    hasToken,
    list,
    statistics,
    getInvoiceList: getInvoiceListProp,
    getInvoiceStatistics,
    total
    // otherSettings,
  } = props;

  const [loadingStatistic, setLoadingStatistic] = React.useState(false);
  const [loadingInvoice, setLoadingInvoice] = React.useState(false);
  const [pagination, setPagination] = React.useState(initPagination);
  const qs = useQuery();

  const {
    totalPaymentsReceived = 0,
    numberInvoiceSent = 0,
    percentagePunctualInvoices = 0
  } = statistics;

  const isEmptyData =
    !totalPaymentsReceived && !numberInvoiceSent && !percentagePunctualInvoices;

  const overviewData = [
    {
      key: 1,
      label: "Total payments received",
      value: (
        <ShortCurrency
          value={totalPaymentsReceived}
          removedZeroDecimal={true}
          fractionDigits={0}
        />
      ),
      currency: currencySymbol
    },
    {
      key: 2,
      label: "Number of invoices sent",
      value: numberInvoiceSent
    },
    {
      key: 3,
      label: "Percentage of punctual invoices",
      value: percentagePunctualInvoices,
      percentage: true
    }
  ];

  const isDraftInvoice =
    (qs.statuses === "1" && qs.key === "invoices") || props.isDraft;

  const hasQueryParams = Object.keys(qs).length > 0;

  const getInvoiceList = (queryParams: any, cb?: any) => {
    getInvoiceListProp(
      queryParams,
      cb,
      setLoadingInvoice,
      pagination.offset,
      pagination.pageCount
    );
  };

  // const fetchInvoices = () => {
  //   getInvoiceStatistics(setLoadingStatistic);

  //   getInvoiceList(qs);
  // };

  const shouldFetchData = !(props.isBgOfPopup && props.isFirstFetchData);

  const handleOnChangePage = (offset: number) => {
    setPagination({ ...pagination, offset });
  };

  React.useEffect(() => {
    if (props.isDraft) {
      qs.statuses = "1";
      qs.key = "invoices";
      qs.is_modal_draft = "y";
      getInvoiceList(qs, undefined);
    }
  }, [props.isDraft]);

  React.useEffect(() => {
    if (shouldFetchData) {
      getInvoiceStatistics(undefined, setLoadingStatistic);
    }
  }, []);

  React.useEffect(() => {
    if (shouldFetchData) {
      getInvoiceList(qs);
    }
  }, [pagination]);

  React.useEffect(() => {
    // if (shouldFetchData) {
    //   getInvoiceList(qs);
    // }
    return () => {
      setPagination(JSON.parse(JSON.stringify(initPagination)));
    };
  }, [
    qs.statuses,
    qs.from,
    qs.to,
    qs.search_keyword,
    qs.dateQueryKey,
    props.openCreateInvoice
  ]);

  const handleSetNewQuery = (newQuery: any) => {
    history.push(
      `${ROUTES.INVOICES}/?${queryString.stringify(newQuery || {})}`
    );
  };

  const onSelectInvoice = (invoice: any) => () => {
    // Invoice draft
    if (invoice.paymentStatusId === 1) {
      props.selectInvoice(invoice.id);
      const ids = [];
      ids.push(invoice.payer.id);
      props.selectCustomers(ids);
      if (invoice) {
        initFormCreateInvoice(invoice);
        if (props.updateExtraInfo && isDesktop) {
          props.updateExtraInfo(FetchModalID.CREATE_INVOICE, {
            step: invoice.step
          });
        }
        if (props.offDraft) {
          props.offDraft();
        }
        switch (invoice.step) {
          case 2:
            history.push(`${ROUTES.CREATE_INVOICES_STEP2}`);
            return;
          case 3:
            history.push(`${ROUTES.CREATE_INVOICES_STEP3}`);
            return;
          case 4:
            history.push(`${ROUTES.CREATE_INVOICES_STEP4}`);
            return;
          default:
            history.push(`${ROUTES.CREATE_INVOICES_STEP1}`);
            return;
        }
      }
      // props.toggleModal(FetchModalID.CREATE_INVOICE);
      history.push(`${ROUTES.CREATE_INVOICES}`);

      return;
    }

    history.push(`${ROUTES.INVOICES}/${invoice.id}`);
    return;
  };

  const initFormCreateInvoice = (invoice: any) => {
    if (!invoice.payer) {
      return;
    }
    props.setControl({
      errors: [],
      form: CREATE_INVOICE_FORM,
      name: `customerName`,
      value: _get(invoice.payer, "name")
    });
    props.setControl({
      errors: [],
      form: CREATE_INVOICE_FORM,
      name: `customer`,
      value: _get(invoice.payer, "id")
    });
    props.setControl({
      errors: [],
      form: CREATE_INVOICE_FORM,
      name: `payment_request_id_${invoice.payer.id}`,
      value: _get(invoice, "paymentRequestId")
    });
    props.setControl({
      errors: [],
      form: CREATE_INVOICE_FORM,
      name: `supplier_amount_${invoice.payer.id}`,
      value: _get(invoice, "payees[0].gross_amount", 0) / 100
    });
    props.setControl({
      errors: [],
      form: CREATE_INVOICE_FORM,
      name: `due_date_${invoice.payer.id}`,
      value: _get(invoice, "payees[0].due_date")
    });
    props.setControl({
      errors: [],
      form: CREATE_INVOICE_FORM,
      name: `invoice_date_${invoice.payer.id}`,
      value: _get(invoice, "payees[0].invoice_date")
    });
    const customerData = _get(props.getCustomerByIds, `${invoice.payer.id}`);
    if (customerData) {
      props.setControl({
        errors: [],
        form: CREATE_INVOICE_FORM,
        name: `mail_to_${invoice.payer.id}`,
        value: _get(customerData, "email")
      });
    }
    props.setControl({
      errors: [],
      form: CREATE_INVOICE_FORM,
      name: `mail_reply_to_${invoice.payer.id}`,
      value: _get(props.collectedAccount, "payeeData.recipientEmail")
    });
    props.setControl({
      errors: [],
      form: CREATE_INVOICE_FORM,
      name: `terms_${invoice.payer.id}`,
      value: _get(
        TermsOptions.find(
          item => item.label === _get(invoice, "payees[0].terms")
        ),
        "value",
        0
      )
    });
    props.setControl({
      errors: [],
      form: CREATE_INVOICE_FORM,
      name: `invoice_number_digits_${invoice.payer.id}`,
      value: _get(invoice, "payees[0].invoice_number_digits")
    });
    props.setControl({
      errors: [],
      form: CREATE_INVOICE_FORM,
      name: `payment_description_${invoice.payer.id}`,
      value: _get(invoice, "payees[0].payment_description")
    });
    const incentivesData = _get(invoice, "payees[0].incentives", []);
    const idsSelect: any[] = [];
    incentivesData?.forEach((item: any) => {
      idsSelect.push(item.incentive_type_id);
    });
    const newIncentives = incentiveDefault.map(item =>
      idsSelect.includes(item.id)
        ? {
            ...item,
            incentive_type_id: item.id,
            open: true,
            rate: incentivesData.find(
              (d: any) => d.incentive_type_id === item.id
            ).rate
          }
        : {
            ...item,
            incentive_type_id: item.id,
            open: false
          }
    );
    props.setControl({
      errors: [],
      form: INCENTIVES_DETAIL_FORM,
      name: `incentives_${invoice.payer.id}`,
      value: newIncentives
    });
    props.setControl({
      errors: [],
      form: CREATE_INVOICE_FORM,
      name: `term_and_condition`,
      value: _get(invoice, "termAndCondition")
    });
    props.setControl({
      errors: [],
      form: CREATE_INVOICE_FORM,
      name: `invoice_note`,
      value: _get(invoice, "invoiceNote")
    });
    props.setControl({
      errors: [],
      form: CREATE_INVOICE_FORM,
      name: `document_tag_supporting_documents_${invoice.payer.id}`,
      value: invoice.payer.id
    });
    props.setControl({
      errors: [],
      form: CREATE_INVOICE_FORM,
      name: `supporting_documents_${invoice.payer.id}`,
      value: _get(invoice, "supportingDocuments[0].key", null)
    });
    props.setControl({
      errors: [],
      form: CREATE_INVOICE_FORM,
      name: `po_number`,
      value: _get(invoice, "payees[0].po_number", null)
    });
    props.setControl({
      errors: [],
      form: CREATE_INVOICE_FORM,
      name: `to_create_or_upload_invoice`,
      value: _get(invoice, "payees[0].to_create_or_upload_invoice", null)
    });
    props.setControl({
      errors: [],
      form: CREATE_INVOICE_FORM,
      name: `calculate_method`,
      value: _get(
        invoice,
        "payees[0].calculate_method",
        "calculate_tax_per_item"
      )
    });

    const items = _get(invoice, "payees[0].items", []);
    if (items) {
      const payload = items.map((item: any) => {
        return {
          ...item,
          discountType: item.discount_type,
          id: item.item_id,
          quantity: item.quantity,
          taxId: item.tax_id,
          taxName: _get(_find(props.taxes, { id: item.tax_id }), "name"),
          taxRate: _get(_find(props.taxes, { id: item.tax_id }), "rate"),
          // tslint:disable-next-line:object-literal-sort-keys
          uid: uuidV1(),
          unitPrice: item.unit_price
        };
      });
      props.selectAllItemIds(payload);
    } else {
      props.selectAllItemIds([]);
    }

    const requesterRates = _get(invoice, "payees[0].requester_rates", []);
    if (requesterRates) {
      requesterRates.forEach((requester: any) => {
        if (requester.brand_id === 2) {
          props.setControl({
            errors: [],
            form: CREATE_INVOICE_FORM,
            name: `requester_international_rate_visa_${invoice.payer.id}`,
            value: _get(requester, "international", 0)
          });
          props.setControl({
            errors: [],
            form: CREATE_INVOICE_FORM,
            name: `requester_national_rate_visa_${invoice.payer.id}`,
            value: _get(requester, "national", 0)
          });
        } else if (requester.brand_id === 5) {
          props.setControl({
            errors: [],
            form: CREATE_INVOICE_FORM,
            name: `requester_international_rate_union_${invoice.payer.id}`,
            value: _get(requester, "international", 0)
          });
          props.setControl({
            errors: [],
            form: CREATE_INVOICE_FORM,
            name: `requester_national_rate_union_${invoice.payer.id}`,
            value: _get(requester, "national", 0)
          });
        }
      });
    }
  };

  const [isRemindDraft, setRemindDraft] = useState(
    _get(props.collectedAccount, "payeeData.remindDraftInvoice")
  );

  const onChangeCheckBox = (e: any) => {
    setRemindDraft(e.target.checked);
    props.setControl({
      errors: [],
      form: DRAFT_INVOICE_SETTING_FORM,
      name: `remind_draft_invoice`,
      value: e.target.checked
    });
    props.updateDraftInvoiceSetting(
      DRAFT_INVOICE_SETTING_FORM,
      true,
      (err?: any) => {
        if (!err) {
          props.fetchMainCollectedAccount();
        }
      }
    );
  };

  const CheckBoxDraft = () => {
    return (
      <>
        <Checkbox
          className={styles.checkbox}
          name={"checkboxRemindDraftInvoice"}
          onChange={onChangeCheckBox}
          checked={isRemindDraft}
        >
          <Typography className={styles.title}>
            Remind me about draft invoices when I create a new invoice.
          </Typography>
        </Checkbox>
        <Typography className={styles.subTitle}>
          You can control this reminder under “Invoice settings” on your menu
          bar.
        </Typography>
      </>
    );
  };

  const CardDetails = ({
    icon,
    title,
    description,
    amount,
    payoutDate,
    receiptNumber,
    paymentStatusId,
    onClick
  }: any) => {
    return (
      <Card className={styles.cardDetails}>
        <BrowserView>
          <Row align="middle" justify="space-between" onClick={onClick}>
            <Col span={5}>
              <Image src={icon} />
            </Col>
            <Col span={11} className={styles.alignJustify}>
              <Row>
                <Col span={24}>
                  <Typography.Text className={styles.title}>
                    {receiptNumber && `Receipt: ${receiptNumber}`}
                  </Typography.Text>
                </Col>
                <Col span={24}>
                  <Typography.Text className={styles.description}>
                    {description}
                  </Typography.Text>
                </Col>
              </Row>
            </Col>
            {receiptNumber && (
              <Col span={6} className={styles.middleFlex}>
                <Row>
                  <Col span={24}>
                    <Typography.Text className={styles.title}>
                      Payout Date
                    </Typography.Text>
                  </Col>
                  <Col span={24}>
                    <Typography.Text
                      ellipsis={true}
                      className={styles.description}
                    >
                      {format(payoutDate, "D MMMM YYYY")}
                    </Typography.Text>
                  </Col>
                </Row>
              </Col>
            )}
            <Col span={8} className={styles.rightFlex}>
              <Row>
                <Col span={24}>
                  <Typography.Text className={styles.title}>
                    {title}
                  </Typography.Text>
                </Col>
                <Col span={24}>
                  <Typography.Text className={styles.amount}>
                    <ShortCurrency value={amount} currencyId={currencyId} />
                  </Typography.Text>
                </Col>
              </Row>
            </Col>
          </Row>
        </BrowserView>
        <MobileView>
          <Row align="middle" justify="space-between" onClick={onClick}>
            <Col span={11} className={styles.alignJustify}>
              <div className={styles.individual}>
                {receiptNumber && (
                  <Typography.Text className={styles.title}>
                    {receiptNumber && `Receipt: ${receiptNumber}:`}&nbsp;
                  </Typography.Text>
                )}
                <Typography.Text className={styles.description}>
                  {description}
                </Typography.Text>
              </div>
              {receiptNumber && (
                <div className={styles.individual}>
                  <Typography.Text
                    className={classNames(styles.title, styles.lastTitle)}
                  >
                    Payout Date:&nbsp;
                  </Typography.Text>
                  <Typography.Text
                    ellipsis={true}
                    className={styles.description}
                  >
                    {format(payoutDate, "D MMMM YYYY")}
                  </Typography.Text>
                </div>
              )}
            </Col>

            <Col span={8} className={styles.rightFlex}>
              <Row>
                <Col span={24} className={styles.rightTitle}>
                  <Typography.Text className={styles.title}>
                    {title}
                  </Typography.Text>
                </Col>
                <Col span={24}>
                  <Typography.Text className={styles.amount}>
                    <ShortCurrency value={amount} currencyId={currencyId} />
                  </Typography.Text>
                </Col>
              </Row>
            </Col>
          </Row>
        </MobileView>
      </Card>
    );
  };

  return !hasToken ? (
    <Redirect to={ROUTES.LOGIN_REDIRECT} />
  ) : (
    <div
      className={classNames(styles.wrapper, {
        [styles.desktop]: isDesktop
      })}
    >
      <Skeleton loading={loadingStatistic}>
        {isDraftInvoice ? (
          <CheckBoxDraft />
        ) : (
          <div className={styles.overviewBoxWrapper}>
            <OverviewBox
              isEmptyData={isEmptyData}
              emptyTitle={"Send your first invoice and get paid."}
              emptyDescription={"Statistics on your invoices will appear here."}
              data={overviewData}
              type={"invoices"}
            />
          </div>
        )}
        {!isDraftInvoice && (
          <FilterTags type="invoices" handleSetNewQuery={handleSetNewQuery} />
        )}
      </Skeleton>
      <Skeleton loading={loadingInvoice}>
        <Row className={styles.listWrapper}>
          {list.length > 0
            ? list.map((item: any) => (
                <Col key={item.id} span={24}>
                  <CardDetails
                    icon={InvoiceAvatar}
                    title={_get(item, "invoiceNumber")}
                    description={_get(item, "customerName", "")}
                    amount={_get(item, "invoiceAmount", "")}
                    payoutDate={_get(item, "payoutDate", "")}
                    receiptNumber={_get(item, "receiptNumber", "")}
                    paymentStatusId={_get(item, "paymentStatusId", "")}
                    onClick={onSelectInvoice(item)}
                  />
                </Col>
              ))
            : hasQueryParams && (
                <div className={styles.noResults}>
                  <Typography.Text>No results found.</Typography.Text>
                </div>
              )}
        </Row>
        <Pagination
          customClassname={styles.paginationWrapper}
          defaultMode={true}
          hideOnSinglePage={true}
          showSizeChanger={false}
          showLessItems={true}
          total={total}
          showQuickJumper={
            total / pagination.pageCount > 10 && {
              goButton: (
                <button className={styles.gotoButton}>
                  <b>
                    Go
                    <Image src={NextIcon} className={styles.gotoIcon} />
                  </b>
                </button>
              )
            }
          }
          locale={{ jump_to: "Go to page" }}
          isDisableToggle={total / pagination.pageCount < 5}
          onChange={handleOnChangePage}
          {...pagination}
        />
      </Skeleton>
    </div>
  );
};
export default InvoiceListContent;
