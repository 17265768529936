import Component from "./View";
import { CollectionRates } from "src/ipm-shared/store/model/CollectedAccount/reducers";
import { CollectionSplitFeePropsWithConnectedProps } from "../CollectionSplitFee";

export type IProps = {
  form: string;
  defaultValue: CollectionRates[];
  hasActiveProgram?: boolean;
  extraName?: string;
  className?: string;
  supportedBrandId: number[];
  currencyId: number;
  onChangeCustom?: (brandId: number, args: any) => any;
  renderer?: (
    props: CollectionSplitFeePropsWithConnectedProps,
    handleSplitRate: (type: string, values: number) => void
  ) => JSX.Element | null;
  rendererSlider?: (
    selectedBrandId: number,
    handleChangeTab: (brandId: number) => void
  ) => JSX.Element | null;
};

export default Component;
