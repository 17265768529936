/* tslint:disable:object-literal-sort-keys jsx-no-lambda */
import React, { useMemo } from "react";
// import {Redirect} from "react-router-dom";
import _get from "lodash-es/get";

import Flex from "antd-mobile/es/flex";

import DateUtils from "src/ipm-shared/Utils/Date";

// import ROUTES from "src/bepaid/routes";
import Skeleton from "src/bepaid/components/Skeleton";
import ExpandBox from "src/bepaid/components/ExpandBox";

import InvoiceOverview from "../InvoiceOverview";
import InvoiceActivity from "../InvoiceActivity";

import styles from "./InvoiceDetail.module.scss";

const showActivity = true;

const InvoiceDetailContent: React.FC<any> = props => {
  const { loading, invoiceDetail } = props;

  /**
   * Open Modal state
   */

  const invoice = useMemo(() => {
    if (invoiceDetail) {
      const data = invoiceDetail;

      // const paymentTotal = _get(data, "paymentTotal", 0);
      const paymentAmount = _get(data, "paymentAmount", 0);

      const dueDate = DateUtils.formatLocaleDate(
        _get(data, "payees.0.dueDate", new Date()),
        {
          day: "numeric",
          month: "short",
          year: "numeric"
        }
      );

      const chargeDate = DateUtils.formatLocaleDate(
        _get(data, "scheduledChargeDate", new Date()),
        {
          day: "numeric",
          month: "short",
          year: "numeric"
        }
      );

      const payoutDate = DateUtils.formatLocaleDate(
        _get(data, "payoutDate", new Date()),
        {
          day: "numeric",
          month: "short",
          year: "numeric"
        }
      );

      return {
        currencyId: _get(data, "currencyId"),
        customerName: `${_get(data, "payer.firstName", "")} ${_get(
          data,
          "payer.lastName",
          ""
        )}`,
        invoiceNumber: _get(data, "payees.0.paymentDescription"),
        // totalAmount: paymentTotal > 0 ? paymentTotal : paymentAmount,
        totalAmount: paymentAmount,
        receiptNumber: _get(data, "receiptNumber"),
        dueDate,
        chargeDate,
        payoutDate
      };
    }
    return {};
  }, [invoiceDetail]);

  return (
    <div className={styles.content}>
      <Skeleton loading={loading}>
        <Flex
          align={"stretch"}
          direction={"column"}
          className={styles.overviewBoxWrapper}
        >
          <ExpandBox
            originalContent={
              <InvoiceOverview.InvoiceOverviewCollapsed invoice={invoice} />
            }
            expandContent={
              <InvoiceOverview.InvoiceOverviewExpand invoice={invoice} />
            }
            defaultExpand={false}
          />
        </Flex>
      </Skeleton>

      {showActivity && (
        <InvoiceActivity
          invoiceDetail={invoiceDetail}
          activities={(_get(invoiceDetail, "paymentActivities") || []).filter(
            (act: any) => act.type
          )}
        />
      )}
    </div>
  );
};

export default InvoiceDetailContent;
