import { connect } from "react-redux";
import View from "./View";

import { RootState } from "src/ipm-shared/store/model/reducers";

import * as GlobalUISelectors from "src/ipm-shared/components/GlobalUI/selectors";
import * as actions from "src/bepaid/store/model/Fetch/actions";
import * as selectors from "src/bepaid/store/model/Fetch/selectors";

const mapStateToProps = (state: RootState, props: any) => ({
  extraInfo: GlobalUISelectors.getExtraInfo(state, props.modalId),
  selectedPayment: selectors.getBepaidSelectedPaymentDetail(state)
});

const mapDispatchToProps = {
  toggleModal: actions.toggleModal,
  selectPayment: actions.selectPayment,
  updateMetadataPayment: actions.updateMetadataPayment,
  fetchCards: actions.fetchCards,
  selectPayees: actions.selectPayees,
  setPaymentCountryCode: actions.setPaymentCountryCode,
  setPaymentCurrencyCode: actions.setPaymentCurrencyCode,
  resetSelectedPayment: actions.resetSelectedPayment,
  fetchPayees: actions.fetchPayees
};

export type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(View);
