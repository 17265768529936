import { connect } from "react-redux";
import * as actions from "src/bepaid/store/model/Fetch/actions";

import View from "./View";
import { RootState } from "src/ipm-shared/store/model/reducers";
import * as selectors from "src/bepaid/store/model/Fetch/selectors";

const noEditRegs = ["T08GB0020K", "824753556"];

const mapStateToProps = (state: RootState, props: any) => ({
  list: {
    landlord: selectors.getPayees(state, "rent").map(e => ({
      id: e.id,
      name: e.name,
      description: e.address,
      amount: e.currentAmount,
      currencyId: e.currencyId,
      allowEdit: true,
      isInternational: e.isInternational,
      reviewStatus: e.reviewStatus
    })),
    employee: selectors.getPayees(state, "salary").map(e => ({
      id: e.id,
      name: e.name,
      description: e.idNumber,
      amount: e.currentAmount,
      currencyId: e.currencyId,
      allowEdit: true,
      isInternational: e.isInternational,
      reviewStatus: e.reviewStatus
    })),
    supplier: selectors.getPayees(state, "invoice").map(e => {
      return {
        id: e.id,
        name: e.name,
        description: e.international.bankRawName
          ? `${e.international.bankRawName} - ${e.accountNumber}`
          : e.accountNumber,
        allowEdit: !noEditRegs.includes(e?.registrationNumber),
        isInternational: e.isInternational,
        reviewStatus: e.reviewStatus
      };
    }),
    self_transfer: selectors.getPayees(state, "self_transfer").map(e => ({
      id: e.id,
      name: e.international.bankAccountHolderName,
      description: e.international.bankRawName
        ? `${e.international.bankRawName} - ${e.accountNumber}`
        : e.accountNumber,
      allowEdit: !noEditRegs.includes(e?.registrationNumber),
      isInternational: e.isInternational,
      reviewStatus: e.reviewStatus
    }))
  }[props.entity],
  shouldDisplayLoadingPayees: selectors.shouldDisplayLoadingPayees(state),
  isFirstFetchData: selectors.getFirstFetchDataByKey(state)(
    `recipients_${props.entity}s`
  ),
  total: selectors.getTotalPayees(state),
  selectedPayee: selectors.selectedPayee(state)
});

const mapDispatchToProps = {
  fetchPayees: actions.fetchPayees,
  selectPayees: actions.selectPayees
};

export default connect(mapStateToProps, mapDispatchToProps)(View);
