import countries from "src/ipm-shared/store/metadata/country";
import currencies from "src/ipm-shared/store/metadata/currency";
import _get from "lodash-es/get";
import T from "src/ipm-shared/Utils/Intl";

import SGFlag from "src/bepaid/assets/images/countries/sg.svg";
import HKFlag from "src/bepaid/assets/images/countries/hk.svg";
import MYFlag from "src/bepaid/assets/images/countries/my.svg";
import AUFlag from "src/bepaid/assets/images/countries/au.svg";
import IDFlag from "src/bepaid/assets/images/countries/id.svg";
import INFlag from "src/bepaid/assets/images/countries/in.svg";
import PHFlag from "src/bepaid/assets/images/countries/ph.svg";
import USFlag from "src/bepaid/assets/images/countries/us.svg";
import JPFlag from "src/bepaid/assets/images/countries/jp.svg";
import FRFlag from "src/bepaid/assets/images/countries/fr.svg";
import GBFlag from "src/bepaid/assets/images/countries/gb.svg";
import DEFlag from "src/bepaid/assets/images/countries/de.svg";
import CNFlag from "src/bepaid/assets/images/countries/cn.svg";
import NZFlag from "src/bepaid/assets/images/countries/nz.svg";
import THFlag from "src/bepaid/assets/images/countries/th.svg";

import ATFlag from "src/bepaid/assets/images/countries/at.svg";
import BEFlag from "src/bepaid/assets/images/countries/be.svg";
import CYFlag from "src/bepaid/assets/images/countries/cy.svg";
import EEFlag from "src/bepaid/assets/images/countries/ee.svg";
import ESFlag from "src/bepaid/assets/images/countries/es.svg";
import FIFlag from "src/bepaid/assets/images/countries/fi.svg";
import GRFlag from "src/bepaid/assets/images/countries/gr.svg";
import IEFlag from "src/bepaid/assets/images/countries/ie.svg";
import ITFlag from "src/bepaid/assets/images/countries/it.svg";
import LTFlag from "src/bepaid/assets/images/countries/lt.svg";
import LUFlag from "src/bepaid/assets/images/countries/lu.svg";
import LVFlag from "src/bepaid/assets/images/countries/lv.svg";
import MTFlag from "src/bepaid/assets/images/countries/mt.svg";
import NLFlag from "src/bepaid/assets/images/countries/nl.svg";
import PTFlag from "src/bepaid/assets/images/countries/pt.svg";
import SIFlag from "src/bepaid/assets/images/countries/si.svg";
import SKFlag from "src/bepaid/assets/images/countries/sk.svg";

export const FLAGS = {
  SG: SGFlag,
  HK: HKFlag,
  MY: MYFlag,
  AU: AUFlag,
  ID: IDFlag,
  IN: INFlag,
  PH: PHFlag,
  US: USFlag,
  JP: JPFlag,
  FR: FRFlag,
  GB: GBFlag,
  DE: DEFlag,
  CN: CNFlag,
  NZ: NZFlag,
  TH: THFlag,
  AT: ATFlag,
  BE: BEFlag,
  CY: CYFlag,
  EE: EEFlag,
  ES: ESFlag,
  FI: FIFlag,
  GR: GRFlag,
  IE: IEFlag,
  IT: ITFlag,
  LT: LTFlag,
  LU: LUFlag,
  LV: LVFlag,
  MT: MTFlag,
  NL: NLFlag,
  PT: PTFlag,
  SI: SIFlag,
  SK: SKFlag
};

const CountryUtil = {
  getCallingCodeFromCountryId: (countryId: number): number => {
    switch (countryId) {
      case 1:
        return 65;
      case 2:
        return 60;
      case 3:
        return 852;
      case 16:
        return 61;
      case 73:
        return 298;
      default:
        return _get(
          countries.filter(c => c.id === countryId)[0],
          "calling_code",
          65
        );
    }
  },
  getCountryCodeFromCountryId: (countryId: number): string => {
    switch (countryId) {
      case 1:
        return "SG";
      case 2:
        return "MY";
      case 3:
        return "HK";
      case 16:
        return "AU";
      case 229:
        return "US";
      default:
        return _get(countries.filter(c => c.id === countryId)[0], "code", "SG");
    }
  },
  getCountryCodeFromCurrencyId: (currencyId: number): string => {
    switch (currencyId) {
      case 1:
        return "SG";
      case 2:
        return "MY";
      case 3:
        return "HK";
      case 11:
        return "AU";
      case 73:
        return "HK";
      default:
        return "";
    }
  },
  getCountryIdFromCountryCode: (countryCode: string): number => {
    switch (countryCode) {
      case "SG":
        return 1;
      case "MY":
        return 2;
      case "HK":
        return 3;
      case "AU":
        return 16;
      default:
        return 0;
    }
  },

  getCountryLabelFromCountryId: (countryId: number): string => {
    const foundCountry = countries.find(c => c.id === countryId);
    return foundCountry ? foundCountry.name : "n/t";
  },

  getCurrencyCodeFromCountryId: (countryId: number): string => {
    switch (countryId) {
      case 1:
        return "SGD";
      case 2:
        return "MYR";
      case 3:
        return "HKD";
      case 16:
        return "AUD";
      case 73:
        return "HK_USD";
      default:
        return "";
    }
  },

  getMobileCountriesCodeOptions: (listCodes: string[] = []): any => {
    return listCodes.map(code => {
      const c: any = countries.find((item: any) => item.code === code);
      return {
        code: c.code,
        flag: FLAGS[c.code],
        label: `+${c.calling_code}`,
        value: c.code
      };
    });
  },

  getCountriesCodeOptions: (listCodes: string[] = []): any => {
    return listCodes.map(code => {
      const c: any = countries.find((item: any) => item.code === code);
      return {
        code: c.code,
        flag: FLAGS[c.code],
        label: c.name,
        value: c.code
      };
    });
  },
  getCountryFromCode: (code: string): any => {
    return countries.find(c => c.code === code);
  },
  getPayslipEmployeeFromCountryId: (id: number): any => {
    switch (id) {
      case 1:
        return T.transl("PAYSLIP_EMPLOYEE_ID_NRIC");
      case 2:
        return T.transl("PAYSLIP_EMPLOYEE_ID_MYKAD");
      case 3:
        return T.transl("PAYSLIP_EMPLOYEE_ID_HKID");
      case 16:
        return T.transl("PAYSLIP_EMPLOYEE_ID");
      default:
        return T.transl("PAYSLIP_EMPLOYEE_ID_NRIC");
    }
  },
  getCountryRegistrationCodeFromCountryCode: (countryCode: string) => {
    switch (countryCode) {
      case "SG":
        return "UEN";
      case "HK":
        return "BRN";
      case "AU":
        return "ABN";
      case "MY":
        return "Company Registration Number";
      default:
        return "";
    }
  },
  getCurrencyCode: (countryCode: string) => {
    const currencyCode = countries.find(
      (country: any) => country.code === countryCode
    )?.currency_code;

    return currencyCode === "CNY" ? "CNH" : currencyCode;
  },
  getCountryId: (countryCode: string) => {
    return countries.find((country: any) => country.code === countryCode)?.id;
  },
  getCurrencyId: (countryCode: string) => {
    const currencyCode = CountryUtil.getCurrencyCode(countryCode);
    return currencies.find((currency: any) => currency.code === currencyCode)
      ?.id;
  }
};

export default CountryUtil;
