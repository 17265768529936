// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Loading_wrapper__19-AP{width:100vw;height:var(--app-height);position:absolute;top:0;left:0;display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;background:#96969647;z-index:9999}", "",{"version":3,"sources":["webpack://./src/bepaid/components/Loading/Loading.module.scss"],"names":[],"mappings":"AAAA,wBAAS,WAAW,CAAC,wBAAwB,CAAC,iBAAiB,CAAC,KAAK,CAAC,MAAM,CAAC,mBAAY,CAAZ,YAAY,CAAC,oBAAsB,CAAtB,sBAAsB,CAAC,qBAAkB,CAAlB,kBAAkB,CAAC,oBAAoB,CAAC,YAAY","sourcesContent":[".wrapper{width:100vw;height:var(--app-height);position:absolute;top:0;left:0;display:flex;justify-content:center;align-items:center;background:#96969647;z-index:9999}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "Loading_wrapper__19-AP"
};
export default ___CSS_LOADER_EXPORT___;
