const getFileNameDisplay = (fileName: string, size: number = 15) => {
  if (!fileName) {
    return;
  }

  let result = fileName;

  const index = fileName.lastIndexOf(".");
  const name = fileName.slice(0, index);
  const extension = fileName.slice(index + 1);

  if (name.length > size) {
    const middle = Math.floor(size / 2);
    result = `${name.substring(0, middle)}...${name.substring(
      name.length - middle,
      name.length
    )}.${extension}`;
  }

  return result;
};

export { getFileNameDisplay };
