/* tslint:disable:jsx-no-lambda */
import React from "react";
import _get from "lodash-es/get";
import classNames from "classnames";
import ConfigProvider from "antd/es/config-provider";
import List from "antd/es/list";
import FetchSkeleton from "../Skeleton";

import Pagination from "../Pagination";

import styles from "./List.module.scss";

const Component = (props: any) => {
  const {
    loading = false,
    data = [],
    total = 0,
    header = null,
    renderItem = null,
    emptyDOM = null,
    pagination = null
  } = props;

  const offset = _get(pagination, "offset", 0);
  const pageCount = _get(pagination, "pageCount", 10);
  const onChangePage = _get(pagination, "onChange");

  const Header = () => (
    <div className={styles.header}>
      {header}
      {pageCount < total && (
        <Pagination
          offset={offset}
          pageCount={pageCount}
          total={total}
          onChange={onChangePage}
          simple={true}
        />
      )}
    </div>
  );

  const handleRenderItem = (item: any) => {
    return renderItem && <List.Item>{renderItem(item)}</List.Item>;
  };

  const handleRenderEmpty = () => {
    return (
      <FetchSkeleton loading={loading}>
        {emptyDOM || (
          <div className={styles.defaultEmptySection}>
            <p>No data found</p>
          </div>
        )}
      </FetchSkeleton>
    );
  };

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.hasPagination]: pagination
      })}
    >
      <ConfigProvider renderEmpty={handleRenderEmpty}>
        <List
          rowKey={(record: any) => record.uid}
          dataSource={data}
          renderItem={handleRenderItem}
          header={<Header />}
        />
      </ConfigProvider>
    </div>
  );
};

export default Component;
