import { ActionType } from "typesafe-actions";
import { put } from "redux-saga/effects";
import * as actions from "./actions";
import { reTryTakeLatest } from "src/ipm-shared/Utils/ReduxSagaEffects";
import countryList from "src/ipm-shared/store/metadata/country";

const watchedSagas = [
  reTryTakeLatest(actions.fetchCountries, handleFetchCountries)
];
export default watchedSagas;
export function* handleFetchCountries(
  action: ActionType<typeof actions.fetchCountries>
) {
  yield put(
    actions.setCountries({
      countries: countryList.map(c => ({
        code: c.code.toUpperCase(),
        codeNumber: c.calling_code,
        currency:
          c.currency_code === null ? "SGD" : c.currency_code.toUpperCase(),
        id: c.id,
        name: c.name
      })),
      isFetching: false
    })
  );

  // const res: Response = yield call(RestClient.send, {
  //   service: "get_countries"
  // });
  //
  // if (!res) {
  //   yield put(
  //     actions.setCountries({
  //       countries: [
  //         {
  //           code: "SG",
  //           codeNumber: 65,
  //           currency: "SGD",
  //           id: 1,
  //           name: "Singapore"
  //         },
  //         {
  //           code: "MY",
  //           codeNumber: 60,
  //           currency: "MYR",
  //           id: 2,
  //           name: "Malaysia"
  //         },
  //         {
  //           code: "HK",
  //           codeNumber: 852,
  //           currency: "HKD",
  //           id: 3,
  //           name: "Hong Kong"
  //         }
  //       ],
  //       isFetching: false
  //     })
  //   );
  //   throw new HttpRequestError("Failed to fetch");
  // }
  //
  // const data: any[] = _get(res, "data.countries", []);
  //
  // yield put(
  //   actions.setCountries({
  //     countries: data.map(c => ({
  //       code: c.code.toUpperCase(),
  //       codeNumber: c.calling_code,
  //       currency: c.currency_code === null ? "SGD" : c.currency_code,
  //       id: c.id,
  //       name: c.name
  //     })),
  //     isFetching: false
  //   })
  // );
}
