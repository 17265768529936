// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PaymentDetailModal_wrapper__2-tFE{display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;padding:0 1.5rem}.PaymentDetailModal_wrapperMobile__3ag1F{padding:0}", "",{"version":3,"sources":["webpack://./src/bepaid/shared/modals/PaymentDetailEditModal/PaymentDetailModal.module.scss"],"names":[],"mappings":"AAAA,mCAAS,mBAAY,CAAZ,YAAY,CAAC,oBAAsB,CAAtB,sBAAsB,CAAC,gBAAgB,CAAC,yCAAe,SAAS","sourcesContent":[".wrapper{display:flex;justify-content:center;padding:0 1.5rem}.wrapperMobile{padding:0}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "PaymentDetailModal_wrapper__2-tFE",
	"wrapperMobile": "PaymentDetailModal_wrapperMobile__3ag1F"
};
export default ___CSS_LOADER_EXPORT___;
