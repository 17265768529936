import React from "react";
import { connect } from "react-redux";

import { history } from "src/ipm-shared/store";
import { PLATFORM } from "src/ipm-shared/components/GlobalUI/reducers";
import { RootState } from "src/ipm-shared/store/model/reducers";
// import { WALLEX_KYC_STATUS } from "src/ipm-shared/store/model/OnBoarding/consts";
// import PaymentUtil from "src/ipm-shared/Utils/Payment";
import _isEmpty from "lodash-es/isEmpty";
import _get from "lodash-es/get";

import useQuery from "src/bepaid/hooks/useQuery";
import * as actions from "src/bepaid/store/model/Fetch/actions";
import * as selectors from "src/bepaid/store/model/Fetch/selectors";
import Button from "src/bepaid/components/Form/controls/Button";
import Image from "src/bepaid/components/Image";
import ROUTES from "src/bepaid/routes";

import ArrowDownPayWhite from "src/bepaid/assets/images/common/drop_down_arrow_pay_white.svg";

import styles from "./MobileMainButton.module.scss";
import FeatureGate from "src/ipm-shared/components/FeatureGating/FeatureGate";

type IProps = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> & {
    platform?: number;
    buttonText?: string;
    action?: () => void;
  };

const PRIMARY_BUTTON_TEXT = "Make payment";

const PAY_BUTTONS = {
  secondary: {
    employees: {
      text: "Add employee",
      onClickType: "redirect_url",
      path: ROUTES.RECIPIENTS_EMPLOYEES_CREATE
    },
    landlords: {
      text: "Add landlord",
      onClickType: "redirect_url",
      path: ROUTES.RECIPIENTS_LANDLORDS_CREATE
    },
    suppliers: {
      text: "Add supplier",
      onClickType: "redirect_url",
      path: ROUTES.RECIPIENTS_SUPPLIERS_CREATE
    },
    bank_accounts: {
      text: "Add bank account",
      onClickType: "redirect_url",
      path: ROUTES.RECIPIENTS_BANK_ACCOUNTS_CREATE
    }
  },
  primary: {
    employees: {
      text: PRIMARY_BUTTON_TEXT,
      onClickType: "redirect_url",
      path: ROUTES.PAYMENTS_CREATION.replace(":paymentType", "domestic")
        .replace(":entity", "employee")
        .replace(":action", "select")
    },
    landlords: {
      text: PRIMARY_BUTTON_TEXT,
      onClickType: "redirect_url",
      path: ROUTES.PAYMENTS_CREATION.replace(":paymentType", "domestic")
        .replace(":entity", "landlord")
        .replace(":action", "select")
    },
    suppliers: {
      text: PRIMARY_BUTTON_TEXT,
      onClickType: "redirect_url",
      path: ROUTES.PAYMENTS_CREATION.replace(":paymentType", "domestic")
        .replace(":entity", "supplier")
        .replace(":action", "select")
    },
    bank_accounts: {
      text: PRIMARY_BUTTON_TEXT,
      onClickType: "redirect_url",
      path: ROUTES.PAYMENTS_CREATION.replace(":paymentType", "domestic")
        .replace(":entity", "bank_account")
        .replace(":action", "select")
    }
  },
  paymentType: {
    domestic: {
      redirectPath: ROUTES.PAYMENTS_CREATION.replace(":paymentType", "domestic")
        .replace(":entity", "entity")
        .replace(":action", "select")
    },
    international: {
      redirectPath: ROUTES.PAYMENTS_CREATION.replace(
        ":paymentType",
        "international"
      )
        .replace(":entity", "country")
        .replace(":action", "select")
    }
  }
};

const MobileMainButton = (props: IProps) => {
  const { buttonText = null, action = null } = props;

  // const isShowIntPayment = props.wallexKycStatus === WALLEX_KYC_STATUS.APPROVED;
  const isShowIntPayment = true;

  const [isOpenDropDown, setIsOpenDropDown] = React.useState<boolean>(false);

  const goToRoute = (route: string) => () => {
    history.push(route);
  };

  const handleCreateNewInvoice = () => {
    const qs = useQuery();
    const isDraftInvoice = qs.statuses === "1" && qs.key === "invoices";

    if (!isDraftInvoice) {
      props.checkKYC();
    } else {
      props.checkKYC(() => {
        props.handleCheckRemindDraftInvoice((err?: any, data?: any) => {
          if (!err && data) {
            history.push(`${ROUTES.CREATE_INVOICES}`);
          }
        });
      });
    }
  };

  const onClickMakePayment = () => {
    if (props.isSingaporeAccount && isShowIntPayment) {
      setIsOpenDropDown(!isOpenDropDown);
    } else {
      onChoosePaymentType("domestic");
    }
  };
  const handleMyinfo = (
    path = "",
    redirectPath = "",
    paymentType = "",
    isBusiness = false
  ) => {
    props.fetchMyInfoSingPass(({ confirmed = false, info }: any) => {
      props.setConfirmedMyInfo(!!confirmed);
      if (!confirmed) {
        if (paymentType !== "domestic") {
          props.fetchMyInfoSingPass(({ confirmed = false, info }: any) => {
            props.setConfirmedMyInfo(!!confirmed);
            if (!confirmed) {
              history.push(
                `${ROUTES.MY_INFO}?is_biz=${
                  isBusiness ? "y" : "n"
                }&redirect_url=${encodeURIComponent(redirectPath)}`
              );
            } else {
              goToRoute(
                `${path && paymentType === "domestic" ? path : redirectPath}`
              )();
            }
          }, false);
        }
        history.push(
          `${ROUTES.MY_INFO}?is_biz=${
            isBusiness ? "y" : "n"
          }&redirect_url=${encodeURIComponent(redirectPath)}`
        );
      } else {
        goToRoute(
          `${path && paymentType === "domestic" ? path : redirectPath}`
        )();
      }
    }, isBusiness);
  };
  const onChoosePaymentType = (paymentType = "", recipientPath = "") => {
    let { redirectPath } = PAY_BUTTONS.paymentType[paymentType];
    const { path } = PAY_BUTTONS.primary[recipientPath] || {};
    if (props.isSingaporeAccount) {
      if (paymentType == "domestic") {
        redirectPath = ROUTES.PAYMENTS_CREATION.replace(
          ":paymentType",
          "domestic"
        )
          .replace(":entity", "method")
          .replace(":action", "select");
      }

      const { collectedAccount } = props;
      const isCollectedAccount = !_isEmpty(collectedAccount);
      const isDraftCollectedAccount =
        isCollectedAccount && collectedAccount && collectedAccount.draft;
      const hasBizfile =
        isCollectedAccount &&
        !_isEmpty(
          _get(collectedAccount, "payeeData.kycDocuments.b_acra_bizfile", [])
        );
      const hasMyinfoBiz =
        isCollectedAccount &&
        !_isEmpty(_get(collectedAccount, "payeeData.myinfoBiz", null));
      if (!isCollectedAccount) {
        handleMyinfo(path, redirectPath, paymentType, !props.isPersonalAccount);
        return;
      } else if (isDraftCollectedAccount) {
        if (hasMyinfoBiz) {
          goToRoute(
            `${path && paymentType === "domestic" ? path : redirectPath}`
          )();
          return;
        } else if (hasBizfile && !hasMyinfoBiz) {
          handleMyinfo(path, redirectPath, paymentType, false);
          return;
        } else {
          handleMyinfo(
            path,
            redirectPath,
            paymentType,
            !props.isPersonalAccount
          );
          return;
        }
      }
      goToRoute(
        `${path && paymentType === "domestic" ? path : redirectPath}`
      )();
    } else {
      // if (!props.isMalaysiaAccount) {
      redirectPath = ROUTES.PAYMENTS_CREATION.replace(
        ":paymentType",
        "domestic"
      )
        .replace(":entity", "method")
        .replace(":action", "select");
      // }
      goToRoute(
        `${path && paymentType === "domestic" ? path : redirectPath}`
      )();
    }
  };

  // const renderGlobalPromotionContent = () => {
  //   if (props.isSingaporeAccount) {
  //     if (!props.wallexKycStatus) {
  //       return (
  //         <>
  //           <a
  //             href="mailto:support@ipaymy.com?subject=Interested%20in%20paying%20abroad"
  //             target="_blank"
  //           >
  //             <div className={styles.globeIcon} />
  //             Interested in paying abroad?
  //           </a>
  //         </>
  //       );
  //     } else if (PaymentUtil.isWallexKycInProgress(props.wallexKycStatus)) {
  //       return (
  //         <>
  //           <a className={styles.hideHover}>
  //             <div className={styles.globeIcon} />
  //             Reviewing your request to pay abroad...
  //           </a>
  //         </>
  //       );
  //     }
  //   }
  //   return <></>;
  // };

  const renderDefaultButton = () => {
    return (
      <FeatureGate feature="NORMAL_PAYMENT">
        {props.platform === PLATFORM.FETCH && (
          <>
            <Button
              id={"ipaymy_btn_create_invoice"}
              type="primary"
              htmlType="button"
              onClick={handleCreateNewInvoice}
            >
              Create invoice
            </Button>
          </>
        )}
        {props.platform === PLATFORM.PAY && (
          <>
            {/* <div className={styles.globalPromotion}>
              {renderGlobalPromotionContent()}
            </div> */}
            <div className={styles.buttonContainer}>
              <Button
                id={"ipaymy_btn_make_payment"}
                type="primary"
                htmlType="button"
                className={styles.buttonAddRecipient}
                onClick={onClickMakePayment}
              >
                {props.isSingaporeAccount && isShowIntPayment ? (
                  <>
                    Make payment <Image src={ArrowDownPayWhite} />
                  </>
                ) : (
                  <>Make payment</>
                )}
              </Button>
              {isOpenDropDown && (
                <div className={styles.dropdown} id={"recipient_dropdown"}>
                  <button
                    id={"ipaymy_btn_make_payment_domestic"}
                    className={styles.dropdownItem}
                    onClick={onChoosePaymentType.bind(null, "domestic")}
                  >
                    Domestic
                  </button>
                  <button
                    id={"ipaymy_btn_make_payment_international"}
                    className={styles.dropdownItem}
                    onClick={onChoosePaymentType.bind(null, "international")}
                  >
                    International
                  </button>
                </div>
              )}
            </div>
          </>
        )}
      </FeatureGate>
    );
  };

  const renderCustomButton = () => {
    return (
      <Button type="primary" htmlType="button" onClick={action}>
        {buttonText}
      </Button>
    );
  };

  return (
    <div className={styles.buttons}>
      {buttonText && action ? renderCustomButton() : renderDefaultButton()}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  isSingaporeAccount: selectors.isSingaporeAccount(state),
  isMalaysiaAccount: selectors.isMalaysiaAccount(state),
  isPersonalAccount: selectors.getIsPersonalAccount(state),
  wallexKycStatus: selectors.getWallexKycStatus(state),
  collectedAccount: selectors.getCollectedAccount(state)
});

const mapDispatchToProps = {
  handleCheckRemindDraftInvoice: actions.checkRemindDraftInvoice,
  checkKYC: actions.checkKYC,
  toggleModal: actions.toggleModal,
  fetchMyInfoSingPass: actions.fetchMyInfoSingPass,
  setConfirmedMyInfo: actions.setConfirmedMyInfo
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileMainButton);
