export const ADD_SUPPLIER_FIRST_SCREEN_ERROR_FIELD = [
  "company_name",
  "registration_number",
  "address",
  "city",
  "state_or_province",
  "region",
  "postal_code",
  "recipient_name"
];

export const ADD_LANDLORD_FIRST_SCREEN_ERROR_FIELD = [
  "company_name",
  "first_name",
  "last_name",
  "national_identification_number",
  "registration_number",
  "address",
  "city",
  "state_or_province",
  "region",
  "postal_code",
  "recipient_name",
  "default_amount",
  "default_comments",
  "supporting_documents"
];

export const TAX_TITLE = {
  SG: {
    placeholder: "i.e. 20150712D Income Tax",
    label: "Tax reference number and tax type",
    link:
      "https://help.ipaymy.com/en/articles/2515189-how-do-i-make-a-tax-payment-to-iras"
  },
  AU: {
    placeholder: "Payment Reference Number (PRN)",
    label: "Payment Reference Number (PRN)",
    link:
      "https://help.ipaymy.com/en/articles/5165124-how-do-i-make-a-tax-payment-to-ato"
  }
};
