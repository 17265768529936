import * as React from "react";
import ShortCurrency from "src/ipm-shared/components/ShortCurrency";

export default {
  renderMoney: (value: string) => {
    let amount;
    try {
      amount = Math.round(parseFloat(value) * 100);
    } catch (e) {
      amount = NaN;
    }
    return <ShortCurrency value={amount} />;
  }
};
