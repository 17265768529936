const URL_REGEX = /^(https?|ftps?|sftp):\/\/[^\s$.?#].[^\s]*$/i;
const WHITE_LIST_SITE_REGEX = /(?:http:\/\/)?(?:([^.]+)\.)?ipaymy\.com/i;

const isUrl = (url: string): RegExpMatchArray | null => {
  return url.match(URL_REGEX);
};

const isMatchWhiteListSite = (url: string): RegExpMatchArray | null => {
  return url.match(WHITE_LIST_SITE_REGEX);
};

const getQsRedirectUrlUserLoggedOut = (): string => {
  let qsRedirectUrl = "";

  if (
    window.location.pathname !== "/" &&
    window.location.pathname !== "/login"
  ) {
    qsRedirectUrl = `?redirect_url=${encodeURIComponent(
      window.location.pathname + window.location.search
    )}`;
  }

  return qsRedirectUrl;
};

// When use this:
// Case study 1: Clear cache / fetch new source code
// Case study 2: Re-fetch/Reset whole data. For example: start payment flow, login
function redirect(url: string): void {
  if (url.charAt(0) === "/") {
    if (window.stop) {
      window.stop();
    }
    window.location.href = url;
  } else {
    if (isUrl(url) && isMatchWhiteListSite(url)) {
      if (window.stop) {
        window.stop();
      }
      window.location.href = url;
      return;
    } else {
      console.error("Failed to redirect: url is not in whitelist, url=", url);
    }
  }
}

function openBackgroundIframe(url: string, cb?: any) {
  let iframeEl = document.getElementById(
    "webhook_3ds_payment_update"
  ) as HTMLIFrameElement;
  if (iframeEl) {
    iframeEl.remove();
  }
  iframeEl = document.createElement("iframe");
  iframeEl.setAttribute("id", "webhook_3ds_payment_update");
  iframeEl.src = url;
  iframeEl.hidden = true;
  (document.getElementsByTagName("body") as any)[0].appendChild(iframeEl);

  iframeEl.onload = () => {
    const iframeHref = iframeEl.contentWindow?.location.href;
    if (iframeHref?.includes("schedules")) {
      if (iframeHref?.includes("nf_errors")) {
        cb(new Error("nf_errors"));
      } else {
        if (cb) {
          cb(null, true);
        }
      }
    }
  };
}

function openBackgroundTabAndClose(url: string): boolean {
  const tab = window.open("");
  if (tab) {
    tab.window.location.href = url;
    tab.window.onfocus = function() {
      setTimeout(function() {
        tab?.window.close();
      }, 1500);
    };
  }
  return true;
}

export default {
  getQsRedirectUrlUserLoggedOut,
  isMatchWhiteListSite,
  isUrl,
  redirect,
  openBackgroundTabAndClose,
  openBackgroundIframe
};
