import { RouteProps } from "react-router";
import { connect } from "react-redux";

import { RootState } from "src/ipm-shared/store/model/reducers";
import * as FormAction from "src/ipm-shared/components/Form/actions";

import * as selectors from "src/bepaid/store/model/Fetch/selectors";
import * as actions from "src/bepaid/store/model/Fetch/actions";

import Component from "./View";

const mapStateToProps = (state: RootState) => ({
  countryId: selectors.getAccountCountryId(state),
  getCountriesById: selectors.getCountriesById(state),
  getLinkFindUen: selectors.getLinkFindUen(state)
});

const mapDispatchToProps = {
  getCustomerActivities: actions.getCustomerActivities,
  setControl: FormAction.setControl
};

export type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteProps;

export default connect(mapStateToProps, mapDispatchToProps)(Component);
