import { connect } from "react-redux";

import { RootState } from "src/ipm-shared/store/model/reducers";

import View from "./View";
import * as selectors from "src/bepaid/store/model/Fetch/selectors";
import * as actions from "src/bepaid/store/model/Fetch/actions";

const mapStateToProps = (state: RootState, props: any) => ({
  hasCompany: selectors.hasCompany(state),
  isSingaporeAccount: selectors.isSingaporeAccount(state),
  isMalaysiaAccount: selectors.isMalaysiaAccount(state),
  isBusinessAccount: selectors.getIsBusinessAccount(state),
  selectedPurpose: selectors.getSelectedPurpose(state),
  getCompany: selectors.getCompany(state),
  paymentCountryCode: selectors.getPaymentCountryCode(state),
  paymentMethodType: selectors.getPaymentMethodType(state),
  isCryptoPaymentMethod: selectors.isCryptoPaymentMethod(state),
  getConfirmedMyInfo: selectors.getConfirmedMyInfo(state),
  accountCountryCode: selectors.getAccountCountryCode(state),
  getCurrentUserId: selectors.getCurrentUserId(state)
});

const mapDispatchToProps = {
  setPaymentMethodType: actions.setPaymentMethodType,
  resetSelectedPayees: actions.resetSelectedPayees,
  toggleModal: actions.toggleModal,
  fetchMyInfoSingPass: actions.fetchMyInfoSingPass
};

export type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(View);
