import React, { useState } from "react";

import { FetchModalID } from "src/ipm-shared/components/GlobalUI/actions";

import DesktopModalLayout from "src/bepaid/components/Modal/components/DesktopModalLayout";

import AddEditCompany from "src/bepaid/layouts/Menu/components/Companies/components/AddEditCompany";
import { MODES } from "src/bepaid/layouts/Menu/components/Companies/const";

import styles from "./AddCompanyModal.module.scss";

const View = (props: any) => {
  const [mode, setModeState] = useState(MODES.ADD_NEW_COMPANY);

  const [selectedCompany] = React.useState({
    id: "",
    name: "",
    // tslint:disable-next-line:object-literal-sort-keys
    code: "",
    countryCode: props.countryCode,
    payeeData: {
      businessIndustry: null,
      businessType: null
    }
  });

  const toggleModal = () => {
    props.toggleModal(FetchModalID.ADD_COMPANY);
  };

  const setMode = (m: any) => {
    setModeState(m);

    if (m === MODES.SHOW_COMPANY_LIST) {
      toggleModal();
    }
  };

  return (
    <DesktopModalLayout title={"Add company"} onClose={toggleModal}>
      <div className={styles.wrapper}>
        <AddEditCompany
          submitLabel={"Add new company"}
          mode={mode}
          companyData={selectedCompany}
          setMode={setMode}
        />
      </div>
    </DesktopModalLayout>
  );
};

export default View;
