import React, { useEffect } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import classNames from "classnames";

import DesktopModalLayout from "src/bepaid/components/Modal/components/DesktopModalLayout";
import MainLayout from "src/bepaid/layouts/MainLayout";
import styles from "./PaymentResultModal.module.scss";
import { history } from "src/ipm-shared/store";

import PaymentScheduled from "./components/PaymentScheduled";
import PaymentSuccessful from "./components/PaymentSuccessful";
import PaymentRecurringSuccessful from "./components/PaymentRecurringSuccessful";

import { useMakePaymentUrlParams } from "src/bepaid/pages/Payments/hooks";
import { isToday } from "date-fns";
import { extractDateFromQueryParam } from "./components/utils";

import { FetchModalID } from "src/bepaid/store/model/Fetch/actions";

import _get from "lodash-es/get";

const View = (props: any) => {
  const prevRoute = history?.location.state?.prevRoute;
  const { query }: any = useMakePaymentUrlParams(prevRoute);

  const renderPaymentResult = () => {
    const modalId = +query.m || props.openedModalIds[1];
    const chargeDate =
      props.openedModalIds.length > 1 && _get(props.extraInfo, "chargeDate")
        ? new Date(_get(props.extraInfo, "chargeDate"))
        : extractDateFromQueryParam(query, "mf_charge_date");

    if (modalId === FetchModalID.PAYMENT_SUCCESS && isToday(chargeDate)) {
      return (
        <PaymentSuccessful query={query} chargeDate={chargeDate} {...props} />
      );
    }

    if (
      modalId === FetchModalID.PAYMENT_SUCCESS_SCHEDULE &&
      !isToday(chargeDate)
    ) {
      return (
        <PaymentScheduled query={query} chargeDate={chargeDate} {...props} />
      );
    }

    if (modalId === FetchModalID.RECURRING_PAYMENT_SUCCESS) {
      return (
        <PaymentRecurringSuccessful
          query={query}
          chargeDate={chargeDate}
          {...props}
        />
      );
    }

    return null;
  };

  useEffect(() => {
    if (!query.m) {
      props.toggleModal(FetchModalID.MAKE_PAYMENT_RESULT);
    }
  }, []);

  return (
    <>
      <BrowserView>
        <DesktopModalLayout
          headingClassName={styles.modalHeading}
          bodyClassName={styles.modalBody}
          hasBorder={false}
        >
          <div className={styles.wrapper}>{renderPaymentResult()}</div>
        </DesktopModalLayout>
      </BrowserView>
      <MobileView>
        <MainLayout
          header={{
            hasBorder: false,
            hideMenu: true
          }}
        >
          <div className={classNames(styles.wrapper, styles.mobileWrapper)}>
            {renderPaymentResult()}
          </div>
        </MainLayout>
      </MobileView>
    </>
  );
};

export default View;
