import { connect } from "react-redux";
import Component from "./View";
import { RootState } from "src/ipm-shared/store/model/reducers";
import * as actions from "src/ipm-shared/store/model/actions";
import * as selectors from "./selectors";
import { StripeCardCvcElement } from "@stripe/stripe-js";
import { paymentRequiredActionParams } from "src/ipm-shared/store/model/Payment/types";

const mapStateToProps = (state: RootState) => ({
  extraInfo: selectors.getExtraInfo(state, actions.ModalID.RECOLLECT_CVV) as {
    callback: (cvv: StripeCardCvcElement | string) => any;
    provider: paymentRequiredActionParams["provider"];
    acquirerId: number;
    onClose: () => void;
  }
});

const mapDispatchToProps = {
  // closeModal: actions.closeModal.bind(actions, actions.ModalID.RECOLLECT_CVV)
  closeModal: actions.toggleModal.bind(actions, actions.ModalID.RECOLLECT_CVV)
};

// Fixed template
export type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Component);
