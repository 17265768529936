import _get from "lodash-es/get";
import * as queryString from "query-string";
import _isEmpty from "lodash-es/isEmpty";
import { ActionType } from "typesafe-actions";
import { eventChannel, delay } from "redux-saga";
import { select, call, put, race, take } from "redux-saga/effects";
import {
  catchTakeLatest,
  reTryTakeLatest
} from "src/ipm-shared/Utils/ReduxSagaEffects";
import { FILTER_EXPORT_FORM_PAYMENTS } from "src/ipm-shared/components/FilterExportProvider";
import {
  PAYMENT_STATUS,
  SCHEDULE_EDIT_COUPON_FORM,
  SCHEDULE_EDIT_FORM,
  PAYMENT_PAYEE_EDIT_FORM,
  DEFAULT_PAYMENT_STATUS_IDS
} from "src/ipm-shared/store/model/Payment/const";
import _uniq from "lodash-es/uniq";

import * as paymentActions from "./actions";

import * as fetchActions from "src/bepaid/store/model/Fetch/actions";

import {
  CONFIRM_FORM,
  EXPEDITE_FORM,
  PAGE_COUNT,
  ADMIN_PAGE_COUNT,
  REFUND_FORM,
  SEARCH_FORM,
  PAYMENT_CHECK_SEARCH_FORM,
  SCHEDULED_PAGE_COUNT
} from "./const";
import * as formSelectors from "src/ipm-shared/components/Form/selectors";
import * as formActions from "src/ipm-shared/components/Form/actions";
import RestClient from "src/ipm-shared/services/Rest";
import * as commonActions from "../actions";
import * as commonSelectors from "./selectors";
import * as payeeSelectors from "src/ipm-shared/store/model/Payee/selectors";
import * as accountProfileSelectors from "src/ipm-shared/store/model/AccountProfile/selectors";
import * as cardSelectors from "src/ipm-shared/store/model/Card/selectors";
import HttpRequestError from "src/ipm-shared/Utils/Exceptions/HttpRequestError";
import { PayeeType } from "../Payee/reducers";
import { format } from "date-fns";
import { RootState } from "../reducers";
import { ModalID } from "src/ipm-shared/components/Form/actions";
import T from "src/ipm-shared/Utils/Intl";
import utils from "src/ipm-shared/Utils/Number";
import { PURPOSE } from "./const";
import { PaymentType } from "./reducers";
import { COUNTRY_OPTION_FORM } from "src/ipm-shared/components/SwitchCountryControl/const";
import {
  getWorldpaySessionId,
  processPaymentRequiredAction
} from "../PaymentRequest/sagas";
import { getQueryParam } from "src/bepaid/utils/getQueryParam";
import PaymentUtil from "src/ipm-shared/Utils/Payment";
import CardUtil from "src/ipm-shared/Utils/Card";
import IPMContext from "src/ipm-shared/Utils/IPMContext";
import { StripeCardCvcElement } from "@stripe/stripe-js";

const actions = {
  ...paymentActions,
  ...formActions,
  ...commonActions
};

const selectors = {
  ...commonSelectors,
  ...payeeSelectors,
  ...accountProfileSelectors,
  ...cardSelectors
};

const watchedSagas = [
  reTryTakeLatest(actions.adminCheckPayment, handleAdminCheckPayment),
  reTryTakeLatest(actions.fetchAdminPayments, handleFetchAdminPayments),
  // reTryTakeLatest(actions.fetchPayments, handleFetchPayments),
  reTryTakeLatest(actions.fetchDashboardPayments, handleFetchDashboardPayments),
  reTryTakeLatest(actions.fetchSchedules, handleFetchSchedules),
  catchTakeLatest(actions.markPaymentAsCompleted, handleMarkPaymentAsCompleted),
  catchTakeLatest(
    actions.markPaymentAsCompletedWithDownloadCSV,
    handleMarkPaymentAsCompletedWithDownloadCSV
  ),
  catchTakeLatest(
    actions.markBulkPaymentsAsCompleted,
    handleMarkBulkPaymentsAsCompleted
  ),
  catchTakeLatest(
    actions.markBulkPaymentsAsCompletedWithDownloadCSV,
    handleMarkBulkPaymentsAsCompletedWithDownloadCSV
  ),
  catchTakeLatest(actions.adminExportPayment, handleAdminExportPayment),
  catchTakeLatest(
    actions.adminExportDailyPayment,
    handleAdminExportDailyPayment
  ),
  reTryTakeLatest(actions.selectPayment, handleFetchPaymentDetail),
  reTryTakeLatest(actions.adminSelectPayment, handleFetchAdminPaymentDetail),
  reTryTakeLatest(
    actions.adminSelectRefundRequestPaymentDetail,
    handleFetchAdminRefundRequestPaymentDetail
  ),
  reTryTakeLatest(actions.adminEditPaymentPayee, adminEditPaymentPayee),
  reTryTakeLatest(actions.selectNextPayment, handleFetchNextPaymentDetail),
  reTryTakeLatest(
    actions.adminSelectNextRefundRequest,
    handleAdminSelectNextRefundRequest
  ),
  reTryTakeLatest(
    actions.adminSelectNextPayment,
    handFetchAdminNextPaymentDetail
  ),
  catchTakeLatest(actions.cancelSchedule, handleCancelSchedule),
  catchTakeLatest(actions.fullyRefund, handleFullyRefund),
  catchTakeLatest(actions.partialRefundPrincipal, handlePartialRefundPrincipal),
  catchTakeLatest(actions.partialRefundFee, handlePartialRefundFee),
  reTryTakeLatest(
    actions.fetchPendingRefundRequests,
    handleFetchPendingRefundRequests
  ),
  catchTakeLatest(actions.processRefund, handleProcessRefund),
  catchTakeLatest(actions.expeditePayoutDate, handleExpeditePayoutDate),
  catchTakeLatest(actions.holdPayment, handleHoldPayment),
  catchTakeLatest(actions.adminCancelPayment, handleAdminCancelPayment),
  reTryTakeLatest(actions.selectSchedule, handleFetchScheduleDetail),
  catchTakeLatest(actions.selectNextSchedule, handleSelectNextSchedule),
  catchTakeLatest(actions.cancelPayments, handleCancelPayments),
  catchTakeLatest(actions.cancelPayment, handleCancelPayment),
  catchTakeLatest(actions.checkFeeVerbose, handleCheckFeeVerbose),
  catchTakeLatest(actions.couponUsageVerbose, handleCouponUsageVerbose),
  catchTakeLatest(actions.editPayments, handleEditPayments),
  catchTakeLatest(actions.validatePayee, handleValidatePayee),
  catchTakeLatest(
    actions.depositInternationalPayments,
    handleDepositInternationalPayments
  ),
  reTryTakeLatest(
    actions.fetchPaymentsHistoryList,
    handleFetchPaymentsHistoryList
  ),
  reTryTakeLatest(
    actions.fetchPaymentsHistoryExport,
    handleFetchPaymentsHistoryExport
  ),
  catchTakeLatest(actions.fetchLogChargeAttempt, handleFetchLogChargeAttempt),
  catchTakeLatest(actions.updateMetadataPayment, handleMetadataPaymentDetail),
  reTryTakeLatest(
    actions.fetchIpmMerchantCategories,
    handleFetchIpmMerchantCategories
  )
];
export default watchedSagas;

export function* handleAdminExportPayment(
  action: ActionType<typeof actions.adminExportPayment>
) {
  const state = yield select();
  const query = formSelectors.getControlsAsObject(state, SEARCH_FORM) as any;
  const queryCountry = formSelectors.getControlsAsObject(
    state,
    COUNTRY_OPTION_FORM
  ) as any;
  const countryId = _get(queryCountry, "switch_control_country_id", 0);
  const currencyId = _get(queryCountry, "switch_control_currency_id", 0);
  query.country_id = countryId;
  query.currency_id = currencyId;
  const controlValue = _get(queryCountry, "switch_control_value", 0);
  if (controlValue === "CITIBANK" || controlValue === "CHEFSTATION") {
    query.partnership = controlValue;
  }
  const payoutDate = query.payout_date_lower
    ? format(query.payout_date_lower, "DD_MMM_YYYY")
    : "";

  const res = yield race({
    res1: call(RestClient.download, {
      fileName: `dbs_fast_payment_csv_GPP_${payoutDate}.csv`,
      query: {
        ...query,
        template: "GPP",
        type: action.payload.type
      },
      service: "get_payments_csv_export"
    })

    // res2: call(RestClient.download, {
    //   fileName: `dbs_fast_payment_csv_LVT_${format(
    //     startDate,
    //     "DD_MMM_YYYY"
    //   )}.csv`,
    //   query: {
    //     end_date: endDate,
    //     environment: query.environment,
    //     reference_no: query.reference_no,
    //     start_date: startDate,
    //     template: "LVT",
    //     type: action.payload.type
    //   },
    //   service: "get_payments_csv_export"
    // })
  });

  const res1 = res.res1;
  const errors = _get(res1, "errors");

  if (!_isEmpty(errors)) {
    for (const e of _get(errors, "form", [])) {
      yield put(actions.toast(T.transl(e)));
    }

    return;
  }
}

export function* handleAdminExportDailyPayment(
  action: ActionType<typeof actions.adminExportDailyPayment>
) {
  const state = yield select();
  const query = formSelectors.getControlsAsObject(state, SEARCH_FORM) as any;
  const queryCountry = formSelectors.getControlsAsObject(
    state,
    COUNTRY_OPTION_FORM
  ) as any;
  const countryId = _get(queryCountry, "switch_control_country_id", 0);
  const currencyId = _get(queryCountry, "switch_control_currency_id", 0);
  query.country_id = countryId;
  query.currency_id = currencyId;
  const startDate = query.payout_date_lower
    ? format(query.payout_date_lower, "DD_MMM_YYYY")
    : "";

  yield call(RestClient.download, {
    fileName: `Payment Operations_(${startDate}).csv`,
    query,
    service: "export_daily_payments_admin"
  });
}

export function* handleFetchAdminPayments(
  action: ActionType<typeof actions.fetchAdminPayments>
) {
  yield put(
    actions.setPayments({
      isFetching: true,
      payments: [],
      sumPaymentAmount: 0,
      sumPaymentTotal: 0,
      total: 0,
      totalBankPayout: 0,
      totalBatch: 0,
      totalStripeTransactions: 0
    })
  );

  const { offset } = action.payload;
  const state = yield select();
  const sortPayments = formSelectors.getControl(state, "sort_payments")
    .value as string;

  let paymentStatusId = formSelectors.getControl(state, "payment_status_id")
    .value;
  if (paymentStatusId) {
    paymentStatusId = (paymentStatusId as string).replace("-", ",");
  }
  const query = formSelectors.getControlsAsObject(state, SEARCH_FORM) as any;

  const queryCountry = formSelectors.getControlsAsObject(
    state,
    COUNTRY_OPTION_FORM
  ) as any;
  const countryId = _get(queryCountry, "switch_control_country_id", 0);
  const currencyId = _get(queryCountry, "switch_control_currency_id", 0);
  query.country_id = countryId;
  query.currency_id = currencyId;
  const controlValue = _get(queryCountry, "switch_control_value", 0);
  if (controlValue === "CITIBANK" || controlValue === "CHEFSTATION") {
    query.partnership = controlValue;
  }
  if (sortPayments) {
    const sort = sortPayments.split(" ");
    query[sort[0]] = sort[1];
  }

  query.offset = offset;
  if (query.email_prefix) {
    query.email_prefix = query.email_prefix.toLowerCase();
  }
  query.page_count = ADMIN_PAGE_COUNT;
  query.payment_status_id = paymentStatusId;
  const res = yield call(RestClient.send, {
    query,
    service: "get_payments_admin"
  });

  if (!res) {
    throw new HttpRequestError("Failed to fetch");
  }
  const { data: payments, ...metadata } = res;

  yield put(
    actions.setPayments({
      isFetching: false,
      payments: payments.map((payment: any) => ({
        accountId: payment.account_id,
        cardCharge: payment.card_charges.map((cc: any) => ({
          amount: cc.amount,
          chargeDate: cc.charge_date,
          id: cc.id,
          receiptNumber: cc.receipt_number,
          status: cc.status
        })),
        cards: payment.cards.map((card: any) => ({
          amount: card.amount,
          cardBrand: card.card_brand,
          id: card.id,
          last4: card.last4,
          statementDescriptor: card.statement_descriptor,
          token: card.token
        })),
        commissionAmount: _get(payment, "comission_amount"),
        company: payment.company_name,
        createdAt: payment.created_at,
        creatorEmail: payment.creator_email,
        currencyId: payment.currency_id,
        id: payment.id,
        isInternationalPayment: payment.is_international_payment,
        payees: payment.payees.map((payee: any) => ({
          accountNumber: payee.account_number,
          bankBSBId: payee.bank_bsb_id,
          bankId: payee.bank_id,
          commissionFee: _get(payee, "commission_fee"),
          commissionRate: _get(payee, "commission_rate"),
          countryId:
            payee.bank_country_id === 0
              ? payee.currency_id
              : payee.bank_country_id,
          currencyId: payee.currency_id,
          currentAmount: payee.amount,
          defaultAmount: payee.amount,
          defaultComments: payee.comments,
          feePayer: payee.fee_payer,
          grossAmount: payee.gross_amount,
          id: payee.id,
          international: {
            bankRawName: payee.bank_raw_name
          },
          name: payee.recipient_name,
          refundedAmount: payee.refunded_amount,
          uid: payee.uid
        })),
        paymentAmount: payment.payment_amount,
        paymentFees: payment.payment_fees,
        paymentStatusId: payment.payment_status_id,
        paymentTotal: payment.payment_total,
        payoutDate: payment.payout_date,
        purposeId: payment.purpose_id,
        receiptNumber: payment.receipt_number,
        scheduleEndDate: payment.schedule_end_date,
        scheduleFrequency: payment.schedule_frequency,
        scheduleId: payment.schedule_id,
        scheduleStartDate: payment.schedule_start_date,
        scheduledChargeDate: payment.scheduled_charge_date,
        settlementTime: payment.settlement_time,
        supportingDocument: [],
        updatedAt: payment.updated_at,
        isAmexMCC: payment.is_amex_mcc
      })),
      sumPaymentAmount: metadata.sum_payment_amount,
      sumPaymentTotal: metadata.sum_payment_total,
      total: metadata.total,
      totalBankPayout: metadata.total_bank_payout,
      totalBatch: metadata.batch_total,
      totalStripeTransactions: metadata.total_stripe_transactions
    })
  );
}

export function* handleAdminCheckPayment(
  action: ActionType<typeof actions.adminCheckPayment>
) {
  const state = yield select();
  const query = formSelectors.getControlsAsObject(
    state,
    PAYMENT_CHECK_SEARCH_FORM
  ) as any;
  const queryCountry = formSelectors.getControl(
    state,
    "switch_control_country_id"
  ) as any;
  const countryId = _get(queryCountry, "value", 1);
  query.country_id = countryId;
  if (query.payment_id === "" || query.card_id === "") {
    return;
  }

  const res = yield call(RestClient.send, {
    query,
    service: "admin_check_payment"
  });

  action.payload.cb(res);
}

export function* handleMarkPaymentAsCompleted(
  action: ActionType<typeof actions.markPaymentAsCompleted>
) {
  yield put(actions.showGlobalLoader());

  const res = yield call(RestClient.send, {
    body: {
      account_id: action.payload.accountId,
      payment_id: action.payload.paymentId
    },
    service: "mark_payment_as_complete"
  });

  yield put(actions.hideGlobalLoader());
  if (!res) {
    return;
  }

  const errors = _get(res, "errors");
  if (!_isEmpty(errors)) {
    for (const e of _get(errors, "form", [])) {
      yield put(actions.toast(T.transl(e)));
    }

    return;
  }

  yield put(actions.fetchAdminPayments());
}

export function* handleMarkPaymentAsCompletedWithDownloadCSV(
  action: ActionType<typeof actions.markPaymentAsCompletedWithDownloadCSV>
) {
  // If Country is MY or HK then call api export csv first then call complete payment action.
  const { accountId, paymentId } = action.payload;
  const state = yield select();
  const query = formSelectors.getControlsAsObject(state, SEARCH_FORM) as any;
  const queryCountry = formSelectors.getControlsAsObject(
    state,
    COUNTRY_OPTION_FORM
  ) as any;
  const countryId = _get(queryCountry, "switch_control_country_id", 0);
  const currencyId = _get(queryCountry, "switch_control_currency_id", 0);
  query.country_id = countryId;
  query.currency_id = currencyId;
  const controlValue = _get(queryCountry, "switch_control_value", 0);
  if (controlValue === "CITIBANK" || controlValue === "CHEFSTATION") {
    query.partnership = controlValue;
  }
  yield call(RestClient.send, {
    query: {
      ...query,
      template: "GPP",
      type: "dbs"
    },
    service: "get_payments_csv_export"
  });

  yield put(actions.markPaymentAsCompleted({ accountId, paymentId }));
}

export function* handleMarkBulkPaymentsAsCompleted(
  action: ActionType<typeof actions.markBulkPaymentsAsCompleted>
) {
  yield put(actions.showGlobalLoader());

  const res = yield call(RestClient.parseJSONAsStream, {
    body: {
      payments: action.payload
    },
    service: "mark_bulk_payments_as_complete",
    timeout: -1,
    showGlobalLoader: true
  });

  if (!res) {
    return;
  }

  const channel = eventChannel(emitter => {
    let count = 0;
    const total = action.payload.length;
    res
      .node("finished_id", function(id: number) {
        count++;
        emitter({
          done: false,
          message: `Processed ${count}/${total} payments`
        });
      })
      .node("errors.form", function(reason: string[]) {
        if (reason[0]) {
          emitter({
            done: true,
            success: false,
            message: T.transl(reason[0])
          });
        } else {
          emitter({
            done: true,
            success: false,
            message: T.transl(
              "Internally technical issue. Please contact engineers."
            )
          });
        }
      })
      .node("done", function() {
        emitter({
          done: true,
          success: true
        });
      });

    return () => {
      // Perform any clean up here
    };
  });

  while (true) {
    const { done, success, message } = yield take(channel);

    if (!done) {
      yield put(
        actions.updateLoaderMessage(
          `${message}. Refresh the page to cancel request`
        )
      );
    } else {
      if (success) {
        yield put(actions.updateLoaderMessage(`Success. Close in 6s`));
        yield put(actions.fetchAdminPayments());
      } else {
        yield put(
          actions.updateLoaderMessage(
            `Action failed and be rollbacled. Reason: ${message}. Close in 6s`
          )
        );
      }
      yield call(delay, 6000);
      yield put(actions.hideGlobalLoader());

      break;
    }
  }
}

export function* handleMarkBulkPaymentsAsCompletedWithDownloadCSV(
  action: ActionType<typeof actions.markBulkPaymentsAsCompletedWithDownloadCSV>
) {
  // If Country is MY or HK then call api export csv first then call complete payment action.
  const state = yield select();
  const query = formSelectors.getControlsAsObject(state, SEARCH_FORM) as any;
  const queryCountry = formSelectors.getControlsAsObject(
    state,
    COUNTRY_OPTION_FORM
  ) as any;
  const countryId = _get(queryCountry, "switch_control_country_id", 0);
  const currencyId = _get(queryCountry, "switch_control_currency_id", 0);
  query.country_id = countryId;
  query.currency_id = currencyId;
  const controlValue = _get(queryCountry, "switch_control_value", 0);
  if (controlValue === "CITIBANK" || controlValue === "CHEFSTATION") {
    query.partnership = controlValue;
  }
  yield call(RestClient.send, {
    query: {
      ...query,
      template: "GPP",
      type: "dbs"
    },
    service: "get_payments_csv_export"
  });

  yield put(actions.markBulkPaymentsAsCompleted(action.payload));
}

export function* handleFetchDashboardPayments(
  action: ActionType<typeof actions.fetchDashboardPayments>
) {
  const res: Response = yield call(RestClient.send, {
    service: "get_payments_history_dashboard_payment_list"
  });

  if (!res) {
    throw new HttpRequestError("Failed to fetch");
  }

  const data: any[] = _get(res, "data.payments", []);
  yield put(
    actions.setPayments({
      isFetching: false,
      payments: data.map(payment => ({
        cards: payment.cards.map((card: any) => ({
          cardBrand: card.card_brand,
          id: card.id,
          last4: card.last4,
          statementDescriptor: card.statement_descriptor
        })),
        createdAt: payment.created_at,
        currencyId: payment.currency_id,
        id: payment.id,
        order: payment.order,
        payees: payment.payees.map((payee: any) => ({
          accountNumber: payee.account_number,
          bankId: payee.bank_id,
          countryId:
            payee.bank_country_id === 0
              ? payee.currency_id
              : payee.bank_country_id,
          currencyId: payee.currency_id,
          currentAmount: payee.amount,
          defaultAmount: payee.amount,
          defaultComments: payee.comments,
          id: payee.id,
          name: payee.recipient_name,
          refundedAmount: payee.refunded_amount,
          bankAccountHolderName: payee.bank_account_holder_name,
          uid: payee.uid
        })),
        paymentAmount: payment.payment_amount,
        paymentFees: payment.payment_fees,
        paymentStatusId: payment.payment_status_id,
        paymentTotal: payment.payment_total,
        payoutDate: payment.payout_date,
        purposeId: payment.purpose_id,
        receiptNumber: payment.receipt_number,
        scheduleEndDate: payment.schedule_end_date,
        scheduleFrequency: payment.schedule_frequency,
        scheduleId: payment.schedule_id,
        scheduleStartDate: payment.schedule_start_date,
        isInternationalPayment: payment.is_international_payment,
        scheduledChargeDate: payment.scheduled_charge_date,
        supportingDocument: [],
        updatedAt: payment.updated_at,
        isAmexMCC: payment.is_amex_mcc
      }))
    })
  );
}

export function* handleFetchSchedules(
  action: ActionType<typeof actions.fetchSchedules>
) {
  yield put(
    actions.setSchedules({
      isFetching: true,
      schedules: []
    })
  );

  const dfPurposeIds = "1,2,3,5,7,8";

  const { offset, isActive, pagecount, cardId, withoutPayout } = action.payload;
  const qs = queryString.parse(window.location.search);
  const searchKeywordFromQs = qs.search_keyword;
  const frequencyFromQs = qs.frequency;
  const isActiveFromQS = qs.statuses;
  const purposesFromQs = qs.purposes;

  const {
    from: payoutDateLowerFromQs,
    to: payoutDateUpperFromQs
  } = getQueryParam(
    qs,
    "payout_date_key",
    "payout_date_from",
    "payout_date_to"
  );

  const query = {
    is_active: isActive,
    offset,
    page_count: pagecount || SCHEDULED_PAGE_COUNT,
    purposes: purposesFromQs || dfPurposeIds,
    card_id: cardId
  } as any;

  const isInvoiceFilter = +query.purposes === PURPOSE.INVOICE;
  const isTaxFilter = query.purposes.includes(PURPOSE.TAX);

  if (searchKeywordFromQs) {
    query.search_keyword = searchKeywordFromQs;
  }

  if (frequencyFromQs) {
    query.frequency = frequencyFromQs;
  }

  if (payoutDateLowerFromQs && !withoutPayout) {
    query.payout_date_lower = payoutDateLowerFromQs;
  }
  if (payoutDateUpperFromQs && !withoutPayout) {
    query.payout_date_upper = payoutDateUpperFromQs;
  }
  if (isActiveFromQS) {
    query.is_active = isActiveFromQS;
  }

  query.purposes = query.purposes.replace(PURPOSE.TAX, PURPOSE.INVOICE);

  const res: Response = yield call(RestClient.send, {
    query,
    service: IPMContext.isPayFetchPlatform()
      ? "get_schedules_compact"
      : "get_schedules"
  });

  if (!res) {
    yield put(
      actions.setSchedules({
        isFetching: false,
        schedules: []
      })
    );
    throw new HttpRequestError("Failed to fetch");
  }

  const data: any[] = _get(res, "data", []);
  const total: number = _get(res, "total", 0);
  if (!data) {
    yield put(
      actions.setSchedules({
        isFetching: false,
        schedules: []
      })
    );
    return;
  }

  let schedules = data.map(schedule => ({
    cards: (_get(schedule, "cards") || []).map((card: any) => ({
      id: card.id,
      statementDescriptor: card.statement_descriptor
    })),
    hasSpecialRate: schedule.has_special_rate,
    isActive: schedule.is_active,
    isCancelled: schedule.is_cancelled,
    payees: (_get(schedule, "payees") || []).map((payee: any) => ({
      accountNumber: payee.account_number,
      bankId: payee.bank_id,
      currentAmount: payee.amount,
      defaultAmount: payee.amount,
      defaultComments: payee.comments,
      id: payee.id,
      recipientName: payee.recipient_name,
      refundedAmount: payee.refunded_amount,
      bankAccountHolderName: payee.bank_account_holder_name
    })),
    isInternationalPayment: schedule.is_international_payment,
    paymentAmount: schedule.payment_amount,
    paymentFees: schedule.payment_fees,
    paymentStatusId: schedule.payment_status_id,
    paymentTotal: schedule.payment_total,
    purposeId: schedule.purpose_id,
    scheduleEndDate: schedule.end_date,
    scheduleFrequency: schedule.frequency,
    scheduleId: schedule.id,
    scheduleStartDate: schedule.start_date,
    scheduleStatus: schedule.schedule_status
  }));

  if (isTaxFilter) {
    schedules = schedules.filter(schedule => {
      const payeeAccountNumber = _get(schedule, "payees[0].accountNumber");
      return (
        payeeAccountNumber === "0010468669" || payeeAccountNumber === "316385"
      );
    });
  }

  if (isInvoiceFilter) {
    schedules = schedules.filter(schedule => {
      const payeeAccountNumber = _get(schedule, "payees[0].accountNumber");
      return (
        payeeAccountNumber !== "0010468669" && payeeAccountNumber !== "316385"
      );
    });
  }

  yield put(
    actions.setSchedules({
      isFetching: false,
      schedules,
      total
    })
  );

  if (action.payload.cb) {
    yield action.payload.cb(null, schedules);
  }
  if (process.env.REACT_APP_FETCH_APP_ID === "fetch") {
    const state: RootState = yield select();
    if (state.payments.selectedScheduleId > 0) {
      yield put(actions.selectSchedule(state.payments.selectedScheduleId));
    }
  }
}

export function* handleFetchPaymentDetail(
  action: ActionType<typeof actions.selectPayment>
) {
  const {
    cb = () => null,
    withPaymentActivity = true,
    idType = "id",
    withSchedule = true
  } = action.payload;

  const paymentId = action.payload.id;

  if (paymentId < 0) {
    return;
  }

  let query = {};
  if (withPaymentActivity) {
    query = {
      ...query,
      payment_activity: "y"
    };
  }
  if (withSchedule) {
    query = {
      ...query,
      with_schedule: "y"
    };
  }
  const res: Response = yield call(RestClient.send, {
    params: {
      id: paymentId
    },
    query,
    service:
      idType === "regNo"
        ? "get_payment_detail_by_recipient_number"
        : "get_payment_detail",
    showGlobalLoader: true
  });

  if (!res) {
    throw new HttpRequestError("Failed to fetch");
  }

  const payment: any = _get(res, "data");

  yield put(actions.updatePaymentDetail(payment.id));
  yield put(
    actions.setControl({
      form: "default",
      name: "selected_payment",
      value: payment.id
    })
  );

  const calcPayment = {
    amountOff: payment.amount_off,
    cardCharge: payment.card_charges.map((cc: any) => {
      return {
        id: cc.id,
        refundedAmount: cc.refunded_amount,
        refundedFee: cc.refunded_fee,
        refundedGSTFee: cc.refunded_gst_fee,
        refundedFlashPayFee: cc.refunded_flash_pay_fee,
        refundedBankPayoutFee: cc.refunded_bank_payout_fee,
        refundedMinimumTransactionFee: cc.refunded_minimum_transaction_fee,
        refundedReason: cc.refuse_reason,
        refuseReason: cc.refuse_reason
      };
    }),
    cards: payment.cards.map((card: any) => ({
      cardBrand: card.card_brand,
      cardBrandId: card.card_brand_id,
      id: card.id,
      last4: card.last_4,
      statementDescriptor: card.statement_descriptor
    })),
    channelFees: payment.channel_fees,
    couponCode: payment.coupon_code,
    createdAt: payment.created_at,
    currencyId: payment.currency_id,
    exchangeRate: payment.exchange_rate,
    feeRate: payment.rate,
    id: payment.id,
    isMultiplePurpose: payment.is_multiple_purpose,
    payees: payment.payees.map((payee: any) => ({
      accountNumber: payee.account_number,
      bankBSBId: payee.bank_bsb_id,
      bankId: payee.bank_id,
      countryId:
        payee.bank_country_id === 0 ? payee.currency_id : payee.bank_country_id,
      currencyId: payee.currency_id,
      currentAmount: payee.amount,
      defaultAmount: payee.amount,
      defaultComments: payee.comments,
      dueDate: payee.due_date,
      files: [],
      grossAmount: payee.gross_amount,
      id: payee.id,
      international: {
        bankAccountHolderName: payee.bank_account_holder_name,
        bankRawName: payee.bank_raw_name
      },
      name: payee.recipient_name,
      paymentDescription: payee.payment_description,
      postalCode: payee.data.postal_code,
      unitNumber: payee.data.unit_number,
      refundedAmount: payee.refunded_amount,
      uid: payee.uid,
      ...extractPayeeData(payee.data)
    })),
    payer: payment.payer
      ? {
          firstName: payment.payer.first_name,
          lastName: payment.payer.last_name
        }
      : {},
    paymentActivities: payment.payment_activity,
    paymentAmount: payment.payment_amount,
    paymentFees: payment.payment_fees,
    paymentFlashPayFees: payment.payment_flash_pay_fees,
    paymentGSTFees: payment.payment_gst_fees,
    paymentBankPayoutFees: payment.payment_bank_payout_fees,
    paymentMinimumTransactionFees: payment.payment_minimum_transaction_fees,
    paymentStatusId: payment.payment_status_id,
    isOnScreening: payment.is_onscreening_payment,
    isDeductedRate: payment.is_deducted_rate,
    paymentTotal: payment.payment_total,
    payoutDate: payment.payout_date,
    // tslint:disable-next-line:object-literal-sort-keys
    isInternationalPayment: payment.is_international_payment,
    payUrl: payment.pay_url,
    purposeId: payment.purpose_id,
    receiptNumber: payment.receipt_number,
    scheduleEndDate: payment.schedule_end_date,
    scheduleFrequency: payment.schedule_frequency,
    scheduleId: payment.schedule_id,
    scheduleStartDate: payment.schedule_start_date,
    scheduledChargeDate: payment.scheduled_charge_date,
    schedulePayments: {
      isActive: _get(payment, "schedule_payments.is_active"),
      payments: _get(payment, "schedule_payments.payments", []).map(
        (item: any) => {
          return {
            payoutDate: item.payout_date,
            paymentStatusId: item.payment_status_id,
            id: item.id,
            receiptNumber: item.receipt_number,
            accountId: item.account_id,
            cards: item.cards,
            paymentTotal: item.payment_total,
            editable: item.editable
          };
        }
      ),
      frequency: _get(payment, "schedule_payments.frequency")
    },
    supportingDocument: [],
    updatedAt: payment.updated_at,
    metadata: payment.metadata,
    isAmexMCC: payment.is_amex_mcc,
    paymentMethodType: payment.payment_method_type,
    cryptoPaymentDetail: {
      id: _get(payment, "crypto_payment_detail.id"),
      cryptoCurrency: _get(payment, "crypto_payment_detail.crypto_currency"),
      exchangeRate: _get(payment, "crypto_payment_detail.exchange_rate")
    }
  };
  if (withSchedule) {
    yield put(actions.selectSchedule(payment.schedule_id));
  }
  yield put(actions.setSelectedPayment({ payment: calcPayment }));
  yield put(actions.updatePaymentDetail(payment.id, calcPayment));

  cb(null, payment);
}
export function* handleMetadataPaymentDetail(
  action: ActionType<typeof actions.updateMetadataPayment>
) {
  const { id: paymentId, metadata = {}, cb } = action.payload;

  if (paymentId < 0) {
    return;
  }

  const res: Response = yield call(RestClient.send, {
    params: { payment_id: paymentId },
    body: { ...metadata },
    service: "metadata_payment"
  });

  if (!res) {
    throw new HttpRequestError("Failed to update payment metadata");
  }

  yield put(actions.setMetadataPayment(metadata));
  if (cb) {
    cb();
  }
}

export function* handleFetchAdminPaymentDetail(
  action: ActionType<typeof actions.adminSelectPayment>
) {
  const paymentId = action.payload.id;
  yield put(actions.updatePaymentDetail(paymentId));

  if (paymentId < 0) {
    return;
  }

  yield put(
    actions.setControl({
      form: "default",
      name: "selected_payment",
      value: paymentId
    })
  );

  const res: Response = yield call(RestClient.send, {
    params: {
      id: paymentId
    },
    service: "get_payment_detail_admin"
  });

  if (!res) {
    throw new HttpRequestError("Failed to fetch");
  }

  const payment: any = _get(res, "data.0");
  yield put(
    actions.updatePaymentDetail(paymentId, {
      amountOff: payment.amount_off,
      cardCharge: payment.card_charges.map((cc: any) => ({
        chargeDate: cc.charge_date,
        id: cc.id,
        receiptNumber: cc.receipt_number,
        refundedAmount: cc.refunded_amount,
        refundedFee: cc.refunded_fee,
        refundedReason: cc.refuse_reason,
        refuseReason: cc.refuse_reason
      })),
      cards: (payment.cards || []).map((card: any) => ({
        cardBrand: card.card_brand,
        id: card.id,
        last4: card.last_4,
        statementDescriptor: card.statement_descriptor
      })),
      channelFees: payment.channel_fees,
      company: payment.company_name,
      couponCode: payment.coupon_code,
      createdAt: payment.created_at,
      creatorEmail: payment.creator_email,
      currencyId: payment.currency_id,
      customerEmail: payment.customer_email,
      exchangeRate: payment.exchange_rate,
      feeRate: payment.rate,
      id: payment.id,
      isInternationalPayment: payment.is_international_payment,
      origPayoutDate: payment.orig_payout_date.Valid
        ? payment.orig_payout_date.Time
        : undefined,
      payees: payment.payees.map((payee: any) => ({
        accountNumber: payee.account_number,
        bankBSBId: payee.bank_bsb_id,
        bankId: payee.bank_id,
        commissionFee: _get(payee, "commission_fee"),
        commissionRate: _get(payee, "commission_rate"),
        countryId:
          payee.bank_country_id === 0
            ? payee.currency_id
            : payee.bank_country_id,
        currencyId: payee.currency_id,
        currentAmount: payee.amount,
        defaultAmount: payee.amount,
        defaultComments: payee.comments,
        feePayer: payee.fee_payer,
        files: payee.payee_data.supporting_documents_data,
        grossAmount: payee.gross_amount,
        id: payee.id,
        idNumber: payee.payee_data.id_number,
        international: {
          bankAccountHolderName: payee.bank_account_holder_name,
          bankRawName: payee.bank_raw_name
        },
        name: payee.recipient_name,
        recipientContactName: payee.payee_data.recipient_contact_name,
        refundedAmount: payee.refunded_amount,
        registrationNumber:
          payee.payee_data.registration_number || payee.payee_data.id_number,
        ...extractPayeeData(payee.payee_data),
        paymentDescription: payee.payment_description,
        uid: payee.uid
      })),
      paymentAmount: payment.payment_amount,
      paymentFees: payment.payment_fees,
      paymentStatusId: payment.payment_status_id,
      paymentTotal: payment.payment_total,
      payoutDate: payment.payout_date,
      purposeId: payment.purpose_id,
      receiptNumber: payment.receipt_number,
      scheduleEndDate: payment.schedule_end_date,
      scheduleFrequency: payment.schedule_frequency,
      scheduleId: payment.schedule_id,
      scheduleStartDate: payment.schedule_start_date,
      scheduledChargeDate: payment.scheduled_charge_date,
      settlementTime: payment.settlement_time,
      supportingDocument: payment.supporting_documents || [],
      updatedAt: payment.updated_at,
      isAmexMCC: payment.is_amex_mcc
    })
  );
}

export function* handleFetchAdminRefundRequestPaymentDetail(
  action: ActionType<typeof actions.adminSelectRefundRequestPaymentDetail>
) {
  const paymentId = action.payload.id;

  if (paymentId < 0) {
    return;
  }
  yield put(
    actions.setControl({
      form: "default",
      name: "selected_refund_request_payment_detail",
      value: paymentId
    })
  );

  yield put(actions.adminSelectPayment(paymentId));
}

export function* handleFetchNextPaymentDetail(
  action: ActionType<typeof actions.selectPayment>
) {
  const state = yield select();
  let nextSelectedPayment = formSelectors.getControl(
    state,
    "next_selected_payment"
  ).value;

  const args = (nextSelectedPayment as string).split(",");
  nextSelectedPayment = args[0];

  nextSelectedPayment = parseInt(nextSelectedPayment, 10);

  yield put(actions.selectPayment(nextSelectedPayment));
}

export function* handFetchAdminNextPaymentDetail(
  action: ActionType<typeof actions.adminSelectPayment>
) {
  const state = yield select();
  let nextSelectedPayment = formSelectors.getControl(
    state,
    "next_selected_payment"
  ).value;

  nextSelectedPayment = parseInt(nextSelectedPayment as string, 10);
  yield put(actions.adminSelectPayment(nextSelectedPayment));
}

export function* handleAdminSelectNextRefundRequest(
  action: ActionType<typeof actions.adminSelectNextRefundRequest>
) {
  const state = yield select();
  let nextSelectedPayment = formSelectors.getControl(
    state,
    "next_selected_refund_request"
  ).value;
  nextSelectedPayment = parseInt(nextSelectedPayment as string, 10);
  yield put(actions.adminSelectRefundRequestPaymentDetail(nextSelectedPayment));
}

export function* adminEditPaymentPayee(
  action: ActionType<typeof actions.adminEditPaymentPayee>
) {
  const state = yield select();
  const selectedPayment = selectors.getSelectedPaymentDetail(state);
  const payees = selectedPayment.payees;

  const documentTags = formSelectors.getControlsPattern(
    state,
    /^document_tag_/
  );

  const paymentSupportingDocs: {
    [payeeId: number]: string[];
  } = {};
  documentTags.map(control => {
    const fileRef = control.name.replace("document_tag_", "");
    const supportingDocument = formSelectors.getControl(state, fileRef)
      .value as string;

    if (supportingDocument) {
      switch (control.value) {
        case "all":
          for (const payee of payees) {
            paymentSupportingDocs[payee.id] =
              paymentSupportingDocs[payee.id] || [];
            paymentSupportingDocs[payee.id].push(supportingDocument);
            paymentSupportingDocs[payee.id] = _uniq(
              paymentSupportingDocs[payee.id]
            );
          }
          break;
        default:
          const payeeId = parseInt(control.value as string, 10);
          paymentSupportingDocs[payeeId] = paymentSupportingDocs[payeeId] || [];
          paymentSupportingDocs[payeeId].push(supportingDocument);
          paymentSupportingDocs[payeeId] = _uniq(
            paymentSupportingDocs[payeeId]
          );
      }
    }
  });

  const newPayees = payees.map((payee: any) => {
    const recipient_name = formSelectors.getControl(
      state,
      `payee_name_${payee.id}`
    ).value;
    const accountNumber = formSelectors.getControl(
      state,
      `account_no_${payee.id}`
    ).value;
    const comments = formSelectors.getControl(
      state,
      `comments_${payee.id}${payee.uid ? `_${payee.uid}` : ""}`
    ).value;
    const bankId = formSelectors.getControl(state, `bank_id_${payee.id}`).value;
    const bankRawName = formSelectors.getControl(
      state,
      `bank_raw_name_${payee.id}`
    ).value;
    const requestdPayees = {
      account_number: accountNumber,
      bank_id: bankId,
      bank_raw_name: bankRawName,
      comments,
      id: payee.id,
      recipient_name: recipient_name,
      uid: payee.uid || ""
    };
    return requestdPayees;
  });
  const paymentId = selectedPayment.id;

  const payload = {
    payees: newPayees,
    payment_id: paymentId,
    purpose_id: selectedPayment.purposeId,
    supporting_documents: paymentSupportingDocs
  };

  const res: Response = yield call(RestClient.send, {
    body: payload,
    service: "admin_edit_payment_payee",
    showGlobalLoader: true
  });

  const errors = _get(res, "errors", undefined);
  if (!res || errors) {
    action.payload.isSuccess(false);
    yield put(
      formActions.parseServerErrors(errors, PAYMENT_PAYEE_EDIT_FORM, undefined)
    );
    return;
  }

  selectedPayment.paymentStatusId = PAYMENT_STATUS.ON_HOLD;
  action.payload.isSuccess(true);
  yield put(formActions.toast(T.transl("ADMIN_EDIT_PAYEE_SUCCESS")));
  yield put(actions.updatePaymentDetail(selectedPayment.id, selectedPayment));
}

function extractPayeeData(data: any): Partial<PayeeType> {
  // Currently we only have rental payee.
  return {
    address: data.address
  };
}

export function* handleCancelSchedule(
  action: ActionType<typeof actions.cancelSchedule>
) {
  const scheduleId = action.payload.id;

  const res: Response = yield call(RestClient.send, {
    params: {
      id: scheduleId
    },
    service: "cancel_schedule",
    showGlobalLoader: true
  });

  if (!res) {
    return;
  }

  const errors = _get(res, "errors", {});
  if (!_isEmpty(errors)) {
    if (action.payload.cb) {
      action.payload.cb(errors);
    }
    yield put(formActions.parseServerErrors(errors, CONFIRM_FORM));
    return;
  }
  yield put(actions.fetchSchedules());
  if (action.payload.cb) {
    action.payload.cb(null);
  }
}

export function* handleFullyRefund(
  action: ActionType<typeof actions.fullyRefund>
) {
  yield put(actions.showGlobalLoader());
  const state: RootState = yield select();
  const formState = formSelectors.getControls(state, REFUND_FORM);

  const paymentId = action.payload.id;
  const holdRequestId = action.payload.holdRequestId;
  const widthFee = _get(formState, "refund_with.value", "") === "WITH_FEE";
  let refundReason = _get(formState, "reason_refund.value", "");
  const refundReasonCode = refundReason.replace(" ", "_").toUpperCase();
  const otherRefundReason = _get(formState, "other_reason.value", "");

  if (refundReason === "other") {
    refundReason = otherRefundReason;
  }

  const res: Response = yield call(RestClient.send, {
    body: {
      hold_request_id: holdRequestId,
      other_refusal_reason: "",
      payment_id: paymentId,
      refusal_reason: refundReason,
      refusal_reason_code: refundReasonCode,
      with_fee: widthFee
    },
    service: "fully_refund"
  });

  yield put(actions.hideGlobalLoader());

  if (!res) {
    return;
  }

  const errors = _get(res, "errors", {});
  if (!_isEmpty(errors)) {
    yield put(formActions.parseServerErrors(errors, REFUND_FORM));
    return;
  }

  yield put(actions.closeModal(actions.ModalID.FULL_REFUND_MODAL));
  yield put(actions.fetchPendingRefundRequests());
  yield put(actions.fetchAdminPayments());
}

export function* handlePartialRefundPrincipal(
  action: ActionType<typeof actions.partialRefundPrincipal>
) {
  yield put(actions.showGlobalLoader());

  const payees = action.payload.payees;
  const paymentId = action.payload.id;
  const holdRequestId = action.payload.holdRequestId;
  const currentFee = action.payload.currentFee * 100;

  const res: Response = yield call(RestClient.send, {
    body: {
      current_rate: currentFee,
      hold_request_id: holdRequestId,
      new_rate: currentFee,
      payees: Object.keys(payees).map((key: any) => ({
        refunded_amount: payees[key]
      })),
      payment_id: paymentId,
      refusal_reason: "",
      refusal_reason_code: ""
    },
    service: "partial_refund_principal"
  });

  yield put(actions.hideGlobalLoader());

  if (!res) {
    return;
  }

  const errors = _get(res, "errors", {});
  if (!_isEmpty(errors)) {
    yield put(formActions.parseServerErrors(errors, REFUND_FORM));
    return;
  }

  yield put(actions.closeModal(actions.ModalID.PRINCIPAL_REFUND_MODAL));
  yield put(actions.fetchPendingRefundRequests());
  yield put(actions.fetchAdminPayments());
}

export function* handlePartialRefundFee(
  action: ActionType<typeof actions.partialRefundFee>
) {
  yield put(actions.showGlobalLoader());

  const paymentId = action.payload.id;
  const holdRequestId = action.payload.holdRequestId;
  const state: RootState = yield select();
  const formState = formSelectors.getControls(state, REFUND_FORM);
  let refundReason = _get(formState, "reason_refund.value", "");
  const refundReasonCode = refundReason.replace(" ", "_").toUpperCase();
  const otherRefundReason = _get(formState, "other_reason.value", "");
  const currentRate = Math.trunc(
    Math.round(parseFloat(_get(formState, "current_fee.value", 0)) * 100)
  );

  const newRate = Math.trunc(
    Math.round(parseFloat(_get(formState, "new_fee.value", 0)) * 100)
  );

  if (refundReason === "other") {
    refundReason = otherRefundReason;
  }

  const res: Response = yield call(RestClient.send, {
    body: {
      current_rate: currentRate,
      hold_request_id: holdRequestId,
      new_rate: newRate,
      payment_id: paymentId,
      refusal_reason: refundReason,
      refusal_reason_code: refundReasonCode
    },
    service: "partial_refund_fee"
  });

  yield put(actions.hideGlobalLoader());

  if (!res) {
    return;
  }

  const errors = _get(res, "errors", {});
  if (!_isEmpty(errors)) {
    yield put(formActions.parseServerErrors(errors, REFUND_FORM));
    return;
  }

  yield put(actions.closeModal(actions.ModalID.FEE_REFUND_MODAL));
  yield put(actions.fetchPendingRefundRequests());
  yield put(actions.fetchAdminPayments());
}

export function* handleFetchPendingRefundRequests(
  action: ActionType<typeof actions.fetchPendingRefundRequests>
) {
  yield put(
    actions.setPayments({
      isFetching: true,
      payments: []
    })
  );

  const state = yield select();
  const query = formSelectors.getControlsAsObject(state, SEARCH_FORM) as any;
  const queryCountry = formSelectors.getControlsAsObject(
    state,
    COUNTRY_OPTION_FORM
  ) as any;
  const currencyId = _get(queryCountry, "switch_control_currency_id", 0);
  query.currency_id = currencyId;
  if (currencyId == null) {
    query.currency_id = 0;
  }
  const res: Response = yield call(RestClient.send, {
    query,
    service: "get_payments_refund"
  });

  if (!res) {
    yield put(
      actions.setPayments({
        isFetching: false,
        payments: []
      })
    );
    throw new HttpRequestError("Failed to fetch");
  }

  const data: any[] = _get(res, "data", []);
  const refundData = data.map(request => {
    const refund: any = {
      actionType: request.action_type,
      chargeDate: request.charge_date,
      createdAt: request.created_at,
      createdBy: {
        email: request.created_by.email,
        firstName: request.created_by.first_name,
        lastName: request.created_by.last_name,
        userId: request.created_by.user_id
      },
      email: request.email,
      paymentAmount: request.payment_amount,
      paymentFees: request.payment_fees,
      paymentId: request.payment_id,
      paymentTotal: request.payment_total,
      payoutDate: request.payout_date,
      receiptNumber: request.receipt_number,
      requestId: request.request_id,
      scheduledChargeDate: request.scheduled_charge_date
    };
    if (refund.actionType === "edit_payment_payee") {
      refund.metaData = {
        supportingDocuments: request.metadata?.supporting_documents,
        payees: request.metadata.payees.map((m: any) => ({
          accountNumber: m.account_number,
          bankId: m.bank_id,
          bankRawName: m.bank_raw_name,
          comments: m.comments,
          payeeId: m.id,
          recipientName: m.recipient_name,
          uid: m.uid || ""
        }))
      };

      refund.oldMetaData = {
        supportingDocuments: request.old_metadata?.supporting_documents,
        payees: request.old_metadata.payees.map((m: any) => ({
          accountNumber: m.account_number,
          bankId: m.bank_id,
          bankRawName: m.bank_raw_name,
          comments: m.comments,
          payeeId: m.id,
          recipientName: m.recipient_name,
          uid: m.uid || ""
        }))
      };
    } else {
      refund.metaData = {
        newPaymentFees: request.metadata.new_payment_fees,
        newPaymentTotal: request.metadata.new_payment_total,
        newPayoutDate: request.metadata.new_payout_date,
        newRate: request.metadata.new_rate,
        otherRefusalReason: request.metadata.other_refusal_reason,
        paymentRefNo: request.receipt_number,
        refundedAmount: request.metadata.refunded_amount,
        refundedFee: request.metadata.refunded_fee,
        refundedTotal: request.metadata.refunded_total,
        refusalReason: request.metadata.refusal_reason,
        refusalReasonCode: request.metadata.refusal_reason_code
      };
    }
    return refund;
  });

  yield put(
    actions.setRefundRequests({
      isFetching: false,
      refundRequests: refundData
    })
  );
}

export function* handleProcessRefund(
  action: ActionType<typeof actions.processRefund>
) {
  yield put(actions.showGlobalLoader());
  const paymentId = action.payload.paymentId;
  const requestId = action.payload.requestId;
  const processStatus = action.payload.status;

  const res: Response = yield call(RestClient.send, {
    body: {
      payment_id: paymentId,
      request_id: requestId,
      status: processStatus
    },
    service: "process_refund"
  });

  yield put(actions.hideGlobalLoader());

  if (!res) {
    return;
  }

  const errors = _get(res, "errors", {});
  if (!_isEmpty(errors)) {
    yield put(formActions.parseServerErrors(errors, CONFIRM_FORM));
    return;
  }

  yield put(actions.fetchPendingRefundRequests());
}

export function* handleExpeditePayoutDate(
  action: ActionType<typeof actions.expeditePayoutDate>
) {
  yield put(actions.showGlobalLoader());
  const paymentId = action.payload.paymentId;
  const state: RootState = yield select();
  const formState = formSelectors.getControls(state, EXPEDITE_FORM);
  const expeditedPayoutDate = _get(
    formState,
    "expedited_payout_date.value",
    ""
  );

  const res: Response = yield call(RestClient.send, {
    body: {
      payment_id: paymentId,
      payout_date: expeditedPayoutDate
    },
    service: "expedite_payout_date"
  });

  yield put(actions.hideGlobalLoader());

  if (!res) {
    return;
  }

  const errors = _get(res, "errors", {});
  if (!_isEmpty(errors)) {
    yield put(formActions.parseServerErrors(errors, EXPEDITE_FORM));
    return;
  }

  yield put(actions.closeModal(ModalID.EXPEDITE_MODAL));
  yield put(actions.fetchAdminPayments());
}

export function* handleHoldPayment(
  action: ActionType<typeof actions.holdPayment>
) {
  const paymentId = action.payload.paymentId;

  const res: Response = yield call(RestClient.send, {
    body: {
      payment_id: paymentId
    },
    service: "hold_payment",
    showGlobalLoader: true
  });

  if (!res) {
    return;
  }

  const errors = _get(res, "errors", {});
  if (!_isEmpty(errors)) {
    yield put(formActions.parseServerErrors(errors, CONFIRM_FORM));
    return;
  }
  yield put(actions.fetchAdminPayments());
}

export function* handleFetchScheduleDetail(
  action: ActionType<typeof actions.selectSchedule>
) {
  const { id } = action.payload;
  if (id < 0) {
    return;
  }
  yield put(
    actions.setControl({
      form: "default",
      name: "selected_schedule",
      value: id
    })
  );

  const res: Response = yield call(RestClient.send, {
    params: {
      id
    },
    service: "get_schedule_detail",
    showGlobalLoader: true
  });

  if (!res) {
    throw new HttpRequestError("Failed to fetch");
  }

  const data: any[] = _get(res, "data", []);
  const upcomingPayments: any[] = _get(data, "payments", []);
  const schedule = _get(data, "schedule", {});
  const paymentSetting = _get(data, "schedule.payment_setting", {});
  yield put(
    actions.setScheduleDetail({
      lastPayout: schedule.last_payout,
      paymentSetting: {
        cards: paymentSetting.cards
          ? _get(paymentSetting, "cards", []).map((card: any) => ({
              cardBrand: card.card_brand,
              id: card.id,
              last4: card.last4,
              statementDescriptor: card.statement_descriptor
            }))
          : undefined,
        couponCode: paymentSetting.coupon,
        createdAt: _get(paymentSetting, "created_at", ""),
        currencyId: paymentSetting.currency_id,
        id: _get(paymentSetting, "id", 0),
        payees: paymentSetting.payees.map((payee: any) => ({
          accountNumber: payee.account_number,
          bankId: payee.bank_id,
          bankBSBId: payee.bank_bsb_id,
          currentAmount: payee.amount,
          defaultAmount: payee.amount,
          defaultComments: payee.comments,
          id: payee.id,
          name: payee.recipient_name,
          refundedAmount: payee.refunded_amount,
          uid: payee.uid,
          ...extractPayeeData(payee.data)
        })),
        paymentAmount: paymentSetting.payment_amount,
        paymentFees: paymentSetting.payment_fees,
        paymentGstFees: paymentSetting.payment_gst_fees,
        paymentBankPayoutFees: paymentSetting.payment_bank_payout_fees,
        paymentMinimumTransactionFees:
          paymentSetting.payment_minimum_transaction_fees,
        paymentStatusId: paymentSetting.payment_status_id || 0,
        paymentTotal: paymentSetting.payment_total,
        payoutDate: paymentSetting.payout_date || "",
        purposeId: paymentSetting.purpose_id,
        receiptNumber: paymentSetting.receipt_number || "",
        scheduleEndDate: schedule.end_date,
        scheduleFrequency: schedule.frequency,
        scheduleId: schedule.id,
        scheduleStartDate: schedule.start_date,
        scheduledChargeDate: schedule.scheduled_charge_date || "",
        upcomingPayout: schedule.upcoming_payout || "",
        supportingDocument: [],
        updatedAt: paymentSetting.updated_at || "",
        isAmexMCC: paymentSetting.is_amex_mcc,
        isWPMCC: paymentSetting.is_wp_mcc
      } as PaymentType,
      payments: upcomingPayments.map(p => ({
        cards: _get(p, "cards", []).map((card: any) => ({
          cardBrand: card.card_brand,
          id: card.id,
          last4: card.last4,
          statementDescriptor: card.statement_descriptor
        })),
        payees: _get(p, "payees", []).map((payee: any) => ({
          accountNumber: payee.account_number,
          bankId: payee.bank_id,
          bankBSBId: payee.bank_bsb_id,
          currentAmount: payee.amount,
          defaultAmount: payee.amount,
          defaultComments: payee.comments,
          id: payee.id,
          name: payee.recipient_name,
          refundedAmount: payee.refunded_amount,
          uid: payee.uid
        })),
        chargeDate: p.scheduled_charge_date,
        defaultComments: p.default_comments,
        editable: p.editable,
        id: p.id,
        paymentAmount: p.payment_amount,
        paymentFee: p.payment_fee,
        paymentGstFee: p.payment_gst_fees,
        paymentBankPayoutFee: p.payment_bank_payout_fees,
        paymentMinimumTransactionFee: p.payment_minimum_transaction_fees,
        paymentTotal: p.payment_total,
        payoutDate: p.payout_date,
        paymentStatusId: p.payment_status_id
      })),
      scheduleId: schedule.id,
      scheduleFrequency: PaymentUtil.convertFrequencyString(schedule.frequency),
      isActive: schedule.is_active
    })
  );
  if (action.payload.cb) {
    action.payload.cb({
      paymentId: _get(upcomingPayments, "[0].id", 0)
    });
  }
}

export function* handleSelectNextSchedule(
  action: ActionType<typeof actions.selectNextSchedule>
) {
  const state = yield select();
  let nextSelectedSchedule = formSelectors.getControl(
    state,
    "next_selected_schedule"
  ).value;

  const args = (nextSelectedSchedule as string).split(",");
  nextSelectedSchedule = args[0];

  nextSelectedSchedule = parseInt(nextSelectedSchedule, 10);

  yield put(actions.selectSchedule(nextSelectedSchedule));
}

export function* handleCancelPayments(
  action: ActionType<typeof actions.cancelPayments>
) {
  // Cancel payments in schedule
  const state = yield select();
  const selectedPaymentIds = commonSelectors.getSelectedPaymentIds(state);

  const res: Response = yield call(RestClient.send, {
    body: {
      payment_ids: selectedPaymentIds
    },
    service: "cancel_payments",
    showGlobalLoader: true
  });

  if (!res) {
    return;
  }

  const errors = _get(res, "errors", {});
  if (!_isEmpty(errors)) {
    yield put(formActions.parseServerErrors(errors, CONFIRM_FORM));
    return;
  }

  yield put(actions.fetchSchedules());
}

export function* handleCancelPayment(
  action: ActionType<typeof actions.cancelPayment>
) {
  // Cancel payment in schedule

  const res: Response = yield call(RestClient.send, {
    body: {
      payment_ids: [action.payload.id]
    },
    service: "cancel_payments",
    showGlobalLoader: true
  });

  if (!res) {
    return;
  }

  const errors = _get(res, "errors", {});
  if (!_isEmpty(errors)) {
    yield put(formActions.parseServerErrors(errors, CONFIRM_FORM));
    if (action.payload.cb) {
      action.payload.cb(errors);
    }
    return;
  }
  yield put(actions.fetchSchedules());
  if (action.payload.cb) {
    action.payload.cb(null);
  }
}

export function* handleCheckFeeVerbose(
  action: ActionType<typeof actions.checkFeeVerbose>
) {
  const state = yield select();
  const formState = formSelectors.getControls(state, SCHEDULE_EDIT_FORM);
  const paymentSelected = selectors.getSelectedPaymentDetail(state);
  const scheduleSelected = selectors.getScheduleDetail(state);
  const chargeDate = _get(
    formState,
    `charge_date_${scheduleSelected.scheduleId}.value`,
    scheduleSelected.scheduleStartDate // TODO: Should check here! Temple solution
  );

  const payees: Array<{
    accountNumber: string;
    bankId: number;
    defaultAmount: number;
    defaultComments?: string;
    id: number;
    name: string;
    uid?: string;
  }> = [];
  try {
    paymentSelected.payees.map((payee: any) => {
      if (payee.uid === "") {
        payees.push({
          accountNumber: _get(
            formState,
            `account_number_${payee.id}.value`,
            ""
          ),
          bankId: _get(formState, `bank_id_${payee.id}.value`),
          defaultAmount: utils.amountStringToInt(
            _get(formState, `supplier_amount_${payee.id}.value`, "0")
          ),
          defaultComments: _get(
            formState,
            `default_comments_${payee.id}.value`,
            ""
          ),
          id: payee.id,
          name: payee.name
        });
      } else {
        payees.push({
          accountNumber: _get(
            formState,
            `account_number_${payee.id}_${payee.uid}.value`
          ),
          bankId: _get(formState, `bank_id_${payee.id}_${payee.uid}.value`),
          defaultAmount: utils.amountStringToInt(
            _get(
              formState,
              `supplier_amount_${payee.id}_${payee.uid}.value`,
              "0"
            )
          ),
          defaultComments: _get(
            formState,
            `default_comments_${payee.id}_${payee.uid}.value`
          ),
          id: payee.id,
          name: payee.name,
          uid: payee.uid
        });
      }
    });
  } catch (e) {
    window.Logger.error("handleCheckFeeVerbose: ", e.message);
    return;
  }

  const res: Response = yield call(RestClient.send, {
    body: {
      card_id: action.payload.cardId,
      coupon_code: action.payload.couponCode,
      currency_id: paymentSelected.currencyId,
      end_date: scheduleSelected.scheduleEndDate,
      frequency:
        scheduleSelected.scheduleFrequency.toLowerCase() === "one-time"
          ? "once"
          : scheduleSelected.scheduleFrequency.toLowerCase(),
      payees: payees.map(payee => ({
        account_number: payee.accountNumber,
        amount: payee.defaultAmount,
        bank_id: payee.bankId,
        comments: payee.defaultComments,
        currency_id: paymentSelected.currencyId,
        id: payee.id,
        recipient_name: payee.name,
        uid: payee.uid
      })),
      purpose_id: paymentSelected.purposeId,
      start_date: chargeDate
    },
    service: "fees_verbose"
    // showGlobalLoader: true
  });

  if (!res) {
    return;
  }

  const data = _get(res, "data", {});

  yield put(
    actions.setFeesVerbose({
      [paymentSelected.id]: {
        coupon: data.coupon,
        fee: data.fee,
        rate: data.rate,
        rateBeforeCoupon: data.rateBeforeCoupon,
        savings: data.savings,
        total: data.total
      }
    })
  );

  if (action.payload.cb) {
    action.payload.cb(
      _get(res, "data.coupon", undefined),
      _get(res, "data.fee", 0)
    );
  }
}

export function* handleCouponUsageVerbose(
  action: ActionType<typeof actions.couponUsageVerbose>
) {
  const state = yield select();
  const formState = formSelectors.getControls(state, SCHEDULE_EDIT_FORM);
  const fromStateCode = formSelectors.getControls(
    state,
    SCHEDULE_EDIT_COUPON_FORM
  );
  const paymentSelected = selectors.getSelectedPaymentDetail(state);
  const scheduleSelected = selectors.getScheduleDetail(state);
  const couponCode = _get(fromStateCode, "code.value", "");

  const payees: Array<{
    accountNumber: string;
    bankId: number;
    defaultAmount: number;
    defaultComments?: string;
    id: number;
    name: string;
    uid?: string;
  }> = [];
  try {
    paymentSelected.payees.map((payee: any) => {
      if (payee.uid === "") {
        payees.push({
          accountNumber: _get(formState, `account_number_${payee.id}.value`),
          bankId: _get(formState, `bank_id_${payee.id}.value`),
          defaultAmount: utils.amountStringToInt(
            _get(formState, `supplier_amount_${payee.id}.value`, "0")
          ),
          defaultComments: _get(
            formState,
            `default_comments_${payee.id}.value`
          ),
          id: payee.id,
          name: payee.name
        });
      } else {
        payees.push({
          accountNumber: _get(
            formState,
            `account_number_${payee.id}_${payee.uid}.value`
          ),
          bankId: _get(formState, `bank_id_${payee.id}_${payee.uid}.value`),
          defaultAmount: utils.amountStringToInt(
            _get(
              formState,
              `supplier_amount_${payee.id}_${payee.uid}.value`,
              "0"
            )
          ),
          defaultComments: _get(
            formState,
            `default_comments_${payee.id}_${payee.uid}.value`
          ),
          id: payee.id,
          name: payee.name,
          uid: payee.uid
        });
      }
    });
  } catch (e) {
    window.Logger.error("handleCouponUsageVerbose: ", e.message);
    return;
  }

  const res: Response = yield call(RestClient.send, {
    body: {
      card_id: action.payload.cardId,
      coupon_code: couponCode,
      currency_id: paymentSelected.currencyId,
      end_date: scheduleSelected.scheduleEndDate,
      frequency:
        scheduleSelected.scheduleFrequency.toLowerCase() === "one-time"
          ? "once"
          : scheduleSelected.scheduleFrequency.toLowerCase(),
      payees: payees.map(payee => ({
        account_number: payee.accountNumber,
        amount: payee.defaultAmount,
        bank_id: payee.bankId,
        comments: payee.defaultComments,
        currency_id: paymentSelected.currencyId,
        id: payee.id,
        recipient_name: payee.name,
        uid: payee.uid
      })),
      purpose_id: paymentSelected.purposeId,
      start_date: _get(
        formState,
        `charge_date_${scheduleSelected.scheduleId}.value`
      )
    },
    service: "coupon_usage_verbose"
    // showGlobalLoader: true
  });

  if (!res) {
    return;
  }

  const errors = _get(res, "errors", {});
  if (!_isEmpty(errors)) {
    yield put(formActions.parseServerErrors(errors, SCHEDULE_EDIT_COUPON_FORM));
    return;
  }

  yield put(actions.checkFeeVerbose(action.payload.cardId, couponCode));

  if (action.payload.cb) {
    action.payload.cb(couponCode);
  }
}

export function* handleEditPayments(
  action: ActionType<typeof actions.editPayments>
) {
  const state = yield select();

  let res: Response | null = null;
  const scheduleSelected = selectors.getScheduleDetail(state);

  if (!action.payload.isDeleteCard) {
    const formState = formSelectors.getControls(state, SCHEDULE_EDIT_FORM);
    let paymentSelected = selectors.getSelectedPaymentDetail(
      state
    ) as PaymentType;
    if (!paymentSelected) {
      paymentSelected = selectors.getSchedulePaymentSetting(
        state
      ) as PaymentType;
    }

    const payees: Array<{
      accountNumber: string;
      bankId: number;
      bankBsbId: number;
      bankCode: string;
      bsbCode: string;
      defaultAmount: number;
      defaultComments?: string;
      id: number;
      name: string;
      uid?: string;
      recipient_name: string;
    }> = [];
    try {
      paymentSelected.payees.map(payee => {
        if (payee.uid === "") {
          payees.push({
            accountNumber: payee.accountNumber,
            bankBsbId: payee.bankBSBId,
            bankCode: payee.bankCode,
            bankId: payee.bankId,
            bsbCode: payee.bsbCode,
            defaultAmount: utils.amountStringToInt(
              _get(formState, `supplier_amount_${payee.id}.value`, "0")
            ),
            defaultComments: _get(
              formState,
              `default_comments_${payee.id}.value`
            ),
            id: payee.id,
            name: payee.name,
            recipient_name: payee.name
          });
        } else {
          payees.push({
            accountNumber: payee.accountNumber,
            bankBsbId: payee.bankBSBId,
            bankCode: payee.bankCode,
            bankId: payee.bankId,
            bsbCode: payee.bsbCode,
            defaultAmount: utils.amountStringToInt(
              _get(
                formState,
                `supplier_amount_${payee.id}_${payee.uid}.value`,
                "0"
              )
            ),
            defaultComments: _get(
              formState,
              `default_comments_${payee.id}_${payee.uid}.value`
            ),
            id: payee.id,
            name: payee.name,
            recipient_name: payee.name,
            uid: payee.uid
          });
        }
      });
    } catch (e) {
      window.Logger.error("handleEditPayments: ", e.message);
      return;
    }

    yield put(actions.showGlobalLoader());
    const firstPayout = _get(
      scheduleSelected,
      "upcomingPayment.payments[0]",
      ""
    );
    let wpSessionId: string | undefined;
    const cardId = _get(
      formState,
      `card_id_${scheduleSelected.scheduleId}.value`
    );
    if (action.payload.ids.length > 0) {
      if (_get(paymentSelected, "cards[0].id") !== cardId) {
        const card = selectors.cardsById(state)[cardId];
        if (CardUtil.isWorldpay(card?.acquirerId)) {
          const wpResult = yield call(
            getWorldpaySessionId,
            cardId,
            card.acquirerId
          );
          wpSessionId = wpResult.sessionId;
          window.Logger.error(JSON.stringify(wpResult));
          if (!wpSessionId) {
            yield put(actions.hideGlobalLoader());
            return;
          }
        }
      }
    } else {
      if (_get(firstPayout, "cards[0].id") !== cardId) {
        const card = selectors.cardsById(state)[cardId];
        if (CardUtil.isWorldpay(card.acquirerId)) {
          const wpResult = yield call(
            getWorldpaySessionId,
            cardId,
            card.acquirerId
          );
          wpSessionId = wpResult.sessionId;
          window.Logger.error(JSON.stringify(wpResult));
          if (!wpSessionId) {
            yield put(actions.hideGlobalLoader());
            return;
          }
        }
      }
    }

    res = yield call(RestClient.send, {
      body: {
        card_id: action.payload.cardId || cardId,
        code: _get(formState, `code.value`),
        currency_id: paymentSelected.currencyId,
        upcoming_date:
          _get(
            formState,
            `upcoming_date_${scheduleSelected.scheduleId}.value`
          ) || null,
        end_date:
          _get(formState, `end_date_${scheduleSelected.scheduleId}.value`) ||
          null,
        frequency:
          _get(scheduleSelected, "scheduleFrequency", "").toLowerCase() ===
          "one-time"
            ? "once"
            : _get(scheduleSelected, "scheduleFrequency", "").toLowerCase(),
        payees: payees.map(payee => ({
          account_number: payee.accountNumber,
          amount: payee.defaultAmount,
          bank_bsb_id: payee.bankBsbId,
          bank_id: payee.bankId,
          comments: payee.defaultComments,
          currency_id: paymentSelected.currencyId,
          id: payee.id,
          recipient_name: payee.name,
          uid: payee.uid
        })),
        payment_ids: action.payload.ids,
        payout_date:
          _get(formState, `payout_date_${scheduleSelected.scheduleId}.value`) ||
          null,
        purpose_id: paymentSelected.purposeId,
        schedule_id: scheduleSelected.scheduleId,
        wp_session_id: wpSessionId,
        cancel_duplicate_payment: action.payload.cancelDuplicatePayment
      },
      service: "edit_payments"
    });
  } else {
    let wpSessionId: string | undefined;
    if (action.payload.cardId) {
      const card = selectors.cardsById(state)[action.payload.cardId];
      if (CardUtil.isWorldpay(card?.acquirerId)) {
        const wpResult = yield call(
          getWorldpaySessionId,
          action.payload.cardId,
          card.acquirerId
        );
        wpSessionId = wpResult.sessionId;
        window.Logger.error(JSON.stringify(wpResult));
        if (!wpSessionId) {
          yield put(actions.hideGlobalLoader());
          return;
        }
      }
    }
    res = yield call(RestClient.send, {
      body: {
        card_id: action.payload.cardId,
        // schedule_ids: action.payload.scheduleIds,
        schedule_id: action.payload.scheduleId,
        wp_session_id: wpSessionId
      },
      service: "edit_payments",
      noRedirect: true
    });
  }

  if (!res) {
    yield put(actions.hideGlobalLoader());
    return;
  }

  if (action.payload.isDeleteCard && (res as any).xLocation) {
    if (action.payload.cb) {
      return action.payload.cb(null, {
        xLocation: (res as any).xLocation
      });
    }
  }

  const errors = _get(res, "errors", {});
  const requiredAction = _get(res, "action_required", undefined);
  if (requiredAction) {
    if (requiredAction.recollect_cvv) {
      yield put(actions.hideGlobalLoader());
      yield put(
        actions.toggleModal(actions.ModalID.RECOLLECT_CVV, {
          callback: (cvv: string | StripeCardCvcElement) => {
            const it = processPaymentRequiredAction(
              {
                acquirerId: requiredAction.acquirer_id,
                callbackUrl: requiredAction.callback_url,
                threedsToken: requiredAction.threeds_token,
                paymentTotal: requiredAction.payment_total,
                bin: requiredAction.bin,
                requestId: requiredAction.request_id,
                provider: requiredAction.provider,
                clientToken: requiredAction.client_token,
                mid: requiredAction.mid ? requiredAction.mid : undefined,
                recollectCVV: requiredAction.recollect_cvv,
                startTime: requiredAction.start_time
              },
              cvv,
              action.payload.isDeleteCard
            );

            while (!it.next().done) {
              // do nothing
            }
          },
          acquirerId: requiredAction.acquirer_id,
          provider: requiredAction.provider,
          onClose: () => {
            if (action.payload && action.payload.loadingFunc) {
              action.payload.loadingFunc(false);
            }
          }
        })
      );
    } else {
      yield processPaymentRequiredAction(
        {
          acquirerId: requiredAction.acquirer_id,
          callbackUrl: requiredAction.callback_url,
          threedsToken: requiredAction.threeds_token,
          paymentTotal: requiredAction.payment_total,
          bin: requiredAction.bin,
          requestId: requiredAction.request_id,
          provider: requiredAction.provider,
          clientToken: requiredAction.client_token,
          mid: requiredAction.mid ? requiredAction.mid : undefined,
          recollectCVV: requiredAction.recollect_cvv,
          startTime: requiredAction.start_time
        },
        undefined,
        action.payload.isDeleteCard,
        action.payload.cb
      );
    }
    return;
  }

  yield put(actions.hideGlobalLoader());

  if (!_isEmpty(errors)) {
    yield put(formActions.parseServerErrors(errors, SCHEDULE_EDIT_FORM));
    yield put(
      actions.setUpdatedPaymentStatus({
        failed: true,
        success: false
      })
    );
    if (action.payload.cb) {
      action.payload.cb(errors, null);
    }
    return;
  }
  if (action.payload.cb) {
    action.payload.cb(null, null);
    yield put(actions.fetchSchedules("y", 0));
    return;
  }

  yield put(actions.closeModal(ModalID.EDIT_PAYMENT_DETAIL_MODAL));
  yield put(
    actions.fetchSchedules(
      "y",
      0,
      !action.payload.notReOpenScheduleDetailModal
        ? reOpenScheduleDetailModal.bind({}, scheduleSelected.scheduleId)
        : undefined
    )
  );
}

function* reOpenScheduleDetailModal(scheduleId: number) {
  yield put(actions.selectSchedule(scheduleId));
  yield put(
    actions.toggleModal(ModalID.SCHEDULED_PAYMENT_DETAIL, {
      key: "schedule_detail"
    })
  );
  yield put(
    actions.setUpdatedPaymentStatus({
      failed: false,
      success: true
    })
  );
}

export function* handleValidatePayee(
  action: ActionType<typeof actions.validatePayee>
) {
  const state = yield select();
  const formState = formSelectors.getControls(state, SCHEDULE_EDIT_FORM);
  const paymentSelected = selectors.getSelectedPaymentDetail(state);

  const payees: Array<{
    accountNumber: string;
    bankId: number;
    defaultAmount: number;
    defaultComments?: string;
    id: number;
    name: string;
    uid?: string;
  }> = [];

  try {
    paymentSelected.payees.map((payee: any) => {
      if (payee.uid === "") {
        payees.push({
          accountNumber: _get(formState, `account_number_${payee.id}.value`),
          bankId: _get(formState, `bank_id_${payee.id}.value`),
          defaultAmount: utils.amountStringToInt(
            _get(formState, `supplier_amount_${payee.id}.value`, "0")
          ),
          defaultComments: _get(
            formState,
            `default_comments_${payee.id}.value`
          ),
          id: payee.id,
          name: payee.name
        });
      } else {
        payees.push({
          accountNumber: _get(
            formState,
            `account_number_${payee.id}_${payee.uid}.value`
          ),
          bankId: _get(formState, `bank_id_${payee.id}_${payee.uid}.value`),
          defaultAmount: utils.amountStringToInt(
            _get(
              formState,
              `supplier_amount_${payee.id}_${payee.uid}.value`,
              "0"
            )
          ),
          defaultComments: _get(
            formState,
            `default_comments_${payee.id}_${payee.uid}.value`
          ),
          id: payee.id,
          name: payee.name,
          uid: payee.uid
        });
      }
    });
  } catch (e) {
    window.Logger.error("handleValidatePayee: ", e.message);
    return;
  }

  let purpose = "";

  switch (paymentSelected.purposeId) {
    case PURPOSE.RENTAL:
      purpose = "rent";
      break;
    case PURPOSE.SALARY:
      purpose = "salary";
      break;
    case PURPOSE.INVOICE:
      purpose = "invoice";
      break;
    default:
      purpose = "";
  }

  const res: Response = yield call(RestClient.send, {
    body: {
      payees: payees.map(payee => ({
        account_number: payee.accountNumber,
        amount: payee.defaultAmount,
        bank_id: payee.bankId,
        comments: payee.defaultComments,
        currency_id: paymentSelected.currencyId,
        id: payee.id,
        recipient_name: payee.name,
        uid: payee.uid
      })),
      purpose
    },
    service: "validate_payment_payee"
  });

  if (!res) {
    return false;
  }

  yield put(actions.setSubmitButtonState(false));

  const json = res;
  const errors = _get(json, "errors", {});

  if (!_isEmpty(errors)) {
    yield put(formActions.parseServerErrors(errors, SCHEDULE_EDIT_FORM));
    return false;
  }
  return;
}

export function* handleDepositInternationalPayments(
  action: ActionType<typeof actions.depositInternationalPayments>
) {
  const res = yield call(RestClient.send, {
    body: {
      payment_ids: action.payload.paymentId
    },
    service: "deposit_international_payments",
    showGlobalLoader: true
  });

  if (!res) {
    return;
  }

  const errors = _get(res, "errors");
  if (!_isEmpty(errors)) {
    for (const e of _get(errors, "form", [])) {
      yield put(actions.toast(T.transl(e)));
    }

    return;
  }

  yield put(actions.fetchAdminPayments());
}

export function* handleFetchPaymentsHistoryList(
  action: ActionType<typeof actions.fetchPaymentsHistoryList>
) {
  const state: RootState = yield select();
  const { offset, paycount, cb = () => null } = action.payload;

  yield put(
    actions.setPayments({
      isFetching: true,
      payments: [],
      sumPaymentAmount: 0,
      sumPaymentTotal: 0,
      total: 0,
      totalBankPayout: 0,
      totalStripeTransactions: 0
    })
  );

  const defaultStatusIds = DEFAULT_PAYMENT_STATUS_IDS.toString();
  // new default: "2, 3,4,5,9,10,11,15,16,17"
  // old default: "3,4,5,6,7,9,10,11,15"

  const dfPurposeIds = "1,2,3,5,7,8";

  const qs = queryString.parse(window.location.search);
  const statusesFromQs = qs.statuses;
  const favouriteFromQs = qs.favourite;
  const purposesFromQs = qs.purposes;
  const searchKeywordFromQs = qs.search_keyword;
  const frequencyFromQs = qs.frequency;
  const destinationFromQs = qs.destination;

  const {
    from: chargeDateLowerFromQs,
    to: chargeDateUpperFromQs
  } = getQueryParam(
    qs,
    "charge_date_key",
    "charge_date_from",
    "charge_date_to"
  );

  const {
    from: payoutDateLowerFromQs,
    to: payoutDateUpperFromQs
  } = getQueryParam(
    qs,
    "payout_date_key",
    "payout_date_from",
    "payout_date_to"
  );

  let query = {
    offset,
    page_count: paycount || PAGE_COUNT,
    is_favourite: favouriteFromQs,
    purposes: purposesFromQs || dfPurposeIds,
    statuses: statusesFromQs || defaultStatusIds
  } as any;

  const isInvoiceFilter = +query.purposes === PURPOSE.INVOICE;
  const isTaxFilter = query.purposes.includes(PURPOSE.TAX);

  query.purposes = query.purposes.replace(PURPOSE.TAX, PURPOSE.INVOICE);

  if (searchKeywordFromQs) {
    query.search_keyword = searchKeywordFromQs;
  }

  if (frequencyFromQs) {
    query.frequency = frequencyFromQs;
  }

  if (destinationFromQs) {
    query.destination = destinationFromQs;
  }

  const formValues = formSelectors.getControlsAsObject(
    state,
    FILTER_EXPORT_FORM_PAYMENTS
  ) as any;

  const {
    [`charge_date_lower_${FILTER_EXPORT_FORM_PAYMENTS}`]: chargeDateLower,
    [`charge_date_upper_${FILTER_EXPORT_FORM_PAYMENTS}`]: chargeDateUpper,
    [`status_ids_${FILTER_EXPORT_FORM_PAYMENTS}`]: statusIds
  } = formValues;

  const withFilters = !!chargeDateLower || !!chargeDateUpper || !!statusIds;

  if (withFilters) {
    query = {
      ...query,
      charge_date_lower: chargeDateLower,
      charge_date_upper: chargeDateUpper,
      statuses: !!statusIds
        ? (statusIds as string).replace(/-/g, ",")
        : defaultStatusIds
    };
  }

  if (chargeDateLowerFromQs) {
    query.charge_date_lower = chargeDateLowerFromQs;
  }
  if (chargeDateUpperFromQs) {
    query.charge_date_upper = chargeDateUpperFromQs;
  }
  if (payoutDateLowerFromQs) {
    query.payout_date_lower = payoutDateLowerFromQs;
  }
  if (payoutDateUpperFromQs) {
    query.payout_date_upper = payoutDateUpperFromQs;
  }

  const res: Response = yield call(RestClient.send, {
    query,
    service: "get_payments_history_list",
    timeout: 30000
  });

  if (!res) {
    yield put(
      actions.setPayments({
        isFetching: false,
        payments: []
      })
    );
    throw new HttpRequestError("Failed to fetch");
  }

  const data: any[] = _get(res, "data", []);
  const total: number = _get(res, "total", 0);
  let payments = data.map(payment => ({
    cards: payment.cards.map((card: any) => ({
      cardBrand: card.card_brand,
      id: card.id,
      last4: card.last4,
      statementDescriptor: card.statement_descriptor
    })),
    createdAt: payment.created_at,
    currencyId: payment.currency_id,
    id: payment.id,
    isMultiplePurpose: payment.is_multiple_purpose,
    order: payment.order,
    payees: payment.payees.map((payee: any) => ({
      accountNumber: payee.account_number,
      bankId: payee.bank_id,
      countryId:
        payee.bank_country_id === 0 ? payee.currency_id : payee.bank_country_id,
      currencyId: payee.currency_id,
      currentAmount: payee.amount,
      defaultAmount: payee.amount,
      defaultComments: payee.comments,
      id: payee.id,
      name: payee.recipient_name,
      refundedAmount: payee.refunded_amount,
      uid: payee.uid,
      bankAccountHolderName: payee.bank_account_holder_name
    })),
    paymentAmount: payment.payment_amount,
    paymentFees: payment.payment_fees,
    paymentStatusId: payment.payment_status_id,
    paymentTotal: payment.payment_total,
    payoutDate: payment.payout_date,
    purposeId: payment.purpose_id,
    receiptNumber: payment.receipt_number,
    scheduleEndDate: payment.schedule_end_date,
    scheduleFrequency: payment.schedule_frequency,
    scheduleId: payment.schedule_id,
    scheduleStartDate: payment.schedule_start_date,
    scheduledChargeDate: payment.scheduled_charge_date,
    supportingDocument: [],
    updatedAt: payment.updated_at,
    metadata: payment.metadata,
    isAmexMCC: payment.is_amex_mcc,
    paymentMethodType: payment.payment_method_type,
    cryptoPaymentDetail: {
      id: _get(payment, "crypto_payment_detail.id"),
      cryptoCurrency: _get(payment, "crypto_payment_detail.crypto_currency"),
      exchangeRate: _get(payment, "crypto_payment_detail.exchange_rate")
    }
  }));

  if (isTaxFilter) {
    payments = payments.filter(payment => {
      const payeeAccountNumber = _get(payment, "payees[0].accountNumber");
      const isMultiplePurpose = _get(payment, "isMultiplePurpose");
      return (
        isMultiplePurpose ||
        payeeAccountNumber === "0010468669" ||
        payeeAccountNumber === "316385"
      );
    });
  }

  if (isInvoiceFilter) {
    payments = payments.filter(payment => {
      const payeeAccountNumber = _get(payment, "payees[0].accountNumber");
      const isMultiplePurpose = _get(payment, "isMultiplePurpose");
      return (
        isMultiplePurpose ||
        (payeeAccountNumber !== "0010468669" && payeeAccountNumber !== "316385")
      );
    });
  }

  yield put(
    actions.setPayments({
      isFetching: false,
      payments,
      total
    })
  );

  yield put(
    fetchActions.setFirstTimeFetchData({
      payments: true
    })
  );

  cb(null, payments);
}

export function* handleFetchPaymentsHistoryExport(
  action: ActionType<typeof actions.fetchPaymentsHistoryExport>
) {
  const defaultStatusIds = DEFAULT_PAYMENT_STATUS_IDS.toString();
  // new default: "3,4,5,9,10,11,15,16,17"
  // old default: "3,4,11,15"

  let query = {
    offset: 0,
    page_count: 1000, // PAGE_COUNT,
    purposes: "1,2,3,5,7",
    statuses: defaultStatusIds,
    template: "normal"
  } as any;

  const state = yield select();

  const formValues = formSelectors.getControlsAsObject(
    state,
    FILTER_EXPORT_FORM_PAYMENTS
  ) as any;

  const {
    [`charge_date_lower_${FILTER_EXPORT_FORM_PAYMENTS}`]: chargeDateLower,
    [`charge_date_upper_${FILTER_EXPORT_FORM_PAYMENTS}`]: chargeDateUpper,
    [`status_ids_${FILTER_EXPORT_FORM_PAYMENTS}`]: statusIds
  } = formValues;

  const withFilters = !!chargeDateLower || !!chargeDateUpper || !!statusIds;

  if (withFilters) {
    query = {
      ...query,
      charge_date_lower: chargeDateLower,
      charge_date_upper: chargeDateUpper,
      statuses: !!statusIds
        ? (statusIds as string).replace(/-/g, ",")
        : defaultStatusIds
    };
  }

  const startDate = query.charge_date_lower
    ? format(query.charge_date_lower, "DD_MMM_YYYY")
    : "x";
  const endDate = query.charge_date_upper
    ? format(query.charge_date_upper, "DD_MMM_YYYY")
    : "y";

  const res = yield call(RestClient.download, {
    fileName: `my_payments_csv_${startDate}-${endDate}.csv`,
    query,
    service: "get_payments_history_export",
    timeout: 30000
  });

  const errors = _get(res, "errors");

  if (!_isEmpty(errors)) {
    for (const e of _get(errors, "form", [])) {
      yield put(actions.toast(T.transl(e)));
    }

    return;
  }
}

export function* handleAdminCancelPayment(
  action: ActionType<typeof actions.adminCancelPayment>
) {
  // Cancel payments in schedule
  const paymentId = action.payload.paymentId;
  const userEmail = action.payload.userEmail;
  const statusId = action.payload.statusId;

  const res: Response = yield call(RestClient.send, {
    body: {
      payment_id: paymentId,
      payment_status_id: statusId,
      user_email: userEmail
    },
    service: "admin_cancel_payment",
    showGlobalLoader: true
  });

  if (!res) {
    return;
  }

  const errors = _get(res, "errors", {});
  if (!_isEmpty(errors)) {
    for (const e of _get(errors, "form", [])) {
      yield put(actions.toast(T.transl(e)));
    }
    return;
  }

  yield put(actions.fetchAdminPayments());
}

export function* handleFetchLogChargeAttempt(
  action: ActionType<typeof actions.fetchLogChargeAttempt>
) {
  const res: Response = yield call(RestClient.send, {
    params: {
      id: action.payload.id
    },
    service: "get_log_charge_attempt",
    showGlobalLoader: false
  });

  if (!res) {
    throw new HttpRequestError("Failed to fetch");
  }

  const data: any = _get(res, "data");

  yield put(
    actions.setLogChargeAttempt(
      data.map((item: any) => ({
        id: item.payment_id,
        success: item.success,
        metadata: item.metadata,
        errors: item.errors,
        actionType: item.action_type,
        last4: item.last4,
        cardBrandId: item.card_brand_id,
        name: item.name,
        cardType: item.card_type,
        bankIssuer: item.bank_issuer,
        cardFunding: item.card_funding
      }))
    )
  );
}

export function* handleFetchIpmMerchantCategories(
  action: ActionType<typeof actions.fetchIpmMerchantCategories>
) {
  const { cardBrandId, purposeId, justGetData, cb } = action.payload;
  const res: Response = yield call(RestClient.send, {
    query: {
      card_brand_id: cardBrandId,
      purpose_id: purposeId
    },
    service: "get_ipm_merchant_descriptions",
    showGlobalLoader: false
  });

  if (!res) {
    throw new HttpRequestError("Failed to fetch");
  }

  const data: any = _get(res, "data", []);
  const ipmMerchantCategories = data
    .map((item: any) => {
      const isHasChildren = item.payment_descriptions.length > 1;
      return {
        label: isHasChildren ? item.name : item.payment_descriptions[0].name,
        value: isHasChildren
          ? item.name
          : `${item.id}_${item.payment_descriptions[0].id}`,
        card_brand_ids: item.payment_descriptions[0].card_brand_ids,
        children: isHasChildren
          ? item.payment_descriptions
              .map((pd: any) => {
                return {
                  label: pd.name,
                  value: `${item.id}_${pd.id}`,
                  card_brand_ids: pd.card_brand_ids
                };
              })
              .sort((a: any, b: any) => a.label.localeCompare(b.label))
          : []
      };
    })
    .sort((a: any, b: any) => a.label.localeCompare(b.label));

  const optionOther = ipmMerchantCategories.filter(
    (item: any) => item.label === "Other"
  );
  let ipmMerchantCategoriesFilter = optionOther.length
    ? ipmMerchantCategories
        .filter((item: any) => item.label !== "Other")
        .concat(optionOther)
    : ipmMerchantCategories;

  if (!justGetData) {
    yield put(actions.setIpmMerchantCategories(ipmMerchantCategoriesFilter));
  }

  if (cb) {
    cb(null, ipmMerchantCategoriesFilter);
  }
}
