import React from "react";
import { connect } from "react-redux";
import classNames from "classnames";

import Dropdown from "reactstrap/lib/Dropdown";
import DropdownToggle from "reactstrap/lib/DropdownToggle";
import DropdownMenu from "reactstrap/lib/DropdownMenu";
import DropdownItem from "reactstrap/lib/DropdownItem";

import _isEmpty from "lodash-es/isEmpty";
import _findIndex from "lodash-es/findIndex";

import InputText from "src/ipm-shared/components/Form/controls/InputText";

import { RootState } from "src/ipm-shared/store/model/reducers";
import T from "src/ipm-shared/Utils/Intl";

import * as selectors from "../selectors";
import * as actions from "../actions";
import { ControlErrorType, ControlType, ControlValueType } from "../types";

import withPropsChecker from "./lib/withPropsChecker";
import BaseControl, { IBaseProps, IBaseState } from "./lib/Base";

export type ISelectOption = {
  value: string | number | undefined;
  label: string | React.Component;
  disableItem?: boolean;
  divider?: boolean;
  callbackOnSelect?: (value: string | number | undefined) => void;
};

type ISelectProps = IBaseProps & {
  options: ISelectOption[];
  labelClassName?: string;
  itemClassName?: string;
  fixedLabel?: string;
  preventFromInitChange?: boolean;
  dropdownMenuRight?: boolean;
};

type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  ISelectProps;

type ISelectState = IBaseState & {
  dropdownShown: boolean;
  originValue?: string;
  searchValue?: string;
};

class SelectSuggestion extends BaseControl<IProps, ISelectState> {
  public static defaultProps = {
    ...BaseControl.defaultProps
  };

  public componentDidMount() {
    const { name, defaultValue, form, displayError, control } = this.props;

    if (this.shouldRevertValueOnMount(control)) {
      return;
    }

    // Init control
    this.props.setControl({
      errors: [],
      form,
      group: this.props.group,
      name,
      value: defaultValue
    });

    this.doValidate(defaultValue, name, displayError, true);
  }

  public componentWillUnmount() {
    this.alive = false;

    if (!this.props.reserveValueOnUnmount) {
      this.props.removeControl(this.props.name);
    } else {
      this.props.resetControlErrors(this.props.name);
    }
  }

  public render() {
    const {
      name,
      control,
      errorStyle,
      labelClassName,
      fixedLabel,
      placeholder
    } = this.props;

    if (control.notFound) {
      return null;
    }

    if (this.props.labelOnly) {
      return this.customRenderLabelOnly(control, this.props.id, errorStyle);
    }

    const disableToggle = !!this.props.disabled;

    return (
      <div className="select-suggestion w-100">
        <Dropdown
          className={this.props.className}
          isOpen={this.state.dropdownShown}
          toggle={this.toggleDropDown}
        >
          <DropdownToggle
            disabled={disableToggle}
            tag="span"
            className={classNames("form-control", labelClassName, {
              "is-invalid text-danger":
                control.displayError && control.errors.length > 0
            })}
            caret={true}
            tabIndex={0}
          >
            {fixedLabel ? fixedLabel : this.getLabel()}
          </DropdownToggle>

          <DropdownMenu tag="ul">
            <InputText
              className={"form-control form-control-search"}
              type="text"
              name={`${name}_search_field`}
              placeholder={placeholder}
              onChangeCustom={this.onChangeSearchText}
            />
            {this.getOptions().length > 0 ? (
              this.getOptions().map((o, idx) =>
                o.divider ? (
                  <DropdownItem key={idx} divider={true} />
                ) : (
                  <DropdownItem
                    key={idx}
                    header={false}
                    text={""}
                    disabled={o.disableItem}
                    tag="li"
                    onClick={this.onChooseOption.bind(this, idx)}
                  >
                    <span title={`${o.label}`}>{o.label}</span>
                  </DropdownItem>
                )
              )
            ) : (
              <div>
                <p className={"text-white p-2"}>
                  {T.transl("SEARCH_BANK_NO_RESULTS")}
                </p>
              </div>
            )}
          </DropdownMenu>

          {this.renderErrorMessage(control)}
        </Dropdown>
      </div>
    );
  }

  private toggleDropDown = () => {
    this.setState({ dropdownShown: !this.state.dropdownShown });
  };

  private getLabel(): ISelectOption["label"] {
    const { options, control, placeholder } = this.props;

    if (options.length === 0) {
      return placeholder || "";
    }
    let label: ISelectOption["label"] = "";

    options.map((o: ISelectOption) => {
      if (o.value === control.value) {
        label = o.label;
      }
    });

    if (label === "") {
      label = placeholder || options[0].label;
    }

    return label;
  }

  private doValidate = (
    value: ControlValueType,
    name: string,
    displayError: boolean = true,
    isInitChange: boolean = false
  ) => {
    let it = this.validate(value);
    let result: any;
    const errors: ControlErrorType[] = [];

    while (!(result = it.next()).done) {
      errors.push(result.value);
    }

    it = SelectSuggestion.baseValidate(this.props, value);
    while (!(result = it.next()).done) {
      errors.push(result.value);
    }

    if (errors.length === 0) {
      if (isInitChange && this.props.preventFromInitChange) {
        // do nothing
      } else {
        if (this.props.onChangeCustom && this.alive) {
          this.props.onChangeCustom(value);
        }

        this.props.options.map(o => {
          if (o.value === value && o.callbackOnSelect !== undefined) {
            o.callbackOnSelect(o.value);
          }
        });
      }
    } else {
      this.props.setControl({
        displayError,
        errors,
        name
      });
    }
  };

  private *validate(value: ControlValueType) {
    const { required, requiredMessage, options } = this.props;

    if (required) {
      // Case: Default value does not belong to select options
      if (value && _findIndex(options, o => o.value === value) === -1) {
        yield {
          code: "REQUIRED",
          message: requiredMessage || T.transl("REQUIRED_FIELD")
        };
      }
    }
  }

  private getValue(key: number): ISelectOption["value"] {
    const options = this.getOptions();

    if (_isEmpty(options[key])) {
      return undefined;
    } else {
      return options[key].value;
    }
  }

  private onChooseOption = (key: number) => {
    const { name, form } = this.props;
    const value = this.getValue(key);

    this.props.setControl({
      errors: [],
      form,
      name,
      value
    });

    this.doValidate(value, name);
  };

  private onChangeSearchText = (searchValue: string) => {
    this.setState({
      searchValue
    });
  };

  private filterList = (option: ISelectOption) => {
    if (this.state.searchValue) {
      return (
        `${option.label}`
          .toLowerCase()
          .search(this.state.searchValue.toLowerCase()) !== -1
      );
    }
    return true;
  };

  private getOptions = () => {
    return this.props.options.filter(this.filterList);
  };

  private customRenderLabelOnly = (
    control: ControlType,
    id?: string,
    errorStyle?: string
  ) => {
    let output = this.renderLabelOnly(control, id, errorStyle, false);
    if (!output) {
      output = <span title={`${this.getLabel()}`}>{this.getLabel()}</span>;
    }

    return output;
  };
}

const mapStateToProps = (
  state: RootState,
  props: ISelectProps
): {
  control: ReturnType<typeof selectors.getControl>;
} => ({
  control: selectors.getControl(state, props.name)
});

const mapDispatchToProps = {
  removeControl: actions.removeControl,
  resetControlErrors: actions.resetControlErrors,
  setControl: actions.setControl
};

export default withPropsChecker(
  connect(mapStateToProps, mapDispatchToProps)(SelectSuggestion),
  ["defaultValue"]
);
