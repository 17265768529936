import _get from "lodash-es/get";
import ROUTES from "src/bepaid/routes";
import { PURPOSE } from "src/ipm-shared/store/model/Payment/const";

export const getRecipientPurpose = (recipient: any) => {
  const ipaymyEvent = _get(recipient, "ipaymyEvent", "");
  const purposeId = _get(recipient, "purposeId", 0);

  if (purposeId === PURPOSE.RENTAL) {
    return "Rent";
  }

  if (purposeId === PURPOSE.SALARY) {
    return "Salary";
  }

  if (purposeId === PURPOSE.INVOICE && ipaymyEvent === "TAX2019") {
    return "Tax";
  }

  if (purposeId === PURPOSE.SELF_TRANSFER) {
    return "Money transfer";
  }

  return "Supplier";
};

export const getRecipientTitle = (recipient: any) => {
  const ipaymyEvent = _get(recipient, "ipaymyEvent", "");
  const purposeId = _get(recipient, "purposeId", 0);

  if (purposeId === PURPOSE.RENTAL) {
    return "Landlord";
  }

  if (purposeId === PURPOSE.SALARY) {
    return "Employee";
  }

  if (purposeId === PURPOSE.INVOICE && ipaymyEvent === "TAX2019") {
    return "Tax";
  }

  if (purposeId === PURPOSE.SELF_TRANSFER) {
    return "Bank account";
  }

  return "Supplier";
};

export const getRecipientPurposeRoute = (recipient: any) => {
  const ipaymyEvent = _get(recipient, "ipaymyEvent", "");
  const purposeId = _get(recipient, "purposeId", 0);

  if (purposeId === PURPOSE.RENTAL) {
    return ROUTES.RECIPIENTS_LANDLORDS;
  }

  if (purposeId === PURPOSE.SALARY) {
    return ROUTES.RECIPIENTS_EMPLOYEES;
  }

  if (purposeId === PURPOSE.INVOICE && ipaymyEvent === "TAX2019") {
    return ROUTES.RECIPIENTS_SUPPLIERS;
  }

  if (purposeId === PURPOSE.SELF_TRANSFER) {
    return ROUTES.RECIPIENTS_BANK_ACCOUNTS;
  }

  return ROUTES.RECIPIENTS_SUPPLIERS;
};

export const typeByEntity = {
  landlords: "landlord",
  employees: "employee",
  suppliers: "supplier",
  "bank-accounts": "bank_account"
};

export const getEntityByPurposeId = (purposeId: number) => {
  switch (purposeId) {
    case PURPOSE.RENTAL:
      return "landlords";

    case PURPOSE.SALARY:
    case PURPOSE.SALARY_BUSINESS:
      return "employees";

    case PURPOSE.INVOICE:
      return "suppliers";

    case PURPOSE.SELF_TRANSFER:
      return "bank-accounts";

    default:
      return "";
  }
};
export const getEntityByType = (type: string) => {
  switch (type) {
    case typeByEntity.landlords:
      return "rent";

    case typeByEntity.employees:
      return "salary";

    case typeByEntity.suppliers:
      return "invoice";

    case typeByEntity["bank-accounts"]:
      return "self_transfer";

    default:
      return "";
  }
};
