import React from "react";
// import classNames from "classnames";

import { connect } from "react-redux";
import _get from "lodash-es/get";
import Checkbox from "antd/es/checkbox";

import SharedCheckBoxText from "src/ipm-shared/components/Form/controls/Checkbox";
import T from "src/ipm-shared/Utils/Intl";

import * as actions from "src/ipm-shared/components/Form/actions";

import styles from "./Checkbox.module.scss";
import classNames from "classnames";

const Component = (props: any) => {
  const renderComponent = (sharedProps: any) => {
    const {
      label,
      // className = null,
      onChange = null,
      forcedHasError = false,
      wrapperClassName = ""
    } = props;

    // INFO: props in custom component should be a higher priority
    const handleChange = (...params: any[]) => {
      if (sharedProps.onChange) {
        sharedProps.onChange(...params);
      }

      if (onChange) {
        onChange(...params);
      }
    };

    const commonProps = {
      ...sharedProps,
      ...props,
      onChange: handleChange
    };

    const { control = {} } = sharedProps;

    const { errors = [] } = control;

    const errorMessage = _get(errors, "0.code", "");

    const hasError = forcedHasError || errors.length > 0;

    return (
      <>
        <Checkbox
          {...commonProps}
          className={classNames(styles.wrapper, wrapperClassName)}
        >
          {label}
        </Checkbox>
        {hasError && (
          <p className={styles.helperTextHasError}>{T.transl(errorMessage)}</p>
        )}
      </>
    );
  };

  return <SharedCheckBoxText {...props} renderComponent={renderComponent} />;
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  setControl: actions.setControl
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
